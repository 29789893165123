import { useState } from 'react';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import MdiIcon from 'src/componentes/mdi-icon';
import Input from 'src/componentes/input';
import Checkbox from 'src/componentes/checkbox';
import ColaboradorEGrupoEResponsabilidadeLookup from 'src/lookups/ColaboradorEGrupoEResponsabilidadeLookup';
import guid from 'src/utils/guid';

const Edit = ({
  model,
  index,
  etapa,
  provided,
  showTrashIcon,
  disabled,
  ehFluxoNegociacaoMetas,
}) => {
  const intl = useIntl();

  const [tooltipId] = useState(`UNIQUE-APPV-${guid()}`);

  const handleChangeFields = (field, value) => {
    const newList = [...model.value];
    const item = newList[index];

    if (item) item[field] = value;

    model.requestChange(newList);
  };

  const handleChangePeopleAndGroupsAndResponsibilities = (list) => {
    const newApprovers =
      list?.filter((x) => x.identificadorLista === 'colaboradores') || [];
    const newGroups =
      list?.filter((x) => x.identificadorLista === 'grupos') || [];
    const newResponsibilities =
      list?.filter((x) => x.identificadorLista === 'responsabilidades') || [];

    handleChangeFields('aprovadores', newApprovers);
    handleChangeFields('gruposAprovadores', newGroups);
    handleChangeFields('responsabilidades', newResponsibilities);
  };

  const handleRemove = () => {
    const newList = [...model.value];
    newList.splice(index, 1);

    model.requestChange(newList);
  };

  const aprovadores = etapa.aprovadores || [];
  const gruposAprovadores = etapa.gruposAprovadores || [];
  const responsabilidades = etapa.responsabilidades || [];

  return (
    <tr
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
      }}
    >
      {!disabled && (
        <td
          style={{ width: '5%' }}
          className="text-center pt-1"
          {...provided.dragHandleProps}
        >
          <MdiIcon size={22} icon="drag" />
        </td>
      )}
      <td
        style={{
          width: '20%',
        }}
        className="pr-3 pt-1"
      >
        <Input
          autoFocus={false}
          formStyle={{ marginBottom: 0 }}
          disabled={disabled}
          model={{
            label: '',
            value: etapa.nome,
            requestChange: (v) => handleChangeFields('nome', v),
          }}
          required
        />
      </td>
      <td style={{ width: '60%' }} className="pr-3 pt-1">
        <ColaboradorEGrupoEResponsabilidadeLookup
          multi
          autoFocus={false}
          formGroupStyle={{ marginBottom: 0 }}
          disabled={disabled}
          ehFluxoNegociacaoMetas={ehFluxoNegociacaoMetas}
          model={{
            label: '',
            value: [...aprovadores, ...gruposAprovadores, ...responsabilidades],
            requestChange: handleChangePeopleAndGroupsAndResponsibilities,
          }}
          data={{ ehFluxoNegociacaoMetas: ehFluxoNegociacaoMetas }}
        />
      </td>
      <td style={{ width: '15%' }} className="pr-3 pt-1">
        <div className="d-flex align-items-center font-weight-bold">
          <Checkbox
            autoFocus={false}
            disabled={disabled}
            model={{
              label: intl.formatMessage({ id: 'aprovacaoUnica' }),
              value: etapa.aprovacaoUnica,
              requestChange: (v) => handleChangeFields('aprovacaoUnica', v),
            }}
          />
          <MdiIcon
            id={tooltipId}
            data-for={tooltipId}
            data-tip
            size={14}
            className="ml-2 text-muted"
            icon="information-outline"
          />
        </div>
        <ReactTooltip
          className="fluxo-aprovacao-tooltip"
          id={tooltipId}
          aria-haspopup="true"
        >
          {intl.formatMessage({ id: 'aprovacaoUnicaExplicacao' })}
        </ReactTooltip>
      </td>
      {showTrashIcon && !disabled && (
        <td style={{ width: '5%' }} className="text-center text-primary pt-1">
          <MdiIcon
            size={20}
            onClick={handleRemove}
            className="cursor-pointer"
            icon="delete"
          />
        </td>
      )}
    </tr>
  );
};

export default Edit;
