import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import Input from '../../../componentes/input';
import { InputTranslate } from 'src/componentes/Form';
import InputNumber from '../../../componentes/input-number';
import ItemOrganizacaoLookup from '../../../lookups/item-organizacao';
import ColaboradorSimplificadoLookup from '../../../lookups/ColaboradorSimplificadoLookup';
import ColaboradorLookup from 'src/lookups/colaborador';
import PeriodicidadeLookup from '../../../lookups/periodicidade';
import UnidadeMedidaLookup from '../../../lookups/unidade-medida';
import Panel from '../../../componentes/panel';
import StatusItemLookup from '../../../lookups/status-item';
import DesenharCampoCustomizado from '../../item/campo-customizado/componentes/desenhar';
import TabelaValorLookup from '../../../lookups/tabela-valor';
import CheckBox from '../../../componentes/checkbox';
import Preview from '../../faixa-farol/preview';
import PreviewTabelaValor from '../../tabela-valor/preview';
import Radio from '../../../componentes/radio';
import FaixaFarolLookup from '../../../lookups/faixa-farol';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';
import DataInicioTermino from '../../../paginas/formula/componentes/inicio-termino';
import ItemCentroCustoLookup from '../../../lookups/item-centro-custo';
import PacoteLookup from '../../../lookups/pacote';
import PontoFaixaResultadoLookup from '../../../lookups/ponto-faixa-resultado';
import ContentManager from '../../../componentes/content-manager';
import Confirm from '../../../componentes/message-box/confirm';
import ItemLookup from '../../../lookups/item';
import DesdobramentoLookup from '../../../lookups/desdobramento';
import TipoConsolidacaoMetaLookup from '../../../lookups/tipo-consolidacao';
import TipoConsolidacaoRealizadoLookup from '../../../lookups/tipo-consolidacao';
import TipoConsolidacaoMetaAcumuladoLookup from '../../../lookups/tipo-consolidacao-acumulado';
import TipoConsolidacaoRealizadoAcumuladoLookup from '../../../lookups/tipo-consolidacao-acumulado';
import DatePicker from '../../../componentes/date-picker';
import InputGroup from '../../../componentes/input-number-group';
import MdiIcon from '../../../componentes/mdi-icon';
import Info from '../../../componentes/info';
import PatrocinadorLookup from '../../../lookups/colaborador';
import http from 'src/services/httpService';
import LoadingContainer from '../../../componentes/loading-container';
import TemaEstrategicoLookup from '../../../lookups/tema-estrategico';
import ClassificacaoLookup from '../../../lookups/classificacao';
import Classificacao2Lookup from '../../../lookups/classificacao';
import PaisUf from '../../item/pais-uf';
import PerspectivaLookup from '../../../lookups/perspectiva';
import Alert from '../../../componentes/message-box/alert';
import TagLookup from 'src/lookups/TagLookup';
import {
  TIPO_ITENS,
  MODULOS,
  TIPO_CONSOLIDACAO,
  TIPO_CONSOLIDACAO_ACUMULADO,
  ADDONS,
  STATUS_ITEM,
} from 'src/utils/constants';

import FluxoAprovacaoGenericoLookup from 'src/lookups/FluxoAprovacaoGenericoLookup';

@autobind
class IndicadorResumoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      camposBloqueados: null,
      modeloItem: this.props.modeloItem,
    };
  }

  handleUnidadeMedidaChange(value) {
    this.props.model.getModel('unidadeMedida').requestChange(value);

    if (value && value.casasDecimais !== null) {
      this.handleCasasDecimaisChange(value.casasDecimais);
    }
  }

  handleCasasDecimaisChange(value) {
    if (value > 15) {
      ContentManager.addContent(
        <Alert
          message={<FormattedMessage id="limiteCasasDecimais" />}
          handleClose={this.props.model
            .getModel('casasDecimais')
            .requestChange(15)}
        />
      );
    } else this.props.model.getModel('casasDecimais').requestChange(value);
  }

  handleAreaChange(value) {
    this.props.model.getModel('area').requestChange(value);

    let usarCodigoArea = this.props.model.getModel(
      'utilizarCodigoAreaParaMascara'
    ).value;
    if (usarCodigoArea) {
      let novoCodigoItem =
        value.idIntegracao + this.props.model.getModel('idIntegracao').value;
      this.props.model.getModel('idIntegracao').requestChange(novoCodigoItem);
    }
  }

  handlePeriodicidade(novo) {
    let isNew = this.props.model.getModel('id').value === 0 ? true : false;

    if (isNew) {
      this.handleChangePeriodicidade(novo);
    } else {
      let antigo = this.props.model.getModel('periodicidade').value;
      let ehDiferente = antigo !== novo;
      if (ehDiferente) {
        ContentManager.addContent(
          <Confirm
            message={this.props.intl.formatMessage({
              id: 'label.alterarPeriodicidadeExcluiraValoresVinculados',
            })}
            handleConfirm={this.handleChangePeriodicidade.bind(this, novo)}
          />
        );
      }
    }
  }

  handleChangePeriodicidade(value) {
    this.props.model.getModel('periodicidade').requestChange(value);
  }

  handleChangeDataTermino() {
    const model = this.props.model;

    const currentStartDate = model.getModel('dataInicioItem').value;
    const currentEndDate = model.getModel('dataTerminoItem').value;
    const currentDate = new Date();

    currentDate.setSeconds(0, 0);

    if (currentEndDate && currentEndDate < currentDate) return;
    else if (currentStartDate > currentDate)
      model.getModel('dataTerminoItem').requestChange(currentStartDate);
    else if (!currentEndDate || currentEndDate > currentDate) {
      model.getModel('dataTerminoItem').requestChange(currentDate);
    }
  }

  changeStatus(value) {
    const model = this.props.model;
    const statusId = value.id;

    model.getModel('status').requestChange(value);

    if (statusId == STATUS_ITEM.CONCLUIDO || statusId == STATUS_ITEM.CANCELADO)
      this.handleChangeDataTermino();
  }

  handleChangeStatus(value) {
    if (value.id === 5) {
      ContentManager.addContent(
        <Confirm
          message={this.props.intl.formatMessage(
            { id: 'label.mensagemStatusItem' },
            { acoes: this.props.resources.acoes }
          )}
          handleConfirm={this.changeStatus.bind(this, value)}
        />
      );
    } else {
      this.changeStatus(value);
    }
  }

  handleItemVinculado(novo) {
    let model = this.props.model.getModel();

    let isNew = model.getModel('id').value === 0 ? true : false;

    if (isNew) {
      this.handleChangeItemVinculado(novo);
    } else {
      let antigo = model.getModel('itemVinculado').value;
      let ehDiferente = antigo !== novo;

      if (ehDiferente) {
        ContentManager.addContent(
          <Confirm
            message={this.props.intl.formatMessage({
              id: 'label.vincularItemIraApagarValores',
            })}
            handleConfirm={this.handleChangeItemVinculado.bind(this, novo)}
            handleCancel={this.handleCancelItemVinculado(this)}
          />
        );
      }
    }
  }

  handleCancelItemVinculado() {
    this.props.model.getModel('itemVinculado').requestChange(null);

    this.getAlteracao();
  }

  handleChangeItemVinculado(value) {
    this.props.model.getModel('itemVinculado').requestChange(value);
  }

  handleChangeFaixa(value) {
    this.props.model.getModel('faixa').requestChange(value);

    if (value != null) this.handleOpen(value);
  }

  async handleOpen(faixaFarol) {
    let model = this.props.model.getModel();

    if (faixaFarol != null) {
      var response = await http.post(`/FaixaFarol/ObterFaixaFarol`, {
        faixaId: faixaFarol.id,
      });

      let melhor = model.getModel('melhor').value;
      let faixa = response.data;

      if (melhor != null && faixa != null) {
        model
          .getModel('faixa')
          .getModel('faixaMaior')
          .requestChange(faixa.faixaMaior);
        model
          .getModel('faixa')
          .getModel('faixaMenor')
          .requestChange(faixa.faixaMenor);
        model
          .getModel('faixa')
          .getModel('faixaIgual')
          .requestChange(faixa.faixaIgual);
      }
    }
  }

  //busca dados model origem, seta lookup do template
  async handleItemTemplate(value) {
    let itemId = value.id;
    this.setState({ isLoading: true });

    await http
      .post(`Item/CopiarDadosItemTemplate`, {
        itemTemplateId: itemId,
      })
      .then((response) => {
        this.handleCopiaDadosTemplate(response.data);
        this.setState({ isLoading: false });
      });
  }

  //copia dados do model origem para o destino
  handleCopiaDadosTemplate(templateModel) {
    this.props.handleSetFormValues &&
      this.props.handleSetFormValues(templateModel);
  }

  async componentDidMount() {
    let { modeloItem } = this.state;
    let { tipoItem } = this.props;

    //indicador criado por template
    if (modeloItem && modeloItem.id == 3) {
      await http
        .post(`Item/ObterCamposBloqueados`, { tipoItemId: tipoItem })
        .then((response) => {
          this.setState({ camposBloqueados: response.data });
        });
    }
  }

  ehCampoBloqueado(nomeCampo) {
    let { camposBloqueados } = this.state;

    if (camposBloqueados != null) {
      let campoBloqueado = camposBloqueados.find(
        (campo) => campo.campo === nomeCampo
      );
      return campoBloqueado;
    } else return false;
  }

  handleTiposIdsSelecionados() {
    let { tipoItem } = this.props;
    let modeloItemId = this.state.modeloItem && this.state.modeloItem.id;

    if (tipoItem == 1 && modeloItemId == 3) {
      return [12];
    } else if (tipoItem == 2 && modeloItemId == 3) {
      return [13];
    } else if (tipoItem == 3 && modeloItemId == 3) {
      return [14];
    }
  }

  handleChangeInputTranslate(field, value) {
    let model = this.props.model;
    model.getModel(field).requestChange(value);
  }

  handleChangeEditTranslate(
    fieldDefault,
    fieldPT,
    fieldEN,
    fieldES,
    fieldIT,
    fieldNL,
    fieldFR,
    fieldDE,
    fieldTR,
    fieldAR,
    localModel
  ) {
    let model = this.props.model;
    model.getModel(fieldDefault).requestChange(localModel[fieldDefault]);
    model.getModel(fieldPT).requestChange(localModel[fieldPT]);
    model.getModel(fieldEN).requestChange(localModel[fieldEN]);
    model.getModel(fieldES).requestChange(localModel[fieldES]);
    model.getModel(fieldIT).requestChange(localModel[fieldIT]);
    model.getModel(fieldNL).requestChange(localModel[fieldNL]);
    model.getModel(fieldFR).requestChange(localModel[fieldFR]);
    model.getModel(fieldDE).requestChange(localModel[fieldDE]);
    model.getModel(fieldTR).requestChange(localModel[fieldTR]);
    model.getModel(fieldAR).requestChange(localModel[fieldAR]);
  }

  render() {
    let {
      model,
      resources,
      intl,
      modulosComprados,
      tipoItem,
      configuracaoSistema,
      moduloAtual,
      addons,
    } = this.props;
    const possuiModuloMO = modulosComprados.find((m) => m.id === 2 && m.ativo);
    const possuiModuloRV = modulosComprados.find((m) => m.id === 3 && m.ativo);
    //let modeloItemId = model.getModel('modeloItem').value && model.getModel('modeloItem').value.id;
    const modeloItemId = this.state.modeloItem && this.state.modeloItem.id;
    const ehTemplateItem = modeloItemId == 3;
    const ehitemDeTemplate =
      ehTemplateItem &&
      (tipoItem == 1 ||
        tipoItem == 2 ||
        tipoItem == 3 ||
        tipoItem == TIPO_ITENS.PROJETO);
    const templateVazio =
      ehTemplateItem && model.getModel('itemTemplate').value == null;

    const isProjetoAprovacao =
      tipoItem == TIPO_ITENS.PROJETO &&
      model &&
      model.getModel('aprovarCadastro') &&
      model.getModel('aprovarCadastro').value;

    const hasEPModule = modulosComprados.find(
      (m) => m.id === MODULOS.ESCRITORIO_PROJETOS && m.ativo
    );

    const addonValorPowerBIActive =
      addons && addons.find((m) => m.id === ADDONS.VALOR_POWERBI)?.ativo;

    const idsIgnorados = [
      ...(model.getModel('idsConsolidacaoIgnorar').value || []),
    ];

    if (!addonValorPowerBIActive) {
      idsIgnorados.push(TIPO_CONSOLIDACAO.VALOR_POWERBI);
    }

    const opcoesMelhor =
      this.props.tipoItem === 14
        ? model.value.opcoesMelhor
          ? model.value.opcoesMelhor.filter((opcao) => opcao.id === 1)
          : []
        : model.value.opcoesMelhor;

    const tiposPeriodicidadeIgnorar =
      tipoItem == TIPO_ITENS.PROJETO ? [1, 2, 3, 5, 7, 8, 9] : null;

    return (
      <LoadingContainer isLoading={this.state.isLoading}>
        <div>
          <Panel header={<FormattedMessage id="label.informacoesBasicas" />}>
            {tipoItem != TIPO_ITENS.PROJETO &&
              tipoItem != 10 &&
              tipoItem != 11 && (
                <div>
                  {modeloItemId == 2 &&
                    configuracaoSistema.utilizarFuncionalidadeDesdobramento && (
                      <Row>
                        <Col md={6}>
                          <DesdobramentoLookup
                            data={{ checarStatusInativo: true }}
                            model={model.getModel('templateDesdobramento')}
                            required
                          />
                        </Col>
                      </Row>
                    )}
                </div>
              )}
            {ehTemplateItem &&
              tipoItem != TIPO_ITENS.PROJETO &&
              tipoItem != 10 &&
              tipoItem != 11 && (
                <div>
                  <Row>
                    <Col md={6}>
                      <ItemLookup
                        autoFocus={ehTemplateItem}
                        required
                        model={{
                          label: (
                            <span>
                              {capitalizeFirstLetter(
                                intl.formatMessage({ id: 'templateItem' })
                              )}
                              <Info
                                id="templateInfoAdicional"
                                placement="right"
                                text={intl.formatMessage({
                                  id: 'templateInfoAdicional',
                                })}
                              />
                            </span>
                          ),
                          value: model.getModel('itemTemplate').value,
                          requestChange: this.handleItemTemplate.bind(this),
                          errors: model.getModel('itemTemplate').errors,
                        }}
                        data={{
                          tiposIdsSelecionados:
                            this.handleTiposIdsSelecionados(),
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              )}

            {tipoItem == TIPO_ITENS.PROJETO &&
              moduloAtual &&
              moduloAtual.id == MODULOS.ESCRITORIO_PROJETOS &&
              model.getModel('configuracaoGeral').value &&
              model.getModel('configuracaoGeral').value
                .utilizarFluxoAprovacaoProjetos && (
                <Row>
                  <Col md={12}>
                    <CheckBox
                      className="my-3"
                      model={{
                        label: intl.formatMessage(
                          { id: 'aprovarCadastroAcoes' },
                          {
                            acao: resources.projetos,
                            acoes: resources.projetos,
                          }
                        ),
                        value: model.getModel('aprovarCadastro').value,
                        requestChange:
                          model.getModel('aprovarCadastro').requestChange,
                      }}
                    />
                  </Col>
                </Row>
              )}
            <Row>
              {tipoItem != 10 && (
                <Col md={6}>
                  <InputTranslate
                    id={model.getModel('id') && model.getModel('id').value}
                    maxLength={300}
                    fieldDefault={'nome'}
                    errorsFieldDefault={model.getModel('nome').errors}
                    valueDefault={model.getModel('nome').value}
                    labelDefault={model.getModel('nome').label}
                    fieldPT={'nomePT'}
                    valuePT={model.getModel('nomePT').value}
                    fieldEN={'nomeEN'}
                    valueEN={model.getModel('nomeEN').value}
                    fieldES={'nomeES'}
                    valueES={model.getModel('nomeES').value}
                    fieldIT={'nomeIT'}
                    valueIT={model.getModel('nomeIT').value}
                    fieldNL={'nomeNL'}
                    valueNL={model.getModel('nomeNL').value}
                    fieldFR={'nomeFR'}
                    valueFR={model.getModel('nomeFR').value}
                    fieldDE={'nomeDE'}
                    valueDE={model.getModel('nomeDE').value}
                    fieldTR={'nomeTR'}
                    valueTR={model.getModel('nomeTR').value}
                    fieldAR={'nomeAR'}
                    valueAR={model.getModel('nomeAR').value}
                    required={tipoItem != 10}
                    disabled={templateVazio || this.ehCampoBloqueado('nome')}
                    autoFocus={!ehTemplateItem}
                    handleChangeInputTranslate={this.handleChangeInputTranslate}
                    handleChangeEditTranslate={this.handleChangeEditTranslate}
                  />
                </Col>
              )}

              <Col md={3}>
                <Input
                  disabled={
                    model.getModel('utilizarCodigoAutomatico').value === true ||
                    templateVazio ||
                    ehitemDeTemplate
                  }
                  model={model.getModel('idIntegracao')}
                  required={
                    !model.getModel('utilizarCodigoAutomatico').value &&
                    !ehitemDeTemplate
                  }
                />
              </Col>
              {tipoItem != 10 && (
                <Col md={3}>
                  <ItemOrganizacaoLookup
                    required={tipoItem != 10}
                    data={{ tiposIdSelecionaveis: [1, 2, 3] }}
                    model={{
                      label: capitalizeFirstLetter(resources.area),
                      value: model.getModel('area').value,
                      requestChange: this.handleAreaChange,
                      errors: model.getModel('area').errors,
                    }}
                    disabled={templateVazio || this.ehCampoBloqueado('area')}
                  />
                </Col>
              )}
            </Row>
            <Row>
              {
                <Col md={6}>
                  {tipoItem == 10 ? (
                    <ColaboradorSimplificadoLookup
                      required
                      model={{
                        label: capitalizeFirstLetter(resources.responsavel),
                        value: model.getModel('colaboradorRemuneracao').value,
                        requestChange: model.getModel('colaboradorRemuneracao')
                          .requestChange,
                        errors: model.getModel('colaboradorRemuneracao').errors,
                      }}
                      disabled={templateVazio}
                    />
                  ) : (
                    <ColaboradorSimplificadoLookup
                      multi
                      required
                      model={{
                        label:
                          tipoItem == TIPO_ITENS.PROJETO
                            ? capitalizeFirstLetter(
                                resources.responsaveisprojeto
                              )
                            : capitalizeFirstLetter(resources.responsaveis),
                        value: model.getModel('responsaveis').value,
                        requestChange:
                          model.getModel('responsaveis').requestChange,
                        errors: model.getModel('responsaveis').errors,
                      }}
                      disabled={templateVazio}
                    />
                  )}
                </Col>
              }
              {!isProjetoAprovacao && (
                <Col md={3}>
                  <StatusItemLookup
                    required
                    model={{
                      label: model.getModel('status').label,
                      value: model.getModel('status').value,
                      requestChange: this.handleChangeStatus,
                    }}
                    disabled={templateVazio}
                    data={{ idsSelecionados: [1, 2, 3, 4, 5] }}
                  />
                </Col>
              )}
              {hasEPModule && isProjetoAprovacao && (
                <Col md={6}>
                  <FluxoAprovacaoGenericoLookup
                    required
                    model={{
                      label: intl.formatMessage({ id: 'fluxoAprovacao' }),
                      value: model.getModel('fluxoAprovacao').value,
                      requestChange:
                        model.getModel('fluxoAprovacao').requestChange,
                      errors: model.getModel('fluxoAprovacao').errors,
                    }}
                    disabled={templateVazio}
                  />
                </Col>
              )}
              {(tipoItem != TIPO_ITENS.PROJETO ||
                (model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .permitirDigitacaoValores)) && (
                <Col md={3}>
                  <PeriodicidadeLookup
                    required
                    model={{
                      label: model.getModel('periodicidade').label,
                      value: model.getModel('periodicidade').value,
                      requestChange: this.handlePeriodicidade,
                      errors: model.getModel('periodicidade').errors,
                    }}
                    disabled={
                      templateVazio || this.ehCampoBloqueado('periodicidade')
                    }
                    data={{ tiposIdsIgnorar: tiposPeriodicidadeIgnorar }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col md={6}>
                <DataInicioTermino
                  required
                  autoFormatDate={true}
                  showClear={false}
                  mesAno={tipoItem == TIPO_ITENS.PROJETO ? false : true}
                  dataInicio={model.getModel('dataInicioItem')}
                  dataTermino={model.getModel('dataTerminoItem')}
                  disabled={templateVazio}
                />
              </Col>
              {tipoItem != TIPO_ITENS.PROJETO && (
                <Col md={3}>
                  <UnidadeMedidaLookup
                    required
                    model={{
                      label: model.getModel('unidadeMedida').label,
                      value: model.getModel('unidadeMedida').value,
                      requestChange: this.handleUnidadeMedidaChange,
                      errors: model.getModel('unidadeMedida').errors,
                    }}
                    disabled={
                      templateVazio || this.ehCampoBloqueado('unidadeMedida')
                    }
                  />
                </Col>
              )}
              {tipoItem != TIPO_ITENS.PROJETO && (
                <Col md={2}>
                  <InputNumber
                    required
                    model={{
                      label: model.getModel('casasDecimais').label,
                      value: model.getModel('casasDecimais').value,
                      requestChange: this.handleCasasDecimaisChange,
                      errors: model.getModel('casasDecimais').errors,
                    }}
                    disabled={
                      templateVazio || this.ehCampoBloqueado('casasDecimais')
                    }
                  />
                </Col>
              )}
              {model.getModel('configuracaoGeral').value &&
                model.getModel('configuracaoGeral').value
                  .utilizarPatrocinador &&
                tipoItem == TIPO_ITENS.PROJETO && (
                  <Col md={4}>
                    <PatrocinadorLookup
                      onBlur={this.handleSave}
                      multi
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage(
                            { id: 'label.patrocinadores' },
                            { patrocinadores: resources.patrocinadores }
                          )
                        ),
                        value: model.getModel('patrocinadores').value,
                        requestChange:
                          model.getModel('patrocinadores').requestChange,
                      }}
                      disabled={templateVazio}
                    />
                  </Col>
                )}
            </Row>
            {tipoItem == TIPO_ITENS.PROJETO && (
              <div>
                {tipoItem == TIPO_ITENS.PROJETO && !isProjetoAprovacao && (
                  <Row>
                    {model.getModel('configuracaoGeral').value &&
                      model.getModel('configuracaoGeral').value
                        .utilizarPrazoAcoes && (
                        <Col md={2}>
                          <DatePicker
                            disabled={true}
                            model={{
                              label: intl.formatMessage(
                                { id: 'label.dataInicioAcao' },
                                { acao: resources.acao }
                              ),
                              value: model.getModel('dataInicioAcao').value,
                              requestChange:
                                model.getModel('dataInicioAcao').requestChange,
                            }}
                          />
                        </Col>
                      )}
                    {model.getModel('configuracaoGeral').value &&
                      model.getModel('configuracaoGeral').value
                        .utilizarPrazoAcoes && (
                        <Col md={2}>
                          <DatePicker
                            disabled={true}
                            model={{
                              label: intl.formatMessage(
                                { id: 'label.dataTerminoAcao' },
                                { acao: resources.acao }
                              ),
                              value: model.getModel('dataTerminoAcao').value,
                              requestChange:
                                model.getModel('dataTerminoAcao').requestChange,
                            }}
                          />
                        </Col>
                      )}
                    {model.getModel('configuracaoGeral').value &&
                      model.getModel('configuracaoGeral').value
                        .utilizarPrazoAcoes && (
                        <Col md={2}>
                          <DatePicker
                            disabled={true}
                            model={{
                              label: capitalizeFirstLetter(
                                intl.formatMessage(
                                  { id: 'label.dataInicioRealizadoAcao' },
                                  { acao: resources.acao }
                                )
                              ),
                              value: model.getModel('dataInicioRealizadoAcao')
                                .value,
                              requestChange: model.getModel(
                                'dataInicioRealizadoAcao'
                              ).requestChange,
                            }}
                          />
                        </Col>
                      )}
                    {model.getModel('configuracaoGeral').value &&
                      model.getModel('configuracaoGeral').value
                        .utilizarPrazoAcoes && (
                        <Col md={2}>
                          <DatePicker
                            disabled={true}
                            model={{
                              label: intl.formatMessage(
                                { id: 'label.dataTerminoRealizadoAcao' },
                                { acao: resources.acao }
                              ),
                              value: model.getModel('dataTerminoRealizadoAcao')
                                .value,
                              requestChange: model.getModel(
                                'dataTerminoRealizadoAcao'
                              ).requestChange,
                            }}
                          />
                        </Col>
                      )}
                    {model.getModel('configuracaoGeral').value &&
                      model.getModel('configuracaoGeral').value
                        .utilizarPercentualExecucao && (
                        <Col md={2}>
                          <InputNumber
                            disabled={true}
                            model={{
                              label: resources.percprevisto,
                              value: model.getModel('percentualPrevisto').value,
                              requestChange:
                                model.getModel('percentualPrevisto')
                                  .requestChange,
                            }}
                          />
                        </Col>
                      )}
                    {model.getModel('configuracaoGeral').value &&
                      model.getModel('configuracaoGeral').value
                        .utilizarPercentualExecucao && (
                        <Col md={2}>
                          <InputNumber
                            disabled={true}
                            model={{
                              label: resources.percrealizado,
                              value: model.getModel('percentualExecutado')
                                .value,
                              requestChange: model.getModel(
                                'percentualExecutado'
                              ).requestChange,
                            }}
                          />
                        </Col>
                      )}
                  </Row>
                )}
                <Row>
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarInvestimento && (
                      <Col md={2}>
                        <InputNumber
                          model={{
                            label: intl.formatMessage(
                              { id: 'investimentoPrevisto' },
                              { investimento: resources.investimento }
                            ),
                            value: model.getModel('investimentoPrevisto').value,
                            requestChange: model.getModel(
                              'investimentoPrevisto'
                            ).requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarInvestimento &&
                    model.getModel('configuracaoGeral').value
                      .utilizarValorRetificado && (
                      <Col md={3}>
                        <InputNumber
                          model={{
                            label: intl.formatMessage(
                              { id: 'investimentoPrevistoRetificado' },
                              { investimento: resources.investimento }
                            ),
                            value: model.getModel(
                              'investimentoPrevistoRetificado'
                            ).value,
                            requestChange: model.getModel(
                              'investimentoPrevistoRetificado'
                            ).requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarInvestimento && (
                      <Col md={2}>
                        <InputGroup
                          addon={
                            model.getModel('farolInvestimento').value ? (
                              <MdiIcon
                                icon={
                                  model.getModel('farolInvestimento').value &&
                                  model.getModel('farolInvestimento').value
                                    .icone
                                }
                                color={
                                  model.getModel('farolInvestimento').value &&
                                  model.getModel('farolInvestimento').value.cor
                                }
                                colorHover={
                                  model.getModel('farolInvestimento').value &&
                                  model.getModel('farolInvestimento').value.cor
                                }
                              ></MdiIcon>
                            ) : (
                              ''
                            )
                          }
                          model={{
                            label: intl.formatMessage(
                              { id: 'investimentoRealizado' },
                              { investimento: resources.investimento }
                            ),
                            value: model.getModel('investimentoRealizado')
                              .value,
                            requestChange: model.getModel(
                              'investimentoRealizado'
                            ).requestChange,
                          }}
                          disabled={templateVazio}
                        ></InputGroup>
                      </Col>
                    )}
                </Row>
                <Row>
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarCusteio && (
                      <Col md={2}>
                        <InputNumber
                          model={{
                            label: intl.formatMessage(
                              { id: 'custeioPrevisto' },
                              { custeio: resources.custeio }
                            ),
                            value: model.getModel('custeioPrevisto').value,
                            requestChange:
                              model.getModel('custeioPrevisto').requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value.utilizarCusteio &&
                    model.getModel('configuracaoGeral').value
                      .utilizarValorRetificado && (
                      <Col md={3}>
                        <InputNumber
                          model={{
                            label: intl.formatMessage(
                              { id: 'custeioPrevistoRetificado' },
                              { custeio: resources.custeio }
                            ),
                            value: model.getModel('custeioPrevistoRetificado')
                              .value,
                            requestChange: model.getModel(
                              'custeioPrevistoRetificado'
                            ).requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarCusteio && (
                      <Col md={2}>
                        <InputNumber
                          model={{
                            label: intl.formatMessage(
                              { id: 'custeioRealizado' },
                              { custeio: resources.custeio }
                            ),
                            value: model.getModel('custeioRealizado').value,
                            requestChange:
                              model.getModel('custeioRealizado').requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                </Row>
                <Row>
                  <Col md={12}>
                    <Input
                      rows={3}
                      maxLength={300}
                      type="textarea"
                      model={{
                        label: model.getModel('escopo').label,
                        value: model.getModel('escopo').value,
                        requestChange: model.getModel('escopo').requestChange,
                      }}
                      disabled={templateVazio}
                    />
                  </Col>
                </Row>
              </div>
            )}
            <Row>
              {model.getModel('configuracaoGeral').value &&
                model.getModel('configuracaoGeral').value
                  .utilizarDigitadorValor &&
                tipoItem != 13 &&
                tipoItem != 14 &&
                tipoItem != 15 && (
                  <Col md={6}>
                    <ColaboradorSimplificadoLookup
                      multi
                      model={{
                        label: capitalizeFirstLetter(resources.digitadores),
                        value: model.getModel('digitadores').value,
                        requestChange:
                          model.getModel('digitadores').requestChange,
                      }}
                      disabled={
                        templateVazio || this.ehCampoBloqueado('digitadores')
                      }
                    />
                  </Col>
                )}
              {model.getModel('configuracaoGeral').value &&
                model.getModel('configuracaoGeral').value.utilizarEquipe && (
                  <Col md={6}>
                    <ColaboradorSimplificadoLookup
                      multi
                      model={{
                        label: intl.formatMessage({ id: 'equipe' }),
                        value: model.getModel('equipe').value,
                        requestChange: model.getModel('equipe').requestChange,
                        errors: model.getModel('equipe').errors,
                      }}
                    />
                  </Col>
                )}

              {model.getModel('configuracaoGeral').value &&
                model.getModel('configuracaoGeral').value
                  .utilizarAprovadoresdeValoresRealizados &&
                tipoItem != 13 &&
                tipoItem != 14 &&
                tipoItem != 15 && (
                  <Col md={3}>
                    <ColaboradorLookup
                      multi
                      model={model.getModel('aprovadoresValores')}
                      disabled={
                        templateVazio ||
                        this.ehCampoBloqueado('aprovadoresValores')
                      }
                    />
                  </Col>
                )}
              {model.getModel('habilitarEnvioAutomaticoRA').value &&
                (tipoItem == 1 || tipoItem == 3) && (
                  <Col md={6}>
                    <ColaboradorSimplificadoLookup
                      onBlur={this.handleSave}
                      multi
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage(
                            { id: 'colaboradoresEmailRA' },
                            {
                              colaboradores: resources.colaboradores,
                              relatoriodeacompanhamento:
                                resources.relatoriodeacompanhamento,
                            }
                          )
                        ),
                        value: model.getModel('colaboradoresEmailRA').value,
                        requestChange: model.getModel('colaboradoresEmailRA')
                          .requestChange,
                      }}
                      disabled={
                        templateVazio ||
                        this.ehCampoBloqueado('colaboradoresEmailRA')
                      }
                    />
                  </Col>
                )}
              {(tipoItem == 1 ||
                tipoItem == 2 ||
                tipoItem == 3 ||
                tipoItem == 4) && (
                <Col md={6}>
                  <TagLookup
                    multi
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'tags' },
                          { tags: resources.tags }
                        )
                      ),
                      value: model.getModel('tags').value,
                      requestChange: model.getModel('tags').requestChange,
                    }}
                    disabled={templateVazio || this.ehCampoBloqueado('tags')}
                    data={{ funcionalidadesIdSelecionaveis: [tipoItem] }}
                    podeEditar={model.getModel('podeCadastrarTag').value}
                  />
                </Col>
              )}
            </Row>
            <Row>
              {tipoItem != TIPO_ITENS.PROJETO && (
                <Col md={12}>
                  <Input
                    maxLength={1000}
                    rows={4}
                    type="textarea"
                    model={model.getModel('descricao')}
                    disabled={templateVazio}
                  />
                </Col>
              )}
            </Row>
            {
              <Row>
                {model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarCampoAuxiliarTexto1 &&
                  (tipoItem == 1 || tipoItem == 2 || tipoItem == 3) && (
                    <Col md={12}>
                      <Input
                        maxLength={1000}
                        rows={5}
                        model={{
                          label: capitalizeFirstLetter(
                            resources.campoauxiliartexto1
                          ),
                          value: model.getModel('campoAuxiliarTexto1').value,
                          requestChange: model.getModel('campoAuxiliarTexto1')
                            .requestChange,
                        }}
                      />
                    </Col>
                  )}
                {model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarCampoAuxiliarTexto2 &&
                  (tipoItem == 1 || tipoItem == 2 || tipoItem == 3) && (
                    <Col md={12}>
                      <Input
                        maxLength={1000}
                        rows={5}
                        model={{
                          label: capitalizeFirstLetter(
                            resources.campoauxiliartexto2
                          ),
                          value: model.getModel('campoAuxiliarTexto2').value,
                          requestChange: model.getModel('campoAuxiliarTexto2')
                            .requestChange,
                        }}
                      />
                    </Col>
                  )}
                {model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarCampoAuxiliarTexto3 &&
                  (tipoItem == 1 || tipoItem == 2 || tipoItem == 3) && (
                    <Col md={12}>
                      <Input
                        maxLength={1000}
                        rows={5}
                        model={{
                          label: capitalizeFirstLetter(
                            resources.campoauxiliartexto3
                          ),
                          value: model.getModel('campoAuxiliarTexto3').value,
                          requestChange: model.getModel('campoAuxiliarTexto3')
                            .requestChange,
                        }}
                      />
                    </Col>
                  )}
              </Row>
            }
            {model.getModel('camposCustomizados').value &&
              model.getModel('camposCustomizados').value.length > 0 && (
                <DesenharCampoCustomizado
                  model={model.getModel('camposCustomizados')}
                  disabled={templateVazio}
                />
              )}
          </Panel>
          {possuiModuloMO && modeloItemId == 1 && tipoItem == 1 && (
            <Panel
              header={
                <FormattedMessage id="label.integracaoMatrizOrcamentaria" />
              }
            >
              <Row>
                <Col md={4}>
                  <div id="vincularComMO">
                    <UncontrolledTooltip
                      placement="bottom"
                      target="vincularComMO"
                    >
                      <FormattedMessage id="label.vincularItemComMOAlert" />
                    </UncontrolledTooltip>
                    <CheckBox
                      model={{
                        label: intl.formatMessage(
                          {
                            id: 'label.vincularIndicadorComMatrizOrcamentaria',
                          },
                          {
                            centrocusto: resources.centrocusto,
                            indicador: resources.indicador,
                          }
                        ),
                        value: model.getModel('vincularComMO').value,
                        requestChange:
                          model.getModel('vincularComMO').requestChange,
                      }}
                      disabled={
                        model.getModel('bloquearIntegracaoMO').value ||
                        templateVazio
                      }
                    />
                  </div>
                </Col>
                {model.getModel('vincularComMO').value && (
                  <Col md={4}>
                    <ItemCentroCustoLookup
                      required
                      model={{
                        label: (
                          <FormattedMessage
                            id="label.centroCusto"
                            values={{ centrocusto: resources.centrocusto }}
                          ></FormattedMessage>
                        ),
                        value: model.getModel('centroCusto').value,
                        requestChange:
                          model.getModel('centroCusto').requestChange,
                        errors: model.getModel('centroCusto').errors,
                      }}
                      disabled={templateVazio}
                    />
                  </Col>
                )}
                {model.getModel('vincularComMO').value && (
                  <Col md={4}>
                    <PacoteLookup
                      model={{
                        label: (
                          <FormattedMessage id="label.pacote"></FormattedMessage>
                        ),
                        value: model.getModel('pacote').value,
                        requestChange: model.getModel('pacote').requestChange,
                        errors: model.getModel('pacote').errors,
                      }}
                      required
                      disabled={templateVazio}
                    />
                  </Col>
                )}
              </Row>
            </Panel>
          )}
          {((tipoItem != TIPO_ITENS.PROJETO &&
            tipoItem != 17 &&
            tipoItem != 2) ||
            (tipoItem == 2 &&
              model.getModel('configuracaoGeral').value &&
              model.getModel('configuracaoGeral').value
                .permitirUsoValoresTabelados)) && (
            <Panel header={<FormattedMessage id="label.referencia" />}>
              <Row>
                <Col md={6}>
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .permitirUsoValoresTabelados && (
                      <CheckBox
                        model={{
                          label: intl.formatMessage({
                            id: 'usarListaRegistros',
                          }),
                          value: model.getModel('usarTabelaValores').value,
                          requestChange:
                            model.getModel('usarTabelaValores').requestChange,
                        }}
                        disabled={templateVazio}
                      />
                    )}
                </Col>
              </Row>
              {tipoItem != TIPO_ITENS.PROJETO && (
                <Row>
                  <Col md={12}>
                    {model.getModel('usarTabelaValores').value ? (
                      <div>
                        <Row>
                          <Col md={3}>
                            <TabelaValorLookup
                              model={{
                                label: intl.formatMessage({
                                  id: 'listaRegistros',
                                }),
                                value: model.getModel('tabelaValor').value,
                                requestChange:
                                  model.getModel('tabelaValor').requestChange,
                              }}
                              disabled={templateVazio}
                            />
                          </Col>
                          <Col md={9}>
                            {model.getModel('tabelaValor').value && (
                              <PreviewTabelaValor
                                exibirFarol={tipoItem != 2}
                                model={model
                                  .getModel('tabelaValor')
                                  .getModel('itens')}
                                disabled={templateVazio}
                              ></PreviewTabelaValor>
                            )}
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Row>
                          <Col md={3}>
                            {tipoItem != 2 && tipoItem != 13 && (
                              <FaixaFarolLookup
                                clearable={false}
                                model={{
                                  label: capitalizeFirstLetter(resources.faixa),
                                  value: model.getModel('faixa').value,
                                  requestChange: this.handleChangeFaixa,
                                }}
                                disabled={
                                  templateVazio ||
                                  this.ehCampoBloqueado('faixa')
                                }
                              />
                            )}
                          </Col>
                          <Col md={3}>
                            {tipoItem != 2 && tipoItem != 13 && (
                              <Radio
                                model={{
                                  label: capitalizeFirstLetter(
                                    resources.polaridade
                                  ),
                                  value: model.getModel('melhor').value,
                                  requestChange:
                                    model.getModel('melhor').requestChange,
                                }}
                                options={opcoesMelhor}
                                labelFunction={this.handleLabelMelhor}
                                labelField="descricao"
                                idField="id"
                                disabled={
                                  templateVazio ||
                                  this.ehCampoBloqueado('melhor')
                                }
                              />
                            )}
                          </Col>
                          <Col md={6}>
                            {tipoItem != 2 &&
                              tipoItem != 13 &&
                              model.getModel('faixa').value &&
                              model.getModel('melhor').value &&
                              (model.getModel('melhor').value.id == 1 ? (
                                <Preview
                                  model={model
                                    .getModel('faixa')
                                    .getModel('faixaMaior')}
                                ></Preview>
                              ) : model.getModel('melhor').value.id == 2 ? (
                                <Preview
                                  model={model
                                    .getModel('faixa')
                                    .getModel('faixaMenor')}
                                ></Preview>
                              ) : (
                                <Preview
                                  model={model
                                    .getModel('faixa')
                                    .getModel('faixaIgual')}
                                ></Preview>
                              ))}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              {possuiModuloRV && (tipoItem == 1 || tipoItem == 3) && (
                <Row>
                  <Col md={3}>
                    <PontoFaixaResultadoLookup
                      model={{
                        label: intl.formatMessage({
                          id: 'label.pontosFaixaResultado',
                        }),
                        value: model.getModel('pontoFaixaResultado').value,
                        requestChange: model.getModel('pontoFaixaResultado')
                          .requestChange,
                        errors: model.getModel('pontoFaixaResultado').errors,
                      }}
                      disabled={templateVazio}
                    />
                  </Col>
                  <Col md={12}>
                    <CheckBox
                      model={{
                        label: intl.formatMessage({
                          id: 'pontosAcumuladosIgualMediaPeriodos',
                        }),
                        value: model.getModel(
                          'pontosAcumuladosIgualMediaPeriodos'
                        ).value,
                        requestChange: model.getModel(
                          'pontosAcumuladosIgualMediaPeriodos'
                        ).requestChange,
                      }}
                      disabled={templateVazio}
                    />
                  </Col>
                </Row>
              )}
              {modeloItemId == 2 && (
                <Panel header={<FormattedMessage id="tipoConsolidacao" />}>
                  <Row>
                    <Col md={6}>
                      {tipoItem != 2 && (
                        <TipoConsolidacaoMetaLookup
                          data={{
                            idsIgnorados: [TIPO_CONSOLIDACAO.VALOR_POWERBI],
                          }}
                          model={{
                            label: intl.formatMessage(
                              { id: 'label.tipoConsolidacaoMeta' },
                              { meta: resources.meta }
                            ),
                            value: model.getModel('tipoConsolidacaoMeta').value,
                            requestChange: model.getModel(
                              'tipoConsolidacaoMeta'
                            ).requestChange,
                          }}
                        ></TipoConsolidacaoMetaLookup>
                      )}
                    </Col>
                    <Col md={6}>
                      {tipoItem != 2 && (
                        <TipoConsolidacaoMetaAcumuladoLookup
                          data={{
                            idsIgnorados: [
                              TIPO_CONSOLIDACAO_ACUMULADO.VALOR_POWERBI,
                            ],
                          }}
                          model={{
                            label: intl.formatMessage(
                              { id: 'tipoConsolidacaoAcumuladoMeta' },
                              { meta: resources.meta }
                            ),
                            value: model.getModel(
                              'tipoConsolidacaoAcumuladoMeta'
                            ).value,
                            requestChange: model.getModel(
                              'tipoConsolidacaoAcumuladoMeta'
                            ).requestChange,
                          }}
                        ></TipoConsolidacaoMetaAcumuladoLookup>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TipoConsolidacaoRealizadoLookup
                        data={{
                          idsIgnorados: idsIgnorados,
                        }}
                        model={{
                          label: intl.formatMessage({
                            id: 'label.tipoConsolidacaoRealizado',
                          }),
                          value: model.getModel('tipoConsolidacaoRealizado')
                            .value,
                          requestChange: model.getModel(
                            'tipoConsolidacaoRealizado'
                          ).requestChange,
                        }}
                      ></TipoConsolidacaoRealizadoLookup>
                    </Col>
                    <Col md={6}>
                      <TipoConsolidacaoRealizadoAcumuladoLookup
                        data={{
                          idsIgnorados: [
                            TIPO_CONSOLIDACAO_ACUMULADO.VALOR_POWERBI,
                          ],
                        }}
                        model={{
                          label: intl.formatMessage({
                            id: 'tipoConsolidacaoAcumuladoRealizado',
                          }),
                          value: model.getModel(
                            'tipoConsolidacaoAcumuladoRealizado'
                          ).value,
                          requestChange: model.getModel(
                            'tipoConsolidacaoAcumuladoRealizado'
                          ).requestChange,
                        }}
                      ></TipoConsolidacaoRealizadoAcumuladoLookup>
                    </Col>
                  </Row>
                </Panel>
              )}
            </Panel>
          )}
          {modeloItemId == 1 &&
            tipoItem != 6 &&
            tipoItem != 7 &&
            tipoItem != 8 &&
            tipoItem != 9 &&
            model.getModel('configuracaoGeral').value &&
            (model.getModel('configuracaoGeral').value.utilizarPerspectiva ||
              model.getModel('configuracaoGeral').value
                .utilizarTemaEstrategico ||
              model.getModel('configuracaoGeral').value.utilizarClassificacao ||
              model.getModel('configuracaoGeral').value
                .utilizarClassificacao2 ||
              model.getModel('configuracaoGeral').value
                .utilizarMapaComparativo ||
              model.getModel('configuracaoGeral').value.utilizarObservacao) && (
              <Panel header={<FormattedMessage id="label.outrasInformacoes" />}>
                <Row>
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarPerspectiva && (
                      <Col md={3}>
                        <PerspectivaLookup
                          clearable={true}
                          model={{
                            label: (
                              <span>
                                {capitalizeFirstLetter(resources.perspectiva)}
                                <Info
                                  id="infoPerspectivaIndicador"
                                  placement="right"
                                  text={intl.formatMessage(
                                    { id: 'label.informativoPerspectiva' },
                                    { perspectiva: resources.perspectiva }
                                  )}
                                />
                              </span>
                            ),
                            value: model.getModel('perspectiva').value,
                            requestChange:
                              model.getModel('perspectiva').requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarTemaEstrategico && (
                      <Col md={3}>
                        <TemaEstrategicoLookup
                          model={{
                            label: (
                              <span>
                                {capitalizeFirstLetter(
                                  resources.temaestrategico
                                )}
                                <Info
                                  id="infoTemaEstrategicoIndicador"
                                  placement="right"
                                  text={intl.formatMessage(
                                    { id: 'label.informativoTemaEstrategico' },
                                    {
                                      objetivosestrategicos:
                                        resources.objetivosestrategicos,
                                    }
                                  )}
                                />
                              </span>
                            ),
                            value: model.getModel('temas').value,
                            requestChange:
                              model.getModel('temas').requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarClassificacao && (
                      <Col md={3}>
                        <ClassificacaoLookup
                          model={{
                            label: capitalizeFirstLetter(
                              resources.classificacao1
                            ),
                            value: model.getModel('classificacoes').value,
                            requestChange:
                              model.getModel('classificacoes').requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarClassificacao2 && (
                      <Col md={3}>
                        <Classificacao2Lookup
                          model={{
                            label: capitalizeFirstLetter(
                              resources.classificacao2
                            ),
                            value: model.getModel('classificacoes2').value,
                            requestChange:
                              model.getModel('classificacoes2').requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                </Row>
                <Row>
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarMapaComparativo && (
                      <PaisUf
                        disabled={templateVazio}
                        pais={model.getModel('pais')}
                        uf={model.getModel('uf')}
                        model={model}
                      />
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarObservacao && (
                      <Col md={8}>
                        <Input
                          maxLength={1000}
                          rows={5}
                          type="textarea"
                          model={{
                            label: capitalizeFirstLetter(resources.observacao),
                            value: model.getModel('observacao').value,
                            requestChange:
                              model.getModel('observacao').requestChange,
                          }}
                          disabled={templateVazio}
                        />
                      </Col>
                    )}
                </Row>
              </Panel>
            )}
        </div>
      </LoadingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracaoSistema: state.user.configuracao,
    modulosComprados: state.user.licenca.modulos,
    moduloAtual: state.user.moduloAtual,
    addons: state.user.addonsDisponiveis,
  };
}
export default injectIntl(connect(mapStateToProps)(IndicadorResumoEdit));
