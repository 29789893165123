import Lookup from 'src/componentes/select2';

const ModeloVisualizacaoComissoesLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/ModeloVisualizacaoComissoesLookup"
    {...rest}
  />
);

export default ModeloVisualizacaoComissoesLookup;
