import React from 'react';
import http from 'src/services/httpService';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LoadingContainer from '../../componentes/loading-container';
import errorHandler from '../../utils/error-handler';
import { FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';
import Avatar from '../../componentes/avatar';
import { prepareHtml } from '../../utils/html';
import { format, isValid } from 'date-fns';
import formatDateFns from 'src/utils/formatDateFns';

@autobind
class Versao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.carregar(this.props.url);
  }

  carregar(url) {
    http
      .post(url, {
        id: this.props.id,
      })
      .then((response) => {
        this.setState({
          ...response.data,
          isLoading: false,
        });

        if (this.props.onTotalLoaded) {
          this.props.onTotalLoaded(this.state.QuantidadeTotal);
        }
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ isLoading: false });
      });
  }

  renderData(dataHora, formato) {
    if (!isValid(new Date(dataHora))) {
      return this.props.intl.formatMessage({ id: 'dataNaoDefinida' });
    }

    const { locale } = this.props.intl;
    const { currentLocale } = formatDateFns(locale);

    return format(new Date(dataHora), 'Pp', { locale: currentLocale });
  }

  renderAlteracao(alteracao, alteracaoIndex) {
    return (
      <div key={alteracaoIndex} style={{ padding: 10 }}>
        <div
          style={{
            marginBottom: 5,
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        >
          <Avatar
            foto={alteracao && alteracao.fotoArquivo}
            size={23}
            label={alteracao.usuario}
          ></Avatar>{' '}
          <span style={{ verticalAlign: 'middle', marginLeft: 5 }}>
            - {this.renderData(alteracao.dataHora, alteracao.formatoData)}
          </span>
        </div>
        <Table size="sm" bordered>
          <thead>
            <tr>
              <th style={{ width: '33%' }}>
                {<FormattedMessage id="label.campo" />}
              </th>
              <th style={{ width: '33%' }}>
                {<FormattedMessage id="label.valorAntigo" />}
              </th>
              <th style={{ width: '33%' }}>
                {<FormattedMessage id="label.valorNovo" />}
              </th>
            </tr>
          </thead>
          <tbody>
            {alteracao.alteracoesCampos.map(
              (alteracaoCampo, alteracaoCampoIndex) => (
                <tr key={alteracaoCampoIndex}>
                  <td>{alteracaoCampo.campo}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={prepareHtml(
                        alteracaoCampo.valorAnterior
                      )}
                    ></div>
                  </td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={prepareHtml(
                        alteracaoCampo.novoValor
                      )}
                    ></div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
        <br></br>
      </div>
    );
  }

  renderAlteracoes() {
    return this.state.alteracoes.map((alteracao, alteracaoIndex) =>
      this.renderAlteracao(alteracao, alteracaoIndex)
    );
  }

  renderSemAlteracoes() {
    return (
      <div>
        <p>{<FormattedMessage id="label.nenhumaAlteracaoRealizada" />}</p>
      </div>
    );
  }
  render() {
    return (
      <LoadingContainer isLoading={this.state.isLoading}>
        {this.state.alteracoes && this.state.alteracoes.length
          ? this.renderAlteracoes()
          : this.renderSemAlteracoes()}
      </LoadingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Versao));
