import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import MdiIcon from '../componentes/mdi-icon';

@autobind
class FarolRevisaoPontoControle extends React.Component {
  handleNome(item) {
    return (
      item &&
      item.data && (
        <div className="d-flex align-items-center">
          <MdiIcon icon={item.data.icone} color={item.data.cor} colorHover={item.data.cor} size={22} className="mr-2"></MdiIcon>
          <span>{item.data.descricao}</span>
        </div>
      )
    );
  }

  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/FarolPontoControleRevisaoLookup" clearable={false} valueComponent={this.handleNome} {...this.props} />;
  }
}

export default FarolRevisaoPontoControle;
