import { createContext, useEffect, useReducer } from 'react';

const keyItem = 'ITEM_RELACIONAMENTO-ITENS';
const keyArray = 'RELACIONAMENTO-ITENS';

const initialState = {
  initialized: false,
  selectedItem: null,
  expandedChildItems: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { selectedItem, expandedChildItems } = action.payload;

      return {
        ...state,
        initialized: true,
        selectedItem,
        expandedChildItems,
      };
    }

    case 'SET_SELECTED_ITEM': {
      const { selectedItem } = action.payload;

      localStorage.setItem(keyItem, JSON.stringify(selectedItem));
      localStorage.setItem(keyArray, JSON.stringify([]));

      return {
        ...state,
        selectedItem,
        expandedChildItems: [],
      };
    }
    case 'UPDATE_EXPANDED_ITEMS': {
      const { expandedChildItems } = action.payload;

      localStorage.setItem(keyArray, JSON.stringify(expandedChildItems || []));

      return {
        ...state,
        expandedChildItems,
      };
    }
  }
};

const RelacionamentoItensContext = createContext({
  ...initialState,
  updateChildItems: () => {},
  setSelectedItem: () => {},
});

export const RelacionamentoItensContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateChildItems = (expandedChildItems) => {
    dispatch({
      type: 'UPDATE_EXPANDED_ITEMS',
      payload: { expandedChildItems },
    });
  };

  const setSelectedItem = (selectedItem) => {
    dispatch({
      type: 'SET_SELECTED_ITEM',
      payload: { selectedItem },
    });
  };

  const initialize = (selectedItem, expandedChildItems) => {
    dispatch({
      type: 'INITIALIZE',
      payload: { selectedItem, expandedChildItems },
    });
  };

  useEffect(() => {
    const itemValue = localStorage.getItem(keyItem);
    const arrayValue = JSON.parse(localStorage.getItem(keyArray) || '[]');

    initialize(!itemValue ? null : JSON.parse(itemValue), arrayValue);
  }, []);

  if (!state.initialized) return '';

  return (
    <RelacionamentoItensContext.Provider
      value={{
        ...state,
        updateChildItems,
        setSelectedItem,
      }}
    >
      {children}
    </RelacionamentoItensContext.Provider>
  );
};

export default RelacionamentoItensContext;
