import React from 'react';
import Lookup from '../componentes/select2';

const TipoPeriodicidadePontoControle = ({ clearable, required, ...rest }) => (
  <Lookup labelKey="descricao" valueKey="id" url="/TipoPeriodicidadePontoControleLookup" clearable={clearable} required={required} {...rest} />
);

TipoPeriodicidadePontoControle.defaultProps = {
  clearable: false,
  required: false,
};

export default TipoPeriodicidadePontoControle;
