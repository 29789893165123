import React from 'react';
import { Col, Row } from 'reactstrap';
import Input from '../../../componentes/input';
import ColaboradorSimplificadoLookup from '../../../lookups/ColaboradorSimplificadoLookup';
import PacoteLookup from '../../../lookups/pacote';
import TipoPacoteLookup from '../../../lookups/tipo-pacote';
import ClassificacaoLookup from '../../../lookups/classificacao-mo';

export default class PacoteSearch extends React.Component {
  render() {
    let { model, exibirClassificacao } = this.props;

    return (
      <Row>
        <Col md={3}>
          <Input model={model.getModel('nome')} />
        </Col>
        {exibirClassificacao && (
          <Col md={3}>
            <ClassificacaoLookup clearable model={model.getModel('classificacao')} />
          </Col>
        )}
        <Col md={3}>
          <PacoteLookup model={model.getModel('pai')} />
        </Col>
        <Col md={3}>
          <ColaboradorSimplificadoLookup model={model.getModel('gestor')} />
        </Col>
        <Col md={3}>
          <TipoPacoteLookup model={model.getModel('tipo')} />
        </Col>
      </Row>
    );
  }
}
