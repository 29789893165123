import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import PontoFaixaResultadoEdit from '../paginas/remuneracao-variavel/pontos-faixa-resultado/edit';
import PontoFaixaResultadoList from '../paginas/remuneracao-variavel/pontos-faixa-resultado/view';

@autobind
class PontoFaixaResultadoLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueComponent={this.renderPontoFaixaResultado}
        valueKey="id"
        labelKey="descricao"
        showSearch
        clearable={true}
        url="/PontoFaixaResultadoLookup"
        editComponent={PontoFaixaResultadoEdit}
        searchComponent={PontoFaixaResultadoList}
        {...this.props}
      />
    );
  }
}
export default PontoFaixaResultadoLookup;
