import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';

import PontoFaixaResultadoEdit from './edit';

@autobind
class PontosPorFaixaResultadoList extends React.Component {
  render() {
    let { model, intl, disabled, podeEditar } = this.props;

    return (
      <div>
        <List
          url="/ItemPontoFaixaResultado"
          showSearch={false}
          showTitle={true}
          title={intl.formatMessage({ id: 'label.pontosFaixaResultado' })}
          editComponent={PontoFaixaResultadoEdit}
          sort="dataInicio"
          parameters={{ ItemId: model.getModel('id').value }}
          showExport={false}
          showNew={!disabled && podeEditar}
          showEdit={!disabled && podeEditar}
          showDelete={!disabled && podeEditar}
          disabled={disabled}
          podeEditar={podeEditar}
          {...this.props}
        >
          <ListColumn
            headerText={intl.formatMessage({ id: 'idIntegracao' })}
            valueField="idIntegracao"
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'dataInicio' })}
            valueField="dataInicio"
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'dataTermino' })}
            valueField="dataTermino"
          />
          <ListColumn
            headerText={intl.formatMessage({
              id: 'descricao',
            })}
            valueField="pontoFaixaResultado"
          />
        </List>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(
  connect(mapStateToProps)(PontosPorFaixaResultadoList)
);
