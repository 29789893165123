import React from 'react';
import { Col, Row } from 'reactstrap';
import Input from '../../componentes/input';
import Checkbox from '../../componentes/checkbox';
import ItemOrganizacaoLookup from '../../lookups/item-organizacao';
import StatusLookup from '../../lookups/status-item';
import MelhorLookup from '../../lookups/melhor';
import ClassificacaoLookup from '../../lookups/classificacao';
import PerspectivaLookup from '../../lookups/perspectiva';
import ResponsavelLookup from '../../lookups/colaborador';
import PeriodicidadeLookup from '../../lookups/periodicidade';
import DigitadorLookup from '../../lookups/colaborador';
import TemaEstrategicoLookup from '../../lookups/tema-estrategico';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import DesenharCampoCustomizado from '../item/campo-customizado/componentes/desenhar';
import DesdobramentoLookup from '../../lookups/desdobramento';
import FarolLookup from '../../lookups/farol';
import http from 'src/services/httpService';
import ItemLookup from '../../lookups/item';
import TagLookup from 'src/lookups/TagLookup';

class IndicadorBaseSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracoes: null,
    };
  }

  componentDidMount() {
    http
      .post(`/ItemConfiguracaoIndicador/ObterConfiguracoes`, {})
      .then((response) => {
        this.setState({
          configuracoes: response.data,
        });
      });
  }

  render() {
    let { model, resources, intl, configuracaoSistema, tipoItemId } =
      this.props;
    let { configuracoes } = this.state;

    let utilizarTemplateIndicador =
      configuracaoSistema.utilizarTemplateIndicador;

    return (
      <div>
        <Row>
          <Col md={3}>
            <Input model={model.getModel('nome')} />
          </Col>
          <Col md={2}>
            <Input model={model.getModel('idIntegracao')} />
          </Col>
          <Col md={3}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.areas),
                value: model.getModel('areas').value,
                requestChange: model.getModel('areas').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              model={{
                label: intl.formatMessage(
                  { id: 'label.considerarAreasSubordinadas' },
                  { areas: resources.areas }
                ),
                value: model.getModel('areasSubordinadas').value,
                requestChange:
                  model.getModel('areasSubordinadas').requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <ResponsavelLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.responsaveis),
                value: model.getModel('responsaveis').value,
                requestChange: model.getModel('responsaveis').requestChange,
              }}
            />
          </Col>
          {configuracoes && configuracoes.utilizarEquipe && (
            <Col md={3}>
              <ResponsavelLookup
                multi={true}
                model={{
                  label: intl.formatMessage({ id: 'equipe' }),
                  value: model.getModel('equipe').value,
                  requestChange: model.getModel('equipe').requestChange,
                }}
              />
            </Col>
          )}
          {this.state.configuracoes != null &&
            this.state.configuracoes.utilizarDigitadorValor && (
              <Col md={3}>
                <DigitadorLookup
                  multi={true}
                  model={{
                    label: capitalizeFirstLetter(resources.digitadores),
                    value: model.getModel('digitadores').value,
                    requestChange: model.getModel('digitadores').requestChange,
                  }}
                />
              </Col>
            )}
          <Col md={3}>
            <StatusLookup
              multi
              clearable
              model={model.getModel('listaStatus')}
              data={{ idsSelecionados: [1, 2, 3, 4, 5] }}
            />
          </Col>
          <Col md={2}>
            <PeriodicidadeLookup
              clearable
              model={model.getModel('periodicidade')}
            />
          </Col>
          <Col md={2}>
            <MelhorLookup
              clearable
              model={{
                label: capitalizeFirstLetter(resources.polaridade),
                value: model.getModel('melhor').value,
                requestChange: model.getModel('melhor').requestChange,
              }}
            />
          </Col>
          <Col md={2}>
            <FarolLookup
              data={{ idsIgnorados: null }}
              clearable
              model={{
                label: capitalizeFirstLetter(resources.farol),
                value: model.getModel('farol').value,
                requestChange: model.getModel('farol').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          {configuracaoSistema.utilizarFuncionalidadeDesdobramento && (
            <Col md={2}>
              <DesdobramentoLookup
                model={{
                  label: model.getModel('desdobramento').label,
                  value: model.getModel('desdobramento').value,
                  requestChange: model.getModel('desdobramento').requestChange,
                }}
              />
            </Col>
          )}
          {configuracoes && configuracoes.utilizarClassificacao && (
            <Col md={2}>
              <ClassificacaoLookup
                clearable
                multi={false}
                model={{
                  label: capitalizeFirstLetter(resources.classificacao1),
                  value: model.getModel('classificacao').value,
                  requestChange: model.getModel('classificacao').requestChange,
                }}
              />
            </Col>
          )}
          {configuracoes && configuracoes.utilizarClassificacao2 && (
            <Col md={2}>
              <ClassificacaoLookup
                clearable
                multi={false}
                model={{
                  label: capitalizeFirstLetter(resources.classificacao2),
                  value: model.getModel('classificacao2').value,
                  requestChange: model.getModel('classificacao2').requestChange,
                }}
              />
            </Col>
          )}
          {configuracoes && configuracoes.utilizarCampoAuxiliarTexto1 && (
            <Col md={6}>
              <Input
                maxLength={1000}
                rows={5}
                model={{
                  label: capitalizeFirstLetter(resources.campoauxiliartexto1),
                  value: model.getModel('campoAuxiliarTexto1').value,
                  requestChange: model.getModel('campoAuxiliarTexto1')
                    .requestChange,
                }}
              />
            </Col>
          )}
          {configuracoes && configuracoes.utilizarCampoAuxiliarTexto2 && (
            <Col md={6}>
              <Input
                maxLength={1000}
                rows={5}
                model={{
                  label: capitalizeFirstLetter(resources.campoauxiliartexto2),
                  value: model.getModel('campoAuxiliarTexto2').value,
                  requestChange: model.getModel('campoAuxiliarTexto2')
                    .requestChange,
                }}
              />
            </Col>
          )}
          {configuracoes && configuracoes.utilizarCampoAuxiliarTexto3 && (
            <Col md={6}>
              <Input
                maxLength={1000}
                rows={5}
                model={{
                  label: capitalizeFirstLetter(resources.campoauxiliartexto3),
                  value: model.getModel('campoAuxiliarTexto3').value,
                  requestChange: model.getModel('campoAuxiliarTexto3')
                    .requestChange,
                }}
              />
            </Col>
          )}
          {configuracoes && configuracoes.utilizarPerspectiva && (
            <Col md={2}>
              <PerspectivaLookup
                clearable
                model={{
                  label: capitalizeFirstLetter(resources.perspectiva),
                  value: model.getModel('perspectiva').value,
                  requestChange: model.getModel('perspectiva').requestChange,
                }}
              />
            </Col>
          )}
          {configuracoes && configuracoes.utilizarTemaEstrategico && (
            <Col md={2}>
              <TemaEstrategicoLookup
                clearable
                multi={false}
                model={{
                  label: capitalizeFirstLetter(resources.temaestrategico),
                  value: model.getModel('tema').value,
                  requestChange: model.getModel('tema').requestChange,
                }}
              />
            </Col>
          )}
          <Col md={2}>
            <FarolLookup
              data={{ idsIgnorados: null }}
              clearable
              model={{
                label: capitalizeFirstLetter(resources.farolAcumulado),
                value: model.getModel('farolAcumulado').value,
                requestChange: model.getModel('farolAcumulado').requestChange,
              }}
            />
          </Col>
          {tipoItemId == 1 && utilizarTemplateIndicador && (
            <Col md={2}>
              <ItemLookup
                className="inline-block"
                data={{ tiposIdsSelecionados: [12] }}
                model={{
                  label: intl.formatMessage({ id: 'template' }),
                  value: model.getModel('itemTemplate').value,
                  requestChange: model.getModel('itemTemplate').requestChange,
                }}
              />
            </Col>
          )}
          {(tipoItemId == 1 || tipoItemId == 2 || tipoItemId == 3) && (
            <Col md={4}>
              <TagLookup
                multi
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage({ id: 'tags' }, { tags: resources.tags })
                  ),
                  value: model.getModel('tags').value,
                  requestChange: model.getModel('tags').requestChange,
                }}
                data={{ funcionalidadesIdSelecionaveis: [tipoItemId] }}
                podeEditar={false}
              />
            </Col>
          )}
        </Row>
        {model.getModel('camposCustomizados').value &&
          model.getModel('camposCustomizados').value.length > 0 && (
            <Row>
              <Col md={12}>
                <DesenharCampoCustomizado
                  model={model.getModel('camposCustomizados')}
                />
              </Col>
            </Row>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracaoSistema: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(IndicadorBaseSearch));
