import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import Info from '../componentes/info';

@autobind
class TratamentoRiscoLookup extends React.Component {
  render() {
    return <Lookup labelKey="nome" valueKey="id" url="/TratamentoRiscoLookup" clearable={false} {...this.props} />;
  }
}

export default TratamentoRiscoLookup;
