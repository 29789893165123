import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import ConsequenciaRiscoEdit from '../paginas/gestao-risco/consequencias/edit';

@autobind
class ConsequenciaRisco extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/ConsequenciaRiscoLookup" editComponent={ConsequenciaRiscoEdit} {...this.props} />;
  }
}

export default ConsequenciaRisco;
