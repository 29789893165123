import { useIntl } from 'react-intl';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import PlanejamentoInicialEdit from './edit';
import SearchSchema from './search-schema';
import Search from './search';

const ListPlanejamentoInicial = ({ parameters, ...props }) => {
  const intl = useIntl();

  const renderItem = (item) => {
    if (!item.item) return;
    return item.item.nome;
  };

  return (
    <List
      url="/PlanejamentoInicial"
      editComponent={PlanejamentoInicialEdit}
      tag={2014}
      showSearch
      filtroId={props.isModal ? 70 : 69}
      searchComponent={<Search />}
      searchSchema={SearchSchema}
      parameters={parameters}
      refreshOnClose
      {...props}
    >
      <ListColumn
        headerText={intl.formatMessage({ id: 'periodo' })}
        valueField="dataPeriodo"
        sortField="dataPeriodo"
      />
      <ListColumn
        headerText={intl.formatMessage({ id: 'status' })}
        valueField="status"
        sortField="status"
      />
      <ListColumn
        headerText={intl.formatMessage({ id: 'item' })}
        valueField="nomeItem"
        valueFunction={renderItem}
      />
    </List>
  );
};

export default ListPlanejamentoInicial;
