import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import { autobind } from 'core-decorators';
import { Schema } from 'src/utils/form';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Checkbox from 'src/componentes/checkbox';
import Radio from 'src/componentes/radio';
import Panel from 'src/componentes/panel';
import Slider from './slider';
import ReprocessarDialog from './reprocessarPontos';
import Confirm from 'src/componentes/message-box/confirm';
import ContentManager from 'src/componentes/content-manager';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import { capitalizeFirstLetter } from 'src/utils/string';
import guid from 'src/utils/guid';
import InputNumber from 'src/componentes/input-number';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';

import Historico from './Historico';
import { ORIGEM_FAIXA_RESULTADO } from 'src/utils/constants';

let schema = Schema.object({
  id: Schema.number().label(),
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="codigo" />)
    .required(),
  interpolarValoresFaixa: Schema.string().label(
    <FormattedMessage id="label.interpolarValoresFaixa" />
  ),
  tipoPontoFaixaResultado: Schema.string().label('').required(),
});

@autobind
class FormEdit extends React.Component {
  renderContent() {
    const { model, isHistory, resources, intl } = this.props;

    return (
      <>
        <Row>
          <Col md={3}>
            <Input
              disabled={isHistory}
              autoFocus
              model={model.getModel('idIntegracao')}
              required
            />
          </Col>
          <Col md={4}>
            <Input
              disabled={isHistory}
              maxLength={300}
              model={model.getModel('descricao')}
              required
            />
          </Col>
          <Col md={4}>
            <Checkbox
              disabled={isHistory}
              model={model.getModel('interpolarValoresFaixa')}
              style={{ marginTop: 27 }}
            />
          </Col>
        </Row>
        {model.getModel('origemFaixaResultado')?.value?.id !=
          ORIGEM_FAIXA_RESULTADO.AUDITORIA && (
          <Row>
            <Col md={8}>
              <Radio
                disabled={isHistory}
                model={model.getModel('tipoPontoFaixaResultado')}
                options={model.getModel('opcoesTipoPontoFaixaResultado').value}
                labelField="descricao"
                idField="id"
                name={`${guid()}`}
              />
            </Col>
          </Row>
        )}
        {model.getModel('exibirMetaDesafio') &&
          model.getModel('exibirMetaDesafio').value && (
            <Row>
              <Col md={3}>
                <InputNumber
                  disabled={isHistory}
                  precision={2}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'metaDesafio' },
                        {
                          meta: resources.meta,
                        }
                      )
                    ),
                    value: model.getModel('metaDesafio').value,
                    requestChange: model.getModel('metaDesafio').requestChange,
                  }}
                />
              </Col>
            </Row>
          )}
        <Row>
          <Col md={12}>
            <Panel>
              <Slider
                disabled={isHistory}
                model={model.getModel('faixas')}
                interpolarValoresFaixa={
                  model.getModel('interpolarValoresFaixa').value
                }
                tipoPontoFaixaResultado={
                  model.getModel('tipoPontoFaixaResultado').value
                }
              />
            </Panel>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    let { model, intl, resources, isHistory = false } = this.props;

    if (isHistory) return this.renderContent();

    const id = model.getModel('id').value;

    return (
      <Tabs>
        <Tab key="1" label={intl.formatMessage({ id: 'label.resumo' })}>
          {this.renderContent()}
        </Tab>
        {id > 0 && (
          <Tab key="2" label={capitalizeFirstLetter(resources.historico)}>
            <Historico pontoFaixaResultadoId={id} />
          </Tab>
        )}
      </Tabs>
    );
  }
}

@autobind
class PontoFaixaResultadoEdit extends React.Component {
  handleSave(model, close) {
    if (model.origemFaixaResultado?.id !== ORIGEM_FAIXA_RESULTADO.AUDITORIA) {
      ContentManager.addContent(
        <Confirm
          labelConfirm={<FormattedMessage id="label.reprocessar" />}
          message={
            <FormattedMessage id="desejaReprocessarPontosPorDesempenhoItenVinculadoFaixa" />
          }
          handleConfirm={this.handleConfirmData.bind(this, model, close)}
        />
      );
    } else {
      this.handleBasicSave(model, close);
    }
  }

  handleConfirmData(model, close) {
    ContentManager.addContent(
      <ReprocessarDialog intl={this.props.intl} close={close} model={model} />
    );
  }

  handleBasicSave(model, close) {
    http
      .post(`/PontoFaixaResultado/Save`, {
        model: model,
        parameters: {
          ...this.props.parameters,
        },
      })
      .then(() => {
        close();
      })
      .catch((error) => {
        errorHandler(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    return (
      <Edit
        url="/PontoFaixaResultado"
        title={this.props.intl.formatMessage({
          id: 'label.pontosFaixaResultado',
        })}
        formComponent={FormEdit}
        schema={schema}
        width={1000}
        handleSave={this.handleSave}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PontoFaixaResultadoEdit));

export { FormEdit };
