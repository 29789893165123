import { FormattedMessage } from 'react-intl';
import { Schema } from 'src/utils/form';

const schema = Schema.object({
  dataAnalise: Schema.string()
    .label(<FormattedMessage id="dataAnalise" />)
    .required(),
  observacao: Schema.string().label(<FormattedMessage id="observacao" />),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  efetividadeControle: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return !context.parent.value.avaliarEfetividadeControleSeparadamente;
      },
    }),
  efetividadeControleCorretivo: Schema.string()
    .label(<FormattedMessage id="controleCorretivo" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.avaliarEfetividadeControleSeparadamente && context.parent.value.tipoCorretivo;
      },
    }),
  efetividadeControlePreventivo: Schema.array()
    .label(<FormattedMessage id="controlePreventivo" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.avaliarEfetividadeControleSeparadamente && !context.parent.value.tipoCorretivo;
      },
    }),
});

export default schema;
