import { handleDownloadFile } from '../utils';
import { useSnackbar } from 'notistack';

let stateBeingChangedFn = null;

export const ganttConfigurations = (
  intl,
  startDate,
  handleUpdateExpantion,
  handleSaveColumnSizes,
  configurations,
  permissions,
  disableTaskMenu,
  isConsultaExterna,
  handleOpenColaboratorsList
) => {
  const { enqueueSnackbar } = useSnackbar();
  const ganttRevertMessage = intl.formatMessage({
    id: 'ganttRejeitouAlteracoes',
  });

  const showMessage = (message) => {
    enqueueSnackbar(message, {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  return {
    features: {
      filter: true,
      dependencies: true,
      nonWorkingTime: true,
      taskMenu: {
        items: {
          editTask: false,
          cut: false,
          copy: false,
          paste: false,
          search: false,
          filterDateEquals: false,
          filterDateBefore: false,
          filterDateAfter: false,
          filterNumberEquals: false,
          filterNumberLess: false,
          filterNumberMore: false,
          filterStringEquals: false,
          filterRemove: false,
          add: false,
          addTaskAbove: false,
          addTaskBelow: false,
          milestone: false,
          subtask: false,
          successor: false,
          predecessor: false,
          convertToMilestone: false,
          splitTask: false,
          deleteTask: false,
          linkTasks: false,
          unlinkTasks: false,
        },
      },
      rowReorder: {
        disabled: false,
        listeners: {
          gridRowBeforeDropFinalize: ({ context }) => {
            const { parent, records } = context || {};

            if (parent.id <= 0) return false;

            const planoAcaoParent = parent?.isProjectModel
              ? 'main'
              : parent.originalData?.planoAcaoId;

            const allRecordsSameContext = records.every(
              (x) => x.originalData?.planoAcaoId === planoAcaoParent
            );

            return allRecordsSameContext;
          },
        },
      },
      taskCopyPaste: false,
      percentBar: {
        disabled: configurations && !configurations.utilizarPercentualRealizado,
        allowResize:
          !isConsultaExterna &&
          configurations &&
          configurations.utilizarPercentualRealizado &&
          permissions &&
          permissions.allowEdit,
        displayField: 'percentDone',
        showPercentage: true,
      },
      taskEdit: { disabled: true },
      baselines: { disabled: true },
      criticalPaths: { disabled: true },
      parentArea: { disabled: true },
      timeRanges: {
        disabled: true,
        showHeaderElements: false,
        showCurrentTimeLine: false,
      },
      cellEdit: {
        addNewAtEnd: false,
      },
      dependencyEdit: {
        disabled: false,
      },
      progressLine: {
        disabled: true,
        statusDate: startDate,
      },
      labels: {
        disabled: true,
        left: {
          field: 'name',
          editor: {
            type: 'textfield',
          },
        },
      },
      taskTooltip: {
        template: ({ taskRecord }) => {
          const { farol, name } = taskRecord;
          return `
            <div>
              <div class="font-weight-bolder">${name}</div>
              ${
                farol
                  ? `<div class="d-flex align-items-center mt-2">
                        <i class="mr-1 icon icon-${farol.icone}" style="color: ${farol.cor}; width: 18px; height: 20px; background-size: 20px; display: inline-block;"></i>
                        <div style="margin-top: -2px;">${farol.descricao}</div>
                      </div>`
                  : ''
              }
            </div>`;
        },
      },
      cellTooltip: {
        hoverDelay: 0,
      },
    },
    listeners: {
      taskDblClick: () => false,
      timeAxisHeaderContextMenuBeforeShow: () => false,
      scheduleMenuBeforeShow: () => false,
      headerMenuBeforeShow: () => false,
      eventDblClick: () => false,
      resourceHeaderDblclick: () => false,
      scheduleDblClick: () => false,
      timeAxisHeaderDblClick: () => false,
      cellDblClick: () => false,
      pdfExport: handleDownloadFile,
      taskMenuBeforeShow: () => {
        if (isConsultaExterna) return false;

        return !disableTaskMenu;
      },
      beforeDependencyCreateDrag: () => {
        if (isConsultaExterna) return false;

        return permissions && permissions.allowEdit;
      },
      beforeDependencyCreateFinalize: ({
        source,
        target,
        fromSide,
        toSide,
      }) => {
        if (isConsultaExterna) return false;

        const canEdit = permissions && permissions.allowEdit;
        const validInteractionSameContext =
          source.originalData?.planoAcaoId === target.originalData?.planoAcaoId;

        const validInteractionClearWay =
          source.interacoesLiberadasEntreContextos &&
          target.interacoesLiberadasEntreContextos;

        const validInteraction =
          validInteractionSameContext || validInteractionClearWay;

        const forbiddenForMilestones =
          (fromSide === 'start' && toSide === 'start') ||
          (fromSide === 'start' && toSide === 'end');

        const validDependencyType =
          !target?.isMilestone || !forbiddenForMilestones;

        return (
          canEdit &&
          validDependencyType &&
          validInteraction &&
          source?.id > 0 &&
          target?.id > 0
        );
      },
      afterDependencyCreateDrop: ({ valid, source, target }) => {
        if (!valid && source && target) {
          showMessage(ganttRevertMessage);
        }
      },
      beforeTaskDrag: ({ taskRecord }) => {
        if (isConsultaExterna) return false;

        const canEdit = taskRecord.editarDataPrevista ?? false;
        return permissions && permissions.allowEdit && canEdit;
      },
      afterTaskDrop: ({ valid }) => {
        if (!valid) {
          showMessage(ganttRevertMessage);
        }
      },
      beforeTaskResize: ({ taskRecord }) => {
        if (isConsultaExterna) return false;

        const canEdit = taskRecord.editarDataPrevista ?? false;
        const canResize = permissions && permissions.allowEdit && canEdit;

        if (!canResize) {
          showMessage(ganttRevertMessage);
        }

        return canResize;
      },
      beforeCellEditStart: ({ editorContext }) => {
        if (isConsultaExterna) return false;

        const { record, column, value, _id } = editorContext;
        let canEdit = false;

        if (column.field == 'startDate' || column.field == 'endDate')
          canEdit = record.editarDataPrevista ?? false;
        else canEdit = record.editarDemaisCampos ?? false;

        const canCompleteAction =
          permissions && permissions.allowEdit && canEdit;

        if (canCompleteAction && column.data.type === 'user') {
          handleOpenColaboratorsList([{ id: value }], _id);
          return false;
        }

        return canCompleteAction;
      },
      beforeFinishCellEdit: ({ inputField, value }) => {
        if (!inputField?.isDependencyField) return true;

        return value?.every((x) => {
          const fromEvent = x.fromEvent;
          const toEvent = x.toEvent;

          const isSameContext =
            fromEvent.originalData?.planoAcaoId ===
            toEvent.originalData?.planoAcaoId;

          const hasClearWay =
            fromEvent.interacoesLiberadasEntreContextos &&
            toEvent.interacoesLiberadasEntreContextos;

          return isSameContext || hasClearWay;
        });
      },
      expandNode: ({ record }) => {
        record.id > 0 && handleUpdateExpantion(record.id, true);
      },
      collapseNode: ({ record }) => {
        record.id > 0 && handleUpdateExpantion(record.id, false);
      },
      stateChange: () => {
        clearTimeout(stateBeingChangedFn);
        stateBeingChangedFn = setTimeout(() => {
          handleSaveColumnSizes();
          stateBeingChangedFn = null;
        }, 1000);
      },
    },
  };
};
