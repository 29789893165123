import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import SvgIcon from '../../../../componentes/svg-icon';
import guid from '../../../../utils/guid';
import { capitalizeFirstLetter } from '../../../../utils/string';

//Arquivo com funções em comum tanto no widget tabela de valores como na própria tabela de valores.

const isIE =
  '-ms-scroll-limit' in document.documentElement.style &&
  '-ms-ime-align' in document.documentElement.style;

export function handleOnScroll() {
  let container = document.getElementById('table-valor');

  if (!isIE) {
    let cells = container.querySelectorAll('.fixed-column,.fixed-row');
    for (let i = 0; i < cells.length; i++) {
      let cell = cells[i];
      let x = cell.classList.contains('fixed-column')
        ? container.scrollLeft
        : 0;
      let y = cell.classList.contains('fixed-row') ? container.scrollTop : 0;
      cells[i].style.transform = `translate(${x}px, ${y}px)`;
    }
  } else {
    let cells = container.querySelectorAll('.ie-fixed-column,.ie-fixed-row');
    for (let i = 0; i < cells.length; i++) {
      let cell = cells[i];
      let x = cell.classList.contains('ie-fixed-column')
        ? container.scrollLeft
        : 0;
      let y = cell.classList.contains('ie-fixed-row') ? container.scrollTop : 0;
      cells[i].style.transform = `translate(${x}px, ${y}px)`;
    }
  }
}

function mostrarConceito(valor, tabelaValor) {
  let tabela = obterTabelaValor(valor, tabelaValor);

  if (tabela) {
    return true;
  } else return false;
}

function obterTabelaValor(valor, tabelaValor) {
  var tabela = tabelaValor && tabelaValor.find((x) => x.valor == valor);

  return tabela;
}

function renderTabelaValor(valor, tabelaValor) {
  let tabela = obterTabelaValor(valor, tabelaValor);
  if (tabela) return tabela.descricao;
  return valor;
}

export function renderMeta(item, itemCorrente, tabelaValor) {
  return item.usarListaOpcoes ? (
    <span>{renderTabelaValor(item.meta, tabelaValor)}</span>
  ) : itemCorrente.unidadeMedida &&
    itemCorrente.unidadeMedida.formatoUnidade &&
    itemCorrente.unidadeMedida.formatoUnidade.id == 1 ? (
    item.metaFormatado
  ) : (
    item.metaHoraMinutos
  );
}

export function renderCampo(nomeCampo, item, itemCorrente, tabelaValor) {
  return item.usarListaOpcoes ? (
    <span>{renderTabelaValor(item.meta, tabelaValor)}</span>
  ) : itemCorrente.unidadeMedida &&
    itemCorrente.unidadeMedida.formatoUnidade &&
    itemCorrente.unidadeMedida.formatoUnidade.id == 1 ? (
    item[nomeCampo + 'Formatado']
  ) : (
    item[nomeCampo + 'HoraMinutos']
  );
}

export function renderFarol(
  item,
  isWidget,
  handleRelatorioAcompanhamento = null,
  valorNaoDisponivel = false
) {
  if (item.tipoItem && item.tipoItem.id === 17) {
    return (
      item.farolIndicadorRisco &&
      !valorNaoDisponivel && (
        <SvgIcon
          style={{ cursor: 'pointer', marginBottom: -8 }}
          title={item.farolIndicadorRisco.legenda}
          icon={item.farolIndicadorRisco.icone}
          color={item.farolIndicadorRisco.cor}
          colorHover={item.farolIndicadorRisco.cor}
          size={20}
        ></SvgIcon>
      )
    );
  }
  if (item && item.status && item.status.id === 0) {
    if (item.farol && item.farol.id === 2) {
      return (
        <SvgIcon
          style={{ cursor: 'pointer', marginBottom: -8 }}
          title={'Desatualizado'}
          icon={'desatualizado'}
          color={'#dcdcdc'}
          colorHover={'#dcdcdc'}
          size={20}
        ></SvgIcon>
      );
    } else return;
  } else {
    return (
      item.farol &&
      !valorNaoDisponivel && (
        <SvgIcon
          style={{ cursor: 'pointer', marginBottom: -8 }}
          title={item.farol.legenda}
          icon={item.farol.icone}
          color={item.farol.cor}
          colorHover={item.farol.cor}
          size={20}
          iconBadge={
            item.relatorioAcompanhamento &&
            item.relatorioAcompanhamento.status &&
            item.relatorioAcompanhamento.sinalizarRelatorioPreenchido
              ? item.relatorioAcompanhamento.status.icone
              : null
          }
          onClick={
            !isWidget &&
            item.status &&
            item.status.id === 1 &&
            handleRelatorioAcompanhamento &&
            handleRelatorioAcompanhamento.bind(this, item)
          }
          className="w-100"
        ></SvgIcon>
      )
    );
  }
}

export function renderRealizado(item, itemCorrente, tabelaValor) {
  return item.usarListaOpcoes ? (
    <span>{renderTabelaValor(item.realizado, tabelaValor)}</span>
  ) : itemCorrente.unidadeMedida &&
    itemCorrente.unidadeMedida.formatoUnidade &&
    itemCorrente.unidadeMedida.formatoUnidade.id == 1 ? (
    item.realizadoFormatado
  ) : (
    item.realizadoHoraMinutos
  );
}

export function renderDesempenho(item, valorNaoDisponivel = false) {
  if (item && item.status && item.status.id === 0) return;

  return !valorNaoDisponivel && <span>{item.desempenhoFormatado}</span>;
}

export function renderMetaAcumulado(
  item,
  itemCorrente,
  index,
  tabelaValor,
  isWidget,
  handleMemoriaMetaAcumulado = null,
  valorNaoDisponivel = false
) {
  function content() {
    return itemCorrente.unidadeMedida &&
      itemCorrente.unidadeMedida.formatoUnidade &&
      itemCorrente.unidadeMedida.formatoUnidade.id == 1 &&
      !valorNaoDisponivel
      ? item.metaAcumulado
      : item.metaAcumuladoHoraMinutos;
  }

  return item.usarListaOpcoes &&
    mostrarConceito(item.metaAcumuladoDecimal, tabelaValor) ? (
    <span>{renderTabelaValor(item.metaAcumuladoDecimal, tabelaValor)}</span>
  ) : (
    <span>
      {isWidget ? (
        <div style={{ color: '#5d9cec' }} tabIndex={index}>
          {content()}
        </div>
      ) : (
        <a
          tabIndex={index}
          onClick={handleMemoriaMetaAcumulado.bind(this, item)}
        >
          {content()}
        </a>
      )}
    </span>
  );
}

export function renderFarolAcumulado(
  item,
  isWidget,
  handleRelatorioAcompanhamentoAcumulado = null,
  valorNaoDisponivel = false
) {
  return (
    item.farolAcumulado &&
    !valorNaoDisponivel && (
      <SvgIcon
        style={{ marginBottom: -8 }}
        title={item.farolAcumulado.legenda}
        size={20}
        icon={item.farolAcumulado.icone}
        color={item.farolAcumulado.cor}
        colorHover={item.farolAcumulado.cor}
        iconBadge={
          item.relatorioAcompanhamentoAcumulado &&
          item.relatorioAcompanhamentoAcumulado.status &&
          item.relatorioAcompanhamentoAcumulado.sinalizarRelatorioPreenchido
            ? item.relatorioAcompanhamentoAcumulado.status.icone
            : null
        }
        onClick={
          !isWidget &&
          item.status &&
          item.status.id === 1 &&
          handleRelatorioAcompanhamentoAcumulado.bind(this, item)
        }
      ></SvgIcon>
    )
  );
}

export function renderValorAcumuladoNaoDisponivel(tipoValor, item, intl) {
  let itemId = item && item.id ? item.id : guid();
  return (
    item && (
      <div>
        <div id={tipoValor + itemId}>&nbsp;&nbsp;</div>

        <UncontrolledTooltip placement="top" target={tipoValor + itemId}>
          {capitalizeFirstLetter(
            intl.formatMessage({ id: 'valorAcumuladoNaoDisponivel' })
          )}
        </UncontrolledTooltip>
      </div>
    )
  );
}

export function renderRealizadoAcumulado(
  item,
  itemCorrente,
  index,
  tabelaValor,
  isWidget,
  handleMemoriaRealizadoAcumulado = null,
  valorNaoDisponivel = false
) {
  function content() {
    return itemCorrente.unidadeMedida &&
      itemCorrente.unidadeMedida.formatoUnidade &&
      itemCorrente.unidadeMedida.formatoUnidade.id == 1 &&
      !valorNaoDisponivel
      ? item.realizadoAcumulado
      : item.realizadoAcumuladoHoraMinutos;
  }

  return item.usarListaOpcoes &&
    mostrarConceito(item.realizadoAcumuladoDecimal, tabelaValor) ? (
    <span>
      {renderTabelaValor(item.realizadoAcumuladoDecimal, tabelaValor)}
    </span>
  ) : (
    <span>
      {isWidget ? (
        <div style={{ color: '#5d9cec' }} tabIndex={index}>
          {content()}
        </div>
      ) : (
        <a
          tabIndex={index}
          onClick={handleMemoriaRealizadoAcumulado.bind(this, item)}
        >
          {content()}
        </a>
      )}
    </span>
  );
}

export function renderDesempenhoAcumulado(item, valorNaoDisponivel = false) {
  return !valorNaoDisponivel && <span>{item.desempenhoAcumulado}</span>;
}

export function renderDesempenhoEstimado(item) {
  return <span>{item.desempenhoEstimadoFormatado}</span>;
}

export function renderDesempenhoEstimadoAcumulado(item) {
  return <span>{item.desempenhoEstimadoAcumuladoFormatado}</span>;
}

export const defaultWidgets = [];
