import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';

import useAuth from 'src/hooks/useAuth';
import Input from 'src/componentes/input';
import { capitalizeFirstLetter } from 'src/utils/string';
import Color from 'src/componentes/color';
import FuncionalidadeTagLookup from 'src/lookups/FuncionalidadeTagLookup';
import Info from 'src/componentes/info';

const Geral = ({ model }) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const modelDescricao = model.getModel('descricao');
  const modelFuncionalidades = model.getModel('funcionalidades');
  const modelCor = model.getModel('cor');
  const modelPermissions = model.getModel('permissions');

  let allowEdit = modelPermissions.value
    ? modelPermissions.value.allowEdit
    : false;

  return (
    <>
      <Row>
        <Col md={6}>
          <Input
            formStyle={{ marginBottom: 0 }}
            model={{
              label: intl.formatMessage({ id: 'descricao' }),
              value: modelDescricao.value,
              requestChange: modelDescricao.requestChange,
              errors: modelDescricao.errors,
            }}
            required
            disabled={!allowEdit}
          />
        </Col>
        <Col md={3}>
          <Color
            disabled={!allowEdit}
            required
            model={modelCor}
            errors={modelCor.errors}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FuncionalidadeTagLookup
            disabled={!allowEdit}
            required
            multi
            model={{
              label: (
                <span>
                  {intl.formatMessage({ id: 'label.funcionalidade' })}
                  <Info
                    id="funcionalidadeTag"
                    placement="right"
                    text={intl.formatMessage({
                      id: 'textoFuncionalidadetag',
                    })}
                  />
                </span>
              ),
              value: modelFuncionalidades.value,
              requestChange: modelFuncionalidades.requestChange,
              errors: modelFuncionalidades.errors,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>&nbsp;</Col>
      </Row>
    </>
  );
};

export default Geral;
