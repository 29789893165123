import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class TipoEvidenciaLookup extends React.Component {
  static defaultProps = {
    clearable: false,
  };

  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/TipoEvidenciaLookup" clearable={this.props.clearable} {...this.props} />;
  }
}

export default TipoEvidenciaLookup;
