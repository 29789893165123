import Lookup from 'src/componentes/select2';

const ToleranciaRiscoLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/ToleranciaRiscoLookup"
    clearable
    {...rest}
  />
);

export default ToleranciaRiscoLookup;
