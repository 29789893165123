import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Box } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';
import { ParseError } from 'src/services/downloadFileUrl';
import http from 'src/services/httpService';
import { getConfiguration } from 'src/services/commonRequests';
import RevisaoEdit from 'src/paginas/gestao-risco/risco/RevisaoPontoControle/RevisaoPontoControleEditView';
import RiscoPontoControleRevisaoEdit from '../RevisaoPontoControleEditView';
import Content from 'src/componentes/pages/content';
import ListColumn from 'src/componentes/NewList/ListColumn';
import Toolbar from 'src/componentes/NewList/Toolbar';
import Results from 'src/componentes/NewList/Results';
import Button from 'src/componentes/button';
import Input from 'src/componentes/input';
import LoadingContainer from 'src/componentes/loading-container';
import ConfirmNew from 'src/componentes/NewConfirm';
import {
  getColumns,
  getFilter,
  getData,
  getPermissions,
  exportList,
} from 'src/componentes/NewList';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import ContentManager from 'src/componentes/content-manager';
import MdiIcon from 'src/componentes/mdi-icon';
import Radio from 'src/componentes/radio';
import Checkbox from 'src/componentes/checkbox';
import PontoControleEdit from 'src/paginas/gestao-risco/ponto-controle/edit';
import {
  FILTROS,
  COLUNAS,
  STATUS_PONTO_CONTROLE_REVISAO,
  ENTIDADES_CUSTOMIZADAS,
} from 'src/utils/constants';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';
import PlanoAcaoModal from 'src/paginas/acao/componentes/PlanoAcaoModal';
import Hint from 'src/paginas/item/componentes/hint';
import Legenda from 'src/paginas/gestao-risco/legenda-farol-revisao';
import pPipe from 'src/utils/pPipe.js';
import DropdownEfetividade from './components/efetividade-controle-dropdown';
import RiscoEditView from 'src/paginas/gestao-risco/risco/RiscoEditView';

import SearchSchema from './SearchSchema';
import Search from './Search';
import Alert from 'src/componentes/message-box/alert';
function ListRevisaoPontoControle({
  entityTag,
  columnsTag,
  filterTag,
  endpoint,
  parameters,
  defaultSort,
  defaultSortBy,
  exibirAuditado,
  selectedFilter,
  openLastFilter,
  showToolbar,
  showTitle,
  simplifyed,
  showNew,
  idRiscoPontoControle,
  idPontoControle,
  utilizarTituloRevisoesPendentes = false,
  dateFilterFormat,
  utilizarClickRenderNomePontoControle = true,
  hideControlesFilter = false,
  showFastSearch = true,
  ...rest
}) {
  const [updatedItems, setUpdatedItems] = useState([]);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [filter, setFilter] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(defaultSort);
  const [sortFieldBy, setSortFieldBy] = useState(defaultSortBy);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [configuration, setConfig] = useState(null);
  const intl = useIntl();
  const { terms: resources, configurations } = useAuth();
  const tiposAnexos = configurations.tiposAnexos;
  const styleCustomFields = { minWidth: 150, maxHeight: '100px' };

  const loadColumns = async (defaultColumns) => {
    try {
      const currentColumns = await getColumns(
        defaultColumns,
        entityTag,
        columnsTag,
        null,
        styleCustomFields
      );
      setColumns(currentColumns.columns);
      setAllColumns(currentColumns.allColumns);
    } catch (err) {
      errorHandler(err);
    }
  };

  const loadData = useCallback(
    async (defaultColumns, filter) => {
      try {
        if (!defaultColumns || !defaultColumns.length)
          defaultColumns = _getDefaultColumns(defaultColumns);

        setLoading(true);
        const newPermissions = await getPermissions(endpoint, parameters);
        setPermissions(newPermissions);

        let newData = [];

        if (newPermissions.allowView || newPermissions.allowList) {
          await loadColumns(defaultColumns);

          let newFilter = selectedFilter;
          if (openLastFilter && filterTag)
            newFilter = await getFilter(filterTag, true);

          if (!newFilter) newFilter = { valor: {} };

          if (filter && filter.valor) newFilter.valor = filter.valor;

          setFilter(newFilter);
          let valueFilter = {};
          if (newFilter != null) valueFilter = newFilter.valor;

          const model = { ...valueFilter, searchText };
          newData = await getData(
            endpoint,
            model,
            sortField,
            sortFieldBy,
            pageSize,
            currentPage - 1,
            parameters
          );
          setDataOriginal(JSON.parse(JSON.stringify(newData.list)));
          setData(newData.list);
          setCount(newData.count);
        }
        setLoading(false);
        return newData;
      } catch (err) {
        setLoading(false);
        errorHandler(err);
      }
    },
    [
      endpoint,
      parameters,
      searchText,
      pageSize,
      currentPage,
      sortField,
      sortFieldBy,
    ]
  );

  useEffect(() => {
    pPipe(
      getConfiguration('PontoControleConfiguracao')(setConfig),
      (config) => config,
      _getDefaultColumns,
      (colunas) => {
        setDefaultColumns(colunas);
        return colunas;
      },
      loadData
    )();
  }, [
    loadData,
    pageSize,
    currentPage,
    searchText,
    sortField,
    sortFieldBy,
    selectedFilter,
  ]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  });

  const handleColumnsChange = useCallback(async () => {
    await loadColumns(defaultColumns);
    await loadData(defaultColumns);
  });

  const handleRefresh = useCallback(async (filter) => {
    await loadData(defaultColumns, filter);
  });

  const handleChangeCurrentPage = useCallback((newValue) => {
    setCurrentPage(newValue);
  });

  const handleChangePageSize = useCallback((pageSize) => {
    setPageSize(pageSize);
  });

  const handleSortColumn = useCallback((sort) => {
    setSortField(sort);
  });

  const handleSortByColumn = useCallback((sortBy) => {
    setSortFieldBy(sortBy);
  });

  const handleExport = async (fileExtension) => {
    try {
      let valueFilter = {};
      if (filter != null) valueFilter = filter.valor;

      let newSearchModel = {
        searchText,
        ...valueFilter,
      };

      if (count > 1000) {
        ContentManager.addContent(
          <ConfirmNew
            showCancel={true}
            primaryMessage={intl.formatMessage({
              id: 'msgLimiteRegistroExportacao',
            })}
            onSaved={async () => {
              setLoading(true);
              await exportList(
                newSearchModel,
                endpoint,
                sortField,
                currentPage,
                parameters,
                fileExtension,
                1000
              );

              setLoading(false);
            }}
          />
        );
      } else {
        setLoading(true);
        await exportList(
          newSearchModel,
          endpoint,
          sortField,
          currentPage,
          parameters,
          fileExtension
        );
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      errorHandler(ParseError(err));
    }
  };

  const renderRisco = (item) => {
    return (
      <div style={{ maxHeight: '100px' }}>
        <a tabindex="-1" onClick={() => handleAbrirRiscoClick(item)}>
          {item.nomeRisco}
        </a>
      </div>
    );
  };

  const renderNomePontoControle = (item) => {
    if (!utilizarClickRenderNomePontoControle)
      return (
        <div style={{ maxHeight: '100px' }}>
          <span>{item.descricaoPontoControle}</span>
        </div>
      );

    return (
      <div style={{ maxHeight: '100px' }}>
        <a tabindex="-1" onClick={() => handleAbrirPontoControleClick(item)}>
          {item.descricaoPontoControle}
        </a>
      </div>
    );
  };

  const renderDataRevisao = (item) => {
    if (!item.dataRevisao) return;

    return (
      <a tabindex="-1" onClick={() => handleDataClick(item)}>
        {moment(item.dataRevisao).format('DD/MM/YYYY')}
      </a>
    );
  };

  const renderConformidade = (item) => {
    var id = `id${item.id}`;
    var idPlanoAcao = `idPlanoAcao${item.id}`;

    return (
      <Row>
        <Col md={12}>
          <Box display="flex" alignItems="center">
            <div style={{ width: '130px' }}>
              <Radio
                name={id}
                model={{
                  value: item.conformidade,
                  requestChange: (value) =>
                    handleChangeItem(item, 'conformidade', value, true),
                }}
                options={item.opcoesConformidade}
                labelField="descricao"
                idField="id"
              />
            </div>
            <div style={{ width: '25px' }}>
              {item.conformidade && item.conformidade.id === 2 && (
                <Box>
                  <MdiIcon
                    id={idPlanoAcao}
                    size={20}
                    style={{ marginTop: -30 }}
                    className="ml-1 cursor-pointer"
                    icon="chart-gantt"
                    onClick={() => handlePlanoAcaoClick(item)}
                  />
                  <UncontrolledTooltip placement="top" target={idPlanoAcao}>
                    {capitalizeFirstLetter(resources.planodeacao)}
                  </UncontrolledTooltip>
                </Box>
              )}
            </div>
          </Box>
        </Col>
      </Row>
    );
  };

  const renderAuditado = (item) => {
    if (!item.temPermissaoAuditoria) return null;

    var idRevisao = `idRevisao${item.id}`;

    return (
      <div>
        <div id={idRevisao}>
          <Checkbox
            style={{ paddingBottom: 50 }}
            cursor={!item.podeEditarAuditado ? 'not-allowed' : ''}
            model={{
              value: item && item.auditado,
              requestChange: !item.podeEditarAuditado
                ? undefined
                : (value) => handleChangeItem(item, 'auditado', value),
            }}
          />
        </div>
        {item.status.id !== STATUS_PONTO_CONTROLE_REVISAO.CONCLUIDO && (
          <UncontrolledTooltip
            hideArrow={true}
            placement="left"
            target={idRevisao}
            style={{
              position: 'absolute',
              top: -15,
              right: 42,
              width: 145,
            }}
          >
            {intl.formatMessage({ id: 'revisaoStatusConcluido' })}
          </UncontrolledTooltip>
        )}
      </div>
    );
  };

  const renderAnexo = (item) => {
    return (
      <ArquivoUploadAnexos
        formGroupStyle={{ paddingTop: 10, marginBottom: 5 }}
        tipoAnexo={tiposAnexos.riscoPontoControleRevisao}
        anexoObrigatorio={item.anexoObrigatorio}
        model={{
          value: item.arquivos,
          requestChange: (arquivos) =>
            handleChangeAnexo(item, 'arquivos', arquivos),
        }}
      />
    );
  };

  const renderFarol = (item) => {
    return (
      <span title={item.farol?.descricao}>
        {item.farol && item.farol.cor && (
          <>
            <MdiIcon
              icon={item.farol.icone}
              color={item.farol.cor}
              colorHover={item.farol.cor}
              size={22}
            ></MdiIcon>
          </>
        )}
      </span>
    );
  };

  const renderComentario = (item) => {
    return showTitle ? (
      <Input
        style={{ marginTop: 10 }}
        rows={3}
        type="textarea"
        model={{
          value: item.comentario,
          requestChange: (value) => handleChangeItem(item, 'comentario', value),
        }}
      />
    ) : (
      <Row style={{ maxHeight: '100px' }}>
        <Col md={12}>
          <Box display="flex" alignItems="center">
            {item.comentario}
          </Box>
        </Col>
      </Row>
    );
  };

  const renderEfetividadeControle = (item) => {
    const filtro = {
      ignorarCorretivo: !item.controleCorretivo,
      ignorarPreventivo: !item.controlePreventivo,
    };

    return (
      <DropdownEfetividade
        resources={resources}
        disabled={
          item.status &&
          item.status.id === STATUS_PONTO_CONTROLE_REVISAO.CONCLUIDO
        }
        showErrorMessage={!item.funcaoControlePreenchida}
        filtro={item.funcaoControlePreenchida ? filtro : null}
        model={{
          value: item.efetividadeControle,
          onChange: (value) =>
            handleChangeItem(item, 'efetividadeControle', value),
        }}
      />
    );
  };

  const renderImpactosIndiretos = (item) => {
    return (
      <Box className="pt-3" style={{ maxHeight: '100px' }}>
        {item.impactosIndiretos &&
          item.impactosIndiretos.map((itemImpacto, i) => (
            <Box>
              <Hint item={item} itemImpacto={itemImpacto} index={i} />
            </Box>
          ))}
      </Box>
    );
  };

  const setNewUpdatedItem = (item, field, valor) => {
    if (!item) return;

    setUpdatedItems((prev) => {
      let newArray = [...prev];
      let currentIndex = newArray.findIndex((i) => i.id === item.id);
      if (currentIndex < 0) {
        newArray.push(item);
      } else {
        if (
          field != null &&
          field === 'concluirRevisao' &&
          !valor &&
          !item.editado
        )
          //remover item, caso usuário tenha clicado no checkbox duas vezes
          newArray = newArray.filter((i) => i.id !== item.id);
        else newArray[currentIndex] = item;
      }
      return newArray;
    });
  };

  const handleChangeItem = async (item, field, valor, radioButton) => {
    try {
      if (radioButton)
        item[field] =
          item[field] && valor && item[field].id === valor.id
            ? { id: -1, descricao: intl.formatMessage({ id: 'naoInformado' }) }
            : valor;
      else item[field] = valor;
      item.editado = true;
      setNewUpdatedItem(item, field, valor);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleChangeAnexo = async (item, field, arquivos) => {
    try {
      await http.post(`${endpoint}/SalvarArquivos`, {
        id: item.id,
        arquivos: arquivos,
      });
      //await loadData(defaultColumns); retirado pelo atendimento GDR-594
      setUpdatedItems((prev) => {
        let newArray = [...prev];
        item[field] = arquivos;
        newArray.push(item);
        return newArray;
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleDataClick = (item) => {
    try {
      ContentManager.addContent(
        <RevisaoEdit
          handleCallBack={() => loadData(defaultColumns)}
          item={item}
          parameters={{
            id: item.id,
            idRiscoPontoControle: item.idRiscoPontoControle,
          }}
        ></RevisaoEdit>
      );
    } catch (err) {
      return '';
    }
  };

  const handleAbrirPontoControleClick = (item) => {
    ContentManager.addContent(
      <PontoControleEdit
        item={item.pontoControle}
        parameters={{ id: item.pontoControle.id }}
        afterSave={() => loadData(defaultColumns)}
      ></PontoControleEdit>
    );
  };

  const handleAbrirRiscoClick = (item) => {
    if (!item) return;

    ContentManager.addContent(
      <RiscoEditView
        mode="edit"
        handleClose={handleRefresh}
        item={item.risco}
        {...rest}
      />
    );
  };

  const handlePlanoAcaoClick = (item) => {
    ContentManager.addContent(<PlanoAcaoModal model={item} />);
  };

  const handleSaveClick = async () => {
    setLoading(true);

    try {
      setUpdatedItems([]);

      await http.post(`${endpoint}/SalvarRevisoesPendentes`, {
        model: updatedItems,
      });

      ContentManager.addContent(
        <Alert message={intl.formatMessage({ id: 'revisaoSalvaSucesso' })} />
      );

      await loadData(defaultColumns);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
    setUpdatedItems((prevItems) => []);
  };

  const renderConcluirRevisao = (item) => {
    return (
      <Checkbox
        disabled={
          item.status &&
          (item.status.id === STATUS_PONTO_CONTROLE_REVISAO.CONCLUIDO ||
            item.status.id === STATUS_PONTO_CONTROLE_REVISAO.EM_VALIDACAO)
        }
        style={{ paddingTop: 10, marginBottom: 50, textAlign: 'center' }}
        model={{
          value: item && item.concluirRevisao,
          requestChange: (value) =>
            handleChangeItem(item, 'concluirRevisao', value),
        }}
      />
    );
  };

  /**
   * @returns set useState de colunas e retorna o valor inserido, necessário pois o set do useState é assíncrono.
   */

  const refreshChangeItens = useEffect(() => {
    const itensAlterados = data.filter((x) => x.editado);
    itensAlterados.forEach((item) => {
      const itemOriginal = dataOriginal.find((x) => x.id == item.id);
      if (itemOriginal) itemOriginal.editado = true;

      const alterado =
        !itemOriginal || JSON.stringify(itemOriginal) !== JSON.stringify(item);
      let newArray = [...updatedItems];
      const currentIndex = newArray.findIndex((i) => i.id === item.id);

      if ((alterado && currentIndex < 0) || (!alterado && currentIndex >= 0)) {
        setUpdatedItems(() => {
          if (currentIndex < 0 && alterado) {
            newArray.push(item);
          } else {
            if (!alterado) {
              item.editado = false;
              newArray = newArray.filter((i) => i.id !== item.id);
            } else if (
              field != null &&
              field === 'concluirRevisao' &&
              !valor &&
              !item.editado
            )
              //remover item, caso usuário tenha clicado no checkbox duas vezes
              newArray = newArray.filter((i) => i.id !== item.id);
            else newArray[currentIndex] = item;
          }
          return newArray;
        });
      }
    });
  }, [data, updatedItems]);

  const _getDefaultColumns = useCallback((config) => {
    const colunas = [
      <ListColumn
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'idIntegracao' })
        )}
        minWidth={100}
        default
        valueField="idIntegracao"
        sortField="UltimaVersao.IdIntegracao"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(resources.risco)}
        valueField="risco"
        valueFunction={renderRisco}
        minWidth={150}
        default
        sortField="RiscoPontoControle.Risco.UltimaVersao.Nome"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.itensDeImpactoIndireto' })
        )}
        valueField="impactosIndiretos"
        minWidth={150}
        valueFunction={renderImpactosIndiretos}
        default
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'labelCodigoImportacaoPontoControle' },
            { pontocontrole: resources.pontodecontrole }
          )
        )}
        valueField="idIntegracaoPontoControle"
        default
        sortField="RiscoPontoControle.PontoControle.IdIntegracao"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'descricao' })
        )}
        valueField="descricaoPontoControle"
        valueFunction={renderNomePontoControle}
        minWidth={200}
        default
        sortField="RiscoPontoControle.PontoControle.Descricao"
      />,
      <ListColumn
        visible={true}
        minWidth={130}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.dataRevisao' })
        )}
        valueField="dataRevisao"
        valueFunction={renderDataRevisao}
        sortField="UltimaVersao.DataRevisao"
        required
      />,
      <ListColumn
        visible={true}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.responsavel' })
        )}
        valueField="responsavelRevisao"
        default
        sortField="UltimaVersao.ResponsavelRevisao.Nome"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'periodicidade' })
        )}
        valueField="periodicidadePontoControle"
        default
        sortField="RiscoPontoControle.PontoControle.Periodicidade"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'tipoPontoControle' },
            { pontodecontrole: resources.pontodecontrole }
          )
        )}
        valueField="tipoPontoControle"
        sortField="RiscoPontoControle.PontoControle.TipoPontoControle"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'tolerancia' })
        )}
        valueField="diasToleranciaRevisao"
        sortField="RiscoPontoControle.PontoControle.DiasToleranciaRevisao"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'tipoTolerancia' })
        )}
        valueField="tipoTolerancia"
        sortField="RiscoPontoControle.PontoControle.TipoTolerancia"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'validacoesPendentes' })
        )}
        valueField="validadoresPendentes"
        default
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'observacao' })
        )}
        valueField="observacaoPontoControle"
        maxHeight={100}
        minWidth={250}
      />,
      <ListColumn
        visible={true}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.comentario' })
        )}
        valueField="comentario"
        valueFunction={renderComentario}
        default
        sortField="UltimaVersao.Descricao"
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'conformidade' })
        )}
        valueField="conformidade"
        valueFunction={renderConformidade}
        sortField="UltimaVersao.Conformidade"
        required
      />,
      <ListColumn
        visible={true}
        headerText={capitalizeFirstLetter(resources.farol)}
        valueField="farol"
        valueFunction={renderFarol}
        default
        sortField="UltimaVersao.Farol"
      />,
      <ListColumn
        visible={true}
        headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'status' }))}
        valueField="statusRevisao"
        minWidth={100}
        default
      />,
      <ListColumn
        visible={!simplifyed}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'tipoEvidencias' })
        )}
        valueField="tipoEvidencias"
      />,
      <ListColumn
        visible={true}
        headerText={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'descricaoRisco' },
            { risco: resources.risco }
          )
        )}
        valueField="descricaoRisco"
        default
        sortField="RiscoPontoControle.Risco.UltimaVersao.Descricao"
        minWidth={200}
      />,
      <ListColumn
        visible={
          !simplifyed && config.permitirAvaliarEfetividadeRevisaoControle
        }
        minWidth={220}
        headerText={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'efetividadeControle' },
            { pontodecontrole: resources.pontodecontrole }
          )
        )}
        valueField="efetividadeControle"
        valueFunction={(item) => renderEfetividadeControle(item)}
      />,
      <ListColumn
        visible={true}
        headerText={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'areasDoRisco' },
            { areas: resources.areas, risco: resources.risco }
          )
        )}
        valueField="areaRisco"
        sortField="RiscoPontoControle.Risco.UltimaVersao.Area.Nome"
      />,
      <ListColumn
        visible={true}
        headerText={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'areasDoControle' },
            {
              areas: resources.areas,
              pontodecontrole: resources.pontodecontrole,
            }
          )
        )}
        valueField="areaControle"
        sortField="RiscoPontoControle.PontoControle.Area.Nome"
      />,
      <ListColumn
        visible={true}
        headerText={capitalizeFirstLetter(
          intl.formatMessage({ id: 'concluirRevisao' })
        )}
        valueFunction={renderConcluirRevisao}
        valueField="concluirRevisao"
        required
      />,
    ];

    if (config && config.utilizarAuditoria)
      colunas.push(
        <ListColumn
          visible={exibirAuditado || !simplifyed}
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'auditado' })
          )}
          valueField="auditado"
          default
          valueFunction={renderAuditado}
          sortField="UltimaVersao.Auditado"
        />
      );

    if (config && config.anexoObrigatorio)
      colunas.push(
        <ListColumn
          visible
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'label.anexo' })
          )}
          valueField="anexos"
          valueFunction={renderAnexo}
          default
        />
      );

    if (config && config.funcaoPontoControle)
      colunas.push(
        <ListColumn
          visible={config.funcaoPontoControle}
          headerText={capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'funcaoPontoControle' },
              { pontodecontrole: resources.pontodecontrole }
            )
          )}
          valueField="funcaoPontoControle"
          sortField="FuncaoPontoControle"
        />
      );

    if (config && config.tipoDeControle)
      colunas.push(
        <ListColumn
          visible={config.tipoDeControle}
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'tipoDeControle' })
          )}
          valueField="tipoDeControle"
          sortField="TipoDeControle"
        />
      );
    return colunas;
  });

  const handleNewClick = () => {
    ContentManager.addContent(
      <RiscoPontoControleRevisaoEdit
        mode="edit"
        handleClose={handleRefresh}
        parameters={{
          ...parameters,
          idRiscoPontoControle: idRiscoPontoControle,
        }}
      />
    );
  };

  const actions = [
    <Button key={1} className="ml-2" onClick={handleSaveClick} type="primary">
      <MdiIcon icon="content-save-outline" />
      {updatedItems.length > 0 && (
        <span className="ml-2">{updatedItems.length}</span>
      )}
    </Button>,
  ];

  const content = (
    <LoadingContainer isLoading={isLoading}>
      {showToolbar && (
        <Toolbar
          useSavedFilter
          showSearch
          showFastSearch={showFastSearch}
          showExport
          onExport={handleExport}
          onColumnsChange={handleColumnsChange}
          onSearchChange={handleSearchChange}
          onRefresh={handleRefresh}
          searchText={searchText}
          searchSchema={SearchSchema}
          searchComponent={
            <Search
              config={configuration}
              hideControlesFilter={hideControlesFilter}
            />
          }
          filter={filter}
          filterTag={filterTag}
          columnsTag={columnsTag}
          columns={columns}
          allColumns={allColumns}
          actions={actions}
          tipoItem={ENTIDADES_CUSTOMIZADAS.PONTO_CONTROLE_REVISAO}
          ignorarCamposCustomizados={false}
          showNew={showNew}
          handleNewClick={handleNewClick}
          permissions={{ allowCreate: true }}
          dateFilterFormat={dateFilterFormat}
        />
      )}
      <Results
        showCustomFields={true}
        resources={resources}
        count={count}
        columns={columns}
        data={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangeCurrentPage={handleChangeCurrentPage}
        onChangePageSize={handleChangePageSize}
        onSortColumn={handleSortColumn}
        onSortByColumn={handleSortByColumn}
        sort={sortField}
        sortBy={sortFieldBy}
        updatedItems={updatedItems}
        url={'/RiscoPontoControleRevisao'}
        handleEditClick={handleDataClick}
        onRefresh={handleRefresh}
        idLabelEditar={'acessarRevisao'}
        idLabelExcluir={'excluirRevisao'}
        showMenu
        sticky={true}
      />
      <Legenda tipoFarol={2} />
    </LoadingContainer>
  );

  return (
    <React.Fragment>
      {showTitle && !utilizarTituloRevisoesPendentes ? (
        <Content
          titulo={intl.formatMessage(
            { id: 'revisoesDePontoControle' },
            { revisoesdecontrole: resources.revisoesdecontrole }
          )}
        >
          {content}
        </Content>
      ) : showTitle && utilizarTituloRevisoesPendentes ? (
        <Content titulo={intl.formatMessage({ id: 'revisoesPendentes' })}>
          {content}
        </Content>
      ) : (
        <Content>{content}</Content>
      )}
    </React.Fragment>
  );
}

ListRevisaoPontoControle.defaultProps = {
  filterTag: FILTROS.REVISOES_PONTO_CONTROLE,
  columnsTag: COLUNAS.REVISOES_PONTO_CONTROLE,
  endpoint: '/RiscoPontoControleRevisao',
  entityTag: ENTIDADES_CUSTOMIZADAS.PONTO_CONTROLE_REVISAO,
  defaultSort: 'UltimaVersao.DataRevisao',
  defaultSortBy: 'asc',
  showToolbar: true,
  showTitle: true,
};
export default ListRevisaoPontoControle;
