import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';
import DatePicker from 'src/componentes/date-picker';
import EfetividadeLookup from 'src/lookups/efetividade-controle';
import Schema from './Schema';
import Edit from 'src/componentes/edit/edit-dialog';
import useAuth from 'src/hooks/useAuth';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import Input from 'src/componentes/input';
import { Fragment } from 'react';

function FormEdit({ model, configuracaoControle, funcaoControle, disabled }) {
  const isDisabled = disabled ? true : model.getModel('permissions').value ? !model.getModel('permissions').value.allowEdit : false;
  const { configurations: tiposAnexos, terms: resources } = useAuth();
  const intl = useIntl();

  return (
    <>
      <Row>
        <Col md={4}>
          <DatePicker className="pt-1" required timeFormat={false} disabled={isDisabled} model={model.getModel('dataAnalise')} />
        </Col>
        {!configuracaoControle.avaliarEfetividadeControleSeparadamente ? (
          <Col md={4}>
            <EfetividadeLookup
              required
              clearable
              model={{
                label: intl.formatMessage({ id: 'efetividadeControle' }, { pontodecontrole: resources.pontodecontrole }),
                value: model.getModel('efetividadeControle').value,
                requestChange: model.getModel('efetividadeControle').requestChange,
              }}
              disabled={isDisabled}
            />
          </Col>
        ) : (
          <Fragment>
            {funcaoControle?.id === 2 && (
              <Col md={3}>
                <EfetividadeLookup
                  required
                  clearable
                  model={{
                    label: intl.formatMessage({ id: 'efetividadeControle' }, { pontodecontrole: resources.pontodecontrole }),
                    value: model.getModel('efetividadeControleCorretivo').value,
                    requestChange: model.getModel('efetividadeControleCorretivo').requestChange,
                  }}
                  data={{ ignorarPreventivo: true }}
                  disabled={isDisabled}
                />
              </Col>
            )}
            {funcaoControle?.id === 1 && (
              <Col md={3}>
                <EfetividadeLookup
                  required
                  clearable
                  model={{
                    label: intl.formatMessage({ id: 'efetividadeControle' }, { pontodecontrole: resources.pontodecontrole }),
                    value: model.getModel('efetividadeControlePreventivo').value,
                    requestChange: model.getModel('efetividadeControlePreventivo').requestChange,
                  }}
                  data={{ ignorarCorretivo: true }}
                  disabled={isDisabled}
                />
              </Col>
            )}
          </Fragment>
        )}
        <Col>
          <ArquivoUploadAnexos
            formGroupStyle={{ paddingTop: 10, marginBottom: 5 }}
            tipoAnexo={tiposAnexos.riscoPontoControleEfetividade}
            formGroupStyle={{ marginBottom: 0 }}
            model={model.getModel('arquivos')}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input disabled={isDisabled} rows={5} type="textarea" model={model.getModel('observacao')} />
        </Col>
      </Row>
    </>
  );
}

function EfetividadeControleEdit({ ...rest }) {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  return (
    <Edit
      url="/RiscoPontoControleEfetividade"
      title={intl.formatMessage({ id: 'efetividadeControle' }, { pontodecontrole: resources.pontodecontrole })}
      formComponent={FormEdit}
      schema={Schema}
      width="60%"
      minHeight="30%"
      maxHeight="60%"
      afterSave={rest.handleClose}
      {...rest}
    ></Edit>
  );
}

export default EfetividadeControleEdit;
