import React, { Suspense, lazy, useState, useEffect } from 'react';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';

import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import StatusReuniaoLookup from 'src/lookups/status-reuniao';
import RecorrenciaLookup from 'src/lookups/tipo-periodicidade-ponto-controle';
import PeriodicidadeLookup from 'src/lookups/periodicidade';
import TipoToleranciaLookup from 'src/lookups/tipo-tolerancia';
import TagLookup from 'src/lookups/TagLookup';
import Edit from 'src/componentes/edit/edit-dialog';
import EditPage from 'src/componentes/edit/edit-page';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import Panel from 'src/componentes/panel/index';
import MdiIcon from 'src/componentes/mdi-icon';
import Input from 'src/componentes/input';
import DatePicker from 'src/componentes/date-picker';
import Checkbox from 'src/componentes/checkbox';
import InputNumber from 'src/componentes/input-number';
import ContentManager from 'src/componentes/content-manager';
import Alert from 'src/componentes/message-box/alert';
import Email from 'src/componentes/email';
import { capitalizeFirstLetter } from 'src/utils/string';
import { Schema } from 'src/utils/form';
import errorHandler from 'src/utils/error-handler';
import AcaoList from 'src/paginas/acao/select-list';
import ColaboradorReuniaoList from 'src/paginas/reuniao/colaborador-reuniao';
import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import LoadingScreen from 'src/componentes/LoadingScreen';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import ApresentacaoLookup from 'src/lookups/apresentacao';
import Orientacao from 'src/componentes/orientation-confim';
import { setPresentation } from 'src/actions/presentation';
import DateList from './DateList';
import http from 'src/services/httpService';
import SelectMeeting from 'src/components/integracoes/microsoft/outlook/components/selectMeeting';

const ReactQuillComponent = lazy(() => import('src/componentes/quill'));

let schema = Schema.object({
  id: Schema.number().label(<FormattedMessage id="label.id" />),
  statusReuniao: Schema.string()
    .label(<FormattedMessage id="status" />)
    .required(),
  assunto: Schema.string()
    .label(<FormattedMessage id="assunto" />)
    .required(),
  dataInicioItem: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTerminoItem: Schema.string().label(<FormattedMessage id="dataTermino" />),
  localReuniao: Schema.string().label(<FormattedMessage id="local" />),
  responsaveis: Schema.array()
    .label(<FormattedMessage id="responsaveis" />)
    .required(),
  enviarEmail: Schema.string().label(
    <FormattedMessage id="label.enviarNotificacaoTambemPorEmail" />
  ),
  alerta: Schema.string().label(<FormattedMessage id="alerta" />),
  ata: Schema.string().label(<FormattedMessage id="label.ata" />),
  pauta: Schema.string().label(<FormattedMessage id="pauta" />),
  colaboradores: Schema.array().label(
    <FormattedMessage id="label.participantes" />
  ),
  ultimaData: Schema.string().label(<FormattedMessage id="label.ultimaData" />),
  repeticaoReuniao: Schema.string().label(
    <FormattedMessage id="label.repeticao" />
  ),
  frequenciaReuniao: Schema.string().label(
    <FormattedMessage id="label.frequencia" />
  ),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="codigo" />)
    .required({
      onlyIf: (context) => {
        return (
          !context.parent.value.utilizarCodigoAutomatico ||
          (context.parent.value.utilizarCodigoAutomatico &&
            context.parent.value.permitirAlterarCodigo &&
            !context.value &&
            context.parent.value.id != 0)
        );
      },
    }),
  diasToleranciaRevisao: Schema.string().label(
    <FormattedMessage id="tolerancia" />
  ),
  tipoTolerancia: Schema.string().label(),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  numeroOcorrencias: Schema.string().label(
    <FormattedMessage id="label.numeroDeOcorrencias" />
  ),
  apresentacao: Schema.string().label(<FormattedMessage id="apresentacao" />),
});

let disabled;

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracoes: [],
      utilizarRecorrencia: false,
      recorrenciaId: 4,
      ata:
        this.props.model.getModel('ata') &&
        this.props.model.getModel('ata').value,
      pauta:
        this.props.model.getModel('pauta') &&
        this.props.model.getModel('pauta').value,
      isPresentation: false,
      isTeamsMeetingChecked: false,
      teamsPermissionDisabled: false,
    };

    http.get(`/ItemConfiguracaoReuniao/ObterConfiguracoes`).then((response) => {
      this.setState({
        configuracoes: response.data,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // pega o status da reunião ao carregar o componente
    if (this.state.statusOriginal == null) {
      const statusCarregado = this.props.model
        .getModel('statusReuniao')
        ?.value?.id;
      if (statusCarregado != null) {
        this.setState({ statusOriginal: statusCarregado });
      }
    }
  }

  handleTeamsPermissionDisabled = (value) => {
    this.setState({ teamsPermissionDisabled: value });
  };

  handleEventSelect = (event) => {
    if (this.state.isTeamsMeetingChecked) {
      if (event) {
        this.updateFormFields(event);
      } else {
        this.clearFormFields();
      }
    }
  };

  updateFormFields = (event) => {
    if (!event) return;
    const { model } = this.props;

    model.getModel('assunto').requestChange(event.subject);
    model
      .getModel('dataInicioItem')
      .requestChange(event.start.formattedDateTime);
    model
      .getModel('dataTerminoItem')
      .requestChange(event.end.formattedDateTime);
    model
      .getModel('localReuniao')
      .requestChange(
        event.onlineMeeting?.joinUrl || event.location.displayName
      );
  };

  clearFormFields = () => {
    const { model } = this.props;
    model.getModel('assunto').requestChange('');
    model.getModel('localReuniao').requestChange('');
  };

  handleEdit() {
    this.props.onChangeMode && this.props.onChangeMode('edit');
  }

  handleClose() {
    this.props.onRequestClose && this.props.onRequestClose();
  }

  handleEditorAtaChange(e) {
    this.props.model.getModel('ata').requestChange(e);
  }

  handleEditorPautaChange(value) {
    this.props.model.getModel('pauta').requestChange(value);
  }

  handleChangeUtilizarRecorrencia(value) {
    let { model } = this.props;
    model.getModel('utilizarRecorrencia').requestChange(value);

    this.setState({ utilizarRecorrencia: value });
  }

  handleChangeRecorrencia(value) {
    let { model } = this.props;
    model.getModel('recorrencia').requestChange(value);

    if (!value) {
      this.props.model.getModel('diasToleranciaRevisao').requestChange(null);
      this.props.model.getModel('tipoTolerancia').requestChange(null);
    }

    this.setState({ recorrenciaId: value.id });
  }

  handleTeamsCheckboxChange = (checked, clear = true) => {
    this.setState({ isTeamsMeetingChecked: checked });
    this.props.model.getModel('enviarEmail').requestChange(!checked);
    if (clear) {
      this.clearFormFields();
    }
  };

  handleEmailClick() {
    let emails =
      this.props.model.getModel('colaboradores').value &&
      this.props.model.getModel('colaboradores').value.map(function (v) {
        let colaboradorEmail = v.colaborador.email;
        return { colaboradorEmail };
      });

    ContentManager.addContent(
      <Email
        item={{ id: this.props.model.getModel('id').value }}
        emails={
          emails && emails.filter((email) => email.colaboradorEmail != null)
        }
        htmlString={this.props.model.getModel('ata').value}
        urlExport="Reuniao/EnviarAtaPorEmail"
      />
    );
  }

  printAta() {
    http
      .post(
        `Reuniao/ExportarAtaReuniao`,
        {
          htmlString: this.props.model.getModel('ata').value,
          id: this.props.model.getModel('id').value,
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        DownloadFile(response);
      })
      .catch((error) => {
        errorHandler(ParseError(error));
      });
  }

  handleConfirmou(item) {
    if (item.confirmou) {
      return (
        <MdiIcon icon="checkbox-marked-outline" size={20} color={'#1A237E'} />
      );
    } else {
      return <MdiIcon icon="close-box-outline" size={20} color={'#1A237E'} />;
    }
  }

  handleApresentarClick() {
    const { model } = this.props;
    const itemId = model.getModel('apresentacao')?.value?.id;
    const reuniaoId = model.getModel('id') && model.getModel('id').value;

    window.location.href = `/acompanhar/apresentacao?id=${itemId}&ehGestaoAVista=${false}&reuniaoId=${reuniaoId}`;
  }

  handlePreExport(apresentacao) {
    ContentManager.addContent(
      <Orientacao handleConfirm={this.handleExport.bind(this, apresentacao)} />
    );
  }

  handleExport(apresentacao, orientacaoId) {
    apresentacao && this.props.setPresentation(apresentacao.id);

    ContentManager.addContent(
      <Alert
        message={this.props.intl.formatMessage({ id: 'downloadEmBreve' })}
      />
    );
    http
      .post(
        `/Apresentacao/GerarRelatorio`,
        {
          id: apresentacao.id,
          orientacaoId: orientacaoId,
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        DownloadFile(response);
      })
      .catch((error) => {
        errorHandler(ParseError(error));
      });
  }

  handleAutomaticSave() {
    if (this.props.isPresentation) {
      this.props.toggleAutomaticSave();
    }
  }

  handleCheckboxChange(value) {
    this.props.model.getModel('exibirAcoesRecorrencia').requestChange(value);
    this.handleAutomaticSave();
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  render() {
    let { model, intl, resources, isPresentation } = this.props;

    let ata = model.getModel('ata').value;
    let pauta = model.getModel('pauta').value;
    let dataReuniao = model.getModel('dataInicioItem').value;
    let podeEditarAta = model.getModel('allowEditMinute').value;

    //se pauta ou ata estiverem vazias tem que preencher
    if (pauta == null) {
      pauta = ' ';
    }
    if (ata == null) {
      ata = ' ';
    }

    let reuniaoId = model.getModel('id') && model.getModel('id').value;
    let detail = this.props.mode && this.props.mode == 'detail';

    // desabilitar os campos da reunião conforme status
    const statusOriginal = this.state.statusOriginal;
    let idStatusReuniao = false;
    if (reuniaoId !== 0 && (statusOriginal === 1 || statusOriginal === 2)) {
      idStatusReuniao = true;
    }
    const permissions = model.getModel('permissions').value
      ? !this.props.model.getModel('permissions').getModel('allowEdit').value
      : false;
    disabled = isPresentation || permissions;

    const configuracoes = this.state.configuracoes;
    const bloquearCodigoAutomatico =
      configuracoes &&
      configuracoes.permitirAlterarCodigoAutomatico == false &&
      configuracoes.utilizarCodigoAutomatico == true
        ? true
        : false;
    const isIdRequired =
      (configuracoes && !configuracoes.utilizarCodigoAutomatico) ||
      (configuracoes.utilizarCodigoAutomatico &&
        configuracoes.permitirAlterarCodigoAutomatico &&
        reuniaoId != 0);
    const teamsPermissionDisabled = this.state.teamsPermissionDisabled;

    return (
      <Suspense fallback={<LoadingScreen />}>
        <Tabs defaultActiveKey="1">
          <Tab key="1" label={<FormattedMessage id="label.resumo" />}>
            <Panel header={<FormattedMessage id="label.dadosBasicos" />} className='reuniao-step2'>
              <Row>
                <Col>
                  <SelectMeeting
                    disabled={disabled}
                    isTeamsMeetingChecked={this.state.isTeamsMeetingChecked}
                    onCheckboxChange={this.handleTeamsCheckboxChange}
                    onEventSelect={this.handleEventSelect}
                    model={model}
                    handleTeamsPermissionDisabled={
                      this.handleTeamsPermissionDisabled
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Input
                    required={isIdRequired}
                    disabled={
                      bloquearCodigoAutomatico || idStatusReuniao || disabled
                    }
                    model={model.getModel('idIntegracao')}
                  />
                </Col>
                <Col md={5}>
                  <ItemOrganizacaoLookup
                    clearable
                    data={{ tiposIdSelecionaveis: [1, 2, 3] }}
                    disabled={idStatusReuniao || disabled}
                    model={{
                      label: capitalizeFirstLetter(resources.area),
                      value: model.getModel('area').value,
                      requestChange: model.getModel('area').requestChange,
                      errors: model.getModel('area').errors,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TagLookup
                    multi
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'tags' },
                          { tags: resources.tags }
                        )
                      ),
                      value: model.getModel('tags').value,
                      requestChange: model.getModel('tags').requestChange,
                    }}
                    data={{ funcionalidadesIdSelecionaveis: [11] }}
                    podeEditar={false}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Input
                        autoFocus
                        model={model.getModel('assunto')}
                        disabled={
                          idStatusReuniao || disabled || teamsPermissionDisabled
                        }
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        model={model.getModel('localReuniao')}
                        disabled={
                          idStatusReuniao || disabled || teamsPermissionDisabled
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <DatePicker
                        timeFormat
                        autoFormatDate={true}
                        placeholder={intl.formatMessage({
                          id: 'label.selecioneAHora',
                        })}
                        model={model.getModel('dataInicioItem')}
                        disabled={
                          idStatusReuniao || disabled || teamsPermissionDisabled
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <DatePicker
                        timeFormat
                        autoFormatDate={true}
                        placeholder={intl.formatMessage({
                          id: 'label.selecioneAHora',
                        })}
                        model={model.getModel('dataTerminoItem')}
                        disabled={
                          idStatusReuniao || disabled || teamsPermissionDisabled
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <StatusReuniaoLookup
                        model={model.getModel('statusReuniao')}
                        disabled={idStatusReuniao || disabled}
                      />
                    </Col>
                    {reuniaoId === 0 && !this.state.isTeamsMeetingChecked && (
                      <Col md={3}>
                        <Checkbox
                          className='reuniao-step3'
                          style={{ marginTop: 35 }}
                          model={{
                            label: <FormattedMessage id="utilizaRecorrencia" />,
                            value: model.getModel('utilizaRecorrencia').value,
                            requestChange: this.handleChangeUtilizarRecorrencia,
                          }}
                          disabled={disabled}
                        />
                      </Col>
                    )}
                  </Row>

                  {reuniaoId === 0 && this.state.utilizarRecorrencia && (
                    <Row>
                      {model.getModel('recorrencia').value &&
                        this.state.recorrenciaId != 11 && (
                          <Col md={3}>
                            <InputNumber
                              model={model.getModel('numeroOcorrencias')}
                            />
                          </Col>
                        )}

                      <Col md={3}>
                        <RecorrenciaLookup
                          clearable
                          model={{
                            label: (
                              <FormattedMessage id="recorrenciasPeriodicidade" />
                            ),
                            value: model.getModel('recorrencia').value,
                            requestChange: this.handleChangeRecorrencia,
                          }}
                        />
                      </Col>
                      {model.getModel('recorrencia').value &&
                        this.state.recorrenciaId != 1 &&
                        this.state.recorrenciaId != 10 &&
                        this.state.recorrenciaId != 11 && (
                          <Col md={1}>
                            <InputNumber
                              model={model.getModel('diasToleranciaRevisao')}
                            />
                          </Col>
                        )}
                      {model.getModel('recorrencia').value &&
                        this.state.recorrenciaId != 10 &&
                        this.state.recorrenciaId != 11 && (
                          <Col md={4}>
                            <TipoToleranciaLookup
                              clearable={false}
                              style={{ marginTop: 27 }}
                              model={model.getModel('tipoTolerancia')}
                            />
                          </Col>
                        )}
                      {this.state.recorrenciaId === 10 &&
                        this.state.recorrenciaId != 11 && (
                          <Col md={3}>
                            <PeriodicidadeLookup
                              model={{
                                label: <FormattedMessage id="periodicidade" />,
                                value: model.getModel('periodicidade').value,
                                requestChange:
                                  model.getModel('periodicidade').requestChange,
                              }}
                            />
                          </Col>
                        )}
                      {this.state.recorrenciaId === 11 && (
                        <Col md={6}>
                          <DateList
                            model={model.getModel(
                              'datasRecorrenciaPersonalizada'
                            )}
                            dataInicio={model.getModel('dataInicioItem').value}
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                  <Row>
                    <Col md={12}>
                      <ColaboradorSimplificadoLookup
                        multi
                        model={{
                          label: capitalizeFirstLetter(resources.responsaveis),
                          value: model.getModel('responsaveis').value,
                          requestChange:
                            model.getModel('responsaveis').requestChange,
                          errors: model.getModel('responsaveis').errors,
                        }}
                        disabled={idStatusReuniao || disabled}
                        required
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>

            <Panel header={<FormattedMessage id="label.participantes" />}>
              <ColaboradorReuniaoList
                model={model.getModel('colaboradores')}
                responsaveis={model.getModel('responsaveis')?.value}
                alterarAprovacaoAta={this.props.alterarAprovacaoAta}
                showNew={
                  disabled
                    ? false
                    : (detail ? false : true) && idStatusReuniao == 0
                }
                showMenu={disabled ? false : true}
                disableParticipou={idStatusReuniao || disabled}
                reuniaoId={reuniaoId}
              />

              {!this.state.isTeamsMeetingChecked && (
                <Checkbox
                  model={model.getModel('enviarEmail')}
                  disabled={idStatusReuniao || disabled}
                  className="mt-2"
                />
              )}
            </Panel>

            <Panel header={<FormattedMessage id="pauta" />} className='reuniao-step6'>
              {pauta && (
                <div className="text-editor">
                  <ReactQuillComponent
                    style={{ minHeight: 200 }}
                    value={pauta}
                    theme="snow"
                    formats={this.formats}
                    modules={this.modules}
                    onChange={this.handleEditorPautaChange}
                    readOnly={
                      idStatusReuniao == 0 ? (disabled ? true : false) : true
                    }
                  />
                </div>
              )}
            </Panel>

            {configuracoes?.utilizarVinculoApresentacao && !isPresentation && (
              <Panel header={<FormattedMessage id="apresentacao" />}>
                <Row className="align-items-center">
                  <Col md={6} className="d-flex align-items-center">
                    <ApresentacaoLookup
                      formGroupStyle={{ marginBottom: 0 }}
                      model={{
                        label: '',
                        value: model.getModel('apresentacao').value,
                        requestChange:
                          model.getModel('apresentacao').requestChange,
                        errors: model.getModel('apresentacao').errors,
                      }}
                    />
                  </Col>

                  {model.getModel('apresentacao')?.value?.id &&
                    model.getModel('id').value != 0 && (
                      <Col md={6}>
                        <a
                          onClick={this.handleApresentarClick}
                          tabIndex="-1"
                          disabled={disabled}
                        >
                          <MdiIcon icon="play" className="mr-2" />
                          <FormattedMessage id="label.apresentar" />
                        </a>
                      </Col>
                    )}

                  <div
                    style={{ position: 'absolute', top: 15, right: 15 }}
                    className="pull-right"
                  >
                    <UncontrolledDropdown
                      direction="left"
                      className="ml-2 d-inline"
                      key="list"
                    >
                      <DropdownToggle color="secondary">
                        <MdiIcon icon="dots-horizontal" />
                      </DropdownToggle>

                      <DropdownMenu>
                        <DropdownItem
                          eventkey="Drop2"
                          onClick={() =>
                            this.handlePreExport(
                              model.getModel('apresentacao').value
                            )
                          }
                        >
                          <FormattedMessage id="label.exportarPDF" />
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Row>
              </Panel>
            )}
          </Tab>
          {reuniaoId !== 0 && (
            <Tab key="2" label={<FormattedMessage id="label.ata" />}>
              {ata && (
                <div>
                  <Row>
                    <Col md={12}>
                      <div className="pull-right">
                        {capitalizeFirstLetter(
                          intl.formatMessage({ id: 'anexos' })
                        )}
                        <ArquivoUploadAnexos
                          tipoAnexo={this.props.tiposAnexos.item}
                          model={model.getModel('arquivos')}
                          disabled={
                            podeEditarAta == true
                              ? false
                              : idStatusReuniao == 0
                              ? disabled
                                ? true
                                : false
                              : true
                          }
                          handleAutomaticSave={this.handleAutomaticSave}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div
                        style={{ marginRight: '10px', marginTop: '5px' }}
                        className="pull-right"
                      >
                        <UncontrolledDropdown
                          direction="left"
                          className="ml-2 d-inline"
                          key="list"
                        >
                          <DropdownToggle color="default">
                            <MdiIcon icon="dots-horizontal" />
                          </DropdownToggle>
                          <DropdownMenu container="body">
                            <DropdownItem
                              eventkey="Drop1"
                              onClick={this.handleEmailClick}
                            >
                              <FormattedMessage id="enviarEmail" />
                            </DropdownItem>
                            <DropdownItem
                              eventkey="Drop2"
                              onClick={this.printAta}
                            >
                              <FormattedMessage id="label.exportarPDF" />
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>

                      <ReactQuillComponent
                        onBlur={this.handleAutomaticSave}
                        value={ata}
                        formats={this.formats}
                        modules={this.modules}
                        onChange={this.handleEditorAtaChange}
                        readOnly={
                          podeEditarAta == true
                            ? false
                            : idStatusReuniao == 0
                            ? disabled
                              ? true
                              : false
                            : true
                        }
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </Tab>
          )}

          {reuniaoId !== 0 && (
            <Tab
              key="3"
              label={capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'label.planoAcao' },
                  { planodeacao: this.props?.resources?.planodeacao }
                )
              )}
            >
              <Row>
                <Col md={4}>
                  <Checkbox
                    style={{ marginTop: 35 }}
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'exibirAcoesRecorrencia' },
                          { acoes: resources.acoes }
                        )
                      ),
                      value: model.getModel('exibirAcoesRecorrencia').value,
                      requestChange: (v) => this.handleCheckboxChange(v),
                      errors: model.getModel('exibirAcoesRecorrencia').errors,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <AcaoList
                    exportApresentacao={this.props.exportApresentacao}
                    kanban={false}
                    showToolBar={!this.props.exportApresentacao}
                    formatoKanbanDefault={false}
                    columnsCard={this.state.columnsCard}
                    columnsKanban={this.state.columnsKanban}
                    allColumnsCard={this.state.allColumnsCard}
                    allColumnsKanban={this.state.allColumnsKanban}
                    showDelete={!permissions}
                    params={{
                      itemId: reuniaoId,
                      dataInicioItem: dataReuniao,
                      ehReuniao: true,
                      exibirAcoesRecorrencia: model.getModel(
                        'exibirAcoesRecorrencia'
                      )
                        ? model.getModel('exibirAcoesRecorrencia').value
                        : false,
                    }}
                    showPrevious={false}
                    showLink={true}
                    showNew={!permissions}
                    showSearch={false}
                    naoSalvarFiltro={true}
                    showMenuAcao={false}
                    disabled={permissions}
                    showExport={false}
                    dateReuniao={
                      model.getModel('dataInicioItem') &&
                      model.getModel('dataInicioItem').value
                    }
                    tag={2005}
                  ></AcaoList>
                </Col>
              </Row>
            </Tab>
          )}
        </Tabs>
      </Suspense>
    );
  }
}

@autobind
class ReuniaoEdit extends React.Component {
  static defaultProps = {
    showModal: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      ehSomenteAprovacaoDeAta: false,
      podeEditarAta: false,
      automaticSave: false,
    };
  }

  componentDidMount() {
    this.obterPermissaoEditarAta();
  }

  obterPermissaoEditarAta() {
    http
      .post(`/reuniao/ObterPermissaoEditarAta`)
      .then((response) => {
        response &&
          response.data &&
          this.setState({ ...this.state, podeEditarAta: response.data });
      })
      .catch();
  }

  alterarAprovacaoAta(reuniaoId, participanteId, aprovou) {
    http
      .post(`/reuniao/AprovarAta`, {
        reuniaoId: reuniaoId,
        participanteId: participanteId,
        aprovou: aprovou,
      })
      .then(() => {
        this.setState({ ...this.state, ehSomenteAprovacaoDeAta: true });
      })
      .catch();
  }

  toggleAutomaticSave() {
    this.setState({
      automaticSave: !this.state.automaticSave,
    });
  }

  render() {
    return this.props.showModal ? (
      <Edit
        url="/Reuniao"
        title="reuniao"
        showDelete={this.props.item ? true : false}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height="95%"
        refreshModel={this.props.refresh}
        alterarAprovacaoAta={this.alterarAprovacaoAta}
        {...this.props}
        parameters={{
          ...this.props.parameters,
          ehSomenteAprovacaoDeAta: this.state.ehSomenteAprovacaoDeAta,
          podeEditarAta: this.state.podeEditarAta,
        }}
        resources={this.props.resources}
        forceShowSave={this.state.podeEditarAta}
        showExport={false}
        close={false}
        ehReuniao={true}
      />
    ) : (
      <EditPage
        url="/Reuniao"
        showDelete={this.props.item ? true : false}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height="95%"
        alterarAprovacaoAta={this.alterarAprovacaoAta}
        {...this.props}
        parameters={{
          ...this.props.parameters,
          ehSomenteAprovacaoDeAta: this.state.ehSomenteAprovacaoDeAta,
          podeEditarAta: this.state.podeEditarAta,
        }}
        resources={this.props.resources}
        forceShowSave={this.state.podeEditarAta}
        toggleAutomaticSave={this.toggleAutomaticSave}
        automaticSave={this.state.automaticSave}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    tiposAnexos: state.user.tiposAnexos,
  };
}
export default injectIntl(
  connect(mapStateToProps, { setPresentation })(ReuniaoEdit)
);
