import { useEffect, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import http from 'src/services/httpService';
import Dialog from 'src/componentes/dialog';
import errorHandler from 'src/utils/error-handler';
import PlanejamentoInicialList from './list';

const ModalPlanejamentoInicial = ({
  dialogWidth = '90%',
  dialogHeight = '70%',
  fromItem,
  item,
  componente,
  filtro,
  ...props
}) => {
  const [exibir, setExibir] = useState(false);
  const intl = useIntl();
  const ref = useRef();

  let params = {
    item: item,
    itemId: item.id,
  };

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  useEffect(() => {
    if (fromItem) {
      http
        .post(`Item/ObterPermissao`, {
          id: item && item.id,
        })
        .then((response) => {
          setExibir(true);
        })
        .catch((error) => {
          setExibir(false);
          errorHandler(error);
          handleClose();
        });
    } else {
      setExibir(true);
    }
  }, []);

  return (
    <Dialog
      title={<FormattedMessage id="planejamentoInicial" />}
      padContent
      maxWidth="100%"
      maxHeight="100%"
      width={dialogWidth}
      height={dialogHeight}
      mouseDownClose={true}
      onRequestClose={handleClose}
      ref={ref}
      {...props}
    >
      {exibir === true && (
        <PlanejamentoInicialList
          localModal
          showNew
          parameters={params}
          handleCloseModal={handleClose}
          isModal
        />
      )}
    </Dialog>
  );
};

export default ModalPlanejamentoInicial;
