import React from 'react';
import Edit from '../../../componentes/edit/edit-dialog';
import Input from '../../../componentes/input';
import { autobind } from 'core-decorators';
import { Schema } from '../../../utils/form';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../../utils/string';

let schema = Schema.object({
  id: Schema.string(),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model } = this.props;

    return (
      <div>
        <Row>
          <Col md={3}>
            <Input autoFocus model={model.getModel('idIntegracao')} required />
          </Col>
          <Col md={4}>
            <Input model={model.getModel('nome')} required />
          </Col>
        </Row>
      </div>
    );
  }
}

@autobind
class CategoriaEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;
    return (
      <Edit
        url="/Categoria"
        title={capitalizeFirstLetter(intl.formatMessage({ id: 'categoria' }))}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height={600}
        {...this.props}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(CategoriaEdit));
