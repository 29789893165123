import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import DatePicker from 'src/componentes/date-picker';
import Alert from 'src/componentes/message-box/alert';
import ContentManager from 'src/componentes/content-manager';

@autobind
export default class PeriodoData extends React.Component {
  handleChangeDataInicio(value) {
    let { dataInicio, dataTermino } = this.props;

    if (moment(value) > moment(dataTermino && dataTermino.value)) {
      ContentManager.addContent(<Alert message={<FormattedMessage id="label.dataInicioDeveSerMenorDataTermino" />} />);
    } else {
      dataInicio && dataInicio.requestChange && dataInicio.requestChange(value);
    }
  }

  handleChangeDataTermino(value) {
    let { dataInicio, dataTermino } = this.props;

    if (moment(value) < moment(dataInicio && dataInicio.value)) {
      ContentManager.addContent(<Alert message={<FormattedMessage id="label.dataTerminoDeveSerMaiorDataInicio" />} />);
      dataTermino && dataTermino.requestChange && dataTermino.requestChange(null);
    } else {
      dataTermino && dataTermino.requestChange && dataTermino.requestChange(value);
    }

    if (dataInicio == null) dataInicio && dataInicio.requestChange && dataInicio.requestChange(value);
  }

  render() {
    let { dataInicio, dataTermino, disabled, required } = this.props;

    return (
      <Row>
        <Col md={6}>
          <DatePicker
            month
            disabled={disabled}
            required={required}
            model={{
              label: <FormattedMessage id="label.dataInicio" />,
              value: dataInicio && dataInicio.value,
              requestChange: this.handleChangeDataInicio,
              errors: dataInicio && dataInicio.errors,
            }}
          ></DatePicker>
        </Col>
        <Col md={6}>
          <DatePicker
            month
            disabled={disabled}
            model={{
              label: <FormattedMessage id="label.dataTermino" />,
              value: dataTermino && dataTermino.value,
              requestChange: this.handleChangeDataTermino,
            }}
          ></DatePicker>
        </Col>
      </Row>
    );
  }
}
