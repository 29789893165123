import Lookup from 'src/componentes/select2';

const StatusPlanejamentoInicial = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/StatusPlanejamentoInicialLookup"
    clearable
    {...rest}
  />
);

export default StatusPlanejamentoInicial;
