import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import CheckBox from 'src/componentes/checkbox';
import { capitalizeFirstLetter } from 'src/utils/string';
import MdiIcon from 'src/componentes/mdi-icon';
import http from 'src/services/httpService';

import Edit from './edit';
@autobind
class ColaboradorReuniao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changedColumns: false,
    };
  }

  static defaultProps = {
    disabled: false,
    showNew: true,
  };

  componentDidMount() {
    http.post(`/ItemConfiguracaoReuniao/ObterConfiguracoes`, {}).then((response) => {
      this.setState({
        configuracoes: response.data,
        changedColumns: true,
      });
    });
  }

  handleRemove(item, index) {
    this.props.model.value.splice(index, 1);
    this.props.model.requestChange(this.props.model.value);
  }

  handleSaveItem(saved, isNew, item, index) {
    if (saved) {
      let novaLista = this.props.model.value ? this.props.model.value : [];

      if (isNew) {
        if (this.props.model.value != null)
          index = this.props.model.value.findIndex((f) => {
            if (Array.isArray(item.colaborador)) {
              return item.colaborador.findIndex((col) => f.colaborador.id == col.id) != -1;
            } else return f.colaborador.id == item.colaborador.id;
          });
        else index = -1;
        if (index == -1)
          if (Array.isArray(item.colaborador)) item.colaborador.forEach((col) => novaLista.push({ ...item, colaborador: col }));
          else novaLista.push(item);
      } else novaLista[index] = item;

      this.props.model.requestChange(novaLista);
    }
  }

  handleColaborador(item) {
    return <span>{item?.colaborador?.nome}</span>;
  }

  handleCheckChange(field, item, value) {
    let newList = this.props.model.value ? this.props.model.value : [];

    let index = newList.findIndex((n) => n.colaborador.id === item.colaborador.id);

    newList[index][field] = value;

    this.handleSaveItem(true, false, newList[index], index);
    field === 'aprovouAta' && this.props.alterarAprovacaoAta && this.props.alterarAprovacaoAta(this.props.reuniaoId, item.colaborador.id, value);
  }

  handleAprovouAta(item) {
    const { aprovouAta, colaborador } = item;
    const { configuracoes } = this.state;
    const { responsaveis, user } = this.props;
    const permitirAlterarAprovouAta = configuracoes?.aprovacaoAtaPeloResponsavel ? responsaveis.some((r) => r.id == user?.id) : user?.id == colaborador?.id;

    return (
      <CheckBox
        inline
        disabled={!permitirAlterarAprovouAta}
        model={{
          value: aprovouAta,
          requestChange: this.handleCheckChange.bind(this, 'aprovouAta', item),
        }}
      />
    );
  }

  handleParticipou(item) {
    const { disableParticipou } = this.props;
    return (
      <CheckBox
        inline
        model={{
          value: item.participou,
          requestChange: this.handleCheckChange.bind(this, 'participou', item),
        }}
        disabled={disableParticipou != null && disableParticipou != undefined ? disableParticipou : false}
      />
    );
  }

  handleConfirmacao(item) {
    return item.confirmacao && <MdiIcon icon={item.confirmacao.icone} color={item.confirmacao.cor} colorHover={item.confirmacao.cor}></MdiIcon>;
  }

  handleParticipacao(item) {
    return <span>{item.tipoParticipacao && item.tipoParticipacao.descricao}</span>;
  }

  render() {
    let { model, showNew, showMenu, resources, intl } = this.props;
    let { configuracoes } = this.state;

    return (
      <List
        local
        showNew={showNew}
        dataSource={model.value}
        handleSaveItem={this.handleSaveItem}
        handleRemove={this.handleRemove}
        editComponent={Edit}
        showExport={false}
        showPage={false}
        showSearch={false}
        showMenu={showMenu}
        showScrollColumn={false}
        changedColumns={this.state.changedColumns}
        ehParticipantes={true}
      >
        <ListColumn headerText="" visible={false} valueField="id"></ListColumn>
        <ListColumn headerText={capitalizeFirstLetter(resources.colaborador)} valueFunction={this.handleColaborador}></ListColumn>
        {configuracoes && configuracoes.utilizarConfirmacao && (
          <ListColumn headerText={intl.formatMessage({ id: 'label.confirmacao' })} valueField="confirmacao" valueFunction={this.handleConfirmacao}></ListColumn>
        )}
        {configuracoes && configuracoes.utilizarAprovouAta && (
          <ListColumn headerText={intl.formatMessage({ id: 'label.aprovouAta' })} valueFunction={this.handleAprovouAta}></ListColumn>
        )}
        {configuracoes && configuracoes.utilizarParticipou && (
          <ListColumn headerText={intl.formatMessage({ id: 'label.participou' })} valueFunction={this.handleParticipou}></ListColumn>
        )}
        {configuracoes && configuracoes.utilizarObrigatorio && (
          <ListColumn headerText={intl.formatMessage({ id: 'label.participacao' })} valueFunction={this.handleParticipacao}></ListColumn>
        )}
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    user: state.user,
  };
}
export default injectIntl(connect(mapStateToProps)(ColaboradorReuniao));
