import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class StatusRiscoPontoControle extends React.Component {
  render() {
    return (
      <Lookup
        labelKey="descricao"
        minHeight={'38px' }
        valueKey="id"
        url="/StatusRiscoPontoControleLookup"
        {...this.props}
      />
    );
  }
}

export default StatusRiscoPontoControle;
