import MdiIcon from 'src/componentes/mdi-icon';

const Chip = ({ id, Icon, label, onDelete, ...rest }) => {
  return (
    <div
      id={id}
      className="border-gray d-flex rounded-pill align-items-center bg-transparent truncate justify-content-center"
      style={{
        maxWidth: '650px',
      }}
      {...rest}
    >
      <div className="ml-1">{Icon}</div>

      <span
        className="px-2 text-truncate"
        style={{
          fontSize: '0.8125rem',
          maxWidth: '95%',
        }}
      >
        {label}
      </span>
      <MdiIcon
        onClick={onDelete}
        icon="close-circle"
        color="#0000006b"
        className="mr-1"
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

export default Chip;
