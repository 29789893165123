import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Schema } from 'src/utils/form';

export default Schema.object({
  risco: Schema.string().label(),
  tipoFiltroData: Schema.string().label(<FormattedMessage id="label.tipoFiltroData" />),
  dataInicio: Schema.string().label(<FormattedMessage id="dataInicio" />),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
  probabilidade: Schema.string().label(<FormattedMessage id="label.probabilidade" />),
  severidade: Schema.string().label(),
  tratamento: Schema.string().label(<FormattedMessage id="tratamento" />),
  incluirSubprocessos: Schema.string(),
});
