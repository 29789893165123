import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import EfetividadeControleEdit from '../paginas/gestao-risco/ponto-controle/efetividade-controle/edit';
import Info from 'src/componentes/info';

@autobind
class EfetividadeControle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infos: [],
    };
  }

  renderDescricaoArray = () => {
    const { infos } = this.state;
    if (infos) {
      return (
        <div
          className="d-flex flex-column text-left"
          style={{
            maxWidth: '780px',
            wordWrap: 'break-word',
          }}
        >
          {' '}
          {infos.map((obj, index) => (
            <div
              key={`descEfetividade-${index}`}
              className="d-flex flex-row text-left pb-2"
            >
              <div className="m-0 p-0">
                <strong>{obj.nome}</strong>: {obj.descricao}
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  getDataLookup(data) {
    if (data) this.setState({ infos: data });
  }

  getPlaceHolder() {
    if (!this.props.efetividadeAutomatica) return '';
    if (this.props.preventivo)
      return this.props.intl.formatMessage(
        { id: 'validacaoNaoHaControlesPreventivos' },
        { pontosdecontrole: this.props.resources.pontosdecontrole }
      );
    if (this.props.corretivo)
      return this.props.intl.formatMessage(
        { id: 'validacaoNaoHaControlesCorretivos' },
        { pontosdecontrole: this.props.resources.pontosdecontrole }
      );

    return this.props.intl.formatMessage(
      { id: 'validacaoNaoHaControles' },
      { pontosdecontrole: this.props.resources.pontosdecontrole }
    );
  }

  render() {
    return (
      <div>
        <Lookup
          labelKey="nome"
          valueKey="id"
          url="/EfetividadeControleLookup"
          editComponent={EfetividadeControleEdit}
          style={{ marginBottom: 0 }}
          getDataLookup={this.getDataLookup}
          {...this.props}
          placeholder={this.getPlaceHolder()}
          model={{
            ...this.props.model,
            label: (
              <span>
                {this.props.model.label}
                <Info
                  id={'efetividadeLookupInfo01' + this.props.id}
                  tooltipMaxWidth={950}
                  placement="auto"
                  renderContent={() => this.renderDescricaoArray()}
                />
              </span>
            ),
          }}
        />
      </div>
    );
  }
}

export default EfetividadeControle;
