import React from 'react';
import { autobind } from 'core-decorators';
import Button from '../button';
import Dropzone from 'react-dropzone';
import { FormGroup } from 'reactstrap';
import http from 'src/services/httpService';
import { Trash } from 'react-feather';
import ContentManager from 'src/componentes/content-manager';
import Confirm from 'src/componentes/message-box/confirm';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class ArquivoUploadAnexo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deletingItem: null,
    };
  }

  static defaultProps = {
    tipoAnexo: null,
    multipleFiles: false,
  };

  handleOnDrop(files) {
    if (!files || files.length == 0) return;

    const { multipleFiles } = this.props;

    var data = new FormData();

    if (multipleFiles) {
      data.append('files', files);
      data.append('tipoAnexo', this.props.tipoAnexo);

      http
        .post('ArquivoSistema/uploadArquivos', { files, tipoAnexo: this.props.tipoAnexo })
        .then((result) => {
          // Propaga o Resultado
          if (this.props.model && this.props.model.requestChange)
            this.props.model.requestChange(result.data);
        })
        .catch((error) => {});
    } else {
      data.append('file', files[0]);
      data.append('tipoAnexo', this.props.tipoAnexo);
      data.append('idReferencia', this.props.idReferencia || null);

      http
        .post('ArquivoSistema/uploadArquivo', data)
        .then((result) => {
          // Propaga o Resultado
          if (this.props.model && this.props.model.requestChange)
            this.props.model.requestChange(result.data);
        })
        .catch((error) => {});
    }
  }

  removeFile(file) {
    var id = file.id;

    http
      .post('ArquivoSistema/RemoverArquivo', { id })
      .then(() => {
        const { value } = this.props.model;
        const novaLista = this.props.multipleFiles ? value.filter((item) => item.id !== id) : null;
        if (this.props.model && this.props.model.requestChange)
          this.props.model.requestChange(novaLista);
      })
      .catch((error) => {});
  }

  renderDeleteConfirm(file) {
    ContentManager.addContent(
      <Confirm
        message={this.props.intl.formatMessage({
          id: 'label.desejaMesmoExcluirEsseAnexo',
        })}
        handleConfirm={() => {
          this.removeFile(file);
          this.setState({ deletingItem: file });
        }}
      />
    );
  }

  render() {
    let { model, required, buttonLabel, colorButton = 'primary', multipleFiles } = this.props;
    let { value } = model;
    const { deletingItem } = this.state;

    return (
      <FormGroup>
        <label className="control-label">
          {model.label} {required ? '*' : ''}
        </label>
        <Dropzone style={{ width: '100%' }} onDrop={this.handleOnDrop}>
          <Button color={colorButton} leftIcon="upload">
            {buttonLabel}
          </Button>
          {value &&
            (multipleFiles ? (
              value.map((item, index) => {
                return (
                  <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    {deletingItem && deletingItem.id === item.id ? null : (
                      <>
                        <div style={{ marginRight: '8px' }}>{item.nome}</div>
                        {item && (
                          <Trash
                            className="cursor-pointer"
                            size={18}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.renderDeleteConfirm(item);
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                );
              })
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {deletingItem && deletingItem.id === value.id ? null : (
                  <>
                    <div style={{ marginRight: '8px' }}>{value.nome}</div>
                    <Trash
                      className="cursor-pointer"
                      size={18}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.renderDeleteConfirm(value);
                      }}
                    />
                  </>
                )}
              </div>
            ))}
        </Dropzone>
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(ArquivoUploadAnexo));
