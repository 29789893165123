import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import Radio from 'src/componentes/radio';
import Input from 'src/componentes/input';
import PanelCollapse from 'src/componentes/panel-collapse';
import { capitalizeFirstLetter, lowerCaseFirstLetter } from 'src/utils/string';
import useAuth from 'src/hooks/useAuth';
import Info from 'src/componentes/info';

import FormulaEdit from '../components/formula/edit';
import TipoCardView from '../components/tipoCard/tipo-card-view';
import CestaItensList from '../components/item';
import DataInicioTermino from '../components/inicio-termino';

const EditComissoes = ({
  isNotNew,
  model,
  disabled,
  renderFormula,
  renderRadioButton,
  handleChangeIdIntegracao,
  handleChangeDataInicio,
  handleChangeDataTermino,
  handleChangeTipoCalculoScore,
  handleChangeTipoCard,
}) => {
  const intl = useIntl();

  const { terms: resources } = useAuth();

  const renderFormulasList = (tipoEntidadeId) => (
    <List
      title={intl.formatMessage({ id: 'formula' })}
      url="/Formula"
      editComponent={FormulaEdit}
      showTitle
      bloquearMultiplosRegistros
      showPage={false}
      showExport={false}
      showDelete
      showMenu={!disabled}
      showNew={!disabled}
      parameters={{
        cestaIndicadorId: model.getModel('id').value,
        tipoEntidadeId,
        alterado: false,
        tipoValorId: 5,
      }}
    >
      <ListColumn
        headerText={intl.formatMessage({ id: 'formula' })}
        valueField="formula"
        valueFunction={renderFormula}
      />
    </List>
  );

  const tipoCalculoScore =
    model.getModel('tipoCalculoScore').value == 0
      ? 1
      : model.getModel('tipoCalculoScore').value;

  return (
    <>
      <Row>
        <Col md={4}>
          <Input
            disabled={disabled}
            maxlength={100}
            required
            model={{
              label: intl.formatMessage({ id: 'idIntegracao' }),
              value: model.getModel('idIntegracao').value,
              requestChange: handleChangeIdIntegracao,
              errors: model.getModel('idIntegracao').errors,
            }}
          />
        </Col>
        <Col md={8}>
          <DataInicioTermino
            disabled={disabled}
            required
            dataInicio={{
              label: intl.formatMessage({ id: 'label.dataInicio' }),
              value: model.getModel('dataInicio').value,
              requestChange: handleChangeDataInicio,
              errors: model.getModel('dataInicio').errors,
            }}
            dataTermino={{
              label: intl.formatMessage({ id: 'label.dataTermino' }),
              value: model.getModel('dataTermino').value,
              requestChange: handleChangeDataTermino,
            }}
          />
        </Col>
      </Row>
      {isNotNew && (
        <>
          <PanelCollapse
            open={false}
            header={
              <div className="d-inline-flex align-items-center">
                <span>
                  {' '}
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'calculoDaPerformance' })
                  )}
                </span>
                <Info
                  id="panel-performance"
                  className="ml-2"
                  placement="auto"
                  text={intl.formatMessage(
                    {
                      id: 'calculoDaPerformanceInformacao',
                    },
                    { comissao: lowerCaseFirstLetter(resources.comissao) }
                  )}
                  size={14}
                  style={{ marginTop: 1 }}
                />
              </div>
            }
          >
            <Row>
              <Col md={12}>
                <Radio
                  disabled={disabled}
                  model={{
                    label:
                      capitalizeFirstLetter(
                        intl.formatMessage({ id: 'tipo' })
                      ) + ' *',
                    value: tipoCalculoScore,
                    requestChange: handleChangeTipoCalculoScore,
                  }}
                  needId
                  options={renderRadioButton()}
                  labelField="descricao"
                  idField="id"
                  name="opcoestipoCalculoScore"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {tipoCalculoScore == 1 && (
                  <CestaItensList
                    parameters={{
                      cestaIndicadorId: model.getModel('id').value,
                    }}
                    showExport={false}
                    model={model}
                    disabled={disabled}
                  />
                )}
                {tipoCalculoScore == 2 && renderFormulasList(7)}
                {tipoCalculoScore == 3 && (
                  <TipoCardView
                    parameters={{
                      cestaIndicadorId: model.getModel('id').value,
                    }}
                    showExport={false}
                    onChange={handleChangeTipoCard}
                    model={model}
                    disabled={disabled}
                  />
                )}
              </Col>
            </Row>
          </PanelCollapse>
          <PanelCollapse
            header={capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'calculoDaComissao' },
                { comissao: lowerCaseFirstLetter(resources.comissao) }
              )
            )}
          >
            {renderFormulasList(8)}
          </PanelCollapse>
        </>
      )}
    </>
  );
};

export default EditComissoes;
