import { Row, Col, Card, CardTitle } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import AnaliseSwotView from './AnaliseSwotView';

function AnaliseSwot({ processoId, disabled, intl, ...props }) {
  return (
    <div>
      <Row>
        <Col md={2}></Col>
        <Col md={5} style={{ marginTop: 20 }} className="p-0">
          <Card style={{ height: '100%' }} body className="text-center justify-content-center">
            <CardTitle tag="h5">
              <FormattedMessage id="labelFatoresPositivos" />
            </CardTitle>
          </Card>
        </Col>
        <Col md={5} style={{ marginTop: 20 }} className="p-0">
          <Card style={{ height: '100%' }} body className="text-center justify-content-center">
            <CardTitle tag="h5">
              <FormattedMessage id="labelFatoresNegativos" />
            </CardTitle>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="p-0">
          <Card style={{ height: '100%' }} body className="justify-content-center p-0">
            <CardTitle tag="h5">
              <div style={{ transform: 'rotate(270deg)' }} className="text-center">
                <FormattedMessage id="labelFatoresInternos" />
              </div>
            </CardTitle>
          </Card>
        </Col>

        <Col md={5} className="p-0">
          <AnaliseSwotView header={intl.formatMessage({ id: 'labelForcas' })} disabled={disabled} tipoSwot={1} processoId={processoId} />
        </Col>
        <Col md={5} className="p-0">
          <AnaliseSwotView header={intl.formatMessage({ id: 'labelFraquezas' })} disabled={disabled} tipoSwot={2} processoId={processoId} />
        </Col>
      </Row>
      <Row>
        <Col md={2} className="p-0">
          <Card style={{ height: '100%' }} body className="justify-content-center">
            <CardTitle tag="h5">
              <div style={{ transform: 'rotate(270deg)' }} className="text-center">
                <FormattedMessage id="labelFatoresExternos" />
              </div>
            </CardTitle>
          </Card>
        </Col>
        <Col md={5} className="p-0">
          <AnaliseSwotView header={intl.formatMessage({ id: 'labelOportunidades' })} disabled={disabled} tipoSwot={3} processoId={processoId} />
        </Col>
        <Col md={5} className="p-0">
          <AnaliseSwotView header={intl.formatMessage({ id: 'labelAmeacas' })} disabled={disabled} tipoSwot={4} processoId={processoId} />
        </Col>
      </Row>
    </div>
  );
}

export default AnaliseSwot;
