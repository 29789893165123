import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import ParametroEdit from '../paginas/matriz-orcamentaria/parametro-entidade/edit';
import ParametroList from '../paginas/matriz-orcamentaria/parametro-entidade/view';

@autobind
class ParametroLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueComponent={this.renderParametroEntidade}
        valueKey="id"
        labelKey="nome"
        showSearch
        clearable={true}
        url="/ParametroEntidadeLookup"
        editComponent={ParametroEdit}
        searchComponent={ParametroList}
        {...this.props}
      />
    );
  }
}

export default ParametroLookup;
