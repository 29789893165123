import moment from 'moment';

export default function formatarData(idioma, data) {
  switch (idioma) {
    case 'pt-br':
      return moment(data).locale('pt-br').format('DD/MM/YY');
    case 'en-us':
      return moment(data).locale('en-us').format('MM/DD/YY');
    case 'es':
      return moment(data).locale('es').format('DD/MM/YY');
    case 'pt-pt':
      return moment(data).locale('pt-pt').format('DD/MM/YY');
    case 'it':
      return moment(data).locale('it').format('DD/MM/YY');

    default:
      return moment(data).locale('pt-br').format('DD/MM/YY');
  }
}
