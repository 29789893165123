import React from 'react';
import { autobind } from 'core-decorators';
import http from 'src/services/httpService';
import { injectIntl } from 'react-intl';
import SvgIcon from '../../componentes/svg-icon';
import LoadingContainer from '../../componentes/loading-container';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import Preview from '../faixa-farol/preview';
import ReactTooltip from 'react-tooltip';
import { capitalizeFirstLetter } from 'src/utils/string';

@autobind
class FarolItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      popoverFarol: false,
      activeItemFarol: null,
      faixa: null,
    };
  }

  async handleOpen(statusPopover, popoverId, item, ehAcumulado) {
    this.setState({
      popoverFarol: statusPopover,
      activeItemFarol: popoverId,
      isLoading: true,
    });

    var response = await http.post(`/Item/ObterFaixaFarol`, {
      itemId: item.id,
      ehAcumulado: ehAcumulado,
    });
    this.setState({
      faixa: response.data,
      isLoading: false,
    });
  }

  renderPopoverFarol(item, target) {
    let model = { value: null };
    let faixa = this.state.faixa;

    if (item.melhor != null && faixa != null) {
      if (item.melhor.id === 1) {
        model.value = faixa.faixaMaior;
      } else if (item.melhor.id === 2) {
        model.value = faixa.faixaMenor;
      } else {
        model.value = faixa.faixaIgual;
      }
    }

    return (
      <Popover
        placement="left"
        isOpen={this.state.popoverFarol}
        target={target}
      >
        <PopoverHeader>
          {this.props.intl.formatMessage({ id: 'faixaDeFarol' })}
        </PopoverHeader>
        <PopoverBody>
          {
            <LoadingContainer isLoading={this.state.isLoading}>
              <Preview model={model}></Preview>
            </LoadingContainer>
          }
        </PopoverBody>
      </Popover>
    );
  }

  renderFarol() {
    const {
      target,
      item,
      ehAcumulado,
      relatorioAcompanhamento,
      sinalizarRelatorioPreenchido,
      field,
    } = this.props;

    if (ehAcumulado) {
      if (item.farolAcumulado) {
        return (
          <div>
            <SvgIcon
              icon={item.farolAcumulado.icone}
              size={20}
              color={item.farolAcumulado.cor}
              colorHover={item.farolAcumulado.cor}
              title={item.farolAcumulado.legenda}
            />
            {this.state.activeItemFarol === target &&
              this.renderPopoverFarol(item, target)}
          </div>
        );
      }
    } else {
      if (field || item.farol) {
        return (
          <div>
            <SvgIcon
              icon={field ? field.icone : item.farol.icone}
              size={20}
              color={field ? field.cor : item.farol.cor}
              colorHover={field ? field.cor : item.farol.cor}
              title={field ? field.legenda : item.farol.legenda}
              onClick={
                this.props.onClick && this.props.onClick.bind(this, item)
              }
              iconBadge={
                sinalizarRelatorioPreenchido &&
                relatorioAcompanhamento &&
                relatorioAcompanhamento.status
                  ? relatorioAcompanhamento.status.icone
                  : null
              }
            />
            {this.state.activeItemFarol === target &&
              this.renderPopoverFarol(item, target)}
          </div>
        );
      }
    }
  }

  render() {
    const { target, item, ehAcumulado, hidePopOver, intl, field } = this.props;
    return (
      <div
        id={target}
        onMouseEnter={
          !hidePopOver &&
          this.handleOpen.bind(this, true, target, item, ehAcumulado)
        }
        onMouseLeave={
          !hidePopOver &&
          this.handleOpen.bind(this, false, target, item, ehAcumulado)
        }
        data-tip
        data-for={target}
      >
        {this.renderFarol()}

        {hidePopOver && (
          <ReactTooltip id={target} aria-haspopup="true">
            {capitalizeFirstLetter(field?.descricao)}
          </ReactTooltip>
        )}
      </div>
    );
  }
}

export default injectIntl(FarolItem);
