import Lookup from 'src/componentes/select2';

const CatalogoNegociacaoMetasLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/NegociacaoMetasCatalogoLookup"
    clearable
    {...rest}
  />
);

export default CatalogoNegociacaoMetasLookup;
