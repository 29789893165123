import React from 'react';
import Edit from '../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import Slider from './slider';
import Panel from '../../componentes/panel';
import MdiIcon from '../../componentes/mdi-icon';
import Input from '../../componentes/input';
import { Col, Row } from 'reactstrap';
import TipoValorFaixaLookup from '../../lookups/tipo-valor-faixa';
import { FormattedMessage } from 'react-intl';
import Confirm from '../../componentes/message-box/confirm';
import ContentManager from '../../componentes/content-manager';
import http from 'src/services/httpService';
import errorHandler from '../../utils/error-handler';
import ReprocessarDialog from './reprocessarFarois';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  tipoValorMelhorIgual: Schema.string().label(
    <FormattedMessage id="label.tipoValor" />
  ),
  tipoValorMelhorParaBaixo: Schema.string().label(
    <FormattedMessage id="label.tipoValor" />
  ),
  tipoValorMelhorParaCima: Schema.string().label(
    <FormattedMessage id="label.tipoValor" />
  ),
  faixaPadrao: Schema.string().label(
    <FormattedMessage id="label.faixaPadrao" />
  ),
  faixaIgual: Schema.array().label(<FormattedMessage id="label.faixaIgual" />),
  faixaMaior: Schema.array().label(<FormattedMessage id="label.faixaMaior" />),
  faixaMenor: Schema.array().label(<FormattedMessage id="label.faixaMenor" />),
  melhorIgual: Schema.array().label(''),
  melhorParaBaixo: Schema.array().label(''),
  melhorParaCima: Schema.array().label(''),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
    };
  }

  handleSelect(activeKey) {
    this.setState({
      activeKey: this.state.activeKey === activeKey ? 0 : activeKey,
    });
  }

  render() {
    let { model, exibirApenasValorAbsoluto = false, faixaIndicadorRisco = false } = this.props;

    return (
      <div>
        <Row>
          <Col md={4}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
          <Col md={8}>
            <Input model={model.getModel('descricao')} required />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Panel
              header={
                <div>
                  <span>
                    <FormattedMessage id="faixaDeFarol" />
                    <MdiIcon
                      icon={
                        model.getModel('melhorParaCima').value &&
                        model.getModel('melhorParaCima').value.icone
                      }
                    ></MdiIcon>
                  </span>
                </div>
              }
              eventkey="1"
            >
              <Slider
                melhor={model.getModel('melhorParaCima')}
                model={model.getModel('faixaMaior')}
                tipoValor={model.getModel('tipoValorMelhorParaCima')}
                faixaIndicadorRisco={faixaIndicadorRisco}
              />
              <Row>
                <Col md={6}>
                  <TipoValorFaixaLookup
                    disabled={exibirApenasValorAbsoluto}
                    model={model.getModel('tipoValorMelhorParaCima')}
                  />
                </Col>
              </Row>
            </Panel>
          </Col>
          <Col md={12}>
            <Panel
              header={
                <div>
                  <span>
                    <FormattedMessage id="faixaDeFarol" />
                    <MdiIcon
                      icon={
                        model.getModel('melhorParaBaixo').value &&
                        model.getModel('melhorParaBaixo').value.icone
                      }
                    ></MdiIcon>
                  </span>
                </div>
              }
              eventkey="2"
            >
              <Slider
                melhor={model.getModel('melhorParaBaixo')}
                model={model.getModel('faixaMenor')}
                tipoValor={model.getModel('tipoValorMelhorParaBaixo')}
                faixaIndicadorRisco={faixaIndicadorRisco}
              />
              <Row>
                <Col md={6}>
                  <TipoValorFaixaLookup
                    disabled={exibirApenasValorAbsoluto}
                    model={model.getModel('tipoValorMelhorParaBaixo')}
                  />
                </Col>
              </Row>
            </Panel>
          </Col>
          <Col md={12}>
            <Panel
              header={
                <div>
                  <span>
                    <FormattedMessage id="faixaDeFarol" />
                    <MdiIcon
                      icon={
                        model.getModel('melhorIgual').value &&
                        model.getModel('melhorIgual').value.icone
                      }
                    ></MdiIcon>
                  </span>
                </div>
              }
              eventkey="3"
            >
              <Slider
                melhor={model.getModel('melhorIgual')}
                model={model.getModel('faixaIgual')}
                tipoValor={model.getModel('tipoValorMelhorIgual')}
                faixaIndicadorRisco={faixaIndicadorRisco}                
              />
              <Row>
                <Col md={6}>
                  <TipoValorFaixaLookup
                    disabled={exibirApenasValorAbsoluto}
                    model={model.getModel('tipoValorMelhorIgual')}
                  />
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }
}

@autobind
class FaixaFarolEdit extends React.Component {
  handleSave(model, close) {
    ContentManager.addContent(
      <Confirm
        labelCancel={<FormattedMessage id="label.reprocessar" />}
        labelConfirm={<FormattedMessage id="label.salvar" />}
        message={
          <FormattedMessage id="desejaReprocessarFaroisItenVinculadoFaixa" />
        }
        handleConfirm={this.handleSaveConfirmFaixa.bind(this, model, close)}
        handleCancel={this.handleConfirmData.bind(this, model, close)}
      />
    );
  }

  handleConfirmData(model, close) {
    ContentManager.addContent(
      <ReprocessarDialog intl={this.props.intl} close={close} model={model} />
    );
  }

  handleSaveConfirmFaixa(model, close) {
    http
      .post(`/FaixaFarol/Save`, {
        model: model,
        parameters: { ...this.props.parameters },
      })
      .then(() => {
        close();
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  render() {
    return (
      <Edit
        url="/FaixaFarol"
        title="faixaDeFarol"
        formComponent={FormEdit}
        schema={schema}
        handleSave={this.handleSave}
        width="80%"
        height="90%"
        {...this.props}
      />
    );
  }
}

export default FaixaFarolEdit;
