import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { capitalizeFirstLetter } from 'src/utils/string';

import { COLABORADOR_CAMPOS } from 'src/utils/constants';
import Pessoal from './Pessoal';
import ExperienciasAnteriores from './ExperienciasAnteriores';
import AutorizacaoDivulgacaoAniversario from './AutorizacaoDivulgacaoAniversario';
import CursosExtracurriculares from './CursosExtracurriculares';
import DisponibilidadeMudancas from './DisponibilidadeMudancas';
import FormacoesAcademicas from './FormacoesAcademicas';
import Idiomas from './Idiomas';
import InteresseOutrasAreas from './InteresseOutrasAreas';
import CamposCustomizados from './CamposCustomizados';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/errorHandler';
import useAuth from 'src/hooks/useAuth';

function DadosPessoais({
  model,
  isVisible,
  isRequired,
  disabled,
  isRequiredCursosExtracurriculares,
}) {
  const intl = useIntl();
  const {terms: resources,} = useAuth();
  const handleChange = (field, value) => {
    model.getModel(field).requestChange(value);
  };
  return (
    <React.Fragment>
      <Pessoal
        disabled={disabled}
        model={model}
        isVisible={isVisible}
        isRequired={isRequired}
      />
      {isVisible(COLABORADOR_CAMPOS.FORMACAO_ACADEMICA) && (
        <FormacoesAcademicas
          disabled={disabled}
          model={{
            label: capitalizeFirstLetter(
              intl.formatMessage({ id: 'formacoesAcademicas' })
            ),
            value: model.getModel('formacoesAcademicas').value,
            requestChange: (e) => handleChange('formacoesAcademicas', e),
          }}
        />
      )}
      {isVisible(COLABORADOR_CAMPOS.IDIOMAS) && (
        <Idiomas
          disabled={disabled}
          model={{
            label: capitalizeFirstLetter(intl.formatMessage({ id: 'idiomas' })),
            value: model.getModel('idiomas').value,
            requestChange: (e) => handleChange('idiomas', e),
          }}
        />
      )}
      {isVisible(COLABORADOR_CAMPOS.EXPERIENCIASANTERIORES) && (
        <ExperienciasAnteriores
          disabled={disabled}
          model={{
            label: capitalizeFirstLetter(
              intl.formatMessage({ id: 'experienciasAnteriores' })
            ),
            value: model.getModel('experiencias').value,
            requestChange: (e) => handleChange('experiencias', e),
          }}
        />
      )}

      {isVisible(COLABORADOR_CAMPOS.CURSO_EXTRACURRICULAR) && (
        <CursosExtracurriculares
          disabled={disabled}
          isRequiredCursosExtracurriculares={isRequiredCursosExtracurriculares}
          naoPossuiCursosCadastrados={model.getModel('naoPossuiCursosACadastrar')}
          model={{
            label: capitalizeFirstLetter(
              intl.formatMessage({ id: 'cursosExtracurriculares' },{cursosextracurriculares : resources.cursosextracurriculares})
            ),
            value: model.getModel('cursos').value,
            requestChange: (e) => handleChange('cursos', e),
          }}
        />
      )}
      {isVisible(COLABORADOR_CAMPOS.AUTORIZACAO_DIVULGACAO_ANIVERSARIO) && (
        <AutorizacaoDivulgacaoAniversario
          disabled={disabled}
          model={model}
          changeModel={handleChange}
        />
      )}
      {isVisible(COLABORADOR_CAMPOS.DISPONIBILIDADE_MUDANCA) && (
        <DisponibilidadeMudancas disabled={disabled} model={model} />
      )}
      {isVisible(COLABORADOR_CAMPOS.INTERESSE_OUTRAS_AREAS) && (
        <InteresseOutrasAreas disabled={disabled} model={model} />
      )}
      <CamposCustomizados disabled={disabled} model={model} />
      {/* <ParticipacaoSquads /> */}
    </React.Fragment>
  );
}

export default DadosPessoais;
