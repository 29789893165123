import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { DropdownItem, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';
import getEditUrl from 'src/utils/item/getEditUrl';
import MdiIcon from 'src/componentes/mdi-icon';
import { TIPO_ITENS, MODULOS } from 'src/utils/constants';

import Mensagem from 'src/paginas/mensagem/modal';
import history from 'src/history';
import RiscoEditView from 'src/paginas/gestao-risco/risco/RiscoEditView';
import http from 'src/services/httpService';
import EmbedPowerBI from 'src/paginas/power-bi/componentes/power-bi-dialog';
import errorHandler from 'src/utils/error-handler';

@autobind
class DropdownItemRisco extends React.Component {
  static defaultProps = {
    exibirEdicao: true,
    exibirFicha: true,
    exibirValor: true,
    exibirRelacionamento: true,
    exibirRelatorioAcompanhamento: true,
    exibirInteligenciaArtificial: false,
    exibirMensagem: true,
    exportResumo: false,
    exibirMatrizResultados: true,
    exibirPlanoAcao: true,
    labelAI: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      utilizarCurvaS: false,
    };
  }

  getLabel(item) {
    const { renderLabel } = this.props;

    if (renderLabel) {
      return renderLabel;
    } else {
      return item.nome;
    }
  }

  getUrlItemExport(item) {
    let urlExportItem = null;

    if (item.tipo && item.tipo.id == TIPO_ITENS.INDICADOR)
      urlExportItem = `/Indicador/ExportResumoIndicador`;
    else if (item.tipo && item.tipo.id == TIPO_ITENS.PROJETO)
      urlExportItem = `/Projeto/ExportResumoPlanoAcao`;

    return urlExportItem;
  }

  handleEditClick(item) {
    if (!item) return;

    ContentManager.addContent(
      <RiscoEditView
        paramsFiltro={this.state.searchEstatistica}
        mode="edit"
        handleClose={this.props.handleEditClose}
        {...this.props}
      />
    );
  }

  obterFiltroUrl(item) {
    if (!item) return;

    const { filtro } = this.props;
    let url = `?itemId=${item.id}`;

    if (filtro != null && filtro != undefined) {
      url += `&openLastFilter=false`;

      // Tipo Filtro Data
      if (filtro.tipoFiltroData != null && filtro.tipoFiltroData.id > 0)
        url += `&tipoFiltroDataId=${filtro.tipoFiltroData.id}`;

      // Data Inicio
      if (filtro.dataInicio != null) url += `&dataInicio=${filtro.dataInicio}`;

      // Data Termino
      if (filtro.dataTermino != null)
        url += `&dataTermino=${filtro.dataTermino}`;
    }

    return url;
  }

  handleValueClick(item) {
    let url = this.obterFiltroUrl(item);

    history.push({
      pathname: '/gerenciar/valor',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handlePlanoAcaoClick(item) {
    let url = this.obterFiltroUrl(item);

    history.push({
      pathname: '/gerenciar/gantt',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handleMensagemClick(item) {
    ContentManager.addContent(<Mensagem fromItem item={{ id: item.id }} />);
    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  handleRelacionamentosClick(item) {
    let url = this.obterFiltroUrl(item);

    history.push({
      pathname: '/gerenciar/arvore',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handleMatrizResultadosClick(item) {
    let url = this.obterFiltroUrlMatrizResultados(item);

    history.push({
      pathname: '/acompanhar/matriz-resultado',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handleClickSophie(item) {
    this.props.handleClickSophieCallBack &&
      this.props.handleClickSophieCallBack(item);
  }

  handleStatusReport(item) {
    const filterUrl = `?id=${item.id}&activeTab=7`;
    const pathName = '/gerenciar/projeto/edit';
    history.push({
      pathname: pathName,
      search: filterUrl,
    });
    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  handleResumoProjeto(item) {
    history.push(`/gerenciar/projeto/resumo?id=${item.id}`);
  }

  handleIndicadoresEParametrosProjetos(item) {
    history.push({
      pathname: '/gerenciar/projeto/edit',
      search: `?id=${item.id}&activeTab=9`,
    });
  }

  handleTermoAberturaProjetos(item) {
    history.push({
      pathname: '/gerenciar/projeto/edit',
      search: `?id=${item.id}&activeTab=10`,
    });
  }

  handleRelatorioBIClick(item) {
    http
      .post(`/PowerBI/ObterConfiguracoesPorTipoItem`, {
        itemId: item.id,
        tipoItemId: item.tipo?.id,
      })
      .then((response) => {
        if (item.desdobramento) {
          response.data.model.campo = null;
        }
        ContentManager.addContent(
          <EmbedPowerBI
            itemId={item.id}
            relatorioPBI={response.data.model.relatorioPBI}
            embedURL={response.data.model.embedURL}
            workspacePBI={response.data.model.workspacePBI}
            filtros={response.data.model.filtros}
            embedToken={response.data.model.embedToken}
            usarAutenticacaoMicrosoft={
              response.data.model.usarAutenticacaoMicrosoft
            }
          />
        );
        this.setState({
          isLoading: false,
          resources: {},
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });

    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  getInternalTabGestaoMudancas(item) {
    if (item.tipo?.id === TIPO_ITENS.PROJETO) return 1;

    return 1;
  }

  handleRedirectGestaoMudancas(item) {
    const internalTab = this.getInternalTabGestaoMudancas(item);
    history.push({
      pathname: '/minhas-tarefas/gestao-mudancas',
      search: `?tab=1&tabInterna=${internalTab}&isNew=true&entityId=${item.id}`,
    });
  }

  render() {
    let {
      resources,
      item,
      exibirEdicao,
      exibirFicha,
      exibirValor,
      exibirRelacionamento,
      exibirRelatorioAcompanhamento,
      exibirMensagem,
      exportResumo,
      exibirMatrizResultados,
      exibirInteligenciaArtificial,
      showEdit = false,
      labelAI,
      tooltipAI,
      exibirPowerBI = true,
      intl,
      exibirPlanoAcao,
    } = this.props;

    const tipoId = item && item.tipo && item.tipo.id;

    exibirPowerBI = exibirPowerBI
      ? item?.permissions
        ? item?.permissions?.allowViewPowerBI
        : true
      : false;

    if (item && item.permissions && item.permissions.allowBasic) {
      exibirEdicao = false;
      exibirRelacionamento = false;
      exibirPlanoAcao = false;
      exibirMensagem = false;
    } else {
      if (tipoId == 6) {
        exibirMatrizResultados = false;
        exibirRelatorioAcompanhamento = false;
        exibirRelacionamento = true;
        exibirFicha = false;
        exibirValor = false;
      }

      if (item && item.ocultarMenus) {
        exibirEdicao = false;
        exibirFicha = false;
        exibirValor = false;
        exibirRelacionamento = false;
        exibirPlanoAcao = false;
        exibirRelatorioAcompanhamento = false;
        exibirMensagem = false;
        exportResumo = false;
        exibirMatrizResultados = false;
      }

      if (item && item.exibirItensCardMetas) {
        exibirEdicao = false;
        exibirFicha = false;
        exibirValor = false;
        exibirRelacionamento = false;
        exibirPlanoAcao = false;
        exibirRelatorioAcompanhamento = false;
        exibirMensagem = false;
        exportResumo = false;
        exibirMatrizResultados = true;
      }

      return [
        showEdit && (
          <DropdownItem key="1" onClick={this.handleEditClick.bind(this, item)}>
            <MdiIcon className="mr-2" icon="pencil" />
            <FormattedMessage
              id="label.editar"
              values={{ editar: resources.editar }}
            />
          </DropdownItem>
        ),
        exibirRelacionamento && (
          <DropdownItem
            key="4"
            onClick={this.handleRelacionamentosClick.bind(this, item)}
          >
            <MdiIcon className="mr-2" icon="sitemap" />
            {capitalizeFirstLetter(resources.relacionamentos)}
          </DropdownItem>
        ),

        exibirPlanoAcao && tipoId !== 2 && (
          <DropdownItem
            key="6"
            onClick={this.handlePlanoAcaoClick.bind(this, item)}
          >
            <MdiIcon className="mr-2" icon="chart-gantt" />
            {capitalizeFirstLetter(resources.planodeacao)}
          </DropdownItem>
        ),

        exibirMensagem && (
          <DropdownItem
            key="10"
            onClick={this.handleMensagemClick.bind(this, item)}
          >
            <MdiIcon className="mr-2" icon="comment-text-outline" />
            <FormattedMessage id="label.mensagens" />
          </DropdownItem>
        ),

        exibirPowerBI && (
          <DropdownItem
            key="13"
            onClick={this.handleRelatorioBIClick.bind(this, item)}
          >
            <MdiIcon className="mr-2" icon="clipboard" />
            <FormattedMessage id="bi" />
          </DropdownItem>
        ),

        exibirInteligenciaArtificial && (
          <>
            <DropdownItem
              id="menuAI"
              key="16"
              onClick={this.handleClickSophie.bind(this, item)}
            >
              <img alt="IA logo" src="/ia/ia_cinza.svg" className="pr-2" />
              {intl.formatMessage(
                { id: labelAI },
                { riscos: resources.riscos }
              )}
            </DropdownItem>
            {tooltipAI && (
              <UncontrolledTooltip placement="auto" target={'menuAI'}>
                {this.props.intl.formatMessage(
                  {
                    id: tooltipAI,
                  },
                  { riscos: resources.riscos }
                )}
              </UncontrolledTooltip>
            )}
          </>
        ),
      ];
    }
  }
}

function mapStateToProps(state) {
  return {
    modules: state.user.licenca.modulos,
  };
}

export default injectIntl(connect(mapStateToProps)(DropdownItemRisco));
