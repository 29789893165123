import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class StatusApresentacao extends React.Component {
  static defaultProps = {
    clearable: true,
  };

  render() {
    let { clearable } = this.props;

    return <Lookup clearable labelKey="descricao" valueKey="id" url="/StatusApresentacaoLookup" {...this.props} />;
  }
}

export default StatusApresentacao;
