import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EditDialog from 'src/componentes/edit/edit-dialog';
import FormEdit from './edit-form';
import { Schema } from 'src/utils/form';
import { capitalizeFirstLetter } from 'src/utils/string';

const schema = Schema.object({
  id: Schema.number().label(),
  item: Schema.string().label(<FormattedMessage id="item" />),
  dataPeriodo: Schema.string().label(<FormattedMessage id="periodo" />),
  status: Schema.string().label(),
  layout: Schema.string().label(),
});

const Edit = ({ parameters, ...props }) => {
  const intl = useIntl();

  return (
    <EditDialog
      url="/PlanejamentoInicial"
      title={capitalizeFirstLetter(
        intl.formatMessage({ id: 'planejamentoInicial' })
      )}
      formComponent={FormEdit}
      schema={schema}
      width="85%"
      maxHeight="90%"
      parameters={parameters}
      {...props}
      showExport={false}
      showDelete={false}
      showActions={false}
      validateAlterModel={false}
    />
  );
};

export default Edit;
