import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Badge,
  Row,
  Col,
} from 'reactstrap';

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';

export default function TipoOrdenacaoItem({
  tipoOrdenacaoId,
  handleTipoOrdenacao,
}) {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  useEffect(() => {
    setTexto(getTextoDefault());
  }, [tipoOrdenacaoId]);

  const optionsTipoOrdenacao = [
    {
      id: 1,
      descricao: intl.formatMessage({ id: resources.meta }),
      icon: 'arrow-up',
    },
    {
      id: 2,
      descricao: intl.formatMessage({ id: resources.meta }),
      icon: 'arrow-down',
    },
    {
      id: 3,
      descricao: intl.formatMessage({ id: 'realizado' }),
      icon: 'arrow-up',
    },
    {
      id: 4,
      descricao: intl.formatMessage({ id: 'realizado' }),
      icon: 'arrow-down',
    },
    {
      id: 5,
      descricao: intl.formatMessage({ id: 'desvio' }),
      icon: 'arrow-up',
    },
    {
      id: 6,
      descricao: intl.formatMessage({ id: 'desvio' }),
      icon: 'arrow-down',
    },
    {
      id: 7,
      descricao: intl.formatMessage({ id: 'percentualDoDesvioAbreviado' }),
      icon: 'arrow-up',
    },
    {
      id: 8,
      descricao: intl.formatMessage({ id: 'percentualDoDesvioAbreviado' }),
      icon: 'arrow-down',
    },
    {
      id: 9,
      descricao: intl.formatMessage(
        { id: 'label.metaAcumulado' },
        { meta: resources.meta }
      ),
      icon: 'arrow-up',
    },
    {
      id: 10,
      descricao: intl.formatMessage(
        { id: 'label.metaAcumulado' },
        { meta: resources.meta }
      ),
      icon: 'arrow-down',
    },
    {
      id: 11,
      descricao: intl.formatMessage({ id: 'realizadoAcumulado' }),
      icon: 'arrow-up',
    },
    {
      id: 12,
      descricao: intl.formatMessage({ id: 'realizadoAcumulado' }),
      icon: 'arrow-down',
    },
    {
      id: 13,
      descricao: intl.formatMessage({ id: 'label.desvioAcum' }),
      icon: 'arrow-up',
    },
    {
      id: 14,
      descricao: intl.formatMessage({ id: 'label.desvioAcum' }),
      icon: 'arrow-down',
    },
    {
      id: 15,
      descricao: intl.formatMessage({
        id: 'percentualDoDesvioAcumuladoAbreviado',
      }),
      icon: 'arrow-up',
    },
    {
      id: 16,
      descricao: intl.formatMessage({
        id: 'percentualDoDesvioAcumuladoAbreviado',
      }),
      icon: 'arrow-down',
    },
    {
      id: 17,
      descricao: intl.formatMessage({
        id: 'idIntegracao',
      }),
      icon: 'arrow-up',
    },
    {
      id: 18,
      descricao: intl.formatMessage({
        id: 'idIntegracao',
      }),
      icon: 'arrow-down',
    },
    {
      id: 19,
      descricao: intl.formatMessage({
        id: 'nome',
      }),
      icon: 'arrow-up',
    },
    {
      id: 20,
      descricao: intl.formatMessage({
        id: 'nome',
      }),
      icon: 'arrow-down',
    },
    {
      id: 21,
      descricao: intl.formatMessage({
        id: 'ordemCadastro',
      }),
      icon: 'arrow-up',
    },
    {
      id: 22,
      descricao: intl.formatMessage({
        id: 'ordemCadastro',
      }),
      icon: 'arrow-down',
    },
    {
      id: 23,
      descricao: capitalizeFirstLetter(resources.desempenho),
      icon: 'arrow-up',
    },
    {
      id: 24,
      descricao: capitalizeFirstLetter(resources.desempenho),
      icon: 'arrow-down',
    },
    {
      id: 25,
      descricao: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'desempenhoAcumulado' },
          { desempenho: resources.desempenho }
        )
      ),
      icon: 'arrow-up',
    },
    {
      id: 26,
      descricao: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'desempenhoAcumulado' },
          { desempenho: resources.desempenho }
        )
      ),
      icon: 'arrow-down',
    },
  ];

  const getTextoDefault = () => {
    if (!tipoOrdenacaoId) {
      return (
        <>
          {intl.formatMessage({ id: 'realizado' })}
          <MdiIcon icon="arrow-up" size={14}></MdiIcon>
        </>
      );
    }

    const tipoOrdenacaoSelecionado = optionsTipoOrdenacao.find(
      (x) => x.id == tipoOrdenacaoId
    );

    if (tipoOrdenacaoSelecionado) {
      return (
        <>
          {tipoOrdenacaoSelecionado.descricao}
          <MdiIcon icon={tipoOrdenacaoSelecionado.icon} size={14}></MdiIcon>
        </>
      );
    } else {
      return (
        <>
          {intl.formatMessage({ id: 'realizado' })}
          <MdiIcon icon="arrow-up" size={14}></MdiIcon>
        </>
      );
    }
  };

  const [texto, setTexto] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleChangeTipoOrdenacao = (label, icon, idOrdenacao) => {
    const novoTexto = (
      <>
        {label}
        <MdiIcon icon={icon} size={14}></MdiIcon>
      </>
    );
    setTexto(novoTexto);
    setShowMenu(false);

    handleTipoOrdenacao && handleTipoOrdenacao(idOrdenacao);
  };

  const renderItemMenu = (key, label, idAsc, idDesc) => {
    return (
      <DropdownItem key={key}>
        <Row>
          <Col
            onClick={() => handleChangeTipoOrdenacao(label, 'arrow-up', idAsc)}
          >
            {label}
          </Col>
          <Col style={{ maxWidth: '10px' }}>
            <>
              <MdiIcon
                title={
                  idAsc == 17 || idAsc == 18 || idAsc == 19 || idAsc == 20
                    ? intl.formatMessage({ id: 'ordenarNome' })
                    : intl.formatMessage({ id: 'ordernarMenorMaior' })
                }
                size={15}
                icon="arrow-up"
                id={'arrow' + key}
                onClick={() =>
                  handleChangeTipoOrdenacao(label, 'arrow-up', idAsc)
                }
              />
            </>
          </Col>
          <Col style={{ maxWidth: '10px' }}>
            <MdiIcon
              title={
                idAsc == 17 || idAsc == 18 || idAsc == 19 || idAsc == 20
                  ? intl.formatMessage({ id: 'ordenarNomeDesc' })
                  : intl.formatMessage({ id: 'ordernarMaiorMenor' })
              }
              size={15}
              icon="arrow-down"
              onClick={() =>
                handleChangeTipoOrdenacao(label, 'arrow-down', idDesc)
              }
            />
          </Col>
        </Row>
      </DropdownItem>
    );
  };

  return (
    <div style={{ display: 'inline-block', maxWidth: '100%' }}>
      <UncontrolledDropdown direction="right">
        <DropdownToggle tag={'a'} style={{ padding: 'inherit' }}>
          <Badge
            style={{ display: 'flex', alignItems: 'center', minWidth: '70px' }}
            color={'primary'}
            onClick={() => handleShowMenuClick()}
          >
            {texto}
            <div style={{ marginLeft: 'auto' }}>
              <MdiIcon
                icon={showMenu ? 'chevron-up' : 'chevron-down'}
                size={14}
              ></MdiIcon>
            </div>
          </Badge>
        </DropdownToggle>
        <DropdownMenu container="body">
          {renderItemMenu(
            '1',
            intl.formatMessage({ id: resources.meta }),
            1,
            2
          )}
          {renderItemMenu('2', intl.formatMessage({ id: 'realizado' }), 3, 4)}
          {renderItemMenu('3', intl.formatMessage({ id: 'desvio' }), 5, 6)}
          {renderItemMenu(
            '4',
            intl.formatMessage({ id: 'percentualDoDesvioAbreviado' }),
            7,
            8
          )}
          {renderItemMenu(
            '5',
            intl.formatMessage(
              { id: 'label.metaAcumulado' },
              { meta: resources.meta }
            ),
            9,
            10
          )}
          {renderItemMenu(
            '6',
            intl.formatMessage({ id: 'realizadoAcumulado' }),
            11,
            12
          )}
          {renderItemMenu(
            '7',
            intl.formatMessage({ id: 'label.desvioAcum' }),
            13,
            14
          )}
          {renderItemMenu(
            '8',
            intl.formatMessage({ id: 'percentualDoDesvioAcumuladoAbreviado' }),
            15,
            16
          )}
          {renderItemMenu(
            '9',
            intl.formatMessage({ id: 'idIntegracao' }),
            17,
            18
          )}
          {renderItemMenu('10', intl.formatMessage({ id: 'nome' }), 19, 20)}
          {renderItemMenu(
            '11',
            intl.formatMessage({ id: 'ordemCadastro' }),
            21,
            22
          )}
          {renderItemMenu(
            '12',
            capitalizeFirstLetter(resources.desempenho),
            23,
            24
          )}
          {renderItemMenu(
            '13',
            capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'desempenhoAcumulado' },
                { desempenho: resources.desempenho }
              )
            ),
            25,
            26
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
