import axios from 'axios';
import ConfigApp from 'src/services/configApp';
import authStorage from 'src/services/authStorage';
import { getSession, setSession } from 'src/contexts/AuthContext';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

const http = axios.create({
  baseURL: ConfigApp.ApiUrl,
  timeout: ConfigApp.ApiTimeOut,
});

http.interceptors.request.use(
  (config) => {
    const { externalAccessToken } = getSession();
    if (externalAccessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${externalAccessToken}`;
    }

    // Esta opção irá permitir o envio e recebimento de Cookie através do Cors
    // Cookie é utilizado para definição da linguagem do Usuário
    config.withCredentials = true;
    // TODO: Verificar pq precisa desta configuração
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => {
    authStorage.setLastRequestDate();
    return response;
  },
  async (error) => {
    try {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (status === 401) {
        const { externalAccessToken, refreshToken, tokenExpirationTime } = getSession();
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;

          if (externalAccessToken && tokenExpirationTime && refreshToken) {
            const dataUltimaRequisicao = authStorage.getLastRequestDate();

            const diferenca = new Date() - dataUltimaRequisicao;
            const diferencaData = new Date(diferenca);
            const diferencaEmMinutos = diferencaData.getTime() / 1000 / 60;

            if (tokenExpirationTime != null && diferencaEmMinutos >= tokenExpirationTime) {
              await http.post(`/Home/Logout`, { refreshToken });
              setSession(null);
              window.location.href = '/';
            } else {
              const response = await http.post(`/Home/ObterRefreshToken`, {
                refreshToken,
              });
              const jwt = response.data;
              setSession({
                accessToken: jwt?.token,
                refreshToken: jwt?.refreshToken,
                tokenExpirationTime: jwt?.expiraTokenMinutos,
              });
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(jwt?.token);
            }
          } else {
            setSession(null);
          }
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token) => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            // Corrige o Bug da baseUrl ser só '/api', então a requisição estava sendo feita como /api/api
            if (originalRequest.baseURL == '/api' && originalRequest.url.startsWith('/api'))
              originalRequest.url = originalRequest.url.substring(4, originalRequest.url.length);
            resolve(axios(originalRequest));
          });
        });

        return retryOriginalRequest;
      }
    } catch (error) {}

    return Promise.reject(error);
  }
);

export default http;
