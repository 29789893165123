import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select-async';
import ApresentacaoList from '../paginas/apresentacao';

@autobind
class ApresentacaoLookup extends React.Component {
  render() {
    return <Lookup showSearch labelKey="nome" valueKey="id" url="/ApresentacaoLookup" clearable={false} searchComponent={ApresentacaoList} {...this.props} />;
  }
}

export default ApresentacaoLookup;
