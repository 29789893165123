import React, { useRef, useState } from 'react';
import { autobind } from 'core-decorators';
import { injectIntl, useIntl } from 'react-intl';
import Table from 'src/componentes/table';
import { Row, Col } from 'reactstrap';
import Button from 'src/componentes/button';
import IconButton from 'src/componentes/icon-button';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from 'src/utils/string';
import Panel from 'src/componentes/panel';
import useAuth from 'src/hooks/useAuth';
import PlanoContigenciaListView from 'src/paginas/gestao-risco/plano-contigencia/PlanoContigenciaListView';
import Dialog from 'src/componentes/dialog';

const PlanosContingenciaVinculadosBuscarView = ({
  model,
  allowNew,
  requestChange,
  handleNewClick,
  ...rest
}) => {
  const ref = useRef();
  const intl = useIntl();
  const [selecteds, setSelecteds] = useState([]);

  const onSelect = (newList) => {
    setSelecteds(newList);
  };

  const handleCancelClick = () => {
    ref.current.close();
  };

  const handleOkClick = () => {
    requestChange && requestChange(selecteds);
    handleCancelClick();
  };

  const actions = [
    <Button onClick={handleCancelClick} color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      className="ml-2"
      type="primary"
      onClick={handleOkClick}
      htmlType="submit"
    >
      {intl.formatMessage({ id: 'label.salvar' })}
    </Button>,
  ];
  
  return (
    <Dialog
      {...rest}
      ref={ref}
      width="80%"
      height="80%"
      actions={actions}
      padContent
      onRequestClose={handleCancelClick}
    >
      <Panel
        header={intl.formatMessage({ id: 'labelPlanosContingenciaVinculados' })}
      >
        <PlanoContigenciaListView
          openLastFilter={false}
          select
          multiple
          isDisabled={true}
          onSelect={onSelect}
          selectedItems={model}
        />
      </Panel>
    </Dialog>
  );
};

export default PlanosContingenciaVinculadosBuscarView;
