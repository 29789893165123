import { useRef, useState } from 'react';
import Dialog from 'src/componentes/dialog';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import Input from 'src/componentes/input';
import { Row, Col } from 'reactstrap';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import { capitalizeFirstLetter, uperCaseAllLetter } from 'src/utils/string';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import Button from 'src/componentes/button';
import TipoInicioPrevistoLookup from 'src/lookups/tipo-inicio-previsto';
import ContentManager from 'src/componentes/content-manager';
import Alert from 'src/componentes/message-box/alert';
import InputNumber from 'src/componentes/input-number';

const TemplateAcaoAdd = ({
  handleChangeCallBack,
  isDisabled = false,
  permitirPlanoContigenciaSemResponsavel = false,
  model = [],
  formEdit = {},
  isNew = false,
  ...rest
}) => {
  const ref = useRef();
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const [errors, setErrors] = useState({});

  const [form, setForm] = useState(formEdit);

  const handleChange = (field, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateRequiredField = (
    form,
    permitirPlanoContigenciaSemResponsavel
  ) => {
    const requiredFields = [
      'descricao',
      'idIntegracao',
      'tipoPrevisto',
      'duracao',
    ];

    if (!permitirPlanoContigenciaSemResponsavel)
      requiredFields.push('responsavel');

    if (form?.tipoPrevisto?.id == 1) requiredFields.push('diasInicioPrevisto');

    const errors = {};
    let hasError = false;

    for (const field of requiredFields) {
      const value = _.get(form, field);
      if (value == null) {
        errors[field] = true;
        hasError = true;
      }
    }

    setErrors(errors);

    return { hasError };
  };

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const handleSave = () => {
    var { hasError } = validateRequiredField(
      form,
      permitirPlanoContigenciaSemResponsavel
    );

    if (!hasError) {
      if (isNew) {
        if (!model) model = [];
        const formNew = { permissions: { allowEdit: true }, ...form };
        model.push(formNew);
      } else {
        const index = model.findIndex((item) => item.id === form.id);
        if (index !== -1) {
          model[index] = form;
        }
      }
      handleChangeCallBack('templateAcoes', model);
      handleClose();
    } else {
      ContentManager.addContent(
        <Alert
          message={intl.formatMessage({
            id: 'label.osCamposDestacadosEmVermelhoSaoObrigatoriosFavorPreenchelos',
          })}
        />
      );
    }
  };

  const editActions = [
    <Button key={1} onClick={handleClose} color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      key={2}
      disabled={isDisabled}
      type="primary"
      onClick={handleSave}
      htmlType="submit"
      className="ml-2"
    >
      {intl.formatMessage({ id: 'label.salvar' })}
    </Button>,
  ];

  return (
    <Dialog
      {...rest}
      ref={ref}
      minWidth={'60%'}
      maxWidth={'70%'}
      minHeight={'40%'}
      onRequestClose={handleClose}
      padContent
      actions={editActions}
      title={uperCaseAllLetter(
        intl.formatMessage(
          { id: 'labelCriacaoAcaoPlanoContigencia' },
          { acao: resources.acao }
        )
      )}
    >
      <Row>
        <Col md={4}>
          <Input
            disabled={isDisabled}
            type="textarea"
            required
            rows={6}
            model={{
              label: intl.formatMessage({ id: 'descricao' }),
              value: form?.descricao,
              requestChange: (v) => handleChange('descricao', v),
              errors: errors?.descricao,
            }}
          />
        </Col>
        <Col md={8}>
          <Row>
            <Col md={4}>
              <Input
                required
                disabled={isDisabled}
                type="text"
                style={{ height: '37px' }}
                model={{
                  label: intl.formatMessage({ id: 'codigo' }),
                  value: form?.idIntegracao,
                  requestChange: (v) => handleChange('idIntegracao', v),
                  errors: errors?.idIntegracao,
                }}
              />
            </Col>
            <Col md={5}>
              <ColaboradorSimplificadoLookup
                disabled={isDisabled}
                required={!permitirPlanoContigenciaSemResponsavel}
                model={{
                  label: capitalizeFirstLetter(resources.responsavel),
                  value: form?.responsavel,
                  requestChange: (v) => handleChange('responsavel', v),
                  errors: errors?.responsavel,
                }}
              />
            </Col>
            <Col md={3}>
              <div className="mt-3 ml-5">
                <div style={{ marginTop: -15, marginRight: 5 }}>
                  {intl.formatMessage({ id: 'anexo' })}
                </div>
                <ArquivoUploadAnexos
                  formGroupStyle={{ marginBottom: 0 }}
                  onlyRead={isDisabled}
                  tipoAnexo={36}
                  model={{
                    value: form?.arquivos,
                    requestChange: (arquivos) =>
                      handleChange('arquivos', arquivos),
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <TipoInicioPrevistoLookup
                disabled={isDisabled}
                required
                model={{
                  label: intl.formatMessage({ id: 'labelTipoInicioPrevisto' }),
                  value: form?.tipoPrevisto,
                  requestChange: (v) => handleChange('tipoPrevisto', v),
                  errors: errors?.tipoPrevisto,
                }}
              />
            </Col>
            {form?.tipoPrevisto?.id == 1 && (
              <Col md={4} className="pr-0">
                <InputNumber
                  disabled={isDisabled}
                  integer={true}
                  required
                  type="text"
                  style={{ height: '37px' }}
                  model={{
                    label: intl.formatMessage(
                      { id: 'labelDiasUteisInicioAcao' },
                      { acao: resources.acao }
                    ),
                    value: form?.diasInicioPrevisto,
                    requestChange: (v) => handleChange('diasInicioPrevisto', v),
                    errors: errors?.diasInicioPrevisto,
                  }}
                />
              </Col>
            )}
            <Col md={4} className="pr-0">
              <InputNumber
                disabled={isDisabled}
                required
                integer={true}
                type="text"
                style={{ height: '37px' }}
                model={{
                  label: intl.formatMessage({ id: 'labelDuracaoDiasUteis' }),
                  value: form?.duracao,
                  requestChange: (v) => handleChange('duracao', v),
                  errors: errors?.duracao,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
};

export default TemplateAcaoAdd;
