import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list/tree';
import ListColumn from '../../../componentes/list/list-column';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PacoteEdit from './edit';
import Search from './search';
import SearchModal from './search-modal';
import SearchSchema from './search-schema';
import SearchSchemaModal from './search-schema-modal';
import ContentManager from '../../../componentes/content-manager';
import Edit from './edit';
import { capitalizeFirstLetter } from '../../../utils/string';
import history from '../../../history';
import ValidacaoRestricao from '../../item/validacao-restricao';
import http from 'src/services/httpService';

@autobind
class PacoteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      exibirClassificacao: false,
      changedColumns: false,
    };
  }

  componentDidMount() {
    http.post(`/Pacote/ObterConfiguracaoSistema`, {}).then((response) => {
      this.setState({
        exibirClassificacao: response.data.exibirClassificacao,
        changedColumns: true,
      });
    });
  }

  handleNewClick(item, parameters, handleEditClose) {
    ContentManager.addContent(<Edit mode="edit" parameters={{ ...parameters, parentId: item.id, tipoId: item.idTipo }} handleClose={handleEditClose} />);
  }

  renderTipo(item) {
    return <span>{item.tipo && item.tipo.descricao}</span>;
  }

  handlePlanoAcaoClick(item) {
    history.push({
      pathname: '/gerenciar/gantt',
      search: `?pacoteId=${item.id}`,
    });
  }

  handleValoresPacoteClick(item) {
    history.push({
      pathname: '/mo/cadastrar/valores-pacote',
      search: `?pacoteId=${item.id}`,
    });
  }

  handleParetoDesviosClick(item) {
    history.push({
      pathname: '/mo/acompanhar/desvios-pacote-conta',
      search: `?pacoteId=${item.id}&tipoDesvioId=2`,
    });
  }

  handleBoasPraticasClick(item) {
    history.push({
      pathname: '/mo/acompanhar/boas-praticas',
      search: `?pacoteId=${item.id}`,
    });
  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao url="/Pacote/ValidarRestricoes" urlExclusao="/Pacote/AtualizarStatusExcluido" item={item} onClose={this.handleClose} />
    );
  }

  handleClose(saved) {
    if (saved) this.setState({ saved: !this.state.saved });
  }

  render() {
    let { resources, intl, showModal } = this.props;
    let { exibirClassificacao } = this.state;

    let createChildItems = [
      {
        label: <FormattedMessage id="label.pacoteSubordinado" />,
        icon: 'format-list-bulleted',
        visible: () => true,
        onClick: this.handleNewClick,
      },
      {
        label: capitalizeFirstLetter(resources.planodeacao),
        icon: 'chart-gantt',
        visible: () => true,
        onClick: this.handlePlanoAcaoClick,
      },
      {
        label: <FormattedMessage id="valoresPacote" values={{ valores: resources.valores }} />,
        icon: 'chart-bar',
        visible: () => true,
        onClick: this.handleValoresPacoteClick,
      },
      {
        label: <FormattedMessage id="paretoDesvios" />,
        icon: 'chart-line',
        visible: () => true,
        onClick: this.handleParetoDesviosClick,
        // },{
        //   label: <FormattedMessage id="boasPraticas" />,
        //   icon: 'certificate',
        //   visible: (() => true),
        //   onClick: this.handleBoasPraticasClick
      },
    ];

    return (
      <List
        url="/Pacote"
        editComponent={PacoteEdit}
        createChildItems={createChildItems}
        showSearch={true}
        hierarchy={true}
        onDeleting={this.handleDeleteClick}
        searchComponent={showModal ? <SearchModal exibirClassificacao={exibirClassificacao} /> : <Search exibirClassificacao={exibirClassificacao} />}
        searchSchema={showModal ? SearchSchemaModal : SearchSchema}
        tag={showModal ? 10010 : 10002}
        filtroId={showModal ? 102 : 101}
        saved={this.state.saved}
        usarFiltroSalvo={!showModal}
        openLastFilter={!showModal}
        changedColumns={this.state.changedColumns}
        {...this.props}
      >
        <ListColumn tree={true} headerText={intl.formatMessage({ id: 'nome' })} valueField="nome" sortField="nome" default required />
        <ListColumn headerText={intl.formatMessage({ id: 'registroSuperior' })} valueField="registroSuperior" default />
        <ListColumn headerText={intl.formatMessage({ id: 'codigo' })} required valueField="idIntegracao" default sortField="idIntegracao" />
        <ListColumn headerText={intl.formatMessage({ id: 'dono' })} valueField="dono" />
        <ListColumn headerText={intl.formatMessage({ id: 'gestores' })} valueField="gestores" />
        <ListColumn headerText={intl.formatMessage({ id: 'tipo' })} valueField="tipo" valueFunction={this.renderTipo} />
        {exibirClassificacao && <ListColumn headerText={intl.formatMessage({ id: 'label.classificacao' })} valueField="classificacao" minWidth={120} />}
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PacoteList));
