import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col, ButtonToolbar } from 'reactstrap';
import { autobind } from 'core-decorators';

import Dialog from 'src/componentes/dialog';
import InputNumber from 'src/componentes/input-number';
import { keydown, KEYS } from 'src/utils/keydown-decorator';
import Button from 'src/componentes/button';
import Color from 'src/componentes/color';
import EstiloBordaLookup from 'src/lookups/estilo-borda';
import Input from 'src/componentes/input';
import guid from 'src/utils/guid';

import './styles.scss';

@autobind
class IshikawaEdit extends React.Component {
  static defaultProps = {
    disabled: false,
    showPropertiesStyle: true,
  };

  constructor(props) {
    super(props);
    this.saved = false;

    this.state = {
      widget: { ...this.props.widget } || null,
    };
  }

  handleAddItem(itemGuid) {
    const newWidget = JSON.parse(JSON.stringify(this.state.widget));

    // cabeça do ishikawa
    if (newWidget.item.componente.dadosIshikawa.guid === itemGuid) {
      const newItem = {
        guid: guid(),
        causaFundamental: false,
        descricao: '',
        itens: [],
      };

      if (newWidget.item.componente.dadosIshikawa.itens === null)
        newWidget.item.componente.dadosIshikawa.itens = [];

      newWidget.item.componente.dadosIshikawa.itens.push(newItem);
    } else {
      // demais categorias
      newWidget.item.componente.dadosIshikawa.itens.forEach(function iter(a) {
        if (itemGuid === a.guid) {
          const newItem = {
            guid: guid(),
            causaFundamental: false,
            descricao: '',
            itens: [],
          };

          if (!a.itens) {
            a.itens = [];
            a.itens.push(newItem);
          } else {
            a.itens.push(newItem);
          }
        }
        Array.isArray(a.itens) && a.itens.forEach(iter);
      });
    }

    this.setState({
      widget: {
        ...newWidget,
      },
    });
  }

  handleRemoveItem(itemGuid, parentItemGuid) {
    const newWidget = JSON.parse(JSON.stringify(this.state.widget));

    // cabeça do ishikawa
    if (newWidget.item.componente.dadosIshikawa.guid === parentItemGuid) {
      const filteredArray =
        newWidget.item.componente.dadosIshikawa.itens.filter(function (
          item,
          index,
          arr
        ) {
          return item.guid !== itemGuid;
        });
      newWidget.item.componente.dadosIshikawa.itens = filteredArray;
    } else {
      // demais categorias
      newWidget.item.componente.dadosIshikawa.itens.forEach(function iter(a) {
        if (parentItemGuid === a.guid) {
          const filteredArray = a.itens.filter(function (item, index, arr) {
            return item.guid !== itemGuid;
          });
          a.itens = filteredArray;
        }
        Array.isArray(a.itens) && a.itens.forEach(iter);
      });
    }

    this.setState({
      widget: {
        ...newWidget,
      },
    });
  }

  handleChangeText(guid, value) {
    const newWidget = JSON.parse(JSON.stringify(this.state.widget));

    // cabeça do ishikawa
    if (newWidget.item.componente.dadosIshikawa.guid === guid) {
      newWidget.item.componente.dadosIshikawa.descricao = value;
    } else {
      // demais categorias
      newWidget.item.componente.dadosIshikawa.itens.forEach(function iter(a) {
        if (guid === a.guid) {
          a.descricao = value;
        }
        Array.isArray(a.itens) && a.itens.forEach(iter);
      });
    }

    this.setState({
      widget: {
        ...newWidget,
      },
    });
  }

  renderEditor(itemModel, index = 0, parentItemModel, level = 0) {
    let itensModel = itemModel.itens;
    let isDefault = itemModel.descricao && itemModel.isDefault;

    return [
      <div
        key={`${level}-${index}`}
        className="item"
        style={{ marginLeft: level * 20 }}
      >
        <div className="actions">
          <Button
            onClick={this.handleAddItem.bind(this, itemModel.guid)}
            leftIcon="plus-circle"
            color="primary"
            tabIndex="-1"
          />

          {level > 0 && (
            <Button
              onClick={this.handleRemoveItem.bind(
                this,
                itemModel.guid,
                parentItemModel.guid
              )}
              leftIcon="delete"
              color="secondary"
              disabled={isDefault}
              tabIndex="-1"
            />
          )}
        </div>
        <div className="editor">
          <Input
            model={{
              value: itemModel.descricao,
              label: '',
              requestChange: this.handleChangeText.bind(this, itemModel.guid),
            }}
            disabled={isDefault}
          />
        </div>
      </div>,
      itensModel &&
        itensModel.map((m, i) => this.renderEditor(m, i, itemModel, level + 1)),
    ];
  }

  handleSave() {
    const dadosIshikawaState = {
      ...this.state.widget.item.componente.dadosIshikawa,
    };

    this.saved = true;

    if (this.props.handleSave) {
      this.props.handleSave(dadosIshikawaState, this.props.widget?.index);
    } else {
      let causasModel = this.props.model.getModel('pontosProblematicos');
      let causasValue = causasModel && causasModel.value;

      if (causasValue) {
        for (var causa of causasValue) {
          // cabeça do ishikawa
          if (dadosIshikawaState.guid === causa.causaIshikawaId) {
            causa.descricao = dadosIshikawaState.descricao;
          } else {
            // demais categorias
            dadosIshikawaState.itens.forEach(function iter(a) {
              if (causa.causaIshikawaId === a.guid) {
                causa.descricao = a.descricao;
              }
              Array.isArray(a.itens) && a.itens.forEach(iter);
            });
          }
        }

        causasModel.requestChange(causasModel.value);
      }
    }

    this.handleClose();
  }

  @keydown(KEYS.ESC)
  handleClose() {
    this.refs.dialog.close();
    setTimeout(() => {
      this.props.onRequestClose &&
        this.props.onRequestClose(this.saved, this.state.widget);
    }, 300);
  }

  handleChageValue(field, value) {
    this.setState({
      widget: {
        ...this.state.widget,
        item: {
          ...this.state.widget.item,
          [field]: value,
        },
      },
    });
  }

  editActions = [
    <ButtonToolbar key={1}>
      <Button leftIcon="close" onClick={this.handleClose} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>
      <Button
        type="primary"
        onClick={this.handleSave.bind(this, false)}
        leftIcon="content-save-outline"
        className="ml-2"
      >
        {<FormattedMessage id="label.salvar" />}
      </Button>
    </ButtonToolbar>,
  ];

  render() {
    let { intl, disabled, showPropertiesStyle } = this.props;

    let { widget } = this.state;

    let item = widget.item;

    return (
      <Dialog
        width={590}
        height={500}
        {...this.props}
        actions={this.editActions}
        onRequestClose={this.handleClose}
        ref="dialog"
        padContent
        title={intl.formatMessage({ id: 'propriedadesWidgetIshikawa' })}
      >
        {showPropertiesStyle && (
          <>
            <Row>
              <Col md={4}>
                <InputNumber
                  disabled={disabled}
                  model={{
                    value: item.height,
                    label: intl.formatMessage({ id: 'label.alturaPx' }),
                    requestChange: this.handleChageValue.bind(this, 'height'),
                  }}
                ></InputNumber>
              </Col>
              <Col md={4}>
                <InputNumber
                  disabled={disabled}
                  model={{
                    value: item.width,
                    label: intl.formatMessage({ id: 'label.larguraPx' }),
                    requestChange: this.handleChageValue.bind(this, 'width'),
                  }}
                ></InputNumber>
              </Col>
              <Col md={4}>
                <InputNumber
                  model={{
                    value: item.zIndex,
                    label: intl.formatMessage({ id: 'label.zIndex' }),
                    requestChange: this.handleChageValue.bind(this, 'zIndex'),
                  }}
                ></InputNumber>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputNumber
                  disabled={disabled}
                  model={{
                    value: item.borderWidth,
                    label: intl.formatMessage({ id: 'label.bordaPx' }),
                    requestChange: this.handleChageValue.bind(
                      this,
                      'borderWidth'
                    ),
                  }}
                ></InputNumber>
              </Col>
              <Col md={4}>
                <EstiloBordaLookup
                  disabled={disabled}
                  model={{
                    value: item.borderStyle,
                    label: intl.formatMessage({ id: 'label.tipoDaBorda' }),
                    requestChange: this.handleChageValue.bind(
                      this,
                      'borderStyle'
                    ),
                  }}
                ></EstiloBordaLookup>
              </Col>
              <Col md={4}>
                <Color
                  disabled={disabled}
                  type="number"
                  model={{
                    value: item.borderColor,
                    label: intl.formatMessage({ id: 'label.corDaBorda' }),
                    requestChange: this.handleChageValue.bind(
                      this,
                      'borderColor'
                    ),
                  }}
                ></Color>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <div style={{ padding: 10, width: '100%' }}>
            {this.renderEditor(item.componente.dadosIshikawa)}
          </div>
        </Row>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(IshikawaEdit));
