import { INC_NUMBER_RENDERED_PAGES, SET_CURRENT_PRESENTATION } from '../types';

export const presentationFetched = (presentationId) => ({
  type: SET_CURRENT_PRESENTATION,
  presentationId,
});

export const renderedPagesIncreased = () => ({
  type: INC_NUMBER_RENDERED_PAGES,
});

export const setPresentation = (presentationId) => (dispatch) => dispatch(presentationFetched(presentationId));

export const incNumberRenderedPages = () => (dispatch) => dispatch(renderedPagesIncreased());
