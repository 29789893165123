import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { capitalizeFirstLetter } from 'src/utils/string';

import http from 'src/services/httpService';
import Edit from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import Input from 'src/componentes/input';
import DataInicioTermino from 'src/paginas/item/componentes/inicio-termino';
import InputNumber from 'src/componentes/input-number';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import Historico from './componentes/historico';
import Panel from 'src/componentes/panel';
import TipoLinhaGrafico from 'src/lookups/tipo-linha-grafico';
import Color from 'src/componentes/color';
import Checkbox from 'src/componentes/checkbox';

let schema = Schema.object({
  id: Schema.string(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  dataInicio: Schema.string()
    .label(<FormattedMessage id="label.dataInicio" />)
    .required(),
  dataTermino: Schema.string().label(
    <FormattedMessage id="label.dataTermino" />
  ),
  valorMinimo: Schema.number()
    .label(<FormattedMessage id="valorMinimo" />)
    .required(),
  valorMaximo: Schema.number()
    .label(<FormattedMessage id="valorMaximo" />)
    .required(),
  itemId: Schema.number(),
  tipoLinhaMinimoGrafico: Schema.string().required({
    onlyIf: (context) => {
      return context.parent.value.exibirValoresMinMaxGrafico;
    },
  }),
  corLinhaMinimoGrafico: Schema.string().required({
    onlyIf: (context) => {
      return context.parent.value.exibirValoresMinMaxGrafico;
    },
  }),
  tipoLinhaMaximoGrafico: Schema.string().required({
    onlyIf: (context) => {
      return context.parent.value.exibirValoresMinMaxGrafico;
    },
  }),
  corLinhaMaximoGrafico: Schema.string().required({
    onlyIf: (context) => {
      return context.parent.value.exibirValoresMinMaxGrafico;
    },
  }),
});

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracaoCardMeta: null,
    };
  }

  componentDidMount() {
    this.refreshConfigPage();
  }

  refreshConfigPage() {
    http.post(`/ConfiguracaoCardMeta/ObterConfiguracoes`).then((response) => {
      this.setState({
        configuracaoCardMeta: response.data,
      });
    });
  }

  handleChangeIdIntegracao(value) {
    this.props.model.getModel('idIntegracao').requestChange(value);
  }

  handleChange(field, value) {
    let { model } = this.props;

    if (value != model.getModel(field).value) {
      this.setState({
        campoAlterado: true,
      });
    }

    model.getModel(field).requestChange(value);
  }

  render() {
    let { model, intl } = this.props;

    const id = model.getModel('id') && model.getModel('id').value;
    const isNotNew = id > 0;
    const exibirValoresMinMaxGrafico = model.getModel(
      'exibirValoresMinMaxGrafico'
    ).value;
    const exibirRotuloDadosMinimoGrafico = model.getModel(
      'exibirRotuloDadosMinimoGrafico'
    ).value;
    const exibirRotuloDadosMaximoGrafico = model.getModel(
      'exibirRotuloDadosMaximoGrafico'
    ).value;

    return (
      <Tabs>
        <Tab
          key="1"
          label={capitalizeFirstLetter(intl.formatMessage({ id: 'resumo' }))}
        >
          <div>
            <Row>
              <Col md={3}>
                <Input
                  maxlength={100}
                  required
                  model={{
                    label: model.getModel('idIntegracao').label,
                    value: model.getModel('idIntegracao').value,
                    requestChange: this.handleChange.bind(this, 'idIntegracao'),
                    errors: model.getModel('idIntegracao').errors,
                  }}
                />
              </Col>
              <Col md={6}>
                <DataInicioTermino
                  required
                  dataInicio={{
                    label: model.getModel('dataInicio').label,
                    value: model.getModel('dataInicio').value,
                    requestChange: this.handleChange.bind(this, 'dataInicio'),
                    errors: model.getModel('dataInicio').errors,
                  }}
                  dataTermino={{
                    label: model.getModel('dataTermino').label,
                    value: model.getModel('dataTermino').value,
                    requestChange: this.handleChange.bind(this, 'dataTermino'),
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ marginBottom: 13 }}>
                <Checkbox
                  style={{ marginTop: 5 }}
                  model={{
                    label: intl.formatMessage({
                      id: 'exibirValoresMinMaxGrafico',
                    }),
                    value: model.getModel('exibirValoresMinMaxGrafico').value,
                    requestChange: this.handleChange.bind(
                      this,
                      'exibirValoresMinMaxGrafico'
                    ),
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="font-weight-bolder mb-3">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'valorMinimo' })
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <InputNumber
                  required
                  precision={2}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'valor' })
                    ),
                    value: model.getModel('valorMinimo').value,
                    requestChange: this.handleChange.bind(this, 'valorMinimo'),
                    errors: model.getModel('valorMinimo').errors,
                  }}
                ></InputNumber>
              </Col>
              <Col md={3} className="ml-n1">
                <TipoLinhaGrafico
                  required={exibirValoresMinMaxGrafico}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'label.tipoDeLinha' })
                    ),
                    value: model.getModel('tipoLinhaMinimoGrafico').value,
                    requestChange: this.handleChange.bind(
                      this,
                      'tipoLinhaMinimoGrafico'
                    ),
                    errors:
                      exibirValoresMinMaxGrafico &&
                      model.getModel('tipoLinhaMinimoGrafico').errors,
                  }}
                />
              </Col>
              <Col md={1} className="ml-n1">
                <Color
                  required={exibirValoresMinMaxGrafico}
                  model={{
                    label: intl.formatMessage({ id: 'linha' }),
                    value: model.getModel('corLinhaMinimoGrafico').value,
                    requestChange: this.handleChange.bind(
                      this,
                      'corLinhaMinimoGrafico'
                    ),
                    errors:
                      exibirValoresMinMaxGrafico &&
                      model.getModel('corLinhaMinimoGrafico').errors,
                  }}
                ></Color>
              </Col>
              <Col md={2}>
                <Checkbox
                  model={{
                    label: intl.formatMessage({
                      id: 'exibirRotulo',
                    }),
                    value: model.getModel('exibirRotuloDadosMinimoGrafico')
                      .value,
                    requestChange: this.handleChange.bind(
                      this,
                      'exibirRotuloDadosMinimoGrafico'
                    ),
                  }}
                />
              </Col>
              {model.getModel('exibirRotuloDadosMinimoGrafico').value && (
                <>
                  <Col md={1} className="ml-n1">
                    <Color
                      required={exibirRotuloDadosMinimoGrafico}
                      disabled={!exibirRotuloDadosMinimoGrafico}
                      model={{
                        label: intl.formatMessage({ id: 'label.rotulo' }),
                        value: model.getModel(
                          'corFonteRotuloDadosMinimoGrafico'
                        ).value,
                        requestChange: this.handleChange.bind(
                          this,
                          'corFonteRotuloDadosMinimoGrafico'
                        ),
                        errors:
                          exibirRotuloDadosMinimoGrafico &&
                          model.getModel('corFonteRotuloDadosMinimoGrafico')
                            .errors,
                      }}
                    ></Color>
                  </Col>
                  <Col md={2}>
                    <InputNumber
                      style={{ width: 80 }}
                      required={exibirRotuloDadosMinimoGrafico}
                      disabled={!exibirRotuloDadosMinimoGrafico}
                      model={{
                        label: intl.formatMessage({ id: 'fonte' }),
                        value: model.getModel(
                          'tamanhoFonteRotuloDadosMinimoGrafico'
                        ).value,
                        requestChange: this.handleChange.bind(
                          this,
                          'tamanhoFonteRotuloDadosMinimoGrafico'
                        ),
                        errors:
                          exibirRotuloDadosMinimoGrafico &&
                          model.getModel('tamanhoFonteRotuloDadosMinimoGrafico')
                            .errors,
                      }}
                    ></InputNumber>
                  </Col>
                </>
              )}
            </Row>

            <Row>
              <Col md={3}>
                <div className="font-weight-bolder mb-3">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'valorMaximo' })
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <InputNumber
                  precision={2}
                  required
                  onBlur={this.handleSave}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'valor' })
                    ),
                    value: model.getModel('valorMaximo').value,
                    requestChange: this.handleChange.bind(this, 'valorMaximo'),
                    errors: model.getModel('valorMaximo').errors,
                  }}
                ></InputNumber>
              </Col>
              <Col md={3} className="ml-n1">
                <TipoLinhaGrafico
                  required={exibirValoresMinMaxGrafico}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'label.tipoDeLinha' })
                    ),
                    value: model.getModel('tipoLinhaMaximoGrafico').value,
                    requestChange: this.handleChange.bind(
                      this,
                      'tipoLinhaMaximoGrafico'
                    ),
                    errors:
                      exibirValoresMinMaxGrafico &&
                      model.getModel('tipoLinhaMaximoGrafico').errors,
                  }}
                />
              </Col>
              <Col md={1} className="ml-n1">
                <Color
                  required={exibirValoresMinMaxGrafico}
                  model={{
                    label: intl.formatMessage({ id: 'linha' }),
                    value: model.getModel('corLinhaMaximoGrafico').value,
                    requestChange: this.handleChange.bind(
                      this,
                      'corLinhaMaximoGrafico'
                    ),
                    errors:
                      exibirValoresMinMaxGrafico &&
                      model.getModel('corLinhaMaximoGrafico').errors,
                  }}
                ></Color>
              </Col>
              <Col md={2}>
                <Checkbox
                  model={{
                    label: intl.formatMessage({
                      id: 'exibirRotulo',
                    }),
                    value: model.getModel('exibirRotuloDadosMaximoGrafico')
                      .value,
                    requestChange: this.handleChange.bind(
                      this,
                      'exibirRotuloDadosMaximoGrafico'
                    ),
                  }}
                />
              </Col>
              {model.getModel('exibirRotuloDadosMaximoGrafico').value && (
                <>
                  <Col md={1} className="ml-n1">
                    <Color
                      required={exibirRotuloDadosMaximoGrafico}
                      disabled={!exibirRotuloDadosMaximoGrafico}
                      model={{
                        label: intl.formatMessage({ id: 'label.rotulo' }),
                        value: model.getModel(
                          'corFonteRotuloDadosMaximoGrafico'
                        ).value,
                        requestChange: this.handleChange.bind(
                          this,
                          'corFonteRotuloDadosMaximoGrafico'
                        ),
                        errors:
                          exibirRotuloDadosMaximoGrafico &&
                          model.getModel('corFonteRotuloDadosMaximoGrafico')
                            .errors,
                      }}
                    ></Color>
                  </Col>
                  <Col md={2}>
                    <InputNumber
                      style={{ width: 80 }}
                      required={exibirRotuloDadosMaximoGrafico}
                      disabled={!exibirRotuloDadosMaximoGrafico}
                      model={{
                        label: intl.formatMessage({ id: 'fonte' }),
                        value: model.getModel(
                          'tamanhoFonteRotuloDadosMaximoGrafico'
                        ).value,
                        requestChange: this.handleChange.bind(
                          this,
                          'tamanhoFonteRotuloDadosMaximoGrafico'
                        ),
                        errors:
                          exibirRotuloDadosMaximoGrafico &&
                          model.getModel('tamanhoFonteRotuloDadosMaximoGrafico')
                            .errors,
                      }}
                    ></InputNumber>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Tab>
        {isNotNew && (
          <Tab
            key="1"
            label={capitalizeFirstLetter(
              intl.formatMessage({ id: 'historico' })
            )}
          >
            <Row>
              <Col md={12}>
                <Panel>
                  <Historico itemFaixaMinimoMaximoId={id} />
                </Panel>
              </Col>
            </Row>
          </Tab>
        )}
      </Tabs>
    );
  }
}

@autobind
class FaixaMinMaxEdit extends React.Component {
  render() {
    let { intl, parameters, item } = this.props;
    const showDelete = item ? true : false;

    return (
      <Edit
        {...this.props}
        formComponent={FormEdit}
        url="/ItemFaixaMinimoMaximo"
        title={intl.formatMessage({ id: 'label.faixaMinMax' })}
        parameters={parameters}
        schema={schema}
        width="62%"
        height={600}
        showClose={false}
        showDelete={showDelete}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}

export default injectIntl(connect(mapStateToProps)(FaixaMinMaxEdit));
