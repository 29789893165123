import React, { useState, useEffect, useCallback } from 'react';
import http from 'src/services/httpService';
import MdiIcon from 'src/componentes/mdi-icon';

function LegendaFarolRevisao(tipoFarol = -1) {
  const [legenda, setLegenda] = useState([]);

  const getConfigurations = useCallback(async (tipoFarol) => {
    try {
      await http.post(`/LegendaRisco/ObterLegendaFarolRevisao`, tipoFarol).then((response) => {
        setLegenda(response.data.legenda);
      });
    } catch (err) {}
  }, []);

  useEffect(() => {
    getConfigurations(tipoFarol);
  }, [getConfigurations]);

  if (!legenda) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {legenda.map((f, i) => (
        <div key={i} style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
          <MdiIcon icon={f.icone} color={f.cor} colorHover={f.cor} size={22} />
          <span style={{ marginLeft: 3 }}>{f.descricao}</span>
        </div>
      ))}
    </div>
  );
}

export default LegendaFarolRevisao;
