import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import { UncontrolledTooltip, Col, Row } from 'reactstrap';
import Radio from 'src/componentes/radio';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';
import MdiIcon from 'src/componentes/mdi-icon';
import Legenda from 'src/paginas/gestao-risco/legenda-farol-revisao';

import ConformeEfetivo from './ConformeEfetivo';
import RiscoPontoControleRevisaoEdit from '../RevisaoPontoControleEditView';
import { Box } from '@material-ui/core';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import Hint from 'src/paginas/item/componentes/hint';

import SearchSchema from '../RevisaoPontoControleListView/SearchSchema';
import Search from '../RevisaoPontoControleListView/Search';
import { FILTROS } from 'src/utils/constants';
import { getConfiguration } from 'src/services/commonRequests';

@autobind
class Revisao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configuracao: {},
    };
  }
  componentDidMount() {
    getConfiguration('PontoControleConfiguracao')((configuracao) =>
      this.setState({ configuracao: configuracao })
    )();
  }

  dataRevisao(item) {
    if (!item.dataRevisao) return;

    return (
      <span>
        {moment(item.dataRevisao).locale('pt-Br').format('DD/MM/YYYY')}
      </span>
    );
  }

  renderDescricao(item, callback) {
    if (!item.descricao) {
      return;
    }

    if (item.status.id === 1) {
      return (
        <span>
          <a
            tabIndex="-1"
            onClick={this.renderConformeEfetivo.bind(this, item, callback)}
            style={{ cursor: 'pointer' }}
          >
            {item.descricao}
          </a>
        </span>
      );
    } else {
      return <span>{item.descricao}</span>;
    }
  }

  renderConformeEfetivo(item, callback) {
    if (item.id > 0) {
      ContentManager.addContent(
        <ConformeEfetivo
          revisaoId={item.id}
          riscoId={item.risco.id}
          pontoControleId={item.pontoControle.id}
        />
      );
    }
  }

  renderFarol(item) {
    return (
      <span>
        {item.farol && item.farol.cor && (
          <MdiIcon
            icon={item.farol.icone}
            color={item.farol.cor}
            colorHover={item.farol.cor}
            size={22}
          ></MdiIcon>
        )}
      </span>
    );
  }

  renderRisco = (item) => {
    return (
      <a tabindex="-1" onClick={() => handleAbrirRiscoClick(item)}>
        {item.nomeRisco}
      </a>
    );
  };

  renderNomePontoControle = (item) => {
    return (
      <a tabindex="-1" onClick={() => handleAbrirPontoControleClick(item)}>
        {item.descricaoPontoControle}
      </a>
    );
  };

  renderDataRevisao = (item) => {
    if (!item.dataRevisao) return;

    return (
      <a tabindex="-1" onClick={() => handleDataClick(item)}>
        {moment(item.dataRevisao).format('DD/MM/YYYY')}
      </a>
    );
  };

  renderImpactosIndiretos = (item) => {
    return (
      <Box>
        {item &&
          item.impactosIndiretos &&
          item.impactosIndiretos.map((itemImpacto) => (
            <Box>
              <Hint item={item} itemImpacto={itemImpacto} />
            </Box>
          ))}
      </Box>
    );
  };

  renderAnexo = (item) => {
    return (
      <ArquivoUploadAnexos
        formGroupStyle={{ paddingTop: 10, marginBottom: 5 }}
        tipoAnexo={this.props.tiposAnexos.riscoPontoControleRevisao}
        anexoObrigatorio={item.anexoObrigatorio}
        disabled={true}
        model={{
          value: item.arquivos,
        }}
      />
    );
  };

  renderTipoTolerancia(item) {
    if (item && item.tipoTolerancia)
      return this.props.intl.formatMessage({ id: item.tipoTolerancia });
  }

  renderConformidade = (item, index) => {
    var id = `id${item.id}${index}`;
    return (
      <Row className="col-md-12">
        <Col md={12} className="pl-3 pb-0">
          <Radio
            className="p-0 m-0"
            disabled={true}
            name={id}
            model={{
              value: item.conformidade,
            }}
            options={item.opcoesConformidade}
            labelField="descricao"
            idField="id"
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { intl, id, resources, parameters, ignoreFiltroId, ...rest } = this.props;

    return (
      <div>
        <List
          url="/RiscoPontoControleRevisao"
          tipoItem={6}
          parameters={{ ...parameters, idRiscoPontoControle: id }}
          editComponent={RiscoPontoControleRevisaoEdit}
          refresh={this.handlePodeConfirmar}
          showPage
          pageSize={20}
          filtroId={ignoreFiltroId ? null : FILTROS.REVISOES_RISCO_PONTO_CONTROLE}
          showSearch={true}
          showExport={false}
          config={this.state.configuracao}
          searchComponent={<Search />}
          searchSchema={SearchSchema}
          sort="UltimaVersao.DataRevisao desc"
          {...rest}
        >
          <ListColumn
            required
            headerText={intl.formatMessage(
              { id: 'label.dataRevisao' },
              { pontodecontrole: resources.pontodecontrole }
            )}
            sortField="UltimaVersao.DataRevisao"
            valueField="dataRevisao"
            valueFunction={this.dataRevisao}
            default
          />
          <ListColumn
            required
            headerText={intl.formatMessage({ id: 'label.executor' })}
            valueField="responsavelRevisao"
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'status' })}
            valueField="statusRevisao"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(resources.farol)}
            valueField="farol"
            valueFunction={this.renderFarol}
          />
          <ListColumn
            headerText={capitalizeFirstLetter(resources.risco)}
            valueField="risco"
            valueFunction={this.renderRisco}
            default
            sortField="RiscoPontoControle.Risco.UltimaVersao.Nome"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'label.itensDeImpactoIndireto' })
            )}
            valueField="impactosIndiretos"
            valueFunction={this.renderImpactosIndiretos}
            default
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'idIntegracao' })
            )}
            valueField="idIntegracaoPontoControle"
            default
            sortField="RiscoPontoControle.PontoControle.IdIntegracao"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'label.responsavel' })
            )}
            valueField="responsavelRevisao"
            default
            sortField="UltimaVersao.ResponsavelRevisao.Nome"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'periodicidade' })
            )}
            valueField="periodicidadePontoControle"
            sortField="RiscoPontoControle.PontoControle.Periodicidade"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'tipoPontoControle' },
                { pontodecontrole: resources.pontodecontrole }
              )
            )}
            valueField="tipoPontoControle"
            sortField="RiscoPontoControle.PontoControle.TipoPontoControle"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'tolerancia' })
            )}
            valueField="diasToleranciaRevisao"
            sortField="RiscoPontoControle.PontoControle.DiasToleranciaRevisao"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'tipoTolerancia' })
            )}
            valueField="tipoTolerancia"
            sortField="RiscoPontoControle.PontoControle.TipoTolerancia"
            valueFunction={this.renderTipoTolerancia}
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'validacoesPendentes' })
            )}
            valueField="validadoresPendentes"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'observacao' })
            )}
            valueField="observacaoPontoControle"
            minWidth={150}
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'label.comentario' })
            )}
            valueField="comentario"
            sortField="UltimaVersao.Descricao"
            default
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'conformidade' })
            )}
            minWidth={180}
            valueField="conformidade"
            valueFunction={this.renderConformidade}
            sortField="UltimaVersao.Conformidade"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'label.anexo' })
            )}
            valueField="anexos"
            valueFunction={this.renderAnexo}
            default
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'tipoEvidencias' })
            )}
            valueField="tipoEvidencias"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'descricaoRisco' },
                { risco: resources.risco }
              )
            )}
            valueField="descricaoRisco"
            sortField="RiscoPontoControle.Risco.UltimaVersao.Descricao"
          />
        </List>
        <Legenda />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    tiposAnexos: state.user.tiposAnexos,
  };
}
export default injectIntl(connect(mapStateToProps)(Revisao));