import { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ParseError } from 'src/services/downloadFileUrl';
import ListColumn from 'src/componentes/NewList/ListColumn';
import Toolbar from 'src/componentes/NewList/Toolbar';
import Results from 'src/componentes/NewList/Results';
import Button from 'src/componentes/button';
import LoadingContainer from 'src/componentes/loading-container';
import {
  getColumns,
  getFilter,
  getData,
  getPermissions,
  exportList,
} from 'src/componentes/NewList';
import MdiIcon from 'src/componentes/mdi-icon';
import { COLUNAS, FILTROS } from 'src/utils/constants';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';
import SearchSchema from './SearchSchema';
import useAuth from 'src/hooks/useAuth';
import Search from './Search';
import Actions from './Actions';
import EditView from 'src/paginas/gestao-risco/plano-contigencia/PlanoContigenciaEditView';
import ContentManager from 'src/componentes/content-manager';
import { getConfiguration } from 'src/services/commonRequests';
import { Badge } from 'reactstrap';

function PlanoContigenciaListView({
  columnsTag,
  filterTag,
  entityTag,
  endpoint,
  defaultSort,
  defaultSortBy,
  selectedFilter,
  openLastFilter,
  parameters,
  isDisabled = false,
  apenasReunioesUsuarioLogado,
  ...rest
}) {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const [updatedItems] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [filter, setFilter] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(defaultSort);
  const [sortFieldBy, setSortFieldBy] = useState(defaultSortBy);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [configuracao, setConfiguracao] = useState({});

  useEffect(() => {
    getConfiguration('ItemConfiguracaoRisco')(setConfiguracao)();
  }, []);

  const loadColumns = async () => {
    try {
      const currentColumns = await getColumns(
        defaultColumns,
        entityTag,
        columnsTag
      );
      setColumns(currentColumns.columns);
      setAllColumns(currentColumns.allColumns);
    } catch (err) {
      errorHandler(err);
    }
  };

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const newPermissions = await getPermissions(endpoint, parameters);
      setPermissions(newPermissions);

      let newData = [];

      if (newPermissions.allowView || newPermissions.allowList) {
        await loadColumns();

        let newFilter = selectedFilter;
        if (openLastFilter && filterTag)
          newFilter = await getFilter(filterTag, true);

        setFilter(newFilter);
        let valueFilter = {};
        if (newFilter != null) valueFilter = newFilter.valor;

        const model = {
          ...valueFilter,
          searchText,
          apenasReunioesUsuarioLogado,
        };
        newData = await getData(
          endpoint,
          model,
          sortField,
          sortFieldBy,
          pageSize,
          currentPage - 1,
          parameters
        );
        setData(newData.list);
        setCount(newData.count);
      }
      setLoading(false);
      return newData;
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  }, [
    endpoint,
    parameters,
    searchText,
    pageSize,
    currentPage,
    sortField,
    sortFieldBy,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData, pageSize, currentPage, searchText, sortField, sortFieldBy]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  });

  const handleColumnsChange = useCallback(async () => {
    await loadColumns();
    await loadData();
  });

  const handleRefresh = useCallback(async () => {
    await loadData();
  });

  const handleChangeCurrentPage = useCallback((newValue) => {
    setCurrentPage(newValue);
  });

  const handleChangePageSize = useCallback((pageSize) => {
    setPageSize(pageSize);
  });

  const handleSortColumn = useCallback((sort) => {
    setSortField(sort);
  });

  const handleSortByColumn = useCallback((sortBy) => {
    setSortFieldBy(sortBy);
  });

  const handleExport = async (fileExtension) => {
    try {
      setLoading(true);

      let valueFilter = {};
      if (filter != null) valueFilter = filter.valor;

      let newSearchModel = {
        searchText,
        ...valueFilter,
      };

      await exportList(
        newSearchModel,
        endpoint,
        sortField,
        currentPage,
        parameters,
        fileExtension
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandler(ParseError(err));
    }
  };

  const handleEdit = (item = {}, configuracaoRisco, isNew = false) => {
    ContentManager.addContent(
      <EditView
        item={item}
        openEdit={true}
        handleRefresh={handleRefresh}
        endpoint={endpoint}
        disabled={!isNew && !permissions.allowEdit}
        isNew={isNew}
        configuracao={configuracaoRisco}
      />
    );
  };

  const renderNome = (item, configuracaoRisco) => {
    if (!item.nome) {
      return;
    }

    return (
      <a
        tabIndex="-1"
        onClick={() => handleEdit(item, configuracaoRisco, false)}
        style={{ cursor: 'pointer' }}
      >
        {item.nome}
      </a>
    );
  }

  const renderStatus = (item) => {
    if (!item.status) return;
    return (
      <Badge
        pill
        className="text-white"
        style={{ background: item.status.cor }}
      >
        {intl.formatMessage({ id: item.status?.descricao })}
      </Badge>
    );
  };

  const renderActions = (item, configuracaoRisco) => (
    <Actions
      onRefresh={handleRefresh}
      item={item}
      showCancelButton={false}
      showDelete={permissions?.allowDelete}
      endpoint={endpoint}
      handleOpenEdit={() => handleEdit(item, configuracaoRisco, false)}
    />
  );

  const defaultColumns = [
    <ListColumn
      className="text-right"
      valueField=""
      valueFunction={(item) => renderActions(item, configuracao)}
      default
    />,
    <ListColumn
      minWidth={200}
      headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'nome' }))}
      valueField="nome"
      valueFunction={(item) => renderNome(item, configuracao, false)}
      default
      sortField="nome"
      required
    />,
    <ListColumn
      minWidth={150}
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'idIntegracao' })
      )}
      valueField="idIntegracao"
      default
      sortField="idIntegracao"
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'area' }, { area: resources.area })
      )}
      minWidth={150}
      valueField="area"
      default
      sortField="area"
    />,
    <ListColumn
      minWidth={150}
      headerText={capitalizeFirstLetter(resources.responsavel)}
      valueField="responsaveis"
      default
    />,
    <ListColumn
      minWidth={150}
      headerText={capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'labelRiscosVinculados' },
          { riscos: resources.riscos }
        )
      )}
      valueField="riscosAtrelados"
      default
    />,
    <ListColumn
      minWidth={130}
      headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'status' }))}
      valueField="status"
      valueFunction={renderStatus}
      default
      sortField="status"
    />,
  ];

  const actions = [
    <Button
      key={1}
      className="ml-2"
      onClick={() => handleEdit({}, configuracao, true)}
      type="primary"
    >
      <MdiIcon icon="plus" />
    </Button>,
  ];

  return (
    <LoadingContainer isLoading={isLoading}>
      <Toolbar
        useSavedFilter
        showSearch
        onExport={handleExport}
        onColumnsChange={handleColumnsChange}
        onSearchChange={handleSearchChange}
        onRefresh={handleRefresh}
        searchText={searchText}
        searchSchema={SearchSchema}
        searchComponent={<Search />}
        filter={filter}
        filterTag={filterTag}
        columnsTag={columnsTag}
        columns={columns}
        allColumns={allColumns}
        actions={!isDisabled && permissions?.allowCreate && actions}
        {...rest}
      />
      <Results
        resources={resources}
        count={count}
        columns={columns}
        data={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangeCurrentPage={handleChangeCurrentPage}
        onChangePageSize={handleChangePageSize}
        onSortColumn={handleSortColumn}
        onSortByColumn={handleSortByColumn}
        sort={sortField}
        sortBy={sortFieldBy}
        updatedItems={updatedItems}
        {...rest}
      />
    </LoadingContainer>
  );
}

PlanoContigenciaListView.defaultProps = {
  columnsTag: COLUNAS.PLANOS_CONTIGENCIA,
  filterTag: FILTROS.PLANOS_CONTIGENCIA,
  endpoint: '/PlanoContigencia',
  entityTag: null,
  defaultSort: 'Nome',
  defaultSortBy: '',
  selectedFilter: null,
  openLastFilter: true,
  parameters: {},
  showToolbar: true,
};

export default PlanoContigenciaListView;
