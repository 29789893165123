import http from './httpService';
import pPipe from './../utils/pPipe';

/**
 * Função que padroniza a chamada de obtenção de configuração de um determinado controller
 * Atribui config retornado do Backend ao setConfig
 * @controller {obrigatório}
 * @setConfig {opcional}
 * @returns registro de configuração do @controller
 */
export const getConfiguration = (controller) => (setConfig) => async () =>
  await pPipe(
    async () => await http.post(`/${controller}/ObterConfiguracoes`),
    ({ data }) => (data.model ? { data } : { data: { model: data } }),
    ({ data: { model } }) => model,
    (config) => {
      setConfig && setConfig(config);
      return config;
    }
  )();
