import React from 'react';
import css from '../../../../utils/css';
import clsx from 'clsx';
import { autobind } from 'core-decorators';
import Loading from '../../../../componentes/spinner';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import http from 'src/services/httpService';

const classes = css`
  .root {
    width: 240px;
    height: 100%;
    user-select: none;
    white-space: normal;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    margin: 5px;
    border-radius: 5px;
    background: #f0f0f0;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.1),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
  }
  .isDraggingOver {
  }
  .header {
    padding: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .content {
    flex-grow: 1;
    overflow-y: auto;
  }
  .inner {
    padding: 0px 5px;
    height: calc(100% - 20px);
    background: #f0f0f0;
  }
`;

@autobind
class TaskList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      pageSize: 20,
      lista: [],
      isLoading: false,
      model: this.props.model,
      parameters: this.props.parameters,
      statusId: this.props.statusId,
      listaLocal: { id: this.props.statusId, itens: [], qntRealItens: 0 },
    };

    this.myScroll = React.createRef();
  }

  // Não é utilizado para listagem única
  // Exemplo: o gantt é uma listagem única, portanto não precisa da maioria das validações desse componente
  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.listagemUnica) {
      if (newProps.filtrando != this.props.filtrando) {
        this.setState(
          {
            page: 1,
            model: newProps.model,
            parameters: newProps.parameters,
            statusId: newProps.statusId,
          },
          () => {
            //após filtrar, reseta tudo
            this.removeListener();
            this.refresh();

            setTimeout(() => {
              this.addListener();
            }, 1000);
          }
        );
      }
    }
  }

  // Não é utilizado para listagem única
  // Exemplo: o gantt é uma listagem única, portanto não precisa da maioria das validações desse componente
  listener() {
    if (
      Math.ceil(this.myScroll.current.scrollTop) +
        Math.ceil(this.myScroll.current.clientHeight) >=
      Math.ceil(this.myScroll.current.scrollHeight)
    ) {
      this.aumentaPagina();
    }
  }

  // Não é utilizado para listagem única
  // Exemplo: o gantt é uma listagem única, portanto não precisa da maioria das validações desse componente
  removeListener() {
    this.myScroll &&
      this.myScroll.current &&
      this.myScroll.current.removeEventListener('scroll', this.listener, true);
  }

  // Não é utilizado para listagem única
  // Exemplo: o gantt é uma listagem única, portanto não precisa da maioria das validações desse componente
  addListener() {
    this.myScroll &&
      this.myScroll.current &&
      this.myScroll.current.addEventListener('scroll', this.listener, true);
  }

  // Não é utilizado para listagem única
  // Exemplo: o gantt é uma listagem única, portanto não precisa da maioria das validações desse componente
  componentDidMount() {
    if (!this.props.listagemUnica) {
      this.aumentaPagina();
      this.addListener();
    }
  }

  // Não é utilizado para listagem única
  // Exemplo: o gantt é uma listagem única, portanto não precisa da maioria das validações desse componente
  refresh(calcularTamanho = false) {
    let { page, pageSize, model, parameters, statusId } = this.state;

    let confereStatus = model?.status
      ? model.status.id == statusId
        ? true
        : false
      : true;

    if (confereStatus) {
      this.setState({ isLoading: true });

      let pagina = page;
      let tamanho = pageSize;

      if (calcularTamanho == true && pagina > 1) {
        tamanho = tamanho * pagina;
        pagina = 1;
      }

      http
        .post(`/Acao/List`, {
          model: { ...model, status: { id: statusId } },
          sort: 'UltimaVersao.Id desc',
          pageSize: tamanho,
          page: pagina - 1,
          parameters: parameters,
        })
        .then((response) => {
          if (response.data && response.data.list) {
            if (pagina > 1) {
              this.setState(
                {
                  listaLocal: {
                    id: statusId,
                    itens: [
                      ...this.state.listaLocal.itens,
                      ...response.data.list,
                    ],
                    qntRealItens: response.data.count,
                  },
                },
                () => this.props.adicionarLista(this.state.listaLocal)
              );
            } else {
              this.setState(
                {
                  listaLocal: {
                    id: statusId,
                    itens: response.data.list,
                    qntRealItens: response.data.count,
                  },
                },
                () => this.props.adicionarLista(this.state.listaLocal)
              );
            }

            this.setState({ isLoading: false });
          }
        });
    } else {
      //filtrando outro status

      let statusInt = parseInt(statusId, 10);

      //se tudo for renderizado no mesmo tempo, o state do componente anterior não terá atualizado
      this.setState(
        {
          listaLocal: { id: statusId, itens: [], qntRealItens: 0 },
        },
        () =>
          setTimeout(() => {
            this.props.adicionarLista(this.state.listaLocal);
          }, statusInt * 100)
      );
    }
  }

  // Não é utilizado para listagem única
  // Exemplo: o gantt é uma listagem única, portanto não precisa da maioria das validações desse componente
  aumentaPagina() {
    this.setState({ page: this.state.page + 1 }, () => {
      this.refresh();
    });
  }

  render() {
    let {
      title,
      total,
      provided,
      snapshot,
      className,
      children,
      resources,
      model,
      parameters,
      statusId,
      ...rest
    } = this.props;

    let { isLoading } = this.state;

    return (
      <div
        {...rest}
        ref={provided.innerRef}
        className={clsx(classes.root, className)}
      >
        <div className={classes.header}>
          <span style={{ fontWeight: 700, fontSize: 15 }}>{title}</span>
          <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
            {isLoading ? (
              <Loading style={{ marginTop: 27, marginLeft: 5 }} size={50} />
            ) : (
              <span style={{ marginLeft: 5, fontSize: 10, marginTop: 3 }}>
                ({total} {total == 1 ? resources.acao : resources.acoes})
              </span>
            )}
          </div>
        </div>

        <div ref={this.myScroll} className={classes.content}>
          <div
            className={clsx(classes.inner, {
              [classes.isDraggingOver]: snapshot.isDraggingOver,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(TaskList));
