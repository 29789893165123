import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class TipoAcesso extends React.Component {
  static defaultProps = {
    clearable: false,
  };
  render() {
    return <Lookup labelKey="descricao" valueKey="id" clearable={this.props.clearable} url="/TipoAcessoLookup" {...this.props} />;
  }
}

export default TipoAcesso;
