import React from 'react';
import { autobind } from 'core-decorators';
import Select from 'react-select';
import '../../styles/react-select.scss';
import css from '../../utils/css';
import httpSelector from 'src/services/httpSelector';
import { FormGroup, FormFeedback, Label } from 'reactstrap';
import ItemMulti from '../item-multi-lookup';
import MdiIcon from '../mdi-icon';
import ContentManager from '../content-manager';
import './style.scss';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import guid from '../../utils/guid';
import classNames from 'classnames';

let classes = css`
  .select {
    position: relative;
    display: table;
    border-collapse: separate;
    width: 100%;

    .Select-control {
      border: 1px solid #dde6e9;
      table-layout: fixed;

      .Select-multi-value-wrapper {
        width: 100%;
        .Select-input {
          max-width: 80%;
          overflow: hidden;
          /*height: 31px;*/
        }
      }

      .Select-arrow-zone {
      }
    }

    .Select-menu-outer {
      z-index: 20000;
    }

    .input-group-btn {
      height: 100%;
      position: relative;
      font-size: 0;
      white-space: nowrap;
      vertical-align: middle;
      width: 1%;
      display: table-cell;

      > .ant-btn:first-child:not(:last-child) {
        border-radius: 0;
      }

      > .ant-btn:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;

@autobind
class SelectAsync extends React.Component {
  static defaultProps = {
    showModal: true,
    showLabel: true,
    showNew: true,
  };

  constructor(props) {
    super(props);
    this.chaveId = guid();

    this.state = {
      searchComponentId: `LOOKUP-ID-${guid()}`,
      isAlreadySearching: false,
    };
  }

  async componentDidMount() {
    if (this.props.parametroUrl) {
      this.props.model.value = this.props.parametroUrl;
    }
    if (this.props.model && this.props.model.value) {
      if (this.props.multi) {
        this.getOptionsMultiFromProps();
      } else {
        const labelKey = this.props.model.value[this.props.labelKey];
        const valueKey = this.props.model.value[this.props.valueKey];
        if (!labelKey && valueKey) {
          // busca a descricao do lookup caso seja passado somente o ID
          var response = await httpSelector
            .getHttp(this.props.viewType)
            .post(`${this.props.url}/Search`, {
              id: valueKey,
            });
          var returnValue = response.data.list.find((v) => v.id == valueKey);
          if (returnValue)
            this.props.model.requestChange &&
              this.props.model.requestChange(returnValue);
        }
      }
    }

    if (!this.props.blockingOnFirstClick) this.handleAddObserver();
  }

  componentWillUnmount = () => {
    this.handleRemoveObserver();
  };

  handleAddObserver() {
    const root = document.getElementById('root');

    this.observer = new MutationObserver(() => {
      const element = document.getElementById(this.state.searchComponentId);
      this.setState({
        isAlreadySearching: element != null,
      });
    });

    this.observer.observe(root, { subtree: true, childList: true });
  }

  handleRemoveObserver = () => {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  };

  async getOptionsMultiFromProps() {
    var newValue = [];
    await this.props.model?.value?.forEach(async (item, index, array) => {
      const labelKey = item[this.props.labelKey];
      const valueKey = item[this.props.valueKey];
      if (!labelKey && valueKey) {
        const response = await httpSelector
          .getHttp(this.props.viewType)
          .post(`${this.props.url}/Search`, {
            id: valueKey,
          });
        const returnValue = response.data.list.find((v) => v.id == valueKey);

        newValue.push(returnValue);
      }

      if (newValue.length > 0 && index === array.length - 1)
        this.props.model.requestChange &&
          this.props.model.requestChange(newValue);
    });
  }

  handleChange(value) {
    this.props.model.requestChange && this.props.model.requestChange(value);
  }

  getDescricaoMulti(list) {
    return list.map((item) => item[this.props.labelKey]).join('; ');
  }

  renderMulti(item) {
    if (this.props.valueComponent) return this.props.valueComponent();
    else {
      return (
        <ItemMulti
          label={item.value[this.props.labelKey]}
          onRemove={item.onRemove.bind(this, item.value)}
          multi={this.props.multi}
          disabled={this.props.disabled}
        ></ItemMulti>
      );
    }
  }

  handleSearch() {
    if (this.state.isAlreadySearching) return;

    if (this.props.blockingOnFirstClick) {
      this.setState({
        isAlreadySearching: true,
      });
    }

    let selectedIds = null;
    let selectedItems = [];
    if (this.props.model && this.props.model.value) {
      if (this.props.model.value && this.props.model.value.length > 0) {
        selectedItems = this.props.model.value.map((d) => d);
        selectedIds = this.props.model.value;
      } else selectedIds = [].concat(this.props.model.value);
    }

    ContentManager.addContent(
      <this.props.searchComponent
        id={this.state.searchComponentId}
        className="popupOpen"
        parameters={{ ...this.props.data }}
        multiple={this.props.multi}
        showModal={this.props.showModal}
        dialogWidth="80%"
        hierarchy={this.props.hierarchy}
        select={true}
        selectedCallback={this.handleCustomSelectSearch}
        selectedIds={selectedIds}
        selectedItems={selectedItems}
        kanban={false}
        showNew={this.props.showNew}
        selecionarAvaliador={this.props.selecionarAvaliador}
        ehFluxoNegociacaoMetas={this.props.ehFluxoNegociacaoMetas}
        showSearch={this.props.showSearch}
      />
    );
  }

  handleCustomSelectSearch(itens) {
    if (this.props.multi) {
      this.props.model.requestChange(itens ? [...itens] : []);
    } else if (itens.length > 0) {
      let item = {
        ...itens[0],
      };
      this.setState({ defaultValue: item });
      this.props.model.requestChange(item);
    }
  }

  async refresh(input) {
    const { loadOnStart } = this.props;
    if (!input && !loadOnStart) {
      return Promise.resolve({ options: [] });
    }
    try {
      var response = await httpSelector
        .getHttp(this.props.viewType)
        .post(`${this.props.url}/Search`, {
          ...this.props.data,
          text: input,
        });
    } catch (err) {
      console.log(err);
    }

    return { options: response.data.list };
  }

  getOptions(input) {
    clearTimeout(this.textoPesquisaDebounceId);

    return new Promise((resolve) => {
      this.textoPesquisaDebounceId = setTimeout(() => {
        resolve(this.refresh(input));
      }, 500);
    });
  }

  renderArrow() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={this.handleSearch}
      >
        <MdiIcon
          style={{ position: 'absolute', bottom: '5px' }}
          size={18}
          color="#1d7789"
          icon="magnify"
        >
          +
        </MdiIcon>
      </div>
    );
  }

  render() {
    let {
      model,
      multi,
      valueKey,
      labelKey,
      labelValue, // Ganha prioridade de exibição para os casos onde o resource só é acessível dentro da classe ou função
      options,
      optionRenderer,
      valueRenderer,
      clearable,
      disabled,
      placeholder,
      showSearch,
      required,
      formGroupStyle,
      intl,
      onBlur,
      showLabel,
      customClassname = '',
      controlStyle = {},
      ...other
    } = this.props;

    return (
      <FormGroup
        validationstate={model.errors ? 'error' : null}
        style={formGroupStyle}
      >
        {showLabel && model.label && (
          <Label className="control-label">
            {labelValue || model.label} {required ? '*' : ''}
          </Label>
        )}
        {
          <div className={classNames(classes.select, customClassname)}>
            <Select.Async
              key={this.chaveId}
              onBlur={this.props.onBlur}
              disabled={disabled}
              style={{
                ...controlStyle,
                borderColor: model.errors && '#f05050',
              }}
              arrowRenderer={!disabled && showSearch && this.renderArrow}
              valueComponent={multi && this.renderMulti}
              clearValueText={intl.formatMessage({ id: 'label.limparValor' })}
              noResultsText={intl.formatMessage({
                id: 'label.nenhumRegistroEncontrado',
              })}
              searchPromptText={intl.formatMessage({
                id: 'label.digiteParaPesquisar',
              })}
              clearable={clearable}
              valueRenderer={valueRenderer}
              optionRenderer={optionRenderer}
              valueKey={valueKey}
              labelKey={labelKey}
              onChange={this.handleChange}
              multi={multi}
              placeholder={placeholder ? placeholder : ''}
              value={model.value}
              loadOptions={this.getOptions}
              ignoreAccents={false}
              {...other}
            ></Select.Async>
          </div>
        }
        {model.erros && <FormFeedback>{model.erros}</FormFeedback>}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    viewType: state.viewType,
  };
}
export default injectIntl(connect(mapStateToProps)(SelectAsync));
