import React from 'react';
import Lookup from '../componentes/select2';

import { autobind } from 'core-decorators';

@autobind
class Pais extends React.Component {
  render() {
    return (
      <Lookup
        labelKey="descricao"
        valueKey="id"
        url="/PaisLookup"
        {...this.props}
      />
    );
  }
}

export default Pais;
