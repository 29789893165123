import React from 'react';
import { autobind } from 'core-decorators';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import MdiIcon from 'src/componentes/mdi-icon';
import LoadingContainer from 'src/componentes/loading-container';
import TipoAcessoLookup from 'src/lookups/tipo-acesso';
import TipoAcessoPacoteLookup from 'src/lookups/tipo-acesso-pacote';

import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';

@autobind
class Permissoes extends React.Component {
  static defaultProps = {
    allowEditPermissions: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      list: [],
      idsExpandidos: [],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({
      isLoading: true,
    });
    if (this.props.colaboradorId) {
      http
        .post(`${this.props.urlLoad}`, {
          colaboradorId: this.props.colaboradorId,
        })
        .then((response) => {
          this.setState({
            isLoading: false,
            ...response.data,
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
        });
    }
  }

  ehGrupo(permissao) {
    return !!(permissao.filhos && permissao.filhos.length);
  }

  estaExpandido(permissao) {
    return this.state.idsExpandidos.indexOf(permissao.organizacaoId) !== -1;
  }

  toggle(permissao) {
    if (this.estaExpandido(permissao)) {
      this.setState({
        idsExpandidos: this.state.idsExpandidos.filter(
          (organizacaoId) => organizacaoId !== permissao.organizacaoId
        ),
      });
    } else {
      this.setState({
        idsExpandidos: this.state.idsExpandidos.concat([
          permissao.organizacaoId,
        ]),
      });
    }
  }

  getItemLabel(campo, item) {
    if (!item) {
      return;
    }

    if (campo.labelFunction) {
      return campo.labelFunction(item);
    }

    if (campo.labelField) {
      return item[campo.labelField];
    }

    return item;
  }

  handlePermissaoAcessoChange(permissao, valor) {
    http
      .post(`${this.props.urlSave}`, {
        permissaoId: permissao ? permissao.id : 0,
        tipoAcessoId: valor ? valor.id : 0,
        organizacaoId: permissao ? permissao.organizacaoId : 0,
        colaboradorId: this.props.colaboradorId,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
          ...response.data,
        });
        this.refresh();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });
  }

  handleReplicarPermissaoAcessoChange(permissao) {
    http
      .post(`${this.props.urlReply}`, {
        colaboradorId: this.props.colaboradorId,
        permissaoId: permissao ? permissao.id : 0,
        organizacaoId: permissao ? permissao.organizacaoId : 0,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
          ...response.data,
        });
        this.refresh();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });
  }

  renderFuncionalidade(permissao, index, level = 0, paiSelecionado) {
    let { allowEditPermissions } = this.props;

    let selecionado = permissao.selecionado;

    const tooltipId = `TOOLTIP-P-${permissao?.organizacaoId}`;

    return [].concat(
      <tr key={permissao.organizacaoId}>
        <td
          style={{
            minWidth: 200,
            paddingLeft: level * 20,
            whiteSpace: `nowrap`,
            width: 1,
            verticalAlign: `middle`,
          }}
        >
          <div className="d-flex align-items-center">
            {this.ehGrupo(permissao) ? (
              <MdiIcon
                icon={
                  this.estaExpandido(permissao)
                    ? `chevron-down`
                    : `chevron-right`
                }
                size={20}
                onClick={this.toggle.bind(this, permissao)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <div style={{ display: `inline-block`, width: 20 }}></div>
            )}
            <div className="text-truncate" style={{ maxWidth: 350 }}>
              {permissao.descricao}
            </div>
          </div>
        </td>
        <td style={{ width: 220, verticalAlign: `middle` }}>
          {this.props.tipo === 3 ? (
            <TipoAcessoPacoteLookup
              style={{ marginBottom: 0 }}
              model={{
                value: permissao.acesso,
                requestChange: this.handlePermissaoAcessoChange.bind(
                  this,
                  permissao
                ),
              }}
              disabled={!allowEditPermissions}
            />
          ) : (
            <TipoAcessoLookup
              style={{ marginBottom: 0 }}
              model={{
                value: permissao.acesso,
                requestChange: this.handlePermissaoAcessoChange.bind(
                  this,
                  permissao
                ),
              }}
              disabled={!allowEditPermissions}
            />
          )}
        </td>
        {permissao.filhos &&
        permissao.filhos.length > 0 &&
        allowEditPermissions ? (
          <td style={{ minWidth: '10%', width: '10%' }}>
            <div
              className="d-flex items-center"
              id={tooltipId}
              data-tip
              data-for={tooltipId}
            >
              <MdiIcon
                icon="arrow-down-bold-circle-outline"
                size={20}
                onClick={this.handleReplicarPermissaoAcessoChange.bind(
                  this,
                  permissao
                )}
                className="cursor-pointer"
              />
              <ReactTooltip id={tooltipId} aria-haspopup="true">
                <FormattedMessage id="label.replicarParaFilhos" />
              </ReactTooltip>
            </div>
          </td>
        ) : (
          <td></td>
        )}
      </tr>,
      ...(this.estaExpandido(permissao)
        ? permissao.filhos &&
          permissao.filhos.length > 0 &&
          permissao.filhos.map((m, i) =>
            this.renderFuncionalidade(
              m,
              i,
              level + 1,
              paiSelecionado || selecionado
            )
          )
        : [])
    );
  }

  render() {
    let { columns } = this.props;
    let { list } = this.state;

    return (
      <LoadingContainer isLoading={this.state.isLoading}>
        <Table style={{ overflowY: 'hidden' }}>
          <thead>
            <tr>
              {columns &&
                columns.length > 0 &&
                columns.map((item, index) => (
                  <th key={index}>{item.headerText}</th>
                ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.length > 0 &&
              list.map((permissao, index) =>
                this.renderFuncionalidade(permissao, index)
              )}
          </tbody>
        </Table>
      </LoadingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(Permissoes);
