import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { capitalizeFirstLetter } from 'src/utils/string';
import { Row, Col  } from 'reactstrap';
import Tab from 'src/componentes/tabs/tab';
import Tabs from 'src/componentes/tabs/tabs';
import Input from 'src/componentes/input';
import Schema from './Schema';
import Edit from 'src/componentes/edit/edit-dialog';

export function AnaliseSwotEditForm({ model, resources, disabled = false, ...others }) {
  const [charCount, setCharCount] = useState(0);
  const intl = useIntl();
  
  useEffect(() => {
    if(model?.getModel('descricao')?.value)
      setCharCount(model.getModel('descricao').value.length)
  }, [model?.getModel('descricao')?.value])
   
  return (
    <div>
      <Row>
        <Col md={12}>
          <Input rows={2} type="textarea" disabled={disabled} model={model.getModel('descricao')} required maxLength={1000}/>
          <p>{intl.formatMessage({ id: 'labelMsgQtdadeCaracteres' }, {charCount: charCount})} </p>
        </Col>
      </Row>
    </div>
  );
}

const AnaliseSwotEditDialog = ({ resources, intl, ...rest }) => {
  return (
    <Edit
      url="/RiscoAnaliseSwot"
      title={rest.header}
      formComponent={AnaliseSwotEditForm}
      resources={resources}
      schema={Schema}
      width="70%"
      maxHeight="90%"
      {...rest}
    />
  );
};

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(AnaliseSwotEditDialog));
