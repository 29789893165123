import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../componentes/button';
import Dialog from '../../../../componentes/dialog';
import { Row, Col, ButtonToolbar } from 'reactstrap';
import StatusLookup from 'src/lookups/status-risco-ponto-controle';
import IndicadorRiscoLookup from 'src/lookups/indicador-risco';
import useAuth from 'src/hooks/useAuth';
import Input from 'src/componentes/input';
import errorHandler from 'src/utils/error-handler';
import http from 'src/services/httpService';
import LoadingContainer from 'src/componentes/loading-container';

function ControleIndicadorRiscoEdit({ item, indexItem, endpoint, parameters, handleClose, disabled, ...rest }) {
  const defaultItem = {
    periodicidade: null,
    dataInicio: null,
    dataTermino: null,
    utilizarRecorrencia: false,
    excluido: false,
    editado: true,
    permissions: {
      allowEdit: true,
      allowDelete: true,
      allowView: true,
    },
  };

  const modalRef = useRef();
  const { terms: resources } = useAuth();
  const [isNew] = useState(item == null ? true : false);
  const [model, setModel] = useState(item == null ? defaultItem : item);
  const [modelErrors, setModelErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    try {
      setIsLoading(true);
      if (item && item.id) {
        const response = await http.post(`${endpoint}/Edit`, { id: item.id, parameters: parameters });
        setModel(response.data);
      } else {
        const response = await http.post(`${endpoint}/Create`, { parameters: parameters });
        setModel(response.data);
      }
    } catch (err) {
      errorHandler(err);
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleCurrentClose = (isSaved = false) => {
    modalRef.current.close();
    setTimeout(() => {
      handleClose && handleClose(isSaved, isNew, model, indexItem);
    }, 300);
  };

  const handleCloseClick = () => {
    handleCurrentClose();
  };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);

      if (validadeField()) {
        await http.post(`${endpoint}/Save`, {
          model: { ...model, controleId: parameters.controleId },
          parameters: parameters,
        });
        handleCurrentClose(true);
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeValue = (field, value) => {
    setModel((current) => ({ ...current, [field]: value }));
  };

  const validadeField = () => {
    const modelValidate = {};
    if (!model.status) modelValidate.status = true;
    if (!model.indicadorRisco) modelValidate.indicadorRisco = true;

    if (modelValidate.status || modelValidate.indicadorRisco) {
      setModelErrors(modelValidate);
      return false;
    }
    return true;
  };

  const editActions = [
    <ButtonToolbar key="toolbar-tela">
      <Button onClick={handleCloseClick} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>
      ,
      <Button type="primary" onClick={handleSaveClick} htmlType="submit" className="ml-2">
        {<FormattedMessage id="label.salvar" />}
      </Button>
    </ButtonToolbar>,
  ];

  return (
    <Dialog
      id="recorrencia-edit"
      ref={modalRef}
      width="600px"
      minHeight="50%"
      title={<FormattedMessage id="indicadorRisco" values={{ risco: resources.risco }} />}
      padContent
      actions={editActions}
      {...rest}
    >
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col md={8}>
            <IndicadorRiscoLookup
              disabled={disabled}
              model={{
                label: <FormattedMessage id="indicadorRisco" values={{ risco: resources.risco }} />,
                value: model.indicadorRisco,
                requestChange: (e) => handleChangeValue('indicadorRisco', e),
                errors: modelErrors && modelErrors.indicadorRisco,
              }}
              data={{ controleId: parameters.controleId }}
              required
            />
          </Col>
          <Col md={4}>
            <StatusLookup
              disabled={disabled}
              model={{
                label: <FormattedMessage id="status" />,
                value: model.status,
                requestChange: (e) => handleChangeValue('status', e),
                errors: modelErrors && modelErrors.status,
              }}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              rows={5}
              type="textarea"
              disabled={disabled}
              model={{
                label: <FormattedMessage id="observacao" />,
                value: model.observacao,
                requestChange: (e) => handleChangeValue('observacao', e),
              }}
            />
          </Col>
        </Row>
      </LoadingContainer>
    </Dialog>
  );
}

export default ControleIndicadorRiscoEdit;
