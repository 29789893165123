import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import { autobind } from 'core-decorators';
import { Schema } from 'src/utils/form';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import http from 'src/services/httpService';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required({
      onlyIf: (context) => {
        return (
          !context.parent.value.utilizarCodigoAutomaticoCausaRisco ||
          context.parent.value.id > 0
        );
      },
    }),
});

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracoes: null,
      isLoading: false,
    };
  }

  loadConfiguracoes() {
    this.setState({
      isLoading: true,
    });

    http
      .post(`/ItemConfiguracaoRisco/ObterConfiguracoes`, {
        parameters: this.props.parameters,
      })
      .then((response) => {
        this.setState({
          configuracoes: response.data,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.loadConfiguracoes();
  }

  render() {
    let { model } = this.props;
    const isNew = model?.getModel('id')?.value < 1;
    const {
      utilizarCodigoAutomaticoCausaRisco,
      permitirAlterarCodigoAutomaticoCausaRisco,
    } = this.state.configuracoes || {};
    const exibirIdIntegracao = !utilizarCodigoAutomaticoCausaRisco || !isNew;

    return (
      <div>
        <Row>
          {exibirIdIntegracao && (
            <Col md={4}>
              <Input
                disabled={
                  utilizarCodigoAutomaticoCausaRisco &&
                  !permitirAlterarCodigoAutomaticoCausaRisco
                }
                model={model.getModel('idIntegracao')}
                required={!utilizarCodigoAutomaticoCausaRisco || !isNew}
              />
            </Col>
          )}
          <Col md={exibirIdIntegracao ? 8 : 12}>
            <Input model={model.getModel('descricao')} required maxLength={400} />
          </Col>
        </Row>
      </div>
    );
  }
}

class CausaRiscoEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;
    return (
      <Edit
        url="/CausaRisco"
        title={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'labelCausasDoRisco' },
            {
              pontosproblematicos: resources.pontosproblematicos,
              risco: resources.risco,
            }
          )
        )}
        formComponent={FormEdit}
        schema={schema}
        width={600}
        height={450}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CausaRiscoEdit));
