import React from 'react';
import Select from '../select2';
import { Field } from 'formik';

function SelectField(props) {
  const newModel = {
    value: props.model.value,
    requestChange: (value) => props.form.setFieldValue(props.field.name, value),
  };

  return <Select {...props} model={newModel} />;
}

const CustomSelectAsync = ({ name, ...rest }) => {
  return <Field name={name} {...rest} component={SelectField} />;
};

export default CustomSelectAsync;
