import React from 'react';
import { autobind } from 'core-decorators';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
} from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import qs from 'query-string';
import history from 'src/history';
import Alert from 'src/componentes/message-box/alert';

import { getConfiguration } from 'src/services/commonRequests';
import Edit from 'src/componentes/edit/edit-dialog';
import ContentManager from 'src/componentes/content-manager';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import { capitalizeFirstLetter, lowerCaseFirstLetter } from 'src/utils/string';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';
import MdiIcon from 'src/componentes/mdi-icon';
import DropdownItemRisco from 'src/paginas/gestao-risco/risco/RiscoListView/DropdownItemRisco';
import Acao from 'src/paginas/NewGantt';
import Button from 'src/componentes/button';
import PanelCollapse from 'src/componentes/panel-collapse';

import RiscoAvaliacao from '../Avaliacao/AvaliacaoListView';
import Resumo from './Resumo';
import RiscoPontosProblematicos from './RiscoPontoControleListView';
import Graficos from '../RiscoEditView/grafico/graficos';
import Schema from './Schema';
import EventoListView from '../Evento/EventoListView';
import AssistenteVirtual from 'src/components/IA/ChatGPT/AssistenteVirtual';
import http from 'src/services/httpService';
import { TIPO_INTERACAO_SOPHIE, ADDONS, TIPO_VALOR, STATUS_ITEM } from 'src/utils/constants';
import Permissao from 'src/paginas/permissao';
import RiscoAvaliacaoPowerBIListView from 'src/paginas/gestao-risco/risco/RiscoEditView/RiscoAvaliacaoPowerBI/RiscoAvaliacaoPowerBIListView';

let disabled;

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
    };
  }

  handleCallBack() {
    this.setState({
      saved: !this.state.saved,
    });
  }

  atualizarComponentsComKeyRandomico() {
    this.props.model.getModel('randomAcaoKey').requestChange &&
      this.props.model.getModel('randomAcaoKey').requestChange(Math.random());
  }

  atualizarResumo() {
    this.props.model.getModel('forceRefresh').requestChange(Math.random());
  }

  //handleClose - Parametros não usados do edit-dialog (a,b,c,d,e)
  onClose({ closeModal }) {
    if (closeModal)
      this.props.handleCloseEdit && this.props.handleCloseEdit(closeModal);
  }

  closeModal() {
    this.props.handleCloseEdit && this.props.handleCloseEdit();
  }

  render() {
    const {
      model,
      resources,
      intl,
      disabledEdit,
      showDelete = true,
      addons,
    } = this.props;

    const id = model.getModel('id') && model.getModel('id').value;

    if (disabledEdit) disabled = disabledEdit;
    else {
      disabled = model.getModel('permissions').value
        ? !model.getModel('permissions').getModel('allowEdit').value
        : false;
    }

    const permissions = model?.getModel('permissions')?.value;
    const disabledPendenteAprovacao = model.getModel('pendenteAprovacao').value;
    const reprovadoFluxoAprovacao = model.getModel('fluxoAprovacao').value && model.getModel('status').value?.id == STATUS_ITEM.REPROVADO;
    const rascunhoPendenteAprovacao = model.getModel('fluxoAprovacao').value && model.getModel('status').value?.id == STATUS_ITEM.EM_PLANEJAMENTO;
    const modelo = model.getModel('modeloRisco') && model.getModel('modeloRisco').value;

    const isEstrategico = modelo && modelo.id == 1 ? true : false;
    const isOperacional = modelo && modelo.id == 2 ? true : false;
    const isProjeto = modelo && modelo.id == 3 ? true : false;

    const { configuracao } = this.props;

    const { habilitarCalculoDeScores, utilizarPermissoes } = configuracao || {};

    const modeloRisco = {
      isEstrategico: isEstrategico,
      isOperacional: isOperacional,
      isProjeto: isProjeto,
    };

    const allowDelete =
      showDelete &&
      model &&
      model.getModel('permissions').getModel('allowDelete').value;

    const isNew = !id || id < 0 || id === 0;

    const addonIntegracaoPowerBIAvaliacaoRisco = addons && addons.find((m) => m.id === ADDONS.INTEGRACAO_POWERBI_AVALIACAORISCO)?.ativo;

    return (
      <div style={{ marginTop: 15 }}>
        {isNew ? (
          <Resumo
            modeloRisco={modeloRisco}
            model={model}
            disabled={disabled}
            configuracao={configuracao}
          />
        ) : (
          <Tabs>
            <Tab key="1" label={<FormattedMessage id="cadastro" />}>
              <Resumo
                modeloRisco={modeloRisco}
                model={model}
                disabled={disabled || disabledPendenteAprovacao || reprovadoFluxoAprovacao}
                rascunhoPendenteAprovacao={rascunhoPendenteAprovacao}
                configuracao={configuracao}
              />
            </Tab>
            {!isNew && !reprovadoFluxoAprovacao && !disabledPendenteAprovacao && !rascunhoPendenteAprovacao && (
              <Tab
                key="4"
                label={capitalizeFirstLetter(resources.analisederisco)}
              >
                <RiscoPontosProblematicos
                  modeloRisco={modeloRisco}
                  model={model}
                  disabled={disabledEdit || disabled || disabledPendenteAprovacao}
                  configuracao={configuracao}
                  handleClose={this.onClose}
                />
              </Tab>
            )}
            {!isNew && !reprovadoFluxoAprovacao && !disabledPendenteAprovacao && !rascunhoPendenteAprovacao && (
              <Tab key="2" label={capitalizeFirstLetter(resources.avaliacoes)}>
                <PanelCollapse header={capitalizeFirstLetter(resources.avaliacoes)}>
                  <RiscoAvaliacao
                    showDelete={allowDelete}
                    showPage={false}
                    showSearch={false}
                    exibirAcao={true}
                    showNew={true}
                    parameters={{ riscoId: id }}
                    model={model}
                    disabled={disabled}
                    funcAtualizarAnscestral={
                      this.atualizarComponentsComKeyRandomico
                    }
                    refreshHeaderCustom={this.props.refreshHeaderCustom}
                    refreshResumo={this.atualizarResumo}
                    habilitarCalculoDeScores={habilitarCalculoDeScores}
                    habilitarLinkRisco={false}
                    configuration={configuracao}
                    habilitarBotaoSincronizarAvaliacoes={model.getModel('utilizarValorPowerBI').value}
                  />                
                </PanelCollapse>
                { addonIntegracaoPowerBIAvaliacaoRisco && model.getModel('utilizarValorPowerBI').value && (
                  <PanelCollapse header={intl.formatMessage({ id: 'label.integracao' })}>
                    <RiscoAvaliacaoPowerBIListView
                      parameters={{
                        itemId: id,
                        tipoValorId: TIPO_VALOR.REALIZADO,
                        alterado: false,
                        utilizarConfigPeriodo: false, 
                      }}
                    />
                  </PanelCollapse>
                )}
              </Tab>
            )}
            {!isNew 
              && !reprovadoFluxoAprovacao 
              && !disabledPendenteAprovacao 
              && !rascunhoPendenteAprovacao 
              && configuracao.habilitarAcompanhamentoEventosRisco 
              && (
                <Tab
                  key={'6'}
                  label={intl.formatMessage(
                    { id: 'eventosRisco' },
                    { risco: resources.risco }
                  )}
                >
                  <EventoListView
                    showDelete={allowDelete}
                    showPage={false}
                    showSearch={false}
                    exibirAcao={true}
                    showNew={true}
                    parameters={{ riscoId: id }}
                    model={model}
                    disabled={disabled}
                    configuracao={configuracao}
                  />
                </Tab>
              )}
            {!isNew && !reprovadoFluxoAprovacao && !disabledPendenteAprovacao && !rascunhoPendenteAprovacao && (
              <Tab
                //força children dessa tab a ser re renderizado, atualizando o estado
                key={
                  (model.getModel('randomAcaoKey') &&
                    model.getModel('randomAcaoKey').value) ||
                  '5'
                }
                label={capitalizeFirstLetter(resources.planodeacao)}
              >
                <Acao itemId={id} handleCloseModal={this.closeModal} />
              </Tab>
            )}
            {!isNew 
              && utilizarPermissoes 
              && !disabledPendenteAprovacao 
              && !reprovadoFluxoAprovacao 
              && !rascunhoPendenteAprovacao 
              && permissions != null 
              && permissions.allowEditPermissions 
              && (
                <Tab
                  label={<FormattedMessage id="label.permissoesDeAcesso" />}
                  key="7"
                >
                  <Row>
                    <Col md={12}>
                      <Permissao
                        parameters={{
                          itemId: id,
                          TipoItemId: 6,
                        }}
                        disabled={disabled}
                        showPanel={false}
                      />
                    </Col>
                  </Row>
                </Tab>
              )}
          </Tabs>
        )}
      </div>
    );
  }
}

@autobind
class RiscoEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.location && qs.parse(this.props.location.search).id,
      dropdownOpen: false,
      configuracao: {},
    };
  }

  componentDidMount() {
    getConfiguration('ItemConfiguracaoRisco')((configuracao) =>
      this.setState({ ...this.state, configuracao })
    )();
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleSaved(data) {
    this.setState({ id: data.id });

    this.props.afterSave && this.props.afterSave(data);
  }

  handleCloseEdit(saved) {
    if (saved) this.setState({ saved: !this.state.saved });
  }

  refreshHeaderCustom = () => {
    this.setState({
      ...this.state,
      updater: Math.random(),
    });
  };

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/Risco/ValidarRestricoes"
        urlExclusao="/Risco/AtualizarStatusExcluido"
        item={item}
        urlList="/gerenciar/Risco"
        //urlEdit="/gerenciar/Risco/edit"
        onClose={this.handleCloseEdit}
        isEdit={true}
      />
    );
  }

  async handleAssistenteVirtualClick() {
    const { user, intl, resources, model, item } = this.props;
    const id =
      this.state.id ||
      item?.id ||
      (model && model.getModel('id') && model.getModel('id').value);

    const infoPrompt = await http.post(`/Risco/GetInfoInsight`, {
      riscoId: id,
    });

    const promptCausas = intl
      .formatMessage({ id: 'promptInsightRisco' })
      .replace(
        /{risco}|{riscos}|{NomeDoRisco}|{AreaDoRisco}|{ProbabilidadeResidual}|{ImpactoResidual}|{TratamentoDefinido}|{PlanoDeAcao}|{ListagemCausas}|{ListagemConsequências}|{ListaControlesPreventivos}|{ListaControlesCorretivos}/gi,
        function (matched) {
          switch (matched) {
            case '{risco}':
              return resources.risco;
            case '{riscos}':
              return resources.riscos;
            case '{NomeDoRisco}':
              return infoPrompt?.data?.nomeDoRisco;
            case '{AreaDoRisco}':
              return infoPrompt?.data?.areaDoRisco;
            case '{ProbabilidadeResidual}':
              return infoPrompt?.data?.probabilidadeResidual;
            case '{ImpactoResidual}':
              return infoPrompt?.data?.impactoResidual;
            case '{TratamentoDefinido}':
              return infoPrompt?.data?.tratamentoDefinido;
            case '{PlanoDeAcao}':
              return infoPrompt?.data?.planoDeAcao;
            case '{ListagemCausas}':
              return infoPrompt?.data?.listagemCausas;
            case '{ListagemConsequências}':
              return infoPrompt?.data?.listagemConsequências;
            case '{ListaControlesPreventivos}':
              return infoPrompt?.data?.listaControlesPreventivos;
            case '{ListaControlesCorretivos}':
              return infoPrompt?.data?.listaControlesCorretivos;
          }
        }
      );

    const msgConsultoraVirtualActioDesvio = intl
      .formatMessage({
        id: 'tituloSophieInsightRiscos',
      })
      .replace('{NomeDoRisco}', infoPrompt?.data?.nomeDoRisco)
      .replace('{risco}', resources.risco);

    ContentManager.addContent(
      <AssistenteVirtual
        user={user}
        prompt={promptCausas}
        title={msgConsultoraVirtualActioDesvio}
        interactionType={TIPO_INTERACAO_SOPHIE.INSIGHTS_RISCO}
      />
    );
  }

  handleCloseEditUrl() {
    const { from } = this.props.location.state || {};
    
    if (from) 
      history.push(from);
    else 
      history.push(`/gerenciar/risco`);
  }

  handleEnviarParaAprovacao(
    model,
    isNew,
    formValid,
    showAllErrors,
    handleClose
  ) {
    if (!formValid) {
      showAllErrors();
      return;
    }

    try {
      http
        .post(`Risco/EnviarParaAprovacao`, {
          model,
          parameters: { enviarParaAprovacao: true },
          isNew,
        })
        .then((response) => {
          ContentManager.addContent(
            <Alert
              message={this.props.intl.formatMessage(
                { id: 'labelEnvioRiscoAprovacaoSucesso' },
                { risco: this.props.resources.risco }
              )}
              type="success"
              handleClose={handleClose}
            />
          );
        });
    } catch (error) {
      console.log(error);
    }
  }

  renderButtonsEnd(model, isNew, formValid, showAllErrors, handleClose) {
    return (model?.existeFluxoAprovacao) || (!isNew && model?.status?.id != STATUS_ITEM.EM_PLANEJAMENTO ) || (!this.state.configuracao?.fluxoAprovacaoObrigatorioRisco && (!model?.fluxoAprovacao || model?.status?.id != STATUS_ITEM.EM_PLANEJAMENTO))
      ? []
      : [
          <Button
            key="btnEnviarParaAprovacao"
            disabled={!formValid}
            onClick={() =>
              this.handleEnviarParaAprovacao(
                model,
                isNew,
                formValid,
                showAllErrors,
                handleClose
              )
            }
            className="ml-2"
          >
            {this.props.intl.formatMessage({ id: 'enviarParaAprovacao' })}
          </Button>,
        ];
  }

  render() {
    const {
      resources,
      intl,
      item,
      showDelete = true,
      disabledEdit = false,
    } = this.props;

    const id = this.state.id;
    const isNew = !((id && id > 0) || (item && item.id > 0));

    return (
      <div>
        <Edit
          {...this.props}
          showExport={false}
          showActionsEnd={true}
          showSaveClose={true}
          disabled={disabledEdit}
          showIconDelete={showDelete}
          handleClose={
            id > 0 ? this.handleCloseEditUrl : this.props.handleClose
          }
          resourceSophieToolTip={'tooltipSophieInsightRisco'}
          handleAssistenteVirtualClick={
            !isNew && this.handleAssistenteVirtualClick
          }
          renderButtonActions={
            this.state.configuracao?.usarFluxoAprovacaoBelt
              ? this.renderButtonsEnd
              : null
          }
          renderActions={(closeModal) => {
            return (
              <div className="d-inline" style={{ height: '20px' }}>
                <UncontrolledDropdown
                  group
                  direction="right"
                  className="ml-2 d-inline"
                  key="indicador"
                >
                  <DropdownToggle color="btn-outline-secondary">
                    <MdiIcon icon="dots-vertical" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItemRisco
                      handleCloseModal={closeModal}
                      showEdit={item?.permissions?.allowEdit}
                      exibirEdicao={item?.permissions?.allowEdit}
                      item={item}
                      resources={resources}
                    />
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            );
          }}
          url="/Risco"
          parameters={{ tipoId: 6, ...this.props.parameters }}
          titleField="nome"
          title={
            !isNew
              ? capitalizeFirstLetter(resources.risco)
              : capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'labelAdicionarRisco' },
                    { risco: lowerCaseFirstLetter(resources.risco) }
                  )
                )
          }
          styleTitle={{ fontSize: '22px', fontFamily: 'nunito' }}
          HeaderCustomComponent={<Graficos updater={this.state.updater} />}
          formComponent={FormEdit}
          width="85%"
          height="90%"
          minHeight="30%"
          schema={Schema}
          handleSaved={this.handleSaved}
          configuracao={this.state.configuracao}
          id={id}
          item={
            this.props.item ? this.props.item : id && id > 0 ? { id: id } : null
          }
          showDelete={showDelete}
          onDeleting={this.handleDeleteClick}
          resources={resources}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloInteligenciaArtificial: state.user.moduloInteligenciaArtificial,
    user: state.user,
    addons: state.user.addonsDisponiveis,
  };
}
export default injectIntl(connect(mapStateToProps)(RiscoEdit));
