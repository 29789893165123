import React from 'react';
import MdiIcon from 'src/componentes/mdi-icon';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import Info from 'src/componentes/info';
import { DownloadArquivoSistema, DownloadFileBucket } from 'src/services/downloadFileUrl';
import Avatar from 'src/componentes/avatar';
import './dropzone-arquivo-exibicao-tabela.css';

const handleDownloadArquivo = (arquivo) => {
  if (arquivo.fileBucket) {
    DownloadFileBucket(arquivo.nome);
  } else if (arquivo.guid) {
    DownloadArquivoSistema(arquivo.guid);
  } else if (arquivo.id) {
    DownloadArquivoSistema(arquivo.id);
  }
};

const DropzoneArquivoExibicaoTabela = ({ arquivos = [], onDownload, onDeleteFile }) => {
  return (
    <div className="dropzone-anexo">
      <table className="dropzone-table">
        <thead>
          <tr>
            <th><FormattedMessage id="nome" /></th>
            <th>
              <FormattedMessage id="inseridoPor" />
              <Info id="responsavelAnexoHint" className="tabela-hint" placement="right" text={<FormattedMessage id="responsavelAnexoHint" />} />
            </th>
            <th>
              <FormattedMessage id="data" />
              <Info id="dataInclusaolAnexoHint" className="tabela-hint" placement="right" text={<FormattedMessage id="dataInclusaolAnexoHint" />} />
            </th>
            <th style={{ width: '60px' }} />
          </tr>
        </thead>
        <tbody>
          {arquivos.map((arquivo, idx) => {
            const rowClass = arquivo.isNew ? 'new-row' : '';

            return (
              <tr key={arquivo.id || idx} className={rowClass}>
                <td>{arquivo.nome ?? '-'}</td>
                <td className="text-center">
                  {arquivo.responsavelNome ? (
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <Avatar size={32} foto={arquivo.fotoColaborador} label={arquivo.responsavelNome} showLabel={true} />
                      </Col>
                    </Row>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {arquivo.dataInclusao ? new Date(arquivo.dataInclusao).toLocaleDateString() : '-'}
                </td>
                <td>
                  {!arquivo.isNew && (
                    <button type="button" className="btn btn-link p-0 mr-2" onClick={() => handleDownloadArquivo(arquivo)}>
                      <MdiIcon icon="download" />
                    </button>
                  )}
                  <button type="button" className="btn btn-link p-0" onClick={() => onDeleteFile?.(arquivo)}>
                    <MdiIcon icon="delete" color='red' />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DropzoneArquivoExibicaoTabela;
