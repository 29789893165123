import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormGroup, Input, Label } from 'reactstrap';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import ListEvent from '../listEvent';
import { getToken } from '../services/auth';

const SelectMeeting = ({
  disabled,
  isTeamsMeetingChecked,
  onCheckboxChange,
  onEventSelect,
  model,
  handleTeamsPermissionDisabled,
}) => {
  const intl = useIntl();
  const [refreshEvents, setRefreshEvents] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleCheckboxChange = () => {
    onCheckboxChange(!isTeamsMeetingChecked);
    handleEventSelect(null);

    if (!isAuthenticated) {
      getToken(instance);
    }
  };

  const handleEventSelect = (event) => {
    if (!event) {
      setSelectedEvent(null);
      onEventSelect(null);
      model.getModel('meetingId').requestChange(null);
    } else if (selectedEvent && selectedEvent.id === event?.id) {
      setSelectedEvent(null);
      onEventSelect(null);
      model.getModel('meetingId').requestChange(null);
    } else {
      setSelectedEvent(event);
      onEventSelect(event);
      model.getModel('meetingId').requestChange(event.id);
    }
  };

  const handleEventCreated = () => {
    setRefreshEvents((prev) => !prev);
  };

  useEffect(() => {
    if (!!model.getModel('meetingId')?.value) {
      if (!isAuthenticated) {
        getToken(instance);
        handleTeamsPermissionDisabled(true);
      } else {
        handleTeamsPermissionDisabled(false);
      }
      onCheckboxChange(true, false);
    }
  }, [model.getModel('meetingId')?.value]);

  useEffect(() => {
    const organizerAddress = selectedEvent?.organizer?.emailAddress?.address;
    if (!organizerAddress) {
      handleTeamsPermissionDisabled(false);
      return;
    }

    if (organizerAddress !== activeAccount.username) {
      handleTeamsPermissionDisabled(true);
    } else {
      handleTeamsPermissionDisabled(false);
    }
  }, [selectedEvent]);

  return (
    <>
      {isAuthenticated && (
        <div className="d-flex flex-column mb-4">
          <FormGroup check className="d-flex align-items-center">
            <Input
              id="teamsCheckbox"
              type="checkbox"
              checked={isTeamsMeetingChecked}
              onChange={handleCheckboxChange}
              disabled={disabled}
              className="me-2 mt-0"
            />
            <Label check htmlFor="teamsCheckbox" className="font-semibold mb-0">
              {intl.formatMessage({ id: 'checkboxInfoTeams' })}
            </Label>
          </FormGroup>
        </div>
      )}

      {isAuthenticated && isTeamsMeetingChecked && (
        <div className="d-flex flex-column gap-4 mb-2">
          <ListEvent
            refreshEvents={refreshEvents}
            onEventSelect={handleEventSelect}
            selectedEvent={selectedEvent}
            onEventCreated={handleEventCreated}
            model={model}
            setSelectedEvent={setSelectedEvent}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default SelectMeeting;
