import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import Button from 'src/componentes/button';
import LoadingContainer from 'src/componentes/loading-container';
import Input from 'src/componentes/input';
import { Row, Col } from 'reactstrap';
import Dialog from 'src/componentes/dialog';
import OrganizacaoLookup from 'src/lookups/item-organizacao';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import StatusPlanoContingenciaLookup from 'src/lookups/StatusPlanoContingenciaLookup';
import Panel from 'src/componentes/panel';
import RiscosAssociadosView from 'src/paginas/gestao-risco/plano-contigencia/RiscosAssociados/RiscosAssociadosView';
import TemplateAcaoView from 'src/paginas/gestao-risco/plano-contigencia/TemplateAcao/TemplateAcaoView';
import Alert from 'src/componentes/message-box/alert';
import ContentManager from 'src/componentes/content-manager';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import { ENTIDADE_ANEXOS } from 'src/utils/constants';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';

const PlanoContigenciaEdit = ({
  item,
  openEdit,
  handleRefresh,
  endpoint,
  configuracao,
  cicloAvaliacao,
  disabled,
  isNew,
  ...rest
}) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const isDisabled = !form?.permissions?.allowEdit || (isNew && disabled);

  const handleChange = (field, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const getModel = async () => {
    const id = item?.id;

    try {
      setIsLoading(true);
      const url = !isNew ? `${endpoint}/Edit` : `${endpoint}/Create`;
      const response = await http.post(url, { id });

      setForm({ ...response.data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getModel();
  }, []);

  const validateRequiredField = (form) => {
    const ignoreIdIntegracao =
      configuracao?.utilizarCodigoAutomaticoPlanoContigencia && form.id <= 0;
    const requiredFields = ignoreIdIntegracao
      ? ['nome', 'status', 'responsavel', 'area']
      : ['nome', 'status', 'idIntegracao', 'responsavel', 'area'];
    const errors = {};
    let hasError = false;

    for (const field of requiredFields) {
      const value = _.get(form, field);
      if (value == null || !value) {
        errors[field] = true;
        hasError = true;
      }
    }

    setErrors(errors);

    return { hasError };
  };

  const handleSave = async () => {
    var { hasError } = validateRequiredField(form);

    if (hasError)
      ContentManager.addContent(
        <Alert
          message={intl.formatMessage({
            id: 'label.osCamposDestacadosEmVermelhoSaoObrigatoriosFavorPreenchelos',
          })}
        />
      );
    else {
      try {
        setIsLoading(true);
        await http.post(`${endpoint}/Save`, {
          model: {
            ...form,
            id: item.id,
          },
        });

        enqueueSnackbar(
          intl.formatMessage({ id: 'label.registroSalvoComSucesso' }),
          {
            variant: 'success',
          }
        );

        handleClose();
        handleRefresh();
      } catch (err) {
        errorHandler(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const selectRiscosAssociados = (itens) => {
    handleChange('riscosAssociados', itens);
  };

  const editActions = [
    <Button key={1} onClick={handleClose} color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      key={2}
      disabled={isDisabled}
      type="primary"
      onClick={handleSave}
      htmlType="submit"
      className="ml-2"
    >
      {intl.formatMessage({ id: 'label.salvar' })}
    </Button>,
  ];

  return (
    <Dialog
      minWidth={'60%'}
      maxWidth={'70%'}
      minHeight={'80%'}
      actions={editActions}
      onRequestClose={handleClose}
      ref={ref}
      padContent
      title={intl.formatMessage({ id: 'labelPlanoContigencia' })}
      {...rest}
    >
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col md={3}>
            <Input
              required
              disabled={
                isDisabled ||
                (configuracao?.utilizarCodigoAutomaticoPlanoContigencia &&
                  isNew) ||
                (!isNew &&
                  !configuracao?.permitirAlterarCodigoAutomaticoPlanoContigencia)
              }
              style={{ height: '37px' }}
              model={{
                label: intl.formatMessage({ id: 'idIntegracao' }),
                value: form?.idIntegracao,
                requestChange: (v) => handleChange('idIntegracao', v),
                errors: errors?.idIntegracao,
              }}
            />
          </Col>
          <Col md={3}>
            <Input
              required
              disabled={isDisabled}
              style={{ height: '37px' }}
              type="text"
              model={{
                label: intl.formatMessage({ id: 'nome' }),
                value: form?.nome,
                requestChange: (v) => handleChange('nome', v),
                errors: errors?.nome,
              }}
            />
          </Col>
          <Col md={3}>
            <ColaboradorSimplificadoLookup
              required
              disabled={isDisabled}
              type="text"
              model={{
                label: capitalizeFirstLetter(resources.responsavel),
                value: form?.responsavel,
                requestChange: (v) => handleChange('responsavel', v),
                errors: errors?.responsavel,
              }}
            />
          </Col>
          <Col md={3}>
            <OrganizacaoLookup
              disabled={isDisabled}
              required
              model={{
                label: capitalizeFirstLetter(resources.area),
                value: form?.area,
                requestChange: (v) => handleChange('area', v),
                errors: errors?.area,
              }}
            />
          </Col>
        </Row>
        <Row>
          {configuracao?.usarCampoPlanoContigenciaProposito && (
            <Col md={4}>
              <Input
                disabled={isDisabled}
                type="textarea"
                rows={3}
                model={{
                  label: intl.formatMessage({ id: 'labelProposito' }),
                  value: form?.proposito,
                  requestChange: (v) => handleChange('proposito', v),
                }}
              />
            </Col>
          )}
          {configuracao?.usarCampoPlanoContigenciaEscopo && (
            <Col md={4}>
              <Input
                disabled={isDisabled}
                type="textarea"
                rows={3}
                model={{
                  label: intl.formatMessage({ id: 'escopo' }),
                  value: form?.escopo,
                  requestChange: (v) => handleChange('escopo', v),
                }}
              />
            </Col>
          )}
          {configuracao?.usarCampoPlanoContigenciaObjetivos && (
            <Col md={4}>
              <Input
                disabled={isDisabled}
                type="textarea"
                rows={3}
                model={{
                  label: intl.formatMessage({ id: 'labelObjetivos' }),
                  value: form?.objetivos,
                  requestChange: (v) => handleChange('objetivos', v),
                }}
              />
            </Col>
          )}
        </Row>
        <Row>
          {configuracao?.usarCampoPlanoContigenciaCriteriosAtivacao && (
            <Col md={6}>
              <Input
                disabled={isDisabled}
                type="textarea"
                rows={3}
                model={{
                  label: intl.formatMessage({ id: 'labelCriteriosAtivacao' }),
                  value: form?.criteriosAtivacao,
                  requestChange: (v) => handleChange('criteriosAtivacao', v),
                }}
              />
            </Col>
          )}
          {configuracao?.usarCampoPlanoContigenciaRecursosNecessarios && (
            <Col md={6}>
              <Input
                disabled={isDisabled}
                type="textarea"
                rows={3}
                model={{
                  label: intl.formatMessage({ id: 'labelRecursosNecessarios' }),
                  value: form?.recursosNecessarios,
                  requestChange: (v) => handleChange('recursosNecessarios', v),
                }}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={4}>
            <StatusPlanoContingenciaLookup
              disabled={isDisabled}
              required
              model={{
                label: intl.formatMessage({ id: 'status' }),
                value: form?.status,
                requestChange: (v) => handleChange('status', v),
                errors: errors?.status,
              }}
            />
          </Col>
          <Col md={2}>
            <div className="d-flex inline mt-2">
              <ArquivoUploadAnexos
                formGroupStyle={{ marginBottom: 0 }}
                onlyRead={isDisabled}
                tipoAnexo={ENTIDADE_ANEXOS.PLANO_CONTINGENCIA}
                rightLabel={true}
                model={{
                  label: intl.formatMessage({ id: 'anexo' }),
                  value: form?.arquivos,
                  requestChange: (arquivos) =>
                    handleChange('arquivos', arquivos),
                }}
              />
            </div>
          </Col>
        </Row>
        {form?.camposCustomizados && form?.camposCustomizados.length > 0 && (
          <Row>
            <Col md={12}>
              <Panel
                header={capitalizeFirstLetter(
                  intl.formatMessage({ id: 'informacoesComplementares' })
                )}
              >
                <DesenharCampoCustomizado
                  model={{
                    label: intl.formatMessage({
                      id: 'informacoesComplementares',
                    }),
                    value: form?.camposCustomizados,
                    requestChange: (e) => handleChange('camposCustomizados', e),
                  }}
                />
              </Panel>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12}>
            <Panel
              header={capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelPlanoAcao' },
                  { acao: resources.acao }
                )
              )}
            >
              <Row>
                <Col md={12} style={{ marginTop: 20 }}>
                  <TemplateAcaoView
                    fieldName="templateAcoes"
                    handleChangeCallBack={handleChange}
                    allowNew={!isDisabled}
                    isDisabled={isDisabled}
                    model={form.templateAcoes}
                    permitirPlanoContigenciaSemResponsavel={
                      configuracao?.permitirPlanoContigenciaSemResponsavel
                    }
                  />
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Panel
              header={capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelRiscosVinculados' },
                  { riscos: resources.riscos }
                )
              )}
            >
              <Row>
                <Col md={12} style={{ marginTop: 20 }}>
                  <RiscosAssociadosView
                    allowNew={!isDisabled}
                    model={form.riscosAssociados}
                    requestChange={handleChange}
                    fieldName={'riscosAssociados'}
                    selectedCallback={selectRiscosAssociados}
                  />
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </LoadingContainer>
    </Dialog>
  );
};

export default PlanoContigenciaEdit;
