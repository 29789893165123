import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';

import Edit from './HistoricoOcupacaoEditView';
import Search from './Search';
import SearchSchema from './SearchSchema';
import { capitalizeFirstLetter } from 'src/utils/string';
import { FILTROS } from 'src/utils/constants';
import useAuth from 'src/hooks/useAuth';
import MdiIcon from 'src/componentes/mdi-icon';
import http from 'src/services/httpService';
import LoadingContainer from 'src/componentes/loading-container';
import errorHandler from 'src/utils/errorHandler';

function HistoricoOcupacao({ disabled, dataValue, parameters, ...rest }) {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const [configuracoesGerais, setConfiguracoesGerais] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { cargoId, colaboradorId } = parameters;

  const handleRemove = (item, index) => {
    dataValue.value.splice(index, 1);
    dataValue.requestChange(dataValue.value);
  };

  const getParam = async () => {
    if (configuracoesGerais == null) {
      try {
        const response = await http.post(
          `/ConfiguracaoSistema/ObterConfiguracoes`,
          {}
        );
        const { definirColaboradoresComSemMetasAssociadas } =
          response.data?.model ?? {};

        setConfiguracoesGerais({
          definirColaboradoresComSemMetasAssociadas:
            definirColaboradoresComSemMetasAssociadas,
        });

        setIsLoading(false);
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  useEffect(() => {
    getParam();
  }, []);

  const handleSaveItem = (saved, isNew, item, index) => {
    if (saved) {
      let novaLista = dataValue.value;

      if (isNew) {
        if (novaLista && novaLista.length > 0) novaLista.push(item);
        else novaLista = [].concat(item);
      } else novaLista[index] = item;

      dataValue.requestChange(novaLista);
    }
  };

  const handleCargo = (item) => {
    return <span>{item.cargo.nome}</span>;
  };

  const handleColaborador = (item) => {
    return <span>{item.colaborador.nome}</span>;
  };

  const handleNomeLider = (item) => {
    return <span>{item?.lider?.nome}</span>;
  };

  const handleArea = (item) => {
    return <span>{item.area.nome}</span>;
  };

  const handleDataInicio = (item) => {
    return <span>{moment(item.dataInicioCargo).format('DD/MM/YYYY')}</span>;
  };

  const handleDataTermino = (item) => {
    return <span>{item.dataTerminoCargo && moment(item.dataTerminoCargo).format('DD/MM/YYYY')}</span>;
  };

  const handleLider = (item) => {
    if (item.ehLider) return <MdiIcon icon="check" size={18} />;
  };

  const handleMeta = (item) => {
    if (item.utilizaMeta) return <MdiIcon icon="check" size={18} />;
  };
  
  const localSearchFilter = (filter) => {
    let itens = dataValue.value;

    const { textoPesquisa, colaborador, cargo, area } = filter;

    if (textoPesquisa != null && textoPesquisa != '') {
      itens = itens.filter((x) => x.colaborador.nome.toLowerCase().includes(textoPesquisa.toLowerCase()));
    }

    if (colaborador != null) {
      itens = itens.filter((x) => x.colaborador.id == colaborador.id);
    }

    if (cargo != null) {
      itens = itens.filter((x) => x.cargo.id == cargo.id);
    }

    if (area != null) {
      itens = itens.filter((x) => x.area.id == area.id);
    }

    return itens;
  };

  const edit = colaboradorId ? !disabled : false; // só pode editar na listagem de colaborador

  return (
    <LoadingContainer isLoading={isLoading}>
      {!isLoading && <List
        local
        dataSource={dataValue.value}
        editComponent={Edit}
        handleSaveItem={handleSaveItem}
        handleRemove={handleRemove}
        searchComponent={<Search />}
        searchSchema={SearchSchema}
        showSearch={true}
        showNew={edit}
        showMenu={edit}
        parameters={{ cargoId, colaboradorId }}
        showExport={false}
        showPagination={false}
        disabled={disabled}
        //changedColumns={configuracoesGerais?.definirColaboradoresComSemMetasAssociadas}
        filtroId={cargoId ? FILTROS.COLABORADOR_HISTORICO_OCUPACAO_CARGO : FILTROS.COLABORADOR_HISTORICO_OCUPACAO}
        localSearchFilter={localSearchFilter}
        {...rest}
      >
        <ListColumn headerText={intl.formatMessage({ id: 'idIntegracao' })} valueField="idIntegracao" />
        {colaboradorId && <ListColumn headerText={capitalizeFirstLetter(resources.cargo)} valueFunction={handleCargo} />}
        {cargoId && <ListColumn headerText={capitalizeFirstLetter(resources.colaborador)} valueFunction={handleColaborador} />}
        <ListColumn headerText={capitalizeFirstLetter(resources.area)} valueFunction={handleArea} />
        <ListColumn headerText={intl.formatMessage({ id: 'dataInicio' })} valueFunction={handleDataInicio} />
        <ListColumn headerText={intl.formatMessage({ id: 'dataTermino' })} valueFunction={handleDataTermino} />
        <ListColumn headerText={intl.formatMessage({ id: 'ehLider' })} valueFunction={handleLider} />
        <ListColumn headerText={intl.formatMessage({ id: 'lider' })} valueFunction={handleNomeLider}  />
        { configuracoesGerais?.definirColaboradoresComSemMetasAssociadas && <ListColumn headerText={intl.formatMessage({ id: 'labelTemMeta' })+'?'} valueFunction={handleMeta}  />}
      </List>}
    </LoadingContainer>
  );
}

export default HistoricoOcupacao;
