import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';

@autobind
class Uf extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/UfLookup" {...this.props} />;
  }
}

export default Uf;
