import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select2';
import CalendarioEdit from '../paginas/calendario/edit';

@autobind
class CalendarioLookup extends React.Component {
  render() {
    return (
      <Lookup
        labelKey="descricao"
        valueKey="id"
        url="/CalendarioLookup"
        editComponent={CalendarioEdit}
        {...this.props}
      />
    );
  }
}

export default CalendarioLookup;
