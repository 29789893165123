import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import Panel from '../../../componentes/panel';
import { capitalizeFirstLetter } from '../../../utils/string';
import FormulaVisao from '../../../paginas/formula/componentes/formula-visao';
import FormulaEdit from './formula/edit';
import GatilhoEdit from './gatilho/edit';
import Content from '../../../componentes/pages/content';

@autobind
class Bonus extends React.Component {
  renderFormula(item) {
    return <FormulaVisao formula={item.expressao} isItem={false}></FormulaVisao>;
  }

  render() {
    let { model, intl } = this.props;
    let disabled = model.getModel('permissions').value ? !this.props.model.getModel('permissions').getModel('allowEdit').value : false;
    return (
      <Panel>
        <Row>
          <Col>
            <Panel>
              <List
                url="/ColaboradorGatilho"
                showTitle={true}
                showSearch={false}
                title={intl.formatMessage({ id: 'gatilhos' })}
                editComponent={GatilhoEdit}
                parameters={{ ColaboradorId: model.getModel('id').value }}
                showExport={false}
                showNew={!disabled}
                showDelete={true}
                disabled={disabled}
                {...this.props}
              >
                <ListColumn headerText={intl.formatMessage({ id: 'gatilho' })} valueField="gatilho" />
              </List>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Panel>
              <List
                showTitle={true}
                title={intl.formatMessage({ id: 'label.formulas' })}
                url="/Formula"
                editComponent={FormulaEdit}
                showPage={false}
                showDelete={true}
                showMenu={!disabled}
                renderMenu={this.renderMenu}
                parameters={{ colaboradorId: model.getModel('id').value, alterado: false, tipoValorId: 2 }}
                showExport={false}
                showNew={!disabled}
                {...this.props}
              >
                <ListColumn headerText={intl.formatMessage({ id: 'idIntegracao' })} valueField="idIntegracao" sortField="idIntegracao" />
                <ListColumn headerText={intl.formatMessage({ id: 'formula' })} valueField="formula" valueFunction={this.renderFormula} />
                <ListColumn headerText={intl.formatMessage({ id: 'dataInicio' })} valueField="dataInicio" />
                <ListColumn headerText={intl.formatMessage({ id: 'dataTermino' })} valueField="dataTermino" />
              </List>
            </Panel>
          </Col>
        </Row>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Bonus));
