import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Dialog from 'src/componentes/dialog';
import Tab from 'src/componentes/tabs/tab';
import Tabs from 'src/componentes/tabs/tabs';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import GruposList from 'src/paginas/grupo-email/view';
import ColaboradoresList from 'src/paginas/colaborador/view';
import Button from 'src/componentes/button';
import distinctBy from 'src/utils/ArrayDistinctBy';

const SearchComponent = ({ selectedCallback, selectedItems, ...rest }) => {
  const intl = useIntl();

  const [data, setData] = useState({
    colaboradores: selectedItems
      ? [
          ...selectedItems.filter(
            (x) => x.identificadorLista === 'colaboradores'
          ),
        ]
      : [],
    grupos: selectedItems
      ? [...selectedItems.filter((x) => x.identificadorLista === 'grupos')]
      : [],
  });

  const dialogRef = useRef();
  const { terms: resources } = useAuth();

  const handleClose = () => {
    if (dialogRef?.current) dialogRef?.current.close();
  };

  const handleSelect = (key, itens) => {
    const isGroup = key === 'grupos';
    const newList = itens ? distinctBy(itens, 'id') : [];

    setData((prev) => ({
      ...prev,
      [key]:
        newList?.map((x) => {
          x.idLookup = isGroup ? `G-${x.id}` : `C-${x.id}`;
          x.identificadorLista = key;
          x.descricao = x.descricao ?? x.nome;
          return x;
        }) || [],
    }));
  };

  const handleSave = () => {
    if (selectedCallback)
      selectedCallback([...data.colaboradores, ...data.grupos]);
    handleClose();
  };

  const actions = [
    <Button onClick={handleClose} color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      className="ml-2"
      type="primary"
      onClick={handleSave}
      htmlType="submit"
    >
      {intl.formatMessage({ id: 'label.confirmar' })}
    </Button>,
  ];

  const colaboradoresList = data?.colaboradores;
  const gruposColaboradoresList = data?.grupos;

  return (
    <Dialog
      ref={dialogRef}
      width={800}
      maxHeight="80%"
      actions={actions}
      padContent
      onRequestClose={handleClose}
      {...rest}
    >
      <Tabs>
        <Tab
          label={intl.formatMessage({
            id: capitalizeFirstLetter(resources.colaboradores),
          })}
        >
          <ColaboradoresList
            multiple
            select
            selectRealTime
            selectedCallback={(itens) => handleSelect('colaboradores', itens)}
            selectedItems={colaboradoresList}
            selectedIds={colaboradoresList}
          />
        </Tab>
        <Tab label={intl.formatMessage({ id: 'grupos' })}>
          <GruposList
            multiple
            select
            selectRealTime
            selectedCallback={(itens) => handleSelect('grupos', itens)}
            selectedItems={gruposColaboradoresList}
            selectedIds={gruposColaboradoresList}
          />
        </Tab>
      </Tabs>
    </Dialog>
  );
};

export default SearchComponent;
