import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
  CardHeader,
} from 'reactstrap';

import FiltroChip from 'src/componentes/filtro-chip';
import Button from 'src/componentes/button';
import MdiIcon from 'src/componentes/mdi-icon';
import ContentManager from 'src/componentes/content-manager';
import Filtro from 'src/componentes/filtro';
import ColunasTabelas from 'src/componentes/list/componentes/colunas-tabela';
import Email from '../email';

function ListToolbar({
  intl,
  showSearch,
  showFastSearch = true,
  showExport,
  searchText,
  searchSchema,
  searchComponent,
  onSearchChange,
  onColumnsChange,
  onRefresh,
  onExport,
  allColumns,
  columns,
  columnsTag,
  filterTag,
  filter,
  useSavedFilter,
  actions,
  permissions,
  handleNewClick,
  showNew,
  refreshFilterChip,
  exportActions,
  sortField,
  renderNewButton,
  onChangeUrl,
  showExportEmail,
  urlExportEmail,
  dateFilterFormat,
  actionsLocal,
  customButtons,
  ...rest
}) {
  const [searchValue, setSearchValue] = useState(searchText);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const handleSearchChange = (e) => {
    clearTimeout(searchTimeout);

    const value = e.target.value;
    setSearchValue(value);

    setSearchTimeout(
      setTimeout(() => {
        onSearchChange && onSearchChange(value);
      }, 1000)
    );
  };

  const handleSearchClick = () => {
    let customFilter = filter;
    if (!customFilter) customFilter = undefined;

    ContentManager.addContent(
      <Filtro
        {...rest}
        filtroId={filterTag}
        searchComponent={searchComponent}
        searchSchema={searchSchema}
        selectedFilter={customFilter}
        usarFiltroSalvo={useSavedFilter}
        onSearch={(filter) => onRefresh && onRefresh(filter)}
      />
    );
  };

  const handleNewClickButton = () => {
    handleNewClick(onRefresh);
  };

  const handlePDFClick = () => {
    if (onExport) onExport('pdf');
  };

  const handleExcelClick = () => {
    if (onExport) onExport('xlsx');
  };

  const handleEmailClick = () => {
    urlExportEmail &&
      ContentManager.addContent(
        <Email
          model={{ filtro: filter ? filter : null }}
          urlExport={urlExportEmail}
        />
      );
  };

  const handleConfigColumns = () => {
    ContentManager.addContent(
      <ColunasTabelas
        columns={columns}
        allColumns={allColumns}
        tag={columnsTag}
        handleAtualizarColunas={() => onColumnsChange && onColumnsChange()}
      />
    );
  };

  return (
    <CardHeader>
      <div className="align-items-center d-flex justify-content-between">
        {showSearch && showFastSearch && (
          <div style={{ width: 187, minWidth: 187 }} className="d-inline-flex">
            <Input
              className="mr-2"
              placeholder={intl.formatMessage({ id: 'label.pesquisaRapida' })}
              autoFocus
              value={searchValue}
              onChange={handleSearchChange}
              type="text"
            />
          </div>
        )}

        {filterTag && (
          <div style={{ minWidth: 40 }} className="mr-auto">
            <FiltroChip
              updateProps
              filtroId={filterTag}
              onSearch={(newFilter) => onRefresh && onRefresh(newFilter)}
              searchSchema={searchSchema}
              selectedFilter={
                filter && filter.valor != null ? filter.valor : filter
              }
              refreshFilterChip={++refreshFilterChip}
              onChangeUrl={onChangeUrl}
              dateFormat={dateFilterFormat}
            />
          </div>
        )}

        <div
          className={classnames(
            filterTag && showSearch
              ? 'justify-content-end'
              : 'justify-content-start',
            'd-flex'
          )}
        >
          {customButtons && customButtons()}
          {showNew &&
            permissions &&
            permissions.allowCreate &&
            (renderNewButton ? (
              renderNewButton()
            ) : (
              <Button className="ml-2" onClick={() => handleNewClickButton()}>
                <MdiIcon icon="plus" />
              </Button>
            ))}
          {showSearch && filterTag && (
            <Button onClick={handleSearchClick} className="ml-2">
              <MdiIcon icon="filter" />
            </Button>
          )}
          {showExport && (
            <UncontrolledDropdown
              group
              direction="left"
              className="ml-2"
              key="list"
            >
              <DropdownToggle color="secondary">
                <MdiIcon icon="dots-horizontal" />
              </DropdownToggle>
              <DropdownMenu container="body">
                {actionsLocal &&
                  actionsLocal.map((a, index) =>
                    React.cloneElement(a, {
                      key: index + 4,
                    })
                  )}
                {showExportEmail && (
                  <DropdownItem eventkey="Drop1" onClick={handleEmailClick}>
                    <FormattedMessage id="enviarEmail" />
                  </DropdownItem>
                )}
                <DropdownItem eventkey="Drop2" onClick={handlePDFClick}>
                  <FormattedMessage id="label.exportarPDF" />
                </DropdownItem>
                <DropdownItem eventkey="Drop3" onClick={handleExcelClick}>
                  <FormattedMessage id="label.exportarExcel" />
                </DropdownItem>
                {exportActions
                  ? exportActions(filter, searchValue, sortField)
                  : ''}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {columnsTag && (
            <Button
              className="ml-2"
              color="secondary"
              onClick={handleConfigColumns}
            >
              <MdiIcon icon="format-columns" color="secondary" />
            </Button>
          )}
          {actions &&
            actions.length > 0 &&
            actions.map((a, index) =>
              React.cloneElement(a, {
                key: index,
              })
            )}
        </div>
      </div>
    </CardHeader>
  );
}

export default injectIntl(ListToolbar);
