import api from '../api';
import errorHandler from '../utils/error-handler';

import { GANHOSPORACAO_SET, UPDATE_GANHOSPORACAO } from '../types';

export const setGanhosPorAcao = (valores, acaoId) => (dispatch) =>
  api
    .setGanhosPorAcao(valores, acaoId)
    .then((res) => {
      dispatch(setGanhosPorAcao(res));
    })
    .catch((error) => {
      errorHandler(error);
    });

export const valoresGanhosPorAcaoUpdated = (valoresGanhosPorAcao) => ({
  type: UPDATE_GANHOSPORACAO,
  valoresGanhosPorAcao,
});

// export const setGanhosPorAcao = ganhosPorAcao => dispatch =>
//   dispatch(setGanhosPorAcaoSet(ganhosPorAcao));

export const updateGanhosPorAcao = (valoresGanhosPorAcao) => (dispatch) => dispatch(valoresGanhosPorAcaoUpdated(valoresGanhosPorAcao));
