import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';

import InputNumber from 'src/componentes/input-number';
import Lookup from './lookup';
import { FormGroup, Input, Label } from 'src/componentes/Form';

@autobind
class DesenharCampoCustomizado extends React.Component {
  static defaultProps = {
    colValue: null,
    mdFields: 3,
    ignoreErrors: false,
    usarFiltroMultiSelecaoListaOpcao: false,
  };

  handleChange(index, item) {
    let itens = this.props.model.value;

    if (index !== -1) {
      itens[index].valor = item;
    }

    this.props.model.requestChange(itens);
  }

  handleLookupChange(index, item) {
    let itens = this.props.model.value;

    if (index !== -1) {
      itens[index].valor = item ? item.id : null;
    }

    this.props.model.requestChange(itens);
  }

  handleLookupChangeMulti(index, item) {
    let itens = this.props.model.value;
    if (index !== -1)
      itens[index].campo.valores = item.map((i) =>
        itens[index].campo.opcoesTabela.find((op) => op.id == i.id)
      );
    this.props.model.requestChange(itens);
  }

  desenharCampoCustomizado(item, index, desconsiderarObrigatorio) {
    const visible = item.campo.visivel == null || item.campo.visivel;
    const required = item?.campo?.obrigatorio && !desconsiderarObrigatorio;
    const errors =
      required &&
      !this.props.ignoreErrors &&
      (item.valor == null ||
        item.valor == '' ||
        item?.campo?.valores?.length <= 0);

    if (!visible) return;

    let disabled = this.props.customIsDisabledValidation
      ? this.props.customIsDisabledValidation(item)
      : this.props.disabled;

    const { maxLength } = this.props;
    if (item.campo.tipo.id === 1) {
      return (
        <FormGroup>
          <Label>
            {item.campo.nome}
            {required ? ' *' : ''}
          </Label>
          <Input
            style={{ height: '33px' }}
            required={required}
            maxLength={maxLength ? maxLength : 3000}
            onBlur={this.props.getAlteracao}
            disabled={disabled}
            value={item.valor}
            errors={errors}
            onChange={(value) => this.handleChange(index, value.target.value)}
          />
        </FormGroup>
      );
    }
    if (item.campo.tipo.id === 2) {
      return (
        <FormGroup>
          <Label>{item.campo.nome}</Label>
          {required ? ' *' : ''}
          <Input
            disabled={disabled}
            required={required}
            onBlur={this.props.getAlteracao}
            type="textarea"
            rows={this.props.rowsTextArea ? this.props.rowsTextArea : '2'}
            value={item.valor}
            onChange={(value) => this.handleChange(index, value.target.value)}
            errors={errors}
            maxLength={maxLength ? maxLength : 3000}
          />
        </FormGroup>
      );
    }
    if (item.campo.tipo.id === 3) {
      return (
        <InputNumber
          style={{ height: '33px' }}
          required={required}
          onBlur={this.props.getAlteracao}
          precision={0}
          disabled={disabled}
          model={{
            errors: errors,
            value: item.valor,
            label: item.campo.nome,
            requestChange: this.handleChange.bind(this, index),
          }}
        />
      );
    }
    if (item.campo.tipo.id === 4) {
      return (
        <InputNumber
          style={{ height: '33px' }}
          required={required}
          onBlur={this.props.getAlteracao}
          precision={2}
          disabled={disabled}
          model={{
            errors: errors,
            value: item.valor,
            label: item.campo.nome,
            requestChange: this.handleChange.bind(this, index),
          }}
        />
      );
    }
    if (item.campo.tipo.id === 5) {
      if (item.campo.multiSelecao || this.props.usarFiltroMultiSelecaoListaOpcao) {
        return (
          <Lookup
            required={required}
            onBlur={this.props.getAlteracao}
            disabled={disabled}
            options={item.campo.opcoesTabela}
            multi
            multiValueWrapper={'max-width: 300px;'}
            model={{
              errors: errors,
              value:
                item.campo.valores &&
                item.campo.valores.map(({ id, descricao }) => ({
                  id,
                  descricao: <span title={descricao}>{descricao}</span>,
                })),
              label: item.campo.nome,
              requestChange: this.handleLookupChangeMulti.bind(this, index),
            }}
          />
        );
      } else {
        let value =
          item.campo.opcoesTabela &&
          item.campo.opcoesTabela.find((op) => op.id == item.valor)
            ? item.campo.opcoesTabela.find((op) => op.id == item.valor)
            : null;
        return (
          <Lookup
            required={required}
            onBlur={this.props.getAlteracao}
            disabled={disabled}
            options={item.campo.opcoesTabela}
            model={{
              errors: errors,
              value: value,
              label: item.campo.nome,
              requestChange: this.handleLookupChange.bind(this, index),
            }}
          />
        );
      }
    }
  }

  render() {
    let { model, colValue, desconsiderarObrigatorio, mdFields, mdFieldsTo } = this.props;
    return model.value ? (
      colValue ? (
        <Row>
          {model.value.map((item, index) => (
            <Col md={colValue} key={index}>
              {this.desenharCampoCustomizado(
                item,
                index,
                desconsiderarObrigatorio
              )}
            </Col>
          ))}
        </Row>
      ) : (
        <Row>
          {model.value.map((item, index) => (
            <Col
              md={
                item.campo &&
                item.campo.tipo &&
                (item.campo.tipo.id == 1 || item.campo.tipo.id == 2) && 
                (!mdFieldsTo || mdFieldsTo.find((x) => x === item.campo.tipo.id))
                  ? 12
                  : mdFields
              }
              key={index}
            >
              {this.desenharCampoCustomizado(
                item,
                index,
                desconsiderarObrigatorio
              )}
            </Col>
          ))}
        </Row>
      )
    ) : (
      <div></div>
    );
  }
}

export default DesenharCampoCustomizado;
