import React from 'react';
import Lookup from 'src/componentes/select2';

const TipoCalculoItemCestaLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/TipoCalculoItemCestaLookup"
    clearable={false}
    {...rest}
  />
);

export default TipoCalculoItemCestaLookup;
