import React from 'react';
import { autobind } from 'core-decorators';
import Input from '../../componentes/input';
import InputNumber from '../../componentes/input-number';
import { Row, Col, Table } from 'reactstrap';
import MdiIcon from '../../componentes/mdi-icon';
import Button from '../../componentes/button';
import FarolLookup from '../../lookups/farol';
import Panel from '../../componentes/panel';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

@autobind
class TabelaValorTabela extends React.Component {
  handleValueChange(index, field, value) {
    let itens = this.props.model.value;
    itens[index][field] = value;

    this.props.model.requestChange(itens);
  }

  handleNovoItem() {
    let itens = this.props.model && this.props.model.value;
    if (itens == null || itens == undefined) itens = [];
    itens.push({
      descricao: null,
      ordem: null,
      farol: null,
      valor: null,
    });
    this.props.model.requestChange(itens);
  }

  handleExcluir(index) {
    let itens = this.props.model.value;
    itens.splice(index, 1);
    this.props.model.requestChange(itens);
  }

  reorderList(startIndex, endIndex) {
    const newList = [...this.props.model.value];
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);

    return newList;
  }

  handleDragEnd(result) {
    if (!result.destination) return;

    const newList = this.reorderList(
      result.source.index,
      result.destination.index
    );
    this.props.model.requestChange(newList);
  }

  render() {
    let itens = this.props.model && this.props.model.value;
    let { intl, resources } = this.props;

    return (
      <>
        <Panel header={intl.formatMessage({ id: 'label.itens' })}>
          <Row>
            <Col md={12}>
              {itens && itens.length > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>
                        <FormattedMessage id="descricao" />
                      </th>
                      <th style={{ width: 90 }}>
                        {intl.formatMessage(
                          { id: 'farol' },
                          { farol: resources.farol }
                        )}
                      </th>
                      <th style={{ width: 190 }}>
                        <FormattedMessage id="valor" />
                      </th>
                      <th style={{ width: 40 }}></th>
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable droppableId="classificacoesContainer">
                      {(provided) => (
                        <tbody ref={provided.innerRef}>
                          {itens.map((item, index) => (
                            <Draggable index={index} draggableId={`${index}`}>
                              {(provided) => (
                                <>
                                  <tr
                                    key={item + index + ''}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <td
                                      style={{ width: '5%' }}
                                      className="text-center pb-3"
                                      {...provided.dragHandleProps}
                                    >
                                      <MdiIcon size={22} icon="drag" />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ marginBottom: '-15px' }}
                                        model={{
                                          value: item.descricao,
                                          requestChange:
                                            this.handleValueChange.bind(
                                              this,
                                              index,
                                              'descricao'
                                            ),
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <FarolLookup
                                        model={{
                                          value: item.farol,
                                          requestChange:
                                            this.handleValueChange.bind(
                                              this,
                                              index,
                                              'farol'
                                            ),
                                        }}
                                        style={{ marginBottom: '0' }}
                                      ></FarolLookup>
                                    </td>
                                    <td>
                                      <InputNumber
                                        style={{ marginBottom: '-15px' }}
                                        model={{
                                          value: item.valor,
                                          requestChange:
                                            this.handleValueChange.bind(
                                              this,
                                              index,
                                              'valor'
                                            ),
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{ width: 40, cursor: 'pointer' }}
                                    >
                                      <MdiIcon
                                        icon="close-circle-outline"
                                        style={{ marginTop: 3 }}
                                        size={20}
                                        onClick={this.handleExcluir.bind(
                                          this,
                                          index
                                        )}
                                      />
                                    </td>
                                  </tr>
                                </>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              )}
            </Col>
          </Row>
        </Panel>

        {this.props.allowNewItem && (
          <div
            className="d-inline-flex align-items-center my-2"
            onClick={this.handleNovoItem}
          >
            <div
              className="d-flex justify-content-center align-items-center p-1 bg-primary rounded-lg text-white"
              style={{ width: 20, height: 20 }}
            >
              <MdiIcon className="cursor-pointer" icon="plus" size={14} />
            </div>
            <a className="ml-2 text-primary">
              {intl.formatMessage({ id: 'AdicionarRegistro' })}
            </a>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(TabelaValorTabela));
