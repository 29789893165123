import React from 'react';

const PolaridadeGrafico = ({ polaridade, item }) => {
  let componente = null;

  if (polaridade && item && item.polaridade && item.tipo && item.tipo.id != 2) {
    switch (item.polaridade.icone) {
      case 'arrow-up':
      case 'arrow-down':
        componente = (
          <svg width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d={item.polaridade.icone == 'arrow-up' ? 'M5 10l7-7m0 0l7 7m-7-7v18' : 'M19 14l-7 7m0 0l-7-7m7 7V3'}
            />
          </svg>
        );
        break;
      case 'equal':
        componente = (
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 500 500" stroke="currentColor">
            <g>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21.333,192h469.333c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333H21.333  C9.551,149.333,0,158.885,0,170.667C0,182.449,9.551,192,21.333,192z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M490.667,320H21.333C9.551,320,0,329.551,0,341.333c0,11.782,9.551,21.333,21.333,21.333h469.333  c11.782,0,21.333-9.551,21.333-21.333C512,329.551,502.449,320,490.667,320z"
              />
            </g>
          </svg>
        );
        break;
    }
  }

  return <div>{componente}</div>;
};

export default PolaridadeGrafico;
