import React, { useState, useEffect } from 'react';
import { autobind } from 'core-decorators';
import history from '../../history';
import http from 'src/services/httpService';
import Alert from '../message-box/alert';
import errorHandler from '../../utils/error-handler';
import { Row, Col } from 'reactstrap';
import LoadingContainer from '../loading-container';
import { Form } from '../../utils/form';
import Content from '../pages/content';
import Button from '../button';
import ContentManager from '../content-manager';
import { FormattedMessage, injectIntl } from 'react-intl';
import Confirm from '../message-box/confirm';
import MdiIcon from '../mdi-icon';
import MessageCancelEdit from './message-cancel-edit';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';

@autobind
class EditPage extends React.Component {
  static defaultProps = {
    showButtonSave: true,
    showToolbar: true,
    showDelete: false,
    beforeSave: null,
    showPrompt: true,
    automaticSave: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      id: this.props.id,
      modelInicial: JSON.stringify({}),
      alterou: false,
      forceRefresh: -1,
    };

    this.form = new Form({
      component: this,
      schema: this.props.schema,
    });
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate() {
    const forceRefresh = this.form.getModel('forceRefresh');

    if (
      forceRefresh &&
      forceRefresh.value &&
      this.state.forceRefresh !== forceRefresh.value
    ) {
      this.setState({ forceRefresh: forceRefresh.value }, () => this.refresh());
    }

    if (this.validateChange()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.automaticSave != this.props.automaticSave) {
      this.handleSave();
    }
    if (newProps.id != this.state.id) {
      this.refresh(newProps.id);
    }
  }

  refresh(idParams) {
    this.setState({
      isLoading: true,
    });

    const id = idParams ?? this.props.id;

    if (id == -1) {
      http
        .post(`${this.props.url}/Create`, {
          parameters: this.props.parameters,
        })
        .then((response) => {
          this.setState({
            isLoading: false,
            modelInicial: JSON.stringify(response.data),
            forceRefresh: -1,
            alterou: false,
          });
          this.form.setValue(null, response.data);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
          !this.props.isExport && this.handleClose();
        });
    } else {
      http
        .post(`${this.props.url}/Edit`, {
          id: id,
          parameters: this.props.parameters,
        })
        .then((response) => {
          this.setState({
            alterou: true,
            id: response.data.id,
          });
          this.form.setValue(null, response.data);
          this.setState({
            isLoading: false,
            modelInicial: JSON.stringify(response.data),
            alterou: false,
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
          !this.props.isExport && this.handleClose();
        });
    }
  }

  handleSave(fechar) {
    if (!this.form.isValid()) {
      this.form.showAllErrors();
      ContentManager.addContent(
        <Alert
          message={
            <FormattedMessage id="label.osCamposDestacadosEmVermelhoSaoObrigatoriosFavorPreenchelos" />
          }
        />
      );
      return;
    }

    this.setState({
      isLoading: true,
    });

    let save = true;

    //Se retornar erro, o save se torna a mensagem de exceção
    if (this.props.beforeSave) {
      save = this.props.beforeSave(this.form.getValue());
      if (save !== true)
        this.setState(
          {
            isLoading: false,
          },
          () => {
            let error = { response: { data: { errorMessage: '' } } };
            error.response.data.errorMessage = save;

            errorHandler(error);
          }
        );
    }

    if (save === true) {
      http
        .post(`${this.props.url}/Save`, {
          model: this.form.getValue(),
          parameters: this.props.parameters,
        })
        .then((response) => {
          this.form.setValue(null, response.data.model);

          this.setState({
            isLoading: false,
            id: response.data.model.id,
            modelInicial: JSON.stringify(response.data.model),
            alterou: false,
          });

          this.props.handleSaved && this.props.handleSaved(response.data.model);

          if (fechar) {
            this.handleClose();
          } else {
            ContentManager.addContent(
              <Alert
                title={<FormattedMessage id="sucesso" />}
                message={
                  <FormattedMessage id="label.registroSalvoComSucesso" />
                }
              />
            );
          }
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  confirmClose() {
    if (this.validateChange()) {
      ContentManager.addContent(
        <MessageCancelEdit
          handleNaoSalvar={this.handleClose}
          handleSave={this.handleSave}
        />
      );
    } else {
      this.handleClose();
    }
  }

  validateChange() {
    if (!this.state.alterou) {
      const modelAtual = JSON.stringify(this.form.getValue());
      const alteracao = this.state.modelInicial != modelAtual;

      if (alteracao && this.state.modelInicial != null && modelAtual != '{}') {
        this.setState({ alterou: alteracao });
      }
      return alteracao;
    } else {
      return true;
    }
  }

  handleClose() {
    if (this.props.handleCloseCustom) {
      this.props.handleCloseCustom();
    } else {
      history.goBack();
    }
  }

  handleDeleteClick(itemId) {
    let item = { id: itemId };
    if (this.props.onDeleting) {
      this.props.onDeleting(item, this.props.urlList);
    } else {
      ContentManager.addContent(
        <Confirm
          message={
            <FormattedMessage id="label.desejaMesmoExcluirEsseRegistro" />
          }
          handleConfirm={this.handleDeleteClickConfirm.bind(this, item)}
        />
      );
    }
  }

  render() {
    let {
      formComponent: FormComponent,
      title,
      titleField,
      showButtonSave,
      showToolbar,
      showDelete,
      showPrompt,
      id,
      HeaderCustomComponent,
      ...other
    } = this.props;

    let allowDelete = this.form
      .getModel('permissions')
      .getModel('allowDelete').value;
    let allowSave = this.form
      .getModel('permissions')
      .getModel('allowEdit').value || this.form.getModel('permissions').getModel('allowEditFallback').value;

    let editActions = [
      <div className="pull-right" key="toolbar-edit">
        {this.state.id != -1 && this.props.actions}
        {showDelete && allowDelete && id > 0 && (
          <Button
            onClick={this.handleDeleteClick.bind(this, this.props.id)}
            className="ml-2"
            color="danger"
          >
            <MdiIcon icon="delete-outline" />
          </Button>
        )}
        {showButtonSave && allowSave && (
          <Button className="ml-2" onClick={this.handleSave.bind(this, false)}>
            <MdiIcon icon="content-save-outline" />
          </Button>
        )}
        <Button onClick={this.confirmClose} className="ml-2" color="secondary">
          <MdiIcon icon="close" />
        </Button>
      </div>,
    ];

    return (
      <Content
        titulo={`${
          titleField
            ? this.form.getModel().getModel(titleField).value == undefined ||
              this.form.getModel().getModel(titleField).value == null
              ? title
              : this.form.getModel().getModel(titleField).value
            : title
            ? title
            : ''
        }`}
      >
        {showPrompt && (
          <Prompt
            when={this.validateChange()}
            message={this.props.intl.formatMessage({
              id: 'existeAlteracoesNaoSalvas',
            })}
          />
        )}
        {!this.props.isExport && showToolbar && (
          <Row>
            <Col md={12}>
              <div>
                {editActions.map((a, index) =>
                  React.cloneElement(a, {
                    key: index + 'a',
                  })
                )}
              </div>
            </Col>
          </Row>
        )}
        {this.form.getModel().value && HeaderCustomComponent && (
          <Row>
            <Col md={12}>
              {React.cloneElement(HeaderCustomComponent, {
                model: this.form.getModel(),
              })}
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            <div {...other}>
              <LoadingContainer isLoading={this.state.isLoading}>
                {typeof FormComponent ===
                'function' /* React.cloneElement só funciona quando é passado o React.Component */ ? (
                  <FormComponent
                    {...other}
                    id={id}
                    model={this.form.getModel()}
                  />
                ) : (
                  React.cloneElement(FormComponent, {
                    model: this.form.getModel(),
                  })
                )}
              </LoadingContainer>
            </div>
          </Col>
        </Row>
        {!this.props.isExport && showToolbar && (
          <Row style={{ marginTop: 15 }}>
            <Col md={12}>
              <div>
                {editActions.map((a, index) =>
                  React.cloneElement(a, {
                    key: index + 'b',
                  })
                )}
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(EditPage));
