import React from 'react';
import { autobind } from 'core-decorators';
import EditDialog from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import DatePicker from 'src/componentes/date-picker';
import PontoFaixaResultadoLookup from 'src/lookups/ponto-faixa-resultado';
import Input from 'src/componentes/input';

let schema = Schema.object({
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  pontoFaixaResultado: Schema.string()
    .label(<FormattedMessage id="label.pontosFaixaResultado" />)
    .required(),
  dataInicio: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
});

@autobind
class FormEdit extends React.Component {
  handleChange(e) {
    this.props.model.requestChange &&
      this.props.model.requestChange(e.target.checked);
  }

  render() {
    let { model, podeEditar, intl, disabled } = this.props;
    return (
      <div>
        <Row>
          <Col md={6}>
            <Input
              model={model.getModel('idIntegracao')}
              required
              disabled={disabled || !podeEditar}
            />
          </Col>
          <Col md={6}>
            <PontoFaixaResultadoLookup
              model={{
                label: intl.formatMessage({
                  id: 'label.pontosFaixaResultado',
                }),
                value: model.getModel('pontoFaixaResultado').value,
                requestChange: model.getModel('pontoFaixaResultado')
                  .requestChange,
                errors: model.getModel('pontoFaixaResultado').errors,
              }}
              disabled={disabled || !podeEditar}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <DatePicker
              month
              model={model.getModel('dataInicio')}
              disabled={disabled || !podeEditar}
              required
            />
          </Col>
          <Col md={3}>
            <DatePicker
              month
              model={model.getModel('dataTermino')}
              disabled={disabled || !podeEditar}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class PontoFaixaResultadoEdit extends React.Component {
  render() {
    let { resources } = this.props;

    return (
      <EditDialog
        url="/ItemPontoFaixaResultado"
        formComponent={FormEdit}
        schema={schema}
        width="60%"
        maxHeight="90%"
        resources={resources}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(PontoFaixaResultadoEdit));
