import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import SvgIcon from '../componentes/svg-icon';

@autobind
class FarolAcaoLookup extends React.Component {
  static defaultProps = {
    showDescription: true,
  };

  handleNome(item) {
    return (
      item &&
      item.data &&
      item.data && (
        <span>
          <SvgIcon
            icon={item.data.icone}
            size={18}
            color={item.data.cor}
            colorHover={item.data.cor}
            className="mr-2"
          ></SvgIcon>
          {this.props.showDescription && <span>{item.data.descricao}</span>}
        </span>
      )
    );
  }
  render() {
    return (
      <Lookup
        labelKey="descricao"
        valueKey="id"
        url="/FarolAcaoLookup"
        clearable={false}
        valueComponent={this.handleNome}
        {...this.props}
      />
    );
  }
}

export default FarolAcaoLookup;
