import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import TemaEdit from '../paginas/tema-estrategico/edit';

@autobind
class TemaEstrategico extends React.Component {
  render() {
    let { multi = true } = this.props;

    return <Lookup labelKey="descricao" valueKey="id" multi={multi} url="/TemaEstrategicoLookup" {...this.props} />;
  }
}

export default TemaEstrategico;
