import React, { useEffect, useRef, useState } from 'react';
import http from 'src/services/httpService';
import Button from 'src/componentes/button';
import Dialog from 'src/componentes/dialog';
import { FormattedMessage, useIntl } from 'react-intl';
import LoadingContainer from 'src/componentes/loading-container';
import Checkbox from 'src/componentes/checkbox';
import errorHandler from 'src/utils/error-handler';
import { useSnackbar } from 'notistack';
import {
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { AlertTriangle } from 'react-feather';
import useAuth from 'src/hooks/useAuth';

export default function ValidarRestricao({
  url,
  urlExclusao,
  item,
  validarAlteracao,
  title = '',
  description = '',
  subDescription = '',
  atualizar = () => {},
  onClose = () => {},
  afterRemove = () => {},
  urlList = '',
  handleClose = () => {},
  exibirEstouCiente = true,
  labelCancelar = 'labelManterVinculo',
  labelAlterar = 'labelExcluirVinculo',
  width = 700,
  minHeight = 500,
  customHandleSalvarClick = null, 
  customHandleCloseValidarRestricaoClick = null, 
  ...rest
}) {
  const { terms: resources } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState([]);
  const [estouCiente, setEstouCiente] = useState(false);
  const intl = useIntl();
  const modalRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleSalvarClick = async (registro) => {
    if (customHandleSalvarClick) {
      customHandleSalvarClick();
      
      if (modalRef.current) 
        modalRef.current.close();
  
      handleClose && handleClose();      
      
      return;
    }

    if (urlExclusao) {
      setIsLoading(true);
      await http
        .post(urlExclusao, {
          id: registro.id,
        })
        .then(() => {
          setIsLoading(false);
          enqueueSnackbar(
            intl.formatMessage({ id: 'label.registroExcluidoComSucesso' }),
            {
              variant: 'success',
            }
          );
          afterRemove && afterRemove(registro);
          onClose && onClose(true);
          handleCloseValidarRestricao(true);
        })
        .catch((error) => {
          errorHandler(error);
          setIsLoading(false);
        });
    }
    setIsLoading(false);
    atualizar && atualizar(handleCloseValidarRestricao());
    if (urlList) {
      window.location.href = urlList;
    }
  };

  const handleCloseValidarRestricao = () => {
    if (customHandleCloseValidarRestricaoClick) {
      customHandleCloseValidarRestricaoClick();
    }

    if (modalRef.current) 
      modalRef.current.close();

    handleClose && handleClose();
  };

  const actions = [
    <Button
      key={1}
      onClick={() => handleCloseValidarRestricao()}
      color="secondary"
    >
      <FormattedMessage id={labelCancelar} />
    </Button>,
    <Button
      key={2}
      disabled={exibirEstouCiente && !estouCiente}
      color="danger"
      className="ml-2"
      onClick={() => handleSalvarClick(item)}
    >
      <FormattedMessage id={labelAlterar} />
    </Button>,
  ];

  useEffect(() => {
    if (url) {
      setIsLoading(true);

      const parametros = { id: item.id };
      http
        .post(url, parametros)
        .then((response) => {
          setModel(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          errorHandler(error);
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  }, [url]);

  return (
    <Dialog
      ref={modalRef}
      actions={actions}
      width={width}
      minHeight={minHeight}
      padContent
      onRequestClose={handleCloseValidarRestricao}
      mouseDownClose={false}
      {...rest}
    >
      <LoadingContainer isLoading={isLoading}>
        <div>
          <Row>
            <Col className="d-flex justify-content-center" md={2}>
              <div
                style={{
                  backgroundColor: 'rgba(255, 0, 0, 0.5)', // Vermelho com 50% de transparência
                  width: '56px', // Largura de 100px
                  height: '56px', // Altura igual à largura para formar o círculo
                  borderRadius: '50%', // Transforma em círculo
                  backgroundColor: 'red',
                  background: '#FFEBEB',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AlertTriangle size={30} color="red" />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  fontFamily: "'Nunito', sans-serif",
                  fontSize: '20px',
                  color: '#A90F0F',
                }}
              >
                <span>
                  {intl.formatMessage(
                    { id: title },
                    {
                      pontodecontrole: resources.pontodecontrole,
                      risco: resources.risco,
                      avaliacoes: resources.avaliacoes,
                    }
                  )}
                </span>
              </div>
              {
                description && (
                <div className="pt-2">
                  <span
                    style={{
                      fontFamily: "'Nunito', sans-serif",
                      fontSize: '16px',
                    }}
                  >
                    {intl.formatMessage(
                      { id: description },
                      {
                        pontodecontrole: resources.pontodecontrole,
                        risco: resources.risco,
                        avaliacoes: resources.avaliacoes,
                      }
                    )}
                  </span>
                </div>
              )}
              { 
                subDescription && (
                <div className="pt-2">
                  <span
                    style={{
                      fontFamily: "'Nunito', sans-serif",
                      fontSize: '16px',
                    }}
                  >
                    {intl.formatMessage({ id: subDescription })}
                  </span>
                </div>
              )}
              <div className="pt-3">
                <Row>
                  <Col>
                    {model?.restricoes?.map((restricao, index) => (
                      <Card key={index} style={{ marginBottom: 5 }}>
                        <CardHeader>
                          <div
                            style={{
                              color: '#808080',
                              fontWeight: '700',
                              fontFamily: "'Nunito', sans-serif",
                              fontSize: '16px',
                            }}
                          >
                            {restricao.restricaoDescricao}
                          </div>
                        </CardHeader>
                        <CardBody className='pt-0'>
                          <ListGroup key={index} flush>
                            {restricao.itensDescricao &&
                              restricao.itensDescricao.map(
                                (item, itemIndex) => (
                                  <ListGroupItem
                                    style={{ cursor: 'default' }}
                                    key={itemIndex}
                                  >
                                    {item}
                                  </ListGroupItem>
                                )
                              )}
                          </ListGroup>
                        </CardBody>
                      </Card>
                    ))}
                  </Col>
                </Row>
                {exibirEstouCiente && (
                  <div className="pt-3 d-flex inline">
                    <Checkbox
                      style={{ paddingBottom: 50 }}
                      model={{
                        value: estouCiente,
                        requestChange: setEstouCiente,
                      }}
                      color="primary"
                    />
                    <span
                      className="pt-3"
                      style={{
                        fontFamily: "'Nunito', sans-serif",
                        fontSize: '16px',
                      }}
                    >
                      {intl.formatMessage({
                        id: 'labelEstouCienteDasInformacoesAcima',
                      })}
                    </span>
                  </div>  
                )}
              </div>
            </Col>
          </Row>
        </div>
      </LoadingContainer>
    </Dialog>
  );
}
