import { autobind } from 'core-decorators';
import React from 'react';
import List from '../../componentes/list';
import ListColumn from '../../componentes/list/list-column';
import GrupoEdit from './edit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import Avatar from 'src/componentes/avatar';
import AvatarGroup from 'src/componentes/AvatarGroup';

@autobind
class GrupoEmail extends React.Component {
  renderAvatarGroup(item) {
    return (
      <>
        {item?.colaboradores && (
          <AvatarGroup max={5} avatarSize={35}>
            {item?.colaboradores &&
              item?.colaboradores.map((colab) => (
                <Avatar
                  showTooltip
                  id={`avatar-${colab.id}`}
                  foto={colab.fotoArquivo}
                  label={colab.nome}
                />
              ))}
          </AvatarGroup>
        )}
      </>
    );
  }

  render() {
    let { resources, intl } = this.props;
    return (
      <List
        url="/GrupoEmail"
        editComponent={GrupoEdit}
        tag={1004}
        showSearch={true}
        sort="descricao"
        validateConstraints
        {...this.props}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'idIntegracao' })}
          valueField="idIntegracao"
          sortField="idIntegracao"
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'descricao' })}
          valueField="descricao"
          required
          sortField="descricao"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.colaboradores)}
          valueField="colaboradores"
          valueFunction={this.renderAvatarGroup}
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'dataInicio' })}
          valueField="dataInicio"
          sortField="dataInicio"
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'dataTermino' })}
          valueField="dataTermino"
          sortField="dataTermino"
        />
      </List>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(GrupoEmail));
