import React from 'react';
import { Col, Row } from 'reactstrap';

import { capitalizeFirstLetter } from 'src/utils/string';
import ColaboradorLookup from 'src/lookups/colaborador';
import StatusModeloAvaliacaoLookup from 'src/lookups/StatusModeloAvaliacao';
import Input from 'src/componentes/input';
import useAuth from 'src/hooks/useAuth';

const ModeloAvaliacaoSearch = ({ model }) => {
  const { terms: resources } = useAuth();

  return (
    <Row>
      <Col md={6}>
        <Input model={model.getModel('nome')} />
      </Col>
      <Col md={6}>
        <Input model={model.getModel('idIntegracao')} />
      </Col>
      <Col md={6}>
        <ColaboradorLookup
          clearable
          model={{
            label: capitalizeFirstLetter(resources.responsavel),
            value: model.getModel('responsavel').value,
            requestChange: model.getModel('responsavel').requestChange,
          }}
        />
      </Col>
      <Col md={6}>
        <StatusModeloAvaliacaoLookup clearable model={model.getModel('status')} />
      </Col>
    </Row>
  );
};

export default ModeloAvaliacaoSearch;
