import React, { useState } from 'react';

import MdiIcon from 'src/componentes/mdi-icon';

const HoverSelect = ({ icon, iconSize, iconColor, onClick = () => {}, renderInfo, itens = [], value }) => {
  const [hovering, setHovering] = useState(null);

  const renderElement = (item, index) => {
    const showHover = hovering ? hovering >= index : value ? value.id >= item.id : false;
    const color = showHover ? iconColor : '#CCC';

    return (
      <div onMouseEnter={() => setHovering(index)} onMouseLeave={() => setHovering(null)} className="d-flex flex-column align-items-center">
        <MdiIcon className="cursor-pointer mr-1" size={iconSize} onClick={() => onClick(item)} icon={icon} colorHover={color} color={color} />
        {renderInfo && <span className="text-muted font-weight-bold" style={{ fontSize: 12, color: '#d0d0d0' }}>{`(${renderInfo(item)})`}</span>}
      </div>
    );
  };

  return <div className="d-flex flex-wrap align-items-center">{itens?.map((item, index) => renderElement(item, index + 1))}</div>;
};

export default HoverSelect;
