import { useIntl } from 'react-intl';
import { useState } from 'react';

import PanelCollapse from 'src/componentes/panel-collapse';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import { TIPO_CESTA_INDICADOR, COLUNAS, ADDONS } from 'src/utils/constants';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter, lowerCaseFirstLetter } from 'src/utils/string';
import ContentManager from 'src/componentes/content-manager';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';

import FormEdit from './edit';
import Clone from './Clone';

const Container = ({ model, disabled }) => {
  const intl = useIntl();
  const { terms: resources, addons } = useAuth();

  const [refreshList, setRefreshList] = useState(1);

  const handleClone = async (id, form = null) => {
    const idIntegracao = form?.idIntegracao;
    const dataInicio = form?.dataInicio;
    const dataTermino = form?.dataTermino;

    try {
      await http.post(`/CestaIndicador/Clonar`, {
        id,
        idIntegracao,
        dataInicio,
        dataTermino,
      });

      setRefreshList((prev) => prev + 1);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handlePreClone = (item) => {
    const handleSave = async (form) => {
      await handleClone(item.id, form);
    };

    ContentManager.addContent(<Clone item={item} save={handleSave} />);
  };

  const renderList = (tipoCestaIndicadorId) => {
    const isBonus = tipoCestaIndicadorId === TIPO_CESTA_INDICADOR.BONUS;
    const childItems = disabled
      ? []
      : [
          {
            label: intl.formatMessage({ id: 'clonar' }),
            icon: 'content-copy',
            onClick: handlePreClone,
            visible: () => true,
          },
        ];

    return (
      <List
        url="/CestaIndicador"
        createChildItems={childItems}
        editComponent={FormEdit}
        parameters={{
          funcaoId: model.getModel('id').value,
          alterado: false,
          tipoCestaIndicadorId,
        }}
        showSearch
        showExport={false}
        tag={
          isBonus
            ? COLUNAS.CESTA_INDICADOR_BONUS
            : COLUNAS.CESTA_INDICADOR_COMISSAO
        }
        saved={refreshList}
        disabled={disabled}
        sort="DataTermino == null ? DataInicio : DataTermino, DataInicio"
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'idIntegracao' })}
          valueField="idIntegracao"
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'label.dataInicio' })}
          valueField="dataInicio"
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'label.dataTermino' })}
          valueField="dataTermino"
          default
        />
      </List>
    );
  };

  const hasCommissionAddon =
    addons?.find((x) => x.id === ADDONS.COMISSOES && x.ativo) != null;

  return (
    <>
      <PanelCollapse
        open={!hasCommissionAddon}
        header={capitalizeFirstLetter(
          intl.formatMessage({ id: 'calculoParaBonificacao' })
        )}
        headerStyle="text-primary font-weight-bolder"
        subtitle={capitalizeFirstLetter(
          intl.formatMessage(
            {
              id: 'calculoParaBonificacaoInformacao',
            },
            { colaborador: resources.colaborador, area: resources.area }
          )
        )}
      >
        {renderList(TIPO_CESTA_INDICADOR.BONUS)}
      </PanelCollapse>
      {hasCommissionAddon && (
        <PanelCollapse
          open={false}
          header={capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'calculoParaComissao' },
              { comissao: lowerCaseFirstLetter(resources.comissao) }
            )
          )}
          headerStyle="text-primary font-weight-bolder"
          subtitle={capitalizeFirstLetter(
            intl.formatMessage({ id: 'calculoParaComissaoInformacao' })
          )}
        >
          {renderList(TIPO_CESTA_INDICADOR.COMISSAO)}
        </PanelCollapse>
      )}
    </>
  );
};

export default Container;
