import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Schema } from '../../../utils/form';

export default Schema.object({
  id: Schema.number().label(),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="codigo" />)
    .required(),
  tipo: Schema.string().label(<FormattedMessage id="tipo" />),
  gestor: Schema.string().label(<FormattedMessage id="gestor" />),
  gestores: Schema.string().label(<FormattedMessage id="gestores" />),
  dono: Schema.string().label(<FormattedMessage id="dono" />),
  pai: Schema.string().label(<FormattedMessage id="pai" />),
  grupoDiaEnvio: Schema.string().label(<FormattedMessage id="grupoDiaEnvio" />),
  classificacao: Schema.string().label(<FormattedMessage id="label.classificacao" />),
});
