import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

import Table from 'src/componentes/table';
import InputNumber from 'src/componentes/input-number';
import SvgIcon from 'src/componentes/svg-icon';
import InputGroup from 'src/componentes/input-number-group';
import MdiIcon from 'src/componentes/mdi-icon';
import LoadingContainer from 'src/componentes/loading-container';
import { updateGanhosPorAcao } from 'src/actions/ganhos-por-acao';
import PeriodoData from 'src/paginas/data/periodo-data';
import dataPeriodo from 'src/utils/dataPeriodo';
import { capitalizeFirstLetter } from 'src/utils/string';
import css from 'src/utils/css';
import http from 'src/services/httpService';

let classes = css`
  .th {
    text-align: center;
    min-width: 80px;
  }

  .td {
    background: white;
    text-align: center !important;
    vertical-align: middle !important;
    height: 48px;
  }
`;

const filtroValoresAcaoId = 45;

@autobind
class Valores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valores: [],
      meta: null,
      isLoading: false,
      filtroDefault: {
        tipoFiltroData: { id: 4 },
        dataInicio: dataPeriodo.inicio,
        dataTermino: dataPeriodo.termino,
      },
      possuiFilhos: false,
      permissaoEditarMeta: false,
      permissaoEditarRealizado: false,
    };
  }

  componentDidMount() {
    this.openLastFilter();
  }

  async openLastFilter() {
    await http
      .post(`/FiltroColaborador/${filtroValoresAcaoId}/AbrirUltimoFiltro`, {})
      .then((response) => {
        if (response.data) {
          this.setState({
            filtroDefault: {
              id: response.data.id,
              ...response.data.valor,
            },
          });
        }
        this.refresh();
      });
  }

  handleSaveFilter() {
    http.post(`/FiltroColaborador/${filtroValoresAcaoId}/SalvarFiltro`, {
      filtroId: filtroValoresAcaoId,
      valor: {
        ...this.state.filtroDefault,
      },
    });
  }

  async refresh() {
    const { acaoId } = this.props;
    const { filtroDefault } = this.state;

    this.setState({
      isLoading: true,
    });

    await http
      .post(`/AcaoValor/ObterValores`, {
        filtro: filtroDefault,
        acaoId: acaoId,
      })
      .then((response) => {
        if (response.data != null) {
          this.setState({
            isLoading: false,
            ...response.data,
          });
        }
      });
    this.handleValoresGanhosPorAcaoUpdate(this.state.valores);
  }

  handleChangeInput(_, index, field, valor) {
    let valores = this.state.valores;
    if (valores) {
      if (field === 'meta') valores[index].editadoMeta = true;
      else if (field === 'realizado') valores[index].editadoRealizado = true;

      valores[index][field] = valor;
      valores[index].editado = true;

      this.setState({ valores: valores });
    }
    this.handleValoresGanhosPorAcaoUpdate(valores);
  }

  async handleReplicarValores(field) {
    let { valores, permissaoEditarMeta, permissaoEditarRealizado } = this.state;
    if (valores && valores.length > 0 && !this.state.possuiFilhos) {
      valores.map((v) => {
        if (field === 'meta' && permissaoEditarMeta) {
          v[field] = this.state[field];
          v.editado = true;
          v.editadoMeta = true;
        } else if (field === 'realizado' && permissaoEditarRealizado) {
          v[field] = this.state[field];
          v.editado = true;
          v.editadoRealizado = true;
        }
      });
    }
    await this.setState({
      [field]: null,
      valores: valores,
    });
    this.handleValoresGanhosPorAcaoUpdate(valores);
  }

  async handleChangeInputReplica(field, value) {
    await this.setState({
      ...this.state,
      [field]: value,
    });
  }

  async handleReplicaInputHorizontalGeral(value) {
    if (this.state.permissaoEditarRealizado) {
      let valores = this.state.valores;

      if (valores && valores.length > 0 && !this.state.possuiFilhos) {
        valores.map((v) => {
          v.realizado = v.meta;
          v.editado = true;
          v.editadoRealizado = true;
        });
      }

      await this.setState({
        valores: valores,
      });
      this.handleValoresGanhosPorAcaoUpdate(valores);
    }
  }

  async handleReplicarValoresHorizontal(value) {
    if (this.state.permissaoEditarRealizado) {
      let valores = this.state.valores;

      if (valores && valores.length > 0 && !this.state.possuiFilhos) {
        valores.map((v) => {
          if (v.periodo === value.periodo) {
            v.realizado = v.meta;
            v.editado = true;
            v.editadoRealizado = true;
          }
        });
      }

      await this.setState({
        valores: valores,
      });
      this.handleValoresGanhosPorAcaoUpdate(valores);
    }
  }

  async handleChangeFiltro(field, value) {
    await this.setState({
      filtroDefault: {
        ...this.state.filtroDefault,
        [field]: value,
      },
    });
    this.handleSaveFilter();
    this.refresh();
  }

  handleValoresGanhosPorAcaoUpdate(valores) {
    if (valores) {
      this.props.updateGanhosPorAcao(valores);
    }
  }

  render() {
    const {
      valores,
      meta,
      realizado,
      filtroDefault,
      possuiFilhos,
      permissaoEditarMeta,
      permissaoEditarRealizado,
    } = this.state;
    const { tipoFiltroData, dataInicio, dataTermino } = filtroDefault;
    const { resources, intl } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <PeriodoData
              tipoFiltroData={tipoFiltroData}
              dataInicio={dataInicio}
              dataTermino={dataTermino}
              onChange={this.handleChangeFiltro}
            />
          </Col>
        </Row>
        <Table size="sm" hover>
          <LoadingContainer isLoading={this.state.isLoading}>
            <thead>
              <tr>
                <th className={classes.th}>
                  {<FormattedMessage id="periodo" />}
                </th>
                <th className={classes.th}>
                  <div style={{ marginBottom: -15 }}>
                    <span>{capitalizeFirstLetter(resources.meta)}</span>
                    <InputGroup
                      bsSize="sm"
                      style={{ textAlign: 'center' }}
                      addon={
                        <MdiIcon
                          style={{ cursor: 'pointer' }}
                          icon="chevron-double-down"
                          onClick={this.handleReplicarValores.bind(
                            this,
                            'meta'
                          )}
                        ></MdiIcon>
                      }
                      disabled={possuiFilhos || !permissaoEditarMeta}
                      model={{
                        value: meta,
                        requestChange: this.handleChangeInputReplica.bind(
                          this,
                          'meta'
                        ),
                      }}
                    ></InputGroup>
                  </div>
                </th>
                <th className={classes.th}>
                  <UncontrolledTooltip placement="top" target="replicaMeta">
                    <FormattedMessage
                      id={intl.formatMessage(
                        { id: 'replicarMetaRealizado' },
                        { meta: resources.meta }
                      )}
                    />
                  </UncontrolledTooltip>
                  <MdiIcon
                    id="replicaMeta"
                    style={{
                      cursor: 'pointer',
                      marginBottom: -5,
                      marginLeft: 25,
                      display: 'flex',
                    }}
                    icon="chevron-double-right"
                    onClick={this.handleReplicaInputHorizontalGeral.bind(
                      this,
                      meta,
                      'meta'
                    )}
                  />
                  {capitalizeFirstLetter(resources.farol)}
                </th>
                <th className={classes.th}>
                  <div style={{ marginBottom: -15 }}>
                    <span>{<FormattedMessage id="realizado" />}</span>
                    <InputGroup
                      bsSize="sm"
                      style={{ textAlign: 'center' }}
                      addon={
                        <MdiIcon
                          style={{ cursor: 'pointer' }}
                          icon="chevron-double-down"
                          onClick={this.handleReplicarValores.bind(
                            this,
                            'realizado'
                          )}
                        ></MdiIcon>
                      }
                      disabled={possuiFilhos || !permissaoEditarRealizado}
                      model={{
                        value: realizado,
                        requestChange: this.handleChangeInputReplica.bind(
                          this,
                          'realizado'
                        ),
                      }}
                    ></InputGroup>
                  </div>
                </th>
                <th className={classes.th}>
                  {<FormattedMessage id="label.desvio" />}
                </th>
                <th className={classes.th}>
                  {capitalizeFirstLetter(resources.metaacumulado)}
                </th>
                <th className={classes.th}>
                  {capitalizeFirstLetter(resources.farolAcumulado)}
                </th>
                <th className={classes.th}>
                  {<FormattedMessage id="realizadoAcumulado" />}
                </th>
                <th className={classes.th}>
                  {<FormattedMessage id="label.desvioAcum" />}
                </th>
              </tr>
            </thead>
            <tbody>
              {valores.map((valor, index) => (
                <tr key={index}>
                  <td className={classes.td}>
                    <a tabindex={index}>{valor.descricaoPeriodo}</a>
                  </td>
                  <td className={classes.td}>
                    <div style={{ marginBottom: -15 }}>
                      <InputGroup
                        precision={2}
                        bsSize="sm"
                        style={{ textAlign: 'center' }}
                        addon={
                          <MdiIcon
                            id={`a${index}`}
                            style={{ cursor: 'pointer' }}
                            icon="chevron-right"
                            onClick={this.handleReplicarValoresHorizontal.bind(
                              this,
                              valor
                            )}
                          ></MdiIcon>
                        }
                        disabled={possuiFilhos || !permissaoEditarMeta}
                        model={{
                          value: valor.meta,
                          requestChange: this.handleChangeInput.bind(
                            this,
                            valor,
                            index,
                            'meta'
                          ),
                        }}
                      />
                      {possuiFilhos && (
                        <UncontrolledTooltip
                          key={index}
                          placement="top"
                          target={`a${index}`}
                        >
                          <FormattedMessage
                            id={intl.formatMessage(
                              { id: 'replicarMetaRealizado' },
                              { meta: resources.meta }
                            )}
                          />
                        </UncontrolledTooltip>
                      )}
                    </div>
                  </td>
                  <td className={classes.td}>
                    {valor.farol && (
                      <SvgIcon
                        style={{ cursor: 'pointer', marginBottom: -8 }}
                        title={valor.farol.legenda}
                        icon={valor.farol.icone}
                        color={valor.farol.cor}
                        colorHover={valor.farol.cor}
                        size={20}
                      ></SvgIcon>
                    )}
                  </td>
                  <td className={classes.td}>
                    <InputNumber
                      precision={2}
                      bsSize="sm"
                      style={{ marginBottom: -15, textAlign: 'center' }}
                      disabled={possuiFilhos || !permissaoEditarRealizado}
                      model={{
                        value: valor.realizado,
                        requestChange: this.handleChangeInput.bind(
                          this,
                          valor,
                          index,
                          'realizado'
                        ),
                      }}
                    />
                  </td>
                  <td className={classes.td}>
                    <span>
                      <a>{valor.desvioFormatado}</a>
                    </span>
                  </td>
                  <td className={classes.td}>
                    <span>
                      <a>{valor.metaAcumulado}</a>
                    </span>
                  </td>
                  <td className={classes.td}>
                    {valor.farolAcumulado && (
                      <SvgIcon
                        style={{
                          cursor: 'pointer',
                          marginBottom: !valor.podeEditarMeta ? -8 : -8,
                        }}
                        title={valor.farolAcumulado.legenda}
                        icon={valor.farolAcumulado.icone}
                        color={valor.farolAcumulado.cor}
                        colorHover={valor.farolAcumulado.cor}
                        size={20}
                      ></SvgIcon>
                    )}
                  </td>
                  <td className={classes.td}>
                    <span>
                      <a>{valor.realizadoAcumulado}</a>
                    </span>
                  </td>
                  <td className={classes.td}>
                    <span>
                      <a>{valor.desvioAcumulado}</a>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </LoadingContainer>
        </Table>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    ganhosPorAcao: state.ganhosPorAcao,
  };
}
export default injectIntl(
  connect(mapStateToProps, { updateGanhosPorAcao })(Valores)
);
