import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';

import DatePicker from 'src/componentes/date-picker';

function ObjetivoSearch({ model, intl }) {
  return (
    <div>
      <Row>
        <Col md={3}>
          {intl.formatMessage({ id: 'dataInicio' })}
          <DatePicker
            autoFormatDate={true}
            model={model.getModel('dataInicio')}
          />
        </Col>
        <Col md={3}>
          {intl.formatMessage({ id: 'dataTermino' })}
          <DatePicker
            autoFormatDate={true}
            model={model.getModel('dataTermino')}
          />
        </Col>
      </Row>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ObjetivoSearch));
