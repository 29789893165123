import { Suspense, lazy, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import LoadingScreen from 'src/componentes/LoadingScreen';
import formatarData from 'src/utils/formatarData';
const Grafico = lazy(() => import('src/componentes/chart'));

const GraficoScorePeriodo = ({ dadosGrafico, avaliacoes, grausDaMatriz, intl, resources, user, maiorScoreDaMatriz, height, filtro, widget, ...props }) => {
  const [dados, setDados] = useState();

  const configWidget = () => {
    if (!widget) return {};

    return {
      showValues: filtro.ocultarRotulo ? '0' : '1',
      showYAxisValues: filtro.exibirValoresEixoY,
      YAxisValueFontSize: filtro.eixoYFontSizeGrafico,
      showLabels: filtro.exibirValoresEixoX,
      legendItemFontSize: filtro.legendaFontSizeGrafico,
      valueFontColor: filtro.rotuloCor,
      valueFont: filtro.rotuloFontFamily?.Descricao,
      valueFontSize: filtro.rotuloFontSize ?? 10,
      valueFontBold: filtro.rotuloNegrito ? '1' : '0',
      rotateValues: filtro.rotuloVertical ? '1' : '0',
    };
  };

  const chart = {
    ...configWidget(),
    decimalSeparator: ',',
    thousandSeparator: '.',
    formatNumberScale: '0',
    pyAxisName: '%',
    bgColor: '#ffffff',
    usePlotGradientColor: '0',
    canvasBgColor: '#ffffff',
    showBorder: '0',
    use3DLighting: '0',
    showShadow: '0',
    showLabels: '1',
    showLegend: '0',
    legendShadow: '0',
    legendBorderAlpha: '0',
    showTooltip: '1',
    captionFontSize: '14',
    subcaptionFontSize: '14',
    subcaptionFontBold: '0',
    divlineThickness: '0',
    divlineAlpha: '100',
    divlineColor: '#999999',
    divLineDashed: '0',
    divLineDashLen: '0',
    paletteColors: '#5598c3,#2785c3',
    baseFontColor: '#333333',
    canvasBorderAlpha: '1',
    showplotborder: '0',
    valueFontColor: '#000000',
    placeValuesInside: '1',
    showXAxisLine: '1',
    xAxisLineThickness: '1',
    xAxisLineColor: '#999999',
    showAlternateHGridColor: '0',
    legendBgAlpha: '0',
    legendItemFontSize: '10',
    legendItemFontColor: '#666666',
    allorDrag: '1',
    showYAxisValues: '1',
    labelDisplay: 'auto',
    rotateLabels: '1',
    slantLabel: '1',
  };

  useEffect(() => {
    if (avaliacoes) {
      setDados({ avaliacoes: avaliacoes, grausDaMatriz: grausDaMatriz, maiorScoreDaMatriz: maiorScoreDaMatriz });
    } else {
      setDados(dadosGrafico);
    }
  }, [dadosGrafico, avaliacoes, grausDaMatriz, maiorScoreDaMatriz]);

  const showGrafico = () => {
    return dados && dados.grausDaMatriz && Array.isArray(dados.grausDaMatriz) && dados.avaliacoes && Array.isArray(dados.avaliacoes);
  };

  const config = showGrafico() && {
    type: 'line',
    renderAt: 'chart-container',
    width: '100%',
    height: height ? height : '300',
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption: intl.formatMessage({ id: 'scoreXtempo' }),
        ...chart,
        yAxisMaxValue: dados.maiorScoreDaMatriz,
      },
      data:
        dados.avaliacoes &&
        dados.avaliacoes.length > 0 &&
        dados.avaliacoes.map(({ score, dataAvaliacao }) => ({ label: formatarData(user.idioma, dataAvaliacao), value: score })),
      trendlines: [
        {
          line:
            dados.grausDaMatriz &&
            dados.grausDaMatriz.length > 0 &&
            dados.grausDaMatriz.map(({ de, ate, grauRisco: { cor } }, index) => ({
              isTrendZone: '1',
              color: cor,
              startvalue: de,
              endValue: index == dados.grausDaMatriz.length - 1 ? dados.maiorScoreDaMatriz : ate,
              displayValue: ' ',
              alpha: '75',
            })),
        },
      ],
    },
  };

  return showGrafico() ? (
    <Suspense fallback={<LoadingScreen />}>
      <Grafico {...config} />
    </Suspense>
  ) : (
    <></>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(GraficoScorePeriodo));
