import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from 'src/utils/form';
import FormatoUnidadeLookup from 'src/lookups/formato-unidade';
import CheckBox from 'src/componentes/checkbox';
import { Col, Row } from 'reactstrap';
import Input from 'src/componentes/input';
import { InputTranslate } from 'src/componentes/Form';
import { FormattedMessage } from 'react-intl';
import InputNumber from 'src/componentes/input-number';
import Alert from 'src/componentes/message-box/alert';
import ContentManager from 'src/componentes/content-manager';
import CheckboxSwitch from 'src/componentes/Form/Checkbox';
import Info from 'src/componentes/info';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  sigla: Schema.string()
    .label(<FormattedMessage id="sigla" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  formatoUnidade: Schema.string()
    .label(<FormattedMessage id="formato" />)
    .required(),
  casasDecimais: Schema.string()
    .label(<FormattedMessage id="casasDecimais" />)
    .required(),
  concatenarDireita: Schema.string().label(
    <FormattedMessage id="label.exibirSiglaADireitaDoValor" />
  ),
  tipoUnidadeMedida: Schema.string().label(
    <FormattedMessage id="labelMoeda" />
  ),
});

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [60, 20, 20, 20],
      colors: ['#366fcc', '#ec1c24', '#f9cd17', '#8bc53f'],
    };
  }

  handleCasasDecimaisChange(value) {
    if (value > 15) {
      ContentManager.addContent(
        <Alert
          message={<FormattedMessage id="limiteCasasDecimais" />}
          handleClose={this.props.model
            .getModel('casasDecimais')
            .requestChange(15)}
        />
      );
    } else this.props.model.getModel('casasDecimais').requestChange(value);
  }

  onChange(values) {
    this.setState({
      values: values,
    });
  }

  handleChangeInputTranslate(field, value) {
    let model = this.props.model;
    model.getModel(field).requestChange(value);
  }

  handleChangeEditTranslate(
    fieldDefault,
    fieldPT,
    fieldEN,
    fieldES,
    fieldIT,
    fieldNL,
    fieldFR,
    fieldDE,
    fieldTR,
    fieldAR,
    localModel
  ) {
    let model = this.props.model;
    model.getModel(fieldDefault).requestChange(localModel[fieldDefault]);
    model.getModel(fieldPT).requestChange(localModel[fieldPT]);
    model.getModel(fieldEN).requestChange(localModel[fieldEN]);
    model.getModel(fieldES).requestChange(localModel[fieldES]);
    model.getModel(fieldIT).requestChange(localModel[fieldIT]);
    model.getModel(fieldNL).requestChange(localModel[fieldNL]);
    model.getModel(fieldFR).requestChange(localModel[fieldFR]);
    model.getModel(fieldDE).requestChange(localModel[fieldDE]);
    model.getModel(fieldTR).requestChange(localModel[fieldTR]);
    model.getModel(fieldAR).requestChange(localModel[fieldAR]);
  }

  render() {
    let { model, intl, modulosComprados } = this.props;

    const possuiModuloGR =
      modulosComprados &&
      modulosComprados.length > 0 &&
      modulosComprados.find((m) => m.id === 5 && m.ativo);
    return (
      <div>
        <Row>
          <Col md={3}>
            <Input model={model.getModel('idIntegracao')} required></Input>
          </Col>
          <Col md={6}>
            <InputTranslate
              id={model.getModel('id') && model.getModel('id').value}
              maxLength={100}
              required={true}
              errorsFieldDefault={model.getModel('descricao').errors}
              fieldDefault={'descricao'}
              valueDefault={model.getModel('descricao').value}
              labelDefault={model.getModel('descricao').label}
              fieldPT={'descricaoPT'}
              valuePT={model.getModel('descricaoPT').value}
              fieldEN={'descricaoEN'}
              valueEN={model.getModel('descricaoEN').value}
              fieldES={'descricaoES'}
              valueES={model.getModel('descricaoES').value}
              fieldIT={'descricaoIT'}
              valueIT={model.getModel('descricaoIT').value}
              fieldNL={'descricaoNL'}
              valueNL={model.getModel('descricaoNL').value}
              fieldFR={'descricaoFR'}
              valueFR={model.getModel('descricaoFR').value}
              fieldDE={'descricaoDE'}
              valueDE={model.getModel('descricaoDE').value}
              fieldTR={'descricaoTR'}
              valueTR={model.getModel('descricaoTR').value}
              fieldAR={'descricaoAR'}
              valueAR={model.getModel('descricaoAR').value}
              handleChangeInputTranslate={this.handleChangeInputTranslate}
              handleChangeEditTranslate={this.handleChangeEditTranslate}
            />
          </Col>
          <Col md={3}>
            <Input maxLength="20" model={model.getModel('sigla')} required />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormatoUnidadeLookup
              model={model.getModel('formatoUnidade')}
              required
            />
          </Col>
          {model.getModel('formatoUnidade').value &&
            model.getModel('formatoUnidade').value.id == 1 && (
              <Col md={3}>
                <InputNumber
                  model={{
                    label: model.getModel('casasDecimais').label,
                    value: model.getModel('casasDecimais').value,
                    requestChange: this.handleCasasDecimaisChange,
                    errors: model.getModel('casasDecimais').errors,
                  }}
                  required
                />
              </Col>
            )}
          <Col md={6}>
            <CheckBox
              style={{ marginTop: 30, display: 'inline-block' }}
              model={model.getModel('concatenarDireita')}
            />
            <Info
              id="concatenarDireita"
              placement="right"
              text={intl.formatMessage({
                id: 'siglaExibidaPainelERelacionamentos',
              })}
            />
          </Col>
        </Row>
        {possuiModuloGR && (
          <Row>
            <Col md={3}>
              <div className="mb-2">
                <CheckboxSwitch
                  type="switch"
                  id={`tipoUnidadeMedida`}
                  name="customSwitch"
                  label={intl.formatMessage({ id: 'labelMoeda' })}
                  checked={model.getModel('tipoUnidadeMedida')?.value}
                  onChange={(e) =>
                    model
                      .getModel('tipoUnidadeMedida')
                      ?.requestChange(e.target.checked)
                  }
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default class UnidadeMedidaEdit extends React.Component {
  render() {
    return (
      <Edit
        {...this.props}
        url="/UnidadeMedida"
        title="unidadeDeMedida"
        formComponent={FormEdit}
        schema={schema}
        width={900}
        height={330}
        showExport={false}
      />
    );
  }
}
