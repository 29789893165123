import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';

import DropdownItemList from 'src/paginas/item/dropdown-item';
import MdiIcon from 'src/componentes/mdi-icon';
import ContentManager from 'src/componentes/content-manager';
import CriticalConfirm from 'src/componentes/message-box/critical-confirm';
import Button from 'src/componentes/button';
import ColunasTabelas from 'src/componentes/list/componentes/colunas-tabela';
import GanttViewLookup from 'src/lookups/GanttViewLookup';
import useAuth from 'src/hooks/useAuth';
import { GANTT_VIEW, TIPO_INTERACAO_SOPHIE } from 'src/utils/constants';
import DialogColunas from 'src/paginas/acao/kanban/components/dialog-colunas';
import AcaoEdit from 'src/paginas/acao/edit';
import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/errorHandler';
import ButtonGroup from 'src/componentes/ButtonGroup';
import FiltroKanban from 'src/paginas/NewGantt/components/GanttContainer/Toolbar/filtroKanban';
import {
  taskMapper,
  getDefaultColumns,
  handleHttp,
  getDefaultTask,
} from '../../../utils';
import { capitalizeFirstLetter } from 'src/utils/string';
import AssistenteVirtual from 'src/components/IA/ChatGPT/AssistenteVirtual';
import Input from 'src/componentes/input';
import { isNullOrWhiteSpace } from 'src/utils/string';

const BTN_IDS = {
  EXPAND: 'gantt-expand',
  COLLAPSE: 'gantt-collapse',
  SHOW_NAME: 'gantt-showname',
  ZOOM_IN: 'gantt-zoomin',
  ZOOM_OUT: 'gantt-zoomout',
  ZOOM_TO_FIT: 'gantt-zoomtofit',
  LEFT: 'gantt-left',
  RIGHT: 'gantt-right',
  BASELINES: 'gantt-baselines',
  CRITICAL_PATH: 'gantt-criticalpath',
  FULL_SCREEN: 'gantt-fullscreen',
  EDIT: 'editar',
  REFRESH: 'refresh',
};

const Toolbar = ({
  gantt,
  columns,
  permissions,
  setColumns,
  itemId,
  centroCustoId,
  pacoteId,
  configurations,
  setIsLoading,
  componentView,
  handleUpdateView,
  kanbanSettings,
  handleUpdateKanbanSettings,
  filter,
  updateTree,
  isConsultaExterna,
  ganttContainerId,
  handleCloseModal,
  showMenuToolBar = true,
  customFields = [],
  atualizarState,
  abrirUltimoFiltro,
  filterKanban,
  ...rest
}) => {
  const intl = useIntl();

  const { terms: resources, user, moduloInteligenciaArtificial } = useAuth();

  const [schedulerView, setSchedulerView] = useState(null);

  const { item } = filter;
  const params = {
    itemId,
    centroCustoId,
    pacoteId,
    ...rest.parameters,
  };

  const activeViewIsGantt = componentView === GANTT_VIEW.GANTT;

  const handleConfirmDeleteAll = () => {
    const handleDeleteAll = async () => {
      setIsLoading(true);
      try {
        await handleHttp('/Acao/ExcluirTodasAcoes', {
          ...params,
        });
        const recordsToRemove =
          gantt.ganttInstance.taskStore.storage.allValues?.filter(
            (x) => !x.originalData?.planoAcaoExterno
          );

        gantt.ganttInstance.taskStore.remove(recordsToRemove);
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    };

    ContentManager.addContent(
      <CriticalConfirm
        title={intl.formatMessage({
          id: 'excluir',
        })}
        message={intl.formatMessage(
          {
            id: 'desejaMesmoExcluirTodoPlanoAcao',
          },
          {
            acoes: resources.acoes,
            acao: resources.acao,
          }
        )}
        handleConfirm={handleDeleteAll}
        labelConfirm={intl.formatMessage({
          id: 'label.confirmarExclusao',
        })}
      />
    );
  };

  const handleOpenColumnsEdit = () => {
    const allColumns = getDefaultColumns(
      intl,
      resources,
      configurations,
      customFields
    );

    ContentManager.addContent(
      <ColunasTabelas
        columns={columns}
        allColumns={allColumns}
        tag={2008}
        handleAtualizarColunas={(newColumns) => setColumns(newColumns)}
      />
    );
  };

  const handleOpenColumnsKanbanEdit = () => {
    const handleUpdateColumnsKanban = (v) =>
      handleUpdateKanbanSettings('columnsKanban', v);

    const handleUpdateColumnsCardKanban = (v) =>
      handleUpdateKanbanSettings('columnsCard', v);

    const { allColumnsKanban, allColumnsCard, columnsKanban, columnsCard } =
      kanbanSettings || {};

    ContentManager.addContent(
      <DialogColunas
        allColumnsKanban={allColumnsKanban}
        allColumnsCard={allColumnsCard}
        columnsKanban={columnsKanban}
        columnsCard={columnsCard}
        handleAtualizarCardKanban={handleUpdateColumnsCardKanban}
        handleAtualizarColunasKanban={handleUpdateColumnsKanban}
      />
    );
  };

  const handleExportDefault = async (format) => {
    const orderIds =
      gantt.ganttInstance.taskStore?._allRecords?.map((r) => r.id) || [];

    setIsLoading(true);
    try {
      const response = await http.post(
        '/Acao/ExportarGanttLista',
        {
          filtro: {
            itemId,
            centroCustoId,
            pacoteId,
          },
          colunas: columns,
          formato: format,
          idsOrdenacao: orderIds,
        },
        {
          responseType: 'arraybuffer',
        }
      );

      DownloadFile(response);
    } catch (err) {
      errorHandler(ParseError(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltroModal = () => {
    ContentManager.addContent(
      <FiltroKanban
        atualizarState={atualizarState}
        filterKanban={filterKanban}
        refresh={refresh}
        abrirUltimoFiltro={abrirUltimoFiltro}
      />
    );
  };

  const renderIcon = (icon) => (
    <MdiIcon className="cursor-pointer mr-4" icon={icon} />
  );

  const renderCommon = (isGantt = false) => (
    <>
      <div
        className="text-primary mr-4"
        onClick={
          !activeViewIsGantt
            ? handleOpenColumnsKanbanEdit
            : handleOpenColumnsEdit
        }
      >
        {renderIcon('format-columns')}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div id={BTN_IDS.REFRESH} onClick={updateTree}>
          {renderIcon('refresh')}
        </div>
        <UncontrolledTooltip target={BTN_IDS.REFRESH}>
          {intl.formatMessage({ id: 'atualizar' })}
        </UncontrolledTooltip>
      </div>
      {!activeViewIsGantt ? (
        <Button
          className="mr-4 d-inline"
          id="btn-filter"
          onClick={handleFiltroModal}
        >
          <MdiIcon icon="filter" />
        </Button>
      ) : null}
      <ButtonGroup
        className="mr-4"
        initialActiveId={!activeViewIsGantt ? 2 : 1}
        items={[
          {
            id: 1,
            description: 'Gantt',
            callback: () => handleUpdateView(GANTT_VIEW.GANTT),
          },
          {
            id: 2,
            description: resources.kanban,
            callback: () => handleUpdateView(GANTT_VIEW.KANBAN),
          },
        ]}
      ></ButtonGroup>
      {showMenuToolBar && (
        <UncontrolledDropdown>
          <DropdownToggle tag="span" className="text-primary">
            {renderIcon('dots-vertical')}
          </DropdownToggle>
          <DropdownMenu container="body">
            <DropdownItemList
              handleCloseModal={handleCloseModal}
              exibirPlanoAcao={false}
              exibirMensagem={false}
              resources={resources}
              item={item}
              intl={intl}
            />
            {isGantt && (
              <>
                <DropdownItem divider />
                <DropdownItem
                  className="d-flex align-items-center"
                  onClick={() => {
                    handleExportDefault('pdf');
                  }}
                >
                  {renderIcon('file-pdf-box')}
                  <div className="ml-2">
                    {intl.formatMessage({ id: 'label.exportarPDF' })}
                  </div>
                </DropdownItem>
                <DropdownItem
                  className="d-flex align-items-center"
                  onClick={() => handleExportDefault('xlsx')}
                >
                  {renderIcon('file-excel')}
                  <div className="ml-2">
                    {intl.formatMessage({ id: 'label.exportarExcel' })}
                  </div>
                </DropdownItem>
              </>
            )}
            {permissions?.allowDelete && (
              <>
                <DropdownItem divider />
                <DropdownItem
                  className="d-flex align-items-center"
                  onClick={() => handleConfirmDeleteAll()}
                >
                  {renderIcon('delete-outline')}
                  <div className="ml-2">
                    {intl.formatMessage(
                      { id: 'label.deletarTodoPlano' },
                      { planodeacao: resources.planodeacao }
                    )}
                  </div>
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );

  const renderGanttToolbar = () => {
    const handleChangeSchedulerView = (v) => {
      if (!v) return;
      setSchedulerView(v);

      gantt.ganttInstance.viewPreset = {
        base: v.key,
        timeResolution: {
          unit: 'day',
          increment: 1,
        },
      };
    };

    const handleAddNewTask = async (customProps) => {
      const defaultTask = getDefaultTask(
        intl,
        resources,
        { ...user },
        'main',
        customProps
      );
      const taskMapped = taskMapper(defaultTask);

      const added =
        gantt.ganttInstance.taskStore.rootNode.appendChild(taskMapped);
      await gantt.ganttInstance.scrollRowIntoView(added);

      gantt.ganttInstance.features.cellEdit.startEditing({
        record: added,
        field: 'name',
      });
    };

    const handleAssistenteVirtualClick = () => {
      try {
        http
          .post('/Acao/GetPromptSugerirAcao', {
            itemId: itemId,
            centroCustoId: centroCustoId,
            pacoteId: pacoteId,
          })
          .then((response) => {
            const prompt = response.data;
            if (isNullOrWhiteSpace(prompt)) return;

            const title = intl.formatMessage(
              {
                id: 'labelSophieTitleSugerirAcoesPlanoDeAcao',
              },
              { acoes: resources.acoes, planodeacao: resources.planodeacao }
            );

            ContentManager.addContent(
              <AssistenteVirtual
                user={user}
                prompt={prompt}
                title={title}
                actions={(openCustomContent, setOpenCustomContent) => (
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      onClick={() => setOpenCustomContent(!openCustomContent)}
                      color="primary"
                      leftIcon={openCustomContent ? 'minus' : 'plus'}
                    >
                      {intl.formatMessage(
                        { id: 'criarAcao' },
                        { acao: resources.acao }
                      )}
                    </Button>
                  </div>
                )}
                customContent={(customObject, setCustomObject) => (
                  <>
                    <hr className="my-3"></hr>
                    <Input
                      required
                      type="textarea"
                      rows={4}
                      model={{
                        label: intl.formatMessage({ id: 'descricao' }),
                        value: customObject?.descricaoAcao,
                        requestChange: (v) =>
                          setCustomObject('descricaoAcao', v),
                      }}
                    />
                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        disabled={!customObject?.descricaoAcao}
                        onClick={() =>
                          handleAddNewTask({
                            descricao: customObject?.descricaoAcao,
                          })
                        }
                        color="primary"
                      >
                        {intl.formatMessage({ id: 'label.salvar' })}
                      </Button>
                    </div>
                  </>
                )}
                interactionType={TIPO_INTERACAO_SOPHIE.SUGERIR_ACOES_GANTT}
              />
            );
          });
      } catch (err) {
        errorHandler(err, intl);
      }
    };

    return (
      <>
        <div className="d-flex align-items-center">
          {permissions?.allowCreate && !isConsultaExterna && (
            <>
              <Button
                className="mr-3"
                outline
                disabled={!configurations.utilizarCodigoAutomatico}
                leftIcon="plus"
                onClick={handleAddNewTask}
                title={intl.formatMessage(
                  { id: 'criarNovaAcaoParaItem' },
                  { acao: resources.acao }
                )}
              >
                {capitalizeFirstLetter(resources.acao)}
              </Button>

              {moduloInteligenciaArtificial && (
                <>
                  <Button
                    id={'sophieInsightsAcoes'}
                    className="bg-primary"
                    onClick={() => handleAssistenteVirtualClick()}
                  >
                    <img alt="IA logo" src="/ia/ia_branco.svg" />
                    <span className="ml-2">
                      {intl.formatMessage(
                        { id: 'labelSophieSugerirAcoes' },
                        {
                          acoes: resources.acoes,
                        }
                      )}
                    </span>
                  </Button>
                  <UncontrolledTooltip
                    placement="auto"
                    target={'sophieInsightsAcoes'}
                  >
                    {intl.formatMessage(
                      {
                        id: 'labelSophieToolTipSugerirAcoesPlanoDeAcao',
                      },
                      {
                        acoes: resources.acoes,
                        planodeacao: resources.planodeacao,
                      }
                    )}
                  </UncontrolledTooltip>
                </>
              )}
            </>
          )}
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <div
            id={BTN_IDS.EXPAND}
            className="text-primary mr-2"
            onClick={() => gantt.ganttInstance.expandAll()}
          >
            {renderIcon('chevron-double-down')}
            <UncontrolledTooltip target={BTN_IDS.EXPAND}>
              {intl.formatMessage({ id: 'expandir' })}
            </UncontrolledTooltip>
          </div>
          <div
            id={BTN_IDS.COLLAPSE}
            className="text-primary mr-4"
            onClick={() => gantt.ganttInstance.collapseAll()}
          >
            {renderIcon('chevron-double-up')}
            <UncontrolledTooltip target={BTN_IDS.COLLAPSE}>
              {intl.formatMessage({ id: 'colapsar' })}
            </UncontrolledTooltip>
          </div>
          <div
            id={BTN_IDS.SHOW_NAME}
            className="text-primary mr-4"
            onClick={() =>
              (gantt.ganttInstance.features.labels.disabled =
                !gantt.ganttInstance.features.labels.disabled)
            }
          >
            {renderIcon('text')}
            <UncontrolledTooltip target={BTN_IDS.SHOW_NAME}>
              {intl.formatMessage(
                { id: 'exibirNomeAcoes' },
                { acoes: resources.acoes }
              )}
            </UncontrolledTooltip>
          </div>
          <div
            id={BTN_IDS.ZOOM_IN}
            className="text-primary mr-2"
            onClick={() => {
              try {
                gantt.ganttInstance.zoomIn();
              } catch (_) {}
            }}
          >
            {renderIcon('magnify-plus-outline')}
            <UncontrolledTooltip target={BTN_IDS.ZOOM_IN}>
              {intl.formatMessage({ id: 'zoom+' })}
            </UncontrolledTooltip>
          </div>
          <div
            id={BTN_IDS.ZOOM_OUT}
            className="text-primary mr-2"
            onClick={() => {
              try {
                gantt.ganttInstance.zoomOut();
              } catch (_) {}
            }}
          >
            {renderIcon('magnify-minus-outline')}
            <UncontrolledTooltip target={BTN_IDS.ZOOM_OUT}>
              {intl.formatMessage({ id: 'zoom-' })}
            </UncontrolledTooltip>
          </div>
          <div
            id={BTN_IDS.ZOOM_TO_FIT}
            className="text-primary mr-4"
            onClick={() => {
              try {
                gantt.ganttInstance.zoomToFit({
                  leftMargin: 50,
                  rightMargin: 50,
                });
              } catch (_) {}
            }}
          >
            {renderIcon('arrow-expand-all')}
            <UncontrolledTooltip target={BTN_IDS.ZOOM_TO_FIT}>
              {intl.formatMessage({ id: 'label.zoomToFit' })}
            </UncontrolledTooltip>
          </div>
          <div
            id={BTN_IDS.LEFT}
            className="text-primary mr-2"
            onClick={() => gantt.ganttInstance.shiftPrevious()}
          >
            {renderIcon('chevron-left')}
            <UncontrolledTooltip target={BTN_IDS.LEFT}>
              {intl.formatMessage({ id: 'deslocarParaEsquerda' })}
            </UncontrolledTooltip>
          </div>
          <div
            id={BTN_IDS.RIGHT}
            className="text-primary mr-4"
            onClick={() => gantt.ganttInstance.shiftNext()}
          >
            {renderIcon('chevron-right')}
            <UncontrolledTooltip target={BTN_IDS.RIGHT}>
              {intl.formatMessage({ id: 'deslocarParaDireita' })}
            </UncontrolledTooltip>
          </div>
          {configurations?.habilitarBaseline && (
            <div
              id={BTN_IDS.BASELINES}
              className="text-primary mr-4"
              onClick={() =>
                (gantt.ganttInstance.features.baselines.disabled =
                  !gantt.ganttInstance.features.baselines.disabled)
              }
            >
              {renderIcon('calendar-range')}
              <UncontrolledTooltip target={BTN_IDS.BASELINES}>
                {intl.formatMessage({ id: 'baselines' })}
              </UncontrolledTooltip>
            </div>
          )}
          <div
            id={BTN_IDS.CRITICAL_PATH}
            className="text-primary mr-4"
            onClick={() =>
              (gantt.ganttInstance.features.criticalPaths.disabled =
                !gantt.ganttInstance.features.criticalPaths.disabled)
            }
          >
            {renderIcon('alert-circle-outline')}
            <UncontrolledTooltip target={BTN_IDS.CRITICAL_PATH}>
              {intl.formatMessage({ id: 'caminhoCritico' })}
            </UncontrolledTooltip>
          </div>
          <div className="mr-4">
            <GanttViewLookup
              style={{ marginBottom: 0, width: 180 }}
              placeholder={`${intl.formatMessage({ id: 'visualizacao' })}...`}
              model={{
                value: schedulerView,
                requestChange: handleChangeSchedulerView,
              }}
            />
          </div>
          <div
            id={BTN_IDS.FULL_SCREEN}
            className="text-primary mr-4"
            onClick={() => {
              const element = document.getElementById(ganttContainerId);

              if (!element) return;

              if (element.requestFullscreen) {
                element.requestFullscreen();
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
              } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
              }
            }}
          >
            {renderIcon('arrow-expand')}
            <UncontrolledTooltip target={BTN_IDS.FULL_SCREEN}>
              {intl.formatMessage({ id: 'telaCheia' })}
            </UncontrolledTooltip>
          </div>
          {renderCommon(true)}
        </div>
      </>
    );
  };

  const renderKanbanToolbar = () => {
    const handleAddNewTask = () => {
      ContentManager.addContent(
        <AcaoEdit
          showDelete={permissions?.allowDelete}
          parameters={{ exibirLookups: true, ...params }}
          handleClose={(saved) => saved && handleUpdateView(GANTT_VIEW.KANBAN)}
        />
      );
    };

    return (
      <>
        <div className="d-flex align-items-center">
          {permissions?.allowCreate && (
            <Button
              className="mr-3"
              outline
              leftIcon="plus"
              onClick={handleAddNewTask}
            >
              {capitalizeFirstLetter(resources.acao)}
            </Button>
          )}
        </div>
        <div className="d-flex align-items-center">{renderCommon()}</div>
      </>
    );
  };

  return (
    <div
      className="d-flex justify-content-between flex-wrap px-3 py-2"
      style={{
        background: '#f3f4f5',
        borderBottom: '1px solid #d8d9da',
      }}
    >
      {componentView
        ? !activeViewIsGantt
          ? renderKanbanToolbar()
          : renderGanttToolbar()
        : ''}
    </div>
  );
};

export default Toolbar;
