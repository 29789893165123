import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import InputNumber from 'src/componentes/input-number';
import Checkbox from 'src/componentes/checkbox';
import { autobind } from 'core-decorators';
import { Schema } from 'src/utils/form';
import { Row, Col, Label, Container } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import http from 'src/services/httpService';

let schema = Schema.object({
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  eficiencia: Schema.number()
    .label(<FormattedMessage id="labelEficiencia" />)
    .required(),
  preventivo: Schema.boolean().label(<FormattedMessage id="controlePreventivo" />),
  corretivo: Schema.boolean().label(<FormattedMessage id="controleCorretivo" />),
});

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracaoPontoControle: {},
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    http.post(`/PontoControleConfiguracao/ObterConfiguracoes`).then((response) => {
      this.setState({
        configuracaoPontoControle: response.data.model,
      });
    });
  }

  render() {
    let { model, intl } = this.props;
    const { configuracaoPontoControle } = this.state;

    return (
      <Container>
        <Row>
          <Col md={4}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
          <Col md={8}>
            <Input model={model.getModel('nome')} required />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Row className="flex-column-reverse flex-md-row">
              <Col md={12}>
                <InputNumber precision={2} placeholder="%" model={model.getModel('eficiencia')} required />
              </Col>
              {configuracaoPontoControle && configuracaoPontoControle.avaliarEfetividadeControleSeparadamente && (
                <div>
                  <Col md={12}>
                    <Label>{intl.formatMessage({ id: 'label.AplicavelA' })} *</Label>
                  </Col>
                  <Col md={12} className="pl-4">
                    <Checkbox model={model.getModel('preventivo')} style={{ marginTop: 10 }} />
                  </Col>
                  <Col md={12} className="pl-4">
                    <Checkbox model={model.getModel('corretivo')} style={{ marginTop: 10 }} />
                  </Col>
                </div>
              )}
            </Row>
          </Col>
          <Col md={8}>
            <Input rows={8} type="textarea" model={model.getModel('descricao')} />
          </Col>
        </Row>
      </Container>
    );
  }
}

class TipoEvidenciaEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;
    return (
      <Edit
        url="/EfetividadeControle"
        title={capitalizeFirstLetter(intl.formatMessage({ id: 'efetividadeDosControles' }, { pontosdecontrole: resources.pontosdecontrole }))}
        formComponent={FormEdit}
        schema={schema}
        width={600}
        height={300}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(TipoEvidenciaEdit));
