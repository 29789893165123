import React from 'react';
import { autobind } from 'core-decorators';
import Dropzone from 'react-dropzone';
import LoadingContainer from './../../loading-container/index';
import Dialog from './../../dialog';
import Button from './../../button';
import Confirm from './../../message-box/confirm';
import Alert from './../../message-box/alert';
import ContentManager from './../../content-manager';
import css from './../../../utils/css';
import http from 'src/services/httpService';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DropzoneArquivoExibicao } from './arquivo-exibicao';

const baseClasses = css`
  .paper {
    float: left;
    height: 100px;
    width: 110px;
    padding: 5px;
    margin-right: 15px;
    margin-bottom: 15px;
    text-align: center;
    display: inline-block;
    line-height: 1.2;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 12px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
      rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  }
  .popover {
    width: 250px;
  }
  .drop {
    cursor: pointer;
    border: 2px dashed #e0e0e0;
    margin-bottom: 5px;
  }
  .placeholder {
    color: #d9d9d9;
    font-size: 24px;
  }
`;

@autobind
class FileBucket extends React.Component {
  static defaultProps = {
    disabled: false,
    tipoAnexo: null,
    onlyRead: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      arquivos:
        this.props.model && this.props.model.value
          ? this.props.model.value
          : [],
      files: [],
    };
  }

  renderDeleteConfirm(anexo) {
    let numAnexos = this.state.arquivos ? this.state.arquivos.length : 0;
    if (numAnexos == 1 && this.props.anexoObrigatorio) {
      ContentManager.addContent(
        <Alert
          message={this.props.intl.formatMessage({
            id: 'obrigatorioPeloMenosUmAnexo',
          })}
        />
      );
    } else {
      ContentManager.addContent(
        <Confirm
          message={this.props.intl.formatMessage({
            id: 'label.desejaMesmoExcluirEsseRegistro',
          })}
          handleConfirm={this.handleDeleteConfirmClick.bind(this, anexo)}
        />
      );
    }
  }

  handleDeleteConfirmClick(anexo) {
    this.props.onDeleting && this.props.onDeleting(anexo);
    let novaLista = [...this.state.arquivos];
    let index = this.state.arquivos.findIndex((a) => a.id === anexo.id);
    if (index !== -1) {
      novaLista.splice(index, 1);
    }
    this.setState({ arquivos: novaLista });
  }

  handleOnDrop(arquivos) {
    const maxFileSize = 32 * 1024 * 1024; // 32 MB em bytes
    let files = this.state.files;

    const validFiles = arquivos.filter((file) => {
      if (file.size > maxFileSize) {
        ContentManager.addContent(
          <Alert
            message={this.props.intl.formatMessage(
              {
                id: 'msgArquivoExcedeTamanhoMaximo32mb',
              },
              {
                filename: file.name,
              }
            )}
          />
        );
        return false;
      }
      return true;
    });

    if (validFiles && validFiles.length == 0) return;

    if (this.props.onlyOneFile) {
      files = [validFiles[validFiles.length - 1]];
      this.setState({ files: files, arquivos: [] });
    } else {
      validFiles.forEach((file) => {
        files.push(file);
      });
      this.setState({ files: files });
    }
  }

  handleSair() {
    this.refs.dialog.close();
  }

  handleSalvarClick() {
    this.setState({ uploading: true });

    var data = new FormData();

    if (this.props.onlyOneFile && this.state.files.length > 1)
      data.append('file', this.state.files.slice(-1));
    else
      this.state.files.map((file) => {
        data.append('file', file);
      });

    const currentFile = this.state.files.slice(-1);

    if (currentFile && currentFile.length > 0) {
      http
        .post('File/UploadFile', data)
        .then((result) => {
          if (result.data) {
            const arquivoArray = [];
            arquivoArray.push({
              name: result.data.name,
              guid: result.data.guid,
              fileSize: result.data.fileSize,
            });
            this.props.onUploadFiles(arquivoArray);
          }

          this.refs.dialog.close();
        })
        .catch((error) => {
          this.refs.dialog.close();
        });
    } else {
      this.props.onUploadFiles(null);
      this.refs.dialog.close();
    }
  }

  render() {
    let { model, intl, disabled, onlyRead, ...other } = this.props;

    const actions = [
      <div>
        <Button
          key="close-Anexos"
          color="secondary"
          leftIcon="close"
          onClick={this.handleSair.bind(this)}
        >
          {intl.formatMessage({ id: 'label.cancelar' })}
        </Button>

        <Button
          disabled={disabled}
          key="save-Anexos"
          className="ml-2"
          leftIcon="content-save-outline"
          onClick={this.handleSalvarClick.bind(this)}
        >
          {intl.formatMessage({ id: 'label.salvar' })}
        </Button>
      </div>,
    ];

    return (
      <Dialog
        className="popupOpen"
        {...other}
        ref="dialog"
        title={intl.formatMessage({ id: 'label.anexos' })}
        actions={!onlyRead && actions}
        height={500}
        padContent
        onRequestClose={this.handleSair.bind(this)}
      >
        <LoadingContainer isLoading={this.state.uploading}>
          {!onlyRead && (
            <Dropzone
              disabledClick={disabled}
              className={baseClasses.drop}
              style={{
                width: this.props.width ? this.props.width : 800,
                overflowY: 'auto',
                height: this.props.height ? this.props.height : 260,
                lineHeight: this.props.lineHeight
                  ? this.props.lineHeight
                  : '190px',
                textAlign: 'center',
                padding: 5,
              }}
              multiple={this.props.onlyOneFile ? false : true}
              onDrop={this.handleOnDrop}
            >
              <span className={baseClasses.placeholder}>
                {intl.formatMessage({
                  id: 'label.cliqueOuArrasteOArquivoNestaAreaParaAdicionalo',
                })}
              </span>
            </Dropzone>
          )}
          {this.state.arquivos &&
            this.state.arquivos.map((arquivo, index) => (
              <DropzoneArquivoExibicao
                key={index}
                arquivoNome={arquivo.name}
                icone="attachment"
                arquivoGuid={arquivo.guid}
                onlyRead={onlyRead}
                onDeleteFile={this.renderDeleteConfirm.bind(this, arquivo)}
                disabled={disabled}
                fileBucket={true}
              />
            ))}
          {this.state.files &&
            this.state.files.map((file, index) => (
              <DropzoneArquivoExibicao
                key={index}
                arquivoNome={file.name}
                icone="attachment"
              />
            ))}
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(FileBucket));
