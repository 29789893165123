import { useRef, useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';

import { Form } from 'src/componentes/Form';
import LoadingContainer from 'src/componentes/loading-container';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import Button from 'src/componentes/button';
import Dialog from 'src/componentes/dialog';

function EditDialog({
  formComponent: FormComponent,
  validationSchema,
  title,
  id,
  parameters,
  url,
  handleClose,
  ...rest
}) {
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState();
  const intl = useIntl();
  const ref = useRef();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      let data;

      if (!id) {
        const response = await http.post(`${url}/Create`, {
          parameters,
        });
        data = response.data;
      } else {
        const response = await http.post(`${url}/Edit`, {
          id: id,
          parameters,
        });
        data = response.data;
      }
      setForm(data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  }, [id, url]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCloseDialog = () => {
    handleClose && handleClose();
    if (ref.current) ref.current.close();
  };

  return (
    <Dialog ref={ref} title={title} {...rest}>
      <LoadingContainer isLoading={isLoading}>
        {form && (
          <Formik
            initialValues={{ ...form }}
            validationSchema={validationSchema}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setLoading(true);
                const response = await http.post(`${url}/Save`, {
                  model: values,
                  parameters: parameters,
                });
                setForm(response.data.model);
                setStatus({ success: true });
                handleCloseDialog();
              } catch (err) {
                setStatus({ success: false });
                let message = intl.formatMessage({ id: 'label.ocorreuUmErro' });
                if (
                  err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.errorMessage
                ) {
                  message = intl.formatMessage({
                    id: err.response.data.errorMessage,
                  });
                }
                setErrors({ submit: message });
                errorHandler(err);
              } finally {
                setLoading(false);
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              setFieldValue,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormComponent
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                />
                <div className="d-flex justify-content-end ml-auto pb-4">
                  <Button onClick={() => handleCloseDialog()} color="secondary">
                    {intl.formatMessage({ id: 'label.cancelar' })}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="ml-2"
                    disabled={isSubmitting}
                  >
                    {intl.formatMessage({ id: 'label.salvar' })}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </LoadingContainer>
    </Dialog>
  );
}

export default EditDialog;
