import { Schema } from '../../utils/form';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default Schema.object({
  area: Schema.string().label(''),
  areasSubordinadas: Schema.string().label(''),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  listaStatus: Schema.string().label(<FormattedMessage id="status" />),
  //melhor: Schema.string().label(""),
  //farol: Schema.string().label(<FormattedMessage id="label.farolDoPeriodo" />),
  //perspectiva: Schema.string().label(""),
  //classificacao: Schema.string().label(),
  //classificacao2: Schema.string().label(),
  responsavel: Schema.string().label(''),
  //periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  //digitador: Schema.string().label(""),
  //tema: Schema.string().label(""),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  //camposCustomizados: Schema.array()
});
