import React from 'react';
import { Col, Row } from 'reactstrap';
import Input from '../../componentes/input';
import Checkbox from '../../componentes/checkbox';
import ItemOrganizacaoLookup from '../../lookups/item-organizacao';
import StatusLookup from '../../lookups/status-item';
import PerspectivaLookup from '../../lookups/perspectiva';
import ResponsavelLookup from '../../lookups/colaborador';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import TipoItemLookup from 'src/lookups/tipo-item';
import TagLookup from 'src/lookups/TagLookup';
import CatalogoLookup from 'src/lookups/CatalogoNegociacaoMetasLookup';

class ItemSearch extends React.Component {
  render() {
    let { model, resources, intl, parameters } = this.props;

    return (
      <div>
        <Row>
          <Col md={3}>
            <Input model={model.getModel('nome')} />
          </Col>
          <Col md={2}>
            <Input model={model.getModel('idIntegracao')} />
          </Col>
          {model.getModel('negociacaoMetasCatalogo') &&
          model.getModel('negociacaoMetasCatalogo').value ? (
            <Col md={6}>
              <CatalogoLookup
                disabled
                clearable={false}
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage({ id: 'catalogo' })
                  ),
                  value: model.getModel('negociacaoMetasCatalogo').value,
                  requestChange: model.getModel('negociacaoMetasCatalogo')
                    .requestChange,
                }}
              />
            </Col>
          ) : (
            <>
              <Col md={3}>
                <ItemOrganizacaoLookup
                  multi={true}
                  model={{
                    label: capitalizeFirstLetter(resources.area),
                    value: model.getModel('areas').value,
                    requestChange: model.getModel('areas').requestChange,
                  }}
                  data={{ tiposIdSelecionaveis: [1, 2, 3] }}
                />
              </Col>
              <Col md={4}>
                <Checkbox
                  model={{
                    label: intl.formatMessage(
                      { id: 'label.considerarAreasSubordinadas' },
                      { areas: resources.areas }
                    ),
                    value: model.getModel('areasSubordinadas').value,
                    requestChange:
                      model.getModel('areasSubordinadas').requestChange,
                  }}
                  style={{ marginTop: 27 }}
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col md={3}>
            <ResponsavelLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.responsavel),
                value: model.getModel('responsaveis').value,
                requestChange: model.getModel('responsaveis').requestChange,
              }}
            />
          </Col>
          <Col md={2}>
            <StatusLookup
              clearable
              model={model.getModel('status')}
              data={{ idsSelecionados: [1, 2, 3, 4, 5] }}
            />
          </Col>
          <Col md={2}>
            <PerspectivaLookup
              clearable
              model={{
                label: capitalizeFirstLetter(resources.perspectiva),
                value: model.getModel('perspectiva').value,
                requestChange: model.getModel('perspectiva').requestChange,
              }}
            />
          </Col>
          <Col md={3}>
            <TipoItemLookup
              clearable
              model={{
                label: intl.formatMessage({ id: 'label.tipoItem' }),
                value: model.getModel('tipoItem').value,
                requestChange: model.getModel('tipoItem').requestChange,
              }}
              data={
                parameters?.tiposIdsIgnorar
                  ? {
                      tiposIdSelecionaveis: [1, 2, 3, 4, 5, 6],
                      tiposIdsIgnorar: parameters.tiposIdsIgnorar,
                    }
                  : { tiposIdSelecionaveis: [1, 2, 3, 4, 5, 6] }
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TagLookup
              multi
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'tags' }, { tags: resources.tags })
                ),
                value: model.getModel('tags').value,
                requestChange: model.getModel('tags').requestChange,
              }}
              data={{ funcionalidadesIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ItemSearch));
