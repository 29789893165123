import React, { Fragment } from 'react';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Edit from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import { Row, Col } from 'reactstrap';
import Radio from 'src/componentes/radio';
import { capitalizeFirstLetter } from 'src/utils/string';
import TipoCardFormula from './tipo-card-formula';
import CestaItensList from '../item';
import Panel from 'src/componentes/panel';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import FormulaEdit from '../formula/edit';
import FormulaVisao from 'src/paginas/formula/componentes/formula-visao';

let schema = Schema.object({
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  expressao: Schema.string().label(<FormattedMessage id="label.expressao" />),
  tipo: Schema.string().label(<FormattedMessage id="tipo" />),
  alterado: Schema.string().label(<FormattedMessage id="label.alterado" />),
  excluido: Schema.string().label(<FormattedMessage id="label.excluido" />),
  item: Schema.string().label(<FormattedMessage id="item" />),
  desdobramento: Schema.string(),
});

class TipoCardEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tipoCalculoScore: 2,
    };
  }

  componentDidMount() {
    this.updateTipoCalculo();
  }

  updateTipoCalculo() {
    const tipoFormula =
      this.props.item &&
      this.props.item.formulas &&
      this.props.item.formulas.length > 0;
    const tipoCalculo = tipoFormula ? 2 : 1;

    if (this.state.tipoCalculoScore !== tipoCalculo)
      this.setState({ tipoCalculoScore: tipoCalculo });
  }

  renderFormula(item) {
    return (
      <FormulaVisao formula={item.expressao} isItem={false}></FormulaVisao>
    );
  }

  renderRadioButton() {
    const optionsRadio = [
      {
        id: 1,
        descricao: capitalizeFirstLetter(
          this.props.intl.formatMessage({ id: 'pesos' })
        ),
      },
      {
        id: 2,
        descricao: capitalizeFirstLetter(
          this.props.intl.formatMessage({ id: 'formula' })
        ),
      },
    ];

    return optionsRadio;
  }

  handleChangeTipoCalculoScore(value) {
    if (value) this.setState({ tipoCalculoScore: value });
  }

  render() {
    let { intl, model } = this.props;
    let { tipoCalculoScore } = this.state;

    return (
      <Fragment>
        <Row>
          <Col md={4}>
            <div>
              <Radio
                model={{
                  label:
                    capitalizeFirstLetter(intl.formatMessage({ id: 'tipo' })) +
                    ' *',
                  value: tipoCalculoScore,
                  requestChange: this.handleChangeTipoCalculoScore.bind(this),
                }}
                needId={true}
                options={this.renderRadioButton()}
                labelField="descricao"
                idField="id"
                name="opcoestipoCalculoScore"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Panel>
              {tipoCalculoScore == 1 && (
                <CestaItensList
                  parameters={{
                    cestaIndicadorId: this.props.parameters.cestaIndicadorId,
                    tipoCardId: this.props.parameters.tipoCardId,
                    categoriaId: this.props.item.categoriaId,
                  }}
                  disableDrag
                  showExport={false}
                  refreshModel={this.props.refreshModel}
                  model={model}
                />
              )}
              {tipoCalculoScore == 2 && (
                <List
                  showTitle
                  title={intl.formatMessage({ id: 'label.formulas' })}
                  url="/Formula"
                  editComponent={TipoCardFormula}
                  bloquearMultiplosRegistros
                  showActions
                  showNew
                  showEdit
                  showPage={false}
                  showDelete={false}
                  parameters={{
                    permissions: this.props.item.permissions,
                    cestaIndicadorId: this.props.parameters.cestaIndicadorId,
                    tipoCardId: this.props.parameters.tipoCardId,
                    categoriaId: this.props.item.categoriaId,
                    alterado: false,
                    tipoValorId: 5,
                  }}
                  showExport={false}
                  modelAnterior={model}
                >
                  <ListColumn
                    headerText={intl.formatMessage({ id: 'formula' })}
                    valueField="expressao"
                    valueFunction={this.renderFormula}
                  />
                </List>
              )}
            </Panel>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

@autobind
class BaseEdit extends React.Component {
  render() {
    let { resources, parameters, item } = this.props;
    const paramsFormula = {
      ...parameters,
      categoriaId: item.categoriaId,
      categoria: item.categoria,
    };
    return (
      <Edit
        {...this.props}
        parameters={paramsFormula}
        item={item}
        title={item.nome}
        formComponent={TipoCardEdit}
        showActions={false}
        width="80%"
        height="580px"
        resources={resources}
        schema={schema}
        validateAlterModel={false}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(BaseEdit));
