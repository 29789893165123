import { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import ListColumn from 'src/componentes/NewList/ListColumn';
import Toolbar from 'src/componentes/NewList/Toolbar';
import Results from 'src/componentes/NewList/Results';
import Panel from 'src/componentes/panel';
import Button from 'src/componentes/button';
import LoadingContainer from 'src/componentes/loading-container';
import { getColumns, getData, getPermissions } from 'src/componentes/NewList';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';
import ContentManager from 'src/componentes/content-manager';
import { TIPO_VALOR } from 'src/utils/constants';

import Edit from '../RiscoAvaliacaoPowerBIEditView';
import Actions from './Actions';

function ItemValorPowerBIListView({
  endpoint,
  defaultSort,
  defaultSortBy,
  parameters,
  showToolbar,
}) {
  const intl = useIntl();

  const [updatedItems, setUpdatedItems] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [allColumns, setAllColumns] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [columns, setColumns] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(defaultSort);
  const [sortFieldBy, setSortFieldBy] = useState(defaultSortBy);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  const loadColumns = async () => {
    try {
      const currentColumns = await getColumns(defaultColumns, null, null);
      setColumns(currentColumns.columns);
      setAllColumns(currentColumns.allColumns);
    } catch (err) {
      errorHandler(err);
    }
  };

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const newPermissions = await getPermissions(endpoint, parameters);
      setPermissions(newPermissions);

      let newData = [];

      if (newPermissions.allowView || newPermissions.allowList) {
        const model = { searchText };
        newData = await getData(
          endpoint,
          model,
          sortField,
          sortFieldBy,
          pageSize,
          currentPage - 1,
          parameters
        );
        setData(newData.list);
        setCount(newData.count);
        loadColumns();
      }

      setLoading(false);
      return newData;
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  }, [
    endpoint,
    parameters,
    searchText,
    pageSize,
    currentPage,
    sortField,
    sortFieldBy,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData, pageSize, currentPage, searchText, sortField, sortFieldBy]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  });

  const handleRefresh = useCallback(async () => {
    await loadData();
  });

  const handleChangeCurrentPage = useCallback((newValue) => {
    setCurrentPage(newValue);
  });

  const handleChangePageSize = useCallback((pageSize) => {
    setPageSize(pageSize);
  });

  const handleSortColumn = useCallback((sort) => {
    setSortField(sort);
  });

  const handleSortByColumn = useCallback((sortBy) => {
    setSortFieldBy(sortBy);
  });

  const renderActions = (item) => (
    <Actions
      onRefresh={handleRefresh}
      item={item}
      endpoint={endpoint}
      parameters={parameters}
    />
  );

  const defaultColumns = [
    <ListColumn
      className="text-right"
      valueField=""
      valueFunction={renderActions}
      default
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'dataInicio' })
      )}
      valueField="dataInicio"
      //valueFunction={renderNome}
      default
      sortField="DataInicio"
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'dataTermino' })
      )}
      valueField="dataTermino"
      default
      sortField="DataTermino"
    />,
  ];

  const handleNew = () => {
    ContentManager.addContent(
      <Edit
        handleClose={handleRefresh}
        url={endpoint}
        parameters={parameters}
      />
    );
  };

  const actions = [
    <Button key={1} className="ml-2" onClick={handleNew} type="primary">
      <MdiIcon icon="plus" />
    </Button>,
  ];

  return (
    <Panel>
      {parameters &&
      parameters.utilizarConfigPeriodo &&
      (parameters.tipoValorId == TIPO_VALOR.METAACUMULADO ||
        parameters.tipoValorId == TIPO_VALOR.REALIZADOACUMULADO) ? (
        <div className="d-inline-flex py-1 px-2 bg-soft-gray shadow-sm mr-2 text-xs font-weight-bold rounded">
          <div>
            {intl.formatMessage({
              id: 'configuracoesParaAcumuladoSeraoAsMesmasDoPeriodo',
            })}
          </div>
        </div>
      ) : (
        <LoadingContainer isLoading={isLoading}>
          {showToolbar && (
            <Toolbar
              showSearch={false}
              onSearchChange={handleSearchChange}
              onRefresh={handleRefresh}
              searchText={searchText}
              columns={columns}
              allColumns={columns}
              actions={actions}
            />
          )}
          <Results
            count={count}
            columns={columns}
            data={data}
            currentPage={currentPage}
            pageSize={pageSize}
            onChangeCurrentPage={handleChangeCurrentPage}
            onChangePageSize={handleChangePageSize}
            onSortColumn={handleSortColumn}
            onSortByColumn={handleSortByColumn}
            sort={sortField}
            sortBy={sortFieldBy}
            updatedItems={updatedItems}
            showPagination={false}
          />
        </LoadingContainer>
      )}
    </Panel>
  );
}

ItemValorPowerBIListView.defaultProps = {
  endpoint: '/RiscoAvaliacaoPowerBI',
  entityTag: null,
  defaultSort: 'idIntegracao',
  defaultSortBy: '',
  selectedFilter: null,
  openLastFilter: false,
  parameters: {},
  showToolbar: true,
};

export default ItemValorPowerBIListView;
