import { useIntl } from 'react-intl';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import useAuth from 'src/hooks/useAuth';

const TipoResponsabilidadeList = ({ ...rest }) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  return (
    <List
      url="/TipoResponsabilidade"
      {...rest}
      showExport={false}
      showNew={false}
      showEdit={false}
      showDelete={false}
      showSearch={true}
      sort="descricao"
      validateConstraints
      tag={15017}
    >
      <ListColumn
        headerText={intl.formatMessage({ id: 'descricao' })}
        valueField="descricao"
        required
        default
      />
    </List>
  );
};

export default TipoResponsabilidadeList;
