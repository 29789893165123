import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Schema } from '../../../utils/form';

export default Schema.object({
  status: Schema.string().label(<FormattedMessage id="status" />),
  registroSuperior: Schema.string().label(<FormattedMessage id="registroSuperior" />),
  responsavel: Schema.string().label(<FormattedMessage id="label.responsavel" />),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  pai: Schema.string().label(<FormattedMessage id="pai"></FormattedMessage>),
  classificacao: Schema.string().label(<FormattedMessage id="label.classificacao" />),
});
