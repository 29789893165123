import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select2';

@autobind
class Perfis extends React.Component {
  render() {
    return <Lookup url="/PerfilLookup" labelKey="nome" valueKey="id" multi={true} {...this.props} />;
  }
}

export default Perfis;
