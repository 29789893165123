import React from 'react';
import { autobind } from 'core-decorators';
import ReactQuill from 'react-quill';

@autobind
class ReactQuillComponent extends React.Component {
  render() {
    let { style, value, theme, formats, modules, onChange, readOnly, ...rest } =
      this.props;

    return (
      <ReactQuill
        style={style}
        value={value}
        theme={theme}
        formats={formats}
        modules={modules}
        onChange={onChange}
        readOnly={readOnly}
        {...rest}
      />
    );
  }
}

export default ReactQuillComponent;
