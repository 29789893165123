import Lookup from 'src/componentes/select2';

const AvaliacaoTesteControle = ({...rest}) => {
  return(
    <Lookup
      labelKey="descricao"
      valueKey="id"
      url="/AvaliacaoTesteControleLookup"
      {...rest}
    />
  )
}

export default AvaliacaoTesteControle;