import React from 'react';
import { Table } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import formatarDataEHora from 'src/utils/formatarDataEHora';
import { capitalizeFirstLetter } from 'src/utils/string';

const StatusValidacoesGrid = ({ user, intl, validacoes }) => {
  console.log(validacoes);
  return (
    <Table size="sm" bordered>
      <thead>
        <tr>
          <th style={{ width: '25%' }}>{intl.formatMessage({ id: 'validador' })}</th>
          <th style={{ width: '25%' }}>{`${intl.formatMessage({ id: 'status' })} ${intl.formatMessage({ id: 'validacao' })}`}</th>
          <th style={{ width: '25%' }}>{intl.formatMessage({ id: 'label.observacao' })}</th>
          <th style={{ width: '25%' }}>{capitalizeFirstLetter(intl.formatMessage({ id: 'alteracao' }))}</th>
        </tr>
      </thead>
      <tbody>
        {validacoes &&
          validacoes.map(({ id, responsavel, status: { descricao }, observacao, dataAlteracao }) => (
            <tr key={id}>
              <td>{responsavel}</td>
              <td>{intl.formatMessage({ id: descricao })}</td>
              <td>{observacao}</td>
              <td>{formatarDataEHora(user ? user.idioma.toLowerCase() : 'pt-br', dataAlteracao)}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default injectIntl(connect(mapStateToProps)(StatusValidacoesGrid));
