import React from 'react';
import Edit from '../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import Calendario from './componentes/calendar';
import moment from 'moment';
import Feriado from './feriado';
import ContentManager from '../../componentes/content-manager';
import CheckBox from '../../componentes/checkbox';
import { Col, Row, Label } from 'reactstrap';
import Input from '../../componentes/input';
import Panel from '../../componentes/panel';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

let schema = Schema.object({
  id: Schema.number().label(''),
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  definirSabadoComoDiaUtil: Schema.string().label(
    <FormattedMessage id="label.definirSabadoComoDiaUtil" />
  ),
  definirDomingoComoDiaUtil: Schema.string().label(
    <FormattedMessage id="label.definirDomingoComoDiaUtil" />
  ),
  feriados: Schema.array().label(<FormattedMessage id="label.feriados" />),
});

@autobind
class CalendarioForm extends React.Component {
  static defaultProps = {
    locale: 'pt-BR',
    yearHeaderFormat: 'YYYY',
    dateFormat: 'DD MMMM',
  };
  constructor(props) {
    super(props);

    this.state = {
      date: this.moment().startOf('year'),
      anoCorrente: this.moment()
        .startOf('year')
        .format(this.props.yearHeaderFormat),
    };
  }

  handleDayClick(item) {
    let { model } = this.props;

    let allowEdit = model.getModel('allowEdit').value;

    if (allowEdit) {
      let feriado;
      if (!item.data) {
        //Novo feriado
        feriado = { data: item, descricao: '' };
      } else {
        //Editar feriado
        feriado = item;
      }

      ContentManager.addContent(
        <Feriado
          item={feriado}
          handleAtualizarFeriado={this.handleAtualizarFeriado}
        />
      );
    }
  }

  handleAtualizarFeriado(feriado, apagar) {
    let itens = this.props.model.getModel('feriados').value || [];

    let itemIndex = this.props.model.getModel('feriados').value
      ? this.props.model
          .getModel('feriados')
          .value.findIndex(
            (f) =>
              this.moment(f.data).format('DD-MM-YYYY') ==
              this.moment(feriado.data).format('DD-MM-YYYY')
          )
      : -1;

    if (itemIndex != -1) {
      if (apagar) {
        itens.splice(itemIndex, 1);
      } else {
        itens[itemIndex] = feriado;
      }
    } else {
      if (!apagar) {
        itens.push({
          ...feriado,
        });
      }
    }

    this.props.model.getModel('feriados').requestChange(itens);
  }

  moment() {
    const localMoment = moment.apply(null, arguments);

    if (this.props.locale.toLowerCase() == 'es-mx') {
      localMoment.locale('es');
    } else {
      localMoment.locale(this.props.locale);
    }

    return localMoment;
  }

  handleAtualizarAnoCorrente(ano) {
    this.setState({ anoCorrente: ano });
  }

  render() {
    let { model } = this.props;

    let allowEdit = model.getModel('allowEdit').value;
    let allowDelete = model.getModel('allowDelete').value;
    let feriados = model.getModel('feriados').value
      ? model.getModel('feriados').value.map((item) => ({
          date: this.moment(item.data),
          classNames: ['current'],
          component: ['day'],
          events: {
            onClick: this.handleDayClick.bind(this, item),
          },
        }))
      : [];

    let feriadosAnoCorrente = model.getModel('feriados').value
      ? model
          .getModel('feriados')
          .value.filter(
            (f) =>
              this.moment(f.data).format(this.props.yearHeaderFormat) ==
              this.state.anoCorrente
          )
      : [];

    return (
      <div>
        <Row>
          <Col md={8}>
            <Input
              autoFocus
              maxlength={200}
              model={model.getModel('descricao')}
              required
            />
          </Col>
          <Col md={4}>
            <Input
              maxlength={100}
              model={model.getModel('idIntegracao')}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <CheckBox model={model.getModel('definirSabadoComoDiaUtil')} />
          </Col>
          <Col md={4}>
            <CheckBox model={model.getModel('definirDomingoComoDiaUtil')} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Calendario
              weekNumbers={true}
              startDate={this.state.date}
              date={this.state.date}
              endDate={this.state.date.clone().endOf('year')}
              onRefreshYearCurrent={this.handleAtualizarAnoCorrente}
              mods={[
                ...feriados,
                {
                  component: ['day'],
                  events: {
                    onClick: this.handleDayClick,
                  },
                },
              ]}
            ></Calendario>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Panel header={<FormattedMessage id="label.feriados" />}>
              <Row>
                {feriadosAnoCorrente &&
                  feriadosAnoCorrente.map((item, index) => (
                    <Col md={6} style={{ marginBottom: 5 }} key={index}>
                      <span>
                        <Label
                          style={{
                            backgroundColor: '#777',
                            color: '#FFF',
                            marginRight: 10,
                            fontSize: 12,
                          }}
                        >
                          {this.moment(item.data).format(this.props.dateFormat)}
                        </Label>
                        {item.descricao}
                      </span>
                    </Col>
                  ))}
              </Row>
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }
}

@autobind
class CalendarioEdit extends React.Component {
  render() {
    return (
      <Edit
        url="/Calendario"
        title="calendario"
        formComponent={CalendarioForm}
        schema={schema}
        width={900}
        height={650}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    locale: state.user.locale,
  };
}

export default connect(mapStateToProps)(CalendarioEdit);
