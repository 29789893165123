import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import InputNumber from '../../../componentes/input-number';
import Button from '../../../componentes/button';
import Dialog from '../../../componentes/dialog';
import { keydown, KEYS } from '../../../utils/keydown-decorator';
import { FormattedMessage } from 'react-intl';

@autobind
class Numero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valor: 1,
    };
  }

  @keydown(KEYS.ESC)
  handleClose() {
    this.refs.dialog.close();
    setTimeout(() => {
      this.props.remove && this.props.remove();
    }, 300);
  }

  @keydown(KEYS.ENTER)
  handleSave() {
    this.props.onRequestClose && this.props.onRequestClose(this.state.valor, true);
    this.handleClose();
  }

  handleChangeValor(value) {
    this.setState({ valor: value });
  }

  render() {
    let actions = [
      <Button key="btn-number" type="primary" onClick={this.handleSave}>
        {<FormattedMessage id="label.salvar" />}
      </Button>,
    ];

    let { ...other } = this.props;

    return (
      <Dialog
        {...other}
        title={<FormattedMessage id="label.numero" />}
        actions={actions}
        width={500}
        height={200}
        padContent
        onRequestClose={this.handleClose}
        ref="dialog"
      >
        <Row>
          <Col md={12}>
            <InputNumber autoFocus model={{ label: <FormattedMessage id="label.numero" />, value: this.state.valor, requestChange: this.handleChangeValor }} />
          </Col>
        </Row>
      </Dialog>
    );
  }
}

export default Numero;
