import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Schema } from 'src/utils/form';

export default Schema.object({
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  status: Schema.string().required(),
  controle: Schema.string().required(),
  riscoConsequencia: Schema.object().label(),
});
