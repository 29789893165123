import Lookup from 'src/componentes/select2';
import Edit from '../paginas/tag/Edit';

const TagLookup = ({ podeEditar, ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/TagLookup"
    editComponent={podeEditar ? Edit : null}
    clearable={true}
    selectNewItem={false}
    {...rest}
  />
);

export default TagLookup;
