import React from 'react';
import { autobind } from 'core-decorators';
import moment from 'moment';
import qs from 'query-string';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';

import http from 'src/services/httpService';
import httpSelector from 'src/services/httpSelector';
import { Schema, Form } from 'src/utils/form';
import Edit from 'src/componentes/edit/edit';
import LoadingContainer from 'src/componentes/loading-container';
import history from 'src/history';
import Button from 'src/componentes/button';
import { Row, Col } from 'reactstrap';
import Panel from 'src/componentes/panel';
import ItemLookup from 'src/lookups/item';
import PeriodoData from '../../data/periodo-data';
import Arvore from './componentes/arvore';
import errorHandler from 'src/utils/error-handler';
import PeriodicidadeLookup from 'src/lookups/periodicidade';
import Card from 'src/componentes/panel-collapse';
import { capitalizeFirstLetter } from 'src/utils/string';
import Radio from 'src/componentes/radio';
import CheckBox from 'src/componentes/checkbox';
import ObjetivoLookup from 'src/lookups/objetivo';
import ChaveResultadoLookup from 'src/lookups/ChaveResultado';
import TipoOrdenacaoItem from 'src/componentes/caixa-item/components/tipo-ordenacao-item';
import useRelacionamentoItens from 'src/hooks/Score/useRelacionamentoItens';

import css from 'src/utils/css';
import { MODULOS } from 'src/utils/constants';
import { RelacionamentoItensContextProvider } from 'src/contexts/Score/RelacionamentoItensContext';
import MdiIcon from 'src/componentes/mdi-icon';

const classes = css`
  .optionsBox {
    border: 1px solid #dde6e9;
    padding: 5px 10px;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }
`;

let schema = Schema.object({
  itemArvore: Schema.array(),
  item: Schema.string(),
});

const filtroArvoreRelacionamentoId = 8;

@autobind
class RelacionamentoView extends React.Component {
  static defaultProps = {
    showToolbar: true,
    showButtonBack: true,
    tipoExibicaoId: 1,
    tipoAcumulacaoId: 1,
    openLastFilter: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      item: this.props.itemId && { id: this.props.itemId },
      objetivo: this.props.objetivoId && { id: this.props.objetivoId },
      kr: this.props.krId && { id: this.props.krId },
      filtroDefault: this.props.filtroDefault
        ? this.props.filtroDefault
        : {
            tipoFiltroData: { id: this.props.tipoFiltroDataId },
            dataInicio: this.props.dataInicio,
            dataTermino: this.props.dataTermino,
            periodicidade: this.props.periodicidadeId && {
              id: parseInt(this.props.periodicidadeId),
            },
            tipoExibicao: this.props.tipoExibicaoId && {
              id: this.props.tipoExibicaoId,
            },
            tipoAcumulacao: this.props.tipoAcumulacaoId && {
              id: this.props.tipoAcumulacaoId,
            },
            exibirImpactosIndiretos:
              this.props.exibirImpactosIndiretos != null
                ? this.props.exibirImpactosIndiretos === 'true' ||
                  this.props.exibirImpactosIndiretos === true
                  ? true
                  : false
                : true,
            exibirItensFormula:
              this.props.exibirItensFormula != null
                ? this.props.exibirItensFormula === 'true' ||
                  this.props.exibirItensFormula === true
                  ? true
                  : false
                : true,
            exibirDesdobramento:
              this.props.exibirDesdobramento != null
                ? this.props.exibirDesdobramento === 'true' ||
                  this.props.exibirDesdobramento === true
                  ? true
                  : false
                : true,
            exibirAcumulado:
              this.props.exibirAcumulado != null
                ? this.props.exibirAcumulado === 'true' ||
                  this.props.exibirAcumulado === true
                  ? true
                  : false
                : true,
            ocultarItensSemValores:
              (this.props.ocultarItensSemValores != null &&
                this.props.ocultarItensSemValores === 'true') ||
              this.props.ocultarItensSemValores === true,
            exibirMetaEmRelacaoRealizado:
              this.props.exibirMetaEmRelacaoRealizado != null
                ? this.props.exibirMetaEmRelacaoRealizado === 'true' ||
                  this.props.exibirMetaEmRelacaoRealizado === true
                  ? true
                  : false
                : false,
            exibirRiscos:
              this.props.exibirRiscos != null
                ? this.props.exibirRiscos === 'true' ||
                  this.props.exibirRiscos === true
                  ? true
                  : false
                : false,

            exibirProcessos:
              this.props.exibirProcessos != null
                ? this.props.exibirProcessos === 'true' ||
                  this.props.exibirProcessos === true
                  ? true
                  : false
                : false,
            tipoOrdenacaoId: this.props.tipoOrdenacaoId
              ? this.props.tipoOrdenacaoId
              : null,
          },
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    if (this.props.openLastFilter) {
      this.openLastFilter();
    } else {
      this.load();
    }
  }

  load() {
    let { item, filtroDefault, objetivo, kr } = this.state;
    let { tipoFiltroData, periodicidade, tipoOrdenacaoId } = filtroDefault;
    let itemId = item && item.id;
    let tipoFiltroDataId = tipoFiltroData && tipoFiltroData.id;
    let periodicidadeId = periodicidade && periodicidade.id;
    let itemIdApresentacao = this.props.id;

    tipoOrdenacaoId = this.props.openLastFilter
      ? tipoOrdenacaoId
      : this.props.tipoOrdenacaoId;
    itemId = itemId !== undefined ? itemId : itemIdApresentacao;

    const objetivoId = objetivo?.id;
    const krId = kr?.id;

    let qsPeriodicidade = qs.parse(window.location.search).periodicidadeId;
    if (qsPeriodicidade !== undefined) {
      periodicidadeId = qsPeriodicidade;
    }

    if (objetivoId) {
      httpSelector
        .getHttp(this.props.viewType)
        .post(`/objetivo/ObterFiltroRelacionamentos`, {
          id: objetivoId,
        })
        .then((response) => {
          if (response.data.objetivo) {
            this.setState({
              isLoading: false,
              objetivo: response.data.objetivo,
            });
          }
          this.refresh();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
        });
    } else if (krId) {
      httpSelector
        .getHttp(this.props.viewType)
        .post(`/chaveresultado/ObterFiltroRelacionamentos`, {
          id: krId,
        })
        .then((response) => {
          if (response.data.kr) {
            this.setState({
              isLoading: false,
              kr: response.data.kr,
            });
          }
          this.refresh();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
        });
    } else if (itemId) {
      httpSelector
        .getHttp(this.props.viewType)
        .post(`/Item/ObterFiltro`, {
          id: itemId,
          tipoFiltroDataId: tipoFiltroDataId,
          periodicidadeId: periodicidadeId,
        })
        .then((response) => {
          if (response.data.item) {
            this.setState({
              isLoading: false,
              item: response.data.item,
              filtroDefault: {
                ...this.state.filtroDefault,
                tipoFiltroData: response.data.tipoFiltroData,
                periodicidade:
                  !periodicidadeId || !isNaN(periodicidadeId)
                    ? response.data.periodicidade
                    : null,
                tipoOrdenacaoId: tipoOrdenacaoId,
              },
            });
          }
          this.refresh();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
        });
    }
  }

  openLastFilter() {
    httpSelector
      .getHttp(this.props.viewType)
      .post(
        `/FiltroColaborador/${filtroArvoreRelacionamentoId}/AbrirUltimoFiltro`,
        {}
      )
      .then((response) => {
        if (response.data && response.data.valor) {
          this.setState({
            ...this.state,
            filtroDefault: {
              ...response.data.valor,
            },
          });
        }
        this.load();
      });
  }

  handleSaveFilter() {
    http
      .post(`/FiltroColaborador/${filtroArvoreRelacionamentoId}/SalvarFiltro`, {
        filtroId: filtroArvoreRelacionamentoId,
        valor: {
          ...this.state.filtroDefault,
        },
      })
      .then((response) => {})
      .catch(() => {});
  }

  refresh() {
    const { item, filtroDefault, objetivo, kr } = this.state;
    const { selectedItem, setSelectedItemFromContext } = this.props;

    let {
      periodicidade,
      tipoFiltroData,
      dataInicio,
      dataTermino,
      exibirImpactosIndiretos,
      exibirItensFormula,
      exibirDesdobramento,
      exibirAcumulado,
      ocultarItensSemValores,
      tipoExibicao,
      exibirProcessos,
      exibirRiscos,
      tipoOrdenacaoId,
      tipoAcumulacao,
    } = filtroDefault;

    const itemId = item && item.id;
    const objetivoId = objetivo && objetivo.id;
    const krId = kr && kr.id;

    const periodicidadeId = periodicidade && periodicidade.id;

    const tipoExibicaoId = tipoExibicao && tipoExibicao.id;

    dataInicio = dataInicio
      ? dataInicio === 'undefined' || dataInicio === undefined
        ? null
        : moment(dataInicio).format('YYYY-MM-DDTHH:mm:ss')
      : null;
    dataTermino = dataTermino
      ? dataTermino === 'undefined' || dataTermino === undefined
        ? null
        : moment(dataTermino).format('YYYY-MM-DDTHH:mm:ss')
      : null;

    const tipoAcumulacaoId = tipoAcumulacao && tipoAcumulacao.id;

    if (itemId || objetivoId || krId) {
      this.setState({
        isLoading: true,
      });

      if (objetivoId) {
        httpSelector
          .getHttp(this.props.viewType)
          .post(`/objetivo/ObterArvoreRelacionamento`, {
            filtro: {
              objetivoId: objetivoId,
            },
            expandedChildItems: this.props.expandedChildItemsFromContext,
          })
          .then((response) => {
            this.setState({
              isLoading: false,
            });
            this.form.setValue(null, response.data);
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            errorHandler(error);
          });
      } else if (krId) {
        httpSelector
          .getHttp(this.props.viewType)
          .post(`/chaveresultado/ObterArvoreRelacionamento`, {
            filtro: {
              krId: krId,
            },
            expandedChildItems: this.props.expandedChildItemsFromContext,
          })
          .then((response) => {
            this.setState({
              isLoading: false,
            });
            this.form.setValue(null, response.data);
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            errorHandler(error);
          });
      } else if (itemId) {
        httpSelector
          .getHttp(this.props.viewType)
          .post(`/Item/ObterArvoreRelacionamento`, {
            filtro: {
              tipoFiltroData: tipoFiltroData,
              dataInicio: dataInicio,
              dataTermino: dataTermino,
              exibirImpactosIndiretos: exibirImpactosIndiretos,
              exibirItensFormula: exibirItensFormula,
              exibirAcumulado: exibirAcumulado,
              ocultarItensSemValores: ocultarItensSemValores,
              itemId: itemId,
              periodicidadeId: periodicidadeId,
              tipoExibicao: tipoExibicaoId && { id: tipoExibicaoId },
              exibirDesdobramento: exibirDesdobramento,
              exibirProcessos: exibirProcessos,
              exibirRiscos: exibirRiscos,
              objetivoId: objetivoId,
              krId: krId,
              tipoOrdenacaoId: tipoOrdenacaoId,
              tipoAcumulacao: {
                id: tipoAcumulacaoId ? tipoAcumulacaoId : 1,
              },
            },
            expandedChildItems: this.props.expandedChildItemsFromContext,
          })
          .then((response) => {
            this.setState({
              isLoading: false,
            });
            this.form.setValue(null, response.data);
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            errorHandler(error);
          });
      }

      if (
        !selectedItem ||
        (itemId && itemId !== selectedItem.id) ||
        (objetivoId && objetivoId !== selectedItem.id) ||
        (krId && krId !== selectedItem.id)
      ) {
        const id = objetivoId ? objetivoId : krId ? krId : itemId;
        if (setSelectedItemFromContext) setSelectedItemFromContext({ id });
      }
    }
  }

  handleClose(saved) {
    history.goBack();

    setTimeout(() => {
      if (saved) this.props.onRequestClose && this.props.onRequestClose();
    }, 300);
  }

  handleChangeItem(value) {
    this.setState({
      ...this.state,
      item: value,
      filtroDefault: {
        ...this.state.filtroDefault,
        periodicidade: value && value.periodicidade,
      },
    });
  }

  handleChangeObjetivo(value) {
    this.setState({
      ...this.state,
      objetivo: value,
      filtroDefault: {
        ...this.state.filtroDefault,
      },
    });
  }

  handleChangeKR(value) {
    this.setState({
      ...this.state,
      kr: value,
      filtroDefault: {
        ...this.state.filtroDefault,
      },
    });
  }

  handleChangeDesdobramento(value) {
    this.setState({
      ...this.state,
      filtroDefault: { ...this.state.filtroDefault, desdobramento: value },
    });
  }

  handleChangePeriodicidade(value) {
    this.setState({
      ...this.state,
      filtroDefault: { ...this.state.filtroDefault, periodicidade: value },
    });
  }

  handleTipoExibicao(value) {
    this.setState({
      ...this.state,
      filtroDefault: { ...this.state.filtroDefault, tipoExibicao: value },
    });
  }

  handleTipoAcumulacao(value) {
    this.setState({
      ...this.state,
      filtroDefault: { ...this.state.filtroDefault, tipoAcumulacao: value },
    });
  }

  handleChangeFiltro(field, value) {
    this.setState({
      ...this.state,
      filtroDefault: { ...this.state.filtroDefault, [field]: value },
    });
  }

  handleTipoOrdenacao(value) {
    this.setState({
      ...this.state,
      filtroDefault: { ...this.state.filtroDefault, tipoOrdenacaoId: value },
    });
  }

  handleFilter() {
    const { item, filtroDefault, objetivo, kr } = this.state;
    let {
      periodicidade,
      tipoFiltroData,
      dataInicio,
      dataTermino,
      exibirImpactosIndiretos,
      exibirItensFormula,
      exibirDesdobramento,
      exibirAcumulado,
      exibirMetaEmRelacaoRealizado,
      tipoExibicao,
      tipoAcumulacao,
      exibirRiscos,
      exibirProcessos,
      tipoOrdenacaoId,
    } = filtroDefault;

    const itemId = item && item.id;
    const objetivoId = objetivo && objetivo.id;
    const krId = kr && kr.id;
    const periodicidadeId = periodicidade && periodicidade.id;
    const tipoFiltroDataId = tipoFiltroData && tipoFiltroData.id;
    const tipoExibicaoId = tipoExibicao && tipoExibicao.id;
    const tipoAcumulacaoId = tipoAcumulacao && tipoAcumulacao.id;

    if (dataInicio && dataInicio !== 'undefined')
      dataInicio = new Date(dataInicio);

    if (dataTermino && dataTermino !== 'undefined')
      dataTermino = new Date(dataTermino);

    if (objetivo) {
      history.push({
        pathname: '/gerenciar/arvore',
        search: `?objetivoId=${objetivoId}&openLastFilter=false`,
      });
    } else if (kr) {
      history.push({
        pathname: '/gerenciar/arvore',
        search: `?krId=${krId}&openLastFilter=false`,
      });
    } else if (this.props.openLastFilter) {
      history.push({
        pathname: '/gerenciar/arvore',
        search: `?itemId=${itemId}&periodicidadeId=${periodicidadeId}&tipoFiltroDataId=${tipoFiltroDataId}&dataInicio=${dataInicio}&dataTermino=${dataTermino}&exibirImpactosIndiretos=${exibirImpactosIndiretos}&exibirItensFormula=${exibirItensFormula}&exibirAcumulado=${exibirAcumulado}&exibirMetaEmRelacaoRealizado=${exibirMetaEmRelacaoRealizado}&tipoExibicaoId=${tipoExibicaoId}&exibirDesdobramento=${exibirDesdobramento}
        &tipoAcumulacaoId=${tipoAcumulacaoId}&exibirRiscos=${exibirRiscos}&exibirProcessos=${exibirProcessos}&tipoOrdenacaoId=${tipoOrdenacaoId}`,
      });
    }

    //necessário setar novamente para limpar o redux já que qualquer alteração no filtro altera a ordenação das caixas
    const id = objetivoId ? objetivoId : krId ? krId : itemId;

    if (this.props.setSelectedItemFromContext)
      this.props.setSelectedItemFromContext({ id });

    this.load();
    if (!objetivo && !kr) {
      this.handleSaveFilter();
    }
  }

  render() {
    const {
      showToolbar,
      intl,
      modulosComprados,
      resources,
      ehRiscoAvaliaco,
      isConsultaExterna,
    } = this.props;
    const { isLoading, item, filtroDefault, objetivo, kr } = this.state;
    let {
      periodicidade,
      tipoFiltroData,
      dataInicio,
      dataTermino,
      tipoExibicao,
      tipoAcumulacao,
      exibirItensFormula,
      exibirImpactosIndiretos,
      exibirDesdobramento,
      exibirAcumulado,
      ocultarItensSemValores,
      filtroExpandido,
      exibirMetaEmRelacaoRealizado,
      exibirRiscos,
      exibirProcessos,
      exibirScoreRisco,
      tipoOrdenacaoId,
    } = filtroDefault;

    const model = this.form.getModel();

    const itemId = item && item.id;
    const objetivoId = objetivo && objetivo.id;
    const krId = kr && kr.id;
    const periodicidadeId = periodicidade && periodicidade.id;
    const tipoExibicaoId = tipoExibicao && tipoExibicao.id;
    const tipoAcumulacaoId = tipoAcumulacao && tipoAcumulacao.id;

    dataInicio = dataInicio
      ? dataInicio === 'undefined' || dataInicio === undefined
        ? null
        : moment(dataInicio).format('YYYY-MM-DDTHH:mm:ss')
      : null;
    dataTermino = dataTermino
      ? dataTermino === 'undefined' || dataTermino === undefined
        ? null
        : moment(dataTermino).format('YYYY-MM-DDTHH:mm:ss')
      : null;

    const optionsExibicao = [
      {
        id: 1,
        descricao: capitalizeFirstLetter(intl.formatMessage({ id: 'causas' })),
      },
      {
        id: 2,
        descricao: capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.impacto' })
        ),
      },
    ];

    const optionsAcumulado = [
      {
        id: 1,
        descricao: capitalizeFirstLetter(
          intl.formatMessage({ id: 'acumuladoYtd' })
        ),
      },
      {
        id: 2,
        descricao: capitalizeFirstLetter(
          intl.formatMessage({ id: 'acumuladoConformeFiltro' })
        ),
      },
    ];

    const possuiDesdobramento =
      item && item.desdobramento && item.desdobramento.id;
    const possuiModuloRisco = modulosComprados.find(
      (m) => m.id === 5 && m.ativo
    );
    const tituloRiscoAvaliacao = ehRiscoAvaliaco
      ? intl.formatMessage({ id: 'label.relacionamentos' })
      : '';

    return (
      <Edit renderFooter={false}>
        <LoadingContainer isLoading={isLoading}>
          {showToolbar && (
            <Card
              open={filtroExpandido}
              header={<FormattedMessage id="label.filtro" />}
            >
              {(objetivo || this.props.objetivoId) && (
                <Row>
                  <Col md={6}>
                    <ObjetivoLookup
                      clearable={false}
                      model={{
                        label: capitalizeFirstLetter(resources.objetivoOKR),
                        value: objetivo,
                        requestChange: this.handleChangeObjetivo,
                      }}
                    ></ObjetivoLookup>
                  </Col>
                  <Col md={3}>
                    <Button
                      onClick={this.handleFilter}
                      className="pull-right"
                      leftIcon="magnify"
                    >
                      <FormattedMessage id="label.filtrar"></FormattedMessage>
                    </Button>
                  </Col>
                </Row>
              )}
              {(kr || this.props.krId) && (
                <Row>
                  <Col md={6}>
                    <ChaveResultadoLookup
                      clearable={false}
                      model={{
                        label: capitalizeFirstLetter(resources.keyresult),
                        value: kr,
                        requestChange: this.handleChangeKR,
                      }}
                    ></ChaveResultadoLookup>
                  </Col>
                  <Col md={3}>
                    <Button
                      onClick={this.handleFilter}
                      className="pull-right"
                      leftIcon="magnify"
                    >
                      <FormattedMessage id="label.filtrar"></FormattedMessage>
                    </Button>
                  </Col>
                </Row>
              )}
              {(item || this.props.itemId) && (
                <>
                  <Row>
                    <Col md={4}>
                      <ItemLookup
                        clearable={false}
                        model={{
                          label: <FormattedMessage id="item" />,
                          value: item,
                          requestChange: this.handleChangeItem,
                        }}
                        disabled={isConsultaExterna}
                      ></ItemLookup>
                    </Col>
                    <Col md={2}>
                      <PeriodicidadeLookup
                        clearable
                        // data={{id: item.periodicidade && item.periodicidade.id}}
                        model={{
                          label: <FormattedMessage id="label.exibirComo" />,
                          value: periodicidade,
                          requestChange: this.handleChangePeriodicidade,
                        }}
                      ></PeriodicidadeLookup>
                    </Col>
                    <Col md={6}>
                      {tipoFiltroData && tipoFiltroData.descricao && (
                        <PeriodoData
                          clearable={false}
                          tipoFiltroData={tipoFiltroData}
                          dataInicio={dataInicio}
                          dataTermino={dataTermino}
                          onChange={this.handleChangeFiltro}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className={classes.optionsBox}>
                        <Radio
                          style={{ marginBottom: 0 }}
                          idField="id"
                          name="tipoExibicao"
                          labelField="descricao"
                          options={optionsExibicao}
                          model={{
                            label: <FormattedMessage id="label.tipoExibicao" />,
                            value: tipoExibicao,
                            requestChange: this.handleTipoExibicao,
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={classes.optionsBox}>
                        <Radio
                          style={{ marginBottom: 0 }}
                          idField="id"
                          name="tipoAcumulacao"
                          labelField="descricao"
                          options={optionsAcumulado}
                          hintItens={[
                            {
                              id: 1,
                              label: intl.formatMessage({
                                id: 'acumuladoYtdHint',
                              }),
                            },
                            {
                              id: 2,
                              label: intl.formatMessage({
                                id: 'acumuladoConformeFiltroHint',
                              }),
                            },
                          ]}
                          model={{
                            label: <FormattedMessage id="tipoAcumulacao" />,
                            value: tipoAcumulacao || { id: 1 },
                            requestChange: this.handleTipoAcumulacao,
                          }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <>
                        <tr>
                          <td>
                            <FormattedMessage id="ordenarRelacionamentos" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                                marginRight: 10,
                                marginTop: 10,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <TipoOrdenacaoItem
                                tipoOrdenacaoId={tipoOrdenacaoId}
                                handleTipoOrdenacao={this.handleTipoOrdenacao}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={12}>
                      <CheckBox
                        className="d-inline-block mr-2"
                        model={{
                          label: intl.formatMessage({
                            id: 'label.exibirItensFormula',
                          }),
                          value: exibirItensFormula,
                          requestChange: this.handleChangeFiltro.bind(
                            this,
                            'exibirItensFormula'
                          ),
                        }}
                      ></CheckBox>
                      {possuiDesdobramento && (
                        <CheckBox
                          className="d-inline-block mr-2"
                          model={{
                            label: intl.formatMessage({
                              id: 'label.exibirDesdobramento',
                            }),
                            value: exibirDesdobramento,
                            requestChange: this.handleChangeFiltro.bind(
                              this,
                              'exibirDesdobramento'
                            ),
                          }}
                        ></CheckBox>
                      )}
                      <CheckBox
                        className="d-inline-block mr-2"
                        model={{
                          label: intl.formatMessage({
                            id: 'label.exibirImpactosIndiretos',
                          }),
                          value: exibirImpactosIndiretos,
                          requestChange: this.handleChangeFiltro.bind(
                            this,
                            'exibirImpactosIndiretos'
                          ),
                        }}
                      ></CheckBox>
                      {possuiModuloRisco && (
                        <CheckBox
                          className="d-inline-block mr-2"
                          model={{
                            label: intl.formatMessage(
                              { id: 'exibirRiscos' },
                              { riscos: resources.riscos }
                            ),
                            value: exibirRiscos,
                            requestChange: this.handleChangeFiltro.bind(
                              this,
                              'exibirRiscos'
                            ),
                          }}
                        />
                      )}
                      {possuiModuloRisco && (
                        <CheckBox
                          className="d-inline-block mr-2"
                          model={{
                            label: intl.formatMessage(
                              { id: 'exibirProcessos' },
                              { processos: resources.processos }
                            ),
                            value: exibirProcessos,
                            requestChange: this.handleChangeFiltro.bind(
                              this,
                              'exibirProcessos'
                            ),
                          }}
                        />
                      )}
                      <CheckBox
                        className="d-inline-block mr-2"
                        model={{
                          label: intl.formatMessage({
                            id: 'mostrarNoGraficoOAcumulado',
                          }),
                          value: exibirAcumulado,
                          requestChange: this.handleChangeFiltro.bind(
                            this,
                            'exibirAcumulado'
                          ),
                        }}
                      ></CheckBox>
                      <CheckBox
                        className="d-inline-block mr-2"
                        model={{
                          label: intl.formatMessage({
                            id: 'ocultarItensSemValores',
                          }),
                          value: ocultarItensSemValores,
                          requestChange: this.handleChangeFiltro.bind(
                            this,
                            'ocultarItensSemValores'
                          ),
                        }}
                      ></CheckBox>
                      {this.props.ehGDRDisponivel && (
                        <CheckBox
                          className="d-inline-block"
                          model={{
                            label: intl.formatMessage({
                              id: 'exibirScoreRiscoPorItem',
                            }),
                            value: exibirScoreRisco,
                            requestChange: (vlr) =>
                              this.handleChangeFiltro('exibirScoreRisco', vlr),
                          }}
                        ></CheckBox>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <h6>
                        <FormattedMessage id="label.legendas" />
                      </h6>
                    </Col>
                  </Row>
                </>
              )}
              {(item || this.props.itemId) && (
                <Row>
                  <Col md={9}>
                    {exibirItensFormula && (
                      <span className="mr-5">
                        <span className="mr-2">
                          <FormattedMessage id="label.itensDaFormulaDeCalculo" />
                        </span>
                        <div
                          className="d-inline-block"
                          style={{ width: 30, borderTop: '1.5px solid #ccc' }}
                        ></div>
                      </span>
                    )}
                    {exibirDesdobramento && (
                      <span className="mr-5">
                        <span className="mr-2">
                          <FormattedMessage id="label.itendDesdobramento" />
                        </span>
                        <div
                          className="d-inline-block"
                          style={{ width: 30, borderTop: '1.5px dashed #ccc' }}
                        ></div>
                      </span>
                    )}
                    {exibirImpactosIndiretos && (
                      <span className="mr-5">
                        <span className="mr-2">
                          <FormattedMessage id="label.itensDeImpactoIndireto" />
                        </span>
                        <div
                          className="d-inline-block"
                          style={{
                            width: 30,
                            borderTop: '1.5px double  #5d9cec',
                          }}
                        ></div>
                      </span>
                    )}
                    <div className="flex mr-5 d-inline-block">
                      <span className="mr-2">
                        {intl.formatMessage(
                          { id: 'valorPrevio' },
                          { valorprevio: resources.valorprevio }
                        )}
                      </span>
                      <div className="d-inline-block">
                        <MdiIcon
                          className="mr-1"
                          icon="file-document-outline"
                        />
                      </div>
                    </div>
                    <div className="flex mr-5 d-inline-block">
                      <span className="mr-2">
                        {intl.formatMessage(
                          { id: 'tags' },
                          { tags: resources.tags }
                        )}
                      </span>
                      <div className="d-inline-block">
                        <MdiIcon className="mr-1" icon="tag" />
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <Button
                      onClick={this.handleFilter}
                      className="pull-right"
                      leftIcon="magnify"
                    >
                      <FormattedMessage id="label.filtrar"></FormattedMessage>
                    </Button>
                  </Col>
                </Row>
              )}
            </Card>
          )}
          <Row>
            <Col md={12}>
              <Panel header={tituloRiscoAvaliacao}>
                <div
                  style={{
                    overflow: 'auto',
                    minHeight: ehRiscoAvaliaco ? 170 : 500,
                  }}
                >
                  <div>
                    {model.getModel('arvore').value && (
                      <Arvore
                        filtro={{
                          tipoFiltroData: tipoFiltroData,
                          dataInicio: dataInicio,
                          dataTermino: dataTermino,
                          exibirImpactosIndiretos: exibirImpactosIndiretos,
                          exibirItensFormula: exibirItensFormula,
                          exibirRiscos: exibirRiscos,
                          exibirProcessos: exibirProcessos,
                          ocultarItensSemValores: ocultarItensSemValores,
                          exibirAcumulado: exibirAcumulado,
                          exibirDesdobramento: exibirDesdobramento,
                          itemId: itemId,
                          periodicidadeId: periodicidadeId,
                          tipoOrdenacaoId: tipoOrdenacaoId,
                          tipoExibicao: tipoExibicaoId && {
                            id: tipoExibicaoId,
                          },
                          exibirMetaEmRelacaoRealizado:
                            exibirMetaEmRelacaoRealizado,
                          tipoAcumulacao: {
                            id: tipoAcumulacaoId ? tipoAcumulacaoId : 1,
                          },
                          exibirScoreRisco:
                            exibirScoreRisco && this.props.ehGDRDisponivel,
                          objetivoId: objetivoId,
                          krId: krId,
                        }}
                        model={model.getModel('arvore')}
                        mode={this.props.mode}
                        isConsultaExterna={isConsultaExterna}
                        handleCloseModal={this.props.handleCloseModal}
                      />
                    )}
                  </div>
                </div>
              </Panel>
            </Col>
          </Row>
        </LoadingContainer>
      </Edit>
    );
  }
}

const Content = (props) => {
  const {
    expandedChildItems: expandedChildItemsFromContext,
    setSelectedItem: setSelectedItemFromContext,
    selectedItem,
  } = useRelacionamentoItens();

  return (
    <RelacionamentoView
      {...props}
      expandedChildItemsFromContext={expandedChildItemsFromContext}
      setSelectedItemFromContext={setSelectedItemFromContext}
      selectedItem={selectedItem}
    />
  );
};

const Main = (props) => {
  const intl = useIntl();

  const resources = useSelector((state) => state.user.termos);
  const modulosComprados = useSelector((state) => state.user.licenca.modulos);
  const viewType = useSelector((state) => state.viewType);

  const hasModule = (modId) =>
    modulosComprados?.find((m) => m.id == modId && m.ativo) != null;

  return (
    <RelacionamentoItensContextProvider>
      <Content
        {...props}
        intl={intl}
        resources={resources}
        modulosComprados={modulosComprados}
        ehGDRDisponivel={hasModule(MODULOS.GESTAO_RISCO)}
        viewType={viewType}
      />
    </RelacionamentoItensContextProvider>
  );
};

export default Main;
