import { Suspense, lazy, useState, useEffect } from 'react';
import LoadingScreen from 'src/componentes/LoadingScreen';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Button from 'src/componentes/button';
import MdiIcon from 'src/componentes/mdi-icon';
import { useIntl } from 'react-intl';
import http from 'src/services/httpService';
import { getConfiguration } from 'src/services/commonRequests';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';
import Avatar from 'src/componentes/item-multi-lookup';
import Input from 'src/componentes/input';
import Filtro from 'src/componentes/filtro';
import ContentManager from 'src/componentes/content-manager';
import { FILTROS } from 'src/utils/constants';
import { getFilter } from 'src/componentes/NewList';
import FiltroChip from 'src/componentes/filtro-chip';

const GraficoGauge = lazy(() =>
  import('src/paginas/item/valor/componentes/grafico-gauge')
);
import GraficoScorePeriodo from './score_por_periodo';
import SearchSchema from './searchSchema';
import Search from './search';
import ToleranciaRiscoBarra from '../../Avaliacao/AvaliacaoEditView/componentes/ToleranciaRiscoBarra';

const Graficos = ({
  model: { value },
  exibirGraficoScorePeriodo = true,
  exibirGraficoScorePeriodoGauge = true,
  resources,
  updater,
  selectedFilter,
  ...props
}) => {
  const getFilterDefaultChart = () => {
    return {
      valor: {
        tipoFiltroData: null, // { id: 7, descricao: intl.formatMessage({ id: 'desdeQuandoHaValores' }) },
        dataInicio: null,
        dataTermino: null,
      },
    };
  };

  const intl = useIntl();
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [avaliacaoGauge, setAvaliacaoGauge] = useState(null);
  const [searchModelState, setSearchModel] = useState(getFilterDefaultChart());
  const [grausDaMatrizRisco, setGrausDaMatriz] = useState([]);
  const [maiorScoreDaMatriz, setMaiorScoreDaMatriz] = useState([]);
  const [configuracao, setConfiguracao] = useState(null);
  const [toleranciaRisco, setToleranciaRisco] = useState({});
  const [valueId, setValueID] = useState(null);

  useEffect(() => {
    getConfiguration('ItemConfiguracaoRisco')(setConfiguracao)();
  }, []);

  useEffect(() => {
    if (value && value.id !== -1) refresh();
  }, [configuracao, updater, value]);

  const refresh = async (searchModel) => {
    if (configuracao && configuracao.habilitarCalculoDeScores) {
      if (!searchModel) {
        searchModel = await getFilter(FILTROS.RISCO_GRAFICOSCORE, true);
        if (!searchModel || !searchModel.valor?.tipoFiltroData)
          searchModel = getFilterDefaultChart();

        setSearchModel(searchModel);
      }

      if (
        isModelNotNull(value) &&
        value.id !== -1 &&
        configuracao &&
        configuracao.habilitarCalculoDeScores
      )
        /* Executa requisicao apenas se configuração habilitarCalculoDeScores */
        try {
          const {
            data: {
              avaliacoes,
              avaliacaoGauge,
              grausDaMatriz,
              maiorScoreDaMatriz,
              toleranciaRisco,
            },
          } = await http.post('/RiscoAvaliacao/ObterGraficoDeScore', {
            riscoId: value.id,
            model:
              searchModel && searchModel.valor
                ? searchModel.valor
                : searchModel,
          });

          setAvaliacoes(avaliacoes);
          setAvaliacaoGauge(avaliacaoGauge);
          setGrausDaMatriz(grausDaMatriz);
          setMaiorScoreDaMatriz(maiorScoreDaMatriz);
          setToleranciaRisco(toleranciaRisco);
        } catch (err) {
          errorHandler(err);
        }
    }
  };

  const handleSearch = (searchModel) => {
    setSearchModel(searchModel);

    if (searchModel && searchModel.valor) refresh(searchModel.valor);
  };

  const isModelNotNull = (modelValue) =>
    modelValue && Object.keys(modelValue).length > 0;

  const generateGaugeGraph = () => {
    return {
      chart: {
        lowerLimit: '0',
        upperLimit: maiorScoreDaMatriz,
        showValue: '1',
        theme: 'fusion',
        showToolTip: '0',
      },
      color:
        grausDaMatrizRisco &&
        grausDaMatrizRisco.length > 0 &&
        grausDaMatrizRisco.map(({ de, ate, grauRisco: { cor } }, idx) => ({
          minValue: de,
          maxValue:
            idx === grausDaMatrizRisco.length - 1 ? maiorScoreDaMatriz : ate,
          label: 'show',
          code: cor,
        })),
      value: avaliacaoGauge && avaliacaoGauge.score,
    };
  };

  const getAvatar = (users) =>
    users &&
    users.length > 0 && (
      <div>
        <div className={'ml-1 mb-1'}>
          {capitalizeFirstLetter(resources.responsaveis)}
        </div>
        <div>
          {users.map((user) => (
            <Avatar
              foto={user.fotoArquivo}
              onlyAvatar={false}
              disabled
              avatar
              label={user.nome}
              value={user.value}
            ></Avatar>
          ))}
        </div>
      </div>
    );

  const getOrganizacao = (area) => (
    <Input
      style={{ margin: 0 }}
      disabled
      model={{
        label: capitalizeFirstLetter(resources.area),
        value: area.nome,
      }}
    />
  );

  const hasValue = (value) => value && value !== '';
  
  const handleFiltroModal = () => {
    ContentManager.addContent(
      <Filtro
        {...props}
        /*modelValue={searchModelState}*/
        filtroId={FILTROS.RISCO_GRAFICOSCORE}
        usarFiltroSalvo={true}
        searchComponent={<Search />}
        searchSchema={SearchSchema}
        onSearch={handleSearch}
        showModal={true}
        selectedFilter={
          searchModelState ? searchModelState : getFilterDefaultChart()
        }
        ignorarCamposCustomizados={true}
      />
    );
  };

  const handleFiltroChip = () => {
    let searchModel =
      searchModelState && searchModelState.valor != null
        ? searchModelState.valor
        : searchModelState;
    return (
      <FiltroChip
        filtroId={FILTROS.RISCO_GRAFICOSCORE}
        onSearch={handleSearch}
        searchSchema={SearchSchema}
        selectedFilter={searchModel}
        onRemoveSaveFilter={true}
        dateFormat={'MM/YYYY'}
      />
    );
  };

  return (
    <Suspense fallback={<LoadingScreen />}>
      {configuracao &&
      configuracao.habilitarCalculoDeScores &&
      value &&
      value.id !== -1 ? (
        <div className={'card mt-3'}>
          <Row>
            <Col md={7}>
              <div
                className="col-md-12 d-flex"
                style={{ margin: '20px 0px 1px 20px' }}
              >
                <div className="col-md-4">
                  {value.area && getOrganizacao(value.area)}
                </div>
                <div
                  className="col-md-8"
                  style={{ margin: '0px 0px 0px 10px' }}
                >
                  {getAvatar(value.responsaveis)}
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className={'mt-3 mr-1 pull-right'}>{handleFiltroChip()}</div>
            </Col>
            <Col md={1}>
              <div className={'mt-3 mr-3 pull-right'}>
                <Button className="ml-2 d-inline" onClick={handleFiltroModal}>
                  <MdiIcon icon="filter" />
                </Button>
              </div>
            </Col>
          </Row>
          {grausDaMatrizRisco && grausDaMatrizRisco.length > 0 && (
            <Row>
              <Col md={hasValue(toleranciaRisco) ? 3 : 5}>
                {exibirGraficoScorePeriodoGauge && (
                  <GraficoGauge
                    backgroundTransparent={true}
                    grafico={generateGaugeGraph()}
                    height={250}
                  />
                )}
              </Col>
              <div className='pt-5' style={{flex: '1'}}>
                <ToleranciaRiscoBarra model={toleranciaRisco} />
              </div>
              <Col md={1} className={'mb-5 mt-5'} style={hasValue(toleranciaRisco) && {maxWidth: '10px'}}>
                <div
                  style={{
                    background: '#e7ecee',
                    height: '100%',
                    width: 2,
                    marginLeft: 10,
                  }}
                ></div>
              </Col>
              <Col md={6}>
                {exibirGraficoScorePeriodo && (
                  <GraficoScorePeriodo
                    handleSearch={handleSearch}
                    avaliacoes={avaliacoes}
                    grausDaMatriz={grausDaMatrizRisco}
                    maiorScoreDaMatriz={maiorScoreDaMatriz}
                  />
                )}
              </Col>
            </Row>
          )}
        </div>
      ) : null}
    </Suspense>
  );
};
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default connect(mapStateToProps)(Graficos);
