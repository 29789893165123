import Lookup from 'src/componentes/select2';

const TipoInicioPrevistoLookup = ({ ...rest }) => {
  return (
    <Lookup
      clearable
      labelKey="descricao"
      valueKey="id"
      url="/TipoInicioPrevistoLookup"
      {...rest}
    />
  );
};

export default TipoInicioPrevistoLookup;
