import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import qs from 'query-string';

import Edit from 'src/paginas/item/edit';
import ContentManager from 'src/componentes/content-manager';
import CriticalValidacaoRestricao from 'src/paginas/item/critical-validacao-restricao';
import Schema from './schema';

@autobind
class ObjetivoEdit extends React.Component {
  static defaultProps = {
    showDelete: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.location && qs.parse(this.props.location.search).id,
    };
  }

  handleSaved(data) {
    this.setState({ id: data.id });
  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <CriticalValidacaoRestricao
        url={this.props.urlValidarRestricoes}
        urlExclusao={this.props.urlAtualizarStatusExcluido}
        urlList={this.props.urlList}
        urlEdit={this.props.urlEdit}
        item={item}
        onClose={this.handleClose}
        isEdit={true}
      />
    );
  }

  render() {
    let {
      showDelete,
      showPrompt,
      resources,
      urlList,
      urlEdit,
      urlController,
      tipoItemId,
      id,
    } = this.props;

    let activeTab =
      this.props.location && qs.parse(this.props.location.search).activeTab;

    return (
      <Edit
        urlList={urlList}
        urlEdit={urlEdit}
        url={urlController}
        parameters={{ tipoId: tipoItemId }}
        titleField="nome"
        title={this.props.title}
        schema={Schema}
        handleSaved={this.handleSaved}
        activeTab={activeTab}
        id={id}
        tipoId={tipoItemId}
        showDelete={showDelete}
        showPrompt={showPrompt}
        onDeleting={this.handleDeleteClick}
        resources={resources}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default connect(mapStateToProps)(ObjetivoEdit);
