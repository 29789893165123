import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import MdiIcon from 'src/componentes/mdi-icon';
import Table from './Table';

const Container = ({
  item,
  estratificacoesModel,
  contadorInicial = 1,
  maxTable = 3,
  allowEdit = true,
}) => {
  const intl = useIntl();
  const [count, setCount] = useState(0);

  const tablesArray = Array(count + contadorInicial).fill(0);

  return (
    <div>
      {tablesArray?.map((_, index) => {
        const code = index + 1;

        return (
          <div>
            <Table
              code={code}
              item={item}
              estratificacoesModel={estratificacoesModel}
              estratificacoesCode={
                estratificacoesModel.value &&
                estratificacoesModel.value.length > 0 &&
                estratificacoesModel.value.filter(
                  (x) => x.codigoTabela === code
                )
              }
              allowEdit={allowEdit}
            />
            <hr className="my-4"></hr>
          </div>
        );
      })}
      {count + contadorInicial < maxTable && allowEdit && (
        <div
          onClick={() => setCount((prev) => prev + 1)}
          className="d-flex justify-content-center align-items-center text-primary cursor-pointer"
        >
          <MdiIcon
            size={24}
            className="cursor-pointer mr-2"
            icon="table-plus"
          />
          {intl.formatMessage({ id: 'novaTabela' })}
        </div>
      )}
    </div>
  );
};

export default Container;
