import Lookup from 'src/componentes/select2';

const TipoPlanoContasProjetoLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/TipoPlanoContasProjetoLookup"
    {...rest}
  />
);

export default TipoPlanoContasProjetoLookup;
