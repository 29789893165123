import { useState, useEffect } from 'react';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import LoadingContainer from 'src/componentes/loading-container';
import DragScroll from 'src/componentes/dragscroll';
import './style/double-tree.scss';
import Info from 'src/componentes/info';
import { UncontrolledTooltip } from 'reactstrap';

function GraficoBowTieView({ riscoId, saved, ...props }) {
  const [valores, setValores] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const obterValores = async () => {
    try {
      setIsLoading(true);

      const response = await http.post('/Risco/ObterBowTie', {
        riscoId: riscoId,
      });

      setIsLoading(false);
      setValores(response.data);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    obterValores();
  }, [riscoId, saved]);

  const renderPerigo = (i) => (
    <div
      style={{
        width: 140,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: -120,
      }}
    >
      <div
        style={{
          display: 'flex',
          background: 'yellow',
          padding: 10,
          width: 60,
          height: 61,
          fontSize: 10,
          fontWeight: 'bold',
          transform: 'rotate(45deg)',
          zIndex: 2,
        }}
      >
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            transform: 'rotate(-45deg)',
            fontSize: 10,
          }}
        >
          {i?.nome}
        </div>
      </div>
    </div>
  );

  const renderRisco = (i) => (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'relative', zIndex: 2 }}>
        {i.perigo && renderPerigo(i.perigo)}
        <div
          style={{
            display:'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            background: 'red',
            color: 'white',
            padding: 10,
            width: 140,
            height: 140,
            fontSize: 12,
            fontWeight: 'bold',
          }}
        >
          {i?.nome}
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          top: -30,
          left: 69.5,
          height: valores.perigo ? 35 : 0,
          borderRight: '1px solid #ccc',
          zIndex: 1,
        }}
      ></div>
    </div>
  );

  const renderCausa = (i) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'white',
          border: '2px solid blue',
          padding: 10,
          minWidth: 150,
          maxWidth: 300,
          height: 90,
          fontSize: 12,
          fontWeight: 'bold',
          zIndex: 2,
        }}
      >
        {i?.nome}
      </div>
      {i.controles && i.controles.length > 0 && (
        <>
          {i.controles.map((c) => renderControle(c, { marginLeft: 20 }))}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              borderTop: '1px solid #ccc',
              width: '100%',
              zIndex: 1,
            }}
          ></div>
        </>
      )}
    </div>
  );

  const renderConsequencia = (i) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {i.controles && i.controles.length > 0 && (
        <>
          {i.controles.map((c) => renderControle(c, { marginRight: 20 }))}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              borderTop: '1px solid #ccc',
              minWidth: '100%',
              zIndex: 1,
            }}
          ></div>
        </>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'white',
          border: '2px solid red',
          padding: 10,
          minWidth: 150,
          maxWidth: 300,
          height: 90,
          fontSize: 10,
          fontWeight: 'bold',
          zIndex: 2,
        }}
      >
        {i?.nome}
      </div>
    </div>
  );

  const renderControle = (i, style) => (
    <div style={{ zIndex: 2 }}>
      <div
        id={'controle-' + i.id}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'white',
          border: '2px solid gray',
          padding: 10,
          minWidth: 130,
          maxWidth: 400,
          height: 70,
          fontSize: 12,
          fontWeight: 'bold',
          zIndex: 1,
          ...style,
        }}
      >
        {i?.nome.length > 50 ? i?.nome.slice(0, 50).concat('...') : i?.nome}
      </div>

      {i?.nome.length > 50 && (
        <UncontrolledTooltip
          placement={'auto'}
          target={'controle-' + i.id}
          style={{
            maxWidth: 800,
            textAlign: 'center',
          }}
        >
          {i?.nome}
        </UncontrolledTooltip>
      )}
    </div>
  );

  const renderItem = (i) => {
    const gettipo = () => {
      const tipo = i?.tipo;

      switch (tipo) {
        case 10:
          return renderRisco(i);
        case 20:
          return renderCausa(i);
        case 30:
          return renderConsequencia(i);
        case 40:
          return renderControle(i);
      }
    };

    return gettipo();
  };

  const renderItemTree = (i, className = '') => {
    const { causas, consequencias } = i || {};

    return (
      <div className={`item-arvore-duplo ${className}`}>
        {causas && causas.length > 0 && <div className="sub-itens-left">{causas.map((c) => renderItemTree(c, 'item-arvore-duplo-left'))}</div>}
        {renderItem(i)}
        {consequencias && consequencias.length > 0 && (
          <div className="sub-itens-right">{consequencias?.map((c) => renderItemTree(c, 'item-arvore-duplo-right'))}</div>
        )}
      </div>
    );
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <DragScroll overflowY="inherit" height="auto" width="100%">
        {renderItemTree(valores)}
      </DragScroll>
    </LoadingContainer>
  );
}

export default GraficoBowTieView;
