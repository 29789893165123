import Lookup from 'src/componentes/select-async';
import ChecklistList from 'src/paginas/gestao-risco/auditoria/CheckList/ChecklistList';

const ModeloChecklistLookup = ({...rest}) => {

  return (
    <Lookup
      labelKey="nome"
      valueKey="id"
      showSearch={true}
      showNew={false}
      clearable={true}
      url="/ModeloChecklistLookup"
      searchComponent={ChecklistList}
      {...rest}
    />
  );
};

export default ModeloChecklistLookup;