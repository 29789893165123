import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  responsavel: Schema.string().label(),
  area: Schema.string(),
  areasSubordinadas: Schema.string(),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
});
