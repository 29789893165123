import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../componentes/list';
import ListColumn from '../../componentes/list/list-column';
import RelatorioAcompanhamentoEdit from './edit';
import { Badge } from 'reactstrap';
import SvgIcon from '../../componentes/svg-icon';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import ContentManager from '../../componentes/content-manager';
import Search from './search';
import SearchSchema from './search-schema';
import DropdownItem from '../item/dropdown';
import Mensagem from '../mensagem/modal';
import { FormattedMessage } from 'react-intl';
import qs from 'query-string';
import { Form } from '../../utils/form';
import history from '../../history';
import http from 'src/services/httpService';
import errorHandler from '../../utils/error-handler';
import LoadingContainer from '../../componentes/loading-container';
import { prepareHtml } from '../../utils/html';

@autobind
class RelatorioAcompanhamentoViewList extends React.Component {
  static defaultProps = {
    select: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      saved: Math.random(),
      responsaveis:
        window.location &&
        qs.parse(window.location.search).responsaveis &&
        JSON.parse(
          decodeURIComponent(qs.parse(window.location.search).responsaveis)
        ),
      statusId:
        window.location && qs.parse(window.location.search).statusId
          ? qs.parse(window.location.search).statusId
          : null,
      statusDescricao:
        window.location && qs.parse(window.location.search).statusDescricao
          ? qs.parse(window.location.search).statusDescricao
          : null,
      relatorioId:
        window.location && qs.parse(window.location.search).relatorioId
          ? qs.parse(window.location.search).relatorioId
          : null,
      parametroUrl: null,
      isLoading: true,
      configuracoes: null,
      areas:
        window.location &&
        qs.parse(window.location.search).areas &&
        JSON.parse(decodeURIComponent(qs.parse(window.location.search).areas)),
      tipoFiltroDataId:
        window.location && qs.parse(window.location.search).tipoFiltroDataId
          ? qs.parse(window.location.search).tipoFiltroDataId
          : null,
      tipoFiltroDataDescricao:
        window.location &&
        qs.parse(window.location.search).tipoFiltroDataDescricao
          ? qs.parse(window.location.search).tipoFiltroDataDescricao
          : null,
      dataInicio:
        window.location && qs.parse(window.location.search).dataInicio
          ? qs.parse(window.location.search).dataInicio
          : null,
      dataTermino:
        window.location && qs.parse(window.location.search).dataTermino
          ? qs.parse(window.location.search).dataTermino
          : null,
    };
    this.form = new Form({ component: this, schema: SearchSchema });
  }

  refresh() {
    this.setState({
      saved: Math.random(),
    });
  }

  componentDidMount() {
    http
      .post(`/RelatorioAcompanhamento/HabilitarColunas`, {})
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            configuracoes: response.data,
          },
          () => {
            this.atualizarParametros();
          }
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });
  }

  renderItem(item) {
    return (
      <DropdownItem
        tabIndex="-1"
        item={item.item}
        handleCloseModal={this.props.handleCloseModal}
      ></DropdownItem>
    );
  }

  renderStatus(item) {
    return (
      item.status && (
        <Badge className="text-white" style={{ background: item.status.cor }}>
          {item.status.descricao}
        </Badge>
      )
    );
  }

  renderFarol(item) {
    return (
      item.farol && (
        <SvgIcon
          title={item.farol.legenda}
          icon={item.farol.icone}
          color={item.farol.cor}
          colorHover={item.farol.cor}
          size={20}
        ></SvgIcon>
      )
    );
  }

  renderFarolAcumulado(item) {
    return (
      item.farolAcumulado && (
        <SvgIcon
          title={item.farolAcumulado.legenda}
          icon={item.farolAcumulado.icone}
          color={item.farolAcumulado.cor}
          colorHover={item.farolAcumulado.cor}
          size={20}
        ></SvgIcon>
      )
    );
  }

  handleMensagemClick(item) {
    ContentManager.addContent(
      <Mensagem
        relatorioAcompanhamentoId={item.id}
        item={item && item.item}
        fromItem
        tipoLookup="relatorioAcompanhamento"
      />
    );
  }

  atualizarParametros() {
    if (window.location.search) {
      this.setState({
        parametroUrl: {
          ...(this.state.responsaveis && {
            responsaveis: this.state.responsaveis,
          }),
          ...(this.state.statusId && {
            statusRelatorioAcompanhamento: {
              id: this.state.statusId,
              descricao: this.state.statusDescricao,
            },
          }),
          ...(this.state.relatorioId && {
            relatorioId: this.state.relatorioId,
          }),
          ...(this.state.areas && {
            areas: this.state.areas,
          }),
          ...(this.state.tipoFiltroDataId && {
            tipoFiltroData: {
              id: this.state.tipoFiltroDataId,
              descricao: this.state.tipoFiltroDataDescricao,
            },
          }),
          ...(this.state.dataInicio && this.state.dataInicio !== 'null' && this.state.dataInicio !== 'undefined' ? { dataInicio: this.state.dataInicio } : {}),
          ...(this.state.dataTermino && this.state.dataTermino !== 'null' && this.state.dataTermino !== 'undefined' ? { dataTermino: this.state.dataTermino } : {}),
        },
      });
      let parametros = this.state.parametroUrl;
      return parametros;
    }
  }

  encodeQueryData(data) {
    var ret = [];
    for (let d in data)
      if (data[d]) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    return ret.join('&');
  }

  handleChangeUrl(model) {
    const data = {
      statusId:
        model.statusRelatorioAcompanhamento &&
        model.statusRelatorioAcompanhamento.id,
      statusDescricao:
        model.statusRelatorioAcompanhamento &&
        model.statusRelatorioAcompanhamento.descricao,
      responsaveis:
        model.responsaveis &&
        encodeURIComponent(JSON.stringify(model.responsaveis)),
    };

    window.location.href.replace({
      pathname: `/gerenciar/relatorio-acompanhamento`,
      search: this.encodeQueryData(data),
    });
    this.refresh();
  }

  renderTexto(field, item) {
    let texto = '';
    if (field === 'planejado') texto = item.planejado;
    else if (field === 'executado') texto = item.executado;
    else if (field === 'fatos') texto = item.fatos;
    else if (field === 'pontosProblematicos') texto = item.causas;
    else if (field === 'classificacao') texto = item.classificacao.descricao;
    return <div dangerouslySetInnerHTML={prepareHtml(texto)}></div>;
  }

  render() {
    let {
      resources,
      intl,
      params,
      forceSelectedFilter,
      showNew = false,
    } = this.props;

    let { parametroUrl } = this.state;
    let createChildItems = [
      {
        label: <FormattedMessage id="mensagem" />,
        icon: 'comment-text-outline',
        visible: () => true,
        onClick: this.handleMensagemClick,
      },
    ];

    let configuracoes = this.state.configuracoes;

    let showList = false;
    if (window.location.search != null && parametroUrl != null) {
      showList = true;
    } else if (!window.location.search) {
      showList = true;
    }

    let ehPendentes =
      this.state &&
      ((this.state.responsaveis && this.state.responsaveis.length > 0) ||
        this.state.relatorioId != null)
        ? true
        : false;

    return (
      <LoadingContainer isLoading={this.state.isLoading}>
        {configuracoes != null && showList && (
          <List
            defaultFilter={this.props.defaultFilter}
            refreshOnClose={true}
            select={this.props.select}
            showModal={this.props.select}
            onChangeUrl={
              !this.props.select &&
              this.state.relatorioId == null &&
              this.handleChangeUrl
            }
            modelValue={
              ehPendentes
                ? parametroUrl
                : this.props.parameters
                ? this.props.parameters
                : params
            }
            url="/RelatorioAcompanhamento"
            saved={this.state.saved}
            editComponent={RelatorioAcompanhamentoEdit}
            pageSize={20}
            tag={ehPendentes ? 2011 : 2006}
            filtroId={25}
            usarFiltroSalvo={!ehPendentes}
            openLastFilter={!ehPendentes}
            searchComponent={<Search />}
            searchSchema={SearchSchema}
            showSearch={true}
            showNew={showNew}
            sort="UltimaVersao.Status"
            parameters={
              this.props.parameters != null ? this.props.parameters : params
            }
            createChildItems={createChildItems}
            showEstatistica={true}
            handleCloseModal={this.props.handleCloseModal}
            forceSelectedFilter={forceSelectedFilter}
            {...this.props}
          >
            <ListColumn
              headerText={intl.formatMessage({ id: 'status' })}
              valueField="status"
              valueFunction={this.renderStatus}
              sortField="UltimaVersao.Status"
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'item' })}
              valueField="item"
              valueFunction={this.renderItem}
              handleCloseModal={this.props.handleCloseModal}
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.area)}
              valueField="area"
              sortField="Valor.Item.UltimaVersao.Area.Nome"
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.responsaveis)}
              valueField="responsaveis"
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'periodo' })}
              valueField="periodo"
              sortField="Valor.UltimaVersao.DataPeriodo"
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.meta)}
              valueField="meta"
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'realizado' })}
              valueField="realizado"
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'desvio' })}
              valueField="desvio"
              sortField="Valor.UltimaVersao.Desvio"
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.farol)}
              valueField="farol"
              valueFunction={this.renderFarol}
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.farolAcumulado)}
              valueField="farolAcumulado"
              valueFunction={this.renderFarolAcumulado}
            />
            <ListColumn
              visible={configuracoes && configuracoes.habilitarPlanejado}
              minWidth={200}
              maxWidth={200}
              headerText={capitalizeFirstLetter(resources.planejado)}
              valueField="planejado"
              valueFunction={this.renderTexto.bind(this, 'planejado')}
            />
            <ListColumn
              visible={configuracoes && configuracoes.habilitarExecutado}
              minWidth={200}
              maxWidth={200}
              headerText={capitalizeFirstLetter(resources.executado)}
              valueField="executado"
              valueFunction={this.renderTexto.bind(this, 'executado')}
            />
            <ListColumn
              visible={configuracoes && configuracoes.habilitarFatos}
              minWidth={200}
              maxWidth={200}
              headerText={capitalizeFirstLetter(resources.fatos)}
              valueField="fatos"
              valueFunction={this.renderTexto.bind(this, 'fatos')}
            />
            <ListColumn
              visible={
                configuracoes && configuracoes.habilitarPontosProblematicos
              }
              minWidth={200}
              maxWidth={200}
              headerText={capitalizeFirstLetter(resources.pontosproblematicos)}
              valueField={capitalizeFirstLetter(resources.pontosproblematicos)}
              valueFunction={this.renderTexto.bind(this, 'pontosProblematicos')}
            />
            <ListColumn
              visible={configuracoes && configuracoes.habilitarAcoes}
              minWidth={200}
              maxWidth={200}
              headerText={capitalizeFirstLetter(resources.acoes)}
              valueField="acoes"
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'tipo' })}
              valueField="descricaoTipoRelatorio"
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'label.classificacao' })}
              valueField="classificacao"
              valueFunction={this.renderTexto.bind(this, 'classificacao')}
              sortField="classificacao"
            />
          </List>
        )}
      </LoadingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(
  connect(mapStateToProps)(RelatorioAcompanhamentoViewList)
);
