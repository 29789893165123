import React from 'react';

import VerticalArrow from './vertical-arrow';
import MultilineText from './multiline-text';
import { configs } from './root-arrow';

export default class HorizontalArrow extends React.Component {
  static measureLabel(props) {
    return MultilineText.measure({
      width: 120,
      text: props.item.descricao || '',
      fontSize: configs.getFontSize(props.level),
      fontFamily: configs.fontFamily,
    });
  }

  static measureLegs(props) {
    let item = props.item;
    let level = props.level;
    let width = 0;
    let height = 0;
    if (item.itens) {
      for (let i = 0; i < item.itens.length; i++) {
        let subItem = item.itens[i];
        let subSize = VerticalArrow.measure({
          item: subItem,
          level: level + 1,
        });
        width += subSize.width + (i < item.itens.length - 1 ? configs.getLegsGap(level) : 0);
        height = Math.max(height, subSize.height);
      }
    }
    return { width, height };
  }

  static measure(props) {
    let level = props.level;
    let labelSize = HorizontalArrow.measureLabel(props);
    let legsSize = HorizontalArrow.measureLegs(props);
    return {
      width: labelSize.width + configs.getLabelGap(level) + configs.getArrowPadding(level) * 2 + legsSize.width,
      height: Math.max(labelSize.height, legsSize.height * 2),
    };
  }

  render() {
    let { item, x, y, level, handleSaveCausaFundamental, widgetId } = this.props;

    let labelMetrics = HorizontalArrow.measureLabel(this.props);

    let children = [];

    let arrowWidth = configs.getArrowPadding(level) * 2;

    const extraOffset = 10;

    if (item.itens) {
      let legsSize = HorizontalArrow.measureLegs(this.props);
      let legX = -configs.getArrowPadding(level) - legsSize.width - extraOffset;
      for (let i = 0; i < item.itens.length; i++) {
        let subItem = item.itens[i];
        let subItemSize = VerticalArrow.measure({
          item: subItem,
          level: level + 1,
        });
        children.push(
          <VerticalArrow
            key={i}
            item={subItem}
            x={legX}
            y={0}
            width={subItemSize.width}
            height={legsSize.height}
            level={level + 1}
            handleSaveCausaFundamental={handleSaveCausaFundamental}
            guid={item.guid}
            ehCausaFundamental={item.causaFundamental}
            widgetId={widgetId}
          />
        );

        legX += subItemSize.width + (i < item.itens.length - 1 ? configs.getLegsGap(level) + extraOffset : 0);
        arrowWidth += subItemSize.width + (i < item.itens.length - 1 ? configs.getLegsGap(level) : 0);
      }
    }

    return (
      <g transform={`translate(${x}, ${y})`}>
        {children}

        <line
          x1={0 - arrowWidth}
          y1={0}
          x2={0 - configs.getMarkerSize(level)}
          y2={0}
          strokeWidth={configs.getStrokeWidth(level)}
          stroke="black"
          markerEnd="url(#head)"
        />

        <MultilineText
          text={item.descricao || ''}
          x={0 - arrowWidth - configs.getLabelGap(level) - labelMetrics.width}
          y={0}
          width={labelMetrics.width}
          height={labelMetrics.height}
          fontFamily={configs.fontFamily}
          fontSize={configs.getFontSize(level)}
          verticalAlign="middle"
          handleSaveCausaFundamental={handleSaveCausaFundamental}
          guid={item.guid}
          ehCausaFundamental={item.causaFundamental}
          widgetId={widgetId}
        />
      </g>
    );
  }
}
