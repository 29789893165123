import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import LoadingContainer from 'src/componentes/loading-container';
import http from 'src/services/httpService';
import useAuth from 'src/hooks/useAuth';
import Grafico from 'src/paginas/item/valor/componentes/grafico';
import TipoPlanoContasProjetoLookup from 'src/lookups/TipoPlanoContasProjetoLookup';

function GraficoCurvaS({ projetoId, tituloPersonalizado }) {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [tipoPlanoContas, setTipoPlanoContas] = useState(null);

  const getData = async () => {
    setIsLoading(true);

    try {
      const response = await http.post(
        '/projeto/ObterGraficoCurvaSFinanceira',
        {
          tipoPlanoContasId: tipoPlanoContas.id,
          projetoId: projetoId,
        }
      );
      setData(response.data || {});
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [tipoPlanoContas]);

  const renderContent = () => {
    if (!data) return '';
    return (
      <Grafico
        tituloPersonalizado={tituloPersonalizado}
        grafico={data.grafico}
        height={320}
      />
    );
  };

  const handleChangeTipoPlanoContas = (value) => {
    setTipoPlanoContas(value);
  };

  return (
    <>
      <div
        style={{
          left: '0',
          maxWidth: '250px',
          maxHeight: '50px',
          padding: '2px 2px',
        }}
      >
        <TipoPlanoContasProjetoLookup
          model={{
            value:
              tipoPlanoContas != null
                ? tipoPlanoContas
                : {
                    id: 1,
                    nome: intl.formatMessage({ id: 'label.custeio' }),
                  },
            requestChange: (value) => handleChangeTipoPlanoContas(value),
          }}
        />
      </div>
      <LoadingContainer isLoading={isLoading}>
        <div>{data && renderContent()}</div>
      </LoadingContainer>
    </>
  );
}

export default GraficoCurvaS;
