import React from 'react';
import { autobind } from 'core-decorators';
import { keydown, KEYS } from 'src/utils/keydown-decorator';
import http from 'src/services/httpService';
import Button from '../../componentes/button';
import Input from '../../componentes/input';
import ContentManager from '../../componentes/content-manager';
import MdiIcon from '../../componentes/mdi-icon';
import Numero from './componentes/numero';
import DataInicioTermino from './componentes/inicio-termino';
import Alert from '../../componentes/message-box/alert';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonToolbar,
  ButtonGroup,
} from 'reactstrap';
import ItemLookup from '../../lookups/item';
import PontoFaixaResultadoLookup from '../../lookups/ponto-faixa-resultado';
import FuncaoLookup from '../../lookups/funcao';
import CategoriaLookup from '../../lookups/categoria';
import Panel from '../../componentes/panel';
import CondicionanteLookup from '../../lookups/condicionante';
import ColaboradorSimplificadoLookup from '../../lookups/ColaboradorSimplificadoLookup';
import ItemOrganizacaoLookup from '../../lookups/item-organizacao';
import FormulaPadraoLookup from '../../lookups/formula-padrao';
import Edit from '../../componentes/edit/edit-dialog';
import { MODULOS } from 'src/utils/constants';
import CriteriosAvaliacaoLookup from 'src/lookups/CriteriosAvaliacaoLookup';

@autobind
class Editor extends React.Component {
  static defaultProps = {
    isItem: true,
  };
  constructor(props) {
    super(props);
    this.isNew = this.props.formula == null ? true : false;
    this.saved = false;
    this.state = {
      validationState: null,
      messageError: '',
      id: this.props.formula ? this.props.formula.id : 0,
      tipo: this.props.tipo ? this.props.tipo : null,
      exluido: false,
      alterado: this.props.formula ? this.props.formula.alterado : false,
      formula: this.props.formula || null,
      currentIndex: this.props.currentIndex,
      funcaoFormula: 'Meta',
      funcaoFormulaTraducao: this.traduzTermo('Meta'),
      desdobramento: null,
      utilizarCurvaS: false,
      esconderEditor: false,
      permitirDigitacaoValores: false,
    };
  }

  componentDidMount() {
    http
      .post(`/ItemConfiguracao/ObterConfiguracaoValorProjeto`, {})
      .then((response) => {
        this.setState({
          utilizarCurvaS: response.data.utilizarCurvaS,
          permitirDigitacaoValores: response.data.permitirDigitacaoValores,
        });
      });

    let tipo = this.props.parameters.tipoValorId;
    let tipoItemId = this.props.tipoItemId;

    switch (tipo) {
      case 1:
        this.setState({ funcaoFormula: 'Meta' });
        this.setState({ funcaoFormulaTraducao: this.traduzTermo('Meta') });
        break;
      case 2:
        if (tipoItemId === 3) {
          this.setState({ funcaoFormula: 'Desempenho' });
          this.setState({
            funcaoFormulaTraducao: this.traduzTermo('Desempenho'),
          });
        } else {
          if (this.props.isItem) {
            this.setState({ funcaoFormula: 'Meta' });
            this.setState({
              funcaoFormulaTraducao: this.traduzTermo('Meta'),
            });
          } else {
            this.setState({ funcaoFormula: 'RealizadoAcumulado' });
            this.setState({
              funcaoFormulaTraducao: this.traduzTermo('RealizadoAcumulado'),
            });
          }
        }
        break;
      case 4:
        this.setState({ funcaoFormula: 'MetaAcumulado' });
        this.setState({
          funcaoFormulaTraducao: this.traduzTermo('MetaAcumulado'),
        });
        break;
      case 5:
        this.setState({ funcaoFormula: 'RealizadoAcumulado' });
        this.setState({
          funcaoFormulaTraducao: this.traduzTermo('RealizadoAcumulado'),
        });
        break;
      default:
    }
  }

  handleValidate(value) {
    this.setState({
      validationState: 'validating',
    });

    http
      .post(`/Formula/ValidarFormula`, {
        formula: this.props.model.getModel('expressao').value,
      })
      .then((response) => {
        this.setState({
          validationState: response.data.error ? 'error' : null,
          messageError: response.data.message,
        });

        if (!response.data.error) {
          ContentManager.addContent(
            <Alert
              title={<FormattedMessage id="sucesso" />}
              message={
                <FormattedMessage id="label.formulaValidadaComSucesso" />
              }
            />
          );
        } else {
          ContentManager.addContent(
            <Alert
              title={<FormattedMessage id="erro" />}
              message={response.data.message}
            />
          );
        }
      });
  }

  insertAtCursor(myValue) {
    let myField = document.getElementById('formula');
    //IE support
    if (document.selection) {
      myField.focus();
      let sel = document.selection.createRange();
      sel.text = myValue;
    }
    // Microsoft Edge
    else if (window.navigator.userAgent.indexOf('Edge') > -1) {
      let startPos = myField.selectionStart;
      let endPos = myField.selectionEnd;

      myField.value =
        myField.value.substring(0, startPos) +
        myValue +
        myField.value.substring(endPos, myField.value.length);

      let pos = startPos + myValue.length;
      myField.focus();
      myField.setSelectionRange(pos, pos);
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
      myField.focus();
      let startPos = myField.selectionStart;
      let endPos = myField.selectionEnd;
      myField.value =
        myField.value.substring(0, startPos) +
        myValue +
        myField.value.substring(endPos, myField.value.length);
    } else {
      myField.value += myValue;
    }
    this.props.model.getModel('expressao').requestChange(myField.value);
  }

  handleAddItemFormula(valor) {
    this.insertAtCursor(valor);
  }

  handleCheckFormula() {
    this.handleValidate(this.state.formula);
  }

  handleRemoverTudo() {
    this.props.model.getModel('expressao').requestChange(null);
    this.props.parameters.alterado = true;
  }

  handleNumeroClick() {
    ContentManager.addContent(
      <Numero onRequestClose={this.handleNumeroRequestClose}></Numero>
    );
  }

  handleNumeroRequestClose(valor, saved) {
    if (saved) {
      this.insertAtCursor(valor);
    }
  }

  handleItemChange(tipo, value) {
    this.handleCustomSelectSearch(tipo, value);
  }

  handleValorClick(valor, termo) {
    this.setState({ funcaoFormula: valor });
    this.setState({ funcaoFormulaTraducao: this.traduzTermo(termo) });
  }

  handleCustomSelectSearch(tipo, item) {
    let { funcaoFormula } = this.state;
    let model = this.props.model;

    if (item) {
      let itemId = item.idIntegracao;

      if (tipo === 'condicionante') {
        funcaoFormula = 'Condicionante';
      } else if (tipo === 'organizacaoScore') {
        funcaoFormula = 'ScoreArea';
      } else if (tipo === 'colaboradorScore') {
        funcaoFormula = 'ScoreColaborador';
      } else if (tipo === 'colaboradorNotaMetaIndividual') {
        funcaoFormula = 'NotaMetaIndividual';
      } else if (tipo === 'criterios') {
        funcaoFormula = 'CriteriosAvaliacao';
      }

      const paramDefaultPreviusYears =
        funcaoFormula == 'MetaAnoAnterior' ||
        funcaoFormula == 'RealizadoAnoAnterior' ||
        funcaoFormula == 'MetaAcumuladoAnoAnterior' ||
        funcaoFormula == 'RealizadoAcumuladoAnoAnterior'
          ? ',1'
          : '';

      let valorFormatado = `${funcaoFormula}([${itemId}]${paramDefaultPreviusYears})`;
      this.insertAtCursor(valorFormatado);
      this.setState({ alterado: true });

      this.props.parameters.alterado = true;

      model.getModel('item').requestChange(null);
    }

    this.setState({ desdobramento: null });
  }

  handleDesdobramentoChange(value) {
    let { funcaoFormula } = this.state;
    let item = this.props.model.getModel('item').value;

    if (item && value) {
      let idIntegracaoItem = item.idIntegracao;
      let idIntegracaoItemDesdobramento = value.idIntegracao;

      let valorFormatado = `${funcaoFormula}([${idIntegracaoItem}],[${idIntegracaoItemDesdobramento}])`;
      this.insertAtCursor(valorFormatado);
      this.setState({ alterado: true, desdobramento: null });

      this.props.parameters.alterado = true;

      this.props.model.getModel('item').requestChange(null);
    }
  }

  periodoDataValida(dataInicio, dataTermino, currentIndex) {
    let formulas = this.props.model.value;
    let f = null;

    if (dataTermino !== null) {
      if (dataTermino < dataInicio) {
        return false;
      }
      f = formulas.find(
        (f, index) =>
          f.dataInicio >= dataInicio &&
          f.dataInicio <= dataTermino &&
          f.tipo === this.props.tipo &&
          !f.excluido &&
          index !== currentIndex
      );
    } else {
      f = formulas.find(
        (f, index) =>
          dataInicio >= f.dataInicio &&
          dataInicio <= f.dataTermino &&
          f.tipo === this.props.tipo &&
          !f.excluido &&
          index !== currentIndex
      );
    }

    if (f) {
      return false;
    } else {
      return true;
    }
  }

  handleSave() {
    if (!this.state.dataInicio) {
      ContentManager.addContent(
        <Alert
          title={<FormattedMessage id="erro" />}
          message={<FormattedMessage id="label.dataInicioObrigatoria" />}
        />
      );
      return;
    }

    if (this.state.formula.length >= 0) {
      http
        .post(`/Formula/ValidarFormula`, {
          formula: this.props.model.getModel('expressao').value,
        })
        .then((response) => {
          this.setState({
            validationState: response.data.error ? 'error' : null,
            messageError: response.data.message,
          });

          if (response.data.error) {
            ContentManager.addContent(
              <Alert
                title={<FormattedMessage id="erro" />}
                message={
                  <FormattedMessage id="label.naoEPossivelSalvarAFormulaContendoErros" />
                }
              />
            );
          } else {
            this.saved = true;
            this.closeFormula();
          }
        });
    } else {
      this.saved = true;
      this.closeFormula();
    }
  }

  handleChangeValue(field, value) {
    this.props.model.getModel(field).requestChange(value);
    this.props.parameters.alterado = true;
  }

  getTiposIdsSelecionaveis(tipoItemId) {
    //retorna lista de tipos de itens que podem ser selecionados no lookup de item
    if (tipoItemId === 12 || tipoItemId === 13 || tipoItemId === 14) {
      return [12, 13, 14];
    } else if (tipoItemId === 10) {
      return [1, 2, 3, 11];
    } else if (
      this.state.utilizarCurvaS ||
      this.state.permitirDigitacaoValores
    ) {
      //se estiver configurado para utlizar curvaS ou permitir digitação de valores libera a inclusão de projetos na fórmula
      return [1, 2, 3, 4];
    } else if (tipoItemId === 17) return [1, 17];
    else {
      return [1, 2, 3];
    }
  }

  traduzTermo(termo) {
    let { resources, intl } = this.props;

    switch (termo) {
      case 'Meta':
        return capitalizeFirstLetter(resources.meta);
      case 'Realizado':
        return <FormattedMessage id="realizado" />;
      case 'Desempenho':
        return capitalizeFirstLetter(resources.desempenho);
      case 'MetaAcumulado':
        return capitalizeFirstLetter(resources.metaacumulado);
      case 'RealizadoAcumulado':
        return <FormattedMessage id="realizadoAcumulado" />;
      case 'DesempenhoAcumulado':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'desempenhoAcumulado' },
            { desempenho: resources.desempenho }
          )
        );
      case 'MetaPeriodoAnterior':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'label.metaPeriodoAnterior' },
            { meta: resources.meta }
          )
        );
      case 'RealizadoPeriodoAnterior':
        return <FormattedMessage id="label.realizadoPeriodoAnterior" />;
      case 'DesempenhoPeriodoAnterior':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'label.desempenhoPeriodoAnterior' },
            { desempenho: resources.desempenho }
          )
        );
      case 'MetaAcumuladoPeriodoAnterior':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'label.metaAcumuladoPeriodoAnterior' },
            { metaacumulado: resources.metaacumulado }
          )
        );
      case 'RealizadoAcumuladoPeriodoAnterior':
        return (
          <FormattedMessage id="label.realizadoAcumuladoPeriodoAnterior" />
        );
      case 'DesempenhoAcumuladoPeriodoAnterior':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'label.desempenhoAcumuladoPeriodoAnterior' },
            { desempenho: resources.desempenho }
          )
        );
      case 'PontosPorFaixaRealizado':
        return capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.pontosPorFaixaRealizado' })
        );
      case 'PontosPorFaixaRealizadoAcumulado':
        return capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.pontosPorFaixaRealizadoAcumulado' })
        );
      case 'Score':
        return capitalizeFirstLetter(intl.formatMessage({ id: 'score' }));
      case 'ScoreCategoria':
        return capitalizeFirstLetter(
          intl.formatMessage({ id: 'score' }) +
            ' ' +
            intl.formatMessage({ id: 'categoria' })
        );
      case 'PontosPorFaixaTransformada':
        return capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.pontosPorFaixaTransformada' })
        );
      case 'ScoreHierarquiaArea':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'label.scoreHierarquiaArea' },
            { area: resources.area }
          )
        );
      case 'ScoreHierarquiaColaborador':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'label.scoreHierarquiaColaborador' },
            { colaborador: resources.colaborador }
          )
        );
      case 'DesempenhoEstimado':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'desempenhoEstimado' },
            {
              desempenho: resources.desempenho,
              valorestimado: resources.valorestimado,
            }
          )
        );
      case 'DesempenhoEstimadoAcumulado':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'desempenhoEstimadoAcumulado' },
            {
              desempenho: resources.desempenho,
              valorestimado: resources.valorestimado,
            }
          )
        );
      case 'MetaAnoAnterior':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'metaAnoAnterior' },
            { meta: resources.meta }
          )
        );
      case 'RealizadoAnoAnterior':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'realizadoAnoAnterior' },
            { realizadoanoanterior: resources.realizadoanoanterior }
          )
        );

      case 'MetaAcumuladoAnoAnterior':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'metaAcumuladaAnoAnterior' },
            { meta: resources.meta }
          )
        );
      case 'RealizadoAcumuladoAnoAnterior':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'realizadoAcumuladoAnoAnterior' },
            { realizadoacumanoanterior: resources.realizadoacumanoanterior }
          )
        );
      case 'notaMetaIndividual':
        return capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'notaMetaIndividual' },
            { metaindividual: resources.metaindividual }
          )
        );
      default:
    }
  }

  render() {
    const propriedades = {
      resources: this.props.resources,
      modulosPermitidos: this.props.modulosPermitidos,
      intl: this.props.intl,
      isItem: this.props.isItem,
      ignorarItemTabelaValor: this.props.ignorarItemTabelaValor,
      model: this.props.model,
    };

    const {
      resources,
      modulosPermitidos,
      intl,
      isItem,
      ignorarItemTabelaValor,
      model,
    } = propriedades;
    const { funcaoFormula, funcaoFormulaTraducao } = this.state;

    let item =
      model.getModel('itemOrigem') && model.getModel('itemOrigem').value;
    let tipoValor =
      model.getModel('tipo') &&
      model.getModel('tipo').value &&
      model.getModel('tipo').value.id;
    let tipoItem =
      model.getModel('itemOrigem') &&
      model.getModel('itemOrigem').value &&
      model.getModel('itemOrigem').value.tipo &&
      model.getModel('itemOrigem').value.tipo.id;
    let tipoEntidade =
      model.getModel('tipoEntidade') &&
      model.getModel('tipoEntidade').value &&
      model.getModel('tipoEntidade').value.id;

    const isCestaIndicadores =
      tipoEntidade == 3 || tipoEntidade == 7 || tipoEntidade == 8;

    const hasLift = modulosPermitidos.find(
      (m) => m.id === MODULOS.AVALIACAO_DESEMPENHO && m.ativo
    );

    const hasScore = modulosPermitidos.find(
      (m) => m.id === MODULOS.REMUNERACAO_VARIAVEL && m.ativo
    );

    return (
      <div>
        <Row>
          {
            //3-cesta de indicadores e 4-tipo de card e 6-gatilho
            (this.props.parameters.padrao ||
              (!isCestaIndicadores &&
                tipoEntidade !== 4 &&
                tipoEntidade !== 6)) && (
              <Col md={2}>
                <Input
                  model={{
                    label: intl.formatMessage({ id: 'idIntegracao' }),
                    value: model.getModel('idIntegracao').value,
                    requestChange: this.handleChangeValue.bind(
                      this,
                      'idIntegracao'
                    ),
                  }}
                />
              </Col>
            )
          }
          {this.props.parameters.padrao && (
            <Col md={4}>
              <Input
                required
                model={{
                  label: intl.formatMessage({ id: 'descricao' }),
                  value: model.getModel('descricao').value,
                  requestChange: this.handleChangeValue.bind(this, 'descricao'),
                }}
              />
            </Col>
          )}
          {
            //3-cesta de indicadores e 4-tipo de card e 6-gatilho
            !isCestaIndicadores &&
              tipoEntidade !== 4 &&
              tipoEntidade !== 6 &&
              !this.props.parameters.padrao && (
                <Col md={6}>
                  <DataInicioTermino
                    required
                    dataInicio={{
                      label: model.getModel('dataInicio').label,
                      value: model.getModel('dataInicio').value,
                      requestChange: this.handleChangeValue.bind(
                        this,
                        'dataInicio'
                      ),
                      errors: model.getModel('dataInicio').errors,
                    }}
                    dataTermino={{
                      label: model.getModel('dataTermino').label,
                      value: model.getModel('dataTermino').value,
                      requestChange: this.handleChangeValue.bind(
                        this,
                        'dataTermino'
                      ),
                    }}
                  />
                </Col>
              )
          }
          {!this.props.parameters.padrao && !isItem && tipoEntidade != 9 && (
            <Col md={3}>
              <FormulaPadraoLookup
                model={{
                  label: intl.formatMessage({ id: 'formulaPadrao' }),
                  value: model.getModel('formulaPadrao').value,
                  requestChange: this.handleChangeValue.bind(
                    this,
                    'formulaPadrao'
                  ),
                  errors: model.getModel('formulaPadrao').errors,
                }}
                clearable={true}
                data={{
                  ...propriedades,
                  ...this.props.parameters,
                  padrao: true,
                }}
              />
            </Col>
          )}
        </Row>
        {!model.getModel('formulaPadrao') ||
          (!model.getModel('formulaPadrao').value && (
            <Panel header={<FormattedMessage id="label.editor" />}>
              <Row>
                <Col md={12}>
                  <ButtonToolbar>
                    {tipoEntidade !== 9 && (
                      <UncontrolledDropdown className="inline-block mr-2">
                        <DropdownToggle color="secondary" caret>
                          {funcaoFormulaTraducao}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ overflowY: 'auto', maxHeight: 350 }}
                        >
                          {/* meta */}
                          {((isItem && tipoItem) || tipoEntidade == 2 || tipoEntidade == 3)  && (
                            <DropdownItem
                              key="1"
                              onClick={this.handleValorClick.bind(
                                this,
                                'Meta',
                                'Meta'
                              )}
                            >
                              <span>{this.traduzTermo('Meta')}</span>
                            </DropdownItem>
                          )}
                          {/* realizado */}
                          {((isItem && tipoItem) || tipoEntidade == 2 || tipoEntidade == 3) && (
                            <DropdownItem
                              key="2"
                              onClick={this.handleValorClick.bind(
                                this,
                                'Realizado',
                                'Realizado'
                              )}
                            >
                              <span>{this.traduzTermo('Realizado')}</span>
                            </DropdownItem>
                          )}
                          {/* desempenho */}
                          {(isItem || tipoEntidade == 2 || tipoEntidade == 3) && (
                            <DropdownItem
                              key="3"
                              onClick={this.handleValorClick.bind(
                                this,
                                'Desempenho',
                                'Desempenho'
                              )}
                            >
                              <span>{this.traduzTermo('Desempenho')}</span>
                            </DropdownItem>
                          )}
                          {/* realizado acumulado */}
                          {(tipoItem || tipoEntidade == 2 || tipoEntidade == 3) && (
                            <DropdownItem
                              key="5"
                              onClick={this.handleValorClick.bind(
                                this,
                                'RealizadoAcumulado',
                                'RealizadoAcumulado'
                              )}
                            >
                              <span>
                                {this.traduzTermo('RealizadoAcumulado')}
                              </span>
                            </DropdownItem>
                          )}
                          {/* desempenho acumulado */}
                          {(tipoItem || tipoEntidade == 2 || tipoEntidade == 3) && (
                            <DropdownItem
                              key="6"
                              onClick={this.handleValorClick.bind(
                                this,
                                'DesempenhoAcumulado',
                                'DesempenhoAcumulado'
                              )}
                            >
                              <span>
                                {this.traduzTermo('DesempenhoAcumulado')}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="3"
                              onClick={this.handleValorClick.bind(
                                this,
                                'DesempenhoEstimado',
                                'DesempenhoEstimado'
                              )}
                            >
                              <span>
                                {this.traduzTermo('DesempenhoEstimado')}
                              </span>
                            </DropdownItem>
                          )}
                          {(!isItem || (tipoItem && tipoItem !== 3)) && (
                            <DropdownItem
                              key="4"
                              onClick={this.handleValorClick.bind(
                                this,
                                'MetaAcumulado',
                                'MetaAcumulado'
                              )}
                            >
                              <span>{this.traduzTermo('MetaAcumulado')}</span>
                            </DropdownItem>
                          )}
                          {isItem && tipoItem && tipoItem !== 3 && (
                            <DropdownItem
                              key="3"
                              onClick={this.handleValorClick.bind(
                                this,
                                'DesempenhoEstimadoAcumulado',
                                'DesempenhoEstimadoAcumulado'
                              )}
                            >
                              <span>
                                {this.traduzTermo(
                                  'DesempenhoEstimadoAcumulado'
                                )}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="7"
                              onClick={this.handleValorClick.bind(
                                this,
                                'MetaPeriodoAnterior',
                                'MetaPeriodoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo('MetaPeriodoAnterior')}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="8"
                              onClick={this.handleValorClick.bind(
                                this,
                                'RealizadoPeriodoAnterior',
                                'RealizadoPeriodoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo('RealizadoPeriodoAnterior')}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="9"
                              onClick={this.handleValorClick.bind(
                                this,
                                'DesempenhoPeriodoAnterior',
                                'DesempenhoPeriodoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo('DesempenhoPeriodoAnterior')}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="10"
                              onClick={this.handleValorClick.bind(
                                this,
                                'MetaAcumuladoPeriodoAnterior',
                                'MetaAcumuladoPeriodoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo(
                                  'MetaAcumuladoPeriodoAnterior'
                                )}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="11"
                              onClick={this.handleValorClick.bind(
                                this,
                                'RealizadoAcumuladoPeriodoAnterior',
                                'RealizadoAcumuladoPeriodoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo(
                                  'RealizadoAcumuladoPeriodoAnterior'
                                )}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="12"
                              onClick={this.handleValorClick.bind(
                                this,
                                'DesempenhoAcumuladoPeriodoAnterior',
                                'DesempenhoAcumuladoPeriodoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo(
                                  'DesempenhoAcumuladoPeriodoAnterior'
                                )}
                              </span>
                            </DropdownItem>
                          )}
                          {((tipoItem === 1 &&
                            modulosPermitidos &&
                            modulosPermitidos.find((i) => {
                              return i.ativo && i.id === 3;
                            })) ||
                            !isItem ||
                            isCestaIndicadores ||
                            tipoEntidade === 4) && (
                            <DropdownItem
                              key="13"
                              onClick={this.handleValorClick.bind(
                                this,
                                'PontosPorFaixaRealizado',
                                'PontosPorFaixaRealizado'
                              )}
                            >
                              <span>
                                {this.traduzTermo('PontosPorFaixaRealizado')}
                              </span>
                            </DropdownItem>
                          )}
                          {((tipoItem === 1 &&
                            modulosPermitidos &&
                            modulosPermitidos.find((i) => {
                              return i.ativo && i.id === 3;
                            })) ||
                            !isItem ||
                            isCestaIndicadores ||
                            tipoEntidade === 4) && (
                            <DropdownItem
                              key="14"
                              onClick={this.handleValorClick.bind(
                                this,
                                'PontosPorFaixaRealizadoAcumulado',
                                'PontosPorFaixaRealizadoAcumulado'
                              )}
                            >
                              <span>
                                {this.traduzTermo(
                                  'PontosPorFaixaRealizadoAcumulado'
                                )}
                              </span>
                            </DropdownItem>
                          )}
                          {(tipoEntidade === 2 ||
                            isCestaIndicadores ||
                            tipoEntidade === 4 ||
                            tipoEntidade === 6) && (
                            <DropdownItem
                              key="15"
                              onClick={this.handleValorClick.bind(
                                this,
                                'ScoreFuncao',
                                'Score'
                              )}
                            >
                              <span>{this.traduzTermo('Score')}</span>
                            </DropdownItem>
                          )}
                          {tipoEntidade === 4 && (
                            <DropdownItem
                              key="15"
                              onClick={this.handleValorClick.bind(
                                this,
                                'ScoreCategoria',
                                'ScoreCategoria'
                              )}
                            >
                              <span>{this.traduzTermo('ScoreCategoria')}</span>
                            </DropdownItem>
                          )}
                          {(tipoEntidade === 2 || isCestaIndicadores) &&
                            modulosPermitidos &&
                            modulosPermitidos.find((i) => {
                              return i.ativo && i.id === 3;
                            }) && (
                              <DropdownItem
                                key="16"
                                onClick={this.handleValorClick.bind(
                                  this,
                                  'PontosPorFaixaTransformada',
                                  'PontosPorFaixaTransformada'
                                )}
                              >
                                <span>
                                  {this.traduzTermo(
                                    'PontosPorFaixaTransformada'
                                  )}
                                </span>
                              </DropdownItem>
                            )}
                          {(tipoEntidade === 2 || tipoEntidade === 6) &&
                            modulosPermitidos &&
                            modulosPermitidos.find((i) => {
                              return i.ativo && i.id === 3;
                            }) && (
                              <DropdownItem
                                key="17"
                                onClick={this.handleAddItemFormula.bind(
                                  this,
                                  'ScoreHierarquiaArea('
                                )}
                              >
                                <span>
                                  {this.traduzTermo('ScoreHierarquiaArea')}
                                </span>
                              </DropdownItem>
                            )}
                          {(tipoEntidade === 2 || tipoEntidade === 6) &&
                            modulosPermitidos &&
                            modulosPermitidos.find((i) => {
                              return i.ativo && i.id === 3;
                            }) && (
                              <DropdownItem
                                key="18"
                                onClick={this.handleAddItemFormula.bind(
                                  this,
                                  'ScoreHierarquiaColaborador('
                                )}
                              >
                                <span>
                                  {this.traduzTermo(
                                    'ScoreHierarquiaColaborador'
                                  )}
                                </span>
                              </DropdownItem>
                            )}
                          {isItem && (
                            <DropdownItem
                              key="19"
                              onClick={this.handleValorClick.bind(
                                this,
                                'MetaAnoAnterior',
                                'MetaAnoAnterior'
                              )}
                            >
                              <span>{this.traduzTermo('MetaAnoAnterior')}</span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="20"
                              onClick={this.handleValorClick.bind(
                                this,
                                'RealizadoAnoAnterior',
                                'RealizadoAnoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo('RealizadoAnoAnterior')}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="21"
                              onClick={this.handleValorClick.bind(
                                this,
                                'MetaAcumuladoAnoAnterior',
                                'MetaAcumuladoAnoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo('MetaAcumuladoAnoAnterior')}
                              </span>
                            </DropdownItem>
                          )}
                          {isItem && (
                            <DropdownItem
                              key="22"
                              onClick={this.handleValorClick.bind(
                                this,
                                'RealizadoAcumuladoAnoAnterior',
                                'RealizadoAcumuladoAnoAnterior'
                              )}
                            >
                              <span>
                                {this.traduzTermo(
                                  'RealizadoAcumuladoAnoAnterior'
                                )}
                              </span>
                            </DropdownItem>
                          )}
                          {(tipoEntidade === 2 ||
                            isCestaIndicadores ||
                            tipoEntidade === 4 ||
                            tipoEntidade === 6) &&
                            hasLift &&
                            hasScore && (
                              <DropdownItem
                                key="23"
                                onClick={this.handleValorClick.bind(
                                  this,
                                  'notaMetaIndividual',
                                  'notaMetaIndividual'
                                )}
                              >
                                <span>
                                  {this.traduzTermo('notaMetaIndividual')}
                                </span>
                              </DropdownItem>
                            )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                    <ButtonGroup className="inline-block mr-2">
                      <Button
                        color="secondary"
                        style={{ width: 35 }}
                        onClick={this.handleAddItemFormula.bind(this, '+')}
                      >
                        +
                      </Button>
                      <Button
                        color="secondary"
                        style={{ width: 35 }}
                        onClick={this.handleAddItemFormula.bind(this, '-')}
                      >
                        -
                      </Button>
                      <Button
                        color="secondary"
                        style={{ width: 35 }}
                        onClick={this.handleAddItemFormula.bind(this, '*')}
                      >
                        *
                      </Button>
                      <Button
                        color="secondary"
                        style={{ width: 35 }}
                        onClick={this.handleAddItemFormula.bind(this, '/')}
                      >
                        /
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup className="inline-block mr-2">
                      <Button
                        color="secondary"
                        style={{ width: 40 }}
                        onClick={this.handleAddItemFormula.bind(this, '(')}
                      >
                        (
                      </Button>
                      <Button
                        color="secondary"
                        style={{ width: 40 }}
                        onClick={this.handleAddItemFormula.bind(this, ')')}
                      >
                        )
                      </Button>
                      <Button
                        color="secondary"
                        style={{ width: 40 }}
                        onClick={this.handleAddItemFormula.bind(this, ',')}
                      >
                        ,
                      </Button>
                      <Button
                        color="secondary"
                        style={{ width: 40 }}
                        onClick={this.handleAddItemFormula.bind(this, '.')}
                      >
                        .
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                      <UncontrolledDropdown className="inline-block mr-2">
                        <DropdownToggle color="secondary" caret>
                          {<FormattedMessage id="label.funcoesGerais" />}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ overflowY: 'auto', maxHeight: 350 }}
                        >
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(
                              this,
                              'se('
                            )}
                          >
                            <FormattedMessage id="label.se" />
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, '>')}
                          >
                            <span>
                              <FormattedMessage id="maior" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, '>=')}
                          >
                            <span>
                              <FormattedMessage id="label.maiorOuIgual" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, '<')}
                          >
                            <span>
                              <FormattedMessage id="menor" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, '<=')}
                          >
                            <span>
                              <FormattedMessage id="label.menorOuIgual" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, '=')}
                          >
                            <span>
                              <FormattedMessage id="igual" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, '!=')}
                          >
                            <span>
                              <FormattedMessage id="label.diferente" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, 'e')}
                          >
                            <span>
                              <FormattedMessage id="label.e" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, 'ou')}
                          >
                            <span>
                              <FormattedMessage id="label.ou" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(this, '!')}
                          >
                            <span>
                              <FormattedMessage id="label.not" />
                            </span>
                          </DropdownItem>
                          {(tipoValor === 5 || tipoValor === 4) && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'NumeroPeriodos()'
                              )}
                            >
                              <span>
                                <FormattedMessage id="label.nDePeriodos" />
                              </span>
                            </DropdownItem>
                          )}
                          {(tipoValor === 5 || tipoValor === 4) && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'NumeroPeriodosRealizados()'
                              )}
                            >
                              <span>
                                <FormattedMessage id="label.nDePeriodosRealizados" />
                              </span>
                            </DropdownItem>
                          )}
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(
                              this,
                              'NumeroItensComValor()'
                            )}
                          >
                            <span>
                              <FormattedMessage id="nDeItensComValor" />
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(
                              this,
                              'NumeroItens()'
                            )}
                          >
                            <span>
                              <FormattedMessage id="label.nDeItensFormula" />
                            </span>
                          </DropdownItem>
                          {!isItem && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'UltimoSalario()'
                              )}
                            >
                              <span>
                                <FormattedMessage
                                  id="label.ultimoSalario"
                                  values={{ salario: resources.salario }}
                                />
                              </span>
                            </DropdownItem>
                          )}
                          {!isItem && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'SalarioProRata()'
                              )}
                            >
                              <span>
                                <FormattedMessage
                                  id="label.salarioProRata"
                                  values={{ salario: resources.salario }}
                                />
                              </span>
                            </DropdownItem>
                          )}
                          {!isItem && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'Score()'
                              )}
                            >
                              <span>{this.traduzTermo('Score')}</span>
                            </DropdownItem>
                          )}
                          {tipoEntidade === 8 && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'SalarioAcumulado()'
                              )}
                            >
                              <span>
                                <FormattedMessage
                                  id="salarioAcumulado"
                                  values={{ salario: resources.salario }}
                                />
                              </span>
                            </DropdownItem>
                          )}
                          {tipoEntidade === 8 && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'Performance()'
                              )}
                            >
                              <span>
                                <FormattedMessage id="performance" />
                              </span>
                            </DropdownItem>
                          )}
                          {hasLift && tipoEntidade === 2 && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'ResultadoCicloAvaliacao()'
                              )}
                            >
                              <span>
                                <FormattedMessage
                                  id="resultadoCicloAvaliacao"
                                  values={{
                                    cicloavaliacao: resources.cicloavaliacao,
                                  }}
                                />
                              </span>
                            </DropdownItem>
                          )}
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(
                              this,
                              'MenorValor('
                            )}
                          >
                            <FormattedMessage id="label.menorValor" />
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(
                              this,
                              'MaiorValor('
                            )}
                          >
                            <FormattedMessage id="label.maiorValor" />
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.handleAddItemFormula.bind(
                              this,
                              'Arredondar('
                            )}
                          >
                            <FormattedMessage id="label.arredondar" />
                          </DropdownItem>
                          {(tipoEntidade === 2 || isCestaIndicadores) && (
                            <DropdownItem
                              onClick={this.handleAddItemFormula.bind(
                                this,
                                'Transformada('
                              )}
                            >
                              <FormattedMessage id="label.transformada" />
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </ButtonGroup>
                    <ButtonGroup>
                      <Button
                        className="inline-block"
                        color="secondary"
                        onClick={this.handleNumeroClick}
                      >
                        {<FormattedMessage id="label.numero" />}
                      </Button>
                    </ButtonGroup>
                    <UncontrolledDropdown className="inline-block ml-2 pull-right">
                      <DropdownToggle color="secondary">
                        <MdiIcon icon="dots-horizontal" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.handleRemoverTudo}>
                          <FormattedMessage id="label.limparFormula" />
                        </DropdownItem>
                        <DropdownItem onClick={this.handleValidate}>
                          <FormattedMessage id="label.validarFormula" />
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </ButtonToolbar>
                </Col>
              </Row>
              <Row className="mt-2">
                {tipoEntidade === 9 && (
                  <Col md={6}>
                    <CriteriosAvaliacaoLookup
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage({ id: 'labelCriteriosAvaliacao' })
                        ),
                        value: model.getModel('criterios').value,
                        requestChange: this.handleItemChange.bind(
                          this,
                          'criterios'
                        ),
                      }}
                    />
                  </Col>
                )}
                <Col md={6}>
                  {
                    //se o tipoentidade for tipocard e o editor selecionado for Score exibe o lookup de categoria
                    //e não o de item
                    tipoEntidade === 4 && funcaoFormula == 'ScoreCategoria' && (
                      <CategoriaLookup
                        model={{
                          label: intl.formatMessage({ id: 'categoria' }),
                          value: model.getModel('categoria').value,
                          requestChange: this.handleItemChange.bind(
                            this,
                            'categoria'
                          ),
                          errors: model.getModel('categoria').errors,
                        }}
                        clearable={false}
                      />
                    )
                  }
                  {funcaoFormula !== 'ScoreFuncao' &&
                    funcaoFormula !== 'ScoreCategoria' &&
                    funcaoFormula !== 'PontosPorFaixaTransformada' &&
                    funcaoFormula !== 'notaMetaIndividual' &&
                    tipoEntidade != 9 && (
                      <ItemLookup
                        className="inline-block"
                        data={{
                          tiposIdsSelecionados:
                            this.getTiposIdsSelecionaveis(tipoItem),
                          periodicidadeIdSuperior:
                            item && item.periodicidade
                              ? item.periodicidade.id
                              : 9,
                          ignorarItemTabelaValor: ignorarItemTabelaValor,
                          desdobramentoId:
                            model.getModel('desdobramento').value &&
                            model.getModel('desdobramento').value.id,
                          periodicidadeId:
                            funcaoFormula == 'MetaAnoAnterior' ||
                            funcaoFormula == 'RealizadoAnoAnterior' ||
                            funcaoFormula == 'MetaAcumuladoAnoAnterior' ||
                            funcaoFormula == 'RealizadoAcumuladoAnoAnterior'
                              ? item?.periodicidade.id
                              : null,
                        }}
                        model={{
                          label: intl.formatMessage({ id: 'item' }),
                          value: model.getModel('item').value,
                          requestChange: this.handleItemChange.bind(
                            this,
                            'item'
                          ),
                        }}
                      />
                    )}
                  {
                    //se o tipoentidade for cesta de indicadores e o editor selecionado for Score exibe o lookup de função
                    //e não o de item
                    (tipoEntidade === 2 ||
                      isCestaIndicadores ||
                      tipoEntidade === 4 ||
                      tipoEntidade === 6) &&
                      funcaoFormula == 'ScoreFuncao' && (
                        <div>
                          <FuncaoLookup
                            model={{
                              label: capitalizeFirstLetter(resources.funcao),
                              value: model.getModel('funcao').value,
                              requestChange: this.handleItemChange.bind(
                                this,
                                'funcao'
                              ),
                              errors: model.getModel('funcao').errors,
                            }}
                            clearable={false}
                          />
                          <ColaboradorSimplificadoLookup
                            model={{
                              label: capitalizeFirstLetter(
                                resources.colaborador
                              ),
                              value: model.getModel('colaboradorScore').value,
                              requestChange: this.handleItemChange.bind(
                                this,
                                'colaboradorScore'
                              ),
                            }}
                          />
                          <ItemOrganizacaoLookup
                            model={{
                              label: capitalizeFirstLetter(resources.area),
                              value: model.getModel('organizacaoScore').value,
                              requestChange: this.handleItemChange.bind(
                                this,
                                'organizacaoScore'
                              ),
                            }}
                          />
                        </div>
                      )
                  }

                  {
                    //se o tipoentidade for cesta de indicadores e o editor selecionado for Meta individual exibe o lookup de colaborador e não exibe o de item
                    (tipoEntidade === 2 ||
                      isCestaIndicadores ||
                      tipoEntidade === 4 ||
                      tipoEntidade === 6) &&
                      funcaoFormula == 'notaMetaIndividual' && (
                        <div>
                          <ColaboradorSimplificadoLookup
                            model={{
                              label: capitalizeFirstLetter(
                                resources.colaborador
                              ),
                              value: model.getModel(
                                'colaboradorNotaMetaIndividual'
                              ).value,
                              requestChange: this.handleItemChange.bind(
                                this,
                                'colaboradorNotaMetaIndividual'
                              ),
                            }}
                          />
                        </div>
                      )
                  }

                  {(tipoEntidade === 2 || isCestaIndicadores) &&
                    funcaoFormula === 'PontosPorFaixaTransformada' && (
                      <PontoFaixaResultadoLookup
                        model={{
                          label: intl.formatMessage({
                            id: 'label.pontosFaixaResultado',
                          }),
                          value: model.getModel('pontosFaixaTransformada')
                            .value,
                          requestChange: this.handleItemChange.bind(
                            this,
                            'pontosFaixaTransformada'
                          ),
                        }}
                        data={{ tipoPontoFaixaResultadoId: 1 }}
                      />
                    )}
                </Col>

                {(!isItem || tipoEntidade === 6 || tipoEntidade === 2) &&
                  tipoEntidade !== 9 && (
                    <Col md={6}>
                      <CondicionanteLookup
                        className="inline-block"
                        model={{
                          label: capitalizeFirstLetter(resources.condicionante),
                          value: model.getModel('condicionante').value,
                          requestChange: this.handleItemChange.bind(
                            this,
                            'condicionante'
                          ),
                        }}
                      />
                    </Col>
                  )}
              </Row>
              <Row className="mt-2">
                <Col md={12}>
                  <Input
                    id="formula"
                    autoFocus
                    rows={12}
                    type="textarea"
                    validationState={this.state.validationState}
                    model={{
                      label: intl.formatMessage({ id: 'label.expressao' }),
                      value: model.getModel('expressao').value,
                      requestChange: this.handleChangeValue.bind(
                        this,
                        'expressao'
                      ),
                    }}
                  ></Input>
                  {this.state.validationState === 'error' && (
                    <span style={{ color: '#f05050' }}>
                      {this.state.messageError}
                    </span>
                  )}
                </Col>
              </Row>
            </Panel>
          ))}
      </div>
    );
  }
}

@autobind
class FormulaEdit extends React.Component {
  render() {
    return (
      <Edit
        url="/Formula"
        title="formula"
        formComponent={Editor}
        width={900}
        height={650}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    modulosPermitidos: state.user.licenca.modulos,
  };
}
export default injectIntl(connect(mapStateToProps)(FormulaEdit));
