import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../../componentes/dialog';
import { Schema, Form } from '../../../utils/form';
import http from 'src/services/httpService';
import LoadingContainer from '../../../componentes/loading-container';
import { Row, Col } from 'reactstrap';
import MdiIcon from '../../../componentes/mdi-icon';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import errorHandler from '../../../utils/error-handler';
import GraficoCurvaS from '../../item/valor/componentes/grafico';

let schema = Schema.object({
  id: Schema.number().label(<FormattedMessage id="label.id" />),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  responsaveis: Schema.array().label(<FormattedMessage id="responsaveis" />),

  dataInicioItem: Schema.string().label(<FormattedMessage id="label.dataDeInicio" />),
  dataTerminoItem: Schema.string().label(<FormattedMessage id="label.dataDeTermino" />),
  percentualPrevisto: Schema.string().label(),
  percentualExecutado: Schema.string().label(),
  investimentoPrevisto: Schema.string().label(),
  investimentoRealizado: Schema.string().label(),
  dataInicioAcao: Schema.string().label(),
  dataFimAcao: Schema.string().label(),
  dataInicioRealizadoAcao: Schema.string().label(),
  dataFimRealizadoAcao: Schema.string().label(),
  escopo: Schema.string().label(<FormattedMessage id="escopo" />),
});

@autobind
class CurvaS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    http
      .post(`Item/ObterPermissao`, {
        id: this.props.id,
      })
      .then((response) => {
        this.setState(
          {
            exibir: true,
          },
          () => {
            this.fichaProjeto();
          }
        );
      })
      .catch((error) => {
        this.setState({
          exibir: false,
        });
        errorHandler(error);
        this.handleClose();
      });
  }

  fichaProjeto() {
    this.setState({
      isLoading: true,
    });

    http
      .post(`/Projeto/Edit`, {
        id: this.props.id,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.form.setValue(null, response.data);
      });
  }

  handleLabelMelhor(item) {
    return (
      <span>
        <MdiIcon icon={item.icone} size={16}></MdiIcon>
      </span>
    );
  }

  handleClose() {
    this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
    }, 300);
  }

  handleMenuClick() {
    this.handleClose();
  }

  render() {
    let model = this.form.getModel();
    let { resources, intl } = this.props;
    let grafico = model.getModel('graficoCurvaS') && model.getModel('graficoCurvaS').value;

    return (
      <Dialog title={model.getModel('nome').value} ref="dialog" padContent width={900} height={550} onRequestClose={this.handleClose}>
        <LoadingContainer isLoading={this.state.isLoading}>
          {this.state.exibir === true && (
            <div>
              {model.value && model.value.id != 0 && model.getModel('configuracaoGeral').value && model.getModel('configuracaoGeral').value.utilizarCurvaS && (
                <Row>
                  <Col md={12} style={{ marginTop: 20 }}>
                    <GraficoCurvaS
                      height={320}
                      tituloPersonalizado={{
                        titulo: <strong>{model.getModel('nome').value}</strong>,
                        polaridade: grafico && grafico.exibirPolaridade,
                        unidadeMedida: false,
                      }}
                      grafico={grafico}
                    ></GraficoCurvaS>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </LoadingContainer>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CurvaS));
