import React from 'react';
import Lookup from '../../../../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class CampoCustomizado extends React.Component {
  render() {
    let { options } = this.props;

    return <Lookup clearable labelKey="descricao" valueKey="id" options={options} {...this.props} />;
  }
}

export default CampoCustomizado;
