import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import { autobind } from 'core-decorators';
import { Schema } from 'src/utils/form';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from 'src/utils/string';
import TipoCargoLookup from 'src/lookups/tipo-cargo';
import CriticidadeCargoLookup from 'src/lookups/CriticidadeCargoLookup';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import CheckBox from 'src/componentes/checkbox';
import ReactQuill from 'src/componentes/quill';
import ArquivoUploadAnexo from 'src/componentes/arquivo-upload/anexo';
import { MODULOS } from 'src/utils/constants';

import FuncoesList from '../funcoes/view';
import HistoricoOcupacaoList from 'src/paginas/colaborador/HistoricoOcupacao';
@autobind
class FormEdit extends React.Component {
  handleChangeCampo(field, value) {
    this.props.model.getModel(field).requestChange(value);
  }

  render() {
    const { model, modulosComprados, moduloAtual, intl, resources } =
      this.props;

    const hasRV = modulosComprados.find(
      (m) => m.id === MODULOS.REMUNERACAO_VARIAVEL && m.ativo
    );
    const hasLift = modulosComprados.find(
      (m) => m.id === MODULOS.AVALIACAO_DESEMPENHO && m.ativo
    );

    return (
      <Tabs defaultActiveKey="1">
        <Tab label={<FormattedMessage id="label.resumo" />} key="1">
          <Row>
            <Col md={4}>
              <Input model={model.getModel('idIntegracao')} required />
              <TipoCargoLookup
                model={{
                  label: intl.formatMessage(
                    { id: 'tipoCargo' },
                    { cargo: resources.cargo }
                  ),
                  value: model.getModel('tipoCargo').value,
                  requestChange: model.getModel('tipoCargo').requestChange,
                }}
              />
              {hasLift && (
                <CriticidadeCargoLookup
                  required
                  model={{
                    label: intl.formatMessage({ id: 'criticidade' }),
                    value: model.getModel('criticidade').value,
                    requestChange: model.getModel('criticidade').requestChange,
                    errors: model.getModel('criticidade').errors,
                  }}
                />
              )}
            </Col>
            <Col md={8}>
              <Input model={model.getModel('nome')} required />
              <Input
                maxLength={600}
                rows={5}
                type="textarea"
                model={model.getModel('descricao')}
              />
            </Col>
          </Row>
        </Tab>
        {hasRV && model.getModel('id').value > 0 && (
          <Tab label={<FormattedMessage id="funcoes" />} key="2">
            <FuncoesList parameters={{ cargoId: model.value.id }} />
          </Tab>
        )}
        {model.value.id > 0 && moduloAtual.id === 4 && (
          <Tab label={<FormattedMessage id="historicoOcupacao" />} key="3">
            <HistoricoOcupacaoList
              dataValue={model.getModel('historicoOcupacao')}
              parameters={{ cargoId: model.value.id }}
            />
          </Tab>
        )}
        {hasRV &&
          model.getModel('id').value > 0 &&
          model.getModel('contratoResultadosVinculadoCargo').value && (
            <Tab
              label={
                <FormattedMessage
                  id="contratoResultados"
                  values={{ contratoresultados: resources.contratoresultados }}
                />
              }
              key="4"
            >
              <>
                <Row>
                  <Col md={4}>
                    <CheckBox
                      style={{ marginTop: 5 }}
                      model={{
                        label: intl.formatMessage(
                          { id: 'exibirMensagemContratoResultados' },
                          {
                            funcoes: resources.funcoes,
                            contratoresultados: resources.contratoresultados,
                          }
                        ),
                        value: model.getModel(
                          'exibirMensagemContratoResultados'
                        ).value,
                        requestChange: model.getModel(
                          'exibirMensagemContratoResultados'
                        ).requestChange,
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={8}>
                    <ReactQuill
                      className="mt-2"
                      style={{ minHeight: 200 }}
                      value={
                        (model.getModel('mensagemContratoResultados') &&
                          model.getModel('mensagemContratoResultados').value) ||
                        ''
                      }
                      onChange={this.handleChangeCampo.bind(
                        this,
                        'mensagemContratoResultados'
                      )}
                      theme="snow"
                      formats={this.formats}
                      modules={this.modules}
                    />
                  </Col>
                  <Col md={4}>
                    <ArquivoUploadAnexo
                      buttonLabel={intl.formatMessage({
                        id: 'label.selecionarAnexo',
                      })}
                      tipoAnexo={this.props.tiposAnexos.modeloBonificacaoCargo}
                      idReferencia={model.getModel('id').value}
                      model={{
                        value:
                          (model.getModel('modeloBonificacao') &&
                            model.getModel('modeloBonificacao').value) ||
                          null,
                        label: intl.formatMessage({
                          id: 'modeloBonificacao',
                        }),
                        requestChange: this.handleChangeCampo.bind(
                          this,
                          'modeloBonificacao'
                        ),
                      }}
                    ></ArquivoUploadAnexo>
                  </Col>
                </Row>
              </>
            </Tab>
          )}
      </Tabs>
    );
  }
}

@autobind
class CargoEdit extends React.Component {
  getSchema(modulosComprados) {
    const hasLift = modulosComprados.find(
      (m) => m.id === MODULOS.AVALIACAO_DESEMPENHO && m.ativo
    );

    const schema = Schema.object({
      id: Schema.string(),
      nome: Schema.string()
        .label(<FormattedMessage id="nome" />)
        .required(),
      idIntegracao: Schema.string()
        .label(<FormattedMessage id="idIntegracao" />)
        .required(),
      tipoCargo: Schema.string().label(<FormattedMessage id="tipoCargo" />),
      descricao: Schema.string().label(<FormattedMessage id="descricao" />),
      indicador: Schema.string().label(
        <FormattedMessage id="label.indicador" />
      ),
      peso: Schema.string().label(<FormattedMessage id="peso" />),
      criticidade: Schema.string()
        .label(<FormattedMessage id="criticidade" />)
        .required({
          onlyIf: () => hasLift,
        }),
    });

    return schema;
  }

  render() {
    const { resources, modulosComprados } = this.props;
    const schema = this.getSchema(modulosComprados);

    return (
      <Edit
        url="/Cargo"
        title={capitalizeFirstLetter(resources.cargo)}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height={600}
        {...this.props}
        showExport={false}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
    modulosComprados: state.user.licenca.modulos,
    moduloAtual: state.user.moduloAtual,
    tiposAnexos: state.user.tiposAnexos,
  };
}
export default injectIntl(connect(mapStateToProps)(CargoEdit));
