import React from 'react';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/string';
import Edit from '../../../../componentes/edit/edit-dialog';
import { Schema } from '../../../../utils/form';
import Editor from '../../../../paginas/formula';
import Tabs from '../../../../componentes/tabs/tabs';
import Tab from '../../../../componentes/tabs/tab';
import List from '../../../../componentes/list';
import ListColumn from '../../../../componentes/list/list-column';
import InputNumber from '../../../../componentes/input-number';

@autobind
class FormEdit extends React.Component {
  renderDescricao(value) {
    return value.condicionante.descricao;
  }

  handleValueChange(index, field, value) {
    let { model } = this.props;
    let condicionantes = model.getModel('condicionantes').value;
    condicionantes[index][field] = value;
    model.requestChange(condicionantes);
  }

  renderValue(value) {
    let { model } = this.props;
    let index = model.getModel('condicionantes').value.findIndex((x) => x.condicionante.id === value.condicionante.id);

    return (
      <InputNumber
        style={{ width: 120 }}
        model={{
          value: value.valor,
          requestChange: this.handleValueChange.bind(this, index, 'valor'),
        }}
      />
    );
  }

  render() {
    let { model, intl, resources } = this.props;

    return (
      <Tabs defaultActiveKey="1">
        <Tab label={<FormattedMessage id="label.formulas" />} key="1">
          <Editor {...this.props} isItem={false} />
        </Tab>
        {model.getModel('id').value > 0 && (
          <Tab label={capitalizeFirstLetter(resources.condicionantes)} key="2">
            <List local dataSource={model.getModel('condicionantes').value} sort="Descricao" showToolBar={false} showMenu={false}>
              <ListColumn headerText={intl.formatMessage({ id: 'descricao' })} valueFunction={this.renderDescricao} />
              <ListColumn headerText={intl.formatMessage({ id: 'valor' })} valueField="valor" valueFunction={this.renderValue} />
            </List>
          </Tab>
        )}
      </Tabs>
    );
  }
}

@autobind
class FormulaEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;

    let schema = Schema.object({
      idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
      formula: Schema.string().label(<FormattedMessage id="formula" />),
      dataInicio: Schema.string()
        .label(<FormattedMessage id="dataInicio" />)
        .required(),
      dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
      tipo: Schema.string().label(<FormattedMessage id="tipo" />),
      alterado: Schema.string().label(<FormattedMessage id="label.alterado" />),
      excluido: Schema.string().label(<FormattedMessage id="label.excluido" />),
      item: Schema.string().label(<FormattedMessage id="item" />),
      condicionante: Schema.string().label(capitalizeFirstLetter(resources.condicionante)),
    });

    return (
      <Edit
        url="/Formula"
        title={intl.formatMessage({ id: 'label.editorFormula' }) + ` - ${resources.condicionante}`}
        formComponent={FormEdit}
        showDelete={false}
        width="80%"
        height="580px"
        resources={resources}
        schema={schema}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(FormulaEdit));
