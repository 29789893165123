import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import formatDateFns from 'src/utils/formatDateFns';
import useAuth from 'src/hooks/useAuth';
import Confirm from 'src/componentes/NewConfirm';
import errorHandler from 'src/utils/error-handler';
import http from 'src/services/httpService';
import Spinner from 'src/componentes/spinner';

const Alert = ({ model, setIsLoadingContainer, ...rest }) => {
  const intl = useIntl();

  const { user, terms: resources } = useAuth();
  const { currentDisplay, currentLocale } = formatDateFns(user?.idioma, true);

  const [data, setData] = useState(null);

  const renderDate = (data) =>
    format(data, currentDisplay, { locale: currentLocale });

  const getData = async () => {
    try {
      const response = await http.post('Projeto/ObterPreviaBaseline', {
        projetoId: model.getModel('id').value,
      });

      setData(response.data || {});
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleGenerate = async () => {
    setIsLoadingContainer(true);

    try {
      const response = await http.post('Projeto/GerarNovaBaseline', {
        projetoId: model.getModel('id').value,
      });

      const { baselineInicial, baselineTermino } = response.data || {};

      model.getModel('baselineInicial').requestChange(baselineInicial);
      model.getModel('baselineTermino').requestChange(baselineTermino);
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoadingContainer(false);
    }
  };

  const isLoading = !data;

  return (
    <Confirm
      showCancel
      labelConfirm={intl.formatMessage({ id: 'gerarBaseline' })}
      disableConfirm={isLoading}
      primaryMessage={intl.formatMessage({
        id: 'voceEstaGerandoUmaBaseline',
      })}
      secondaryMessage={
        <>
          <div>
            {intl.formatMessage(
              {
                id: 'gerandoBaselineProjeto1',
              },
              { projeto: resources.projeto }
            )}
          </div>
          {isLoading ? (
            <Spinner style={{ marginTop: 8, marginBottom: -18 }} size={50} />
          ) : (
            <div className="font-weight-bolder my-3">
              {`${renderDate(data.baselineInicial)} - ${renderDate(
                data.baselineTermino
              )}`}
            </div>
          )}
          <div>
            {intl.formatMessage(
              {
                id: 'gerandoBaselineProjeto2',
              },
              { acoes: resources.acoes }
            )}
          </div>
        </>
      }
      onSaved={handleGenerate}
      {...rest}
    />
  );
};

export default Alert;
