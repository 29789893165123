import React from 'react';
import { useIntl } from 'react-intl';

import CheckBox from 'src/componentes/checkbox';
import Panel from 'src/componentes/panel-collapse';
import LocalidadeLookup from 'src/lookups/LocalidadeLookup';
import Radio from 'src/componentes/radio';

function DisponibilidadeMudancas({ model, disabled }) {
  const intl = useIntl();

  const options = [
    { id: 1, descricao: intl.formatMessage({ id: 'label.sim' }) },
    { id: 0, descricao: intl.formatMessage({ id: 'label.nao' }) },
  ];

  const getOption = (resposta) => {
    if (resposta == null) return null;

    return options.find((x) => x.id == resposta);
  };

  return (
    <Panel errors={model.getModel('disponivelParaMudanca').errors} open={false} header={intl.formatMessage({ id: 'disponibilidadeMudancas' })}>
      <Radio
        name="disponibilidadeMudancas"
        disabled={disabled}
        className="mb-4"
        model={{
          label: intl.formatMessage({ id: 'disponibilidadeMudancas' }),
          value: getOption(model.getValue('disponivelParaMudanca')),
          requestChange: (value) => model.getModel('disponivelParaMudanca').requestChange(value.id),
          errors: model.getModel('disponivelParaMudanca').errors,
        }}
        options={options}
        labelField="descricao"
        idField="id"
      />
      {model.getModel('disponivelParaMudanca').value == 1 && <LocalidadeLookup disabled={disabled} multi model={model.getModel('localidades')} />}
    </Panel>
  );
}

export default DisponibilidadeMudancas;
