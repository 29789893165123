import List from 'src/componentes/list/';
import ListColumn from 'src/componentes/list/list-column';
import Search from 'src/paginas/gestao-risco/auditoria/CheckList/ModeloAvaliacaoListView/Search';
import SearchSchema from 'src/paginas/gestao-risco/auditoria/CheckList/ModeloAvaliacaoListView/SearchSchema';
import { capitalizeFirstLetter } from 'src/utils/string';
import { Badge } from 'reactstrap';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import { FILTROS } from 'src/utils/constants';

const ChecklistList = ({ ...rest }) => {

  const intl = useIntl();
  const { terms: resources } = useAuth();

  const renderStatus = (item) => {
    if (!item.status) return;

    /*if (item.status.id == 1) return <Badge color="success">{item.status.descricao}</Badge>;
    else return <Badge color="secondary">{item.status.descricao}</Badge>;*/

    switch (item.status.id) {
      case 0:
        return <Badge color="danger">{item.status.descricao}</Badge>;
      case 1:
        return <Badge color="success">{item.status.descricao}</Badge>;
      case 2:
        return <Badge color="#539C51">{item.status.descricao}</Badge>;
      case 3:
        return <Badge color="warning">{item.status.descricao}</Badge>;
      default:
        return <Badge color="secondary">{item.status.descricao}</Badge>;
    }
  };

  return (
    <List
      url="/ModeloChecklist"
      showSearch={true}
      searchComponent={<Search />}
      searchSchema={SearchSchema}
      pageSize={10}
      showMenu={false}
      showNew={false}
      showExport={false}
      filtroId={FILTROS.MODELO_CHECKLIST}
      showPagination={true}
      showModal={true}
      modalClose={true}
      {...rest}
    >
      <ListColumn
        headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'idIntegracao' }))}
        valueField="idIntegracao"
        minWidth={150}
      />
      <ListColumn
        headerText={intl.formatMessage({ id: 'nome' })}
        valueField="nome"
      />
      <ListColumn
        headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'descricao' }))}
        valueField="descricao"
      />
      <ListColumn
        headerText={capitalizeFirstLetter(resources.responsavel)}
        valueField="responsaveis"
      />
      <ListColumn
        headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'status' }))}
        valueField="status"
        valueFunction={renderStatus}
      />
    </List>
  );
};

export default ChecklistList;