const getEditUrl = (type) => {
  let url = '';

  if (type === 1) {
    url = '/gerenciar/indicador/edit';
  } else if (type === 2) {
    url = '/gerenciar/parametro/edit';
  } else if (type === 3) {
    url = '/gerenciar/objetivo-estrategico/edit';
  } else if (type === 4) {
    url = '/gerenciar/projeto/edit';
  } else if (type === 6) {
    url = '/gerenciar/risco/edit';
  } else if (type === 5) {
    url = '/gerenciar/processo/edit';
  } else if (type === 10) {
    url = '/rv/gerenciar/desempenho-individual/edit';
  } else if (type === 11) {
    url = '/rv/gerenciar/calculo-intermediario/edit';
  } else if (type === 12) {
    url = '/cadastrar/template-indicador/edit';
  } else if (type === 13) {
    url = '/cadastrar/template-parametro/edit';
  } else if (type === 14) {
    url = '/cadastrar/template-objetivo-estrategico/edit';
  } else if (type === 15) {
    url = '/cadastrar/template-projeto/edit';
  } else if (type === 17) {
    url = '/gerenciar/indicador-risco/edit';
  }

  return url;
};

export default getEditUrl;
