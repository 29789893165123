import React from 'react';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Input from 'src/componentes/input';
import Checkbox from 'src/componentes/checkbox';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import StatusLookup from 'src/lookups/status-item';
import ResponsavelLookup from 'src/lookups/colaborador';
import { capitalizeFirstLetter } from 'src/utils/string';
import ProcessoLookup from 'src/lookups/processo';
import CausaRiscoLookup from 'src/lookups/causa-risco';
import ConsequenciaRiscoLookup from 'src/lookups/consequencia-risco';

class RiscoSearch extends React.Component {
  render() {
    let { model, resources, intl } = this.props;

    return (
      <div>
        <Row>
          <Col md={3}>
            <Input model={model.getModel('nome')} />
          </Col>
          <Col md={2}>
            <Input model={model.getModel('idIntegracao')} />
          </Col>
          <Col md={3}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.areas),
                value: model.getModel('areas').value,
                requestChange: model.getModel('areas').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              model={{
                label: intl.formatMessage(
                  { id: 'label.considerarAreasSubordinadas' },
                  { areas: resources.areas }
                ),
                value: model.getModel('areasSubordinadas').value,
                requestChange:
                  model.getModel('areasSubordinadas').requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <ResponsavelLookup
              model={{
                label: capitalizeFirstLetter(resources.responsavel),
                value: model.getModel('responsavel').value,
                requestChange: model.getModel('responsavel').requestChange,
              }}
            />
          </Col>
          <Col md={2}>
            <StatusLookup
              clearable
              model={model.getModel('status')}
              data={{ idsSelecionados: [1, 2, 3, 4, 5, 7] }}
            />
          </Col>
          <Col md={3}>
            <ProcessoLookup
              multi
              data={{ TipoId: 5 }}
              model={{
                label: capitalizeFirstLetter(
                  this.props.intl.formatMessage(
                    { id: 'label.processos' },
                    { processos: resources.processos }
                  )
                ),
                value: model.getModel('processos').value,
                requestChange: model.getModel('processos').requestChange,
              }}
            />
          </Col>
          <Col md={3}>
            <Checkbox
              model={{
                label: capitalizeFirstLetter(
                  this.props.intl.formatMessage(
                    { id: 'incluirSubprocessos' },
                    {
                      processosfilhosplural:
                        this.props.resources.processosfilhosplural.toLowerCase(),
                    }
                  )
                ),
                value: model.getModel('incluirSubprocessos').value,
                requestChange: model.getModel('incluirSubprocessos')
                  .requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
        </Row>
        <Row>
          {this.props.configuracao &&
            this.props.configuracao.utilizarCausaRisco && (
              <Col md={4}>
                <CausaRiscoLookup
                  multi
                  clearable
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'labelCausasDoRisco' },
                        {
                          pontosproblematicos: resources.pontosproblematicos,
                          risco: resources.risco,
                        }
                      )
                    ),
                    value: model.getModel('causasRisco').value,
                    requestChange: model.getModel('causasRisco').requestChange,
                  }}
                />
              </Col>
            )}
          {this.props.configuracao &&
            this.props.configuracao.utilizarConsequenciaRisco && (
              <Col md={4}>
                <ConsequenciaRiscoLookup
                  multi
                  clearable
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'labelConsequenciasDoRisco' },
                        {
                          consequencias: resources.consequencias,
                          risco: resources.risco,
                        }
                      )
                    ),
                    value: model.getModel('consequenciasRisco').value,
                    requestChange:
                      model.getModel('consequenciasRisco').requestChange,
                  }}
                />
              </Col>
            )}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(RiscoSearch));
