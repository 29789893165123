import FeedbackTour from 'src/components/FeedbackTour/FeedbackTour';
import { capitalizeFirstLetter } from 'src/utils/string';

const agendaPageSteps = (intl, resources, jaExisteFeedback, isLoadingFeedback) => {
  const feature = {
    id: 4,
    descricao: 'agenda',
  };

  return [
    {
      selector: '.iniciando-tour',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'iniciandoTour' })
      ),
      position: [20, 20],
    },
    {
      selector: '.agenda-step1',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'selecionePeriodoCalendario' })
      ),
    },
    {
      selector: '.agenda-step2',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'verifiquePendencias' })
      ),
    },
    {
      selector: '.agenda-step3',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'cadastreReuniao' })
      ),
    },
    {
      selector: '.agenda-step4',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'visualizeAgendaFormato' }
        )
      ),
    },
    {
      selector: '.agenda-step5',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'visualizeListaPendencias' }
        )
      ),
    },
    ...(jaExisteFeedback
      ? []
      : [
          {
            selector: '.dash-feedback',
            content: <FeedbackTour feature={feature} intl={intl} isLoadingFeedback={isLoadingFeedback} />,
            position: 'center',
          },
        ]),
  ];
};

export default agendaPageSteps;
