CanvasRenderingContext2D.prototype.fillMLText = function (text, x, y, maxWidth, lineHeight) {
  let lines = text.split('\n');
  for (let i = 0; i < lines.length; i++) {
    let words = lines[i].split(' ');
    let line = '';
    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + ' ';
      let metrics = this.measureText(testLine);
      let testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        this.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    this.fillText(line, x, y);
    y += lineHeight;
  }
};

CanvasRenderingContext2D.prototype.measureMLText = function (text, maxWidth, lineHeight, maxLines = Number.MAX_VALUE) {
  let lines = text.split('\n');
  let l = 0;
  for (let i = 0; i < lines.length; i++) {
    let words = lines[i].split(' ');
    let line = '';
    for (let n = 0; n < words.length && l < maxLines; n++) {
      let testLine = line + words[n] + ' ';
      let metrics = this.measureText(testLine);
      let testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        line = words[n] + ' ';
        if (l < maxLines) {
          l++;
        } else {
          break;
        }
      } else {
        line = testLine;
      }
    }
    if (l < maxLines) {
      l++;
    } else {
      break;
    }
  }
  if (lineHeight instanceof Array) {
    let h = 0;
    for (let x = 0; x < l; x++) {
      h += lineHeight[x];
    }
    return Math.ceil(h);
  } else {
    return Math.ceil(l * lineHeight);
  }
};

let canvas = document.createElement('canvas');
canvas.width = 500;
canvas.height = 500;
let context = canvas.getContext('2d');
export default context;
