import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import formatDateFns from 'src/utils/formatDateFns';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import { COLUNAS, FILTROS, MODULOS } from 'src/utils/constants';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import Avatar from 'src/componentes/avatar';
import MdiIcon from 'src/componentes/mdi-icon';

import EditView from '../EditView';
import SearchComponent from './SearchComponent';

const TarefasListView = ({ ...rest }) => {
  const intl = useIntl();

  const { user, terms: resources, module, modules } = useAuth();
  const { currentDisplay, currentLocale } = formatDateFns(user?.idioma);

  const renderDate = ({ dataConclusao }) =>
    dataConclusao &&
    format(dataConclusao, currentDisplay, { locale: currentLocale });

  const renderCheck = ({ concluida }) => (
    <div className="d-flex items-center">
      {concluida ? (
        <>
          <span className="font-weight-bold mr-2">
            {intl.formatMessage({ id: 'label.sim' })}
          </span>
          <MdiIcon
            style={{ marginTop: -2 }}
            className="text-primary"
            icon="check"
          />
        </>
      ) : (
        <div>{intl.formatMessage({ id: 'label.nao' })}</div>
      )}
    </div>
  );

  const renderUser = ({ responsavel }) =>
    responsavel ? (
      <Avatar
        foto={responsavel?.fotoArquivo}
        showLabel
        border={1}
        size={30}
        label={responsavel?.nome}
      />
    ) : (
      ''
    );

  const renderItem = ({ item }) => item?.nome;
  const renderCentroCusto = ({ centroCusto }) => centroCusto?.nome;
  const renderPacote = ({ pacote }) => pacote?.nome;
  const renderAcao = ({ acao }) => acao?.descricao;

  const currentModule = modules?.find((m) => m.id === module.id);
  const hasMOModule = modules.find(
    (m) => m.id === MODULOS.MATRIZ_ORCAMENTARIA && m.exibir
  );

  return (
    <List
      url="/AcaoTarefa"
      sort="id"
      showSearch
      editComponent={EditView}
      tag={COLUNAS.ACAO_TAREFA}
      filtroId={FILTROS.ACAO_TAREFA}
      searchComponent={<SearchComponent />}
      {...rest}
    >
      <ListColumn
        headerText={capitalizeFirstLetter(resources.acao)}
        valueField="acao"
        valueFunction={renderAcao}
        required
        sortField="Acao.UltimaVersao.Descricao"
        default
      />
      <ListColumn
        headerText={intl.formatMessage({ id: 'item' })}
        valueField="item"
        valueFunction={renderItem}
        sortField="Acao.Item.UltimaVersao.Nome"
        default
      />
      <ListColumn
        headerText={capitalizeFirstLetter(resources.centrocusto)}
        valueField="centroCusto"
        valueFunction={renderCentroCusto}
        sortField="Acao.CentroCusto.Nome"
        visible={
          hasMOModule && currentModule?.id === MODULOS.MATRIZ_ORCAMENTARIA
        }
        default
      />
      <ListColumn
        headerText={intl.formatMessage({ id: 'pacote' })}
        valueField="pacote"
        valueFunction={renderPacote}
        sortField="Acao.Pacote.Nome"
        visible={
          hasMOModule && currentModule?.id === MODULOS.MATRIZ_ORCAMENTARIA
        }
        default
      />
      <ListColumn
        headerText={intl.formatMessage({ id: 'concluida' })}
        valueField="concluida"
        valueFunction={renderCheck}
        required
        default
        sortField="concluida"
      />
      <ListColumn
        headerText={intl.formatMessage({ id: 'descricao' })}
        valueField="descricao"
        required
        default
        sortField="descricao"
      />
      <ListColumn
        headerText={capitalizeFirstLetter(resources.responsavel)}
        valueField="responsavel"
        valueFunction={renderUser}
        required
        default
        sortField="Responsavel.Nome"
      />
      <ListColumn
        headerText={intl.formatMessage({ id: 'data' })}
        valueField="dataConclusao"
        valueFunction={renderDate}
        required
        default
        sortField="dataConclusao"
      />
    </List>
  );
};

export default TarefasListView;
