import React from 'react';
import { autobind } from 'core-decorators';
import EditDialog from '../../../../componentes/edit/edit-dialog';
import { Schema } from '../../../../utils/form';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import GatilhoLookup from '../../../../lookups/gatilho';

let schema = Schema.object({
  gatilho: Schema.string()
    .label(<FormattedMessage id="gatilho" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  handleChange(e) {
    this.props.model.requestChange && this.props.model.requestChange(e.target.checked);
  }

  render() {
    let { model } = this.props;
    return (
      <div>
        <Row>
          <Col md={5}>
            <div style={{ marginBottom: 15 }}>
              <GatilhoLookup
                model={{
                  label: <FormattedMessage id="gatilho" />,
                  value: model.getModel('gatilho').value,
                  requestChange: model.getModel('gatilho').requestChange,
                  errors: model.getModel('gatilho').errors,
                }}
                clearable={false}
                required
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

class FuncaoEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;

    return (
      <EditDialog
        url="/ColaboradorGatilho"
        title={intl.formatMessage({ id: 'gatilho' })}
        formComponent={FormEdit}
        schema={schema}
        width="60%"
        height="40%"
        respeitarAltura
        maxHeight="90%"
        resources={resources}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(FuncaoEdit));
