import React from 'react';
import { autobind } from 'core-decorators';
import Table from '../../../../componentes/table';
import { Row, Col } from 'reactstrap';
import Button from '../../../../componentes/button';
import ItemList from '../../processo';
import ProcessoEdit from '../../processo/edit';
import ContentManager from '../../../../componentes/content-manager';
import IconButton from '../../../../componentes/icon-button';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from '../../../../utils/string';
import { connect } from 'react-redux';

@autobind
class Filhos extends React.Component {
  handleNewClick() {
    ContentManager.addContent(
      <ItemList
        parameters={{ parentId: 0, itemIdIgnorar: this.props.itemIdIgnorar }} // somente itens que não tenham pai
        showModal={true}
        multiple
        disabled
        showExport={false}
        select={true}
        selectedCallback={this.handleSelect}
        selectedIds={this.props.model.value}
      />
    );
  }

  handleDetailClick(item) {
    ContentManager.addContent(<ProcessoEdit mode="detail" item={{ id: item.id }} params={item.id}></ProcessoEdit>);
  }

  handleSelect(itens) {
    let itensAtuais = this.props.model.value.map((d) => d);
    itensAtuais.map((item) => itens.push(item));

    this.props.model.requestChange([...itens]);
  }

  handleRemove(item, index) {
    let novaLista = this.props.model.value;
    novaLista[index].excluido = true;
    this.props.model.requestChange(novaLista);
  }

  render() {
    let { model, allowNew, resources } = this.props;

    return (
      <div>
        <Row>
          <Col md={6}>{model.label && <label className="control-label">{model.label}</label>}</Col>
          <Col md={6}>
            {allowNew && (
              <Button className="pull-right" leftIcon="magnify" type="primary" style={{ marginBottom: 10 }} onClick={this.handleNewClick}>
                <FormattedMessage id="label.buscar" />
              </Button>
            )}
          </Col>
        </Row>
        {model.value && model.value.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="nome" />
                </th>
                <th>
                  <FormattedMessage id="label.tipoDoItem" />
                </th>
                <th>{capitalizeFirstLetter(resources.responsaveis)}</th>
                <th>{capitalizeFirstLetter(resources.area)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {model.value &&
                model.value.map(
                  (item, index) =>
                    !item.excluido && (
                      <tr>
                        <td>{item.nome}</td>
                        <td>{capitalizeFirstLetter(resources[item.tipo.resources])}</td>
                        <td>{item.responsaveis}</td>
                        <td>{item.area}</td>
                        <td style={{ width: 40 }}>
                          {allowNew && <IconButton icon="close-circle" className="clarear" onClick={this.handleRemove.bind(this, item, index)} />}
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </Table>
        ) : (
          <i>
            <FormattedMessage id="label.naoHaItensRelacionados" />
          </i>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(Filhos);
