import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select-async';
import AcaoList from '../paginas/acao/view';

@autobind
class AcaoLookup extends React.Component {
  render() {
    return <Lookup showSearch labelKey="descricao" valueKey="id" url="/AcaoLookup" clearable={true} searchComponent={AcaoList} {...this.props} />;
  }
}

export default AcaoLookup;
