import React from 'react';
import http from 'src/services/httpService';
import { autobind } from 'core-decorators';
import Button from '../button';
import { FormattedMessage } from 'react-intl';
import { Form } from '../../utils/form';
import { keyDownNew, keydown, KEYS } from '../../utils/keydown-decorator';
import Dialog from '../../componentes/dialog';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

@autobind
class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFilter:
        this.props.modelValue &&
        !(this.props.selectedFilter && this.props.selectedFilter.valor)
          ? this.props.modelValue
          : this.props.selectedFilter,
      ignorarCamposCustomizados: this.props.ignorarCamposCustomizados,
    };

    let model;

    if (this.props.modelValue) {
      model =
        this.props.modelValue &&
        !(this.props.selectedFilter && this.props.selectedFilter.valor)
          ? this.props.modelValue
          : this.props.selectedFilter && this.props.selectedFilter.valor;
    } else {
      model = this.props.selectedFilter ? this.props.defaultFilter : {};
    }

    this.form = new Form({
      component: this,
      schema: this.props.searchSchema,
      model: model
        ? model
        : this.props.modelValue &&
          !(this.props.selectedFilter && this.props.selectedFilter.valor)
        ? this.props.modelValue
        : this.props.selectedFilter && this.props.selectedFilter.valor,
    });
  }

  async componentDidMount() {
    let { currentFilter, ignorarCamposCustomizados } = this.state;
    if (currentFilter && currentFilter.valor && !ignorarCamposCustomizados) {
      await this.obterCamposCustomizados();
    } else {
      this.setState({
        currentFilter: {
          ...this.state.currentFilter,
          valor: this.props.search,
        },
      });
    }

    if (!ignorarCamposCustomizados) this.obterCamposCustomizados();
  }

  /**
   * /CampoCustomizado/ObterListaCamposCustomizados não está trazendo os valores salvo do filtro, apenas a listagem de consulta.
   * FiltroColaborador/IdColaborador/AbrirUltimoFiltro - A requisição que trás os valores salvo do filtro.
   * @return a listagem de consulta de ObterListaCamposCustomizados mais os valores salvo do filtro
   */
  mergeFilteredVlrs = (currentFilter, listaCamposCustomizados) => {
    const currentFilterClone = cloneDeep(currentFilter); // forçar imutabilidade
    const listaCamposCustomizadosClone = cloneDeep(listaCamposCustomizados);
    try {
      for (
        let i = 0;
        i < currentFilterClone.valor.camposCustomizados.length;
        i++
      ) {
        const e = currentFilterClone.valor.camposCustomizados[i];
        let campoCustomizado = listaCamposCustomizadosClone.find(
          (l) => l.idCampo == e.idCampo
        );
        if (campoCustomizado) {
          campoCustomizado.campo.valores = e.campo.valores;
          campoCustomizado.valor = e.valor;
        }
      }
    } catch (err) {}

    return listaCamposCustomizadosClone;
  };

  obterCamposCustomizados() {
    const currentFilter = this.state.currentFilter;
    if (this.props.tipoItem) {
      http
        .post(`/CampoCustomizado/ObterListaCamposCustomizados`, {
          tipoItem: this.props.tipoItem,
        })
        .then((response) => {
          if (
            this.form.getModel('camposCustomizados') &&
            response.data.length > 0
          ) {
            let lista = this.form.getModel('camposCustomizados').value;
            if (
              !lista ||
              lista == undefined ||
              (lista != null &&
                lista != undefined &&
                (lista[0].tipoItem == response.data[0].tipoItem ||
                  lista[0].campo.tipoItem == response.data[0].tipoItem))
            ) {
              this.setState(
                {
                  currentFilter: {
                    ...currentFilter,
                    valor: {
                      camposCustomizados: this.mergeFilteredVlrs(
                        currentFilter,
                        response.data
                      ),
                    },
                  },
                },
                () => {
                  this.form
                    .getModel('camposCustomizados')
                    .requestChange(
                      this.mergeFilteredVlrs(currentFilter, response.data)
                    );
                  //  this.form.setValue(null, this.state.currentFilter.valor)
                }
              );
            } else {
              this.form.getModel('camposCustomizados').requestChange(null);
            }
          }
        });
    } else {
      this.form.getModel('camposCustomizados').requestChange(null);
    }
  }

  @keyDownNew(true, KEYS.ENTER)
  async handleSaveFilter() {
    let { currentFilter } = this.state;
    if (this.props.onChangeUrl && this.props.modelValue) {
      this.props.onChangeUrl(this.form.getValue());
    } else {
      let novoFiltro = currentFilter;
      novoFiltro.valor = this.form.getValue();

      if (this.props.usarFiltroSalvo) {
        await http
          .post(`/FiltroColaborador/${this.props.filtroId}/SalvarFiltro`, {
            ...novoFiltro,
            filtroId: this.props.filtroId,
          })
          .then((response) => {
            this.props.onSearch && this.props.onSearch(response.data);
          })
          .catch(() => {});
      } else {
        this.props.onSearch && this.props.onSearch(this.form.getValue(), true);
      }
    }
    this.refs.dialog.close();
  }

  handleClearFilter() {
    const fields = this.form && this.form.getValue();

    for (var item in fields) {
      var campo = this.form.getModel(item);

      if (campo.value === true || campo.value === false) {
        this.form.setValue(item, false);
      } else if (item === 'camposCustomizados') {
        const array = this.form.getModel('camposCustomizados').value;
        const newList = Object.assign([], array);

        newList.map((campoCustomizado) => {
          if (campoCustomizado.campo.multiSelecao) {
            campoCustomizado.campo.valores = null;
          } else if (campoCustomizado.campo.tipo.id === 5) {
            campoCustomizado.valor = null;
          } else {
            campoCustomizado.valor = '';
          }
        });

        this.form.getModel('camposCustomizados').requestChange(newList);
      } else {
        this.form.setValue(item, null);
      }
    }
  }

  render() {
    let { searchComponent, model, intl, ...other } = this.props;
    const actions = [
      <Button
        onClick={this.handleSaveFilter}
        className="pull-right"
        leftIcon="magnify"
      >
        <FormattedMessage id="label.filtrar"></FormattedMessage>
      </Button>,
    ];

    const footerButton = (
      <div className="pt-1">
        <a className="text-primary" onClick={this.handleClearFilter}>
          {intl.formatMessage({ id: 'limparTodos' })}
        </a>
      </div>
    );

    const modelDefault = this.form.getModel();
    return (
      <Dialog
        title={intl.formatMessage({ id: 'filtros' })}
        ref="dialog"
        {...this.props}
        actions={actions}
        footer={footerButton}
      >
        <div
          style={{
            minHeight: 200,
            minWidth: 500,
            maxHeight: 600,
            maxWidth: 1000,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          {searchComponent &&
            React.cloneElement(searchComponent, {
              ...other,
              model: model ? model : modelDefault,
            })}
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter,
  };
}
export default injectIntl(connect(mapStateToProps)(Filter));
