import React from 'react';
import { autobind } from 'core-decorators';
import http from 'src/services/httpService';
import { Row, Col } from 'reactstrap';
import Input from '../../../componentes/input';
import ColaboradorSimplificadoLookup from '../../../lookups/ColaboradorSimplificadoLookup';
import ItemLookup from '../../../lookups/item';
import CheckBox from '../../../componentes/checkbox';
import { Form, Schema } from '../../../utils/form';
import Button from '../../../componentes/button';
import Dialog from '../../../componentes/dialog';
import ArquivoUploadAnexos from '../../../componentes/arquivo-upload/anexos';
import { FormattedMessage, injectIntl } from 'react-intl';
import ContentManager from '../../../componentes/content-manager';
import Alert from '../../../componentes/message-box/alert';
import { capitalizeFirstLetter } from '../../../utils/string';
import { connect } from 'react-redux';
import AcaoLookup from '../../../lookups/acao';
import errorHandler from '../../../utils/error-handler';

let schema = Schema.object({
  colaboradores: Schema.array()
    .label(<FormattedMessage id="label.para" />)
    .required(),
  texto: Schema.string().label(<FormattedMessage id="mensagem" />),
  enviarEmail: Schema.string().label(
    <FormattedMessage id="label.enviarCopiaPorEmail" />
  ),
  itens: Schema.array().label(<FormattedMessage id="label.itens" />),
  acoes: Schema.array().label(),
  relatorioAcompanhamentoId: Schema.string().label(),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  itemValorId: Schema.string().label(),
  dataPeriodo: Schema.string().label(),
  periodo: Schema.string().label(),
  ano: Schema.string().label(),
});

@autobind
class MensagemModal extends React.Component {
  static defaultProps = {
    tipoLookup: 'item',
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      isLoading: false,
      showModal: true,
      exibir: false,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    if (this.props.colaborador) {
      this.form
        .getModel('colaboradores')
        .requestChange([this.props.colaborador]);
    }

    if (this.props.fromItem)
      http
        .post(`Item/ObterPermissao`, {
          id: this.props.item && this.props.item.id,
        })
        .then((response) => {
          this.setState(
            {
              exibir: true,
            },
            () => {
              this.obterItem();
            }
          );
        })
        .catch((error) => {
          this.setState({
            exibir: false,
          });
          errorHandler(error);
          this.handleClose();
        });
    else {
      if (this.props.fromAcao) {
        this.setState({ exibir: true }, () => {
          this.obterAcao();
        });
      } else {
        this.setState({ exibir: true });
      }
    }
  }

  obterItem() {
    if (this.props.item) {
      http
        .post(`/Item/ObterItemPorId`, {
          id: this.props.item.id,
        })
        .then((response) => {
          let itens = this.form.getModel('itens').value;
          if (itens && itens.length > 0) {
            itens.concat(response.data);
          } else {
            itens = [].concat(response.data);
          }
          this.form.getModel('itens').requestChange(itens);
        });
    }

    this.props.relatorioAcompanhamentoId &&
      this.form
        .getModel('relatorioAcompanhamentoId')
        .requestChange(this.props.relatorioAcompanhamentoId);
    this.props.itemValorId &&
      this.form.getModel('itemValorId').requestChange(this.props.itemValorId);
    this.props.dataPeriodo &&
      this.form.getModel('dataPeriodo').requestChange(this.props.dataPeriodo);
    this.props.periodo &&
      this.form.getModel('periodo').requestChange(this.props.periodo);
    this.props.ano && this.form.getModel('ano').requestChange(this.props.ano);
  }

  obterAcao() {
    if (this.props.acaoId) {
      http
        .post(`/Acao/ObterAcaoPorId`, {
          acaoId: this.props.acaoId,
        })
        .then((response) => {
          let acoes = this.form.getModel('acoes').value;
          if (acoes && acoes.length > 0) {
            acoes.concat(response.data);
          } else {
            acoes = [].concat(response.data);
          }
          this.form.getModel('acoes').requestChange(acoes);
        });
    }
  }

  handleClose() {
    this.refs.dialog.close();
  }

  handleSalvarClick() {
    http
      .post(`/Mensagem/SalvarMensagem`, {
        model: this.form.getValue(),
      })
      .then(() => {
        this.setState({
          isLoading: true,
        });

        ContentManager.addContent(
          <Alert
            title={capitalizeFirstLetter(
              this.props.intl.formatMessage({ id: 'sucesso' })
            )}
            message={<FormattedMessage id="label.mensagemSucesso" />}
          />
        );
        this.refs.dialog.close();
      })
      .catch((error) => { 
        errorHandler(error);
        this.setState({
          isLoading: false,
        });
      });
  }

  renderLookup() {
    let { tipoLookup } = this.props;

    if (tipoLookup === 'item') {
      return <ItemLookup multi={true} model={this.form.getModel('itens')} />;
    } else if (tipoLookup === 'acao') {
      return (
        <AcaoLookup
          multi
          model={{
            label: capitalizeFirstLetter(this.props.resources.acoes),
            value: this.form.getModel('acoes').value,
            requestChange: this.form.getModel('acoes').requestChange,
          }}
          data={{ itemId: this.props.itemId }}
        />
      );
    }
  }

  render() {
    let actions = [
      <Button key={1} onClick={this.handleClose} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>,
      <Button
        key={2}
        bsStyle="primary"
        className="ml-2"
        onClick={this.handleSalvarClick}
      >
        {<FormattedMessage id="label.enviar" />}
      </Button>,
    ];

    return (
      <Dialog
        className="popupOpen"
        title={<FormattedMessage id="enviarMensagem" />}
        actions={actions}
        width={700}
        height={505}
        padContent
        onRequestClose={this.handleClose}
        mouseDownClose={false}
        ref="dialog"
        {...this.props}
      >
        {this.state.exibir === true && (
          <div>
            <Row>
              <Col md={12}>
                <ColaboradorSimplificadoLookup
                  required
                  multi={true}
                  model={this.form.getModel('colaboradores')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Input
                  type="textarea"
                  rows={5}
                  model={this.form.getModel('texto')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>{this.renderLookup()}</Col>
            </Row>
            <Row>
              <Col md={2}>
                <ArquivoUploadAnexos
                  tipoAnexo={this.props.tiposAnexos.mensagem}
                  model={this.form.getModel('arquivos')}
                  iconSize="24"
                />
              </Col>
              <Col md={10}>
                <CheckBox
                  style={{ float: 'left', margin: 10 }}
                  model={this.form.getModel('enviarEmail')}
                ></CheckBox>
              </Col>
            </Row>
          </div>
        )}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    tiposAnexos: state.user.tiposAnexos,
  };
}
export default injectIntl(connect(mapStateToProps)(MensagemModal));
