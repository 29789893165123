import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../../componentes/dialog';
import { Schema, Form } from '../../../utils/form';
import http from 'src/services/httpService';
import LoadingContainer from '../../../componentes/loading-container';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import Radio from '../../../componentes/radio';
import MdiIcon from '../../../componentes/mdi-icon';
import Preview from '../../faixa-farol/preview';
import TabelaValorLookup from '../../../lookups/tabela-valor';
import PreviewTabelaValor from '../../tabela-valor/preview';
import FaixaFarolLookup from '../../../lookups/faixa-farol';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';
import DropdownItemList from '../../item/dropdown-item';
import errorHandler from '../../../utils/error-handler';
import PanelCollapse from 'src/componentes/panel-collapse';
import Avatar from 'src/componentes/avatar';
import AvatarGroup from 'src/componentes/AvatarGroup';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import CadastroContent from 'src/components/AbasFichaItem/CadastroContent';
import PontosFaixaPorPeriodoOuAcumulado from 'src/components/AbasFichaItem/PontosFaixaPorPeriodoOuAcumulado';
import Info from 'src/componentes/info';

let schema = Schema.object({
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  area: Schema.string().label(<FormattedMessage id="area" />),
  meta: Schema.number().label(
    <FormattedMessage id="label.metaUltimoPeriodo" />
  ),
  realizado: Schema.number().label(
    <FormattedMessage id="label.realizadoUltimoPeriodo" />
  ),
  metaAcumulado: Schema.number().label(),
  realizadoAcumulado: Schema.number().label(
    <FormattedMessage id="realizadoAcumulado" />
  ),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  melhor: Schema.string().label(<FormattedMessage id="label.polaridade" />),
  responsaveis: Schema.array().label(<FormattedMessage id="responsaveis" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  unidadeMedida: Schema.string().label(
    <FormattedMessage id="unidadeDeMedida" />
  ),
  faixa: Schema.string().label(<FormattedMessage id="faixaDeFarol" />),
  tabelaValor: Schema.string().label(<FormattedMessage id="listaRegistros" />),
  farol: Schema.string().label(''),
  farolAcumulado: Schema.string().label(''),
  pontoFaixaResultado: Schema.string().label(''),
  pontoFaixaResultadoPeriodo: Schema.string().label(''),
  pontoFaixaResultadoYTD: Schema.string().label(''),
  faixas: Schema.string().label(''),
  exibirValores: Schema.string().label(''),
  exibirFaixaFarol: Schema.string().label(''),
  exibirPontosFaixaResultado: Schema.string().label(''),
  exibirCamposCustomizados: Schema.string().label(''),
});

@autobind
class FichaBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({
      isLoading: true,
    });
    //http.post(`/ObjetivoEstrategico/ObterDadosFicha`, {
    http
      .post(this.props.obterDadosFichaUrl, {
        itemId: this.props.id,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.form.setValue(null, response.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        this.handleClose();
        errorHandler(error);
      });
  }

  handleLabelMelhor(item) {
    return (
      <span>
        <MdiIcon icon={item.icone} size={16}></MdiIcon>
      </span>
    );
  }

  handleClose() {
    this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
    }, 300);
  }

  handleMenuClick() {
    this.handleClose();
  }

  handleDesenharCampoCustomizado(item) {
    const visible = item.campo.visivel == null || item.campo.visivel;

    if (!visible) return;

    if (item.campo.tipo.id !== 5) {
      return (
        <Row className="mb-3">
          <Col md={3} className="font-weight-bolder">
            {item.campo.nome}
          </Col>
          <Col md={9}>{item.valor}</Col>
        </Row>
      );
    }
    if (item.campo.tipo.id === 5) {
      if (item.campo.multiSelecao) {
        return (
          <Row className="mb-3 d-flex align-items-center">
            <Col md={3} className="font-weight-bolder">
              {item.campo.nome}
            </Col>
            <Col md={9}>
              {item.campo.valores &&
                item.campo.valores.map(({ id, descricao }) => (
                  <div>{descricao}</div>
                ))}
            </Col>
          </Row>
        );
      } else {
        let value =
          item.campo.opcoesTabela &&
          item.campo.opcoesTabela.find((op) => op.id == item.valor)
            ? item.campo.opcoesTabela.find((op) => op.id == item.valor)
            : null;

        return (
          <Row className="mb-3">
            <Col md={3} className="font-weight-bolder">
              {item.campo.nome}
            </Col>
            <Col md={9}>{value?.descricao}</Col>
          </Row>
        );
      }
    }
  }

  getColorFromFarol(farol) {
    if (!farol || !farol.id || farol.id == 4 || farol.id == 3) {
      return '#000000';
    }

    return '#FFFFFF';
  }

  render() {
    let model = this.form.getModel();
    let { resources, intl, showActions = true } = this.props;

    let actions = (
      <UncontrolledDropdown className="ml-2 d-inline" key="indicador-ficha">
        <DropdownToggle color="secondary">
          <MdiIcon icon="dots-horizontal" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItemList
            exibirFicha={false}
            resources={resources}
            item={{ ...model.getValue(), tipo: { id: 3 } }}
            callback={this.handleMenuClick}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    return (
      <Dialog
        title={
          model.getModel('nome').value
            ? model.getModel('nome').value
            : intl.formatMessage(
                { id: 'label.fichaObjetivoEstrategico' },
                { objetivoestrategico: resources.objetivoestrategico }
              )
        }
        ref="dialog"
        padContent
        footer={showActions && actions}
        width={900}
        height={450}
        onRequestClose={this.handleClose}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <div className="pb-3">
            <PanelCollapse
              open={false}
              header={capitalizeFirstLetter(
                intl.formatMessage({ id: 'dadosCadastro' })
              )}
            >
              <Row>
                <Col md={3} className="font-weight-bolder">
                  {model.getModel('idIntegracao').label}
                </Col>
                <Col md={9}>{model.getModel('idIntegracao').value}</Col>
              </Row>
              <Row className="my-3">
                <Col md={3} className="font-weight-bolder">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'unidadeDeMedida' })
                  )}
                </Col>
                <Col md={9}>
                  {model.getModel('unidadeMedida').value?.descricao}
                </Col>
              </Row>
              <Row className="my-3">
                <Col md={3} className="font-weight-bolder">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'periodicidade' })
                  )}
                </Col>
                <Col md={9}>
                  {model.getModel('periodicidade').value?.descricao}
                </Col>
              </Row>
              <Row className="my-3 d-flex align-items-center">
                <Col md={3} className="font-weight-bolder">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'responsaveis' })
                  )}
                </Col>
                <Col md={9}>
                  {model.getModel('responsaveis').value && (
                    <AvatarGroup max={15}>
                      {model.getModel('responsaveis').value.map((item) => (
                        <Avatar
                          showTooltip
                          id={`avatar-${item.id}`}
                          foto={item.fotoArquivo}
                          label={item.nome}
                        />
                      ))}
                    </AvatarGroup>
                  )}
                </Col>
              </Row>

              {model.getModel('utilizarDigitadorValor').value && (
                <Row className="my-3 d-flex align-items-center">
                  <Col md={3} className="font-weight-bolder">
                    {capitalizeFirstLetter(resources.digitadores)}
                  </Col>
                  <Col md={9}>
                    {model.getModel('digitadores').value && (
                      <AvatarGroup max={15}>
                        {model.getModel('digitadores').value.map((item) => (
                          <Avatar
                            showTooltip
                            id={`avatar-${item.id}`}
                            foto={item.fotoArquivo}
                            label={item.nome}
                          />
                        ))}
                      </AvatarGroup>
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={3} className="font-weight-bolder">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'descricao' })
                  )}
                </Col>
                <Col md={9}>{model.getModel('descricao').value}</Col>
              </Row>
            </PanelCollapse>
            {model.getModel('exibirValores').value && (
              <PanelCollapse
                open={false}
                header={
                  <>
                    {capitalizeFirstLetter(intl.formatMessage({ id: 'valoresNumericos' }))}
                    {model.getModel('descricaoPeriodo').value && (
                      <Info
                        id="descricaoPeriodoId"
                        className="ml-2"
                        text={model.getModel('descricaoPeriodo').value}
                      />
                    )}
                  </>
                }
              >
                <Row>
                  <Col
                    md={3}
                    className="font-weight-bolder d-inline-flex align-items-center"
                  >
                    {capitalizeFirstLetter(resources.meta)}
                  </Col>
                  <Col md={3}>
                    <div className="d-inline-flex align-items-center justify-content-center px-3 py-1 rounded-xl w-100">
                      {model.getModel('meta').value}
                    </div>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col
                    md={3}
                    className="font-weight-bolder d-inline-flex align-items-center"
                  >
                    {capitalizeFirstLetter(resources.metaacumulado)}
                  </Col>
                  <Col md={3}>
                    <div className="d-inline-flex align-items-center justify-content-center px-3 py-1 rounded-xl w-100">
                      {model.getModel('metaAcumulado').value}
                    </div>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col
                    md={3}
                    className="font-weight-bolder d-inline-flex align-items-center"
                  >
                    {capitalizeFirstLetter(
                      intl.formatMessage({ id: 'realizado' })
                    )}
                  </Col>
                  <Col md={3}>
                    <div
                      style={{
                        background: model.getModel('farol').value
                          ? model.getModel('farol').value.cor
                          : '#F0F0F0',
                        color: this.getColorFromFarol(
                          model.getModel('farol').value
                        ),
                        flexDirection: 'column',
                      }}
                      className="d-inline-flex align-items-center justify-content-center px-3 py-1 rounded-xl w-100"
                    >
                      {model.getModel('realizado').value}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={3}
                    className="font-weight-bolder d-inline-flex align-items-center"
                  >
                    {capitalizeFirstLetter(
                      intl.formatMessage({ id: 'realizadoAcumulado' })
                    )}
                  </Col>
                  <Col md={3}>
                    <div
                      style={{
                        background: model.getModel('farolAcumulado').value
                          ? model.getModel('farolAcumulado').value.cor
                          : '#F0F0F0',
                        color: this.getColorFromFarol(
                          model.getModel('farolAcumulado').value
                        ),
                        flexDirection: 'column',
                      }}
                      className="d-inline-flex align-items-center justify-content-center px-3 py-1 rounded-xl w-100"
                    >
                      {model.getModel('realizadoAcumulado').value}
                    </div>
                  </Col>
                </Row>
              </PanelCollapse>
            )}
            {model.getModel('exibirFaixaFarol').value && (
              <PanelCollapse
                open={false}
                header={capitalizeFirstLetter(
                  intl.formatMessage({ id: 'faixaDeFarol' })
                )}
              >
                <Row>
                  <Col md={12}>
                    {model.getModel('usarTabelaValores').value ? (
                      <div>
                        <Row>
                          <Col md={12}>
                            <TabelaValorLookup
                              model={model.getModel('tabelaValor')}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            {model.getModel('tabelaValor').value && (
                              <PreviewTabelaValor
                                exibirFarol={true}
                                model={model
                                  .getModel('tabelaValor')
                                  .getModel('itens')}
                              ></PreviewTabelaValor>
                            )}
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Row>
                          <Col md={6}>
                            <FaixaFarolLookup
                              model={{
                                label: capitalizeFirstLetter(resources.faixa),
                                value: model.getModel('faixa').value,
                                requestChange:
                                  model.getModel('faixa').requestChange,
                              }}
                              disabled={true}
                            />
                          </Col>
                          <Col md={6}>
                            <Radio
                              model={{
                                label: capitalizeFirstLetter(
                                  resources.polaridade
                                ),
                                value: model.getModel('melhor').value,
                                requestChange:
                                  model.getModel('melhor').requestChange,
                              }}
                              options={model.value.opcoesMelhor}
                              labelFunction={this.handleLabelMelhor}
                              disabled={true}
                              labelField="descricao"
                              idField="id"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            {model.getModel('faixa').value &&
                              model.getModel('melhor').value &&
                              (model.getModel('melhor').value.id == 1 ? (
                                <Preview
                                  model={model
                                    .getModel('faixa')
                                    .getModel('faixaMaior')}
                                ></Preview>
                              ) : model.getModel('melhor').value.id == 2 ? (
                                <Preview
                                  model={model
                                    .getModel('faixa')
                                    .getModel('faixaMenor')}
                                ></Preview>
                              ) : (
                                <Preview
                                  model={model
                                    .getModel('faixa')
                                    .getModel('faixaIgual')}
                                ></Preview>
                              ))}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </PanelCollapse>
            )}
            {model.getModel('exibirPontosFaixaResultado').value &&
              model.getModel('pontoFaixaResultado').value && (
                <PanelCollapse
                  open={false}
                  header={capitalizeFirstLetter(
                    intl.formatMessage({ id: 'label.pontosFaixaResultado' })
                  )}
                >
                  <Tabs>
                    <Tab label={intl.formatMessage({ id: 'cadastro' })} key="1">
                      <CadastroContent
                        model={model}
                        resources={resources}
                        intl={intl}
                      />
                    </Tab>
                    <Tab label={intl.formatMessage({ id: 'periodo' })} key="2">
                      <PontosFaixaPorPeriodoOuAcumulado
                        tipo="pontos"
                        model={model.value}
                        resources={resources}
                        intl={intl}
                      />
                    </Tab>
                    <Tab
                      label={intl.formatMessage({ id: 'yearToDate' })}
                      key="3"
                    >
                      <PontosFaixaPorPeriodoOuAcumulado
                        tipo="pontosAcumulado"
                        model={model.value}
                        resources={resources}
                        intl={intl}
                      />
                    </Tab>
                  </Tabs>
                </PanelCollapse>
              )}
            {model.getModel('exibirCamposCustomizados').value && (
              <PanelCollapse
                open={false}
                header={capitalizeFirstLetter(
                  intl.formatMessage({ id: 'camposCustomizados' })
                )}
              >
                {model.getModel('camposCustomizados').value &&
                  model
                    .getModel('camposCustomizados')
                    .value.map((item) =>
                      this.handleDesenharCampoCustomizado(item)
                    )}
              </PanelCollapse>
            )}
          </div>
        </LoadingContainer>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    modulosComprados: state.user.licenca.modulos,
  };
}
export default injectIntl(connect(mapStateToProps)(FichaBase));
