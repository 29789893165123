import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select2';

@autobind
class NivelAcaoLookup extends React.Component {
  render() {
    return <Lookup showSearch={false} labelKey="descricao" valueKey="id" url="/NivelAcaoLookup" clearable={true} {...this.props} />;
  }
}

export default NivelAcaoLookup;
