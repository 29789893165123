import React from 'react';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { getModsByCompType } from './util';
import Month from './month';
import './style/index.css';
import Button from '../../../componentes/button';
import MdiIcon from '../../../componentes/mdi-icon';
import css from '../../../utils/css';
import { connect } from 'react-redux';

const classes = css`
  .header {
    display: flex;
    padding: 8px 5px;
    align-items: center;
    font-size: 15px;
  }

  .year {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    > * {
      margin: 0 5px;
    }
  }

  .month {
    margin-top: 15px;
  }
`;

@autobind
class Calendar extends React.Component {
  static defaultProps = {
    locale: 'pt-BR',
    yearHeaderFormat: 'YYYY',
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      date: this.props.date || moment().startOf('year'),
      startDate: this.props.startDate || moment().startOf('year'),
      endDate: this.props.endDate || moment().endOf('year'),
    };
  }

  moment() {
    const localMoment = moment.apply(null, arguments);

    if (this.props.userLocale.toLowerCase() == 'es-mx') {
      localMoment.locale('es');
    } else {
      localMoment.locale(this.props.locale);
    }

    return localMoment;
  }

  renderHeader() {
    return (
      <header key="header">
        {this.moment(this.state.date).format(this.props.yearHeaderFormat)}
      </header>
    );
  }

  getMonthRange() {
    const focus = this.moment(this.state.date || this.state.startDate).startOf(
      'month'
    );
    const start = this.moment(this.state.startDate);
    const end = this.moment(this.state.endDate);
    const size = end.diff(start, 'month') + 1;
    return Array(size)
      .fill(0)
      .map((n, i) => focus.clone().add(n + i, 'months'));
  }

  handleClickNext() {
    let year = this.moment(this.state.date).year();
    this.setState({
      date: new Date(year + 1, 0, 1),
      startDate: new Date(year + 1, 0, 1),
      endDate: new Date(year + 1, 11, 1),
    });

    this.props.onRefreshYearCurrent &&
      this.props.onRefreshYearCurrent(year + 1);
  }

  handleClickPrevious() {
    let year = this.moment(this.state.date).year();
    this.setState({
      date: new Date(year - 1, 0, 1),
      startDate: new Date(year - 1, 0, 1),
      endDate: new Date(year - 1, 11, 1),
    });

    this.props.onRefreshYearCurrent &&
      this.props.onRefreshYearCurrent(year - 1);
  }

  render() {
    const { mods } = this.props;
    const monthMods = getModsByCompType('month', mods);
    let weekMods = getModsByCompType('week', mods);
    let dayMods = getModsByCompType('day', mods);

    return (
      <div>
        <div className={classes.header}>
          <Button color="defualt" onClick={this.handleClickPrevious}>
            <MdiIcon icon="chevron-left" />
          </Button>
          <div className={classes.year}>{this.renderHeader()}</div>
          <Button color="defualt" onClick={this.handleClickNext}>
            <MdiIcon icon="chevron-right" />
          </Button>
        </div>
        <div className={classes.month}>
          {this.getMonthRange().map((date, i) => (
            <Month
              key={`month-${i}`}
              date={date}
              weekNumbers={this.props.weekNumbers}
              mods={monthMods}
              week={weekMods}
              day={dayMods}
              monthNameFormat="MMMM"
            />
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    userLocale: state.user.locale,
  };
}
export default connect(mapStateToProps)(Calendar);
