import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';

import { capitalizeFirstLetter } from 'src/utils/string';
import ControleLookup from 'src/lookups/ControleLookup';
import RiscoLookup from 'src/lookups/item';
import PeriodicidadeLookup from 'src/lookups/tipo-periodicidade-ponto-controle';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import TipoPontoControle from 'src/lookups/tipo-ponto-controle';
import Input from 'src/componentes/input';
import InputNumber from 'src/componentes/input-number';
import TipoToleranciaLookup from 'src/lookups/tipo-tolerancia';
import PeriodoData from 'src/paginas/data/periodo-data';
import StatusRevisaoLookup from 'src/lookups/status-risco-ponto-controle-revisao';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import ProcessoLookup from 'src/lookups/processo';
import AgingLookup from 'src/lookups/aging-risco-ponto-controle-revisao';
import Checkbox from 'src/componentes/checkbox';
import FarolRevisaoLookup from 'src/lookups/farol-risco-ponto-controle-revisao';
import ConformidadeLookup from 'src/lookups/ConformidadeRevisao';
import TipoEvidenciaLookup from 'src/lookups/tipo-evidencia';
import FuncaoPontoControlelookup from 'src/lookups/funcao-pontocontrole';
import TipoDeControleLookup from 'src/lookups/tipo-de-controle';
import FiltroAnexoLookup from 'src/lookups/filtro-anexo';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';

@autobind
class RevisoesGerenciarSearch extends React.Component {
  handleChangeFiltro(field, value) {
    let { model } = this.props;
    model.getModel(field).requestChange(value);
  }

  render() {
    const { model, resources, intl, config, hideControlesFilter } = this.props;

    if (config) 
      var { utilizarTipoEvidencia, utilizarAuditoria, utilizarTipoPontoControle, funcaoPontoControle, tipoDeControle } = config;

    const showRiscoFilter = !this.props.parameters || !this.props.parameters.idRiscoPontoControle;
    const showControlesFilters = !hideControlesFilter;

    return (
      <div>
        <Row>
          <Col md={6}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(intl.formatMessage({ id: 'areasDoRisco' }, { areas: resources.areas, risco: resources.risco })),
                value: model.getModel('areas').value,
                requestChange: model.getModel('areas').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              model={{
                label: intl.formatMessage({ id: 'label.considerarAreasSubordinadas' }, { areas: resources.areas }),
                value: model.getModel('areasSubordinadas').value,
                requestChange: model.getModel('areasSubordinadas').requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
        </Row>
        <Row>
          {showControlesFilters && (
          <Col md={6}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(intl.formatMessage({ id: 'areasDoControle' }, { areas: resources.areas, pontodecontrole: resources.pontodecontrole })),
                value: model.getModel('areasControle').value,
                requestChange: model.getModel('areasControle').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>  
          )}      
        </Row>
        <Row>
          <Col md={4}>
            <ProcessoLookup
              multi
              data={{ TipoId: 5 }}
              model={{
                label: capitalizeFirstLetter(this.props.intl.formatMessage({ id: 'label.processos' }, { processos: resources.processos })),
                value: model.getModel('processos').value,
                requestChange: model.getModel('processos').requestChange,
              }}
            />
          </Col>
          <Col md={5}>
            <Checkbox
              model={{
                label: capitalizeFirstLetter(
                  this.props.intl.formatMessage({ id: 'incluirSubprocessos' }, { processosfilhosplural: this.props.resources.processosfilhosplural.toLowerCase() })
                ),
                value: model.getModel('incluirSubprocessos').value,
                requestChange: model.getModel('incluirSubprocessos').requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
        </Row>
        <Row>
          {showRiscoFilter && (
            <Col md={4}>
              <RiscoLookup
                clearable
                data={{ tipoId: 6 }}
                model={{
                  label: capitalizeFirstLetter(resources.risco),
                  value: model.getModel('risco').value,
                  requestChange: model.getModel('risco').requestChange,
                }}
              />
            </Col>
          )}
          {showControlesFilters && (
            <Col md={4}>
              <ControleLookup
                multi
                clearable
                model={{
                  label: capitalizeFirstLetter(resources.pontosdecontrole),
                  value: model.getModel('controles').value,
                  requestChange: model.getModel('controles').requestChange,
                }}
              />
            </Col>
          )}
          <Col md={4}>
            <AgingLookup
              clearable
              model={{
                label: intl.formatMessage({ id: 'aging' }),
                value: model.getModel('aging').value,
                requestChange: model.getModel('aging').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FarolRevisaoLookup multi clearable model={model.getModel('farois')} />
          </Col>
          <Col md={4}>
            <ConformidadeLookup clearable model={model.getModel('conformidade')} />
          </Col>
        </Row>
        {utilizarAuditoria && (
          <Row>
            <Col md={4}>
              <Checkbox
                style={{ marginTop: 35 }}
                model={{
                  label: intl.formatMessage({ id: 'exibirAuditados' }),
                  value: model.getModel('exibirAuditados').value,
                  requestChange: model.getModel('exibirAuditados').requestChange,
                }}
              />
            </Col>
            <Col md={4}>
              <Checkbox
                style={{ marginTop: 35 }}
                model={{
                  label: intl.formatMessage({ id: 'exibirNaoAuditados' }),
                  value: model.getModel('exibirNaoAuditados').value,
                  requestChange: model.getModel('exibirNaoAuditados').requestChange,
                }}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col md={4}>
            <StatusRevisaoLookup clearable model={model.getModel('status')} />
          </Col>
          <Col md={4}>
            <Checkbox
              style={{ marginTop: 35 }}
              model={{
                label: intl.formatMessage({ id: 'desconsiderarStatusConcluido' }),
                value: model.getModel('desconsiderarStatusConcluido').value,
                requestChange: model.getModel('desconsiderarStatusConcluido').requestChange,
              }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              style={{ marginTop: 35 }}
              model={{
                label: intl.formatMessage({ id: 'desconsiderarStatusCancelado' }),
                value: model.getModel('desconsiderarStatusCancelado').value,
                requestChange: model.getModel('desconsiderarStatusCancelado').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          {showControlesFilters && (
            <Col md={12}>
              <Input model={model.getModel('descricao')} />
            </Col>
          )}
        </Row>
        <Row>
          {showControlesFilters && (
            <Col md={3}>
              <Input model={model.getModel('idIntegracao')} />
            </Col>
          )}
          <Col md={9}>
            <PeriodoData
              clearable
              tipoFiltroData={model.getModel('tipoFiltroData').value}
              dataInicio={model.getModel('dataInicio').value}
              dataTermino={model.getModel('dataTermino').value}
              onChange={this.handleChangeFiltro}
            />
          </Col>
        </Row>
        <Row>
          <Col md={utilizarTipoEvidencia ? 4 : 6}>
            <ColaboradorSimplificadoLookup
              clearable
              model={{
                label: capitalizeFirstLetter(resources.responsavel),
                value: model.getModel('responsavel').value,
                requestChange: model.getModel('responsavel').requestChange,
              }}
            />
          </Col>
          <Col md={utilizarTipoEvidencia ? 4 : 6}>
            <ColaboradorSimplificadoLookup clearable model={model.getModel('validador')} />
          </Col>
          {utilizarTipoEvidencia && showControlesFilters && (
            <Col md={4}>
              <TipoEvidenciaLookup multi={true} model={model.getModel('tipoEvidencias')} />
            </Col>
          )}
        </Row>
        {showControlesFilters && (
          <Row>
            <Col md={3}>
              <PeriodicidadeLookup clearable model={model.getModel('periodicidade')} />
            </Col>
            <Col md={3}>
              {utilizarTipoPontoControle && (
                <TipoPontoControle
                  clearable
                  model={{
                    label: intl.formatMessage({ id: 'tipoPontoControle' }, { pontodecontrole: resources.pontodecontrole }),
                    value: model.getModel('tipoPontoControle').value,
                    requestChange: model.getModel('tipoPontoControle').requestChange,
                  }}
                />
              )}
            </Col>
            <Col md={3}>
              <InputNumber model={model.getModel('diasToleranciaRevisao')} />
            </Col>
            <Col md={3}>
              <TipoToleranciaLookup clearable model={model.getModel('tipoTolerancia')} />
            </Col>
          </Row>
        )}
        <Row>
          {funcaoPontoControle && showControlesFilters && (
            <Col md={6}>
              <FuncaoPontoControlelookup
                model={{
                  label: intl.formatMessage({ id: 'funcaoPontoControle' }, { pontodecontrole: resources.pontodecontrole }),
                  value: model.getModel('funcaoPontoControle').value,
                  requestChange: model.getModel('funcaoPontoControle').requestChange,
                }}
                clearable
              />
            </Col>
          )}
          {tipoDeControle && showControlesFilters && (
            <Col md={3}>
              <TipoDeControleLookup
                model={{
                  label: intl.formatMessage({ id: 'tipoDeControle' }, { pontodecontrole: resources.pontodecontrole }),
                  value: model.getModel('tipoDeControle').value,
                  requestChange: model.getModel('tipoDeControle').requestChange,
                }}
                clearable
              />
            </Col>
          )}
          <Col md={3}>
            <FiltroAnexoLookup
              model={{
                label: intl.formatMessage({ id: 'anexo' }),
                value: model.getModel('filtroAnexo').value,
                requestChange: model.getModel('filtroAnexo').requestChange,
              }}
              clearable
            />
          </Col>
        </Row>
        {model.getModel('camposCustomizados') && model.getModel('camposCustomizados').value && model.getModel('camposCustomizados').value.length > 0 && (
          <Row>
            <Col md={12}>
              <DesenharCampoCustomizado model={model.getModel('camposCustomizados')} />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(RevisoesGerenciarSearch));
