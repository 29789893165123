import React from 'react';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import DatePicker from 'src/componentes/date-picker';
import Alert from 'src/componentes/message-box/alert';
import ContentManager from 'src/componentes/content-manager';

@autobind
export default class PeriodoData extends React.Component {
  handleChangeDataInicio(value) {
    let dataTermino = this.props.dataTermino.value;

    if (dataTermino && moment(value) > moment(dataTermino)) {
      ContentManager.addContent(
        <Alert
          message={
            <FormattedMessage id="label.dataInicioDeveSerMenorDataTermino" />
          }
        />
      );
    } else {
      this.props.dataInicio.requestChange(value);
    }
  }

  handleChangeDataTermino(value) {
    let dataInicio = this.props.dataInicio.value;

    if (moment(value) < moment(dataInicio)) {
      ContentManager.addContent(
        <Alert
          message={
            <FormattedMessage id="label.dataTerminoDeveSerMaiorDataInicio" />
          }
        />
      );
      this.props.dataTermino.requestChange(null);
    } else {
      this.props.dataTermino.requestChange(value);
    }

    if (dataInicio == null) this.props.dataInicio.requestChange(value);
  }

  render() {
    let { dataInicio, dataTermino, disabled, required } = this.props;

    return (
      <Row>
        <Col md={6}>
          <DatePicker
            month
            disabled={disabled}
            required={required}
            model={{
              label: <FormattedMessage id="label.dataInicio" />,
              value: dataInicio.value,
              requestChange: this.handleChangeDataInicio,
              errors: dataInicio.errors,
            }}
          ></DatePicker>
        </Col>
        <Col md={6}>
          <DatePicker
            month
            disabled={disabled}
            model={{
              label: <FormattedMessage id="label.dataTermino" />,
              value: dataTermino.value,
              requestChange: this.handleChangeDataTermino,
            }}
          ></DatePicker>
        </Col>
      </Row>
    );
  }
}
