import React from 'react';
import { autobind } from 'core-decorators';
import List from 'src/componentes/list/tree';
import ListColumn from 'src/componentes/list/list-column';
import ConsequenciaEventoRiscoEdit from './edit';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ContentManager from 'src/componentes/content-manager';
import ConsequenciaControleEdit from '../../components/ConsequenciaControleEdit';
import Confirm from 'src/componentes/message-box/confirm';
import http from 'src/services/httpService';
import MdiIcon from 'src/componentes/mdi-icon';
import Button from 'src/componentes/button';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';

@autobind
class ConsequenciasEventoRiscoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: -1,
      refreshAfterRemove: -1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshAfterRemove !== this.props.refreshAfterRemove) {
      this.setState({ saved: this.state.saved + 1 });
    }
  }

  handleCloseEdit(saved) {
    if (saved) {
      this.props.refreshCount && this.props.refreshCount(saved);
      this.setState({ saved: Math.random() });
    }
  }

  render() {
    const { configuracao, disabled, intl } = this.props;

    return (
      <List
        editComponent={ConsequenciaEventoRiscoEdit}
        url="/RiscoConsequencias"
        showDelete={true}
        showExportItem={false}
        showExport={false}
        hierarchy={true}
        valueField="descricao"
        saved={this.state.saved}
        showNew={!disabled}
        showMenu={!disabled}
        parameters={{ eventoRiscoId: this.props.eventoRiscoId }}
        afterSave={this.props.refreshCount}
        afterDelete={this.props.refreshCount}
        local
        modelValue={this.props.model.value}
        dataSource={this.props.model.value}
        {...this.props}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'descricao' })}
          tree={true}
          valueField="descricao"
          required
        />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(
  connect(mapStateToProps)(ConsequenciasEventoRiscoList)
);
