import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import List from 'src/componentes/list/tree';
import ListColumn from 'src/componentes/list/list-column';
import { capitalizeFirstLetter } from 'src/utils/string';
import { ENTIDADES_CUSTOMIZADAS, FILTROS, COLUNAS } from 'src/utils/constants';
import Legenda from 'src/paginas/gestao-risco/legenda-farol-revisao';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';
import ContentManager from 'src/componentes/content-manager';
import MdiIcon from 'src/componentes/mdi-icon';
import { lowerCaseFirstLetter } from 'src/utils/string';
import Search from './search';
import SearchSchema from './search-schema';
import PontoControleEdit from './edit';
import Novo from './novo-controle';
import { Badge } from 'reactstrap';

function PontoControleView({
  resources,
  intl,
  openLastFilter,
  filtroDefault,
  showToolbar,
  urlController = '/PontoControle',
  ...rest
}) {
  const history = useHistory();
  const [savedCount, setSavedCount] = useState(0);

  const handleDeleteClick = (item) => {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/PontoControle/ValidarRestricoes"
        urlExclusao="/PontoControle/AtualizarStatusExcluido"
        item={item}
        urlEdit="/gerenciar/ponto-controle/edit"
        onClose={handleCloseNew}
        isEdit={true}
      />
    );
  };

  const renderFarol = (item) => {
    return (
      <span>
        {item.farol && item.farol.cor && (
          <MdiIcon
            icon={item.farol.icone}
            color={item.farol.cor}
            colorHover={item.farol.cor}
            size={22}
          ></MdiIcon>
        )}
      </span>
    );
  };

  const renderStatusUltimoCSA = (item) => {
    if (item?.statusCSA)
      return (
        <span className="text-primary">
          <Badge
            color="custom"
            style={{ backgroundColor: item.statusCSA.cor, color: 'white' }}
          >
            {item.statusCSA.nome}
          </Badge>
        </span>
      );
  };

  const handleNewClick = (itemOrigem, tipoInclusao) => {
    ContentManager.addContent(
      <Novo
        url={urlController}
        tipoInclusaoParams={tipoInclusao}
        itemOrigem={itemOrigem}
        handleClose={handleCloseNew}
        history={history}
        urlEdit="/gerenciar/ponto-controle/edit"
      ></Novo>
    );
  };

  const handleCloseNew = (saved) => {
    if (saved) {
      setSavedCount(savedCount + 1);
    }
  };

  const renderPontoControle = (item, parameters) => {
    return (
      <a tabindex="-1" onClick={() => handleEditClick(item, parameters)}>
        {item.descricao}
      </a>
    );
  };

  const handleEditClick = (item, parameters) => {
    ContentManager.addContent(
      <PontoControleEdit
        item={item}
        currenttabIndex={1}
        parameters={parameters}
        showDelete={true}
      />
    );
  };

  const createChildItems = [
    {
      label: intl.formatMessage(
        { id: 'labelCadastrarControleVinculado' },
        { pontodecontrole: lowerCaseFirstLetter(resources.pontodecontrole) }
      ),
      icon: 'format-list-bulleted',
      parameters: { tipoId: 1 },
      visible: (item) => item.agrupador,
      onClick: (item) => handleNewClick(item, 1),
    },
    {
      label: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'labelCadastrarAgrupadorVincular' },
          { area: resources.area }
        )
      ),
      icon: 'folder',
      parameters: { tipoId: 2 },
      visible: (item) => item.agrupador,
      onClick: (item) => handleNewClick(item, 2),
    },
  ];

  const funcaoPontoControle = rest.configuracao && rest.configuracao.funcaoPontoControle;
  const tipoDeControle = rest.configuracao && rest.configuracao.tipoDeControle;
  const temPermissaoVisualizarCSA = rest.configuracao && rest.configuracao.temPermissaoVisualizarCSA;

  return (
    <React.Fragment>
      <List
        url="/PontoControle"
        showCustomFields
        tipoItem={ENTIDADES_CUSTOMIZADAS.PONTO_CONTROLE}
        editComponent={PontoControleEdit}
        onDeleting={handleDeleteClick}
        searchComponent={<Search configuracao />}
        searchSchema={SearchSchema}
        showSearch
        handleNewClick={handleNewClick}
        tag={COLUNAS.PONTO_CONTROLE}
        filtroId={openLastFilter ? FILTROS.PONTO_CONTROLE : null}
        openLastFilter={openLastFilter}
        selectedFilter={{ valor: filtroDefault }}
        showToolBar={showToolbar}
        sort="Descricao asc"
        hierarchy={true}
        showPagination={true}
        retirarTreeCamposCustomizados={true}
        saved={savedCount}
        createChildItems={createChildItems}
        useExportLimit_1000={true}
        {...rest}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'descricao' })}
          valueFunction={renderPontoControle}
          tree={true}
          default
          valueField="descricao"
          required
          sortField="Descricao"
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'idIntegracao' })}
          default
          valueField="idIntegracao"
          sortField="IdIntegracao"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.responsaveis)}
          valueField="responsaveis"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'periodicidade' })
          )}
          valueField="periodicidade"
          sortField="Periodicidade.Descricao"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'tipoPontoControle' },
              { pontodecontrole: resources.pontodecontrole }
            )
          )}
          valueField="tipoPontoControle"
          sortField="TipoPontoControle.Descricao"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.farol)}
          valueField="farol"
          valueFunction={renderFarol}
        />
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'tolerancia' })
          )}
          valueField="diasToleranciaRevisao"
          sortField="DiasToleranciaRevisao"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'tipoTolerancia' })
          )}
          valueField="tipoTolerancia"
          sortField="TipoTolerancia.Descricao"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'numeroRevisoesGeradasAutomaticamente' })
          )}
          valueField="numeroRevisoesGeradasAutomaticamente"
          sortField="NumeroRevisoesGeradasAutomaticamente"
        />
        <ListColumn
          visible={funcaoPontoControle}
          headerText={capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'funcaoPontoControle' },
              { pontodecontrole: resources.pontodecontrole }
            )
          )}
          valueField="funcaoPontoControle"
          sortField="FuncaoPontoControle"
        />
        <ListColumn
          visible={tipoDeControle}
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'tipoDeControle' })
          )}
          valueField="tipoDeControle"
          sortField="TipoDeControle"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.area)}
          valueField="area"
          sortField="Area"
        />
        <ListColumn
          visible={temPermissaoVisualizarCSA}
          headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'statusCSA' }))}
          valueField="statusCSA"
          valueFunction={renderStatusUltimoCSA}
        />
      </List>
      <Legenda tipoFarol={1} />
    </React.Fragment>
  );
}

PontoControleView.defaultProps = {
  openLastFilter: true,
  showToolbar: true,
  showTitle: true,
};

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PontoControleView));
