import React from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import Input from 'src/componentes/input';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import StatusAcaoLookup from 'src/lookups/status-acao';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import ItemLookup from 'src/lookups/item';
import DatePicker from 'src/componentes/date-picker';
import PeriodicidadeLookup from 'src/lookups/periodicidade';
import Checkbox from 'src/componentes/checkbox';
import TemaEstrategicoLookup from 'src/lookups/tema-estrategico';

class OrganizacaoSearch extends React.Component {
  componentDidMount() {
    this.updateStatusField();
  }

  // Novo filtro Multi - Sem impactar o antigo (outras telas, etc.)
  updateStatusField() {
    const { model } = this.props || {};

    const status = model.getModel('status').value;

    if (status) {
      const listaStatus = model.getModel('listaStatus').value;
      if (!listaStatus || listaStatus?.length === 0) {
        model.getModel('listaStatus').requestChange([{ ...status }]);
        model.getModel('status').requestChange(null);
      }
    }
  }

  render() {
    let { model, resources, intl } = this.props;

    return (
      <div>
        <Row>
          <Col md={2}>
            <Input model={model.getModel('descricao')} />
          </Col>
          <Col md={2}>
            <Input model={model.getModel('codigo')} />
          </Col>
          <Col md={3}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.areas),
                value: model.getModel('areas').value,
                requestChange: model.getModel('areas').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>
          <Col md={3}>
            <Checkbox
              model={model.getModel('areaSubordinada')}
              style={{ marginTop: 27 }}
            />
          </Col>
          <Col md={3}>
            <ItemLookup
              clearable={true}
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'item' })
                ),
                value: model.getModel('item').value,
                requestChange: model.getModel('item').requestChange,
              }}
            />
          </Col>
          <Col md={3}>
            <ColaboradorSimplificadoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.responsaveis),
                value: model.getModel('responsaveis').value,
                requestChange: model.getModel('responsaveis').requestChange,
              }}
            />
          </Col>
          <Col md={2}>
            <StatusAcaoLookup
              multi
              clearable
              model={{
                label: intl.formatMessage({ id: 'status' }),
                value: model.getModel('listaStatus').value,
                requestChange: model.getModel('listaStatus').requestChange,
              }}
            />
          </Col>
          <Col md={3}>
            <PeriodicidadeLookup
              clearable
              model={model.getModel('periodicidade')}
            />
          </Col>
          <Col md={3}>
            <TemaEstrategicoLookup
              multi={false}
              model={{
                label: capitalizeFirstLetter(resources.temaestrategico),
                value: model.getModel('tema').value,
                requestChange: model.getModel('tema').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <DatePicker model={model.getModel('dataInicioPrevista')} />
          </Col>
          <Col md={3}>
            <DatePicker model={model.getModel('dataTerminoPrevista')} />
          </Col>
          <Col md={3}>
            <DatePicker model={model.getModel('dataInicioRealizada')} />
          </Col>
          <Col md={3}>
            <DatePicker model={model.getModel('dataTerminoRealizada')} />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(OrganizacaoSearch));
