import React from 'react';
import Edit from '../../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Schema } from '../../../utils/form';
import { FormattedMessage } from 'react-intl';
import Input from '../../../componentes/input';
import Panel from '../../../componentes/panel';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import FormulaEdit from './formula/edit';
import FormulaVisao from '../../../paginas/formula/componentes/formula-visao';

let schema = Schema.object({
  id: Schema.string(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  handleChangeIdIntegracao(value) {
    this.props.model.getModel('idIntegracao').requestChange(value);
  }

  handleChangeNome(value) {
    this.props.model.getModel('nome').requestChange(value);
  }

  renderFormula(item) {
    return (
      <FormulaVisao formula={item.expressao} isItem={false}></FormulaVisao>
    );
  }

  render() {
    let { model, intl } = this.props;
    let id = model.getModel('id') && model.getModel('id').value;

    return (
      <div>
        <Row>
          <Col md={2}>
            <Input
              maxlength={100}
              model={model.getModel('idIntegracao')}
              required
            />
          </Col>
          <Col md={4}>
            <Input maxlength={200} model={model.getModel('nome')} required />
          </Col>
        </Row>
        {id > 0 && (
          <Row>
            <Col md={12}>
              <Panel>
                <List
                  showTitle={true}
                  url="/Formula"
                  editComponent={FormulaEdit}
                  bloquearMultiplosRegistros={true}
                  showPage={false}
                  showDelete={true}
                  renderMenu={this.renderMenu}
                  parameters={{
                    GatilhoId: id,
                    alterado: false,
                    tipoValorId: 5,
                  }}
                  showExport={false}
                >
                  <ListColumn
                    headerText={intl.formatMessage({ id: 'formula' })}
                    valueField="formula"
                    valueFunction={this.renderFormula}
                  />
                </List>
              </Panel>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

@autobind
class GatilhoEdit extends React.Component {
  render() {
    let { intl, parameters } = this.props;
    return (
      <Edit
        url="/Gatilho"
        {...this.props}
        title={intl.formatMessage({ id: 'gatilho' })}
        formComponent={FormEdit}
        parameters={parameters}
        schema={schema}
        width="70%"
        height={600}
        showClose={false}
        handleOnClose={this.handleOnClose}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(GatilhoEdit));
