import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import Avatar from 'src/componentes/avatar';
import LoadingContainer from 'src/componentes/loading-container';
import errorHandler from 'src/utils/error-handler';
import http from 'src/services/httpService';
import formatDateFns from 'src/utils/formatDateFns';
import useAuth from 'src/hooks/useAuth';

import { FormEdit } from '../edit';

const Historico = ({ pontoFaixaResultadoId }) => {
  const intl = useIntl();

  const { terms: resources, user } = useAuth();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { currentDisplay, currentLocale } = formatDateFns(user?.idioma, true);

  const getData = async () => {
    try {
      const response = await http.post('PontoFaixaResultado/ObterHistorico', {
        pontoFaixaResultadoId,
      });
      setData(response.data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderDate = (data) =>
    format(data, currentDisplay, { locale: currentLocale });

  const getModel = (register) => {
    const getLabel = (field) => {
      const labels = {
        idIntegracao: intl.formatMessage({ id: 'idIntegracao' }),
        descricao: intl.formatMessage({
          id: 'descricao',
        }),
        interpolarValoresFaixa: intl.formatMessage({
          id: 'label.interpolarValoresFaixa',
        }),
        tipoPontoFaixaResultado: '',
      };

      return labels[field];
    };

    return {
      getModel: (key) => {
        return {
          label: getLabel(key),
          value: register[key],
          requestChange: () => {},
        };
      },
    };
  };

  const renderItem = ({ usuarioAlteracao, dataAlteracao, registro }) => {
    return (
      <div>
        <div className="d-flex align-items-center mb-4">
          <Avatar
            foto={usuarioAlteracao?.fotoArquivo}
            size={32}
            label={usuarioAlteracao?.nome}
          />
          <div className="ml-2 font-weight-bold">
            ({renderDate(dataAlteracao)})
          </div>
        </div>
        <FormEdit
          model={getModel(registro)}
          intl={intl}
          resources={resources}
          disabled
          isHistory
        />
        <hr></hr>
      </div>
    );
  };

  if (!isLoading && (!data || data?.length == 0))
    return (
      <div className="p-2">
        {intl.formatMessage({ id: 'label.nenhumaAlteracaoRealizada' })}
      </div>
    );

  return (
    <LoadingContainer isLoading={isLoading}>
      {data?.map((d) => renderItem(d))}
    </LoadingContainer>
  );
};

export default Historico;
