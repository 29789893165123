import React from 'react';
import { autobind } from 'core-decorators';
import Button from '../../componentes/button';
import ColaboradorList from './view';
import ContentManager from '../../componentes/content-manager';
import IconButton from '../../componentes/icon-button';
import Table from '../../componentes/table';
import { FormGroup, Row, Col, Alert } from 'reactstrap';
import Panel from '../../componentes/panel';
import { injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../utils/string';
import { connect } from 'react-redux';
import MdiIcon from 'src/componentes/mdi-icon';

@autobind
class ColaboradorSelectList extends React.Component {
  static defaultProps = {
    showPage: true,
    pageSize: null,
    forcarPageSize: false,
  };

  handleNewClick() {
    let { resources, showPage, pageSize } = this.props;
    ContentManager.addContent(
      <ColaboradorList
        pageSize={pageSize}
        showPage={showPage}
        forcarPageSize={this.props.forcarPageSize}
        showModal
        multiple
        parameters={{ somenteAtivos: this.props.somenteAtivos }}
        select={true}
        tag=""
        showExport={false}
        titulo={capitalizeFirstLetter(resources.colaboradores)}
        selectedCallback={this.handleSelect}
        selectedIds={this.props.model.value}
        selectedItems={this.props.model.value}
        filtroInterno={this.props.filtroInterno}
      />
    );
  }

  handleSelect(colaboradores) {
    this.props.model.requestChange([...colaboradores]);
  }

  handleRemove(colaborador) {
    this.props.model.requestChange(
      this.props.model.value.filter((d) => d.id !== colaborador.id)
    );
  }

  render() {
    let { model, disabled, resources } = this.props;

    return (
      <FormGroup
        style={{ overflowY: 'auto', overflowX: 'hidden', height: '300px' }}
        validationstate={model.errors ? 'error' : null}
      >
        <Row>
          <Col md={6}>
            {model.label && (
              <label className="control-label">{model.label}</label>
            )}
          </Col>
          <Col md={6}>
            {!disabled && (
              <Button
                className="pull-right"
                type="primary"
                style={{ marginBottom: 10 }}
                onClick={this.handleNewClick}
              >
                <MdiIcon icon="plus" />
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Panel>
              {model.value && model.value.length > 0 ? (
                <Table>
                  <tbody>
                    {model.value.map((item, index) => (
                      <tr key={index}>
                        <td>{item.nome}</td>
                        <td>{item.gestorSubstituto}</td>
                        <td style={{ width: 40 }}>
                          <IconButton
                            icon="close-circle"
                            className="clarear"
                            onClick={this.handleRemove.bind(this, item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <i>
                  {this.props.intl.formatMessage(
                    { id: 'label.semColabRelacionados' },
                    { colaboradores: resources.colaboradores }
                  )}
                </i>
              )}
            </Panel>
          </Col>
        </Row>
        {model.erros && <Alert>{model.erros}</Alert>}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ColaboradorSelectList));
