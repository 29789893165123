import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Dialog from 'src/componentes/dialog';
import { keydown, KEYS } from 'src/utils/keydown-decorator';
import Button from 'src/componentes/button';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import CheckBox from 'src/componentes/checkbox';
import ConfirmacaoReuniaoLookup from 'src/lookups/confirmacao-reuniao';
import { capitalizeFirstLetter } from 'src/utils/string';
import http from 'src/services/httpService';
import Radio from 'src/componentes/radio';
import Alert from 'src/componentes/message-box/alert';
import ContentManager from 'src/componentes/content-manager';

@autobind
class ColaboradorReuniaoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.saved = false;
    this.isNew = this.props.item == null ? true : false;
    this.state = {
      item: this.props.item || null,
      index: this.props.index,
      configuracoes: null,
    };
  }

  componentDidMount() {
    http
      .post(`/ItemConfiguracaoReuniao/ObterConfiguracoes`, {})
      .then((response) => {
        this.setState({
          configuracoes: response.data,
        });
      });
  }

  handleColaboradorChange(value) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        colaborador: value,
      },
    });
  }

  handleConfirmacaoChange(value) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        confirmacao: value,
      },
    });
  }

  handleCheckChange(field, value) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        [field]: value,
      },
    });
  }

  handleChangeComplete(color) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        cor: color.hex,
      },
    });
  }

  handleSave() {
    if (this.state.item.colaborador == null) {
      ContentManager.addContent(
        <Alert
          message={this.props.intl.formatMessage(
            { id: 'label.selecioneUmColaborador' },
            { colaborador: this.props.resources.colaborador }
          )}
        />
      );
      return;
    }
    this.saved = true;
    this.close();
  }

  @keydown(KEYS.ESC)
  close() {
    this.refs.dialog.close();
    setTimeout(() => {
      this.props.handleClose &&
        this.props.handleClose(
          this.saved,
          this.isNew,
          this.state.item,
          this.props.indexItem
        );
    }, 300);
  }

  editActions = [
    <Button key="cancel" onClick={this.close} color="secondary">
      {this.props.intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      key="save"
      type="primary"
      onClick={this.handleSave.bind(this, false)}
      htmlType="submit"
      className="ml-2"
    >
      {this.props.intl.formatMessage({ id: 'label.salvar' })}
    </Button>,
  ];

  render() {
    let { resources, intl, user } = this.props;
    const { configuracoes, item } = this.state;
    const optionsObrigatoriedade = [
      { id: 0, descricao: intl.formatMessage({ id: 'obrigatorio' }) },
      { id: 1, descricao: intl.formatMessage({ id: 'opcional' }) },
    ];
    const isAprovouAtaDisabled =
      (!configuracoes?.aprovacaoAtaPeloResponsavel && !item?.id) ||
      user?.id != item?.colaborador?.id;

    return (
      <Dialog
        className={'reuniao-step5 modal-participantes'}
        padContent
        width={650}
        height={350}
        title={intl.formatMessage({ id: 'label.participantesDaReuniao' })}
        {...this.props}
        actions={this.editActions}
        onRequestClose={this.handleClose}
        ref="dialog"
      >
        <div>
          <Row>
            <Col md={6}>
              <ColaboradorSimplificadoLookup
                required
                multi={true}
                model={{
                  label: capitalizeFirstLetter(resources.colaborador),
                  value: this.state.item && this.state.item.colaborador,
                  requestChange: this.handleColaboradorChange,
                }}
              />
            </Col>
            {configuracoes && configuracoes.utilizarConfirmacao && (
              <Col md={4}>
                <ConfirmacaoReuniaoLookup
                  model={{
                    label: intl.formatMessage({ id: 'label.confirmacao' }),
                    value: this.state.item && this.state.item.confirmacao,
                    requestChange: this.handleConfirmacaoChange,
                  }}
                />
              </Col>
            )}
          </Row>
          <Row>
            {configuracoes && configuracoes.utilizarAprovouAta && (
              <Col md={4}>
                <CheckBox
                  disabled={isAprovouAtaDisabled}
                  model={{
                    label: intl.formatMessage({ id: 'label.aprovouAta' }),
                    value: this.state.item && this.state.item.aprovouAta,
                    requestChange: this.handleCheckChange.bind(
                      this,
                      'aprovouAta'
                    ),
                  }}
                />
              </Col>
            )}
            {configuracoes && configuracoes.utilizarParticipou && (
              <Col md={3}>
                <CheckBox
                  model={{
                    label: intl.formatMessage({ id: 'label.participou' }),
                    value: this.state.item && this.state.item.participou,
                    requestChange: this.handleCheckChange.bind(
                      this,
                      'participou'
                    ),
                  }}
                />
              </Col>
            )}
            {configuracoes && configuracoes.utilizarObrigatorio && (
              <Col md={3}>
                <Radio
                  model={{
                    label: intl.formatMessage({ id: 'label.participacao' }),
                    value: this.state.item
                      ? this.state.item.tipoParticipacao
                      : this.handleCheckChange(
                          'tipoParticipacao',
                          optionsObrigatoriedade[0]
                        ),
                    requestChange: this.handleCheckChange.bind(
                      this,
                      'tipoParticipacao'
                    ),
                  }}
                  options={optionsObrigatoriedade}
                  labelField="descricao"
                  idField="id"
                />
              </Col>
            )}
          </Row>
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    user: state.user,
  };
}
export default injectIntl(connect(mapStateToProps)(ColaboradorReuniaoEdit));
