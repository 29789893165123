import { useState } from 'react';

import LogoModulo from 'src/layout/DashboardLayout/LogoModulo';
import { Checkbox } from 'src/componentes/Form';
import Historico from 'src/paginas/versao-alteracao';
import { useIntl } from 'react-intl';
import MdiIcon from 'src/componentes/mdi-icon';

const ModulosColaborador = ({ model, modulos }) => {
  const [mostrarHistorico, setMostrarHistorico] = useState(false);
  const intl = useIntl();
  const colaboradorId = model.getModel('id') && model.getModel('id').value;

    const onChange = (id, checked) => {
      const list = model.getValue('colaboradorModulos');
      const index = list.findIndex((x) => x === id);

      if (index === -1) list.push(id);
      else list.splice(index, 1);

      model.getModel('colaboradorModulos').requestChange(list);
    };

    return (
      <div>
        <span className="font-weight-bold text-lg">
          {intl.formatMessage({ id: 'licencas' })}
        </span>
        <div className="row mt-3 ml-2">
          {modulos?.map((modulo, index) => (
            <div className="col-md-6 mb-2 border-bottom mt-2">
              <div className="d-flex mb-2">
                <div className="w-75 d-flex">
                  <LogoModulo module={modulo} size={modulo?.icone === 'sophie' ? 65 : 42} />
                  <span className="mr-2 ml-2">{modulo?.nome}</span>
                </div>
                <Checkbox
                  type="switch"
                  id={`modulo-${modulo.id}`}
                  name={`modulo-${modulo.id}`}
                  checked={model
                    .getValue('colaboradorModulos')
                    .find((e) => e === modulo?.id)}
                  onChange={(e) => onChange(modulo.id, e.target.checked)}
                />
              </div>
            </div>
          ))}
        </div>
        {colaboradorId != null && colaboradorId > 0 && (
          <div className="mt-3">
            <a
              className="text-primary text-sm font-weight-bolder"
              onClick={() => setMostrarHistorico(!mostrarHistorico)}
            >
              <MdiIcon
                className="mr-2"
                icon={`${mostrarHistorico ? 'eye-off-outline' : 'eye-outline'}`}
                size={20}
              />
              {mostrarHistorico
                ? intl.formatMessage({ id: 'ocultarHistorico' })
                : intl.formatMessage({ id: 'verHistorico' })}
            </a>
            {mostrarHistorico && (
              <Historico
                url={`/Colaborador/ObterHistoricoModulos`}
                id={colaboradorId}
              />
            )}
          </div>
        )}
      </div>
    );
};

export default ModulosColaborador;
