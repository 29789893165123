import React from 'react';
import { autobind } from 'core-decorators';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import DropdownItemList from './dropdown-item';
import DropdownItemRisco from '../gestao-risco/risco/RiscoListView/DropdownItemRisco';

@autobind
class Dropdown extends React.Component {
  static defaultProps = {
    link: true,
    noPadding: false,
  };
  getLabel(item) {
    if (this.props.renderLabel) {
      return this.props.renderLabel && this.props.renderLabel(item);
    } else {
      return item && item.nome;
    }
  }

  render() {
    const {
      item,
      filtro,
      resources,
      link,
      tabIndex,
      showMenu = true,
      exibirPlanoAcao,
      noPadding,
      tag = 'a',
    } = this.props;

    return (
      <div style={{ display: 'inline-block', maxWidth: '100%' }}>
        <UncontrolledDropdown>
          <DropdownToggle
            tag={tag}
            className={link ? 'nav-link' : ''}
            tabIndex={tabIndex}
            style={{ padding: noPadding ? 0 : 'inherit' }}
          >
            {this.getLabel(item)}
          </DropdownToggle>
          {showMenu && (
            <DropdownMenu container="body">
              {item?.tipo?.id == 6 ? (
                <DropdownItemRisco
                  filtro={filtro}
                  handleCloseModal={this.props.handleCloseModal}
                  selectCloseModal={this.props.selectClose}
                  resources={resources}
                  showEdit={true}
                  item={item}
                  ehEditar={this.props.ehEditar}
                  {...this.props}
                />
              ) : (
                <DropdownItemList
                  filtro={filtro}
                  handleCloseModal={this.props.handleCloseModal}
                  selectCloseModal={this.props.selectClose}
                  exibirPlanoAcao={exibirPlanoAcao}
                  resources={resources}
                  item={item}
                  ehEditar={this.props.ehEditar}
                  {...this.props}
                ></DropdownItemList>
              )}
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Dropdown));
