import TesteControleViewList from 'src/paginas/gestao-risco/TesteControle/TesteControleListView';
import { useIntl } from 'react-intl';
import Content from 'src/componentes/pages/content';

export default function TesteControleView({ showPanel = true, showNew = true, ...rest }) {
  const intl = useIntl();
  return showPanel ? (
    <Content titulo={intl.formatMessage({ id: 'labelTesteControle' })}>
      <TesteControleViewList disabled={true} showNew={showNew} {...rest} />
    </Content>
  ) : (
    <TesteControleViewList disabled={true} showNew={showNew} {...rest} />
  );
}
