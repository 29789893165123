import React, { useEffect, useState } from 'react';
import { autobind } from 'core-decorators';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import ReactFC from 'react-fusioncharts';
import ContentManager from '../content-manager';
import { LICENSE_KEYS } from 'src/utils/constants';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

FusionCharts.options.license({
  key: LICENSE_KEYS.FUSIONCHARTS,
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, PowerCharts, Widgets);

const FusionChart = (props) => {
  useEffect(() => {
    props.modalAcoes && trackPlotClick();
  }, []);

  const trackPlotClick = () => {
    FusionCharts.addEventListener('dataplotClick', dataPlotClick);
  };

  const dataPlotClick = (_, dataObj) => {
    ContentManager.addContent(
      <props.modalAcoes
        periodo={dataObj.index}
        periodoLabel={dataObj.categoryLabel}
        filtro={props.filtro}
      ></props.modalAcoes>
    );
  };

  return <ReactFC {...props} />;
};

FusionChart.defaultProps = {};

export default FusionChart;
