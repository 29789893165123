import React, { Suspense, lazy } from 'react';
import { autobind } from 'core-decorators';
import { Col, Row, UncontrolledAlert } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';

import Panel from 'src/componentes/panel-collapse';
import ContentManager from 'src/componentes/content-manager';
import errorHandler from 'src/utils/error-handler';
import { capitalizeFirstLetter } from 'src/utils/string';
import StatusRelatorioLookup from 'src/lookups/status-relatorio-acompanhamento';
import http from 'src/services/httpService';
import httpSelector from 'src/services/httpSelector';
import GraficoValores from 'src/paginas/item/valor/componentes/grafico';
import LoadingContainer from 'src/componentes/loading-container';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import AcaoEdit from 'src/paginas/acao/edit';
import Confirm from 'src/componentes/message-box/confirm';
import {
  allColumnsCard,
  fetchStatusDisponiveis,
  fetchCard,
  fetchStatus,
} from 'src/paginas/acao/kanban/components/functions';
import Mdilcon from 'src/componentes/mdi-icon';
import Info from 'src/componentes/info';
import Card from 'src/componentes/panel-collapse';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import SvgIcon from 'src/componentes/svg-icon';
import css from 'src/utils/css';
import LoadingScreen from 'src/componentes/LoadingScreen';
import DatePicker from 'src/componentes/date-picker';

import View from './view-list';
import AcaoList from '../acao/select-list';
import EstratificacaoPareto from 'src/paginas/EstratificacaoPareto';

const PontosProblematicosList = lazy(() => import('./ponto-problematico'));
const PontosProblematicosListOption = lazy(() =>
  import('./ponto-problematico/listOptions')
);
const ValidacaoRestricao = lazy(() => import('../item/validacao-restricao'));
const Historico = lazy(() => import('../versao-alteracao'));
const ItemLink = lazy(() => import('../item/link'));
const ProjetosList = lazy(() => import('../projeto/select-list'));
const ReactQuill = lazy(() => import('src/componentes/quill'));

const baseClasses = css`
  .colunaValor {
    text-align: center;

    & > span {
      display: block;
    }
  }
`;

@autobind
class FormEdit extends React.Component {
  static defaultProps = {
    refreshModel: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      saved: false,
      planoCompleto: false,
      labelPlanoCompleto: (
        <FormattedMessage
          id="label.exibirPlanoCompleto"
          values={{ planodeacao: this.props.resources.planodeacao }}
        />
      ),
      allColumnsCard: [],
      allColumnsKanban: [],
      columnsCard: null,
      columnsKanban: null,
    };
  }

  colors = [
    '#000000',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#ffffcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
    '#444444',
    '#5c0000',
    '#663d00',
    '#666600',
    '#003700',
    '#002966',
    '#3d1466',
  ];

  formats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'color',
    'background',
    'align',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'mb',
  ];

  modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: this.colors }, { background: [] }],
      [{ align: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
      [{ mb: [0, 1, 2, 3, 4, 5] }],
    ],
  };

  componentDidMount() {
    this.didMountCalls();
  }

  refreshModel() {
    this.setState({
      saved: !this.state.saved,
    });
  }

  async didMountCalls() {
    httpSelector
      .getHttp(this.props.mode)
      .post(`/AcaoConfiguracao/ObterConfiguracoes`, {})
      .then(async (response) => {
        let allColumnsCardLocal = await allColumnsCard(
          this.props.intl,
          this.props.resources,
          response.data.model
        );
        let columnsCard = await fetchCard(
          allColumnsCardLocal,
          this.props.viewType
        );
        let allColumnsKanban = await fetchStatusDisponiveis(
          this.props.viewType
        );
        let columnsKanban = await fetchStatus(
          allColumnsKanban,
          this.props.viewType
        );

        this.setState({
          allColumnsCard: allColumnsCardLocal,
          columnsCard: columnsCard,
          allColumnsKanban: allColumnsKanban,
          columnsKanban: columnsKanban,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handlePlanoCompletoClick() {
    let { resources, intl } = this.props;
    this.setState({
      saved: !this.state.saved,
      planoCompleto: !this.state.planoCompleto,
      labelPlanoCompleto: !this.state.planoCompleto
        ? intl.formatMessage(
            { id: 'label.ocultarPlanoCompleto' },
            { planodeacao: resources.planodeacao }
          )
        : intl.formatMessage(
            { id: 'label.exibirPlanoCompleto' },
            { planodeacao: resources.planodeacao }
          ),
    });
  }

  handleAssociarClick() {
    ContentManager.addContent(
      <AcaoList
        showSearch={true}
        params={{ relatorioIdIgnorar: this.props.model.getModel('id').value }}
        multiple
        showModal
        disableGantt={true}
        disableDelete={true}
        select={true}
        selectedCallback={this.handleCustomSelectSearch}
      />
    );
  }

  handleCustomSelectSearch(itens) {
    http
      .post(`/Acao/AssociarAcoes`, {
        relatorioId: this.props.model.getModel('id').value,
        acoes: itens,
      })
      .then(() => {
        this.setState({
          saved: !this.state.saved,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleChangeStatus(value) {
    this.props.model.getModel('statusDefasadoAlterado').requestChange(false);

    //se for trocar o status de Defasado para pendente
    if (value != null && this.props.model.getModel('status').value != null) {
      if (value.id == 0 && this.props.model.getModel('status').value.id == 2) {
        this.props.model.getModel('statusDefasadoAlterado').requestChange(true);
      }
    }

    this.props.model.getModel('status').requestChange(value);
  }

  handleChangeText(field, text) {
    this.props.model.getModel(field).requestChange(text);
  }

  handleCopiarRelatorio(relatorioId, relatorioOrigemId) {
    let model = this.props.model;

    //guarda o gráfico para não precisar gerar novamente e melhorar o tempo de processamento da cópia
    const grafico = model.getModel('grafico').value;

    let allowEdit = model.getModel('permissions').getModel('allowEdit').value;

    this.setState({
      isLoading: true,
    });

    http
      .post(`/RelatorioAcompanhamento/CopiarRelatorio`, {
        relatorioId: relatorioId,
        relatorioOrigemId: relatorioOrigemId,
      })
      .then((response) => {
        model.requestChange(response.data);
        model
          .getModel('permissions')
          .getModel('allowEdit')
          .requestChange(allowEdit);

        //atualiza o gráfico no novo model
        model.getModel('grafico').requestChange(grafico);

        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleSelect(lista) {
    let { intl, resources } = this.props;
    let relatorioOrigem = lista && lista[0];
    let model = this.props.model;

    let relatorioId = this.props.model.getModel('id').value;
    let relatorioOrigemId = relatorioOrigem && relatorioOrigem.id;

    ContentManager.addContent(
      <Confirm
        message={intl.formatMessage(
          { id: 'desejaCopiarConteudoRelatorioAcompanhamento' },
          {
            relatoriodeacompanhamento: resources.relatoriodeacompanhamento,
            acoes: resources.acoes,
          }
        )}
        handleConfirm={this.handleCopiarRelatorio.bind(
          this,
          relatorioId,
          relatorioOrigemId
        )}
      />
    );
  }

  handleDeleteClick(acao) {
    if (
      !acao ||
      !acao.item ||
      (this.props.model.getModel('item').value &&
        this.props.model.getModel('item').value.id != acao.item.id) ||
      (this.props.model.getModel('id') &&
        this.props.model.getModel('id').value !=
          (acao.relatorioAcompanhamento && acao.relatorioAcompanhamento.id))
    ) {
      ContentManager.addContent(
        <Confirm
          message={
            <FormattedMessage
              id="aAcaoSeraSomenteDesvinculadaENaoApagada"
              values={{
                planodeacao: this.props.resources.planodeacao,
                relatoriodeacompanhamento:
                  this.props.resources.relatoriodeacompanhamento,
              }}
            />
          }
          handleConfirm={this.handleConfirmdesvincularAcao.bind(this, acao)}
        />
      );
    } else {
      ContentManager.addContent(
        <ValidacaoRestricao
          url="/Acao/ValidarRestricoes"
          urlExclusao="/Acao/AtualizarStatusExcluido"
          item={acao}
          onClose={this.refreshModel}
        />
      );
    }
  }

  handleConfirmdesvincularAcao(acao) {
    let model = this.props.model;
    let pontosModel = model.getModel('pontosProblematicos').value;

    http
      .post(`/Acao/DesvincularRelatorioAcompanhamento`, {
        acaoId: acao.id,
        relatorioId: this.props.model.getModel('id').value,
      })
      .then(() => {
        pontosModel &&
          pontosModel.map((ponto) => {
            const acoes = ponto.acoes;
            const index =
              acoes && acoes.findIndex((prop) => prop.id === acao.id);

            if (index != -1) ponto.acoes.splice(index, 1);
          });

        model.getModel('pontosProblematicos').requestChange(pontosModel);
        this.refreshModel();
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleOpenRelatorios() {
    ContentManager.addContent(
      <View modelValue={null} selectedCallback={this.handleSelect} select />
    );
  }

  handleDescricaoClick(item, tab = null) {
    ContentManager.addContent(
      <AcaoEdit
        defaultActiveKey={tab}
        handleClose={this.handleCloseEdit}
        item={item}
        parameters={{ ...this.props.parameters }}
        onDeleting={this.handleDeleteClick}
      ></AcaoEdit>
    );
  }

  handleDesvincularPontoProblematicoClick(item, parameters) {
    this.props.model.getModel('acaoSelecionadaAtual').requestChange(item);
    ContentManager.addContent(
      <PontosProblematicosListOption
        selectedCallback={this.handleSelectDesvincularPontoDaAcao}
        pontosProblematicos={item.pontosProblematicos.filter((x) => x.id > 0)}
        relatorioAcompanhamentoId={this.props.model.getModel('id').value}
      />
    );
  }

  handleSelectDesvincularPontoDaAcao(pontosProblematicos) {
    let model = this.props.model;
    const idsPontos =
      pontosProblematicos && pontosProblematicos.map((ponto) => ponto.id);
    const acaoId =
      model.getModel('acaoSelecionadaAtual').value &&
      model.getModel('acaoSelecionadaAtual').value.id;
    let pontosModel = model.getModel('pontosProblematicos').value;

    http
      .post(`/Acao/DesvincularPontoProblematico`, {
        acaoId,
        pontosProblematicosId: idsPontos,
      })
      .then(() => {
        pontosModel &&
          pontosModel.map((ponto) => {
            if (idsPontos.includes(ponto.id)) {
              const acoes = ponto.acoes;
              const index =
                acoes && acoes.findIndex((prop) => prop.id === acaoId);

              if (index != -1) ponto.acoes.splice(index, 1);
            }
          });

        model.getModel('pontosProblematicos').requestChange(pontosModel);
        this.refreshModel();
      });
  }

  render() {
    let {
      model,
      resources,
      intl,
      divIdToExport,
      parameters,
      ehPlanejamentoInicial,
      isConsultaExterna,
    } = this.props;
    let configuracao = model.getModel('configuracao');
    let detail = this.props.mode && this.props.mode == 'detail';
    const isNew = this.props.model.getModel('id').value == 0;

    let itemId =
      parameters && parameters.itemId
        ? parameters.itemId
        : this.props.model.getModel('item').value &&
          this.props.model.getModel('item').value.id;
    let allowEdit = model.getModel('permissions').getModel('allowEdit').value;
    let disabled = !allowEdit ? true : detail;

    let grafico = model.getModel('grafico').value;
    let item = model.getModel('item').value;
    let farol = model.getModel('farolItem').value;

    let createChildItemsAcao = [
      {
        label: (
          <FormattedMessage
            id="desvincularPontoProblematico"
            values={{
              pontosproblematicos: this.props.resources.pontosproblematicos,
            }}
          />
        ),
        icon: 'link-off',
        visible: () => true,
        onClick: this.handleDesvincularPontoProblematicoClick,
      },
    ];

    return (
      <Suspense fallback={<LoadingScreen />}>
        <LoadingContainer isLoading={this.state.isLoading}>
          <Tabs>
            <Tab key="1" label={<FormattedMessage id="label.resumo" />}>
              <div id={divIdToExport}>
                {model.getModel('status').value &&
                  model.getModel('status').value.id === 2 && (
                    <Row>
                      <Col>
                        <UncontrolledAlert color="warning">
                          {intl.formatMessage(
                            { id: 'relatorioDefasadoValoresAntigos' },
                            {
                              relatoriodeacompanhamento:
                                resources.relatoriodeacompanhamento,
                            }
                          )}
                        </UncontrolledAlert>
                      </Col>
                    </Row>
                  )}
                <Row>
                  <Col md={3}>
                    <span>
                      <b>
                        <FormattedMessage id="item" />:{' '}
                      </b>
                    </span>
                    <ItemLink
                      handleCloseEdit={this.props.handleCloseEdit}
                      model={model.getModel('item')}
                      isConsultaExterna={isConsultaExterna}
                    ></ItemLink>
                  </Col>
                  {!isNew && (
                    <Col md={2} className={baseClasses.colunaValor}>
                      <span>
                        <b>
                          <FormattedMessage id="periodo" />:{' '}
                        </b>
                      </span>
                      <span>{model.getModel('descricaoPeriodo').value}</span>
                    </Col>
                  )}
                  {!ehPlanejamentoInicial && (
                    <>
                      <Col md={1} className={baseClasses.colunaValor}>
                        <span>
                          <b>{capitalizeFirstLetter(resources.meta)}: </b>
                        </span>
                        <span>{model.getModel('meta').value}</span>
                      </Col>
                      <Col md={1} className={baseClasses.colunaValor}>
                        <span>
                          <b>
                            <FormattedMessage id="realizado" />:{' '}
                          </b>
                        </span>
                        <span>{model.getModel('realizado').value}</span>
                      </Col>
                      <Col md={1} className={baseClasses.colunaValor}>
                        <span>
                          <b>
                            <FormattedMessage id="desvio" />:{' '}
                          </b>
                        </span>
                        <span>{model.getModel('desvio').value}</span>
                      </Col>
                      <Col md={1} className={baseClasses.colunaValor}>
                        <span>
                          <b>{capitalizeFirstLetter(resources.farol)}: </b>
                        </span>
                        {farol && (
                          <SvgIcon
                            style={{ display: 'inline' }}
                            icon={farol.icone}
                            color={farol.cor}
                            colorHover={farol.cor}
                            title={farol.descricao}
                          ></SvgIcon>
                        )}
                      </Col>
                    </>
                  )}
                  {configuracao.value &&
                    configuracao.value.habilitarCopiar.id !== 0 &&
                    !isConsultaExterna &&
                    model.getModel('status').value &&
                    model.getModel('status').value.id !== 1 && (
                      <Col md={3} style={{ textAlign: 'right' }}>
                        <Mdilcon
                          icon={'content-copy'}
                          size={20}
                          onClick={() => this.handleOpenRelatorios()}
                          color="primary"
                        ></Mdilcon>
                        <Info
                          id="copiarRelatorioAcompanhamento"
                          placement="right"
                          text={
                            <FormattedMessage id="copiarRelatorioPreenchidoParaRelatorioPendente" />
                          }
                        />
                      </Col>
                    )}
                </Row>
                {isNew && ehPlanejamentoInicial && (
                  <Row style={{ marginTop: 0 }}>
                    <Col md={4}>
                      <DatePicker
                        year
                        timeFormat={false}
                        model={{
                          label: intl.formatMessage({ id: 'periodo' }),
                          value: model.getModel('dataPeriodo').value,
                          requestChange:
                            model.getModel('dataPeriodo').requestChange,
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row style={{ marginTop: 0 }}>
                  <Col md={4}>
                    <StatusRelatorioLookup
                      model={{
                        label: <FormattedMessage id="status" />,
                        value: model.getModel('status').value,
                        requestChange: this.handleChangeStatus,
                      }}
                      data={{ idSelecionaveis: [0, 1, 2] }}
                      clearable={false}
                      disabled={
                        disabled || model.getModel('status').value.id !== 2
                      }
                    />
                  </Col>
                  <Col md={2} className="pull-right">
                    <ArquivoUploadAnexos
                      tipoAnexo={this.props.tiposAnexos.relatorioAcompanhamento}
                      model={model.getModel('arquivos')}
                      disabledClick={disabled || isConsultaExterna}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {configuracao.value &&
                      configuracao.value.habilitarGrafico.id !== 0 &&
                      grafico &&
                      item && (
                        <Panel
                          header={capitalizeFirstLetter(
                            intl.formatMessage({ id: 'graficoValores' })
                          )}
                        >
                          <div
                            style={{ width: '100%', height: 400 }}
                            id="graficoRelatorioPag"
                          >
                            <GraficoValores item={item} grafico={grafico} />
                          </div>
                        </Panel>
                      )}
                  </Col>
                </Row>
                <Row style={{ marginTop: 0 }}>
                  {configuracao.value &&
                    configuracao.value.habilitarOrientacao.id !== 0 && (
                      <Col md={12}>
                        <Card open>
                          <div>
                            {ReactHtmlParser(
                              configuracao.value.orientacaoTexto
                            )}
                          </div>
                        </Card>
                      </Col>
                    )}
                </Row>
                <Row>
                  <Col md={12}>
                    <Panel
                      header={capitalizeFirstLetter(
                        resources.entendendoasituacao
                      )}
                      style={{ marginBottom: 0 }}
                    >
                      {configuracao.value &&
                        configuracao.value.habilitarPlanejado.id !== 0 && (
                          <Col md={12} style={{ marginTop: 0 }}>
                            <Panel
                              header={capitalizeFirstLetter(
                                resources.planejado
                              )}
                            >
                              <div
                                className={`text-editor ${
                                  disabled ? 'containerDisabled' : ''
                                }`}
                              >
                                <ReactQuill
                                  value={
                                    model.getModel('planejado') &&
                                    model.getModel('planejado').value
                                  }
                                  onChange={this.handleChangeText.bind(
                                    this,
                                    'planejado'
                                  )}
                                  readOnly={disabled || isConsultaExterna}
                                  theme="snow"
                                  formats={this.formats}
                                  modules={this.modules}
                                />
                              </div>
                            </Panel>
                          </Col>
                        )}
                      {configuracao.value &&
                        configuracao.value.habilitarExecutado.id !== 0 && (
                          <Col md={12}>
                            <Panel
                              header={capitalizeFirstLetter(
                                resources.executado
                              )}
                            >
                              <div
                                className={`text-editor ${
                                  disabled ? 'containerDisabled' : ''
                                }`}
                              >
                                <ReactQuill
                                  value={
                                    model.getModel('executado') &&
                                    model.getModel('executado').value
                                  }
                                  theme="snow"
                                  onChange={this.handleChangeText.bind(
                                    this,
                                    'executado'
                                  )}
                                  readOnly={disabled || isConsultaExterna}
                                  formats={this.formats}
                                  modules={this.modules}
                                />
                              </div>
                            </Panel>
                          </Col>
                        )}
                      {configuracao.value &&
                        configuracao.value.habilitarFatos.id !== 0 && (
                          <Col md={12}>
                            <Panel
                              header={capitalizeFirstLetter(resources.fatos)}
                            >
                              <div
                                className={`text-editor ${
                                  disabled ? 'containerDisabled' : ''
                                }`}
                              >
                                <ReactQuill
                                  value={
                                    model.getModel('fatos') &&
                                    model.getModel('fatos').value
                                  }
                                  theme="snow"
                                  onChange={this.handleChangeText.bind(
                                    this,
                                    'fatos'
                                  )}
                                  readOnly={disabled || isConsultaExterna}
                                  formats={this.formats}
                                  modules={this.modules}
                                />
                              </div>
                            </Panel>
                          </Col>
                        )}
                      {configuracao.value &&
                        configuracao.value.habilitarEstratificacaoPareto.id !==
                          0 && (
                          <Col md={12}>
                            {model.getModel(
                              'contadorInicialTabelaEstratificacao'
                            ).value && (
                              <EstratificacaoPareto
                                item={model.getModel('item').value}
                                estratificacoesModel={model.getModel(
                                  'estratificacoesValores'
                                )}
                                contadorInicial={
                                  model.getModel(
                                    'contadorInicialTabelaEstratificacao'
                                  ).value
                                }
                              />
                            )}
                          </Col>
                        )}
                    </Panel>
                  </Col>
                </Row>
                <Row>
                  {configuracao.value &&
                    configuracao.value.habilitarPontosProblematicos.id !==
                      0 && (
                      <Col md={12}>
                        {(this.props.model.getModel('id').value &&
                        this.props.model.getModel('id').value != 0
                          ? true
                          : false) &&
                          !this.state.isLoading && (
                            <PontosProblematicosList
                              saved={this.state.saved}
                              showExport={false}
                              showNew={!isConsultaExterna && !disabled}
                              showMenu={!isConsultaExterna && !disabled}
                              disabled={isConsultaExterna || disabled}
                              showDelete={!isConsultaExterna && !disabled}
                              showAnaliseCausa={!isConsultaExterna && !disabled}
                              model={model}
                              refreshModel={this.refreshModel}
                              isConsultaExterna={isConsultaExterna}
                            />
                          )}
                      </Col>
                    )}
                </Row>
                <Row>
                  {configuracao.value &&
                    configuracao.value.habilitarAcoes.id !== 0 && (
                      <Col md={12}>
                        {(this.props.model.getModel('id').value &&
                        this.props.model.getModel('id').value != 0
                          ? true
                          : false) &&
                          !this.state.isLoading && (
                            <AcaoList
                              saved={this.state.saved}
                              configuracao={configuracao}
                              exportApresentacao={this.props.exportApresentacao}
                              kanban={!this.props.exportApresentacao}
                              showToolBar={!this.props.exportApresentacao}
                              formatoKanbanDefault={false}
                              columnsCard={this.state.columnsCard}
                              columnsKanban={this.state.columnsKanban}
                              allColumnsCard={this.state.allColumnsCard}
                              allColumnsKanban={this.state.allColumnsKanban}
                              showDelete={!disabled}
                              onDeleting={this.handleDeleteClick}
                              params={{
                                relatorioId:
                                  this.props.model.getModel('id').value,
                                itemId: itemId,
                              }}
                              showPrevious={false}
                              showLink={true}
                              showNew={!isConsultaExterna && !disabled}
                              showSearch={false}
                              naoSalvarFiltro={true}
                              showMenuAcao={false}
                              disabled={isConsultaExterna && disabled}
                              showExport={false}
                              createChildItems={createChildItemsAcao}
                              refreshModel={this.refreshModel}
                              tag={2005}
                              isConsultaExterna={isConsultaExterna}
                            ></AcaoList>
                          )}
                      </Col>
                    )}
                </Row>
                {configuracao.value &&
                  configuracao.value.habilitarProjetos.id !== 0 &&
                  this.props.model.getModel('id').value &&
                  !this.state.isLoading && (
                    <Row>
                      <Col md={12}>
                        <Panel
                          header={capitalizeFirstLetter(resources.projetos)}
                        >
                          <ProjetosList
                            saved={this.state.saved}
                            model={this.props.model.getModel('projetos')}
                            params={{
                              relatorioId:
                                this.props.model.getModel('id').value,
                            }}
                            showSearch={!isConsultaExterna}
                            isConsultaExterna={isConsultaExterna}
                          ></ProjetosList>
                        </Panel>
                      </Col>
                    </Row>
                  )}
              </div>
            </Tab>
            <Tab key="2" label={capitalizeFirstLetter(resources.historico)}>
              <Historico
                url={`/RelatorioAcompanhamento/ObterHistorico`}
                id={this.props.model.getModel('id').value}
              />
            </Tab>
          </Tabs>
        </LoadingContainer>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    user: state.user,
    moduloAtual: state.user.moduloAtual,
    tiposAnexos: state.user.tiposAnexos,
    viewType: state.viewType,
  };
}
export default injectIntl(connect(mapStateToProps)(FormEdit));
