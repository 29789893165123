import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'reactstrap';

import Panel from 'src/componentes/panel-collapse';
import Button from 'src/componentes/button';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import InputField from 'src/componentes/input';
import InputNumber from 'src/componentes/input-number';
import Grafico from 'src/paginas/grafico';

import http from 'src/services/httpService';

const columns = [
  {
    id: 1,
    field: 'descricao',
  },
  {
    id: 2,
    description: 'quantidadeValor',
    field: 'quantidadeValor',
  },
  {
    id: 3,
    description: 'percentualAcumulado',
    field: 'percentualAcumulado',
  },
  {
    id: 4,
    description: 'percentualTotal',
    field: 'percentualTotal',
  },
  {
    id: 5,
  },
];

const newStratification = {
  descricao: null,
  quantidadeValor: null,
  percentualAcumulado: 0,
  percentualTotal: 0,
};

function ValorExterno({
  item,
  code,
  estratificacoesModel,
  estratificacoesCode,
  allowEdit,
}) {
  const intl = useIntl();
  const [viewGraphic, setViewGraphic] = useState(false);
  const [graphic, setGraphic] = useState(null);

  useEffect(() => {
    if (estratificacoesModel.value) {
      handleGenerateGraphic();
    }
  }, [estratificacoesModel.value]); // atualiza o grafico sempre que a lista de valores mudar, seja deletando, adicionando ou editando

  const handleAdd = () => {
    const newList = Object.assign([], estratificacoesModel.value);
    newList.push({ ...newStratification, codigoTabela: code });

    estratificacoesModel.requestChange(newList);
  };

  const handleChange = (index, field, value) => {
    const newList = Object.assign([], estratificacoesModel.value);
    const itemCorrente = newList[index];

    if (!itemCorrente) return;

    itemCorrente[field] = value;
    estratificacoesModel.requestChange(newList);

    if (field === 'quantidadeValor') {
      calculatePercentage(field, newList); // atualiza os percentuais com os novos valores
    }
  };

  const calculatePercentage = (field, list) => {
    const newList = Object.assign([], list ? list : estratificacoesCode);

    let valorTotal = 0;
    for (var i = 0; i < newList.length; i++) {
      if (newList[i][field]) valorTotal += parseFloat(newList[i][field]);
    }

    for (var i = 0; i < newList.length; i++) {
      const itemCorrente = newList[i];
      if (itemCorrente[field]) {
        let valorCorrente = (parseFloat(newList[i][field]) / valorTotal) * 100;
        itemCorrente['percentualTotal'] = valorCorrente;

        if (i === 0) itemCorrente['percentualAcumulado'] = valorCorrente;
        else
          itemCorrente['percentualAcumulado'] =
            newList[i - 1]['percentualAcumulado'] + valorCorrente;
      }
    }
  };

  const handleDelete = (index) => {
    const newList = Object.assign([], estratificacoesModel.value);
    newList.splice(index, 1);

    estratificacoesModel.requestChange(newList);

    calculatePercentage(
      'quantidadeValor',
      newList.filter((x) => x.codigoTabela === code)
    );
  };

  const handleGenerateGraphic = async () => {
    const valoresFiltrados = estratificacoesModel.value.filter(
      (x) => x.codigoTabela === code && x.quantidadeValor > 0
    ); // o valoresFiltrados é uma nova lista que tem só as estratificações validas da tabela atual e com valores preenchidos

    if (valoresFiltrados.length === 0) {
      return; // nao tenta gerar o grafico se nao tiver dados
    }

    setViewGraphic(true);

    const newData = await http.post(
      'RelatorioAcompanhamento/ObterGraficoEstratificacaoPareto',
      {
        valores:
          estratificacoesModel.value &&
          estratificacoesModel.value.length > 0 &&
          estratificacoesModel.value.filter((x) => x.codigoTabela === code),
        valoresFiltrados,
      }
    );

    if (newData && newData.data && newData.data.grafico)
      setGraphic(newData.data.grafico);
  };

  const renderColumn = (index, stratification, column) => {
    const isInputText = column.id === 1;
    const isPercentual = column.id === 3 || column.id === 4;
    const isRemove = column.id === 5;

    if (isInputText)
      return (
        <InputField
          disabled={!allowEdit}
          placeholder={intl.formatMessage({ id: 'novaEstratificacao' })}
          formStyle={{ marginBottom: 0 }}
          model={{
            value: stratification[column.field],
            requestChange: (v) => handleChange(index, column.field, v),
          }}
        />
      );
    else if (isRemove)
      return (
        <div className="d-flex justify-content-center align-items-center">
          {allowEdit && (
            <MdiIcon
              onClick={() => handleDelete(index)}
              className="cursor-pointer"
              icon="delete-outline"
            />
          )}
        </div>
      );

    return (
      <InputNumber
        precision={2}
        placeholder={intl.formatMessage({ id: 'digiteValor' })}
        formStyle={{ marginBottom: 0 }}
        className="text-center"
        disabled={isPercentual || !allowEdit}
        model={{
          value: stratification[column.field],
          requestChange: (v) => handleChange(index, column.field, v),
        }}
      />
    );
  };

  const renderContent = () => (
    <>
      <Table>
        <thead>
          {columns.map(({ id, description }) => {
            const isItem = id === 1;
            const isRemove = id === 5;
            const width = isItem ? '50%' : '';

            return (
              <th
                className={`p-3 ${
                  isItem
                    ? 'text-primary bg-light-gray'
                    : 'text-white bg-primary'
                }`}
                style={{
                  width,
                  minWidth: width,
                  maxWidth: width,
                  verticalAlign: 'middle',
                }}
              >
                {!isRemove && (
                  <div
                    className={
                      !isItem &&
                      'h-100 w-100 d-flex justify-content-center align-items center'
                    }
                  >
                    {isItem
                      ? `${capitalizeFirstLetter(
                          intl.formatMessage({ id: 'item' })
                        )}: ${item?.nome}`
                      : intl.formatMessage({
                          id: description,
                        })}
                  </div>
                )}
              </th>
            );
          })}
        </thead>
        <tbody>
          {estratificacoesModel &&
            estratificacoesModel.value &&
            estratificacoesModel.value.map((stratification, index) => {
              const canShow = stratification?.codigoTabela === code;
              return (
                canShow && (
                  <tr>
                    {columns.map((column) => (
                      <td>{renderColumn(index, stratification, column)}</td>
                    ))}
                  </tr>
                )
              );
            })}
        </tbody>
      </Table>
    </>
  );

  const renderAddButton = () => (
    <div className="d-flex align-items-center text-primary" onClick={handleAdd}>
      <Button outline>
        <MdiIcon icon="plus" size={12} />
      </Button>
      <div className="ml-2 text-primary">
        {`${capitalizeFirstLetter(
          intl.formatMessage({ id: 'adicionar' })
        )} ${intl.formatMessage({ id: 'estratificacao' })}`}
      </div>
    </div>
  );

  const renderActions = () => (
    <div className="d-flex justify-content-between mt-2 text-md">
      {allowEdit && renderAddButton()}
    </div>
  );

  const renderGraphic = () => (
    <Panel
      className="mt-3"
      header={capitalizeFirstLetter(
        intl.formatMessage({ id: 'graficoPareto' })
      )}
    >
      {graphic && <Grafico grafico={graphic} />}
    </Panel>
  );

  return (
    <>
      {renderContent()}
      {renderActions()}
      {viewGraphic && renderGraphic()}
    </>
  );
}

export default ValorExterno;
