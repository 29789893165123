import React from 'react';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Input from 'src/componentes/input';
import Checkbox from 'src/componentes/checkbox';
import DatePicker from 'src/componentes/date-picker';
import StatusLookup from 'src/lookups/status-item';
import ResponsavelLookup from 'src/lookups/colaborador';
import DigitadorLookup from 'src/lookups/colaborador';
import StatusReuniaoLookup from 'src/lookups/status-reuniao';
import TagLookup from 'src/lookups/TagLookup';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import { capitalizeFirstLetter } from 'src/utils/string';

class ReuniaoSearch extends React.Component {
  render() {
    let { model, resources, intl } = this.props;

    return (
      <div>
        <Row>
          <Col md={3}>
            <Input model={model.getModel('assunto')} />
          </Col>

          <Col md={3}>
            <Input model={model.getModel('idIntegracao')} />
          </Col>

          <Col md={3}>
            <DatePicker
              timeformat
              placeholder={intl.formatMessage({ id: 'label.selecioneAHora' })}
              model={model.getModel('dataInicioItem')}
            />
          </Col>

          <Col md={3}>
            <DatePicker
              timeformat
              placeholder={intl.formatMessage({ id: 'label.selecioneAHora' })}
              model={model.getModel('dataTerminoItem')}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <ResponsavelLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.responsaveis),
                value: model.getModel('responsaveis').value,
                requestChange: model.getModel('responsaveis').requestChange,
              }}
            />
          </Col>

          <Col md={3}>
            <DigitadorLookup
              multi={true}
              model={{
                label: intl.formatMessage({ id: 'label.participantes' }),
                value: model.getModel('participantes').value,
                requestChange: model.getModel('participantes').requestChange,
              }}
            />
          </Col>
          <Col md={4}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.area),
                value: model.getModel('areas').value,
                requestChange: model.getModel('areas').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>

          <Col md={3}>
            <StatusReuniaoLookup
              model={model.getModel('statusReuniao')}
              data={{ idsSelecionados: [1, 2, 3, 4, 5] }}
            />
          </Col>

          <Col md={3}>
            <Checkbox
              model={model.getModel('utilizaRecorrencia')}
              style={{ marginTop: 27 }}
            />
          </Col>
          <Col md={3}>
            <TagLookup
              multi
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'tags' },
                    { tags: resources.tags }
                  )
                ),
                value: model.getModel('tags').value,
                requestChange: model.getModel('tags').requestChange,
              }}
              data={{ funcionalidadesIdSelecionaveis: [11] }}
              podeEditar={false}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracaoSistema: state.user.configuracao,
  };
}

export default injectIntl(connect(mapStateToProps)(ReuniaoSearch));
