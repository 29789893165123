import Lookup from 'src/componentes/select-async';
import ItemLookupMulti from 'src/componentes/item-multi-lookup';

import SearchComponent from './SearchComponent';

const ColaboradorEGrupoColaboradoresLookup = ({ disabled, ...rest }) => {
  const renderItemComponent = (item) => {
    const isValid =
      item.value &&
      (typeof item.value === 'object' ? item.value.id : item.value.length > 0);

    const isGroup = item.value.identificadorLista === 'grupos';

    return isValid ? (
      <ItemLookupMulti
        avatar={!isGroup}
        disabled={disabled}
        onlyAvatar={false}
        label={item.value.descricao}
        customClassname={
          isGroup ? 'bg-primary-light border border-primary' : ''
        }
        onRemove={() => !disabled && item.onRemove && item.onRemove(item.value)}
      />
    ) : (
      ''
    );
  };

  return (
    <Lookup
      showSearch
      disabled={disabled}
      labelKey="descricao"
      valueKey="idLookup"
      url="/ColaboradorEGrupoColaboradoresLookup"
      searchComponent={SearchComponent}
      valueComponent={renderItemComponent}
      {...rest}
    />
  );
};

export default ColaboradorEGrupoColaboradoresLookup;
