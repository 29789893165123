import React from 'react';
import { autobind } from 'core-decorators';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import TipoAcessoLookup from 'src/lookups/tipo-acesso';
import PermissaoEdit from './edit';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import { Form } from 'src/utils/form';
import Schema from './schema';

@autobind
class ParteInteressada extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      disabled: false,
    };

    this.form = new Form({
      component: this,
      schema: Schema,
    });
  }

  handleChangeAcesso(item, valor) {
    http
      .post(`/PermissaoOrganizacao/AtualizarTipoAcesso`, {
        permissaoOrganizacaoId: item.id,
        tipoAcessoId: valor ? valor.id : 0,
      })
      .then((response) => {
        this.setState({
          saved: Math.random(),
          isLoading: true,
          showMessage: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });
  }

  renderAcesso(item) {
    let { disabled } = this.props;

    return (
      <TipoAcessoLookup
        disabled={disabled}
        model={{
          value: item.tipoAcesso,
          requestChange: this.handleChangeAcesso.bind(this, item),
        }}
        style={{ marginBottom: '-5px' }}
      ></TipoAcessoLookup>
    );
  }

  render() {
    let { resources, intl, parameters, disabled, ...other } = this.props;

    return (
      <div>
        <List
          url="/PermissaoOrganizacao"
          saved={this.state.saved}
          editComponent={PermissaoEdit}
          showSearch={true}
          showNew={!disabled}
          showMenu={!disabled}
          parameters={parameters}
          forceRefresh={true}
          {...other}
        >
          <ListColumn
            headerText={capitalizeFirstLetter(resources.colaborador)}
            valueField="colaborador"
          ></ListColumn>
          <ListColumn
            headerText={intl.formatMessage({ id: 'label.acesso' })}
            valueFunction={this.renderAcesso}
            minHeight={38}
          ></ListColumn>
        </List>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ParteInteressada));
