import React from 'react';
import { autobind } from 'core-decorators';
import { Label, UncontrolledTooltip } from 'reactstrap';
import css from '../utils/css';
import guid from '../utils/guid';

const classes = css`
  .text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    text-align: left;
  }
`;

@autobind
class InputTextLookup extends React.Component {
  static defaultProps = {
    text: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      exibirInput: false,
      chave: guid(),
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    let ref = this.ref;

    if (this.props.disabled == true && ref && !ref.contains(event.target)) {
      this.setState({ exibirInput: false });
    }
  }

  getError(model) {
    if (model && model.errors && model.errors != '') return true;

    return false;
  }

  renderSingle(model) {
    let { labelField, options } = this.props;
    let { chave } = this.state;

    let id = `id${chave}`;

    let error = this.getError(model);
    let valor = model && model.value;
    let label = model && model.label;

    return (
      <div
        class="form-group"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: this.props.center == true && 'center',
        }}
      >
        <Label
          style={{ color: error && 'rgb(240, 80, 80)' }}
          className="control-label"
        >
          {label}
          <span style={{ marginLeft: 3 }}>
            {' '}
            {this.props.required ? '*' : ''}
          </span>
        </Label>
        {this.state.exibirInput ? (
          <div ref={(node) => (this.ref = node)}>
            <this.props.input
              {...this.props}
              ref={(node) => (this.ref = node)}
              autoFocus
              onBlur={() => this.setState({ exibirInput: false })}
              getAlteracao={() =>
                !this.props.text && this.setState({ exibirInput: false })
              }
              showLabel={false}
              blockingOnFirstClick
            />
          </div>
        ) : labelField != null && valor != null ? (
          options != null ? (
            options.map(
              (item) =>
                item.id == valor && (
                  <strong
                    style={{ color: error && 'rgb(240, 80, 80)' }}
                    id={id}
                    className={classes.text}
                    onClick={() => this.setState({ exibirInput: true })}
                  >
                    {(item && item[labelField]) || '-'}
                    <UncontrolledTooltip placement="top" target={id}>
                      {(item && item[labelField]) || '-'}
                    </UncontrolledTooltip>
                  </strong>
                )
            )
          ) : (
            <strong
              style={{ color: error && 'rgb(240, 80, 80)' }}
              id={id}
              className={classes.text}
              onClick={() => this.setState({ exibirInput: true })}
            >
              {(valor && valor[labelField]) || '-'}
              <UncontrolledTooltip placement="top" target={id}>
                {(valor && valor[labelField]) || '-'}
              </UncontrolledTooltip>
            </strong>
          )
        ) : (
          <strong
            style={{ color: error && 'rgb(240, 80, 80)' }}
            id={id}
            className={classes.text}
            onClick={() => this.setState({ exibirInput: true })}
          >
            {valor || '-'}
            <UncontrolledTooltip placement="top" target={id}>
              {valor || '-'}
            </UncontrolledTooltip>
          </strong>
        )}
      </div>
    );
  }

  render() {
    return this.renderSingle(this.props.model);
  }
}

export default InputTextLookup;
