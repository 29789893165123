import { Column, ColumnStore } from '@bryntum/gantt';

export default class TextColumn extends Column {
  static get $name() {
    return 'text';
  }

  static get type() {
    return 'text';
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      filterable: false,
      sortable: false,
    };
  }
}

ColumnStore.registerColumnType(TextColumn);
