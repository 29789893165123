import { useRef, useEffect } from 'react';
import FeedbackTour from 'src/components/FeedbackTour/FeedbackTour';
import { capitalizeFirstLetter } from 'src/utils/string';
import ContentManager from 'src/componentes/content-manager';
import ReuniaoEdit from 'src/paginas/reuniao/edit';
import ParticipantesReuniao from 'src/paginas/reuniao/colaborador-reuniao/edit';
import { useTour } from '@reactour/tour';

const reuniaoPageSteps = (
  intl,
  resources,
  jaExisteFeedback,
  isLoadingFeedback
) => {
  const { isOpen } = useTour();
  const participantesModalKey = useRef(null);
  const newModalKey = useRef(null);

  const openModalNew = () => {
    newModalKey.current = ContentManager.addContent(<ReuniaoEdit />);
  };

  const openModalParticipantes = () => {
    if (!participantesModalKey.current) {
      participantesModalKey.current = ContentManager.addContent(
        <ParticipantesReuniao />
      );
    }
  };

  const closeModalParticipantes = () => {
    if (participantesModalKey.current) {
      ContentManager.removeContent(participantesModalKey.current);
      participantesModalKey.current = null;
    }
  };

  const closeModalNew = () => {
    if (newModalKey.current) {
      ContentManager.removeContent(newModalKey.current);
      newModalKey.current = null;
    }
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (!isOpen) {
      ContentManager.removeAllContentsByTarget();
      enableScroll();
    }
  }, [isOpen]);

  const feature = { id: 5, descricao: 'reuniao' };

  return [
    {
      selector: '.iniciando-tour',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'iniciandoTour' })
      ),
      position: [20, 20],
      action: disableScroll,
    },
    {
      selector: '.reuniao-step1',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'cadastrarNovaReuniao' })
      ),
      action: () => {
        openModalNew();
      },
    },
    {
      selector: '.reuniao-step2',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'camposObrigatoriosReuniao' },
          {
            codigo: resources.codigo,
            area: resources.area,
            responsavel: resources.responsavel,
          }
        )
      ),
      position: [1150, 100],
    },
    {
      selector: '.reuniao-step3',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'recorrenciaReuniao' })
      ),
    },
    {
      selector: '.reuniao-step4',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'adicionaParticipantes' })
      ),
      action: () => openModalParticipantes(),
    },
    {
      selector: '.reuniao-step5',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'selecionaParticipantes' })
      ),
      position: [100, 200],
    },
    {
      selector: '.reuniao-step6',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'camposDestacadosPauta' })
      ),
      action: () => closeModalParticipantes(),
    },
    {
      selector: '.reuniao-step7',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'cliqueSalvar' })
      ),
      actionAfter: () => {
        closeModalNew(), enableScroll();
      },
    },
    ...(jaExisteFeedback
      ? []
      : [
          {
            selector: '.dash-feedback',
            content: (
              <FeedbackTour
                feature={feature}
                intl={intl}
                isLoadingFeedback={isLoadingFeedback}
              />
            ),
            position: 'center',
          },
        ]),
  ];
};

export default reuniaoPageSteps;
