import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select2';

@autobind
class ModeloRiscoLookup extends React.Component {
  static defaultProps = {
    clearable: false,
  };

  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/ModeloRiscoLookup" clearable={this.props.clearable} {...this.props} />;
  }
}

export default ModeloRiscoLookup;
