import React, { Suspense, lazy } from 'react';
import qs from 'query-string';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import LoadingContainer from 'src/componentes/loading-container';
import Button from 'src/componentes/button';
import history from 'src/history';
import Panel from 'src/componentes/panel';
import http from 'src/services/httpService';
import EditPage from 'src/componentes/edit/edit-page';
import LoadingScreen from 'src/componentes/LoadingScreen';

import Resumo from './resumo';
import Schema from '../../indicador/schema';
import DropdownItemList from '../dropdown-item';
import { capitalizeFirstLetter } from 'src/utils/string';
import { TIPO_ITENS } from 'src/utils/constants';

const Desdobramento = lazy(() => import('./desdobramento'));
const Consolidacao = lazy(() => import('./consolidacao'));
const Configuracao = lazy(() => import('./configuracao'));
const Permissoes = lazy(() => import('./permissao'));
const Historico = lazy(() => import('./../../versao-alteracao'));
const StatusReportList = lazy(() => import('../../projeto/status-report'));
const IndicadoresParametros = lazy(() =>
  import('../../projeto/valores/indicadores-parametros')
);
const TermoAbertura = lazy(() => import('./termoabertura'));
const CestaIndicadores = lazy(() => import('./cestaindicadores'));

let idAtual = null;

@autobind
class ItemEdit extends React.Component {
  static defaultProps = {
    activeTab: 1,
    showDelete: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      saved: false,
      count: 0,
      activeTab: this.props.activeTab,
      modified: false,
      titleItem: '',
      configuracoes: {},
      utilizarPermissoes: true,
      quantidadesMudancasPage: 0,
      permissions: {
        allowEdit: false,
        allowDelete: false,
        allowView: false,
        allowViewPlanejamentoInicial: false,
      },
    };
  }

  componentDidMount() {
    idAtual = this.props.id;
    const tipoId = this.props.tipoId;

    // Verifica se tem permissao para deletar
    this.getPermissions(idAtual);

    if (tipoId == 1 || tipoId == 3 || tipoId == 2) {
      http
        .post(
          `/ItemConfiguracao${this.getTipoItem(
            tipoId,
            true
          )}/ObterConfiguracoes`,
          {
            parameters: this.props.parameters,
          }
        )
        .then((response) => {
          this.setState({
            loaded: true,
            isLoading: false,
            configuracoes: response.data,
            utilizarPermissoes:
              response.data && response.data.utilizarPermissoes,
          });
        });
    }
  }

  getPermissions(id) {
    http
      .post(`/Item/GetPermissoesPorId`, {
        id: id,
      })
      .then((response) => {
        if (response.data != null) {
          this.setState({
            permissions: { ...response.data },
          });
        }
      });
  }

  componentDidUpdate() {
    if (this.props.id != idAtual) {
      idAtual = this.props.id;
      if (this.state.activeTab != 1) {
        this.setState({
          activeTab: 1,
        });
      }
    }
  }

  handelToggleTab(tab) {
    this.setState({
      quantidadesMudancasPage: this.state.quantidadesMudancasPage++,
    });
    this.navigate(tab);
  }

  handleBack() {
    this.handleBackConfirm();
  }

  handleSaveConfirm(tab) {
    this.navigate(tab);
  }

  handleBackConfirm() {
    history.goBack();
  }

  navigate(tab) {
    history.push({
      pathname: this.props.urlEdit,
      search: `?id=${this.props.id}&activeTab=${tab}`,
    });

    if (this.state.activeTab != tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getTipoItem(tipoItemId, nomeAbreviado) {
    if (tipoItemId == 1) return 'Indicador';
    else if (tipoItemId == 2) return 'Parametro';
    else if (tipoItemId == 3)
      return nomeAbreviado ? 'Objetivo' : 'ObjetivoEstrategico';
    else if (tipoItemId == TIPO_ITENS.PROJETO) return 'Projeto';
    else if (tipoItemId == 10) return 'DesempenhoIndividual';
    else if (tipoItemId == 11) return 'CalculoIntermediario';
    else if (tipoItemId == 12) return 'TemplateIndicador';
    else if (tipoItemId == 13) return 'TemplateParametro';
    else if (tipoItemId == 14) return 'TemplateObjetivoEstrategico';
    else if (tipoItemId == 15) return 'TemplateProjeto';
  }

  renderContent() {
    let { activeTab } = this.state;
    const { model } = this.props;

    let tipoItemId = this.props.tipoId;
    let tipoItemString = this.getTipoItem(tipoItemId);
    const ehProjetoEmAprovacao =
      TIPO_ITENS.PROJETO &&
      model.getModel('status') &&
      model.getModel('status').value &&
      (model.getModel('status').value.id == 6 ||
        model.getModel('status').value.id == 7);

    if (activeTab == 1)
      return (
        <Resumo
          model={this.props.model}
          url={this.props.url}
          schema={this.props.schema}
          id={this.props.id}
          onChangeTitle={this.handleChangeTitle}
        />
      );
    else if (
      activeTab == 2 &&
      (tipoItemId == 1 || tipoItemId == 2 || tipoItemId == 3)
    )
      return <Desdobramento id={this.props.id} model={this.props.model} />;
    else if (activeTab == 3 && tipoItemId != TIPO_ITENS.PROJETO)
      return (
        <Consolidacao
          disabled={
            this.props.model.getModel('permissions').value
              ? !this.props.model.getModel('permissions').getModel('allowEdit')
                  .value
              : false
          }
          model={this.props.model.getModel('consolidacao')}
          tipoItemId={tipoItemId}
          id={this.props.id}
        />
      );
    else if (
      activeTab == 4 &&
      this.state.utilizarPermissoes &&
      !ehProjetoEmAprovacao
    )
      return (
        <Permissoes
          model={this.props.model}
          id={this.props.id}
          url="/ItemPermissao"
          action="AtualizarTipoAcesso"
          actionPermission="ObterItemPermissions"
          parameters={{
            itemId: this.props.id,
            tipoItemId: tipoItemId,
            url: '/ItemPermissao',
          }}
        />
      );
    else if (activeTab == 5 && !ehProjetoEmAprovacao)
      return (
        <Configuracao
          modelGrafico={this.props.model}
          model={this.props.model.getModel('configuracao')}
          utilizarGestaoMudancas={
            this.props.model.getModel('configuracaoGeral').value &&
            this.props.model.getModel('configuracaoGeral').value
              .utilizarGestaoMudancas
          }
          utilizarValoresPrevios={
            this.props.model.getModel('configuracaoGeral').value &&
            this.props.model.getModel('configuracaoGeral').value
              .utilizarValoresPrevios
          }
          tipoItemId={tipoItemId}
          id={this.props.id}
          exibirCorDoFarol={this.props.exibirCorDoFarol}
        />
      );
    else if (activeTab == 6 && tipoItemId != 10 && tipoItemId != 11)
      return (
        <Panel>
          <Historico
            url={`/${tipoItemString}/ObterHistorico`}
            id={this.props.id}
          />
        </Panel>
      );
    else if (activeTab == 7 && tipoItemId == TIPO_ITENS.PROJETO)
      return (
        <StatusReportList
          url="/ItemPermissao"
          actionPermission="ObterItemPermissions"
          id={this.props.id}
        />
      );
    else if (activeTab == 9 && tipoItemId == TIPO_ITENS.PROJETO)
      return (
        <IndicadoresParametros
          url={`/${tipoItemString}/ObterIndicadoresParametros`}
          id={this.props.id}
        />
      );
    else if (
      activeTab == 10 &&
      tipoItemId == TIPO_ITENS.PROJETO &&
      !ehProjetoEmAprovacao
    )
      return <TermoAbertura model={this.props.model} id={this.props.id} />;
    else if (
      activeTab == 11 &&
      (tipoItemId == 1 || tipoItemId == 2 || tipoItemId == 3)
    )
      return <CestaIndicadores itemId={this.props.id} />;
  }

  handleTabAnaliseStakeholders() {
    history.push({
      pathname: '/gerenciar/projeto/analise-stakeholders',
      search: `?id=${this.props.id}`,
    });
  }

  handleTabEAP() {
    history.push({
      pathname: '/acompanhar/eap',
      search: `?itemId=${this.props.id}`,
    });
  }

  handleChangeTitle(titleItem) {
    this.setState({ titleItem });
  }

  obterFiltroUrl(item) {
    let { filtro } = this.props;
    let url = '';

    // Filtra o Id do Item
    url = `?itemId=${item.id}`;

    if (filtro != null && filtro != undefined) {
      url += `&openLastFilter=false`;

      // Tipo Filtro Data
      if (filtro.tipoFiltroData != null && filtro.tipoFiltroData.id > 0)
        url += `&tipoFiltroDataId=${filtro.tipoFiltroData.id}`;

      // Data Inicio
      if (filtro.dataInicio != null) url += `&dataInicio=${filtro.dataInicio}`;

      // Data Termino
      if (filtro.dataTermino != null)
        url += `&dataTermino=${filtro.dataTermino}`;
    }

    return url;
  }

  handleValueClick(item) {
    let url = this.obterFiltroUrl(item);

    history.push({
      pathname: '/gerenciar/valor',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  render() {
    const { activeTab, configuracoes, permissions } = this.state;
    const { id, tipoId, resources, intl, moduloAtual, model } = this.props;

    const ehProjetoEmAprovacao =
      TIPO_ITENS.PROJETO &&
      model.getModel('status') &&
      model.getModel('status').value &&
      (model.getModel('status').value.id == 6 ||
        model.getModel('status').value.id == 7);

    let exibirPlanoAcao = configuracoes && configuracoes.permitirCadastrarAcoes;
    let utilizaAnaliseStakeholders = this.props.model.getModel('configuracaoGeral').value &&
    this.props.model.getModel('configuracaoGeral').value
      .utilizarAnaliseStakeholders;

    return (
      <Suspense fallback={<LoadingScreen />}>
        <div style={{ marginTop: 15 }}>
          <Row>
            <Col md={4} lg={3}>
              <Panel>
                <ListGroup flush>
                  <ListGroupItem
                    action
                    className={activeTab == 1 ? 'active' : ''}
                    onClick={() => {
                      this.handelToggleTab(1);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <FormattedMessage id="label.resumo">
                      {(txt) => (
                        <p
                          style={{
                            overflowWrap: 'normal',
                            textOverflow: 'ellipsis',
                            overflowX: 'hidden',
                            marginBottom: 'initial',
                          }}
                        >
                          {txt}
                        </p>
                      )}
                    </FormattedMessage>
                  </ListGroupItem>
                  {(tipoId == 1 || tipoId == 2 || tipoId == 3) && (
                    <ListGroupItem
                      action
                      className={activeTab == 2 ? 'active' : ''}
                      onClick={() => {
                        this.handelToggleTab(2);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <FormattedMessage id="desdobramento">
                        {(txt) => (
                          <p
                            style={{
                              overflowWrap: 'normal',
                              textOverflow: 'ellipsis',
                              overflowX: 'hidden',
                              marginBottom: 'initial',
                            }}
                          >
                            {txt}
                          </p>
                        )}
                      </FormattedMessage>
                    </ListGroupItem>
                  )}
                  {tipoId != TIPO_ITENS.PROJETO && (
                    <ListGroupItem
                      action
                      className={activeTab == 3 ? 'active' : ''}
                      onClick={() => {
                        this.handelToggleTab(3);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <FormattedMessage id="label.consolidacao">
                        {(txt) => (
                          <p
                            style={{
                              overflowWrap: 'normal',
                              textOverflow: 'ellipsis',
                              overflowX: 'hidden',
                              marginBottom: 'initial',
                            }}
                          >
                            {txt}
                          </p>
                        )}
                      </FormattedMessage>
                    </ListGroupItem>
                  )}
                  {this.state.utilizarPermissoes &&
                    tipoId != 12 &&
                    tipoId != 13 &&
                    tipoId != 14 &&
                    tipoId != 15 &&
                    tipoId != 17 &&
                    !ehProjetoEmAprovacao && (
                      <ListGroupItem
                        action
                        className={activeTab == 4 ? 'active' : ''}
                        onClick={() => {
                          this.handelToggleTab(4);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <FormattedMessage id="label.permissoes">
                          {(txt) => (
                            <p
                              style={{
                                overflowWrap: 'normal',
                                textOverflow: 'ellipsis',
                                overflowX: 'hidden',
                                marginBottom: 'initial',
                              }}
                            >
                              {txt}
                            </p>
                          )}
                        </FormattedMessage>
                      </ListGroupItem>
                    )}
                  {!ehProjetoEmAprovacao && (
                    <ListGroupItem
                      action
                      className={activeTab == 5 ? 'active' : ''}
                      onClick={() => {
                        this.handelToggleTab(5);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <FormattedMessage id="configuracoes">
                        {(txt) => (
                          <p
                            style={{
                              overflowWrap: 'normal',
                              textOverflow: 'ellipsis',
                              overflowX: 'hidden',
                              marginBottom: 'initial',
                            }}
                          >
                            {txt}
                          </p>
                        )}
                      </FormattedMessage>
                    </ListGroupItem>
                  )}
                  {tipoId != 10 && tipoId != 11 && tipoId != 17 && (
                    <ListGroupItem
                      action
                      className={activeTab == 6 ? 'active' : ''}
                      onClick={() => {
                        this.handelToggleTab(6);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <FormattedMessage id="label.historico">
                        {(txt) => (
                          <p
                            style={{
                              overflowWrap: 'normal',
                              textOverflow: 'ellipsis',
                              overflowX: 'hidden',
                              marginBottom: 'initial',
                            }}
                          >
                            {txt}
                          </p>
                        )}
                      </FormattedMessage>
                    </ListGroupItem>
                  )}
                  {tipoId == TIPO_ITENS.PROJETO && !ehProjetoEmAprovacao && (
                    <ListGroupItem
                      action
                      className={activeTab == 7 ? 'active' : ''}
                      onClick={() => {
                        this.handelToggleTab(7);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {capitalizeFirstLetter(resources.statusreport)}
                    </ListGroupItem>
                  )}
                  {tipoId == 17 && (
                    <ListGroupItem
                      action
                      className={activeTab == 7 ? 'active' : ''}
                      onClick={() => {
                        this.handleValueClick({ id: id, tipo: { id: tipoId } });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'valores' },
                          { valores: resources.valores }
                        )
                      )}
                    </ListGroupItem>
                  )}
                  {moduloAtual &&
                    moduloAtual.id == 7 &&
                    tipoId == TIPO_ITENS.PROJETO &&
                    !ehProjetoEmAprovacao && (
                      <ListGroupItem
                        action
                        className={activeTab == 9 ? 'active' : ''}
                        onClick={() => {
                          this.handelToggleTab(9);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {capitalizeFirstLetter(
                          intl.formatMessage({ id: 'indicadoresEParametros' })
                        )}
                      </ListGroupItem>
                    )}
                  {moduloAtual &&
                    moduloAtual.id == 7 &&
                    tipoId == TIPO_ITENS.PROJETO &&
                    !ehProjetoEmAprovacao && (
                      <ListGroupItem
                        action
                        className={activeTab == 10 ? 'active' : ''}
                        onClick={() => {
                          this.handelToggleTab(10);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {capitalizeFirstLetter(resources.tap)}
                      </ListGroupItem>
                    )}
                  {moduloAtual &&
                    moduloAtual.id == 3 &&
                    (tipoId == 1 || tipoId == 2 || tipoId == 3) && (
                      <ListGroupItem
                        action
                        className={activeTab == 11 ? 'active' : ''}
                        onClick={() => {
                          this.handelToggleTab(11);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {capitalizeFirstLetter(resources.cardmetas)}
                      </ListGroupItem>
                    )}
                  {moduloAtual &&
                    moduloAtual.id == 7 &&
                    tipoId == TIPO_ITENS.PROJETO &&
                    !ehProjetoEmAprovacao && utilizaAnaliseStakeholders &&(
                      <ListGroupItem
                        action
                        onClick={() => {
                          this.handleTabAnaliseStakeholders();
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {capitalizeFirstLetter(
                          intl.formatMessage({ id: 'analiseStakeholders' })
                        )}
                      </ListGroupItem>
                    )}
                  {moduloAtual &&
                    moduloAtual.id == 7 &&
                    tipoId == TIPO_ITENS.PROJETO && (
                      <ListGroupItem
                        action
                        onClick={() => {
                          this.handleTabEAP();
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {capitalizeFirstLetter(
                          intl.formatMessage({ id: 'eapGrafica' })
                        )}
                      </ListGroupItem>
                    )}
                </ListGroup>
                {tipoId != 17 && !ehProjetoEmAprovacao && (
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="mt-2"
                      caret
                      color="secondary"
                      style={{ width: '100%' }}
                    >
                      <FormattedMessage id="label.mais" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItemList
                        exportResumo={
                          tipoId == TIPO_ITENS.PROJETO || tipoId == 1
                            ? true
                            : false
                        }
                        exibirEdicao={false}
                        resources={resources}
                        item={{
                          id: id,
                          tipo: { id: tipoId },
                          permissions: permissions,
                        }}
                        configuracoes={configuracoes}
                        exibirPlanoAcao={exibirPlanoAcao}
                        ehEditar
                      />
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}

                {/* Desabilitado para evitar duplicidade do botão "Voltar", já que o título de cada página já inclui esse controle à partir de agora no componente de content.jsx. */}

                {/* <Button
                  color="secondary"
                  leftIcon="arrow-left"
                  style={{ width: '100%' }}
                  className="mt-2"
                  onClick={this.handleBack}
                >
                  <FormattedMessage id="label.voltar" />
                </Button> */}
              </Panel>
            </Col>
            <Col Col lg={9} md={8}>
              <LoadingContainer isLoading={this.state.isLoading}>
                {this.renderContent()}
              </LoadingContainer>
            </Col>
          </Row>
        </div>
      </Suspense>
    );
  }
}

class Edit extends React.Component {
  handleClose() {
    history.push(this.urlList);
  }

  render() {
    let { intl, location, model, showPrompt } = this.props; //
    let id = location && qs.parse(location.search).id;

    return (
      <EditPage
        title="teste"
        titleField="nome"
        url="/Item"
        formComponent={ItemEdit}
        schema={Schema}
        showTopToolbar={false}
        id={id}
        handleCloseCustom={this.handleClose}
        showPrompt={showPrompt}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloAtual: state.user.moduloAtual,
  };
}
export default injectIntl(connect(mapStateToProps)(Edit));
