import { useIntl, FormattedMessage } from 'react-intl';
import Edit from 'src/componentes/edit/edit-dialog';
import Geral from './geral';
import { Schema } from 'src/utils/form';
import useAuth from 'src/hooks/useAuth';

const schema = Schema.object({
  descricao: Schema.string().required(),
  funcionalidades: Schema.string().required(),
  cor: Schema.string()
    .required()
    .label(<FormattedMessage id="cor" />),
});

const EditForm = ({ model }) => {
  const intl = useIntl();

  return <Geral model={model} />;
};

const EditView = (props) => {
  const { terms: resources } = useAuth();
  const intl = useIntl();
  const id = props.item && props.item.id;

  return (
    <Edit
      {...props}
      url="/Tag"
      title={intl.formatMessage({ id: 'tags' }, { tags: resources.tags })}
      schema={schema}
      formComponent={EditForm}
      width="60%"
      height={500}
      intl={intl}
      showExport={false}
    />
  );
};

export default EditView;
