import { useState, useEffect, useCallback } from 'react';
import { ListGroup, ListGroupItem, ButtonGroup, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';
import { useIntl } from 'react-intl';

import LoadingContainer from 'src/componentes/loading-container';
import Panel from 'src/componentes/panel';
import css from 'src/utils/css';
import Avatar from 'src/componentes/avatar';
import AvatarGroup from 'src/componentes/AvatarGroup';
import MdiIcon from 'src/componentes/mdi-icon';
import { fetchEvents, fetchEventById } from './services/eventsService';
import CreateEvent from './createEvent';

const ListEvent = ({
  refreshEvents,
  onEventSelect,
  selectedEvent,
  onEventCreated,
  model,
  setSelectedEvent,
  disabled,
}) => {
  const intl = useIntl();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showCreateEvent, setShowCreateEvent] = useState(false);

  const fetchEventsData = useCallback(
    async (deselectEvent = false) => {
      setIsLoading(true);
      try {
        const meetingId = model.getModel('meetingId')?.value;
        const eventsData = meetingId
          ? await fetchEventById(instance, meetingId)
          : await fetchEvents(instance, currentDate);

        const dateFormattedEventsData = eventsData.map((event) => ({
          ...event,
          start: {
            ...event.start,
            formattedDateTime: new Date(
              moment.utc(event.start.dateTime).format()
            ),
          },
          end: {
            ...event.end,
            formattedDateTime: new Date(
              moment.utc(event.end.dateTime).format()
            ),
          },
        }));

        setEvents(dateFormattedEventsData);

        if (deselectEvent) {
          onEventSelect(null);
        } else {
          setSelectedEvent(
            dateFormattedEventsData.find((event) => event.id === meetingId)
          );
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [instance, currentDate, model.getModel('meetingId')?.value]
  );

  useEffect(() => {
    fetchEventsData();
  }, [fetchEventsData, refreshEvents]);

  const classes = css`
    .tituloNegrito {
      color: #000000;
      text-align: left;
      background-color: #fff;
      font-weight: bold;
    }
  `;

  /*
  const handleDelete = async (eventId) => {
    setIsLoading(true);
    try {
      const activeAccount = instance.getActiveAccount();
      if (!activeAccount) {
        throw new Error('No active account found');
      }

      const accessToken = await instance
        .acquireTokenSilent({
          scopes: ['https://graph.microsoft.com/Calendars.ReadWrite'],
          account: activeAccount,
        })
        .catch(() =>
          instance.acquireTokenPopup({
            scopes: ['https://graph.microsoft.com/Calendars.ReadWrite'],
          })
        );

      await deleteEvent(accessToken.accessToken, eventId);
      fetchEventsData();
    } catch (error) {
      console.error('Error deleting event:', error);
    } finally {
      setIsLoading(false);
    }
  };
  */

  const renderItem = (item) => {
    const timezoneOffset = new Date().getTimezoneOffset() / 60;

    const startTimeLocal = moment
      .utc(item.start.dateTime)
      .utcOffset(-timezoneOffset)
      .format('DD/MM/YYYY HH:mm');
    const endTimeLocal = moment
      .utc(item.end.dateTime)
      .utcOffset(-timezoneOffset)
      .format('DD/MM/YYYY HH:mm');

    return (
      <ListGroupItem
        key={item.id}
        className={`d-flex align-items-center border-1 border-[#dbdbdb]`}
        style={{
          transition: 'background-color 0.3s',
          cursor: !disabled ? 'pointer' : 'not-allowed',
          backgroundColor:
            selectedEvent && selectedEvent.id === item.id ? '#c3c3c3' : '',
        }}
        onMouseEnter={(e) => {
          if (!selectedEvent || selectedEvent.id !== item.id) {
            e.currentTarget.style.backgroundColor = '#f0f0f0';
          }
        }}
        onMouseLeave={(e) => {
          if (!selectedEvent || selectedEvent.id !== item.id) {
            e.currentTarget.style.backgroundColor = '';
          }
        }}
        onClick={(e) => {
          if (disabled) return;

          if (e.target.tagName == 'A') {
            return;
          }

          onEventSelect(
            selectedEvent && selectedEvent.id === item.id ? null : item
          );
        }}
      >
        <div className="mr-4" style={{ flexBasis: '20%' }}>
          {item.subject}
        </div>
        <div className="mr-4" style={{ flexBasis: '15%' }}>
          {startTimeLocal}
        </div>
        <div className="mr-4" style={{ flexBasis: '15%' }}>
          {endTimeLocal}
        </div>
        <div className="mr-4" style={{ flexBasis: '20%' }}>
          <a
            href={disabled ? '#' : item.onlineMeeting?.joinUrl}
            target={disabled ? '' : '_blank'}
            rel="noopener noreferrer"
            title={intl.formatMessage({ id: 'linkTeams' })}
          >
            {intl.formatMessage({ id: 'linkTeams' })}
          </a>
        </div>
        <div className="mr-4" style={{ flexBasis: '30%' }}>
          <AvatarGroup>
            {item.attendees.map((att) => (
              <Avatar
                key={att.emailAddress.address}
                showInitials={true}
                label={att.emailAddress.name}
                alt={att.emailAddress.name}
                showTooltip
              />
            ))}
          </AvatarGroup>
        </div>
      </ListGroupItem>
    );
  };

  const goToDate = (nextDate) => {
    setCurrentDate(nextDate);
  };

  const goToCurrent = () => {
    if (disabled) return;
    onEventSelect(null);
    goToDate(new Date());
  };

  const goToPastWeek = () => {
    if (disabled) return;
    goToDate(moment(currentDate).subtract(1, 'week').toDate());
    onEventSelect(null);
  };

  const goToNextWeek = () => {
    if (disabled) return;
    goToDate(moment(currentDate).add(1, 'week').toDate());
    onEventSelect(null);
  };

  const getCurrentMonth = () => moment(currentDate).format('MMMM');

  const getDateTitle = () => (
    <span className="text-center">
      <b>{getCurrentMonth().toUpperCase()}</b>
    </span>
  );

  const getWeekSelected = () => {
    const start = moment(currentDate).startOf('week');
    const end = moment(currentDate).endOf('week');
    return `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`;
  };

  const handleAddTeamsMeeting = () => {
    if (disabled) return;
    setShowCreateEvent((prev) => !prev);
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <div>
        <Panel className="w-100">
          <div className="d-flex justify-content-between mb-4">
            <ButtonGroup>
              <Button
                disabled={disabled}
                color="secondary"
                onClick={goToPastWeek}
              >
                {intl.formatMessage({ id: 'semanaAnterior' })}
              </Button>
              <Button
                disabled={disabled}
                color="secondary"
                onClick={goToCurrent}
              >
                {intl.formatMessage({ id: 'semanaAtual' })}
              </Button>
              <Button
                disabled={disabled}
                color="secondary"
                onClick={goToNextWeek}
              >
                {intl.formatMessage({ id: 'semanaProxima' })}
              </Button>
            </ButtonGroup>

            <div className="d-flex align-items-center">
              <Button
                disabled={disabled}
                onClick={() => fetchEventsData(true)}
                className="mr-2"
              >
                <MdiIcon icon="sync" />
              </Button>

              <Button
                disabled={disabled}
                color="primary"
                onClick={handleAddTeamsMeeting}
              >
                <MdiIcon icon="plus" />
              </Button>
            </div>
          </div>

          {showCreateEvent && (
            <CreateEvent
              onEventCreated={onEventCreated}
              model={model}
              setShowCreateEvent={setShowCreateEvent}
            />
          )}

          <div className="flex align-items-center gap-4 mb-2">
            {getDateTitle()} - {`(${getWeekSelected()})`}
          </div>

          <ListGroup
            style={{
              minHeight: 300,
              maxHeight: 600,
              overflow: 'auto',
            }}
          >
            <ListGroupItem className="d-flex align-items-center">
              <div className="text-bold mr-4" style={{ flexBasis: '20%' }}>
                <span className={classes.tituloNegrito}>
                  {intl.formatMessage({ id: 'titulo' })}
                </span>
              </div>
              <div className="text-bold mr-4" style={{ flexBasis: '15%' }}>
                <span className={classes.tituloNegrito}>
                  {intl.formatMessage({ id: 'dataInicio' })}
                </span>
              </div>
              <div className="text-bold mr-4" style={{ flexBasis: '15%' }}>
                <span className={classes.tituloNegrito}>
                  {intl.formatMessage({ id: 'dataTermino' })}
                </span>
              </div>
              <div className="text-bold mr-4" style={{ flexBasis: '20%' }}>
                <span className={classes.tituloNegrito}>
                  {intl.formatMessage({ id: 'link' })}
                </span>
              </div>
              <div className="text-bold mr-4" style={{ flexBasis: '30%' }}>
                <span className={classes.tituloNegrito}>
                  {intl.formatMessage({ id: 'participantes' })}
                </span>
              </div>
              {/*
              <div className="text-bold mr-4" style={{ flexBasis: '5%' }}>
                <span className={classes.tituloNegrito}>Ações</span>
              </div>
              */}
            </ListGroupItem>

            {events && events.length > 0 ? (
              events.map((item) => renderItem(item))
            ) : (
              <p style={{ fontStyle: 'italic', padding: 20 }}>
                <FormattedMessage id="label.nenhumRegistroFoiEncontrado" />
              </p>
            )}
          </ListGroup>
        </Panel>
      </div>
    </LoadingContainer>
  );
};

export default ListEvent;
