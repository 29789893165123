import { Column, ColumnStore } from '@bryntum/gantt';

import SvgIcon from 'src/componentes/svg-icon';
export default class FarolColumn extends Column {
  static get $name() {
    return 'farol';
  }

  static get type() {
    return 'farol';
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      field: 'farol',
      htmlEncode: false,
      editor: false,
      sortable: (first, second) => {
        return first.farol?.descricao < second.farol?.descricao ? -1 : 1;
      },
      filterable: ({ value, record }) => {
        return record?.farol?.descricao
          ?.toLowerCase()
          ?.includes(value?.toLowerCase());
      },
    };
  }

  renderer({ record }) {
    const { farol } = record || {};

    if (!farol) return '';

    //Ajuste feito devido ao componente bryntum/gantt não renderizar
    //componente na última coluna com o gantt colapsado (GP-418)
    const size = 22;
    return `<div style="font-size: ${size}px;" class="d-flex justify-content-center align-items-center"> <i style="width: ${size}px; height: ${size}px; color: ${farol.cor}; background-size: ${size}px;" class="icon icon-${farol.icone} meta-icon"></i> </div>`;
  }
}

ColumnStore.registerColumnType(FarolColumn);
