import { Row, Col } from 'reactstrap';

import classnames from 'classnames';
import DatePicker from '../date-picker';

function DateRangePicker({
  startValue,
  endValue,
  onChange,
  placeholderStart,
  placeholderEnd,
  timeFormat = false,
  className,
  disabledStartDate = false,
  disabled,
  required,
  useCols = false,
  errors,
  autoFormatDate = false,
  renderHint,
  month,
  ...rest
}) {
  const handleChangeStart = (value) => {
    if (!onChange) return;

    if (!value) {
      onChange(value, endValue);
      return;
    }

    if (!endValue) {
      onChange(value, endValue);
      return;
    }

    if (value > endValue) {
      onChange(value, value);
      return;
    }

    onChange(value, endValue);
    return;
  };

  const handleChangeEnd = (value) => {
    if (!onChange) return;

    if (!value) {
      onChange(startValue, value);
      return;
    }

    if (value === startValue) {
      onChange(startValue, startValue);
      return;
    }
    
    if (value < startValue) {
      onChange(startValue, value);
      return;
    }

    onChange(startValue, value);
    return;
  };

  const renderStartDate = () => {
    return (
      <>
        <DatePicker
          required={required}
          disabled={disabled || disabledStartDate}
          className="mr-2"
          timeFormat={timeFormat}
          autoFormatDate={autoFormatDate}
          placeholder={placeholderStart}
          model={{
            value: startValue,
            requestChange: (value) => handleChangeStart(value),
            errors: errors,
          }}
          month={month}
        />
        <div className="pl-1 pr-1">{renderHint && renderHint()}</div>
      </>
    );
  };

  const renderEndDate = () => (
    <DatePicker
      required={required}
      disabled={disabled}
      timeFormat={timeFormat}
      autoFormatDate={autoFormatDate}
      placeholder={placeholderEnd}
      model={{
        value: endValue,
        requestChange: (value) => handleChangeEnd(value),
        errors: errors,
      }}
      month={month}
    />
  );

  return (
    <div className={classnames('d-flex', className)} {...rest}>
      {useCols ? (
        <Row className="w-100">
          <Col md={6}>{renderStartDate()}</Col>
          <Col md={6}>{renderEndDate()}</Col>
        </Row>
      ) : (
        <>
          {renderStartDate()}
          {renderEndDate()}
        </>
      )}
    </div>
  );
}

export default DateRangePicker;
