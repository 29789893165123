import View from './view';

function MatrizItensImpactados(props) {
  return (
      <View />
  );
}


export default MatrizItensImpactados;
