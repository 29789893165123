import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import Content from 'src/componentes/pages/content';
import View from './view';

@autobind
class Reuniao extends React.Component {
  render() {
    const { showOnlyList = false } = this.props;
    return (
      showOnlyList ? <View {...this.props} /> :
      <Content
        titulo={<FormattedMessage id="reunioes" />}
        iconSettings={true}
        titleSettings={this.props.intl.formatMessage({
          id: 'label.configuracoesReuniao',
        })}
        urlSettings="/configuracoes/reuniao"
        endPointPermissionSettings="/itemConfiguracaoReuniao/PossuiPermissaoConfiguracao"
      >
        <View {...this.props} stickyHeader />
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(Reuniao));
