import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col, Table } from 'reactstrap';
import MdiIcon from '../../componentes/mdi-icon';
import Button from '../../componentes/button';
import FarolLookup from '../../lookups/farol';
import FarolIndicadorRiscoLookup from 'src/lookups/farol-indicador-risco';
import OperadorLookup from '../../lookups/operador';
import MinimoEMaximoLookup from 'src/lookups/minimo-maximo';
import InputNumber from '../../componentes/input-number';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';

@autobind
class Slider extends React.Component {
  handleValueChange(index, field, value) {
    let faixas = this.props.model.value;
    faixas[index][field] = value;

    this.props.model.requestChange(faixas);
  }

  handleNovaFaixa() {
    let faixas = this.props.model && this.props.model.value;
    if (faixas == null || faixas === undefined) faixas = [];
    faixas.push({
      operadorInferior: null,
      valorInferior: null,
      farol: null,
      operadorSuperior: null,
      valorSuperior: null,
      melhor: this.props.melhor.value,
      minimoEMaximoInferior: null,
      minimoEMaximoSuperior: null,
    });
    this.props.model.requestChange(faixas);
  }

  handleExcluir(index) {
    let faixas = this.props.model.value;
    faixas.splice(index, 1);
    this.props.model.requestChange(faixas);
  }

  render() {
    const { faixaIndicadorRisco = false } = this.props;
    let faixas = this.props.model && this.props.model.value;

    let tipoValor =
      this.props.tipoValor &&
      this.props.tipoValor.value &&
      this.props.tipoValor.value.id;

    return (
      <div>
        <Button
          type="primary"
          className="mt-2 mb-2"
          onClick={this.handleNovaFaixa}
        >
          <MdiIcon icon="plus" />
        </Button>
        <Row>
          <Col md={12}>
            {faixas && faixas.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: 90 }}>
                      <FormattedMessage id="label.operador" />
                    </th>
                    <th>
                      <FormattedMessage id="valor" />
                    </th>
                    <th style={{ width: 90 }}>
                      <FormattedMessage id="label.operador" />
                    </th>
                    <th>
                      <FormattedMessage id="valor" />
                    </th>
                    <th style={{ width: 90 }}>
                      {capitalizeFirstLetter(this.props.resources.farol)}
                    </th>
                    <th style={{ width: 40 }}></th>
                    <th style={{ width: '5%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {faixas.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: 100 }}>
                        <OperadorLookup
                          model={{
                            value: item.operadorInferior,
                            requestChange: this.handleValueChange.bind(
                              this,
                              index,
                              'operadorInferior'
                            ),
                          }}
                          style={{ marginBottom: '0' }}
                        ></OperadorLookup>
                      </td>
                      <td>
                        {tipoValor != 4 ? (
                          <InputNumber
                            precision={3}
                            style={{ marginBottom: '-15px' }}
                            model={{
                              value: item.valorInferior,
                              requestChange: this.handleValueChange.bind(
                                this,
                                index,
                                'valorInferior'
                              ),
                            }}
                          />
                        ) : (
                          <MinimoEMaximoLookup
                            model={{
                              value: item.minimoEMaximoInferior,
                              requestChange: this.handleValueChange.bind(
                                this,
                                index,
                                'minimoEMaximoInferior'
                              ),
                            }}
                            style={{ marginBottom: '0' }}
                          />
                        )}
                      </td>
                      <td style={{ width: 100 }}>
                        <OperadorLookup
                          model={{
                            value: item.operadorSuperior,
                            requestChange: this.handleValueChange.bind(
                              this,
                              index,
                              'operadorSuperior'
                            ),
                          }}
                          style={{ marginBottom: '0' }}
                        ></OperadorLookup>
                      </td>
                      <td>
                        {tipoValor != 4 ? (
                          <InputNumber
                            precision={3}
                            style={{ marginBottom: '-15px' }}
                            model={{
                              value: item.valorSuperior,
                              requestChange: this.handleValueChange.bind(
                                this,
                                index,
                                'valorSuperior'
                              ),
                            }}
                          />
                        ) : (
                          <MinimoEMaximoLookup
                            model={{
                              value: item.minimoEMaximoSuperior,
                              requestChange: this.handleValueChange.bind(
                                this,
                                index,
                                'minimoEMaximoSuperior'
                              ),
                            }}
                            style={{ marginBottom: '0' }}
                          />
                        )}
                      </td>
                      <td style={{ width: 100 }}>
                        <FarolLookup
                          model={{
                            value: item.farol,
                            requestChange: this.handleValueChange.bind(
                              this,
                              index,
                              'farol'
                            ),
                          }}
                          style={{ marginBottom: '0' }}
                          data={{
                            tipoFarolId: faixaIndicadorRisco || item.tipo?.id == 1 ? 12 : null,
                          }}
                        ></FarolLookup>
                      </td>
                      <td>
                        <MdiIcon
                          style={{ cursor: 'pointer' }}
                          icon="delete-outline"
                          onClick={this.handleExcluir.bind(this, index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(Slider);
