import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
@autobind
class TipoTolerancia extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/TipoToleranciaLookup" {...this.props} />;
  }
}

export default TipoTolerancia;
