import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import ContentManager from 'src/componentes/content-manager';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';

import Search from './search';
import SearchSchema from './search-schema';
import Edit from './edit';
import { ORIGEM_FAIXA_RESULTADO } from 'src/utils/constants';
@autobind
class PontosFaixaResultadoList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
    };
  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/PontoFaixaResultado/ValidarRestricoes"
        urlExclusao="/PontoFaixaResultado/Remove"
        item={item}
        onClose={this.handleCloseEdit}
      />
    );
  }

  handleCloseEdit(saved) {
    if (saved) this.setState({ saved: Math.random() });
  }

  render() {
    const { intl, origemFaixaId } = this.props;

    return (
      <List
        url="/PontoFaixaResultado"
        editComponent={Edit}
        saved={this.state.saved}
        searchComponent={<Search />}
        searchSchema={SearchSchema}
        showSearch={true}
        filtroId={320}
        onDeleting={this.handleDeleteClick}
        parameters={{ origemFaixaId: origemFaixaId }}
        {...this.props}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'idIntegracao' })}
          valueField="idIntegracao"
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'descricao' })}
          valueField="descricao"
          default
          required
        />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PontosFaixaResultadoList));
