import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from 'src/utils/string';
import { Row, Col } from 'reactstrap';
import Tab from 'src/componentes/tabs/tab';
import Tabs from 'src/componentes/tabs/tabs';
import PontoControleLookup from 'src/lookups/ponto-controle';
import StatusLookup from 'src/lookups/status-risco-ponto-controle';
import Input from 'src/componentes/input';
import Schema from './Schema';
import Edit from 'src/componentes/edit/edit-dialog';

export function CausaControleEditForm({ model, intl, resources, disabled = false, ...others }) {
  const createModel = (nomeItem, resourceStr) => {
    const modelTemp = model.getModel(nomeItem)
    return {
      label: capitalizeFirstLetter(resourceStr),
      value: modelTemp.value,
      requestChange: modelTemp.requestChange,
      errors: modelTemp.errors,
    };
  };

  const setRiscoId = () => {
    const modelRisco = model && model.getModel('riscoCausa');

    if (!modelRisco.value || (others.item && modelRisco.value.id !== others.item.id)) modelRisco.requestChange(others.item);
  };

  const riscoCausaId = others.item && others.item.id;

  setRiscoId();

  return (
    <div>
      <Tabs>
        <Tab key="1" label={intl.formatMessage({ id: 'label.resumo' })}>
          <Row>
            <Col md={4}>
              <PontoControleLookup disabled={disabled} data={{ riscoCausaId: riscoCausaId, funcaoPontoControle:{id: 1} }} model={createModel('controle', resources.pontodecontrole)} required />
            </Col>
            <Col md={4}>
              <StatusLookup disabled={disabled} model={createModel('status', intl.formatMessage({ id: 'status' }))} required />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Input rows={2} type="textarea" disabled={disabled} model={createModel('descricao', intl.formatMessage({ id: 'descricao' }))} />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
}

const CausaControleEditDialog = ({ resources, intl, ...rest }) => (
  <Edit
    url="/RiscoCausaControle"
    title={capitalizeFirstLetter(intl.formatMessage({ id: 'vincularControle' }, { pontodecontrole: resources.pontodecontrole }))}
    formComponent={CausaControleEditForm}
    resources={resources}
    schema={Schema}
    width="70%"
    maxHeight="90%"
    isNew={true}
    afterSave={rest.handleClose}
    {...rest}
  />
);

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(CausaControleEditDialog));
