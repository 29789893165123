import { useRef, useEffect } from 'react';
import FeedbackTour from 'src/components/FeedbackTour/FeedbackTour';
import { capitalizeFirstLetter } from 'src/utils/string';
import ContentManager from 'src/componentes/content-manager';
import AcaoEdit from 'src/paginas/acao/edit';
import { useTour } from '@reactour/tour';

const acaoPageSteps = (
  intl,
  resources,
  jaExisteFeedback,
  isLoadingFeedback
) => {
  const { isOpen } = useTour();
  const newModalKey = useRef(null);

  const feature = { id: 6, descricao: 'acao' };

  const openModalNew = () => {
    newModalKey.current = ContentManager.addContent(<AcaoEdit />);
  };

  const closeModalNew = () => {
    if (newModalKey.current) {
      ContentManager.removeContent(newModalKey.current);
      newModalKey.current = null;
    }
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (!isOpen) {
      ContentManager.removeAllContentsByTarget();
      enableScroll();
    }
  }, [isOpen]);

  return [
    {
      selector: '.iniciando-tour',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'iniciandoTour' })
      ),
      position: [20, 20],
      action: disableScroll,
    },
    {
      selector: '.acao-step1',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'registrarNovaAcao' },
          {
            acao: resources.acao,
            acoes: resources.acoes,
          }
        )
      ),
      action: () => {
        openModalNew();
      },
    },
    {
      selector: '.acao-step2',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'preenchaCamposObrigatorios' },
          {
            item: resources.item,
            responsavel: resources.responsavel,
          }
        )
      ),
    },
    {
      selector: '.acao-step3',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'definaDatas' }, {acao: resources.acao}) 
      ),
    },
    {
      selector: '.acao-step4',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'preencherDataRealizada' }, {acao: resources.acao})
      ),
    },
    {
      selector: '.acao-step5',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'preencherCamposOpcionais' })
      ),
      position: [1000, 3500], 
    },
    {
      selector: '.acao-step6',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'salvarAposRegistro' }, {acao: resources.acao})
      ),
      actionAfter: () => {
        closeModalNew(), enableScroll();
      },
    },
    ...(jaExisteFeedback
      ? []
      : [
          {
            selector: '.dash-feedback',
            content: (
              <FeedbackTour
                feature={feature}
                intl={intl}
                isLoadingFeedback={isLoadingFeedback}
              />
            ),
            position: 'center',
          },
        ]),
  ];
};

export default acaoPageSteps;
