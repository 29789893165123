import React from 'react';
import http from 'src/services/httpService';
import Button from '../../componentes/button';
import Dialog from '../../componentes/dialog';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { Form, Schema } from '../../utils/form';
import { FormattedMessage } from 'react-intl';
import LoadingContainer from '../../componentes/loading-container';
import Radio from '../../componentes/radio';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import errorHandler from '../../utils/error-handler';

let schema = Schema.object({
  pageOrientation: Schema.string()
    .label(<FormattedMessage id="label.orientacao" />)
    .required(),
});

@autobind
class ExportarRelatorioModal extends React.Component {
  static defaultProps = {
    fileExtension: 'pdf',
  };

  constructor(props) {
    super(props);

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    this.form.getModel('pageOrientation').requestChange({ id: 1, descricao: this.props.intl.formatMessage({ id: 'label.retrato' }) });
  }

  handleOkClick() {
    this.setState({
      isLoading: true,
    });

    http
      .post(
        `RelatorioPeriodicoVersao/ExportPdf`,
        {
          //htmlString: newHtml,
          fileExtension: this.props.fileExtension,
          orientacaoId: this.form.getModel('pageOrientation').value && this.form.getModel('pageOrientation').value.id,
          relatorioId: this.props.relatorioId,
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });

        DownloadFile(response);

        this.refs.dialog.close();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        errorHandler(ParseError(error));
      });
  }

  handleCancelarClick() {
    this.refs.dialog.close();
  }

  handleChange(value) {
    this.setState({ descricao: value });
  }

  handleChangeLookup(value) {
    this.setState({ area: value });
  }

  render() {
    const optionsPageOrientation = [
      { id: 1, descricao: this.props.intl.formatMessage({ id: 'label.retrato' }) },
      { id: 2, descricao: this.props.intl.formatMessage({ id: 'label.paisagem' }) },
    ];

    const actions = [
      <Button key={1} onClick={this.handleCancelarClick} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>,
      <Button key={2} type="primary" onClick={this.handleOkClick} htmlType="submit" className="ml-2">
        {<FormattedMessage id="label.ok" />}
      </Button>,
    ];

    return (
      <Dialog
        {...this.props}
        ref="dialog"
        title={<FormattedMessage id="exportar" />}
        actions={actions}
        onRequestClose={this.handleCancelarClick}
        width={500}
        minHeight={200}
        style={{ fontSize: 15 }}
        padContent
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <Row>
            <Col md={12}>
              <Radio model={this.form.getModel('pageOrientation')} options={optionsPageOrientation} labelField="descricao" idField="id" />
            </Col>
          </Row>
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(ExportarRelatorioModal));
