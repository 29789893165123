import { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import useAuth from 'src/hooks/useAuth';

import pPipe from 'src/utils/pPipe';
import Content from 'src/componentes/pages/content';
import http from 'src/services/httpService';
import { capitalizeFirstLetter } from 'src/utils/string';

import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';

import PontoControleView from './view';
import TesteControleView from 'src/paginas/gestao-risco/TesteControle/TesteControleView';
import { ADDONS } from 'src/utils/constants';

function PontoControleList({ resources, intl, ...rest }) {
  const [configuracao, setConfig] = useState(null);
  const { addons } = useAuth();
  const possuiAddonTesteControle = addons?.find((x) => x.id === ADDONS.TESTE_CONTROLE && x.ativo) != null;

  useEffect(() => {
    obterConfiguracao();
  }, []);

  const obterConfiguracao = async () =>
    await pPipe(
      async () =>
        await http.post(`/PontoControleConfiguracao/ObterConfiguracoes`),
      ({ data: { model } }) => model,
      setConfig
    )();

  if (!configuracao) 
    return null;

  return (
    <Content
      titulo={intl.formatMessage(
        { id: 'pontosDeControlesCadastrados'},
        { pontosdecontrole: resources.pontosdecontrole }
      )}
      iconSettings={true}
      urlSettings="/configuracoes/ponto-controle"      
      endPointPermissionSettings="/PontoControleConfiguracao/PossuiPermissaoConfiguracao"
    >
      <Tabs>
        <Tab label={capitalizeFirstLetter(resources.pontosdecontrole)} key="1">
          <PontoControleView
            {...{ ...rest, configuracao }}
            urlController={'/PontoControle'}
          />          
        </Tab>
        {possuiAddonTesteControle && configuracao.temPermissaoVisualizarTesteControle && (
          <Tab label={intl.formatMessage({ id: 'labelTesteControle' })} key="2"> 
            <TesteControleView 
              showPanel={false}
              showNew={false}
              showSearch={true}
            />
          </Tab>
        )}
      </Tabs>
    </Content>
  );
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(PontoControleList));