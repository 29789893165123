import React, { useCallback, useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import httpSelector from 'src/services/httpSelector';
import Toolbar from './Toolbar';
import Results from './Results';

export const getColumns = async (
  defaultColumns,
  entityTag,
  columnsTag,
  viewType,
  styleCustomFields
) => {
  try {
    let childrenArray = defaultColumns;
    if (!(childrenArray instanceof Array)) {
      childrenArray = [childrenArray];
    }

    const filtered = childrenArray.filter(function (x) {
      return x != null && x != undefined && x != false;
    });

    let allColumns = filtered.map((c) => c.props);
    let columns = [];

    if (entityTag) {
      const responseCustomFields = await http.post(
        `/CampoCustomizado/ObterListaCamposCustomizados`,
        { tipoItem: entityTag }
      );

      const fields = responseCustomFields.data;
      if (fields && fields.length > 0) {
        fields.map((itemCC) => {
          let colunaCC = {
            headerText: itemCC.campo.nome,
            valueField: itemCC.campo.nome,
            maxHeight: styleCustomFields?.maxHeight
              ? styleCustomFields.maxHeight
              : '',
            minWidth: styleCustomFields?.minWidth
              ? styleCustomFields.minWidth
              : '',
            tree: false,
            visible: true,
            default: false,
          };
          allColumns.push(colunaCC);
          return colunaCC;
        });
      }
    }

    const responseColumnsConfig = await httpSelector
      .getHttp(viewType)
      .post(`/ColunasTabela/ObterConfiguracoesColunasTabela`, {
        tag: columnsTag || 0,
      });
    const columnsConfig = responseColumnsConfig?.data;

    if (columnsConfig && columnsConfig.length > 0) {
      columnsConfig.map((coluna) => {
        let index = allColumns.findIndex(
          (c) => c.valueField === coluna.valueField
        );
        if (index !== -1) columns.push(allColumns[index]);

        return null;
      });
    }

    if (columns.length === 0) {
      columns = allColumns.filter((c) => c.visible && c.default);
      columns = columns.length === 0 ? allColumns : columns;
    }

    return {
      columns,
      allColumns,
    };
  } catch (err) {
    errorHandler(err);
  }
};

export const getPermissions = async (endpoint, parameters) => {
  const responsePermissions = await http.post(
    `${endpoint}/LoadPermissionsAndSearch`,
    { parameters: parameters }
  );
  return responsePermissions.data.permissions;
};

export const getFilter = async (filterTag, openLastFilter) => {
  const responseFilter = await http.post(
    `/FiltroColaborador/${filterTag}/${
      openLastFilter ? 'AbrirUltimoFiltro' : 'ObterFiltroModelVazio'
    }`
  );
  return responseFilter.data;
};

export const getData = async (
  endpoint,
  model,
  sortField = 'nome',
  sortFieldBy = 'asc',
  pageSize,
  page,
  parameters
) => {
  const responseData = await http.post(`${endpoint}/List`, {
    model: model,
    sort: `${sortField} ${sortFieldBy}`,
    pageSize: pageSize,
    page: page,
    parameters: parameters,
  });
  return responseData.data;
};

export const getExpandedData = async (
  endpoint,
  list,
  model,
  sort,
  pageSize,
  page,
  parameters
) => {
  const responseData = await http.post(`${endpoint}/Expand`, {
    list,
    model,
    sort,
    pageSize,
    page,
    parameters,
  });
  return responseData.data;
};

export const exportList = async (
  filter,
  endpoint,
  sortField,
  currentPage,
  parameters,
  fileExtension,
  pageSize = 999999
) => {
  try {
    const response = await http.post(
      `${endpoint}/ExportList`,
      {
        model: filter,
        sort: sortField,
        pageSize: pageSize,
        page: currentPage - 1,
        parameters: parameters,
        fileExtension: fileExtension,
      },
      {
        responseType: 'arraybuffer',
      }
    );
    DownloadFile(response);
  } catch (err) {
    errorHandler(ParseError(err));
  }
};

function NewList({
  endpoint,
  parameters,
  entityTag,
  columnsTag,
  filterTag,
  openLastFilter,
  defaultSort,
  defaultSortBy,
  defaultPageSize = 20,
  showExport,
  showSearch,
  searchSchema,
  searchComponent,
  useSavedFilter,
  resources,
  children,
  viewType,
}) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [filter, setFilter] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(defaultSort);
  const [sortFieldBy, setSortFieldBy] = useState(defaultSortBy);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  const loadColumns = async () => {
    try {
      const currentColumns = await getColumns(
        children,
        entityTag,
        columnsTag,
        viewType,
        styleCustomFields
      );
      setColumns(currentColumns.columns);
      setAllColumns(currentColumns.allColumns);
    } catch (err) {
      errorHandler(err);
    }
  };

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const newPermissions = await getPermissions(endpoint, parameters);
      setPermissions(newPermissions);

      let newData = [];

      if (newPermissions.allowView || newPermissions.allowList) {
        await loadColumns();
        const newFilter = await getFilter(filterTag, true);
        setFilter(newFilter);
        let valueFilter = {};
        if (newFilter != null) valueFilter = newFilter.valor;

        const model = { ...valueFilter, searchText };
        newData = await getData(
          endpoint,
          model,
          sortField,
          sortFieldBy,
          pageSize,
          currentPage - 1,
          parameters
        );
        setData(newData.list);
        setCount(newData.count);
      }
      setLoading(false);
      return newData;
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  }, [
    endpoint,
    parameters,
    searchText,
    pageSize,
    currentPage,
    sortField,
    sortFieldBy,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData, pageSize, currentPage, searchText, sortField, sortFieldBy]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  });

  const handleColumnsChange = useCallback(async () => {
    await loadColumns();
    await loadData();
  });

  const handleRefresh = useCallback(async () => {
    await loadData();
  });

  const handleChangeCurrentPage = useCallback((newValue) => {
    setCurrentPage(newValue);
  });

  const handleChangePageSize = useCallback((pageSize) => {
    setPageSize(pageSize);
  });

  const handleSortColumn = useCallback((sort) => {
    setSortField(sort);
  });

  const handleSortByColumn = useCallback((sortBy) => {
    setSortFieldBy(sortBy);
  });

  const handleExport = async (fileExtension) => {
    try {
      setLoading(true);

      let newSearchModel = {
        searchText,
        ...filter.valor,
      };

      await exportList(
        newSearchModel,
        endpoint,
        sortField,
        currentPage,
        parameters,
        fileExtension
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandler(ParseError(err));
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box>
        <Toolbar
          showSearch={showSearch}
          onExport={handleExport}
          onColumnsChange={handleColumnsChange}
          onSearchChange={handleSearchChange}
          onRefresh={handleRefresh}
          searchText={searchText}
          searchSchema={searchSchema}
          searchComponent={searchComponent}
          filter={filter}
          filterTag={filterTag}
          showExport={showExport}
          columnsTag={columnsTag}
          columns={columns}
          allColumns={allColumns}
          useSavedFilter={useSavedFilter}
          sortField={sortField}
        />
        <Results
          resources={resources}
          count={count}
          columns={columns}
          data={data}
          currentPage={currentPage}
          pageSize={pageSize}
          onChangeCurrentPage={handleChangeCurrentPage}
          onChangePageSize={handleChangePageSize}
          onSortColumn={handleSortColumn}
          onSortByColumn={handleSortByColumn}
          sort={sortField}
          sortBy={sortFieldBy}
        />
      </Box>
    );
  }
}

NewList.defaultProps = {
  useSavedFilter: true,
};

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    viewType: state.viewType,
  };
}

export default injectIntl(connect(mapStateToProps)(NewList));
