import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { DropdownItem, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import ModalPlanejamentoInicial from 'src/paginas/PlanejamentoInicial/view-modal';
import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';
import getEditUrl from 'src/utils/item/getEditUrl';
import errorHandler from 'src/utils/error-handler';
import MdiIcon from 'src/componentes/mdi-icon';
import http from 'src/services/httpService';
import Info from 'src/componentes/info';
import { TIPO_ITENS, MODULOS } from 'src/utils/constants';
import { manager } from 'src/services/cardMetaItemPermissions';
import ContextMenuIcon from 'src/componentes/context_menu_icon';

import ModalRelatorioAcompanhamento from '../relatorio-acompanhamento/modal';
import FichaObjetivoEstrategicoBase from '../objetivo-estrategico-base/ficha';
import FichaDesempenhoIndividual from '../remuneracao-variavel/desempenho-individual/ficha';
import FichaCalculoIntermediario from '../remuneracao-variavel/calculo-intermediario/ficha';
import ExportarRelatorioModal from '../relatorio-periodico/exportar-relatorio-modal';
import FichaIndicadorBase from '../indicador-base/ficha';
import FichaParametroBase from '../parametro-base/ficha';
import EmbedPowerBI from '../power-bi/componentes/power-bi-dialog';
import FichaProjeto from '../projeto/ficha';
import Mensagem from '../mensagem/modal';
import CurvaS from '../projeto/curvaS';
import history from '../../history';
import ProcessoEditView from 'src/paginas/gestao-risco/processo/edit';

@autobind
class DropdownItemCustom extends React.Component {
  static defaultProps = {
    exibirEdicao: true,
    exibirFicha: true,
    exibirValor: true,
    exibirRelacionamento: true,
    exibirRelatorioAcompanhamento: true,
    exibirInteligenciaArtificial: false,
    exibirMensagem: true,
    exportResumo: false,
    exibirMatrizResultados: true,
    exibirPlanoAcao: true,
    labelAI: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      utilizarCurvaS: this.props.configuracoes?.utilizarCurvaS,
      permitirDigitacaoValores:
        this.props.configuracoes?.permitirDigitacaoValores,
    };
  }

  componentDidMount() {
    const { item } = this.props;

    if (
      (this.state.utilizarCurvaS == null ||
        this.state.permitirDigitacaoValores == null) &&
      item &&
      item.tipo &&
      item.tipo.id === TIPO_ITENS.PROJETO
    ) {
      http
        .post(`/ItemConfiguracao/ObterConfiguracaoValorProjeto`, {})
        .then((response) => {
          this.setState({
            utilizarCurvaS: response.data.utilizarCurvaS,
            permitirDigitacaoValores: response.data.permitirDigitacaoValores,
          });
        });
    }
  }

  getLabel(item) {
    const { renderLabel } = this.props;

    if (renderLabel) {
      return renderLabel;
    } else {
      return item.nome;
    }
  }

  handleExportClick(item) {
    const urlExportItem = this.getUrlItemExport(item);

    if (urlExportItem != null && item != null)
      http
        .post(
          urlExportItem,
          {
            id: item.id,
          },
          {
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          DownloadFile(response);
        })
        .catch((error) => {
          errorHandler(ParseError(error));
        });
  }

  getUrlItemExport(item) {
    let urlExportItem = null;

    if (item.tipo && item.tipo.id == TIPO_ITENS.INDICADOR)
      urlExportItem = `/Indicador/ExportResumoIndicador`;
    else if (item.tipo && item.tipo.id == TIPO_ITENS.PROJETO)
      urlExportItem = `/Projeto/ExportResumoPlanoAcao`;

    return urlExportItem;
  }

  handleEditClick(item) {
    if (!item) return;

    const { callback, handleCloseModal, showModal } = this.props;

    if (showModal) {
      ContentManager.addContent(
        <this.props.editComponent
          {...this.props}
          item={item}
          handleClose={handleCloseModal}
          parameters={this.props.parameters}
        />
      );
    } else {
      const tipo = item.tipo && item.tipo?.id;
      const urlEdit = getEditUrl(tipo);

      history.push({
        pathname: `${urlEdit}`,
        search: `?id=${item.id}`,
      });
    }

    callback && callback();
    handleCloseModal && handleCloseModal();
  }

  obterFiltroUrl(item) {
    if (!item) return;

    const { filtro, visaoItensImpactados } = this.props;
    let url = `?itemId=${item.id}`;

    if (filtro != null && filtro != undefined) {
      url += `&openLastFilter=false`;

      // Tipo Filtro Data
      if (filtro.tipoFiltroData != null && filtro.tipoFiltroData.id > 0)
        url += `&tipoFiltroDataId=${filtro.tipoFiltroData.id}`;

      // Visão Itens Impactados
      if (visaoItensImpactados) url += `&visaoItensImpactados=true`;

      // Data Inicio
      if (filtro.dataInicio != null) url += `&dataInicio=${filtro.dataInicio}`;

      // Data Termino
      if (filtro.dataTermino != null)
        url += `&dataTermino=${filtro.dataTermino}`;
    }

    return url;
  }

  obterFiltroUrlMatrizResultados(item) {
    let { filtro } = this.props;
    let url = '';

    if (item && item.exibirItensCardMetas) {
      let tipoItemCardMetas = item.tipoItemCardMetas;

      let searchParam =
        tipoItemCardMetas && tipoItemCardMetas.id == 1
          ? `&colaboradorCardMetasId=${item.user.id}&colaboradorCardMetasNome=${item.user.nome}`
          : item.area
          ? `&areaId=${item.area.id}&areaNome=${item.area.nome}`
          : '';

      url = '?exibirItensCardMetas=true';

      url += searchParam;

      if (filtro != null && filtro != undefined) {
        if (filtro.tipoFiltroData != null && filtro.tipoFiltroData.id > 0) {
          url += `&tipoFiltroDataId=${filtro.tipoFiltroData.id}`;
          url += `&tipoFiltroDataDescricao=${filtro.tipoFiltroData.descricao}`;
        }

        if (filtro.dataInicio != null)
          url += `&dataInicio=${filtro.dataInicio}`;

        if (filtro.dataTermino != null)
          url += `&dataTermino=${filtro.dataTermino}`;
      }
    } else {
      // Filtra o Id do Item
      url = `?itemId=${item.id}`;

      if (filtro != null && filtro != undefined) {
        url += `&openLastFilter=false`;

        // Tipo Filtro Data
        if (filtro.tipoFiltroData != null && filtro.tipoFiltroData.id > 0)
          url += `&tipoFiltroDataId=${filtro.tipoFiltroData.id}`;

        // Data Inicio
        if (filtro.dataInicio != null)
          url += `&dataInicio=${filtro.dataInicio}`;

        // Data Termino
        if (filtro.dataTermino != null)
          url += `&dataTermino=${filtro.dataTermino}`;
      }
    }

    return url;
  }

  handleValueClick(item) {
    let url = this.obterFiltroUrl(item);

    history.push({
      pathname: '/gerenciar/valor',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handleRelatorioAcompanhamentoClick(item) {
    ContentManager.addContent(
      <ModalRelatorioAcompanhamento
        fromItem
        item={item}
        filtro={this.props.filtro}
      />
    );
    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handlePlanejamentoInicialClick(item) {
    ContentManager.addContent(
      <ModalPlanejamentoInicial
        fromItem
        item={item}
        filtro={this.props.filtro}
      />
    );
    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handlePlanoAcaoClick(item) {
    let url = this.obterFiltroUrl(item);

    history.push({
      pathname: '/gerenciar/gantt',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handleMensagemClick(item) {
    ContentManager.addContent(<Mensagem fromItem item={{ id: item.id }} />);
    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  handleCurvaSClick(item) {
    ContentManager.addContent(<CurvaS id={item.id}></CurvaS>);
    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  handleRelacionamentosClick(item) {
    let url = this.obterFiltroUrl(item);

    history.push({
      pathname: '/gerenciar/arvore',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handleMatrizResultadosClick(item) {
    let url = this.obterFiltroUrlMatrizResultados(item);

    history.push({
      pathname: '/acompanhar/matriz-resultado',
      search: url,
    });

    this.props.callback && this.props.callback();
    this.props.handleCloseModal && this.props.handleCloseModal();
  }

  handleFichaClick(item) {
    const tipo = item.tipo && item.tipo.id;

    if (tipo === TIPO_ITENS.INDICADOR) {
      ContentManager.addContent(
        <FichaIndicadorBase
          id={item.id}
          obterDadosFichaUrl={`/Indicador/ObterDadosFicha`}
        ></FichaIndicadorBase>
      );
    } else if (tipo === TIPO_ITENS.PARAMETRO) {
      ContentManager.addContent(
        <FichaParametroBase
          id={item.id}
          obterDadosFichaUrl={`/Parametro/ObterDadosFicha`}
        ></FichaParametroBase>
      );
    } else if (tipo === TIPO_ITENS.OBJETIVO_ESTRATEGICO) {
      ContentManager.addContent(
        <FichaObjetivoEstrategicoBase
          id={item.id}
          obterDadosFichaUrl={`/ObjetivoEstrategico/ObterDadosFicha`}
        ></FichaObjetivoEstrategicoBase>
      );
    } else if (tipo === TIPO_ITENS.PROJETO) {
      ContentManager.addContent(<FichaProjeto id={item.id}></FichaProjeto>);
    } else if (tipo === TIPO_ITENS.CALCULO_INTERMEDIARIO) {
      ContentManager.addContent(
        <FichaCalculoIntermediario id={item.id}></FichaCalculoIntermediario>
      );
    } else if (tipo === TIPO_ITENS.DESEMPENHO_INDIVIDUAL) {
      ContentManager.addContent(
        <FichaDesempenhoIndividual id={item.id}></FichaDesempenhoIndividual>
      );
    } else if (tipo === TIPO_ITENS.TEMPLATE_INDICADOR) {
      ContentManager.addContent(
        <FichaIndicadorBase
          id={item.id}
          obterDadosFichaUrl={`/TemplateIndicador/ObterDadosFicha`}
        ></FichaIndicadorBase>
      );
    } else if (tipo == TIPO_ITENS.TEMPLATE_PARAMETRO) {
      ContentManager.addContent(
        <FichaParametroBase
          id={item.id}
          obterDadosFichaUrl={`/TemplateParametro/ObterDadosFicha`}
        ></FichaParametroBase>
      );
    } else if (tipo == TIPO_ITENS.TEMPLATE_OBJETIVO_ESTRATEGICO) {
      ContentManager.addContent(
        <FichaObjetivoEstrategicoBase
          id={item.id}
          obterDadosFichaUrl={`/TemplateObjetivoEstrategico/ObterDadosFicha`}
        ></FichaObjetivoEstrategicoBase>
      );
    }

    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  handleExportGrafico() {
    let {
      item,
      dataInicio,
      dataTermino,
      tipoFiltroData,
      exibirAcumulado,
      periodicidadeExibicao,
    } = this.props;

    const htmlGrafico =
      document.getElementById('htmlGrafico') &&
      document.getElementById('htmlGrafico').outerHTML;
    const htmlGraficoAnosAnteriores =
      document.getElementById('htmlGraficoAnosAnteriores') &&
      document.getElementById('htmlGraficoAnosAnteriores').outerHTML;
    const htmlGraficoValoresAcumuladosAno =
      document.getElementById('htmlGraficoValoresAcumuladosAno') &&
      document.getElementById('htmlGraficoValoresAcumuladosAno').outerHTML;

    //seleciona as medidas do gráfico de anos antriores para evitar problema com o zoom da tela
    const svgGraficoAnosAnteriores =
      document
        .getElementById('graficoValoresItem')
        ?.querySelectorAll('svg')[0] || null;
    const widthGraficoAnosAnteriores =
      (svgGraficoAnosAnteriores &&
        svgGraficoAnosAnteriores.getAttribute('width')) ||
      1190;
    const heightGraficoAnosAnteriores =
      (svgGraficoAnosAnteriores &&
        svgGraficoAnosAnteriores.getAttribute('height')) ||
      450;

    //seleciona as medidas do gráfico de valores para evitar problema com o zoom da tela
    const svgGraficoValores =
      document.getElementById('htmlGrafico')?.querySelectorAll('svg')[1] ||
      null;
    const widthGraficoValores =
      (svgGraficoValores && svgGraficoValores.getAttribute('width')) || 1190;
    const heightGraficoValores =
      (svgGraficoValores && svgGraficoValores.getAttribute('height')) || 450;

    //seleciona as medidas do gráfico acumulados do ano para evitar problema com o zoom da tela
    const svgsValoresAcumulados =
      document.getElementById('htmlGraficoValoresAcumuladosAno') &&
      document
        .getElementById('htmlGraficoValoresAcumuladosAno')
        .querySelectorAll('svg');
    const widthValoresAcumulados =
      (svgsValoresAcumulados &&
        svgsValoresAcumulados[0] &&
        svgsValoresAcumulados[0].getAttribute('width')) ||
      400;
    const heightValoresAcumulados =
      (svgsValoresAcumulados &&
        svgsValoresAcumulados[0] &&
        svgsValoresAcumulados[0].getAttribute('height')) ||
      450;

    this.setState({
      isLoading: true,
    });

    http
      .post(
        '/ItemValor/ExportarGraficoValores',
        {
          id: item.id,
          widthGraficoValores: widthGraficoValores,
          heightGraficoValores: heightGraficoValores,
          widthGraficoAnosAnteriores: widthGraficoAnosAnteriores,
          heightGraficoAnosAnteriores: heightGraficoAnosAnteriores,
          tipoFiltroData: tipoFiltroData,
          dataInicio: dataInicio,
          dataTermino: dataTermino,
          exibirAcumulado: exibirAcumulado,
          periodicidadeExibicao: periodicidadeExibicao
            ? periodicidadeExibicao
            : null,
          htmlGrafico: htmlGrafico,
          htmlGraficoAnosAnteriores: htmlGraficoAnosAnteriores,
          htmlGraficoValoresAcumuladosAno: htmlGraficoValoresAcumuladosAno,
          heightValoresAcumulados: heightValoresAcumulados,
          widthValoresAcumulados: widthValoresAcumulados,
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        DownloadFile(response);

        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        errorHandler(ParseError(error));
      });
  }

  handleExport() {
    ContentManager.addContent(
      <ExportarRelatorioModal
        handleUrlCallBack={this.handleUrlCallBack}
        fileExtension="jpeg"
      />
    );
  }

  handleAnalytics(item) {
    window.open(item.analytics.uri, '_blank');
  }

  handleRelatorioBIClick(item) {
    http
      .post(`/PowerBI/ObterConfiguracoesPorIndicador`, {
        indicadorID: item.id,
      })
      .then((response) => {
        if (item.desdobramento) {
          response.data.model.campo = null;
        }
        ContentManager.addContent(
          <EmbedPowerBI
            itemId={item.id}
            relatorioPBI={response.data.model.relatorioPBI}
            embedURL={response.data.model.embedURL}
            workspacePBI={response.data.model.workspacePBI}
            filtros={response.data.model.filtros}
            embedToken={response.data.model.embedToken}
            usarAutenticacaoMicrosoft={
              response.data.model.usarAutenticacaoMicrosoft
            }
          />
        );
        this.setState({
          isLoading: false,
          resources: {},
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });

    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  handleClickSophie(item) {
    this.props.handleClickSophieCallBack &&
      this.props.handleClickSophieCallBack(item);
  }

  handleStatusReport(item) {
    const filterUrl = `?id=${item.id}&activeTab=7`;
    const pathName = '/gerenciar/projeto/edit';
    history.push({
      pathname: pathName,
      search: filterUrl,
    });
    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  handleResumoProjeto(item) {
    history.push(`/gerenciar/projeto/resumo?id=${item.id}`);
  }

  handleIndicadoresEParametrosProjetos(item) {
    history.push({
      pathname: '/gerenciar/projeto/edit',
      search: `?id=${item.id}&activeTab=9`,
    });
  }

  handleTermoAberturaProjetos(item) {
    history.push({
      pathname: '/gerenciar/projeto/edit',
      search: `?id=${item.id}&activeTab=10`,
    });
  }

  getInternalTabGestaoMudancas(item) {
    if (item.tipo?.id === TIPO_ITENS.PROJETO) return 1;
    else if (item.tipo?.id === TIPO_ITENS.INDICADOR) return 1;
    else if (item.tipo?.id === TIPO_ITENS.PARAMETRO) return 2;
    else if (item.tipo?.id === TIPO_ITENS.OBJETIVO_ESTRATEGICO) return 3;

    return 1;
  }

  handleRedirectGestaoMudancas(item) {
    const internalTab = this.getInternalTabGestaoMudancas(item);
    history.push({
      pathname: '/minhas-tarefas/gestao-mudancas',
      search: `?tab=1&tabInterna=${internalTab}&isNew=true&entityId=${item.id}`,
    });
  }

  handleRedirectMatrizResponsabilidades(item) {
    history.push({
      pathname: '/acompanhar/matriz-responsabilidades',
      search: `?id=${item.id}`,
    });
  }

  handleClickContainer(callbackFn) {
    const item = this.props.item;

    if (this.props.fromCardMetas && item) {
      manager.addItemId(item.id);
    }

    callbackFn();
  }

  handleEditClickProcesso(item) {
    ContentManager.addContent(
      <ProcessoEditView
        item={item}
        currenttabIndex={1}
        showDelete={true}
        handleClose={this.props.handleClose}
      />
    );
  }

  render() {
    let {
      resources,
      item,
      exibirEdicao,
      exibirFicha,
      exibirValor,
      exibirRelacionamento,
      exibirRelatorioAcompanhamento,
      exibirMensagem,
      exportResumo,
      exportGrafico,
      exibirMatrizResultados,
      exibirInteligenciaArtificial,
      handleRelatorioBIClick,
      labelAI,
      tooltipAI,
      exibirPowerBI = true,
      intl,
      exibirPlanoAcao,
      configuracoes,
      ehEditar,
      modules,
      hideSummary = false,
      verificarPermissoes,
    } = this.props;

    const hasEPModule = modules.find(
      (m) => m.id === MODULOS.ESCRITORIO_PROJETOS && m.ativo
    );

    const { utilizarPlanejamentoInicial } = configuracoes || {};

    let utilizarGestaoMudancas = configuracoes
      ? configuracoes.utilizarGestaoMudancas
      : false;

    if (!utilizarGestaoMudancas && configuracoes) {
      if (
        item.tipo?.id === TIPO_ITENS.INDICADOR &&
        configuracoes.utilizarGestaoMudancasIndicador
      ) {
        utilizarGestaoMudancas = configuracoes.utilizarGestaoMudancasIndicador;
      } else if (
        item.tipo?.id === TIPO_ITENS.PARAMETRO &&
        configuracoes.utilizarGestaoMudancasParametro
      ) {
        utilizarGestaoMudancas = configuracoes.utilizarGestaoMudancasParametro;
      } else if (
        item.tipo?.id === TIPO_ITENS.OBJETIVO_ESTRATEGICO &&
        configuracoes.utilizarGestaoMudancasObjetivo
      ) {
        utilizarGestaoMudancas = configuracoes.utilizarGestaoMudancasObjetivo;
      }
    }

    const exibirAnalytics =
      item &&
      item.analytics &&
      item.analytics.uri !== null &&
      item.analytics.uri !== '';

    const tipoId = item && item.tipo && item.tipo.id;
    const naoEhTemplate =
      tipoId !== TIPO_ITENS.TEMPLATE_INDICADOR &&
      tipoId !== TIPO_ITENS.TEMPLATE_PARAMETRO &&
      tipoId !== TIPO_ITENS.TEMPLATE_OBJETIVO_ESTRATEGICO;

    exibirPowerBI = exibirPowerBI
      ? item?.permissions
        ? item?.permissions?.allowViewPowerBI
        : true
      : false;

    let exibirPlanejamentoInicial =
      item && item.permissions && item.permissions.allowViewPlanejamentoInicial;
    let exibirCurvaS = false;
    let exibirStatusReport = false;
    let exibirResumoProjeto = false;
    let exibirIndicadoresParametrosETermoAberturaProjetos = false;
    let exibirGestaoMudancas = false;
    let exibirMatrizResponsabilidades = false;
    let visaoItensImpactados = false;

    if (item && item.permissions && item.permissions.allowBasic) {
      exibirEdicao = false;
      exibirRelacionamento = false;
      exibirPlanoAcao = false;
      exibirMensagem = false;
    } else {
      if (tipoId == 6) {
        exibirMatrizResultados = false;
        exibirRelatorioAcompanhamento = false;
        exibirRelacionamento = true;
        exibirFicha = false;
        exibirValor = false;
      }

      if (tipoId == 5) {
        exibirRelatorioAcompanhamento = false;
        exibirRelacionamento = true;
        exibirFicha = false;
        exibirValor = false;
        exibirPlanoAcao = true;
        visaoItensImpactados = true;
        exibirMensagem = false;
        exibirMatrizResultados = false;
      }
    }

    if (tipoId == 1 || tipoId == 2 || tipoId == 3) {
      exibirGestaoMudancas =
        utilizarGestaoMudancas &&
        (!verificarPermissoes ||
          (item &&
            item.permissions &&
            item.permissions.allowViewGestaoMudancas));
    }

    if (tipoId == 4) {
      const ehProjetoEmAprovacao =
        item.status && (item.status.id == 6 || item.status.id == 7);

      exibirFicha = !ehProjetoEmAprovacao;
      exibirRelacionamento = !ehProjetoEmAprovacao;
      exibirPlanoAcao = !ehProjetoEmAprovacao;
      exibirMensagem = !ehProjetoEmAprovacao;
      exibirMatrizResultados = false;
      exibirCurvaS = this.state.utilizarCurvaS && !ehProjetoEmAprovacao;
      exibirStatusReport = !ehEditar && !ehProjetoEmAprovacao;
      exibirResumoProjeto = !hideSummary && !ehProjetoEmAprovacao;
      exibirIndicadoresParametrosETermoAberturaProjetos =
        hasEPModule && hideSummary;
      exibirMatrizResponsabilidades = hasEPModule;
      exibirGestaoMudancas =
        hasEPModule &&
        utilizarGestaoMudancas &&
        item &&
        item.permissions &&
        item.permissions.allowViewGestaoMudancas;
      exibirValor = this.state.permitirDigitacaoValores;
    }

    if (tipoId == 12 || tipoId == 13 || tipoId == 14) {
      exibirRelacionamento = false;
      exibirMatrizResultados = false;
    }

    if (tipoId == 17) {
      exibirRelacionamento = false;
      exibirMatrizResultados = false;
      exibirPlanoAcao = false;
      exibirFicha = false;
      exibirMensagem = false;
    }

    if (item && item.ocultarMenus) {
      exibirEdicao = false;
      exibirFicha = false;
      exibirValor = false;
      exibirRelacionamento = false;
      exibirPlanoAcao = false;
      exibirRelatorioAcompanhamento = false;
      exibirMensagem = false;
      exportResumo = false;
      exibirMatrizResultados = false;
    }

    if (item && item.exibirItensCardMetas) {
      exibirEdicao = false;
      exibirFicha = false;
      exibirValor = false;
      exibirRelacionamento = false;
      exibirPlanoAcao = false;
      exibirRelatorioAcompanhamento = false;
      exibirMensagem = false;
      exportResumo = false;
      exibirMatrizResultados = true;
    }

    return [
      <>
        {exibirEdicao && (
          <DropdownItem key="1" style={{ minWidth: '18rem' }}>
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  tipoId === TIPO_ITENS.PROCESSO
                    ? this.handleClickContainer(() =>
                        this.handleEditClickProcesso(item)
                      )
                    : this.handleClickContainer(() =>
                        this.handleEditClick(item)
                      )
                }
              >
                <MdiIcon className="mr-2" icon="pencil" />
                <FormattedMessage
                  id="label.editar"
                  values={{ editar: resources.editar }}
                />
              </div>
              <div className="ml-auto">
                <ContextMenuIcon item={item} origemClick={'editar'} />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      exibirFicha && (
        <DropdownItem
          key="2"
          onClick={() =>
            this.handleClickContainer(() => this.handleFichaClick(item))
          }
        >
          <MdiIcon className="mr-2" icon="eye-outline" />
          <FormattedMessage
            id="label.ficha"
            values={{ ficha: resources.ficha }}
          />
        </DropdownItem>
      ),

      <>
        {exibirResumoProjeto && (
          <DropdownItem key="16">
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  this.handleClickContainer(() =>
                    this.handleResumoProjeto(item)
                  )
                }
              >
                <MdiIcon className="mr-2" icon="note-text" />
                <FormattedMessage id="label.resumo" />
              </div>
              <div className="ml-auto">
                <ContextMenuIcon item={item} origemClick="resumo" />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      exibirIndicadoresParametrosETermoAberturaProjetos && (
        <>
          <DropdownItem
            key="17"
            onClick={() =>
              this.handleClickContainer(() =>
                this.handleIndicadoresEParametrosProjetos(item)
              )
            }
          >
            <MdiIcon className="mr-2" icon="poll" />
            <FormattedMessage id="indicadoresEParametros" />
          </DropdownItem>
          <DropdownItem
            key="18"
            onClick={() =>
              this.handleClickContainer(() =>
                this.handleTermoAberturaProjetos(item)
              )
            }
          >
            <MdiIcon className="mr-2" icon="book-open-page-variant" />
            {capitalizeFirstLetter(resources.tap)}
          </DropdownItem>
        </>
      ),

      <>
        {exibirValor && naoEhTemplate && (
          <DropdownItem key="3">
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  this.handleClickContainer(() => this.handleValueClick(item))
                }
              >
                <MdiIcon className="mr-2" icon="format-list-numbered" />
                {capitalizeFirstLetter(resources.valores)}
              </div>
              <div className="ml-auto">
                <ContextMenuIcon
                  item={item}
                  origemClick="valores"
                  obterFiltroUrl={this.obterFiltroUrl}
                />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      <>
        {exibirRelacionamento && (
          <DropdownItem key="4">
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  this.handleClickContainer(() =>
                    this.handleRelacionamentosClick(item)
                  )
                }
              >
                <MdiIcon className="mr-2" icon="sitemap" />
                {capitalizeFirstLetter(resources.relacionamentos)}
              </div>
              <div className="ml-auto">
                <ContextMenuIcon
                  item={item}
                  origemClick="relacionamentos"
                  obterFiltroUrl={this.obterFiltroUrl}
                />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      <>
        {exibirMatrizResultados && (
          <DropdownItem key="5">
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  this.handleClickContainer(() =>
                    this.handleMatrizResultadosClick(item)
                  )
                }
              >
                <MdiIcon className="mr-2" icon="table-edit" />
                {capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'matrizderesultados' },
                    { matrizderesultados: resources.matrizderesultados }
                  )
                )}
              </div>
              <div className="ml-auto">
                <ContextMenuIcon
                  item={item}
                  origemClick="matrizResultados"
                  obterFiltroUrlMatrizResultados={
                    this.obterFiltroUrlMatrizResultados
                  }
                />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      <>
        {exibirPlanoAcao && tipoId !== 2 && naoEhTemplate && (
          <DropdownItem key="6">
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  this.handleClickContainer(() =>
                    this.handlePlanoAcaoClick(item)
                  )
                }
              >
                <MdiIcon className="mr-2" icon="chart-gantt" />
                {capitalizeFirstLetter(resources.planodeacao)}
              </div>
              <div className="ml-auto">
                <ContextMenuIcon
                  item={item}
                  origemClick="planoAcao"
                  obterFiltroUrl={this.obterFiltroUrl}
                />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      <>
        {exibirGestaoMudancas && (
          <DropdownItem key="19">
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  this.handleClickContainer(() =>
                    this.handleRedirectGestaoMudancas(item)
                  )
                }
              >
                <MdiIcon className="mr-2" icon="sync" />
                {intl.formatMessage(
                  { id: 'gestaoMudancas' },
                  { gestaoMudancas: resources.gestaoMudancas }
                )}
              </div>
              <div className="ml-auto">
                <ContextMenuIcon
                  item={item}
                  origemClick="gestaoMudancas"
                  getInternalTabGestaoMudancas={
                    this.getInternalTabGestaoMudancas
                  }
                />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      <>
        {exibirMatrizResponsabilidades && (
          <DropdownItem key="20">
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  this.handleClickContainer(() =>
                    this.handleRedirectMatrizResponsabilidades(item)
                  )
                }
              >
                <MdiIcon className="mr-2" icon="table-account" />
                {intl.formatMessage({ id: 'matrizResponsabilidades' })}
              </div>
              <div className="ml-auto">
                <ContextMenuIcon
                  item={item}
                  origemClick="matrizResponsabilidades"
                />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      exibirCurvaS && (
        <DropdownItem
          key="7"
          onClick={() =>
            this.handleClickContainer(() => this.handleCurvaSClick(item))
          }
        >
          <MdiIcon className="mr-2" icon="chart-line" />
          <FormattedMessage id="curvaS" />
        </DropdownItem>
      ),

      exibirRelatorioAcompanhamento &&
        (tipoId === 1 || tipoId === 3) &&
        naoEhTemplate && (
          <DropdownItem
            key="8"
            onClick={() =>
              this.handleClickContainer(() =>
                this.handleRelatorioAcompanhamentoClick(item)
              )
            }
          >
            <MdiIcon className="mr-2" icon="clipboard-text" />
            {capitalizeFirstLetter(resources.relatoriodeacompanhamento)}
          </DropdownItem>
        ),

      utilizarPlanejamentoInicial && exibirPlanejamentoInicial && (
        <DropdownItem
          key="9"
          onClick={() =>
            this.handleClickContainer(() =>
              this.handlePlanejamentoInicialClick(item)
            )
          }
        >
          <MdiIcon className="mr-2" icon="note-multiple-outline" />
          <FormattedMessage id="planejamentoInicial" />
        </DropdownItem>
      ),

      exibirMensagem && (
        <DropdownItem
          key="10"
          onClick={() =>
            this.handleClickContainer(() => this.handleMensagemClick(item))
          }
        >
          <MdiIcon className="mr-2" icon="comment-text-outline" />
          <FormattedMessage id="label.mensagens" />
        </DropdownItem>
      ),

      exportResumo && naoEhTemplate && (
        <DropdownItem
          key="11"
          onClick={() =>
            this.handleClickContainer(() => this.handleExportClick(item))
          }
        >
          <MdiIcon className="mr-2" icon="file-pdf-box" />
          <FormattedMessage id="label.exportarPDF" />{' '}
          <Info
            id="exportPDF"
            placement="right"
            text={<FormattedMessage id="exportResumo" />}
          />
        </DropdownItem>
      ),

      exportGrafico && naoEhTemplate && (
        <DropdownItem
          key="12"
          onClick={() =>
            this.handleClickContainer(() => this.handleExportGrafico(item))
          }
        >
          <MdiIcon className="mr-2" icon="file-excel" />
          <FormattedMessage id="exportarGrafico" />{' '}
        </DropdownItem>
      ),

      exibirPowerBI && (
        <DropdownItem
          key="13"
          onClick={() =>
            this.handleClickContainer(() =>
              handleRelatorioBIClick
                ? handleRelatorioBIClick(item)
                : this.handleRelatorioBIClick(item)
            )
          }
        >
          <MdiIcon className="mr-2" icon="clipboard" />
          <FormattedMessage id="bi" />
        </DropdownItem>
      ),

      exibirAnalytics && (
        <DropdownItem
          key="14"
          onClick={() =>
            this.handleClickContainer(() => this.handleAnalytics(item))
          }
        >
          <MdiIcon className="mr-2" icon="clipboard" />
          <FormattedMessage id="Analytics" />
        </DropdownItem>
      ),

      <>
        {exibirStatusReport && (
          <DropdownItem key="15">
            <div className="d-flex">
              <div
                style={{ minWidth: '14rem' }}
                onClick={() =>
                  this.handleClickContainer(() => this.handleStatusReport(item))
                }
              >
                <MdiIcon className="mr-2" icon="note-text" />
                {capitalizeFirstLetter(resources.statusreport)}
              </div>
              <div className="ml-auto">
                <ContextMenuIcon item={item} origemClick="statusReport" />
              </div>
            </div>
          </DropdownItem>
        )}
      </>,

      exibirInteligenciaArtificial && naoEhTemplate && (
        <>
          <DropdownItem
            id={`D-${item?.id}-menuAI`}
            key="16"
            onClick={() =>
              this.handleClickContainer(() => this.handleClickSophie(item))
            }
          >
            <img alt="IA logo" src="/ia/ia_cinza.svg" className="pr-2" />
            {labelAI}
          </DropdownItem>
          {tooltipAI && (
            <UncontrolledTooltip
              placement="auto"
              target={`D-${item?.id}-menuAI`}
            >
              {tooltipAI}
            </UncontrolledTooltip>
          )}
        </>
      ),
    ];
  }
}

function mapStateToProps(state) {
  return {
    modules: state.user.licenca.modulos,
  };
}

export default injectIntl(connect(mapStateToProps)(DropdownItemCustom));
