import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Input from 'src/componentes/input';
import StatusLookup from 'src/lookups/status-organizacao';
import TipoLookup from 'src/lookups/tipo-organizacao';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import { capitalizeFirstLetter } from 'src/utils/string';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';

class OrganizacaoSearch extends React.Component {
  render() {
    let { model, resources, intl, configuracao } = this.props;

    return (
      <Row>
        <Col md={3}>
          <Input model={model.getModel('nome')} />
        </Col>
        <Col md={2}>
          <Input model={model.getModel('idIntegracao')} />
        </Col>
        <Col md={3}>
          <ColaboradorSimplificadoLookup
            model={{
              label: capitalizeFirstLetter(resources.responsavel),
              value: model.getModel('responsavel').value,
              requestChange: model.getModel('responsavel').requestChange,
            }}
          />
        </Col>
        <Col md={2}>
          <StatusLookup clearable={true} model={model.getModel('status')} />
        </Col>
        <Col md={2}>
          <TipoLookup model={model.getModel('tipo')} clearable={true} />
        </Col>
        {configuracao.utilizarSubordinacaoMatricial && (
          <Col md={3}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'subordinacaoMatricial' })
                ),
                value: model.getModel('subordinacaoMatricial').value,
                requestChange: model.getModel('subordinacaoMatricial')
                  .requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2] }}
            />
          </Col>
        )}
      </Row>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(OrganizacaoSearch));
