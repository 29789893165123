import React from 'react';
import { ADDONS } from 'src/utils/constants';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import { InputTranslate } from 'src/componentes/Form';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import ItemOrganizacaoLookup from '../../lookups/item-organizacao';
import ColaboradorSimplificadoLookup from '../../lookups/ColaboradorSimplificadoLookup';
import StatusLookup from '../../lookups/status-organizacao';
import Panel from 'src/componentes/panel/index';
import TipoLookup from '../../lookups/tipo-organizacao';
import { Row, Col } from 'reactstrap';
import Georeferencia from '../item/georeferencia';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../utils/string';
import DatePicker from '../../componentes/date-picker';
import FuncoesList from './funcao';
import Permissao from './permissao';
import Info from 'src/componentes/info';
import FuncaoRelacionada from './funcaorelacionada';

let schema = Schema.object({
  id: Schema.string(),
  tipo: Schema.string()
    .label(<FormattedMessage id="tipo" />)
    .required(),
  pai: Schema.string().label(<FormattedMessage id="pai" />),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  responsavel: Schema.array().label(
    <FormattedMessage id="label.responsavel" />
  ),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  status: Schema.string()
    .label(<FormattedMessage id="status" />)
    .required(),
  enderecoGeoreferencia: Schema.string().label(
    <FormattedMessage id="label.enderecoGeoreferencia" />
  ),
  latitudeGeoreferencia: Schema.string().label(
    <FormattedMessage id="latitude" />
  ),
  longitudeGeoreferencia: Schema.string().label(
    <FormattedMessage id="longitude" />
  ),
  sobre: Schema.string().label(<FormattedMessage id="sobre" />),
  missao: Schema.string().label(),
  visao: Schema.string().label(),
  valores: Schema.string().label(<FormattedMessage id="valoresNumericos" />),
  dataInativacao: Schema.string().label(
    <FormattedMessage id="label.dataInativacao" />
  ),
  subordinacaoMatricial: Schema.string().label(
    <FormattedMessage id="subordinacaoMatricial" />
  ),
});

@autobind
class FormEdit extends React.Component {
  handleChangeInputTranslate(field, value) {
    let model = this.props.model;
    model.getModel(field).requestChange(value);
  }

  handleChangeEditTranslate(
    fieldDefault,
    fieldPT,
    fieldEN,
    fieldES,
    fieldIT,
    fieldNL,
    fieldFR,
    fieldDE,
    fieldTR,
    fieldAR,
    localModel
  ) {
    let model = this.props.model;
    model.getModel(fieldDefault).requestChange(localModel[fieldDefault]);
    model.getModel(fieldPT).requestChange(localModel[fieldPT]);
    model.getModel(fieldEN).requestChange(localModel[fieldEN]);
    model.getModel(fieldES).requestChange(localModel[fieldES]);
    model.getModel(fieldIT).requestChange(localModel[fieldIT]);
    model.getModel(fieldNL).requestChange(localModel[fieldNL]);
    model.getModel(fieldFR).requestChange(localModel[fieldFR]);
    model.getModel(fieldDE).requestChange(localModel[fieldDE]);
    model.getModel(fieldTR).requestChange(localModel[fieldTR]);
    model.getModel(fieldAR).requestChange(localModel[fieldAR]);
  }

  render() {
    let { model, intl, configuracao, resources, moduloAtual, item, addons } =
      this.props;

    let isNew = model.getModel('id').value == 0 ? true : false;
    let allowEdit =
      model &&
      model.getModel('permissions') &&
      model.getModel('permissions').value &&
      model.getModel('permissions').value.allowEdit;

    let permissions = model.getModel('permissions').value;

    let id = model.getModel('id') && model.getModel('id').value;

    const addonNegociacaoMetas =
      addons && addons.find((m) => m.id === ADDONS.NEGOCIACAO_METAS)?.ativo;

    return (
      <Tabs defaultActiveKey="1">
        <Tab
          label={intl.formatMessage({ id: 'label.resumo' })}
          key="1"
          id="tableResumo"
        >
          <Row>
            <Col md={4}>
              <TipoLookup
                disabled={!allowEdit}
                model={model.getModel('tipo')}
                required
              />
            </Col>
            <Col md={4}>
              <ItemOrganizacaoLookup
                disabled={!allowEdit}
                model={model.getModel('pai')}
                data={{ tiposIdSelecionaveis: [1, 2] }}
              />
            </Col>
            <Col md={4}>
              <StatusLookup
                disabled={!allowEdit}
                model={model.getModel('status')}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <InputTranslate
                id={model.getModel('id') && model.getModel('id').value}
                disabled={!allowEdit}
                autoFocus
                maxLength={300}
                required={true}
                fieldDefault={'nome'}
                errorsFieldDefault={model.getModel('nome').errors}
                valueDefault={model.getModel('nome').value}
                labelDefault={model.getModel('nome').label}
                fieldPT={'nomePT'}
                valuePT={model.getModel('nomePT').value}
                fieldEN={'nomeEN'}
                valueEN={model.getModel('nomeEN').value}
                fieldES={'nomeES'}
                valueES={model.getModel('nomeES').value}
                fieldIT={'nomeIT'}
                valueIT={model.getModel('nomeIT').value}
                fieldNL={'nomeNL'}
                valueNL={model.getModel('nomeNL').value}
                fieldFR={'nomeFR'}
                valueFR={model.getModel('nomeFR').value}
                fieldDE={'nomeDE'}
                valueDE={model.getModel('nomeDE').value}
                fieldTR={'nomeTR'}
                valueTR={model.getModel('nomeTR').value}
                fieldAR={'nomeAR'}
                valueAR={model.getModel('nomeAR').value}
                handleChangeInputTranslate={this.handleChangeInputTranslate}
                handleChangeEditTranslate={this.handleChangeEditTranslate}
              />
            </Col>
            <Col md={4}>
              <Input
                disabled={!allowEdit}
                model={model.getModel('idIntegracao')}
                required
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={6}>
              <ColaboradorSimplificadoLookup
                disabled={!allowEdit}
                model={{
                  label: capitalizeFirstLetter(resources.responsavel),
                  value: model.getModel('responsavel').value,
                  requestChange: model.getModel('responsavel').requestChange,
                }}
              />
            </Col>
            {model.getModel('status').value &&
              model.getModel('status').value.id == 0 && (
                <Col md={3}>
                  <DatePicker
                    timeFormat={''}
                    disabled
                    model={model.getModel('dataInativacao')}
                  />
                </Col>
              )}
            {configuracao.utilizarSubordinacaoMatricial && (
              <Col md={6}>
                <ItemOrganizacaoLookup
                  multi
                  disabled={!allowEdit}
                  model={{
                    label: (
                      <span>
                        {capitalizeFirstLetter(
                          intl.formatMessage({ id: 'subordinacaoMatricial' })
                        )}
                        <Info
                          id="cadastroValidoCardMetas"
                          className="ml-2"
                          text={intl.formatMessage(
                            {
                              id: 'cadastroValidoCardMetas',
                            },
                            { cardmetas: resources.cardmetas }
                          )}
                        />
                      </span>
                    ),
                    value: model.getModel('subordinacaoMatricial').value,
                    requestChange: model.getModel('subordinacaoMatricial')
                      .requestChange,
                  }}
                  data={{ tiposIdSelecionaveis: [1, 2] }}
                />
              </Col>
            )}

            {/*<Col md={6}>
            <ColaboradorLookup model={model.getModel('aprovadorProjeto')}/>
          </Col>*/}
          </Row>
          {model.getModel('tipo').value &&
            model.getModel('tipo').value.id == 1 && (
              <div>
                <Row>
                  <Col md={6}>
                    <Input
                      disabled={!allowEdit}
                      rows={2}
                      type="textarea"
                      model={model.getModel('sobre')}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      disabled={!allowEdit}
                      rows={2}
                      type="textarea"
                      model={{
                        label: capitalizeFirstLetter(resources.missao),
                        value: model.getModel('missao').value,
                        requestChange: model.getModel('missao').requestChange,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      disabled={!allowEdit}
                      rows={2}
                      type="textarea"
                      model={{
                        label: capitalizeFirstLetter(resources.visao),
                        value: model.getModel('visao').value,
                        requestChange: model.getModel('visao').requestChange,
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      disabled={!allowEdit}
                      rows={2}
                      type="textarea"
                      model={{
                        label: capitalizeFirstLetter(resources.valores),
                        value: model.getModel('valores').value,
                        requestChange: model.getModel('valores').requestChange,
                      }}
                    />
                  </Col>
                </Row>
              </div>
            )}
          {configuracao.vincularFuncaoOrganizacao &&
            !isNew &&
            moduloAtual.id === 3 && (
              <div>
                <Panel header={capitalizeFirstLetter(resources.funcoes)}>
                  <FuncoesList organizacaoId={item && item.id ? item.id : 0} />
                </Panel>
              </div>
            )}
          {configuracao.utilizarGeoreferencia && (
            <Panel header={<FormattedMessage id="label.georeferencia" />}>
              <Georeferencia
                enderecoFixo={true}
                exibir={true}
                endereco={model.getModel('enderecoGeoreferencia')}
                latitude={model.getModel('latitudeGeoreferencia')}
                longitude={model.getModel('longitudeGeoreferencia')}
                isNew={isNew}
              ></Georeferencia>
            </Panel>
          )}
        </Tab>
        {id > 0 && permissions != null && permissions.allowEditPermissions && (
          <Tab label={<FormattedMessage id="label.permissoes" />} key="2">
            <Row>
              <Col md={12}>
                <Permissao
                  parameters={{
                    organizacaoId: id,
                  }}
                  disabled={!allowEdit}
                />
              </Col>
            </Row>
          </Tab>
        )}
        {id > 0 && addonNegociacaoMetas && (
          <Tab
            label={intl.formatMessage(
              { id: 'funcoesRelacionadas' },
              { funcoes: resources.funcoes }
            )}
            key="3"
          >
            <Row>
              <Col md={12}>
                <FuncaoRelacionada
                  model={model.getModel('funcoesRelacionadas')}
                  disabled={!allowEdit}
                />
              </Col>
            </Row>
          </Tab>
        )}
      </Tabs>
    );
  }
}

@autobind
class AreaEdit extends React.Component {
  render() {
    let { resources } = this.props;
    return (
      <Edit
        showExportPDF={false}
        showExportExcel={false}
        url="/Organizacao"
        title={capitalizeFirstLetter(resources.area)}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height={600}
        {...this.props}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
    moduloAtual: state.user.moduloAtual,
    addons: state.user.addonsDisponiveis,
  };
}
export default injectIntl(connect(mapStateToProps)(AreaEdit));
