import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';

@autobind
class Edit extends React.Component {
  static defaultProps = {
    showActionsTop: true,
  };

  render() {
    let { titulo, showActionsTop, renderFooter, ...other } = this.props;

    return (
      <div>
        {this.props.actions && showActionsTop && (
          <Row style={{ marginBottom: 15 }}>
            <Col md={12}>
              {this.props.actions.map((a, index) =>
                React.cloneElement(a, {
                  key: index,
                })
              )}
            </Col>
          </Row>
        )}
        <div {...other}>{this.props.children}</div>
        {this.props.actions && renderFooter && (
          <Row style={{ marginTop: 15 }}>
            <Col md={12}>
              {this.props.actions.map((a, index) =>
                React.cloneElement(a, {
                  key: index,
                })
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default Edit;
