import React from 'react';
import Table from '../../componentes/table';
import SvgIcon from '../../componentes/svg-icon';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class Preview extends React.Component {
  static defaultProps = {
    type: 'vertical',
  };

  handleMinimoMaximo(valor) {
    if (!valor) return '';

    if (valor.id == 1) {
      return this.props.intl.formatMessage({ id: 'minimo' });
    } else if (valor.id == 2) {
      return this.props.resources.meta;
    } else if (valor.id == 3) {
      return this.props.intl.formatMessage({ id: 'maximo' });
    } else {
      return '';
    }
  }

  render() {
    let { model, type, tipoValorFaixa } = this.props;

    return (
      <div>
        {model.value && model.value.length > 0 ? (
          type === 'horizontal' ? (
            model.value &&
            model.value.length > 0 &&
            model.value.map((item, index) => (
              <div
                style={{ display: 'inline-block', marginRight: 20 }}
                key={index}
              >
                <span style={{ marginRight: 7 }}>
                  <span>
                    {item.operadorInferior && item.operadorInferior.simbolo}
                  </span>
                  <span style={{ marginLeft: 7 }}>
                    {tipoValorFaixa && tipoValorFaixa.id == 4
                      ? this.handleMinimoMaximo(item.minimoEMaximoInferior)
                      : item.valorInferior}
                  </span>
                </span>
                <span>
                  <SvgIcon
                    icon={item.farol.icone}
                    color={item.farol.cor}
                    colorHover={item.farol.cor}
                  ></SvgIcon>
                </span>
                <span style={{ marginLeft: 7 }}>
                  <span>
                    {item.operadorSuperior && item.operadorSuperior.simbolo}
                  </span>
                  <span style={{ marginLeft: 7 }}>
                    {tipoValorFaixa && tipoValorFaixa.id == 4
                      ? this.handleMinimoMaximo(item.minimoEMaximoSuperior)
                      : item.valorSuperior > 0 && item.valorSuperior}
                  </span>
                </span>
              </div>
            ))
          ) : (
            <Table>
              <tbody>
                {model.value &&
                  model.value.length > 0 &&
                  model.value.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span>
                          {item.operadorInferior &&
                            item.operadorInferior.simbolo}
                        </span>
                        <span style={{ marginLeft: 7 }}>
                          {tipoValorFaixa && tipoValorFaixa.id == 4
                            ? this.handleMinimoMaximo(
                                item.minimoEMaximoInferior
                              )
                            : item.valorInferior}
                        </span>
                      </td>
                      <td>
                        <span>
                          {item.operadorSuperior &&
                            item.operadorSuperior.simbolo}
                        </span>
                        <span style={{ marginLeft: 7 }}>
                          {tipoValorFaixa && tipoValorFaixa.id == 4
                            ? this.handleMinimoMaximo(
                                item.minimoEMaximoSuperior
                              )
                            : item.valorSuperior > 0 && item.valorSuperior}
                        </span>
                      </td>
                      <td>
                        {item.farol ? (
                          <SvgIcon
                            icon={item.farol.icone}
                            color={item.farol.cor}
                            colorHover={item.farol.cor}
                            style={{ marginRight: 10 }}
                          ></SvgIcon>
                        ) : (
                          <i>
                            <FormattedMessage id="label.semFarol" />
                          </i>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )
        ) : (
          <i>
            <FormattedMessage id="label.nenhumaFaixaConfiguradaParaEstaPolaridade" />
          </i>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(Preview));
