import React from 'react';
import Edit from '../../componentes/edit/edit-dialog';
import Input from '../../componentes/input';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import { Row, Col } from 'reactstrap';
import ItemOrganizacaoLookup from '../../lookups/item-organizacao';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  unidadeArea: Schema.string().label(),
  observacao: Schema.string().label(<FormattedMessage id="observacao" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, resources } = this.props;

    return (
      <div>
        <Row>
          <Col md={8}>
            <Input model={model.getModel('descricao')} required />
          </Col>
          <Col md={4}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ItemOrganizacaoLookup
              model={{
                label: capitalizeFirstLetter(resources.area),
                value: model.getModel('unidadeArea').value,
                requestChange: model.getModel('unidadeArea').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2] }}
            />
          </Col>
          <Col md={12}>
            <Input
              type="textarea"
              rows={3}
              model={{
                label: capitalizeFirstLetter(resources.observacao),
                value: model.getModel('observacao').value,
                requestChange: model.getModel('observacao').requestChange,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class ClassificacaoEdit extends React.Component {
  render() {
    let { resources } = this.props;
    return (
      <Edit
        url="/Classificacao"
        title={capitalizeFirstLetter(resources.classificacao1)}
        formComponent={FormEdit}
        schema={schema}
        width={600}
        height={400}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(ClassificacaoEdit);
