import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import Table from '../../../componentes/table';
import Button from '../../../componentes/button';
import ItemList from '../index';
import ContentManager from '../../../componentes/content-manager';
import IconButton from '../../../componentes/icon-button';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';
import Info from '../../../componentes/info';
import DropDownItem from 'src/paginas/item/dropdown';
import Confirm from 'src/componentes/message-box/confirm';
import Dialog from 'src/componentes/dialog';
import Tab from 'src/componentes/tabs/tab';
import Tabs from 'src/componentes/tabs/tabs';
import ObjetivoList from 'src/paginas/okr/Objetivo/ListView';

@autobind
class RelacionamentoList extends React.Component {
  constructor(props) {
    super(props);
    this.dialogRef = React.createRef(null);
  }

  handleClose() {
    if (this.dialogRef?.current) {
      this.dialogRef.current.close();
    }
  }

  handleNewClick(intl, resources, showOkrs) {
    const actions = [
      <Button
        className="ml-2"
        type="primary"
        onClick={this.handleClose.bind(this)}
        htmlType="submit"
      >
        {intl.formatMessage({ id: 'label.fechar' })}
      </Button>,
    ];

    ContentManager.addContent(
      <Dialog
        width="70%"
        maxHeight="80%"
        actions={actions}
        padContent
        onRequestClose={this.handleClose.bind(this)}
        ref={this.dialogRef}
      >
        <Tabs>
          <Tab
            label={capitalizeFirstLetter(
              intl.formatMessage({ id: 'label.itens' })
            )}
          >
            <ItemList
              multiple
              select
              selectRealTime
              selectedCallback={this.handleSelectItens}
              selectedIds={this.props.model.value}
              selectedItems={this.props.model.value}
              parameters={this.props.parameters}
            />
          </Tab>
          {showOkrs && (
            <Tab
              label={capitalizeFirstLetter(intl.formatMessage({ id: 'OKRS' }))}
            >
              <ObjetivoList
                select
                selectRealTime
                selectedCallback={this.handleSelectOkrs}
                selectedIds={this.props.modelOkrs.value}
                selectedItems={this.props.modelOkrs.value}
                showSearch={true}
                showNew={false}
                hideColumnsButton={true}
                parameters={this.props.parameters}
              />
            </Tab>
          )}
        </Tabs>
      </Dialog>
    );
  }

  async validateItens(itens) {}

  async handleSelectItens(itens) {
    let isValidated = true;

    if (this.props.validateResults != null)
      isValidated = await this.props.validateResults(itens);

    if (isValidated) {
      this.props.model.requestChange([...itens]);
    }
    if (this.props.getAlteracao != null) this.props.getAlteracao();
  }

  async handleSelectOkrs(okrs) {
    this.props.modelOkrs.requestChange([...okrs]);
  }

  handleRemove(item) {
    ContentManager.addContent(
      <Confirm
        message={<FormattedMessage id="label.desejaMesmoExcluirEsseRegistro" />}
        handleConfirm={this.handleDeleteClickConfirm.bind(this, item)}
      />
    );
  }

  handleDeleteClickConfirm(item) {
    if (item.tipoNome === 'OKR') {
      this.props.modelOkrs.requestChange(
        this.props.modelOkrs.value.filter((d) => d.id !== item.id)
      );
    } else {
      this.props.model.requestChange(
        this.props.model.value.filter((d) => d.id !== item.id)
      );

      if (this.props.getAlteracao != null) this.props.getAlteracao();
    }
  }

  renderTags(tags) {
    return tags?.map((x) => (
      <Badge pill className="text-white" style={{ background: x.cor }}>
        {x.descricao}
      </Badge>
    ));
  }

  render() {
    let {
      model,
      modelOkrs,
      allowNew,
      disabled,
      resources,
      info,
      showFarol = true,
      intl,
      showOkrs = false,
      showTags = false,
      showTipoItem = true,
      showPeriodiciade = true,
      showResponsaveis = true,
    } = this.props;

    let impactosIndiretos = [
      ...(model?.value || []),
      ...(modelOkrs?.value || []),
    ];

    return (
      <div>
        <Row>
          <Col md={6}>
            {info
              ? model.label && (
                  <label className="control-label">
                    {model.label}
                    <Info id={info.id} placement="right" text={info.text} />
                  </label>
                )
              : model.label && (
                  <label className="control-label">{model.label}</label>
                )}
          </Col>
          <Col md={6}>
            {allowNew && (
              <Button
                className="pull-right"
                leftIcon="magnify"
                type="primary"
                style={{ marginBottom: 10 }}
                onClick={this.handleNewClick.bind(
                  this,
                  intl,
                  resources,
                  showOkrs
                )}
              >
                <FormattedMessage id="label.buscar" />
              </Button>
            )}
          </Col>
        </Row>
        {impactosIndiretos && impactosIndiretos.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="nome" />
                </th>
                {showTipoItem && (
                  <th>
                    <FormattedMessage id="label.tipoDoItem" />
                  </th>
                )}
                {showPeriodiciade && (
                  <th>
                    <FormattedMessage id="periodicidade" />
                  </th>
                )}
                {showResponsaveis && (
                  <th>
                    <FormattedMessage id="responsaveis" />
                  </th>
                )}
                <th>{capitalizeFirstLetter(resources.area)}</th>
                {showTags && <th>{capitalizeFirstLetter(resources.tags)}</th>}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {impactosIndiretos &&
                impactosIndiretos
                  .filter((i) => !i.excluido)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.tipoNome === 'OKR' ? (
                          item.descricao
                        ) : (
                          <DropDownItem item={item} />
                        )}
                      </td>
                      {showTipoItem && (
                        <td>
                          {capitalizeFirstLetter(
                            item.tipoNome
                              ? item.tipoNome
                              : item.tipo.descricao.replace(
                                  /{indicador}|{parametro}|{objetivoestrategico}/gi,
                                  function (matched) {
                                    switch (matched) {
                                      case '{indicador}':
                                        return resources.indicador;
                                      case '{parametro}':
                                        return resources.parametro;
                                      case '{objetivoestrategico}':
                                        return resources.objetivoestrategico;
                                    }
                                  }
                                )
                          )}
                        </td>
                      )}
                      {showPeriodiciade && (
                        <td>
                          {item.tipoNome === 'OKR'
                            ? ''
                            : item.periodicidade &&
                              item.periodicidade.descricao}
                        </td>
                      )}
                      {showResponsaveis && (
                        <td>
                          {item.tipoNome === 'OKR'
                            ? item.responsavel.nome
                            : item.responsaveis}
                        </td>
                      )}
                      <td>{item.area}</td>
                      {showTags && <td>{this.renderTags(item.tags)}</td>}
                      <td style={{ width: 40 }}>
                        {!disabled && (
                          <IconButton
                            icon="close-circle"
                            className="clarear"
                            onClick={this.handleRemove.bind(this, item)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        ) : (
          <i>
            <FormattedMessage id="label.naoHaItensRelacionados" />
          </i>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(RelacionamentoList));
