import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import CondicionanteEdit from './edit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class Condicionante extends React.Component {
  render() {
    let { intl } = this.props;

    return (
      <List url="/Condicionante" editComponent={CondicionanteEdit} sort="Descricao" showSearch={true} {...this.props} tag={11002}>
        <ListColumn headerText={intl.formatMessage({ id: 'idIntegracao' })} valueField="idIntegracao" default />
        <ListColumn headerText={intl.formatMessage({ id: 'descricao' })} required valueField="descricao" sortField="descricao" default />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Condicionante));
