import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Dialog from '../../componentes/dialog';
import Button from '../../componentes/button';
import TipoGrafico from '../../lookups/tipo-grafico';
import Checkbox from '../../componentes/checkbox';
import MdiIcon from '../../componentes/mdi-icon';
import GraficoEstatistica from './estatistica-modal';
import ExportarRelatorioModal from '../relatorio-periodico/exportar-relatorio-modal';
import ContentManager from '../../componentes/content-manager';

class SearchEstatistica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtroDefault: {
        exibirRotulo: true,
        exibirTabela: true,
        tipoGrafico: 10,
        type: 'Pie2D',
      },
    };
  }

  async toggleRotulo() {
    await this.setState({
      filtroDefault: {
        exibirRotulo: !this.state.filtroDefault.exibirRotulo,
        exibirTabela: this.state.filtroDefault.exibirTabela,
        tipoGrafico: this.state.filtroDefault.tipoGrafico,
        type: this.state.filtroDefault.type,
      },
    });
  }

  async toggleTabela() {
    await this.setState({
      filtroDefault: {
        exibirRotulo: this.state.filtroDefault.exibirRotulo,
        exibirTabela: !this.state.filtroDefault.exibirTabela,
        tipoGrafico: this.state.filtroDefault.tipoGrafico,
        type: this.state.filtroDefault.type,
      },
    });
  }

  async handleChangeTipo(field, value) {
    let graficoId = this.state.filtroDefault.tipoGrafico.id;

    if (value) {
      await this.setState({
        filtroDefault: {
          type:
            value.id == 2
              ? 'msline'
              : value.id == 11
              ? 'StackedColumn2d'
              : 'Pie2D',
          exibirRotulo: this.state.filtroDefault.exibirRotulo,
          exibirTabela: this.state.filtroDefault.exibirTabela,
          [field]: value,
        },
      });
    } else {
      await this.setState({
        filtroDefault: {
          type:
            graficoId == 2
              ? 'msline'
              : graficoId == 11
              ? 'StackedColumn2d'
              : 'Pie2D',
          exibirRotulo: this.state.filtroDefault.exibirRotulo,
          exibirTabela: this.state.filtroDefault.exibirTabela,
          [field]: value,
        },
      });
    }
  }

  handleExport() {
    ContentManager.addContent(<ExportarRelatorioModal />);
  }

  render() {
    let { filtroDefault } = this.state;
    let { exibirRotulo, tipoGrafico, exibirTabela } = filtroDefault;
    let { intl } = this.props;

    return (
      <Dialog
        onRequestClose={this.handleClose}
        mouseDownClose={false}
        ref="dialog"
        {...this.props}
      >
        <Col style={{ minHeight: 280, minWidth: 600 }}>
          <Row>
            <Col md={4}>
              <TipoGrafico
                clearable={false}
                style={{ marginLeft: 15 }}
                model={{
                  label: intl.formatMessage({ id: 'label.tipoGrafico' }),
                  value: tipoGrafico,
                  requestChange: this.handleChangeTipo.bind(
                    this,
                    'tipoGrafico'
                  ),
                }}
                data={{ tiposIdSelecionaveis: [2, 11, 10] }}
              />
            </Col>
            <Col md={4}>
              <Checkbox
                style={{ marginTop: 30 }}
                model={{
                  label: intl.formatMessage({ id: 'exibirRotulo' }),
                  value: exibirRotulo,
                  requestChange: this.toggleRotulo.bind(this, exibirRotulo),
                }}
              />
            </Col>
            <Col md={4}>
              <Checkbox
                style={{ marginTop: 30 }}
                model={{
                  label: intl.formatMessage({ id: 'label.exibirTabela' }),
                  value: exibirTabela,
                  requestChange: this.toggleTabela.bind(this, exibirTabela),
                }}
              />
            </Col>
          </Row>
          <GraficoEstatistica
            model={this.props.searchModel}
            changeTipo={this.handleChangeTipo}
            filterModel={filtroDefault}
          />
        </Col>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(SearchEstatistica));
