import React from 'react';
import { Col, Row } from 'reactstrap';
import Input from '../../../componentes/input';
import StatusLookup from '../../../lookups/status-centro-custo';
import ColaboradorSimplificadoLookup from '../../../lookups/ColaboradorSimplificadoLookup';
import { capitalizeFirstLetter } from '../../../utils/string';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ClassificacaoLookup from '../../../lookups/classificacao-mo';

class OrganizacaoSearch extends React.Component {
  render() {
    let { model, resources, exibirClassificacao } = this.props;

    return (
      <Row>
        <Col md={3}>
          <Input model={model.getModel('nome')} />
        </Col>
        <Col md={2}>
          <Input model={model.getModel('idIntegracao')} />
        </Col>
        {exibirClassificacao && (
          <Col md={3}>
            <ClassificacaoLookup clearable model={model.getModel('classificacao')} />
          </Col>
        )}
        <Col md={3}>
          <ColaboradorSimplificadoLookup
            model={{
              label: capitalizeFirstLetter(resources.responsavel),
              value: model.getModel('responsavel').value,
              requestChange: model.getModel('responsavel').requestChange,
            }}
          />
        </Col>
        <Col md={2}>
          <StatusLookup model={model.getModel('status')} clearable={true} />
        </Col>
      </Row>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(OrganizacaoSearch));
