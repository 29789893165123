import { Col, Row } from 'reactstrap';
import Input from 'src/componentes/input';
import { capitalizeFirstLetter } from 'src/utils/string';
import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';
import Checkbox from 'src/componentes/checkbox';

import PeriodoData from 'src/paginas/data/periodo-data.jsx';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import StatusAuditoriaRiscoLookup from 'src/lookups/status-auditoria-risco';
import AuditoriaRiscoLookup from 'src/lookups/auditoriaRisco';
import ModeloChecklistLookup from 'src/lookups/ModeloChecklistLookup';
import StatusAcaoLookup from 'src/lookups/status-acao';

const ModeloEscalaSearch = ({ model }) => {

  const { terms: resources } = useAuth();
  const intl = useIntl();

  const handleChangeFiltro = (field, value) => {
    model.getModel(field).requestChange(value);
  };

  return(
    <>
    <Row>
      <Col md={4}>
        <Input model={model.getModel('nome')} />
      </Col>
      <Col md={4}>
        <Input model={model.getModel('idIntegracao')} />
      </Col>
      <Col md={4}>
        <Input model={model.getModel('descricao')} />
      </Col>      
    </Row>
    <Row>
      <Col md={4}>
        <ColaboradorSimplificadoLookup
          multi
          clearable
          model={{
            label: capitalizeFirstLetter(resources.responsaveis),
            value: model.getModel('responsaveisSimplificado').value,
            requestChange: model.getModel('responsaveisSimplificado').requestChange,
            errors: model.getModel('responsaveisSimplificado').errors,
          }}
        />
      </Col>
      <Col md={4}>
        <ItemOrganizacaoLookup
          multi={true}
          model={{
            label: capitalizeFirstLetter(resources.areas),
            value: model.getModel('areas').value,
            requestChange: model.getModel('areas').requestChange,
          }}
          data={{ tiposIdSelecionaveis: [1, 2, 3] }}
        />
      </Col>
      <Col md={4}>
        <Checkbox
          model={{
            label: intl.formatMessage(
              { id: 'label.considerarAreasSubordinadas' },
              { areas: resources.areas }
            ),
            value: model.getModel('areasSubordinadas').value,
            requestChange: model.getModel('areasSubordinadas').requestChange,
          }}
          style={{ marginTop: 27 }}
        />
      </Col>
    </Row>  
    <Row>
      <Col md={4}>
        <AuditoriaRiscoLookup
          multi={true}
          clearable
          model={{
            label: capitalizeFirstLetter(intl.formatMessage({ id: 'auditorias' })),
            value: model.getModel('auditorias').value,
            requestChange: model.getModel('auditorias').requestChange,
          }}
        />
      </Col>  
      <Col md={4}>
        <ModeloChecklistLookup
          multi={true}
          clearable
          model={{
            label: intl.formatMessage({ id: 'labelChecklist' }),
            value: model.getModel('checklists').value,
            requestChange: model.getModel('checklists').requestChange,
          }}
        />   
      </Col>   
      <Col md={4}>
        <StatusAuditoriaRiscoLookup
          multi={true}
          clearable
          model={{
            label: capitalizeFirstLetter(intl.formatMessage({ id: 'status' })),
            value: model.getModel('statusAuditoriaList').value,
            requestChange: model.getModel('statusAuditoriaList').requestChange,
          }}
        />
      </Col> 
    </Row>
    <Row>
      <Col md={12}>
        <PeriodoData
          clearable
          data={{ idsIgnorados: [7,8,9,10] }}
          tipoFiltroData={model.getModel('tipoFiltroDataPeriodoAuditoriaRisco').value}
          dataInicio={model.getModel('deDataInicial').value}
          dataTermino={model.getModel('ateDataFinal').value}
          tipoFiltroDataField={'tipoFiltroDataPeriodoAuditoriaRisco'}
          dataInicioField={'deDataInicial'}
          dataTerminoField={'ateDataFinal'}
          onChange={handleChangeFiltro}
          customLabelTipoPeriodo=
            {capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'tipoFiltroDataPeriodoAuditoriaRisco' }
              )
            )}
          customLabelDataInicio=
            {capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'deDataInicial' }
              )
            )}
          customLabelDataTermino=
            {capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'ateDataFinal' }
              )
            )}
        />
      </Col>      
    </Row> 
    <Row>
      <Col md={4}>
        <StatusAcaoLookup
          multi={true}
          clearable
          model={{
            label: intl.formatMessage(
              { id: 'statusAcao' },
              { acao: resources.acao }
            ),
            value: model.getModel('statusAcaoList').value,
            requestChange: model.getModel('statusAcaoList').requestChange,
          }}
        />   
      </Col>    
    </Row>
    </>
  );
};

export default ModeloEscalaSearch;
