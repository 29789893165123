import ReactTooltip from 'react-tooltip';

import MdiIcon from '../mdi-icon';

const Info = ({
  id,
  placement,
  text,
  className,
  style,
  renderContent,
  tooltipMaxWidth,
  tooltipTextAlign,
  size = 16,
}) => {
  const validPositions = ['top', 'bottom', 'left', 'right'];
  const position = validPositions.includes(placement) ? placement : 'top';

  return (
    <span style={style} className={className}>
      <MdiIcon
        color="#ccc"
        colorHover="#ccc"
        icon="information-outline"
        size={size}
        id={id}
        data-tip
        data-for={id}
      />
      <ReactTooltip id={id} place={position} aria-haspopup="true">
        <div
          style={{
            maxWidth: tooltipMaxWidth != null ? tooltipMaxWidth : 200,
            textAlign: tooltipTextAlign != null ? tooltipTextAlign : 'center',
          }}
        >
          {renderContent ? renderContent() : text}
        </div>
      </ReactTooltip>
    </span>
  );
};

export default Info;
