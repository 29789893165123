import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Input from 'src/componentes/input';
import TipoPontoControleLookup from 'src/lookups/tipo-ponto-controle';
import { capitalizeFirstLetter } from 'src/utils/string';
import ResponsaveisLookup from 'src/lookups/colaborador';
import PeriodicidadeLookup from 'src/lookups/tipo-periodicidade-ponto-controle';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import InputNumber from 'src/componentes/input-number';
import TipoToleranciaLookup from 'src/lookups/tipo-tolerancia';
import TipoEvidenciaLookup from 'src/lookups/tipo-evidencia';
import FuncaoPontoControlelookup from 'src/lookups/funcao-pontocontrole';
import TipoDeControleLookup from 'src/lookups/tipo-de-controle';
import AreaLookup from 'src/lookups/item-organizacao';
import StatusControlSelfAssessmentLookup from 'src/lookups/StatusControlSelfAssessmentLookup';

class PontoControleSearch extends React.Component {
  render() {
    let {
      model,
      resources,
      intl,
      configuracao: { funcaoPontoControle, tipoDeControle, utilizarTipoPontoControle, utilizarTipoEvidencia },
    } = this.props;

    return (
      <div>
        <Row>
          <Col md={2}>
            <Input model={model.getModel('idIntegracao')} />
          </Col>
          <Col md={3}>
            <Input model={model.getModel('descricao')} />
          </Col>
          {utilizarTipoPontoControle && (
            <Col md={3}>
              <TipoPontoControleLookup
                clearable
                model={{
                  label: capitalizeFirstLetter(intl.formatMessage({ id: 'tipoPontoControle' }, { pontodecontrole: resources.pontodecontrole })),
                  value: model.getModel('tipoPontoControle').value,
                  requestChange: model.getModel('tipoPontoControle').requestChange,
                }}
              />
            </Col>
          )}
          <Col md={4}>
            <ResponsaveisLookup
              multi
              model={{
                label: capitalizeFirstLetter(resources.responsaveis),
                value: model.getModel('responsaveis').value,
                requestChange: model.getModel('responsaveis').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <PeriodicidadeLookup clearable model={model.getModel('periodicidade')} />
          </Col>
          <Col md={3}>
            <TipoToleranciaLookup model={model.getModel('tipoTolerancia')} />
          </Col>
          <Col md={3}>
            <InputNumber model={model.getModel('diasToleranciaRevisao')} />
          </Col>
          <Col md={3}>
            <InputNumber model={model.getModel('numeroRevisoesGeradasAutomaticamente')} />
          </Col>
        </Row>
        <Row>
          {utilizarTipoEvidencia && (
            <Col md={5}>
              <TipoEvidenciaLookup multi={true} model={model.getModel('tipoEvidencias')} />
            </Col>
          )}
          <Col md={4}>
            <StatusControlSelfAssessmentLookup multi model={model.getModel('statusControlSelfAssessment')} />
          </Col>
        </Row>
        <Row>
          {funcaoPontoControle && (
            <Col md={4}>
              <FuncaoPontoControlelookup
                model={{
                  label: intl.formatMessage({ id: 'funcaoPontoControle' }, { pontodecontrole: resources.pontodecontrole }),
                  value: model.getModel('funcaoPontoControle').value,
                  requestChange: model.getModel('funcaoPontoControle').requestChange,
                }}
                clearable
              />
            </Col>
          )}
          {tipoDeControle && (
            <Col md={4}>
              <TipoDeControleLookup
                model={{
                  label: intl.formatMessage({ id: 'tipoDeControle' }, { pontodecontrole: resources.pontodecontrole }),
                  value: model.getModel('tipoDeControle').value,
                  requestChange: model.getModel('tipoDeControle').requestChange,
                }}
                clearable
              />
            </Col>
          )}
          <Col md={4}>
            <AreaLookup
              clearable
              model={{
                label: capitalizeFirstLetter(intl.formatMessage({ id: 'area' }, { area: resources.area })),
                value: model.getModel('area').value,
                requestChange: model.getModel('area').requestChange,
              }}
            />
          </Col>
        </Row>
        {model.getModel('camposCustomizados').value && model.getModel('camposCustomizados').value.length > 0 && (
          <Row>
            <Col md={12}>
              <DesenharCampoCustomizado model={model.getModel('camposCustomizados')} />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PontoControleSearch));
