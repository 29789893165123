import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required({
      onlyIf: (context) => {
        return !context.parent.value.utilizarCodigoAutomatico;
      },
    }),
  area: Schema.string()
    .label(<FormattedMessage id="area" />)
    .required(),
  status: Schema.string()
    .label(<FormattedMessage id="status" />)
    .required(),
  dataInicioItem: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTerminoItem: Schema.string().label(<FormattedMessage id="dataTermino" />),
  matrizRisco: Schema.string()
    .label(<FormattedMessage id="matrizRisco" />)
    .required(),
  tipoRisco: Schema.string(),
  modeloRisco: Schema.string().label(<FormattedMessage id="modelo" />),
  classificacaoRisco: Schema.string().label(
    <FormattedMessage id="label.classificacao" />
  ),
  responsaveis: Schema.array()
    .label(<FormattedMessage id="responsaveis" />)
    .required(),
  periodicidadeAvaliacao: Schema.string().label(
    <FormattedMessage id="label.periodicidadeAvaliacao" />
  ),
  responsavelAvaliacao: Schema.string().label(
    <FormattedMessage id="label.responsavelAvaliacao" />
  ),
  causaRisco: Schema.string().label(<FormattedMessage id="causaDoRisco" />),
  consequenciaRisco: Schema.string().label(
    <FormattedMessage id="consequenciaDoRisco" />
  ),
  causasRisco: Schema.string(),
  toleranciaRisco: Schema.string(),
  consequenciasRisco: Schema.string(),
  tipoTolerancia: Schema.string().label(
    <FormattedMessage id="tipoTolerancia" />
  ),
  diasToleranciaRevisao: Schema.string().label(
    <FormattedMessage id="tolerancia" />
  ),
  numeroRevisoesGeradasAutomaticamente: Schema.string().label(
    <FormattedMessage id="numeroRevisoesGeradasAutomaticamente" />
  ),

  // configurações gerais
  configuracaoGeral: Schema.array().label(
    <FormattedMessage id="configuracoesGerais" />
  ),
  itensAssociados: Schema.string(),
  revisaoUnica: Schema.string().label(
    <FormattedMessage id="label.revisaoUnica" />
  ),
  utilizarValorPowerBI: Schema.string().label(
    <FormattedMessage id="utilizarValorPowerBI" />
  ),

  //campos customizados
  camposCustomizadosConfig: Schema.array(),
  camposCustomizados: Schema.array(),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  fluxoAprovacao: Schema.string()
    .label(<FormattedMessage id="fluxoAprovacao" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.id <= 0 && context.parent.value.configuracaoRisco.usarFluxoAprovacaoBelt && context.parent.value.configuracaoRisco.fluxoAprovacaoObrigatorioRisco;
      },
    }),
  forceRefresh: Schema.string(),
});
