import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import FuncaoEdit from '../paginas/remuneracao-variavel/funcoes/edit';
import FuncaoList from '../paginas/remuneracao-variavel/funcoes/view';

@autobind
class FuncaoLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueKey="id"
        labelKey="nome"
        showSearch
        clearable={true}
        url="/FuncaoLookup"
        editComponent={FuncaoEdit}
        searchComponent={FuncaoList}
        {...this.props}
      />
    );
  }
}

export default FuncaoLookup;
