import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import ItemList from '../paginas/item';

@autobind
class ItemLookup extends React.Component {
  render() {
    return (
      <Lookup
        //valueComponent={this.renderComponent}
        valueKey="id"
        labelKey="nome"
        showSearch
        showNew={false}
        url="/ItemLookup"
        clearable={true}
        searchComponent={ItemList}
        {...this.props}
      />
    );
  }
}

export default ItemLookup;
