import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Edit from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import { capitalizeFirstLetter } from 'src/utils/string';
import CausaRiscoLookup from 'src/lookups/causa-risco';

let schema = Schema.object({
  causa: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, disabled, riscoId } = this.props;

    return (
      <div>
        <br />
        <Row>
          <Col md={12}>
            <Col md={8}>
              <CausaRiscoLookup
                disabled={disabled}
                model={model.getModel('causa')}
                data={{ riscoId: riscoId }}
              />
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

class CausaEventoRiscoEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;
    return (
      <Edit
        {...this.props}
        parameters={{ eventoRiscoId: this.props.eventoRiscoId }}
        title={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'labelCausasDoRisco' },
            {
              pontosproblematicos: resources.pontosproblematicos,
              risco: resources.risco,
            }
          )
        )}
        formComponent={FormEdit}
        afterSave={this.props.afterSave && this.props.afterSave}
        schema={schema}
        showActions={true}
        width={600}
        minHeight={240}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CausaEventoRiscoEdit));
