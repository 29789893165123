import React, { useState } from 'react';
import MdiIcon from './../../mdi-icon';
import css from './../../../utils/css';
import { Popover, PopoverBody, DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import {
  DownloadArquivoSistema,
  DownloadFileBucket,
} from 'src/services/downloadFileUrl';

const baseClasses = css`
  .paper {
    float: left;
    height: 80px;
    width: 150px;
    padding: 5px;
    margin: 15px 15px 15px 0;

    text-align: center;
    display: inline-block;
    line-height: 1.2;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 12px;
    border-radius: 2px;
    border: 1px dotted #ccc;
  }
`;

const parseNome = (arquivoNome) => {
  // Nome Exibicao do Arquivo
  let nomeExibicao = arquivoNome;
  if (arquivoNome && arquivoNome.length > 17) {
    nomeExibicao = arquivoNome.substring(0, 14).concat('...');
    // Pega a extensao
    var extensao = arquivoNome.split('.');
    nomeExibicao = nomeExibicao + '.' + extensao[extensao.length - 1];
  }
  return nomeExibicao;
};

export const DropzoneArquivoExibicao = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const onDownload = () => {
    toggle();

    if (props.fileBucket) DownloadFileBucket(props.arquivoNome);
    else DownloadArquivoSistema(props.arquivoGuid);
  };

  const onDelete = () => {
    toggle();
    props.onDeleteFile();
  };

  if (props.arquivoNome) {
    return (
      <div>
        <div
          id={`anexo-${props.arquivoGuid}`}
          onClick={toggle}
          className={baseClasses.paper}
          icone="file-excel"
        >
          <div style={{ marginBottom: 5 }}>
            <MdiIcon icon="file-outline" size={32} color="#555" />
          </div>
          {parseNome(props.arquivoNome)}
        </div>
        {props.arquivoGuid && (
          <Popover
            placement="auto"
            target={`anexo-${props.arquivoGuid}`}
            isOpen={popoverOpen}
            toggle={toggle}
          >
            <PopoverBody>
              <DropdownItem onClick={onDownload}>
                <MdiIcon style={{ marginRight: 5 }} icon="pencil" />
                {<FormattedMessage id="label.download" />}
              </DropdownItem>
              {props.onDeleteFile && !props.disabled && !props.onlyRead && (
                <DropdownItem onClick={onDelete}>
                  <MdiIcon style={{ marginRight: 5 }} icon="delete" />
                  {<FormattedMessage id="excluir" />}
                </DropdownItem>
              )}
            </PopoverBody>
          </Popover>
        )}
      </div>
    );
  } else return null;
};
