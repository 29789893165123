import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import TabelaEdit from '../paginas/tabela-valor/edit';

@autobind
class TabelaValor extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/TabelaValorLookup" editComponent={TabelaEdit} {...this.props} />;
  }
}

export default TabelaValor;
