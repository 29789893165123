import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import DatePicker from 'src/componentes/date-picker';
import Alert from 'src/componentes/message-box/alert';
import ContentManager from 'src/componentes/content-manager';

@autobind
class PeriodoData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeCalendarDataInicio: false,
    };
  }

  handleChangeDataInicio(value) {
    let dataTermino = this.props.dataTermino.value;
    const dataAntiga = this.props.dataInicio.value;

    if (moment(value) > moment(dataTermino)) {
      ContentManager.addContent(
        <Alert
          message={
            <FormattedMessage id="label.dataInicioDeveSerMenorDataTermino" />
          }
        />
      );
    } else {
      this.props.dataInicio.requestChange(value);

      if (this.props.getAlteracao != null)
        this.props.getAlteracao('dataInicioItem', dataAntiga);
    }
  }

  handleChangeDataTermino(value) {
    let dataInicio = this.props.dataInicio.value;
    const dataAntiga = this.props.dataTermino.value;

    if (moment(value) < moment(dataInicio)) {
      ContentManager.addContent(
        <Alert
          message={
            <FormattedMessage id="label.dataTerminoDeveSerMaiorDataInicio" />
          }
        />
      );
      this.props.dataTermino.requestChange(dataInicio);
      if (this.props.getAlteracao != null)
        this.props.getAlteracao('dataTerminoItem', dataAntiga);
    } else {
      this.props.dataTermino.requestChange(value);

      if (this.props.getAlteracao != null)
        this.props.getAlteracao('dataTerminoItem', dataAntiga);
    }

    if (dataInicio == null) this.props.dataInicio.requestChange(value);
  }

  handleFocusInput = (close) => {
    this.setState({ closeCalendarDataInicio: close });
  };

  render() {
    let {
      dataInicio,
      dataTermino,
      disabled,
      required,
      mesAno,
      height,
      requiredTermino = false,
    } = this.props;

    return (
      <Row>
        <Col md={6} onFocus={() => this.handleFocusInput(false)}>
          {mesAno == false ? (
            <DatePicker
              closeCalendarDataInicio={this.state.closeCalendarDataInicio}
              disabled={disabled}
              required={required}
              timeFormat={false}
              autoFormatDate={this.props.autoFormatDate}
              height={height}
              model={{
                label: <FormattedMessage id="dataInicio" />,
                value: dataInicio.value,
                requestChange: this.handleChangeDataInicio,
                errors: dataInicio.errors,
              }}
            ></DatePicker>
          ) : (
            <DatePicker
              closeCalendarDataInicio={this.state.closeCalendarDataInicio}
              disabled={disabled}
              required={required}
              autoFormatDate={this.props.autoFormatDate}
              month
              height={height}
              model={{
                label: <FormattedMessage id="dataInicio" />,
                value: dataInicio.value,
                requestChange: this.handleChangeDataInicio,
                errors: dataInicio.errors,
              }}
            ></DatePicker>
          )}
        </Col>
        <Col md={6} onFocus={() => this.handleFocusInput(true)}>
          {mesAno == false ? (
            <DatePicker
              required={requiredTermino}
              disabled={disabled}
              timeFormat={false}
              autoFormatDate={this.props.autoFormatDate}
              height={height}
              model={{
                label: <FormattedMessage id="dataTermino" />,
                value: dataTermino.value,
                requestChange: this.handleChangeDataTermino,
                errors: dataTermino.errors,
              }}
            ></DatePicker>
          ) : (
            <DatePicker
              required={requiredTermino}
              disabled={disabled}
              autoFormatDate={this.props.autoFormatDate}
              month
              height={height}
              model={{
                label: <FormattedMessage id="dataTermino" />,
                value: dataTermino.value,
                requestChange: this.handleChangeDataTermino,
                errors: dataTermino.errors,
              }}
            ></DatePicker>
          )}
        </Col>
      </Row>
    );
  }
}

export default PeriodoData;
