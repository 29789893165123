import React, { useCallback, useEffect, useState } from 'react';
import CriteriosAvaliacaoLookup from 'src/lookups/CriteriosAvaliacaoLookup';
import http from 'src/services/httpService';
import Panel from 'src/componentes/panel-collapse';
import { useIntl } from 'react-intl';
import Button from 'src/componentes/button';
import LoadingContainer from 'src/componentes/loading-container';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import MemoriaCalculoValor from './MemoriaCalculo';
import ContentManager from 'src/componentes/content-manager';
import { AlertTriangle } from 'react-feather';

const CriteriosAvaliacao = ({
  riscoId = 0,
  avaliacaoModel = {},
  dataAvaliacao = null,
  disabled = false,
  avaliacaoAnterior = false,
  setIsLoadingFormula = () => {},
  isLoadingFormula = false,
}) => {
  const [criteriosAvaliacao, setCriteriosAvaliacao] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resultado, setResultado] = useState(null);
  const [model, setModel] = useState([]);
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const getCriteriosAvaliacao = useCallback(async () => {
    setIsLoading(true);
    await http
      .post(`/CriteriosAvaliacao/ObterCriteriosAvaliacao`, {
        riscoId: riscoId,
        dataReferencia: dataAvaliacao,
      })
      .then((response) => {
        setCriteriosAvaliacao(response.data);
        setIsLoading(false);
      });
  });

  const requestChange = (value, criterio) => {
    if (avaliacaoModel?.getModel) {
      let criterios = avaliacaoModel.getModel('criteriosAvaliacao')?.value;

      if (!criterios) criterios = [];

      let index = criterios.findIndex((v) => v.id == criterio.id);
      if (index > -1) {
        criterios[index].criterioItem = value;
      } else {
        criterios.push({
          id: criterio.id,
          criterioItem: value,
        });
      }
    }
  };

  const gerarProbabilidade = () => {
    setIsLoadingFormula(true);
    http
      .post(`/RiscoAvaliacao/CalcularProbabilidadeFormula`, {
        riscoId: riscoId,
        criterios: avaliacaoModel.getModel('criteriosAvaliacao')?.value,
        dataReferencia: dataAvaliacao,
      })
      .then((response) => {
        if (!response.data) {
          enqueueSnackbar(
            intl.formatMessage({
              id: 'msgValidacaoGerarProbabilidadeValorNaoEncontradoCalculoFormula',
            }),
            {
              variant: 'error',
            }
          );
        }

        if (avaliacaoModel?.getModel) {
          avaliacaoModel
            .getModel('probabilidadeInerente')
            ?.requestChange(response.data.probabilidade);
          setResultado(response.data);
        }
      })
      .finally(() => {
        setIsLoadingFormula(false);
      });
  };

  const getValue = (criterio) => {
    if (avaliacaoModel?.getModel || (avaliacaoAnterior && avaliacaoModel)) {
      var criterios = avaliacaoAnterior
        ? avaliacaoModel?.criteriosAvaliacao
        : avaliacaoModel.getModel('criteriosAvaliacao')?.value;

      if (criterios) {
        const item = criterios.find(
          (v) => v.criterioItem?.criterio?.id == criterio.id
        )?.criterioItem;

        return item;
      }
    }
    return model[criterio.id];
  };

  const abrirMemoriaCalculo = () => {
    ContentManager.addContent(
      <MemoriaCalculoValor
        riscoId={riscoId}
        avaliacaoModel={avaliacaoModel}
        dataAvaliacao={dataAvaliacao}
        resultado={resultado}
      />
    );
  };

  useEffect(() => {
    getCriteriosAvaliacao();
  }, [riscoId]);

  return (
    <LoadingContainer isLoading={isLoading || isLoadingFormula}>
      {criteriosAvaliacao && criteriosAvaliacao.length > 0 && (
        <Panel
          header={intl.formatMessage({
            id: 'labelAvaliacaoProbabilidadeCriterios',
          })}
          open={true}
          isLoading={isLoading || isLoadingFormula}
        >
          {criteriosAvaliacao &&
            criteriosAvaliacao.map((criterio) => {
              return (
                <CriteriosAvaliacaoLookup
                  model={{
                    label: criterio.descricao,
                    value: getValue(criterio),
                    requestChange: (value) => requestChange(value, criterio),
                  }}
                  disabled={disabled}
                  options={criterio.itens}
                  labelKey="descricao"
                  valueKey="id"
                />
              );
            })}
          <div className="w-100 d-flex justify-content-center">
            <Button
              color="primary"
              className="w-100"
              onClick={gerarProbabilidade}
              disabled={disabled || isLoadingFormula}
              leftIcon="calculator"
            >
              {intl.formatMessage(
                { id: 'labelGerarProbabilidade' },
                { probabilidade: resources?.probabilidade }
              )}
            </Button>
          </div>
          {resultado && resultado.hasError && (
            <div className="pt-2 d-inline-flex" style={{ color: '#D33115' }}>
              <a
                href="# "
                style={{ color: '#D33115' }}
                onClick={abrirMemoriaCalculo}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <AlertTriangle size={12} color="red" />
                  </div>
                  <div className="pl-2">
                    {intl.formatMessage({
                      id: 'labelValorNaoEncontrado',
                    })}
                  </div>
                </div>
              </a>
            </div>
          )}
          {resultado && !resultado.hasError && (
            <div className="pt-2">
              <a href="# " onClick={abrirMemoriaCalculo}>
                {intl.formatMessage({
                  id: 'labelVerMemoriaCalculo',
                })}
              </a>
            </div>
          )}
        </Panel>
      )}
    </LoadingContainer>
  );
};

export default CriteriosAvaliacao;
