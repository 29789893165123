import React from 'react';
import classnames from 'classnames';
import { autobind } from 'core-decorators';
import css from '../../utils/css';

function distance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

const classes = css`
  .ripple {
    position: relative;
    overflow: hidden;

    user-select: none;

    .ink {
      position: absolute;
      border-radius: 50%;
    }

    &,
    &.ripple-dark {
      .ink {
        background-color: rgba(0, 0, 0, 0.1);

        &.in {
          background-color: rgba(0, 0, 0, 0.2);
        }

        &.out {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }

    &.ripple-light {
      .ink {
        background-color: rgba(255, 255, 255, 0.1);

        &.in {
          background-color: rgba(255, 255, 255, 0.2);
        }

        &.out {
          background-color: rgba(255, 255, 255, 0);
        }
      }
    }
  }
`;

@autobind
class Ripple extends React.Component {
  handleMouseDown(e) {
    let container = this.refs.container;
    let { center } = this.props;

    if (container) {
      let ink = document.createElement('span');

      ink.className = 'ink';

      let boundingRect = container.getBoundingClientRect();

      let cx, cy, radius, d, duration;

      if (center) {
        cx = boundingRect.width / 2;
        cy = boundingRect.height / 2;
        radius = Math.max(cx, cy);
      } else {
        cx = e.pageX - boundingRect.left;
        cy = e.pageY - boundingRect.top;
        radius = Math.max(
          distance(cx, cy, 0, 0),
          distance(cx, cy, boundingRect.width, 0),
          distance(cx, cy, boundingRect.width, boundingRect.height),
          distance(cx, cy, 0, boundingRect.height)
        );
      }

      d = radius * 2;
      duration = 250 + radius;

      ink.style.left = cx + 'px';
      ink.style.top = cy + 'px';
      ink.style.width = ink.style.height = '0px';
      ink.style.transition = `all ${duration}ms ease-out`;

      container.appendChild(ink);

      setTimeout(() => {
        ink.classList.add('in');

        ink.style.width = ink.style.height = d + 'px';
        ink.style.marginLeft = ink.style.marginTop = -radius + 'px';
      }, 0);

      function onMouseUp() {
        ink.classList.remove('in');
        ink.classList.add('out');

        setTimeout(() => {
          ink.remove();
        }, duration);

        container.removeEventListener('mouseup', onMouseUp);
        container.removeEventListener('mouseleave', onMouseUp);
      }

      container.addEventListener('mouseup', onMouseUp);
      container.addEventListener('mouseleave', onMouseUp);
    }
  }

  render() {
    let { children, className, light, dark, component = 'div', ...other } = this.props;

    let composedClassName = classnames(classes.ripple, className, {
      'ripple-light': light,
      'ripple-dark': dark,
    });

    return React.createElement(
      component,
      {
        ref: 'container',
        onMouseDown: this.handleMouseDown,
        className: composedClassName,
        ...other,
      },
      children
    );
  }
}

export default Ripple;
