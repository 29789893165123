import React from 'react';
import classNames from 'classnames';
import ButtonHelp from './ButtonHelp';
import { NoPrint } from 'react-easy-print';

const Main = ({ className, children, module }) => (
  <div className={classNames('main', className)}>
    {children}
    {(module.id === 1 || module.id === 3) && (
      <div
        style={{
          position: 'fixed',
          right: '10px',
          top: '90%',
          zIndex: 1000,
        }}
      >
        <NoPrint>
          <ButtonHelp />
        </NoPrint>
      </div>
    )}
  </div>
);

export default Main;
