import React from 'react';
import { Col, Row } from 'reactstrap';
import FarolAcaoLookup from '../../lookups/farol-acao';

class AreaColaboradorSearch extends React.Component {
  render() {
    let { model } = this.props;

    return (
      <div>
        <Row>
          <Col md={3}>
            <FarolAcaoLookup clearable model={model.getModel('farol')} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default AreaColaboradorSearch;
