import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select-async';
import ProcessoList from '../paginas/gestao-risco/processo/view';

@autobind
class RiscoLookup extends React.Component {
  render() {
    return (
      <Lookup
        showSearch
        labelKey="nome"
        valueKey="id"
        url="/ProcessoLookup"
        hierarchy={true}
        clearable={true}
        searchComponent={ProcessoList}
        {...this.props}
      />
    );
  }
}

export default RiscoLookup;
