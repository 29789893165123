import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../../componentes/list';
import ListColumn from '../../../../componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/string';
import Search from './search';
import SearchSchema from './search-schema';

@autobind
class HitoricoOcupacaoList extends React.Component {
  render() {
    let { funcaoId, intl, resources, disabled } = this.props;
    return (
      <div>
        <List
          url="/ColaboradorFuncao"
          showMenu={false}
          showNew={false}
          searchComponent={<Search />}
          searchSchema={SearchSchema}
          showSearch={true}
          titulo={'historicoOcupacao'}
          sort="dataInicio"
          parameters={{ FuncaoId: funcaoId }}
          showExport={false}
          showDelete={false}
          disabled={disabled}
          filtroId={201}
          {...this.props}
        >
          <ListColumn headerText={capitalizeFirstLetter(resources.colaborador)} valueField="colaborador" />
          <ListColumn headerText={intl.formatMessage({ id: 'matricula' })} valueField="matricula" />
          <ListColumn headerText={intl.formatMessage({ id: 'dataInicio' })} valueField="dataInicio" />
          <ListColumn headerText={intl.formatMessage({ id: 'dataTermino' })} valueField="dataTermino" />
        </List>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(HitoricoOcupacaoList));
