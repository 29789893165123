import React from 'react';
import { connect } from 'react-redux';
import { Badge, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { injectIntl } from 'react-intl';

import Table from 'src/componentes/table/index.jsx';
import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';

import ListRisco from 'src/paginas/gestao-risco/risco/RiscoListView';
import css from 'src/utils/css';
import { defineBgColorByScore } from 'src/paginas/gestao-risco/matriz-risco/utils/matriz_com_score.utils';

const classes = css`
  .verticalTxtTh {
    writing-mode: tb-rl;
    vertical-align: middle !important;
  }

  .fillAvaliableSpace {
    position: absolute;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: 0px !important;
  }

  .parentOfFillAvaliableSpace {
    position: relative;
  }

  .renderAllTxt {
    padding: 0px !important;
  }

  .centralizedInsideAbs {
    color: #000000 !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

class TabelaMatrizRiscoComScore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpenId: null,
    };
  }

  openPop(open, id) {
    if (this.state.popoverOpenId === id || !this.state.popoverOpenId)
      this.setState({ popoverOpenId: open ? id : null });
  }

  handleRiscoClick(
    score,
    riscoAvaliacoes,
    severidade,
    probabilidade,
    inerente
  ) {
    const riscos =
      riscoAvaliacoes && inerente
        ? riscoAvaliacoes.filter(
            (a) =>
              a.severidadeInerente.id == severidade.id &&
              a.probabilidadeInerente.id == probabilidade.id
          )
        : riscoAvaliacoes.filter(
            (a) =>
              a.severidade.id == severidade.id &&
              a.probabilidade.id == probabilidade.id
          );

    if (riscos && riscos.length) {
      let { resources } = this.props;

      ContentManager.addContent(
        <ListRisco
          openLastFilter={true}
          usarFiltroSalvo={true}
          showModal={true}
          showNew={false}
          params={{ idsSelecionados: riscos.map(({ risco: { id } }) => id) }}
          title={capitalizeFirstLetter(resources.risco)}
        />
      );
    }
  }

  getAvaliacoes(
    riscoAvaliacoes,
    severidade,
    probabilidade,
    inerente,
    somenteSelecionados = false
  ) {
    if (inerente) {
      const avaliacaoInerente = riscoAvaliacoes.filter(
        (a) =>
          a.severidadeInerente &&
          a.severidadeInerente.id == severidade.severidade.id &&
          a.probabilidadeInerente &&
          a.probabilidadeInerente.id == probabilidade.probabilidade.id &&
          (!somenteSelecionados ||
            this.props.idsSelecionados?.includes(a.risco.id))
      );

      if (avaliacaoInerente.length === 0) {
        return null;
      }

      return avaliacaoInerente;
    }

    const avaliacao = riscoAvaliacoes.filter(
      (a) =>
        a.severidade.id == severidade.severidade.id &&
        a.probabilidade.id == probabilidade.probabilidade.id &&
        (!somenteSelecionados ||
          this.props.idsSelecionados?.includes(a.risco.id))
    );

    if (avaliacao.length === 0) {
      return null;
    }

    return avaliacao;
  }

  getAvaliacao(riscoAvaliacoes, severidade, probabilidade, inerente) {
    const avaliacao = this.getAvaliacoes(
      riscoAvaliacoes,
      severidade,
      probabilidade,
      inerente
    );

    if (!avaliacao) return null;
    return avaliacao[0];
  }

  countOfRiscoAtScore(
    riscoAvaliacoes,
    severidade,
    probabilidade,
    inerente,
    showScore
  ) {
    if (inerente) {
      const avaliacaoInerente = this.getAvaliacoes(
        riscoAvaliacoes,
        severidade,
        probabilidade,
        inerente,
        this.props.idsSelecionados?.length > 0 &&
          this.rowSelected(riscoAvaliacoes, severidade, probabilidade, inerente)
      );

      if (!avaliacaoInerente || avaliacaoInerente.length === 0) {
        return 0;
      }

      return avaliacaoInerente
        ? showScore
          ? avaliacaoInerente[0].scoreInerente
          : avaliacaoInerente.length
        : 0;
    }

    const avaliacao = this.getAvaliacoes(
      riscoAvaliacoes,
      severidade,
      probabilidade,
      inerente,
      this.props.idsSelecionados?.length > 0 &&
        this.rowSelected(riscoAvaliacoes, severidade, probabilidade, inerente)
    );

    if (!avaliacao || avaliacao.length === 0) {
      return 0;
    }

    return avaliacao ? (showScore ? avaliacao[0].score : avaliacao.length) : 0;
  }

  getSelectedsIds(riscoAvaliacoes, severidade, probabilidade, inerente) {
    const riscos =
      riscoAvaliacoes && inerente
        ? riscoAvaliacoes.filter(
            (a) =>
              a.severidadeInerente &&
              a.severidadeInerente.id == severidade.severidade.id &&
              a.probabilidadeInerente &&
              a.probabilidadeInerente.id == probabilidade.probabilidade.id
          )
        : riscoAvaliacoes.filter(
            (a) =>
              a.severidade &&
              a.severidade.id == severidade.severidade.id &&
              a.probabilidade &&
              a.probabilidade.id == probabilidade.probabilidade.id
          );

    return riscos.length == 0 ? [] : riscos.map(({ risco: { id } }) => id);
  }

  setFilter(riscoAvaliacoes, severidade, probabilidade, inerente) {
    const idsSelecionados = this.getSelectedsIds(
      riscoAvaliacoes,
      severidade,
      probabilidade,
      inerente
    );

    const cancelSelection =
      this.props.idsSelecionados &&
      JSON.stringify(this.props.idsSelecionados.sort()) ===
        JSON.stringify(idsSelecionados.sort());

    if (cancelSelection) {
      if (idsSelecionados.length !== 0)
        this.props.setFilter &&
          this.props.setFilter(cancelSelection ? [] : idsSelecionados, {}, {});
    } else
      this.props.setFilter &&
        this.props.setFilter(idsSelecionados, severidade, probabilidade);
  }

  rowSelected(riscoAvaliacoes, severidade, probabilidade, inerente) {
    if (!this.props.idsSelecionados || this.props.idsSelecionados.length === 0)
      return true;

    const idsRow = this.getSelectedsIds(
      riscoAvaliacoes,
      severidade,
      probabilidade,
      inerente
    );

    const result = this.props.idsSelecionados.some((x) => {
      if (idsRow.includes(x)) return true;
    });

    return result;
  }

  setPositionMouse(column, inerente) {
    this.props.setPositionMouse &&
      this.props.setPositionMouse(column, inerente);
  }

  getPopOver(item, chave, inerente) {
    if (!item || !chave) return;

    const { resources, intl } = this.props;
    return (
      <Popover
        key={`Popover-${chave}`}
        placement="right"
        isOpen={this.state.popoverOpenId == chave}
        target={chave}
      >
        <PopoverHeader key={`PopoverHeader-${chave}`}>      
          {capitalizeFirstLetter(
            intl.formatMessage(
              { id: inerente ? 'labelRiscoInerente' : 'labelRiscoResidual' },
              { risco: resources.risco }
            )
          )}
        </PopoverHeader>
        <PopoverBody key={`PopoverBody-${chave}`}>
          {inerente ? (
            <span>
              <strong>
                {capitalizeFirstLetter(
                  intl.formatMessage({ id: 'labelScoreInerente' })
                )}
                : {item.scoreInerente}
              </strong>
            </span>
          ) : (
            <span>
              <strong>
                {capitalizeFirstLetter(
                  intl.formatMessage({ id: 'labelScoreResidual' })
                )}
                : {item.score}
              </strong>
            </span>
          )}
        </PopoverBody>
      </Popover>
    );
  }

  renderRows(matrizFarol, inverter) {
    if (inverter) return this.renderRowsInvertida(matrizFarol);

    const {
      apenasMaiorQueZero,
      inerente,
      isWidget,
      heightBase,
      intl,
      informarScore,
    } = this.props;
    const {
      scoreProbabilidades,
      scoreSeveridades,
      grauMatrizRisco,
      riscoAvaliacoes,
    } = matrizFarol;
    const _defineBgColorByScore = defineBgColorByScore(
      grauMatrizRisco ? grauMatrizRisco : []
    );
    const heightMatrizRisco = isWidget ? `${heightBase * 0.1}px` : '60px';

    return (
      scoreProbabilidades &&
      scoreProbabilidades.length > 0 &&
      scoreProbabilidades.map((probabilidade, indexLinhaProbabilidade) => {
        return (
          <tr
            key={`trKeyProb-${probabilidade.id}`}
            style={{ height: heightMatrizRisco }}
          >
            <td
              key={`tdKeyProb-${probabilidade.id}`}
              className={`font-weight-bold text-center ${classes.renderAllTxt}`}
              style={{
                padding: '20px 0px 0px 0.75rem',
                verticalAlign: 'middle',
              }}
            >
              {probabilidade.probabilidade.nome}
            </td>
            {scoreSeveridades &&
              scoreSeveridades.length > 0 &&
              scoreSeveridades.map((severidade, indexLinhaSeveridade) => {
                const hasScore =
                  this.countOfRiscoAtScore(
                    riscoAvaliacoes,
                    severidade,
                    probabilidade,
                    true,
                    true
                  ) > 0 ||
                  this.countOfRiscoAtScore(
                    riscoAvaliacoes,
                    severidade,
                    probabilidade,
                    false,
                    true
                  ) > 0;
                return (
                  <td
                    key={`tdKeySevProb-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                    id={`linhaSev-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                    style={{ padding: '0px' }}
                    className={`${classes.parentOfFillAvaliableSpace} cursor-pointer`}
                  >
                    <div
                      key={`divKeySevProb-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                      onClick={() =>
                        this.setFilter(
                          riscoAvaliacoes,
                          severidade,
                          probabilidade,
                          inerente
                        )
                      }
                      onMouseEnter={() =>
                        this.setPositionMouse(indexLinhaSeveridade, inerente)
                      }
                      onMouseLeave={() => this.setPositionMouse(-1)}
                      style={Object.assign(
                        _defineBgColorByScore(
                          severidade.score * probabilidade.score
                        ),
                        this.rowSelected(
                          riscoAvaliacoes,
                          severidade,
                          probabilidade,
                          inerente
                        )
                          ? this.props.idsSelecionados &&
                            this.props.idsSelecionados.length > 0
                            ? {
                                opacity: '1',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'black',
                              }
                            : !hasScore && informarScore
                            ? { opacity: '0.2' }
                            : {}
                          : { opacity: '0.2' }
                      )}
                      className={`text-center h5 ${
                        classes.fillAvaliableSpace
                      } ${'text-hover-link'}`}
                    >
                      {!informarScore &&
                        (!apenasMaiorQueZero ||
                          (apenasMaiorQueZero &&
                            this.countOfRiscoAtScore(
                              riscoAvaliacoes,
                              severidade,
                              probabilidade,
                              inerente,
                              false
                            )) > 0) && (
                          <Badge
                            key={`linhaSev-inerente-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                            id={`linhaSev-inerente-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                            className={`${classes.centralizedInsideAbs}`}
                            color="light"
                            pill
                          >
                            {this.countOfRiscoAtScore(
                              riscoAvaliacoes,
                              severidade,
                              probabilidade,
                              inerente
                            )}
                          </Badge>
                        )}
                      {informarScore && (
                        <div className="pt-4">
                          {this.countOfRiscoAtScore(
                            riscoAvaliacoes,
                            severidade,
                            probabilidade,
                            true,
                            true
                          ) > 0 && (
                            <>
                              <Badge
                                key={`linhaSev-inerente-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                                id={`linhaSev-inerente-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                                className={`${classes.centralizedInsideAbs}`}
                                onMouseOver={
                                  hasScore &&
                                  this.openPop.bind(
                                    this,
                                    true,
                                    `linhaSev-inerente-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`
                                  )
                                }
                                onMouseOut={
                                  hasScore &&
                                  this.openPop.bind(
                                    this,
                                    false,
                                    `linhaSev-inerente-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`
                                  )
                                }
                                color="light"
                                pill
                              >
                                {intl.formatMessage({
                                  id: 'labelSiglaRiscoInerente',
                                })}{' '}
                                (
                                {this.countOfRiscoAtScore(
                                  riscoAvaliacoes,
                                  severidade,
                                  probabilidade,
                                  true,
                                  true
                                )}
                                )
                              </Badge>
                              {this.getPopOver(
                                this.getAvaliacao(
                                  riscoAvaliacoes,
                                  severidade,
                                  probabilidade,
                                  true
                                ),
                                `linhaSev-inerente-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`,
                                true
                              )}
                            </>
                          )}
                          {this.countOfRiscoAtScore(
                            riscoAvaliacoes,
                            severidade,
                            probabilidade,
                            false,
                            true
                          ) > 0 && (
                            <>
                              <Badge
                                key={`linhaSev-residual-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                                id={`linhaSev-residual-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                                className={`${classes.centralizedInsideAbs}`}
                                onMouseOver={
                                  hasScore &&
                                  this.openPop.bind(
                                    this,
                                    true,
                                    `linhaSev-residual-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`
                                  )
                                }
                                onMouseOut={
                                  hasScore &&
                                  this.openPop.bind(
                                    this,
                                    false,
                                    `linhaSev-residual-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`
                                  )
                                }
                                color="light"
                                pill
                              >
                                {intl.formatMessage({
                                  id: 'labelSiglaRiscoResidual',
                                })}{' '}
                                (
                                {this.countOfRiscoAtScore(
                                  riscoAvaliacoes,
                                  severidade,
                                  probabilidade,
                                  false,
                                  true
                                )}
                                )
                              </Badge>
                              {this.getPopOver(
                                this.getAvaliacao(
                                  riscoAvaliacoes,
                                  severidade,
                                  probabilidade,
                                  false
                                ),
                                `linhaSev-residual-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`,
                                false
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                );
              })}
          </tr>
        );
      })
    );
  }

  renderRowsInvertida(matrizFarol) {
    const {
      apenasMaiorQueZero,
      heightBase,
      inerente,
      isWidget,
      informarScore,
      intl,
    } = this.props;
    const {
      scoreProbabilidades,
      scoreSeveridades,
      grauMatrizRisco,
      riscoAvaliacoes,
    } = matrizFarol;

    if (!grauMatrizRisco) return <></>;

    const _defineBgColorByScore = defineBgColorByScore(grauMatrizRisco);
    const heightMatrizRisco = isWidget ? `${heightBase * 0.1}px` : '60px';

    return (
      scoreSeveridades &&
      scoreSeveridades.length > 0 &&
      scoreSeveridades.map((severidade, indexLinhaSeveridade) => {
        return (
          <tr 
            key={`trSeveridadeKey-${indexLinhaSeveridade}`}          
            style={{ height: heightMatrizRisco }}
          >
            <td
              key={`tdSeveridadeKey-${indexLinhaSeveridade}`}          
              className={`font-weight-bold text-center ${classes.renderAllTxt}`}
              style={{
                padding: '20px 0px 0px 0.75rem',
                verticalAlign: 'middle',
              }}
            >
              {severidade.severidade.nome}
            </td>
            {scoreProbabilidades &&
              scoreProbabilidades.length > 0 &&
              scoreProbabilidades.map(
                (probabilidade, indexLinhaProbabilidade) => {
                  const hasScore =
                    this.countOfRiscoAtScore(
                      riscoAvaliacoes,
                      severidade,
                      probabilidade,
                      true,
                      true
                    ) > 0 ||
                    this.countOfRiscoAtScore(
                      riscoAvaliacoes,
                      severidade,
                      probabilidade,
                      false,
                      true
                    ) > 0;
                  return (
                    <td
                      key={`tdLinhaSeveridadeProbabilidadeKey-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}          
                      style={{ padding: '0px' }}
                      className={`${classes.parentOfFillAvaliableSpace}`}
                    >
                      <div
                        key={`divLinhaSeveridadeProbabilidadeKey-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}          
                        onClick={() =>
                          this.setFilter(
                            riscoAvaliacoes,
                            severidade,
                            probabilidade,
                            inerente
                          )
                        }
                        onMouseEnter={() =>
                          this.setPositionMouse(
                            indexLinhaProbabilidade,
                            inerente
                          )
                        }
                        onMouseLeave={() => this.setPositionMouse(-1)}
                        style={Object.assign(
                          _defineBgColorByScore(
                            severidade.score * probabilidade.score
                          ),
                          this.rowSelected(
                            riscoAvaliacoes,
                            severidade,
                            probabilidade,
                            inerente
                          )
                            ? this.props.idsSelecionados &&
                              this.props.idsSelecionados.length > 0
                              ? {
                                  opacity: '1',
                                  borderStyle: 'solid',
                                  borderWidth: '1px',
                                  borderColor: 'black',
                                }
                              : !hasScore && informarScore
                              ? { opacity: '0.2' }
                              : {}
                            : { opacity: '0.2' }
                        )}
                        className={`text-center h5 ${
                          classes.fillAvaliableSpace
                        } ${'text-hover-link'} cursor-pointer`}
                      >
                        {!informarScore &&
                          (!apenasMaiorQueZero ||
                            (apenasMaiorQueZero &&
                              this.countOfRiscoAtScore(
                                riscoAvaliacoes,
                                severidade,
                                probabilidade,
                                inerente
                              )) > 0) && (
                            <Badge
                              key={`tdLinhaSeveridadeProbabilidadeKey-divLinhaSeveridadeProbabilidadeKey-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}          
                              id={`tdLinhaSeveridadeProbabilidadeKey-divLinhaSeveridadeProbabilidadeKey-Badge-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}                                        
                              className={`${classes.centralizedInsideAbs}`}
                              onClick={this.handleRiscoClick.bind(
                                this,
                                severidade.score * probabilidade.score,
                                riscoAvaliacoes,
                                severidade,
                                probabilidade,
                                inerente
                              )}
                              color="light"
                              pill
                            >
                              {this.countOfRiscoAtScore(
                                riscoAvaliacoes,
                                severidade,
                                probabilidade,
                                inerente
                              )}
                            </Badge>
                          )}
                        {informarScore && (
                          <div className="pt-4">
                            {this.countOfRiscoAtScore(
                              riscoAvaliacoes,
                              severidade,
                              probabilidade,
                              true,
                              true
                            ) > 0 && (
                              <>
                                <Badge
                                  key={`linhaSev-inerente-Badge3-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                                  id={`linhaSev-inerente-Badge3-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                                  className={`${classes.centralizedInsideAbs}`}
                                  onMouseOver={
                                    hasScore &&
                                    this.openPop.bind(
                                      this,
                                      true,
                                      `linhaSev-inerente-Badge3-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`
                                    )
                                  }
                                  onMouseOut={
                                    hasScore &&
                                    this.openPop.bind(
                                      this,
                                      false,
                                      `linhaSev-inerente-Badge3-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`
                                    )
                                  }
                                  color="light"
                                  pill
                                >
                                  {intl.formatMessage({
                                    id: 'labelSiglaRiscoInerente',
                                  })}{' '}
                                  (
                                  {this.countOfRiscoAtScore(
                                    riscoAvaliacoes,
                                    severidade,
                                    probabilidade,
                                    true,
                                    true
                                  )}
                                  )
                                </Badge>
                                {this.getPopOver(
                                  this.getAvaliacao(
                                    riscoAvaliacoes,
                                    severidade,
                                    probabilidade,
                                    true
                                  ),
                                  `linhaSev-inerente-Badge3-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`,
                                  true
                                )}
                              </>
                            )}
                            {this.countOfRiscoAtScore(
                              riscoAvaliacoes,
                              severidade,
                              probabilidade,
                              false,
                              true
                            ) > 0 && (
                              <>
                                <Badge
                                  key={`linhaSev-residual-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                                  id={`linhaSev-residual-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`}
                                  className={`${classes.centralizedInsideAbs}`}
                                  onMouseOver={
                                    hasScore &&
                                    this.openPop.bind(
                                      this,
                                      true,
                                      `linhaSev-residual-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`
                                    )
                                  }
                                  onMouseOut={
                                    hasScore &&
                                    this.openPop.bind(
                                      this,
                                      false,
                                      `linhaSev-residual-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`
                                    )
                                  }
                                  color="light"
                                  pill
                                >
                                  {intl.formatMessage({
                                    id: 'labelSiglaRiscoResidual',
                                  })}{' '}
                                  (
                                  {this.countOfRiscoAtScore(
                                    riscoAvaliacoes,
                                    severidade,
                                    probabilidade,
                                    false,
                                    true
                                  )}
                                  )
                                </Badge>
                                {this.getPopOver(
                                  this.getAvaliacao(
                                    riscoAvaliacoes,
                                    severidade,
                                    probabilidade,
                                    false
                                  ),
                                  `linhaSev-residual-Badge2-${indexLinhaSeveridade}-${indexLinhaProbabilidade}`,
                                  false
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  );
                }
              )}
          </tr>
        );
      })
    );
  }

  renderHead(
    severidades,
    probabilidades,
    resources,
    inverter,
    header,
    inerente
  ) {
    if (inverter)
      return (
        <thead>
          <tr>
            <th></th>
            <th
              colSpan={probabilidades ? probabilidades.length : 0}
              className="text-center text-uppercase"
            >
              {resources.probabilidade}
            </th>
          </tr>
          <tr>
            <th
              className={`text-uppercase text-center ${classes.verticalTxtTh}`}
            >
              {resources.severidade}
            </th>
            {probabilidades &&
              probabilidades.length > 0 &&
              probabilidades.map((probabilidade, index) => (
                <th
                  key={`thKeyProb-${index}`}
                  onMouseEnter={() =>
                    this.setPositionMouse(index, this.props.inerente)
                  }
                  onMouseLeave={() => this.setPositionMouse(-1)}
                  className={`text-center ${classes.verticalTxtTh}`}
                  style={
                    this.props.positionInerente === this.props.inerente &&
                    this.props.positionMouse >= 0 &&
                    this.props.positionMouse === index
                      ? { backgroundColor: 'rgba(0, 0, 0, 0.075)' }
                      : {}
                  }
                >
                  {probabilidade.nome}
                </th>
              ))}
          </tr>
        </thead>
      );

    return (
      <thead>
        <tr>
          <th></th>
          <th
            colSpan={severidades ? severidades.length : 0}
            className="text-center text-uppercase"
          >
            {resources.severidade}
          </th>
        </tr>
        <tr>
          <th className={`text-uppercase text-center ${classes.verticalTxtTh}`}>
            {resources.probabilidade}
          </th>
          {severidades &&
            severidades.length > 0 &&
            severidades.map((severidade, index) => (
              <th
                key={`thKeySev-${index}`}
                onMouseEnter={() =>
                  this.setPositionMouse(index, this.props.inerente)
                }
                onMouseLeave={() => this.setPositionMouse(-1)}
                className={`text-center ${classes.verticalTxtTh}`}
                style={
                  this.props.positionInerente === this.props.inerente &&
                  this.props.positionMouse >= 0 &&
                  this.props.positionMouse === index
                    ? { backgroundColor: 'rgba(0, 0, 0, 0.075)' }
                    : {}
                }
              >
                {severidade.nome}
              </th>
            ))}
        </tr>
      </thead>
    );
  }

  render() {
    const { resources, matrizFarol, styleDiv, header } = this.props;

    if (!matrizFarol)
      return <></>;

    const { scoreSeveridades, scoreProbabilidades, inverter } = matrizFarol;
    const severidades = scoreSeveridades && scoreSeveridades.map((s) => s.severidade);
    const probabilidades = scoreProbabilidades && scoreProbabilidades.map((s) => s.probabilidade);

    return (
      <div>
        {header && (
          <p
            colSpan={probabilidades ? probabilidades.length : 0}
            className="pt-3 text-center text-uppercase font-weight-bolder text-muted"
          >
            {header}
          </p>
        )}
        <Table
          styleDiv={{ ...styleDiv }}
          style={{ tableLayout: 'fixed' }}
          responsive
          size="md"
          bordered
        >
          {this.renderHead(
            severidades,
            probabilidades,
            resources,
            inverter,
            header
          )}
          <tbody>{matrizFarol && this.renderRows(matrizFarol, inverter)}</tbody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(TabelaMatrizRiscoComScore));
