import { FormattedMessage } from 'react-intl';

import Edit from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import { capitalizeFirstLetter } from 'src/utils/string';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import useAuth from 'src/hooks/useAuth';

import Funcao from './funcao';
import HistoricoOcupacao from './historico-ocupacao';
import CestaIndicadores from './cesta-indicadores/view';

const FormEdit = ({ model }) => {
  const id = model.getModel('id') && model.getModel('id').value;
  const disabled = model.getModel('permissions').value
    ? !model.getModel('permissions').getModel('allowEdit').value
    : false;

  return (
    <Tabs>
      <Tab key="1" label={<FormattedMessage id="funcao" />}>
        <Funcao disabled={disabled} model={model}></Funcao>
      </Tab>
      <Tab key="2" label={<FormattedMessage id="historicoOcupacao" />}>
        <HistoricoOcupacao
          disabled={disabled}
          funcaoId={model.getModel('id').value}
        ></HistoricoOcupacao>
      </Tab>
      {id > 0 && (
        <Tab key="3" label={<FormattedMessage id="label.cestaIndicadores" />}>
          <CestaIndicadores disabled={disabled} model={model} />
        </Tab>
      )}
    </Tabs>
  );
};

const FuncaoEdit = (props) => {
  const { terms: resources } = useAuth();

  let schema = Schema.object({
    id: Schema.string(),
    nome: Schema.string()
      .label(<FormattedMessage id="nome" />)
      .required(),
    idIntegracao: Schema.string()
      .label(<FormattedMessage id="idIntegracao" />)
      .required(),
    cargo: Schema.string().label(<FormattedMessage id="cargo" />),
    descricao: Schema.string().label(<FormattedMessage id="descricao" />),
    indicador: Schema.string().label(<FormattedMessage id="label.indicador" />),
    peso: Schema.string().label(<FormattedMessage id={resources.peso} />),
  });

  return (
    <Edit
      url="/Funcao"
      title={capitalizeFirstLetter(resources.funcoes)}
      formComponent={FormEdit}
      schema={schema}
      width="70%"
      height={600}
      resources={resources}
      disabledEdit={false}
      forceShowSave={true}
      close={false}
      {...props}
    />
  );
};

export default FuncaoEdit;
