import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import 'nprogress/nprogress.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { AuthProvider } from 'src/contexts/AuthContext';
import store from 'src/store';
import App from './App';
import { TourProvider } from '@reactour/tour';
import ButtonRestartStepsTour from './components/ButtonRestartStepsTour/ButtonRestartStepsTour';

const queryClient = new QueryClient();

const generateMsalConfig = () => {
  const msalConfig = {
    auth: {
      clientId: '7f6b1e3a-3936-4920-8eca-5e8993d2e6a7',
      authority: 'https://login.microsoftonline.com/common/',
      redirectUri: '/',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  };

  return msalConfig;
};

const pca = new PublicClientApplication(generateMsalConfig());
pca.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
  ) {
    const currentAccount = pca.getActiveAccount();
    if (!currentAccount) {
      pca.setActiveAccount(event.payload.account);
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <TourProvider
        position={'bottom'}
        disableDotsNavigation={true}
        disableKeyboardNavigation={["left"]}
        prevButton={({ setCurrentStep }) => (
          <ButtonRestartStepsTour setCurrentStep={setCurrentStep} />
        )}
      >
        <AuthProvider>
          <MsalProvider instance={pca}>
            <App />
          </MsalProvider>
        </AuthProvider>{' '}
      </TourProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
