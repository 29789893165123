import React from 'react';
import { Table } from 'reactstrap';
import './style/table.css';
import DragScroll from '../dragscroll';

export default class Tabela extends React.Component {
  static defaultProps = {
    styleDiv: null,
    overflow: null,
  };

  render() {
    let { style, count, responsive, drag, styleDiv, overflow, stickyHeader, hover = true, ...other } = this.props;

    let classNames = responsive ? 'table-responsive' : '';
    classNames += stickyHeader ? ' sticky-header' : '';

    return responsive ? (
      <div style={styleDiv ? styleDiv : { paddingBottom: 15 }} className={classNames}>
        {drag ? (
          <DragScroll overflow={overflow} height="auto" width="100%">
            <Table style={{ overflow: 'visible', ...style }} {...other} hover={hover}>
              {this.props.children}
            </Table>
          </DragScroll>
        ) : (
          <Table style={{ overflow: 'visible', ...style }} {...other} hover={hover}>
            {this.props.children}
          </Table>
        )}
      </div>
    ) : (
      <div style={styleDiv ? styleDiv : { paddingBottom: 15 }}>
        <Table style={{ overflow: 'visible', width: '99%', ...style }} {...other} hover={hover}>
          {this.props.children}
        </Table>
      </div>
    );
  }
}
