import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../../componentes/dialog';
import Button from '../../../componentes/button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LoadingContainer from '../../../componentes/loading-container';
import { Alert } from 'reactstrap';
import ListItem from '../../item';
import MdiIcon from '../../../componentes/mdi-icon';

@autobind
class DesdobramentoErro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {}

  handleClose() {
    this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
      this.props.handleClose && this.props.handleClose();
    }, 300);
  }

  handleSave() {}

  render() {
    let { descricao, intl } = this.props;

    let editActions = [
      <div key="edit-toolbar">
        <Button color="secondary" onClick={this.handleClose}>
          <FormattedMessage id="label.fechar" />
        </Button>
      </div>,
    ];

    return (
      <Dialog
        ref="dialog"
        title={`${intl.formatMessage({ id: 'label.excluirDesdobramento' })}: ${descricao}`}
        minWidth={'80%'}
        minHeigth={'80%'}
        style={{ fontSize: 12 }}
        onRequestClose={this.handleClose}
        padContent
        actions={editActions}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <Alert color="warning">
            <MdiIcon icon="alert" />
            <span className="ml-2">{this.props.message}</span>
          </Alert>
          <ListItem parameters={{ desdobramentoId: this.props.desdobramentoId }} filtroId={-1} showSearch={false} showToolBar={false} />
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(DesdobramentoErro));
