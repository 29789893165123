import { Column, ColumnStore } from '@bryntum/gantt';

import ConfigApp from 'src/services/configApp';
import getInitials from 'src/utils/getInitials';

export default class UserColumn extends Column {
  static get $name() {
    return 'user';
  }

  static get type() {
    return 'user';
  }

  static get isGanttColumn() {
    return true;
  }

  static getRenderString(data) {
    const { nome, fotoArquivo } = data || {};

    return `<div class="d-flex align-items-center">
        <div class="mr-2">
          ${
            fotoArquivo
              ? `<img
            style="width: 30px; height: 30px; border-radius: 50%;"
            alt="Colaborador"
            src="${ConfigApp.ApiUrl}/ArquivoSistema/DownloadImagem?guid=${fotoArquivo?.guid}"                
          />`
              : `<div
            style="display: flex; justify-content: center; align-items: center; color: #FFF; width: 30px; height: 30px; border-radius: 50%; background: #BCBCBC;"                          
          >${getInitials(nome)}</div>`
          }
        </div>
        <div>${nome}</div>
      </div>`;
  }

  static get defaults() {
    return {
      htmlEncode: false,
    };
  }
}

ColumnStore.registerColumnType(UserColumn);
