import React from 'react';
import TabelaRelacionamento from '../relacionamento/filhos';

class ProcessoFilho extends React.Component {
  render() {
    let { model, itemIdIgnorar } = this.props;

    return (
      <div>
        <TabelaRelacionamento
          itemIdIgnorar={itemIdIgnorar}
          model={model.getModel('filhos')}
          allowNew={this.props.allowNew !== null ? this.props.allowNew : true}
        ></TabelaRelacionamento>
      </div>
    );
  }
}

export default ProcessoFilho;
