import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
@autobind
class TipoAcessoEstatico extends React.Component {
  render() {
    return (
      <Lookup
        labelKey="descricao"
        valueKey="id"
        options={[
          {
            id: '1',
            descricao: this.props.intl.formatMessage({ id: 'semPermissao' }),
          },
          {
            id: '2',
            descricao: this.props.intl.formatMessage({ id: 'somenteLeitura' }),
          },
          {
            id: '3',
            descricao: this.props.intl.formatMessage({ id: 'alteracao' }),
          },
        ]}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(TipoAcessoEstatico));
