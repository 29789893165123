import React, { lazy, useState } from 'react';
import PanelCollapse from 'src/componentes/panel-collapse';
import Panel from 'src/componentes/panel';
import Input from 'src/componentes/input';
import { PlusSquare, Trash } from 'react-feather';
import { Col, Label, Row } from 'reactstrap';
import { formats, modules } from './PlanoTesteUtils';
import ContentManager from 'src/componentes/content-manager';
import Button from 'src/componentes/button';
import { capitalizeFirstLetter } from 'src/utils/string';
import IconButton from 'src/componentes/icon-button';
import Table from 'src/componentes/table';
import RevisaoPontoControleSimplificadaListView from '../../risco/RevisaoPontoControle/RevisaoPontoControleSimplificadaListView';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import useAuth from 'src/hooks/useAuth';
import moment from 'moment';
import { ENTIDADE_ANEXOS } from 'src/utils/constants';
const ReactQuill = lazy(() => import('src/componentes/quill'));

const PlanoTesteEdit = ({
  intl,
  isDisabled = false,
  handleChange,
  testeControleId,
  controleId,
  planoTeste,
  errors,
  ...rest
}) => {
  const { configurations } = useAuth();

  const [form, setForm] = useState(
    planoTeste
      ? planoTeste
      : {
          descricao: '',
          testeControleId: testeControleId,
          steps: [{ descricao: '', comentario: '' }],
          amostras: [],
        }
  );

  const handleTitleChange = (value) => {
    const newForm = { ...form, nome: value };
    setForm(newForm);
    handleChange('planoTeste', newForm);
  };

  const handleStepChange = (index, value, field) => {
    if (field === 'comentario' && value > 49999) {
      return;
    }
    const newSteps = [...form.steps];
    newSteps[index][field] = value;
    const newForm = { ...form, steps: newSteps };
    setForm(newForm);
    handleChange('planoTeste', newForm);
  };

  const addStep = () => {
    const newSteps = form.steps;
    newSteps.push({ descricao: '', comentario: '', amostras: [] });
    setForm({ ...form, steps: newSteps });
  };

  const removeStep = (index) => {
    const newSteps = form.steps;
    newSteps.splice(index, 1);
    setForm({ ...form, steps: newSteps });
  };
  const handleSelectedCallBack = (itens, indexStep) => {
    requestChangeAmostra(itens, indexStep);
  };

  const handleRemove = (index, step, indexStep) => {
    let novaLista = step?.amostras;
    novaLista.splice(index, 1);
    handleSelectedCallBack(novaLista, indexStep);
  };

  const handleOpenAmostras = (indexStep, amostras) => {
    ContentManager.addContent(
      <RevisaoPontoControleSimplificadaListView
        {...rest}
        showModal={true}
        //model={form?.amostras}
        requestChange={requestChangeAmostra}
        hideHead={true}
        //ignoreFiltroId={true}
        //usarFiltroSalvo={false}
        hideControlesFilter={true}
        showNew={false}
        select={true}
        multiple={true}
        selectedIds={amostras}
        selectedItems={amostras}
        parameters={{ idPontoControle: controleId }}
        selectedCallback={(v) => handleSelectedCallBack(v, indexStep)}
      />
    );
  };

  const requestChangeAmostra = (amostra, indexStep) => {
    const newForm = { ...form };
    const step = newForm.steps[indexStep];
    step.amostras = amostra;
    setForm(newForm);
    handleChange('planoTeste', newForm);
  };

  const renderAnexo = (item) => {
    return (
      <ArquivoUploadAnexos
        formGroupStyle={{ paddingTop: 10, marginBottom: 5 }}
        tipoAnexo={configurations?.tiposAnexos.riscoPontoControleRevisao}
        anexoObrigatorio={false}
        disabled={true}
        useChunks={true}
        model={{
          value: item.arquivos,
          requestChange: () => {},
        }}
      />
    );
  };

  const renderDataRevisao = (item) => {
    if (!item.dataRevisao) return;

    return <p>{moment(item.dataRevisao).format('DD/MM/YYYY')}</p>;
  };

  return (
    <PanelCollapse
      header={intl.formatMessage({ id: 'labelPlanoTeste' })}
      style={{ backgroundColor: '#DEE2E626' }}
    >
      <Panel>
        <Input
          disabled={isDisabled}
          type="textarea"
          required
          style={errors?.planoTeste?.nome ? { borderColor: 'red' } : {}}
          maxLength={20000}
          rows={2}
          model={{
            label: intl.formatMessage({ id: 'labelNomePlano' }),
            value: form?.nome,
            requestChange: (v) => handleTitleChange(v),
            errors: errors?.planoTeste?.nome,
          }}
        />
        <hr />
        {form?.steps?.map((step, indexStep) => (
          <div className="pt-2" key={indexStep}>
            <Panel>
              <Row>
                <Col
                  className="d-flex align-items-end justify-content-end"
                  md={12}
                >
                  <div className="p-0 ">
                    <Trash
                      size={18}
                      disabled={isDisabled}
                      style={{ opacity: isDisabled ? 0.5 : 1 }}
                      className="cursor-pointer"
                      onClick={() => !isDisabled && removeStep(indexStep)}
                    />
                  </div>
                </Col>
              </Row>
              <Input
                disabled={isDisabled}
                type="textarea"
                maxLength={20000}
                rows={2}
                model={{
                  label: intl.formatMessage({ id: 'labelInsiraPasso' }),
                  value: step?.nome,
                  requestChange: (v) => handleStepChange(indexStep, v, 'nome'),
                }}
              />
              <Label className="mt-2">
                {intl.formatMessage({ id: 'label.comentarios' })}
              </Label>
              <ReactQuill
                style={{
                  minHeight: '200px',
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
                value={step?.comentario}
                theme="snow"
                formats={formats}
                modules={modules}
                disabled={isDisabled}
                readOnly={isDisabled}
                onChange={(v) => handleStepChange(indexStep, v, 'comentario')}
              />
              <Panel className="mt-3">
                <Row>
                  <Col md={3}>
                    <Label className="mt-2">
                      {intl.formatMessage({ id: 'labelSelecioneAmostras' })}
                    </Label>
                  </Col>
                  <Col
                    className="d-flex align-items-end justify-content-end"
                    md={9}
                  >
                    <Button
                      className="pull-right"
                      leftIcon="magnify"
                      type="primary"
                      disabled={isDisabled}
                      style={{ marginBottom: 10 }}
                      onClick={() =>
                        handleOpenAmostras(indexStep, step.amostras)
                      }
                    >
                      {intl.formatMessage({ id: 'label.buscar' })}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {!step?.amostras || step?.amostras?.length === 0 ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '100px' }}
                      >
                        {intl.formatMessage({
                          id: 'labelNenhumaAmostraSelecionada',
                        })}
                      </div>
                    ) : (
                      <Table>
                        <thead>
                          <tr>
                            <th>
                              {intl.formatMessage({ id: 'labelAmostras' })}
                            </th>
                            <th>
                              {intl.formatMessage({
                                id: 'label.campoComentario',
                              })}
                            </th>
                            <th>
                              {capitalizeFirstLetter(
                                intl.formatMessage({ id: 'labelAnexos' })
                              )}
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {step?.amostras &&
                            step?.amostras?.map((item, indexAmostra) => (
                              <tr>
                                <td>{renderDataRevisao(item)}</td>
                                <td>{item.comentario}</td>
                                <td>{renderAnexo(item)}</td>
                                <td style={{ width: 40 }}>
                                  <IconButton
                                    icon="close-circle"
                                    disabled={isDisabled}
                                    className="clarear"
                                    onClick={() =>
                                      !isDisabled &&
                                      handleRemove(
                                        indexAmostra,
                                        step,
                                        indexStep
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}
                  </Col>
                </Row>
              </Panel>
              <div>
                <ArquivoUploadAnexos
                  classNameFormGroup={'d-flex inline'}
                  formGroupStyle={{ marginBottom: 0 }}
                  onlyRead={isDisabled}
                  maxWidth="150px"
                  tipoAnexo={ENTIDADE_ANEXOS.PLANO_TESTE_STEP}
                  rightLabel={true}
                  useChunks={true}
                  model={{
                    label: intl.formatMessage({ id: 'labelAnexarEvidencia' }),
                    value: step?.anexos,
                    requestChange: (v) =>
                      handleStepChange(indexStep, v, 'anexos'),
                  }}
                />
              </div>
            </Panel>
          </div>
        ))}
      </Panel>
      <div
        style={{ height: '100%', width: '100%', opacity: isDisabled ? 0.5 : 1 }}
        onClick={isDisabled ? () => {} : addStep}
      >
        <PlusSquare className="cursor-pointer text-primary" size={20} />
        <span className="text-primary text-center mb-2">
          {intl.formatMessage({ id: 'labelAdicionarPasso' })}
        </span>
      </div>
    </PanelCollapse>
  );
};

export default PlanoTesteEdit;
