import React, { useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './style.css';

const DropdownSubmenu = ({
  title,
  children,
  direction = 'right',
  isSubmenu = false,
  isButtonHelp = false,
}) => {
  // controlar se o menu está sendo "hovered"
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredSubItemIndex, setHoveredSubItemIndex] = useState(null); // indice do item que está com hover

  // função chamada quando o mouse entra no menu principal
  const handleMouseEnter = () => setIsHovered(true);

  // função chamada quando o mouse sai do menu, reseta o hover do submenu
  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredSubItemIndex(null); // volta o índice de hover do submenu
  };

  // função chamada quando o mouse entra em um item do submenu
  const handleSubItemMouseEnter = (index) => setHoveredSubItemIndex(index);

  // função chamada quando o mouse sai de um item do submenu
  const handleSubItemMouseLeave = () => setHoveredSubItemIndex(null);

  return (
    <UncontrolledDropdown
      direction={direction} // direção do dropdown
      nav
      inNavbar
      isOpen={isHovered} // mostra os itens ao passar o mouse no submenu
      toggle={() => {}} // desabilita o comportamento padrão de abrir/fechar ao clicar para mostrar os itens somente ao passar o mouse
      onMouseEnter={handleMouseEnter} // chama handleMouseEnter ao passar o mouse sobre o menu principal
      onMouseLeave={handleMouseLeave} // chama handleMouseLeave ao tirar o mouse do menu
    >
      <DropdownToggle
        nav
        className="dropdown-toggle-custom"
        data-is-submenu={isSubmenu}
      >
        {title}
        <span className="caret-icon"></span> {/* ícone da settinha */}
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu-custom"
        data-fix-dropdown={isButtonHelp ? 'true' : 'false'}
      >
        {/* dá um map nos filhos do menu para aplicar eventos de hover */}
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) return null;

          // escolhe a classe com base no estado de hover
          const itemClass =
            hoveredSubItemIndex === index
              ? 'submenu-item submenu-item-hover' // Aplica a classe de hover no item atual
              : 'submenu-item'; // Aplica a classe padrão para itens que não estão com hover

          // Clona o elemento filho e adiciona as classes e eventos de hover
          return React.cloneElement(child, {
            className: itemClass,
            onMouseEnter: () => handleSubItemMouseEnter(index), // Adiciona o hover no item atual
            onMouseLeave: handleSubItemMouseLeave, // Remove o hover quando o mouse sai do item
          });
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default DropdownSubmenu;
