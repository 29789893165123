import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useMsal } from '@azure/msal-react';
import { searchUsers } from '../services/eventsService';
import { getToken } from '../services/auth';
import {
  Input,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Badge,
  Button,
} from 'reactstrap';
import MdiIcon from 'src/componentes/mdi-icon';
import { useSnackbar } from 'notistack';

const UserSearchSelect = ({ onUserSelect, attendeeType }) => {
  const intl = useIntl();
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getToken(instance);
      setAccessToken(token);
    };

    fetchToken();
  }, [instance]);

  const loadOptions = useCallback(
    async (inputValue) => {
      if (!accessToken || !inputValue) return [];

      try {
        const users = await searchUsers(accessToken, inputValue);
        return users.map((user) => ({
          value: user.mail,
          label: user.displayName,
        }));
      } catch (error) {
        console.error('Error fetching users:', error);
        return [];
      }
    },
    [accessToken]
  );

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      const newOptions = await loadOptions(value);
      setOptions(newOptions);
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  const handleSelectOption = (option) => {
    if (!selectedOptions.some((opt) => opt.value === option.value)) {
      const updatedOptions = [...selectedOptions, option];
      setSelectedOptions(updatedOptions);
      setInputValue('');
      setShowOptions(false);
      onUserSelect(updatedOptions, attendeeType);
    } else {
      enqueueSnackbar(intl.formatMessage({ id: 'erroEmailJaAdicionado' }), { variant: 'error' });
    }
  };

  const handleCreateOption = () => {
    if (!inputValue) return;

    if (!selectedOptions.some((opt) => opt.value === inputValue)) {
      const newOption = { value: inputValue, label: inputValue, isNew: true };
      const updatedOptions = [...selectedOptions, newOption];
      setSelectedOptions(updatedOptions);
      setInputValue('');
      setShowOptions(false);
      onUserSelect(updatedOptions, attendeeType);
    } else {
      enqueueSnackbar(intl.formatMessage({ id: 'erroEmailJaAdicionado' }), { variant: 'error' });
    }
  };

  const handleRemoveOption = (option) => {
    const updatedOptions = selectedOptions.filter(
      (opt) => opt.value !== option.value
    );
    setSelectedOptions(updatedOptions);
    onUserSelect(updatedOptions, attendeeType);
  };

  return (
    <FormGroup>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={() => setShowOptions(false)}
        onFocus={() => inputValue && setShowOptions(true)}
        placeholder={intl.formatMessage({ id: 'label.pesquisar' })}
      />
      {showOptions && (
        <ListGroup
          className="options-list"
          style={{ maxHeight: '280px', overflowY: 'auto' }}
        >
          {options.map((option) => (
            <ListGroupItem
              key={option.value}
              onMouseDown={() => handleSelectOption(option)}
              className="cursor-pointer"
            >
              {option.label} - ({option.value})
            </ListGroupItem>
          ))}
          {inputValue && (
            <ListGroupItem
              onMouseDown={handleCreateOption}
              className="cursor-pointer"
            >
              {intl.formatMessage({ id: 'adicionar' })} "{inputValue}"
            </ListGroupItem>
          )}
        </ListGroup>
      )}
      <div className="selected-options mt-2 mb-2 gap-4 d-flex flex-wrap">
        {selectedOptions.map((option) => (
          <Badge
            key={option.value}
            color="secondary"
            className="mr-2 mb-2 d-flex align-items-center gap-2"
          >
            <span>{option.label}</span>
            <Button
              className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-0 border-0 bg-transparent"
              onClick={() => handleRemoveOption(option)}
            >
              <MdiIcon icon="close" />
            </Button>
          </Badge>
        ))}
      </div>
    </FormGroup>
  );
};

export default UserSearchSelect;
