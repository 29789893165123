import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  responsaveisSimplificado: Schema.string().label(''),
  areas: Schema.string().label(''),
  areasSubordinadas: Schema.string().label(''),
  tipoFiltroDataPeriodoAuditoriaRisco: Schema.string().label(''),
  deDataInicial: Schema.string().label(<FormattedMessage id="deDataInicial" />),
  ateDataFinal: Schema.string().label(<FormattedMessage id="ateDataFinal" />),
  auditorias: Schema.string().label(<FormattedMessage id="auditorias" />),
  checklists: Schema.string().label(<FormattedMessage id="labelChecklist" />),
  statusAuditoriaList: Schema.string().label(<FormattedMessage id="status" />),
  statusAcaoList: Schema.string().label(''),
});
