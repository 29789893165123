import { useIntl } from 'react-intl';
import { Table } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import guid from 'src/utils/guid';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import Edit from './edit';
import MdiIcon from 'src/componentes/mdi-icon';

const List = ({ model, disabled }) => {
  const intl = useIntl();

  const { terms: resources } = useAuth();

  const funcoes = model.value;

  const handleAdd = () => {
    const newList = [...model.value];
    newList.push({ key: guid() });

    model.requestChange(newList);
  };

  const reorderList = (startIndex, endIndex) => {
    const newList = [...model.value];
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);

    return newList;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newList = reorderList(result.source.index, result.destination.index);
    model.requestChange(newList);
  };

  const showTrashIcon = funcoes?.length >= 1;

  const existeFuncaoResponsavel =
    funcoes?.length >= 1 && funcoes.some((f) => f.funcaoResponsavel);

  return (
    <div className="w-100 mb-3" style={{ padding: '0 12px' }}>
      <Table className="w-100 table-borderless m-0">
        {funcoes?.length >= 1 && (
          <thead>
            <tr>
              <th
                className="p-0 pl-3 font-weight-bold"
                style={{ width: '70%' }}
              >
                {capitalizeFirstLetter(resources.funcao)} *
              </th>
              <th
                className="text-center p-0 pl-0 font-weight-bold"
                style={{ width: '25%' }}
              >
                {capitalizeFirstLetter(resources.responsavel)}
              </th>
              {showTrashIcon && <th className="p-0" style={{ width: '5%' }} />}
            </tr>
          </thead>
        )}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="funcoesContainer">
            {(provided) => (
              <tbody>
                {funcoes?.map((e, index) => (
                  <Draggable index={index} draggableId={`${index}`}>
                    {(provided) => {
                      const key = e.id ? `${e.id}` : e.key;
                      return (
                        <>
                          <Edit
                            key={key}
                            provided={provided}
                            funcao={e}
                            index={index}
                            model={model}
                            showTrashIcon={showTrashIcon}
                            disabled={disabled}
                            disabledCheckbox={
                              existeFuncaoResponsavel && !e.funcaoResponsavel
                            }
                          />
                          {provided.placeholder}
                        </>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      <div
        className="d-inline-flex align-items-center mt-2"
        onClick={handleAdd}
      >
        <div
          className="d-flex justify-content-center align-items-center p-1 bg-primary rounded-lg text-white"
          style={{ width: 20, height: 20, marginLeft: 15 }}
        >
          <MdiIcon className="cursor-pointer" icon="plus" size={14} />
        </div>
        <a className="ml-2 text-primary">
          {intl.formatMessage(
            { id: 'adicionarFuncaoRelacionada' },
            { funcao: resources.funcao }
          )}
        </a>
      </div>
    </div>
  );
};

export default List;
