import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../componentes/list';
import ListColumn from '../../componentes/list/list-column';
import MdiIcon from '../../componentes/mdi-icon';
import Search from './search';
import SearchModal from './search';
import SearchSchema from './search-schema';
import SearchSchemaModal from './search-schema-modal';
import SvgIcon from '../../componentes/svg-icon';
import Legenda from '../../componentes/legenda/legenda-item';
import { Badge, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import Dropdown from '../item/dropdown';
import history from '../../history';
import Preview from '../faixa-farol/preview';
import ContentManager from '../../componentes/content-manager';
import RelatorioAcompanhamento from '../relatorio-acompanhamento/edit';
import Farol from '../item/farol';
import Novo from './../item/novo';
import Schema from './schema';
import http from 'src/services/httpService';
import { getConfiguration } from 'src/services/commonRequests';
import ScoreAcumulado from 'src/componentes/score-acumulado/index';
import ProgressBar from 'src/componentes/progress-bar';

@autobind
class IndicadorBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      popoverFarol: false,
      activeItemFarol: null,
      configuracoes: null,
      changedColumns: false,
      sinalizarRelatorioPreenchido: false,
    };
  }

  componentDidMount() {
    getConfiguration('ItemConfiguracaoRisco')((configuracaoRisco) =>
      this.setState({ ...this.state, configuracaoRisco })
    )();
    http.post(this.props.urlItemConfiguracao, {}).then((response) => {
      this.setState({
        configuracoes: response.data,
        changedColumns: true,
      });
    });

    getConfiguration('RelatorioAcompanhamentoConfiguracao')(
      (configuracaoRelat) =>
        this.setState({
          sinalizarRelatorioPreenchido:
            configuracaoRelat.sinalizarRelatorioPreenchido,
        })
    )();
  }

  renderResponsavel(item) {
    return (
      <div>
        <p style={{ margin: '0 0 3px' }}>{item.responsaveis}</p>
      </div>
    );
  }

  renderDescricao(item, callback) {
    if (!item.nome) {
      return;
    }

    return item.tipo.id == 12 || this.props.isConsultaExterna ? ( //não permitir acesso a tela de valores se for template ou consulta externa
      <span>{item.nome}</span>
    ) : (
      <a
        tabIndex="-1"
        onClick={this.handleValueClick.bind(this, item, callback)}
        style={{ cursor: 'pointer' }}
      >
        {item.nome}
      </a>
    );
  }

  handleValueClick(item) {
    let allowBasic = item.permissions && item.permissions.allowBasic;

    history.push({
      pathname: '/gerenciar/valor',
      search: `?showActions=${!allowBasic}&autoLoad=${!allowBasic}&filtroExpandido=${allowBasic}&itemId=${
        item.id
      }`,
    });
    this.props.handleClose && this.props.handleClose();
  }

  handleRelatorioAcompanhamento(item) {
    if (item.relatorioAcompanhamento != null) {
      if (item.relatorioAcompanhamento.id != null) {
        ContentManager.addContent(
          <RelatorioAcompanhamento
            item={{ id: item.relatorioAcompanhamento.id }}
            parameters={{ itemValorId: item.id, itemId: item.itemId }}
            handleClose={this.handleClose}
          ></RelatorioAcompanhamento>
        );
      }
    }
  }

  handleClose() {
    this.setState({
      saved: true,
    });
  }

  renderFarol(item) {
    let target = 'farol' + item.id;
    return (
      <Farol
        target={target}
        item={item}
        ehAcumulado={false}
        onClick={
          !this.props.isConsultaExterna
            ? this.handleRelatorioAcompanhamento
            : null
        }
        sinalizarRelatorioPreenchido={this.state.sinalizarRelatorioPreenchido}
        relatorioAcompanhamento={item.relatorioAcompanhamento}
      />
    );
  }

  renderFarolAcumulado(item) {
    let target = 'farolAcumulado' + item.id;
    return <Farol target={target} item={item} ehAcumulado={true}></Farol>;
  }

  renderStatus(item) {
    return (
      <Badge
        pill
        className="text-white"
        style={{ background: item.status.cor }}
      >
        {item.status.descricao}
      </Badge>
    );
  }

  renderTags(item) {
    return item.tags?.map((x) => (
      <Badge pill className="text-white" style={{ background: x.cor }}>
        {x.descricao}
      </Badge>
    ));
  }

  renderFarolAcum(item) {
    return (
      <div>
        {item.farolAcumulado && (
          <SvgIcon
            icon={item.farolAcumulado.icone}
            size={20}
            color={item.farolAcumulado.cor}
            colorHover={item.farolAcumulado.cor}
            title={item.farolAcumulado.legenda}
          />
        )}
      </div>
    );
  }

  renderMelhor(item) {
    return item.melhor && <MdiIcon icon={item.melhor.icone} size={20} />;
  }

  renderDesdobramento(item) {
    return <span>{item.desdobramento && item.desdobramento.descricao}</span>;
  }

  renderPeriodicidade(item) {
    return <span>{item.periodicidade && item.periodicidade.descricao}</span>;
  }

  renderLabelMenu() {
    return <MdiIcon icon="dots-vertical" />;
  }

  renderEquipe(item) {
    if (!item.equipe) return null;
    if (Array.isArray(item.equipe)) {
      return item.equipe.map((m) => m.nome).join('; ');
    }
    return item.equipe.nome || '';
  }

  renderMenu(item) {
    const { configuracoes } = this.state;
    if (this.props.isConsultaExterna) return;

    return (
      <Dropdown
        handleCloseModal={this.props.handleClose}
        item={item}
        renderLabel={this.renderLabelMenu}
        configuracoes={configuracoes}
      ></Dropdown>
    );
  }

  abrirFaixaFarol(status, id) {
    this.setState({
      popoverFarol: status,
      activeItemFarol: id,
    });
  }

  renderPopoverFarol(item, target) {
    let model = { value: null };

    if (item.melhor != null && item.faixa != null) {
      if (item.melhor.id === 1) {
        model.value = item.faixa.faixaMaior;
      } else if (item.melhor.id === 2) {
        model.value = item.faixa.faixaMenor;
      } else {
        model.value = item.faixa.faixaIgual;
      }
    }

    return (
      <Popover
        placement="left"
        isOpen={this.state.popoverFarol}
        target={target}
      >
        <PopoverHeader>
          {this.props.intl.formatMessage({ id: 'faixaDeFarol' })}
        </PopoverHeader>
        <PopoverBody>{<Preview model={model}></Preview>}</PopoverBody>
      </Popover>
    );
  }

  renderUnidadeMedida(item) {
    return <span>{item.unidadeMedida && item.unidadeMedida.descricao}</span>;
  }

  handleCloseNew(saved) {
    if (saved) {
      this.setState({ saved: !this.state.saved });
    }
  }

  renderCronograma(item) {
    if (!item.acoesCronograma || item.acoesCronograma.length == 0) {
      return;
    }

    return (
      <div className="progress" style={{ borderRadius: 6 }}>
        {item.acoesCronograma &&
          item.acoesCronograma.map((acao, index) => (
            <ProgressBar
              key={index}
              color={acao.farolAcao.cor}
              value={acao.percentual}
            />
          ))}
      </div>
    );
  }

  handleNewClick() {
    ContentManager.addContent(
      <Novo
        url={this.props.urlController}
        tipoItem={this.props.tipoItemId}
        schema={Schema}
        handleClose={this.handleCloseNew}
      ></Novo>
    );
  }

  renderScoreAcumulado = ({ id, scoreAcumuladoDoGrau }) => (
    <ScoreAcumulado id={id} scoreAcumuladoDoGrau={scoreAcumuladoDoGrau} />
  );

  render() {
    let {
      resources,
      intl,
      params,
      showModal,
      urlController,
      tipoItemId,
      urlEdit,
      filtroModalId,
      filtroId,
      configuracaoSistema,
      tag,
      tagModal,
      isConsultaExterna,
    } = this.props;
    let { configuracoes, configuracaoRisco } = this.state;
    return (
      <div>
        {resources.area && configuracoes && configuracaoRisco && (
          <List
            url={urlController}
            saved={
              this.props.refreshResultadoArea
                ? this.props.refreshResultadoArea
                : this.state.saved
            }
            editMode="page"
            showCustomFields={true}
            tipoItem={tipoItemId}
            urlEdit={urlEdit}
            sort="UltimaVersao.Nome"
            handleNewClick={this.handleNewClick}
            searchComponent={showModal ? <SearchModal /> : <Search />}
            searchSchema={showModal ? SearchSchemaModal : SearchSchema}
            pageSize={10}
            valueField="nome"
            filtroId={showModal ? filtroModalId : filtroId}
            showSearch={true}
            tag={showModal ? tagModal : tag}
            parameters={params}
            showScrollColumn={false}
            showDelete={false}
            renderMenu={this.renderMenu}
            changedColumns={this.state.changedColumns}
            habilitarGeoreferencia={
              configuracaoSistema &&
              configuracaoSistema.utilizarGeoreferencia == true &&
              configuracoes &&
              configuracoes.utilizarGeoreferencia == true
            }
            {...this.props}
          >
            <ListColumn
              headerText={intl.formatMessage({ id: 'nome' })}
              required
              valueField="nome"
              sortField="UltimaVersao.Nome"
              valueFunction={this.renderDescricao}
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'codigo' })}
              tooltip="Código de Importação"
              valueField="idIntegracao"
              sortField="UltimaVersao.idIntegracao"
              width={100}
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.area)}
              valueField="area"
              sortField="UltimaVersao.Area.Nome"
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.responsaveis)}
              valueField="responsaveis"
              valueFunction={this.renderResponsavel}
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'periodicidade' })}
              valueField="periodicidade"
              sortField="UltimaVersao.periodicidade"
              valueFunction={this.renderPeriodicidade}
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'desdobramento' })}
              valueField="desdobramento"
              sortField="UltimaVersao.desdobramento"
              valueFunction={this.renderDesdobramento}
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'status' })}
              valueField="status"
              sortField="UltimaVersao.status"
              valueFunction={this.renderStatus}
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.polaridade)}
              valueField="melhor"
              sortField="UltimaVersao.melhor"
              valueFunction={this.renderMelhor}
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.farol)}
              valueField="farol"
              valueFunction={this.renderFarol}
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.farolAcumulado)}
              valueField="farolAcumulado"
              valueFunction={this.renderFarolAcumulado}
              default
            />
            {configuracoes && configuracoes.utilizarClassificacao && (
              <ListColumn
                headerText={capitalizeFirstLetter(resources.classificacao1)}
                valueField="classificacoes"
                minWidth={120}
              />
            )}
            {configuracoes && configuracoes.utilizarClassificacao2 && (
              <ListColumn
                headerText={capitalizeFirstLetter(resources.classificacao2)}
                valueField="classificacoes2"
                minWidth={120}
              />
            )}
            {configuracoes && configuracoes.utilizarTemaEstrategico && (
              <ListColumn
                headerText={capitalizeFirstLetter(resources.temaestrategico)}
                valueField="temasEstrategicos"
                minWidth={120}
              />
            )}
            {configuracoes && configuracoes.utilizarDigitadorValor && (
              <ListColumn
                headerText={capitalizeFirstLetter(resources.digitadores)}
                valueField="digitadores"
                minWidth={120}
              />
            )}
            <ListColumn
              headerText={intl.formatMessage({ id: 'unidadeDeMedida' })}
              valueField="unidadeMedida"
              sortField="UltimaVersao.unidadeMedida"
              valueFunction={this.renderUnidadeMedida}
              minWidth={120}
            />
            {configuracoes && configuracoes.utilizarPerspectiva && (
              <ListColumn
                headerText={capitalizeFirstLetter(resources.perspectiva)}
                valueField="perspectiva"
                sortField="UltimaVersao.perspectiva"
                minWidth={120}
              />
            )}
            {configuracaoRisco &&
              configuracaoRisco.habilitarCalculoDeScores && (
                <ListColumn
                  headerText={intl.formatMessage(
                    { id: 'scoreAcumulado' },
                    { risco: resources.risco.toLowerCase() }
                  )}
                  valueField="scoreAcumuladoDoGrau"
                  valueFunction={this.renderScoreAcumulado}
                  default
                />
              )}
            {configuracoes && configuracoes.utilizarCampoAuxiliarTexto1 && (
              <ListColumn
                headerText={capitalizeFirstLetter(
                  resources.campoauxiliartexto1
                )}
                valueField="campoAuxiliarTexto1"
                minWidth={120}
              />
            )}
            {configuracoes && configuracoes.utilizarCampoAuxiliarTexto2 && (
              <ListColumn
                headerText={capitalizeFirstLetter(
                  resources.campoauxiliartexto2
                )}
                valueField="campoAuxiliarTexto2"
                minWidth={120}
              />
            )}
            {configuracoes && configuracoes.utilizarCampoAuxiliarTexto3 && (
              <ListColumn
                headerText={capitalizeFirstLetter(
                  resources.campoauxiliartexto3
                )}
                valueField="campoAuxiliarTexto3"
                minWidth={120}
              />
            )}
            <ListColumn
              headerText={intl.formatMessage({ id: 'cronograma' })}
              valueField="acoesCronograma"
              valueFunction={this.renderCronograma}
              default
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'tags' },
                { tags: resources.tags }
              )}
              valueField="tags"
              valueFunction={this.renderTags}
              default
            />
            {configuracoes && configuracoes.utilizarEquipe && (
              <ListColumn
                headerText={intl.formatMessage({ id: 'equipe' })}
                valueField="equipe"
                valueFunction={this.renderEquipe}
                default
              />
            )}
          </List>
        )}
        <Legenda></Legenda>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracaoSistema: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(IndicadorBase));
