import React, { Suspense, lazy } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';

import { capitalizeFirstLetter } from 'src/utils/string';
import LoadingScreen from 'src/componentes/LoadingScreen';
import http from 'src/services/httpService';

const Grafico = lazy(() => import('src/componentes/chart'));
const filtroGraficoEstatistica = 36;

class Estatistica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relatorios: null,
      exibirRotulo: this.props.filterModel.componente
        ? this.props.filterModel.componente.exibirRotulo
        : this.props.filterModel.exibirRotulo,
      exibirTabela: this.props.filterModel.componente
        ? this.props.filterModel.componente.exibirTabela
        : this.props.filterModel.exibirTabela,
      tipoGrafico: this.props.filterModel.tipoExibicao,
      isLoading: false,
      dataSource: null,
      type: 'Pie2D',
    };
  }

  async componentDidMount() {
    await this.openLastFilter();
    this.refreshModel();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filterModel !== this.props.filterModel) {
      this.setState({
        exibirRotulo: this.props.filterModel.componente
          ? this.props.filterModel.componente.exibirRotulo
          : this.props.filterModel.exibirRotulo,
        exibirTabela: this.props.filterModel.componente
          ? this.props.filterModel.componente.exibirTabela
          : this.props.filterModel.exibirTabela,
        tipoGrafico: this.props.filterModel.tipoExibicao,
      });
      this.refreshModel(true);
    }
  }

  async refreshModel(value, checked) {
    let { model, filterModel } = this.props;
    this.setState({
      isLoading: true,
    });

    //alteração necessária quando chamado pelo widget, os widgtes salvam como 'subordinadas' e o model do
    //filtro espera por 'areasSubordinadas'
    model.areasSubordinadas = this.props.filterModel.componente
      ? model.subordinadas
      : model.areasSubordinadas;

    var tipoGraficoId =
      filterModel && filterModel.tipoExibicao && filterModel.tipoExibicao.id
        ? filterModel.tipoExibicao.id
        : filterModel.tipoExibicao;
    await http
      .post(`/RelatorioAcompanhamento/ObterGraficoEstatistica`, {
        model: model,
        tipoGraficoId: tipoGraficoId ? tipoGraficoId : 10,
        exibirRotulo: filterModel.componente
          ? filterModel.componente.exibirRotulo
          : filterModel.exibirRotulo,
        parameters: this.props.parameters,
        backgroundTransparente:
          filterModel.componente && filterModel.componente.transparent,
        exibirLegenda:
          filterModel.componente && filterModel.componente.exibirLegenda,
        rotuloFontFamily:
          filterModel.componente && filterModel.componente.rotuloFontFamily,
        rotuloCor: filterModel.componente && filterModel.componente.rotuloCor,
        rotuloNegrito:
          filterModel.componente && filterModel.componente.rotuloNegrito,
        rotuloFontSize:
          filterModel.componente && filterModel.componente.rotuloFontSize,
        exibirValoresEixoX: filterModel.exibirValoresEixoX,
        exibirValoresEixoY: filterModel.exibirValoresEixoY,
        host: window.location.origin,
      })
      .then((response) => {
        this.setState({
          relatorios: response.data,
          dataSource: response.data.grafico.dataSource,
          isLoading: false,
        });
      });
    if (value || checked) {
      this.handleSaveFilter();
    }
  }

  handleSaveFilter() {
    let { filterModel } = this.props;

    http
      .post(`/FiltroColaborador/${filtroGraficoEstatistica}/SalvarFiltro`, {
        filtroId: filtroGraficoEstatistica,
        valor: {
          exibirRotulo: filterModel.componente
            ? filterModel.componente.exibirRotulo
            : filterModel.exibirRotulo,
          exibirTabela: filterModel.componente
            ? filterModel.componente.exibirTabela
            : filterModel.exibirTabela,
          tipoGrafico: filterModel.tipoExibicao,
          type: filterModel.type,
        },
      })
      .then((response) => {})
      .catch(() => {});
  }

  //busca o ultimo filtro acessado
  async openLastFilter() {
    await http
      .post(
        `/FiltroColaborador/${filtroGraficoEstatistica}/AbrirUltimoFiltro`,
        {}
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            exibirRotulo: response.data.valor.exibirRotulo,
            exibirTabela: response.data.valor.exibirTabela,
          });
        }
      });
  }

  returnColor(idTipoFarol) {
    let color;
    switch (idTipoFarol) {
      case 0: {
        color = 'text-primary';
        break;
      }
      case 1: {
        color = 'text-info';
        break;
      }
      case 2: {
        color = 'text-warning';
        break;
      }
      default: {
        color = 'text-dark';
        break;
      }
    }
    return color;
  }

  renderGrafico() {
    let { relatorios, exibirTabela, tipoGrafico } = this.state;
    let { filterModel, backgroundTransparent, resources } = this.props;
    let data = relatorios.grafico.data;
    let dataSet = relatorios.grafico.dataSet;
    let tipoGraficoNovo = null;

    const statusPx = data && data.length * 60;
    const relatorioPx = relatorios && relatorios.total > 0 && 65;

    if (!filterModel.type) {
      tipoGraficoNovo =
        tipoGrafico && tipoGrafico.id == 2
          ? 'msline'
          : tipoGrafico && tipoGrafico.id == 11
          ? 'StackedColumn2d'
          : 'Pie2D';
    }

    var config = {
      type: filterModel.type ? filterModel.type : tipoGraficoNovo,
      width: filterModel.width ? filterModel.width : '600',
      height: filterModel.height
        ? filterModel.height - (statusPx + relatorioPx)
        : '280',
      containerBackgroundOpacity: backgroundTransparent ? '0' : '1',
      dataSource: {
        chart: {
          ...(relatorios && relatorios.grafico.chart),
          canvasBgAlpha: backgroundTransparent && '0',
          legendBgAlpha: backgroundTransparent && '0',
        },
        data: data,
        dataSet: dataSet,
        categories: relatorios.grafico && relatorios.grafico.categories,
      },
    };

    return (
      <div>
        <Grafico {...config} />
        <ListGroup
          flush
          className="mt-2"
          style={{
            maxWidth: '100%',
            width: filterModel.width && filterModel.width,
            height: statusPx + relatorioPx,
          }}
        >
          {exibirTabela &&
            data.length > 0 &&
            data.map((item) => (
              <ListGroupItem
                className={`pl-0 pr-0 font-weight-bold ${this.returnColor(
                  item.tipo
                )}`}
              >
                <ul className="list-inline">
                  <li className="list-inline-item">
                    {capitalizeFirstLetter(
                      this.props.intl.formatMessage({ id: item.label })
                    )}
                  </li>
                  <li className="list-inline-item pull-right">
                    <div className="d-inline-block">{item.percent}%</div>
                    <div
                      className="d-inline-block text-right"
                      style={{ minWidth: 35 }}
                    >
                      {item.value}
                    </div>
                  </li>
                </ul>
              </ListGroupItem>
            ))}
          {exibirTabela && relatorios.total > 0 && (
            <ListGroupItem className="text-right text-uppercase pl-0 pr-0 pb-0">
              <ul className="list-inline font-weight-bold">
                <li className="list-inline-item pull-right">
                  <div className="d-inline-block">
                    {this.props.intl.formatMessage(
                      { id: 'total' },
                      { total: resources.total }
                    )}
                  </div>
                  <div
                    className="d-inline-block text-right"
                    style={{ minWidth: 35 }}
                  >
                    {relatorios.total}
                  </div>
                </li>
              </ul>
            </ListGroupItem>
          )}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <Suspense fallback={<LoadingScreen />}>
        {this.state.relatorios && this.renderGrafico()}
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Estatistica));
