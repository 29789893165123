import { escapeRegExp } from './regexp';

let languageData = {
  decimalSeparator: ',',
  thousandsSeparator: '.',
};

export function formatNumber(value, precision) {
  if (value == null || value == undefined || value == '') {
    return '';
  }

  let { decimalSeparator, thousandsSeparator } = languageData;

  if (precision && precision instanceof Array) {
    let currentPrecision = (value.toString().split('.')[1] || '').length;
    precision = Math.min(Math.max(precision[0], currentPrecision), precision[1]);
  }
  let strValue = precision === undefined ? value.toString() : parseFloat(value).toFixed(precision);
  let parts = strValue.split('.');

  if (thousandsSeparator) {
    //Don't put the global modifier on that regex, check this bug: http://stackoverflow.com/questions/3827456/what-is-wrong-with-my-date-regex/3827500#3827500
    let regex = new RegExp('(\\d)(\\d{3})(?:' + escapeRegExp(thousandsSeparator) + '|$)');
    while (regex.test(parts[0])) {
      parts[0] = parts[0].replace(regex, '$1' + thousandsSeparator + '$2');
    }
  }
  return parts.join(decimalSeparator);
}

export function parseNumber(value, precision, userDecimalSeparator) {
  let { decimalSeparator } = languageData;

  if (userDecimalSeparator) {
    decimalSeparator = userDecimalSeparator;
  }

  value = value.replace(new RegExp('[^0-9+\\-\\' + decimalSeparator + ']', 'g'), '');
  value = value.replace(new RegExp('\\' + decimalSeparator, 'g'), '.');

  if (value == '') return null;

  if (precision && precision instanceof Array) {
    precision = precision[precision.length - 1];
  }

  let numberValue = Number(value);

  if (isNaN(numberValue)) return null;

  if (precision != null) numberValue = round(numberValue, precision);

  return numberValue;
}

export function round(value, precision) {
  let multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}
