import React from 'react';
import { autobind } from 'core-decorators';
import Edit from './edit';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import CheckBox from '../../../componentes/checkbox';
import MdiIcon from '../../../componentes/mdi-icon';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';

@autobind
class ParteInteressada extends React.Component {
  handleRemove(item, index) {
    this.props.model.value.splice(index, 1);
    this.props.model.requestChange(this.props.model.value);
  }

  handleSaveItem(saved, isNew, item, index) {
    if (saved) {
      let novaLista = this.props.model.value;

      if (isNew) {
        if (novaLista && novaLista.length > 0) novaLista.push(item);
        else novaLista = [].concat(item);
      } else novaLista[index] = item;

      this.props.model.requestChange(novaLista);
    }
  }

  handleParteInteressada(item) {
    return <span>{item.parteInteressada.descricao}</span>;
  }

  handleExibirGrafico(item) {
    return <CheckBox model={{ value: item.exibirNoGrafico }} style={{ marginBottom: '-15px' }}></CheckBox>;
  }

  handleCor(item) {
    return item.exibirNoGrafico && <MdiIcon icon="label-outline" color={item.cor} size={22}></MdiIcon>;
  }

  render() {
    let { model, disabled, resources, intl, ...other } = this.props;

    return (
      <List
        local
        showTitle
        title={capitalizeFirstLetter(resources.partesinteressadas)}
        dataSource={model.value}
        actionsWidth={30}
        handleSaveItem={this.handleSaveItem}
        handleRemove={this.handleRemove}
        editComponent={Edit}
        showPage={false}
        showSearch={false}
        showNew={!disabled}
        {...other}
      >
        <ListColumn headerText={intl.formatMessage({ id: 'codigo' })} visible={false} valueField="id"></ListColumn>
        <ListColumn headerText={capitalizeFirstLetter(resources.parteinteressada)} valueFunction={this.handleParteInteressada}></ListColumn>
        <ListColumn headerText={capitalizeFirstLetter(resources.observacao)} valueField="observacao"></ListColumn>
        <ListColumn headerText={intl.formatMessage({ id: 'label.resultadoEsperado' })} valueField="resultadoEsperado"></ListColumn>
        <ListColumn headerText={intl.formatMessage({ id: 'label.exibirNoGrafico' })} valueFunction={this.handleExibirGrafico}></ListColumn>
        <ListColumn headerText={intl.formatMessage({ id: 'label.corNoGrafico' })} valueFunction={this.handleCor}></ListColumn>
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ParteInteressada));
