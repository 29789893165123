import { useIntl } from 'react-intl';
import { Badge } from 'reactstrap';

import { capitalizeFirstLetter } from 'src/utils/string';
import { parseNumber } from 'src/utils/number';
import { COLUNAS } from 'src/utils/constants';
import http from 'src/services/httpService';
import ContentManager from 'src/componentes/content-manager';
import Alert from 'src/componentes/message-box/alert';
import useAuth from 'src/hooks/useAuth';
import FormEdit from 'src/paginas/remuneracao-variavel/funcoes/cesta-indicadores/edit/components/item/edit';
import errorHandler from 'src/utils/error-handler';
import ListCommon from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';

const List = ({ model, parameters, disableDrag = false }) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const itens = model?.getModel('itens')?.value;
  const permissions = model?.getModel('permissions').value;

  itens?.map((item) => {
    item.permissions = permissions;
  });

  let labelItem =
    intl.formatMessage({ id: 'label.indicador' }) +
    '/' +
    capitalizeFirstLetter(resources.funcao);

  const allowEdit = permissions ? permissions?.allowEdit : false;
  
  const reorderList = (startIndex, endIndex) => {
    const newList = [...model?.getModel('itens')?.value];
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);

    model?.getModel('itens')?.requestChange(newList);
  };

  const handleAfterSaveItemCesta = (itemCesta, isNew) => {
    try {
      let newList = [...(model?.getModel('itens')?.value || [])];
      let updatedItemCesta = {
        id: itemCesta.id,
        idIntegracao: itemCesta.item
          ? itemCesta.item.idIntegracao
          : itemCesta.funcao.idIntegracao,
        nome: itemCesta.item ? itemCesta.item.nome : itemCesta.funcao.nome,
        peso: itemCesta.peso,
        valorAplicado: itemCesta.valorAplicado,
        tipoCesta: itemCesta.tipoCesta,
        tipoItemCesta: itemCesta.tipoItemCesta,
        tipoCalculoItemCesta: itemCesta.tipoCalculoItemCesta,
        item: itemCesta.item
      };
      if (isNew) {
        newList.push(updatedItemCesta);
      } else {
        const index = newList.findIndex((i) => i.id == itemCesta.id);
        newList[index] = updatedItemCesta;
      }
      model?.getModel('itens')?.requestChange(newList);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleSaveItem = (item, isNew) => {
    handleAfterSaveItemCesta(item, isNew);    
  };

  const handleRemove = async (item, index) => {
    const newList = [...model?.getModel('itens')?.value];
    newList.splice(index, 1);
    const url = '/CestaIndicadorItem';

    try {
      await http.post(`${url}/Remove`, {
        id: item.id,
        parameters: parameters,
      });
      ContentManager.addContent(
        <Alert
          title={intl.formatMessage({ id: 'sucesso' })}
          message={intl.formatMessage({
            id: 'label.registroExcluidoComSucesso',
          })}
        />
      );
    } catch (error) {
      errorHandler(error);
    }

    model?.getModel('itens')?.requestChange(newList);
  };

  const renderTotal = () => {
    const itensArray = itens || [];
 
    let pesoSum = itensArray
      .map((item) => item.peso)
      .reduce((prev, curr) => prev + curr, 0);
    pesoSum = parseNumber(pesoSum + '', 2, '.');
  
    return (
      <tr>
        <td colSpan={9} className="font-weight-bolder text-right">
          {intl.formatMessage({ id: 'label.pesoTotal' })}: {pesoSum}
        </td>
      </tr>
    );
  }; 

  const renderPeso = (item) => {
    const utilizarAdicionalReducional = model?.getModel(
      'utilizarAdicionalReducional'
    )?.value;

    return (
      <>
        {utilizarAdicionalReducional && item.tipoCalculoItemCesta
          ? item.tipoCalculoItemCesta.descricao
          : item.peso}
      </>
    );
  };

  const renderValorAplicado = (item) => {
    return <>{item.valorAplicado?.descricao}</>;
  };

  const renderTipo = (item) => {
    return <>{item.tipoItemCesta?.descricao}</>;
  };

  const renderArea = (item) => {
    return <>{item.item?.area}</>;
  };

  const renderTags = (item) => {
    return item?.item?.tags?.map((x) => (
      <Badge pill className="text-white" style={{ background: x.cor }}>
        {x.descricao}
      </Badge>
    ));
  };

  return (
    <>
      <ListCommon
        local
        showTitle
        title={intl.formatMessage({ id: 'label.itens' })}
        dataSource={itens}
        actionsWidth={30}
        afterSave={handleSaveItem}
        handleRemove={handleRemove}
        showNew={allowEdit}
        sortDragEnable={!disableDrag}
        changeOrderList={reorderList}
        tag={COLUNAS.CESTA_INDICADOR_ITENS}
        editComponent={FormEdit}
        showPage={false}
        showSearch={false}
        showMenu={true}
        showIconDraggable={!disableDrag}
        showEdit={allowEdit}
        showDelete={true}
        createChildItems={false}
        showExport={false}
        parameters={parameters}
      >
        <ListColumn headerText="" visible={false} valueField="id"></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'idIntegracao' })}
          valueField="idIntegracao"
        ></ListColumn>
        <ListColumn
          headerText={labelItem}
          valueField="nome"
          required
        ></ListColumn>
        <ListColumn
          headerText={resources.peso}
          resource="resources.peso"
          valueFunction={renderPeso}
          valueField="peso"
          required
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'label.valorAplicado' })}
          valueFunction={renderValorAplicado}
          valueField="valorAplicado"
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'tipo' })}
          valueFunction={renderTipo}
          valueField="tipo"
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'tags' },
            { tags: resources.tags }
          )}
          valueField="tags"
          valueFunction={renderTags}
        />
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'area' }, { area: resources.area })
          )}
          valueField="area"
          valueFunction={renderArea}
        ></ListColumn>
      </ListCommon>
      {itens?.length > 0 && renderTotal()}
    </>    
  );
};

export default List;
