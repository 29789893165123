import React, { useCallback } from 'react';
import { useEffect, useRef, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import ReactGA from 'react-ga4';

import Dialog from 'src/componentes/dialog';
import Input from 'src/componentes/input';
import MdiIcon from 'src/componentes/mdi-icon';
import Avatar from 'src/componentes/avatar';
import Confirm from 'src/componentes/NewConfirm';
import ContentManager from 'src/componentes/content-manager';
import { isNullOrWhiteSpace } from 'src/utils/string';
import css from 'src/utils/css';
import guid from 'src/utils/guid';
import { getSession } from 'src/contexts/AuthContext';
import FeedbackSophie from './FeedbackSophie';
import http from 'src/services/httpService';

const botMessageBody = {
  isUser: false,
  isVisible: false,
  text: '',
};

const botHistoryMessage = {
  role: 'assistant',
  content: '',
};

const classes = css`
  .dialogIA {
    z-index: 999;
  }
`;

let observer = null;

const AssistenteVirtual = ({
  user,
  itemId,
  prompt,
  title,
  questionBold,
  actionsToolBar = [],
  onClickButtonCustom,
  actions,
  customContent,
  handleEditButton,
  closeCallEdit = false,
  sendBack = false,
  interactionType,
  dontShowFeedbackSophie = false,
  ...rest
}) => {
  const ref = useRef();
  const intl = useIntl();

  const [endStream, setEndStream] = useState(null);
  const [customObject, setCustomObject] = useState({});
  const requestId = useMemo(() => guid(), [interactionType, user]);

  const [openCustomContent, setOpenCustomContent] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [infoPromptLoaded, setInfoPromptLoaded] = useState(false);

  const [newMessage, setNewMessage] = useState('');
  const [listMessages, setListMessages] = useState([
    {
      ...botMessageBody,
    },
  ]);
  const [listHistoryContext, setListHistoryContext] = useState([
    {
      role: 'system',
      content: '',
    },
    { role: 'user', content: prompt },
    { ...botHistoryMessage },
  ]);

  const fetchInfo = useCallback(async () => {
    const infoPrompt = await http.post(
      `/ConfiguracaoConsultoraVirtual/ObterPromptSistema`
    );

    setListHistoryContext((prev) => [
      { role: 'system', content: infoPrompt?.data },
      { role: 'user', content: prompt },
      { ...botHistoryMessage },
    ]);

    setInfoPromptLoaded(true);
  }, []);

  useEffect(() => {
    ReactGA.event('assistente_virtual', {
      category: 'sophie_gpt',
      action: interactionType,
      label: user?.nome,
    });

    fetchInfo();
  }, [fetchInfo]);

  useEffect(() => {
    if (infoPromptLoaded) {
      const lastBotMessage = listMessages[listMessages.length - 1];
      const { isVisible, isUser } = lastBotMessage || {};

      if (!isUser && !isVisible) handleGetBotMessage();

      handleScrollBottom();
    }
  }, [listMessages, infoPromptLoaded]);

  const handleAddObserver = () => {
    const body = document.body;
    observer = new MutationObserver(() => {
      const isDrawerOpen = document.body.classList.contains('drawer-open');
      setIsDrawerOpen(isDrawerOpen);
    });

    observer.observe(body, {
      attributes: true,
    });
  };

  const handleRemoveObserver = () => {
    observer && observer.disconnect();
    observer = null;
  };

  useEffect(() => {
    handleAddObserver();

    return () => {
      handleRemoveObserver();
    };
  }, []);

  const handleSetCustomObject = (field, value) => {
    setCustomObject((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditButtonCustom = () => {
    handleClose();
    handleEditButton &&
      handleEditButton({
        listHistoryContext: listHistoryContext,
        itemId: itemId,
      });
  };

  const setLastBotMessage = (newText) => {
    setListMessages((prev) => {
      const index = listMessages.length - 1;
      const newList = [...prev];

      const lastBotMessage = newList[index];
      lastBotMessage.isVisible = true;
      lastBotMessage.text += newText;

      return newList;
    });

    setListHistoryContext((prev) => {
      const index = listHistoryContext.length - 1;
      const newList = [...prev];

      const lastBotHistoryMessage = newList[index];
      lastBotHistoryMessage.content += newText;

      return newList;
    });
  };

  const handleClose = () => {
    if (ref.current) ref.current.close();

    !dontShowFeedbackSophie && colectFeedback();
  };

  const confirmClose = () => {
    ContentManager.addContent(
      <Confirm
        cancelColor="primary"
        showCancel={true}
        labelConfirm={intl.formatMessage({ id: 'label.fechar' })}
        primaryMessage={intl.formatMessage({
          id: 'voceEstaFechandoModalSophie',
        })}
        secondaryMessage={intl.formatMessage({
          id: 'historicoSophieSeraPerdido',
        })}
        emphasysMessage={intl.formatMessage({
          id: 'desejaFechaloMesmoAssim',
        })}
        onSaved={handleClose}
      />
    );
  };

  const colectFeedback = () => {
    ContentManager.addContent(
      <FeedbackSophie
        requestId={requestId}
        subdomain={window?.location?.hostname.split('.')[0] || null}
        userId={user?.id}
        interactionType={interactionType}
      />
    );
  };

  const handleGetBotMessage = async () => {
    setNewMessage('');
    const { accessToken } = getSession();

    const response = await fetch(
      process.env.REACT_APP_API_OPENAI + '/api/open-ai',
      {
        method: 'POST',
        body: JSON.stringify({
          messages: listHistoryContext,
          userId: user?.id,
          interactionType,
          requestId,
          subdomain: window?.location?.hostname.split('.')[0] || null,
        }),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.ok) {
      try {
        const data = response.body;
        if (!data) {
          return;
        }
        const reader = data.getReader();
        const decoder = new TextDecoder();
        while (true) {
          const { value, done } = await reader.read();
          const chunkValue = decoder.decode(value);

          setLastBotMessage(chunkValue);
          if (done) {
            setEndStream(true);
            break;
          }
        }
      } catch (err) {
        //error = 'Looks like OpenAI timed out :(';
      }
    } else {
      //error = await response.text();
    }
  };

  const handleScrollBottom = () => {
    const element = document.getElementsByClassName('chat-ia-container')[0];
    if (!element) return;

    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth',
    });
  };

  const renderQuestion = (message, index) => {
    const bgClass = message.isUser ? 'bg-white' : 'bg-primary-light';
    const splitMessage = message.text?.split('\n');

    return (
      <div key={`Q-${index}`} className={`d-flex py-3 px-6 ${bgClass}`}>
        {message.isUser ? (
          <Avatar
            style={{ marginRight: 15 }}
            size={32}
            label={user?.nome}
            foto={user?.fotoArquivo}
            showLabel={false}
          />
        ) : (
          <div
            className="d-flex justify-content-center align-items-center bg-white rounded-circle p-1 border"
            style={{
              marginRight: 18,
              minHeight: 30,
              height: 30,
              minWidth: 30,
              width: 30,
            }}
          >
            <img
              alt="IA logo"
              src="/ia/ia_verde.svg"
              style={{ width: 18, height: 18 }}
            />
          </div>
        )}
        <div style={{ marginTop: 4 }}>
          {splitMessage?.map((text, index) => (
            <div
              key={`M-${index}`}
              style={{ marginBottom: index > 0 ? 10 : 0 }}
            >
              {text}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleChangeNewMessage = (value) => {
    setNewMessage(value);
  };

  const handleSendUserMessage = () => {
    if (isNullOrWhiteSpace(newMessage)) return;

    setEndStream(false);

    setListHistoryContext((prev) => [
      ...prev,
      { role: 'user', content: newMessage },
      { ...botHistoryMessage },
    ]);

    setListMessages((prev) => [
      ...prev,
      {
        isUser: true,
        isVisible: true,
        text: newMessage,
      },
      {
        ...botMessageBody,
      },
    ]);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      handleSendUserMessage();
    }
  };

  return (
    <Dialog
      className={sendBack ? classes.dialogIA : ''}
      width={900}
      height={'100%'}
      onRequestClose={confirmClose}
      mouseDownClose={true}
      respeitarAltura
      ref={ref}
      {...rest}
    >
      <div className="d-flex justify-content-center align-items-center mb-4 bg-primary-light px-6 py-5 text-xl w-100">
        <img alt="IA logo" src="/ia/ia_grande.svg" />
        <div className="ml-4">
          <div className="mb-2">{title}</div>
          {questionBold ? (
            <span className="font-weight-bolder">{questionBold}</span>
          ) : (
            <div>
              <span className="font-weight-bolder">
                {intl.formatMessage({ id: 'vamosComecar' })}{' '}
              </span>{' '}
              {intl.formatMessage({ id: 'gostariaFazerAlgumasPerguntas' })}
            </div>
          )}
        </div>
      </div>
      <div
        className="d-flex flex-column"
        style={{ height: 'calc(100% - 250px)' }}
      >
        {endStream == null && !listMessages?.some((msg) => msg.text !== '') ? (
          <div className="d-flex w-100 h-100 justify-content-center align-items-center px-7">
            <LinearProgress
              // className="bg-primary"
              style={{ width: '50%' }}
            />
          </div>
        ) : (
          <div
            className="new-list-tree overflow-auto chat-ia-container"
            style={{ flexGrow: 1 }}
          >
            {listMessages?.map((msg, index) =>
              msg.isVisible ? renderQuestion(msg, index) : ''
            )}
          </div>
        )}
        <div className="py-4 px-6">
          <div className="d-flex align-items-center">
            <div
              className="w-100 mr-4"
              onKeyDown={(event) => handleKeyDown(event)}
            >
              <Input
                disabled={!endStream || isDrawerOpen}
                formStyle={{ width: '100%' }}
                style={{ padding: 25 }}
                type="textarea"
                rows={2}
                maxLength={1000}
                placeholder={intl.formatMessage({
                  id: 'digiteNovasInstrucoesAqui',
                })}
                model={{
                  value: newMessage,
                  requestChange: (value) => handleChangeNewMessage(value),
                }}
              />
            </div>
            {endStream != null &&
              (endStream ? (
                <MdiIcon
                  onClick={() => endStream && handleSendUserMessage()}
                  style={{ marginTop: -14 }}
                  size={24}
                  className="text-primary cursor-pointer"
                  icon="send"
                />
              ) : (
                <CircularProgress
                  style={{ marginTop: -15 }}
                  size={22}
                  className="text-primary"
                />
              ))}
          </div>

          <div className="d-flex align-items-center justify-content-center mb-2 mt-n2">
            <span className="text-muted" style={{ fontSize: '0.75rem' }}>
              {intl.formatMessage({ id: 'avisoSophie' })}
            </span>
          </div>
          <div className="pull-right">
            {actionsToolBar &&
              !closeCallEdit &&
              actionsToolBar.map((a, index) =>
                React.cloneElement(a, {
                  key: index + 4,
                })
              )}
            {actionsToolBar &&
              closeCallEdit &&
              actionsToolBar.map((a, index) =>
                React.cloneElement(a, {
                  key: index + 4,
                  onClick: handleEditButtonCustom,
                })
              )}
          </div>
          {actions && actions(openCustomContent, setOpenCustomContent)}
          {openCustomContent &&
            customContent &&
            customContent(customObject, handleSetCustomObject)}
        </div>
      </div>
    </Dialog>
  );
};

export default AssistenteVirtual;
