import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  area: Schema.string().label(''),
  areasSubordinadas: Schema.string().label(''),
  tipoFiltroData: Schema.string().label(
    <FormattedMessage id="label.tipoFiltroData" />
  ),
  dataInicio: Schema.string().label(<FormattedMessage id="dataInicio" />),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
  statusRelatorioAcompanhamento: Schema.string().label(
    <FormattedMessage id="status" />
  ),
  itens: Schema.object().label(<FormattedMessage id="label.itens" />),
  desdobramento: Schema.object().label(<FormattedMessage id="desdobramento" />),
  responsavel: Schema.string().label().required(),
  classificacao: Schema.object().label(
    <FormattedMessage id="label.classificacao" />
  ),
  farol: Schema.string().label(<FormattedMessage id="label.farolDoPeriodo" />),
  farolAcumulado: Schema.string().label(
    <FormattedMessage id="farolAcumulado" />
  ),
  areas: Schema.string().label(),
});
