import React from 'react';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';

import http from 'src/services/httpService';
import { Form, Schema } from 'src/utils/form';
import Button from 'src/componentes/button';
import IconDialog from 'src/componentes/icon-dialog';
import LoadingContainer from 'src/componentes/loading-container';
import errorHandler from 'src/utils/error-handler';
import Checkbox from 'src/componentes/checkbox';
import MdiIcon from 'src/componentes/mdi-icon';

let schema = Schema.object({
  restricoes: Schema.array(),
  podeExcluir: Schema.string(),
});

@autobind
class CriticalValidacaoRestricao extends React.Component {
  static defaultProps = {
    isEdit: false,
  };
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showModal: true,
      checked: false,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  changeValues(field, value) {
    this.setState({
      [field]: value,
    });
  }

  componentDidMount() {
    if (this.props.url) {
      this.setState({ isLoading: true });

      const parametros = this.props.validarAlteracao
        ? { id: this.props.item.id, alteracao: true }
        : { id: this.props.item.id };
      http
        .post(this.props.url, parametros)
        .then((response) => {
          this.form.setValue(null, response.data);
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ isLoading: false });
        });
    }
    this.setState({ isLoading: false });
  }

  handleClose() {
    this.refs.dialog.close();
  }

  async handleSalvarClick() {
    if (this.props.urlExclusao) {
      this.setState({ isLoading: true });
      await http
        .post(this.props.urlExclusao, {
          id: this.props.item.id,
        })
        .then(() => {
          this.setState({ isLoading: false });
          this.props.afterRemove && this.props.afterRemove(this.props.item);
          this.props.onClose && this.props.onClose(true);
          this.handleClose(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ isLoading: false });
        });
    }
    this.setState({ isLoading: false });
    this.props.atualizar && this.props.atualizar(this.handleClose());
    if (this.props.urlList) {
      window.location.href = this.props.urlList;
    }
  }

  TitleIcon = () => (
    <MdiIcon
      style={{
        backgroundColor: '#FFEBEB',
        height: 40,
        width: 40,
        padding: '10px',
      }}
      icon="alert-outline"
      className="text-danger rounded-circle d-flex justify-content-center align-items-center mt-2 ml-2"
      size={20}
    />
  );

  render() {
    const actions = [
      <Button key={1} onClick={this.handleClose} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>,
      <Button
        disabled={!this.state.checked}
        color="danger"
        key={2}
        bsStyle="primary"
        className="ml-2"
        onClick={this.handleSalvarClick}
      >
        {this.props.validarAlteracao ? (
          <FormattedMessage id="label.confirmar" />
        ) : (
          <FormattedMessage id="label.confirmarExclusao" />
        )}
      </Button>,
    ];

    return (
      <IconDialog
        ref="dialog"
        title={
          this.props.validarAlteracao ? (
            <FormattedMessage id="alterar" />
          ) : (
            <FormattedMessage id="excluir" />
          )
        }
        titleColor="text-danger"
        actions={this.form.getModel('podeExcluir').value && actions}
        width={700}
        minHeight={500}
        padContent
        onRequestClose={this.handleClose}
        mouseDownClose={false}
        TitleIcon={this.TitleIcon}
        {...this.props}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <Row>
            <Col>
              {this.form.getModel('restricoes').value &&
                this.form
                  .getModel('restricoes')
                  .value.map((restricao, index) => (
                    <Card key={index} style={{ marginBottom: 5 }}>
                      <CardHeader>{restricao.restricaoDescricao}</CardHeader>
                      <CardBody>
                        <ListGroup key={index} flush>
                          {restricao.itensDescricao &&
                            restricao.itensDescricao.map((item, itemIndex) => (
                              <ListGroupItem
                                style={{ cursor: 'default' }}
                                key={itemIndex}
                              >
                                {item}
                              </ListGroupItem>
                            ))}
                        </ListGroup>
                      </CardBody>
                    </Card>
                  ))}
            </Col>
          </Row>

          {this.form.getModel('restricoes').value &&
            this.form.getModel('podeExcluir').value && (
              <Checkbox
                className="mb-2 mt-3"
                model={{
                  label: this.props.intl.formatMessage({
                    id: 'cienteAcaoIrreversivel',
                  }),
                  value: this.state.checked,
                  requestChange: this.changeValues.bind(this, 'checked'),
                }}
              />
            )}
        </LoadingContainer>
      </IconDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CriticalValidacaoRestricao));
