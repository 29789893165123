import React from 'react';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

import css from '../../../../utils/css';
import formatarData from '../../../../utils/formatarData';
import { capitalizeFirstLetter } from '../../../../utils/string';
import SvgIcon from '../../../../componentes/svg-icon';
import ArquivoUploadAnexos from '../../../../componentes/arquivo-upload/anexos';
import Avatar from '../../../../componentes/avatar';
import MdiIcon from '../../../../componentes/mdi-icon';

const classes = css`
  .root {
    overflow-x: auto;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    background: #fff;
    margin: 5px 0;
    padding: 10px;
    padding-top: 5px;
    border-radius: 5px;
    cursor: grab;
    text-align: left !important;
    &:hover {
      background: #f3f3f3;
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.5);
    }
  }
  .rootFantasma {
    text-align: left !important;
    background: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    cursor: grab;
    border: 1px dashed #5d9cec;
    opacity: 0.6;
  }
  .isDragging {
    width: 115%;
    background: #f3f3f3;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.5);
  }
  .descricao {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #5d9cec;
    margin: 5px 0;
  }
  .tooltip {
    max-width: 25%;
    max-height: 25%;
    overflow-y: auto;
    pointer-events: auto !important;
  }
  .tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
`;

class TaskItem extends React.Component {
  static defaultProps = {
    ehFantasma: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      columnsCard: this.props.columnsCard,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.columnsCard !== this.props.columnsCard) {
      this.setState({
        columnsCard: newProps.columnsCard,
      });
    }
  }

  renderContent(task) {
    return (
      <div>
        <div
          style={{
            maxHeight: 30,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 5,
          }}
        >
          <div style={{ fontSize: 12 }}>
            {this.findField('tipoOrigem') &&
              capitalizeFirstLetter(this.props.resources[task.tipoOrigem])}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {this.findField('checklist') && task.tarefas && (
              <div
                style={{ height: 26, display: 'flex', alignItems: 'center' }}
                onClick={() =>
                  this.props.handleDescricaoClick &&
                  this.props.handleDescricaoClick(task)
                }
              >
                <MdiIcon
                  style={{ marginTop: 3, marginRight: 5 }}
                  icon="checkbox-marked-outline"
                />
                {task.tarefas &&
                  task.tarefas.filter((a) => a.concluida == true).length}
                /{task.tarefas && task.tarefas.length}
              </div>
            )}
            {this.findField('arquivos') &&
              task.arquivos &&
              task.arquivos.length > 0 && (
                <div
                  style={{ height: 26 }}
                  onClick={() =>
                    this.props.handleDescricaoClick &&
                    this.props.handleDescricaoClick(task, 1)
                  }
                >
                  <ArquivoUploadAnexos
                    model={{ value: task.arquivos }}
                    disabledClick={true}
                  />
                </div>
              )}
            {this.findField('farol') && task.farolAcao && (
              <div
                data-toggle="tooltip"
                data-placement="bottom"
                title={task.farolAcao && task.farolAcao.descricao}
              >
                <span
                  title={task.farolAcao && task.farolAcao.descricao}
                  className="list-title-item list-item-comum"
                >
                  <SvgIcon
                    style={{ marginLeft: 8, marginTop: 4 }}
                    icon={task.farolAcao.icone}
                    color={task.farolAcao.cor}
                    colorHover={task.farolAcao.cor}
                    size={16}
                    title={task.farolAcao.descricao}
                  ></SvgIcon>
                </span>
              </div>
            )}
            {this.findField('responsavel') && (
              <div
                data-toggle="tooltip"
                data-placement="bottom"
                title={task.responsavel && task.responsavel.nome}
                className="avatar-icon mt-2"
                style={{ marginLeft: 8 }}
              >
                <Avatar
                  foto={task && task.responsavelFoto}
                  semEstilo={true}
                  border={1}
                  showLabel={false}
                  size={30}
                  label={task.responsavel && task.responsavel.nome}
                  style={{ marginTop: 20 }}
                ></Avatar>
                <br></br>
              </div>
            )}
          </div>
        </div>
        {this.findField('descricao') && (
          <div style={{ marginBottom: 5 }} className={classes.descricao}>
            <div>
              <a
                data-toggle="tooltip"
                data-placement="bottom"
                title={task.descricao}
                style={{ fontWeight: 'bold', cursor: 'grab' }}
              >
                {task.descricao}
              </a>
            </div>
          </div>
        )}
        {this.findField('nomeItem') && task.nomeItem && (
          <div style={{ marginBottom: 5, fontSize: 12 }}>
            <strong>{this.props.intl.formatMessage({ id: 'item' })}:</strong>
            <span style={{ marginLeft: 5 }}>{task.nomeItem}</span>
          </div>
        )}
        {this.findField('tags') && task.tags?.length > 0 && (
          <div className="d-flex flex-wrap">
            {task.tags?.map((t, index) => (
              <div
                data-tip
                data-for={`tag-${index}`}
                className="mb-1 mr-1 d-inline-flex"
              >
                <Badge
                  pill
                  className="text-white text-sm"
                  style={{ background: t?.cor }}
                >
                  <div className="text-truncate" style={{ maxWidth: 120 }}>
                    {t?.descricao}
                  </div>
                </Badge>
                <ReactTooltip
                  id={`tag-${index}`}
                  aria-haspopup="true"
                  place="right"
                >
                  {t?.descricao}
                </ReactTooltip>
              </div>
            ))}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
          }}
        >
          {this.findField('dataInicioPrevista') && (
            <div style={{ fontSize: 12 }}>
              {this.props.intl.formatMessage({ id: 'inicioPrevisto' })}
              <br></br>
              <span>
                {formatarData(this.props.user.idioma, task.dataInicioPrevista)}
              </span>
            </div>
          )}
          {this.findField('dataTerminoPrevista') && (
            <div style={{ fontSize: 12 }}>
              {this.props.intl.formatMessage({ id: 'terminoPrevisto' })}
              <br></br>
              <span>
                {formatarData(this.props.user.idioma, task.dataTerminoPrevista)}
              </span>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {this.findField('ganhosMeta') && (
            <div style={{ fontSize: 12 }}>
              {this.props.intl.formatMessage(
                { id: 'ganhosMeta' },
                {
                  meta: this.props.resources.meta,
                  acao: this.props.resources.acao,
                }
              )}
              <br></br>
              <span>{task.ganhosMeta}</span>
            </div>
          )}
          {this.findField('ganhosRealizado') && (
            <div style={{ fontSize: 12 }}>
              {this.props.intl.formatMessage(
                { id: 'ganhosRealizado' },
                {
                  meta: this.props.resources.realizado,
                  acao: this.props.resources.acao,
                }
              )}
              <br></br>
              <span>{task.ganhosRealizado}</span>
            </div>
          )}
        </div>
        {this.findField('pontosProblematicos') &&
          task.pontosProblematicos?.length > 0 && (
            <div style={{ fontSize: 12 }}>
              {capitalizeFirstLetter(this.props.resources.pontosproblematicos)}

              <MdiIcon
                className="ml-1"
                color="#ccc"
                colorHover="#ccc"
                icon="information-outline"
                data-tip
                data-for={`infoPontosProblematicos-${task.id}-${task?.item?.id}`}
              />

              <ReactTooltip
                id={`infoPontosProblematicos-${task.id}-${task?.item?.id}`}
                className={classes.tooltip}
                aria-haspopup="true"
                place="right"
                multiline
                delayHide={250}
                overridePosition={({ left, top }, _e, _t, node) => {
                  return {
                    top,
                    left: typeof node === 'string' ? left : Math.max(left, 0),
                  };
                }}
              >
                <p style={{ fontSize: 14 }}>
                  {task.pontosProblematicos
                    ?.map((e) => e.descricao)
                    ?.join('; ')}
                </p>
              </ReactTooltip>
            </div>
          )}
      </div>
    );
  }

  findField(valueField) {
    let index =
      this.state.columnsCard &&
      this.state.columnsCard.findIndex((e) => e.valueField === valueField);
    return index !== -1;
  }

  render() {
    let { task, provided, snapshot, className, style, ehFantasma, ...rest } =
      this.props;
    let providedStyles = provided
      ? snapshot.isDragging
        ? provided.draggableProps && provided.draggableProps.style
        : {}
      : {};

    return ehFantasma ? (
      <div className={classes.rootFantasma}>{this.renderContent(task)}</div>
    ) : (
      <div
        {...rest}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{ ...style, ...providedStyles }}
      >
        <div
          id={`id${task.id}`}
          onClick={() =>
            this.props.handleDescricaoClick &&
            this.props.handleDescricaoClick(task)
          }
          className={clsx(
            classes.root,
            {
              [classes.isDragging]: snapshot.isDragging,
            },
            className
          )}
        >
          {this.renderContent(task)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    user: state.user,
  };
}
export default injectIntl(connect(mapStateToProps)(TaskItem));
