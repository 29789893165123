import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import CopiarLayout from '../widget-custom/componentes/copiar-layout';
import history from '../../history';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import { capitalizeFirstLetter } from 'src/utils/string';
import Content from 'src/componentes/pages/content';
import MdiIcon from 'src/componentes/mdi-icon';
import ContentManager from 'src/componentes/content-manager';
import { setPresentation } from 'src/actions/presentation';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import Alert from 'src/componentes/message-box/alert';
import SearchSchema from './search-schema';
import Search from './search';
import { Schema } from 'src/utils/form';

@autobind
class Apresentacao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      atualizarTela: false,
      idApresentacao: null,
    };
  }

  handleNomeClick(item) {
    history.push({
      pathname: '/acompanhar/apresentacao',
      search: `?id=${item.id}&ehGestaoAVista=${false}`,
    });
  }

  renderNome(item) {
    return (
      <a tabIndex="-1" onClick={this.handleNomeClick.bind(this, item)}>
        <MdiIcon icon="play" /> {item.nome}
      </a>
    );
  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/Apresentacao/ValidarRestricoes"
        urlExclusao="/Apresentacao/Remove"
        item={item}
        onClose={this.handleClose}
        atualizar={this.atualizarTela}
      />
    );
  }

  async atualizarTela() {
    await this.setState({
      atualizarTela: !this.state.atualizarTela,
    });
  }

  handleExport(apresentacao) {
    apresentacao && this.props.setPresentation(apresentacao.id);
    ContentManager.addContent(<Alert message={this.props.intl.formatMessage({ id: 'downloadEmBreve' })} />);
    http
      .post(
        `/Apresentacao/GerarRelatorio`,
        {
          id: apresentacao.id,
          orientacaoId: 1,
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        DownloadFile(response);
      })
      .catch((error) => {
        errorHandler(ParseError(error));
      });
  }

  renderStatus(item) {
    if (!item.status) return;

    if (item.status.id == 1) return <Badge color="success">{item.status.descricao}</Badge>;
    else return <Badge color="secondary">{item.status.descricao}</Badge>;
  }

  handleAtivarInativarClick(item) {
    http
      .post(`/Apresentacao/AtivarInativarApresentacao`, {
        idApresentacao: item.id,
        ativar: item.status.id === 0,
      })
      .then(() => {
        this.atualizarTela();
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleCopiarLayoutClick(item) {
    this.setState({ idApresentacao: item.id });
    const schema = Schema.object({
      descricao: Schema.string()
        .label(<FormattedMessage id="descricao" />)
        .required(),
      area: Schema.string().label().required(),
    });
    ContentManager.addContent(<CopiarLayout item={item} schema={schema} salvarLayout={this.handleSalvarCopiaLayoutClick} />);
  }

  handleSalvarCopiaLayoutClick(model) {
    http
      .post(`/Apresentacao/SalvarCopiaLayout`, {
        id: this.state.idApresentacao,
        nome: model.descricao,
        idArea: model.area && model.area.id,
      })
      .then(() => {
        this.atualizarTela();
        ContentManager.addContent(<Alert title={<FormattedMessage id="sucesso" />} message={<FormattedMessage id="label.registroCopiadoComSucesso" />} />);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  render() {
    let { resources, intl, showModal } = this.props;
    let createChildItems = [
      {
        label: <FormattedMessage id="label.apresentar" />,
        icon: 'play',
        visible: () => true,
        onClick: this.handleNomeClick,
      },
      {
        label: <FormattedMessage id="exportar" />,
        icon: 'file-chart',
        visible: () => true,
        onClick: this.handleExport,
        id: 'exportar',
      },
      {
        label: <FormattedMessage id="label.ativar" />,
        icon: 'check-circle-outline',
        visible: (item) => item && item.status && item.status.id === 0,
        onClick: this.handleAtivarInativarClick,
      },
      {
        label: <FormattedMessage id="label.inativar" />,
        icon: 'cancel',
        visible: (item) => item && item.status && item.status.id === 1,
        onClick: this.handleAtivarInativarClick,
      },
      {
        label: <FormattedMessage id="label.copiarLayout" />,
        icon: 'content-copy',
        visible: () => true,
        onClick: this.handleCopiarLayoutClick,
      },
    ];

    return (
      <Content titulo={<FormattedMessage id="apresentacoes" />}>
        <List
          url="/Apresentacao"
          createChildItems={createChildItems}
          editMode="page"
          urlEdit="/gerenciar/apresentacao/edit"
          showSearch
          showFilter
          searchSchema={SearchSchema}
          searchComponent={<Search />}
          sort="nome"
          tag={3005}
          pageSize={20}
          filtroId={58}
          onDeleting={this.handleDeleteClick}
          saved={this.state.atualizarTela}
          stickyHeader={!showModal}
          {...this.props}
        >
          <ListColumn headerText={intl.formatMessage({ id: 'nome' })} valueFunction={this.renderNome} valueField="nome" sortField="nome" required default />
          <ListColumn headerText={capitalizeFirstLetter(resources.responsavel)} valueField="responsavel" sortField="Responsavel.Nome" default />
          <ListColumn headerText={intl.formatMessage({ id: 'status' })} valueField="status" valueFunction={this.renderStatus} sortField="status" />
          <ListColumn headerText={capitalizeFirstLetter(resources.area)} valueField="area" sortField="area" />
        </List>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps, { setPresentation })(Apresentacao));
