import brightnessByColor from 'src/utils/colors';
/**
 * Colore o container do score que aparece na matriz e define a cor do texto com base no algoritmo brightnessByColor
 * @param {int} score
 * @returns objeto com cor de background e cor do texto
 */
export const defineBgColorByScore = (grauMatrizRisco) => (score) => {
  grauMatrizRisco = grauMatrizRisco.filter((level) => level.grauRisco);

  
  const getColorObj = (cor) => ({ background: cor, color: brightnessByColor(cor) / 2 > 125 ? '#000000' /* Black */ : '#ffffff' /* White */, textAlign: 'center' });

  for (let i = 0; i < grauMatrizRisco.length; i++) {
    const {
      de,
      ate,
      grauRisco: { cor },
    } = grauMatrizRisco[i];
    if (score >= de && score < ate) return getColorObj(cor);

    if (i === 0 && score < de) return getColorObj(cor);

    if (i === grauMatrizRisco.length - 1 && score > ate) return getColorObj(cor);
  }
  return getColorObj('#fff');
};
