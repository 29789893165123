const locale = {
  DateHelper: {
    locale: 'PT',
    localeName: 'Pt',
    localeDesc: 'Portuguese',
    unitNames: [
      { single: 'Milissegundo', plural: 'Milissegundos', abbrev: 'ms' },
      { single: 'Segundo', plural: 'Segundos', abbrev: 's' },
      { single: 'Minuto', plural: 'Minutos', abbrev: 'min' },
      { single: 'Hora', plural: 'Horas', abbrev: 'h' },
      { single: 'Dia', plural: 'Dias', abbrev: 'd' },
      { single: 'Semana', plural: 'Semanas', abbrev: 'sem' },
      { single: 'Mês', plural: 'Meses', abbrev: 'mes' },
      { single: 'Trimestre', plural: 'Trimestres', abbrev: 'trim' },
      { single: 'Ano', plural: 'Anos', abbrev: 'ano' },
      { single: 'Década', plural: 'Décadas', abbrev: 'dec' },
    ],
    unitAbbreviations: [
      ['mil'],
      ['s', 'sec'],
      ['m', 'min'],
      ['h', 'hr'],
      ['d'],
      ['w', 'wk'],
      ['mo', 'mes', 'mnt'],
      ['q', 'quar', 'qrt'],
      ['y', 'yr'],
      ['dec'],
    ],
    parsers: {
      L: 'DD.MM.YYYY',
      LT: 'HH:mm',
    },
    ordinalSuffix: function (number) {
      return number;
    },
  },

  Object: {
    Yes: 'Sim',
    No: 'Não',
    Cancel: 'Cancelar',
    Ok: 'Ok',
    Save: 'Salvar',
    newEvent: 'Nova Ação',
  },

  Button: {
    'Add column': 'Adicionar coluna',
    Apply: 'Salvar',
    'Display hints': 'Exibir hints',
    'Remove column': 'Remover coluna',
  },

  Checkbox: {
    'Auto apply': 'Aplicar automaticamente',
    Automatically: 'Automaticamente',
    toggleRowSelect: 'Alternar seleção de linha',
    toggleSelection: 'Alternar toda a seleção',
  },

  CodeEditor: {
    'Code editor': 'Editor de código',
    'Download code': 'Download do código',
  },

  Combo: {
    noResults: 'Sem resultados',
    Theme: 'Tema',
    Language: 'Idioma',
    Size: 'Tamanho',
    recordNotCommitted: 'Não foi possível adicionar o registro',
    addNewValue: (value) => `Adicionar ${value}`,
  },

  Tooltip: {
    infoButton:
      'Clique aqui para obter informações e alterar o tópico ou localidade',
    codeButton: 'Clique aqui para ver o editor de código integrado',
    hintCheck:
      'Selecione esta opção para exibir dicas automaticamente quando o arquivo for carregado',
    fullscreenButton: 'Tela cheia',
  },

  Shared: {
    'Locale changed': 'Idioma alterado',
    'Full size': 'Tela cheia',
    'Phone size': 'Telefone',
  },

  UndoRedo: {
    Undo: 'Desfazer',
    Redo: 'Refazer',
    UndoLastAction: 'Desfazer última ação',
    RedoLastAction: 'Refazer a última ação desfeita',
    NoActions: 'Nenhuma ação registrada',
  },

  InactiveColumn: {
    Inactive: 'Inativa',
  },

  AddNewColumn: {
    'New Column': 'Nova coluna',
  },

  EarlyStartDateColumn: {
    'Early Start': 'Data de início prevista',
  },

  EarlyEndDateColumn: {
    'Early End': 'Data de término prevista',
  },

  LateStartDateColumn: {
    'Late Start': 'Data de início realizada',
  },

  LateEndDateColumn: {
    'Late End': 'Data de término realizada',
  },

  TotalSlackColumn: {
    'Total Slack': 'Folga total',
  },

  CalendarColumn: {
    Calendar: 'Calendário',
  },

  ConstraintDateColumn: {
    'Constraint Date': 'Data de restrição',
  },

  ConstraintTypeColumn: {
    'Constraint Type': 'Tipo de restrição',
  },

  DeadlineDateColumn: {
    Deadline: 'Data limite',
  },

  DependencyColumn: {
    'Invalid dependency': 'Dependência inválida',
  },

  DurationColumn: {
    Duration: 'Duração',
  },

  EffortColumn: {
    Effort: 'Esforço',
  },

  EndDateColumn: {
    Finish: 'Conclusão',
  },

  EventModeColumn: {
    'Event mode': 'Modo de evento',
    Manual: 'Manual',
    Auto: 'Automático',
  },

  ManuallyScheduledColumn: {
    'Manually scheduled': 'Agendado manualmente',
  },

  MilestoneColumn: {
    Milestone: 'Marco',
  },

  NameColumn: {
    Name: 'Nome',
  },

  NoteColumn: {
    Note: 'Nota',
  },

  PercentDoneColumn: {
    '% Done': '% realizado',
  },

  PredecessorColumn: {
    Predecessors: 'Predecessoras',
  },

  ResourceAssignmentColumn: {
    'Assigned Resources': 'Recursos atribuídos',
    'more resources': 'Recursos adicionais',
  },

  ResourceInfoColumn: {
    eventCountText: function (data) {
      return data + ' Evento' + (data !== 1 ? 'en' : '');
    },
  },

  RollupColumn: {
    Rollup: 'Rolar',
  },

  SchedulingModeColumn: {
    'Scheduling Mode': 'Modo',
  },

  ShowInTimelineColumn: {
    'Show in timeline': 'Exibir na linha do tempo',
  },

  SequenceColumn: {
    Sequence: '#',
  },

  StartDateColumn: {
    Start: 'Início',
  },

  SuccessorColumn: {
    Successors: 'Sucessoras',
  },

  WBSColumn: {
    WBS: 'EAP',
    renumber: 'Renumerar',
  },

  ConflictEffectDescription: {
    descriptionTpl:
      'Esta ação causa um conflito de agendamento para: {0} e {1}',
  },

  ConstraintIntervalDescription: {
    dateFormat: 'TT',
  },

  ProjectConstraintIntervalDescription: {
    startDateDescriptionTpl: 'Data de início do projeto {0}',
    endDateDescriptionTpl: 'Data de término do projeto {0}',
  },

  DependencyConstraintIntervalDescription: {
    descriptionTpl: 'Dependência ({2}) de "{3}" a "{4}"',
  },

  RemoveDependencyResolution: {
    descriptionTpl: 'Excluir dependência de "{1}" para "{2}"',
  },

  DeactivateDependencyResolution: {
    descriptionTpl: 'Desabilitar a dependência de "{1}" para "{2}"',
  },

  DateConstraintIntervalDescription: {
    startDateDescriptionTpl: 'Restrição de tarefa "{2}" {3} {0}',
    endDateDescriptionTpl: 'Restrição de tarefa "{2}" {3} {1}',
    constraintTypeTpl: {
      startnoearlierthan: 'Deve iniciar em',
      startnolaterthan: 'Deve terminar em',
      muststarton: 'Terminar não antes de',
      mustfinishon: 'Termine o mais tardar',
      finishnoearlierthan: 'Não iniciar antes de',
      finishnolaterthan: 'Começar o mais tardar',
    },
  },

  RemoveDateConstraintConflictResolution: {
    descriptionTpl: 'Remova a restrição "{1}".',
  },

  RemoveDependencyCycleEffectResolution: {
    descriptionTpl: 'Excluir dependência',
  },

  DeactivateDependencyCycleEffectResolution: {
    descriptionTpl: 'Desativar dependência',
  },

  CycleEffectDescription: {
    descriptionTpl:
      'Um ciclo de planejamento foi encontrado. As seguintes tarefas compõem o ciclo de planejamento: {0}',
  },

  EmptyCalendarEffectDescription: {
    descriptionTpl:
      '"{0}" O calendário não tem intervalos de tempo de trabalho.',
  },

  Use24hrsEmptyCalendarEffectResolution: {
    descriptionTpl: 'Utilize o calendário de 24 horas (segunda a sexta).',
  },

  Use8hrsEmptyCalendarEffectResolution: {
    descriptionTpl:
      'Use o calendário de 8 horas (segunda a sexta 08:00 - 12:00, 13:00 - 17:00).',
  },

  DependencyField: {
    invalidDependencyFormat: 'Formato de dependência inválido',
  },

  ProjectLines: {
    'Project Start': 'Início do projeto',
    'Project End': 'Término do projeto',
  },

  TaskTooltip: {
    Start: 'Início',
    End: 'Término',
    Duration: 'Duração',
    Complete: 'Conclusão',
  },

  AssignmentGrid: {
    Name: 'Nome do recurso',
    Units: 'Unidades',
    unitsTpl: function (value) {
      return value.value ? value.value + '%' : '';
    },
  },

  SchedulerProBase: {
    propagating: 'Calcular projeto',
    storePopulation: 'Os dados estão carregando',
    finalizing: 'Finalizar',
  },

  Gantt: {
    Edit: 'Editar',
    Indent: 'Rebaixar',
    Outdent: 'Promover',
    'Convert to milestone': 'Converter para marco',
    Add: 'Adicionar',
    'New task': 'Nova ação',
    'New milestone': 'Novo marco',
    'Task above': 'Ação anterior',
    'Task below': 'Ação abaixo',
    'Delete task': 'Excluir ação',
    Milestone: 'Marco',
    'Sub-task': 'Sub-ação',
    Successor: 'Sucessora',
    Predecessor: 'Predecessora',
    changeRejected: 'O Gantt rejeitou as alterações',
  },

  Indicators: {
    earlyDates: 'Início/término antecipado',
    lateDates: 'Início/término tardio',
    deadlineDate: 'Data limite',
    Start: 'Início',
    End: 'Término',
  },

  SchedulingIssueResolutionPopup: {
    'Cancel changes': 'Cancelar a alteração',
    schedulingConflict: 'Conflito de agendamento',
    emptyCalendar: 'Erro de calendário',
    cycle: 'Ciclo de planejamento',
    Apply: 'Usar',
  },

  CycleResolutionPopup: {
    dependencyLabel:
      'Selecione uma dependência para aplicar uma alteração abaixo:',
    invalidDependencyLabel:
      'Existem dependências inválidas que precisam ser corrigidas:',
  },

  DependencyType: {
    SS: 'II',
    SF: 'IT',
    FS: 'TI',
    FF: 'TT',
    StartToStart: 'Início-Início',
    StartToEnd: 'Início-Término',
    EndToStart: 'Término-Início',
    EndToEnd: 'Término-Término',
    short: ['II', 'IT', 'TI', 'TT'],
    long: [
      'Início-Início',
      'Início-Término',
      'Término-Início',
      'Término-Término',
    ],
  },

  ConstraintTypePicker: {
    none: 'Nenhum',
    muststarton: 'Terminar não antes de',
    mustfinishon: 'Terminar o mais tardar',
    startnoearlierthan: 'Deve iniciar em',
    startnolaterthan: 'Deve terminar em',
    finishnoearlierthan: 'Não iniciar antes de',
    finishnolaterthan: 'Comece o mais tardar',
  },

  CalendarField: {
    'Default calendar': 'Calendário padrão',
  },

  TaskEditorBase: {
    Information: 'Informação',
    Save: 'Salvar',
    Cancel: 'Cancelar',
    Delete: 'Apagar',
    calculateMask: 'Calcular ações',
    saveError: 'Não é possível salvar, corrija os erros primeiro',
  },

  SchedulerTaskEditor: {
    editorWidth: '30em',
  },

  GanttTaskEditor: {
    editorWidth: '54em',
  },

  SchedulerGeneralTab: {
    labelWidth: '15em',
    General: 'Geral',
    Name: 'Nome',
    Resources: 'Recursos',
    '% complete': '% realizado',
    Duration: 'Duração',
    Start: 'Início',
    Finish: 'Término',
    Preamble: 'Preâmbulo',
    Postamble: 'Postâmbulo',
  },

  SchedulerAdvancedTab: {
    labelWidth: '15em',
    Advanced: 'Avançado',
    Calendar: 'Calendário',
    'Manually scheduled': 'Programado manualmente',
    'Constraint type': 'Tipo de restrição',
    'Constraint date': 'Data de restrição',
    Inactive: 'Inativo',
  },

  GeneralTab: {
    labelWidth: '15em',
    General: 'Geral',
    Name: 'Nome',
    '% complete': '% realizado',
    Duration: 'Duração',
    Start: 'Início',
    Finish: 'Término',
    Effort: 'Esforço',
    Dates: 'Datas',
  },

  AdvancedTab: {
    labelWidth: '15em',
    Advanced: 'Avançado',
    Calendar: 'Calendário',
    'Scheduling mode': 'Modo de planejamento',
    'Effort driven': 'Medição de esforço',
    'Manually scheduled': 'Programado manualmente',
    'Constraint type': 'Tipo de restrição',
    'Constraint date': 'Data de restrição',
    Constraint: 'Restrição',
    Rollup: 'Rolar',
    Inactive: 'Inativo',
  },

  DependencyTab: {
    Predecessors: 'Predecessora',
    Successors: 'Sucessora',
    ID: 'ID',
    Name: 'Nome',
    Type: 'Tipo',
    Lag: 'Latência',
    cyclicDependency: 'A dependência cíclica foi reconhecida',
    invalidDependency: 'Dependência inválida',
  },

  ResourcesTab: {
    Resources: 'Recursos',
    Resource: 'Recurso',
    Units: 'Unidades',
    unitsTpl: function (value) {
      return value.value ? value.value + '%' : '';
    },
  },

  NotesTab: {
    Notes: 'Notas',
  },

  SchedulingModePicker: {
    Normal: 'Normal',
    'Fixed Duration': 'Duração Fixa',
    'Fixed Units': 'Unidades Fixas',
    'Fixed Effort': 'Esforço Fixo',
  },

  ResourceHistogram: {
    barTipInRange:
      '<b>{resource}</b> {startDate} - {endDate}<br>{allocated} a partir de {available} acessível',
    barTipOnDate:
      '<b>{resource}</b> am {startDate}<br>{allocated} a partir de {available} acessível',
    groupBarTipAssignment:
      '<b>{resource}</b> - <span class="{cls}">{allocated} a partir de {available}</span>',
    groupBarTipInRange:
      '{startDate} - {endDate}<br><span class="{cls}">{allocated} a partir de {available}</span> vergeben:<br>{assignments}',
    groupBarTipOnDate:
      'Am {startDate}<br><span class="{cls}">{allocated} a partir de {available}</span> vergeben:<br>{assignments}',
    plusMore: '+{value} mais',
  },

  ResourceUtilization: {
    barTipInRange:
      '<b>{event}</b> {startDate} - {endDate}<br><span class="{cls}">{allocated}</span> acessível',
    barTipOnDate:
      '<b>{event}</b> am {startDate}<br><span class="{cls}">{allocated}</span> allocated',
    groupBarTipAssignment:
      '<b>{event}</b> - <span class="{cls}">{allocated}</span>',
    groupBarTipInRange:
      '{startDate} - {endDate}<br><span class="{cls}">{allocated} a partir de {available}</span> vergeben:<br>{assignments}',
    groupBarTipOnDate:
      'Am {startDate}<br><span class="{cls}">{allocated} a partir de {available}</span> allocated:<br>{assignments}',
    plusMore: '+{value} mais',
    nameColumnText: 'Recurso / Ação',
  },

  CrudManagerView: {
    serverResponseLabel: 'Resposta do servidor:',
  },

  ColumnPicker: {
    column: 'Coluna',
    columnsMenu: 'Colunas',
    hideColumn: 'Ocultar coluna',
    hideColumnShort: 'Ocultar',
    newColumns: 'Nova coluna',
  },

  Filter: {
    applyFilter: 'Aplicar filtros',
    filter: 'Filtro',
    editFilter: 'Editar filtros',
    on: 'Sobre',
    before: 'Antes',
    after: 'Depois',
    equals: 'Igual a',
    lessThan: 'Menor que',
    moreThan: 'Maior que',
    removeFilter: 'Remover filtros',
  },

  FilterBar: {
    enableFilterBar: 'Exibir barra de filtro',
    disableFilterBar: 'Ocultar barra de filtro',
  },

  Group: {
    group: 'Grupo',
    groupAscending: 'Grupo em ordem ascendente',
    groupDescending: 'Grupo em ordem descendente',
    groupAscendingShort: 'Ascendente',
    groupDescendingShort: 'Descendente',
    stopGrouping: 'Parar de agrupar',
    stopGroupingShort: 'Parar',
  },

  HeaderMenu: {
    moveBefore: (text) => `Siga em frente "${text}"`,
    moveAfter: (text) => `Mover para "${text}"`,
  },

  MergeCells: {
    mergeCells: 'Mesclar células',
    menuTooltip:
      'Mesclar células com o mesmo conteúdo quando classificadas por esta coluna',
  },

  Search: {
    searchForValue: 'Procurar valor',
  },

  Sort: {
    sort: 'Classificar',
    sortAscending: 'Classificação crescente',
    sortDescending: 'Classificação descendente',
    multiSort: 'Classificar vários',
    removeSorter: 'Remover classificação',
    addSortAscending: 'Adicionar classificação crescente',
    addSortDescending: 'Adicionar classificação decrescente',
    toggleSortAscending: 'Mudar para ascendente',
    toggleSortDescending: 'Mudar para descendente',
    sortAscendingShort: 'Ascendente',
    sortDescendingShort: 'Descendente',
    removeSorterShort: 'Remover',
    addSortAscendingShort: '+ Ascendente',
    addSortDescendingShort: '+ Descendente',
  },

  Summary: {
    'Summary for': function (date) {
      return 'Resumo para ' + date;
    },
  },

  TrialButton: {
    downloadTrial: 'Baixar demonstração',
  },

  TrialPanel: {
    title: 'Por favor, preencha os campos',
    name: 'Nome',
    email: 'E-mail',
    company: 'Empresa',
    product: 'Produto',
    cancel: 'Cancelar',
    submit: 'Enviar',
    downloadStarting: 'Download começando, por favor aguarde...',
  },

  RatingColumn: {
    cellLabel: (column) =>
      `${column.text} ${
        column.location?.record
          ? `Avaliação : ${column.location.record[column.field]}`
          : ''
      }`,
  },

  GridBase: {
    loadFailedMessage: 'Carregando, tente novamente',
    syncFailedMessage: 'Falha na sincronização de dados',
    unspecifiedFailure: 'Erro não especificado',
    networkFailure: 'Erro de rede',
    parseFailure: 'A resposta do servidor não pôde ser analisada',
    loadMask: 'Carregando...',
    syncMask: 'Salvando alterações, aguarde...',
    noRows: 'Nenhum registro para exibir.',
    moveColumnLeft: 'Mover para a esquerda',
    moveColumnRight: 'Mover para a direita',
    moveColumnTo: function (region) {
      return 'Mover coluna para ' + region;
    },
  },

  CellMenu: {
    removeRow: 'Linha',
  },

  PdfExport: {
    'Waiting for response from server': 'Aguardando resposta do servidor...',
    'Export failed': 'Falha na exportação',
    'Server error': 'Erro de servidor',
    'Generating pages': 'Gerar páginas...',
    'Click to abort': 'Abortar',
  },

  ExportDialog: {
    width: '40em',
    labelWidth: '12em',
    exportSettings: 'Exportar configurações',
    export: 'Exportar',
    exporterType: 'Conferir a paginação',
    cancel: 'Cancelar',
    fileFormat: 'Formato de arquivo',
    rows: 'Linhas',
    alignRows: 'Alinhar linhas',
    columns: 'Colunas',
    paperFormat: 'Tamanho do papel',
    orientation: 'Orientação',
    repeatHeader: 'Repetir cabeçalho',
  },

  ExportRowsCombo: {
    all: 'Todas as linhas',
    visible: 'Linhas visíveis',
  },

  ExportOrientationCombo: {
    portrait: 'Retrato',
    landscape: 'Paisagem',
  },

  RowCopyPaste: {
    copyRecord: 'Copiar',
    cutRecord: 'Cortar',
    pasteRecord: 'Colar',
  },

  EventCopyPaste: {
    copyEvent: 'Copiar',
    cutEvent: 'Cortar',
    pasteEvent: 'Colar',
  },

  TaskCopyPaste: {
    copyTask: 'Copiar',
    cutTask: 'Cortar',
    pasteTask: 'Colar',
  },

  SinglePageExporter: {
    singlepage: 'Página única',
  },

  MultiPageExporter: {
    multipage: 'Múltiplas páginas',
    exportingPage: function (data) {
      return 'Exportar página ' + data.currentPage + '/' + data.totalPages;
    },
  },

  MultiPageVerticalExporter: {
    multipagevertical: 'Múltiplas páginas (vertical)',
    exportingPage: function (data) {
      return 'Exportar página ' + data.currentPage + '/' + data.totalPages;
    },
  },

  ScheduleRangeCombo: {
    completeview: 'Visão completa',
    currentview: 'Visão atual',
    daterange: 'Intervalo de datas',
    completedata: 'Visão completa (para todas as ações)',
  },

  SchedulerExportDialog: {
    'Schedule range': 'Intervalo de data',
    'Export from': 'De',
    'Export to': 'Para',
  },

  FilePicker: {
    file: 'Arquivo',
  },

  DateField: {
    invalidDate: 'Data inválida',
  },

  DatePicker: {
    gotoPrevYear: 'Ir para o ano anterior',
    gotoPrevMonth: 'Ir para o mês anterior',
    gotoNextMonth: 'Ir para o próximo mês',
    gotoNextYear: 'Ir para o próximo ano',
  },

  Field: {
    badInput: 'Valor de campo inválido',
    patternMismatch: 'O valor deve corresponder a um padrão específico',
    rangeOverflow: function (value) {
      return 'O valor deve ser maior ou igual a ' + value.max + ' ser';
    },
    rangeUnderflow: function (value) {
      return 'O valor deve ser maior ou igual a ' + value.min + ' ser';
    },
    stepMismatch: 'O valor deve corresponder ao passo',
    tooLong: 'O valor deve ser menor',
    tooShort: 'O valor deveria ser maior',
    typeMismatch: 'O valor deve estar em um formato especial',
    valueMissing: 'Este campo é obrigatório',

    invalidValue: 'Valor de campo inválido',
    minimumValueViolation: 'Violação de valor mínimo',
    maximumValueViolation: 'violação de valor máximo',
    fieldRequired: 'Este campo é obrigatório',
    validateFilter: 'O valor deve ser selecionado na lista',
  },

  List: {
    loading: 'Carregando...',
  },

  PagingToolbar: {
    firstPage: 'Ir para a primeira página',
    prevPage: 'Voltar para a página anterior',
    page: 'Página',
    nextPage: 'Ir para a página seguinte',
    lastPage: 'Ir para a última página',
    reload: 'Recarregar a página atual',
    noRecords: 'Nenhuma linha para exibir',
    pageCountTemplate(store) {
      return `a partir de ${store.lastPage}`;
    },
    summaryTemplate(store) {
      const start = (store.currentPage - 1) * store.pageSize + 1;

      return `Resultados ${start} - ${start + store.pageSize - 1} a partir de ${
        store.allCount
      }`;
    },
  },

  PanelCollapser: {
    Collapse: 'Reduzir',
    Expand: 'Expandir',
  },

  Popup: {
    close: 'Fechar modal',
  },

  NumberFormat: {
    locale: 'de',
    currency: 'EUR',
  },

  DurationField: {
    invalidUnit: 'Unidade inválida',
  },

  TimeField: {
    invalidTime: 'Especificação de hora inválida',
  },

  TimePicker: {
    hour: 'Hora',
    minute: 'Minuto',
  },

  ExcelExporter: {
    'No resource assigned': 'Nenhum recurso atribuído',
  },

  Dependencies: {
    from: 'De',
    to: 'Para',
    valid: 'Válido',
    invalid: 'Inválido',
  },

  DependencyEdit: {
    From: 'De',
    To: 'Para',
    Type: 'Tipo',
    Lag: 'Latência',
    'Edit dependency': 'Editar dependência',

    Save: 'Salvar',
    Delete: 'Apagar',
    Cancel: 'Cancelar',
    StartToStart: 'Início-Início',
    StartToEnd: 'Início-Término',
    EndToStart: 'Término-Início',
    EndToEnd: 'Término-Término',

    Active: 'Ativo',
  },

  EventEdit: {
    Name: 'Nome',
    Resource: 'Recurso',
    Start: 'Início',
    End: 'Término',
    Save: 'Salvar',
    Delete: 'Excluir',
    Cancel: 'Cancelar',
    'Edit event': 'Editar ação',
    Repeat: 'Repetir',
  },

  TaskEdit: {
    'Edit task': 'Editar ação',
    ConfirmDeletionTitle: 'Confirmar exclusão',
    ConfirmDeletionMessage: 'Deseja mesmo excluir a ação?',
  },

  SchedulerBase: {
    'Add event': 'Adicionar ação',
    'Delete event': 'Excluir ação',
    'Unassign event': 'Não atribuir ação',
  },

  EventDrag: {
    eventOverlapsExisting:
      'A ação se sobrepõe à ação existente para este recurso',
    noDropOutsideTimeline:
      'A ação não pode ser completamente excluída fora da linha do tempo',
  },

  TimeAxisHeaderMenu: {
    pickZoomLevel: 'Ampliar',
    activeDateRange: 'Intervalo de Datas',
    startText: 'Data de Início',
    endText: 'Data de Término',
    todayText: 'Hoje',
  },

  EventFilter: {
    filterEvents: 'Filtrar ações',
    byName: 'Por Nome',
  },

  TimeRanges: {
    showCurrentTimeLine: 'Mostrar linha do tempo atual',
  },

  PresetManager: {
    minuteAndHour: {
      topDateFormat: 'ddd DD.MM, HH:mm',
    },
    hourAndDay: {
      topDateFormat: 'ddd DD.MM',
    },
    weekAndDay: {
      topDateFormat: 'ddd DD.MM',
      displayDateFormat: 'HH:mm',
    },
  },

  RecurrenceConfirmationPopup: {
    'delete-title': 'Você excluiu uma ação',
    'delete-all-message': 'Deseja excluir todas as ações deste item?',
    'delete-further-message':
      'Deseja excluir esta e todas as ações futuras deste evento ou apenas a ocorrência selecionada?',
    'delete-further-btn-text': 'Excluir todas as ações futuras',
    'delete-only-this-btn-text': 'Excluir apenas esta ação',

    'update-title': 'Você está alterando uma ação repetida',
    'update-all-message': 'Deseja alterar todas as ações deste item?',
    'update-further-message':
      'Você deseja alterar apenas esta ação do item, ou esta e todas as ações futuras?',
    'update-further-btn-text': 'Todas as ações futuras',
    'update-only-this-btn-text': 'Apenas esta ação',

    Yes: 'Sim',
    Cancel: 'Cancelar',

    width: 600,
  },

  RecurrenceLegend: {
    ' and ': ' e ',
    Daily: 'Diário',
    'Weekly on {1}': function (data) {
      return 'Semanalmente em ' + data.days;
    },
    'Monthly on {1}': function (data) {
      return 'Mensalmente em ' + data.days;
    },
    'Yearly on {1} of {2}': function (data) {
      return 'Anualmente em ' + data.days + ' e ' + data.months;
    },
    'Every {0} days': function (data) {
      return 'Cada ' + data.interval + ' dias';
    },
    'Every {0} weeks on {1}': function (data) {
      return 'Cada ' + data.interval + ' semanas em ' + data.days;
    },
    'Every {0} months on {1}': function (data) {
      return 'Cada ' + data.interval + ' meses em ' + data.days;
    },
    'Every {0} years on {1} of {2}': function (data) {
      return (
        'Cada ' + data.interval + ' Anos em ' + data.days + ' de ' + data.months
      );
    },

    position1: 'primeiro',
    position2: 'segundo',
    position3: 'terceiro',
    position4: 'quarto',
    position5: 'quinto',
    'position-1': 'último',
    day: 'dia',
    weekday: 'dia da semana',
    'weekend day': 'dia de fim de semana',
    daysFormat: function (data) {
      return data.position + ' ' + data.days;
    },
  },

  RecurrenceEditor: {
    'Repeat event': 'Repetir ação',
    Cancel: 'Cancelar',
    Save: 'Salvar',
    Frequency: 'Frequência',
    Every: 'Cada',
    DAILYintervalUnit: 'Dia',
    WEEKLYintervalUnit: 'Semana',
    MONTHLYintervalUnit: 'Mês',
    YEARLYintervalUnit: 'Ano',
    Each: 'Cada',
    'On the': 'No/na',
    'End repeat': 'Fim',
    'time(s)': 'Tempo',
  },

  RecurrenceDaysCombo: {
    day: 'dia',
    weekday: 'dia da semana',
    'weekend day': 'dia de fim de semana',
  },

  RecurrencePositionsCombo: {
    position1: 'primeiro',
    position2: 'segundo',
    position3: 'terceiro',
    position4: 'quarto',
    position5: 'quinto',
    'position-1': 'último',
  },

  RecurrenceStopConditionCombo: {
    Never: 'Nunca',
    After: 'Depois',
    'On date': 'No dia',
  },

  RecurrenceFrequencyCombo: {
    Daily: 'Diariamente',
    Weekly: 'Semanalmente',
    Monthly: 'Mensalmente',
    Yearly: 'Anualmente',
  },

  RecurrenceCombo: {
    None: 'Nunca',
    Custom: 'Personalizado...',
  },

  Column: {
    columnLabel: (column) =>
      `${column.text ? `${column.text} dividir. ` : ''} para menu de contexto${
        column.sortable ? ', ENTER para classificar' : ''
      }`,
    cellLabel: '',
    Name: 'Nome',
    Age: 'Idade',
    City: 'Cidade',
    Food: 'Comida',
    Color: 'Cor',
    'First name': 'Nome',
    Surname: 'Sobrenome',
    Team: 'Equipe',
    Score: 'Resultado',
    Rank: 'Classificação',
    Percent: 'por cento',
    Birthplace: 'Naturalidade',
    Start: 'Início',
    Finish: 'Término',
    Template: 'Template',
    Date: 'Data',
    Check: 'Check',
    Contact: 'Contato',
    Favorites: 'Favoritos',
    'Customer#': 'Cliente#',
    When: 'Quando',
    Brand: 'Marca',
    Model: 'Modelo',
    'Personal best': 'Recorde pessoal',
    'Current rank': 'Classificação atual',
    Hometown: 'Cidade natal',
    Satisfaction: 'Satisfação',
    'Favorite color': 'Cor favorita',
    Rating: 'Avaliação',
    Cooks: 'Preparos',
    Birthday: 'Aniversário',
    Staff: 'Colaborador',
    Machines: 'Máquinas',
    Type: 'Tipo',
    'Task color': 'Cor da ação',
    'Employment type': 'Tipo de emprego',
    Capacity: 'Capacidade',
    'Production line': 'Linha de produção',
    Company: 'Companhia',
    End: 'Fim',
  },
};

export default locale;
