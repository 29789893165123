import React from 'react';
import { Col, Row } from 'reactstrap';
import Input from '../../../componentes/input';
import StatusLookup from '../../../lookups/status-centro-custo';
import ColaboradorSimplificadoLookup from '../../../lookups/ColaboradorSimplificadoLookup';
import ClassificacaoLookup from '../../../lookups/classificacao-mo';

export default class OrganizacaoSearch extends React.Component {
  render() {
    let { model, exibirClassificacao } = this.props;

    return (
      <Row>
        <Col md={3}>
          <Input model={model.getModel('nome')} />
        </Col>
        <Col md={2}>
          <Input model={model.getModel('idIntegracao')} />
        </Col>
        {exibirClassificacao && (
          <Col md={3}>
            <ClassificacaoLookup clearable model={model.getModel('classificacao')} />
          </Col>
        )}
        <Col md={5}>
          <ColaboradorSimplificadoLookup model={model.getModel('responsavel')} />
        </Col>
        <Col md={2}>
          <StatusLookup model={model.getModel('status')} clearable={true} />
        </Col>
      </Row>
    );
  }
}
