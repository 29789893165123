import React from 'react';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Input from 'src/componentes/input';
import Checkbox from 'src/componentes/checkbox';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import { capitalizeFirstLetter } from 'src/utils/string';
import ProcessoLookup from 'src/lookups/processo';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import ModeloRiscoLookup from 'src/lookups/modelo-risco';
import MatrizRiscoLookup from 'src/lookups/matriz-risco';
import TipoRiscoLookup from 'src/lookups/tipo-risco';
import StatusItemLookup from 'src/lookups/status-item';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import ControleLookup from 'src/lookups/ControleLookup';
import PeriodicidadeLookup from 'src/lookups/periodicidade';
import CausaRiscoLookup from 'src/lookups/causa-risco';
import ConsequenciaRiscoLookup from 'src/lookups/consequencia-risco';
import GrauRiscoLookup from 'src/lookups/grau-risco';
import SeveridadeRiscoLookup from 'src/lookups/severidade-risco';
import ProbabilidadeRiscoLookup from 'src/lookups/probabilidade-risco';
import ToleranciaRiscoLookup from 'src/lookups/ToleranciaRiscoLookup';
import ToleranciaRiscoFaixasLookup from 'src/lookups/ToleranciaRiscoFaixasLookup';
import TagLookup from 'src/lookups/TagLookup';
import TratamentoLookup from 'src/lookups/tratamento-risco';

class RiscoSearch extends React.Component {
  render() {
    const { model, resources, intl, configuracao } = this.props;
    return (
      <div>
        <Row>
          <Col md={4}>
            <Input model={model.getModel('nome')} />
          </Col>
          <Col md={4}>
            <Input model={model.getModel('idIntegracao')} />
          </Col>
          <Col md={4}>
            <ControleLookup
              multi
              clearable
              model={{
                label: capitalizeFirstLetter(resources.pontosdecontrole),
                value: model.getModel('controles').value,
                requestChange: model.getModel('controles').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <ProcessoLookup
              multi
              data={{ TipoId: 5 }}
              model={{
                label: capitalizeFirstLetter(
                  this.props.intl.formatMessage(
                    { id: 'label.processos' },
                    { processos: resources.processos }
                  )
                ),
                value: model.getModel('processos').value,
                requestChange: model.getModel('processos').requestChange,
              }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              model={{
                label: capitalizeFirstLetter(
                  this.props.intl.formatMessage(
                    { id: 'incluirSubprocessos' },
                    {
                      processosfilhosplural:
                        this.props.resources.processosfilhosplural.toLowerCase(),
                    }
                  )
                ),
                value: model.getModel('incluirSubprocessos').value,
                requestChange: model.getModel('incluirSubprocessos')
                  .requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
          <Col md={4}>
            <PeriodicidadeLookup
              clearable
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'label.periodicidadeAvaliacao' },
                    { avaliacao: resources.avaliacao }
                  )
                ),
                value: model.getModel('periodicidadeAvaliacao').value,
                requestChange: model.getModel('periodicidadeAvaliacao')
                  .requestChange,
                errors: model.getModel('periodicidadeAvaliacao').errors,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.areas),
                value: model.getModel('areas').value,
                requestChange: model.getModel('areas').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              model={{
                label: intl.formatMessage(
                  { id: 'label.considerarAreasSubordinadas' },
                  { areas: resources.areas }
                ),
                value: model.getModel('areasSubordinadas').value,
                requestChange:
                  model.getModel('areasSubordinadas').requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
          <Col md={4}>
            <ModeloRiscoLookup
              clearable
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'modelo' })
                ),
                value: model.getModel('modelo').value,
                requestChange: model.getModel('modelo').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <MatrizRiscoLookup
              clearable
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'matrizRisco' },
                    { risco: this.props.resources.risco }
                  )
                ),
                value: model.getModel('matrizRisco').value,
                requestChange: model.getModel('matrizRisco').requestChange,
              }}
            />
          </Col>
          <Col md={4}>
            <TipoRiscoLookup
              clearable
              model={{
                label: capitalizeFirstLetter(resources.tipoRisco),
                value: model.getModel('tipoRisco').value,
                requestChange: model.getModel('tipoRisco').requestChange,
              }}
            />
          </Col>
          <Col md={4}>
            <ColaboradorSimplificadoLookup
              multi
              clearable
              model={{
                label: capitalizeFirstLetter(resources.responsaveis),
                value: model.getModel('responsaveis').value,
                requestChange: model.getModel('responsaveis').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          {configuracao && configuracao.utilizarCausaRisco && (
            <Col md={4}>
              <CausaRiscoLookup
                multi
                clearable
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'labelCausasDoRisco' },
                      {
                        pontosproblematicos: resources.pontosproblematicos,
                        risco: resources.risco,
                      }
                    )
                  ),
                  value: model.getModel('causasRisco').value,
                  requestChange: model.getModel('causasRisco').requestChange,
                }}
              />
            </Col>
          )}
          {configuracao && configuracao.utilizarConsequenciaRisco && (
            <Col md={4}>
              <ConsequenciaRiscoLookup
                multi
                clearable
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'labelConsequenciasDoRisco' },
                      {
                        consequencias: resources.consequencias,
                        risco: resources.risco,
                      }
                    )
                  ),
                  value: model.getModel('consequenciasRisco').value,
                  requestChange:
                    model.getModel('consequenciasRisco').requestChange,
                }}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={4}>
            <StatusItemLookup
              clearable
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'status' })
                ),
                value: model.getModel('status').value,
                requestChange: model.getModel('status').requestChange,
              }}
              data={{ idsSelecionados: [1, 2, 3, 4, 5, 7] }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              style={{ marginTop: 35 }}
              model={{
                label: intl.formatMessage({
                  id: 'desconsiderarStatusConcluido',
                }),
                value: model.getModel('desconsiderarStatusConcluido').value,
                requestChange: model.getModel('desconsiderarStatusConcluido')
                  .requestChange,
              }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              style={{ marginTop: 35 }}
              model={{
                label: intl.formatMessage({
                  id: 'desconsiderarStatusCancelado',
                }),
                value: model.getModel('desconsiderarStatusCancelado').value,
                requestChange: model.getModel('desconsiderarStatusCancelado')
                  .requestChange,
              }}
            />
          </Col>
        </Row>
        {configuracao?.avaliarRiscoInerente && (
          <Row>
            <Col md={4}>
              <GrauRiscoLookup
                clearable
                multi
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'labelGrauRiscoInerente' },
                      { risco: resources.risco }
                    )
                  ),
                  value: model.getModel('grausRiscoInerente').value,
                  requestChange:
                    model.getModel('grausRiscoInerente').requestChange,
                }}
              />
            </Col>
            <Col md={4}>
              <SeveridadeRiscoLookup
                clearable
                multi
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'labelSeveridadeInerente' },
                      { severidade: resources.severidade }
                    )
                  ),
                  value: model.getModel('severidadeInerente').value,
                  requestChange:
                    model.getModel('severidadeInerente').requestChange,
                }}
              />
            </Col>
            <Col md={4}>
              <ProbabilidadeRiscoLookup
                clearable
                multi
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'labelProbabilidadeInerente' },
                      { probabilidade: resources.probabilidade }
                    )
                  ),
                  value: model.getModel('probabilidadeInerente').value,
                  requestChange: model.getModel('probabilidadeInerente')
                    .requestChange,
                }}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4}>
            <GrauRiscoLookup
              clearable
              multi
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'labelGrauRiscoResidual' },
                    { risco: resources.risco }
                  )
                ),
                value: model.getModel('grausRiscoResidual').value,
                requestChange:
                  model.getModel('grausRiscoResidual').requestChange,
              }}
            />
          </Col>
          <Col md={4}>
            <SeveridadeRiscoLookup
              clearable
              multi
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'labelSeveridadeResidual' },
                    { severidade: resources.severidade }
                  )
                ),
                value: model.getModel('severidadeResidual').value,
                requestChange:
                  model.getModel('severidadeResidual').requestChange,
              }}
            />
          </Col>
          <Col md={4}>
            <ProbabilidadeRiscoLookup
              clearable
              multi
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'labelProbabilidadeResidual' },
                    { probabilidade: resources.probabilidade }
                  )
                ),
                value: model.getModel('probabilidadeResidual').value,
                requestChange: model.getModel('probabilidadeResidual')
                  .requestChange,
              }}
            />
          </Col>
        </Row>
        {configuracao?.habilitarAcompanhamentoToleranciaRisco && (
          <Row>
            <Col md={4}>
              <ToleranciaRiscoLookup
                clearable
                multi
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'labelToleranciaRisco' },
                      { risco: resources.risco }
                    )
                  ),
                  value: model.getModel('toleranciaRisco').value,
                  requestChange:
                    model.getModel('toleranciaRisco').requestChange,
                }}
              />
            </Col>
            <Col md={4}>
              <ToleranciaRiscoFaixasLookup
                clearable
                multi
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'labelToleranciaRiscoFaixas' },
                      { risco: resources.risco }
                    )
                  ),
                  value: model.getModel('toleranciaRiscoFaixas').value,
                  requestChange: model.getModel('toleranciaRiscoFaixas')
                    .requestChange,
                }}
              />
            </Col>
          </Row>
        )}
        <Row>
          {configuracao && configuracao.podeVisualizarTag && (
            <Col md={4}>
              <TagLookup
                multi
                model={{
                  label: capitalizeFirstLetter(intl.formatMessage({ id: 'tags' }, { tags: resources.tags })),
                  value: model.getModel('tags').value,
                  requestChange: model.getModel('tags').requestChange,
                }}
                data={{ funcionalidadesIdSelecionaveis: [7] }}
                podeEditar={false}
              />
            </Col>
          )}
          <Col md={4}>
            <TratamentoLookup
              clearable
              multi
              model={{
                label: capitalizeFirstLetter(
                  this.props.intl.formatMessage({ id: 'tratamento' })
                ),
                value: model.getModel('tratamentoRisco').value,
                requestChange: model.getModel('tratamentoRisco').requestChange,
              }}
            />
          </Col>
        </Row>
        {model.getModel('camposCustomizados') &&
          model.getModel('camposCustomizados').value &&
          model.getModel('camposCustomizados').value.length > 0 && (
            <Row>
              <Col md={12}>
                <DesenharCampoCustomizado
                  model={model.getModel('camposCustomizados')}
                />
              </Col>
            </Row>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(RiscoSearch));
