import { useState } from 'react';

import Content from 'src/componentes/pages/content';
import Legenda from 'src/componentes/legenda/legenda-acao';
import GanttView from './View.js';
import { useHistory } from 'react-router';
import qs from 'query-string';

const Container = (props = {}) => {
  const history = useHistory();
  const { location } = history || {};
  const [urlProps] = useState({
    showBackButton: (location && qs.parse(location.search).showBackButton),
  });

  const [title, setTitle] = useState('');

  const [componentKey, setComponentKey] = useState(1);
  const [componentView, setComponentView] = useState(null);
  const [filter, setFilter] = useState({});

  const { ehApresentacao, showBackButton = false } = props;

  const handleChangeFieldFilter = (field, value) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const updateTree = () => setComponentKey((prev) => prev + 1);

  const renderContent = () => (
    <>
      <GanttView
        handleCloseModal={props.handleCloseModal}
        key={componentKey}
        filter={filter}
        handleChangeFieldFilter={handleChangeFieldFilter}
        updateTree={updateTree}
        componentView={componentView}
        setComponentView={setComponentView}
        handleChangePageTitle={setTitle}
        {...props}
      />
      <div className="d-flex justify-content-center mt-2">
        <Legenda />
      </div>
    </>
  );

  return ehApresentacao ? (
    renderContent()
  ) : (
    <Content isEllipsis titulo={title} showBackButton={showBackButton || urlProps?.showBackButton}>
      {renderContent()}
    </Content>
  );
};

export default Container;
