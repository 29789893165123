import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';

import http from 'src/services/httpService';
import ContentManager from 'src/componentes/content-manager';
import Alert from 'src/componentes/message-box/alert';
import Table from 'src/componentes/table';
import Pagination from 'src/componentes/list/pagination';
import MdiIcon from 'src/componentes/mdi-icon';
import Confirm from 'src/componentes/message-box/confirm';
import errorHandler from 'src/utils/error-handler';
import { Checkbox } from 'src/componentes/Form';
import guid from 'src/utils/guid';

const stickyDefault = {
  position: 'sticky',
  background: 'inherit',
  zIndex: 5,
};

const useStyles = makeStyles({
  cell: {
    padding: '0.3rem',
  },
  hierarchy: {
    width: 15,
    minWidth: 15,
    cursor: 'pointer',
    margin: '-3px 10px',
  },
  '.dropdown': {
    '& > .btn': {
      border: '0px',
      color: 'inherit',
      '&:hover': {
        'background-color': '#e4eaec',
        color: 'inherit',
      },
    },
  },
});

const colorIcon = '#939393';

function Results({
  resources,
  count,
  columns,
  showPagination,
  currentPage,
  pageSize,
  onChangeCurrentPage,
  onChangePageSize,
  onSortColumn,
  onSortByColumn,
  forceShowSort = false,
  sort,
  sortBy,
  data,
  url /** Caminho para Controller, com '/' no início */,
  updatedItems,
  showDelete = true,
  parameters,
  onRefresh,
  handleRemove,
  onRemove,
  handleEditClick,
  showMenu,
  expandedIds,
  isExpanded,
  handleToggleExpand,
  select = false,
  selectLimit,
  onSelect,
  onDeleting,
  responsive = true,
  sticky = false,
  selectedItems = [],
  getRowColor = () => {},
  getRowTextColor = () => {},
  renderFakeChildrenWhileLoading,
  renderContentWhileLoading,
  renderMenu: renderMenuProps,
  showSortIcon = true,
  isLoading = false,
  dragTable = false,
  showFullTextColumns = false,
  multiple = false,
  idLabelEditar,
  idLabelExcluir,
  showScrollColumn = true,
  propsPopOver = {},
}) {
  const intl = useIntl();
  const classes = useStyles();
  const [allItensSelected, setAllItensSelected] = useState(false);
  const [currentSelectedItems, setCurrentSelectedItems] = useState([
    ...(selectedItems || []),
  ]);

  useEffect(() => {
    if (select && onSelect) {
      onSelect([...(currentSelectedItems || [])]);
    }
  }, [currentSelectedItems]);

  const getCellPopoVer = (item, column) => {
    if (column.popoverFunction) {
      return column.popoverFunction(item, propsPopOver);
    }
  };

  const handleHeaderClick = (column) => {
    if (!column.sortField) return;

    const sortByKey = sortBy === 'asc' ? 'desc' : 'asc';

    onSortColumn && onSortColumn(column.sortField, sortByKey);
    onSortByColumn && onSortByColumn(sortByKey);
  };

  const getHeaderText = (column) => {
    if (column.resource) {
      if (resources) {
        return resources[column.resource];
      } else {
        return column.customHeaderText ?? column.headerText;
      }
    } else {
      return column.customHeaderText ?? column.headerText;
    }
  };

  const getCellContent = (item, column) => {
    if (column.valueFunction) {
      return column.valueFunction(item, propsPopOver);
    }

    if (column.valueField) {
      let value = item[column.valueField];

      if (column.formatFunction) {
        value = column.formatFunction(value);
      }

      if (item.campoCustomizado) {
        if (item.campoCustomizado.length > 0) {
          let campoCustomizado = item.campoCustomizado.find(
            (i) => i.nomeCampo === column.valueField
          );
          if (campoCustomizado) value = campoCustomizado.valor;
        }
      }

      return value;
    }
  };

  const isUpdated = (item) => {
    return updatedItems.find((i) => i.id === item.id);
  };

  const handleDeleteClick = (item, index) => {
    if (onDeleting) {
      onDeleting(item);
    } else {
      ContentManager.addContent(
        <Confirm
          message={
            <FormattedMessage id="label.desejaMesmoExcluirEsseRegistro" />
          }
          handleConfirm={() => handleDeleteClickConfirm(item, index)}
        />
      );
    }
  };

  const handleDeleteClickConfirm = async (item, index) => {
    if (handleRemove) handleRemove && handleRemove(item, index);
    else
      try {
        await http.post(`${url}/Remove`, { id: item.id, parameters });
        ContentManager.addContent(
          <Alert
            title={<FormattedMessage id="sucesso" />}
            message={<FormattedMessage id="label.registroExcluidoComSucesso" />}
          />
        );
        onRefresh && onRefresh();
        onRemove && onRemove();
      } catch (error) {
        errorHandler(error);
      }
  };

  const renderMenu = (item, indexItem) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" style={{ padding: 0 }}>
          <MdiIcon icon="dots-vertical" size={18} />
        </DropdownToggle>
        <DropdownMenu container="body" placement="left">
          {item.permissions && (
            <DropdownItem
              onClick={() => handleEditClick(item, indexItem, parameters)}
            >
              <MdiIcon className="mr-2" icon="pencil" />
              {intl.formatMessage({id: idLabelEditar ? idLabelEditar : 'label.editar'}, {editar: resources.editar})}
            </DropdownItem>
          )}
          {item.permissions && item.permissions.allowDelete && showDelete && (
            <DropdownItem onClick={() => handleDeleteClick(item, indexItem)}>
              <MdiIcon className="mr-2" icon="delete-outline" />
              <FormattedMessage
                id={idLabelExcluir ? idLabelExcluir : 'excluir'}
              />
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const handleToggleItem = (item) => {
    let newList = [];

    if (multiple) {
      newList = Object.assign([], currentSelectedItems);

      const index = newList.findIndex((x) => x.id === item.id);

      if (index === -1) newList.push(item);
      else newList.splice(index, 1);
    } else {
      newList.push(item);
    }

    setAllItensSelected(newList?.length === count);
    setCurrentSelectedItems(newList);
  };

  const handleToggleSelectAll = () => {
    if (!allItensSelected && data?.length > 0) {
      setCurrentSelectedItems([...data]);
    } else setCurrentSelectedItems([]);

    setAllItensSelected(!allItensSelected);
  };

  const isSelected = (id) => {
    return currentSelectedItems?.find((x) => x.id === id);
  };

  const renderFakeRows = (item, level) =>
    renderFakeChildrenWhileLoading(item.id, level * 20);

  const renderRows = (data, level = 0, parentColor = null) => {
    if (!data) return;

    return data.map((item, indexItem) => {
      const currentBackgroundColor =
        getRowColor && getRowColor(indexItem, parentColor);
      const currentTextColor =
        getRowTextColor && getRowTextColor(indexItem, parentColor);
      return [
        <tr
          key={item.id}
          style={{
            color: currentTextColor || 'inherit',
            backgroundColor: !currentBackgroundColor
              ? isUpdated(item)
                ? '#ECECEC'
                : '#FFFFFF'
              : currentBackgroundColor,
          }}
        >
          {showMenu && (
            <td style={{ zIndex: 1, position: 'relative' }}>
              {renderMenuProps
                ? renderMenuProps(item, onRefresh)
                : renderMenu(item, indexItem)}
            </td>
          )}
          {select && (
            <td style={{ width: 30 }}>
              <Checkbox
                id={`toggle-individual-item-${guid()}`}
                key={guid()}
                disabled={
                  selectLimit &&
                  currentSelectedItems?.length >= selectLimit &&
                  !isSelected(item.id)
                }
                checked={isSelected(item.id)}
                onChange={() => handleToggleItem(item)}
              />
            </td>
          )}
          {columns.map(
            (column, index) =>
              column.visible && (
                <td
                  id={'col' + column.valueField + item.id}
                  key={index}
                  className={classnames(classes.cell, column.className)}
                  style={{ ...column.style, ...getStyleCell(index) }}
                >
                  <div
                    className={`d-flex m-auto align-items-center ${
                      !column.tree && showScrollColumn
                        ? 'overflow-y-auto overflow-x-hidden'
                        : !column.tree && !showScrollColumn
                        ? 'overflow-y-hidden overflow-x-hidden'
                        : ''
                    }`}
                    style={{
                      paddingLeft: column.tree ? level * 20 : 0,
                      maxHeight: column.maxHeight
                        ? column.maxHeight
                        : !column.tree
                        ? 130
                        : '',
                      minHeight: column.tree ? 25 : 'auto',
                      maxWidth: column.maxWidth ? column.maxWidth : 'auto',
                      minWidth: column.minWidth ? column.minWidth : 100,
                      wordWrap: 'anywhere',
                    }}
                  >
                    {column.tree &&
                      (item.hasChildren ? (
                        <MdiIcon
                          className={classes.hierarchy}
                          color={colorIcon}
                          icon={
                            isExpanded(item) ? 'chevron-down' : 'chevron-right'
                          }
                          onClick={() => handleToggleExpand(item)}
                        />
                      ) : (
                        <MdiIcon
                          className={classes.hierarchy}
                          color={colorIcon}
                        />
                      ))}
                    <div
                      style={{
                        maxHeight: column.maxHeight ? column.maxHeight : '',
                      }}
                    >
                      {getCellContent(item, column)}
                      {getCellPopoVer(item, column)}
                    </div>
                  </div>
                </td>
              )
          )}
        </tr>,
        expandedIds &&
          expandedIds.indexOf(item.id) !== -1 &&
          (!item.children?.length && renderFakeChildrenWhileLoading
            ? renderFakeRows(item, level + 1)
            : renderRows(item.children, level + 1, currentBackgroundColor)),
      ];
    });
  };

  const renderSort = (column) => {
    if (!forceShowSort && (!sort || !column.sortField)) return;

    let iconSort = 'unfold-more-horizontal';

    if (column.sortField == sort?.split(' ')[0] && sortBy == 'desc') {
      iconSort = 'chevron-up';
    }
    if (column.sortField == sort?.split(' ')[0] && sortBy == 'asc') {
      iconSort = 'chevron-down';
    }

    return (
      <MdiIcon
        style={{ cursor: 'pointer' }}
        size="14"
        className="ml-2"
        icon={iconSort}
      />
    );
  };

  const getStyleHeader = (index) => {
    const stickyObj = {
      ...stickyDefault,
    };

    if (sticky) {
      stickyObj['top'] = -1;
      stickyObj['background'] = '#FFFFFF';
      stickyObj['border'] = 'none';
      stickyObj['boxShadow'] = 'inset 0 2px 0 #dee2e6, inset 0 -2px 0 #dee2e6';

      if (index === 0) {
        stickyObj['zIndex'] = 6;
        stickyObj['left'] = 0;
        return stickyObj;
      }

      return stickyObj;
    }

    return {};
  };

  const getStyleCell = (index) => {
    const stickyObj = {
      ...stickyDefault,
    };

    if (index === 0 && sticky) {
      stickyObj['left'] = 0;
      return stickyObj;
    }

    return {};
  };

  return (
    <div>
      {data && data.length > 0 ? (
        <Table
          drag={dragTable}
          responsive={responsive}
          styleDiv={{}}
          style={{ background: '#FFF', marginBottom: 0 }}
          count={count}
          stickyHeader={sticky}
        >
          {showMenu && <th style={{ width: 30, ...getStyleHeader(-1) }}></th>}
          {select &&
            (multiple ? (
              <th style={{ width: 30, ...getStyleHeader(-1) }}>
                <Checkbox
                  id="toggle-all"
                  disabled={selectLimit && count > selectLimit}
                  checked={allItensSelected}
                  onChange={handleToggleSelectAll}
                />
              </th>
            ) : (
              <th style={{ width: 30, ...getStyleHeader(-1) }}></th>
            ))}
          {columns.map(
            (column, index) =>
              column.visible && (
                <th
                  key={index}
                  onClick={() => handleHeaderClick(column)}
                  className={classnames(
                    column.className,
                    showFullTextColumns ? 'text-nowrap' : ''
                  )}
                  style={{ ...column.styleHead, ...getStyleHeader(index) }}
                >
                  <div className="d-flex align-items-center">
                    {getHeaderText(column)}
                    {showSortIcon && column.sortField && renderSort(column)}
                  </div>
                </th>
              )
          )}
          <tbody>
            {renderRows(data)}
            {isLoading &&
              renderContentWhileLoading &&
              renderContentWhileLoading()}
          </tbody>
        </Table>
      ) : (
        !isLoading && (
          <div className="p-4">
            {intl.formatMessage({ id: 'label.nenhumRegistroFoiEncontrado' })}
          </div>
        )
      )}
      {showPagination && count > 0 && (
        <div className="d-flex justify-content-end p-3">
          <Pagination
            allowPaging
            currentPage={currentPage}
            count={count}
            pageSize={pageSize}
            changeCurrentPage={onChangeCurrentPage}
            changePageSize={onChangePageSize}
          />
        </div>
      )}
    </div>
  );
}

Results.defaultProps = {
  showPagination: true,
  data: [],
  updatedItems: [],
};

export default Results;
