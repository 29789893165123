import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';

import useAuth from 'src/hooks/useAuth';
import http from 'src/services/httpService';

import Drawer from 'src/componentes/drawer';
import { capitalizeFirstLetter } from 'src/utils/string';
import LoadingContainer from 'src/componentes/loading-container';
import { MODULOS } from 'src/utils/constants';
import PanelCollapse from 'src/componentes/panel-collapse';
import TourController from 'src/components/TourController/TourController';
import dashboardPageSteps from 'src/tours/DashboardPageSteps';
import matrizPageSteps from 'src/tours/MatrizResultadosSteps';
import atualizarValoresPageSteps from 'src/tours/AtualizarValoresPageSteps';
import agendaPageSteps from 'src/tours/AgendaPageSteps';
import reuniaoPageSteps from 'src/tours/ReuniaoPageSteps';
import acaoPageSteps from 'src/tours/AcaoPageSteps';

const HelpDrawer = ({
  openHelp,
  setOpenHelp,
  module,
  podeVisualizarMatriz,
  podeVisualizarValoresDesatualizados,
}) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [jaExisteFeedbackDash, setJaExisteFeedbackDash] = useState(null);
  const [jaExisteFeedbackMatriz, setJaExisteFeedbackMatriz] = useState(null);
  const [
    jaExisteFeedbackAtualizarValores,
    setJaExisteFeedbackAtualizarValores,
  ] = useState(null);
  const [jaExisteFeedbackAgenda, setJaExisteFeedbackAgenda] = useState(null);
  const [jaExisteFeedbackReuniao, setJaExisteFeedbackReuniao] = useState(null);
  const [jaExisteFeedbackAcao, setJaExisteFeedbackAcao] = useState(null);
  const [isFeedbackLoaded, setIsFeedbackLoaded] = useState(false);

  const isLoadingFeedback =
    jaExisteFeedbackDash === null ||
    jaExisteFeedbackMatriz === null ||
    jaExisteFeedbackAtualizarValores === null ||
    jaExisteFeedbackAgenda === null ||
    jaExisteFeedbackReuniao === null ||
    jaExisteFeedbackAcao === null;

  useEffect(() => {
    getFeedbackTour();
  }, [openHelp]);

  const getFeedbackTour = async () => {
    try {
      var response = await http.post(
        'FeedbackTour/ObterFeedbackPorColaborador'
      );
      setJaExisteFeedbackDash(response.data.value.jaExisteFeedbackDash);
      setJaExisteFeedbackMatriz(response.data.value.jaExisteFeedbackMatriz);
      setJaExisteFeedbackAtualizarValores(
        response.data.value.jaExisteFeedbackAtualizarValores
      );
      setJaExisteFeedbackAgenda(response.data.value.jaExisteFeedbackAgenda);
      setJaExisteFeedbackReuniao(response.data.value.jaExisteFeedbackReuniao);
      setJaExisteFeedbackAcao(response.data.value.jaExisteFeedbackAcao);
      setIsFeedbackLoaded(true);
    } catch (err) {}
  };

  const dashSteps = dashboardPageSteps(
    intl,
    resources,
    jaExisteFeedbackDash,
    isLoadingFeedback
  );
  const matrizSteps = matrizPageSteps(
    intl,
    resources,
    jaExisteFeedbackMatriz,
    isLoadingFeedback
  );
  const atualizarValoresSteps = atualizarValoresPageSteps(
    intl,
    resources,
    jaExisteFeedbackAtualizarValores,
    isLoadingFeedback
  );
  const agendaSteps = agendaPageSteps(
    intl,
    resources,
    jaExisteFeedbackAgenda,
    isLoadingFeedback
  );
  const reuniaoSteps = reuniaoPageSteps(
    intl,
    resources,
    jaExisteFeedbackReuniao,
    isLoadingFeedback
  );
  const acaoSteps = acaoPageSteps(
    intl,
    resources,
    jaExisteFeedbackAcao,
    isLoadingFeedback
  );

  const handleClose = () => {
    setOpenHelp(false);
  };

  const renderAll = () => (
    <>
      {module?.id == MODULOS.GESTAO_ESTRATEGICA && (
        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(
            intl.formatMessage({ id: 'label.dashboardIndividual' })
          )}
          headerStyle="text-primary"
          className="mb-3"
          style={{ width: '100%' }}
        >
          <div md={12}>
            {capitalizeFirstLetter(
              intl.formatMessage({ id: 'visualizeResumoCompleto' })
            )}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <TourController
              setOpenHelp={setOpenHelp}
              redirectPath="/painel/dashboard-individual"
              steps={dashSteps}
            />
          </div>
        </PanelCollapse>
      )}
      {podeVisualizarMatriz && (
        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(resources.matrizderesultados)}
          headerStyle="text-primary"
          className="mb-3"
          style={{ width: '100%' }}
        >
          <div md={12}>
            {capitalizeFirstLetter(
              intl.formatMessage({ id: 'acompanheAtualizeValoresItens' })
            )}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <TourController
              setOpenHelp={setOpenHelp}
              redirectPath="/acompanhar/matriz-resultado"
              steps={matrizSteps}
            />
          </div>
        </PanelCollapse>
      )}

      {podeVisualizarValoresDesatualizados &&
        (module?.id == MODULOS.GESTAO_ESTRATEGICA ||
          module?.id == MODULOS.REMUNERACAO_VARIAVEL) && (
          <PanelCollapse
            md={12}
            open={false}
            header={capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'atualizarValores' },
                { valores: resources.valores }
              )
            )}
            headerStyle="text-primary"
            className="mb-3"
            style={{ width: '100%' }}
          >
            <div md={12}>
              {capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'atualizeValoresItensSobSuaResponsabilidade' },
                  { valores: resources.valores }
                )
              )}
            </div>
            <div style={{ marginTop: '1rem' }}>
              <TourController
                setOpenHelp={setOpenHelp}
                redirectPath="/minhas-tarefas/valor-desatualizado"
                steps={atualizarValoresSteps}
              />
            </div>
          </PanelCollapse>
        )}

      {module?.id == MODULOS.GESTAO_ESTRATEGICA && (
        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(intl.formatMessage({ id: 'agenda' }))}
          headerStyle="text-primary"
          className="mb-3"
          style={{ width: '100%' }}
        >
          <div md={12}>
            {capitalizeFirstLetter(
              intl.formatMessage({ id: 'acompanheCalendario' })
            )}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <TourController
              setOpenHelp={setOpenHelp}
              redirectPath="/minhas-tarefas/minha-agenda"
              steps={agendaSteps}
            />
          </div>
        </PanelCollapse>
      )}
       {module?.id == MODULOS.GESTAO_ESTRATEGICA && (
        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(intl.formatMessage({ id: 'cadastroReunioes' }))}
          headerStyle="text-primary"
          className="mb-3"
          style={{ width: '100%' }}
        >
          <div md={12}>
            {capitalizeFirstLetter(
              intl.formatMessage({ id: 'registreGerenciaReunioes' })
            )}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <TourController
              setOpenHelp={setOpenHelp}
              redirectPath="/gerenciar/reuniao"
              steps={reuniaoSteps}
            />
          </div>
        </PanelCollapse>
      )}  
      {module?.id == MODULOS.GESTAO_ESTRATEGICA && (
        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(intl.formatMessage({ id: 'cadastrarAcao' }, {acao: resources.acao}))}
          headerStyle="text-primary"
          className="mb-3"
          style={{ width: '100%' }}
        >
          <div md={12}>
            {
              intl.formatMessage({ id: 'registreGerencieAcao' }, {planodeacao: resources.planodeacao})
            }
          </div>
          <div style={{ marginTop: '1rem' }}>
            <TourController
              setOpenHelp={setOpenHelp}
              redirectPath="/gerenciar/acao"
              steps={acaoSteps}
            />
          </div>
        </PanelCollapse>
      )}          
    </>
  );

  return (
    <Drawer open={openHelp} close={handleClose}>
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col md={12}>
            <div
              className="font-weight-bolder mb-2"
              style={{ fontSize: '22px' }}
            >
              {capitalizeFirstLetter(
                intl.formatMessage({ id: 'conhecerPlataforma' })
              )}
            </div>
          </Col>
          {isFeedbackLoaded && renderAll()}
        </Row>
      </LoadingContainer>
    </Drawer>
  );
};

export default HelpDrawer;
