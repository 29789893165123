import React from 'react';
import { autobind } from 'core-decorators';
import {
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Dialog from '../../../componentes/dialog';
import Button from '../../../componentes/button';
import { Form } from '../../../utils/form';
import errorHandler from '../../../utils/error-handler';
import LoadingContainer from '../../../componentes/loading-container';
import Alert from '../../../componentes/message-box/alert';
import Confirm from '../../../componentes/message-box/confirm';
import ContentManager from '../../../componentes/content-manager';
import http from 'src/services/httpService';
import history from '../../../history';
import { TIPO_ITENS } from 'src/utils/constants';

import Resumo from './resumo';

@autobind
class Template extends React.Component {
  constructor(props) {
    super(props);
    this.salvo = false;
    this.state = {
      isLoading: false,
      isLoadingParams: false,
      modeloItem: null,
    };

    this.form = new Form({
      component: this,
      schema: this.props.schema,
    });
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    try {
      var response = await http.post(`${this.props.url}/Create`, {
        parameters: this.props.parameters,
      });
      this.setState({ isLoading: false });
      this.handleSetFormValues(response.data);
      //this.form.setValue(null, response.data);
    } catch (error) {
      this.setState({ isLoading: false });
      errorHandler(error);
    }
  }

  handleClose() {
    this.refs.dialog.close();
    setTimeout(() => {
      this.props.remove && this.props.remove();
      this.props.handleClose &&
        this.props.handleClose(this.salvo, this.form.getValue('id'));
      this.salvo = false;
    }, 300);
  }

  redirecionaRegistroCriado() {
    var tipo = this.form.getModel('tipo').value;
    var urlEdit = '';

    if (tipo.id === 1) {
      urlEdit = '/gerenciar/indicador/edit';
    } else if (tipo.id === 2) {
      urlEdit = '/gerenciar/parametro/edit';
    } else if (tipo.id === 3) {
      urlEdit = '/gerenciar/objetivo-estrategico/edit';
    } else if (tipo.id === 4) {
      urlEdit = '/gerenciar/projeto/edit';
    } else if (tipo.id === 6) {
      urlEdit = '/gerenciar/risco/edit';
    } else if (tipo.id === 5) {
      urlEdit = '/gerenciar/processo/edit';
    } else if (tipo.id === 10) {
      urlEdit = '/rv/gerenciar/desempenho-individual/edit';
    } else if (tipo.id === 11) {
      urlEdit = '/rv/gerenciar/calculo-intermediario/edit';
    } else if (tipo.id === 17) {
      urlEdit = '/gerenciar/indicador-risco/edit';
    }

    history.push({
      pathname: `${urlEdit}`,
      search: `id=${this.form.getValue('id')}`,
    });
  }

  handleBack() {
    this.setState({ modeloItem: null });
    this.handleClose();
  }

  handleSetCriarItensFormula(criarItens) {
    this.setState({ criarItensFormulaTemplate: criarItens });
  }

  handleValidate() {
    var model = this.form.getModel();

    if (!this.form.isValid()) {
      this.form.showAllErrors();
      ContentManager.addContent(
        <Alert
          message={
            <FormattedMessage id="label.osCamposDestacadosEmVermelhoSaoObrigatoriosFavorPreenchelos" />
          }
        />
      );
      return;
    }

    let itemTemplate = model.getModel('itemTemplate').value;

    if (itemTemplate != null && itemTemplate.possuiFormula == true) {
      ContentManager.addContent(
        <Confirm
          handleConfirm={this.handleSave.bind(this, true)}
          handleCancel={this.handleSave.bind(this, false)}
          message={
            <FormattedMessage id="criarItensDaFormulaAssociadosNoTemplate" />
          }
          handle
        />
      );
    } else {
      this.handleSave();
    }
  }

  async handleSave(criarItensFormulaTemplate) {
    let { modeloItem } = this.state;
    var model = this.form.getModel();

    this.setState({ isLoading: true });
    try {
      var response = await http.post(`${this.props.url}/Save`, {
        model: this.form.getValue(),
        parameters: {
          modeloItemId: modeloItem && modeloItem.id,
          criarItensFormulaAssociadaAoTemplate: criarItensFormulaTemplate,
          TemplateDesdobramentoId:
            model.getModel('templateDesdobramento').value &&
            model.getModel('templateDesdobramento').value.id,
          ...this.props.parameters,
        },
      });
      this.form.setValue(null, response.data.model);
      this.salvo = true;
      this.setState({ isLoading: false });
      this.handleClose();
      this.redirecionaRegistroCriado();
    } catch (error) {
      this.setState({ isLoading: false });
      errorHandler(error);
    }
  }

  handleClick(modeloItemId) {
    this.setState({ modeloItem: { id: modeloItemId } });
  }

  handleSetFormValues(value) {
    this.form.setValue(null, value);
  }

  handleColumnSize(
    habilitaOpcaoTemplateItem,
    utilizarDesdobramento,
    modeloSelecionado,
    tipoItem
  ) {
    if (tipoItem == TIPO_ITENS.PROJETO) return 6;
    if (modeloSelecionado) return 6;

    if (habilitaOpcaoTemplateItem || utilizarDesdobramento) {
      if (
        (habilitaOpcaoTemplateItem && !utilizarDesdobramento) ||
        (!habilitaOpcaoTemplateItem && utilizarDesdobramento)
      )
        return 6;

      return 4;
    }

    return 12;
  }

  obterConfiguracaoSistema() {
    return this.props.configuracaoSistema ? this.props.configuracaoSistema : {};
  }

  renderCard({ src, title, text, btnText, onClick }) {
    return (
      <Card>
        <CardImg
          style={{ maxHeight: 480 }}
          top
          width="100%"
          src={src}
          alt="Card image cap"
        />
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardText style={{ minHeight: 40 }}>{text}</CardText>
          <Button onClick={onClick}>{btnText}</Button>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { tipoItem } = this.props;
    const { modeloItem } = this.state;

    const {
      utilizarTemplateIndicador,
      utilizarTemplateParametro,
      utilizarTemplateObjetivoEstrategico,
      utilizarFuncionalidadeDesdobramento,
    } = this.obterConfiguracaoSistema();

    const habilitaOpcaoTemplateItem =
      (tipoItem == 1 && utilizarTemplateIndicador) ||
      (tipoItem == 2 && utilizarTemplateParametro) ||
      (tipoItem == 3 && utilizarTemplateObjetivoEstrategico);
    const model = this.form.getModel();
    const columnSize = this.handleColumnSize(
      habilitaOpcaoTemplateItem,
      utilizarFuncionalidadeDesdobramento,
      modeloItem,
      tipoItem
    );

    const editActions = [
      <div key="edit-toolbar">
        {tipoItem != TIPO_ITENS.PROJETO && tipoItem != 10 && tipoItem != 11 && (
          <Button color="secondary" onClick={this.handleBack}>
            <FormattedMessage id="label.voltar" />
          </Button>
        )}
        <Button className="ml-2" onClick={this.handleValidate}>
          <FormattedMessage id="label.salvarEFechar" />
        </Button>
      </div>,
    ];

    return (
      <Dialog
        ref="dialog"
        title={<FormattedMessage id="label.novo" />}
        width={columnSize == 12 ? '500px' : columnSize == 4 ? '90%' : '80%'}
        style={{ fontSize: 12 }}
        onRequestClose={this.handleClose}
        padContent
        actions={
          (modeloItem || (tipoItem != 1 && tipoItem != 2 && tipoItem != 3)) &&
          editActions
        }
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          {!modeloItem && (tipoItem == 1 || tipoItem == 2 || tipoItem == 3) ? (
            <Row>
              <Col sm={columnSize}>
                <Card>
                  <CardImg
                    style={{ maxHeight: 480 }}
                    top
                    width="100%"
                    src={'/static/images/template-item/individual.jpg'}
                    alt="Card image cap"
                  />
                  <CardBody>
                    <CardTitle>
                      <FormattedMessage id="templateIndividual" />
                    </CardTitle>
                    <CardText style={{ minHeight: 40 }}>
                      <FormattedMessage id="templateIndividualMensagem" />
                    </CardText>
                    <Button onClick={this.handleClick.bind(this, 1)}>
                      <FormattedMessage id="selecionar" />
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              {utilizarFuncionalidadeDesdobramento && (
                <Col sm={columnSize}>
                  <Card>
                    <CardImg
                      style={{ maxHeight: 480 }}
                      top
                      width="100%"
                      src={'/static/images/template-item/desdobramento.jpg'}
                      alt="Card image cap"
                    />
                    <CardBody>
                      <CardTitle>
                        <FormattedMessage id="label.templateDesdobramento" />
                      </CardTitle>
                      <CardText style={{ minHeight: 40 }}>
                        <FormattedMessage id="templateDesdobramentoMensagem" />
                      </CardText>
                      <Button onClick={this.handleClick.bind(this, 2)}>
                        <FormattedMessage id="selecionar" />
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {habilitaOpcaoTemplateItem && (
                <Col sm={columnSize}>
                  <Card>
                    <CardImg
                      style={{ maxHeight: 480 }}
                      top
                      width="100%"
                      src={'/static/images/template-item/template-item.jpg'}
                      alt="Card image cap"
                    />
                    <CardBody>
                      <CardTitle>
                        <FormattedMessage id="templateItem" />
                      </CardTitle>
                      <CardText style={{ minHeight: 40 }}>
                        <FormattedMessage id="templateItemMensagem" />
                      </CardText>
                      <Button onClick={this.handleClick.bind(this, 3)}>
                        <FormattedMessage id="selecionar" />
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          ) : (
            <Resumo
              tipoItem={tipoItem}
              modeloItem={modeloItem}
              model={model}
              handleSetFormValues={this.handleSetFormValues}
            />
          )}
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracaoSistema: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(Template));
