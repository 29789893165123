import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';
import { capitalizeFirstLetter } from 'src/utils/string';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import OrganizacaoLookup from 'src/lookups/item-organizacao';
import CargoLookup from 'src/lookups/cargo';
import DatePicker from 'src/componentes/date-picker';
import useAuth from 'src/hooks/useAuth';
import Checkbox from 'src/componentes/Form/Checkbox';
import Input from 'src/componentes/input';
import http from 'src/services/httpService';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import Panel from 'src/componentes/panel-collapse';

function HistoricoOcupacaoEditView({
  handleClose,
  item,
  indexItem,
  parameters,
  ...rest
}) {
  const intl = useIntl();
  const modalRef = useRef();
  const { terms: resources } = useAuth();

  const { cargoId, colaboradorId } = parameters;
  const [currentItem, setCurrentItem] = useState(item);
  const [isNew] = useState(item == null);
  const [configuracoesGerais, setConfiguracoesGerais] = useState(null);
  const [configuacaoColaborador, setConfiguracaoColaborador] = useState();
  const [configuacaoCicloAvaliacao, setConfiguracaoCicloAvaliacao] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const handleCurrentClose = (isSaved = false) => {
    modalRef.current.close();
    setTimeout(() => {
      handleClose && handleClose(isSaved, isNew, currentItem, indexItem);
    }, 300);
  };

  const getParam = () => {
    if (configuracoesGerais == null) {
      http
        .post(`/ConfiguracaoSistema/ObterConfiguracoes`, {})
        .then((response) => {
          const {
            definirLiderRegraSoftware,
            definirColaboradoresComSemMetasAssociadas,
          } = response.data?.model;

          setConfiguracoesGerais({
            definirLiderRegraSoftware,
            definirColaboradoresComSemMetasAssociadas,
          });

          setIsLoading(false);
        });
    }
  };

  const getConfiguracaoColaborador = () => {
    http
      .post(`/ColaboradorConfiguracao/ObterConfiguracoes`, {})
      .then((response) => {
        setConfiguracaoColaborador(response.data);

        setIsLoading(false);
      });
  };

  const getConfiguracaoCiclo = () => {
    http
      .post(`/CicloAvaliacaoConfiguracao/ObterConfiguracoes`, {})
      .then((response) => {
        setConfiguracaoCicloAvaliacao(response.data);

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getParam();
    getConfiguracaoColaborador();
    getConfiguracaoCiclo();
  }, []);

  const handleChange = (field, value) => {
    setCurrentItem((prev) => ({
      ...prev,
      permissions: {
        allowEdit: true,
        allowDelete: true,
      },
      [field]: value,
    }));
  };

  const handleCloseClick = () => {
    handleCurrentClose();
  };

  const handleSave = () => {
    if (configuracoesGerais?.definirLiderRegraSoftware) {
      http
        .post(`colaborador/ObterLider`, {
          model: { ...currentItem, colaborador: { id: colaboradorId } },
        })
        .then((response) => {
          currentItem.lider = response.data;
          handleCurrentClose(true);
        });
    } else {
      handleCurrentClose(true);
    }
  };

  const etapaGestorMatricial = configuacaoCicloAvaliacao?.etapasHabilitadas.find(etapa => etapa === 105);

  const isDisabled = () =>
    !currentItem ||
    !currentItem.cargo ||
    !currentItem.area ||
    !currentItem.dataInicioCargo ||
    (!currentItem.idIntegracao &&
      !configuacaoColaborador?.utilizarCodigoAutomaticoHistoricoOcupacao);

  const editActions = [
    <Button key={1} onClick={handleCloseClick} color="secondary">
      {<FormattedMessage id="label.cancelar" />}
    </Button>,
    <Button
      disabled={isDisabled()}
      key={2}
      type="primary"
      onClick={handleSave}
      htmlType="submit"
      className="ml-2"
    >
      {<FormattedMessage id="label.salvar" />}
    </Button>,
  ];

  return (
    <Dialog
      isLoading={isLoading}
      ref={modalRef}
      width={850}
      minHeight={250}
      padContent
      actions={editActions}
      onRequestClose={handleCurrentClose}
      {...rest}
    >
      <Row>
        <Col md={4}>
          <Input
            disabled={
              configuacaoColaborador?.utilizarCodigoAutomaticoHistoricoOcupacao &&
              !configuacaoColaborador?.permitirAlterarCodigoAutomaticoHistoricoOcupacao
            }
            autoFocus
            model={{
              label: intl.formatMessage({ id: 'idIntegracao' }),
              value: currentItem && currentItem.idIntegracao,
              requestChange: (e) => handleChange('idIntegracao', e),
            }}
            required
          />
        </Col>
        {cargoId && (
          <Col md={4}>
            <ColaboradorSimplificadoLookup
              model={{
                label: capitalizeFirstLetter(resources.colaborador),
                value: currentItem && currentItem.colaborador,
                requestChange: (e) => handleChange('colaborador', e),
              }}
              required
            />
          </Col>
        )}
        {colaboradorId && (
          <Col md={4}>
            <CargoLookup
              model={{
                label: capitalizeFirstLetter(resources.cargo),
                value: currentItem && currentItem.cargo,
                requestChange: (e) => handleChange('cargo', e),
              }}
              required
            />
          </Col>
        )}
        <Col md={4}>
          <OrganizacaoLookup
            autoFocus
            model={{
              label: capitalizeFirstLetter(resources.area),
              value: currentItem && currentItem.area,
              requestChange: (e) => handleChange('area', e),
            }}
            required
          />
        </Col>
        <Col md={4}>
          <DatePicker
            autoFormatDate={true}
            model={{
              label: intl.formatMessage({ id: 'label.dataInicio' }),
              value: currentItem && currentItem.dataInicioCargo,
              requestChange: (e) => handleChange('dataInicioCargo', e),
            }}
            required
          ></DatePicker>
        </Col>
        <Col md={4}>
          <DatePicker
            autoFormatDate={true}
            model={{
              label: intl.formatMessage({ id: 'label.dataTermino' }),
              value: currentItem && currentItem.dataTerminoCargo,
              requestChange: (e) => handleChange('dataTerminoCargo', e),
            }}
          ></DatePicker>
        </Col>
        <Col md={2}>
          <div style={{ marginTop: 34 }}>
            <Checkbox
              type="switch"
              id={`ehlider`}
              name="customSwitch"
              label={intl.formatMessage({ id: 'ehLider' })}
              checked={currentItem && currentItem.ehLider}
              onChange={(e) => handleChange('ehLider', e.target.checked)}
            />
          </div>
        </Col>
        {configuracoesGerais?.definirColaboradoresComSemMetasAssociadas && (
          <Col md={2}>
            <div style={{ marginTop: 34 }}>
              <Checkbox
                type="switch"
                id={`utilizaMeta`}
                name="customSwitch"
                label={intl.formatMessage({ id: 'labelTemMeta' })}
                checked={currentItem && currentItem.utilizaMeta}
                onChange={(e) => handleChange('utilizaMeta', e.target.checked)}
              />
            </div>
          </Col>
        )}
        {(!configuracoesGerais?.definirLiderRegraSoftware || !isNew) && (
          <Col md={6}>
            <ColaboradorSimplificadoLookup
              disabled={configuracoesGerais?.definirLiderRegraSoftware}
              model={{
                label: intl.formatMessage({ id: 'lider' }),
                value: currentItem && currentItem.lider,
                requestChange: (e) => handleChange('lider', e),
              }}
            />
            <br />
          </Col>
        )}
        {etapaGestorMatricial && (
          <Col md={6}>
            <ColaboradorSimplificadoLookup
              model={{
                label: intl.formatMessage({ id: 'gestorMatricial' }),
                value: currentItem && currentItem.gestorMatricial,
                requestChange: (e) => handleChange('gestorMatricial', e),
              }}
            />
            <br />
          </Col>
        )}
      </Row>
      {currentItem?.camposCustomizados?.length > 0 && (
        <Row>
          <Col md={12}>
            <Panel
              open={false}
              header={intl.formatMessage({ id: 'informacoesComplementares' })}
            >
              <DesenharCampoCustomizado
                model={{
                  label: intl.formatMessage({
                    id: 'informacoesComplementares',
                  }),
                  value: currentItem && currentItem.camposCustomizados,
                  requestChange: (e) => handleChange('camposCustomizados', e),
                }}
              />
            </Panel>
          </Col>
        </Row>
      )}
    </Dialog>
  );
}

export default HistoricoOcupacaoEditView;
