import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Content from '../../../componentes/pages/content';
import View from './view';
import { capitalizeFirstLetter } from '../../../utils/string';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import { getConfiguration } from 'src/services/commonRequests';
import MatrizRiscoFarol from 'src/paginas/gestao-risco/matriz-risco/matriz-farol-itens-impactados';

@autobind
class Processo extends React.Component {
  componentDidMount() {
    getConfiguration('ItemConfiguracaoProcesso')((configuracaoProcesso) =>
      this.setState({ ...this.state, configuracaoProcesso })
    )();
  }
  render() {
    let { resources, intl } = this.props;
    return (
      <Content
        titulo={capitalizeFirstLetter(resources.processos)}
        iconSettings={true}
        titleSettings={intl.formatMessage(
          {
            id: 'configuracaoProcesso',
          },
          { processo: resources.processo }
        )}
        urlSettings="/configuracoes/processo"
        endPointPermissionSettings="/itemConfiguracaoProcesso/PossuiPermissaoConfiguracao"
      >
        <Tabs>
          <Tab label={capitalizeFirstLetter(resources.processos)}>
            <View {...this.props} />
          </Tab>
          {this.state?.configuracaoProcesso?.habilitarMatrizItensImpactados && (
            <Tab
              label={intl.formatMessage(
                { id: 'labelMatrizItensImpactados' },
                { processos: resources.processos }
              )}
            >
              <MatrizRiscoFarol {...this.props} />
            </Tab>
          )}
        </Tabs>
      </Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Processo));
