import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import PacoteEdit from '../paginas/matriz-orcamentaria/pacote/edit';
import PacoteList from '../paginas/matriz-orcamentaria/pacote/view';

@autobind
class PacoteLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueComponent={this.renderPacote}
        valueKey="id"
        labelKey="nome"
        showSearch
        clearable={true}
        url="/PacoteLookup"
        editComponent={PacoteEdit}
        searchComponent={PacoteList}
        {...this.props}
      />
    );
  }
}

export default PacoteLookup;
