import React from 'react';
import { Col, Row } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Label } from 'react-bootstrap';
import ParametroLookup from '../../../../lookups/parametro';
import Panel from '../../../../componentes/panel';

import { capitalizeFirstLetter } from 'src/utils/string';

class PacoteIndicadoresEdit extends React.Component {
  render() {
    let { model, resources } = this.props;

    return (
      <div>
        <Row>
          <Col md={4}>
            <Panel header={capitalizeFirstLetter(resources.indicadordepreco)}>
              <div style={{ marginBottom: 15 }}>
                <Label>{capitalizeFirstLetter(resources.parametro)}</Label>
              </div>
              <div style={{ marginBottom: 15 }}>{model.getModel('parametroPreco') && <ParametroLookup model={model.getModel('parametroPreco')} />}</div>
              <div style={{ marginBottom: 15 }}>
                {model.getModel('parametroPreco').value && <Label>{' ' + model.getModel('moeda').value + ' / ' + model.getModel('parametroPreco').value.nome}</Label>}
              </div>
            </Panel>
          </Col>
          <Col md={4}>
            <Panel header={capitalizeFirstLetter(resources.indicadordeconsumo)}>
              <div style={{ marginBottom: 15 }}>
                <Label>{capitalizeFirstLetter(resources.parametro)}</Label>
              </div>
              <div style={{ marginBottom: 15 }}>{model.getModel('parametroPreco') && <ParametroLookup model={model.getModel('parametroConsumo')} />}</div>
              <div style={{ marginBottom: 15 }}>
                {model.getModel('parametroConsumo').value && model.getModel('parametroPreco').value && (
                  <Label>{' ' + model.getModel('parametroPreco').value.nome + ' / ' + model.getModel('parametroConsumo').value.nome}</Label>
                )}
              </div>
            </Panel>
          </Col>
          <Col md={4}>
            <Panel header={capitalizeFirstLetter(resources.indicadorfinal)}>
              {model.getModel('parametroConsumo').value && (
                <Label>{' ' + model.getModel('moeda').value + ' / ' + model.getModel('parametroConsumo').value.nome}</Label>
              )}
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(PacoteIndicadoresEdit));
