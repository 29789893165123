import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Input from 'src/componentes/input';
import Checkbox from 'src/componentes/checkbox';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import ResponsavelLookup from 'src/lookups/colaborador';
import { capitalizeFirstLetter } from 'src/utils/string';

@autobind
class DesdobramentoSearch extends React.Component {
  render() {
    let { model, resources, intl } = this.props;
    return (
      <Row>
        <Col md={2}>
          <Input model={model.getModel('idIntegracao')} />
        </Col>
        <Col md={3}>
          <Input model={model.getModel('descricao')} />
        </Col>
        <Col md={3}>
          <ItemOrganizacaoLookup
            multi
            model={{
              label: capitalizeFirstLetter(resources.areas),
              value: model.getModel('area').value,
              requestChange: model.getModel('area').requestChange,
            }}
            data={{ tiposIdSelecionaveis: [1, 2, 3] }}
          />
        </Col>
        <Col md={3}>
          <Checkbox
            model={{
              label: intl.formatMessage({ id: 'label.considerarAreasSubordinadas' }, { areas: resources.areas }),
              value: model.getModel('areasSubordinadas').value,
              requestChange: model.getModel('areasSubordinadas').requestChange,
            }}
            disabled={!model.getModel('area').value}
            style={{ marginTop: 27 }}
          />
        </Col>
        <Col md={3}>
          <ResponsavelLookup
            multi
            model={{
              label: capitalizeFirstLetter(resources.responsaveis),
              value: model.getModel('responsaveis').value,
              requestChange: model.getModel('responsaveis').requestChange,
            }}
          />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(DesdobramentoSearch));
