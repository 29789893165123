import React from 'react';

import RootArrow from './root-arrow';

export const debug = false;

export default class IshikawaDiagrama extends React.Component {
  render() {
    let { className, style, dados, handleSaveCausaFundamental, widgetId } =
      this.props;

    let size = RootArrow.measure({ item: dados });

    return (
      <svg
        className={className}
        style={style}
        viewBox={`0 0 ${size.width + 100} ${size.height + 50}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <marker
            id="head"
            markerWidth="4"
            markerHeight="4"
            viewBox="-6 -6 12 12"
            refX="-2"
            refY="0"
            markerUnits="strokeWidth"
            orient="auto"
          >
            <polygon points="-2,0 -5,5 5,0 -5,-5" fill="black" />
          </marker>
        </defs>
        {/* 
        <svg
          viewBox="0 30 100 40"
          x={0}
          y={0}
          width={size.width + 100}
          height={size.height + 50}
          opacity={0.05}
          preserveAspectRatio="xMidYMid meet"
        >
          <path d="M96.806,50.581c-0.775,0.021-1.511,0.322-2.283,0.202c-0.016-0.053-0.03-0.151-0.038-0.202  c0.666-0.302,1.403-0.49,2.011-0.925c0.325-0.316,0.792-0.691,0.573-1.209c-0.242-1.068-1.218-1.729-2.06-2.307  c-1.007-0.707-2.14-1.173-3.214-1.735c-1.18-0.504-2.771-2.356-18.807-5.387c-5.913-1.133-11.902-1.648-12.152-1.836  c-5.526,0.102-11.099-6.188-12.797-6.691C47.776,30.317,47.581,31,47.452,31h0.002c-0.16,1-0.614,8.46,0.302,8.738  c-1.03,0.308-2.109,0.271-3.156,0.497c-1.72,0.33-10.451,2.965-14.923,3.992c-1.585,0.294-3.21,0.235-4.815,0.242  c-1.549-0.029-3.073-0.372-4.561-0.761c-1.817-0.518-16.438-6.635-16.537-6.133c0.038,4.065,2.203,6.819,3.75,10.432  c0.052,0.526,0.269,1.035,0.196,1.576c-0.858,2.793-4.111,5.208-4.913,8.098c0.006,0.556-0.234,1.051-0.438,1.554  c0.67,0.044,1.339,0.083,2.013,0.067c1.466-0.172,10.055-2.336,11.376-2.645c1.292-0.299,2.501-0.85,3.777-1.164  c0.725-0.173,1.377-0.548,2.104-0.669c1.443-0.188,2.901-0.367,4.359-0.225c1.016,0.104,2.027,0.203,3.029,0.42  c0.757,0.166,6.36,1.226,9.716,2.373c2.207,0.173,10.578,3.585,11.368,3.751c-1.289,2.099-1.147,3.252-1.48,5.746  c-0.216,0.879-0.254,1.888,0.359,2.614c0.278,0.264,0.731,0.277,1.044,0.069c4.525-4.812,9.603-6.665,9.82-7.348  c2.756,0.329,5.53,0.044,8.294,0.059c15.207-0.16,25.758-8.233,26.728-8.767c0.812-0.413,1.678-0.872,2.166-1.675  C97.422,51.488,97.287,50.769,96.806,50.581z M87.604,50.102c-0.896,0-1.621-0.727-1.621-1.623c0-0.896,0.725-1.623,1.621-1.623  c0.897,0,1.624,0.727,1.624,1.623C89.229,49.375,88.502,50.102,87.604,50.102z" />
        </svg> */}

        <RootArrow
          handleSaveCausaFundamental={handleSaveCausaFundamental}
          item={dados}
          widgetId={widgetId}
          x={50}
          y={25}
          width={size.width}
          height={size.height}
        />
      </svg>
    );
  }
}
