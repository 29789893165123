import React from 'react';
import { autobind } from 'core-decorators';
import Content from '../../../../../componentes/pages/content';
import View from './view';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class FaixaMinMaxIndex extends React.Component {
  render() {
    return (
      <Content>
        <View {...this.props} />
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(FaixaMinMaxIndex));
