import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Input from 'src/componentes/input';
import Panel from 'src/componentes/panel-collapse';
import GeneroLookup from 'src/lookups/GeneroLookup';
import RacaLookup from 'src/lookups/RacaLookup';
import OrientacaoSexualLookup from 'src/lookups/OrientacaoSexualLookup';
import { COLABORADOR_CAMPOS } from 'src/utils/constants';
import { Label } from 'src/componentes/Form';

function Pessoal({ model, isVisible, isRequired, disabled }) {
  const { intl } = useIntl();

  const anyVisible = () =>
    isVisible(COLABORADOR_CAMPOS.GENERO) ||
    isVisible(COLABORADOR_CAMPOS.NOME_SOCIAL) ||
    isVisible(COLABORADOR_CAMPOS.RACA) ||
    isVisible(COLABORADOR_CAMPOS.ORIENTACAO_SEXUAL) ||
    isVisible(COLABORADOR_CAMPOS.LINKEDIN);

  return anyVisible() ? (
    <Panel open={false} header={<FormattedMessage id="pessoal" />}>
      {isVisible(COLABORADOR_CAMPOS.GENERO) && (
        <GeneroLookup
          disabled={disabled}
          required={isRequired(COLABORADOR_CAMPOS.GENERO, model)}
          model={{
            label: model.getModel('genero').label,
            value: model.getModel('genero').value,
            requestChange: model.getModel('genero').requestChange,
            errors: model.getModel('genero').errors,
          }}
        />
      )}
      {isVisible(COLABORADOR_CAMPOS.NOME_SOCIAL) && (
        <Input
          disabled={disabled}
          required={isRequired(COLABORADOR_CAMPOS.NOME_SOCIAL, model)}
          model={model.getModel('nomeSocial')}
        />
      )}
      {isVisible(COLABORADOR_CAMPOS.RACA) && (
        <RacaLookup
          disabled={disabled}
          required={isRequired(COLABORADOR_CAMPOS.RACA, model)}
          model={model.getModel('raca')}
        />
      )}
      {isVisible(COLABORADOR_CAMPOS.ORIENTACAO_SEXUAL) && (
        <OrientacaoSexualLookup
          disabled={disabled}
          required={isRequired(COLABORADOR_CAMPOS.ORIENTACAO_SEXUAL, model)}
          model={model.getModel('orientacaoSexual')}
        />
      )}
      {isVisible(COLABORADOR_CAMPOS.LINKEDIN) &&
        (!model.getModel('linkedIn').value || !disabled ? (
          <Input
            required={!disabled && isRequired(COLABORADOR_CAMPOS.LINKEDIN)}
            model={model.getModel('linkedIn')}
            disabled={disabled}
          ></Input>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Label>{model.getModel('linkedIn').label}</Label>
            <div>
              <a target="_blank" href={model.getModel('linkedIn').value}>
                {model.getModel('linkedIn').value}
              </a>
            </div>
          </div>
        ))}
    </Panel>
  ) : (
    <></>
  );
}

export default Pessoal;
