import { useRef } from 'react';
import { useIntl } from 'react-intl';
import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';

const AlertaRevisao = ({ model, children, handleConfirm, ...rest }) => {
  const ref = useRef();
  const intl = useIntl();

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const handleNext = () => {
    handleConfirm && handleConfirm();
    handleClose();
  };

  return (
    <Dialog
      ref={ref}
      width={700}
      height="80%"
      onRequestClose={handleClose}
      {...rest}
    >
      <div
        style={{ background: '#a5c9d0' }}
        className="d-flex align-items-center px-3 pt-3"
      >
        <div
          style={{ width: 25, height: 25 }}
          className="bg-primary rounded-circle text-white mr-2 d-inline-flex justify-content-center align-items-center"
        >
          {model.numero}
        </div>
        <div className="font-weight-bold">{model.titulo}</div>
      </div>
      <div
        style={{ height: 150, background: '#a5c9d0' }}
        className="text-center mb-5"
      >
        <img src={model.urlImagem} style={{ width: 170, marginTop: 20 }} />
      </div>
      <div className="text-center px-6 pb-4">
        <div className="text-primary text-lg font-weight-bolder">
          {model.mensagemTitulo}
        </div>
        <div className="text-sm my-4">{model.mensagemPrincipal}</div>
        <div className="text-sm font-weight-bolder">
          {model.mensagemConfirmacao}
        </div>
        {children}
        <div className="d-flex justify-content-center">
          <Button outline className="mr-3" onClick={() => handleClose()}>
            {intl.formatMessage({ id: 'label.cancelar' })}
          </Button>
          <Button className="mr-3" onClick={() => handleNext()}>
            {intl.formatMessage({ id: 'label.proximo' })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertaRevisao;
