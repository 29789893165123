import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl, useIntl } from 'react-intl';
import Table from 'src/componentes/table';
import { Row, Col } from 'reactstrap';
import Button from 'src/componentes/button';
import IconButton from 'src/componentes/icon-button';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from 'src/utils/string';
import Panel from 'src/componentes/panel-collapse';
import useAuth from 'src/hooks/useAuth';
import ContentManager from 'src/componentes/content-manager';
import PlanosContingenciaVinculadosBuscarView from 'src/paginas/gestao-risco/risco/RiscoEditView/PlanosContingenciaVinculados/PlanosContingenciaVinculadosBuscarView';

const PlanosContingenciaVinculadosView = ({
  model,
  allowNew,
  handleNewClick,
  requestChange,
  ...rest
}) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const handleOpenPlanosContingencia = () => {
    ContentManager.addContent(
      <PlanosContingenciaVinculadosBuscarView
        {...rest}
        model={model?.value}
        requestChange={requestChange}
      />
    );
  };

  const handleRemove = (index) => {
    let novaLista = model?.value;
    novaLista.splice(index, 1);
    requestChange && requestChange(novaLista);
  };

  return (
    <Panel
      header={intl.formatMessage({ id: 'labelPlanosContingenciaVinculados' })} 
    >
      <div>
        <Row>
          <Col md={6}>
            <label className="control-label">{model?.label}</label>   
          </Col>
          <Col md={6}>
            {allowNew && (
              <Button
                className="pull-right"
                leftIcon="magnify"
                type="primary"
                style={{ marginBottom: 10 }}
                onClick={handleOpenPlanosContingencia}
              >
                <FormattedMessage id="label.buscar" />
              </Button>
            )}
          </Col>
        </Row>
        {model?.value && model.value.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="nome" />
                </th>
                <th>{capitalizeFirstLetter(resources.responsavel)}</th>
                <th>{capitalizeFirstLetter(resources.area)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {model.value &&
                model.value.map((item, index) => (
                  <tr>
                    <td>{item.nome}</td>
                    <td>{item.responsaveis}</td>
                    <td>{item.area}</td>
                    <td style={{ width: 40 }}>
                      {allowNew && (
                        <IconButton
                          icon="close-circle"
                          className="clarear"
                          onClick={() => handleRemove(index)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <i>
            <FormattedMessage id="label.naoHaItensRelacionados" />
          </i>
        )}
      </div>
    </Panel>
  );
};

export default PlanosContingenciaVinculadosView;
