import React from 'react';
import Edit from '../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import Input from '../../componentes/input';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from '../../utils/string';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  objetivo: Schema.string().label(<FormattedMessage id="objetivo" />),
  observacao: Schema.string().label(<FormattedMessage id="observacao" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, resources, intl } = this.props;
    return (
      <div>
        <Row>
          <Col md={8}>
            <Input model={model.getModel('descricao')} required />
          </Col>
          <Col md={4}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              type="textarea"
              rows={3}
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'objetivo' })
                ),
                value: model.getModel('objetivo').value,
                requestChange: model.getModel('objetivo').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              type="textarea"
              rows={3}
              model={{
                label: capitalizeFirstLetter(resources.observacao),
                value: model.getModel('observacao').value,
                requestChange: model.getModel('observacao').requestChange,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class TemaEstrategicoEdit extends React.Component {
  render() {
    let { resources } = this.props;
    return (
      <Edit
        url="/TemaEstrategico"
        title={capitalizeFirstLetter(resources.temaestrategico)}
        formComponent={FormEdit}
        schema={schema}
        resources={resources}
        width={700}
        height={450}
        {...this.props}
      />
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(TemaEstrategicoEdit));
