import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import InputNumber from 'src/componentes/input-number';
import Checkbox from 'src/componentes/checkbox';
import { autobind } from 'core-decorators';
import schema from './schema';
import { Row, Col, Label, Container } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import http from 'src/services/httpService';
import DataInicioTermino from 'src/paginas/item/componentes/inicio-termino';
import UnidadeMedidaLookup from 'src/lookups/unidade-medida';
import TipoLimiteLookup from 'src/lookups/tipo-limite';

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracaoPontoControle: {},
    };
  }

  handleChange(field, value) {
    let { model } = this.props;

    if (value != model.getModel(field).value) {
      this.setState({
        campoAlterado: true,
      });
    }

    model.getModel(field).requestChange(value);
  }

  render() {
    let { model, intl, disabled } = this.props;

    return (
      <Container>
        <Row>
          <Col md={3}>
            <Input
              disabled={disabled}
              model={model.getModel('idIntegracao')}
              required
            />
          </Col>
          <Col md={5}>
            <DataInicioTermino
              disabled={disabled}
              required
              dataInicio={{
                label: model.getModel('dataInicio').label,
                value: model.getModel('dataInicio').value,
                requestChange: this.handleChange.bind(this, 'dataInicio'),
                errors: model.getModel('dataInicio').errors,
              }}
              dataTermino={{
                label: model.getModel('dataTermino').label,
                value: model.getModel('dataTermino').value,
                requestChange: this.handleChange.bind(this, 'dataTermino'),
              }}
            />
          </Col>
          <Col md={4}>
            <InputNumber
              disabled={disabled}
              required
              precision={0}
              model={{
                label: model.getModel('geracaoAutomatica').label,
                value: model.getModel('geracaoAutomatica').value,
                requestChange: this.handleChange.bind(
                  this,
                  'geracaoAutomatica'
                ),
                errors: model.getModel('geracaoAutomatica').errors,
              }}
              style={{ height: '32px' }}
            ></InputNumber>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <TipoLimiteLookup
              required
              model={{
                label: model.getModel('tipoLimite').label,
                value: model.getModel('tipoLimite').value,
                requestChange: this.handleChange.bind(this, 'tipoLimite'),
                errors: model.getModel('tipoLimite').errors,
              }}
              disabled={disabled}
            />
          </Col>
          <Col md={3}>
            <InputNumber
              disabled={disabled}
              required
              precision={2}
              model={{
                label: intl.formatMessage({ id: 'valorLimite' }),
                value: model.getModel('valor').value,
                requestChange: this.handleChange.bind(this, 'valor'),
                errors: model.getModel('valor').errors,
              }}
            ></InputNumber>
          </Col>
          <Col md={3}>
            <UnidadeMedidaLookup
              required
              model={{
                label: model.getModel('unidadeMedida').label,
                value: model.getModel('unidadeMedida').value,
                requestChange: this.handleChange.bind(this, 'unidadeMedida'),
                errors: model.getModel('unidadeMedida').errors,
              }}
              disabled={true}
            />
          </Col>
          <Col md={3}>
            <InputNumber
              required
              precision={0}
              model={{
                label: model.getModel('casasDecimais').label,
                value: model.getModel('casasDecimais').value,
                requestChange: this.handleChange.bind(this, 'casasDecimais'),
                errors: model.getModel('casasDecimais').errors,
              }}
              disabled={true}
            ></InputNumber>
          </Col>
        </Row>
      </Container>
    );
  }
}

class TipoEvidenciaEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;
    return (
      <Edit
        url="/IndicadorRiscoLimites"
        title={capitalizeFirstLetter(
          intl.formatMessage({ id: 'cadastroLimites' })
        )}
        formComponent={FormEdit}
        schema={schema}
        width={900}
        height={500}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(TipoEvidenciaEdit));
