import { Schema } from '../../../utils/form';
import { FormattedMessage } from 'react-intl';

export default Schema.object({
  utilizarCodigoAutomatico: Schema.array().label(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required({
      onlyIf: (context) => {
        return !context.parent.value.utilizarCodigoAutomatico;
      },
    }),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  area: Schema.string()
    .label(<FormattedMessage id="area" />)
    .required(),
  responsaveis: Schema.array()
    .label(<FormattedMessage id="responsaveis" />)
    .required(),
  processos: Schema.array().label(''),
  incluirSubprocessos: Schema.string().label(''),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  dataInicioItem: Schema.string().label(
    <FormattedMessage id="label.dataDeInicio" />
  ),
  dataTerminoItem: Schema.string().label(
    <FormattedMessage id="label.dataDeTermino" />
  ),
});
