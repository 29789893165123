import { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Alert } from 'reactstrap';
import LoadingContainer from 'src/componentes/loading-container';
import Toolbar from 'src/componentes/NewList/Toolbar';
import Results from 'src/componentes/NewList/Results';
import useAuth from 'src/hooks/useAuth';
import Button from 'src/componentes/button';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import ListColumn from 'src/componentes/NewList/ListColumn';
import errorHandler from 'src/utils/error-handler';
import { getConfiguration } from 'src/services/commonRequests';
import ContentManager from 'src/componentes/content-manager';
import Actions from './actions';
import { getColumns, getData, getPermissions } from 'src/componentes/NewList';
import ControleIndicadorRiscoEdit from './edit';
import Farol from 'src/paginas/farol';
import SvgIcon from 'src/componentes/svg-icon';
import history from 'src/history';
import IndicadorBase from 'src/paginas/indicador-base/view';

function ControleIndicadorRiscoView({
  columnsTag,
  filterTag,
  entityTag,
  defaultSort,
  defaultSortBy,
  parameters,
  endpoint,
  controleId,
  disabled,
  ...rest
}) {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const [updatedItems, setUpdatedItems] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [filter, setFilter] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(defaultSort);
  const [sortFieldBy, setSortFieldBy] = useState(defaultSortBy);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const loadColumns = async () => {
    try {
      const currentColumns = await getColumns(
        defaultColumns,
        entityTag,
        columnsTag
      );
      setColumns(currentColumns.columns);
      setAllColumns(currentColumns.allColumns);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    loadColumns();
  }, []);

  useEffect(() => {
    loadData();
  }, [pageSize, currentPage, searchText, sortField, sortFieldBy]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const newPermissions = await getPermissions(endpoint, parameters);
      setPermissions(newPermissions);

      let newData = [];

      if (newPermissions.allowView || newPermissions.allowList) {
        await loadColumns();

        const model = { searchText };
        newData = await getData(
          endpoint,
          model,
          sortField,
          sortFieldBy,
          pageSize,
          currentPage - 1,
          { ...parameters, controleId: controleId }
        );
        setData(newData.list);
        setCount(newData.count);
      }
      setLoading(false);
      return newData;
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  }, [
    endpoint,
    parameters,
    searchText,
    pageSize,
    currentPage,
    sortField,
    sortFieldBy,
  ]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  });

  const handleRefresh = useCallback(async (filter) => {
    await loadData(defaultColumns, filter);
  });

  const handleChangeCurrentPage = useCallback((newValue) => {
    setCurrentPage(newValue);
  });

  const handleChangePageSize = useCallback((pageSize) => {
    setPageSize(pageSize);
  });

  const handleSortColumn = useCallback((sort) => {
    setSortField(sort);
  });

  const handleSortByColumn = useCallback((sortBy) => {
    setSortFieldBy(sortBy);
  });

  const handleNew = () =>
    ContentManager.addContent(
      <ControleIndicadorRiscoEdit
        endpoint={endpoint}
        parameters={{ controleId: controleId }}
        handleClose={handleRefresh}
      />
    );

  const handleEdit = (item) =>
    ContentManager.addContent(
      <ControleIndicadorRiscoEdit
        endpoint={endpoint}
        parameters={{ controleId: controleId }}
        item={item}
        disabled={disabled}
        handleClose={handleRefresh}
      />
    );

  const renderFarol = (item) => {
    return (
      <span>
        {item.farol && (
          <span
            title={item.farol.descricao}
            className="list-title-item list-item-comum"
          >
            <SvgIcon
              id={'farolid' + item.id}
              icon={item.farol.icone}
              color={item.farol.cor}
              colorHover={item.farol.cor}
              size={item.farol.tamanho}
              title={item.farol.descricao}
            ></SvgIcon>
          </span>
        )}
      </span>
    );
  };

  const renderDescricao = (item, callback) => {
    if (!item.indicadorRisco) {
      return;
    }

    return (
      <a
        tabIndex="-1"
        onClick={handleValueClick.bind(this, item, callback)}
        style={{ cursor: 'pointer' }}
      >
        {item.indicadorRisco}
      </a>
    );
  };

  const handleValueClick = (item) => {
    rest.closeModal && rest.closeModal(true);
    const allowBasic = item.permissions && item.permissions.allowBasic;

    history.push({
      pathname: '/gerenciar/valor',
      search: `?showActions=${!allowBasic}&autoLoad=${!allowBasic}&filtroExpandido=${allowBasic}&itemId=${
        item.indicadorRiscoId
      }`,
    });
  };

  const actions = [
    <Button
      key={1}
      className="ml-2"
      onClick={handleNew}
      type="primary"
      disabled={disabled}
    >
      <MdiIcon icon="plus" />
    </Button>,
  ];

  const renderActions = (item) => {
    return (
      <Actions
        disabled={disabled}
        onRefresh={handleRefresh}
        item={item}
        endpoint={endpoint}
        handleOpenEdit={handleEdit}
      />
    );
  };

  const defaultColumns = [
    <ListColumn
      className="text-right"
      valueField=""
      valueFunction={renderActions}
      default
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'indicadorRisco' }, { risco: resources.risco })
      )}
      valueField="indicadorRisco"
      default
      sortField="indicadorRisco"
      style={{ minWidth: '50%' }}
      valueFunction={renderDescricao}
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'status' }))}
      valueField="status"
      default
      sortField="status"
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'periodicidade' })
      )}
      valueField="periodicidade"
      default
      sortField="periodicidade"
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        capitalizeFirstLetter(resources.responsaveis)
      )}
      valueField="responsavel"
      default
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'observacao' })
      )}
      valueField="observacao"
      default
      sortField="observacao"
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'area' }, { area: resources.area })
      )}
      valueField="area"
      default
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'codigo' }))}
      valueField="codigo"
      default
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'unidadeMedida' })
      )}
      valueField="unidadeMedida"
      default
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(resources.farol)}
      valueField="farol"
      valueFunction={renderFarol}
      default
    />,
  ];

  return (
    <LoadingContainer isLoading={isLoading}>
      <Toolbar
        useSavedFilter
        showSearch
        onSearchChange={handleSearchChange}
        onRefresh={handleRefresh}
        searchText={searchText}
        filter={filter}
        filterTag={filterTag}
        columnsTag={columnsTag}
        columns={columns}
        allColumns={allColumns}
        actions={actions}
        parameters={{ controleId: controleId }}
      />
      <Results
        resources={resources}
        count={count}
        columns={columns}
        data={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangeCurrentPage={handleChangeCurrentPage}
        onChangePageSize={handleChangePageSize}
        onSortColumn={handleSortColumn}
        onSortByColumn={handleSortByColumn}
        sort={sortField}
        sortBy={sortFieldBy}
        updatedItems={updatedItems}
      />
    </LoadingContainer>
  );
}

ControleIndicadorRiscoView.defaultProps = {
  endpoint: '/PontoControleIndicadorRisco',
  entityTag: null,
  defaultSort: 'indicadorRisco.UltimaVersao.Descricao',
  defaultSortBy: 'asc',
  selectedFilter: null,
  openLastFilter: false,
  parameters: {},
  showToolbar: true,
};

export default ControleIndicadorRiscoView;
