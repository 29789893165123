import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class TipoOrganizacao extends React.Component {
  render() {
    return <Lookup clearable={false} labelKey="descricao" valueKey="id" url="/TipoOrganizacaoLookup" {...this.props} />;
  }
}

export default TipoOrganizacao;
