import Lookup from 'src/componentes/select2';

const FuncionalidadeTagLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/FuncionalidadeTagLookup"
    clearable={false}
    {...rest}
  />
);

export default FuncionalidadeTagLookup;
