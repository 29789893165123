import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Form } from 'src/utils/form';
import { capitalizeFirstLetter } from 'src/utils/string';
import http from 'src/services/httpService';
import SvgIcon from 'src/componentes/svg-icon';
import MdiIcon from 'src/componentes/mdi-icon';
import Chip from 'src/componentes/chip';
import moment from 'moment';

class FiltroChip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilter: this.props.modelValue
        ? this.props.modelValue
        : this.props.selectedFilter,
      updateProps: false,
      showLeftArrow: false,
      showRightArrow: true,
    };

    this.form = new Form({ component: this, schema: this.props.searchSchema });
    this.divScroll = React.createRef();
  }

  componentDidMount() {
    this.checkScroll();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedFilter } = this.props;

    if (prevProps.selectedFilter != selectedFilter) {
      this.setState({
        selectedFilter: selectedFilter,
      });
      this.checkScroll();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.selectedFilter)
      this.setState({ selectedFilter: newProps.selectedFilter });
  }

  selectCampoTabela(opcoesTabela, objFiltragem) {
    if (Array.isArray(objFiltragem))
      return opcoesTabela.filter(
        (opcaoTabela) =>
          objFiltragem.filter(({ id }) => opcaoTabela.id == id).length
      );

    return opcoesTabela.filter(
      (opcaoTabela) => opcaoTabela.id.toString() == objFiltragem
    );
  }

  handleLabel(obj, index, selectedFilter, check, indexCampo) {
    let { intl, resources } = this.props;
    let label = '';
    let objectIndex = Object.keys(selectedFilter);
    let labelObject = this.form.getModel(objectIndex[index]).label;
    let campoCustomizado =
      selectedFilter.camposCustomizados &&
      selectedFilter.camposCustomizados[index] &&
      selectedFilter.camposCustomizados[index].descricaoCampo;
    let finalLabel =
      labelObject && labelObject.props
        ? labelObject.props.id
          ? labelObject.props.id
          : 'label.a'
        : campoCustomizado;
    let termo = this.form.getModel(objectIndex[index]).label ? false : true;

    if (obj.nome != null) {
      label = obj.nome;
    } else if (obj.descricao != null) {
      label = obj.descricao;
    } else if (typeof obj == 'string') {
      label = obj;
    } else if (objectIndex[indexCampo] == 'camposCustomizados') {
      if (obj.campo.opcoesTabela != null && obj.campo.opcoesTabela.length > 0) {
        if (obj.campo && obj.campo.multiSelecao)
          var campoTabela = this.selectCampoTabela(
            obj.campo.opcoesTabela,
            obj.campo.valores
          );
        else
          var campoTabela = this.selectCampoTabela(
            obj.campo.opcoesTabela,
            obj.valor
          );

        label =
          campoTabela.length > 0 &&
          campoTabela.map(({ descricao }) => descricao).join('; ');

        return obj.campo.nome + ': ' + label;
      }
      return obj.campo.nome + ': ' + obj.valor;
    } else if (obj.length > 0) {
      if (obj[0].nome) label = obj.map((x) => x.nome).join(', ');
      else if (obj[0].descricao) label = obj.map((x) => x.descricao).join(', ');
      else
        obj.map(
          (x) =>
            (label =
              x.campo != null && x.valor != ''
                ? x.campo.nome
                : label === ''
                ? x.nome
                : label + ', ' + x.nome)
        );
    } else if (Object.prototype.toString.call(obj) === '[object Date]') {
      label =
        obj.getDate() + '/' + (obj.getMonth() + 1) + '/' + obj.getFullYear();
    } else if (typeof obj == 'number') {
      label = '' + obj;
    }

    if (objectIndex[index] == 'id' || objectIndex[index] == 'altered') {
      return;
    }

    const objArray = Array.isArray(obj);

    if (termo) {
      const path = resources[this.form.getModel(objectIndex[index]).path];

      if (label && path)
        return check
          ? capitalizeFirstLetter(path)
          : capitalizeFirstLetter(path) + ': ' + label;
      else if (objectIndex && objectIndex[index])
        switch (objectIndex[index]) {
          case 'areasSubordinadas':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'label.areaSubordinada' },
                { area: resources.area }
              )
            );
          case 'areaSubordinada':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'label.areaSubordinada' },
                { area: resources.area }
              )
            );
          case 'ocultarParametros':
            return capitalizeFirstLetter(resources.parametros);
          case 'usarCorFarolRealizado':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'usarCorFarolRealizado' },
                { realizado: resources.valorrealizado }
              )
            );
          case 'filtrarAcoesEspelho':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'filtrarAcoesEspelho' },
                { acoes: resources.acoes }
              )
            );
          case 'areaColaboradores':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'label.colaboradoresArea' },
                  {
                    colaboradores: resources.colaboradores,
                    area: resources.area,
                  }
                )
              ) +
              ': ' +
              label
            );
          case 'pontoControle':
            return capitalizeFirstLetter(
              resources.pontodecontrole + ': ' + obj.descricao
            );
          case 'apenasAcoesModuloAtual':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'label.apenasAcoesModuloAtual' },
                { acoes: resources.acoes }
              )
            );
          case 'exibirAcoesComoMembroEquipe':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'labelConsiderarResponsavelNaEquipe' },
                { acoes: resources.acoes }
              )
            );
          case 'somenteAcoesFilhas':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'labelSomenteAcoesFilhas' },
                { acoes: resources.acoes }
              )
            );
          case 'apenasAcoesOrigem':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'label.apenasAcoesOrigem' },
                { acoes: resources.acoes }
              )
            );
          case 'tipoRisco':
            return (
              capitalizeFirstLetter(intl.formatMessage({ id: 'tipo' })) +
              ': ' +
              obj.descricao
            );
          case 'matrizRisco':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'matrizRisco' },
                  { risco: resources.risco }
                )
              ) +
              ': ' +
              obj.descricao
            );
          case 'grauRisco':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'grauRisco' },
                  { risco: resources.risco }
                )
              ) +
              ': ' +
              (obj.descricao ? obj.descricao : `Id (${obj.id})`)
            );
          case 'grausRisco':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'grauRisco' },
                  { risco: resources.risco }
                )
              ) +
              ': ' +
              label
            );
          case 'riscoAtrelado':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelRiscoAtrelado' },
                  { risco: resources.risco }
                )
              ) +
              ': ' +
              (obj.descricao ? obj.descricao : `Id (${obj.id})`)
            );
          case 'grausRiscoInerente':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelGrauRiscoInerente' },
                  { risco: resources.risco }
                )
              ) +
              ': ' +
              label
            );
          case 'grausRiscoResidual':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelGrauRiscoResidual' },
                  { risco: resources.risco }
                )
              ) +
              ': ' +
              label
            );
          case 'severidadeInerente':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelSeveridadeInerente' },
                  { severidade: resources.severidade }
                )
              ) +
              ': ' +
              label
            );
          case 'severidadeResidual':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelSeveridadeResidual' },
                  { severidade: resources.severidade }
                )
              ) +
              ': ' +
              label
            );
          case 'probabilidadeInerente':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelProbabilidadeInerente' },
                  { probabilidade: resources.probabilidade }
                )
              ) +
              ': ' +
              label
            );
          case 'probabilidadeResidual':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelProbabilidadeResidual' },
                  { probabilidade: resources.probabilidade }
                )
              ) +
              ': ' +
              label
            );
          case 'toleranciaRisco':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelToleranciaRisco' },
                  {
                    risco: resources.risco,
                  }
                )
              ) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.descricao
                : '')
            );
          case 'toleranciaRiscoFaixas':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelRatingRisco' },
                  {
                    risco: resources.risco,
                  }
                )
              ) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.descricao
                : '')
            );
          case 'causasRisco':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelCausasDoRisco' },
                  {
                    pontosproblematicos: resources.pontosproblematicos,
                    risco: resources.risco,
                  }
                )
              ) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.descricao
                : '')
            );
          case 'consequenciasRisco':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelConsequenciasDoRisco' },
                  {
                    consequencias: resources.consequencias,
                    risco: resources.risco,
                  }
                )
              ) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.descricao
                : '')
            );
          case 'areas':
            return (
              capitalizeFirstLetter(resources.areas) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.nome;
                      })
                      .join(', ')
                  : obj.nome
                : '')
            );
          case 'processos':
            return (
              capitalizeFirstLetter(resources.processos) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.nome;
                      })
                      .join(', ')
                  : obj.nome
                : '')
            );
          case 'tipoPontoControle':
            return (
              intl.formatMessage(
                { id: 'tipoPontoControle' },
                { pontodecontrole: resources.pontodecontrole }
              ) +
              ': ' +
              obj.descricao
            );
          case 'aging':
            return intl.formatMessage({ id: 'aging' }) + ': ' + obj.descricao;
          case 'responsavel':
            return capitalizeFirstLetter(
              resources.responsavel + ': ' + obj.nome
            );
          case 'tipoAcao':
            return `${capitalizeFirstLetter(
              intl.formatMessage(
                { id: objectIndex[index] },
                { acao: resources.acao }
              )
            )}: ${obj.descricao}`;
          case 'incluirSubprocessos':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'incluirSubprocessos' },
                {
                  processosfilhosplural:
                    resources.processosfilhosplural.toLowerCase(),
                }
              )
            );
          case 'tema':
            return capitalizeFirstLetter(
              resources.temaestrategico + ': ' + obj.descricao
            );
          case 'responsaveisProjeto':
            return (
              capitalizeFirstLetter(resources.responsaveisprojeto) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.nome;
                      })
                      .join(', ')
                  : obj.nome
                : '')
            );
          case 'funcaoPontoControle':
            return (
              intl.formatMessage(
                { id: 'funcaoPontoControle' },
                { pontodecontrole: resources.pontodecontrole }
              ) +
              ': ' +
              obj.descricao
            );
          case 'tipoDeControle':
            return (
              capitalizeFirstLetter(
                intl.formatMessage({ id: 'tipoDeControle' })
              ) +
              ': ' +
              obj.descricao
            );
          case 'nivelAcao':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'nivelAcao' },
                  { acao: resources.acao }
                )
              ) +
              ': ' +
              obj.descricao
            );
          case 'criadorAcao':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'criadorAcao' },
                { acao: resources.acao }
              )
            );
          case 'dataCriacaoAcao':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'dataCriacaoAcao' },
                { acao: resources.acao }
              )
            );
          case 'statusAprovacao':
            return capitalizeFirstLetter(intl.formatMessage({ id: 'status' }));
          case 'listaStatus':
            return (
              capitalizeFirstLetter(intl.formatMessage({ id: 'status' })) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.nome
                : '')
            );
          case 'acaoCadastradaAprovacao':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'acaoCadastradaAprovacao' },
                { acao: resources.acao, acoes: resources.acoes }
              )
            );
          case 'projeto':
            return capitalizeFirstLetter(resources.projeto);

          case 'listaDesempenho':
            return (
              capitalizeFirstLetter(resources.desempenho) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.descricao
                : '')
            );

          case 'listaFarois':
            return (
              capitalizeFirstLetter(resources.desempenho) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.legenda;
                      })
                      .join(', ')
                  : obj.legenda
                : '')
            );

          case 'statusAvaliacao':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'statusAvaliacao' },
                  { avaliacao: resources.avaliacao }
                )
              ) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.descricao
                : '')
            );

          case 'farolPontoControle':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'farolPontoControle' },
                  { pontodecontrole: resources.pontodecontrole }
                )
              ) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.descricao
                : '')
            );

          case 'tipoFiltroData':
            return (
              intl.formatMessage({ id: 'label.tipoFiltroData' }) +
              ': ' +
              obj.descricao
            );

          case 'tipoFiltroDataCriacaoAcao':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'tipoFiltroDataCriacaoAcao' },
                  { acao: resources.acao }
                )
              ) +
              ': ' +
              obj.descricao
            );

          case 'dataInicialCriacaoAcao':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'dataInicialCriacaoAcao' },
                  { acao: resources.acao }
                )
              ) +
              ': ' +
              moment(obj).format('MM/YYYY')
            );

          case 'dataFinalCriacaoAcao':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'dataFinalCriacaoAcao' },
                  { acao: resources.acao }
                )
              ) +
              ': ' +
              moment(obj).format('MM/YYYY')
            );

          case 'tipoFiltroDataPeriodoCSA':
            return (
              capitalizeFirstLetter(
                intl.formatMessage({ id: 'tipoFiltroDataPeriodoCSA' })
              ) +
              ': ' +
              obj.descricao
            );

          case 'dataInicialPeriodoCSA':
            return (
              capitalizeFirstLetter(
                intl.formatMessage({ id: 'dataInicialPeriodoCSA' })
              ) +
              ': ' +
              moment(obj).format('MM/YYYY')
            );

          case 'dataFinalPeriodoCSA':
            return (
              capitalizeFirstLetter(
                intl.formatMessage({ id: 'dataFinalPeriodoCSA' })
              ) +
              ': ' +
              moment(obj).format('MM/YYYY')
            );

          case 'farolAcumulado':
            return capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'farolAcumulado' },
                { farolAcumulado: resources.farolAcumulado }
              )
            );
          case 'tags':
            return capitalizeFirstLetter(
              intl.formatMessage({ id: 'tags' }, { tags: resources.tags })
            );
          case 'statusBaseline':
            return (
              capitalizeFirstLetter(
                intl.formatMessage({ id: 'labelStatusBaseLine' })
              ) +
              ': ' +
              obj.descricao
            );

          case 'riscosVinculados':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'labelRiscosVinculados' },
                  { riscos: resources.riscos }
                )
              ) +
              ': ' +
              label
            );

          case 'responsaveisPeloInput':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'responsaveisPeloInput' },
                  { responsaveis: resources.responsaveis }
                )
              ) +
              ': ' +
              label
            );

          case 'controles':
            let descricoes = obj?.length
              ? obj.map((item) => item.descricao).join(', ')
              : '';
            return (
              capitalizeFirstLetter(resources.pontosdecontrole) +
              ': ' +
              descricoes
            );

          case 'tratamentoRisco':
            return (
              capitalizeFirstLetter(intl.formatMessage({ id: 'tratamento' })) +
              ': ' +
              label
            );

          case 'responsavelItem':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'responsavelItem' },
                  { responsavel: resources.responsavel, item: resources.item }
                )
              ) +
              ': ' +
              label
            );

          case 'statusAcaoList':
            return (
              capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'statusAcao' },
                  { acao: resources.acao }
                )
              ) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.descricao;
                      })
                      .join(', ')
                  : obj.descricao
                : '')
            );

          case 'responsaveisSimplificado':
            return (
              capitalizeFirstLetter(resources.responsaveis) +
              ': ' +
              (obj
                ? objArray
                  ? obj
                      .map((item) => {
                        return item.nome;
                      })
                      .join(', ')
                  : obj.nome
                : '')
            );

          default:
            return label
              ? intl.formatMessage({ id: objectIndex[index] }) + ': ' + label
              : intl.formatMessage({ id: objectIndex[index] });
        }
    } else {
      switch (finalLabel) {
        case 'farolAcumulado':
          return capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'farolAcumulado' },
              { farolAcumulado: resources.farolAcumulado }
            )
          );
        case 'labelRiscoAtrelado':
          return (
            capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'labelRiscoAtrelado' },
                { risco: resources.risco }
              )
            ) +
            ': ' +
            label
          );
        case 'labelRiscosVinculados':
          return (
            capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'labelRiscosVinculados' },
                { riscos: resources.riscos }
              )
            ) +
            ': ' +
            label
          );
        case 'areasDoRisco':
          return (
            capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'areasDoRisco' },
                { areas: resources.areas, risco: resources.risco }
              )
            ) +
            ': ' +
            label
          );

        case 'areasDoControle':
          return (
            capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'areasDoControle' },
                {
                  areas: resources.areas,
                  pontodecontrole: resources.pontodecontrole,
                }
              )
            ) +
            ': ' +
            label
          );

        case 'tags':
          return (
            capitalizeFirstLetter(
              intl.formatMessage({ id: 'tags' }, { tags: resources.tags })
            ) +
            ': ' +
            label
          );

        case 'dataInicio':
          return (
            capitalizeFirstLetter(intl.formatMessage({ id: 'dataInicio' })) +
            ': ' +
            moment(obj).format(
              this.props.dateFormat ? this.props.dateFormat : 'DD/MM/yyyy'
            )
          );

        case 'dataTermino':
          return (
            capitalizeFirstLetter(intl.formatMessage({ id: 'dataTermino' })) +
            ': ' +
            moment(obj).format(
              this.props.dateFormat ? this.props.dateFormat : 'DD/MM/yyyy'
            )
          );

        case 'deDataInicial':
        case 'deDataInicialExecucao':
        case 'deDataInicialConclusao':
          return (
            capitalizeFirstLetter(intl.formatMessage({ id: 'deDataInicial' })) +
            ': ' +
            moment(obj).format('MM/yyyy')
          );

        case 'ateDataFinal':
        case 'ateDataFinalExecucao':
        case 'ateDataFinalConclusao':
          return (
            capitalizeFirstLetter(intl.formatMessage({ id: 'ateDataFinal' })) +
            ': ' +
            moment(obj).format('MM/yyyy')
          );
      }
    }

    return check
      ? intl.formatMessage(
          { id: finalLabel },
          { centroscusto: resources.centroscusto }
        )
      : finalLabel
      ? intl.formatMessage(
          { id: finalLabel },
          {
            centrocusto: resources.centrocusto,
            responsavel: capitalizeFirstLetter(resources.responsavel),
          }
        ) +
        ': ' +
        label
      : label;
  }

  handleFarol(farol) {
    return farol.cor == null ? (
      <MdiIcon className="d-flex" icon={farol.icone} />
    ) : (
      <SvgIcon
        className="d-flex mr-n1"
        style={{ marginTop: -2 }}
        icon={farol.icone}
        color={farol.cor}
      />
    );
  }

  removeChip(filter, index, indexCampo) {
    const { selectedFilter } = this.state;

    let objectIndex = Object.keys(filter);
    let field = objectIndex[indexCampo ? indexCampo : index];

    if (this.props.preventRemoveFilterChip) {
      const prevent = this.props.preventRemoveFilterChip(
        field,
        this.state.selectedFilter
      );
      if (prevent) return;
    }

    this.props.onRemoveFilterChip && this.props.onRemoveFilterChip(field);

    if (field === 'camposCustomizados') {
      selectedFilter.camposCustomizados &&
        selectedFilter.camposCustomizados.splice(index, 1);

      let list = selectedFilter.camposCustomizados;
      this.setState(
        {
          selectedFilter: {
            ...this.state.selectedFilter,
            [field]: list,
          },
          updateProps: false,
        },
        async () => {
          await this.saveFilter();
          this.props.onChangeUrl &&
            this.props.onChangeUrl(this.state.selectedFilter);
        }
      );
    } else {
      const currentFilter = {
        ...this.state.selectedFilter,
        [field]: selectedFilter[field] === true ? false : null,
      };

      this.setState(
        {
          selectedFilter: currentFilter,
        },
        async () => {
          await this.saveFilter();
          this.props.onChangeUrl && this.props.onChangeUrl(currentFilter);
        }
      );
    }
  }

  async saveFilter() {
    let { filtroId, onRemoveSaveFilter = true } = this.props;
    if (onRemoveSaveFilter) {
      await http
        .post(`/FiltroColaborador/${filtroId}/SalvarFiltro`, {
          valor: this.state.selectedFilter,
          filtroId: filtroId,
        })
        .then((response) => {
          this.props.onSearch && this.props.onSearch(response.data, true);
          this.setState({
            updateProps: false,
          });
        });
    } else {
      this.props.onSearch &&
        this.props.onSearch({ ...this.state.selectedFilter }, true);
      this.setState({
        updateProps: false,
      });
    }
  }

  isNotBoolean(filtro) {
    return (
      filtro !== true &&
      filtro !== false &&
      filtro !== 'true' &&
      filtro !== 'false'
    );
  }

  renderChip(filtro, index, indexCampo) {
    let { selectedFilter } = this.state;
    if (this.props.selectedFilter && selectedFilter === null)
      selectedFilter = this.props.selectedFilter;

    let chipType = null;
    let labelType;
    let objectIndex = Object.keys(selectedFilter);

    if (filtro != null && filtro.icone != null) {
      chipType = this.handleFarol(filtro);
      labelType = this.handleLabel(
        filtro,
        index,
        selectedFilter,
        !filtro.descricao
      );
    } else if (
      indexCampo != null &&
      objectIndex[indexCampo] === 'camposCustomizados'
    ) {
      labelType = this.handleLabel(
        filtro,
        index,
        selectedFilter,
        null,
        indexCampo
      );
    } else if (filtro != null && filtro != '' && this.isNotBoolean(filtro)) {
      labelType = this.handleLabel(filtro, index, selectedFilter);
    } else if (filtro == true || filtro == 'true') {
      chipType = <MdiIcon className="d-flex" icon="check" />;
      labelType = this.handleLabel(filtro, index, selectedFilter, true);
    }
    if (chipType == null && labelType == null) {
      return;
    }
    return (
      filtro &&
      labelType &&
      !labelType.includes('undefined') && (
        <div style={{ marginRight: 10 }}>
          <Chip
            id={'chip' + index}
            data-tip
            data-for={'chip' + index}
            Icon={chipType}
            style={{
              maxWidth: '650px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              deletableColorPrimary: 'green',
            }}
            label={labelType}
            onDelete={() => this.removeChip(selectedFilter, index, indexCampo)}
          />

          <ReactTooltip id={'chip' + index} aria-haspopup="true">
            {labelType}
          </ReactTooltip>
        </div>
      )
    );
  }

  scroll(scrollOffset) {
    if (this.divScroll.current) {
      const newValue = this.divScroll.current.scrollLeft + scrollOffset;
      const maxValue =
        this.divScroll.current.scrollWidth - this.divScroll.current.clientWidth;

      this.divScroll.current.scrollLeft = newValue;

      if (newValue > 0 && maxValue != 0) {
        this.setState({
          showLeftArrow: true,
        });
      } else {
        this.setState({
          showLeftArrow: false,
        });
      }

      if (newValue >= maxValue || maxValue == 0) {
        this.setState({
          showRightArrow: false,
        });
      } else {
        this.setState({
          showRightArrow: true,
        });
      }
    }
  }

  checkScroll() {
    if (this.divScroll.current) {
      const maxValue =
        this.divScroll.current.scrollWidth - this.divScroll.current.clientWidth;

      if (maxValue == 0) {
        this.setState({
          showRightArrow: false,
          showLeftArrow: false,
        });
      } else {
        this.setState({
          showRightArrow: true,
        });
      }
    }
  }

  render() {
    const { intl } = this.props;
    let { selectedFilter, showLeftArrow, showRightArrow } = this.state;

    let filtroList =
      selectedFilter &&
      (selectedFilter.valor != null
        ? Object.values(selectedFilter.valor)
        : Object.values(selectedFilter));
    let objectIndex =
      selectedFilter &&
      (selectedFilter.valor != null
        ? Object.getOwnPropertyNames(selectedFilter.valor)
        : Object.getOwnPropertyNames(selectedFilter));

    return (
      <div className="w-100 position-relative">
        <div
          className="d-flex flex-nowrap chips-wrapper mx-2 h-100 align-items-center"
          ref={this.divScroll}
        >
          {filtroList &&
            filtroList.map((filtro, index) =>
              filtro &&
              filtro.length > 0 &&
              objectIndex[index] === 'camposCustomizados'
                ? filtro.map(
                    (campo, indexCampo) =>
                      (campo.valor ||
                        (campo.campo.valores && campo.campo.multiSelecao)) &&
                      this.renderChip(campo, indexCampo, index)
                  )
                : this.renderChip(filtro, index)
            )}

          {showLeftArrow && (
            <div
              id="filter-left-arrow"
              title={capitalizeFirstLetter(
                intl.formatMessage({ id: 'label.anterior' })
              )}
              className="position-absolute d-flex align-items-center h-100 justify-center"
              style={{
                left: 0,
                color: '#868282',
                border: 'none',
                padding: '1rem 0.5rem',
                cursor: 'pointer',
                width: 55,
              }}
              onClick={() => this.scroll(-150)}
            >
              <MdiIcon style={{ zIndex: 2 }} size={35} icon="chevron-left" />
            </div>
          )}

          {showRightArrow && (
            <div
              id="filter-right-arrow"
              title={capitalizeFirstLetter(
                intl.formatMessage({ id: 'label.proximo' })
              )}
              className="position-absolute d-flex align-items-center h-100 justify-center"
              style={{
                right: 0,
                color: '#868282',
                border: 'none',
                padding: '1rem 0.5rem',
                cursor: 'pointer',
                width: 55,
              }}
              onClick={() => this.scroll(150)}
            >
              <MdiIcon style={{ zIndex: 2 }} size={35} icon="chevron-right" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(FiltroChip));
