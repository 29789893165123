import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Table from 'src/componentes/table';
import Results from 'src/componentes/NewList/Results';

const columnStyle = {
  background: '#D9D9D9',
  borderRadius: 10,
  height: 15,
};

const columnHeaderStyle = {
  ...columnStyle,
  background: '#C0C0C0',
};

const columnMenuStyle = {
  ...columnStyle,
  borderRadius: '50%',
  width: 15,
  margin: '0 auto',
};

const columnNameStyle = {
  ...columnStyle,
  width: '100%',
};

const List = ({
  sortField = '',
  sortFieldBy = 'asc',
  columns,
  data,
  page,
  handleChangePage,
  isLoading,
  expandedIds,
  isExpanded,
  handleToggleExpand,
  renderMenu,
  handleSortColumn,
  showSearch,
  showNew,
  usingWidget,
  getRowColor,
  getRowTextColor,
  infoMessage,
  hasFilters,
  showSortIcon,
  showFullTextColumns,
  containerSize,
  showMenu = true,
  entitiesKey = '',
  skeletonItensSize = 5,
  skeletonColumnsSize = 10,
  onRefresh,
  select = false,
  setSelectedItems,
  selectedItems,
  ...rest
}) => {
  const intl = useIntl();

  const infinityScrollRef = useRef();

  const currentInfinityScroll = infinityScrollRef?.current;

  const handleInfityScrollRules = () => {
    const refIsLoading =
      currentInfinityScroll.classList?.contains('isLoading-ref');

    if (
      !refIsLoading &&
      Math.ceil(currentInfinityScroll.scrollTop) +
        Math.ceil(currentInfinityScroll.clientHeight) >=
        Math.ceil(currentInfinityScroll.scrollHeight)
    ) {
      handleChangePage((prev) => prev + 1);
    }
  };

  const handleAddScrollListener = () =>
    currentInfinityScroll.addEventListener(
      'scroll',
      handleInfityScrollRules,
      true
    );

  const handleRemoveScrollListener = () =>
    currentInfinityScroll.removeEventListener(
      'scroll',
      handleInfityScrollRules,
      true
    );

  useEffect(() => {
    if (currentInfinityScroll) {
      handleAddScrollListener();
      return () => handleRemoveScrollListener();
    }
  }, [currentInfinityScroll]);

  const getfakeColumns = (size = skeletonColumnsSize) => Array(size).fill(0);
  const getfakeItems = (size = skeletonItensSize) => Array(size).fill(0);

  const { lista, contadorExibicao, contadorTotal } = data || {};

  const hasData = lista?.length > 0;

  const renderSkeletonContent = (initializing = true, padding = 0) => {
    const renderInfo = () => (
      <div
        style={{ paddingRight: '1.25rem' }}
        className="d-flex justify-content-end align-items-center mb-2 text-sm skeleton"
      >
        <div>{infoMessage}</div>
      </div>
    );

    const renderHeader = () => (
      <thead className="skeleton">
        {getfakeColumns().map((_, index) => {
          const isFirstOne = index === 0;
          const isFirstOneAfterMenu = index === 1;

          return (
            <th
              style={{
                width: isFirstOne ? 30 : isFirstOneAfterMenu ? 250 : '',
              }}
            >
              <div style={!isFirstOne ? columnHeaderStyle : {}} />
            </th>
          );
        })}
      </thead>
    );

    const renderContent = (columnsSize) =>
      getfakeItems(skeletonItensSize).map((_) => (
        <tr className="skeleton bg-white">
          {getfakeColumns(columnsSize).map((_, index) => {
            const isFirstOne = index === 0;
            const isFirstOneAfterMenu = index === 1;

            return (
              <td
                style={{
                  paddingLeft: isFirstOneAfterMenu ? padding || '' : 0,
                  paddingRight: isFirstOne ? 0 : '',
                }}
              >
                <div
                  style={
                    isFirstOneAfterMenu
                      ? columnNameStyle
                      : isFirstOne
                      ? columnMenuStyle
                      : columnStyle
                  }
                />
              </td>
            );
          })}
        </tr>
      ));

    if (initializing)
      return (
        <>
          {infoMessage && renderInfo()}
          <Table hover={false} styleDiv={{}} style={{ marginBottom: '-1rem' }}>
            {renderHeader()}
            <tbody>{renderContent()}</tbody>
          </Table>
        </>
      );

    const originalColumnsLength = columns?.length + 1;
    return renderContent(originalColumnsLength);
  };

  const renderFakeChildren = (_, padding) =>
    renderSkeletonContent(false, padding);

  const renderContent = () => (
    <>
      {hasData && !usingWidget && (
        <div
          style={{ paddingRight: '1.25rem' }}
          className="d-flex justify-content-end align-items-center mb-2 text-sm"
        >
          {hasFilters
            ? intl.formatMessage(
                { id: 'listandoResultadosFiltrados' },
                { 1: contadorTotal, 2: entitiesKey }
              )
            : intl.formatMessage(
                { id: 'listandoContadorETotal' },
                {
                  1: contadorExibicao,
                  2: contadorTotal,
                  3: entitiesKey,
                }
              )}
        </div>
      )}
      <div
        ref={infinityScrollRef}
        className={`${!usingWidget ? 'overflow-auto new-list-tree' : ''} ${
          isLoading ? 'isLoading-ref' : ''
        }`}
        style={
          !usingWidget ? { maxHeight: `calc(100vh - ${containerSize}px)` } : {}
        }
      >
        <Results
          {...rest}
          sticky
          responsive={false}
          showMenu={showMenu}
          showSearch={showSearch}
          showNew={showNew}
          renderMenu={renderMenu}
          count={contadorTotal}
          columns={columns}
          data={lista}
          currentPage={page}
          showPagination={false}
          expandedIds={expandedIds}
          isExpanded={isExpanded}
          handleToggleExpand={handleToggleExpand}
          sort={sortField}
          sortBy={sortFieldBy}
          isLoading={isLoading}
          renderFakeChildrenWhileLoading={renderFakeChildren}
          renderContentWhileLoading={() => renderSkeletonContent(false)}
          onSortColumn={handleSortColumn}
          onChangeCurrentPage={handleChangePage}
          getRowColor={getRowColor}
          getRowTextColor={getRowTextColor}
          showSortIcon={showSortIcon}
          showFullTextColumns={showFullTextColumns}
          onRefresh={onRefresh}
          select={select}
          onSelect={setSelectedItems}
          selectedItems={selectedItems}
        />
      </div>
    </>
  );

  const isFirstPage = page === 0;
  const skeletonIsValid = isFirstPage && isLoading;

  return (
    <>
      <div style={{ display: skeletonIsValid ? 'block' : 'none' }}>
        {renderSkeletonContent()}
      </div>
      <div style={{ display: !skeletonIsValid ? 'block' : 'none' }}>
        {renderContent()}
      </div>
    </>
  );
};

export default List;
