import { Schema } from 'src/utils/form';

const schema = Schema.object({
  acao: Schema.object().required(),
  descricao: Schema.string().required(),
  concluida: Schema.boolean(),
  responsavel: Schema.object(),
  dataConclusao: Schema.string(),
});

export default schema;
