import React from 'react';
import { autobind } from 'core-decorators';

@autobind
class Tab extends React.Component {
  isActive() {
    return this.props.active;
  }

  render() {
    let { active, key, children, onClick } = this.props;

    if (!active && !this.rendered) {
      return null;
    }

    this.rendered = true;

    return (
      <div
        onClick={onClick && onClick()}
        className={`tab-pane ${active ? 'active' : ''} `}
        id={key}
      >
        {children}
      </div>
    );
  }
}

export default Tab;
