export default function pPipe(...functions) {
  if (functions.length === 0) {
    throw new Error('É esperado ao menos um argumento');
  }

  return async (input) => {
    let currentValue = input;

    for (const function_ of functions) currentValue = await function_(currentValue);

    return currentValue;
  };
}
