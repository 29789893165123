import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import MdiIcon from '../mdi-icon/index';
import { FormattedMessage } from 'react-intl';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import http from 'src/services/httpService';

@autobind
class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: props.pageSize,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) return nextProps;
    else return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== prevState) {
      this.setState({ ...this.props });
    }
  }

  movePage(diff) {
    this.props.changeCurrentPage(this.props.currentPage + diff);
  }

  range(start, length) {
    let array = [];
    for (let i = 0; i < length; i++) {
      array.push(start + i);
    }

    return array.reverse();
  }

  async componentDidMount() {
    if (this.props.filtroId) {
      await http
        .post(`/ListagemTelaColaborador/ObterPageSize`, {
          listId: this.props.filtroId,
        })
        .then((response) => {
          if (response.data) {
            this.setState({
              pageSize: response.data,
            });
          }
        });
    }
  }

  beforeChangePageSize(size) {
    const pageCount = Math.max(Math.ceil(this.props.count / size), 1);
    if (this.props.currentPage > pageCount)
      this.props.changeCurrentPage(pageCount);

    this.props.changePageSize(size);
  }

  render() {
    let {
      count,
      currentPage,
      allowPaging,
      changeCurrentPage,
      changePageSize,
      showPaginacaoSimples,
      ...other
    } = this.props;

    const { pageSize } = this.state;
    const pageCount = Math.max(Math.ceil(count / pageSize), 1);
    const pages = this.range(1, pageCount);

    return (
      <div style={{ display: 'table' }} {...other}>
        {!showPaginacaoSimples && (
          <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <span className="mr-2">{count}</span>
            <span className="mr-2">
              <FormattedMessage id="label.itens" />
            </span>
            <MdiIcon
              icon="refresh"
              style={{ cursor: 'pointer' }}
              onClick={this.movePage.bind(this, 0)}
              color="#666"
            />
          </div>
        )}
        {allowPaging && pageSize != 999999 && (
          <div
            style={{ display: 'table-cell', verticalAlign: 'middle' }}
            className="pl-3"
          >
            <span className="mr-2">
              <FormattedMessage id="label.pagina" />:
            </span>
            <MdiIcon
              icon="arrow-left-drop-circle"
              style={{ cursor: 'pointer' }}
              // onClick={currentPage > 1 && this.movePage.bind(this, -1)}
              onClick={
                currentPage > 1 ? this.movePage.bind(this, -1) : undefined
              }
              color="#666"
            />
          </div>
        )}
        {allowPaging && pageSize != 999999 && (
          <div
            style={{ display: 'table-cell', verticalAlign: 'middle' }}
            className="pl-2"
          >
            <UncontrolledDropdown>
              <DropdownToggle caret color="secondary">
                {currentPage}
              </DropdownToggle>
              <DropdownMenu container="body">
                {pages &&
                  pages.length > 0 &&
                  pages.map((p, index) => (
                    <DropdownItem
                      key={index}
                      onClick={changeCurrentPage.bind(this, p)}
                    >
                      {p}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {allowPaging && pageSize != 999999 && (
          <div
            style={{ display: 'table-cell', verticalAlign: 'middle' }}
            className="pl-2"
          >
            <span className="mr-2">
              <span className="mr-2">
                <FormattedMessage id="label.de" />
              </span>
              <span className="mr-2">{pageCount}</span>
              <MdiIcon
                icon="arrow-right-drop-circle"
                style={{ cursor: 'pointer' }}
                // onClick={currentPage < pageCount && this.movePage.bind(this, 1)}
                onClick={
                  currentPage < pageCount
                    ? this.movePage.bind(this, 1)
                    : undefined
                }
                color="#666"
              />
            </span>
          </div>
        )}
        {allowPaging && !showPaginacaoSimples && pageSize != 999999 && (
          <div
            style={{ display: 'table-cell', verticalAlign: 'middle' }}
            className="pl-2"
          >
            <UncontrolledDropdown>
              <DropdownToggle caret color="secondary">
                {this.props.pageSize}
              </DropdownToggle>
              <DropdownMenu container="body">
                {this.props.noLimitPageSize && (
                  <>
                    <DropdownItem
                      onClick={this.beforeChangePageSize.bind(this, 160)}
                    >
                      160
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.beforeChangePageSize.bind(this, 80)}
                    >
                      80
                    </DropdownItem>
                  </>
                )}
                <DropdownItem
                  onClick={this.beforeChangePageSize.bind(this, 40)}
                >
                  40
                </DropdownItem>
                <DropdownItem
                  onClick={this.beforeChangePageSize.bind(this, 20)}
                >
                  20
                </DropdownItem>
                <DropdownItem
                  onClick={this.beforeChangePageSize.bind(this, 10)}
                >
                  10
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {allowPaging && !showPaginacaoSimples && (
          <div
            style={{ display: 'table-cell', verticalAlign: 'middle' }}
            className="pl-2"
          >
            <span>
              <FormattedMessage id="label.itensPorPagina" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default Pagination;

Pagination.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  allowPaging: PropTypes.bool,
  compact: PropTypes.bool,
  index: PropTypes.number,
};

Pagination.defaultProps = {
  allowPaging: true,
};
