import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Input from 'src/componentes/input';
import { Row, Col } from 'reactstrap';
import MdiIcon from 'src/componentes/mdi-icon';
import EditTranslate from './EditTranslate';
import ContentManager from 'src/componentes/content-manager';
import { Popover, PopoverBody } from 'reactstrap';
import css from 'src/utils/css';
import { capitalizeFirstLetter } from 'src/utils/string';

const InputTranslate = ({
  id,
  maxLength,
  fieldDefault,
  valueDefault,
  labelDefault,
  fieldPT,
  valuePT,
  fieldEN,
  valueEN,
  fieldES,
  valueES,
  fieldIT,
  valueIT,
  fieldNL,
  valueNL,
  fieldFR,
  valueFR,
  fieldDE,
  valueDE,
  fieldTR,
  valueTR,
  fieldAR,
  valueAR,
  required,
  disabled,
  handleChangeInputTranslate,
  handleChangeEditTranslate,
  errorsFieldDefault,
}) => {
  const [isPopUpOpen, setPopUp] = useState(false);
  const intl = useIntl();

  const classes = css`
    .descricao {
      text-align: left;
      background-color: #fff;
    }

    .descricaoNegrito {
      color: #000000;
      text-align: left;
      background-color: #fff;
      font-weight: bold;
    }
  `;

  const handleChangeInput = (field, value) => {
    handleChangeInputTranslate(field, value);
  };

  const renderInput = () => {
    return (
      <>
        <Input
          maxLength={maxLength}
          model={{
            label: labelDefault,
            value: valueDefault,
            requestChange: (v) => handleChangeInput(fieldDefault, v),
            errors: errorsFieldDefault,
          }}
          required={required}
          disabled={disabled}
        />
      </>
    );
  };

  const handleTraducao = () => {
    ContentManager.addContent(
      <EditTranslate
        fieldDefault={fieldDefault}
        valueDefault={valueDefault}
        labelDefault={labelDefault}
        fieldPT={fieldPT}
        valuePT={valuePT}
        fieldEN={fieldEN}
        valueEN={valueEN}
        fieldES={fieldES}
        valueES={valueES}
        fieldIT={fieldIT}
        valueIT={valueIT}
        fieldNL={fieldNL}
        valueNL={valueNL}
        fieldFR={fieldFR}
        valueFR={valueFR}
        fieldDE={fieldDE}
        valueDE={valueDE}
        fieldTR={fieldTR}
        valueTR={valueTR}
        fieldAR={fieldAR}
        valueAR={valueAR}
        handleChangeEditTranslate={handleChangeEditTranslate}
        maxLength={maxLength}
        required={required}
        disabled={disabled}
      />
    );
  };

  const renderPopoverBody = () => {
    return (
      <>
        <Row>
          <Col className={classes.descricaoNegrito} md={12}>
            {labelDefault}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div style={{ float: 'left', paddingLeft: 10 }}>{valueDefault}</div>
          </Col>
        </Row>
        {fieldPT && valuePT && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'portugues' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valuePT}</div>
              </Col>
            </Row>
          </>
        )}
        {fieldEN && valueEN && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'ingles' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valueEN}</div>
              </Col>
            </Row>
          </>
        )}
        {fieldES && valueES && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'espanhol' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valueES}</div>
              </Col>
            </Row>
          </>
        )}

        {fieldIT && valueIT && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'italiano' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valueIT}</div>
              </Col>
            </Row>
          </>
        )}

        {fieldNL && valueNL && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'holandes' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valueNL}</div>
              </Col>
            </Row>
          </>
        )}
        {fieldFR && valueFR && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'frances' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valueFR}</div>
              </Col>
            </Row>
          </>
        )}
        {fieldDE && valueDE && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'alemao' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valueDE}</div>
              </Col>
            </Row>
          </>
        )}
        {fieldTR && valueTR && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'turco' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valueTR}</div>
              </Col>
            </Row>
          </>
        )}
        {fieldAR && valueAR && (
          <>
            <Row>
              <Col className={classes.descricaoNegrito} md={12}>
                {intl.formatMessage({ id: 'arabe' })}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'left', paddingLeft: 10 }}>{valueAR}</div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-11 font-weight-bolder">{renderInput()}</div>
        <div
          id={'popOverId' + id}
          className="col-md-1 font-weight-bolder vertical-middle"
        >
          <MdiIcon
            onMouseOver={() => setPopUp(true)}
            onMouseOut={() => setPopUp(false)}
            onClick={() => handleTraducao()}
            style={{ cursor: 'pointer', marginTop: 15, marginLeft: 5 }}
            icon={'web'}
            size={22}
            className="ml-1"
          />
          <Popover
            placement="auto"
            isOpen={isPopUpOpen}
            target={'popOverId' + id}
          >
            <PopoverBody style={{ width: 350 }}>
              {renderPopoverBody()}
            </PopoverBody>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default InputTranslate;
