import React from 'react';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import Dialog from '../../componentes/dialog';
import Button from '../../componentes/button';
import LoadingContainer from '../../componentes/loading-container';
import { Schema, Form } from '../../utils/form';
import ColaboradorList from './select-list';
import { ButtonToolbar } from 'reactstrap';
import { connect } from 'react-redux';
import errorHandler from '../../utils/error-handler';
import http from '../../services/httpService';
import { capitalizeFirstLetter } from '../../utils/string';
import Input from '../../componentes/input';

let schema = Schema.object({
  conteudo: Schema.string().label(<FormattedMessage id="label.conteudo" />),
  colaboradores: Schema.array().label(''),
});

@autobind
class EmailColaboradores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    let model = this.form.getModel();

    http
      .post('ConfiguracaoSistema/ObterMensagemEmailColaboradorNovo', {})
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        let message = this.props.intl.formatMessage({ id: 'bemVindoSistema' });
        if (response.data) message = response.data;

        model.getModel('conteudo').requestChange(message);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
        this.handleClose();
      });
  }

  handleCancelClick() {
    this.refs.dialog.close();
  }

  isValid(model) {
    let conteudo = model.getModel('conteudo') && model.getModel('conteudo').value;
    let colaboradores = model.getModel('colaboradores') && model.getModel('colaboradores').value;
    return !this.state.isLoading && conteudo && conteudo.length > 0 && colaboradores && colaboradores.length > 0;
  }

  handleSendEmail() {
    let model = this.form.getModel();

    if (this.isValid(model)) {
      this.setState({
        isLoading: true,
      });

      http
        .post('Colaborador/EnviarEmailColaboradoresNovos', {
          colaboradores: model.getModel('colaboradores') && model.getModel('colaboradores').value,
          conteudo: model.getModel('conteudo') && model.getModel('conteudo').value,
        })
        .then(() => {
          this.setState({
            isLoading: false,
          });
          this.handleCancelClick();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
          this.handleClose();
        });
    }
  }

  render() {
    let { intl, resources } = this.props;
    let model = this.form.getModel();

    const actions = [
      <ButtonToolbar key="toolbar-img">
        <Button onClick={this.handleCancelClick} className="pull-left" leftIcon="close" color="secondary">
          {intl.formatMessage({ id: 'label.fechar' })}
        </Button>
        <Button disabled={!this.isValid(model)} onClick={this.handleSendEmail} className="ml-2" leftIcon="email" type="primary">
          {intl.formatMessage({ id: 'enviarEmail' })}
        </Button>
      </ButtonToolbar>,
    ];

    return (
      <Dialog
        {...this.props}
        ref="dialog"
        title={capitalizeFirstLetter(resources.colaboradores)}
        width={600}
        height={450}
        actions={actions}
        padContent
        respeitarAltura
        onRequestClose={this.handleCancelClick}
      >
        <div style={{ position: 'relative' }}>
          <LoadingContainer isLoading={this.state.isLoading}>
            <Row>
              <Col md={12}>
                <Input type="textarea" model={model.getModel('conteudo')} required />
              </Col>
            </Row>
            <ColaboradorList
              filtroInterno={{ filtrarEmailNotNull: true }}
              pageSize={10000}
              forcarPageSize
              showPage={true}
              somenteAtivos={true}
              model={model.getModel('colaboradores')}
            />
          </LoadingContainer>
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(EmailColaboradores));
