import { Col, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import Input from 'src/componentes/input';
import CargoLookup from 'src/lookups/cargo';
import AreaLookup from 'src/lookups/item-organizacao';
import TagLookup from 'src/lookups/TagLookup';
import { ADDONS } from 'src/utils/constants';

const FuncaoSearch = ({ model }) => {
  const intl = useIntl();
  const { terms: resources, addons } = useAuth();
  const possuiAddonNegociacaoMetas =
    addons?.find((x) => x.id === ADDONS.NEGOCIACAO_METAS && x.ativo) != null;

  return (
    <>
      <Row>
        <Col md={4}>
          <Input
            model={{
              label: intl.formatMessage({ id: 'idIntegracao' }),
              value: model.getModel('idIntegracao').value,
              requestChange: model.getModel('idIntegracao').requestChange,
            }}
          />
        </Col>
        <Col md={3}>
          <CargoLookup
            model={{
              label: intl.formatMessage(
                { id: 'cargo' },
                { cargo: resources.cargo }
              ),
              value: model.getModel('cargo').value,
              requestChange: model.getModel('cargo').requestChange,
            }}
          ></CargoLookup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TagLookup
            multi
            model={{
              label: capitalizeFirstLetter(
                intl.formatMessage({ id: 'tags' }, { tags: resources.tags })
              ),
              value: model.getModel('tags').value,
              requestChange: model.getModel('tags').requestChange,
            }}
            data={{ funcionalidadesIdSelecionaveis: [7] }}
            podeEditar={false}
          />
        </Col>
        {possuiAddonNegociacaoMetas && (
          <Col md={3}>
            <AreaLookup
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'area' }, { area: resources.area })
                ),
                value: model.getModel('area').value,
                requestChange: model.getModel('area').requestChange,
              }}
            ></AreaLookup>
          </Col>
        )}
      </Row>
    </>
  );
};

export default FuncaoSearch;
