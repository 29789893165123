import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class TipoConsolidacao extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" clearable={false} url="/TipoConsolidacaoLookup" {...this.props} />;
  }
}

export default TipoConsolidacao;
