import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Button from 'src/componentes/button';

const Stepper = ({
  initialStepIndex = 1,
  steps,
  children,
  containerHeight = 'auto',
  backButtonText = 'label.voltar',
  nextButtonText = 'label.proximo',
  nextCallback = () => {},
  backCallback = () => {},
  confirmNextStep = false,
  blockButtons = false,
  blockNextButton = false,
  blockBackButton = false,
  canClickOnStep = false
}) => {
  const intl = useIntl();

  const [activeStepIndex, setActiveStepIndex] = useState(initialStepIndex);

  const renderSteps = () => {
    return (
      <div className="d-flex flex-wrap">
        {steps?.map((step, index) => {
          const isActive = index === activeStepIndex;
          return (
            <div
              key={index}
              className={`d-flex align-items-center my-2 ${
                index + 1 < steps.length ? 'mr-5' : ''
              }`}
              onClick={() => {
                if (!isActive && canClickOnStep) {
                  handleStepClick(index);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <div
                style={{ height: 25, width: 25 }}
                className={`d-flex justify-content-center align-items-center rounded-circle font-weight-bolder text-white mr-2 
                  ${isActive ? 'bg-primary' : 'bg-gray'}`}
              >
                {index + 1}
              </div>
              <div>
                <div
                  className={`font-weight-bolder ${
                    !isActive ? 'text-muted' : ''
                  }`}
                >
                  {step.titulo}
                </div>
                <div className="text-muted text-sm">{step.descricao}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="actions clearfix">
        {activeStepIndex === 0 ? (
          ''
        ) : (
          <Button
            outline
            disabled={blockButtons || blockBackButton}
            className="pull-left"
            onClick={() => handleBack()}
            id="prev-button"
          >
            {intl.formatMessage({ id: backButtonText })}
          </Button>
        )}
        {actualStep === steps.length ? (
          ''
        ) : (
          <Button
            disabled={blockButtons || blockNextButton}
            className="pull-right"
            onClick={() => handleNext()}
            id="next-button"
          >
            {intl.formatMessage({ id: nextButtonText })}
          </Button>
        )}
      </div>
    );
  };

  const actualStep = activeStepIndex + 1;

  const handleStepClick = (index) => {
    const newStep = index + 1;

    if (newStep > actualStep) {
      nextCallback(newStep, setActiveStepIndex, index, true);

      if (!confirmNextStep) setActiveStepIndex(index);
    } else {
      backCallback(newStep);

      setActiveStepIndex(index);
    }
  };

  const handleNext = () => {
    const newStep = actualStep + 1;
    nextCallback(newStep, setActiveStepIndex, activeStepIndex + 1);

    if (!confirmNextStep) setActiveStepIndex(activeStepIndex + 1);
  };

  const handleBack = () => {
    const newStep = actualStep - 1;
    backCallback(newStep);

    setActiveStepIndex(activeStepIndex - 1);
  };

  return (
    <div>
      {renderSteps()}
      <hr></hr>
      <div className="overflow-hidden">
        <div style={{ height: containerHeight, maxHeight: containerHeight }}>
          {children}
        </div>
      </div>
      <hr></hr>
      {renderFooter()}
    </div>
  );
};

export default Stepper;
