import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col, Table } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import MdiIcon from 'src/componentes/mdi-icon';
import Button from 'src/componentes/button';
import InputNumber from 'src/componentes/input-number';
import OperadorLookup from 'src/lookups/operador';
import MinimoEMaximoLookup from 'src/lookups/minimo-maximo';

@autobind
class Slider extends React.Component {
  handleValueChange(index, field, value) {
    if (this.props.disabled) return;

    let faixas = this.props.model.value;
    faixas[index][field] = value;

    this.props.model.requestChange(faixas);
  }

  handleNovaFaixa() {
    if (this.props.disabled) return;

    let faixas = this.props.model && this.props.model.value;
    if (faixas == null || faixas === undefined) faixas = [];
    faixas.push({
      operadorInferior: null,
      valorInferior: null,
      operadorSuperior: null,
      valorSuperior: null,
      pontoInferior: null,
      pontoSuperior: null,
    });
    this.props.model.requestChange(faixas);
  }

  handleExcluir(index) {
    if (this.props.disabled) return;

    let faixas = this.props.model.value;
    faixas.splice(index, 1);
    this.props.model.requestChange(faixas);
  }

  render() {
    const disabled = this.props.disabled;

    let faixas = this.props.model && this.props.model.value;
    let interpolarValoresFaixa = this.props.interpolarValoresFaixa;
    let tipoPontoFaixaResultado = this.props.tipoPontoFaixaResultado;

    return (
      <div>
        {!disabled && (
          <Button
            type="primary"
            className="mt-2 mb-2"
            onClick={this.handleNovaFaixa}
          >
            <MdiIcon icon="plus" />
          </Button>
        )}
        <Row>
          <Col md={12}>
            {faixas && faixas.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: 95 }}>
                      <FormattedMessage id="label.operador" />
                    </th>
                    <th>
                      <FormattedMessage id="valor" />
                    </th>
                    <th style={{ width: 95 }}>
                      <FormattedMessage id="label.operador" />
                    </th>
                    <th>
                      <FormattedMessage id="valor" />
                    </th>
                    <th
                      colSpan={interpolarValoresFaixa && 2}
                      className={interpolarValoresFaixa && 'text-center'}
                    >
                      <FormattedMessage id="label.pontos" />
                    </th>
                    {!disabled && <th style={{ width: '5%' }}></th>}
                  </tr>
                </thead>
                <tbody>
                  {faixas.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: 95 }}>
                        <OperadorLookup
                          disabled={disabled}
                          model={{
                            value: item.operadorInferior,
                            requestChange: this.handleValueChange.bind(
                              this,
                              index,
                              'operadorInferior'
                            ),
                          }}
                          style={{ marginBottom: '0' }}
                        ></OperadorLookup>
                      </td>
                      <td
                        style={{
                          width: tipoPontoFaixaResultado.id === 4 ? 130 : '',
                        }}
                      >
                        <Row>
                          <Col md={tipoPontoFaixaResultado.id === 2 ? 10 : 12}>
                            {tipoPontoFaixaResultado &&
                            tipoPontoFaixaResultado.id != 4 ? (
                              <InputNumber
                                disabled={disabled}
                                precision={2}
                                style={{ marginBottom: '-15px' }}
                                model={{
                                  value: item.valorInferior,
                                  requestChange: this.handleValueChange.bind(
                                    this,
                                    index,
                                    'valorInferior'
                                  ),
                                }}
                              />
                            ) : (
                              <MinimoEMaximoLookup
                                disabled={disabled}
                                model={{
                                  value: item.minimoEMaximoInferior,
                                  requestChange: this.handleValueChange.bind(
                                    this,
                                    index,
                                    'minimoEMaximoInferior'
                                  ),
                                }}
                                style={{ marginBottom: '0' }}
                              />
                            )}
                          </Col>
                          {tipoPontoFaixaResultado.id === 2 && (
                            <Col
                              md={2}
                              className="d-flex align-items-center pl-0"
                            >
                              %
                            </Col>
                          )}
                        </Row>
                      </td>
                      <td style={{ width: 95 }}>
                        <OperadorLookup
                          disabled={disabled}
                          model={{
                            value: item.operadorSuperior,
                            requestChange: this.handleValueChange.bind(
                              this,
                              index,
                              'operadorSuperior'
                            ),
                          }}
                          style={{ marginBottom: '0' }}
                        ></OperadorLookup>
                      </td>

                      <td
                        style={{
                          width: tipoPontoFaixaResultado.id === 4 ? 130 : '',
                        }}
                      >
                        <Row>
                          <Col md={tipoPontoFaixaResultado.id === 2 ? 10 : 12}>
                            {tipoPontoFaixaResultado &&
                            tipoPontoFaixaResultado.id != 4 ? (
                              <InputNumber
                                disabled={disabled}
                                precision={2}
                                style={{ marginBottom: '-15px' }}
                                model={{
                                  value: item.valorSuperior,
                                  requestChange: this.handleValueChange.bind(
                                    this,
                                    index,
                                    'valorSuperior'
                                  ),
                                }}
                              />
                            ) : (
                              <MinimoEMaximoLookup
                                disabled={disabled}
                                model={{
                                  value: item.minimoEMaximoSuperior,
                                  requestChange: this.handleValueChange.bind(
                                    this,
                                    index,
                                    'minimoEMaximoSuperior'
                                  ),
                                }}
                                style={{ marginBottom: '0' }}
                              />
                            )}
                          </Col>
                          {tipoPontoFaixaResultado.id === 2 && (
                            <Col
                              md={2}
                              className="d-flex align-items-center pl-0"
                            >
                              %
                            </Col>
                          )}
                        </Row>
                      </td>

                      <td>
                        <InputNumber
                          disabled={disabled}
                          precision={2}
                          style={{ marginBottom: '-15px' }}
                          model={{
                            value: item.pontoInferior,
                            requestChange: this.handleValueChange.bind(
                              this,
                              index,
                              'pontoInferior'
                            ),
                          }}
                        />
                      </td>
                      {interpolarValoresFaixa && (
                        <td>
                          <Row>
                            <Col
                              md={2}
                              className="d-flex align-items-center pr-0"
                            >
                              <FormattedMessage id="label.a" />
                            </Col>
                            <Col md={10}>
                              <InputNumber
                                disabled={disabled}
                                precision={2}
                                style={{ marginBottom: '-15px' }}
                                model={{
                                  value: item.pontoSuperior,
                                  requestChange: this.handleValueChange.bind(
                                    this,
                                    index,
                                    'pontoSuperior'
                                  ),
                                }}
                              />
                            </Col>
                          </Row>
                        </td>
                      )}
                      {!disabled && (
                        <td>
                          <MdiIcon
                            style={{ cursor: 'pointer' }}
                            icon="delete-outline"
                            onClick={this.handleExcluir.bind(this, index)}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(Slider);
