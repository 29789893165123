import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { useIntl } from 'react-intl';

import Confirm from 'src/componentes/message-box/confirm';
import http from 'src/services/httpService';
import ContentManager from 'src/componentes/content-manager';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';

function Actions({
  endpoint,
  onRefresh,
  handleOpenEdit,
  showDelete = true,
  showCancelButton = true,
  item,
  viewMenu = false,
}) {
  const intl = useIntl();
  const handleEdit = () => handleOpenEdit(item);

  const handleDeleteConfirm = async () => {
    if (!item) return;

    try {
      await http.post(`${endpoint}/Remove`, { id: item.id });
      onRefresh && onRefresh();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleDelete = async () => {
    ContentManager.addContent(
      <Confirm
        message={capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.desejaMesmoExcluirEsseRegistro' })
        )}
        handleConfirm={handleDeleteConfirm}
      />
    );
  };

  const handleCancel = async () => {
    if (!item) return;

    try {
      await http.post(`${endpoint}/CancelarReuniao`, { reuniaoId: item.id });
      onRefresh && onRefresh();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <UncontrolledDropdown group>
        <DropdownToggle tag="a" className="nav-link p-0">
          <MdiIcon icon="dots-vertical" size={18} />
        </DropdownToggle>
        <DropdownMenu placement="left" container="body">
          {
            <DropdownItem eventkey="edit" onClick={() => handleEdit()}>
              <MdiIcon className="mr-2" icon="pencil" />
              {capitalizeFirstLetter(
                intl.formatMessage({ id: 'label.editar' })
              )}
            </DropdownItem>
          }
          {!viewMenu && showDelete && (
            <DropdownItem onClick={handleDelete}>
              <MdiIcon className="mr-2" icon="delete-outline" />
              {capitalizeFirstLetter(
                intl.formatMessage({ id: 'label.excluir' })
              )}
            </DropdownItem>
          )}
          {!viewMenu && item?.statusReuniao?.id !== 2 && showCancelButton && (
            <DropdownItem onClick={handleCancel}>
              <MdiIcon className="mr-2" icon="minus" />
              {capitalizeFirstLetter(
                intl.formatMessage({ id: 'label.cancelar' })
              )}
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default Actions;
