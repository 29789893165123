import React from 'react';
import Edit from '../../../componentes/edit/edit-dialog';
import Input from '../../../componentes/input';
import { autobind } from 'core-decorators';
import { Schema } from '../../../utils/form';
import ItemCentroCustoLookup from '../../../lookups/item-centro-custo';
import ColaboradorSimplificadoLookup from '../../../lookups/ColaboradorSimplificadoLookup';
import StatusLookup from '../../../lookups/status-centro-custo';
import { Row, Col } from 'reactstrap';
import Checkbox from '../../../componentes/checkbox';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DatePicker from '../../../componentes/date-picker';
import { capitalizeFirstLetter } from '../../../utils/string';
import ClassificacaoLookup from '../../../lookups/classificacao-mo';

let schema = Schema.object({
  id: Schema.string(),
  pai: Schema.string().label(<FormattedMessage id="pai" />),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  responsavel: Schema.array().label(''),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  status: Schema.string()
    .label(<FormattedMessage id="status" />)
    .required(),
  dataInativacao: Schema.string().label(<FormattedMessage id="label.dataInativacao" />),
  classificacao: Schema.string().label(<FormattedMessage id="label.classificacao" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, intl, resources } = this.props;

    return (
      <Row>
        <Col md={3}>
          <Input autoFocus model={model.getModel('idIntegracao')} required />
        </Col>
        <Col md={3}>
          <Input maxLength={200} model={model.getModel('nome')} required />
        </Col>
        {model.getModel('exibirClassificacao').value && model.getModel('exibirClassificacao').value && (
          <Col md={3}>
            <ClassificacaoLookup model={model.getModel('classificacao')} required />
          </Col>
        )}
        <Col md={3}>
          <StatusLookup model={model.getModel('status')} required />
        </Col>
        <Col md={3}>
          <ItemCentroCustoLookup model={model.getModel('pai')} />
        </Col>
        <Col md={4}>
          <Checkbox
            model={{
              label: intl.formatMessage({ id: 'label.gerarRelatorioAcompanhamento' }, { relatoriodeacompanhamento: resources.relatoriodeacompanhamento }),
              value: model.getModel('gerarRelatorioAcompanhamento').value,
              requestChange: model.getModel('gerarRelatorioAcompanhamento').requestChange,
            }}
            style={{ marginTop: 27 }}
          />
        </Col>
        <Col md={6}>
          <ColaboradorSimplificadoLookup
            model={{
              label: capitalizeFirstLetter(resources.responsavel),
              value: model.getModel('responsavel').value,
              requestChange: model.getModel('responsavel').requestChange,
            }}
          />
        </Col>
        {model.getModel('status').value && model.getModel('status').value.id == 0 && (
          <Col md={3}>
            <DatePicker disabled model={model.getModel('dataInativacao')} />
          </Col>
        )}
      </Row>
    );
  }
}

@autobind
class CentroCustoEdit extends React.Component {
  render() {
    let { intl, resources } = this.props;

    return (
      <Edit
        url="/CentroCusto"
        title={intl.formatMessage({ id: 'centroDeCusto' }, { centrocusto: resources.centrocusto })}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height={600}
        {...this.props}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(CentroCustoEdit));
