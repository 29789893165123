import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
@autobind
class EstiloBorda extends React.Component {
  render() {
    let { intl } = this.props;
    return (
      <Lookup
        clearable={false}
        labelKey="descricao"
        valueKey="id"
        options={[
          {
            id: 'none',
            descricao: intl.formatMessage({ id: 'nenhum' }),
          },
          {
            id: 'solid',
            descricao: intl.formatMessage({ id: 'linha' }),
          },
        ]}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(EstiloBorda));
