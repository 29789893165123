import React from 'react';
import Edit from '../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import Input from '../../componentes/input';
import { Row, Col } from 'reactstrap';
import ItemOrganizacaoLookup from '../../lookups/item-organizacao';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import ValidacaoRestricao from '../item/validacao-restricao';
import ContentManager from '../../componentes/content-manager';
import Alert from '../../componentes/message-box/alert';
import { InputTranslate } from 'src/componentes/Form';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  observacao: Schema.string().label(<FormattedMessage id="observacao" />),
  unidadeArea: Schema.string()
    .label('Unidade/Área')
    .resource('perspectiva.unidadeArea'),
});

@autobind
class FormEdit extends React.Component {
  handleChangeInputTranslate(field, value) {
    let model = this.props.model;
    model.getModel(field).requestChange(value);
  }

  handleChangeEditTranslate(
    fieldDefault,
    fieldPT,
    fieldEN,
    fieldES,
    fieldIT,
    fieldNL,
    fieldFR,
    fieldDE,
    fieldTR,
    fieldAR,
    localModel
  ) {
    let model = this.props.model;
    model.getModel(fieldDefault).requestChange(localModel[fieldDefault]);
    model.getModel(fieldPT).requestChange(localModel[fieldPT]);
    model.getModel(fieldEN).requestChange(localModel[fieldEN]);
    model.getModel(fieldES).requestChange(localModel[fieldES]);
    model.getModel(fieldIT).requestChange(localModel[fieldIT]);
    model.getModel(fieldNL).requestChange(localModel[fieldNL]);
    model.getModel(fieldFR).requestChange(localModel[fieldFR]);
    model.getModel(fieldDE).requestChange(localModel[fieldDE]);
    model.getModel(fieldTR).requestChange(localModel[fieldTR]);
    model.getModel(fieldAR).requestChange(localModel[fieldAR]);
  }

  render() {
    let { model, intl, resources } = this.props;
    return (
      <div>
        <Row>
          <Col md={12}>
            <InputTranslate
              id={model.getModel('id') && model.getModel('id').value}
              maxLength={100}
              required={true}
              errorsFieldDefault={model.getModel('descricao').errors}
              fieldDefault={'descricao'}
              valueDefault={model.getModel('descricao').value}
              labelDefault={model.getModel('descricao').label}
              fieldPT={'descricaoPT'}
              valuePT={model.getModel('descricaoPT').value}
              fieldEN={'descricaoEN'}
              valueEN={model.getModel('descricaoEN').value}
              fieldES={'descricaoES'}
              valueES={model.getModel('descricaoES').value}
              fieldIT={'descricaoIT'}
              valueIT={model.getModel('descricaoIT').value}
              fieldNL={'descricaoNL'}
              valueNL={model.getModel('descricaoNL').value}
              fieldFR={'descricaoFR'}
              valueFR={model.getModel('descricaoFR').value}
              fieldDE={'descricaoDE'}
              valueDE={model.getModel('descricaoDE').value}
              fieldTR={'descricaoTR'}
              valueTR={model.getModel('descricaoTR').value}
              fieldAR={'descricaoAR'}
              valueAR={model.getModel('descricaoAR').value}
              handleChangeInputTranslate={this.handleChangeInputTranslate}
              handleChangeEditTranslate={this.handleChangeEditTranslate}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ItemOrganizacaoLookup
              model={{
                label: intl.formatMessage(
                  { id: 'label.unidadeArea' },
                  { area: resources.area }
                ),
                value: model.getModel('unidadeArea').value,
                requestChange: model.getModel('unidadeArea').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2] }}
            />
          </Col>
          <Col md={6}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              type="textarea"
              rows={3}
              model={{
                label: capitalizeFirstLetter(resources.observacao),
                value: model.getModel('observacao').value,
                requestChange: model.getModel('observacao').requestChange,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class PerspectivaEdit extends React.Component {
  handleDelete(item, index) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/Perspectiva/ValidarRestricoes"
        urlExclusao="/Perspectiva/Remove"
        item={item}
        onClose={this.handleDeleteClickConfirm.bind(this)}
      />
    );
  }

  handleDeleteClickConfirm(excluido) {
    if (excluido) {
      ContentManager.addContent(
        <Alert
          title={this.props.intl.formatMessage({ id: 'sucesso' })}
          message={this.props.intl.formatMessage({
            id: 'label.registroExcluidoComSucesso',
          })}
        />
      );
    }
  }

  render() {
    let { intl, resources } = this.props;
    return (
      <Edit
        url="/Perspectiva"
        title={capitalizeFirstLetter(resources.perspectiva)}
        formComponent={FormEdit}
        schema={schema}
        width={800}
        height={400}
        intl={intl}
        resources={resources}
        onDeleting={this.handleDelete}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PerspectivaEdit));
