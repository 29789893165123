import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  area: Schema.array().label(''),
  areasSubordinadas: Schema.string().label(''),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  responsaveis: Schema.string().label(''),
  idIntegracao: Schema.string().label(<FormattedMessage id="codigo" />),
  processos: Schema.array().label(''),
  incluirSubprocessos: Schema.string().label(''),
  modelo: Schema.string().label(''),
  matrizRisco: Schema.string().label(''),
  tipoRisco: Schema.string().label(''),
  camposCustomizados: Schema.array(),
  causasRisco: Schema.string().label(''),
  consequenciasRisco: Schema.string().label(''),
  grauRisco: Schema.string().label(''),
  grauRiscoInerente: Schema.string().label(''),
  severidadeInerente: Schema.string().label(''),
  probabilidadeInerente: Schema.string().label(''),
  grauRiscoResidual: Schema.string().label(''),
  severidadeResidual: Schema.string().label(''),
  probabilidadeResidual: Schema.string().label(''),
  toleranciaRiscoFaixas: Schema.string().label(''),
  toleranciaRisco: Schema.string().label(''),
  tratamentoRisco: Schema.array().label(''),
  controles: Schema.string().label(''),
});
