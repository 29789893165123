import React from 'react';
import Button from '../button';
import Dialog from '../../componentes/dialog';
import { autobind } from 'core-decorators';
import jsxArray from '../../utils/jsx-array';
import { ButtonToolbar } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class MessageCancelEdit extends React.Component {
  static defaultProps = {
    title: 'label.confirmar',
  };

  handleCancelClick() {
    this.props.handleCancel && this.props.handleCancel();
    this.refs.dialog.close();
  }

  handleNaoSalvarClick() {
    this.props.handleNaoSalvar && this.props.handleNaoSalvar();
    this.refs.dialog.close();
  }

  handleSaveClick() {
    this.props.handleSave && this.props.handleSave(true);
    this.refs.dialog.close();
  }

  render() {
    let { title, message, intl, ...other } = this.props;

    const actions = jsxArray(
      <ButtonToolbar>
        <Button className="ml-2" onClick={this.handleCancelClick} color="default">
          {intl.formatMessage({ id: 'label.cancelar' })}
        </Button>
        <Button className="ml-2" color="danger" onClick={this.handleNaoSalvarClick}>
          {intl.formatMessage({ id: 'label.naoSalvar' })}
        </Button>
        <Button className="ml-2" autoFocus type="primary" onClick={this.handleSaveClick}>
          {intl.formatMessage({ id: 'label.salvar' })}
        </Button>
      </ButtonToolbar>
    );

    return (
      <Dialog {...other} width={440} ref="dialog" title="label.confirmar" actions={actions} padContent>
        {intl.formatMessage({ id: 'existeAlteracoesNaoSalvas' })}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(MessageCancelEdit));
