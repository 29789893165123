import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class TipoDeControleLookup extends React.Component {
  static defaultProps = {
    clearable: false,
  };

  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/TipoDeControleLookup" clearable={true} {...this.props} />;
  }
}

export default TipoDeControleLookup;
