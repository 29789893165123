import { Col, Row } from 'reactstrap';

import Input from 'src/componentes/input';
import StatusPlanoContingenciaLookup from 'src/lookups/StatusPlanoContingenciaLookup';
import ColaboradorLookup from 'src/lookups/colaborador';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import RiscoLookup from 'src/lookups/item';
import { useEffect } from 'react';
import http from 'src/services/httpService';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import { ENTIDADES_CUSTOMIZADAS } from 'src/utils/constants';

const ModeloAvaliacaoSearch = ({ model }) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  useEffect(() => {
    obterCamposCustomizados();
  }, []);

  const obterCamposCustomizados = () => {
    http
      .post(`/CampoCustomizado/ObterListaCamposCustomizados`, {
        tipoItem: ENTIDADES_CUSTOMIZADAS.PLANOS_CONTIGENCIA,
      })
      .then((response) => {
        model.getModel('camposCustomizados').requestChange(response.data);
      });
  };

  return (
    <>
      <Row className="pb-5">
        <Col md={6}>
          <Input model={model.getModel('nome')} />
        </Col>
        <Col md={6}>
          <Input model={model.getModel('idIntegracao')} />
        </Col>
        <Col md={6}>
          <StatusPlanoContingenciaLookup model={model.getModel('status')} />
        </Col>
        <Col md={6}>
          <ColaboradorLookup
            model={{
              label: capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'responsavel' },
                  { responsavel: resources.responsavel }
                )
              ),
              requestChange: model.getModel('responsavel').requestChange,
              value: model.getModel('responsavel').value,
            }}
          />
        </Col>
        <Col md={6}>
          <RiscoLookup
            clearable
            data={{ tipoId: 6 }}
            model={{
              label: capitalizeFirstLetter(resources.risco),
              value: model.getModel('riscoAtrelado').value,
              requestChange: model.getModel('riscoAtrelado').requestChange,
            }}
          />
        </Col>
      </Row>
      <Row>
        {model.getModel('camposCustomizados').value &&
          model.getModel('camposCustomizados').value.length > 0 && (
            <Col md={12}>
              <DesenharCampoCustomizado
                model={model.getModel('camposCustomizados')}
              />
            </Col>
          )}
      </Row>
    </>
  );
};

export default ModeloAvaliacaoSearch;
