import React from 'react';
import { autobind } from 'core-decorators';
import Edit from '../item/edit';
import ContentManager from '../../componentes/content-manager';
import Schema from './schema';
import qs from 'query-string';
import { connect } from 'react-redux';
import ValidacaoRestricao from '../item/validacao-restricao';
import { capitalizeFirstLetter } from '../../utils/string';

@autobind
class ProjetoEdit extends React.Component {
  static defaultProps = {
    showDelete: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.location && qs.parse(this.props.location.search).id,
    };
  }

  handleSaved(data) {
    this.setState({ id: data.id });
  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/Projeto/ValidarRestricoes"
        urlExclusao="/Projeto/AtualizarStatusExcluido"
        urlList="/gerenciar/projeto"
        urlEdit="/gerenciar/projeto/edit"
        item={item}
        onClose={this.handleClose}
        isEdit={true}
      />
    );
  }

  render() {
    let { showDelete, showPrompt, resources } = this.props;

    let id = this.props.id ? this.props.id : this.props.location && qs.parse(this.props.location.search).id;
    let activeTab = this.props.location && qs.parse(this.props.location.search).activeTab;

    return (
      <Edit
        urlList="/gerenciar/projeto"
        urlEdit="/gerenciar/projeto/edit"
        url="/Projeto"
        parameters={{ tipoId: 4 }}
        titleField="nome"
        title={capitalizeFirstLetter(resources.projeto)}
        schema={Schema}
        handleSaved={this.handleSaved}
        activeTab={activeTab}
        id={id}
        tipoId={4}
        showDelete={showDelete}
        showPrompt={showPrompt}
        onDeleting={this.handleDeleteClick}
        resources={resources}
      ></Edit>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(ProjetoEdit);
