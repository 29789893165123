import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { useSnackbar } from 'notistack';

import Input from 'src/componentes/input';
import PerfilLookup from 'src/lookups/perfil';
import StrongPassword from 'src/componentes/StrongPassword';
import CheckBox from 'src/componentes/checkbox';
import { filterTree } from 'src/utils/tree';
import ModulosColaborador from './ModulosColaborador';
import LoadingContainer from 'src/componentes/loading-container';
import Button from 'src/componentes/button';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import useAuth from 'src/hooks/useAuth';
import Confirm from 'src/componentes/message-box/confirm';
import ContentManager from 'src/componentes/content-manager';

const Usuario = ({ model, modulos, disabled = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disabledMFAReset, setDisabledMFAReset] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user, utilizandoAdfs } = useAuth();
  const intl = useIntl();

  const obterIdsCompartilhamento = () => {
    let compartilhamento = model.getValue('compartilhamento');
    return compartilhamento
      ? filterTree(
          compartilhamento,
          'children',
          (item) => item.selecionado
        ).map((i) => i.id)
      : [];
  };

  const handleConfirmResetMFA = (userCode) => {
    ContentManager.addContent(
      <Confirm
        message={<FormattedMessage id="desejaMesmoResetarMFA" />}
        handleConfirm={() => handleResetMFA(userCode)}
      />
    );
  };

  const handleResetMFA = (userCode) => {
    setIsLoading(true);

    http
      .post(`/Colaborador/ResetMFA`, {
        userCode: userCode,
      })
      .then((response) => {
        setDisabledMFAReset(true);

        model.getModel('habilitadoMFA').requestChange(false);

        setIsLoading(false);

        enqueueSnackbar(
          intl.formatMessage({
            id: 'metodoVerificacaoRedefinidoSucesso',
          }),
          {
            variant: 'success',
          }
        );
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error);
      });
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <Row>
        {!disabled && (
          <Col md={6}>
            <Input model={model.getModel('email')} disabled={disabled} />
          </Col>
        )}
        <Col md={6}>
          <PerfilLookup
            model={model.getModel('perfis')}
            data={{ itensId: obterIdsCompartilhamento() }}
            multiple={true}
          />
        </Col>
      </Row>
      {!disabled && (
        <Row>
          <Col md={4}>
            <form autoComplete="off">
              <Input autoComplete="off" model={model.getModel('nomeUsuario')} />
            </form>
          </Col>

          <Col md={4}>
            <StrongPassword
              model={model.getModel('senhaAlterada')}
              userName={model.getModel('nomeUsuario').value}
              email={model.getModel('email').value}
            />
          </Col>

          <Col md={4}>
            <CheckBox
              style={{
                marginTop: model.getModel('adLdapAtivo').value ? 10 : 30,
              }}
              model={model.getModel('bloqueadoTentativaLogin')}
            />
            {model.getModel('adLdapAtivo').value && (
              <CheckBox model={model.getModel('utilizarAdLdap')} />
            )}
          </Col>

          {!utilizandoAdfs && user?.usuarioPerfilAdmin && (
            <Col md={8}>
              <Button
                disabled={disabledMFAReset}
                onClick={() =>
                  handleConfirmResetMFA(model.getModel('idIntegracao')?.value)
                }
                iconSize={15}
                color="primary"
                leftIcon="lock-reset"
              >
                <FormattedMessage id="redefinirMFA"></FormattedMessage>
              </Button>
            </Col>
          )}

          {model.getValue('podeAlterarModulos') && (
            <Col md={12}>
              <hr />
              <ModulosColaborador model={model} modulos={modulos} />
            </Col>
          )}
        </Row>
      )}
    </LoadingContainer>
  );
};

export default Usuario;
