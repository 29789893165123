import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import MdiIcon from '../mdi-icon';
import { autobind } from 'core-decorators';
import css from '../../utils/css';
import { StickyContainer } from '../sticky';
import classnames from 'classnames';
import FocusTrap from '../focus-trap';
import focusManager from '../../utils/focus-manager';
import LoadingOverlay from '../loading-overlay';
import { Row, Col } from 'reactstrap';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';

const transitionTimeouts = {
  appear: 250,
  enter: 250,
  leave: 250,
};

let baseClasses = css`
  .container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
  }

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    &.dialog-transition-enter,
    &.dialog-transition-appear {
      opacity: 0;
    }

    &.dialog-transition-enter.dialog-transition-enter-active,
    &.dialog-transition-appear.dialog-transition-appear-active {
      opacity: 1;
      transition: all ${transitionTimeouts.enter}ms ease;
    }

    &.dialog-transition-leave {
      opacity: 1;
    }

    &.dialog-transition-leave.dialog-transition-leave-active {
      opacity: 0;
      transition: all ${transitionTimeouts.leave}ms ease;
    }
  }

  .dialog {
    position: relative;
    box-shadow: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
    display: flex;
    flex-direction: column;
    outline: none;
    height: auto !important;
    border-radius: 0.3rem;
    &.dialog-transition-enter,
    &.dialog-transition-appear {
      opacity: 0;
      transform: translate(0, -100px);
    }

    &.dialog-transition-enter.dialog-transition-enter-active,
    &.dialog-transition-appear.dialog-transition-appear-active {
      opacity: 1;
      transition: all ${transitionTimeouts.enter}ms ease;
      transform: translate(0, 0);
    }

    &.dialog-transition-leave {
      opacity: 1;
      transform: translate(0, 0);
    }

    &.dialog-transition-leave.dialog-transition-leave-active {
      opacity: 0;
      transition: all ${transitionTimeouts.leave}ms ease;
      transform: translate(0, 100px);
    }
  }

  .header {
    padding: 10px 15px;
    font-size: 15px;
    text-transform: uppercase;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .title {
    color: #000;
    font-weight: bold;
    flex: 1 1 auto;
  }

  .titleLink {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    align-items: center;
    padding-right: 35px;
  }

  .close {
    cursor: pointer;
  }

  .content {
    position: relative;
    flex-grow: 1;
    overflow: auto;
    overflow-x: auto;

    &.padContent {
      padding: 0px 15px 0px 15px;
    }
  }

  .footer {
    padding: 5px 15px;
    flex-shrink: 0;
    flex-grow: 0;    

    .content {
      flex: 1 1 auto;
    }

    .buttons {
      flex: 0 0 auto;
      white-space: nowrap;
    }
  }
`;

@autobind()
class Dialog extends React.Component {
  static defaultProps = {
    showClose: true,
    mouseDownClose: false,
    respeitarAltura: false,
    showHeader: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      dialogInsideDialog: false,
    };
  }

  close() {
    this.setState({
      isOpen: false,
    });

    setTimeout(() => {
      this.props.remove && this.props.remove();
      this.props.onClose && this.props.onClose();
    }, transitionTimeouts.leave);
  }

  componentDidMount() {
    this.setState({
      dialogInsideDialog: document.body?.classList?.contains('modal-open'),
    });

    $('body').addClass('modal-open');
    setTimeout(() => {
      focusManager.ensureFocusIsValid();
    }, transitionTimeouts.appear);
  }

  componentWillUnmount() {
    if (!this.state.dialogInsideDialog) $('body').removeClass('modal-open');
  }

  handleCloseClick() {
    //$('body').removeClass('modal-open')
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    } else {
      this.close();
    }
  }

  tratarUnidade(valor, width = false) {
    if (valor === undefined || valor === null) {
      return;
    }

    if (typeof valor == `string`) {
      if (valor.indexOf('%') !== -1) {
        var unidadeMedida = valor.substring(0, valor.length - 1);
        return width == true ? unidadeMedida + 'vw' : valor;
      } else if (valor.indexOf('px') !== -1) {
        return valor;
      }
    }

    return valor + 'px';
  }

  renderTitle(title, ignoreResourceTitle = false) {
    const isString = typeof title === 'string';

    if (isString) {
      if (ignoreResourceTitle) return title;

      return <FormattedMessage id={title} />;
    }

    return title;
  }

  renderActions(classes) {
    if (this.props.showActionsEnd)
      return (
        (this.props.actions || this.props.footer) && (
          <div className={classes.footer}>
            <Row>
              <Col md={this.props.footer ? 7 : 12}>
                <div className="pull-right">
                  {this.props.actions &&
                    this.props.actions.length &&
                    this.props.actions.map((a, index) =>
                      React.cloneElement(a, {
                        key: index,
                      })
                    )}
                </div>
              </Col>
              {this.props.footer && <Col md={5}>{this.props.footer}</Col>}
            </Row>
          </div>
        )
      );

    return (
      (this.props.actions || this.props.footer) && (
        <div className={classes.footer}>
          <Row>
            {this.props.footer && <Col md={5}>{this.props.footer}</Col>}
            <Col md={this.props.footer ? 7 : 12}>
              <div className="pull-right">
                {this.props.actions &&
                  this.props.actions.length &&
                  this.props.actions.map((a, index) =>
                    React.cloneElement(a, {
                      key: index,
                    })
                  )}
              </div>
            </Col>
          </Row>
        </div>
      )
    );
  }

  render() {
    let {
      id,
      className,
      showClose,
      width,
      height,
      minWidth,
      minHeight,
      maxHeight,
      maxWidth,
      padContent,
      mouseDownClose,
      title,
      ignoreResourceTitle = false,
      styleTitle = {},
      customTitle,
      link,
      respeitarAltura,
      background,
      customClose,
      showHeader,
      textCapitalizeTitle = true,
      customHandleCloseFunction,
      titleCapitalizeNone,
      customButton,
    } = this.props;
    const isLinkFunction = link instanceof Function;
    let classes = css`
      .dialog {
        width: ${this.tratarUnidade(width, true) || 'auto'};
        height: ${this.tratarUnidade(height) || 'auto'}${respeitarAltura ? ' !important' : ''};
        min-width: ${this.tratarUnidade(minWidth) || '0px'};
        min-height: ${this.tratarUnidade(minHeight) || '0px'};
        max-height: ${this.tratarUnidade(maxHeight) || '100%'};
        max-width: ${this.tratarUnidade(maxWidth) || '100%'};
      }
    `.extend(baseClasses);

    const mergedStyle = {
      background: background || '#fff',
      ...this.props.style,
    };

    return (
      <ReactCSSTransitionGroup
        id={id}
        component="div"
        className={classnames(classes.container, className, 'dialog')}
        transitionName="dialog-transition"
        transitionAppear={true}
        transitionAppearTimeout={transitionTimeouts.appear}
        transitionEnterTimeout={transitionTimeouts.enter}
        transitionLeaveTimeout={transitionTimeouts.leave}
      >
        {this.state.isOpen && [
          <div
            key="overlay"
            className={classes.overlay}
            onMouseDown={mouseDownClose ? this.handleCloseClick : undefined}
          ></div>,
          <FocusTrap
            key="dialog"
            className={classes.dialog}
            style={mergedStyle}
            trap={this.props.index + 1}
            tabIndex="0"
          >
            {showHeader && (
              <div className={classnames(classes.header, 'dialog-header')}>
                <div className={classes.titleLink}>
                  {title && (
                    <div
                      tabIndex={
                        this.props.handleAssistenteVirtualClick &&
                        this.props.moduloInteligenciaArtificial &&
                        '0'
                      }
                      className={`font-weight-bolder text-primary mb-2 ${
                        textCapitalizeTitle
                          ? 'text-capitalize'
                          : titleCapitalizeNone
                          ? 'text-transform-none'
                          : ''
                      }`}
                      style={styleTitle ? styleTitle : {}}
                    >
                      {this.renderTitle(title, ignoreResourceTitle)}
                    </div>
                  )}
                  <div style={{ textTransform: 'none' }}>
                    {customTitle && customTitle}
                  </div>
                  {link && isLinkFunction && link()}
                </div>
                {showClose &&
                  (customClose ? (
                    <>
                      {customClose(this.handleCloseClick)}
                      {customButton && customButton()}
                    </>
                  ) : (
                    <MdiIcon
                      className={classes.close}
                      icon="close"
                      onClick={
                        customHandleCloseFunction
                          ? customHandleCloseFunction
                          : this.handleCloseClick
                      }
                    />
                  ))}
              </div>
            )}
            <StickyContainer
              id="dialog-sticky-container"
              className={classnames(classes.content, { padContent })}
            >
              {this.props.children}
              {this.props.isLoading && <LoadingOverlay />}
            </StickyContainer>
            {this.renderActions(classes)}
          </FocusTrap>,
        ]}
      </ReactCSSTransitionGroup>
    );
  }
}

export default Dialog;
