import React, { useState, useCallback, useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';
import http from 'src/services/httpService';
import LoadingContainer from 'src/componentes/loading-container';
import errorHandler from 'src/utils/error-handler';
import { FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';
import moment from 'moment';
import Avatar from 'src/componentes/avatar';
import { prepareHtml } from 'src/utils/html';

const HistoricoItemFaixaMinimoMaximo = ({ itemFaixaMinimoMaximoId }) => {
  const { user } = useAuth();
  const intl = useIntl();
  const [alteracoes, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const renderData = (dataHora, formato) => {
    let formatoSplit = formato.split(',');
    let formatoOk = formatoSplit[0].toUpperCase() + ', ' + formatoSplit[1];

    return moment(dataHora).format(formatoOk);
  };

  const renderAlteracao = (alteracao, alteracaoIndex) => {
    return (
      <div key={alteracaoIndex} style={{ padding: 10 }}>
        <div
          style={{
            marginBottom: 5,
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        >
          <Avatar
            foto={alteracao && alteracao.fotoArquivo}
            size={23}
            label={alteracao.usuario}
          ></Avatar>{' '}
          <span style={{ verticalAlign: 'middle', marginLeft: 5 }}>
            - {renderData(alteracao.dataHora, alteracao.formatoData)}
          </span>
        </div>
        <Table size="sm" bordered>
          <thead>
            <tr>
              <th style={{ width: '34%' }}>
                {<FormattedMessage id="label.campo" />}
              </th>
              <th style={{ width: '33%' }}>
                {<FormattedMessage id="label.valorAntigo" />}
              </th>
              <th style={{ width: '33%' }}>
                {<FormattedMessage id="label.valorNovo" />}
              </th>
            </tr>
          </thead>
          <tbody>
            {alteracao.alteracoesCampos.map(
              (alteracaoCampo, alteracaoCampoIndex) => (
                <tr key={alteracaoCampoIndex}>
                  <td>{alteracaoCampo.campo}</td>
                  <td>
                    <div
                      title={alteracaoCampo.valorAnterior}
                      dangerouslySetInnerHTML={prepareHtml(
                        alteracaoCampo.valorAnterior
                      )}
                      className="ml-2"
                      style={{
                        textOverflow: 'ellipsis',
                        width: '220px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    ></div>
                  </td>
                  <td>
                    <div
                      title={alteracaoCampo.novoValor}
                      dangerouslySetInnerHTML={prepareHtml(
                        alteracaoCampo.novoValor
                      )}
                      className="ml-2"
                      style={{
                        textOverflow: 'ellipsis',
                        width: '220px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    ></div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
        <br></br>
      </div>
    );
  };

  const renderAlteracoes = () => {
    return alteracoes.map((alteracao, alteracaoIndex) =>
      renderAlteracao(alteracao, alteracaoIndex)
    );
  };

  const renderSemAlteracoes = () => {
    return (
      <div>
        <p>{<FormattedMessage id="label.nenhumaAlteracaoRealizada" />}</p>
      </div>
    );
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await http.post(
        '/ItemFaixaMinimoMaximo/ObterHistorico',
        {
          id: itemFaixaMinimoMaximoId,
        }
      );
      setData(response.data.alteracoes);
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (itemFaixaMinimoMaximoId) {
      getData();
    }
  }, []);

  return (
    <LoadingContainer isLoading={isLoading}>
      {alteracoes && alteracoes.length
        ? renderAlteracoes()
        : renderSemAlteracoes()}
    </LoadingContainer>
  );
};

export default HistoricoItemFaixaMinimoMaximo;
