import { useState } from 'react';
import { useIntl } from 'react-intl';
import { PlusCircle, Trash } from 'react-feather';
import DatePicker from 'src/componentes/date-picker';

function DateList({ model, dataInicio }) {
  const intl = useIntl();
  const [datas, setDatas] = useState([]);

  const handleAdd = (e) => {
    e.preventDefault();
    const newList = [...datas];
    newList.push(null);
    setDatas(newList);
    model.requestChange(newList);
  };

  const handleChange = (value, index) => {
    datas[index] = value;
  };

  const handleRemove = (index) => {
    if (index < 0) return;

    const newList = [...datas];
    newList.splice(index, 1);
    setDatas(newList);
    model.requestChange(newList);
  };

  const isEmpty = !datas || datas.length === 0;

  return (
    <div>
      <div>
        <span>{intl.formatMessage({ id: 'datas' })}</span>
      </div>
      {datas.map((data, index) => (
        <div
          className="d-flex align-items-center"
          style={{ height: 38, marginTop: 7 }}
        >
          <div className="mr-3" style={{ width: 18 }}>
            <Trash
              className="cursor-pointer"
              size={18}
              onClick={() => handleRemove(index)}
            />
          </div>
          <div>
            <DatePicker
              style={{ marginBottom: 0 }}
              model={{
                value: data,
                requestChange: (value) => handleChange(value, index),
              }}
            />
          </div>
        </div>
      ))}
      <PlusCircle
        style={{
          marginTop: isEmpty ? 15 : 10,
        }}
        className="text-primary cursor-pointer"
        size={24}
        onClick={handleAdd}
      />
    </div>
  );
}

export default DateList;
