import { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

import LoadingContainer from 'src/componentes/loading-container';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import Table from 'src/componentes/table';

function Log({ id }) {
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await http.post(`RiscoAvaliacaoPowerBI/ObterLog`, {
        riscoAvaliacaoPowerBIId: id,
      });

      setData(response.data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const renderLog = (item) => {
    if (!item.log)
      return (
        <div style={{ textAlign: 'center' }}>
          <span style={{ textAlign: 'center' }}>-</span>
        </div>
      );

    return (
      <div style={{ textAlign: 'center' }}>
        <span>{item.log}</span>
      </div>
    );
  };

  const renderStatus = (item) => {
    return (
      <span
        style={{
          textAlign: 'center',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Badge
          id={`btn-sync-${item.id}`}
          pill
          className="text-white"
          style={{ background: item?.status?.cor }}
        >
          {item?.status?.descricao}
        </Badge>
        {item?.status?.id === 4 && (
          <UncontrolledTooltip
            placement="bottom"
            target={`btn-sync-${item.id}`}
            style={{ textAlign: 'center' }}
          >
            {item.log}
          </UncontrolledTooltip>
        )}
      </span>
    );
  };

  const renderDataInicio = (item) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <span>
          {moment(item?.dataInicio)
            .locale('pt-Br')
            .format('DD MMMM YYYY, HH:mm:ss')}
        </span>
      </div>
    );
  };

  const renderDataTermino = (item) => {
    return (
      <div style={{ textAlign: 'center' }}>
        {item?.dataTermino && (
          <span>
            {moment(item?.dataTermino)
              .locale('pt-Br')
              .format('DD MMMM YYYY, HH:mm:ss')}
          </span>
        )}
      </div>
    );
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <Table size="sm" bordered style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ width: '20%', textAlign: 'center' }}>
              {intl.formatMessage({ id: 'dataInicio' })}
            </th>
            <th style={{ width: '20%', textAlign: 'center' }}>
              {intl.formatMessage({ id: 'dataTermino' })}
            </th>
            <th style={{ width: '20%', textAlign: 'center' }}>
              {intl.formatMessage({ id: 'status' })}
            </th>
            <th style={{ width: '40%', textAlign: 'center' }}>
              {intl.formatMessage({ id: 'log' })}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr>
              <td>{renderDataInicio(item)}</td>
              <td>{renderDataTermino(item)}</td>
              <td>{renderStatus(item)}</td>
              <td>{renderLog(item)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </LoadingContainer>
  );
}

export default Log;
