import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import ParametroEntidadeEdit from './edit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class ParametroEntidade extends React.Component {
  render() {
    let { resources, intl } = this.props;

    return (
      <List url="/ParametroEntidade" editComponent={ParametroEntidadeEdit} sort="Nome" showSearch={true} {...this.props} tag={10001}>
        <ListColumn headerText={intl.formatMessage({ id: 'idIntegracao' })} valueField="idIntegracao" default />
        <ListColumn headerText={intl.formatMessage({ id: 'nome' })} required valueField="nome" sortField="Nome" valueFunction={this.renderDescricao} default />
        <ListColumn headerText={intl.formatMessage({ id: 'unidadeDeMedida' })} valueField="unidadeMedida" default />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ParametroEntidade));
