import React from 'react';
import { autobind } from 'core-decorators';
import FichaIndicador from 'src/paginas/indicador-base/ficha';
import FichaProjeto from '../projeto/ficha';
import FichaAcao from '../acao/edit';
import FichaObjetivo from '../objetivo-estrategico-base/ficha';
import FichaParametro from '../parametro-base/ficha';
import ContentManager from '../../componentes/content-manager';

@autobind
class ItemLink extends React.Component {
  handleClick() {
    const tipo = this.props.tipo
      ? this.props.tipo
      : this.props.model.value && this.props.model.value.tipo.id;
    if (tipo === 1) {
      //Indicador
      ContentManager.addContent(
        <FichaIndicador
          handleCloseEdit={this.props.handleCloseEdit}
          id={this.props.model.value.id}
          showActions={this.props.showActions}
          obterDadosFichaUrl={`/Indicador/ObterDadosFicha`}
        ></FichaIndicador>
      );
    } else if (tipo === 2) {
      //parametro
      ContentManager.addContent(
        <FichaParametro
          handleCloseEdit={this.props.handleCloseEdit}
          id={this.props.model.value.id}
          showActions={this.props.showActions}
          obterDadosFichaUrl={`/Parametro/ObterDadosFicha`}
        ></FichaParametro>
      );
    } else if (tipo === 3) {
      //objetivo
      ContentManager.addContent(
        <FichaObjetivo
          handleCloseEdit={this.props.handleCloseEdit}
          id={this.props.model.value.id}
          showActions={this.props.showActions}
          obterDadosFichaUrl={`/ObjetivoEstrategico/ObterDadosFicha`}
        ></FichaObjetivo>
      );
    } else if (tipo === 4) {
      //Projeto
      ContentManager.addContent(
        <FichaProjeto
          handleCloseEdit={this.props.handleCloseEdit}
          id={this.props.model.value.id}
          showActions={this.props.showActions}
          obterDadosFichaUrl={`/Projeto/ObterDadosFicha`}
        ></FichaProjeto>
      );
    } else if (tipo === 901) {
      //acao
      ContentManager.addContent(
        <FichaAcao
          afterSave={this.props.afterSave}
          item={{ id: this.props.model.value.id }}
          showActions={this.props.showActions}
        />
      );
    }
  }

  render() {
    let { model, tipo } = this.props;
    let descricao = '';
    const tipoItem = tipo
      ? tipo
      : model.value && model.value.tipo && model.value.tipo.id;

    if (model.value)
      descricao = model.value.nome ? model.value.nome : model.value.descricao;

    return (
      <React.Fragment>
        {tipoItem !== 7 && !this.props.isConsultaExterna ? ( // temporario ate melhoria para implementar ficha reuniao
          <a tabIndex="-1" onClick={this.handleClick}>
            {descricao}
          </a>
        ) : (
          <span tabIndex="-1">{descricao}</span>
        )}
      </React.Fragment>
    );
  }
}

export default ItemLink;
