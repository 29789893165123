import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
//import { capitalizeFirstLetter } from '../../../../utils/string';
import List from '../../../../componentes/list';
import ListColumn from '../../../../componentes/list/list-column';
import MdiIcon from '../../../../componentes/mdi-icon';
import RecorrenciaPeriodicidadeEdit from './edit';

function RecorrenciaPeriodicidade({ model, intl, disabled, ...rest }) {
  const handleRemove = (item, index) => {
    const currentIndex = model.value.findIndex((c) => c.dataInicio === item.dataInicio && c.dataTermino === item.dataTermino);

    if (currentIndex >= 0) {
      model.value[currentIndex]['excluido'] = true;

      model.requestChange(model.value);
    }
  };

  const handleSaveItem = (saved, isNew, item, index) => {
    if (saved) {
      let novaLista = model.value;

      if (isNew) {
        if (novaLista && novaLista.length > 0) novaLista.push(item);
        else novaLista = [].concat(item);
      } else novaLista[index] = item;

      model.requestChange(novaLista);
    }
  };

  const renderDataInicio = (item) => {
    if (!item.dataInicio) return;

    return <span>{moment(item.dataInicio).format('L')}</span>;
  };

  const renderDataTermino = (item) => {
    if (!item.dataTermino) return;

    return <span>{moment(item.dataTermino).format('L')}</span>;
  };

  const renderPeriodicidade = (item) => {
    return <span>{item.periodicidade && item.periodicidade.descricao}</span>;
  };

  const renderRecorrencia = (item) => {
    if (item.utilizarRecorrencia) return <MdiIcon icon="check" />;
  };

  return (
    <List
      labelNew={intl.formatMessage({ id: 'label.novaTela' })}
      local
      dataSource={model.value && model.value.filter((f) => !f.excluido)}
      handleSaveItem={handleSaveItem}
      handleRemove={handleRemove}
      editComponent={RecorrenciaPeriodicidadeEdit}
      showNew={!disabled}
      showMenu={!disabled}
      showPage={false}
      showSearch={false}
      showEdit={!disabled}
      showDelete={!disabled}
      //parameters={{ duracao: duracao, telas: model.value }}
      showExport={false}
      {...rest}
    >
      <ListColumn headerText={intl.formatMessage({ id: 'label.dataInicio' })} valueField="dataInicio" valueFunction={renderDataInicio}></ListColumn>
      <ListColumn headerText={intl.formatMessage({ id: 'utilizaRecorrencia' })} valueField="utilizarRecorrencia" valueFunction={renderRecorrencia}></ListColumn>
      <ListColumn headerText={intl.formatMessage({ id: 'periodicidade' })} valueField="periodicidade" sortField="periodicidade" valueFunction={renderPeriodicidade} />
      <ListColumn headerText={intl.formatMessage({ id: 'label.dataTermino' })} valueField="dataTermino" valueFunction={renderDataTermino}></ListColumn>
    </List>
  );
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(RecorrenciaPeriodicidade));
