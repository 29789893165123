import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import ParteInteressadaEdit from '../paginas/parte-interessada/edit';

@autobind
class ParteInteressadaLookup extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/ParteInteressadaLookup" editComponent={ParteInteressadaEdit} {...this.props} />;
  }
}

export default ParteInteressadaLookup;
