import React, { Suspense, lazy } from 'react';
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { capitalizeFirstLetter } from 'src/utils/string';
import LoadingContainer from 'src/componentes/loading-container';
import LoadingScreen from 'src/componentes/LoadingScreen';
import Dialog from 'src/componentes/dialog';
import http from 'src/services/httpService';
// import Grafico from 'src/componentes/chart';
const Grafico = lazy(() => import('src/componentes/chart'));
class Estatistica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      chart: {},
      data: [],
      total: 0,
      searchModel: {
        areaSubordinada: true,
        farol: null,
        areaSubordinadaColaboradores: true,
      },
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    this.setState({
      isLoading: true,
    });
    await http
      .post(`/Acao/ObterGraficoDoughnutAcoes`, {
        host: window.location.origin,
        searchModel: this.props.searchModel,
        estatistica: true,
      })
      .then((response) => {
        this.setState({
          chart: response.data.chart,
          data: response.data.data,
          isLoading: false,
          total: response.data.totalAcoes,
        });
      });
  }

  returnColor(idTipoFarol) {
    let color;
    switch (idTipoFarol) {
      case 1: {
        color = '#8B008B';
        break;
      }
      case 2: {
        color = '#0000FF';
        break;
      }
      case 3: {
        color = '#32CD32';
        break;
      }
      case 4: {
        color = '#FFD700';
        break;
      }
      case 5: {
        color = '#FF0000';
        break;
      }
      case 6: {
        color = '#A9A9A9';
        break;
      }
      case 7: {
        color = '#000000';
        break;
      }
      default: {
        color = '#A9A9A9';
        break;
      }
    }
    return color;
  }

  renderGrafico() {
    let { chart, data, total } = this.state;
    const config = {
      type: 'doughnut2d',
      width: '585',
      height: '300',
      theme: 'fusion',
      dataSource: {
        chart: {
          ...chart,
          caption: '',
          showBorder: '0',
          use3DLighting: '0',
          showShadow: '0',
          enableSmartLabels: '1',
          startingAngle: '310',
          showLabels: '0',
          showPercentValues: '1',
          showLegend: '0',
          legendShadow: '0',
          legendBorderAlpha: '1',
          centerLabel: '$label: $value',
          centerLabelBold: '1',
          showTooltip: '1',
          decimals: '1',
          captionFontSize: '14',
          subcaptionFontSize: '14',
          subcaptionFontBold: '0',
        },
        data: data,
      },
    };
    return (
      <div style={{ margin: 15, overflow: 'hidden' }}>
        {Object.getOwnPropertyNames(chart).length >= 1 && (
          <Grafico {...config} />
        )}
        <ListGroup flush className="mt-2">
          {data.map((item) => (
            <ListGroupItem
              className={`pl-0 pr-0 font-weight-bold`}
              style={{ color: this.returnColor(item.tipo) }}
            >
              <ul className="list-inline">
                <li className="list-inline-item">
                  {capitalizeFirstLetter(item.label)}
                </li>
                <li className="list-inline-item pull-right">
                  <div className="d-inline-block">{item.percent}%</div>
                  <div
                    className="d-inline-block text-right"
                    style={{ minWidth: 35 }}
                  >
                    {item.value}
                  </div>
                </li>
              </ul>
            </ListGroupItem>
          ))}
          <ListGroupItem className="text-right text-uppercase pl-0 pr-0 pb-0">
            <ul className="list-inline font-weight-bold">
              <li className="list-inline-item pull-right">
                <div className="d-inline-block">
                  {this.props.intl.formatMessage(
                    { id: 'total' },
                    { total: this.props.resources.total }
                  )}
                </div>
                <div
                  className="d-inline-block text-right"
                  style={{ minWidth: 35 }}
                >
                  {total}
                </div>
              </li>
            </ul>
          </ListGroupItem>
        </ListGroup>
      </div>
    );
  }

  render() {
    let { intl } = this.props;
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Dialog ref="dialog" {...this.props} style={{ overflow: 'hidden' }}>
          <Col style={{ minHeight: 280, minWidth: 600 }}>
            <Card id="relatorioPag">
              <CardBody>
                <CardTitle>
                  {intl.formatMessage({ id: 'estatisticas' })}
                </CardTitle>
                <LoadingContainer isLoading={this.state.isLoading}>
                  <CardText>{this.renderGrafico()}</CardText>
                </LoadingContainer>
              </CardBody>
            </Card>
          </Col>
        </Dialog>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Estatistica));
