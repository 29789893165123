import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Panel from 'src/componentes/panel-collapse';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';

import Edit from './FormacoesAcademicasEditView';

function FormacoesAcademicas({ disabled, model, ...rest }) {
  const intl = useIntl();

  const handleRemove = (item, index) => {
    model.value.splice(index, 1);
    model.requestChange(model.value);
  };

  const handleSaveItem = (saved, isNew, item, index) => {
    if (saved) {
      let novaLista = model.value;

      if (isNew) {
        if (novaLista && novaLista.length > 0) novaLista.push(item);
        else novaLista = [].concat(item);
      } else novaLista[index] = item;

      model.requestChange(novaLista);
    }
  };

  const renderData = (item) => {
    if (!item.dataConclusao) return;

    return <span>{moment(item.dataConclusao).format('DD/MM/YYYY')}</span>;
  };

  const renderNome = (item) => <span>{item.nivelEducacional && item.nivelEducacional.nome}</span>;

  return (
    <Panel open={false} header={<FormattedMessage id="formacoesAcademicas" />}>
      <List
        local
        showTitle={false}
        dataSource={model.value}
        actionsWidth={30}
        handleSaveItem={handleSaveItem}
        handleRemove={handleRemove}
        editComponent={Edit}
        showPage={false}
        showSearch={false}
        showNew={!disabled}
        {...rest}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'nivelEducacional' })}
          valueFunction={renderNome}
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'curso' })}
          valueField="curso"
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'instituicaoEnsino' })}
          valueField="instituicaoEnsino"
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'dataConclusao' })}
          valueFunction={renderData}
        ></ListColumn>
      </List>
    </Panel>
  );
}

export default FormacoesAcademicas;
