import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import GatilhoEdit from '../paginas/remuneracao-variavel/gatilho/edit';
import GatilhoList from '../paginas/remuneracao-variavel/gatilho/view';

@autobind
class GatilhoLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueComponent={this.renderGatilho}
        valueKey="id"
        labelKey="nome"
        showSearch
        clearable={true}
        url="/GatilhoLookup"
        editComponent={GatilhoEdit}
        searchComponent={GatilhoList}
        {...this.props}
      />
    );
  }
}

export default GatilhoLookup;
