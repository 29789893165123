import React from 'react';
import { autobind } from 'core-decorators';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';

@autobind
class ListView extends React.Component {
  render() {
    let { intl, resources } = this.props;

    return (
      <List url="/ChaveResultado" titulo={capitalizeFirstLetter(resources.keyresult)} tag={14001} showNew={false} showEdit={false} showDelete={false} {...this.props}>
        <ListColumn headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'descricao' }))} valueField="descricao" required />
        <ListColumn headerText={capitalizeFirstLetter(resources.responsavel)} valueField="responsavel" />
        <ListColumn headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'dataInicio' }))} valueField="dataInicio" />
        <ListColumn headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'dataTermino' }))} valueField="dataTermino" />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ListView));
