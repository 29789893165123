import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import PerspectivaEdit from '../paginas/perspectiva/edit';

@autobind
class PerspectivaLookup extends React.Component {
  render() {
    return (
      <Lookup
        labelKey="descricao"
        valueKey="id"
        url="/PerspectivaLookup"
        editComponent={PerspectivaEdit}
        {...this.props}
      />
    );
  }
}

export default PerspectivaLookup;
