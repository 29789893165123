import { memo } from 'react';
import { BryntumGantt } from '@bryntum/gantt-react';
import { MultiPageVerticalExporter } from '@bryntum/gantt';

import ConfigApp from 'src/services/configApp';

const subGridConfigs = {
  locked: {
    flex: 2,
  },
  normal: {
    flex: 2,
  },
};

const syncMask = {
  showDelay: 0,
};

const pdfExport = {
  exportServer: `${ConfigApp.ApiUrl}/Acao/ExportatGanttPDF`,
  enableDirectRendering: true,
  exportDialog: {
    items: {
      fileFormatField: { hidden: true },
      rowsRangeField: { hidden: true },
    },
  },
  exporters: [MultiPageVerticalExporter],
  exporterType: 'multipagevertical'
};

const dependencyIdField = 'codigo';

const Gantt = ({
  ganttContainerId,
  gantt,
  project,
  componentConfigurations,
  isReadOnly,
}) => {
  return (
    <div style={{ height: 500 }}>
      <BryntumGantt
        id={ganttContainerId}
        ref={gantt}
        dependencyIdField={dependencyIdField}
        syncMask={syncMask}
        project={project}
        subGridConfigs={subGridConfigs}
        pdfExportFeature={pdfExport}
        readOnly={isReadOnly}
        {...componentConfigurations}
      />
    </div>
  );
};

export default memo(Gantt);
