import React from 'react';
import { autobind } from 'core-decorators';
import List from 'src/componentes/list/tree';
import ListColumn from 'src/componentes/list/list-column';
import ConsequenciasRiscoEdit from './edit';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ContentManager from 'src/componentes/content-manager';
import ConsequenciaControleEdit from '../../components/ConsequenciaControleEdit';
import Confirm from 'src/componentes/message-box/confirm';
import http from 'src/services/httpService';
import MdiIcon from 'src/componentes/mdi-icon';
import Button from 'src/componentes/button';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';
import { UncontrolledTooltip } from 'reactstrap';
import AssistenteVirtual from 'src/components/IA/ChatGPT/AssistenteVirtual';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/errorHandler';
import { TIPO_INTERACAO_SOPHIE } from 'src/utils/constants';

@autobind
class ConsequenciasRiscoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: -1,
      refreshAfterRemove: -1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshAfterRemove !== this.props.refreshAfterRemove) {
      this.setState({ saved: this.state.saved + 1 });
    }
  }

  renderDescricao(item, callback) {
    if (!item.descricao) {
      return;
    }
    return (
      <div id={'itemId' + item.id} className="pull-right">
        {item.controle ? (
          <Button
            id="deletePicture"
            className="pull-right"
            onClick={this.handleDesvincularConfirmClick.bind(
              this,
              item,
              callback
            )}
            color={'danger'}
          >
            <MdiIcon icon="delete-outline" />
          </Button>
        ) : (
          <a
            className="justify-content-end"
            tabindex="-1"
            onClick={this.handleDescricaoClick.bind(this, item, callback)}
            style={{ cursor: 'pointer' }}
          >
            {this.props.intl.formatMessage(
              { id: 'vincularControle' },
              { pontodecontrole: this.props.resources.pontodecontrole }
            )}
          </a>
        )}
      </div>
    );
  }

  handleDescricaoClick(item) {
    ContentManager.addContent(
      <ConsequenciaControleEdit
        defaultActiveKey={0}
        handleClose={this.handleCloseEdit}
        item={item}
      />
    );
  }

  handleDesvincularClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/RiscoConsequenciaControle/ValidarRestricoes"
        item={item}
        urlExclusao={'RiscoConsequencias/Remove'}
        isEdit={false}
        onClose={this.handleCloseEdit}
        validarAlteracao={false}
      />
    );
  }

  handleConfirmarExclusao(item) {
    http
      .post('RiscoConsequenciaControle/Remove', {
        id: item.id,
      })
      .then(() => {
        this.handleCloseEdit(true);
        this.props.refreshCount && this.props.refreshCount(true);
      });
  }

  handleDesvincularConfirmClick(item) {
    ContentManager.addContent(
      <Confirm
        message={this.props.intl.formatMessage(
          { id: 'desvincularControleCausaConsequencia' },
          { pontodecontrole: this.props.resources.pontodecontrole }
        )}
        handleConfirm={this.handleConfirmarExclusao.bind(this, item)}
      />
    );
  }

  handleOpenAddConsequencia() {
    ContentManager.addContent(
      <ConsequenciasRiscoEdit
        url="/RiscoConsequencias"
        parameters={{ riscoId: this.props.riscoId }}
        afterSave={this.props.refreshCount}
        valueField="descricao"
        saved={this.state.saved}
        {...this.props}
      />
    );
  }

  renderButtonAddConsequenciaRiscoIA() {
    return [
      <Button
        id={'botaoMapearconsequenciasRisco'}
        key={1}
        className="ml-2"
        onClick={() => this.handleOpenAddConsequencia()}
        type="primary"
      >
        <MdiIcon icon="plus" />
        <span className="ml-2">
          {capitalizeFirstLetter(this.props.resources.consequencias)}
        </span>
      </Button>,
    ];
  }

  handleAssistenteVirtualClick() {
    const { user, intl, resources } = this.props;

    try {
      http
        .post('/RiscoConsequencias/ObterPromptMapearConsequencias', {
          idRisco: this.props.riscoId,
        })
        .then((response) => {
          const prompt = response.data?.prompt;
          const nome = response.data?.nome;

          const msgConsultoraVirtual = intl
            .formatMessage({ id: 'tituloSophieMapearConsequenciasRisco' })
            .replace(/{risco}|{nomeRisco}/gi, function (matched) {
              switch (matched) {
                case '{risco}':
                  return resources.risco;
                case '{nomeRisco}':
                  return nome;
              }
            });

          ContentManager.addContent(
            <AssistenteVirtual
              user={user}
              prompt={prompt}
              title={msgConsultoraVirtual}
              actionsToolBar={this.renderButtonAddConsequenciaRiscoIA()}
              questionBold={intl.formatMessage({ id: 'vamosComecar' })}
              interactionType={TIPO_INTERACAO_SOPHIE.SUGERIR_CONSEQUENCIA_RISCO}
            />
          );
        });
    } catch (err) {
      errorHandler(err);
    }
  }

  handleCloseEdit(saved) {
    if (saved) {
      this.props.refreshCount && this.props.refreshCount(saved);
      this.setState({ saved: Math.random() });
    }
  }

  render() {
    const {
      configuracao,
      disabled,
      intl,
      moduloInteligenciaArtificial,
      resources,
    } = this.props;

    const actionsToolBar = moduloInteligenciaArtificial
      ? [
          <>
            <Button
              id={'botaoMapearConsequenciasRisco'}
              key={1}
              className="ml-2"
              onClick={this.handleAssistenteVirtualClick}
              type="primary"
            >
              <img alt="IA logo" src="/ia/ia_branco.svg" />
              <span className="ml-2">
                {intl.formatMessage(
                  { id: 'mapearConsequencias' },
                  {
                    consequencias: capitalizeFirstLetter(
                      resources.consequencias
                    ),
                  }
                )}
              </span>
            </Button>
            <UncontrolledTooltip
              placement="right"
              target={'botaoMapearConsequenciasRisco'}
            >
              {intl.formatMessage(
                {
                  id: 'labelSophieToolTipMapearConsequenciasRisco',
                },
                {
                  riscos: resources.riscos,
                }
              )}
            </UncontrolledTooltip>
          </>,
        ]
      : [];

    return (
      <List
        editComponent={ConsequenciasRiscoEdit}
        url="/RiscoConsequencias"
        showDelete={true}
        showExportItem={false}
        showExport={false}
        hierarchy={true}
        valueField="descricao"
        saved={this.state.saved}
        showNew={!disabled}
        showMenu={!disabled}
        parameters={{ riscoId: this.props.riscoId }}
        afterSave={this.props.refreshCount}
        afterDelete={this.props.refreshCount}
        onDeleting={this.handleDesvincularClick}
        actionsToolBarEnd={actionsToolBar}
        hideInsertDropDown={true}
        {...this.props}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'descricao' })}
          tree={true}
          valueField="descricao"
          required
        />
        {!disabled &&
          configuracao &&
          configuracao.associarControleCausaConsequencia && (
            <ListColumn
              valueField="addControle"
              valueFunction={this.renderDescricao}
              required
            />
          )}
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloInteligenciaArtificial: state.user.moduloInteligenciaArtificial,
    user: state.user,
  };
}
export default injectIntl(connect(mapStateToProps)(ConsequenciasRiscoList));
