import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
export default class TipoCardLookup extends React.Component {
  render() {
    return <Lookup labelKey="nome" valueKey="id" url="/TipoCardLookup" {...this.props} />;
  }
}
