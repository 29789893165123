import EstratificacaoPareto from 'src/paginas/EstratificacaoPareto';

const EstratificacaoParetoPlanejamentoIncial = ({
  item,
  step1Data,
  handleChangeData,
  allowEdit,
}) => {
  const handleChange = (v) => {
    handleChangeData('step1Data', 'estratificacoesValores', v);
    handleChangeData('step1Data', 'alterado', true);
  };
  return (
    <EstratificacaoPareto
      item={item}
      estratificacoesModel={{
        label: '',
        value:
          step1Data && step1Data.estratificacoesValores
            ? step1Data.estratificacoesValores
            : [],
        requestChange: (v) => handleChange(v),
      }}
      contadorInicial={
        step1Data && step1Data.contadorInicialTabelaEstratificacao
      }
      allowEdit={allowEdit}
    />
  );
};

export default EstratificacaoParetoPlanejamentoIncial;
