import React from 'react';
import classnames from 'classnames';
import { autobind } from 'core-decorators';
import css from '../../utils/css';
import Ripple from '../ripple';
import MdiIcon from '../mdi-icon';

let classes = css`
  .button {
    border: none;
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    padding: 8px;
    background: none;
    line-height: 1px;

    &[disabled] {
      cursor: default;
      opacity: 0.4;
    }
  }
`;

@autobind
class IconButton extends React.Component {
  render() {
    let { icon, color, size, className, ...other } = this.props;

    let composedClassName = classnames(classes.button, className);

    return (
      <Ripple {...other} component="button" className={composedClassName} center>
        <MdiIcon icon={icon} color={color} size={size} />
      </Ripple>
    );
  }
}

export default IconButton;
