import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Results from 'src/componentes/NewList/Results';
import useAuth from 'src/hooks/useAuth';
import errorHandler from 'src/utils/error-handler';
import http from 'src/services/httpService';
import { capitalizeFirstLetter } from 'src/utils/string';

const List = ({ acaoId, configuration }) => {
  const intl = useIntl();

  const { terms: resources } = useAuth();

  const [columns] = useState([
    {
      id: 1,
      visible: true,
      valueField: 'periodo',
      headerText: intl.formatMessage({ id: 'periodo' }),
    },
    {
      id: 2,
      visible: configuration?.utilizarPercentualPrevisto,
      valueField: 'previsto',
      headerText: capitalizeFirstLetter(resources.percprevisto),
    },
    {
      id: 3,
      visible: configuration?.utilizarPercentualRealizado,
      valueField: 'realizado',
      headerText: capitalizeFirstLetter(resources.percrealizado),
    },
  ]);

  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const response = await http.post('Acao/ObterListaPercentuais', {
        acaoId,
      });
      setData(response.data);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return <Results data={data} columns={columns} />;
};

export default List;
