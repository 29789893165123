import React from 'react';
import { autobind } from 'core-decorators';
import { Creatable } from 'react-select-v2';
import Select, { components } from 'react-select-v2';
import { FormGroup, FormFeedback, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import '../../styles/react-select.scss';

import css from '../../utils/css';
import httpSelector from 'src/services/httpSelector';
import ItemMulti from '../item-multi-lookup';
import ContentManager from '../content-manager';

@autobind
class Select2 extends React.Component {
  static defaultProps = {
    showLabel: true,
    menuPlacement: 'auto',
    menuPosition: 'fixed',
    pageSize: 1000,
    selectNewItem: true,
    moduloLookup: false,
  };

  constructor(props) {
    super(props);

    const { multiValueWrapper } = this.props;
    this.state = {
      data: this.props.options ? this.props.options : [],
      classes: css`
        .select {
          .Select-control {
            /*height: 34px;
          line-height: 30px;*/
            border: 1px solid #dde6e9;

            .Select-multi-value-wrapper {
              ${multiValueWrapper &&
              this.setMultiValueWrapper(multiValueWrapper)}
              .Select-input {
                /*height: 31px;*/
              }
            }
          }
        }
      `,
    };
  }

  setMultiValueWrapper = (multiValueWrapper) =>
    !Array.isArray(multiValueWrapper)
      ? multiValueWrapper
      : multiValueWrapper.join(';');

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      JSON.stringify(newProps.data) !== JSON.stringify(this.props.data) ||
      newProps.saved !== this.props.saved
    ) {
      this.loadData(newProps.data);
    }
  }

  async componentDidMount() {
    if (this.props.parametroUrl) {
      this.props.model.value = this.props.parametroUrl;
    }
    if (this.props.model.value) {
      var labelKey = this.props.model.value[this.props.labelKey];
      var valueKey = this.props.model.value[this.props.valueKey];
      if (!labelKey && valueKey) {
        // busca a descricao do lookup caso seja passado somente o ID
        var response = await httpSelector
          .getHttp(this.props.viewType)
          .post(`${this.props.url}/Search`, {
            id: valueKey,
          });
        var returnValue = response.data.list.find((v) => v.id == valueKey);
        if (returnValue) {
          this.props.model.requestChange &&
            this.props.model.requestChange(returnValue);
        }
      }
    }

    if (this.props.url) this.loadData(this.props.data);
  }

  loadData(data) {
    httpSelector
      .getHttp(this.props.viewType)
      .post(`${this.props.url}/Search`, {
        filter: data,
        pageSize: this.props.pageSize,
      })
      .then((response) => {
        this.setState({
          data: response.data.list,
        });

        this.props.getDataLookup &&
          this.props.getDataLookup(response.data.list);

        if (
          this.props.selectFirstValue &&
          !this.props.model.value &&
          response?.data?.list &&
          response.data.list.length > 0
        )
          this.props.model.requestChange &&
            this.props.model.requestChange(response.data.list[0]);
      });
  }

  handleChange(value) {
    this.props.model.requestChange && this.props.model.requestChange(value);
  }

  handleChangeCreatable(value) {
    let isNew = value && value.isNew;

    if (this.props.multi && value && value.length > 0) {
      isNew = value && value[value.length - 1].isNew;
    }

    if (isNew) {
      this.handleNewClick(value);
    } else {
      this.handleChange(value);
    }
  }

  getDescricaoMulti(list) {
    return list.map((item) => item[this.props.labelKey]).join('; ');
  }

  renderMulti(item) {
    if (this.props.valueComponent) return this.props.valueComponent();
    else {
      if (this.props.multi) {
        return (
          <ItemMulti
            disabled={this.props.disabled}
            label={item.value[this.props.labelKey]}
            onRemove={item.onRemove.bind(this, item.value)}
            multi={this.props.multi}
          ></ItemMulti>
        );
      } else {
        return (
          <div className="Select-value">
            <span className="Select-value-label">
              {item.value[this.props.labelKey]}
            </span>
          </div>
        );
      }
    }
  }

  renderOption(item) {
    if (this.props.valueComponent) {
      return (
        <components.Option {...item}>
          {this.props.valueComponent(item, true)}
        </components.Option>
      );
    } else {
      return <components.Option {...item}>{item.children}</components.Option>;
    }
  }

  singleValue(item) {
    if (this.props.valueComponent) {
      return (
        <components.SingleValue {...item}>
          {this.props.valueComponent(item)}
        </components.SingleValue>
      );
    } else {
      return (
        <components.SingleValue {...item}>
          {item.children}
        </components.SingleValue>
      );
    }
  }

  renderMultiValue(item) {
    if (this.props.valueComponent) {
      return (
        <components.MultiValue {...item}>
          {this.props.valueComponent(item)}
        </components.MultiValue>
      );
    } else {
      return (
        <components.MultiValue {...item}>{item.children}</components.MultiValue>
      );
    }
  }

  handleNewClick(value) {
    const data = this.props.data || {};
    ContentManager.addContent(
      <this.props.editComponent
        parameters={{ ...data, nome: value[this.props.labelKey] }}
        handleClose={this.handleClose}
      />
    );
  }

  handleClose(saved, id) {
    if (saved) {
      this.loadData(this.props.data);

      httpSelector
        .getHttp(this.props.viewType)
        .get(`${this.props.url}/Get`, {
          params: {
            id: id,
          },
        })
        .then((response) => {
          if (this.props.selectNewItem) {
            this.toggleItem(response.data);
          }
        });
    }
  }

  toggleItem(item) {
    if (this.props.multi && item) {
      const lista = this.props.model && this.props.model.value;
      lista.push(item);
      this.props.model.requestChange(lista);
    } else {
      this.props.model.requestChange(item);
    }
  }

  getValue(objectValue, model) {
    if (
      (objectValue && !objectValue[this.props.labelKey]) ||
      (!this.props.multi &&
        model &&
        model.value &&
        !model.value[this.props.labelKey])
    ) {
      return this.state.data
        ? objectValue
          ? this.state.data.find(
              (x) => x[this.props.valueKey] == objectValue[this.props.valueKey]
            )
          : this.state.data.find(
              (x) =>
                x[this.props.valueKey] ==
                (model.value[this.props.valueKey] ?? model.value)
            )
        : objectValue || model.value;
    } else return objectValue || model.value;
  }

  getOptionLabel(option) {    
    if (this.props.moduloLookup && option.nomeExibicao) {
      return `${option.nomeExibicao} - ${option.nome}`;
    }
    return option[this.props.labelKey];
  }
  

  getOptionValue(option) {
    return option[this.props.valueKey];
  }

  CustomMultiValueLabel = (props) => {
    if (this.props.renderLabelMulti) {
      return (
        <components.MultiValueLabel {...props}>
          {this.props.renderLabelMulti(props.data)}
        </components.MultiValueLabel>
      );
    }

    return (
      <components.MultiValueLabel {...props}>
        {props.data[this.props.labelKey]}
      </components.MultiValueLabel>
    );
  };

  render() {
    let {
      model,
      multi,
      valueKey,
      labelKey,
      options,
      optionRenderer,
      valueRenderer,
      clearable,
      disabled,
      placeholder = '',
      required,
      style,
      autoFocus,
      editComponent,
      url,
      intl,
      row,
      showLabel,
      objectValue,
      menuPlacement,
      customStyle,
      menuPosition,
      controlStyle = {},
      ...other
    } = this.props;

    return (
      <FormGroup
        row={row}
        validationstate={model.errors ? 'error' : null}
        style={style}
      >
        {showLabel && model.label && (
          <Label className="control-label">
            {model.label} {required ? '*' : ''}
          </Label>
        )}
        {editComponent ? (
          <Creatable
            styles={{
              ...customStyle,
              control: (base) => ({
                ...base,
                borderColor:
                  required && model.errors != null && model.errors != ''
                    ? '#f05050'
                    : '#dde6e9',
              }),
              menuPortal: (base) => ({ ...base, zIndex: 15000 }),
            }}
            menuPortalTarget={document.body}
            getOptionLabel={this.getOptionLabel}
            getOptionValue={this.getOptionValue}
            getNewOptionData={(inputValue, optionLabel) => ({
              [valueKey]: inputValue,
              [labelKey]: optionLabel,
              isNew: true,
            })}
            isDisabled={disabled}
            autoFocus={autoFocus}
            valueComponent={this.renderMulti}
            clearValueText={intl.formatMessage({ id: 'label.limparValor' })}
            formatCreateLabel={(label) => (
              <a tabIndex="-1">
                {intl.formatMessage(
                  { id: 'label.cliqueAquiParaCriarLabel' },
                  { label: label }
                )}
              </a>
            )}
            isClearable={clearable}
            valueRenderer={valueRenderer}
            optionRenderer={optionRenderer}
            onChange={this.handleChangeCreatable}
            isMulti={multi}
            placeholder={placeholder}
            menuPlacement={menuPlacement}
            menuPosition={menuPosition}
            className={this.state.classes.select}
            value={model.value}
            options={options || this.state.data}
            {...other}
          />
        ) : (
          <Select
            styles={{
              ...customStyle,
              control: (base) => ({
                ...base,
                ...controlStyle,
                borderColor:
                  required && model.errors != null && model.errors != ''
                    ? '#f05050'
                    : '#dde6e9',
              }),
              menuPortal: (base) => ({ ...base, zIndex: 15000 }),
            }}
            getOptionValue={this.getOptionValue}
            getOptionLabel={this.getOptionLabel}
            menuPortalTarget={document.body}
            isDisabled={disabled}
            components={
              this.props.valueComponent && {
                Option: this.renderOption,
                SingleValue: this.singleValue,
                MultiValue: this.renderMultiValue,
                MultiValueLabel: this.CustomMultiValueLabel,
              }
            }
            autoFocus={autoFocus}
            clearValueText={intl.formatMessage({ id: 'label.limparValor' })}
            noOptionsMessage={() =>
              intl.formatMessage({ id: 'label.nenhumRegistroEncontrado' })
            }
            isClearable={clearable}
            onChange={this.handleChange}
            isMulti={multi}
            className={this.state.classes.select}
            value={this.getValue(objectValue, model)}
            options={options || this.state.data}
            placeholder={placeholder}
            menuPlacement={menuPlacement}
            menuPosition={menuPosition}
            {...other}
          />
        )}
        {model.erros && <FormFeedback>{model.erros}</FormFeedback>}
      </FormGroup>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    viewType: state.viewType,
  };
}
export default injectIntl(connect(mapStateToProps)(Select2));
