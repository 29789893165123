import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list/tree';
import ListColumn from '../../../componentes/list/list-column';
import { connect } from 'react-redux';
import Content from '../../../componentes/pages/content';
import ContentManager from '../../../componentes/content-manager';
import { capitalizeFirstLetter } from '../../../utils/string';
import { injectIntl, FormattedMessage } from 'react-intl';
import Edit from './edit';
import history from '../../../history';
import Search from './search';
import ValidacaoRestricao from '../../item/validacao-restricao';
import SearchModal from './search-modal';
import SearchSchema from './search-schema';
import SearchSchemaModal from './search-schema-modal';
import http from 'src/services/httpService';

@autobind
class CentroCusto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      exibirClassificacao: false,
      changedColumns: false,
    };
  }

  componentDidMount() {
    http.post(`/CentroCusto/ObterConfiguracaoSistema`, {}).then((response) => {
      this.setState({
        exibirClassificacao: response.data.exibirClassificacao,
        changedColumns: true,
      });
    });
  }

  handleNewClick(item, parameters, handleEditClose) {
    ContentManager.addContent(<Edit mode="edit" parameters={{ ...parameters, parentId: item.id }} handleClose={handleEditClose} />);
  }

  handleClose(saved) {
    if (saved) this.setState({ saved: !this.state.saved });
  }

  handleRelacionamentosClick(item) {
    history.push({
      pathname: '/gerenciar/arvoreCentroCusto',
      search: `?id=${item.id}`,
    });
  }

  handleMatrizMensalClick(item) {
    history.push({
      pathname: '/mo/acompanhar/matriz-mensal',
      search: `?centroCustoId=${item.id}`,
    });
  }

  handlePlanoAcaoClick(item) {
    history.push({
      pathname: '/gerenciar/gantt',
      search: `?centroCustoId=${item.id}`,
    });
  }

  handleMatrizResultadoClick(item) {
    history.push({
      pathname: '/mo/acompanhar/matriz-resultado',
      search: `?centroCustoId=${item.id}`,
    });
  }

  handleValoresPacoteClick(item) {
    history.push({
      pathname: '/mo/cadastrar/valores-pacote',
      search: `?centroCustoId=${item.id}`,
    });
  }

  handleParetoDesviosClick(item) {
    history.push({
      pathname: '/mo/acompanhar/desvios-centro-custo',
      search: `?centroCustoId=${item.id}&tipoDesvioId=2`,
    });
  }

  handleBoasPraticasClick(item) {
    history.push({
      pathname: '/mo/acompanhar/boas-praticas',
      search: `?centroCustoId=${item.id}`,
    });
  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao url="/CentroCusto/ValidarRestricoes" urlExclusao="/CentroCusto/AtualizarStatusExcluido" item={item} onClose={this.handleClose} />
    );
  }

  render() {
    let { resources, intl, showModal } = this.props;
    let { exibirClassificacao } = this.state;

    let createChildItems = [
      {
        label: <FormattedMessage id="label.centrosCustoSubordinado" values={{ centrocusto: resources.centrocusto }} />,
        icon: 'format-list-bulleted',
        visible: () => true,
        onClick: this.handleNewClick,
      },
      {
        label: capitalizeFirstLetter(resources.relacionamentos),
        icon: 'sitemap',
        visible: () => true,
        onClick: this.handleRelacionamentosClick,
      },
      {
        label: <FormattedMessage id="matrizMensal" />,
        icon: 'calendar-today',
        visible: () => true,
        onClick: this.handleMatrizMensalClick,
      },
      {
        label: capitalizeFirstLetter(resources.planodeacao),
        icon: 'chart-gantt',
        visible: () => true,
        onClick: this.handlePlanoAcaoClick,
      },
      {
        label: <FormattedMessage id="matrizDeResultado" />,
        icon: 'calendar-star',
        visible: () => true,
        onClick: this.handleMatrizResultadoClick,
      },
      {
        label: <FormattedMessage id="valoresPacote" values={{ valores: resources.valores }} />,
        icon: 'chart-bar',
        visible: () => true,
        onClick: this.handleValoresPacoteClick,
      },
      {
        label: <FormattedMessage id="paretoDesvios" />,
        icon: 'chart-line',
        visible: () => true,
        onClick: this.handleParetoDesviosClick,
        // },{
        //   label: <FormattedMessage id="boasPraticas" />,
        //   icon: 'certificate',
        //   visible: (() => true),
        //   onClick: this.handleBoasPraticasClick
      },
    ];

    return (
      <Content titulo={intl.formatMessage({ id: 'centrosDeCusto' }, { centroscusto: resources.centroscusto })}>
        <List
          url="/CentroCusto"
          editComponent={Edit}
          createChildItems={createChildItems}
          sort="nome"
          tag={showModal ? 10011 : 10005}
          showSearch={true}
          searchComponent={showModal ? <SearchModal exibirClassificacao={exibirClassificacao} /> : <Search exibirClassificacao={exibirClassificacao} />}
          searchSchema={showModal ? SearchSchemaModal : SearchSchema}
          filtroId={showModal ? 104 : 103}
          onDeleting={this.handleDeleteClick}
          saved={this.state.saved}
          changedColumns={this.state.changedColumns}
          hierarchy={true}
          {...this.props}
        >
          <ListColumn tree={true} headerText={intl.formatMessage({ id: 'nome' })} valueField="nome" sortField="nome" default required />
          <ListColumn headerText={intl.formatMessage({ id: 'idIntegracao' })} valueField="idIntegracao" sortField="idIntegracao" default />
          <ListColumn headerText={intl.formatMessage({ id: 'registroSuperior' })} valueField="registroSuperior" default />
          <ListColumn headerText={capitalizeFirstLetter(resources.responsavel)} valueField="responsavel" default />
          <ListColumn
            headerText={intl.formatMessage({ id: 'label.gerarRelatorioAcompanhamento' }, { relatoriodeacompanhamento: resources.relatoriodeacompanhamento })}
            valueField="gerarRelatorioAcompanhamento"
            default
          />
          <ListColumn headerText={intl.formatMessage({ id: 'status' })} valueField="status" default />
          {exibirClassificacao && <ListColumn headerText={intl.formatMessage({ id: 'label.classificacao' })} valueField="classificacao" minWidth={120} />}
        </List>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CentroCusto));
