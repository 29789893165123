import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  riscoAtrelado: Schema.string().label(<FormattedMessage id="labelRiscoAtrelado" />),
  responsavel: Schema.string().label(<FormattedMessage id="responsavel" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
});
