import React from 'react';
import { autobind } from 'core-decorators';
import classNames from 'classnames';

import css from 'src/utils/css';
import MdiIcon from '../mdi-icon';
import ConfigApp from 'src/services/configApp';
import getInitials from 'src/utils/getInitials';

const baseClasses = css`
  .avatar {
    border: 10px;
    box-sizing: border-box;
    display: inline-flex;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    margin: 4px;
    padding: 0px;
    outline: none;
    position: relative;
    background-color: rgb(224, 224, 224);
    border-radius: 16px;
    white-space: nowrap;
    width: fit-content;
    max-width: 90%;

    .img-perfil {
      color: rgb(255, 255, 255);
      background-color: rgb(188, 188, 188);
      user-select: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      margin-right: -4px;
      margin-top: 1px;
    }

    .label {
      font-weight: 400;
      line-height: 32px;
      padding-left: 8px;
      padding-right: 12px;
      user-select: none;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.87);
    }

    .close {
      cursor: pointer;
    }
  }
`;

@autobind
class ItemMultiLookup extends React.Component {
  tratarUnidade(valor) {
    if (valor === undefined || valor === null) {
      return;
    }

    if (typeof valor == `string`) {
      if (valor.indexOf('%') !== -1) {
        return valor;
      } else if (valor.indexOf('px') !== -1) {
        return valor;
      }
    }

    return valor + 'px';
  }

  render() {
    let {
      foto,
      label,
      size,
      avatar,
      fontSize,
      fontWeight,
      onlyAvatar,
      onRemove,
      disabled,
      customClassname = '',
    } = this.props;

    let classes = css`
      .avatar {
        width: ${this.tratarUnidade(size) || 'auto'};
        height: ${this.tratarUnidade(size) || 'auto'};
        font-size: ${fontSize + 'px' || 'inherit'};
        font-weight: ${fontWeight + 'px' || 'inherit'};
        vertical-align: middle;
      }
    `.extend(baseClasses);

    return (
      <div className={classNames(classes.avatar, customClassname)}>
        {avatar &&
          (foto && foto.id > 0 ? (
            <img
              className="img-perfil"
              style={{
                height: size && this.tratarUnidade(size),
                width: this.tratarUnidade(size),
              }}
              src={`${ConfigApp.ApiUrl}/ArquivoSistema/DownloadImagem?guid=${foto.guid}`}
              alt="perfil"
            ></img>
          ) : (
            <div
              className="img-perfil"
              style={{
                height: size && this.tratarUnidade(size),
                width: this.tratarUnidade(size),
              }}
            >
              {label && getInitials(label)}
            </div>
          ))}
        {onlyAvatar != true && (
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'inline-block',
              maxWidth: '90%',
              horizontalAlign: 'middle',
              verticalAlign: -10,
            }}
          >
            <span className="label">{label}</span>
          </div>
        )}
        {onlyAvatar != true && onRemove && (
          <MdiIcon
            icon="close"
            onClick={!disabled && onRemove}
            style={{
              alignSelf: 'center',
              marginRight: label?.length > 35 ? 0 : 10,
              textAlign: 'right',
              paddingLeft: label?.length > 35 ? 0 : 5,
            }}
          ></MdiIcon>
        )}
      </div>
    );
  }
}

export default ItemMultiLookup;
