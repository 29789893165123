import { FormattedMessage } from 'react-intl';
import React from 'react';

import { Schema } from 'src/utils/form';

export default Schema.object({
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  pontoControle: Schema.string().required(),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  responsaveis: Schema.string().label(<FormattedMessage id="responsaveis" />),
  status: Schema.string()
    .label(<FormattedMessage id="status" />)
    .required(),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  pontoControleEdit: Schema.string(),
  tipoTolerancia: Schema.string().label(<FormattedMessage id="tipoTolerancia" />),
  diasToleranciaRevisao: Schema.string().label(<FormattedMessage id="tolerancia" />),
  numeroRevisoesGeradasAutomaticamente: Schema.string().label(<FormattedMessage id="numeroRevisoesGeradasAutomaticamente" />),
});
