import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Row, Col } from 'reactstrap';

import useAuth from 'src/hooks/useAuth';
import { Schema } from 'src/utils/form';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import { capitalizeFirstLetter } from 'src/utils/string';
import Radio from 'src/componentes/radio';
import EtapasListView from './Etapas';
import TipoMudancaSolicitacaoGestaoMudancasProjetoLookup from 'src/lookups/TipoMudancaSolicitacaoGestaoMudancasProjetoLookup';
import TipoMudancaSolicitacaoGestaoMudancasIndicadorLookup from 'src/lookups/TipoMudancaSolicitacaoGestaoMudancasIndicadorLookup';
import TipoMudancaSolicitacaoGestaoMudancasRiscoLookup from 'src/lookups/TipoMudancaSolicitacaoGestaoMudancasRiscoLookup';
import TipoMudancaSolicitacaoGestaoMudancasDocumentoLookup from 'src/lookups/TipoMudancaSolicitacaoGestaoMudancasDocumentoLookup';
import TipoFluxoAprovacaoLookup from 'src/lookups/tipo-fluxoaprovacao';
import { MODULOS } from 'src/utils/constants';

const schema = Schema.object({
  idIntegracao: Schema.string().required(),
  nome: Schema.string().required(),
  etapas: Schema.string().required(),
  organizacao: Schema.string(),
});

const EditForm = ({ model }) => {
  const intl = useIntl();

  const modelIdIntegracao = model.getModel('idIntegracao');
  const modelNome = model.getModel('nome');
  const modelEtapas = model.getModel('etapas');
  const modelOrganizacao = model.getModel('organizacao');
  const modelTiposMudancasProjeto = model.getModel('tiposMudancasProjeto');
  const modelTiposMudancasIndicador = model.getModel('tiposMudancasIndicador');
  const modelTiposMudancasRisco = model.getModel('tiposMudancasRisco');
  const modelTipoItemFluxoAprovacao = model.getModel('tipoItemFluxoAprovacao');
  const modelTiposMudancasDocumento = model.getModel('tiposMudancasDocumento');
  const modelTipoFluxoAprovacao = model.getModel('tipoFluxoAprovacao');
  const ehFluxoNegociacaoMetas =
    modelTipoFluxoAprovacao &&
    modelTipoFluxoAprovacao.value &&
    modelTipoFluxoAprovacao.value.id == 2;

  const { terms: resources, module } = useAuth();

  const isBelt = module.id == MODULOS.GESTAO_RISCO;

  let disabled = model.getModel('permissions').value
    ? !model.getModel('permissions').value?.allowEdit
    : false;

  const showDeleteIcon = !disabled;

  if (disabled && ehFluxoNegociacaoMetas) {
    disabled = !model.getModel('permissions').value?.allowEditFallback;
  }

  return (
    <>
      <Row>
        <Col md={3}>
          <TipoFluxoAprovacaoLookup
            model={{
              label: intl.formatMessage({ id: 'tipo' }),
              value: modelTipoFluxoAprovacao.value,
              requestChange: modelTipoFluxoAprovacao.requestChange,
              errors: modelTipoFluxoAprovacao.errors,
            }}
            clearable={false}
            required
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Input
            formStyle={{ marginBottom: 0 }}
            disabled={disabled}
            model={{
              label: intl.formatMessage({ id: 'idIntegracao' }),
              value: modelIdIntegracao.value,
              requestChange: modelIdIntegracao.requestChange,
              errors: modelIdIntegracao.errors,
            }}
            required
          />
        </Col>
        <Col md={5}>
          <Input
            formStyle={{ marginBottom: 0 }}
            disabled={disabled}
            model={{
              label: intl.formatMessage({ id: 'nome' }),
              value: modelNome.value,
              requestChange: modelNome.requestChange,
              errors: modelNome.errors,
            }}
            required
          />
        </Col>
        {
          //se não for negociação de metas
          !ehFluxoNegociacaoMetas && (
            <Col md={4}>
              <ItemOrganizacaoLookup
                formStyle={{ marginBottom: 0 }}
                disabled={disabled}
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage({ id: 'area' }, { area: resources.area })
                  ),
                  value: modelOrganizacao.value,
                  requestChange: modelOrganizacao.requestChange,
                  errors: modelOrganizacao.errors,
                }}
              />
            </Col>
          )
        }
      </Row>

      {
        //se não for negociação de metas
        !ehFluxoNegociacaoMetas && (
          <>
            <Row>
              <Col md={12}>
                <Radio
                  disabled={disabled}
                  model={{
                    label: intl.formatMessage({ id: 'label.tipoItem' }),
                    value: modelTipoItemFluxoAprovacao.value,
                    requestChange: modelTipoItemFluxoAprovacao.requestChange,
                    errors: modelTipoItemFluxoAprovacao.errors,
                  }}
                  options={model.getModel('opcoesTipoItemFluxoAprovacao').value}
                  labelField="descricao"
                  name="opcoesTipoItemFluxoAprovacao"
                  idField="id"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                {model.getModel('tipoItemFluxoAprovacao') &&
                  model.getModel('tipoItemFluxoAprovacao').value &&
                  model.getModel('tipoItemFluxoAprovacao').value.id == 1 && (
                    <TipoMudancaSolicitacaoGestaoMudancasProjetoLookup
                      multi
                      disabled={disabled}
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage({ id: 'tipoMudanca' })
                        ),
                        value: modelTiposMudancasProjeto.value,
                        requestChange: modelTiposMudancasProjeto.requestChange,
                        errors: modelTiposMudancasProjeto.errors,
                      }}
                    />
                  )}
                {model.getModel('tipoItemFluxoAprovacao') &&
                  model.getModel('tipoItemFluxoAprovacao').value &&
                  model.getModel('tipoItemFluxoAprovacao').value.id == 2 && (
                    <TipoMudancaSolicitacaoGestaoMudancasIndicadorLookup
                      multi
                      disabled={disabled}
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage({ id: 'tipoMudanca' })
                        ),
                        value: modelTiposMudancasIndicador.value,
                        requestChange:
                          modelTiposMudancasIndicador.requestChange,
                        errors: modelTiposMudancasIndicador.errors,
                      }}
                    />
                  )}
                {model.getModel('tipoItemFluxoAprovacao') &&
                  model.getModel('tipoItemFluxoAprovacao').value &&
                  model.getModel('tipoItemFluxoAprovacao').value.id == 3 && (
                    <TipoMudancaSolicitacaoGestaoMudancasRiscoLookup
                      multi
                      disabled={true} //Deixar desabilitado porque é a única opção, seguindo assim o protótipo de tela. No futuro, caso surjam outras opções, isso deve ser mudado.
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage({ id: 'tipoSolicitacao' })
                        ),
                        value: modelTiposMudancasRisco.value,
                        requestChange: modelTiposMudancasRisco.requestChange,
                        errors: modelTiposMudancasRisco.errors,
                      }}
                      style={{ marginTop: 25 }}
                    />
                  )}
                {model.getModel('tipoItemFluxoAprovacao') &&
                  model.getModel('tipoItemFluxoAprovacao').value &&
                  model.getModel('tipoItemFluxoAprovacao').value.id == 4 && (
                    <TipoMudancaSolicitacaoGestaoMudancasDocumentoLookup
                      multi
                      disabled={disabled}
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage({ id: 'tipoSolicitacao' })
                        ),
                        value: modelTiposMudancasDocumento.value,
                        requestChange:
                          modelTiposMudancasDocumento.requestChange,
                        errors: modelTiposMudancasDocumento.errors,
                      }}
                      style={{ marginTop: 25 }}
                    />
                  )}
              </Col>
            </Row>
          </>
        )
      }

      <hr></hr>
      <Row>
        <Col md={12}>
          <div>
            <div className="font-weight-bolder text-lg">
              {intl.formatMessage({ id: 'fluxo' })}
            </div>
            <div className="text-muted mt-2 mb-3 text-sm">
              {intl.formatMessage({
                id: 'fluxoAprovacaoGenericoEtapaExplicacao',
              })}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <EtapasListView
          model={modelEtapas}
          disabled={disabled}
          ehFluxoNegociacaoMetas={ehFluxoNegociacaoMetas}
          showDeleteIcon={showDeleteIcon}
        />
      </Row>
    </>
  );
};

const EditView = (props) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const AfterSave = (data, isNew) => {
    if (
      data.tipoFluxoAprovacao &&
      data.tipoFluxoAprovacao.id == 2 &&
      !data.permissions.allowEdit &&
      data.permissions.allowEditFallback
    ) {
      enqueueSnackbar(
        intl.formatMessage(
          { id: 'reprocesseFuncaoCiclo' },
          { funcoes: resources.funcoes }
        ),
        {
          variant: 'warning',
          autoHideDuration: 5000,
        }
      );
    }
  };

  return (
    <Edit
      url="/FluxoAprovacaoGenerico"
      title={intl.formatMessage({ id: 'fluxoAprovacao' })}
      schema={schema}
      formComponent={EditForm}
      width="90%"
      maxWidth="80%"
      maxHeight="80%"
      afterSave={AfterSave}
      intl={intl}
      {...props}
      showExport={false}
    />
  );
};

export default EditView;
