import React from 'react';
import { autobind } from 'core-decorators';
import EditDialog from '../../../componentes/edit/edit-dialog';
import { Schema } from '../../../utils/form';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../../utils/string';
import InputNumber from '../../../componentes/input-number/index';
import CondicionanteLookup from '../../../lookups/condicionante';

let schema = Schema.object({
  valor: Schema.string()
    .label(<FormattedMessage id="valor" />)
    .required(),
  condicionante: Schema.string()
    .label(<FormattedMessage id="condicionante" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, intl, resources } = this.props;
    return (
      <div>
        <Row>
          <Col md={5}>
            <div style={{ marginBottom: 15 }}>
              <CondicionanteLookup
                model={{
                  label: capitalizeFirstLetter(resources.condicionante),
                  value: model.getModel('condicionante').value,
                  requestChange: model.getModel('condicionante').requestChange,
                  errors: model.getModel('condicionante').errors,
                }}
                clearable={false}
                required
              />
            </div>
          </Col>
          <Col md={3}>
            <div style={{ marginBottom: 15 }}>
              <InputNumber
                required
                precision={4}
                model={{
                  label: capitalizeFirstLetter(intl.formatMessage({ id: 'valor' })),
                  value: model.getModel('valor').value,
                  requestChange: model.getModel('valor').requestChange,
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

class CondicionanteEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;

    return (
      <EditDialog
        url="/ColaboradorCondicionante"
        title={capitalizeFirstLetter(resources.condicionante)}
        formComponent={FormEdit}
        schema={schema}
        width="60%"
        height="40%"
        respeitarAltura
        maxHeight="90%"
        resources={resources}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(CondicionanteEdit));
