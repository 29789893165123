import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class StatusRiscoPontoControleRevisao extends React.Component {
  render() {
    return (
      <Lookup
        disabled={this.props.disabled === null || this.props.disabled === undefined ? false : this.props.disabled}
        labelKey="descricao"
        valueKey="id"
        url="/StatusPontoControleRevisaoLookup"
        {...this.props}
      />
    );
  }
}

export default StatusRiscoPontoControleRevisao;
