import { FormattedMessage } from 'react-intl';
import { Schema } from 'src/utils/form';

export default Schema.object({
  dataAvaliacao: Schema.string()
    .label(<FormattedMessage id="label.dataAvaliacao" />)
    .required(),
  descricao: Schema.string().label(<FormattedMessage id="label.comentario" />),
  descricaoInerente: Schema.string().label(
    <FormattedMessage id="label.comentario" />
  ),
  responsavel: Schema.string().label(
    <FormattedMessage id="label.responsavel" />
  ),
  nomeRisco: Schema.string().required(),
  tipoRisco: Schema.string(),
  classificacaoRisco: Schema.string().label(
    <FormattedMessage id="label.classificacao" />
  ),
  severidade: Schema.string()
    .label(<FormattedMessage id="label.severidade" />)
    .required(),
  tratamento: Schema.string()
    .label(<FormattedMessage id="tratamento" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.tratamentoObrigatorio;
      },
    }),
  probabilidade: Schema.string()
    .label(<FormattedMessage id="label.probabilidade" />)
    .required(),
  itensImpactados: Schema.array(),
  listaSeveridadeIdMatriz: Schema.array(),
  listaProbabilidadeIdMatriz: Schema.array(),
  tratamentoObrigatorio: Schema.array(),
  utilizarTratamento: Schema.string(),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  arquivosInerentes: Schema.array().label(
    <FormattedMessage id="label.anexos" />
  ),
  causasRisco: Schema.array(),
  consequenciasRisco: Schema.array(),
  severidadeInerente: Schema.string()
    .label(<FormattedMessage id="label.severidade" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.avaliarRiscoInerente;
      },
    }),
  probabilidadeInerente: Schema.string()
    .label(<FormattedMessage id="label.probabilidade" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.avaliarRiscoInerente;
      },
    }),
  efetividadeControle: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        if(!context.parent.value.avaliarEfetividadeControle)
          return false;
        
        if (
          context.parent.value.avaliarEfetividadeControle &&
          !context.parent.value.calcularEfetividadeConformeEfetividadeIndividual &&
          !context.parent.value.gerarAvaliacaoRiscoResidualPelaEfetividade &&
          !context.parent.value.avaliarEfetividadeControleSeparadamente
        )
          return false;

        return (
          !context.parent.value.gerarAvaliacaoRiscoResidualPelaEfetividade &&
          !context.parent.value.avaliarEfetividadeControleSeparadamente &&
          context.parent.value.existeControle
        );
      },
    }),
  efetividadeControleCorretivo: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.avaliarEfetividadeControle &&
          !context.parent.value.gerarAvaliacaoRiscoResidualPelaEfetividade &&
          context.parent.value.avaliarEfetividadeControleSeparadamente &&
          context.parent.value.existeControleCorretivo
        );
      },
    }),
  efetividadeControlePreventivo: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.avaliarEfetividadeControle &&
          !context.parent.value.gerarAvaliacaoRiscoResidualPelaEfetividade &&
          context.parent.value.avaliarEfetividadeControleSeparadamente &&
          context.parent.value.existeControlePreventivo
        );
      },
    }),
  impactoFinanceiroInerente: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.avaliarRiscoInerente &&
          context.parent.value.acompanharImpactoFinanceiroEventoRisco &&
          context.parent.value.impactoFinanceiroEventoRiscoObrigatorio
        );
      },
    }),
  impactoFinanceiroResidual: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.acompanharImpactoFinanceiroEventoRisco &&
          context.parent.value.impactoFinanceiroEventoRiscoObrigatorio
        );
      },
    }),
  comentarioTipoSeveridade: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.permitirComentariosPorTipoSeveridade &&
          context.parent.value.comentarioPorTipoSeveridadeObrigatorio
        );
      },
    }),
  comentarioTipoProbabilidade: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.permitirComentariosPorTipoProbabilidade &&
          context.parent.value.comentarioPorTipoProbabilidadeObrigatorio
        );
      },
    }),
  comentarioProbabilidade: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value
            .avaliacaoComentarioSeparadoEntreImpactoProbabilidade &&
          context.parent.value.avaliacaoComentarioObrigatorioProbabilidade
        );
      },
    }),
  comentarioProbabilidadeInerente: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value
            .avaliacaoComentarioSeparadoEntreImpactoProbabilidade &&
          context.parent.value.avaliacaoComentarioObrigatorioProbabilidade
        );
      },
    }),
  comentarioSeveridade: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value
            .avaliacaoComentarioSeparadoEntreImpactoProbabilidade &&
          context.parent.value.avaliacaoComentarioObrigatorioSeveridade
        );
      },
    }),
  comentarioSeveridadeInerente: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value
            .avaliacaoComentarioSeparadoEntreImpactoProbabilidade &&
          context.parent.value.avaliacaoComentarioObrigatorioSeveridade
        );
      },
    }),
});
