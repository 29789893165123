import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import FormulaEdit from '../paginas/formula/index';
import FormulaList from '../paginas/formula/formula-padrao/view';

@autobind
class FormulaPadraoLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueKey="id"
        labelKey="descricao"
        showSearch
        clearable={true}
        url="/FormulaLookup"
        editComponent={FormulaEdit}
        searchComponent={FormulaList}
        {...this.props}
      />
    );
  }
}

export default FormulaPadraoLookup;
