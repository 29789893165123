import http from 'src/services/httpService';
import { saveAs } from 'file-saver';
import errorHandler from '../utils/error-handler';

const getFileName = (response) => {
  var filename = '';
  var disposition = response.request.getResponseHeader('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename;
};

export const DownloadFile = (response) => {
  let nomeArquivoServer = getFileName(response);
  // Converte o arquivo
  const file = new Blob([response.data], { type: response.data.type });
  saveAs(file, nomeArquivoServer);
};

export const ParseError = (error) => {
  if (error && error.response) {
    if (error.response.status == 400) {
      error.response.data = null;
    } else {
      if (
        error.response &&
        error.response.data &&
        error.response.data.type != 'application/json'
      ) {
        var enc = new TextDecoder('utf-8');
        error.response.data = JSON.parse(enc.decode(error.response.data));
      }
    }
  }

  return error;
};

export const DownloadArquivoSistema = (guid) => {
  http
    .post(
      'ArquivoSistema/DownloadArquivo',
      {
        guid,
      },
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      DownloadFile(response);
    })
    .catch((error) => {
      errorHandler(ParseError(error));
    });
};

export const DownloadFileBucket = async (fileName) => {
  http
    .post(
      'File/DownloadFile',
      {
        fileName,
      },
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      DownloadFile(response);
    })
    .catch((error) => {
      errorHandler(ParseError(error));
    });
};

export const DownloadContentFileBucket = async (fileName) => {
  try {
    const response = await http.post(
      'File/DownloadFile',
      { fileName },
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    errorHandler(ParseError(error));
    throw error;
  }
};

export const DownloadArquivosSistemaZip = (items) => {
  http
    .post(
      'Anexo/DownloadArquivos',
      {
        list: items,
      },
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      saveAs(new Blob([response.data]), 'actio_files.zip');
    })
    .catch((error) => {
      errorHandler(ParseError(error));
    });
};
