import { Col, Row } from 'reactstrap';
import Input from 'src/componentes/input';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import Checkbox from 'src/componentes/checkbox';

import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import ControleLookup from 'src/lookups/ControleLookup';
import StatusTesteControleLookup from 'src/lookups/status-teste-controle';
import AvaliacaoTesteControleLookup from 'src/lookups/avaliacao-teste-controle';
import PeriodoData from 'src/paginas/data/periodo-data.jsx';

const TesteControleSearch = ({ model }) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const handleChangeFiltro = (field, value) => {
    model.getModel(field).requestChange(value);
  };

  return (
    <>
      <Row>
        <Col md={3}>
          <Input model={model.getModel('idIntegracao')} />
        </Col>  
        <Col md={3}>
          <Input model={model.getModel('nome')} />
        </Col> 
        <Col md={3}>
          <ItemOrganizacaoLookup
            multi
            clearable
            model={{
              label: capitalizeFirstLetter(resources.area),
              value: model.getModel('area').value,
              requestChange: model.getModel('area').requestChange,
            }}
            data={{ tiposIdSelecionaveis: [1, 2, 3] }}
          />
        </Col>
        <Col md={3}>
          <Checkbox
            model={{
              label: intl.formatMessage(
                { id: 'label.considerarAreasSubordinadas' },
                { areas: resources.areas }
              ),
              value: model.getModel('areasSubordinadas').value,
              requestChange: model.getModel('areasSubordinadas').requestChange,
            }}
            style={{ marginTop: 27 }}
          />
        </Col>
      </Row>   
      <Row>
        <Col md={3}>
          <ControleLookup
            multi
            clearable
            model={{
              label: capitalizeFirstLetter(resources.pontodecontrole),
              value: model.getModel('controle').value,
              requestChange: model.getModel('controle').requestChange,
            }}
          />
        </Col>
        <Col md={3}>
          <ColaboradorSimplificadoLookup
            multi
            clearable
            model={{
              label: capitalizeFirstLetter(resources.responsavel),
              value: model.getModel('responsaveisSimplificado').value,
              requestChange: model.getModel('responsaveisSimplificado').requestChange,
            }}
          />
        </Col>
        <Col md={3}>
          <StatusTesteControleLookup
            multi
            clearable
            model={{
              label: capitalizeFirstLetter(intl.formatMessage({ id: 'status' })),
              value: model.getModel('statusTesteControleList').value,
              requestChange: model.getModel('statusTesteControleList').requestChange,
            }}
          />
        </Col> 
        <Col md={3}>
          <AvaliacaoTesteControleLookup
            multi
            clearable
            model={{
              label: capitalizeFirstLetter(intl.formatMessage({ id: 'label.resultado' })),
              value: model.getModel('avaliacaoTesteControleList').value,
              requestChange: model.getModel('avaliacaoTesteControleList').requestChange,
            }}
          />
        </Col>       
      </Row>  
      <Row>
        <Col md={12}>
          <PeriodoData
            clearable
            data={{ idsIgnorados: [7,8,9,10] }}
            tipoFiltroData={model.getModel('tipoFiltroDataPeriodoExecucao').value}
            dataInicio={model.getModel('deDataInicialExecucao').value}
            dataTermino={model.getModel('ateDataFinalExecucao').value}
            tipoFiltroDataField={'tipoFiltroDataPeriodoExecucao'}
            dataInicioField={'deDataInicialExecucao'}
            dataTerminoField={'ateDataFinalExecucao'}
            onChange={handleChangeFiltro}
            customLabelTipoPeriodo=
              {capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'label.PeriodoDataExecucao' }
                )
              )}
            customLabelDataInicio=
              {capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'deDataInicial' }
                )
              )}
            customLabelDataTermino=
              {capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'ateDataFinal' }
                )
              )}
          />
        </Col>      
      </Row>    
      <Row>
        <Col md={12}>
          <PeriodoData
            clearable
            data={{ idsIgnorados: [7,8,9,10] }}
            tipoFiltroData={model.getModel('tipoFiltroDataPeriodoConclusao').value}
            dataInicio={model.getModel('deDataInicialConclusao').value}
            dataTermino={model.getModel('ateDataFinalConclusao').value}
            tipoFiltroDataField={'tipoFiltroDataPeriodoConclusao'}
            dataInicioField={'deDataInicialConclusao'}
            dataTerminoField={'ateDataFinalConclusao'}
            onChange={handleChangeFiltro}
            customLabelTipoPeriodo=
              {capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'label.PeriodoDataConclusao' }
                )
              )}
            customLabelDataInicio=
              {capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'deDataInicial' }
                )
              )}
            customLabelDataTermino=
              {capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'ateDataFinal' }
                )
              )}
          />
        </Col>      
      </Row>   
    </>
  );
};

export default TesteControleSearch;
