import React from 'react';
import { Row, Col } from 'reactstrap';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Edit from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import TipoAcessoLookup from 'src/lookups/tipo-acesso';
import { capitalizeFirstLetter } from 'src/utils/string';

let schema = Schema.object({
  tipoAcesso: Schema.string()
    .label(<FormattedMessage id="label.tipoAcesso" />)
    .required(),
  colaborador: Schema.string().label().required(),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, resources } = this.props;
    return (
      <Row>
        <Col md={8}>
          <ColaboradorSimplificadoLookup
            clearable={false}
            required
            model={{
              label: capitalizeFirstLetter(resources.responsavel),
              value: model.getModel('colaborador').value,
              requestChange: model.getModel('colaborador').requestChange,
              errors: model.getModel('colaborador').errors,
            }}
          />
        </Col>
        <Col md={4}>
          <TipoAcessoLookup required model={model.getModel('tipoAcesso')} />
        </Col>
      </Row>
    );
  }
}

class ItemPermissaoEdit extends React.Component {
  render() {
    return (
      <Edit
        url={this.props.parameters && this.props.parameters.url}
        title="label.permissoesDeAcesso"
        formComponent={FormEdit}
        schema={schema}
        width={700}
        minHeight={250}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ItemPermissaoEdit));
