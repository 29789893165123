import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import ColaboradorLookup from 'src/lookups/colaborador';
import OrganizacaoLookup from 'src/lookups/item-organizacao';
import CargoLookup from 'src/lookups/cargo';

@autobind
class HistoricoSearch extends React.Component {
  render() {
    const { model, resources, parameters } = this.props;
    const { cargoId, colaboradorId } = parameters;

    return (
      <Row>
        {cargoId && (
          <Col md={6}>
            <ColaboradorLookup
              model={{
                label: capitalizeFirstLetter(resources.colaborador),
                value: model.getModel('colaborador').value,
                requestChange: model.getModel('colaborador').requestChange,
              }}
            />
          </Col>
        )}
        {colaboradorId && (
          <Col md={6}>
            <CargoLookup
              model={{
                label: capitalizeFirstLetter(resources.cargo),
                value: model.getModel('cargo').value,
                requestChange: model.getModel('cargo').requestChange,
              }}
            />
          </Col>
        )}
        <Col md={6}>
          <OrganizacaoLookup
            autoFocus
            model={{
              label: capitalizeFirstLetter(resources.area),
              value: model.getModel('area').value,
              requestChange: model.getModel('area').requestChange,
            }}
          />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(HistoricoSearch));
