import { Schema } from '../../../../../utils/form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default Schema.object({
  descricao: Schema.string().label(<FormattedMessage id="label.comentario" />),
  dataRevisao: Schema.string()
    .label(<FormattedMessage id="label.dataRevisao" />)
    .required(),
  responsavelRevisao: Schema.array()
    .label(<FormattedMessage id="label.executor" />)
    .required(),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  conformidade: Schema.array().label(<FormattedMessage id="conformidade" />),
  opcoesConformidade: Schema.array().label().required(),
  efetividade: Schema.array().label(<FormattedMessage id="label.efetividade" />),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  anexoObrigatorio: Schema.array().label(),
  utilizarTipoEvidencia: Schema.string(),
  tipoEvidencias: Schema.string().label(<FormattedMessage id="tipoEvidencias" />),
  utilizarAuditoria: Schema.string().label(),
  auditado: Schema.string().label(<FormattedMessage id="auditado" />),
});
