import Lookup from 'src/componentes/select-async';
import ControlSelfAssessmentList from 'src/paginas/gestao-risco/CSA/ControlSelfAssessment/ControlSelfAssessmentList';

const ControlSelfAssessmentLookup = ({...rest}) => {

  return (
    <Lookup
      labelKey="nome"
      valueKey="id"
      showSearch={true}
      showNew={false}
      url="/ControlSelfAssessmentLookup"
      clearable
      searchComponent={ControlSelfAssessmentList}
      {...rest}
    />
  );
};

export default ControlSelfAssessmentLookup;