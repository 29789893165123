import React from 'react';
import { autobind } from 'core-decorators';

@autobind
class ProgressBarComponent extends React.Component {
  render() {
    let { color, label, value, border } = this.props;

    return (
      //<ProgressBar style={{borderRadius: 6, backgroundColor: '#000000'}} now={60} key={1} {...other} />

      <div className="progress-bar" title={value} role="progressbar" style={{ width: value.replace(',', '.'), backgroundColor: color }}>
        {label}
      </div>
    );
  }
}

export default ProgressBarComponent;
