import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertTriangle } from 'react-feather';

import Dialog from 'src/componentes/dialog';
import useAuth from 'src/hooks/useAuth';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/errorHandler';
import LogoModulo from 'src/layout/DashboardLayout/LogoModulo';
import ProgressBar from 'src/componentes/NewProgressBar';
import LoadingContainer from 'src/componentes/loading-container';
import { MODULOS } from 'src/utils/constants';

const LicencasModulosColaborador = ({ ...rest }) => {
  const { modules } = useAuth();

  const [isLoading, setLoading] = useState(false);
  const [estatisticaModulos, setEstatisticaModulos] = useState([]);
  const intl = useIntl();
  const ref = useRef();

  const handleClose = () => {
    ref.current?.close();
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await http.get(
        'Colaborador/ObterEstatiscaLicencaModulos'
      );

      setEstatisticaModulos(response.data);
    } catch (err) {
      errorHandler(err);
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const obterCorPeloPercentual = (percent) => {
    if (percent < 60) return '#44834C';
    else if (percent < 80) return '#FFAE0D';
    else return '#AE3939';
  };

  const renderModulo = (modulo) => {
    const estatisticaModulo = estatisticaModulos?.find(
      (e) => e.id === modulo.id
    );

    const percentual =
      estatisticaModulo?.numeroColaboradores > 0
        ? (estatisticaModulo?.numeroColaboradores / modulo.usuarios) * 100
        : 0;

    return (
      <div className="d-flex justify-content-center col-md-6 border-right mb-5">
        <div className="d-flex flex-column">
          <div className="ml-auto mr-auto mb-2">
            <LogoModulo module={modulo} size={60} />
          </div>
          {modulo.usuariosIlimitados ? (
            <div className="rounded-xl bg-soft-gray pl-3 pr-3 mt-3 font-weight-bold">
              {`${
                estatisticaModulo?.numeroColaboradores
              } / ${intl.formatMessage({ id: 'ilimitado' })}`}
            </div>
          ) : (
            <>
              <div className="ml-auto mr-auto mb-1">
                <span className="font-weight-bold">
                  {estatisticaModulo?.numeroColaboradores}/{modulo.usuarios}
                </span>
              </div>
              <div style={{ width: '370px' }}>
                <ProgressBar
                  roundeSize={10}
                  itens={[
                    {
                      percentual: percentual,
                      cor: obterCorPeloPercentual(percentual),
                    },
                  ]}
                  containerHeight={15}
                  hidePercent
                  hidePercentValue={20}
                  showBorder={false}
                  bgColor="#EEE"
                />
              </div>
              {percentual > 100 && (
                <div className="text-gray mt-2">
                  <AlertTriangle size={18} />
                  <span className="ml-2 mt-2">
                    {intl.formatMessage({
                      id: 'voceUltrapassouNumeroMaxLicenca',
                    })}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderModuloSophie = (modulo) => {
    const estatisticaModulo = estatisticaModulos?.find(
      (e) => e.id === modulo.id
    );

    return (
      <div className="d-flex justify-content-center col-md-6 border-right mb-5">
        <div className="d-flex flex-column align-items-center">
          <div className="ml-auto mr-auto mb-2">
            <LogoModulo module={modulo} size={95} />
          </div>

          <div
            className="rounded-xl bg-soft-gray pl-3 pr-3 my-3 font-weight-bold"
            style={{ width: 'fit-content' }}
          >
            {`${
              estatisticaModulo?.numeroColaboradores ?? 0
            } / ${intl.formatMessage({
              id: 'ilimitado',
            })}`}
          </div>
        </div>
      </div>
    );
  };

  const modulesAtivos = modules.filter((e) => e.exibir);
  return (
    <Dialog
      title={intl.formatMessage({ id: 'acompanharLicencas' })}
      width="1100px"
      minHeight={400}
      padContent
      ref={ref}
      onClose={handleClose}
      {...rest}
    >
      <LoadingContainer isLoading={isLoading}>
        <div className="row">
          {modulesAtivos.map((modulo, index) =>
            modulo.id !== MODULOS.INTELIGENCIA_ARTIFICIAL
              ? renderModulo(modulo)
              : renderModuloSophie(modulo)
          )}
        </div>
      </LoadingContainer>
    </Dialog>
  );
};

export default LicencasModulosColaborador;
