import { useState, useRef, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { isSameDay } from 'date-fns';

import useAuth from 'src/hooks/useAuth';
import guid from 'src/utils/guid';
import LoadingContainer from 'src/componentes/loading-container';
import MdiIcon from 'src/componentes/mdi-icon';
import ContentManager from 'src/componentes/content-manager';
import AcaoEdit from 'src/paginas/acao/edit';
import Kanban from 'src/paginas/acao/kanban';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';
import {
  allColumnsCard,
  fetchStatusDisponiveis,
  fetchGanttSettings,
  fetchCard,
  fetchStatus,
} from 'src/paginas/acao/kanban/components/functions';
import { round } from 'src/utils/number';
import { GANTT_VIEW } from 'src/utils/constants';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import CollaboratorsSelectList from 'src/paginas/colaborador/view';
import { capitalizeFirstLetter } from 'src/utils/string';
import { getFilter } from 'src/componentes/NewList';

import { ganttConfigurations } from '../configurations';
import Toolbar from './Toolbar';
import {
  handleHttp,
  getColumns,
  getMappedColumns,
  getMappedTasks,
  getProjectModel,
  taskMapper,
  taskMapperForUpdates,
  getDefaultTask,
} from '../../utils';

import Gantt from './Gantt';

let dataIsReady = false;

const GanttContainer = ({
  updateTree = () => {},
  showToolbar = true,
  showBorder = true,
  componentView,
  setComponentView,
  isConsultaExterna,
  isReadOnly = false,
  handleCloseModal,
  forceRefreshAfterChange = false,
  ignorarFiltroSalvo = false,
  ...rest
}) => {
  const intl = useIntl();
  const {
    terms: resources,
    user,
    decimalSeparator,
    thousandsSeparator,
  } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const ganttRef = useRef();

  const [ganttContainerId] = useState(`GANTT-CONTAINER-${guid()}`);
  const [kanbanKey, setKanbanKey] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  const [kanbanSettings, setkanbanSettings] = useState({});

  const [configurations, setConfigurations] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [project, setProject] = useState(null);
  const [disableTaskMenu, setDisableTaskMenu] = useState(false);
  const [columns, setColumns] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [filtrando, setFiltrando] = useState(false);
  const [filterKanban, setFilterKanban] = useState({});
  const [isFilterLoaded, setIsFilterLoaded] = useState(false);

  const params = {
    itemId: (rest || {}).itemId,
    centroCustoId: (rest || {}).centroCustoId,
    pacoteId: (rest || {}).pacoteId,
    ...rest?.parameters,
  };

  const callUpdateTree = () => {
    dataIsReady = false;
    updateTree();
  };

  const handleCloseEditTask = (record, saved, editModel, deleted) => {
    if(forceRefreshAfterChange){
      getData();
      return;
    }

    if (deleted) {
      ganttRef.current.ganttInstance.taskStore.remove(record);
      return;
    }

    if (!saved) return;

    const taskMapped = taskMapperForUpdates(editModel);

    delete taskMapped.customFields;

    record.set(taskMapped);

    // Mudança de pai após atualizar task

    const oldParentId = record.parentId;
    let newParentId = editModel?.pai?.id;

    // Se for plano externo, sempre que não tiver pai novo é pra ficar na raiz daquele plano
    if (record.planoAcaoExterno && !newParentId)
      newParentId = record.planoAcaoId;

    if (oldParentId == newParentId) return;

    if (oldParentId != null) {
      const oldParent =
        ganttRef.current.ganttInstance.taskStore.getById(oldParentId);
      if (oldParent) oldParent.removeChild(record);
    }

    if (newParentId) {
      const newParent =
        ganttRef.current.ganttInstance.taskStore.getById(newParentId);
      if (newParent) newParent.appendChild(record);
    } else {
      ganttRef.current.ganttInstance.taskStore.rootNode.appendChild(record);
    }
  };

  const getEveryColumn = () => {
    const handleAddNewTask = async (record) => {
      const defaultTask = getDefaultTask(
        intl,
        resources,
        { ...user },
        record.originalData?.planoAcaoId
      );
      const taskMapped = taskMapper(defaultTask);

      const added = record.appendChild(taskMapped);
      await ganttRef.current.ganttInstance?.scrollRowIntoView(added);

      ganttRef.current.ganttInstance?.features.cellEdit.startEditing({
        record: added,
        field: 'name',
      });
    };

    const allRecords =
      ganttRef.current.ganttInstance.taskStore.allRecords || [];

    const newList = [
      {
        type: 'editTask',
        width: 20,
        renderer: ({ record }) => {
          const isAction = record.id > 0;
          if (!isAction || isConsultaExterna || isReadOnly) return '';

          return (
            <div
              className="d-flex align-items-center justify-content-center cursor-pointer"
              onClick={() => {
                const item = { id: record.id };

                ContentManager.addContent(
                  <AcaoEdit
                    item={item}
                    showDelete={permissions?.allowDelete}
                    showExport
                    handleClose={(saved, _, editModel, __) =>
                      handleCloseEditTask(record, saved, editModel)
                    }
                    onDeleting={() => validateOnRemove(item, record)}
                    handleRefresh={() => atualizarState(filterKanban)}
                    refreshOnDelete={false}
                    parameters={{
                      ignorarCalculosPredecessao: true,
                      ...params,
                    }}
                    isGanttSearch
                  />
                );
              }}
            >
              <div size={20} style={{ color: '#83b5fe' }}>
                <MdiIcon
                  icon="pencil"
                  style={{ marginLeft: 2 }}
                  title={intl.formatMessage(
                    { id: 'label.editar' },
                    { editar: resources.editar }
                  )}
                />
              </div>
            </div>
          );
        },
      },
      ...getMappedColumns(
        columns,
        intl,
        user,
        {
          decimalSeparator,
          thousandsSeparator,
        },
        allRecords
      ),
    ];

    if (configurations?.utilizarCodigoAutomatico) {
      newList.unshift({
        type: 'addSubtask',
        width: 20,
        renderer: ({ record }) => {
          const isAction = record.id > 0;
          const allowCreate =
            record.originalData?.allowCreate == null
              ? permissions?.allowCreate
              : record.originalData?.allowCreate;

          if (!isAction || isConsultaExterna || !allowCreate || isReadOnly)
            return '';

          return (
            <div
              className="d-flex align-items-center justify-content-center cursor-pointer"
              onClick={() => handleAddNewTask(record)}
            >
              <div size={20} className="text-primary">
                <MdiIcon
                  icon="plus-circle-outline"
                  style={{ marginLeft: 2 }}
                  title={intl.formatMessage(
                    { id: 'criarEtapaParaAcaoListada' },
                    { acao: resources.acao }
                  )}
                />
              </div>
            </div>
          );
        },
      });
    }

    return newList;
  };

  const handleUpdateColumns = () => {
    const ganttInstance = ganttRef.current.ganttInstance;

    const oldRecords = ganttInstance.columns?.records?.filter((x) => x?.locked);
    ganttInstance.columns.remove(oldRecords);

    const columnsToShow = getEveryColumn();
    ganttInstance.columns.add(columnsToShow);

    setIsLoaded(true);
  };

  const handleUpdateTasksDB = async (list) => {
    if (!list || list?.length === 0) return;

    setIsLoading(true);
    try {
      await http.post('/Acao/SincronizarAcoesGantt', {
        model: {
          tasks: {
            updated: list,
          },
          atualizacaoAutomaticaGantt: true,
        },
      });

      if (!viewIsGantt) setKanbanKey((prev) => prev + 1);

      enqueueSnackbar(
        intl.formatMessage(
          { id: 'sucessoSincronizacaoAcoes' },
          { acoes: resources.acoes }
        ),
        { variant: 'success' }
      );
    } catch (err) {
      errorHandler({
        response: {
          data: {
            errorMessage: err.response.data,
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateExpantion = async (taskId, status) => {
    const { itemId } = rest || {};
    await handleHttp('/Acao/SalvarConfiguracoesAcaoPorColaborador', {
      itemId,
      acaoId: taskId,
      open: status,
    });
  };

  const handleSaveColumnSizes = async () => {
    const usedColumns =
      ganttRef?.current?.ganttInstance?.columns.records.filter(
        (x) => x?.locked && x.type != 'addSubtask' && x.type != 'editTask'
      );

    const columnsMapped = usedColumns?.map((c) => {
      return {
        headerText: c.text,
        valueField: c.originalData.uniqueKey,
        width: c.width,
      };
    });

    await handleHttp('/ColunasTabela/SalvarTamanhoColunas', {
      colunasAlteradas: columnsMapped || [],
      tag: 2008,
    });
  };

  const fillKanbanSettings = async (newConfiguration, allColumnsKanban) => {
    const allColumnsCardLocal = allColumnsCard(
      intl,
      resources,
      newConfiguration
    );
    const columnsCard = await fetchCard(allColumnsCardLocal);
    //const allColumnsKanban = await fetchGanttSettings(null, newConfiguration?.habilitarStatusValidacao, newConfiguration?.habilitarStatusArquivada);
    const columnsKanban = await fetchStatus(allColumnsKanban);

    setkanbanSettings({
      allColumnsCard: allColumnsCardLocal,
      columnsCard,
      allColumnsKanban,
      columnsKanban,
    });
  };

  const autoSyncFlow = async () => {
    const awaitColumns = async () =>
      await handleHttp('/ColunasTabela/ObterConfiguracoesColunasTabela', {
        tag: 2008,
      });
    const getActions = async () =>
      await handleHttp('/Acao/ObterAcoesGantt', params);
    const getActionsChildren = async () =>
      await handleHttp('/Acao/ObterAcoesItensFilhosGantt', params);
    const getLinks = async () =>
      await handleHttp('/Acao/ObterLinksAcoesGantt', params);
    const getCustomFields = async () =>
      await handleHttp('/Acao/ObterListaAcaoCamposCustomizados', {
        tipoItemId: 100,
      });

    const gantSettings = await fetchGanttSettings();
    //const { model: newConfiguration } = await getConfigurations();
    setConfigurations(gantSettings?.configuracao);

    if (!componentView)
      setComponentView(
        gantSettings?.configuracao?.formatoKanbanDefault
          ? GANTT_VIEW.KANBAN
          : GANTT_VIEW.GANTT
      );

    await fillKanbanSettings(
      gantSettings.configuracao,
      gantSettings.colunaStatus
    );

    const [newColumns, newCustomFields] = await Promise.all([
      awaitColumns(),
      getCustomFields(),
    ]);

    setColumns(
      getColumns(
        intl,
        resources,
        newColumns,
        gantSettings.configuracao,
        newCustomFields
      )
    );

    setCustomFields(newCustomFields);

    const {
      dataInicio,
      calendar,
      list: tasks,
      allowDelete,
      allowCreate,
      allowEdit,
    } = await getActions();
    const tasksChildren = params?.itemId && (await getActionsChildren());

    setPermissions({ allowDelete, allowCreate, allowEdit });

    let links = [];
    let items = [];

    const internalLinks = await getLinks();
    if (internalLinks?.length > 0) links = [...internalLinks];

    if (tasks?.length > 0) items = [...tasks];

    if (tasksChildren?.length > 0) items = [...items, ...tasksChildren];

    if (items?.length > 0) items = getMappedTasks(items);

    setDisableTaskMenu(items?.filter((x) => x.id <= 0)?.length > 0);

    return {
      dataInicio,
      items,
      links,
      calendar,
    };
  };

  const syncProject = async () => {
    try {
      await ganttRef.current?.ganttInstance?.project?.propagate();
    } catch (_) {}
  };

  const getData = async () => {
    setIsLoading(true);

    const { dataInicio, items, links, calendar } = await autoSyncFlow();

    const newModel = await getProjectModel(dataInicio, items, links, calendar);

    newModel.onChange = ({ store, record, changes }) => {
      if (
        !store?.isTaskStore ||
        !record ||
        record.isRoot ||
        !changes ||
        changes.length === 0
      )
        return;

      const newChanges = {};

      // RULES - Sempre que alterar algo valida se está diferente do valor antigo já existente.

      // Regra pra garantir o posicionamento correto das datas das ações (utilizando constraints)

      const isParent = record.isParent;
      const hasPredecessors = record.predecessors?.length > 0;

      const newConstraintValue =
        hasPredecessors || isParent ? null : 'startnoearlierthan';

      if (newConstraintValue != record.constraintType) {
        if (newConstraintValue == 'startnoearlierthan')
          newChanges.utilizarDatasJaSalvas = true;
        else newChanges.constraintDate = null;

        newChanges.constraintType = newConstraintValue;
      }

      // Regra pra garantir que independente do horário que o usuário definir para as ações, elas irão respeitar
      // o horário que o software trabalha (09hrs ~ 17hrs).

      if (record.startDate && record.endDate) {
        const startDateHours = record.startDate.getHours();
        const endDateHours = record.endDate.getHours();

        let newStartDate = record.startDate;

        if (record.isMilestone) {
          if (startDateHours != 17) {
            const copiedStartDate = new Date(record.startDate.getTime());
            copiedStartDate.setHours(17, 0, 0, 0);

            newStartDate = copiedStartDate;
            newChanges.startDate = newStartDate;
          }

          if (endDateHours != 17) {
            const copiedEndDate = new Date(record.endDate.getTime());
            copiedEndDate.setHours(17, 0, 0, 0);

            newChanges.endDate = copiedEndDate;
          }
        } else if (!isParent) {
          if (startDateHours != 9) {
            const copiedStartDate = new Date(record.startDate.getTime());
            copiedStartDate.setHours(9, 0, 0, 0);

            newStartDate = copiedStartDate;
            newChanges.startDate = newStartDate;
          }

          if (endDateHours != 17) {
            const copiedEndDate = new Date(record.endDate.getTime());
            copiedEndDate.setHours(17, 0, 0, 0);

            newChanges.endDate = copiedEndDate;
          }
        }
        // Colocar a constraint date sempre igual a data de início para não haver movimentações indesejadas.

        if (
          newStartDate &&
          newConstraintValue &&
          (!record.constraintDate ||
            !isSameDay(record.constraintDate, newStartDate))
        )
          newChanges.constraintDate = newStartDate;
      }

      // Regra pra garantir que a duração sempre será inteira

      if (record.duration != null && !Number.isInteger(record.duration))
        newChanges.duration = round(record.duration, 0);

      // END

      if (Object.entries(newChanges)?.length > 0) {
        record.set(newChanges, true);
      }
    };

    newModel.onDataReady = ({ source }) => {
      if (dataIsReady) {
        syncProject();
        return;
      }

      // Alterações ao carregar componente.

      ganttRef?.current?.ganttInstance?.zoomToFit({
        leftMargin: 50,
        rightMargin: 50,
      });

      try {
        const allChangedItems = source.allChildren
          ?.filter((x) => {
            const isAction = x.id > 0;

            if (!isAction) return;

            const {
              duration: duracao,
              startDate: dataInicioPrevista,
              endDate: dataTerminoPrevista,
            } = x.originalData || {};
            const { startDate, endDate, duration } = x._data || {};

            const prevStart =
              dataInicioPrevista && new Date(dataInicioPrevista);
            const afterStart = startDate && new Date(startDate);

            const prevFinish =
              dataTerminoPrevista && new Date(dataTerminoPrevista);
            const afterFinish = endDate && new Date(endDate);

            if (!prevStart && !afterStart && !prevFinish && !afterFinish)
              return false;

            return (
              !isSameDay(prevStart, afterStart) ||
              !isSameDay(prevFinish, afterFinish) ||
              duration != duracao
            );
          })
          ?.map((i) => {
            return {
              ...i._data,
            };
          });

        handleUpdateTasksDB(allChangedItems);
      } catch (err) {
        enqueueSnackbar(
          intl.formatMessage(
            { id: 'erroSincronizacaoAcoes' },
            { acoes: resources.acoes }
          ),
          { variant: 'error' }
        );
      }

      dataIsReady = true;
    };

    newModel.onBeforeSync = (request) => {
      if (request.pack?.tasks?.updated) {
        request.pack.tasks.updated = request.pack.tasks.updated?.map((x) => {
          const xCopy = { ...x };
          const parentIdExists = Object.keys(x)?.find((k) => k === 'parentId');
          const responsavelIdExists = Object.keys(x)?.find(
            (k) => k === 'responsavelId'
          );

          if (parentIdExists && xCopy['parentId'] == null) {
            xCopy.parentId = 0;
          }

          if (responsavelIdExists && xCopy['responsavelId'] == null) {
            xCopy.responsavelId = 0;
          }

          return xCopy;
        });
      }

      request.pack.myData = {
        ...params,
      };
    };

    newModel.onRequestDone = ({ response }) => {
      const { tasks } = response || {};
      const { rows } = tasks || {};
      rows?.map((x) => {
        let record = ganttRef.current.ganttInstance.taskStore.getById(
          x['$PhantomId']
        );

        if (!record)
          record = ganttRef.current.ganttInstance.taskStore.getById(x.id);

        if (!record) return;

        const taskMapped = taskMapperForUpdates(x);

        taskMapped.utilizarDatasJaSalvas = false;

        taskMapped.constraintDate = record.constraintType
          ? taskMapped.startDate
          : null;

        delete taskMapped.startDate;
        delete taskMapped.endDate;

        record.set(taskMapped, undefined, true);
        record.clearChanges();
      });
    };

    newModel.onRequestFail = ({ responseText }) => {
      enqueueSnackbar(responseText, { variant: 'error' });
      callUpdateTree();
    };

    setProject(newModel);
    setIsLoading(false);
  };

  const handleUpdateKanbanSettings = (field, value) => {
    setkanbanSettings((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if(isFilterLoaded) getData();

    return () => {
      ganttRef?.current?.ganttInstance?.destroy();
    };
  }, [isFilterLoaded]);

  useEffect(() => {
    if (!ganttRef?.current || !permissions || !configurations) return;

    handleUpdateColumns();
  }, [ganttRef?.current, permissions, configurations, columns]);

  const abrirUltimoFiltro = async () => {
    const filtroSalvo = await getFilter(73, true);

    if (!ignorarFiltroSalvo && filtroSalvo) {
      setFilterKanban(filtroSalvo.valor);
    }

    setIsFilterLoaded(true);
  };

  useEffect(() => {
    abrirUltimoFiltro();
  }, []);

  const atualizarState = (filter) => {
    setFiltrando(true);
    setFilterKanban(filter);
    setKanbanKey((prev) => prev + 1);
  };

  const renderAlertaCodigoAutomatico = () => (
    <div
      className="d-flex align-items-center bg-primary-light rounded-lg p-3"
      style={{ borderBottom: '1px solid #d8d9da' }}
    >
      <MdiIcon icon="alert-circle-outline" className="mr-2 text-primary" />
      <div>
        {intl.formatMessage(
          {
            id: 'alertaCodigoAutomaticoGantt',
          },
          { acoes: resources.acoes }
        )}
      </div>
    </div>
  );

  const handleUpdateView = (newView) => {
    setComponentView(newView);

    if (newView === GANTT_VIEW.GANTT) callUpdateTree();
    else {
      ganttRef?.current?.ganttInstance?.destroy();
      setKanbanKey((prev) => prev + 1);
    }
  };

  const validateOnRemove = (item, record = null) => {
    const handleDeleteConfirm = (saved) => {
      if (!saved) return;

      if (record) handleCloseEditTask(record, true, null, true);
      else setKanbanKey((prev) => prev + 1);
    };

    return ContentManager.addContent(
      <ValidacaoRestricao
        url="/Acao/ValidarRestricoes"
        urlExclusao="/Acao/AtualizarStatusExcluido"
        item={item}
        onClose={handleDeleteConfirm}
      />
    );
  };

  const handleClickTaskKanban = (item) => {
    ContentManager.addContent(
      <AcaoEdit
        showDelete={permissions?.allowDelete}
        showExport
        onDeleting={() => validateOnRemove(item)}
        handleClose={() => setKanbanKey((prev) => prev + 1)}
        item={item}
        parameters={{ ...params }}
        isGanttSearch
      />
    );
  };

  const { startDate } = project || {};

  const handleOpenColaboratorsList = (actualValue, recordId) => {
    const handleSelectUser = (newListValue) => {
      const filteredList = newListValue?.filter(
        (x) => x != null && x.id != null
      );
      const record = ganttRef.current.ganttInstance.taskStore.getById(recordId);
      if (record) {
        const newValue = filteredList ? filteredList[0] : null;
        const changes = {
          responsavelId: newValue ? newValue.id : null,
          responsavel: newValue ?? null,
        };

        record.set(changes);
      }
    };

    ContentManager.addContent(
      <CollaboratorsSelectList
        showModal
        parameters={{ somenteAtivos: true }}
        select
        showExport={false}
        titulo={capitalizeFirstLetter(resources.colaboradores)}
        selectedCallback={handleSelectUser}
        selectedIds={actualValue}
        selectedItems={actualValue}
      />
    );
  };

  const localGanttConfigurations = useMemo(() => {
    // **ATENÇÃO**
    // As configurações do Gantt precisam ter o mesmo endereço de memória,.
    // No futuro temos que trocar o objeto de "Features" e "Listeners" para o novo padrão.

    return {
      ...ganttConfigurations(
        intl,
        startDate,
        handleUpdateExpantion,
        handleSaveColumnSizes,
        configurations,
        permissions,
        disableTaskMenu,
        isConsultaExterna,
        handleOpenColaboratorsList
      ),
    };
  }, [startDate, configurations, permissions]);

  const ganttComponent = useMemo(() => {
    return (
      <Gantt
        ganttContainerId={ganttContainerId}
        gantt={ganttRef}
        project={project}
        componentConfigurations={localGanttConfigurations}
        isReadOnly={isReadOnly}
      />
    );
  }, [ganttRef, project, localGanttConfigurations, isReadOnly]);

  const viewIsGantt = componentView === GANTT_VIEW.GANTT;

  return (
    <div
      style={{
        border: showBorder ? '1px solid #d8d9da' : '',
      }}
    >
      <LoadingContainer isLoading={isLoading}>
        {viewIsGantt &&
          configurations &&
          !configurations.utilizarCodigoAutomatico &&
          renderAlertaCodigoAutomatico()}
        {showToolbar && (
          <Toolbar
            handleCloseModal={handleCloseModal}
            gantt={ganttRef?.current}
            permissions={permissions}
            configurations={configurations}
            columns={columns}
            setColumns={setColumns}
            setIsLoading={setIsLoading}
            componentView={componentView}
            handleUpdateView={handleUpdateView}
            kanbanSettings={kanbanSettings}
            handleUpdateKanbanSettings={handleUpdateKanbanSettings}
            updateTree={callUpdateTree}
            isConsultaExterna={isConsultaExterna}
            startDate={startDate}
            ganttContainerId={ganttContainerId}
            customFields={customFields}
            atualizarState={atualizarState}
            filterKanban={filterKanban}
            abrirUltimoFiltro={abrirUltimoFiltro}
            {...rest}
          />
        )}
        {project &&
          (viewIsGantt ? (
            <div style={{ display: isLoaded ? 'block' : 'none' }}>
              {ganttComponent}
            </div>
          ) : (
            <Kanban
              key={kanbanKey}
              columnsCard={kanbanSettings.columnsCard}
              columnsKanban={kanbanSettings.columnsKanban}
              handleDescricaoClick={handleClickTaskKanban}
              parameters={{ ...params }}
              filtrando={filtrando}
              model={filterKanban}
            />
          ))}
      </LoadingContainer>
    </div>
  );
};

export default GanttContainer;
