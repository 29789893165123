import { useState } from 'react';
import { useIntl } from 'react-intl';
import Lookup from 'src/componentes/select2';

const GanttViewLookup = ({ ...rest }) => {
  const intl = useIntl();

  const [options] = useState([
    {
      id: 1,
      descricao: intl.formatMessage({ id: 'semanaEDiaSemana' }),
      key: 'weekAndDayLetter',
    },
    {
      id: 2,
      descricao: intl.formatMessage({ id: 'semanaEDia' }),
      key: 'weekAndDay',
    },
    {
      id: 3,
      descricao: intl.formatMessage({ id: 'semanaEMes' }),
      key: 'weekAndMonth',
    },
  ]);

  return (
    <Lookup labelKey="descricao" valueKey="id" options={options} {...rest} />
  );
};

export default GanttViewLookup;
