import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  controles: Schema.string().label(''),
  risco: Schema.string().label(),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  responsavel: Schema.string().label(),
  tipoPontoControle: Schema.string().label(),
  diasToleranciaRevisao: Schema.string().label(<FormattedMessage id="tolerancia" />),
  tipoTolerancia: Schema.string().label(<FormattedMessage id="tipoTolerancia" />),
  validador: Schema.string().label(<FormattedMessage id="validador" />),
  tipoFiltroData: Schema.string().label(<FormattedMessage id="label.tipoFiltroData" />),
  dataInicio: Schema.string().label(<FormattedMessage id="dataInicio" />),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  farois: Schema.string().label(<FormattedMessage id="label.farois" />),
  conformidade: Schema.string().label(<FormattedMessage id="conformidade" />),
  exibirAuditados: Schema.string().label(<FormattedMessage id="exibirAuditados" />),
  exibirNaoAuditados: Schema.string().label(<FormattedMessage id="exibirNaoAuditados" />),
  tipoEvidencias: Schema.string().label(<FormattedMessage id="tipoEvidencias" />),
  filtroAnexo: Schema.string().label(<FormattedMessage id="anexo" />),
  areas: Schema.string().label(<FormattedMessage id="areasDoRisco" />),
  areasControle: Schema.string().label(<FormattedMessage id="areasDoControle" />),
  camposCustomizados: Schema.array(),
});
