import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class TipoLimite extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/TipoLimiteLookup" {...this.props} />;
  }
}

export default TipoLimite;
