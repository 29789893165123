import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select-async';
import OrganizacaoList from '../paginas/organizacao';
import OrganizacaoEdit from '../paginas/organizacao/edit';

@autobind
class OrganizacaoLookup extends React.Component {
  render() {
    const { ...rest } = this.props;
    let exibirInativos = this.props.exibirInativos;

    return (
      <Lookup
        url="/OrganizacaoLookup"
        valueKey="id"
        labelKey="nome"
        showSearch
        clearable={true}
        hierarchy={true}
        editComponent={OrganizacaoEdit}
        searchComponent={OrganizacaoList}
        {...rest}
        data={{ 
          ...this.props.data, 
          viewListSearch: !exibirInativos,
          exibirInativos: exibirInativos,
        }}
      />
    );
  }
}

export default OrganizacaoLookup;
