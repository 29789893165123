import React from 'react';
import { autobind } from 'core-decorators';
import Content from '../../componentes/pages/content';
import View from './view';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

@autobind
class Desdobramento extends React.Component {
  render() {
    let { ...other } = this.props;
    return (
      <Content titulo={<FormattedMessage id="desdobramentos" />}>
        <View {...other} />
      </Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Desdobramento));
