import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Salario from './edit';

@autobind
class SalarioList extends React.Component {
  render() {
    let { model, intl, disabled, resources } = this.props;
    let podeEditar = !model.getModel('podeEditar').value;
    return (
      <div>
        <List
          url="/Salario"
          showSearch={false}
          titulo={'salarios'}
          editComponent={Salario}
          sort="dataInicio"
          parameters={{ ColaboradorId: model.getModel('id').value }}
          showExport={false}
          showNew={podeEditar}
          showDelete={podeEditar}
          disabled={disabled}
          podeEditar={podeEditar}
          {...this.props}
        >
          <ListColumn headerText={intl.formatMessage({ id: 'dataInicio' })} valueField="dataInicio" />
          <ListColumn headerText={intl.formatMessage({ id: 'dataTermino' })} valueField="dataTermino" />
          <ListColumn headerText={intl.formatMessage({ id: 'label.salario' }, { salario: resources.salario })} valueField="valorFormatado" />
        </List>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(SalarioList));
