import React from 'react';
import http from 'src/services/httpService';
import httpSelector from 'src/services/httpSelector';
import { autobind } from 'core-decorators';
import ContentManager from '../content-manager';
import css from '../../utils/css';
import history from '../../history';
import Button from '../button';
import MdiIcon from '../mdi-icon';
import Dialog from '../dialog';
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Row,
  Col,
  UncontrolledDropdown,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import Filtro from '../filtro';
import Table from '../table';
import { Checkbox } from '../Form';
import LoadingContainer from '../loading-container';
import Confirm from '../message-box/confirm';
import Alert from '../message-box/alert';
import errorHandler from '../../utils/error-handler';
import ColunasTabelas from './componentes/colunas-tabela';
import Email from '../email';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import GeoreferenciaExibicao from './componentes/georeferencia-exibicao';
import InputSearch from '../input-search';
import { removeAllSpaces } from '../../utils/string';
import Pagination from './pagination';
import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import FiltroChip from '../filtro-chip';
import guid from 'src/utils/guid';
import ConfirmNew from 'src/componentes/NewConfirm';

let classes = css`
  .checkCell {
      width: 40px;
      cursor: pointer;
    }

  .hierarchy {
    width: 15px;
    cursor: pointer;
    margin: -3px 10px;
  }

  .dropdown {
    & > .btn {
      border: 0px;
      color: inherit;
      &:hover {
        background-color: #e4eaec;
        color: inherit;
      }
    }
  }
  .sort {
    position: relative;
    cursor: pointer;
    padding-right: 15px!important;
    &:after{
      font-family: FontAwesome;
      right: 5px;
      font-size: 10px!important;
      position: absolute;
      display: inline-block;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      margin-top: 3px;
    }
    &:empty{
      &:after{
        display: none;
      }
    }
  }

  .tHeadContent {
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -o-max-content;
    width: -ms-max-content;

    white-space: nowrap;
  }

  }
  .menulistcustom {
    li a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.52857;
      color: #5f5f5f;
      white-space: nowrap;
      position: relative;
      .nameFilter{
        display: inline;
      }
      .iconFilter{
        position: absolute;
        right: 5px;
        margin: auto;
      }
    }
  }
`;

@autobind
class List extends React.Component {
  static defaultProps = {
    showMenu: true,
    showEdit: true,
    showDelete: true,
    showNew: true,
    showPage: true,
    showToolBar: true,
    showDetail: false,
    showSearch: false,
    dialogWidth: '70%',
    dialogHeight: '70%',
    labelNew: 'Novo',
    showTitle: false,
    showExport: true,
    showCustomFields: false,
    openLastFilter: true,
    changedColumns: false,
    usarFiltroSalvo: true,
    useExportLimit_1000: false,
    parameters: {
      paiId: null,
      filtrarPorColaborador: false,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      list: this.props.dataSource || [],
      count: 0,
      isLoading: false,
      expandedIds: [],
      selectedIds: [],
      selectedItems: [],
      pageSize: this.props.pageSize || 10,
      currentPage: 1,
      currentItemIndex: -1,
      columns: [],
      allColumns: [],
      columnsComponentOrder: null, //usado para mandar colunas null quando não houver no banco de dados para o component colunas-tabela.jsx
      sort: this.props.sort,
      sortBy:
        this.props.sort &&
        this.props.sort.split(' ')[1] &&
        this.props.sort.split(' ')[1] === 'desc'
          ? 'desc'
          : 'asc',
      searchModelFilter: null,
      selectedFilter: this.props.selectedFilter,
      parameters: this.props.parameters,
      saved: false,
      refreshFilterChip: 0,
      hideFiltroChip: false,
    };
  }

  componentDidMount() {
    this.loadColumns(this.props.children);
    this.loadSelectedIds();
    this.loadPermissions();
    this.renderContent();
  }

  loadColumns(children) {
    let childrenArray = children;
    if (!(childrenArray instanceof Array)) {
      childrenArray = [childrenArray];
    }

    var filtered = childrenArray.filter(function (x) {
      return x != null && x != undefined && x != false;
    });

    let allColumns = filtered.map((c) => c.props);

    let columns = [];
    const {
      showCustomFields,
      retirarTreeCamposCustomizados,
      tipoItem,
      viewType,
    } = this.props;
    if (showCustomFields) {
      httpSelector
        .getHttp(viewType)
        .post(`/CampoCustomizado/ObterListaCamposCustomizados`, {
          tipoItem: tipoItem,
        })
        .then((response) => {
          let campos = response.data;

          if (campos && campos.length > 0) {
            campos.map((itemCC) => {
              let colunaCC = {
                headerText: itemCC.campo.nome,
                valueField: itemCC.campo.nome,
                tree: retirarTreeCamposCustomizados ? false : true,
                visible: true,
                default: false,
              };
              allColumns.push(colunaCC);
              return colunaCC;
            });
          }
          this.obterConfiguracoesColunas(columns, allColumns);
        });
    } else {
      this.obterConfiguracoesColunas(columns, allColumns);
    }
  }

  obterConfiguracoesColunas(columns, allColumns) {
    httpSelector
      .getHttp(this.props.viewType)
      .post(`/ColunasTabela/ObterConfiguracoesColunasTabela`, {
        tag: this.props.tag || 0,
      })
      .then((response) => {
        if (response.data) {
          response.data.map((coluna) => {
            let index = allColumns.findIndex(
              (c) => c.valueField === coluna.valueField
            );
            if (index !== -1) columns.push(allColumns[index]);

            return null;
          });
        }

        if (columns.length === 0) {
          columns = allColumns.filter((c) => c.visible && c.default);
          columns = columns.length === 0 ? allColumns : columns;
        }

        this.setState({
          columns: columns,
          allColumns: allColumns,
          columnsComponentOrder: columns,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }
  loadSelectedIds() {
    if (this.props.select) {
      this.setState({
        selectedIds:
          this.props.selectedIds && this.props.selectedIds.length > 0
            ? this.props.selectedIds.map((d) => d.id)
            : [],
        //lista de itens
        selectedItems:
          this.props.selectedItems && this.props.selectedItems.length > 0
            ? this.props.selectedItems.map((d) => d)
            : [],
      });
    }
  }

  loadPermissions() {
    if (!this.props.local) {
      httpSelector
        .getHttp(this.props.viewType)
        .post(`${this.props.url}/LoadPermissionsAndSearch`, {
          parameters: this.props.parameters,
        })
        .then((response) => {
          this.setState(
            {
              ...response.data,
            },
            () => {
              this.refresh();
            }
          );
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }

  checkHeaderText(header) {
    if (this.state.columns.headerText !== header) {
      return header;
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.changedColumns !== this.props.changedColumns) {
      this.loadColumns(newProps.children);
    }
    if (newProps.saved !== this.props.saved) {
      this.setState({ parameters: newProps.parameters }, this.refresh);
    }
    if (newProps.dataSource !== this.props.dataSource) {
      this.setState({
        list: newProps.dataSource,
      });
    }
    if (newProps.closeModal) {
      this.handleCloseShowModal();
    }
  }

  handleAtualizarColunas(colunas) {
    this.setState({
      columns: colunas,
      columnsComponentOrder: colunas,
    });
  }

  handleSearch(searchModel, chip = false) {
    if (this.props.openLastFilter && !chip) {
      this.handleOpenLastFilter(this.refreshModel);
    } else {
      this.refreshModel(searchModel);
      this.setState({
        searchModelFilter: searchModel.valor,
        selectedFilter: searchModel,
      });
    }
  }

  handleOpenLastFilter(callback) {
    if (
      this.props.filtroId &&
      this.props.showSearch &&
      this.props.usarFiltroSalvo
    ) {
      // busca o ultimo filtro acessado
      httpSelector
        .getHttp(this.props.viewType)
        .post(`/FiltroColaborador/${this.props.filtroId}/AbrirUltimoFiltro`, {})
        .then((response) => {
          if (response.data) {
            this.setState({
              searchModelFilter: response.data.valor,
              selectedFilter: response.data,
            });
            callback(response.data.valor);
          } else {
            callback();
          }
        });
    } else {
      callback();
    }
  }

  refresh() {
    if (this.props.openLastFilter) {
      this.handleOpenLastFilter(this.refreshModel);
    } else if (this.props.selectedFilter || this.props.modelValue) {
      this.refreshModel(this.props.selectedFilter.valor);
    } else if (this.props.refresh) {
      this.props.refresh();
    } else this.refreshModel();
  }

  refreshModel(searchModel, pai) {
    this.setState({
      isLoading: true,
    });

    //Se possuir filtro preenchido
    this.setState(
      {
        parameters: {
          ...this.props.parameters,
          paiId: pai ? pai.id : null,
        },
      },
      () => {
        this.search(searchModel);
      }
    );
  }

  search(searchModel) {
    let { local, url, select, hierarchy } = this.props;
    let { sort, parameters, textoPesquisa, expandedIds, saved, pageSize } =
      this.state;

    if (local) {
      this.searchLocal();
    } else {
      const guidReq =
        Date.now().toString().substring(6) +
        Math.random().toString(36).slice(2) +
        Date.now();

      let newSearchModel = {
        ...searchModel,
        searchText: textoPesquisa,
      };
      let newParameters = {
        ...parameters,
        expandedIds: expandedIds,
        isSaved: saved,
        isSelect: select,
        hierarchy: hierarchy,
      };
      httpSelector
        .getHttp(this.props.viewType)
        .post(`${url}/List`, {
          model:
            newSearchModel.valor != null
              ? newSearchModel.valor
              : newSearchModel,
          sort: sort,
          pageSize: pageSize || 999999,
          page: this.state.currentPage - 1,
          parameters: newParameters,
        })
        .then((response) => {
          this.setState({
            isLoading: false,
            ...response.data,
          });
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  possuiFiltros(searchModel, textoPesquisa) {
    let retorno = false;
    let txtPesquisa = textoPesquisa ? removeAllSpaces(textoPesquisa) : null;

    if (txtPesquisa) {
      retorno = true;
    }

    searchModel &&
      Object.entries(searchModel).map((e) => {
        if ((e[0] === 'status' && e[1] && e[1].id === 0) || e[0] === 'nome') {
          if (e[1]) {
            retorno = true;
          }
        }
        return null;
      });

    return retorno;
  }

  atualizarFilhos(paiId = null, childrenList) {
    let { list } = this.state;

    if (paiId != null) {
      this.updateChildren(list, childrenList, paiId);
    }
  }

  updateChildren(listaItens, childrenList, paiId) {
    if (listaItens) {
      for (let i = 0; i < listaItens.length; i++) {
        if (listaItens[i].id === paiId) {
          listaItens[i].children = childrenList;
        }
        if (listaItens[i].hasChildren) {
          this.updateChildren(listaItens[i].children, childrenList, paiId);
        }
      }
    }
    this.setState({ list: listaItens });
  }

  handleExport(fileExtension) {
    this.setState({
      isLoading: true,
    });

    let newSearchModel = {
      searchText: this.state.textoPesquisa,
      ...this.state.searchModelFilter,
    };

    let { parameters, useExportLimit_1000 } = this.props;
    let params = parameters;
    let { pageSize } = this.state;

    httpSelector
      .getHttp(this.props.viewType)
      .post(
        `${this.props.url}/ExportList`,
        {
          model: newSearchModel,
          sort: this.state.sort,
          pageSize: useExportLimit_1000 ? 1000 : pageSize || 999999,
          page: this.state.currentPage - 1,
          parameters: params,
          fileExtension: fileExtension,
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });

        DownloadFile(response);
      });
  }

  handleExportClick = (exportType) => {
    let { intl, useExportLimit_1000 } = this.props;

    if (useExportLimit_1000 && this.state.count > 1000) {
      ContentManager.addContent(
        <ConfirmNew
          showCancel={true}
          primaryMessage={intl.formatMessage({
            id: 'msgLimiteRegistroExportacao',
          })}
          onSaved={async () => {
            this.handleExport(exportType);
          }}
        />
      );
    } else {
      this.handleExport(exportType);
    }
  };

  handlePDFClick = () => {
    this.handleExportClick('pdf');
  };

  handleExcelClick = () => {
    this.handleExportClick('xlsx');
  };

  handleEmailClick() {
    let { parameters } = this.props;
    let params = parameters;

    ContentManager.addContent(
      <Email
        url={this.props.url}
        model={{
          searchText: this.state.textoPesquisa,
          ...this.state.searchModelFilter,
        }}
        sort={this.state.sort}
        parameters={params}
      />
    );
  }

  handleGeoreferenciaClick() {
    ContentManager.addContent(
      <GeoreferenciaExibicao
        filtroId={this.props.filtroId}
        searchModel={{
          searchText: this.state.textoPesquisa,
          ...this.state.searchModelFilter,
        }}
      />
    );
  }

  searchLocal() {
    let newList = [];

    if (this.state.textoPesquisa && this.state.textoPesquisa !== '') {
      this.state.columns.map(
        (column) =>
          (newList = [
            ...newList.filter(
              (item) =>
                !item[column.valueField]
                  .toString()
                  .toUpperCase()
                  .includes(this.state.textoPesquisa.toUpperCase())
            ),
            ...this.props.dataSource.filter((item) =>
              item[column.valueField]
                .toString()
                .toUpperCase()
                .includes(this.state.textoPesquisa.toUpperCase())
            ),
          ])
      );
      this.setState({
        list: newList,
      });
    } else {
      this.setState({
        list: this.props.dataSource,
      });
    }
    this.setState({
      isLoading: false,
    });
  }

  handleNewClick(parentId, par) {
    this.handleClosePopover();
    if (this.props.handleNewClick) {
      this.props.handleNewClick();
    } else {
      if (this.props.local) {
        ContentManager.addContent(
          <this.props.editComponent
            childTitle={this.props.childTitle}
            mode="edit"
            handleClose={this.handleEditCloseLocal}
            parameters={this.props.parameters}
            {...this.props}
          />
        );
      } else if (this.props.editMode && this.props.editMode === 'page') {
        if (parentId) {
          history.push({
            pathname: `${this.props.urlEdit}`,
            search: `?id=${-1}&parentId=${parentId}`,
          });
        } else {
          history.push({
            pathname: `${this.props.urlEdit}`,
            search: `?id=${-1}`,
          });
        }
      } else {
        ContentManager.addContent(
          <this.props.editComponent
            mode="edit"
            handleClose={this.handleEditClose}
            parameters={{
              ...par,
              ...this.props.parameters,
              parentId: parentId,
            }}
            {...this.props}
          />
        );
      }
    }
  }

  handleEditClick(item, index) {
    this.handleClosePopover();
    if (this.props.local) {
      ContentManager.addContent(
        <this.props.editComponent
          childTitle={this.props.childTitle}
          item={item}
          indexItem={index}
          handleClose={this.handleEditCloseLocal}
          parameters={this.props.parameters}
          {...this.props}
        />
      );
    } else if (this.props.editMode && this.props.editMode === 'page') {
      history.push({
        pathname: `${this.props.urlEdit}`,
        search: `?id=${item.id}`,
      });
    } else {
      ContentManager.addContent(
        <this.props.editComponent
          item={item}
          handleClose={this.handleEditClose}
          parameters={this.props.parameters}
          {...this.props}
        />
      );
    }
  }

  handleEditCloseLocal(saved, isNew, item, index) {
    if (saved) {
      this.props.handleSaveItem &&
        this.props.handleSaveItem(saved, isNew, item, index);
    }
  }

  handleEditClose(saved) {
    this.setState({ saved: saved }, () => {
      if (saved) {
        this.refresh();
      }
    });
  }

  handleDeleteClick(item, index) {
    this.handleClosePopover();
    if (this.props.onDeleting) {
      this.props.onDeleting(item);
    } else {
      ContentManager.addContent(
        <Confirm
          message={
            <FormattedMessage id="label.desejaMesmoExcluirEsseRegistro" />
          }
          handleConfirm={this.handleDeleteClickConfirm.bind(this, item, index)}
        />
      );
    }
  }

  handleDeleteClickConfirm(item, index) {
    if (this.props.local || this.props.handleRemove) {
      this.props.handleRemove && this.props.handleRemove(item, index);
    } else {
      http
        .post(`${this.props.url}/Remove`, {
          id: item.id,
          parameters: this.props.parameters,
        })
        .then(() => {
          ContentManager.addContent(
            <Alert
              title={<FormattedMessage id="sucesso" />}
              message={
                <FormattedMessage id="label.registroExcluidoComSucesso" />
              }
            />
          );
          this.refresh();
          this.props.afterDelete && this.props.afterDelete();
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }

  handleToggleClick(item) {
    let {
      expandedIds,
      list,
      parameters,
      sort,
      textoPesquisa,
      searchModelFilter,
      pageSize,
    } = this.state;

    let newSearchModel = {
      ...searchModelFilter,
      searchText: textoPesquisa,
    };

    let index = expandedIds.findIndex((i) => i === item.id);

    if (index === -1) {
      expandedIds.push(item.id);
    } else {
      expandedIds.splice(index, 1);
    }

    this.setState(
      {
        expandedIds: expandedIds,
      },
      () => {
        if (item.hasChildren && index === -1) {
          this.setState({
            isLoading: true,
          });

          httpSelector
            .getHttp(this.props.viewType)
            .post(`${this.props.url}/Expand`, {
              list: list,
              model: newSearchModel,
              pageSize: pageSize || 999999,
              page: this.state.currentPage - 1,
              sort: sort,
              parameters: { ...parameters, paiId: item.id },
            })
            .then((response) => {
              this.setState({
                list: response.data.list,
                isLoading: false,
              });
            })
            .catch(() => {
              this.setState({
                isLoading: false,
              });
            });
        }
      }
    );
  }

  handlePopoverClick(id, tag) {
    this.setState({
      currentItemIndex: tag.target.id.toString() === id.toString() ? id : 0,
    });
  }

  handleClosePopover() {
    this.setState({
      currentItemIndex: -1,
    });
  }

  isExpanded(item) {
    return this.state.expandedIds.indexOf(item.id) !== -1;
  }

  getHeaderText(column) {
    if (column.resource) {
      if (this.state.resources) {
        return this.state.resources[column.resource];
      } else {
        return column.headerText;
      }
    } else {
      return column.headerText;
    }
  }

  getCellContent(item, column) {
    if (column.valueFunction) {
      return column.valueFunction(item, this.handleEditClose);
    }

    if (column.valueField) {
      let value = item[column.valueField];

      if (column.formatFunction) {
        value = column.formatFunction(value);
      }

      if (item.campoCustomizado) {
        if (item.campoCustomizado.length > 0) {
          let campoCustomizado = item.campoCustomizado.find(
            (i) => i.nomeCampo === column.valueField
          );
          if (campoCustomizado) value = campoCustomizado.valor;
        }
      }
      return value;
    }
  }

  setState(changes, callback) {
    let futureState = {
      ...this.state,
      ...changes,
    };

    let maxPage = Math.floor(futureState.count / futureState.pageSize) + 1;

    if (futureState.currentPage > maxPage) {
      changes.currentPage = maxPage;
    } else if (futureState.currentPage < 1) {
      changes.currentPage = 1;
    }

    super.setState(changes, callback);
  }

  changePageSize(pageSize) {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.refresh();
      }
    );
  }

  changeCurrentPage(newValue) {
    this.setState(
      {
        currentPage: newValue,
      },
      () => {
        this.refresh();
      }
    );
  }

  changeSort(sortField) {
    let newSortBy = this.state.sortBy === 'asc' ? 'desc' : 'asc';
    let newSortField = newSortBy ? sortField + ' ' + newSortBy : sortField;
    this.setState(
      {
        sort: newSortField,
        sortBy: newSortBy,
      },
      () => {
        this.refresh();
      }
    );
  }

  itemEstaSelecionado(item) {
    return this.state.selectedIds.indexOf(item.id) !== -1;
  }

  toggleItem(item) {
    let selectedIdsTemp = this.state.selectedIds;
    let selectedItemsTemp = this.state.selectedItems.map((item) => item);

    if (!this.props.multiple) {
      selectedIdsTemp = [];
      selectedItemsTemp = [];
    }

    if (this.itemEstaSelecionado(item)) {
      selectedIdsTemp = this.state.selectedIds.filter((id) => id !== item.id);
      selectedItemsTemp = this.state.selectedItems.filter(
        (id) => id !== item.id
      );
    } else {
      if (!this.props.multiple) {
        selectedIdsTemp = selectedIdsTemp.concat([item.id]);
        selectedItemsTemp.push(item);
      } else {
        selectedIdsTemp = this.state.selectedIds.concat([item.id]);
        selectedItemsTemp.push(item);
      }
    }

    let selectedItems =
      selectedItemsTemp && selectedItemsTemp.length > 0
        ? selectedItemsTemp.map((item) => item)
        : [];

    this.setState(
      {
        selectedIds: selectedIdsTemp,
        selectedItems,
      },
      () => {
        if (this.props.selectRealTime) {
          this.handleConfirmSelection();
        }
      }
    );
  }

  handleConfirmSelection() {
    if (this.props.selectedCallback) {
      this.props.selectedCallback(
        this.getAllSelectedItems(this.state.selectedItems)
      );
    }

    if (!this.props.selectRealTime) {
      this.props.remove();
    }
  }

  getAllSelectedItems(list, result = []) {
    let { hierarchy } = this.props;

    list.filter((item) => {
      if (
        this.itemEstaSelecionado(item) &&
        !result.some((a) => a.id === item.id)
      ) {
        result.push(item);
      }
      if (hierarchy && item.children && item.children.length > 0) {
        this.getAllSelectedItems(item.children, result);
      }
      return null;
    });
    return result;
  }

  handleTextoPesquisaChange(value) {
    if (value) this.setState({ textoPesquisa: value.target.value });

    clearTimeout(this.textoPesquisaDebounceId);
    this.textoPesquisaDebounceId = setTimeout(() => {
      this.refresh();
    }, 500);
  }

  handleCustomItemClick(item, customItem) {
    this.handleClosePopover();
    customItem.onClick &&
      customItem.onClick(item, customItem.parameters, this.handleEditClose);
  }

  getVisibleCreateChildItems(listItem, item) {
    return listItem.map((customItem, index) => {
      let visible = customItem.visible(item);
      if (visible) {
        return (
          <DropdownItem
            key={4 + index}
            onClick={this.handleCustomItemClick.bind(this, item, customItem)}
          >
            <MdiIcon className="mr-2" icon={customItem.icon} />
            {customItem.label}
          </DropdownItem>
        );
      }
      return null;
    });
  }

  handleColunasTabelaClick(columns) {
    ContentManager.addContent(
      <ColunasTabelas
        columns={columns}
        allColumns={this.state.allColumns}
        tag={this.props.tag}
        handleAtualizarColunas={this.handleAtualizarColunas}
      />
    );
  }

  handleSort(id, column) {
    let { sort, sortBy } = this.state;
    if (!sort) return;
    if (column.sortField == sort.split(' ')[0] && sortBy == 'desc') {
      return (
        <MdiIcon
          style={{ cursor: 'pointer' }}
          id={id}
          size="14"
          className="ml-2"
          icon="chevron-up"
        />
      );
    }

    if (column.sortField == sort.split(' ')[0] && sortBy == 'asc') {
      return (
        <MdiIcon
          style={{ cursor: 'pointer' }}
          id={id}
          size="14"
          className="ml-2"
          icon="chevron-down"
        />
      );
    }

    if (column.sortField)
      return (
        <MdiIcon
          style={{ cursor: 'pointer' }}
          id={id}
          size="14"
          className="ml-2"
          icon="unfold-more-horizontal"
        />
      );
  }

  handleCloseShowModal() {
    this.refs.dialog.close();
  }

  render() {
    if (this.props.showModal) {
      let { select } = this.props;
      let actions = select && [
        <Button onClick={this.props.remove} key="btnclose" color="secondary">
          <FormattedMessage id="label.cancelar" />
        </Button>,
        <Button
          key="bynSave"
          type="primary"
          style={{ marginLeft: 10 }}
          onClick={this.handleConfirmSelection}
        >
          <FormattedMessage id="label.confirmar" />
        </Button>,
      ];

      return (
        <Dialog
          onRequestClose={this.handleCloseShowModal}
          ref="dialog"
          {...this.props}
          title={this.props.title}
          padContent
          width={this.props.dialogWidth}
          height={this.props.dialogHeight}
          actions={actions}
        >
          {this.renderContent()}
        </Dialog>
      );
    } else {
      return this.renderContent();
    }
  }
  handleFiltroModal() {
    let {
      searchComponent,
      searchSchema,
      url,
      filtroId,
      showModal,
      actionsLocal,
      usarFiltroSalvo,
      ...other
    } = this.props;
    let { selectedFilter, search } = this.state;

    return ContentManager.addContent(
      <Filtro
        {...other}
        filtroId={filtroId}
        searchComponent={searchComponent}
        searchSchema={searchSchema}
        onSearch={this.handleSearch}
        showModal={showModal}
        url={url}
        selectedFilter={selectedFilter}
        search={search}
        tipoItem={this.props.tipoItem}
        usarFiltroSalvo={usarFiltroSalvo}
      ></Filtro>
    );
  }
  renderContent() {
    let columns = this.state.columnsComponentOrder
      ? this.state.columnsComponentOrder
      : this.state.columns.filter((x) => x.default);

    let {
      select,
      actionsWidth,
      showNew,
      customToolBar,
      showToolBar,
      showSearch,
      showQuickSearch,
      showMenu,
      local,
      actions,
      tag,
      title,
      showTitle,
      showExport,
      intl,
      actionsLocal,
      showPagination = false,
      searchSchema,
      stickyHeader = false,
      CustomComponent,
      showCustomComponent,
      requiredCustomComponent,
      actionsToolBarEnd,
      hideFiltroChip,
    } = this.props;

    let { list, selectedFilter, refreshFilterChip } = this.state;
    let actionsWidthTemp = actionsWidth ? actionsWidth : 120;
    let count = local ? (list ? list.length : 0) : this.state.count;
    return (
      <Card style={this.props.styleCard}>
        {showToolBar && (
          <CardHeader>
            <div className="align-items-center d-flex justify-content-between">
              {showTitle && (
                <div
                  style={{ maxWidth: 100 }}
                  className="d-inline-flex mx-2 font-weight-bolder"
                >
                  <div className="text-truncate">{title}</div>
                </div>
              )}

              {(showSearch || showQuickSearch) && (
                <div
                  style={{ width: 187, minWidth: 187 }}
                  className="d-inline-flex"
                >
                  <InputSearch
                    placeholder={intl.formatMessage({
                      id: 'label.pesquisaRapida',
                    })}
                    autoFocus
                    value={this.state.textoPesquisa}
                    onChange={this.handleTextoPesquisaChange}
                  ></InputSearch>
                </div>
              )}

              {!hideFiltroChip &&
              ((selectedFilter && selectedFilter.valor) || selectedFilter) ? (
                <div style={{ minWidth: 40 }} className="mr-auto">
                  <FiltroChip
                    refreshFilterChip={
                      this.props.refreshFilterChip + refreshFilterChip
                    }
                    filtroId={this.props.filtroId}
                    onSearch={this.handleSearch}
                    searchSchema={searchSchema}
                    selectedFilter={
                      selectedFilter && selectedFilter.valor != null
                        ? selectedFilter.valor
                        : selectedFilter
                    }
                    preventRemoveFilterChip={this.props.preventRemoveFilterChip}
                    onRemoveSaveFilter={this.props.onRemoveSaveFilter}
                  />
                </div>
              ) : (
                !showCustomComponent && <div />
              )}

              <div className="d-inline-flex">
                {showCustomComponent && (
                  <Col md={7}>
                    <CustomComponent
                      {...this.props}
                      model={this.props.modelOrigem}
                      disabled={this.props.disabledCustom}
                      required={requiredCustomComponent}
                    />
                  </Col>
                )}

                {showSearch && (
                  <Button className="ml-2" onClick={this.handleFiltroModal}>
                    <MdiIcon icon="filter" />
                  </Button>
                )}
                {(showExport || actions || actionsLocal) && (
                  <UncontrolledDropdown
                    group
                    direction="left"
                    className="ml-2 d-inline"
                    key="list"
                  >
                    <DropdownToggle color="secondary">
                      <MdiIcon icon="dots-horizontal" />
                    </DropdownToggle>
                    <DropdownMenu container="body">
                      {actions &&
                        this.state.permissions &&
                        this.state.permissions.allowCreate &&
                        actions.map((a, index) =>
                          React.cloneElement(a, {
                            key: index + 4,
                          })
                        )}
                      {actionsLocal &&
                        actionsLocal.map((a, index) =>
                          React.cloneElement(a, {
                            key: index + 4,
                          })
                        )}
                      {showExport && actions && <DropdownItem divider />}
                      {showExport && (
                        <DropdownItem
                          eventkey="1"
                          onClick={this.handleEmailClick}
                        >
                          <FormattedMessage id="enviarEmail" />
                        </DropdownItem>
                      )}
                      {showExport && (
                        <DropdownItem
                          eventkey="2"
                          onClick={this.handlePDFClick}
                        >
                          <FormattedMessage id="label.exportarPDF" />
                        </DropdownItem>
                      )}
                      {showExport && (
                        <DropdownItem
                          eventkey="3"
                          onClick={this.handleExcelClick}
                        >
                          <FormattedMessage id="label.exportarExcel" />
                        </DropdownItem>
                      )}
                      {(this.props.filtroId === 1 ||
                        this.props.filtroId === 3 ||
                        this.props.filtroId === 4) && (
                        <DropdownItem
                          eventkey="3"
                          onClick={this.handleGeoreferenciaClick}
                        >
                          <FormattedMessage id="label.georeferencia" />
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {tag && (
                  <Button
                    className="ml-2"
                    color="secondary"
                    onClick={this.handleColunasTabelaClick.bind(
                      this,
                      this.state.columnsComponentOrder
                    )}
                  >
                    <MdiIcon icon="format-columns" color="secondary" />
                  </Button>
                )}
                {((showNew &&
                  this.state.permissions &&
                  this.state.permissions.allowCreate) ||
                  (showNew && local)) && (
                  <Button
                    className="ml-2"
                    onClick={this.handleNewClick.bind(this, null, null)}
                  >
                    <MdiIcon icon="plus" />
                  </Button>
                )}
                {actionsToolBarEnd &&
                  actionsToolBarEnd.map((a, index) =>
                    React.cloneElement(a, {
                      key: index + 100,
                    })
                  )}
              </div>
            </div>
            {customToolBar &&
              React.cloneElement(customToolBar, { intl: this.props.intl })}
          </CardHeader>
        )}

        <CardBody>
          <Row>
            <Col md={12}>
              <LoadingContainer
                className={classes.LoadingContainer}
                isLoading={this.state.isLoading}
              >
                {this.state.list && this.state.list.length > 0 ? (
                  <div>
                    <Row>
                      <Col md={12}>
                        {(this.state.permissions &&
                          this.state.permissions.allowView) ||
                        local ? (
                          <Table
                            responsive={true}
                            count={count}
                            size="sm"
                            stickyHeader={stickyHeader}
                            style={{ background: '#FFF' }}
                          >
                            <thead>
                              <tr>
                                {select && (
                                  <th className={classes.checkCell}></th>
                                )}
                                {showMenu && <th style={{ width: 30 }}></th>}
                                {columns.map(
                                  (column, index) =>
                                    column.visible && (
                                      <th
                                        className="align-top"
                                        key={index}
                                        onClick={
                                          column.sortField &&
                                          this.changeSort.bind(
                                            this,
                                            column.sortField
                                          )
                                        }
                                        style={{
                                          minWidth: column.minWidth
                                            ? column.minWidth
                                            : 'auto',
                                        }}
                                      >
                                        {
                                          <div className={classes.tHeadContent}>
                                            <div className="d-inline">
                                              {this.getHeaderText(column)}
                                            </div>
                                            {column.sortField && (
                                              <div className="d-inline">
                                                {this.handleSort(index, column)}
                                              </div>
                                            )}
                                          </div>
                                        }
                                      </th>
                                    )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {this.renderRows(
                                columns,
                                this.state.list,
                                actionsWidthTemp
                              )}
                            </tbody>
                          </Table>
                        ) : (
                          <p>
                            <FormattedMessage id="label.voceNaoTemPermissaoParaVisualizarEstesRegistros" />
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <p style={{ fontStyle: 'italic', padding: 20 }}>
                    <FormattedMessage id="label.nenhumRegistroFoiEncontrado" />
                  </p>
                )}
              </LoadingContainer>
            </Col>
          </Row>
          {showPagination && (
            <Row>
              <Col md={12}>
                <div className="pull-right">
                  <Pagination
                    allowPaging
                    currentPage={this.state.currentPage}
                    count={this.state.count}
                    pageSize={this.state.pageSize}
                    changeCurrentPage={this.changeCurrentPage}
                    changePageSize={this.changePageSize}
                  />
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    );
  }

  renderMenu(item, indexItem, exibirMenu = true) {
    let {
      hierarchy,
      createChildItems,
      showEdit,
      showDelete,
      renderMenu,
      hideInsertDropDown = false,
    } = this.props;
    if (renderMenu) {
      return this.props.renderMenu(item);
    } else {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            style={{ cursor: exibirMenu ? 'pointer' : 'inherit' }}
            tag="a"
            className="nav-link"
          >
            <MdiIcon
              icon="dots-vertical"
              color={exibirMenu ? '#656565' : 'transparent'}
              colorHover={exibirMenu ? '#656565' : 'transparent'}
              size={18}
            />
          </DropdownToggle>
          <DropdownMenu container="body">
            {item.permissions && item.permissions.allowView && showEdit && (
              <DropdownItem
                onClick={this.handleEditClick.bind(this, item, indexItem)}
              >
                <MdiIcon className="mr-2" icon="pencil" />
                <FormattedMessage
                  id="label.editar"
                  values={{ editar: this.props.resources.editar }}
                />
              </DropdownItem>
            )}
            {item.permissions && item.permissions.allowDelete && showDelete && (
              <DropdownItem
                onClick={this.handleDeleteClick.bind(this, item, indexItem)}
              >
                <MdiIcon className="mr-2" icon="delete-outline" />
                <FormattedMessage id="excluir" />
              </DropdownItem>
            )}
            {createChildItems &&
              item.permissions &&
              (item.permissions.allowEdit || item.permissions.allowView) &&
              this.getVisibleCreateChildItems(createChildItems, item)}
            {!hideInsertDropDown && hierarchy && !createChildItems && (
              <DropdownItem
                onClick={this.handleNewClick.bind(this, item.id, null)}
              >
                <MdiIcon className="mr-2" icon="plus" />
                <FormattedMessage id="label.inserir" />
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  }

  renderRows(columns, items, actionsWidth, level = 0) {
    if (!items) return;

    let { select, hierarchy, showMenu } = this.props;

    let colorIcon = '#939393';

    return items.map((item, indexItem) => [
      <tr key={item.id}>
        {select && (
          <td>
            {item && item.permissions && item.permissions.allowView && (
              <Checkbox
                id={`checklist-${guid()}`}
                style={{ marginBottom: 0, paddingTop: 7 }}
                checked={this.itemEstaSelecionado(item)}
                onChange={this.toggleItem.bind(this, item)}
              />
            )}
          </td>
        )}
        {showMenu && (
          <td>
            {this.renderMenu(
              item,
              indexItem,
              item && item.permissions && item.permissions.allowView
            )}
          </td>
        )}
        {columns.map(
          (column, index) =>
            column.visible && (
              <td key={index}>
                <div
                  className="d-flex align-items-center"
                  style={{ paddingLeft: column.tree ? level * 20 : 0 }}
                >
                  {column.tree &&
                    (hierarchy && item && item.hasChildren ? (
                      <MdiIcon
                        className={classes.hierarchy}
                        color={colorIcon}
                        icon={
                          this.isExpanded(item)
                            ? 'chevron-down'
                            : 'chevron-right'
                        }
                        onClick={this.handleToggleClick.bind(this, item)}
                      />
                    ) : (
                      <MdiIcon
                        className={classes.hierarchy}
                        color={colorIcon}
                      />
                    ))}
                  {this.getCellContent(item, column)}
                </div>
              </td>
            )
        )}
      </tr>,
      this.state.expandedIds.indexOf(item.id) !== -1 &&
        hierarchy &&
        this.renderRows(columns, item.children, actionsWidth, level + 1),
    ]);
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    viewType: state.viewType,
  };
}
export default injectIntl(connect(mapStateToProps)(List));
