import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import qs from 'query-string';
import { injectIntl } from 'react-intl';
import IndicadorBaseEdit from '../indicador-base/edit.jsx';

@autobind
class IndicadorEdit extends React.Component {
  render() {
    let { resources, showDelete, showPrompt } = this.props;
    let id = this.props.id ? this.props.id : this.props.location && qs.parse(this.props.location.search).id;

    return (
      <IndicadorBaseEdit
        showDelete={showDelete}
        urlValidarRestricoes="/Indicador/ValidarRestricoes"
        urlAtualizarStatusExcluido="/Indicador/AtualizarStatusExcluido"
        urlList="/gerenciar/indicador"
        urlEdit="/gerenciar/indicador/edit"
        urlController="/Indicador"
        tipoItemId={1}
        title={capitalizeFirstLetter(resources.indicador)}
        id={id}
        location={this.props.location}
        showPrompt={showPrompt}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(IndicadorEdit));
