import React from 'react';
import { autobind } from 'core-decorators';
import { FormGroup, Alert, Input } from 'reactstrap';
import { injectIntl } from 'react-intl';

import { capitalizeFirstLetter } from 'src/utils/string';
import Info from '../info';
@autobind
class Radio extends React.Component {
  static defaultProps = {
    inline: true,
    needId: false,
    showLabel: true,
    hintItens: null,
    style: null,
    hintLabel: 'label',
    colunaUnica: false,
  };

  handleChangeRadio(value) {
    let item = this.props.options.find((i) => i.id == value.id);

    if (this.props.needId) {
      this.props.model.requestChange(item.id);
    } else {
      this.props.model.requestChange(item);
    }

    this.triggerChange(item);

    if (this.props.getAlteracao != null) this.props.getAlteracao();
  }

  triggerChange(changedValue) {
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(changedValue);
    }
  }

  getLabel(item) {
    if (this.props.labelFunction) return this.props.labelFunction(item);

    if (this.props.labelField) return item[this.props.labelField];

    return item;
  }

  renderHint(item, hintItens, hintKey) {
    const hint = hintItens.find((i) => i.id == item.id);

    if (hint && hint[this.props.hintLabel]) {
      return (
        <Info
          style={{ marginTop: -4 }}
          id={`hint-${hint.id}-${hintKey}`}
          placement="right"
          text={capitalizeFirstLetter(hint[this.props.hintLabel])}
        />
      );
    }
  }

  render() {
    const {
      model,
      disabled,
      name,
      options,
      className,
      inline,
      inColum,
      needId,
      showLabel,
      style,
      hintItens,
      absolute,
      hintKey = '',
      colunaUnica,
    } = this.props;

    return (
      <FormGroup
        style={style}
        className={className}
        validationstate={model.errors ? 'error' : null}
      >
        <div style={{ color: model.errors ? 'red' : null }}>
          {showLabel && model.label && (
            <label className="control-label">{model.label}</label>
          )}
          <div>
            {options &&
              options.map((item, index) => (
                <div
                  key={`div-options-${index}`}
                  className={`${inline ? 'form-check-inline' : ''} ${
                    inColum
                      ? colunaUnica
                        ? 'col-md-5 d-flex flex-column align-items-start justify-content-center'
                        : 'col-md-5'
                      : ''
                  }`}
                >
                  <div
                    className="c-radio d-flex align-items-center"
                    key={index}
                  >
                    <label className="mr-2">
                      {absolute ? (
                        <Input
                          type="radio"
                          disabled={disabled || item.disabled}
                          name={`${name}-${index}`}
                          checked={
                            model.value &&
                            (needId
                              ? item.id === model.value
                              : item.id === model.value.id)
                          }
                          onClick={this.handleChangeRadio.bind(this, item)}
                        />
                      ) : (
                        <Input
                          type="radio"
                          disabled={disabled || item.disabled}
                          name={`${name}-${index}`}
                          checked={
                            model.value &&
                            (needId
                              ? item.id === model.value
                              : item.id === model.value.id)
                          }
                          onClick={this.handleChangeRadio.bind(this, item)}
                          className="mr-1 pt-1"
                          style={{ position: 'relative' }}
                        />
                      )}
                      {this.getLabel(item)}
                    </label>
                    {hintItens && this.renderHint(item, hintItens, hintKey)}
                  </div>
                </div>
              ))}
          </div>
          {model.erros && <Alert>{model.erros}</Alert>}
        </div>
      </FormGroup>
    );
  }
}

export default injectIntl(Radio);
