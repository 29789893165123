import { injectIntl, FormattedMessage } from 'react-intl';
import React from 'react';
import { Badge, DropdownItem } from 'reactstrap';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';

import http from 'src/services/httpService';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import Avatar from 'src/componentes/avatar';
import Confirm from 'src/componentes/message-box/confirm';
import errorHandler from 'src/utils/error-handler';
import { capitalizeFirstLetter } from 'src/utils/string';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';
import ContentManager from 'src/componentes/content-manager';
import SendEmail from './email-colaboradores';
import ColaboradorEdit from './edit';
import Search from './search';
import SearchSchema from './search-schema';
import LicencasModulosColaborador from './Licencas';
import ColunaLicencaColaborador from './Licencas/ColunaLicencaColaborador';

@autobind
class Colaborador extends React.Component {
  static defaultProps = {
    sendEmails: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      permissaoLicenca: false,
      changedColumns: false,
      estatisticaLicencaModulos: null,
    };
  }

  async ocultarAcoesLista() {
    try {
      const response = await http.post('Colaborador/OcultarAcoesLista', {});
      this.setState({
        ocultarAcoesLista: response.data,
      });
    } catch (err) {
      errorHandler(err);
    }
  }

  componentDidMount() {
    this.ocultarAcoesLista();
    this.obterPermissaoGerenciarLicenca();
  }

  async obterEstatiscaLicencaModulos() {
    try {
      const response = await http.get(
        'Colaborador/ObterEstatiscaLicencaModulos'
      );
      this.setState({
        estatisticaLicencaModulos: response.data,
      });
    } catch (err) {
      errorHandler(err);
    }
  }

  async obterPermissaoGerenciarLicenca() {
    try {
      const response = await http.get(
        'Colaborador/ObterPermissaoGerenciarLicenca'
      );
      this.setState({
        permissaoLicenca: response.data,
        changedColumns: response.data,
      });

      if (response.data) this.obterEstatiscaLicencaModulos();
    } catch (err) {
      errorHandler(err);
    }
  }

  renderStatus(item) {
    if (!item.status) return;

    if (item.status.id == 1)
      return <Badge color="success">{item.status.descricao}</Badge>;
    else return <Badge color="secondary">{item.status.descricao}</Badge>;
  }

  renderDescricao(item) {
    return (
      <Avatar foto={item.fotoArquivo} label={item.nome} size={28}></Avatar>
    );
  }

  renderEmail(item) {
    if (item.email != null) return <span>{item.email}</span>;
    else
      return (
        <span className="label label-danger">
          {<FormattedMessage id="label.cadastrarEmailParaEsteUsuario" />}
        </span>
      );
  }

  handleAtivarInativarConfirm(item) {
    if (item && item.status && item.status.id == 0) {
      return this.handleAtivarInativarConfirmClick(item);
    }

    ContentManager.addContent(
      <Confirm
        message={this.props.intl.formatMessage({
          id: 'desejaMesmoInativarColaborador',
        })}
        title={this.props.intl.formatMessage({
          id: 'label.inativar',
        })}
        handleConfirm={this.handleAtivarInativarConfirmClick.bind(this, item)}
      />
    );
  }

  handleAtivarInativarConfirmClick(item) {
    this.setState({
      saved: false,
    });

    http
      .post(`/Colaborador/AtivarInativarColaborador`, {
        idColaborador: item.id,
        ativar: item.status.id == 0,
      })
      .then(() => {
        this.setState({
          saved: true,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/Colaborador/ValidarRestricoes"
        urlExclusao="/Colaborador/AtualizarStatusExcluido"
        item={item}
        onClose={this.handleCloseEdit}
      />
    );
  }

  handleCloseEdit(saved) {
    if (saved) this.setState({ saved: Math.random() });
  }

  handleSendEmails() {
    ContentManager.addContent(<SendEmail />);
  }

  disableSelectedItems(item) {
    const { bloquearSelecaoSemEmail } = this.props;

    if (!item.podeSerAvaliador) return true;

    if (item.limiteAvaliacoes) return true;

    if (!bloquearSelecaoSemEmail) return false;

    return !item || !item.email || item.email === '';
  }

  handleLicencasClick() {
    ContentManager.addContent(<LicencasModulosColaborador />);
  }

  hintOnDisabledItem(item) {
    const { bloquearSelecaoSemEmail, intl, resources } = this.props;
    if (bloquearSelecaoSemEmail)
      return intl.formatMessage({
        id: 'msgValidacaoCadastrarEmailSelecionarRegistro',
      });

    if (!item.podeSerAvaliador)
      return intl.formatMessage({
        id: 'msgValidacaoIndicarRepetidoProcessoAvaliativo',
      });

    if (item.limiteAvaliacoes)
      return intl.formatMessage(
        {
          id: 'msgValidacaoIndicarRepetidoProcessoAvaliativoLider',
        },
        { avaliacoes: resources.avaliacoes }
      );
  }

  renderLicencas(item) {
    if (this.state.permissaoLicenca && this.state.changedColumns)
      this.setState({ changedColumns: false });
    return (
      <ColunaLicencaColaborador
        item={item}
        modulosDisponiveis={this.props.modulosDisponiveis}
        estatisticaLicencaModulos={this.state.estatisticaLicencaModulos}
        setEstatisticaLicencaModulos={(e) =>
          this.setState({ estatisticaLicencaModulos: e })
        }
      />
    );
  }

  render() {
    let createChildItems = [
      {
        label: <FormattedMessage id="label.inativar" />,
        icon: 'account-remove',
        visible: (item) => item.status.id == 1,
        onClick: this.handleAtivarInativarConfirm,
      },
      {
        label: <FormattedMessage id="label.ativar" />,
        icon: 'account-plus',
        visible: (item) => item.status.id == 0,
        onClick: this.handleAtivarInativarConfirm,
      },
    ];
    let {
      resources,
      intl,
      showModal,
      sendEmails,
      parameters,
      bloquearSelecaoSemEmail,
      selecionarAvaliador,
    } = this.props;

    let actions = this.state.permissaoLicenca && [
      <DropdownItem eventkey="1" onClick={this.handleLicencasClick}>
        {capitalizeFirstLetter(
          intl.formatMessage({ id: 'acompanharLicencas' })
        )}
      </DropdownItem>,
    ];

    return (
      <List
        handleSendEmails={sendEmails && this.handleSendEmails}
        emailsTooltip="emailBoasVindas"
        url="/Colaborador"
        saved={this.state.saved}
        createChildItems={createChildItems}
        filtroId={showModal ? 24 : 10}
        searchComponent={<Search />}
        searchSchema={SearchSchema}
        showSearch={true}
        actionsColumn="nome"
        actionsLocal={actions}
        setPermissions={this.setPermissions}
        titulo={capitalizeFirstLetter(resources.colaboradores)}
        editComponent={ColaboradorEdit}
        sort="nome"
        onDeleting={this.handleDeleteClick}
        showScrollColumn={false}
        changedColumns={this.state.changedColumns}
        parameters={parameters}
        {...this.props}
        showFilter={true}
        tag={1003}
        disableSelectedItems={
          (bloquearSelecaoSemEmail || selecionarAvaliador) &&
          this.disableSelectedItems
        }
        hintOnDisabledItem={this.hintOnDisabledItem}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'nome' })}
          valueField="nome"
          valueFunction={this.renderDescricao}
          sortField="nome"
          required
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'email' })}
          valueField="email"
          sortField="email"
          valueFunction={this.renderEmail}
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'matricula' })}
          valueField="idIntegracao"
          sortField="idIntegracao"
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'telefone' })}
          valueField="telefone"
          sortField="telefone"
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'status' })}
          valueField="status"
          sortField="status"
          valueFunction={this.renderStatus}
          default
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.area)}
          valueField="unidadeArea"
          sortField="organizacao.nome"
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'emailBoasVindasEnviado' })}
          valueField="emailBoasVindasEnviado"
          sortField="emailBoasVindasEnviado"
          default
        />
        <ListColumn
          visible={this.state.permissaoLicenca}
          default
          valueField="colaboradorModulos"
          headerText={intl.formatMessage({ id: 'licencasDeSoftware' })}
          valueFunction={this.renderLicencas}
        />
      </List>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    modulosDisponiveis: state.user.modulosDisponiveis,
  };
}
export default injectIntl(connect(mapStateToProps)(Colaborador));
