import React from 'react';
import { autobind } from 'core-decorators';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import qs from 'query-string';

import Input from 'src/componentes/input';
import Panel from 'src/componentes/panel/index';
import DatePicker from 'src/componentes/date-picker';
import Checkbox from 'src/componentes/checkbox';
import EditDialog from 'src/componentes/edit/edit-dialog';
import ColaboradorLookup from 'src/lookups/colaborador';
import AcaoList from 'src/paginas/acao/select-list';
import Radio from 'src/componentes/radio';
import EfetividadeLookup from 'src/lookups/ponto-controle-revisao';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import { capitalizeFirstLetter } from 'src/utils/string';
import Historico from 'src/paginas/versao-alteracao';
import PanelCollapse from 'src/componentes/panel-collapse';
import RiscoPontoControleRevisaoLookup from 'src/lookups/status-risco-ponto-controle-revisao';
import Info from 'src/componentes/info';
import Hint from 'src/paginas/item/componentes/hint';
import { STATUS_PONTO_CONTROLE_REVISAO } from 'src/utils/constants';
import history from 'src/history';

import Schema from './Schema';
import StatusValidacoesGrid from './StatusValidacoesGrid';
import { getConfiguration } from 'src/services/commonRequests';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';

@autobind
class RiscoPontoControleRevisaoForm extends React.Component {
  constructor(props) {
    super(props);
    this.saved = false;
    this.state = {
      disabled: false,
      podeConfirmar: false,
      podeOptarEfetivo: false,
      efetivo: null,
      opcoesEfetivo: [
        {
          id: 1,
          descricao: this.props.intl.formatMessage({ id: 'efetivo' }),
        },
        {
          id: 2,
          descricao: this.props.intl.formatMessage({ id: 'naoEfetivo' }),
        },
      ],
      conforme: {},
      opcoesConforme: [
        {
          id: 1,
          descricao: this.props.resources.conforme,
        },
        {
          id: 2,
          descricao: this.props.resources.naoconforme,
        },
      ],
      openListaAcao: true,
      openListaAcaoEfetivar: true,
      openConforme: true,
      item: this.props.item || null,
      index: this.props.index,
      configuracao: {},
    };
  }

  componentDidMount() {
    getConfiguration('PontoControleConfiguracao')((configuracao) =>
      this.setState({ ...this.state, configuracao })
    )();
  }

  handleSave() {
    this.saved = true;
    this.close();
  }

  renderContentInfoStatus() {
    const { intl, resources } = this.props;
    return (
      <div>
        <div>
          {intl.formatMessage(
            { id: 'infoStatusNaoIniciadoRevisaoPontoControle' },
            { revisaodecontrole: resources.revisaodecontrole }
          )}
        </div>
        <div>
          {intl.formatMessage(
            { id: 'infoStatusEmAndamentoRevisaoPontoControle' },
            { revisaodecontrole: resources.revisaodecontrole }
          )}
        </div>
        <div>
          {intl.formatMessage(
            { id: 'infoStatusEmTratamentoRevisaoPontoControle' },
            { revisaodecontrole: resources.revisaodecontrole }
          )}
        </div>
        <div>
          {intl.formatMessage(
            { id: 'infoStatusConcluidoRevisaoPontoControle' },
            { revisaodecontrole: resources.revisaodecontrole }
          )}
        </div>
      </div>
    );
  }

  renderContentInfoAnexo() {
    const { intl } = this.props;
    return (
      <div>
        <div>
          {intl.formatMessage({
            id: 'infoAnexoObrigatorioSomenteParaConcluirRevisao',
          })}
        </div>
      </div>
    );
  }

  renderInformativo(
    item,
    model,
    titulo,
    nomeRiscoLabel,
    descricaoPtControleLabel,
    itensImpactoLabel
  ) {
    try {
      const {
        risco: { nome },
        pontoControle: { descricao },
        impactosIndiretos,
      } = model.getModel().value;
      return (
        <PanelCollapse open={true} header={titulo}>
          <Row>
            <Col md={6}>
              <Input
                type="text"
                model={{
                  label: nomeRiscoLabel,
                  value: nome,
                }}
                disabled={true}
              />
            </Col>
            <Col md={6}>
              <Input
                type="text"
                model={{
                  label: descricaoPtControleLabel,
                  value: descricao,
                }}
                disabled={true}
              />
            </Col>
            <Col md={6}>
              <ListGroup style={{ marginTop: 10 }}>
                {impactosIndiretos && impactosIndiretos.length > 0 && (
                  <ListGroupItemHeading>
                    {itensImpactoLabel}
                  </ListGroupItemHeading>
                )}
                {impactosIndiretos &&
                  impactosIndiretos.map((itemImpacto, index) => (
                    <ListGroupItem>
                      <Hint
                        item={item}
                        itemImpacto={itemImpacto}
                        index={index}
                      />
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </Col>
          </Row>
        </PanelCollapse>
      );
    } catch (err) {
      return '';
    }
  }

  render() {
    const { intl, resources, model, item } = this.props;
    const disabled = this.props.disabled
      ? this.props.disabled
      : model && !model.getModel('permissions').getModel('allowEdit').value;
    const revisaoId = model && model.getModel('id').value;
    const isNew = item == null ? true : false;
    const validacoes = model.getModel('validacoes').value;
    const configAnexoObrigadotio = this.state.configuracao.anexoObrigatorio;

    return (
      <div>
        {model.value && (
          <div>
            {!isNew &&
              this.renderInformativo(
                item,
                model,
                intl.formatMessage({ id: 'label.informacoesBasicas' }),
                capitalizeFirstLetter(resources.risco),
                capitalizeFirstLetter(resources.pontosdecontrole),
                intl.formatMessage({ id: 'label.itensDeImpactoIndireto' })
              )}
            <PanelCollapse
              open={true}
              header={intl.formatMessage({ id: 'label.revisao' })}
            >
              {!isNew && (
                <Row>
                  <Col md={2}>
                    <Input
                      style={{ height: '37px' }}
                      disabled={true}
                      required={false}
                      model={model.getModel('idIntegracao')}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={2}>
                  <DatePicker
                    style={{ height: '45px' }}
                    required
                    timeFormat={false}
                    disabled={disabled || !isNew}
                    model={{
                      label: intl.formatMessage(
                        { id: 'label.dataRevisao' },
                        { pontodecontrole: resources.pontodecontrole }
                      ),
                      value: model.getModel('dataRevisao').value,
                      requestChange:
                        model.getModel('dataRevisao').requestChange,
                      errors: model.getModel('dataRevisao').errors,
                    }}
                  />
                </Col>
                <Col md={4}>
                  <ColaboradorLookup
                    required
                    disabled={disabled || !isNew}
                    model={{
                      label: intl.formatMessage({ id: 'label.executor' }),
                      value: model.getModel('responsavelRevisao').value,
                      requestChange:
                        model.getModel('responsavelRevisao').requestChange,
                      errors: model.getModel('responsavelRevisao').errors,
                    }}
                  />
                </Col>
                <Col md={2}>
                  <RiscoPontoControleRevisaoLookup
                    disabled
                    model={{
                      label: (
                        <span>
                          {intl.formatMessage({ id: 'status' })}
                          <Info
                            id="status"
                            placement="right"
                            renderContent={this.renderContentInfoStatus}
                          />
                        </span>
                      ),
                      value: model.getModel('status').value,
                      requestChange: model.getModel('status').requestChange,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Radio
                    disabled={disabled}
                    model={model.getModel('conformidade')}
                    options={this.state.opcoesConforme}
                    labelField="descricao"
                    idField="id"
                  />
                </Col>
                {configAnexoObrigadotio && (
                  <Col md={1}>
                    <ArquivoUploadAnexos
                      tipoAnexo={
                        this.props.tiposAnexos.riscoPontoControleRevisao
                      }
                      formGroupStyle={{ marginBottom: 0 }}
                      model={{
                        label: (
                          <span>
                            {intl.formatMessage({ id: 'anexos' })}
                            <Info
                              id="arquivos"
                              placement="right"
                              renderContent={this.renderContentInfoAnexo}
                            />
                          </span>
                        ),
                        value: model.getModel('arquivos').value,
                        requestChange: model.getModel('arquivos').requestChange,
                      }}
                    />
                  </Col>
                )}
              </Row>
              {model.getModel('utilizarAuditoria').value && (
                <Row>
                  <Col md={1}>
                    <Checkbox
                      disabled={
                        model.getModel('status').value &&
                        model.getModel('status').value.id !==
                          STATUS_PONTO_CONTROLE_REVISAO.CONCLUIDO
                      }
                      model={{
                        label: intl.formatMessage({ id: 'auditado' }),
                        value: model.getModel('auditado').value,
                        requestChange: model.getModel('auditado').requestChange,
                        errors: model.getModel('auditado').errors,
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Input
                    rows={3}
                    type="textarea"
                    model={model.getModel('descricao')}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {model.getModel('utilizarTipoEvidencia').value &&
                model.getModel('tipoEvidencias').value &&
                model.getModel('tipoEvidencias').value.length > 0 && (
                  <Row>
                    <Col md={4}>
                      {
                        <div>
                          <span>
                            {capitalizeFirstLetter(
                              intl.formatMessage({ id: 'tipoEvidencias' })
                            )}
                          </span>
                          <ListGroup style={{ marginTop: 10 }}>
                            {model
                              .getModel('tipoEvidencias')
                              .value.map((item) => (
                                <ListGroupItem>{item.descricao}</ListGroupItem>
                              ))}
                          </ListGroup>
                        </div>
                      }
                    </Col>
                  </Row>
                )}
            </PanelCollapse>
            {model.getModel('camposCustomizados').value &&
              model.getModel('camposCustomizados').value.length > 0 && (
                <PanelCollapse
                  open={true}
                  header={intl.formatMessage({
                    id: 'informacoesComplementares',
                  })}
                >
                  <DesenharCampoCustomizado
                    rowsTextArea={6}
                    model={model.getModel('camposCustomizados')}
                    disabled={disabled}
                  />
                </PanelCollapse>
              )}
            {model.getModel('conformidade') &&
              model.getModel('conformidade').value &&
              model.getModel('conformidade').value.id == 2 && (
                <Row>
                  <Col md={12}>
                    <AcaoList
                      showDelete={false}
                      params={{
                        itemId:
                          model.getModel('risco') &&
                          model.getModel('risco').value?.id,
                        riscoPontoControleRevisaoId: model.getModel('id').value,
                      }}
                      showPrevious={false}
                      showLink={true}
                      showNew={!disabled}
                      disabled={disabled}
                      showExport={true}
                      autoFocus={false}
                    ></AcaoList>
                  </Col>
                  <Col md={3}>
                    <EfetividadeLookup
                      clearable
                      model={model.getModel('efetividade')}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              )}
            {validacoes && validacoes.length > 0 && (
              <PanelCollapse
                open={true}
                header={capitalizeFirstLetter(
                  intl.formatMessage({ id: 'validacao' })
                )}
              >
                <StatusValidacoesGrid validacoes={validacoes} />
              </PanelCollapse>
            )}
            {revisaoId > 0 && (
              <Row>
                <Col md={12}>
                  <PanelCollapse
                    open={false}
                    header={intl.formatMessage({ id: 'label.historico' })}
                  >
                    <Historico
                      url={`/RiscoPontoControleRevisao/ObterHistorico`}
                      id={revisaoId}
                    />
                  </PanelCollapse>
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}

class RiscoPontoControleRevisaoEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idRevisao:
        this.props.location && qs.parse(this.props.location.search).idRevisao,
      idRiscoPontoControle:
        this.props.location &&
        qs.parse(this.props.location.search).idRiscoPontoControle,
    };
  }

  handleOnClose() {
    history.push('/gerenciar/revisoes-ponto-controle');
  }

  render() {
    let { resources, intl, item, afterSave } = this.props;

    const idRevisao = this.state.idRevisao
      ? this.state.idRevisao
      : item && item.id;
    const idRiscoPontoControle = this.state.idRiscoPontoControle
      ? this.state.idRiscoPontoControle
      : this.props.parameters && this.props.parameters.idRiscoPontoControle;

    if (idRevisao) item = { id: idRevisao };

    return (
      <EditDialog
        url="/RiscoPontoControleRevisao"
        title={intl.formatMessage(
          { id: 'label.revisaoPontoControle' },
          { revisaodecontrole: resources.revisaodecontrole }
        )}
        formComponent={RiscoPontoControleRevisaoForm}
        schema={Schema}
        width="90%"
        maxHeight="90%"
        resources={resources}
        item={item}
        {...this.props}
        handleOnClose={this.state.idRevisao && this.handleOnClose}
        parameters={
          this.props.parameters
            ? this.props.parameters
            : {
                id: idRevisao,
                idRiscoPontoControle: idRiscoPontoControle,
              }
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    tiposAnexos: state.user.tiposAnexos,
  };
}
export default injectIntl(
  connect(mapStateToProps)(RiscoPontoControleRevisaoEdit)
);
