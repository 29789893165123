import React from 'react';
import { autobind } from 'core-decorators';
import { Popover, PopoverBody } from 'reactstrap';

import http from 'src/services/httpService';
import LoadingContainer from 'src/componentes/loading-container';
import css from 'src/utils/css';
import errorHandler from 'src/utils/error-handler';
import IndicadorEditModal from 'src/paginas/indicador/editModal';
import ProcessoEditModal from 'src/paginas/gestao-risco/processo/EditModal';
import ProjetoEditModal from 'src/paginas/projeto/editModal';
import ObjetivoEditModal from 'src/paginas/objetivo-estrategico/editModal';
import ContentManager from 'src/componentes/content-manager';
import ReactTooltip from 'react-tooltip';
import ProcessoEditView from 'src/paginas/gestao-risco/processo/edit';

const classes = css`
  .descricao {
    text-align: left;
    background-color: #fff;
  }

  .descricaoNegrito {
    color: #0000ff;
    text-align: left;
    background-color: #fff;
    font-weight: bold;
  }
`;

@autobind
class Hint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemImpactoHintId: 0,
      isLoadingHint: false,
      arvoreHintItemImpacto: null,
    };
  }

  handleMouseOut() {
    this.setState({ itemImpactoHintId: 0 });
  }

  async handleMouseOver(item, itemImpacto) {
    this.setState({
      isLoadingHint: true,
      itemImpactoHintId: item.id + itemImpacto.id,
    });

    try {
      var retorno = await http.post(`/Item/ObterHint`, {
        id: itemImpacto.id,
      });

      this.setState({ arvoreHintItemImpacto: retorno, isLoadingHint: false });
    } catch (error) {
      errorHandler(error);
      this.setState({
        isLoadingHint: false,
      });
    }
  }

  handleHintAcao(itens, itemId) {
    return itens.data.itens.map((itemImpacto, index) => (
      <tr key={index * 2}>
        <td
          className={
            itemImpacto.id == itemId
              ? classes.descricaoNegrito
              : classes.descricao
          }
          style={{ background: '#ffffff' }}
        >
          <span>
            <div
              style={{
                verticalAlign: 'middle',
                paddingLeft: itemImpacto.level * 20,
              }}
            >
              {itemImpacto.nome}
            </div>
          </span>
        </td>
      </tr>
    ));
  }

  handleModalClick(item) {
    switch (item.tipo.id) {
      case 1:
        return this.handleAbrirIndicadorClick(item);
      case 2:
        return null;
      case 3:
        return this.handleAbrirObjetivoEstrategicoClick(item);
      case 4:
        return this.handleAbrirProjetoClick(item);
      case 5:
        return this.handleAbrirProcessoClick(item);
    }
  }

  handleAbrirIndicadorClick(item) {
    ContentManager.addContent(
      <IndicadorEditModal showDelete={false} id={item.id} />
    );
  }

  handleAbrirProcessoClick(item) {
    ContentManager.addContent(
      <ProcessoEditView
        item={item}
        currenttabIndex={1}
        hideDelete={true}        
      />
    );
  }

  handleAbrirProjetoClick(item) {
    ContentManager.addContent(
      <ProjetoEditModal showDelete={false} id={item.id} />
    );
  }

  handleAbrirObjetivoEstrategicoClick(item) {
    ContentManager.addContent(
      <ObjetivoEditModal showDelete={false} id={item.id} />
    );
  }

  render() {
    let { item, itemImpacto, index = '' } = this.props;
    const id = 'itemImpacto' + item.id + itemImpacto.id + index;

    return (
      <div
        data-tip
        data-for={id}
        id={id}
        onMouseEnter={this.handleMouseOver.bind(this, item, itemImpacto)}
        onMouseLeave={this.handleMouseOut}
      >
        <a
          tabIndex="-1"
          onClick={this.handleModalClick.bind(this, itemImpacto)}
        >
          {itemImpacto.nome}
        </a>
        <ReactTooltip
          border
          type="light"
          effect="solid"
          id={id}
          aria-haspopup="true"
          place="right"
          style={{ opacity: '1 !important' }}
        >
          <LoadingContainer isLoading={this.state.isLoadingHint}>
            {this.state.arvoreHintItemImpacto &&
              this.handleHintAcao(
                this.state.arvoreHintItemImpacto,
                itemImpacto.id
              )}
          </LoadingContainer>
        </ReactTooltip>
      </div>
    );
  }
}

export default Hint;
