import React from 'react';
import { autobind } from 'core-decorators';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import RiscoDefinicaoLimitesEdit from './edit';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Panel from 'src/componentes/panel-collapse';
import { capitalizeFirstLetter } from 'src/utils/string';

@autobind
class IndicadorRiscoLimitesView extends React.Component {
  render() {
    const { disabled, intl } = this.props;
    return (
      <Panel header={capitalizeFirstLetter(intl.formatMessage({ id: 'definicaoLimites' }))}>
        <List
          editComponent={RiscoDefinicaoLimitesEdit}
          url="/IndicadorRiscoLimites"
          showExport={false}
          parameters={{
            indicadorId: this.props.id,
          }}
          showPage={false}
          showNew={!disabled}
          showDelete={!disabled}
          disabled={disabled}
          disabledEdit={disabled}

        >
          <ListColumn headerText={intl.formatMessage({ id: 'idIntegracao' })} sortField="idIntegracao" valueField="idIntegracao" required />
          <ListColumn headerText={intl.formatMessage({ id: 'dataInicio' })} sortField="dataInicio" valueField="dataInicio" required />
          <ListColumn headerText={intl.formatMessage({ id: 'dataTermino' })} sortField="dataTermino" valueField="dataTermino" required />
          <ListColumn headerText={intl.formatMessage({ id: 'tipoLimite' })} sortField="tipoLimite" valueField="tipoLimite" required />
          <ListColumn headerText={intl.formatMessage({ id: 'valorLimite' })} sortField="valor" valueField="valor" required />
        </List>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(IndicadorRiscoLimitesView));
