import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import CargoEdit from '../paginas/remuneracao-variavel/cargo/edit';

@autobind
class Cargo extends React.Component {
  render() {
    return <Lookup labelKey="nome" valueKey="id" url="/CargoLookup" {...this.props} />;
  }
}

export default Cargo;
