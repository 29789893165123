import React from 'react';
import { autobind } from 'core-decorators';
import { Badge, Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import List from '../../../componentes/list/tree';
import ListColumn from '../../../componentes/list/list-column';
import MdiIcon from '../../../componentes/mdi-icon';
import Search from './search';
import SearchModal from './search';
import SearchSchema from './search-schema';
import SearchSchemaModal from './search-schema-modal';
import {
  capitalizeFirstLetter,
  lowerCaseAllLetter,
} from '../../../utils/string';
import Dropdown from '../../item/dropdown';
import history from '../../../history';
import { getConfiguration } from 'src/services/commonRequests';
import ScoreAcumulado from 'src/componentes/score-acumulado/index';
import { formatNumber } from 'src/utils/number';
import AssistenteVirtual from 'src/components/IA/ChatGPT/AssistenteVirtual';
import ContentManager from 'src/componentes/content-manager';
import Button from 'src/componentes/button';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/errorHandler';
import { Checkbox } from 'src/componentes/Form';
import ReactTooltip from 'react-tooltip';
import EmbedPowerBI from 'src/paginas/power-bi/componentes/power-bi-dialog';
import RiscoEditView from 'src/paginas/gestao-risco/risco/RiscoEditView';
import ProcessoEditView from 'src/paginas/gestao-risco/processo/edit';
import { TIPO_INTERACAO_SOPHIE } from 'src/utils/constants';

@autobind
class Processo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: 0,
      consolidarSubItens: true,
    };
  }

  componentDidMount() {
    getConfiguration('ItemConfiguracaoRisco')((configuracao) =>
      this.setState({ ...this.state, configuracao })
    )();
    getConfiguration('ItemConfiguracaoProcesso')((configuracaoProcesso) =>
      this.setState({ ...this.state, configuracaoProcesso })
    )();
  }

  renderResponsavel(item) {
    return (
      <div>
        <p style={{ margin: '0 0 3px' }}>{item.area}</p>
        <p>{item.responsavel}</p>
      </div>
    );
  }

  renderNome(item, callback, refreshView) {
    if (!item.nome) {
      return;
    }

    return (
      <a
        tabIndex="-1"
        onClick={this.handleNomeClick.bind(this, item, callback, refreshView)}
        style={{ cursor: 'pointer' }}
      >
        {item.nome}
      </a>
    );
  }

  handleEditClick = (item, parameters, refreshView) => {
    ContentManager.addContent(
      <ProcessoEditView
        item={item}
        currenttabIndex={1}
        parameters={parameters}
        showDelete={true}
        handleClose={refreshView}
      />
    );
  };

  handleNomeClick(item, parameters, refreshView) {
    this.handleEditClick(item, parameters, refreshView);
  }

  renderStatus(item) {
    return (
      <Badge
        pill
        className="text-white"
        style={{ background: item.status.cor }}
      >
        {item.status.descricao}
      </Badge>
    );
  }

  renderLabelMenu() {
    return <MdiIcon icon="dots-vertical" />;
  }

  handleClickSophie(item) {
    const { user, intl, resources } = this.props;

    try {
      http
        .post('/Processo/ObterPromptIdentificarRiscos', {
          idProcesso: item?.id,
        })
        .then((response) => {
          const promptCausas = response.data?.promptIdentificarRiscos;
          const msgConsultoraVirtualActioDesvio = intl.formatMessage(
            {
              id: 'tituloSophieSugestaoRiscos',
            },
            { riscos: resources.riscos }
          );

          ContentManager.addContent(
            <AssistenteVirtual
              user={user}
              prompt={promptCausas}
              title={msgConsultoraVirtualActioDesvio}
              showCause={false}
              actionsToolBar={this.renderButtonCreateRisk()}
              interactionType={
                TIPO_INTERACAO_SOPHIE.SUGERIR_RISCOS_ITENS_IMPACTADOS
              }
            />
          );
        });
    } catch (err) {
      errorHandler(err);
    }
  }

  showCreateRisk() {
    ContentManager.addContent(<RiscoEditView mode="edit" {...this.props} />);
  }

  renderButtonCreateRisk() {
    return [
      <Button
        id={'botaoIdentificarRisco'}
        key={1}
        className="ml-2"
        onClick={() => this.showCreateRisk()}
        type="primary"
      >
        <MdiIcon icon="plus" />
        <span className="ml-2">
          {capitalizeFirstLetter(this.props.resources.riscos)}
        </span>
      </Button>,
    ];
  }

  renderMenu(item) {
    const { intl, resources } = this.props;

    return (
      <Dropdown
        item={item}
        exibirPowerBI={true}
        showModal={true}
        handleClose={this.refreshView}
        exibirInteligenciaArtificial={this.props.moduloInteligenciaArtificial}
        handleRelatorioBIClick={() => this.handleRelatorioBIClick(item)}
        exibirPlanoAcao={true}
        editComponent={ProcessoEditView}
        visaoItensImpactados={true}
        labelAI={intl.formatMessage(
          {
            id: 'labelIdentificarRiscos',
          },
          { riscos: resources.riscos }
        )}
        tooltipAI={intl.formatMessage(
          {
            id: 'msgToolTipIdentificarRiscosItensImpactados',
          },
          { riscos: resources.riscos }
        )}
        handleClickSophieCallBack={this.handleClickSophie}
        renderLabel={this.renderLabelMenu}
      ></Dropdown>
    );
  }

  renderScoreAcumulado({ id, scoreAcumuladoDoGrau }) {
    return (
      <ScoreAcumulado id={id} scoreAcumuladoDoGrau={scoreAcumuladoDoGrau} />
    );
  }

  renderMaiorGrauRisco({ id, maiorGrauRisco }) {
    return (
      <div className="d-flex w-100 justify-content-center">
        <div
          className="pl-2 pr-2 rounded-lg"
          style={{
            backgroundColor: maiorGrauRisco?.cor,
            color: 'white',
            whiteSpace: 'nowrap',
          }}
        >
          {maiorGrauRisco?.descricao}
        </div>
      </div>
    );
  }

  getListColumn(
    { resources, intl },
    habilitarCalculoDeScores,
    configuracao,
    configuracaoProcesso,
    refreshView
  ) {
    const list = [];
    list.push(
      <ListColumn
        tree={true}
        headerText={intl.formatMessage({ id: 'nome' })}
        required
        valueField="nome"
        sortField="UltimaVersao.Nome"
        valueFunction={(item) => this.renderNome(item, null, refreshView)}
        default
      />
    );
    list.push(
      <ListColumn
        headerText={intl.formatMessage({ id: 'codigo' })}
        tooltip="Código de Importação"
        valueField="idIntegracao"
        sortField="UltimaVersao.idIntegracao"
        width={100}
        default
      />
    );
    list.push(
      <ListColumn
        headerText={capitalizeFirstLetter(resources.responsaveis)}
        valueField="responsaveis"
        default
      />
    );
    habilitarCalculoDeScores &&
      list.push(
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'scoreAcumulado' },
            { risco: resources.risco.toLowerCase() }
          )}
          valueField="scoreAcumuladoDoGrau"
          valueFunction={this.renderScoreAcumulado}
          default
        />
      );
    list.push(
      <ListColumn
        headerText={intl.formatMessage({ id: 'exposicao' })}
        valueField="maiorGrauRisco"
        valueFunction={this.renderMaiorGrauRisco}
        visible={
          habilitarCalculoDeScores &&
          configuracaoProcesso.calcularNivelExposicaoProcesso
        }
      />
    );
    list.push(
      <ListColumn
        headerText={capitalizeFirstLetter(resources.area)}
        valueField="area"
        default
      />
    );
    list.push(
      <ListColumn
        headerText={intl.formatMessage({
          id: 'impactoFinanceiroResidualEstimado',
        })}
        sortField="impactoFinanceiroResidualEstimado"
        valueFunction={(item) =>
          item.impactoFinanceiroResidualEstimado ? (
            <div>
              {configuracao.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
              {formatNumber(item.impactoFinanceiroResidualEstimado, 2)}
            </div>
          ) : (
            <>-</>
          )
        }
        valueField="impactoFinanceiroResidualEstimado"
        visible={configuracao.acompanharImpactoFinanceiroEventoRisco}
      />
    );
    list.push(
      <ListColumn
        headerText={intl.formatMessage({
          id: 'impactoFinanceiroInerenteEstimado',
        })}
        sortField="impactoFinanceiroInerenteEstimado"
        valueFunction={(item) =>
          item.impactoFinanceiroInerenteEstimado ? (
            <div>
              {configuracao.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
              {formatNumber(item.impactoFinanceiroInerenteEstimado, 2)}
            </div>
          ) : (
            <>-</>
          )
        }
        valueField="impactoFinanceiroInerenteEstimado"
        visible={
          configuracao.avaliarRiscoInerente &&
          configuracao.acompanharImpactoFinanceiroEventoRisco
        }
      />
    );
    list.push(
      <ListColumn
        headerText={`
                  ${intl.formatMessage({
                    id: 'reducaoImpactoFinanceiroRealizado',
                  })} ${
          configuracao?.unidadeMedidaImpactoFinanceiro?.sigla
            ? `(${configuracao?.unidadeMedidaImpactoFinanceiro?.sigla})`
            : ''
        }`}
        sortField="reducaoImpactoFinanceiroRealizado"
        valueFunction={(item) =>
          item.reducaoImpactoFinanceiroRealizado ? (
            <div>
              {configuracao.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
              {formatNumber(item.reducaoImpactoFinanceiroRealizado, 2)}
            </div>
          ) : (
            <>-</>
          )
        }
        valueField="reducaoImpactoFinanceiroRealizado"
        visible={
          configuracao.avaliarRiscoInerente &&
          configuracao.acompanharImpactoFinanceiroEventoRisco
        }
      />
    );
    list.push(
      <ListColumn
        headerText={intl.formatMessage({
          id: 'reducaoImpactoFinanceiroPercentual',
        })}
        sortField="reducaoImpactoFinanceiroPercentual"
        valueFunction={(item) =>
          item.reducaoImpactoFinanceiroPercentual ? (
            <div>
              {formatNumber(item.reducaoImpactoFinanceiroPercentual, 2)} %
            </div>
          ) : (
            <>-</>
          )
        }
        valueField="reducaoImpactoFinanceiroPercentual"
        visible={
          configuracao.avaliarRiscoInerente &&
          configuracao.acompanharImpactoFinanceiroEventoRisco
        }
      />
    );
    return list;
  }

  setConsolidaAreasSubordinadas(value) {
    this.setState({
      consolidarSubItens: value,
      saved: ++this.state.saved,
    });
  }

  toggleConsolidarSubItens(intl, resources, handleChange) {
    if (this.props.showModal) return;

    return (
      <Row>
        <Col className="pt-3">
          <div className="d-flex align-items">
            <span className="mr-3">
              {intl?.formatMessage(
                { id: 'labelConsolidarRiscosSubItensSuperiores' },
                { riscos: lowerCaseAllLetter(resources.riscos) }
              )}
            </span>
            <div
              style={{ width: '10px' }}
              id="consolidaAreasSubordinadas"
              data-tip
              data-for="switch-consolidaAreasSubordinadas"
            >
              <Checkbox
                id="switch-consolidaAreasSubordinadas"
                type="switch"
                name="consolidaAreasSubordinadas"
                checked={this.state.consolidarSubItens}
                onChange={(e) => handleChange(e.target?.checked)}
              />
            </div>
          </div>
          <ReactTooltip
            className="w-25"
            id="switch-consolidaAreasSubordinadas"
            aria-haspopup="true"
          >
            <div className="text-center">
              {intl.formatMessage(
                { id: 'labelTooltipConsolidarRiscosSubItensSuperiores' },
                {
                  riscos: lowerCaseAllLetter(resources.riscos),
                  risco: lowerCaseAllLetter(resources.risco),
                }
              )}
            </div>
          </ReactTooltip>
        </Col>
      </Row>
    );
  }

  handleRelatorioBIClick(item) {
    http
      .post(`/PowerBI/ObterConfiguracoesPorTipoItem`, {
        itemId: item.id,
        tipoItemId: item.tipo?.id,
      })
      .then((response) => {
        if (item.desdobramento) {
          response.data.model.campo = null;
        }
        ContentManager.addContent(
          <EmbedPowerBI
            itemId={item.id}
            relatorioPBI={response.data.model.relatorioPBI}
            embedURL={response.data.model.embedURL}
            workspacePBI={response.data.model.workspacePBI}
            filtros={response.data.model.filtros}
            embedToken={response.data.model.embedToken}
            usarAutenticacaoMicrosoft={
              response.data.model.usarAutenticacaoMicrosoft
            }
          />
        );
        this.setState({
          isLoading: false,
          resources: {},
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });

    this.props.callback && this.props.callback();
    this.props.closePopover && this.props.closePopover();
  }

  refreshView = (saved) => {
    if (saved) {
      this.setState({ saved: ++this.state.saved });
    }
  };

  handleNewClick = (itemOrigem, tipoInclusao) => {
    ContentManager.addContent(
      <ProcessoEditView
        url="/gerenciar/ponto-controle"
        tipoInclusaoParams={tipoInclusao}
        itemOrigem={itemOrigem}
        handleClose={this.refreshView}
      />
    );
  };

  render() {
    let {
      resources,
      intl,
      params,
      showModal,
      hiddenConsolidarOption,
      filtroDefault,
    } = this.props;
    const { configuracao, configuracaoProcesso } = this.state;

    return (
      <div>
        {resources.area && configuracao && configuracaoProcesso && (
          <List
            url="/Processo"
            showCustomFields={true}
            tipoItem={5}
            sort="UltimaVersao.Nome"
            editComponent={ProcessoEditView}
            searchComponent={showModal ? <SearchModal /> : <Search />}
            searchSchema={showModal ? SearchSchemaModal : SearchSchema}
            exibirPowerBI={true}
            pageSize={999999}
            hierarchy={true}
            handleNewClick={this.handleNewClick}
            valueField="nome"
            filtroId={showModal ? 308 : 307}
            showSearch={true}
            tag={showModal ? 2012 : 2013}
            showDelete={true}
            saved={this.state.saved}
            renderMenu={this.renderMenu}
            showPagination={true}
            retirarTreeCamposCustomizados={true}
            selectedFilter={filtroDefault && { valor: filtroDefault }}
            modelValue={filtroDefault ? filtroDefault : null}
            customToolBar={
              !hiddenConsolidarOption &&
              this.toggleConsolidarSubItens(
                intl,
                resources,
                this.setConsolidaAreasSubordinadas
              )
            }
            {...this.props}
            parameters={{
              consolidaItensSubordinados:
                this.state.consolidarSubItens && !hiddenConsolidarOption,
              tag: showModal ? 2012 : 2013,
              ...params,
            }}
          >
            {this.getListColumn(
              this.props,
              configuracao.habilitarCalculoDeScores,
              configuracao,
              configuracaoProcesso,
              this.refreshView
            )}
          </List>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    user: state.user,
    moduloInteligenciaArtificial: state.user.moduloInteligenciaArtificial,
  };
}
export default injectIntl(connect(mapStateToProps)(Processo));
