export const getToken = async (instance) => {
  const activeAccount = instance.getActiveAccount();
  try {
    const { accessToken } = await instance.acquireTokenSilent({
      scopes: ['https://graph.microsoft.com/Calendars.ReadWrite', 'User.ReadBasic.All'],
      account: activeAccount,
    });
    return accessToken;
  } catch (error) {
    const { accessToken } = await instance.acquireTokenPopup({
      scopes: ['https://graph.microsoft.com/Calendars.ReadWrite', 'User.ReadBasic.All'],
    });
    return accessToken;
  }
};
