import Lookup from 'src/componentes/select2';

const RespostaOcorrenciaLookup = ({ ...rest }) => (
  <Lookup
    labelKey="nome"
    valueKey="id"
    url="/RespostaOcorrenciaLookup"
    clearable
    {...rest}
  />
);

export default RespostaOcorrenciaLookup;
