import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
@autobind
class FiltroAnexo extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/FiltroAnexoLookup" {...this.props} />;
  }
}

export default FiltroAnexo;
