import Lookup from 'src/componentes/select-async';
import AuditoriaList from 'src/paginas/gestao-risco/auditoria/AuditoriaRiscoList';

const AuditoriaRiscoLookup = ({...rest}) => {

  return (
    <Lookup
      labelKey="nome"
      valueKey="id"
      showSearch={true}
      showNew={false}
      url="/AuditoriaRiscoLookup"
      clearable
      searchComponent={AuditoriaList}
      {...rest}
    />
  );
};

export default AuditoriaRiscoLookup;