import { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import isDarkColor from 'src/utils/isDarkColor';
import guid from 'src/utils/guid';
import MdiIcon from './mdi-icon';

const ProgressBar = ({
  itens,
  bgColor,
  roundeSize,
  labelSizeClass = 'text-sm',
  handleClick = () => {},
  showBar = () => true,
  showOpacityBar = () => true,
  showBorder = true,
  containerHeight = '100%',
  containerWidth = '100%',
  hidePercentValue = 35,
  hidePercent = false,
  vertical = false,
  showLegenda = false,
  utilizarMarcador = false,
  showLabel = true,
  alertaMarcador,
  legendaMarcador,
  ajustarPercentualMarcador = 0,
  exibirValorMarcador = true,
  showTooltip = true,
  showDescription = false,
  showCursor = true,
}) => {
  const [componentId] = useState(guid());

  const renderMarcadorWithValue = (s, itemId) => {
    return (
      <div className="container p-0">
        <div
          className="flex-container p-0"
          style={{
            position: 'relative',
            top: '-18px',
            fontSize: '12px',
            padding: '0px',
            right: '7px',

          }}
        >
          {s.percentual && s.valorMarcador
            ? parseFloat(s.valorMarcador ? s.valorMarcador : s.percentualMarcador)
            : ''}
        </div>

          {s.percentual && s.valorMarcador || s.percentualMarcador > 0 ? (
            <div
              className="flex-item p-0"
              id={`marcador-${itemId}`}
              style={{
                width: '7px',
                height: '26px',
                padding: '0px',
                backgroundColor: '#4E555C',
                borderRadius: '24px',
                border: '1px solid #FFFFFF',
                marginTop: '-7px',
                right: '7px',
                position: 'relative',
                top: s.valorMarcador ? '-10px' : '0px',
              }}
            />

          ) : (
            <div
              className="flex-item p-0"
              id={`marcador-${itemId}`}
              
            />
          )}

      </div>
    );
  };

  const exibirMarcador = (s, exibirValorMarcador) => {
    return (
      !exibirValorMarcador ||
      (exibirValorMarcador && s?.valorMarcador != null && s?.valorMarcador >= 0)
    );
  };

  const renderColors = () =>
    itens &&
    itens.map((s, index) => {
      const itemId = `PROGRESS-${componentId}-${s.id}-${index}`;
      const showPercent =
        (s.percentual >= hidePercentValue && !hidePercent) || s.label;
      return (
        <>
          <div
            id={itemId}
            onClick={() => handleClick(s)}
            className={`align-items-center justify-content-center font-weight-bold ${labelSizeClass} ${
              showCursor ? 'cursor-pointer' : ''
            }`}
            style={{
              width: vertical ? '100%' : `${s.percentual}%`,
              height: vertical ? `${s.percentual}%` : '100%',
              borderRight: vertical
                ? 'none'
                : index + 1 === itens.length
                ? 'none'
                : '2px solid white',
              background: `${s.cor}`,
              display: showBar(s) ? 'flex' : 'none',
              opacity: showOpacityBar(s) ? 1 : 0.3,
              borderRadius: roundeSize ?? '',
            }}
          >
            {showPercent && (
              <span className={`${isDarkColor(s.cor) ? 'text-white' : ''}`}>
                {!showLabel ? '' : s.label ? s.label : `${s.percentual}%`}
              </span>
            )}
          </div>
          {(utilizarMarcador && !exibirValorMarcador) ||
            (exibirValorMarcador &&
              s?.valorMarcador != null &&
              s?.valorMarcador >= 0 &&
              alertaMarcador &&
              s.percentual < s.percentualMarcador && (
                <div
                  className="position-absolute d-flex"
                  style={{
                    width: `${s.percentualMarcador}%`,
                    height: '100%',
                    border: `2px dashed ${s.cor}`,
                    borderRadius: roundeSize ?? '',
                  }}
                />
              ))}
          {utilizarMarcador && (
            <div
              className="p-0 position-absolute  d-flex"
              style={{
                left: s.percentual
                  ? `${s.percentualMarcador - ajustarPercentualMarcador}%`
                  : 0,
              }}
            >
              {exibirValorMarcador ? (
                renderMarcadorWithValue(s, itemId)
              ) : ((
                <div
                  id={`marcador-${itemId}`}
                  style={{
                    width: '7px',
                    height: '26px',
                    backgroundColor: '#4E555C',
                    borderRadius: '24px',
                    border: '1px solid #FFFFFF',
                    marginTop: '-7px',
                  }}
                />
              ))}
              {alertaMarcador && exibirMarcador(s, exibirValorMarcador)  && s.percentual < s.percentualMarcador && (
                <div style={{ marginTop: '-6px' }}>
                  <MdiIcon
                    id={`alert-${itemId}`}
                    icon="alert-outline"
                    className="ml-2 text-warning"
                    size={22}
                  />
                  <UncontrolledTooltip
                    target={`alert-${itemId}`}
                    placement="bottom"
                  >
                    {alertaMarcador}
                  </UncontrolledTooltip>
                </div>
              )}
              {legendaMarcador && (
                <UncontrolledTooltip
                  target={`marcador-${itemId}`}
                  placement="bottom"
                >
                  {legendaMarcador.replace(
                    '{percent}',
                    s.percentualMarcador + '%'
                  )}
                </UncontrolledTooltip>
              )}
            </div>
          )}
          {showTooltip && (!showPercent || showLegenda) && (
            <UncontrolledTooltip target={itemId} placement="right">
              {`${s.percentual}% ${showLegenda && s.legenda ? s.legenda : ''}`}
            </UncontrolledTooltip>
          )}
        </>
      );
    });

  const renderDescriptions = () =>
    itens &&
    itens.map((s, index) => {
      const itemId = `DESCRIPTION-${componentId}-${s.id}`;

      return (
        <div
          id={itemId}
          className={`align-items-center justify-content-center font-weight-bold ${labelSizeClass} cursor-pointer`}
          style={{
            width: vertical ? '100%' : `${s.percentual}%`,
            display: 'flex',
          }}
        >
          {s.percentual > 0 && (
            <div className="d-flex flex-column align-items-center">
              <span className="font-weight-bolder">{s.descriptionTitle}</span>
              <span>{s.descriptionValue}</span>
            </div>
          )}
        </div>
      );
    });

  return (
    <>
      <div
        style={{
          display: vertical ? '' : 'flex',
          height: containerHeight,
          width: containerWidth,
          border: showBorder ? '1px solid #E5E5E5' : '',
          background: bgColor ?? '',
          borderRadius: roundeSize ?? '',
          position: 'relative',
          marginTop: utilizarMarcador ? '6px' : '',
        }}
      >
        {renderColors()}
      </div>

      {showDescription && (
        <div
          style={{
            display: 'flex',
            width: containerWidth,
            position: 'relative',
          }}
        >
          {renderDescriptions()}
        </div>
      )}
    </>
  );
};

export default ProgressBar;
