import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  responsavel: Schema.string(),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  areas: Schema.string().label(''),
  areasSubordinadas: Schema.string().label(''),
  status: Schema.string().label(<FormattedMessage id="status" />),
  checklist: Schema.string().label(<FormattedMessage id="labelChecklist" />),
});
