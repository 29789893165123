import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import css from 'src/utils/css';
import errorHandler from 'src/utils/error-handler';
import { autobind } from 'core-decorators';
import IdiomaLookup from 'src/lookups/idioma';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import Permissao from './componentes/permissao';
import PermissaoMO from './componentes/permissao-mo';
import { Col, Row, Popover, PopoverBody } from 'reactstrap';
import AvatarUsuario from './componentes/avatar-usuario';
import InputNumber from 'src/componentes/input-number';
import LoadingContainer from 'src/componentes/loading-container';
import MdiIcon from 'src/componentes/mdi-icon';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import TelaInicialLookup from 'src/lookups/tela-inicial';
import SalariosList from './salario';
import FuncoesList from './funcao';
import CondicionantesList from './condicionante';
import Bonus from './bonus';
import TipoAcessoLookup from 'src/lookups/tipo-acesso';
import SeparadorNumericoLookup from 'src/lookups/separador-numerico';
import http from 'src/services/httpService';
import HistoricoOcupacaoList from 'src/paginas/colaborador/HistoricoOcupacao';
import DatePicker from 'src/componentes/date-picker';
import schema from './Schema';
import { COLABORADOR_CAMPOS } from 'src/utils/constants';
import InformacoesComplementares from './InformacoesComplementares';
import Usuario from './usuario';
import FusoHorarioLookup from 'src/lookups/FusoHorarioLookup';
import ReactTooltip from 'react-tooltip';

const classes = css`
  .descricao {
    text-align: left;
    background-color: #fff;
  }

  .descricaoNegrito {
    color: #0000ff;
    text-align: left;
    background-color: #fff;
    font-weight: bold;
  }
`;
@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cropResult: null,
      mensagemErro: '',
      corMensagemErro: '',
      exibirClassificacao: false,
      isLoadingHintHistorico: false,
      colaboradorId: 0,
      historicoArea: null,
      permissaoInformacoesComplementares: false,
      selectedFieldDate: ['dataNascimento', 'dataAdmissao', 'dataDesligamento'],
    };
  }

  componentDidMount() {
    http.post(`/CentroCusto/ObterConfiguracaoSistema`, {}).then((response) => {
      this.setState({
        exibirClassificacao: response.data.exibirClassificacao,
      });
    });
    window.addEventListener('onbeforeunload', this.VerificaArquivoFoto());
    this.obterPermissaoInformacoesComplementares();
  }

  componentWillUnmount() {
    window.removeEventListener('onbeforeunload', this.VerificaArquivoFoto());
  }

  async VerificaArquivoFoto() {
    const model = this.props.model;

    if (model && model.getModel('fotoArquivo').value) {
      const colaboradorId = model.getModel('id') && model.getModel('id').value;
      const arquivoId = model.getModel('fotoArquivo').value.id;

      try {
        var retorno = await http.post(`/Colaborador/VerificaArquivoFoto`, {
          colaboradorId: colaboradorId,
          arquivoId: arquivoId,
        });
      } catch (error) {
        errorHandler(error);
      }
    }
  }

  async obterPermissaoInformacoesComplementares() {
    try {
      const response = await http.get(
        'Colaborador/ObterPermissaoInformacoesComplementares'
      );
      this.setState({
        permissaoInformacoesComplementares: response.data,
      });
    } catch (err) {
      errorHandler(err);
    }
  }

  async handleMouseOver(colaboradorId) {
    this.setState({
      isLoadingHintHistorico: true,
      colaboradorId: colaboradorId,
    });

    try {
      var retorno = await http.post(`/Colaborador/ObterHistoricoArea`, {
        colaboradorId: colaboradorId,
      });

      this.setState({ historicoArea: retorno, isLoadingHintHistorico: false });
    } catch (error) {
      errorHandler(error);
      this.setState({
        isLoadingHintHistorico: false,
      });
    }
  }

  handleMouseOut() {
    this.setState({ colaboradorId: 0, isLoadingHintHistorico: false });
  }

  handleHintHistoricoArea(historicoArea) {
    const { intl } = this.props;
    return (
      <div>
        <tr>
          <th
            className={classes.descricaoNegrito}
            width={'100px'}
            style={{ textAlign: 'left', background: '#ffffff' }}
          >
            <span>
              <div
                style={{
                  verticalAlign: 'middle',
                }}
              >
                {' '}
                {intl.formatMessage({ id: 'dataInicio' })}
              </div>
            </span>
          </th>
          <th
            className={classes.descricaoNegrito}
            width={'100px'}
            style={{ textAlign: 'left', background: '#ffffff' }}
          >
            <span>
              <div
                style={{
                  verticalAlign: 'middle',
                }}
              >
                {' '}
                {intl.formatMessage({ id: 'dataTermino' })}
              </div>
            </span>
          </th>
          <th
            className={classes.descricaoNegrito}
            width={'200px'}
            style={{ textAlign: 'left', background: '#ffffff' }}
          >
            <span>
              <div
                style={{
                  verticalAlign: 'middle',
                }}
              >
                {'Área'}
              </div>
            </span>
          </th>
        </tr>
        {this.handleTabelaHintHistoricoArea(historicoArea)}
      </div>
    );
  }

  handleTabelaHintHistoricoArea(historicoArea) {
    return historicoArea.data.list.map((historico, index) => (
      <div>
        <tr key={index * 2}>
          <th
            className={classes.descricao}
            width={'100px'}
            style={{ textAlign: 'left', background: '#ffffff' }}
          >
            <span>
              <div
                style={{
                  verticalAlign: 'middle',
                }}
              >
                {historico.dataInicioFormatada}
              </div>
            </span>
          </th>
          <th
            className={classes.descricao}
            width={'100px'}
            style={{ textAlign: 'left', background: '#ffffff' }}
          >
            <span>
              <div
                style={{
                  verticalAlign: 'middle',
                }}
              >
                {historico.dataTerminoFormatada}
              </div>
            </span>
          </th>
          <th
            className={classes.descricao}
            width={'200px'}
            style={{ textAlign: 'left', background: '#ffffff' }}
          >
            <span>
              <div
                style={{
                  verticalAlign: 'middle',
                }}
              >
                {historico.nomeOrganizacao}
              </div>
            </span>
          </th>
        </tr>
      </div>
    ));
  }

  isVisible(campoId) {
    const campos = this.props.model.getModel('campos').value;

    if (campos) {
      const campo = campos.find((c) => c.campoId === campoId);
      return campo.visivel;
    }

    return true;
  }

  handleErrorRequired() {
    this.setState({ tabError: 0 });
  }

  requiredTabError() {
    let tabsError = [];

    if (this.hasErrorTab(0)) tabsError.push(0);
    if (this.hasErrorTab(1)) tabsError.push(1);

    return tabsError;
  }

  hasErrorTab(tabIndex) {
    const { model } = this.props;

    if (!model) return false;

    if (tabIndex == 0) {
      return (
        model.getModel('nome').errors ||
        model.getModel('idIntegracao').errors ||
        model.getModel('unidadeArea').errors ||
        model.getModel('celular').errors ||
        model.getModel('telefone').errors ||
        model.getModel('emailExterno').errors ||
        model.getModel('dataAdmissao').errors
      );
    }

    if (tabIndex == 1) {
      return (
        model.getModel('nomeSocial').errors ||
        model.getModel('genero').errors ||
        model.getModel('raca').errors ||
        model.getModel('genero').errors ||
        model.getModel('orientacaoSexual').errors ||
        model.getModel('interesseOutrasAreas').errors ||
        model.getModel('disponivelParaMudanca').errors ||
        model.getModel('autorizacaoDivulgacaoAniversario').errors ||
        model.getModel('linkedin').errors
      );
    }
  }

  isRequired(campoId, modelParam) {
    if (!modelParam && !this.form) return false;

    const model = modelParam ? modelParam : this.form.getModel();
    const campos = model.getModel('campos').value;

    if (campos) {
      const campo = campos.find((c) => c.campoId === campoId);
      return campo.obrigatorio;
    }

    return true;
  }

  handleSelectDate(fieldName) {
    this.setState((prevState) => ({
      selectedFieldDate: this.state.selectedFieldDate.filter(
        (item) => item !== fieldName
      ),
    }));
  }

  render() {
    let { model, resources, moduloAtual, intl, modulosComprados } = this.props;
    let exibirClassificacao = this.state.exibirClassificacao;

    let permissions = model.getModel('permissions').value;

    let id = model.getModel('id') && model.getModel('id').value;

    const disabled = !model.getModel('aceitouTermosUso').value || id == 0;
    const disabledUsuarioCunsultaExterna =
      model.getModel('idIntegracao').value === 'CONSULTAEXTERNA';

    if (
      model.getModel('handleErrorRequired')?.value == '1' &&
      this.state.tabError != '0'
    )
      this.handleErrorRequired();

    const possuiModuloLIFT = modulosComprados.find(
      (m) => m.id === 4 && m.exibir
    );
    const possuiModuloMatrizOrcamentaria = modulosComprados.find(
      (m) => m.id === 2 && m.exibir
    );

    return (
      <Tabs defaultActiveKey="1" tabsError={this.requiredTabError()}>
        <Tab
          label={intl.formatMessage({ id: 'label.resumo' })}
          key="1"
          id="tableResumo"
        >
          <Row>
            <Col md={3}>
              <AvatarUsuario
                disabled={disabled}
                usuarioId={id}
                model={model.getModel('fotoArquivo')}
                label={model.getModel('nome')}
              />
            </Col>
            <Col md={9}>
              <Row>
                <Col md={6}>
                  <Input
                    disabled={disabledUsuarioCunsultaExterna}
                    autoFocus
                    model={model.getModel('nome')}
                    required
                  />
                </Col>
                {!disabledUsuarioCunsultaExterna && (
                  <Col md={6}>
                    <Input
                      disabled={disabledUsuarioCunsultaExterna}
                      model={model.getModel('idIntegracao')}
                      required
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={6}>
                  <ItemOrganizacaoLookup
                    model={{
                      label: (
                        <span
                          onMouseEnter={this.handleMouseOver.bind(this, id)}
                          id={'historico' + id}
                          onMouseLeave={this.handleMouseOut}
                        >
                          {capitalizeFirstLetter(resources.area)}
                          <MdiIcon
                            style={{ cursor: 'default' }}
                            icon="information-outline"
                            id={'historicoIcon' + id}
                          />
                          <Popover
                            placement="auto"
                            isOpen={id != 0 && this.state.colaboradorId == id}
                            target={'historico' + id}
                          >
                            <PopoverBody style={{ width: 500 }}>
                              <LoadingContainer
                                isLoading={this.state.isLoadingHintHistorico}
                              >
                                {this.state.historicoArea &&
                                  this.handleHintHistoricoArea(
                                    this.state.historicoArea
                                  )}
                              </LoadingContainer>
                            </PopoverBody>
                          </Popover>
                        </span>
                      ),
                      value: model.getModel('unidadeArea').value,
                      requestChange:
                        model.getModel('unidadeArea').requestChange,
                      errors: model.getModel('unidadeArea').errors,
                    }}
                    clearable={false}
                    required
                    data={{ tiposIdSelecionaveis: [1, 2] }}
                  />
                </Col>
                <Col md={6}>
                  <TipoAcessoLookup
                    model={{
                      label: intl.formatMessage(
                        { id: 'label.permissaoDemaisAreas' },
                        { areas: resources.areas }
                      ),
                      value: model.getModel('permissaoDemaisAreas').value,
                      requestChange: model.getModel('permissaoDemaisAreas')
                        .requestChange,
                    }}
                    clearable={true}
                  ></TipoAcessoLookup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <TelaInicialLookup
                    model={model.getModel('telaInicial')}
                    clearable
                  />
                </Col>
                {possuiModuloMatrizOrcamentaria && (
                  <Col md={6}>
                    <TipoAcessoLookup
                      model={{
                        label: intl.formatMessage(
                          { id: 'label.permissaoDemaisCentrosDeCusto' },
                          { centroscusto: resources.centroscusto }
                        ),
                        value: model.getModel('permissaoDemaisCentrosDeCusto')
                          .value,
                        requestChange: model.getModel(
                          'permissaoDemaisCentrosDeCusto'
                        ).requestChange,
                      }}
                      clearable={true}
                    ></TipoAcessoLookup>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={3}>
                  <SeparadorNumericoLookup
                    model={model.getModel('separadorMilhar')}
                    required
                  />
                </Col>
                <Col md={3}>
                  <SeparadorNumericoLookup
                    model={model.getModel('separadorDecimal')}
                    required
                  />
                </Col>
                <Col md={6}>
                  <IdiomaLookup model={model.getModel('idioma')}></IdiomaLookup>
                </Col>
                <Col md={12}>
                  <FusoHorarioLookup
                    model={model.getModel('fusoHorario')}
                  ></FusoHorarioLookup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputNumber
                    disabled={disabled}
                    model={model.getModel('ramal')}
                  />
                </Col>
                {this.isVisible(COLABORADOR_CAMPOS.CELULAR) && (
                  <Col md={4}>
                    <Input
                      disabled={disabled}
                      required={this.isRequired(COLABORADOR_CAMPOS.CELULAR)}
                      model={{
                        label: intl.formatMessage({ id: 'celular' }),
                        value: this.state.celular
                          ? this.state.celular
                          : model.getModel('celular').value,
                        requestChange: model.getModel('celular').requestChange,
                        errors: model.getModel('celular').errors,
                      }}
                    />
                  </Col>
                )}
                {this.isVisible(COLABORADOR_CAMPOS.TELEFONE) && (
                  <Col md={4}>
                    <Input
                      disabled={disabled}
                      required={this.isRequired(COLABORADOR_CAMPOS.TELEFONE)}
                      model={model.getModel('telefone')}
                    />
                  </Col>
                )}
              </Row>
              {possuiModuloLIFT && (
                <React.Fragment>
                  <Row>
                    {this.isVisible(COLABORADOR_CAMPOS.EMAIL) && (
                      <Col md={6}>
                        <Input
                          disabled={disabled}
                          required={this.isRequired(COLABORADOR_CAMPOS.EMAIL)}
                          type="email"
                          model={model.getModel('emailExterno')}
                        />
                      </Col>
                    )}
                    <Col md={6}>
                      <DatePicker
                        disabled={disabled}
                        timeFormat={false}
                        model={model.getModel('dataNascimento')}
                        autoFormatDate={true}
                        onFocus={() => this.handleSelectDate('dataNascimento')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <DatePicker
                        required={possuiModuloLIFT}
                        timeFormat={false}
                        model={model.getModel('dataAdmissao')}
                        autoFormatDate={true}
                        onFocus={() => this.handleSelectDate('dataAdmissao')}
                      />
                    </Col>
                    <Col md={6} className="d-flex">
                      <DatePicker
                        style={{ width: '100%' }}
                        timeFormat={false}
                        model={model.getModel('dataDesligamento')}
                        autoFormatDate={true}
                        onFocus={() =>
                          this.handleSelectDate('dataDesligamento')
                        }
                        disableFutureDates={true}
                      />
                      <MdiIcon
                        id="dataDesligamento"
                        data-tip
                        data-for='dataDesligamento'
                        icon="alert-outline"
                        style={{ left: '160px' }}
                        className="position-absolute text-warning"
                      />
                      <ReactTooltip id='dataDesligamento' aria-haspopup="true">
                        {intl.formatMessage({
                          id: 'alertaDataDesligamentoPosDiaAtualNaoPermitida',
                        })}
                      </ReactTooltip>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Tab>
        {this.state.permissaoInformacoesComplementares && (
          <Tab
            label={<FormattedMessage id="informacoesComplementares" />}
            key="3"
            hasRequiredField={this.hasErrorTab(1)}
          >
            <InformacoesComplementares
              disabled={disabled}
              model={model}
              isVisible={this.isVisible}
              isRequired={(f, model) => this.isRequired(f, model)}
            />
          </Tab>
        )}
        {permissions != null && permissions.allowEditUsuario && (
          <Tab label={<FormattedMessage id="usuario" />} key="2">
            <Col md={12}>
              <Usuario
                disabled={disabledUsuarioCunsultaExterna}
                model={model}
                modulos={modulosComprados.filter((x) => x.exibir)}
              />
            </Col>
          </Tab>
        )}
        {id > 0 && permissions != null && permissions.allowEditPermissions && (
          <Tab
            label={
              <FormattedMessage
                id="label.permissoesArea"
                values={{ area: resources.area }}
              />
            }
            key="4"
          >
            <Row>
              <Col md={12}>
                <Permissao
                  allowEditPermissions={
                    permissions != null && permissions.allowEditPermissions
                  }
                  tipo={1}
                  colaboradorId={id}
                  urlLoad="/Colaborador/ObterPermissoesArea"
                  urlSave="/Colaborador/AlterarPermissaoArea"
                  urlReply="/Colaborador/ReplicarPermissaoArea"
                  columns={[
                    { headerText: capitalizeFirstLetter(resources.area) },
                    { headerText: <FormattedMessage id="label.acesso" /> },
                  ]}
                />
              </Col>
            </Row>
          </Tab>
        )}
        {
          //Remuneração variável
          id > 0 &&
            moduloAtual.id === 3 &&
            permissions != null &&
            permissions.allowEditSalarios && (
              <Tab
                label={
                  <FormattedMessage
                    id="salarios"
                    values={{ salarios: resources.salarios }}
                  />
                }
                key="6"
              >
                <SalariosList model={model} />
              </Tab>
            )
        }
        {
          //Remuneração variável
          id > 0 &&
            moduloAtual.id === 3 &&
            permissions != null &&
            permissions.allowEditHistoricoFuncoes && (
              <Tab
                label={intl.formatMessage(
                  { id: 'label.historicoDeFuncoes' },
                  { funcoes: resources.funcoes }
                )}
                key="6"
              >
                <FuncoesList model={model} />
              </Tab>
            )
        }

        {
          //Remuneração variável
          id > 0 &&
            moduloAtual.id === 3 &&
            permissions != null &&
            permissions.allowEditCondicionantes && (
              <Tab
                label={capitalizeFirstLetter(resources.condicionantes)}
                key="7"
              >
                <CondicionantesList model={model} />
              </Tab>
            )
        }
        {
          //Remuneração variável
          id > 0 &&
            moduloAtual.id === 3 &&
            permissions != null &&
            permissions.allowEditBonus && (
              <Tab label={capitalizeFirstLetter(resources.bonus)} key="7">
                <Bonus model={model} />
              </Tab>
            )
        }
        {
          //Matriz orçamentária
          id > 0 &&
            moduloAtual.id === 2 &&
            permissions != null &&
            permissions.allowEditPermissionsCentroCusto && (
              <Tab
                label={
                  <FormattedMessage
                    id="label.permissoesCentroCusto"
                    values={{ centrocusto: resources.centrocusto }}
                  />
                }
                key="4"
              >
                <Row>
                  <Col md={12}>
                    <PermissaoMO
                      exibirClassificacao={exibirClassificacao}
                      allowEditPermissions={
                        permissions != null &&
                        permissions.allowEditPermissionsCentroCusto
                      }
                      tipo={2}
                      colaboradorId={id}
                      urlLoad="/Colaborador/ObterPermissoesCentroCusto"
                      urlSave="/Colaborador/AlterarPermissaoCentroCusto"
                      urlReply="/Colaborador/ReplicarPermissaoCentroCusto"
                      columns={[
                        {
                          headerText: (
                            <FormattedMessage
                              id="centroDeCusto"
                              values={{ centrocusto: resources.centrocusto }}
                            />
                          ),
                        },
                        { headerText: <FormattedMessage id="label.acesso" /> },
                      ]}
                    />
                  </Col>
                </Row>
              </Tab>
            )
        }
        {
          //Matriz orçamentária
          id > 0 &&
            moduloAtual.id === 2 &&
            permissions != null &&
            permissions.allowEditPermissionsPacote && (
              <Tab
                label={<FormattedMessage id="label.permissoesPacote" />}
                key="5"
              >
                <Row>
                  <Col md={12}>
                    <PermissaoMO
                      exibirClassificacao={exibirClassificacao}
                      allowEditPermissions={
                        permissions != null &&
                        permissions.allowEditPermissionsPacote
                      }
                      tipo={3}
                      colaboradorId={id}
                      urlLoad="/Colaborador/ObterPermissoesPacote"
                      urlSave="/Colaborador/AlterarPermissaoPacote"
                      urlReply="/Colaborador/ReplicarPermissaoPacote"
                      columns={[
                        { headerText: <FormattedMessage id="pacoteConta" /> },
                        { headerText: <FormattedMessage id="label.acesso" /> },
                      ]}
                    />
                  </Col>
                </Row>
              </Tab>
            )
        }
        {
          //Avaliação desempenho
          id > 0 && possuiModuloLIFT && (
            <Tab
              label={capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'historicoCargos' },
                  { cargos: resources.cargos }
                )
              )}
              key="8"
            >
              <Row>
                <Col md={12}>
                  <HistoricoOcupacaoList
                    dataValue={model.getModel('historicoOcupacao')}
                    parameters={{ colaboradorId: model.value.id }}
                  />
                </Col>
              </Row>
            </Tab>
          )
        }
      </Tabs>
    );
  }
}

class ColaboradorEdit extends React.Component {
  render() {
    let { resources, colaboradorGestao } = this.props;

    return (
      <Edit
        url="/Colaborador"
        title={capitalizeFirstLetter(resources.colaborador)}
        formComponent={FormEdit}
        showExportPDF={false}
        showExportExcel={false}
        urlExport="/Colaborador/ExportarColaborador"
        parameters={{ colaboradorGestao: colaboradorGestao }}
        schema={schema}
        width={900}
        height={700}
        handleErrorRequired={true}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloAtual: state.user.moduloAtual,
    modulosComprados: state.user.modulosDisponiveis,
  };
}
export default connect(mapStateToProps)(ColaboradorEdit);
