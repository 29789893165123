import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Input from 'src/componentes/input';
import MdiIcon from 'src/componentes/mdi-icon';
import Button from 'src/componentes/button';

const CincoPorques = ({ model, handleChangeData, allowEdit }) => {
  const intl = useIntl();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(model || []);
  }, [model]);

  const renderContent = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        {data &&
          data.map((c, index) => (
            <div key={`causa-${index}`} className="max-w-50 w-50">
              {renderCausa(c, c.level, c.motivos ? c.motivos.length : 0, index)}
              <br />
            </div>
          ))}
      </div>
    );
  };

  const renderCausa = (causa, level = 0, levelMaxCorrente = 0, indexCausa) => {
    return [
      <div key={`motivo-${level}`}>
        {
          <div className="item" style={{ marginLeft: level * 20 }}>
            <div className="actions">
              {level === levelMaxCorrente && level < 5 && allowEdit && (
                <Button
                  onClick={() => handleAdd(indexCausa, level, causa)}
                  leftIcon="plus-circle"
                  color="primary"
                  tabIndex="-1"
                  title={intl.formatMessage({ id: 'adicionarPorque' })}
                />
              )}
              {level > 0 && allowEdit && (
                <Button
                  onClick={() => handleDelete(indexCausa, level)}
                  leftIcon="delete"
                  color="secondary"
                  tabIndex="-1"
                />
              )}
            </div>
            <div className="editor">
              <Input
                disabled={causa.favoritado || !allowEdit}
                className={level === 0 ? 'font-weight-bolder' : ''}
                model={{
                  label: '',
                  value: causa.descricao,
                  requestChange: (v) =>
                    handleChangeDescricao(v, indexCausa, level),
                }}
              />
            </div>
            {level === levelMaxCorrente && (
              <MdiIcon
                onClick={() => handleFavorite(indexCausa, level)}
                className="btn-analise-causa text-primary cursor-pointer"
                size={24}
                icon={causa.favoritado ? 'star' : 'star-outline'}
              />
            )}
          </div>
        }
      </div>,
      causa.motivos &&
        causa.motivos.map((motivo) => {
          return renderCausa(
            motivo,
            motivo.level,
            levelMaxCorrente,
            indexCausa
          );
        }),
    ];
  };

  const handleAdd = (indexCausa, level, causa) => {
    const newList = Object.assign([], data);
    const newItem = newList[indexCausa];
    newItem.motivos.push({
      descricao: '',
      favoritado: false,
      level: level + 1,
    });

    if (causa.favoritado) {
      if (level === 0) {
        newItem.favoritado = false;
      } else {
        newList[indexCausa].motivos[level - 1].favoritado = false;
      }
    }

    setData(newList);

    handleSave();
  };

  const handleDelete = (indexCausa, level) => {
    const newList = Object.assign([], data);

    const newItem = newList[indexCausa];

    if (newItem.motivos && newItem.motivos.length > 0) {
      var quantidadeMotivos = newItem.motivos.length;
      for (var i = quantidadeMotivos; i >= level; i--) {
        newItem.motivos.splice(i - 1, 1);
      }

      setData(newList);

      handleSave();
    }
  };

  const handleFavorite = (indexCausa, level) => {
    if (allowEdit) {
      const newList = Object.assign([], data);

      if (level === 0) {
        if (
          newList[indexCausa].descricao === null ||
          newList[indexCausa].descricao === ''
        )
          return;

        newList[indexCausa].favoritado = !newList[indexCausa].favoritado;
      } else {
        if (
          newList[indexCausa].motivos[level - 1].descricao === null ||
          newList[indexCausa].motivos[level - 1].descricao === ''
        )
          return;

        newList[indexCausa].motivos[level - 1].favoritado =
          !newList[indexCausa].motivos[level - 1].favoritado;
      }

      setData(newList);

      handleSave();
    }
  };

  const handleChangeDescricao = (value, indexCausa, level) => {
    const newList = Object.assign([], data);
    if (level === 0) {
      newList[indexCausa].descricao = value;
    } else {
      newList[indexCausa].motivos[level - 1].descricao = value;
    }

    setData(newList);

    handleSave();
  };

  const handleSave = () => {
    handleChangeData &&
      handleChangeData('step4Data', 'cincoPorquesModel', data);

    handleChangeData && handleChangeData('step4Data', 'alterado', true);
  };

  return renderContent();
};

export default CincoPorques;
