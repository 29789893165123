import React from 'react';
import { autobind } from 'core-decorators';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

@autobind
class Toastify extends React.Component {
  renderToast(autoClose) {
    let { type, message, position, onClose } = this.props;

    if (toast.isActive(1)) return;

    if (type == 'info')
      toast.info(message, {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        onClose: onClose,
        toastId: 1,
      });
    else if (type == 'warning')
      toast.warning(message, {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        onClose: onClose,
        toastId: 1,
      });
    else if (type == 'success')
      toast.success(message, {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        onClose: onClose,
        toastId: 1,
      });
    else if (type == 'error')
      toast.error(message, {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        onClose: onClose,
        toastId: 1,
      });
  }

  render() {
    let { autoClose } = this.props;

    return (
      <div style={{ textColor: 'transparent' }}>
        <ToastContainer autoClose={autoClose} transition={Slide} />
        {this.renderToast(autoClose)}
      </div>
    );
  }
}

export default Toastify;
