import { useIntl } from 'react-intl';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import useAuth from 'src/hooks/useAuth';
import FormEdit from './edit';

const GatilhoList = ({ ...rest }) => {
  const { user } = useAuth();
  const intl = useIntl();
  const { disabled } = rest;

  return (
    <List
      {...rest}
      showTitle={true}
      title={intl.formatMessage({ id: 'gatilhos' })}
      url="/CestaIndicadorGatilho"
      editComponent={FormEdit}
      parameters={{ CestaIndicadorId: rest.model.getModel('id').value }}
      showSearch={false}
      showNew={!disabled}
      showMenu={!disabled}
      sort="Id asc"
    >
      <ListColumn
        headerText={intl.formatMessage({ id: 'gatilho' })}
        valueField="gatilho"
      />
    </List>
  );
};

export default GatilhoList;
