import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'reactstrap';
import { format } from 'date-fns';

import formatDateFns from 'src/utils/formatDateFns';
import useAuth from 'src/hooks/useAuth';
import LoadingContainer from 'src/componentes/loading-container';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import Drawer from 'src/componentes/drawer';

const Historic = ({ model, open, setOpen }) => {
  const intl = useIntl();

  const { user } = useAuth();
  const { currentDisplay, currentLocale } = formatDateFns(user?.idioma, true);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await http.post('Projeto/ObterHistoricoBaseline', {
        projetoId: model.getModel('id').value,
      });

      setData(response.data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) getData();
  }, [open]);

  const renderDate = (data) =>
    format(data, currentDisplay, { locale: currentLocale });

  const renderDates = ({ baselineInicial, baselineTermino }) =>
    `${renderDate(baselineInicial)} ${intl.formatMessage({
      id: 'a',
    })} ${renderDate(baselineTermino)}`;

  const renderGeneratedBy = ({ colaborador, dataHistorico }) =>
    `${colaborador?.nome} ${intl.formatMessage({
      id: 'em',
    })} ${renderDate(dataHistorico)}`;

  return (
    <Drawer open={open} close={() => setOpen(false)}>
      <LoadingContainer isLoading={isLoading}>
        <div className="mb-3 text-primary text-lg font-weight-bolder">
          {intl.formatMessage({ id: 'historicoBaselines' })}
        </div>
        {data && (
          <Table>
            <thead>
              <th>#</th>
              <th>{intl.formatMessage({ id: 'data' })}</th>
              <th>{intl.formatMessage({ id: 'geradaPor' })}</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{renderDates(item)}</td>
                  <td>{renderGeneratedBy(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </LoadingContainer>
    </Drawer>
  );
};

export default Historic;
