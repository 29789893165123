import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Dialog from '../../../../../componentes/dialog';
import Button from '../../../../../componentes/button';
import Panel from '../../../../../componentes/panel-collapse';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import ItemList from '../../../../../paginas/item';
import http from 'src/services/httpService';
import ContentManager from 'src/componentes/content-manager';
import Alert from 'src/componentes/message-box/alert';
import { MODULOS } from 'src/utils/constants';

@autobind
class RiscoItensAssociadosList extends React.Component {
  constructor(props) {
    super(props);

    const { model } = this.props;

    this.state = {
      indicadoresRisco: model.value
        ? model.value.filter((i) => i.tipo.id == 17)
        : [],
      indicadores: model.value ? model.value.filter((i) => i.tipo.id == 1) : [],
      processos: model.value ? model.value.filter((i) => i.tipo.id == 5) : [],
      projetos: model.value ? model.value.filter((i) => i.tipo.id == 4) : [],
      objetivos: model.value ? model.value.filter((i) => i.tipo.id == 3) : [],
      isLoading: false,
    };
  }

  handleOkClick() {
    this.handleUpdateModel();
    this.refs.dialog.close();
  }

  handleCancelarClick() {
    this.refs.dialog.close();
  }

  handleUpdateModel() {
    const { indicadores, indicadoresRisco, processos, projetos, objetivos } =
      this.state;

    this.props.model.requestChange([
      ...indicadoresRisco,
      ...indicadores,
      ...processos,
      ...projetos,
      ...objetivos,
    ]);
  }

  async handleCustomSelectSearch(selecteds, tipoId) {
    const itens = selecteds ? selecteds : [];

    switch (tipoId) {
      case 1: {
        this.setState({ indicadores: itens });
        break;
      }
      case 5: {
        this.setState({isLoading: true});
        const risco = this.props.risco;
        const addedProcesso = itens.find(
          (item) => !this.state.processos.includes(item)
        );
        this.setState({ processos: itens });
        if (addedProcesso && risco != null && risco.matrizRisco != null) {
          const resultMatriz = await http.post(
            '/Processo/ObterMatrizProcesso',
            {
              idProcesso: addedProcesso?.id,
            }
          );

          if (
            resultMatriz &&
            resultMatriz.data &&
            resultMatriz.data.id != risco.matrizRisco.id
          ) {
            ContentManager.addContent(
              <Alert
                message={this.props.intl.formatMessage(
                  {
                    id: 'processoSelecionadoTemUmaMatrizDiferenteDoRiscoAtual',
                  },
                  {
                    risco: this.props.resources.risco,
                    processo: this.props.resources.processo,
                  }
                )}
              />
            );
          }
        }
        this.setState({ isLoading: false });
        break;
      }
      case 4: {
        this.setState({ projetos: itens });
        break;
      }
      case 3: {
        this.setState({ objetivos: itens });
        break;
      }
      case 17: {
        this.setState({ indicadoresRisco: itens });
        break;
      }
    }
  }

  handleGetList(tipoId) {
    const { indicadores, indicadoresRisco, processos, projetos, objetivos } =
      this.state;

    switch (tipoId) {
      case 1:
        return indicadores;
      case 5:
        return processos;
      case 4:
        return projetos;
      case 3:
        return objetivos;
      case 17:
        return indicadoresRisco;
    }
  }

  handleList(tipoId, hierarchy = true, pageSize = 10) {
    const list = this.handleGetList(tipoId) || [];

    return (
      <ItemList
        multiple
        select
        selectRealTime
        selectedCallback={(itens) =>
          this.handleCustomSelectSearch(itens, tipoId)
        }
        selectedItems={list}
        selectedIds={list}
        pageSize={pageSize}
        showPagination={!hierarchy}
        hierarchy={hierarchy}
        parameters={{ tipoId }}
      ></ItemList>
    );
  }

  render() {
    const {
      intl,
      habilitarIndicadoresRisco = false,
      resources,
      showOnlyProjects = false,
      modules,
      ...other
    } = this.props;

    const actions = [
      <Button disabled={this.state.isLoading} onClick={this.handleCancelarClick} color="secondary">
        {intl.formatMessage({ id: 'label.cancelar' })}
      </Button>,
      <Button
        className="ml-2"
        type="primary"
        disabled={this.state.isLoading}
        onClick={this.handleOkClick}
        htmlType="submit"
      >
        {intl.formatMessage({ id: 'label.salvar' })}
      </Button>,
    ];

    const possuiModuloGE = !modules || modules.find((m) => m.id === MODULOS.GESTAO_ESTRATEGICA && m.ativo);
    const possuiModuloMO = !modules || modules.find((m) => m.id === MODULOS.MATRIZ_ORCAMENTARIA && m.ativo);
    const possuiModuloRV = !modules || modules.find((m) => m.id === MODULOS.REMUNERACAO_VARIAVEL && m.ativo);
    const possuiModuloGR = !modules || modules.find((m) => m.id === MODULOS.GESTAO_RISCO && m.ativo);
    const possuiModuloPROJETO = !modules || modules.find((m) => m.id === MODULOS.ESCRITORIO_PROJETOS && m.ativo);
    const possuiGEMORV = possuiModuloGE || possuiModuloMO || possuiModuloRV;

    return (
      <Dialog
        {...other}
        ref="dialog"
        width="80%"
        height="80%"
        actions={actions}
        padContent
        onRequestClose={this.handleCancelClick}
      >
        {habilitarIndicadoresRisco && (
          <Panel
            header={capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'indicadorRisco' },
                { risco: resources.risco }
              )
            )}
            open={false}
          >
            {this.handleList(17, false)}
          </Panel>
        )}
        {possuiGEMORV && !showOnlyProjects && (
          <Panel
            header={capitalizeFirstLetter(resources.indicador)}
            open={false}
          >
            {this.handleList(1, false)}
          </Panel>
        )}
        {!showOnlyProjects && (
          <Panel
            header={capitalizeFirstLetter(resources.processos)}
            open={false}
          >
            {this.handleList(5, true, 9999)}
          </Panel>
        )}
        {(possuiModuloPROJETO || possuiModuloGR) && (
          <Panel 
            header={capitalizeFirstLetter(resources.projeto)} 
            open={false}>
            {this.handleList(4, true, 9999)}
          </Panel>
        )}
        {possuiGEMORV && !showOnlyProjects && (
          <Panel
            header={capitalizeFirstLetter(resources.objetivoestrategico)}
            open={false}
          >
            {this.handleList(3, false)}
          </Panel>
        )}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(RiscoItensAssociadosList));
