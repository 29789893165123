import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class TipoCargo extends React.Component {
  render() {
    return <Lookup labelKey="nome" valueKey="id" url="/TipoCargoLookup" {...this.props} />;
  }
}

export default TipoCargo;
