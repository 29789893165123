import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Popover, PopoverHeader } from 'reactstrap';

import MdiIcon from 'src/componentes/mdi-icon';
import ListRisco from 'src/paginas/gestao-risco/risco/RiscoListView';
import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';

import { Container, ContainerSpacos } from './index.style';
/**
 Erro1: O componente fica piscando na tela ao passar o mouse sobre
 Solução:
    O pai desse componente não deve conter a propriedade de css OverFlowY: auto. Por Default, no componente List é dado essa propriedade. 
    Pra remover é só passar como parametro no List a propriedade showScrollColumn=false
 * 
 * 
 * @param {object} scoreAcumuladoDoGrau representa o número de risco num determinado grau. Referencia: GDR-337  
 */
const ScoreAcumulado = ({ scoreAcumuladoDoGrau, id, resources, showLabel = false, intl, style }) => {
  const [isPopUpOpen, setPopUp] = useState(false);

  const getTotal = () => scoreAcumuladoDoGrau && scoreAcumuladoDoGrau.reduce((total, current) => current.scoreAcumulado + total, null);

  const renderLabel = () => {
    const frase = intl.formatMessage({ id: 'scoreAcumulado' }, { risco: resources.risco }).split(' ');
    return frase[0] + ' ' + frase[2];
  };

  const handleRiscoClick = (riscoAvaliacoes) => {
    let keys = [];

    if (riscoAvaliacoes) {
      riscoAvaliacoes.forEach((x) => {
        if (x && x.riscos) x.riscos.forEach((risco) => keys.push(risco.id));
      });

      if (keys && keys.length > 0) {
        ContentManager.addContent(
          <ListRisco
            openLastFilter={true}
            usarFiltroSalvo={true}
            showModal={true}
            showNew={false}
            params={{ idsSelecionados: keys }}
            title={capitalizeFirstLetter(resources.risco)}
            ignoreCloseModal={true}
          />
        );
      }
    }
  };

  return (
    <Container style={{ cursor: 'pointer', ...style }}>
      <span>
        {showLabel && renderLabel()}
        <strong style={{ marginLeft: 5, marginRight: 5 }}>{getTotal()}</strong>
      </span>
      <ContainerSpacos id={'popOverId' + id}>
        {scoreAcumuladoDoGrau &&
          scoreAcumuladoDoGrau.map(({ grau: { cor }, riscos }) => (
            <div
              onMouseOver={() => setPopUp(true)}
              onMouseOut={() => setPopUp(false)}
              onClick={handleRiscoClick.bind(this, scoreAcumuladoDoGrau)}
              style={{ display: 'flex', flex: riscos.length, background: cor }}
            />
          ))}
      </ContainerSpacos>
      <Popover placement="right" isOpen={isPopUpOpen} target={'popOverId' + id}>
        {scoreAcumuladoDoGrau &&
          scoreAcumuladoDoGrau.map(({ grau: { cor }, riscos }) => (
            <PopoverHeader>
              <MdiIcon icon="circle" color={cor} size={22}></MdiIcon>
              {riscos.length} {riscos.length > 1 ? resources.riscos : resources.risco}
            </PopoverHeader>
          ))}
      </Popover>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ScoreAcumulado));
