import { useState, useCallback, useEffect } from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Alert } from 'reactstrap';
import http from 'src/services/httpService';
import { useIntl } from 'react-intl';

function EfetividadeControleDropdown({ filtro, model, disabled, showErrorMessage, resources }) {
  const [value, setValue] = useState(model?.value);
  const [itensLookups, setItensLookups] = useState([]);
  const intl = useIntl();

  const onSelectValue = (item) => {
    if (item.id == -1) {
      setValue(null);
      model?.onChange(null);
    } else {
      setValue(item);
      model?.onChange(item);
    }
  };

  const loadItens = useCallback(async () => {
    if (showErrorMessage) return;

    await http
      .post(`/EfetividadeControleLookup/Search`, {
        filter: filtro,
        pageSize: 1000,
      })
      .then((response) => {
        if (response.data && response.data.list) {
          const itens = [{ id: -1, nome: intl.formatMessage({ id: 'labelSelecioneUmValor' }) }].concat(response.data.list);
          setItensLookups(itens);
        }
      });
  }, [filtro]);

  useEffect(() => {
    loadItens();
  }, [filtro]);

  if (showErrorMessage)
    return <Alert color="warning">{intl.formatMessage({ id: 'funcaoControleNaoDefinida' }, { pontodecontrole: resources.pontodecontrole })}</Alert>;

  return (
    <UncontrolledDropdown disabled={disabled}>
      <DropdownToggle tag="a" caret={!disabled} style={disabled ? {cursor: 'default', textDecoration:'none'} : {}}>
        {value ? value.nome : disabled ? intl.formatMessage({ id: 'labelRevisaoConcluida' }) : intl.formatMessage({ id: 'labelSelecioneUmValor' })}
      </DropdownToggle>
      <DropdownMenu container="body">
        {itensLookups &&
          itensLookups.map((item) => {
            return <DropdownItem onClick={() => onSelectValue(item)}>{item.nome}</DropdownItem>;
          })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default EfetividadeControleDropdown;
