import React from 'react';
import { autobind } from 'core-decorators';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import classnames from 'classnames';
import css from 'src/utils/css';

const classes = css`
  .hoverEffect {
    &:hover {
      background: #1d7789;
    }
  }

  .badge {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #d9534f;
    //margin-left: 1px;
  }
`;
@autobind
class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currenttabIndex: this.props.currenttabIndex
        ? this.props.currenttabIndex
        : 0,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.tabIndex != this.props.tabIndex && !newProps.currenttabIndex) {
      this.setState({ currenttabIndex: 0 });
    } else if (
      (newProps.currenttabIndex || newProps.currenttabIndex == 0) &&
      newProps.currenttabIndex != this.props.currenttabIndex
    ) {
      this.setState({ currenttabIndex: newProps.currenttabIndex });
    } else if (newProps.forceChangeTab) {
      this.setState({ currenttabIndex: newProps.currenttabIndex });
    }
  }

  gototabIndex(index) {
    this.setState({ currenttabIndex: index });

    this.props.onSelect && this.props.onSelect(index);
  }

  hasError(tabsError, tabIndex) {
    return tabsError && tabsError.includes(tabIndex);
  }

  render() {
    let {
      children: tabs,
      tabsError = [],
      paddingContent,
      style = {},
      styleContent = {},
    } = this.props;
    if (!(tabs instanceof Array)) {
      tabs = [tabs];
    }
    tabs = tabs.filter((t) => t);
    let { currenttabIndex } = this.state;

    return (
      <div className={'tab '} style={{ ...style }}>
        <Nav tabs className={this?.props?.tabClass}>
          {tabs.map((tab, tabIndex) =>
            !tab.props?.dropdown ? (
              <NavItem key={tabIndex}>
                <NavLink
                  style={{
                    marginBottom: 1,
                    color: this.hasError(tabsError, tabIndex)
                      ? '#FF1A39'
                      : currenttabIndex == tabIndex && '#1d7789',
                    borderColor: '#1d7789',
                  }}
                  className={classnames(
                    { active: currenttabIndex == tabIndex },
                    classes.hoverEffect
                  )}
                  onClick={this.gototabIndex.bind(this, tabIndex)}
                >
                  {tab.props.label}
                  {tab.props.showBadge && <div className={classes.badge}></div>}
                </NavLink>
              </NavItem>
            ) : (
              tab.props.label
            )
          )}
        </Nav>
        <TabContent
          activeTab={currenttabIndex}
          style={{ padding: paddingContent, ...styleContent }}
        >
          {tabs.map((tab, tabIndex) =>
            React.cloneElement(tab, {
              key: tab.key || tabIndex,
              active: currenttabIndex == tabIndex,
            })
          )}
        </TabContent>
      </div>
    );
  }
}

export default Tabs;
