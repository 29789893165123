import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TaskList from './components/task-list';
import TaskItem from './components/task-item';
import { autobind } from 'core-decorators';
import http from 'src/services/httpService';
import errorHandler from '../../../utils/error-handler';
@autobind
class Kanban extends React.Component {
  static defaultProps = {
    listagemUnica: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      list: this.props.list != null ? this.props.list : [],
      taskFantasma: null,
      isDragging: false,
    };
  }

  //removendo isso, ficará com lag quando tiver muitas ações
  shouldComponentUpdate() {
    return !this.state.isDragging;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.list != null)
      if (newProps.list !== this.props.list) {
        this.setState({
          list: newProps.list,
        });
      }
  }

  //Sempre utilizado
  handleDragStart(event) {
    this.setState({ isDragging: true }, () =>
      this.setState({ isDragging: false })
    );

    if (this.props.listagemUnica)
      this.setTaskFantasma(
        this.state.list.find((e) => e.id == event.draggableId)
      );
    else {
      let source = event.source;
      this.setTaskFantasma(
        this.itensLista(source && source.droppableId).find(
          (e) => e.id == event.draggableId
        )
      );
    }
  }

  //Não é utilizado para listagem única
  removeAndAddListas(sourceId, destinationId, draggableId, farol) {
    let newLists = Object.assign([], this.state.list);
    let sourceList = newLists.find((list) => list.id === sourceId);
    let destinationList = newLists.find((list) => list.id === destinationId);

    let itensSource = sourceList && sourceList.itens;
    let itensDestination = destinationList && destinationList.itens;

    if (itensSource) {
      let index = itensSource.findIndex((a) => a.id == draggableId);

      if (index != -1) {
        let acao = itensSource[index];

        if (acao) {
          if (acao.status && acao.status.id == destinationId) {
            acao.status = { id: destinationId };
            acao.farolAcao = farol;
          } else {
            itensSource.splice(index, 1);

            acao.status = { id: destinationId };
            acao.farolAcao = farol;

            itensDestination && itensDestination.unshift(acao); //atualiza listas afetadas
          }
        }
      }

      this.setState({ list: newLists });
    }
  }

  //Utilizado para listagem única
  removeAndAddListaUnica(listaCopia, draggableId, destinationId, farol = null) {
    let acaoIndex = listaCopia.findIndex((a) => a.id == draggableId);

    if (acaoIndex != -1) {
      let acao = listaCopia[acaoIndex];

      if (acao) {
        if (acao.status && acao.status.id == destinationId) {
          acao.status = { id: destinationId };
          acao.farolAcao = farol;
        } else {
          listaCopia.splice(acaoIndex, 1);

          acao.status = { id: destinationId };
          acao.farolAcao = farol;

          listaCopia.unshift(acao);
        }

        this.setState({ list: listaCopia });
      }
    }
  }

  //Sempre utilizado
  handleDragEnd(event) {
    this.setTaskFantasma(null);

    const { source, destination } = event;
    let { list } = this.state;

    if (!destination) {
      return;
    }

    let destinationId = parseInt(destination.droppableId, 10);
    let sourceId = parseInt(source.droppableId, 10);

    let listaCopia = Object.assign([], list);

    //Jogo na lista antes de salvar, para não haver delay
    this.callRemoveAndAdd(
      listaCopia,
      sourceId,
      event.draggableId,
      destinationId
    );

    //posteriormente caso tenha alguma alteração, as tasks são reorganizadas

    http
      .post(`/Acao/AlterarApenasStatus`, {
        acaoId: event.draggableId,
        novoStatusId: destinationId,
      })
      .then((response) => {
        this.callRemoveAndAdd(
          listaCopia,
          destinationId,
          event.draggableId,
          response.data.status && response.data.status.id,
          response.data.farol
        );
      })
      .catch((error) => {
        this.callRemoveAndAdd(
          listaCopia,
          destinationId,
          event.draggableId,
          sourceId
        );
        errorHandler(error);
      });
  }

  //Sempre utilizado
  callRemoveAndAdd(
    listaCopia,
    sourceId,
    draggableId,
    destinationId,
    farol = null
  ) {
    let { listagemUnica } = this.props;

    if (listagemUnica)
      this.removeAndAddListaUnica(
        listaCopia,
        draggableId,
        destinationId,
        farol
      );
    else {
      this.removeAndAddListas(sourceId, destinationId, draggableId, farol);
      this.props.handleChangeFiltrandoBloqueado &&
        this.props.handleChangeFiltrandoBloqueado();
    }
  }

  //Sempre utilizado
  setTaskFantasma(task) {
    this.setState({
      taskFantasma: task,
    });
  }

  //Não é utilizado para listagem única
  async adicionarLista(lista) {
    let listaCopia = Object.assign([], this.state.list);
    let indexLista = listaCopia.findIndex((list) => list.id == lista.id);

    if (indexLista == -1) listaCopia.push(lista);
    else listaCopia[indexLista] = lista;

    //distinct
    this.setState({
      list: listaCopia.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      ),
    });
  }

  //Não é utilizado para listagem única
  itensLista(statusId) {
    try {
      var lista;
      const { list } = this.state;
      if (list.length && list.filter((ls) => ls.itens).length)
        lista = list.find(
          (ls) => ls.itens.filter((item) => item.status.id == statusId).length
        );
      else lista = this.state.list.find((list) => list.id == statusId);

      if (lista) {
        return lista.itens;
      }

      return [];
    } catch (err) {
      return [];
    }
  }

  getRealCount(statusId) {
    try {
      var lista;
      const { list } = this.state;
      if (list.length && list.filter((ls) => ls.itens).length)
        lista = list.find(
          (ls) => ls.itens.filter((item) => item.status.id == statusId).length
        );
      else lista = this.state.list.find((list) => list.id == statusId);

      if (lista) {
        return lista.qntRealItens;
      }

      return 0;
    } catch (err) {
      return 0;
    }
  }

  render() {
    let {
      columnsKanban,
      columnsCard,
      filtrando,
      model,
      parameters,
      listagemUnica,
    } = this.props;

    let { list } = this.state;

    let columnsCardFinal = Object.assign([], columnsCard);

    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            flexGrow: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            height: '75vh',
          }}
        >
          <DragDropContext
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}
          >
            {columnsKanban &&
              columnsKanban.map((column) => (
                <Droppable droppableId={column.id + ''} key={column.id}>
                  {(provided, snapshot) => (
                    <TaskList
                      listagemUnica={listagemUnica}
                      adicionarLista={this.adicionarLista}
                      statusId={column && column.id}
                      filtrando={filtrando}
                      model={model}
                      parameters={parameters}
                      quantidadeColunas={columnsKanban && columnsKanban.length}
                      provided={provided}
                      snapshot={snapshot}
                      title={column.headerText}
                      total={
                        listagemUnica
                          ? list &&
                            list.filter((a) => a.status.id == column.id).length
                          : this.getRealCount(column.id)
                      }
                    >
                      {this.state.taskFantasma && (
                        <div
                          style={{
                            display: snapshot.isDraggingOver ? 'block' : 'none',
                          }}
                        >
                          <TaskItem
                            ehFantasma={true}
                            columnsCard={columnsCardFinal}
                            task={this.state.taskFantasma}
                          />
                        </div>
                      )}
                      {(listagemUnica
                        ? list && list.filter((a) => a.status.id === column.id)
                        : this.itensLista(column.id)
                      ).map((task, index) => (
                        <Draggable
                          draggableId={task.id + ''}
                          index={index}
                          key={task.id}
                        >
                          {(provided, snapshot) => (
                            <TaskItem
                              handleDescricaoClick={
                                this.props.handleDescricaoClick
                              }
                              columnsCard={columnsCardFinal}
                              provided={provided}
                              snapshot={snapshot}
                              task={task}
                              setTaskFantasma={this.setTaskFantasma}
                              taskFantasma={this.state.taskFantasma}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TaskList>
                  )}
                </Droppable>
              ))}
          </DragDropContext>
        </div>
      </div>
    );
  }
}

export default Kanban;
