import React from 'react';
import { autobind } from 'core-decorators';
import { FormFeedback, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import BigNumber from 'bignumber.js/bignumber.mjs';
import { injectIntl } from 'react-intl';

import { parseNumber } from '../../utils/number';
import Info from 'src/componentes/info';

@autobind
class InputNumber extends React.Component {
  static defaultProps = {
    locale: null,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    precision: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
      value: this.getValueFromProps(),
      textValue: this.getTextValueFromProps(props),
    };
  }

  getValueFromProps(props = this.props) {
    return props.model && props.model.value;
  }

  getTextValueFromProps(props = this.props) {
    let finalValue = null;

    const { afterValue, model, precision } = props || {};
    if (model) {
      if (model.value == 0) finalValue = model.value;
      else if (model.value)
        finalValue = this.formatBigNumber(model.value, precision);
    }

    if (finalValue != null && afterValue) return `${finalValue}${afterValue}`;

    return finalValue ?? '';
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.getValueFromProps(newProps) !== this.state.value) {
      this.setState({
        value: this.getValueFromProps(newProps),
        textValue: this.getTextValueFromProps(newProps),
      });
    }
  }

  removeSymbol(inputString) {
    const { symbolEnd } = this.props;
    if (!inputString || !symbolEnd) return inputString;

    return inputString.replace(symbolEnd, '');
  }

  handleChange(e) {
    let { precision, decimalSeparator } = this.props;
    let number = parseNumber(
      this.removeSymbol(e.target.value),
      precision,
      decimalSeparator
    );

    this.setState(
      {
        value: this.formatBigNumberString(number),
        textValue: this.removeSymbol(e.target.value),
      },
      () => {
        this.props.model.requestChange(this.state.value);
      }
    );
  }

  handleFocus() {
    this.setState({
      hasFocus: true,
    });
  }

  addSymbol(inputString) {
    const { symbolEnd } = this.props;

    if (!inputString || !symbolEnd) return inputString;

    let result = inputString.replace(symbolEnd, '');
    result = inputString + symbolEnd;

    return result;
  }

  handleBlur() {
    let { precision, min, max } = this.props;
  
    let number = 
      this.state.value % 1 != 0 
        ? parseNumber(this.state.value, precision, '.')
        : this.state.value;
  
    if (min && number && number < min) number = min;

    if (max && number && number > max) number = max;
  
    const numberFormated = number != null ? this.formatBigNumber(number, precision) : '';
  
    this.setState(
      {
        hasFocus: false,
        value: number,
        textValue: numberFormated, 
      },
      () => {
        this.props.model.requestChange(this.state.value);
        if (this.props.onBlur) this.props.onBlur();
      }
    );
  }
  
  formatBigNumber(value, precision) {
    var BN = BigNumber.clone({
      FORMAT: {
        groupSize: 3,
        groupSeparator: this.props.thousandsSeparator,
        decimalSeparator: this.props.decimalSeparator,
      },
    });
    var bigNumber = new BN(value);
    return bigNumber.toFormat(precision);
  }

  formatBigNumberString(value) {
    if (value == 0) {
      return '0';
    } else if (value) {
      var bigNumber = new BigNumber(value);
      return bigNumber.toString();
    } else {
      return null;
    }
  }

  render() {
    let {
      model,
      info,
      intl,
      autoFocus,
      maxLength,
      placeholder,
      required = false,
      mask,
      type,
      disabled,
      detail,
      row,
      style,
      formStyle,
      min,
      max = {},
      customClassNameLabel = null,
      integer = false,
      symbolEnd = null,
      ...other
    } = this.props;

    let value = model.value;

    return (
      <FormGroup
        style={{ ...formStyle }}
        row={row}
        validationstate={model.errors ? 'error' : null}
      >
        {model.label && (
          <Label
            className={
              customClassNameLabel ? customClassNameLabel : 'control-label'
            }
          >
            {model.label} {required ? '*' : ''}
          </Label>
        )}
        {info && (
          <Info
            className="ml-1"
            id={model.path || info}
            placement="right"
            text={intl.formatMessage({ id: info })}
          />
        )}
        {detail ? (
          <div style={{ whiteSpace: 'pre' }} {...other}>
            {value}
          </div>
        ) : (
          <Input
            autoFocus={autoFocus}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            value={this.addSymbol(this.state.textValue)}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            invalid={model.errors}
            type={type}
            min={min}
            max={max}
            {...other}
            onBlur={this.handleBlur}
            style={{
              ...style,
              padding: 5,
              borderColor: model.errors && '#f05050',
            }}
            onKeyPress={(event) => {
              if (
                integer
                  ? !/[0-9-]/.test(event.key)
                  : !/[0-9.,-]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
          />
        )}
        {model.erros && <FormFeedback>{model.erros}</FormFeedback>}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    decimalSeparator: state.user.decimalSeparator,
    thousandsSeparator: state.user.thousandsSeparator,
    locale: state.user.locale,
  };
}
export default injectIntl(
  connect(mapStateToProps)(InputNumber, { withRef: true })
);
