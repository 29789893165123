import React from 'react';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Button from 'src/componentes/button';
import Dialog from 'src/componentes/dialog';
import LoadingContainer from 'src/componentes/loading-container';
import { capitalizeFirstLetter } from 'src/utils/string';
import AcaoList from 'src/paginas/acao/select-list';

@autobind
class RevisaoPlanoAcao extends React.Component {
  static defaultProps = {
    showModal: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleCancelarClick() {
    this.props.refresh && this.props.refresh();
    this.refs.dialog.close();
  }

  render() {
    let {
      resources,
      model,
      intl,
      title,
      naoSalvarFiltro,
      avaliacaoInerente,
      showMenuAcao = true,
      showDelete = false,
      showColumnItem = true,
      showTitle = true,
      headTitle,
      disabled,
    } = this.props;

    if (!headTitle){
      headTitle = `${
        title ? title : intl.formatMessage({ id: `label.revisao` })
      } ${capitalizeFirstLetter(resources.planodeacao)}`;
    }

    const actions = [
      <Button key={1} onClick={this.handleCancelarClick} color="default">
        {<FormattedMessage id="label.cancelar" />}
      </Button>,
      <Button
        key={2}
        type="primary"
        onClick={this.handleCancelarClick}
        htmlType="submit"
        className="ml-2"
      >
        {<FormattedMessage id="label.gerar" />}
      </Button>,
    ];

    return (
      <Dialog
        {...this.props}
        ref="dialog"
        title={showTitle ? headTitle : ''}
        actions={actions}
        onRequestClose={this.handleCancelarClick}
        width={950}
        minHeight={500}
        style={{ fontSize: 15 }}
        padContent
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <AcaoList
            showDelete={showDelete}
            params={{
              itemId: model && model.item && model.item.id,
              riscoPontoControleRevisaoId: model && model.id,
              riscoAvaliacaoId: model.riscoAvaliacaoId,
              riscoInerente: avaliacaoInerente,
              ...model,
            }}
            disabled={disabled}
            showPrevious={false}
            showLink={true}
            showNew={true}
            showExport={true}
            showMenuAcao={showMenuAcao}
            showColumnItem={showColumnItem}
            naoSalvarFiltro={naoSalvarFiltro}
          ></AcaoList>
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloAtual: state.user.moduloAtual,
    user: state.user,
  };
}

export default injectIntl(connect(mapStateToProps)(RevisaoPlanoAcao));
