import React from 'react';
import { useIntl } from 'react-intl';

import useAuth from 'src/hooks/useAuth';
import Panel from 'src/componentes/panel-collapse';
import AreaLookup from 'src/lookups/item-organizacao';
import Radio from 'src/componentes/radio';
import { capitalizeFirstLetter } from 'src/utils/string';

function InteresseOutrasAreas({ model, disabled }) {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const options = [
    { id: 1, descricao: intl.formatMessage({ id: 'label.sim' }) },
    { id: 0, descricao: intl.formatMessage({ id: 'label.nao' }) },
  ];

  const getOption = (resposta) => {
    if (resposta == null) return null;

    return options.find((x) => x.id == resposta);
  };

  return (
    <Panel errors={model.getModel('interesseOutrasAreas').errors} open={false} header={intl.formatMessage({ id: 'interesseOutrasAreas' })}>
      <Radio
        name="interesseOutrasAreas"
        disabled={disabled}
        className="mb-4"
        model={{
          label: intl.formatMessage({ id: 'interesseOutrasAreas' }),
          value: getOption(model.getValue('interesseOutrasAreas')),
          requestChange: (value) => model.getModel('interesseOutrasAreas').requestChange(value.id),
          errors: model.getModel('interesseOutrasAreas').errors,
        }}
        options={options}
        labelField="descricao"
        idField="id"
      />
      {model.getModel('interesseOutrasAreas').value == 1 && (
        <AreaLookup
          disabled={disabled}
          multi
          model={{
            label: capitalizeFirstLetter(intl.formatMessage({ id: 'areasInteresse' }, { areas: resources.areas })),
            value: model.getModel('areasInteresse').value,
            requestChange: model.getModel('areasInteresse').requestChange,
          }}
        />
      )}
    </Panel>
  );
}

export default InteresseOutrasAreas;