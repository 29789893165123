import React from 'react';
import Dialog from '../../../componentes/dialog';
import { autobind } from 'core-decorators';
import Button from '../../../componentes/button';
import ParteInteressadaLookup from '../../../lookups/parte-interessada';
import CheckBox from '../../../componentes/checkbox';
import { CompactPicker } from 'react-color';
import { Row, Col } from 'reactstrap';
import Input from '../../../componentes/input';
import InputNumber from '../../../componentes/input-number';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);
    this.saved = false;
    this.isNew = this.props.item == null ? true : false;
    this.state = {
      item: this.props.item || null,
      index: this.props.index,
    };
  }
  handleParteInteressadaChange(value) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        parteInteressada: value,
      },
      disableSave: true,
    });
  }

  handleObservacaoChange(value) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        observacao: value,
      },
    });
  }

  handleResultadoChange(value) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        resultadoEsperado: value,
      },
    });
  }

  handleCorChange(value) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        cor: value,
      },
    });
  }

  handleExibirGraficoChange(value) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        exibirNoGrafico: value,
      },
    });
  }

  handleChangeComplete(color) {
    this.setState({
      item: {
        permissions: {
          allowEdit: true,
          allowDelete: true,
        },
        ...this.state.item,
        cor: color.hex,
      },
    });
  }

  handleSave() {
    this.saved = true;
    this.close();
  }

  close() {
    this.refs.dialog.close();
    setTimeout(() => {
      this.props.handleClose && this.props.handleClose(this.saved, this.isNew, this.state.item, this.props.indexItem);
    }, 300);
  }

  IdsDesconsiderados() {
    let { dataSource } = this.props;
    var ids = [];
    dataSource.map((item, i) => ids.push(item.id));
    return ids;
  }

  conferirParteInteressada() {
    if (this.state.item && this.state.item.parteInteressada != null) this.setState({ disableSave: false });
    else this.setState({ disableSave: true });
  }

  render() {
    let { resources } = this.props;

    let editActions = [
      <Button key={1} onClick={this.close} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>,
      <Button key={2} disabled={this.state.disableSave} type="primary" onClick={this.handleSave.bind(this, false)} htmlType="submit" className="ml-2">
        {<FormattedMessage id="label.salvar" />}
      </Button>,
    ];

    return (
      <Dialog
        width={850}
        height={450}
        title={capitalizeFirstLetter(resources.parteinteressada)}
        {...this.props}
        padContent
        actions={editActions}
        onRequestClose={this.handleClose}
        ref="dialog"
      >
        <Row>
          <Col md={6}>
            <ParteInteressadaLookup
              onBlur={this.conferirParteInteressada}
              model={{
                label: capitalizeFirstLetter(resources.parteinteressada),
                value: this.state.item && this.state.item.parteInteressada,
                requestChange: this.handleParteInteressadaChange,
              }}
              data={{ idsDesconsiderados: this.IdsDesconsiderados() }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <Input
              type="textarea"
              model={{
                label: capitalizeFirstLetter(resources.observacao),
                value: this.state.item && this.state.item.observacao,
                requestChange: this.handleObservacaoChange,
              }}
            />
          </Col>
          <Col md={5}>
            <Row>
              <Col md={12}>
                <InputNumber
                  model={{
                    label: <FormattedMessage id="label.resultadoEsperado" />,
                    value: this.state.item && this.state.item.resultadoEsperado,
                    requestChange: this.handleResultadoChange,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <CheckBox
                  model={{
                    label: <FormattedMessage id="label.exibirNoGrafico" />,
                    value: this.state.item && this.state.item.exibirNoGrafico,
                    requestChange: this.handleExibirGraficoChange,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {this.state.item && this.state.item.exibirNoGrafico && (
                  <CompactPicker color={this.state.item.cor ? this.state.item.cor : '#ddd'} onChangeComplete={this.handleChangeComplete}></CompactPicker>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(FormEdit));
