import Lookup from 'src/componentes/select2';

const TipoMudancaSolicitacaoGestaoMudancasIndicadorLookup = ({ ...rest }) => {
  const customStyle = {
    option: (base, state) => ({
      ...base,
      color: '#1e2022',
      backgroundColor: state.isSelected ? '#dae1eb' : 'white',
      padding: '.5rem 3rem .5rem .5rem',
      cursor: 'pointer',
    }),
  };

  const lookupRenderOrientacao = ({ data }, isOption) => {
    return isOption ? (
      <div>
        <div className="font-weight-bold">{data?.descricao}</div>
        {data?.orientacao && (
          <div className="overflow-hidden line-clamp-2 text-sm text-muted">
            {data?.orientacao}
          </div>
        )}
      </div>
    ) : (
      data?.descricao
    );
  };

  return (
    <Lookup
      labelKey="descricao"
      valueKey="id"
      url="/TipoMudancaSolicitacaoGestaoMudancasIndicadorLookup"
      valueComponent={lookupRenderOrientacao}
      clearable={false}
      {...rest}
      customStyle={customStyle}
    />
  );
};

export default TipoMudancaSolicitacaoGestaoMudancasIndicadorLookup;
