import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Funcao from './edit';
import { capitalizeFirstLetter } from '../../../utils/string';

@autobind
class FuncaoList extends React.Component {
  render() {
    let { model, intl, resources, disabled } = this.props;
    let podeEditar = !model.getModel('podeEditar').value;
    return (
      <div>
        <List
          url="/ColaboradorFuncao"
          showSearch={false}
          titulo={capitalizeFirstLetter(resources.funcoes)}
          editComponent={Funcao}
          sort="dataInicio"
          parameters={{ ColaboradorId: model.getModel('id').value }}
          showExport={false}
          showNew={podeEditar}
          showDelete={podeEditar}
          disabled={disabled}
          podeEditar={podeEditar}
          {...this.props}
        >
          <ListColumn
            headerText={intl.formatMessage({ id: 'dataInicio' })}
            valueField="dataInicio"
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'dataTermino' })}
            valueField="dataTermino"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(resources.funcao)}
            valueField="funcao"
          />
        </List>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(FuncaoList));
