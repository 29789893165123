import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Row,
  Col,
  UncontrolledDropdown,
  ButtonGroup,
  UncontrolledTooltip,
  Label,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { autobind } from 'core-decorators';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CancelToken } from 'axios';

import { EXCEPTION_CANCELLATION_TOKEN } from 'src/utils/constants';
import httpSelector from 'src/services/httpSelector';
import history from '../../history';
import http from 'src/services/httpService';
import ContentManager from 'src/componentes/content-manager';
import css from 'src/utils/css';
import Button from 'src/componentes/button';
import MdiIcon from 'src/componentes/mdi-icon';
import Dialog from 'src/componentes/dialog';
import Pagination from 'src/componentes/list/pagination';
import Filtro from 'src/componentes/filtro';
import Table from 'src/componentes/table';
import { Checkbox } from 'src/componentes/Form';
import LoadingContainer from 'src/componentes/loading-container';
import Confirm from 'src/componentes/message-box/confirm';
import CriticalConfirm from 'src/componentes/message-box/critical-confirm';
import Alert from 'src/componentes/message-box/alert';
import errorHandler from 'src/utils/error-handler';
import ColunasTabelas from 'src/componentes/list/componentes/colunas-tabela';
import Email from 'src/componentes/email';
import GeoreferenciaExibicao from 'src/componentes/list/componentes/georeferencia-exibicao';
import InputSearch from 'src/componentes/input-search';
import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import Estatisticas from 'src/paginas/relatorio-acompanhamento/search-estatistica';
import EstatisticasAcoes from 'src/paginas/acao/estatistica-modal';
import Kanban from 'src/paginas/acao/kanban';
import DialogColunas from 'src/paginas/acao/kanban/components/dialog-colunas';
import FiltroChip from 'src/componentes/filtro-chip';
import guid from 'src/utils/guid';

let classes = css`
  .checkCell {
      width: 40px;
      cursor: pointer;
    }

  .hierarchy {
    width: 15px;
    cursor: pointer;
    margin: -3px 10px;
  }

  .dropdown {
    & > .btn {
      border: 0px;
      color: inherit;
      &:hover {
        background-color: #e4eaec;
        color: inherit;
      }
    }
  }
  .sort {
    position: relative;
    cursor: pointer;
    padding-right: 15px!important;
    &:after{
      font-family: FontAwesome;
      right: 5px;
      font-size: 10px!important;
      position: absoluimport { SetSalvarGanhosPorAcao } from './../../store/ganhos-por-acao';
te;
      display: inline-block;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      margin-top: 3px;
    }
    &:empty{
      &:after{
        display: none;
      }
    }
  }

  .tHeadContent {
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -o-max-content;
    width: -ms-max-content;

    white-space: nowrap;
  }

  }
  .menulistcustom {
    li a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.52857;
      color: #5f5f5f;
      white-space: nowrap;
      position: relative;
      .nameFilter{
        display: inline;
      }
      .iconFilter{
        position: absolute;
        right: 5px;
        margin: auto;
      }
    }
  }
`;

@autobind
class List extends React.Component {
  static defaultProps = {
    showMenu: true,
    showEdit: true,
    showDelete: true,
    showNew: true,
    showPage: true,
    showToolBar: true,
    showDetail: false,
    showSearch: false,
    dialogWidth: '70%',
    dialogHeight: '70%',
    labelNew: 'Novo',
    showTitle: false,
    showExport: true,
    showCustomFields: false,
    openLastFilter: true,
    changedColumns: false,
    usarFiltroSalvo: true,
    urlExportItem: null,
    getCorLinha: null,
    showExportItem: false,
    size: 'sm',
    showScrollColumn: true,
    habilitarGeoreferencia: false,
    kanban: false,
    showHierarchyPagination: false,
    showAnaliseCausa: false,
    showFinalRow: false,
    showTotalRow: false,
    refreshOnClose: false,
    autoFocus: true,
    forceSelectedFilter: false,
    colunasDinamicas: [],
    refreshFilterChip: 1,
    usarIdCampCustomizado: false,
    showIconDraggable: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      list: this.props.dataSource || [],
      count: 0,
      isLoading: false,
      expandedIds: [],
      selectedIds: [],
      selectedItems: [],
      pageSize: this.props.pageSize || 10,
      currentPage: 1,
      currentItemIndex: -1,
      columns: [],
      allColumns: [],
      columnsComponentOrder: null, //usado para mandar colunas null quando não houver no banco de dados para o component colunas-tabela.jsx
      sort: this.props.sort,
      sortBy:
        this.props.sort &&
        this.props.sort.split(' ')[1] &&
        this.props.sort.split(' ')[1] === 'desc'
          ? 'desc'
          : 'asc',
      searchModelFilter: null,
      selectedFilter: this.props.selectedFilter,
      parameters: this.props.parameters,
      params: this.props.centroCustoId,
      searchModel: null,
      selectAllItems: false,
      view:
        this.props.kanban && this.props.formatoKanbanDefault
          ? 'kanban'
          : 'lista',
      columnsCard: this.props.columnsCard,
      columnsKanban: this.props.columnsKanban,
      allColumnsCard: this.props.allColumnsCard,
      allColumnsKanban: this.props.allColumnsKanban,
      getCorLinha: this.props.getCorLinha,
      filtrando: true,
      filtrandoBloqueado: false,
      modelValue: null,
      refreshFilterChip: 1,
      componentKey: guid(),
      cancelTokens: {},
    };
  }

  componentDidMount() {
    this.props.usandoKanban &&
      this.props.usandoKanban(this.props.formatoKanbanDefault);

    this.obterPageSize();
    this.loadColumns(this.props.children);
    this.loadSelectedIds();
    this.loadPermissions();
    this.renderContent();
  }

  componentWillUnmount() {
    Object.keys(this.state.cancelTokens).forEach((key) => {
      this.state.cancelTokens[key] &&
        this.state.cancelTokens[key](EXCEPTION_CANCELLATION_TOKEN);
    });
  }

  createCancelToken(id) {
    const cancelToken = new CancelToken((source) => {
      this.setState((prevState) => ({
        cancelTokens: {
          ...prevState.cancelTokens,
          [id]: source, // armazenar o cancelToken no objeto usando o id como chave
        },
      }));
    });
    return cancelToken;
  }

  loadColumns(children) {
    let childrenArray = children;
    if (!(childrenArray instanceof Array)) {
      childrenArray = [childrenArray];
    }
    var filtered = childrenArray.filter(function (x) {
      return x != null && x != undefined && x != false;
    });

    let allColumns = filtered.map((c) => c.props);

    let columns = [];
    if (this.props.showCustomFields) {
      const customUrl = `/CampoCustomizado/ObterListaCamposCustomizados`;
      httpSelector
        .getHttp(this.props.viewType)
        .post(
          customUrl,
          {
            tipoItem: this.props.tipoItem,
            useCustomFieldId: this.props.usarIdCampCustomizado,
          },
          {
            cancelToken: this.createCancelToken(customUrl),
          }
        )
        .then((response) => {
          let campos = response.data;
          let camposComValorUrl = [];

          if (campos && campos.length > 0) {
            campos.map((itemCC) => {
              let colunaCC = {
                headerText: itemCC.descricaoCampo,
                valueField: itemCC.campo.nome,
                visible: true,
                default: false,
              };
              allColumns.push(colunaCC);
              if (
                this.props?.camposCustomizadosUrl &&
                Object.keys(this.props?.camposCustomizadosUrl).length > 0
              ) {
                camposComValorUrl.push({
                  ...itemCC,
                  valor:
                    this.props.camposCustomizadosUrl[itemCC.descricaoCampo],
                });
              }

              return colunaCC;
            });
          }

          if (
            this.props?.camposCustomizadosUrl &&
            Object.keys(this.props?.camposCustomizadosUrl).length > 0
          ) {
            this.setState({
              modelValueCamposCustomizados: camposComValorUrl,
              selectedFilter: {
                ...this.props.modelValue,
                camposCustomizados: camposComValorUrl,
              },
            });

            if (!this.props.usarFiltroSalvo) this.refreshModel();
          }

          this.loadConfigColunasTabela(columns, allColumns);
        });
    } else {
      this.loadConfigColunasTabela(columns, allColumns);
    }
  }

  loadConfigColunasTabela(columns, allColumns) {
    const customUrl = `/ColunasTabela/ObterConfiguracoesColunasTabela`;
    httpSelector
      .getHttp(this.props.viewType)
      .post(
        customUrl,
        {
          tag: this.props.tag || 0,
        },
        {
          cancelToken: this.createCancelToken(customUrl),
        }
      )
      .then((response) => {
        if (this.props.columnsDinamic && this.props.columnsDinamic.length > 0) {
          allColumns = allColumns.concat(this.props.columnsDinamic);
        }

        if (response.data) {
          response.data.map((coluna) => {
            let index = allColumns.findIndex(
              (c) => c.valueField === coluna.valueField
            );

            if (index !== -1) columns.push(allColumns[index]);

            return null;
          });
        }

        if (columns.length === 0) {
          columns = allColumns.filter((c) => c.visible && c.default);
          columns = columns.length === 0 ? allColumns : columns;
        } else {
          const columsRequired = allColumns.filter(
            (c) =>
              c.required &&
              !columns.find((col) => col.valueField === c.valueField)
          );

          if (columsRequired && columsRequired.length > 0)
            columns = columns.concat(columsRequired);
        }

        const columnsComponentOrder = this.props.handleCustomColumnsOrder
          ? this.props.handleCustomColumnsOrder(allColumns, columns)
          : columns;
        this.setState({
          columns: columns,
          allColumns: allColumns,
          columnsComponentOrder: columnsComponentOrder,
        });
      })
      .catch((error) => {
        if (error && error.message === EXCEPTION_CANCELLATION_TOKEN) return;
        errorHandler(error);
      });
  }

  loadSelectedIds() {
    if (this.props.select) {
      this.setState({
        selectedIds:
          this.props.selectedIds && this.props.selectedIds.length > 0
            ? this.props.selectedIds.map((d) => d.id)
            : [],
        //lista de itens
        selectedItems:
          this.props.selectedItems && this.props.selectedItems.length > 0
            ? this.props.selectedItems.map((d) => d)
            : [],
      });
    }
  }

  loadPermissions() {
    if (!this.props.local) {
      const customUrl = `${this.props.url}/LoadPermissionsAndSearch`;
      httpSelector
        .getHttp(this.props.viewType)
        .post(
          customUrl,
          {
            parameters: this.props.parameters,
          },
          {
            cancelToken: this.createCancelToken(customUrl),
          }
        )
        .then((response) => {
          this.setState(
            {
              ...response.data,
            },
            () => {
              this.refresh();
            }
          );
        })
        .catch((error) => {
          if (error && error.message === EXCEPTION_CANCELLATION_TOKEN) return;
          errorHandler(error);
        });
    } else {
      this.handleOpenLastFilter(this.refreshModel);
    }
  }

  checkHeaderText(header) {
    if (this.state.columns.headerText !== header) {
      return header;
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.changedColumns !== this.props.changedColumns) {
      this.loadColumns(newProps.children);
    }
    if (newProps.saved !== this.props.saved) {
      this.setState({ parameters: newProps.parameters }, () => this.refresh());
    } else if (newProps.forceRefresh) {
      this.setState({ parameters: newProps.parameters }, () => this.refresh());
    }
    if (newProps.dataSource !== this.props.dataSource) {
      this.setState({
        list: newProps.dataSource,
      });
    }
    if (newProps.closeModal) {
      this.handleCloseShowModal();
    }
    if (newProps.selectedFilter !== this.props.selectedFilter) {
      this.setState({ selectedFilter: newProps.selectedFilter });
    }
    if (newProps.columnsCard !== this.props.columnsCard) {
      this.setState({ columnsCard: newProps.columnsCard });
    }
    if (newProps.columnsKanban !== this.props.columnsKanban) {
      this.setState({ columnsKanban: newProps.columnsKanban });
    }
    if (newProps.allColumnsCard !== this.props.allColumnsCard) {
      this.setState({ allColumnsCard: newProps.allColumnsCard });
    }
    if (newProps.allColumnsKanban !== this.props.allColumnsKanban) {
      this.setState({ allColumnsKanban: newProps.allColumnsKanban });
    }
    if (newProps.handleSearch !== this.props.handleSearch) {
      this.refreshModel(newProps.handleSearch);
    }
  }

  handleAtualizarColunas(colunas) {
    this.setState({
      columns: colunas,
      columnsComponentOrder: colunas,
    });
  }

  handleAtualizarCardKanban(colunas) {
    this.setState({
      columnsCard: colunas,
    });
  }

  handleAtualizarColunasKanban(colunas) {
    this.setState({
      columnsKanban: colunas,
    });
  }

  handleSearch(searchModel, atualizarFiltroChip = false) {
    if (this.props.openLastFilter && !this.props.filtroLocal) {
      this.handleOpenLastFilter(this.refreshModel);
    } else {
      this.refreshModel(searchModel);

      let newSearchModel = {};

      if (!searchModel.valor) newSearchModel = { valor: searchModel };
      else newSearchModel = searchModel;

      this.setState({
        selectedFilter: newSearchModel,
        refreshFilterChip: atualizarFiltroChip
          ? this.state.refreshFilterChip + 1
          : this.state.refreshFilterChip,
      });
    }
  }

  handleOpenLastFilter(callback) {
    if (this.props.filtroId && this.props.showSearch) {
      // busca o ultimo filtro acessado
      if (this.props.usarFiltroSalvo && !this.props.modelValue) {
        httpSelector
          .getHttp(this.props.viewType)
          .post(
            `/FiltroColaborador/${this.props.filtroId}/AbrirUltimoFiltro`,
            {}
          )
          .then((response) => {
            if (response.data) {
              this.setState({
                searchModelFilter: response.data.valor,
                selectedFilter: response.data,
              });
              callback(response.data.valor);
            } else {
              callback();
            }
          });
      } else {
        httpSelector
          .getHttp(this.props.viewType)
          .post(
            `/FiltroColaborador/${this.props.filtroId}/ObterFiltroModelVazio`,
            {}
          )
          .then((response) => {
            if (response.data) {
              this.setState({
                searchModelFilter: response.data.valor,
                selectedFilter: response.data,
              });
              callback(response.data.valor);
            } else {
              callback();
            }
          });
      }
    } else {
      callback();
    }
  }

  handleChangeFiltrandoBloqueado() {
    this.setState(
      {
        filtrandoBloqueado: true,
      },
      () => this.refresh()
    );
  }

  refresh() {
    if (
      (this.props.openLastFilter || this.props.filtroId) &&
      !this.props.forceSelectedFilter
    ) {
      this.handleOpenLastFilter(this.refreshModel);
    } else if (this.props.selectedFilter || this.props.modelValue) {
      this.refreshModel(this.props.selectedFilter?.valor);
    }
    if (this.props.refresh) {
      this.props.refresh();
    }
  }

  async refreshModel(searchModel) {
    this.setState({
      isLoading: true,
    });
    this.props.setIsLoading && this.props.setIsLoading(true);

    let { hierarchy, local, customAction } = this.props;

    let listAction = hierarchy === true ? `ListHierarchy` : `List`;

    if (customAction) listAction = customAction;

    if (local) {
      this.searchLocal();
    } else {
      let newSearchModel = {
        ...searchModel,
        searchText: this.state.textoPesquisa,
        filtroInterno: this.props.filtroInterno,
      };

      let parameters = this.state.parameters;

      if (this.props.updateParams) {
        if (searchModel) {
          parameters = this.props.updateParams(
            searchModel.valor ? searchModel.valor : searchModel
          );
          this.setState({
            parameters: { ...parameters },
          });
        } else if (this.props.selectedFilter) {
          parameters = this.props.updateParams(
            this.props.selectedFilter.valor
              ? this.props.selectedFilter.valor
              : this.props.selectedFilter
          );
          this.setState({
            parameters: { ...parameters },
          });
        }
      }

      if (
        !Object.keys(newSearchModel).filter((att) => newSearchModel[att])
          .length &&
        this.props.modelValue
      ) {
        newSearchModel = {
          ...this.props.modelValue,
          searchText: this.state.textoPesquisa,
          filtroInterno: this.props.filtroInterno,
        };
        if (this.state.modelValueCamposCustomizados)
          newSearchModel.camposCustomizados =
            this.state.modelValueCamposCustomizados;
      } else {
        newSearchModel = {
          ...searchModel,
          searchText: this.state.textoPesquisa,
          filtroInterno: this.props.filtroInterno,
        };
      }

      this.setState({
        searchEstatistica: newSearchModel,
      });

      const customUrl = `${this.props.url}/${listAction}`;

      await httpSelector
        .getHttp(this.props.viewType)
        .post(
          customUrl,
          {
            model:
              newSearchModel.valor != null
                ? newSearchModel.valor
                : newSearchModel,
            sort: this.state.sort,
            pageSize: this.state.pageSize,
            page: this.state.currentPage - 1,
            parameters: {
              ...parameters,
              ehAtualizarAcoes: this.props.params
                ? this.props.params.ehAtualizarAcoes
                : false,
            },
          },
          {
            cancelToken: this.createCancelToken(customUrl),
          }
        )
        .then((response) => {
          if (
            this.state.currentPage > 1 &&
            (!response?.data?.list || response.data.list.length == 0)
          ) {
            this.setState({ currentPage: this.state.currentPage - 1 });
            this.refresh();
            return;
          }
          if (this.props.selectedItems && this.props.selectedItems.length > 0) {
            const distinctIds = [...response.data.list];

            this.setState({
              isLoading: false,
              filtrando: this.state.filtrandoBloqueado
                ? this.state.filtrando
                : !this.state.filtrando,
              filtrandoBloqueado: false,
              ...response.data,
              list: distinctIds,
            });
          } else {
            this.setState({
              isLoading: false,
              filtrando: this.state.filtrandoBloqueado
                ? this.state.filtrando
                : !this.state.filtrando,
              filtrandoBloqueado: false,
              ...response.data,
            });
          }
          this.props.setIsLoading && this.props.setIsLoading(false);
        })
        .catch((error) => {
          // exception silenciada
          if (error && error.message === EXCEPTION_CANCELLATION_TOKEN) return;

          errorHandler(error);
          this.setState({
            isLoading: false,
          });
          this.props.setIsLoading && this.props.setIsLoading(false);
        });
    }
  }

  handleExport(fileExtension) {
    const url = this.props.urlExportList
      ? this.props.urlExportList
      : `${this.props.url}/ExportList`;

    this.setState({
      isLoading: true,
    });

    let newSearchModel = {
      searchText: this.state.textoPesquisa,
      ...this.state.searchModelFilter,
    };

    if (this.props.modelValue) {
      newSearchModel = {
        searchText: this.state.textoPesquisa,
        ...this.props.modelValue,
      };
    }

    httpSelector
      .getHttp(this.props.viewType)
      .post(
        url,
        {
          model: newSearchModel,
          sort: this.state.sort,
          pageSize: 999999,
          parameters: this.props.parameters,
          fileExtension: fileExtension,
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });

        DownloadFile(response);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        errorHandler(ParseError(error));
      });
  }

  handlePDFClick() {
    this.handleExport('pdf');
  }

  handleExcelClick() {
    this.handleExport('xlsx');
  }

  handleEmailClick() {
    ContentManager.addContent(
      <Email
        url={this.props.url}
        model={{
          searchText: this.state.textoPesquisa,
          ...this.state.searchModelFilter,
        }}
        sort={this.state.sort}
        parameters={this.props.parameters}
      />
    );
  }

  handleGeoreferenciaClick() {
    ContentManager.addContent(
      <GeoreferenciaExibicao
        filtroId={this.props.filtroId}
        searchModel={{
          searchText: this.state.textoPesquisa,
          ...this.state.searchModelFilter,
        }}
      />
    );
  }

  searchLocal() {
    const { textoPesquisa, searchModelFilter } = this.state;

    this.setState({
      isLoading: false,
      list: this.props.localSearchFilter
        ? this.props.localSearchFilter({ ...searchModelFilter, textoPesquisa })
        : this.props.dataSource,
    });
  }

  handleNewClick(parentId, par) {
    this.handleClosePopover();
    if (this.props.handleNewClick) {
      this.props.handleNewClick();
    } else {
      if (this.props.local) {
        ContentManager.addContent(
          <this.props.editComponent
            paramsFiltro={this.state.searchEstatistica}
            childTitle={this.props.childTitle}
            mode="edit"
            handleClose={this.handleEditCloseLocal}
            parameters={this.props.parameters}
            {...this.props}
          />
        );
      } else if (this.props.editMode && this.props.editMode === 'page') {
        if (parentId) {
          history.push({
            pathname: `${this.props.urlEdit}`,
            search: `?id=${-1}&parentId=${parentId}`,
          });
        } else {
          history.push({
            pathname: `${this.props.urlEdit}`,
            search: `?id=${-1}`,
          });
        }
      } else {
        ContentManager.addContent(
          <this.props.editComponent
            paramsFiltro={this.state.searchEstatistica}
            mode="edit"
            handleClose={this.handleEditClose}
            parameters={{
              ...par,
              ...this.props.parameters,
              parentId: parentId,
            }}
            {...this.props}
          />
        );
      }
    }
  }

  handleEditClick(item, index) {
    this.handleClosePopover();
    if (this.props.local) {
      ContentManager.addContent(
        <this.props.editComponent
          childTitle={this.props.childTitle}
          item={item}
          indexItem={index}
          handleClose={this.handleEditCloseLocal}
          parameters={this.props.parameters}
          {...this.props}
        />
      );
    } else if (this.props.editMode && this.props.editMode === 'page') {
      history.push({
        pathname: `${this.props.urlEdit}`,
        search: `?id=${item.id}`,
      });
    } else {
      ContentManager.addContent(
        <this.props.editComponent
          item={item}
          handleClose={this.handleEditClose}
          parameters={this.props.parameters}
          {...this.props}
        />
      );
    }
  }

  handleEditCloseLocal(saved, isNew, item, index) {
    if (saved) {
      this.props.handleSaveItem &&
        this.props.handleSaveItem(saved, isNew, item, index);
      this.props.onEditClose && this.props.onEditClose();
    }
  }

  handleEditClose(saved) {
    if (saved || this.props.refreshOnClose) {
      this.refresh();
    } else {
      this.props.onEditClose && this.props.onEditClose();
    }
  }

  handleDeleteClick(item, index) {
    this.handleClosePopover();
    if (this.props.onDeleting) {
      this.props.onDeleting(item);
    } else {
      if (this.props.useDeleteCriticalConfirm) {
        ContentManager.addContent(
          <CriticalConfirm
            title={this.props.intl.formatMessage({
              id: 'excluir',
            })}
            message={
              <FormattedMessage id="label.desejaMesmoExcluirEsseRegistro" />
            }
            handleConfirm={this.handleDeleteClickConfirm.bind(
              this,
              item,
              index
            )}
            labelConfirm={this.props.intl.formatMessage({
              id: 'label.confirmarExclusao',
            })}
          />
        );
      } else {
        ContentManager.addContent(
          <Confirm
            message={
              <FormattedMessage id="label.desejaMesmoExcluirEsseRegistro" />
            }
            handleConfirm={this.handleDeleteClickConfirm.bind(
              this,
              item,
              index
            )}
          />
        );
      }
    }
  }

  handleDeleteClickConfirm(item, index) {
    if (this.props.local) {
      this.props.handleRemove && this.props.handleRemove(item, index);
    } else {
      http
        .post(`${this.props.url}/Remove`, {
          id: item.id,
          parameters: this.props.parameters,
        })
        .then(() => {
          ContentManager.addContent(
            <Alert
              title={<FormattedMessage id="sucesso" />}
              message={
                <FormattedMessage id="label.registroExcluidoComSucesso" />
              }
            />
          );
          this.refresh();
          this.props.onRemove && this.props.onRemove();
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }

  handleToggleClick(id) {
    let index = this.state.expandedIds.indexOf(id);
    if (index === -1) {
      this.state.expandedIds.push(id);
    } else {
      this.state.expandedIds.splice(index, 1);
    }

    this.setState({
      expandedIds: this.state.expandedIds,
    });
  }

  handlePopoverClick(id, tag) {
    this.setState({
      currentItemIndex: tag.target.id.toString() === id.toString() ? id : 0,
    });
  }

  handleClosePopover() {
    this.setState({
      currentItemIndex: -1,
    });
  }

  //exportar item do edit
  handleExportItem(item, extensaoExport) {
    if (this.props.urlExportItem) {
      this.setState({
        isLoading: true,
      });
      http
        .post(
          this.props.urlExportItem,
          {
            id: item.id,
            extensaoExport: extensaoExport,
            parameters: this.props.parameters,
          },
          {
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          this.setState({
            isLoading: false,
          });

          DownloadFile(response);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });

          errorHandler(ParseError(error));
        });
    }
  }

  isExpanded(item) {
    return this.state.expandedIds.indexOf(item.id) !== -1;
  }

  getHeaderText(column) {
    if (column.resource) {
      if (this.state.resources) {
        return this.state.resources[column.resource];
      } else {
        return column.headerText;
      }
    } else {
      return column.headerText;
    }
  }

  getCellPopoVer(item, column) {
    if (column.popoverFunction) {
      return column.popoverFunction(item);
    }
  }

  getCellContent(item, column, utilizaColunasDinamicas) {
    if (item.tipoExibicaoWidget == '2') return;

    if (column.valueFunction) {
      return column.valueFunction(item, this.handleEditClose);
    }

    if (column.valueField) {
      let value = item[column.valueField];

      if (utilizaColunasDinamicas && item.valoresColunasDinamicas) {
        const colunaEncontrada = item.valoresColunasDinamicas.find(
          (colunaDinamica) => colunaDinamica.idColuna == column.valueField
        );
        if (colunaEncontrada) value = colunaEncontrada.descricao;
      }

      if (column.formatFunction) {
        value = column.formatFunction(value);
      }

      if (item.campoCustomizado) {
        if (item.campoCustomizado.length > 0) {
          let campoCustomizado = item.campoCustomizado.find((i) =>
            i.valueField
              ? i.valueField == column.valueField
              : i.nomeCampo === column.valueField
          );
          if (campoCustomizado) value = campoCustomizado.valor;
        }
      }

      return value;
    }
  }

  setState(changes, callback) {
    let futureState = {
      ...this.state,
      ...changes,
    };

    let maxPage = Math.floor(futureState.count / futureState.pageSize) + 1;

    if (futureState.currentPage > maxPage) {
      changes.currentPage = maxPage;
    } else if (futureState.currentPage < 1) {
      changes.currentPage = 1;
    }

    super.setState(changes, callback);
  }

  changePageSize(pageSize) {
    this.savePageSize(pageSize);
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.refresh();
      }
    );
  }

  changeCurrentPage(newValue) {
    this.setState(
      {
        currentPage: newValue,
        selectAllItems: false,
      },
      () => {
        this.refresh();
      }
    );
  }

  async obterPageSize() {
    if (this.props.filtroId && this.props.showPage) {
      const customUrl = `/ListagemTelaColaborador/ObterPageSize`;
      await httpSelector
        .getHttp(this.props.viewType)
        .post(
          customUrl,
          {
            listId: this.props.filtroId,
          },
          {
            cancelToken: this.createCancelToken(customUrl),
          }
        )
        .then((response) => {
          if (response.data) {
            this.setState({
              pageSize: response.data,
            });
          }
        });
    }
  }

  async savePageSize(pageSize) {
    if (this.props.filtroId) {
      await http.post(`/ListagemTelaColaborador/SalvarPageSize`, {
        telaId: this.props.filtroId,
        pageSize: pageSize,
      });
    }
  }

  changeSort(sortField) {
    let newSortBy = this.state.sortBy === 'asc' ? 'desc' : 'asc';
    let newSortField = newSortBy ? sortField + ' ' + newSortBy : sortField;
    this.setState(
      {
        sort: newSortField,
        sortBy: newSortBy,
      },
      () => {
        this.refresh();
      }
    );
  }

  itemEstaSelecionado(item) {
    return this.state.selectedIds.indexOf(item.id) !== -1;
  }

  handleSelectAllItems(event, items) {
    const isChecked = event.target.checked;
    let selectedItems = [];
    let selectedItemsId = [];
    const { disableSelectedItems } = this.props;
    const itensFiltrados = items.filter(
      (item) =>
        !disableSelectedItems ||
        (disableSelectedItems && disableSelectedItems(item))
    );

    itensFiltrados.map((item) =>
      this.toggleItemMulti(item, selectedItems, selectedItemsId, isChecked)
    );

    if (!this.state.selectAllItems)
      this.props.selectIds && this.props.setSelectIds(selectedItemsId);

    this.setState({
      selectAllItems: !this.state.selectAllItems,
    });
  }

  toggleItemMulti(item, selectedItems, selectedItemsId, isChecked) {
    const { disableSelectedItems } = this.props;

    if (disableSelectedItems && disableSelectedItems(item)) return;

    if (!isChecked && this.itemEstaSelecionado(item)) {
      selectedItems = selectedItems.filter((i) => i.id !== item.id);
      selectedItemsId = selectedItemsId.filter((id) => id !== item.id);
    } else {
      selectedItems.push(item);
      selectedItemsId.push(item.id);
    }

    this.setState({
      selectedIds: selectedItemsId,
      selectedItems:
        selectedItems && selectedItems.length > 0
          ? selectedItems.map((item) => item)
          : [],
    });
  }

  toggleItem(item) {
    const { disableSelectedItems } = this.props;
    if (disableSelectedItems && disableSelectedItems(item)) return;

    let selectedIdsTemp = this.state.selectedIds;
    let selectedItemsTemp = this.state.selectedItems.map((item) => item);

    if (!this.props.multiple) {
      selectedIdsTemp = [];
      selectedItemsTemp = [];
    }

    if (this.itemEstaSelecionado(item)) {
      selectedIdsTemp = this.state.selectedIds.filter((id) => id !== item.id);
      selectedItemsTemp = this.state.selectedItems.filter(
        (i) => i.id !== item.id
      );
    } else {
      if (!this.props.multiple) {
        selectedIdsTemp = selectedIdsTemp.concat([item.id]);
        selectedItemsTemp.push(item);
      } else {
        selectedIdsTemp = this.state.selectedIds.concat([item.id]);
        selectedItemsTemp.push(item);
      }
    }

    this.setState(
      {
        selectedIds: selectedIdsTemp,
        selectedItems:
          selectedItemsTemp && selectedItemsTemp.length > 0
            ? selectedItemsTemp.map((item) => item)
            : [],
        selectAllItems: false,
      },
      () => {
        if (this.props.selectRealTime) {
          this.handleConfirmSelection();
        }
      }
    );

    this.props.selectIds && this.props.setSelectIds(selectedIdsTemp);
    this.props.selectIds && this.props.changeMultipleSelectDataEvent();
  }

  handleConfirmSelection() {
    if (this.props.selectedCallback) {
      this.props.selectedCallback(
        this.getAllSelectedItems(this.state.selectedItems)
      );
    }

    if (!this.props.selectRealTime) {
      this.refs.dialog.close();
    }
  }

  getAllSelectedItems(list, result = []) {
    let { hierarchy } = this.props;

    list.filter((item) => {
      if (this.itemEstaSelecionado(item)) {
        result.push(item);
      }
      if (hierarchy && item.children && item.children.length > 0) {
        this.getAllSelectedItems(item.children, result);
      }
      return null;
    });
    return result;
  }

  handleTextoPesquisaChange(value) {
    if (value)
      this.setState({
        textoPesquisa: value.target ? value.target.value : value,
      });

    clearTimeout(this.textoPesquisaDebounceId);
    this.textoPesquisaDebounceId = setTimeout(() => {
      this.refresh();
    }, 500);
  }

  handleCustomItemClick(item, customItem) {
    this.handleClosePopover();
    customItem.onClick &&
      customItem.onClick(item, customItem.parameters, this.handleEditClose);
  }

  getVisibleCreateChildItems(listItem, item) {
    return listItem.map((customItem, index) => {
      let visible = customItem.visible(item);
      if (visible) {
        return (
          <DropdownItem
            key={4 + index}
            onClick={this.handleCustomItemClick.bind(this, item, customItem)}
          >
            <MdiIcon className="mr-2" icon={customItem.icon} />
            {customItem.label}
          </DropdownItem>
        );
      }
    });
  }

  handleColunasTabelaClick(columns) {
    ContentManager.addContent(
      <ColunasTabelas
        columns={columns}
        allColumns={this.state.allColumns}
        tag={this.props.tag}
        handleAtualizarColunas={this.handleAtualizarColunas}
        isValidDragToUsedColumns={this.props.isValidDragToUsedColumns}
      />
    );
  }

  handleColunasKanbanClick() {
    ContentManager.addContent(
      <DialogColunas
        allColumnsKanban={this.state.allColumnsKanban}
        allColumnsCard={this.state.allColumnsCard}
        columnsKanban={this.state.columnsKanban}
        columnsCard={this.state.columnsCard}
        handleAtualizarCardKanban={this.handleAtualizarCardKanban}
        handleAtualizarColunasKanban={this.handleAtualizarColunasKanban}
      />
    );
  }

  handleSort(id, column) {
    let { sort, sortBy } = this.state;
    if (!sort) return;
    if (column.sortField == sort.split(' ')[0] && sortBy == 'desc') {
      return (
        <MdiIcon
          style={{ cursor: 'pointer' }}
          id={id}
          size="14"
          className="ml-2"
          icon="chevron-up"
        />
      );
    }

    if (column.sortField == sort.split(' ')[0] && sortBy == 'asc') {
      return (
        <MdiIcon
          style={{ cursor: 'pointer' }}
          id={id}
          size="14"
          className="ml-2"
          icon="chevron-down"
        />
      );
    }

    if (column.sortField)
      return (
        <MdiIcon
          style={{ cursor: 'pointer' }}
          id={id}
          size="14"
          className="ml-2"
          icon="unfold-more-horizontal"
        />
      );
  }

  handleCloseShowModal() {
    this.refs.dialog && this.refs.dialog.close();
  }

  handleEstatisticas() {
    ContentManager.addContent(
      <Estatisticas
        searchModel={this.state.searchEstatistica}
        parameters={this.state.parameters}
      ></Estatisticas>
    );
  }

  handleEstatisticasAcoes() {
    ContentManager.addContent(
      <EstatisticasAcoes searchModel={this.state.searchEstatistica} />
    );
  }

  handleViewChange(view) {
    this.props.usandoKanban && this.props.usandoKanban(view === 'kanban');
    this.setState({
      view: view,
    });
  }

  handleFiltroModal() {
    let {
      searchComponent,
      searchSchema,
      url,
      filtroId,
      showModal,
      usarFiltroSalvo,
      onChangeUrl,
      modelValue,
      tipoItem,
      modalClose,
      ...other
    } = this.props;
    let { selectedFilter, search } = this.state;
    this.setState({
      modelValue: modelValue,
    });

    ContentManager.addContent(
      <Filtro
        {...other}
        onChangeUrl={onChangeUrl}
        modelValue={modelValue}
        filtroId={filtroId}
        usarFiltroSalvo={usarFiltroSalvo}
        searchComponent={searchComponent}
        searchSchema={searchSchema}
        onSearch={this.handleSearch}
        showModal={showModal}
        url={url}
        selectedFilter={selectedFilter}
        search={search}
        tipoItem={tipoItem}
        modalClose={modalClose}
      />
    );
  }

  render() {
    if (this.props.showModal) {
      let { select } = this.props;
      let actions = select && [
        <Button onClick={this.props.remove} key="btnclose" color="secondary">
          <FormattedMessage id="label.cancelar" />
        </Button>,
        <Button
          key="bynSave"
          type="primary"
          style={{ marginLeft: 10 }}
          onClick={this.handleConfirmSelection}
        >
          <FormattedMessage id="label.confirmar" />
        </Button>,
      ];
      return (
        <Dialog
          title={this.props.title}
          customTitle={this.props.customTitle}
          padContent
          width={this.props.dialogWidth}
          height={this.props.dialogHeight}
          onRequestClose={this.handleCloseShowModal}
          mouseDownClose={false}
          ref="dialog"
          {...this.props}
          actions={
            this.props.actions && this.props.actions.length > 0
              ? this.props.actions
              : actions
          }
        >
          {this.renderContent()}
        </Dialog>
      );
    } else {
      return this.renderContent();
    }
  }

  renderTable(
    count,
    size,
    select,
    multiple,
    showMenu,
    list,
    columns,
    sortDragEnable,
    actionsWidthTemp,
    showHeadTable,
    stickyHeader,
    showIconDraggable
  ) {
    const styleShowHeadTable = !showHeadTable ? 'p-0 border-0' : '';
    const { showTotalRow, colunasDinamicas, utilizaColunasDinamicas } =
      this.props;
    if (utilizaColunasDinamicas && colunasDinamicas) {
      columns = columns.concat(colunasDinamicas);
    }

    return (
      <Table
        responsive={true}
        count={count}
        size={size}
        stickyHeader={stickyHeader}
        style={{ background: '#FFF' }}
      >
        {
          <thead className={styleShowHeadTable}>
            <tr>
              {select && !multiple && (
                <td style={{ width: 30 }} className={styleShowHeadTable} />
              )}
              {select && multiple && (
                <td style={{ width: 30 }} className={styleShowHeadTable}>
                  {!this.props.maxSelect && (
                    <Checkbox
                      id={`CHECK-ALL-${this.state.componentKey}`}
                      checked={this.state.selectAllItems}
                      onChange={() => this.handleSelectAllItems(event, list)}
                      disabled={
                        this.props.maxSelect
                          ? this.state.selectedIds?.length >=
                            this.props.maxSelect
                          : this.props.checkboxDisabled
                          ? this.props.checkboxDisabled
                          : false
                      }
                    />
                  )}
                </td>
              )}
              {showIconDraggable && (
                <th style={{ width: 30 }} className={styleShowHeadTable} />
              )}
              {showMenu && (
                <th style={{ width: 30 }} className={styleShowHeadTable} />
              )}
              {columns.map(
                (column, index) =>
                  column.visible && (
                    <th
                      className={'align-top ' + styleShowHeadTable}
                      key={index}
                      onClick={
                        column.sortField &&
                        this.changeSort.bind(this, column.sortField)
                      }
                      style={{
                        minWidth: column.minWidth ? column.minWidth : 'auto',
                        maxWidth: column.maxWidth ? column.maxWidth : 'auto',
                        width: column.width ? column.width : 'auto',
                      }}
                    >
                      {showHeadTable && (
                        <div
                          style={{ margin: column.center && '0 auto' }}
                          className={classes.tHeadContent}
                        >
                          <div className="d-inline">
                            {this.getHeaderText(column)}
                          </div>
                          {column.sortField && (
                            <div className="d-inline">
                              {this.handleSort(index, column)}
                            </div>
                          )}
                        </div>
                      )}
                    </th>
                  )
              )}
            </tr>
          </thead>
        }
        <tbody>
          {sortDragEnable
            ? this.renderRowsDroppable(columns, this.state.list)
            : this.renderRows(columns, this.state.list, actionsWidthTemp)}
          {showTotalRow && (
            <tr>{this.renderTotalRow(columns, this.state.list)}</tr>
          )}
        </tbody>
      </Table>
    );
  }

  renderDraggableTable(
    count,
    size,
    select,
    multiple,
    showMenu,
    list,
    columns,
    sortDragEnable,
    actionsWidthTemp,
    showHeadTable,
    stickyHeader,
    showIconDraggable
  ) {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="tableList">
          {(provided) => (
            <div ref={provided.innerRef}>
              {this.renderTable(
                count,
                size,
                select,
                multiple,
                showMenu,
                list,
                columns,
                sortDragEnable,
                actionsWidthTemp,
                showHeadTable,
                stickyHeader,
                showIconDraggable
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleTextoPesquisaChange(this.state.textoPesquisa);
      return;
    }
  };

  renderContent() {
    let columns = this.state.columnsComponentOrder
      ? this.state.columnsComponentOrder
      : this.state.columns.filter((x) => x.default);

    let pageCount = Math.ceil(this.state.count / this.state.pageSize);

    let {
      select,
      hierarchy,
      actionsWidth,
      showNew,
      showPage,
      showToolBar,
      showSearch,
      showMenu,
      local,
      actions,
      tag,
      showExport,
      intl,
      actionsLocal,
      size,
      modelValue,
      multiple,
      kanban,
      hideSearchText,
      showRemoveEmail,
      searchSchema,
      searchComponent,
      showTitle,
      title,
      handleSendEmails,
      emailsTooltip,
      showAnaliseCausa,
      handleShowAnaliseCausa,
      sortDragEnable,
      actionsToolBar,
      actionsToolBarEnd,
      autoFocus,
      CustomComponent,
      showCustomComponent,
      requiredCustomComponent,
      hideFiltroChip,
      renderNewButton,
      showHeadTable = true,
      stickyHeader = false,
      showRequired = false,
      showDownloadItems = false,
      hideSendEmail = false,
      showIconDraggable,
      ehReuniao,
      ehParticipantes,
      ehAcao,
    } = this.props;

    let { list, selectedFilter, view } = this.state;
    let actionsWidthTemp = actionsWidth ? actionsWidth : hierarchy ? 120 : 80;
    let count = local ? (list ? list.length : 0) : this.state.count;

    if (this.props.bloquearMultiplosRegistros && count > 0) {
      showNew = false;
    }

    return (
      <Card
        style={
          showRequired
            ? { ...this.props.styleCard, borderColor: 'red' }
            : this.props.styleCard
        }
      >
        {showToolBar && (
          <CardHeader>
            {showTitle && <h5>{title}</h5>}
            <div className="align-items-center d-flex justify-content-between">
              {showSearch && !hideSearchText ? (
                <div
                  style={{ width: 187, minWidth: 187 }}
                  className="d-inline-flex"
                >
                  <InputSearch
                    placeholder={intl.formatMessage({
                      id: 'label.pesquisaRapida',
                    })}
                    onKeyPress={this.handleKeyPress}
                    autoFocus={autoFocus}
                    value={this.state.textoPesquisa}
                    onChange={this.handleTextoPesquisaChange}
                  ></InputSearch>
                </div>
              ) : (
                <div></div>
              )}
              {!hideFiltroChip &&
              ((selectedFilter && selectedFilter.valor) ||
                selectedFilter ||
                modelValue) ? (
                <div style={{ minWidth: 40 }} className="mr-auto">
                  <FiltroChip
                    refreshFilterChip={
                      this.props.refreshFilterChip +
                      this.state.refreshFilterChip
                    }
                    updateProps={true}
                    modelValue={modelValue}
                    filtroId={this.props.filtroId}
                    onSearch={this.handleSearch}
                    searchSchema={searchSchema}
                    selectedFilter={
                      selectedFilter && selectedFilter.valor != null
                        ? selectedFilter.valor
                        : selectedFilter
                    }
                    onRemoveFilterChip={this.props.onRemoveFilterChip}
                    onRemoveSaveFilter={this.props.onRemoveSaveFilter}
                    preventRemoveFilterChip={this.props.preventRemoveFilterChip}
                    onChangeUrl={this.props.onChangeUrl}
                  />
                </div>
              ) : (
                !showCustomComponent && <div />
              )}

              <div className="d-inline-flex">
                {showCustomComponent && (
                  <Col>
                    <CustomComponent
                      {...this.props}
                      model={this.props.modelOrigem}
                      disabled={this.props.disabledCustom}
                      required={requiredCustomComponent}
                      selectedItems={this.state.selectedItems || []}
                    />
                  </Col>
                )}

                {showRemoveEmail && (
                  <Button
                    id="deleteEmails"
                    outline
                    className="ml-2"
                    color={'danger'}
                    onClick={() => {
                      this.props.removeFracassados &&
                        this.props.removeFracassados();
                    }}
                  >
                    {' '}
                    <MdiIcon icon="delete-outline" color="default" />
                    <UncontrolledTooltip
                      placement="bottom"
                      target="deleteEmails"
                    >
                      {intl.formatMessage({
                        id: 'excluirTentativasFracassadas',
                      })}
                    </UncontrolledTooltip>
                  </Button>
                )}
                {kanban && (
                  <ButtonGroup>
                    {['lista', 'kanban'].map((value) => {
                      let label = ''; //definindo label dos botões
                      if (value === 'lista')
                        label = intl.formatMessage({ id: 'label.lista' });
                      else if (value === 'kanban')
                        label = this.props.resources.kanban;
                      return (
                        <Button
                          color={view === value ? 'primary' : 'secondary'}
                          key={value}
                          eventkey={value}
                          onClick={() => this.handleViewChange(value)}
                        >
                          {label}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                )}
                {handleSendEmails && (
                  <div className="ml-2 d-inline">
                    <Button id="sendEmail" onClick={handleSendEmails}>
                      <MdiIcon icon="email" />
                    </Button>
                    <UncontrolledTooltip placement="bottom" target="sendEmail">
                      {intl.formatMessage({ id: emailsTooltip })}
                    </UncontrolledTooltip>
                  </div>
                )}
                {showAnaliseCausa && (
                  <Button
                    className="ml-2 d-inline"
                    id="showAnaliseCausa"
                    onClick={handleShowAnaliseCausa}
                  >
                    <MdiIcon icon="lightbulb-on-outline" />
                  </Button>
                )}
                {showSearch && searchComponent && (
                  <Button
                    className="ml-2 d-inline"
                    onClick={this.handleFiltroModal}
                  >
                    <MdiIcon icon="filter" />
                  </Button>
                )}
                {actionsToolBar &&
                  this.state.permissions &&
                  this.state.permissions.allowCreate &&
                  actionsToolBar.map((a, index) =>
                    React.cloneElement(a, {
                      key: index + 4,
                    })
                  )}
                {(showExport ||
                  (actions && actions.length > 0) ||
                  actionsLocal) && (
                  <UncontrolledDropdown
                    group
                    direction="left"
                    className="ml-2 d-inline"
                    key="list"
                  >
                    <DropdownToggle color="secondary" className="valores-step5">
                      <MdiIcon icon="dots-horizontal" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {actions &&
                        this.state.permissions &&
                        this.state.permissions.allowCreate &&
                        actions.map((a, index) =>
                          React.cloneElement(a, {
                            key: index + 4,
                          })
                        )}
                      {actionsLocal &&
                        actionsLocal.map((a, index) =>
                          React.cloneElement(a, {
                            key: index + 4,
                          })
                        )}
                      {showExport && actions && actions.length > 0 && (
                        <DropdownItem divider />
                      )}
                      {showExport && !hideSendEmail && (
                        <DropdownItem
                          eventkey="Drop1"
                          onClick={this.handleEmailClick}
                        >
                          <FormattedMessage id="enviarEmail" />
                        </DropdownItem>
                      )}
                      {showExport && (
                        <DropdownItem
                          eventkey="Drop2"
                          onClick={this.handlePDFClick}
                        >
                          <FormattedMessage id="label.exportarPDF" />
                        </DropdownItem>
                      )}
                      {showExport && (
                        <DropdownItem
                          eventkey="Drop3"
                          onClick={this.handleExcelClick}
                        >
                          <FormattedMessage id="label.exportarExcel" />
                        </DropdownItem>
                      )}
                      {(this.props.filtroId === 1 ||
                        this.props.filtroId === 3 ||
                        this.props.filtroId === 4) && (
                        <DropdownItem
                          eventkey="Drop4"
                          onClick={this.handleGeoreferenciaClick}
                        >
                          <FormattedMessage id="label.georeferencia" />
                        </DropdownItem>
                      )}
                      {this.props.showEstatistica && (
                        <DropdownItem
                          eventKey="5"
                          onClick={this.handleEstatisticas}
                        >
                          <FormattedMessage id="estatisticas" />
                        </DropdownItem>
                      )}
                      {this.props.showEstatisticaAcoes && (
                        <DropdownItem
                          eventKey="6"
                          onClick={this.handleEstatisticasAcoes}
                        >
                          <FormattedMessage id="estatisticas" />
                        </DropdownItem>
                      )}
                      {this.props.showArquivamento && (
                        <DropdownItem
                          eventKey="6"
                          onClick={() =>
                            history.push('/ferramentas/arquivar-acoes')
                          }
                        >
                          {intl.formatMessage(
                            { id: 'arquivarEmLote' },
                            { acoes: this.props.resources.acoes }
                          )}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {tag && !this.props.hideColumnsButton && (
                  <Button
                    className="ml-2 valores-step2"
                    color="secondary"
                    onClick={
                      view === 'kanban'
                        ? () => this.handleColunasKanbanClick()
                        : this.handleColunasTabelaClick.bind(
                            this,
                            this.state.columnsComponentOrder
                          )
                    }
                  >
                    <MdiIcon icon="format-columns" color="default" />
                  </Button>
                )}
                {!this.props.bloquearInsercaoManual &&
                  ((showNew &&
                    this.state.permissions &&
                    this.state.permissions.allowCreate) ||
                    (showNew && local)) &&
                  (renderNewButton ? (
                    <div className="ml-2">
                      {renderNewButton(this.handleNewClick)}
                    </div>
                  ) : (
                      <Button
                        className={`ml-2 ${ehReuniao ? 'reuniao-step1' : ehParticipantes ? 'reuniao-step4' : ehAcao ? 'acao-step1' : ''}`}
                        onClick={this.handleNewClick.bind(this, null, null)}
                      >
                        <MdiIcon icon="plus" />
                      </Button>
                  ))}
                {actionsToolBarEnd &&
                  actionsToolBarEnd.map((a, index) =>
                    React.cloneElement(a, {
                      key: index + 100,
                    })
                  )}
              </div>
            </div>
          </CardHeader>
        )}
        <CardBody>
          <Row>
            <Col md={12}>
              <LoadingContainer
                className={classes.LoadingContainer}
                isLoading={
                  !this.state.filtrandoBloqueado && this.state.isLoading
                }
              >
                {this.state.list && this.state.list.length > 0 ? (
                  <div>
                    {/* Feito dessa forma para preservar as páginas do kanban sem necessidade de redux, que não seria
                      utilizado sempre */}
                    <Row>
                      <Col
                        style={{ display: view == 'lista' && 'none' }}
                        md={12}
                      >
                        <Kanban
                          filtrandoBloqueado={this.state.filtrandoBloqueado}
                          handleChangeFiltrandoBloqueado={
                            this.handleChangeFiltrandoBloqueado
                          }
                          exportApresentacao={this.props.exportApresentacao}
                          columnsCard={this.state.columnsCard}
                          columnsKanban={this.state.columnsKanban}
                          handleDescricaoClick={this.props.handleDescricaoClick}
                          filtrando={this.state.filtrando}
                          //filtros
                          model={
                            this.state.searchEstatistica &&
                            this.state.searchEstatistica.valor
                              ? this.state.searchEstatistica.valor
                              : this.state.searchEstatistica
                          }
                          parameters={this.state.parameters}
                        />
                      </Col>
                      <Col
                        style={{ display: view == 'kanban' && 'none' }}
                        md={12}
                      >
                        {(this.state.permissions &&
                          this.state.permissions.allowView) ||
                        local ? (
                          sortDragEnable ? (
                            this.renderDraggableTable(
                              count,
                              size,
                              select,
                              multiple,
                              showMenu,
                              list,
                              columns,
                              sortDragEnable,
                              actionsWidthTemp,
                              showHeadTable,
                              stickyHeader,
                              showIconDraggable
                            )
                          ) : (
                            this.renderTable(
                              count,
                              size,
                              select,
                              multiple,
                              showMenu,
                              list,
                              columns,
                              sortDragEnable,
                              actionsWidthTemp,
                              showHeadTable,
                              stickyHeader,
                              showIconDraggable
                            )
                          )
                        ) : (
                          <p>
                            <FormattedMessage id="label.voceNaoTemPermissaoParaVisualizarEstesRegistros" />
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <p style={{ fontStyle: 'italic', padding: 20 }}>
                    <FormattedMessage id="label.nenhumRegistroFoiEncontrado" />
                  </p>
                )}
              </LoadingContainer>
            </Col>
          </Row>
          {view != 'kanban' && (
            <div>
              {this.props.showHierarchyPagination ||
              (!hierarchy && showPage && pageCount) ? (
                <Row>
                  <Col md={12}>
                    <div className="pull-right">
                      <Pagination
                        allowPaging={
                          this.props.showHierarchyPagination ||
                          !this.props.hierarchy
                        }
                        currentPage={this.state.currentPage}
                        count={this.state.count}
                        pageSize={this.state.pageSize}
                        showPaginacaoSimples={this.props.showPaginacaoSimples}
                        changeCurrentPage={this.changeCurrentPage}
                        changePageSize={this.changePageSize}
                        noLimitPageSize={this.props.noLimitPageSize}
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </div>
          )}
        </CardBody>
      </Card>
    );
  }

  renderMenu(item, indexItem) {
    let {
      hierarchy,
      createChildItems,
      showEdit,
      showDelete,
      renderMenu,
      showExportItem,
      renderMenuAddOptions,
    } = this.props;

    if (renderMenu && !renderMenuAddOptions) {
      return this.props.renderMenu(item);
    } else {
      return (
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="nav-link fixed">
            <MdiIcon icon="dots-vertical" size={18} />
          </DropdownToggle>
          <DropdownMenu container="body" placement="left">
            {item.permissions && showEdit && (
              <DropdownItem
                onClick={this.handleEditClick.bind(this, item, indexItem)}
              >
                {this.props.editContainer ?? (
                  <>
                    <MdiIcon className="mr-2" icon="pencil" />
                    <FormattedMessage
                      id="label.editar"
                      values={{ editar: this.props.resources.editar }}
                    />
                  </>
                )}
              </DropdownItem>
            )}
            {createChildItems &&
              item.permissions &&
              item.permissions.allowEdit &&
              this.getVisibleCreateChildItems(createChildItems, item)}
            {hierarchy && !createChildItems && (
              <DropdownItem
                onClick={this.handleNewClick.bind(this, item.id, null)}
              >
                <MdiIcon className="mr-2" icon="plus" />
                <FormattedMessage id="label.inserir" />
              </DropdownItem>
            )}
            {item.permissions && item.permissions.allowDelete && showDelete && (
              <DropdownItem
                onClick={this.handleDeleteClick.bind(this, item, indexItem)}
              >
                <MdiIcon className="mr-2" icon="delete-outline" />
                <FormattedMessage id="excluir" />
              </DropdownItem>
            )}
            {showExportItem && (
              <>
                <DropdownItem divider />
                <DropdownItem
                  onClick={this.handleExportItem.bind(this, item, 'pdf')}
                >
                  <MdiIcon className="mr-2" icon="file-pdf-box" />
                  <FormattedMessage id="label.exportarPDF" />
                </DropdownItem>
                <DropdownItem
                  onClick={this.handleExportItem.bind(this, item, 'xlsx')}
                >
                  <MdiIcon className="mr-2" icon="file-excel" />
                  <FormattedMessage id="label.exportarExcel" />
                </DropdownItem>
              </>
            )}
            {renderMenuAddOptions &&
              renderMenu &&
              renderMenu(item, this.props.parameters)}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  }

  renderFinalRow(item) {
    let { renderFinalRow, iconFinalRow } = this.props;

    if (renderFinalRow) {
      return this.props.renderFinalRow(item);
    } else {
      return <div>{iconFinalRow}</div>;
    }
  }

  renderTotalRow(columns, items) {
    const { renderTotalRow } = this.props;

    if (renderTotalRow) return renderTotalRow(columns, items);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    this.props.changeOrderList(result.source.index, result.destination.index);
  }

  renderRowsDroppable(columns, items) {
    let { select, showMenu, showFinalRow, showIconDraggable } = this.props;
    const { selectAllItems } = this.state;

    return items.map((item, indexItem) => (
      <Draggable
        index={indexItem}
        key={indexItem + ''}
        draggableId={indexItem + ''}
      >
        {(provided) => (
          <tr
            key={item.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...provided.draggableProps.style }}
          >
            {select && (
              <td>
                <Checkbox
                  id="draggable-checkbox"
                  checked={
                    selectAllItems
                      ? selectAllItems
                      : this.itemEstaSelecionado(item)
                  }
                  onChange={(item) => this.toggleItem.bind(this, item)}
                  disabled={
                    this.props.maxSelect
                      ? this.state.selectedIds?.length >= this.props.maxSelect
                      : this.props.checkboxDisabled
                      ? this.props.checkboxDisabled
                      : false
                  }
                />
                {this.props.messageSelectDisable && (
                  <UncontrolledTooltip placement="bottom" target="deleteEmails">
                    {this.props.messageSelectDisable}
                  </UncontrolledTooltip>
                )}
              </td>
            )}
            {showIconDraggable && (
              <td className="fixed-column fixed-row">
                {<MdiIcon size={22} icon="drag" />}
              </td>
            )}
            {showMenu && (
              <td className="fixed-column fixed-row">
                {this.renderMenu(item, indexItem)}
              </td>
            )}
            {columns.map(
              (column, index) =>
                column.visible && (
                  <td key={index}>
                    <div
                      id={'col' + column.valueField + item.id}
                      style={{
                        textAlign: column.center ? 'center' : 'left',
                        minWidth: '40px',
                        maxHeight: 100,
                        verticalAlign: 'middle',
                        paddingLeft: 0,
                        wordBreak: column.wordBreak ? 'break-word' : null,
                      }}
                    >
                      {this.getCellContent(item, column, false)}
                      {this.getCellPopoVer(item, column)}
                    </div>
                  </td>
                )
            )}
            {showFinalRow && (
              <td style={{ width: '10px' }}>
                {this.renderFinalRow(item, indexItem)}
              </td>
            )}
          </tr>
        )}
      </Draggable>
    ));
  }

  renderColunasDinamicas(item) {
    const { colunasDinamicas } = this.props;
    return (
      <Fragment>
        {' '}
        {colunasDinamicas.map(
          (column, index) =>
            column.visible && (
              <td key={`dinam+${index}`}>
                <div
                  id={'col' + column.valueField + item.id}
                  style={{
                    textAlign: column.center ? 'center' : 'left',
                    minWidth: column.minWidth ? column.minWidth : 40,
                    maxHeight: 100,
                    minHeight: column.minHeight ?? 0,
                    overflowY: showScrollColumn ? 'auto' : 'visible',
                    verticalAlign: 'middle',
                    paddingLeft: column.tree ? level * 20 : 0,
                    wordBreak: column.wordBreak ? 'break-word' : null,
                  }}
                >
                  {this.getCellContent(item, column)}
                  {this.getCellPopoVer(item, column)}
                </div>
              </td>
            )
        )}
      </Fragment>
    );
  }

  renderRows(columns, items, actionsWidth, level = 0) {
    let {
      select,
      hierarchy,
      showMenu,
      showScrollColumn,
      showFinalRow,
      disableSelectedItems,
      hintOnDisabledItem,
      utilizaColunasDinamicas,
      allColumns,
    } = this.props;
    const { selectAllItems } = this.state;
    const colorIcon = '#939393';

    return (
      items &&
      items.map((item, indexItem) => [
        <tr key={item.id}>
          {select && (
            <td
              id={`ROW-CHECK-COLUMN-${item.id}-${this.state.componentKey}`}
              title={
                disableSelectedItems &&
                disableSelectedItems(item) &&
                hintOnDisabledItem(item)
              }
            >
              <Checkbox
                id={`ROW-CHECK-${item.id}-${this.state.componentKey}`}
                checked={
                  selectAllItems
                    ? selectAllItems &&
                      (!disableSelectedItems || !disableSelectedItems(item))
                    : this.itemEstaSelecionado(item)
                }
                onChange={this.toggleItem.bind(this, item)}
                disabled={
                  (disableSelectedItems && disableSelectedItems(item)) ||
                  (this.props.maxSelect
                    ? this.state.selectedIds?.length >= this.props.maxSelect &&
                      !this.itemEstaSelecionado(item)
                    : this.props.checkboxDisabled
                    ? this.props.checkboxDisabled
                    : false)
                }
              />
              {this.props.messageSelectDisable &&
                this.props.maxSelect &&
                this.state.selectedIds?.length >= this.props.maxSelect &&
                !this.itemEstaSelecionado(item) && (
                  <UncontrolledTooltip
                    placement="right"
                    target={`ROW-CHECK-COLUMN-${item.id}-${this.state.componentKey}`}
                  >
                    {this.props.messageSelectDisable}
                  </UncontrolledTooltip>
                )}
              {disableSelectedItems &&
                disableSelectedItems(item) &&
                hintOnDisabledItem &&
                hintOnDisabledItem(item) &&
                this.state.selectedIds?.length >= 0 && (
                  <UncontrolledTooltip
                    placement="right"
                    target={`ROW-CHECK-COLUMN-${item.id}-${this.state.componentKey}`}
                  >
                    {hintOnDisabledItem(item)}
                  </UncontrolledTooltip>
                )}
            </td>
          )}
          {showMenu && <td>{this.renderMenu(item, indexItem)}</td>}
          {columns.map(
            (column, index) =>
              column.visible && (
                <td key={index}>
                  <div
                    id={'col' + column.valueField + item.id}
                    style={{
                      color: this.props.getCorLinha
                        ? this.props.getCorLinha(item)
                        : '',
                      textAlign: column.center ? 'center' : 'left',
                      minWidth: column.minWidth ? column.minWidth : 40,
                      maxHeight: 100,
                      minHeight: column.minHeight ?? 0,
                      overflowY: showScrollColumn ? 'auto' : 'visible',
                      verticalAlign: 'middle',
                      paddingLeft: column.tree ? level * 20 : 0,
                      wordBreak: column.wordBreak ? 'break-word' : null,
                    }}
                  >
                    {column.tree &&
                      ((!!(
                        hierarchy &&
                        item.children &&
                        item.children.length
                      ) && (
                        <MdiIcon
                          className={classes.hierarchy}
                          color={colorIcon}
                          icon={
                            this.isExpanded(item)
                              ? 'chevron-down'
                              : 'chevron-right'
                          }
                          onClick={this.handleToggleClick.bind(this, item.id)}
                        />
                      )) || (
                        <MdiIcon
                          color={colorIcon}
                          className={classes.hierarchy}
                        />
                      ))}
                    {this.getCellContent(item, column, utilizaColunasDinamicas)}
                    {this.getCellPopoVer(item, column)}
                  </div>
                </td>
              )
          )}
          {showFinalRow && (
            <td style={{ width: '10px' }}>
              {this.renderFinalRow(item, indexItem)}
            </td>
          )}
        </tr>,
        this.state.expandedIds.indexOf(item.id) !== -1 &&
          this.renderRows(columns, item.children, actionsWidth, level + 1),
      ])
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    viewType: state.viewType,
  };
}
export default injectIntl(connect(mapStateToProps)(List));
