import React from 'react';
import DateTime from 'react-datetime';
import { Input, Popover } from 'reactstrap';
import CalendarContainer from 'react-datetime/src/CalendarContainer';
import './style.scss';
import { autobind } from 'core-decorators';
import guid from 'src/utils/guid';
import moment from 'moment';

@autobind
export default class TetheredDateTime extends DateTime {
  constructor(props) {
    super(props);
    this.guid = guid();
  }

  toggleOpen() {
    this.setState((prevState) => {
      return {
        ...prevState,
        open: !prevState.open,
        openCalendarTime: !prevState.openCalendar,
      };
    });
  }

  close() {
    this.setState({
      open: false,
      openCalendarTime: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.closeCalendarDataInicio !== this.props.closeCalendarDataInicio){
     this.close();
    }
  }

  preventValue(event) {
    const { timeFormat, month } = this.props;
    if (
      event.charCode === 8 ||
      event.charCode === 46 ||
      (timeFormat && (event.charCode === 32 || event.charCode === 58))
    )
      return;

    const tamanhoMaximo = month ? 7 : timeFormat ? 16 : 10;

    if (!/[0-9/]/.test(event.key)) {
      event.preventDefault();
      return;
    }

    const isSlash = event.charCode == 47;

    const countSlash = (event.target.value.match(/[/]/g) || []).length;
    const valorAtual = event.target.value;
    const numerosData = valorAtual.split('/');

    if (
      !isSlash &&
      countSlash === 0 &&
      valorAtual &&
      valorAtual.length > 1 &&
      valorAtual.length < 3 &&
      countSlash === 0
    ) {
      event.target.value = event.target.value + '/';
    } else if (
      !isSlash &&
      !month &&
      countSlash < 2 &&
      numerosData &&
      numerosData.length > 0 &&
      numerosData[1].length > 1
    ) {
      event.target.value = valorAtual + '/';
    }

    if (
      countSlash === 2 &&
      valorAtual.length >= 5 &&
      valorAtual.length < tamanhoMaximo
    ) {
      const numeroFinal = month
        ? ('0' + numerosData[0]).slice(-2) + '/' + numerosData[1]
        : ('0' + numerosData[0]).slice(-2) +
          '/' +
          ('0' + numerosData[1]).slice(-2) +
          '/' +
          numerosData[2];

      event.target.value = numeroFinal;
    }
  }

  onChange(event) {
    const regex = /^([0-9]|\/)*$/;
    if (!regex.test(event.target.value)) {
      event.preventDefault();
      return;
    }
    this.onInputChange(event);
  }

  onBlur(event) {
    if (!event?.target?.value) return;

    const { timeFormat, month } = this.props;
    const countSlash = (event.target.value.match(/[/]/g) || []).length;
    const valorAtual = event.target.value;
    const numerosData = valorAtual.split('/');
    const tamanhoMaximo = month ? 7 : timeFormat ? 16 : 10;

    if (
      (!month &&
        countSlash === 2 &&
        valorAtual.length > 5 &&
        valorAtual.length < tamanhoMaximo) ||
      (month &&
        countSlash === 1 &&
        valorAtual.length > 4 &&
        valorAtual.length < tamanhoMaximo)
    ) {
      const moment = require('moment');
      let year = month ? numerosData[1] : numerosData[2];
      let time = '';

      const yearAndTime = timeFormat
        ? null
        : month
        ? numerosData[1].split(' ')
        : numerosData[2].split(' ');

      if (yearAndTime) year = yearAndTime[0];

      if (
        (month && numerosData[1].length === 2) ||
        (!month && numerosData[2].length === 2)
      ) {
        const date = moment(month ? numerosData[1] : numerosData[2], 'YY');
        year = date.format('YYYY');
      }

      if (yearAndTime && yearAndTime.length > 1)
        time = yearAndTime[1] ? yearAndTime[1] : ' 00:00';
      else if (timeFormat && year.length === 4) time = ' 00:00';

      const numeroFinal = month
        ? ('0' + numerosData[0]).slice(-2) + '/' + year
        : ('0' + numerosData[0]).slice(-2) +
          '/' +
          ('0' + numerosData[1]).slice(-2) +
          '/' +
          year +
          time;

      this.setState({ inputValue: numeroFinal });

      if (numeroFinal) {
        const data = moment(numeroFinal, month ? 'MM/YYYY' : 'DD/MM/YYYY');
        this.props.onChange(data);
      }
    }
  }

  isValidDate(current) {
    if(this.props.disableFutureDates) {
      const today = moment(); 
      return current.isSameOrBefore(today, 'day'); 
    } 
    return true;
  }

  render() {
    const id = `Calendar-${this.guid}`;
    const { autoFormatDate, timeFormat, month } = this.props;

    return (
      <div
        className={`rdt rdtStatic ${
          this.props.className ? this.props.className : ''
        }`}
        id={id}
      >
        {this.props.input && (
          <Input
            key="i"
            type="text"
            onClick={this.toggleOpen}
            onChange={(event) => this.onChange(event)}
            onKeyDown={this.onInputKey}
            value={this.state.inputValue}
            style={this.props.style}
            onKeyPress={(event) => {
              autoFormatDate && this.preventValue(event);
            }}
            onBlur={(event) => {
              autoFormatDate && this.onBlur(event);
            }}
            maxLength={
              autoFormatDate ? (month ? '7' : timeFormat ? '16' : '10') : ''
            }
            {...this.props.props}
            {...this.props.inputProps}
            autoFocus={false}
            dispatch={null}
          />
        )}
        <div onMouseLeave={this.close}>
          <Popover
            hideArrow
            isOpen={timeFormat ? this.state.openCalendarTime : this.state.open}
            placement={this.props.placement ? this.props.placement : 'auto'}
            target={id}
          >
            <div className="rdtPicker">
              <CalendarContainer
                autoFocus={false}
                view={this.state.currentView}
                viewProps={{
                  ...this.getComponentProps(),
                  isValidDate: this.isValidDate,
                  }}
                onClickOutside={this.close}
                oncloseOnSelect={this.close}
              />
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}
