import { useState } from 'react';

const grayColor = '#D9D9D9';
const mainColor = '#1D7789';

function ButtonGroup({ initialActiveId = 1, items, className }) {
  const [activeItem, setActiveItem] = useState({ id: initialActiveId });

  const handleClick = (item) => {
    setActiveItem(item);
    item.callback();
  };

  return (
    <div
      className={`d-inline-flex overflow-hidden ${className ?? ''}`}
      style={{
        backgroundColor: grayColor,
        borderRadius: 4,
      }}
    >
      {items?.map((i) => {
        const isActive = activeItem?.id === i.id;
        return (
          <div
            className={`cursor-pointer font-weight-bolder ${
              isActive ? 'text-white' : 'text-gray'
            }`}
            onClick={() => handleClick(i)}
            style={{
              backgroundColor: isActive ? mainColor : grayColor,
              borderRadius: 4,
              padding: '8px 20px',
              boxShadow: isActive
                ? '2px 1px 2px rgba(0, 0, 0, 0.15), -2px 1px 2px rgba(0, 0, 0, 0.15)'
                : '',
              zIndex: isActive ? 2 : 1,
              cursor: 'pointer',
            }}
          >
            {i.description}
          </div>
        );
      })}
    </div>
  );
}

export default ButtonGroup;
