import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import Panel from 'src/componentes/panel';
import { FormGroup, Input, Label } from 'src/componentes/Form';
import Button from 'src/componentes/button';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/errorHandler';
import ContentManager from 'src/componentes/content-manager';
import EmbedPowerBI from 'src/paginas/power-bi/componentes/power-bi-dialog';

const FiltroItemRelatorioPowerBI = ({
  model,
  disabled,
  itemId,
  tipoItemId,
}) => {
  const intl = useIntl();

  const handleChange = (index, value) => {
    let item = model.value;

    if (item?.filtros.length > 0) {
      item.filtros[index].valor = value;
    }

    model.requestChange(item);
  };

  const handleRelatorioBIClick = () => {
    http
      .post(`/PowerBI/ObterConfiguracoesPorTipoItem`, {
        itemId: itemId,
        tipoItemId: tipoItemId,
      })
      .then((response) => {
        ContentManager.addContent(
          <EmbedPowerBI
            itemId={itemId}
            relatorioPBI={response.data.model.relatorioPBI}
            embedURL={response.data.model.embedURL}
            workspacePBI={response.data.model.workspacePBI}
            filtros={response.data.model.filtros}
            embedToken={response.data.model.embedToken}
            usarAutenticacaoMicrosoft={response.data.model.usarAutenticacaoMicrosoft}
          />
        );
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  return (
    <Panel
      header={`${intl.formatMessage({ id: 'filtros' })} ${intl.formatMessage({
        id: 'powerBI',
      })} - "${model.value.nome}"`}
      headerStyle={{ display: 'inline-flex' }}
      headerContent={
        <Button
          onClick={() => handleRelatorioBIClick()}
          className="pull-right"
          outline
        >
          {intl.formatMessage({ id: 'powerBI' })}
        </Button>
      }
    >
      <Row>
        {model.value.filtros.map((filtro, index) => (
          <Col md={3} key={filtro.id}>
            <FormGroup>
              <Label>{filtro.coluna}</Label>
              <Input
                style={{ height: '33px' }}
                disabled={disabled}
                value={filtro.valor}
                onChange={(value) => handleChange(index, value.target.value)}
              />
            </FormGroup>
          </Col>
        ))}
      </Row>
    </Panel>
  );
};

export default FiltroItemRelatorioPowerBI;
