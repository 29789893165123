import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import CategoriaEdit from '../paginas/remuneracao-variavel/categoria/edit';
import CategoriaList from '../paginas/remuneracao-variavel/categoria/view';

@autobind
class CategoriaLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueComponent={this.renderFuncao}
        valueKey="id"
        labelKey="nome"
        showSearch
        clearable={true}
        url="/CategoriaLookup"
        editComponent={CategoriaEdit}
        searchComponent={CategoriaList}
        {...this.props}
      />
    );
  }
}

export default CategoriaLookup;
