/* eslint-disable no-undef */
import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
} from 'react-google-maps';
import { debounce } from 'lodash';

import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import {
  compose,
  withProps,
  lifecycle,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import Input from '../../../componentes/input';
import { autobind } from 'core-decorators';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Col, Row, ListGroup, ListGroupItem } from 'reactstrap';
import Button from '../../../componentes/button';
import LoadingContainer from '../../../componentes/loading-container';
import { FormattedMessage } from 'react-intl';
import SvgIcon from '../../../componentes/svg-icon';
import history from '../../../history';
import Mensagem from '../../mensagem/modal';
import MdiIcon from '../../../componentes/mdi-icon';
import css from '../../../utils/css';
import FichaIndicador from '../../indicador/ficha';
import FichaObjetivo from '../../objetivo-estrategico-base/ficha';
import ContentManager from '../../../componentes/content-manager';
import guid from '../../../utils/guid';

let classes = css`
  .iconAction {
    margin-right: 5px;
  }
`;

const MapWithASearchBox = compose(
  withHandlers({
    handleFichaClick: (props) => (item) => {
      props.onClose && props.onClose();
      ContentManager.addContent(
        props.filtroId === 1 ? (
          <FichaIndicador id={item.idItem} />
        ) : props.filtroId === 3 ? (
          <FichaObjetivo id={item.idItem} />
        ) : (
          ''
        )
      );
    },

    handleEditar: (props) => (item) => {
      props.onClose && props.onClose();

      history.push({
        pathname:
          props.filtroId === 1
            ? '/gerenciar/indicador/edit'
            : props.filtroId === 3
            ? '/gerenciar/objetivo-estrategico/edit'
            : '',
        search: `?id=${item.idItem}`,
      });
    },

    handleValoresIndicador: (props) => (item) => {
      props.onClose && props.onClose();
      history.push({
        pathname: '/gerenciar/valor',
        search: `?itemId=${item.idItem}`,
      });
    },

    handleRelacionamentosClick: (props) => (item) => {
      props.onClose && props.onClose();
      history.push({
        pathname: '/gerenciar/arvore',
        search: `?itemId=${item.idItem}`,
      });
    },

    handlePlanoAcaoClick: (props) => (item) => {
      props.onClose && props.onClose();
      history.push({
        pathname: '/gerenciar/acao',
        search: `?itemId=${item.idItem}`,
      });
    },

    handleMensagemClick: (props) => (item) => {
      props.onClose && props.onClose();
      ContentManager.addContent(<Mensagem item={{ id: item.idItem }} />);
    },
  }),

  withStateHandlers(
    () => ({
      isOpen: false,
      idItem: 0,
    }),
    {
      onToggleOpen: () => (idItem) => ({
        idItem: idItem,
      }),
    }
  ),

  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.NODE_ENV == 'development'
        ? 'AIzaSyAEYlc4rn4dBNWnRLYK4GS0vN9Q6KYKxDk'
        : 'AIzaSyC6fSTY_30meoqCrbHjdm3GSKZtKRP4lWI'
    }`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),

  lifecycle({
    UNSAFE_componentWillReceiveProps(newProps) {
      if (newProps.model.value !== this.props.model.value) {
        let pos = null;

        if (this.props.markers !== '') {
          for (let i = 0; i < this.props.markers.length; i++) {
            if (this.props.markers[i].position.lat != null) {
              pos = this.props.markers[i];
              break;
            }
          }
        }

        let centro = pos !== null ? pos : '';

        this.setState({
          bounds: null,
          center: {
            lat:
              centro !== ''
                ? centro.position.lat
                : parseFloat(this.props.model.value.lat),
            lng:
              centro !== ''
                ? centro.position.lng
                : parseFloat(this.props.model.value.lng),
          },
          centerPadrao: {
            lat: -19.912998,
            lng: -43.940933,
          },
          markers: this.props.markers,
        });
      }
    },
    componentWillMount() {
      const refs = {};
      let pos = null;

      if (this.props.markers !== '') {
        for (let i = 0; i < this.props.markers.length; i++) {
          if (this.props.markers[i].position.lat != null) {
            pos = this.props.markers[i];
            break;
          }
        }
      }

      let centro = pos !== null ? pos : '';

      this.setState({
        bounds: null,
        center: {
          lat:
            centro !== ''
              ? centro.position.lat
              : parseFloat(this.props.model.value.lat),
          lng:
            centro !== ''
              ? centro.position.lng
              : parseFloat(this.props.model.value.lng),
        },
        centerPadrao: {
          lat: -19.912998,
          lng: -43.940933,
        },
        markers: this.props.markers,
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onBoundsChanged: debounce(
          () => {
            this.setState({
              bounds:
                refs && refs.map && refs.map.getBounds && refs.map.getBounds(),
              center:
                refs && refs.map && refs.map.getCenter && refs.map.getCenter(),
            });
            let { onBoundsChange } = this.props;
            if (onBoundsChange) {
              onBoundsChange(refs.map);
            }
          },
          100,
          { maxWait: 5000 }
        ),
      });
    },
  }),

  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={props.center.lat ? 12 : 3}
    center={props.center.lat ? props.center : props.centerPadrao}
    onBoundsChanged={props.onBoundsChanged}
  >
    {props.model.value &&
      props.model.value.lat &&
      props.model.value.lng &&
      props.farol && (
        <InfoBox
          key={guid()}
          defaultPosition={
            new google.maps.LatLng(props.model.value.lat, props.model.value.lng)
          }
          options={{ closeBoxURL: ``, enableEventPropagation: true }}
        >
          <div style={{ cursor: 'pointer' }}>
            <span
              title={props.nomeItem ? props.nomeItem : ''}
              className="list-title-item list-item-comum"
            >
              <SvgIcon
                icon={
                  props.farol.value ? props.farol.value.icone : 'desatualizado'
                }
                color={props.farol.value ? props.farol.value.cor : '#696969'}
                colorHover={
                  props.farol.value ? props.farol.value.cor : '#696969'
                }
                size={25}
              ></SvgIcon>
            </span>
          </div>
        </InfoBox>
      )}
    {props.markers &&
      props.markers.map(
        (marker) =>
          marker.position.lat &&
          marker.position.lng && (
            <InfoBox
              key={marker.idItem}
              defaultPosition={
                new google.maps.LatLng(marker.position.lat, marker.position.lng)
              }
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div
                //onClick={() => props.onToggleOpen(marker.idItem)}
                style={{ cursor: 'pointer' }}
              >
                <span
                  title={marker.nomeItem ? marker.nomeItem : ''}
                  className="list-title-item list-item-comum"
                >
                  <SvgIcon
                    icon={marker.farol ? marker.farol.icone : ''}
                    color={marker.farol ? marker.farol.cor : ''}
                    colorHover={marker.farol ? marker.farol.cor : ''}
                    size={25}
                  ></SvgIcon>
                </span>
                {props.idItem === marker.idItem && (
                  <InfoWindow
                    defaultPosition={
                      new google.maps.LatLng(
                        marker.position.lat,
                        marker.position.lng
                      )
                    }
                  >
                    <ListGroup>
                      <ListGroupItem onClick={() => props.handleEditar(marker)}>
                        <MdiIcon className={classes.iconAction} icon="pencil" />
                        <FormattedMessage
                          id="label.editar"
                          values={{ editar: resources.editar }}
                        />
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => props.handleFichaClick(marker)}
                      >
                        <MdiIcon
                          className={classes.iconAction}
                          icon="eye-outline"
                        />{' '}
                        <FormattedMessage
                          id="label.ficha"
                          values={{ ficha: resources.ficha }}
                        />
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => props.handleValoresIndicador(marker)}
                      >
                        <MdiIcon
                          className={classes.iconAction}
                          icon="format-list-numbered"
                        />{' '}
                        <FormattedMessage id="valoresNumericos" />
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => props.handleRelacionamentosClick(marker)}
                      >
                        <MdiIcon
                          className={classes.iconAction}
                          icon="sitemap"
                        />{' '}
                        {capitalizeFirstLetter(resources.relacionamentos)}
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => props.handlePlanoAcaoClick(marker)}
                      >
                        <MdiIcon
                          className={classes.iconAction}
                          icon="chart-gantt"
                        />
                        Plano de ação
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => props.handleMensagemClick(marker)}
                      >
                        <MdiIcon
                          className={classes.iconAction}
                          icon="comment-text-outline"
                        />{' '}
                        <FormattedMessage id="label.mensagens" />
                      </ListGroupItem>
                    </ListGroup>
                  </InfoWindow>
                )}
              </div>
            </InfoBox>
          )
      )}
    {props.model.value &&
      props.model.value.lat &&
      props.model.value.lng &&
      props.enderecoFixo && <Marker position={props.model.value} />}
  </GoogleMap>
));

@autobind
class referencia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      endereco: null,
      latitude: null,
      longitude: null,
      isLoading: false,
      listaItensCoordenadas: this.props.listaItensCoordenadas
        ? this.props.listaItensCoordenadas
        : '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.endereco !== prevProps.endereco ||
      this.props.latitude !== prevProps.latitude ||
      this.props.longitude !== prevProps.longitude
    ) {
      this.setState({
        endereco: this.props.endereco ? this.props.endereco.value : null,
        latitude: this.props.latitude ? this.props.latitude.value : null,
        longitude: this.props.longitude ? this.props.longitude.value : null,
      });
    }
  }

  searchedCallback(lat, lng, endereco) {
    this.setState(
      {
        isLoading: false,
        latitude: lat,
        longitude: lng,
        endereco: endereco,
      },
      () => {
        this.props.latitude.requestChange(lat);
        this.props.longitude.requestChange(lng);
        this.props.endereco.requestChange(endereco);

        this.props.georeferenciaAlterada &&
          this.props.georeferenciaAlterada.requestChange(true);
        this.props.getAlteracao && this.props.getAlteracao();
      }
    );
  }

  handleSearch() {
    this.setState({
      isLoading: true,
    });

    //primeiro valida se o campo de endereço está vazio, se estiver, executa a busca por latitude e longitude
    if (this.state.endereco != null && this.state.endereco != '') {
      this.props.latitude.value = parseFloat(0.0);
      this.props.longitude.value = parseFloat(0.0);

      geocodeByAddress(this.state.endereco)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          this.searchedCallback(latLng.lat, latLng.lng, this.state.endereco);
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    } else this.handleGeocodeReverse();
  }

  //Função para realizar a busca por endereço inversa, através da entrada de uma latitude e longitude
  handleGeocodeReverse() {
    let geocoder = new google.maps.Geocoder();
    var latLng = {
      lat: parseFloat(this.state.latitude),
      lng: parseFloat(this.state.longitude),
    };

    geocoder
      .geocode({ location: latLng }, (results, status) => {
        if (status === 'OK' && results[0])
          this.searchedCallback(
            latLng.lat,
            latLng.lng,
            results[0].formatted_address
          );

        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleChangeLng(value) {
    this.setState({
      longitude: value,
      endereco: null,
    });

    if (this.props.onChange && this.props.longitudeField) {
      this.props.onChange(this.props.longitudeField, value);

      if (this.props.enderecoField) {
        this.props.onChange(this.props.enderecoField, null);
      }
    }
  }

  handleChangeLat(value) {
    this.setState({
      latitude: value,
      endereco: null,
    });

    if (this.props.onChange && this.props.latitudeField) {
      this.props.onChange(this.props.latitudeField, value);

      if (this.props.enderecoField) {
        this.props.onChange(this.props.enderecoField, null);
      }
    }
  }

  handleChangeEndereco(value) {
    this.setState({
      endereco: value,
    });

    if (this.props.onChange && this.props.enderecoField) {
      this.props.onChange(this.props.enderecoField, value);
    }
  }

  disableFields() {
    if (!this.state.endereco && !this.state.latitude && !this.state.longitude)
      return true;
    else if (this.state.latitude && !this.state.longitude) return true;
    else if (this.state.longitude && !this.state.latitude) return true;

    return false;
  }

  render() {
    let { disabled } = this.props;

    let { latitude, longitude, endereco, enderecoFixo, farol, nomeItem } =
      this.props;

    return (
      <LoadingContainer isLoading={this.state.isLoading}>
        {this.props.exibir && (
          <Row>
            <Col md={5}>
              <Input
                model={{
                  label: endereco.label,
                  value: this.state.endereco,
                  requestChange: this.handleChangeEndereco,
                }}
                disabled={disabled}
              />
            </Col>
            <Col md={2}>
              <Input
                model={{
                  label: latitude.label,
                  value: this.state.latitude,
                  requestChange: this.handleChangeLat,
                }}
                disabled={disabled}
              />
            </Col>
            <Col md={2}>
              <Input
                model={{
                  label: longitude.label,
                  value: this.state.longitude,
                  requestChange: this.handleChangeLng,
                }}
                disabled={disabled}
              />
            </Col>
            <Col md={3}>
              <Button
                disabled={disabled || this.disableFields()}
                onClick={this.handleSearch}
                style={{ marginTop: 25 }}
                className="pull-right"
              >
                <FormattedMessage
                  id="label.pesquisarNoMapa"
                  disabled={disabled}
                />
              </Button>
            </Col>
          </Row>
        )}
        {
          <MapWithASearchBox
            enderecoFixo={enderecoFixo}
            farol={farol}
            nomeItem={nomeItem}
            model={{ value: { lat: latitude.value, lng: longitude.value } }}
            markers={this.state.listaItensCoordenadas}
            onClose={this.props.onClose}
            filtroId={this.props.filtroId}
          />
        }
      </LoadingContainer>
    );
  }
}

export default referencia;
