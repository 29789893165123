import React from 'react';
import Edit from '../../../componentes/edit/edit-dialog';
import Input from '../../../componentes/input';
import { autobind } from 'core-decorators';
import { Schema } from '../../../utils/form';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../../utils/string';
import CheckBox from '../../../componentes/checkbox';
import UnidadeMedidaLookup from '../../../lookups/unidade-medida';

let schema = Schema.object({
  id: Schema.string(),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  unidadeMedida: Schema.string()
    .label(<FormattedMessage id="unidadeDeMedida" />)
    .required(),
  parametroGlobal: Schema.string().label(<FormattedMessage id="label.parametroGlobal" />),
  consolidarValores: Schema.string().label(<FormattedMessage id="label.consolidarValores" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, resources, intl } = this.props;

    return (
      <div>
        <Row>
          <Col md={3}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
          <Col md={4}>
            <Input autoFocus model={model.getModel('nome')} required />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <UnidadeMedidaLookup model={model.getModel('unidadeMedida')} disabled={false} required />
          </Col>
          <Col md={3}>
            <CheckBox
              model={{
                label: capitalizeFirstLetter(intl.formatMessage({ id: 'label.parametroGlobal' }, { parametro: resources.parametro })),
                value: model.getModel('parametroGlobal').value,
                requestChange: model.getModel('parametroGlobal').requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
          <Col md={4}>
            <CheckBox
              model={{
                label: capitalizeFirstLetter(intl.formatMessage({ id: 'label.consolidarValores' })),
                value: model.getModel('consolidarValores').value,
                requestChange: model.getModel('consolidarValores').requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

@autobind
class AreaEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;
    return (
      <Edit
        url="/ParametroEntidade"
        title={capitalizeFirstLetter(intl.formatMessage({ id: 'parametroEntidade' }, { parametro: resources.parametro }))}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height={600}
        {...this.props}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(AreaEdit));
