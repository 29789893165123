import React, { Suspense, lazy } from 'react';
import { Row, Col } from 'reactstrap';

import LoadingScreen from 'src/componentes/LoadingScreen';
import guid from 'src/utils/guid';
import Grafico from 'src/componentes/chart';

class GraficoValor extends React.Component {
  render() {
    let { grafico, backgroundTransparente, height, chartCallBack } = this.props;

    let config = {
      width: '100%',
      height: height ? height : '300',
      type: grafico && grafico.type ? grafico.type : 'mscombi2d',
      containerBackgroundOpacity: backgroundTransparente && '0',
      dataFormat: 'json',
      dataSource: {
        chart: {
          ...(grafico && grafico.chart),
        },
        data: grafico && grafico.data,
        categories: grafico && grafico.categories,
        dataset:
          grafico && grafico.dataSet && grafico.dataSet.length > 0
            ? grafico.dataSet
            : null,
      },
      events: {
        dataPlotClick: function (ev, props) {
          chartCallBack(props);
        },
      },
    };

    return (
      <Suspense fallback={<LoadingScreen />}>
        <Row>
          <Col>
            <Grafico key={guid()} {...config} />
          </Col>
        </Row>
      </Suspense>
    );
  }
}
export default GraficoValor;
