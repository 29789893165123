import { Schema } from 'src/utils/form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default Schema.object({
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  unidadeMedida: Schema.object()
    .label(<FormattedMessage id="unidadeDeMedida" />)
    .required(),
  casasDecimais: Schema.string()
    .label(<FormattedMessage id="label.nDeCasasDecimais" />)
    .required(),
  dataInicio: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
  tipoLimite: Schema.object()
    .label(<FormattedMessage id="tipoLimite" />)
    .required(),
  geracaoAutomatica: Schema.object()
    .label(<FormattedMessage id="geracaoAutomatica" />)
    .required(),
  valor: Schema.object()
    .label(<FormattedMessage id="valorLimite" />)
    .required(),
});
