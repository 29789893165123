import React from 'react';
import { autobind } from 'core-decorators';
import http from 'src/services/httpService';
import httpSelector from 'src/services/httpSelector';
import history from '../../history';
import moment from 'moment';
import List from '../../componentes/list';
import ListColumn from '../../componentes/list/list-column';
import AcaoEdit from './edit';
import ContentManager from '../../componentes/content-manager';
import SvgIcon from '../../componentes/svg-icon';
import Search from './search';
import SearchSchema from './search-schema';
import errorHandler from '../../utils/error-handler';
import Panel from '../../componentes/panel-collapse';
import Legenda from '../../componentes/legenda/legenda-acao';
import { DropdownItem } from 'reactstrap';
import AcaoList from './view';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import { FormattedMessage, injectIntl } from 'react-intl';
import MdiIcon from '../../componentes/mdi-icon';

@autobind
class AcaoSelectList extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      parameters: this.props.params,
      saved: false,
      planoCompleto: false,
      labelPlanoCompleto: (
        <FormattedMessage
          id="label.exibirPlanoCompleto"
          values={{
            planodeacao:
              this.props.resources && this.props.resources.planodeacao,
          }}
        />
      ),
      resources: {},
      dropdownOpen: false,
      bloquearInsercaoManual: false,
    };
  }

  componentDidMount() {
    this.obterConfiguracoes(this.props.params && this.props.params.itemId);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handlePlanoCompletoClick() {
    this.setState({
      saved: !this.state.saved,
      planoCompleto: !this.state.planoCompleto,
      labelPlanoCompleto: !this.state.planoCompleto ? (
        <FormattedMessage
          id="label.ocultarPlanoCompleto"
          values={{
            planodeacao:
              this.props.resources && this.props.resources.planodeacao,
          }}
        />
      ) : (
        <FormattedMessage
          id="label.exibirPlanoCompleto"
          values={{
            planodeacao:
              this.props.resources && this.props.resources.planodeacao,
          }}
        />
      ),
    });
  }

  handleNewClick(item, parameters) {
    ContentManager.addContent(
      <AcaoEdit
        mode="edit"
        parameters={{
          ...parameters,
          ...this.state.parameters,
          parentId: item.id,
          planoCompleto: this.state.planoCompleto,
        }}
        handleClose={this.handleClose}
      />
    );
  }

  handleDesassociarClick(item) {
    http
      .post(`/Acao/DesassociarAcao`, {
        acao: item,
        acaoParams: this.props.params,
      })
      .then(() => {
        this.setState({
          saved: !this.state.saved,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleAssociarClick(item) {
    http
      .post(`/Acao/AssociarAcao`, {
        acao: item,
        acaoParams: this.props.params,
      })
      .then(() => {
        this.setState({
          saved: !this.state.saved,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleOpenGantt() {
    history.push({
      pathname: '/gerenciar/gantt',
      search: `?itemId=${this.props.params.itemId}`,
    });
  }

  renderDescricao(item, callback) {
    if (!item.descricao || this.props.isConsultaExterna) {
      return;
    }
    return !this.props.disabled ? (
      <span>
        <a
          tabIndex="-1"
          onClick={this.handleDescricaoClick.bind(this, item, callback)}
          style={{ cursor: 'pointer' }}
        >
          {item.descricao}
        </a>
        {/*
          item.temRelatorio
          ? <MdiIcon icon="unlink" title={<FormattedMessage id="label.desassociar"/>} className="pull-right"
              style={{cursor: 'pointer'}} onClick={this.handleDesassociarClick.bind(this, item)}></MdiIcon>
          : <MdiIcon icon="link" title={<FormattedMessage id="label.associar"/>} className="pull-right"
              style={{cursor: 'pointer'}} onClick={this.handleAssociarClick.bind(this, item)}></MdiIcon>
        */}
      </span>
    ) : (
      <span>{item.descricao}</span>
    );
  }

  handleDescricaoClick(item) {
    ContentManager.addContent(
      <AcaoEdit
        handleClose={this.handleClose}
        item={item}
        parameters={{ ...this.state.parameters }}
        onDeleting={this.props.handleDeleteClick}
      ></AcaoEdit>
    );
  }

  handleClose(saved) {
    if (saved) this.setState({ saved: Math.random() });
  }

  renderResponsavel(item) {
    return <span>{item.responsavel ? item.responsavel.nome : ''}</span>;
  }

  renderStatus(item) {
    return <span>{item.status.descricao}</span>;
  }

  renderDataTerminoPrevista(item) {
    if (!item.dataTerminoPrevista) return;

    return <span>{moment(item.dataTerminoPrevista).format('L')}</span>;
  }

  renderDataInicioPrevista(item) {
    if (!item.dataInicioPrevista) return;

    return <span>{moment(item.dataInicioPrevista).format('L')}</span>;
  }

  renderDataTerminoRealizada(item) {
    if (!item.dataTerminoRealizada) return;

    return <span>{moment(item.dataTerminoRealizada).format('L')}</span>;
  }

  renderDataInicioRealizada(item) {
    if (!item.dataInicioRealizada) return;

    return <span>{moment(item.dataInicioRealizada).format('L')}</span>;
  }

  renderFarol(item) {
    return (
      <span>
        {item.farolAcao && (
          <SvgIcon
            icon={item.farolAcao.icone}
            color={item.farolAcao.cor}
            colorHover={item.farolAcao.cor}
            size={item.farolAcao.tamanho}
            title={item.farolAcao.descricao}
          ></SvgIcon>
        )}
      </span>
    );
  }

  handleAssociarMultiClick() {
    ContentManager.addContent(
      <AcaoList
        showSearch={true}
        params={{
          itemId: this.props.params.itemId,
          relatorioIdIgnorar: this.props.params.relatorioId,
          riscoPontoControleIdIgnorar: this.props.params.riscoPontoControleId,
          riscoAvaliacaoIdIgnorar: this.props.params.riscoAvaliacaoId,
          riscoPontoControleRevisaoIdIgnorar:
            this.props.params.riscoPontoControleRevisaoId,
        }}
        multiple
        showModal
        select={true}
        selectedCallback={this.handleCustomSelectSearch}
        showExport={false}
        disabled={this.props.disabled}
      />
    );
  }

  handleCustomSelectSearch(itens) {
    httpSelector
      .getHttp(this.props.viewType)
      .post(`/Acao/AssociarAcoes`, {
        acoes: itens,
        acaoParams: this.props.params,
      })
      .then(() => {
        this.setState({
          saved: !this.state.saved,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  obterConfiguracoes(itemId) {
    httpSelector
      .getHttp(this.props.viewType)
      .post(`/Item/ObterConfiguracao`, {
        itemId: itemId,
      })
      .then((response) => {
        this.setState({
          bloquearInsercaoManual:
            response.data && response.data.bloquearInsercaoManualPlanoAcao,
        });
      });
  }

  renderTipoOrigem(item, intl) {
    let tipoOrigem;
    if (item.tipoOrigem === 'auditoriaChecklist') {
      tipoOrigem = intl.formatMessage({
        id: 'titulo.auditoriaChecklist',
      });
    } else {
      tipoOrigem = this.props.resources[item.tipoOrigem];
    }

    return <span>{capitalizeFirstLetter(tipoOrigem)}</span>;
  }

  renderPontosProblematicos(item) {
    if (item.pontosProblematicos) {
      let descricaoPonto = '';
      item.pontosProblematicos.map(
        (ponto, index) => (descricaoPonto += ponto.descricao + '; ')
      );

      return (
        <div style={{ display: 'flex', alignItems: 'left' }}>
          {descricaoPonto}
        </div>
      );
    }
  }

  renderTipoAcao(item) {
    if (item.tipoAcao == 1) {
      return <FormattedMessage id="preventiva" />;
    } else if (item.tipoAcao == 2) {
      return <FormattedMessage id="corretiva" />;
    }
  }

  renderChecklist(item) {
    if (item.tarefas) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MdiIcon
            style={{ marginTop: 3, marginRight: 5 }}
            icon="checkbox-marked-outline"
          />
          {item.tarefas &&
            item.tarefas.filter((a) => a.concluida == true).length}
          /{item.tarefas && item.tarefas.length}
        </div>
      );
    }
  }

  render() {
    let {
      params,
      resources,
      showMenuAcao = true,
      disabled = false,
      naoSalvarFiltro = false,
      intl,
      user,
      configuracao,
      showColumnItem = true,
    } = this.props;
    let { bloquearInsercaoManual } = this.state;
    let createChildItems = [
      {
        label: <FormattedMessage id="label.registroFilho" />,
        icon: 'plus',
        parameters: { ...params, planoCompleto: this.state.planoCompleto },
        visible: () => true,
        onClick: this.handleNewClick,
      },
      {
        label: <FormattedMessage id="label.associar" />,
        icon: 'link-variant',
        parameters: { ...params, planoCompleto: this.state.planoCompleto },
        visible: (item) => !item.associada,
        onClick: this.handleAssociarClick,
      },
      {
        label: <FormattedMessage id="label.desassociar" />,
        icon: 'link-variant-off',
        parameters: { ...params, planoCompleto: this.state.planoCompleto },
        visible: (item) => item.associada,
        onClick: this.handleDesassociarClick,
      },
    ];

    let moduloLicenca = user.licenca.modulos.find((i) => {
      return i.ativo && i.id === 2;
    });

    if (moduloLicenca === undefined) {
      moduloLicenca = false;
    } else {
      moduloLicenca = true;
    }

    return (
      <Panel header={capitalizeFirstLetter(resources.planodeacao)}>
        <List
          handleDescricaoClick={this.handleDescricaoClick}
          exportApresentacao={this.props.exportApresentacao}
          bloquearInsercaoManual={bloquearInsercaoManual || disabled}
          url="/Acao"
          editComponent={AcaoEdit}
          createChildItems={disabled || !showMenuAcao ? [] : createChildItems}
          sort="UltimaVersao.Descricao"
          showDetail
          pageSize={20}
          //tag={(!disabled && showOrderColumn) && 2005}
          parameters={{ ...params, planoCompleto: this.state.planoCompleto }}
          saved={this.state.saved}
          showSearch={!disabled}
          showMenu={!this.props.isConsultaExterna && !disabled}
          searchComponent={<Search />}
          searchSchema={SearchSchema}
          onDeleting={this.props.handleDeleteClick}
          filtroId={naoSalvarFiltro ? '' : 5}
          actions={
            !disabled &&
            showMenuAcao && [
              <DropdownItem
                eventkey="1"
                onClick={this.handleAssociarMultiClick}
              >
                <FormattedMessage
                  id="label.associarVariasAcoes"
                  values={{ acoes: resources.acoes }}
                />
              </DropdownItem>,
              <DropdownItem
                eventkey="2"
                onClick={this.handlePlanoCompletoClick}
              >
                {this.state.labelPlanoCompleto}
              </DropdownItem>,
            ]
          }
          {...this.props}
          hierarchy={false}
        >
          {showColumnItem && (
            <ListColumn
              headerText={intl.formatMessage({ id: 'item' })}
              valueField="nomeItem"
              sortField="Item.UltimaVersao.Nome"
              default
            />
          )}
          <ListColumn
            tree={true}
            headerText={intl.formatMessage({ id: 'codigo' })}
            valueField="codigo"
            sortField="UltimaVersao.CodigoOrdenacao"
            default
          />
          <ListColumn
            headerText={capitalizeFirstLetter(
              intl.formatMessage({ id: 'descricao' })
            )}
            valueField="descricao"
            sortField="UltimaVersao.Descricao"
            valueFunction={this.renderDescricao}
            default
          />
          <ListColumn
            headerText={capitalizeFirstLetter(resources.responsavel)}
            valueField="responsavel"
            valueFunction={this.renderResponsavel}
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'dataInicioPrevista' })}
            valueField="dataInicioPrevista"
            sortField="UltimaVersao.dataInicioPrevista"
            valueFunction={this.renderDataInicioPrevista}
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'dataTerminoPrevista' })}
            valueField="dataTerminoPrevista"
            sortField="UltimaVersao.dataTerminoPrevista"
            valueFunction={this.renderDataTerminoPrevista}
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'dataInicioRealizada' })}
            valueField="dataInicioRealizada"
            sortField="UltimaVersao.dataInicioRealizada"
            valueFunction={this.renderDataInicioRealizada}
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'dataTerminoRealizada' })}
            valueField="dataTerminoRealizada"
            sortField="UltimaVersao.dataTerminoRealizada"
            valueFunction={this.renderDataTerminoRealizada}
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'status' })}
            valueField="status"
            valueFunction={this.renderStatus}
            default
          />
          <ListColumn
            headerText={capitalizeFirstLetter(resources.farol)}
            valueField="farolAcao"
            sortField="UltimaVersao.FarolAcao"
            valueFunction={this.renderFarol}
            default
          />
          <ListColumn
            headerText={capitalizeFirstLetter(resources.area)}
            valueField="area"
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'duracao' })}
            valueField="duracao"
            sortField="UltimaVersao.duracao"
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'itemVinculado' })}
            valueField="idIntegracaoItensVinculados"
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'tipoOrigem' })}
            valueField="tipoOrigem"
            valueFunction={(item) => this.renderTipoOrigem(item, intl)}
            default
          />
          <ListColumn
            headerText={intl.formatMessage(
              { id: 'tipoAcao' },
              { acao: resources.acao }
            )}
            valueField="tipoAcao"
            valueFunction={this.renderTipoAcao}
            default
          />
          <ListColumn
            center
            visible={configuracao && configuracao.habilitarChecklistTarefas}
            headerText={intl.formatMessage({ id: 'checklistTarefas' })}
            valueField="checklist"
            valueFunction={this.renderChecklist}
          />
          <ListColumn
            visible={configuracao && configuracao.utilizarCausasImpactadas}
            headerText={intl.formatMessage(
              { id: 'pontosProblematicos' },
              { pontosproblematicos: resources.pontosproblematicos }
            )}
            valueField="pontosProblematicos"
            valueFunction={this.renderPontosProblematicos}
          />
        </List>
        <Legenda mode={this.props.mode}></Legenda>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloAtual: state.user.moduloAtual,
    user: state.user,
    viewType: state.viewType,
  };
}
export default injectIntl(connect(mapStateToProps)(AcaoSelectList));
