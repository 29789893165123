import Table from 'src/componentes/table';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Button from 'src/componentes/button';
import IconButton from 'src/componentes/icon-button';
import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';
import TemplateAcaoAdd from 'src/paginas/gestao-risco/plano-contigencia/TemplateAcao/TemplateAcaoAdd';
import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';
import MdiIcon from 'src/componentes/mdi-icon';

const TemplateAcaoView = ({
  model,
  allowNew,
  selectedCallback,
  requestChange,
  fieldName,
  isDisabled = false,
  handleChangeCallBack,
  permitirPlanoContigenciaSemResponsavel = false,
  ...rest
}) => {
  const { terms: resources } = useAuth();
  const intl = useIntl();
  const handleNewClick = () => {
    ContentManager.addContent(
      <TemplateAcaoAdd
        parameters={{ parentId: 0, itemIdIgnorar: rest.itemIdIgnorar }} // somente itens que não tenham pai
        showModal={true}
        multiple
        isDisabled={isDisabled}
        isNew={true}
        handleChangeCallBack={handleChangeCallBack}
        showExport={false}
        select={true}
        selectedCallback={selectedCallback}
        selectedIds={model}
        model={model}
        permitirPlanoContigenciaSemResponsavel={
          permitirPlanoContigenciaSemResponsavel
        }
        {...rest}
      />
    );
  };

  const handleRemove = (index) => {
    let novaLista = model;
    novaLista.splice(index, 1);
    handleChangeCallBack(fieldName, novaLista);
  };

  const handleEditClick = (item) => {
    ContentManager.addContent(
      <TemplateAcaoAdd
        parameters={{ parentId: 0, itemIdIgnorar: rest.itemIdIgnorar }} // somente itens que não tenham pai
        showModal={true}
        isDisabled={isDisabled}
        multiple
        handleChangeCallBack={handleChangeCallBack}
        showExport={false}
        select={true}
        selectedCallback={selectedCallback}
        selectedIds={model}
        isNew={false}
        model={model}
        formEdit={item}
        permitirPlanoContigenciaSemResponsavel={
          permitirPlanoContigenciaSemResponsavel
        }
        {...rest}
      />
    );
  };

  const renderMenu = (item, indexItem) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="nav-link fixed"
          style={{ padding: 0 }}
        >
          <MdiIcon icon="dots-vertical" size={18} />
        </DropdownToggle>
        <DropdownMenu container="body" placement="left">
          {item.permissions && (
            <DropdownItem onClick={() => handleEditClick(item, indexItem)}>
              <MdiIcon className="mr-2" icon="pencil" />
              {intl.formatMessage({ id: 'label.editar' })}
            </DropdownItem>
          )}
          {item.permissions && item.permissions.allowDelete && (
            <DropdownItem onClick={() => handleRemove(indexItem)}>
              <MdiIcon className="mr-2" icon="delete-outline" />
              {intl.formatMessage({ id: 'excluir' })}
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          {model?.label && (
            <label className="control-label">{model.label}</label>
          )}
        </Col>
        <Col md={6}>
          {allowNew && (
            <Button
              className="pull-right"
              type="primary"
              style={{ marginBottom: 10 }}
              onClick={handleNewClick}
            >
              <MdiIcon icon="plus" />
            </Button>
          )}
        </Col>
      </Row>
      {model && model.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>{intl.formatMessage({ id: 'descricao' })}</th>
              <th>{capitalizeFirstLetter(resources.responsavel)}</th>
              <th>{intl.formatMessage({ id: 'labelTipoInicioPrevisto' })}</th>
              <th>
                {intl.formatMessage(
                  { id: 'labelDiasUteisInicioAcao' },
                  { acao: resources.acao }
                )}
              </th>
              <th>{intl.formatMessage({ id: 'labelDuracaoDiasUteis' })}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {model &&
              model.map((item, index) => (
                <tr key={index}>
                  <td style={{ zIndex: 1, position: 'relative' }}>
                    {renderMenu(item, index)}
                  </td>
                  <td>{item.descricao}</td>
                  <td>{item.responsavel?.nome}</td>
                  <td>{item.tipoPrevisto?.descricao}</td>
                  <td>{item.diasInicioPrevisto}</td>
                  <td>{item.duracao}</td>
                  <td style={{ width: 40 }}>
                    {allowNew && (
                      <IconButton
                        icon="close-circle"
                        className="clarear"
                        onClick={() => handleRemove(index)}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <i>{intl.formatMessage({ id: 'label.naoHaItensRelacionados' })}</i>
      )}
    </div>
  );
};
export default TemplateAcaoView;
