import React from 'react';
import Button from '../button';
import Dialog from '../../componentes/dialog';
import { autobind } from 'core-decorators';
import { keydown, KEYS } from '../../utils/keydown-decorator';
import jsxArray from '../../utils/jsx-array';
import { ButtonToolbar } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class Confirm extends React.Component {
  static defaultProps = {
    title: 'label.confirmar',
    isFunction: false,
  };

  @keydown(KEYS.ESC)
  handleCancelClick() {
    this.props.handleCancel && this.props.handleCancel();
    this.refs.dialog.close();
  }

  @keydown(KEYS.ENTER)
  handleConfirmClick() {
    this.props.handleConfirm && this.props.handleConfirm();
    this.refs.dialog.close();
  }

  render() {
    let { title, handleCancel, handleConfirm, message, intl, labelCancel, labelConfirm, isFunction, ...other } = this.props;

    const actions = jsxArray(
      <ButtonToolbar>
        <Button onClick={this.handleCancelClick} color="secondary">
          {labelCancel ? labelCancel : intl.formatMessage({ id: 'label.cancelar' })}
        </Button>
        <Button className="ml-2" autoFocus type="primary" onClick={this.handleConfirmClick}>
          {labelConfirm ? labelConfirm : intl.formatMessage({ id: 'label.confirmar' })}
        </Button>
      </ButtonToolbar>
    );

    return (
      <Dialog {...other} width={500} ref="dialog" title={title} actions={actions} onRequestClose={this.handleCancelClick} padContent>
        <div className="mb-3">{isFunction ? message() : message}</div>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Confirm));
