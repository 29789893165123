import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import CondicionanteEdit from '../paginas/remuneracao-variavel/condicionante/edit';
import DesdobramentoList from '../paginas/remuneracao-variavel/condicionante';

@autobind
class CondicionanteLookup extends React.Component {
  render() {
    return (
      <Lookup
        labelKey="descricao"
        valueKey="id"
        url="/CondicionanteLookup"
        clearable={true}
        showSearch
        editComponent={CondicionanteEdit}
        searchComponent={DesdobramentoList}
        {...this.props}
      />
    );
  }
}

export default CondicionanteLookup;
