import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import Edit from 'src/componentes/edit/edit-dialog';
import Checkbox from 'src/componentes/checkbox';
import Input from 'src/componentes/input';
import DatePicker from 'src/componentes/date-picker';
import ColaboradorLookup from 'src/lookups/colaborador';
import AcaoLookup from 'src/lookups/acao';
import useAuth from 'src/hooks/useAuth';

import Schema from './Schema';
import { capitalizeFirstLetter } from 'src/utils/string';

const FormEdit = ({ model }) => {
  const intl = useIntl();

  const { terms: resources } = useAuth();

  const id = model.getModel('id').value;

  const isNew = !id || id <= 0;

  const disabled = !model.getModel('podeEditar').value;

  return (
    <>
      <Row>
        <Col md={12}>
          <AcaoLookup
            disabled={!isNew || disabled}
            model={{
              label: capitalizeFirstLetter(resources.acao),
              value: model.getModel('acao').value,
              requestChange: model.getModel('acao').requestChange,
            }}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            disabled={disabled}
            required
            rows="2"
            type="textarea"
            model={{
              label: intl.formatMessage({ id: 'descricao' }),
              value: model.getModel('descricao').value,
              requestChange: model.getModel('descricao').requestChange,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ColaboradorLookup
            disabled={disabled}
            model={{
              label: capitalizeFirstLetter(resources.responsavel),
              value: model.getModel('responsavel').value,
              requestChange: model.getModel('responsavel').requestChange,
            }}
          />
        </Col>
        <Col md={6}>
          <DatePicker
            disabled={disabled}
            model={{
              label: intl.formatMessage({ id: 'data' }),
              value: model.getModel('dataConclusao').value,
              requestChange: model.getModel('dataConclusao').requestChange,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="mb-3">
            <Checkbox
              disabled={disabled}
              model={{
                label: intl.formatMessage({ id: 'concluida' }),
                value: model.getModel('concluida').value,
                requestChange: model.getModel('concluida').requestChange,
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

const EditView = ({ ...rest }) => {
  const intl = useIntl();
  return (
    <Edit
      url="/AcaoTarefa"
      title={intl.formatMessage({ id: 'tarefa' })}
      formComponent={FormEdit}
      schema={Schema}
      width={700}
      height={400}
      {...rest}
    ></Edit>
  );
};

export default EditView;
