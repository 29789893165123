import React, { useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import Input from 'src/componentes/input';
import Dialog from 'src/componentes/dialog';
import { capitalizeFirstLetter } from 'src/utils/string';
import Button from 'src/componentes/button';

const EditTranslate = ({
  fieldDefault,
  valueDefault,
  labelDefault,
  fieldPT,
  valuePT,
  fieldEN,
  valueEN,
  fieldES,
  valueES,
  fieldIT,
  valueIT,
  fieldNL,
  valueNL,
  fieldFR,
  valueFR,
  fieldDE,
  valueDE,
  fieldTR,
  valueTR,
  fieldAR,
  valueAR,
  maxLength,
  required,
  disabled,
  handleChangeEditTranslate,
  ...rest
}) => {
  const ref = useRef();
  const intl = useIntl();

  const values = {
    [fieldDefault]: valueDefault,
    [fieldPT]: valuePT,
    [fieldEN]: valueEN,
    [fieldES]: valueES,
    [fieldIT]: valueIT,
    [fieldNL]: valueNL,
    [fieldFR]: valueFR,
    [fieldDE]: valueDE,
    [fieldTR]: valueTR,
    [fieldAR]: valueAR,
  };

  const [localModel, setLocalModel] = useState({ ...values });

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const handleSave = () => {
    handleChangeEditTranslate(
      fieldDefault,
      fieldPT,
      fieldEN,
      fieldES,
      fieldIT,
      fieldNL,
      fieldFR,
      fieldDE,
      fieldTR,
      fieldAR,
      localModel
    );
    handleClose();
  };

  const actions = [
    <div>
      <Button
        key="close-Anexos"
        color="secondary"
        leftIcon="close"
        onClick={handleSave}
      >
        {intl.formatMessage({ id: 'label.fechar' })}
      </Button>
    </div>,
  ];

  const handleChangeLocalModel = (field, value) => {
    setLocalModel((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Dialog
      title={capitalizeFirstLetter(
        intl.formatMessage({ id: 'traducaoDescricoes' })
      )}
      ref={ref}
      padContent
      width={600}
      height={900}
      actions={actions}
      onRequestClose={handleSave}
      {...rest}
    >
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            required={required}
            disabled={disabled}
            model={{
              label: <span className="font-weight-bold">{labelDefault}</span>,
              value: localModel[fieldDefault],
              requestChange: (v) => handleChangeLocalModel(fieldDefault, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'portugues' })}
                </span>
              ),
              value: localModel[fieldPT],
              requestChange: (v) => handleChangeLocalModel(fieldPT, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'ingles' })}
                </span>
              ),
              value: localModel[fieldEN],
              requestChange: (v) => handleChangeLocalModel(fieldEN, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'espanhol' })}
                </span>
              ),
              value: localModel[fieldES],
              requestChange: (v) => handleChangeLocalModel(fieldES, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'italiano' })}
                </span>
              ),
              value: localModel[fieldIT],
              requestChange: (v) => handleChangeLocalModel(fieldIT, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'holandes' })}
                </span>
              ),
              value: localModel[fieldNL],
              requestChange: (v) => handleChangeLocalModel(fieldNL, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'frances' })}
                </span>
              ),
              value: localModel[fieldFR],
              requestChange: (v) => handleChangeLocalModel(fieldFR, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'alemao' })}
                </span>
              ),
              value: localModel[fieldDE],
              requestChange: (v) => handleChangeLocalModel(fieldDE, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'turco' })}
                </span>
              ),
              value: localModel[fieldTR],
              requestChange: (v) => handleChangeLocalModel(fieldTR, v),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            maxLength={maxLength}
            disabled={disabled}
            model={{
              label: (
                <span className="font-weight-bold">
                  {intl.formatMessage({ id: 'arabe' })}
                </span>
              ),
              value: localModel[fieldAR],
              requestChange: (v) => handleChangeLocalModel(fieldAR, v),
            }}
          />
        </Col>
      </Row>
    </Dialog>
  );
};

export default EditTranslate;
