import React from 'react';
import { autobind } from 'core-decorators';
import history from '../../../history';
import http from 'src/services/httpService';
import { prepareHtml } from '../../../utils/html';

@autobind
class FormulaVisao extends React.Component {
  static defaultProps = {
    isItem: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      formula: this.props.formula,
      entiTy: 'Item',
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.formula !== this.props.formula) {
      this.refresh(newProps.formula);
    }
  }

  componentDidMount() {
    this.refresh(this.props.formula);
  }

  refresh(formula) {
    http
      .post(`/ItemValor/ObterFormulaDetalhada`, {
        formula: formula,
      })
      .then((response) => {
        this.setState({ formula: response.data.formulaDetalhada });
      });
  }

  handleFormulaClick(id) {
    history.push({
      pathname: '/gerenciar/valor',
      search: `?${this.state.entiTy.toLowerCase()}Id=${id}`,
    });
  }

  handleDivClick(e) {
    this.props.onRequestClose && this.props.onRequestClose();
    let clickedElement = e.target;
    let link = clickedElement.closest('a');
    if (!link) return;
    let itemId = link.dataset.itemId;
    let desdobramentoId = link.dataset.desdobramentoId;
    let url = `?${this.state.entiTy.toLowerCase()}Id=${itemId}`;

    if (desdobramentoId) {
      url = `${url}&desdobramentoId=${desdobramentoId}`;
    }

    history.push({
      pathname: '/gerenciar/valor',
      search: url,
    });
    this.props.refreshPage && this.props.refreshPage(itemId);
    window.parent.location = window.parent.location.href;
  }

  render() {
    let { formula } = this.state;

    return (
      <div
        style={{ maxWidth: 700 }}
        onClick={this.handleDivClick}
        dangerouslySetInnerHTML={prepareHtml(formula)}
      ></div>
    );
  }
}

export default FormulaVisao;
