import React from 'react';
import Avatar from '../avatar';

const AvatarGroup = ({
  renderLastElement,
  children,
  avatarSize = 40,
  fontSize = 14,
  max = 3,
}) => {
  const size = children?.length;
  const extra = size - max;

  const finalArray = children ? children.slice(0, max) : null;

  const renderElement = (child, index, props) => {
    return (
      <div
        style={{
          //zIndex: ignoreZindex ? 1 : max - index,
          marginLeft: index > 0 ? -6 : 0,
          marginTop: -1,
        }}
      >
        {React.cloneElement(child, { ...props })}
      </div>
    );
  };

  const renderLast = (zIndex) => {
    if (renderLastElement) return renderLastElement();

    return (
      <div
        style={{
          //zIndex: ignoreZindex ? 1 : zIndex,
          marginLeft: -6,
          marginTop: -1,
        }}
      >
        <Avatar
          fontSize={fontSize}
          showTooltip={false}
          showLabel={false}
          showInitials={false}
          size={avatarSize}
          label={`+${extra}`}
        />
      </div>
    );
  };

  return (
    <div className="d-flex flex-wrap align-items-center">
      {finalArray &&
        React.Children.map(finalArray, (child, index) =>
          renderElement(child, index, {
            ...child.props,
            border: 2,
            size: avatarSize,
            showLabel: false,
            fontSize,
          })
        )}
      {extra > 0 && renderLast(0)}
    </div>
  );
};

export default AvatarGroup;
