import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import Radio from 'src/componentes/radio';
import Input from 'src/componentes/input';
import Panel from 'src/componentes/panel';
import { capitalizeFirstLetter } from 'src/utils/string';

import FormulaEdit from '../components/formula/edit';
import TipoCardView from '../components/tipoCard/tipo-card-view';
import CestaItensList from '../components/item';
import DataInicioTermino from '../components/inicio-termino';

const EditBonus = ({
  isNotNew,
  model,
  disabled,
  renderFormula,
  renderRadioButton,
  handleChangeIdIntegracao,
  handleChangeDataInicio,
  handleChangeDataTermino,
  handleChangeTipoCalculoScore,
  handleChangeTipoCard,
}) => {
  const intl = useIntl();

  const tipoCalculoScore =
    model.getModel('tipoCalculoScore').value == 0
      ? 1
      : model.getModel('tipoCalculoScore').value;

  return (
    <>
      <Row>
        <Col md={2}>
          <Input
            disabled={disabled}
            maxlength={100}
            required
            model={{
              label: intl.formatMessage({ id: 'idIntegracao' }),
              value: model.getModel('idIntegracao').value,
              requestChange: handleChangeIdIntegracao,
              errors: model.getModel('idIntegracao').errors,
            }}
          />
        </Col>
        <Col md={6}>
          <DataInicioTermino
            disabled={disabled}
            required
            dataInicio={{
              label: intl.formatMessage({ id: 'label.dataInicio' }),
              value: model.getModel('dataInicio').value,
              requestChange: handleChangeDataInicio,
              errors: model.getModel('dataInicio').errors,
            }}
            dataTermino={{
              label: intl.formatMessage({ id: 'label.dataTermino' }),
              value: model.getModel('dataTermino').value,
              requestChange: handleChangeDataTermino,
            }}
          />
        </Col>
        <Col md={4}>
          <div>
            <Radio
              disabled={disabled}
              model={{
                label:
                  capitalizeFirstLetter(intl.formatMessage({ id: 'tipo' })) +
                  ' *',
                value: tipoCalculoScore,
                requestChange: handleChangeTipoCalculoScore,
              }}
              needId
              options={renderRadioButton()}
              labelField="descricao"
              idField="id"
              name="opcoestipoCalculoScore"
            />
          </div>
        </Col>
      </Row>
      {isNotNew && (
        <Row>
          <Col md={12}>
            <Panel>
              {tipoCalculoScore == 1 && (
                <CestaItensList
                  parameters={{
                    cestaIndicadorId: model.getModel('id').value,
                  }}
                  showExport={false}
                  model={model}
                  disabled={disabled}
                />
              )}
              {tipoCalculoScore == 2 && (
                <List
                  showTitle
                  title={intl.formatMessage({ id: 'label.formulas' })}
                  url="/Formula"
                  editComponent={FormulaEdit}
                  bloquearMultiplosRegistros
                  showPage={false}
                  showDelete
                  showMenu={!disabled}
                  showNew={!disabled}
                  parameters={{
                    cestaIndicadorId: model.getModel('id').value,
                    alterado: false,
                    tipoValorId: 5,
                  }}
                  showExport={false}
                >
                  <ListColumn
                    headerText={intl.formatMessage({ id: 'formula' })}
                    valueField="formula"
                    valueFunction={renderFormula}
                  />
                </List>
              )}
              {tipoCalculoScore == 3 && (
                <TipoCardView
                  parameters={{
                    cestaIndicadorId: model.getModel('id').value,
                  }}
                  showExport={false}
                  onChange={handleChangeTipoCard}
                  model={model}
                  disabled={disabled}
                />
              )}
            </Panel>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EditBonus;
