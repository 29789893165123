import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';

import { capitalizeFirstLetter } from 'src/utils/string';
import PontoControleLookup from 'src/lookups/ponto-controle';
import RiscoLookup from 'src/lookups/item';
import PeriodicidadeLookup from 'src/lookups/tipo-periodicidade-ponto-controle';
import ColaboradorLookup from 'src/lookups/colaborador';
import TipoPontoControle from 'src/lookups/tipo-ponto-controle';
import Input from 'src/componentes/input';
import InputNumber from 'src/componentes/input-number';
import TipoToleranciaLookup from 'src/lookups/tipo-tolerancia';
import PeriodoData from 'src/paginas/data/periodo-data';
import StatusRevisaoLookup from 'src/lookups/status-risco-ponto-controle-revisao';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import ProcessoLookup from 'src/lookups/processo';
import ProbabilidadeRiscoLookup from 'src/lookups/probabilidade-risco';
import SeveridadeRiscoLookup from 'src/lookups/severidade-risco';
import TratamentoLookup from 'src/lookups/tratamento-risco';
import Checkbox from 'src/componentes/checkbox';

@autobind
class RevisoesGerenciarSearch extends React.Component {
  handleChangeFiltro(field, value) {
    let { model } = this.props;
    model.getModel(field).requestChange(value);
  }

  render() {
    let { model, resources, intl } = this.props;
    
    return (
      <div>
        <Row>
          <Col md={4}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.areas),
                value: model.getModel('areas').value,
                requestChange: model.getModel('areas').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              model={{
                label: intl.formatMessage({ id: 'label.considerarAreasSubordinadas' }, { areas: resources.areas }),
                value: model.getModel('areasSubordinadas').value,
                requestChange: model.getModel('areasSubordinadas').requestChange,
              }}
              style={{ marginTop: 27 }}
            />
          </Col>
          <Col md={4}>
            <ProcessoLookup
              multi
              data={{ TipoId: 5 }}
              model={{
                label: capitalizeFirstLetter(this.props.intl.formatMessage({ id: 'label.processos' }, { processos: resources.processos })),
                value: model.getModel('processos').value,
                requestChange: model.getModel('processos').requestChange,
              }}
            />
            <Checkbox
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'incluirSubprocessos' }, { processosfilhosplural: resources.processosfilhosplural.toLowerCase() })
                ),
                value: model.getModel('incluirSubprocessos').value,
                requestChange: model.getModel('incluirSubprocessos').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RiscoLookup
              clearable
              data={{ tipoId: 6 }}
              model={{
                label: capitalizeFirstLetter(resources.risco),
                value: model.getModel('risco').value,
                requestChange: model.getModel('risco').requestChange,
              }}
            />
          </Col>
          <Col md={6}>
            <ColaboradorLookup
              clearable
              model={{
                label: capitalizeFirstLetter(resources.responsavel),
                value: model.getModel('responsavel').value,
                requestChange: model.getModel('responsavel').requestChange,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <PeriodoData
              clearable
              tipoFiltroData={model.getModel('tipoFiltroData').value}
              dataInicio={model.getModel('dataInicio').value}
              dataTermino={model.getModel('dataTermino').value}
              onChange={this.handleChangeFiltro}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <ProbabilidadeRiscoLookup clearable model={model.getModel('probabilidade')} />
          </Col>
          <Col md={4}>
            <SeveridadeRiscoLookup clearable model={model.getModel('severidade')} />
          </Col>
          <Col md={4}>
            <TratamentoLookup clearable model={model.getModel('tratamento')} />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(RevisoesGerenciarSearch));
