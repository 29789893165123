export function getValueOnPath(path, target) {
  let pathParts = path && path.split('.');

  if (pathParts) {
    for (let i = 0; target && i < pathParts.length; i++) {
      target = target[pathParts[i]];
    }
  }
  return target;
}

export function setValueOnPath(path, target, value) {
  if (!path) return value;

  let pathParts = path && path.split('.');

  let currentTarget = target;
  for (let i = 0; i < pathParts.length - 1; i++) {
    let part = pathParts[i];
    currentTarget = currentTarget[part] || (currentTarget[part] = {});
  }
  currentTarget[pathParts[pathParts.length - 1]] = value;

  return target;
}
