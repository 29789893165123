import httpSelector from '../../../../services/httpSelector';
import errorHandler from '../../../../utils/error-handler';
import { capitalizeFirstLetter } from '../../../../utils/string';

export function allColumnsCard(intl, resources, configuracao) {
  let columns = [
    {
      headerText: intl.formatMessage({ id: 'item' }),
      valueField: 'nomeItem',
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'label.anexos' }),
      valueField: 'arquivos',
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'descricao' }),
      valueField: 'descricao',
      visible: true,
    },
    {
      headerText: capitalizeFirstLetter(resources.responsavel),
      valueField: 'responsavel',
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'dataInicioPrevista' }),
      valueField: 'dataInicioPrevista',
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'dataTerminoPrevista' }),
      valueField: 'dataTerminoPrevista',
      visible: true,
    },
    {
      headerText: capitalizeFirstLetter(resources.farol),
      valueField: 'farol',
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'tipoOrigem' }),
      valueField: 'tipoOrigem',
      visible: true,
    },
    {
      headerText: intl.formatMessage(
        { id: 'metaGanhosPorAcao' },
        { meta: resources.meta, acao: resources.acao }
      ),
      valueField: 'ganhosMeta',
      visible: true,
    },
    {
      headerText: intl.formatMessage(
        { id: 'realizadoGanhosPorAcao' },
        { acao: resources.acao }
      ),
      valueField: 'ganhosRealizado',
      visible: true,
    },
    {
      headerText: capitalizeFirstLetter(resources.pontosproblematicos),
      valueField: 'pontosProblematicos',
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'tags' }, { tags: resources.tags }),
      valueField: 'tags',
      visible: true,
    },
  ];

  if (configuracao && configuracao.habilitarChecklistTarefas) {
    columns.unshift({
      headerText: intl.formatMessage({ id: 'checklistTarefas' }),
      valueField: 'checklist',
      visible: true,
    });
  }

  return columns;
}

export function fetchStatusDisponiveis(viewType) {
  return httpSelector
    .getHttp(viewType)
    .post(`/Acao/StatusColumn`)
    .then((response) => {
      if (response.data) return response.data;
      else return [];
    })
    .catch((error) => {
      errorHandler(error);
    });
}

export function fetchGanttSettings(viewType) {
  return httpSelector
    .getHttp(viewType)
    .post(`/Acao/GanttSettings`)
    .then((response) => {
      if (response.data) return response.data;
      else return [];
    })
    .catch((error) => {
      errorHandler(error);
    });
}

export async function fetchCard(allColumnsCard, viewType) {
  let columns = [];
  return await httpSelector
    .getHttp(viewType)
    .post(`/ColunasTabela/ObterConfiguracoesColunasTabela`, {
      tag: 6000,
    })
    .then((response) => {
      if (response.data) {
        response.data &&
          response.data.map((coluna) => {
            let index = allColumnsCard.findIndex(
              (c) => c.valueField === coluna.valueField
            );
            if (index !== -1) columns.push(allColumnsCard[index]);
            return null;
          });
      } else {
        allColumnsCard &&
          allColumnsCard.map((coluna) => {
            columns.push(coluna);
            return null;
          });
      }

      return columns;
    })
    .catch((error) => {
      errorHandler(error);
    });
}

export async function fetchStatus(allColumnsKanban, viewType) {
  let columns = [];

  return await httpSelector
    .getHttp(viewType)
    .post(`/ColunasTabela/ObterConfiguracoesColunasTabela`, {
      tag: 6001,
    })
    .then((response) => {
      if (response.data) {
        response.data &&
          response.data.map((coluna) => {
            let index = allColumnsKanban.findIndex(
              (c) => c.valueField === coluna.valueField
            );
            if (index !== -1) columns.push(allColumnsKanban[index]);
            return null;
          });
      } else {
        allColumnsKanban &&
          allColumnsKanban.map((coluna) => {
            columns.push(coluna);
            return null;
          });
      }

      return columns;
    })
    .catch((error) => {
      errorHandler(error);
    });
}
