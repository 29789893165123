import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../componentes/list';
import ListColumn from '../../componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import TipoAcessoLookup from '../../lookups/tipo-acesso';
import PermissaoEdit from './edit';
import http from 'src/services/httpService';
import errorHandler from '../../utils/error-handler';
import Panel from '../../componentes/panel/index';
import { Row, Col } from 'react-bootstrap';
import Info from '../../componentes/info';
import Checkbox from 'src/componentes/checkbox';

@autobind
class ParteInteressada extends React.Component {
  static defaultProps = {
    url: '/ItemPermissao',
    action: 'AtualizarTipoAcesso',
  };

  constructor(props) {
    super(props);

    this.state = {
      saved: false,
    };
  }

  handleChangeAcesso(item, valor) {
    http
      .post(`${this.props.url}/${this.props.action}`, {
        itemPermissaoId: item.id,
        tipoAcessoId: valor ? valor.id : 0,
      })
      .then((response) => {
        this.setState({
          saved: Math.random(),
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });
  }

  renderAcesso(item) {
    return (
      <TipoAcessoLookup
        disabled={this.props.disabled}
        model={{
          value: item.tipoAcesso,
          requestChange: this.handleChangeAcesso.bind(this, item),
        }}
        style={{ marginBottom: '-5px' }}
      ></TipoAcessoLookup>
    );
  }

  renderList(parameters, disabled, other, resources, intl) {
    return (
      <List
        url={this.props.url}
        saved={this.state.saved}
        editComponent={PermissaoEdit}
        showSearch={true}
        showNew={!disabled}
        showMenu={!disabled}
        parameters={parameters}
        forceRefresh={true}
        {...other}
      >
        <ListColumn
          headerText={capitalizeFirstLetter(resources.colaborador)}
          valueField="colaborador"
        ></ListColumn>
        <ListColumn
          headerText={capitalizeFirstLetter(resources.area)}
          valueField="area"
        ></ListColumn>
        <ListColumn
          minHeight={40}
          headerText={intl.formatMessage({ id: 'label.acesso' })}
          valueFunction={this.renderAcesso}
        ></ListColumn>
      </List>
    );
  }

  render() {
    const {
      showPanel = true,
      model,
      disabled,
      resources,
      intl,
      clearable,
      parameters,
      ...other
    } = this.props;
    const { tipoItemId } = parameters;
    return (
      <div>
        {model?.value?.area != null && (
          <Panel>
            <Row>
              <Col md={4}>
                <Checkbox
                  style={{ marginTop: '27px' }}
                  model={{
                    label: intl.formatMessage(
                      { id: 'label.bloquearAcessoColaboradoresArea' },
                      {
                        colaboradores: resources.colaboradores,
                        area: resources.area,
                        areas: resources.areas,
                      }
                    ),
                    value: model.value.bloquearAcessoColaboradoresArea,
                    requestChange: model.getModel(
                      'bloquearAcessoColaboradoresArea'
                    ).requestChange,
                  }}
                />
              </Col>
            </Row>
          </Panel>
        )}
        {tipoItemId &&
          (tipoItemId === 1 ||
            tipoItemId === 2 ||
            tipoItemId === 3 ||
            tipoItemId === 4) && (
            <Row>
              <Col md={4}>
                <div style={{ display: 'inline-flex' }}>
                  <TipoAcessoLookup
                    clearable={true}
                    model={{
                      label: intl.formatMessage(
                        { id: 'label.acessoParaColaboradoresPermissaoArea' },
                        {
                          colaboradores: resources.colaboradores,
                          area: resources.area,
                        }
                      ),
                      value: model.getModel('tipoAcessoArea').value,
                      requestChange:
                        model.getModel('tipoAcessoArea').requestChange,
                    }}
                  />
                  <Info
                    id="utilizarCodigoAutomatico"
                    placement="right"
                    text={intl.formatMessage(
                      {
                        id: 'label.acessoParaColaboradoresPermissaoAreaObservacao',
                      },
                      {
                        colaborador: resources.colaborador,
                        area: resources.area,
                        indicador: resources.indicador,
                      }
                    )}
                  />
                </div>
              </Col>
            </Row>
          )}
        {showPanel ? (
          <Panel>
            {this.renderList(parameters, disabled, other, resources, intl)}
          </Panel>
        ) : (
          this.renderList(parameters, disabled, other, resources, intl)
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ParteInteressada));
