import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Condicionante from './edit';
import { capitalizeFirstLetter } from '../../../utils/string';

@autobind
class CondicionanteList extends React.Component {
  render() {
    let { model, intl, resources, disabled } = this.props;
    return (
      <div>
        <List
          url="/ColaboradorCondicionante"
          showSearch={false}
          titulo={capitalizeFirstLetter(resources.condicionantes)}
          editComponent={Condicionante}
          sort="id"
          parameters={{ ColaboradorId: model.getModel('id').value }}
          showExport={false}
          showNew={true}
          showDelete={true}
          disabled={disabled}
          podeEditar={true}
          {...this.props}
        >
          <ListColumn headerText={capitalizeFirstLetter(resources.condicionante)} valueField="condicionante" />
          <ListColumn headerText={intl.formatMessage({ id: 'valor' })} valueField="valor" />
        </List>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CondicionanteList));
