import { useRef } from 'react';
import RiscoListView from 'src/paginas/gestao-risco/risco/RiscoListView';
import Dialog from 'src/componentes/dialog';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';

const RiscosAssociadosAdd = ({selectedCallback, removeDialog = false, ...rest}) => {
  const ref = useRef();
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const handleSelectedCallBack = (itens) => {
    selectedCallback(itens);
    handleClose();
  };

  return (
    removeDialog ? 
    <RiscoListView
      {...rest}
      hideHead={true}
      ignoreFiltroId={true}
      select={true}
      multiple={true}
      selectedCallback={handleSelectedCallBack}
      showModal={true}
    /> :
    <Dialog
      {...rest}
      ref={ref}
      minWidth={'60%'}
      maxWidth={'60%'}
      minHeight={'70%'}
      onRequestClose={handleClose}
      padContent
      title={intl.formatMessage(
        { id: 'labelRiscosVinculados' },
        { riscos: resources.riscos }
      )}
    >
      <RiscoListView
        {...rest}
        hideHead={true}
        ignoreFiltroId={true}
        select={true}
        multiple={true}
        selectedCallback={handleSelectedCallBack}
        showModal={true}
      />
    </Dialog>
  );
};

export default RiscosAssociadosAdd;
