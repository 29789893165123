import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Dialog from 'src/componentes/dialog';
import Tab from 'src/componentes/tabs/tab';
import Tabs from 'src/componentes/tabs/tabs';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import GruposList from 'src/paginas/grupo-email/view';
import ColaboradoresList from 'src/paginas/colaborador/view';
import TipoResponsabilidadeList from 'src/paginas/EscritorioProjetos/FluxoAprovacaoGenerico/TipoResponsabilidade';
import Button from 'src/componentes/button';
import distinctBy from 'src/utils/ArrayDistinctBy';
import { ADDONS } from 'src/utils/constants';

const SearchComponent = ({
  selectedCallback,
  selectedItems,
  ehFluxoNegociacaoMetas,
  ...rest
}) => {
  const intl = useIntl();

  const { terms: resources, addons } = useAuth();

  const possuiAddonNegociacaoMetas =
    addons?.find((x) => x.id === ADDONS.NEGOCIACAO_METAS && x.ativo) != null;

  const [data, setData] = useState({
    colaboradores: selectedItems
      ? [
          ...selectedItems.filter(
            (x) => x.identificadorLista === 'colaboradores'
          ),
        ]
      : [],
    grupos: selectedItems
      ? [...selectedItems.filter((x) => x.identificadorLista === 'grupos')]
      : [],
    responsabilidades: selectedItems
      ? [
          ...selectedItems.filter(
            (x) => x.identificadorLista === 'responsabilidades'
          ),
        ]
      : [],
  });

  const dialogRef = useRef();

  const handleClose = () => {
    if (dialogRef?.current) dialogRef?.current.close();
  };

  const handleSelect = (key, itens) => {
    const isGroup = key === 'grupos';
    const isColab = key === 'colaboradores';
    const newList = itens ? distinctBy(itens, 'id') : [];

    setData((prev) => ({
      ...prev,
      [key]:
        newList?.map((x) => {
          x.idLookup = isGroup
            ? `G-${x.id}`
            : isColab
            ? `C-${x.id}`
            : `R-${x.id}`;
          x.identificadorLista = key;
          x.descricao = x.descricao ?? x.nome;
          return x;
        }) || [],
    }));
  };

  const handleSave = () => {
    if (selectedCallback)
      selectedCallback([
        ...data.colaboradores,
        ...data.grupos,
        ...data.responsabilidades,
      ]);
    handleClose();
  };

  const actions = [
    <Button onClick={handleClose} color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      className="ml-2"
      type="primary"
      onClick={handleSave}
      htmlType="submit"
    >
      {intl.formatMessage({ id: 'label.confirmar' })}
    </Button>,
  ];

  const colaboradoresList = data?.colaboradores;
  const gruposColaboradoresList = data?.grupos;
  const responsabilidadesList = data?.responsabilidades;

  return (
    <Dialog
      ref={dialogRef}
      width={800}
      maxHeight="80%"
      actions={actions}
      padContent
      onRequestClose={handleClose}
      {...rest}
    >
      <Tabs>
        <Tab
          label={intl.formatMessage({
            id: capitalizeFirstLetter(resources.colaboradores),
          })}
        >
          <ColaboradoresList
            multiple
            select
            selectRealTime
            selectedCallback={(itens) => handleSelect('colaboradores', itens)}
            selectedItems={colaboradoresList}
            selectedIds={colaboradoresList}
          />
        </Tab>
        <Tab label={intl.formatMessage({ id: 'grupos' })}>
          <GruposList
            multiple
            select
            selectRealTime
            selectedCallback={(itens) => handleSelect('grupos', itens)}
            selectedItems={gruposColaboradoresList}
            selectedIds={gruposColaboradoresList}
          />
        </Tab>
        {possuiAddonNegociacaoMetas && ehFluxoNegociacaoMetas && (
          <Tab label={intl.formatMessage({ id: 'responsabilidades' })}>
            <TipoResponsabilidadeList
              multiple
              select
              selectRealTime
              selectedCallback={(itens) =>
                handleSelect('responsabilidades', itens)
              }
              selectedItems={responsabilidadesList}
              selectedIds={responsabilidadesList}
            />
          </Tab>
        )}
      </Tabs>
    </Dialog>
  );
};

export default SearchComponent;
