import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import MdiIcon from '../componentes/mdi-icon';

@autobind
class GrauRiscoLookup extends React.Component {
  renderFarol(item) {
    return (
      item &&
      item.data && (
        <span>
          <MdiIcon icon="circle" size={18} color={item.data.cor} colorHover={item.data.cor}></MdiIcon>
          <span className="ml-2">{item.data.descricao}</span>
        </span>
      )
    );
  }

  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/GrauRiscoLookup" clearable={false} valueComponent={this.renderFarol} {...this.props} />;
  }
}

export default GrauRiscoLookup;
