import Lookup from 'src/componentes/select2';

const StatusControlSelfAssessmentLookup = ({ ...rest }) => (
  <Lookup
    labelKey="nome"
    valueKey="id"
    url="/StatusControlSelfAssessmentLookup"
    clearable
    {...rest}
  />
);

export default StatusControlSelfAssessmentLookup;
