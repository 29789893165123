export default class Schema {
  constructor() {
    this.validators = [];
  }

  getChildSchema(prop) {}
  validateChildren(target, path, errors, context) {}

  validate(target, path = '', errors = [], parentContext = null) {
    let context = {
      value: target,
      parent: parentContext,
    };

    this.validators.forEach((v) => {
      let error = v(context);

      if (error) {
        errors.push({ path: path, message: error });
      }
    });

    this.validateChildren(target, path, errors, context);

    if (!parentContext && errors.length > 0) {
      errors.map((e) => `${e.path}: ${e.message}`).join(`\n`);
    }

    return errors;
  }

  getSchema(path) {
    if (path) {
      return path.split('.').reduce((schema, prop) => schema && schema.getChildSchema(prop), this);
    } else {
      return this;
    }
  }

  addValidator(fn) {
    this.validators.push(fn);
    return this;
  }

  static string() {
    return new SimpleSchema('string');
  }

  static boolean() {
    return new SimpleSchema('boolean');
  }

  static number() {
    return new SimpleSchema('number');
  }

  static object(fields) {
    return new ObjectSchema(fields);
  }

  static array(itemSchema) {
    return new ArraySchema(itemSchema);
  }

  static recurse(fn) {
    let schema;
    return (schema = fn(() => schema));
  }
}

class SimpleSchema extends Schema {
  constructor(type) {
    super();

    this.type = type;
  }
}

class ObjectSchema extends Schema {
  constructor(fields) {
    super();

    this.fields = fields;
  }

  getChildSchema(prop) {
    let schema = this.fields[prop];
    if (typeof schema == `function`) schema = schema();
    return schema;
  }

  validateChildren(target, path, errors, context) {
    if (target) {
      for (let p in this.fields) {
        let childSchema = this.getChildSchema(p);
        if (childSchema) {
          childSchema.validate(target[p], path ? path + '.' + p : p, errors, context);
        }
      }
    }
  }
}

class ArraySchema extends Schema {
  constructor(itemSchema) {
    super();

    this.itemSchema = itemSchema;
  }

  getChildSchema(prop) {
    let schema = this.itemSchema;
    if (typeof schema == `function`) schema = schema();
    return schema;
  }

  validateChildren(target, path, errors, context) {
    if (target) {
      for (let i = 0; i < target.length; i++) {
        let childSchema = this.getChildSchema(i);
        if (childSchema) {
          childSchema.validate(target[i], path ? path + '.' + i : '', errors, context);
        }
      }
    }
  }
}

// metadata
Schema.prototype.label = function (label) {
  this._label = label;
  return this;
};

Schema.prototype.resource = function (resource) {
  this._resource = resource;
  return this;
};

Schema.prototype.placeholder = function (placeholder) {
  this._placeholder = placeholder;
  return this;
};

// validators
Schema.prototype.required = function (options) {
  return this.addValidator((context) => {
    if (options && options.onlyIf && !options.onlyIf(context)) {
      return;
    }

    if ((!context.value && context.value !== 0 && context.value !== false) || (context.value instanceof Array && context.value.length === 0)) {
      return 'Campo requerido';
    }
  });
};

Schema.prototype.email = function () {
  return this.addValidator((context) => {
    if (context.value && !/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i.test(context.value)) return 'E-mail inválido';
  });
};

Schema.prototype.telefone = function () {
  return this.addValidator((context) => {
    if (context.value && !/\([0-9]{2}\)\s*[0-9]{4,5}-[0-9]{4}/i.test(context.value)) return 'Telefone inválido';
  });
};
