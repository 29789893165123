import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  area: Schema.string(),
  areasSubordinadas: Schema.string(),
  responsaveis: Schema.string().label(''),
  status: Schema.string().label(<FormattedMessage id="status" />),
});
