import Lookup from 'src/componentes/select-async';
import PontoControleEdit from 'src/paginas/gestao-risco/ponto-controle/edit';
import PontoControleList from 'src/paginas/gestao-risco/ponto-controle';

const ControleLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/PontoControleLookup"
    showSearch
    searchComponent={PontoControleList}
    editComponent={PontoControleEdit}
    clearable={true}
    {...rest}
  />
);

export default ControleLookup;
