import { useState } from 'react';
import PanelCollapse from 'src/componentes/panel-collapse';
import Input from 'src/componentes/input';
import Radio from 'src/componentes/radio';
import { Row, Col, CustomInput } from 'reactstrap';
import { AVALIACAO_TESTE_CONTROLE } from 'src/utils/constants';

export default function TesteControleResultado({
  isDisabled = false,
  intl = {},
  model = {},
  errors = {},
  resources = {},
  handleChange = () => {},
}) {

  const [form, setForm] = useState(model);

  const handleChangeRadio = (item) => {
    setForm((prevForm) => ({
      ...prevForm,
      avaliacaoTeste: item,
    }));
    handleChange('avaliacaoTeste', item);

    if (item == AVALIACAO_TESTE_CONTROLE.FRACO || item == AVALIACAO_TESTE_CONTROLE.DEFICIENCIA_SEVERA)
      handleInputChange(true, 'utilizarPlanoAcao')
    else if (item == AVALIACAO_TESTE_CONTROLE.SATISFATORIO || item == AVALIACAO_TESTE_CONTROLE.NAO_TESTADO)
      handleInputChange(false, 'utilizarPlanoAcao')
  };

  const handleInputChange = (value, field) => {
    if (field == 'utilizarPlanoAcao' && (form?.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.FRACO || form?.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.DEFICIENCIA_SEVERA))
      return;

    handleChange(field, value);
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const options = [
    {
      id: 1,
      descricao: intl.formatMessage({ id: 'labelSatisfatorio' }),
    },
    {
      id: 2,
      descricao: intl.formatMessage({ id: 'labelFraco' }),
    },
    {
      id: 3,
      descricao: intl.formatMessage({ id: 'labelDeficienciaSevera' }),
    },
    {
      id: 4,
      descricao: intl.formatMessage({ id: 'labelNaoTestado' }),
    },
  ];

  return (
    <PanelCollapse
      header={intl.formatMessage({ id: 'resultados' })}
      id="resultado"
    >
      <Input
        disabled={isDisabled}
        type="textarea"
        style={errors?.observacao ? { borderColor: 'red' } : {}}
        maxLength={30000}
        rows={3}
        model={{
          label: intl.formatMessage({ id: 'labelObservacoes' }),
          value: form?.observacao,
          requestChange: (v) => handleInputChange(v, 'observacao'),
          errors: errors?.observacao,
        }}
      />
      <Input
        disabled={isDisabled}
        type="textarea"
        style={errors?.conclusao ? { borderColor: 'red' } : {}}
        maxLength={30000}
        rows={3}
        model={{
          label: intl.formatMessage({ id: 'labelConclusao' }),
          value: form?.conclusao,
          requestChange: (v) => handleInputChange(v, 'conclusao'),
          errors: errors?.conclusao,
        }}
      />
      <Row>
        <Col md={5}>
          <Radio
            disabled={isDisabled}
            needId
            inColum={true}
            showLabel={false}
            model={{
              value: form?.avaliacaoTeste,
              requestChange: handleChangeRadio,
              errors: errors?.avaliacaoTeste,
            }}
            options={options}
            labelField="descricao"
            colunaUnica={true}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <Col md={4}>
          <CustomInput
            disabled={isDisabled}
            label={intl.formatMessage(
              { id: 'label.UtilizarPlanoDeAcao' },
              { acao: resources?.acao }
            )}
            type="switch"
            id="switch-utilizar-plano-acao"
            checked={form?.utilizarPlanoAcao || form?.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.FRACO || form?.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.DEFICIENCIA_SEVERA}
            onChange={(e) => handleInputChange(e.target.checked, 'utilizarPlanoAcao')}
          />        
        </Col>
      </Row>
      <Input
        required={form?.utilizarPlanoAcao || form?.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.FRACO || form?.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.DEFICIENCIA_SEVERA}
        disabled={isDisabled}
        type="textarea"
        style={errors?.sugestao ? { borderColor: 'red' } : {}}
        maxLength={30000}
        rows={3}
        model={{
          label: intl.formatMessage(
            { id: 'labelSugestoesPlanoAcao' },
            { planodeacao: resources?.planodeacao }
          ),
          value: form?.sugestao,
          requestChange: (v) => handleInputChange(v, 'sugestao'),
          errors: errors?.sugestao,
        }}
      />
    </PanelCollapse>
  );
}
