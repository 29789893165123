import React, { useState, useEffect } from 'react';
import EfetividadeControleLookup from 'src/lookups/efetividade-controle';
import http from 'src/services/httpService';
import { Row, Col, Alert } from 'reactstrap';
import Info from 'src/componentes/info';
import { useIntl } from 'react-intl';
import PanelCollapse from 'src/componentes/panel-collapse';

function AvaliacaoEfetividadeControle({
  model,
  modelCorretivo,
  modelPreventivo,
  disabled,
  configuracaoAvaliacao,
  required,
  efetividadeAutomatica,
  resources,
  riscoId,
}) {
  const intl = useIntl();
  const [efetividadeParametros, setEfetividadeParametros] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const renderDescricao = () => {
    return intl.formatMessage(
      { id: 'efetividadeCalculadaConformeEfetividadeIndividual' },
      { pontodecontrole: resources.pontodecontrole }
    );
  };

  const obterParametrosEfetividade = () => {
    setIsLoading(true);
    http
      .post('RiscoAvaliacao/ObterAvaliacaoEfetividadeParametros', {
        riscoId: riscoId,
      })
      .then((response) => {
        setEfetividadeParametros(response.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    obterParametrosEfetividade();
  }, [riscoId]);

  const renderEfetividadeDescAutomatica = () => {
    if (!efetividadeAutomatica) return;

    return (
      <Col md={2} style={{ marginTop: 'auto' }}>
        <span>
          <Info
            id={'efetividadeAutomaticaInfo01'}
            tooltipMaxWidth={500}
            placement="auto"
            renderContent={() => renderDescricao()}
          />
        </span>
      </Col>
    );
  };

  const renderHeader = () => {
    return (
      <div className="row">
        <span>
          {intl.formatMessage(
            { id: 'efetividadeDosControles' },
            { pontosdecontrole: resources.pontosdecontrole }
          )}
        </span>
        {renderEfetividadeDescAutomatica()}
      </div>
    );
  };

  const renderLookups = () => {
    if (
      !configuracaoAvaliacao ||
      !configuracaoAvaliacao.avaliarEfetividadeControleSeparadamente
    )
      return (
        model && (
          <Row>
            <Col md={4}>
              {efetividadeParametros.controleSemEfetividadePreenchida ? (
                <Alert
                  color="warning"
                  isOpen={true}
                  className="p-2 mb-0 mt-4"
                  style={{ fontSize: 10 }}
                >
                  {intl.formatMessage(
                    { id: 'validacaoEfetividadeControleDeveSerPreenchida' },
                    { pontosdecontrole: resources.pontosdecontrole }
                  )}
                </Alert>
              ) : (
                <EfetividadeControleLookup
                  efetividadeAutomatica={efetividadeAutomatica}
                  id={100}
                  preventivo={false}
                  corretivo={false}
                  intl={intl}
                  resources={resources}
                  model={model}
                  className="pl-0"
                  disabled={disabled}
                  required={required}
                />
              )}
            </Col>
          </Row>
        )
      );
    else
      return (
        modelCorretivo &&
        modelPreventivo && (
          <Row>
            <Col md={4}>
              {efetividadeParametros.controleCorretivoSemEfetividadePreenchida ? (
                <Alert
                  color="warning"
                  isOpen={true}
                  className="p-2 mb-0 mt-4"
                  style={{ fontSize: 11 }}
                >
                  {intl.formatMessage(
                    {
                      id: 'validacaoEfetivdadeControleCorretivoDeveSerPreenchida',
                    },
                    { pontosdecontrole: resources.pontosdecontrole }
                  )}
                </Alert>
              ) : (
                <EfetividadeControleLookup
                  efetividadeAutomatica={efetividadeAutomatica}
                  id={200}
                  preventivo={false}
                  corretivo={true}
                  intl={intl}
                  resources={resources}
                  model={modelCorretivo}
                  className="pl-0"
                  disabled={disabled}
                  data={{ ignorarPreventivo: true }}
                  required={required}
                />
              )}
            </Col>
            <Col md={4}>
              {efetividadeParametros.controlePreventivoSemEfetividadePreenchida ? (
                <Alert
                  color="warning"
                  isOpen={true}
                  className="p-2 mb-0 mt-4"
                  style={{ fontSize: 10 }}
                >
                  {intl.formatMessage(
                    {
                      id: 'validacaoEfetivdadeControlePreventivoDeveSerPreenchida',
                    },
                    { pontosdecontrole: resources.pontosdecontrole }
                  )}
                </Alert>
              ) : (
                <EfetividadeControleLookup
                  efetividadeAutomatica={efetividadeAutomatica}
                  id={300}
                  preventivo={true}
                  corretivo={false}
                  intl={intl}
                  resources={resources}
                  model={modelPreventivo}
                  className="pl-0"
                  disabled={disabled}
                  data={{ ignorarCorretivo: true }}
                  required={required}
                />
              )}
            </Col>
          </Row>
        )
      );
  };

  return (
    <PanelCollapse header={renderHeader()}>{renderLookups()}</PanelCollapse>
  );
}

export default AvaliacaoEfetividadeControle;
