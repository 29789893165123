import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';

import Dialog from 'src/componentes/dialog';
import Input from 'src/componentes/input';
import Button from 'src/componentes/button';
import LoadingContainer from 'src/componentes/loading-container';
import DataInicioTermino from '../edit/components/inicio-termino';

const Clone = ({ item, save, ...rest }) => {
  const intl = useIntl();

  const ref = useRef();
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const isDisabled = () => {
    return !form.idIntegracao || !form.dataInicio;
  };

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const handleSave = async () => {
    setIsLoading(true);
    setIsSaving(true);

    await save(form);
    setIsLoading(false);

    handleClose();
  };

  const actions = [
    <Button key={1} onClick={handleClose} color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      key={2}
      disabled={isDisabled()}
      className="ml-2"
      onClick={handleSave}
    >
      {intl.formatMessage({ id: 'label.salvar' })}
    </Button>,
  ];

  return (
    <Dialog
      ref={ref}
      padContent
      actions={!isSaving ? actions : null}
      onRequestClose={handleClose}
      width={400}
      minHeight={200}
      title={intl.formatMessage({ id: 'clone' })}
      {...rest}
    >
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col md={12}>
            <Input
              required
              model={{
                label: intl.formatMessage({ id: 'idIntegracao' }),
                value: form.idIntegracao,
                requestChange: (value) => handleChange('idIntegracao', value),
              }}
            />
          </Col>
          <Col md={12}>
            <DataInicioTermino
              required
              dataInicio={{
                label: intl.formatMessage({ id: 'label.dataInicio' }),
                value: form.dataInicio,
                requestChange: (value) => handleChange('dataInicio', value),
              }}
              dataTermino={{
                label: intl.formatMessage({ id: 'label.dataTermino' }),
                value: form.dataTermino,
                requestChange: (value) => handleChange('dataTermino', value),
              }}
            />
          </Col>
        </Row>
      </LoadingContainer>
    </Dialog>
  );
};

export default Clone;
