import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select-async';
import CentroCustoList from '../paginas/matriz-orcamentaria/centro-custo';
import CentroCustoEdit from '../paginas/matriz-orcamentaria/centro-custo/edit';

@autobind
class CentroCustoLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueComponent={this.renderCentroCusto}
        valueKey="id"
        labelKey="nome"
        showSearch
        clearable={true}
        url="/CentroCustoLookup"
        editComponent={CentroCustoEdit}
        searchComponent={CentroCustoList}
        hierarchy
        {...this.props}
      />
    );
  }
}

export default CentroCustoLookup;
