import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  tipoFiltroData: Schema.string().label(<FormattedMessage id="label.tipoFiltroData" />),
  dataInicio: Schema.string().label(<FormattedMessage id="dataInicio" />),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
});
