import { Col, Row } from 'reactstrap';

import ItemLookup from 'src/lookups/item';
import CentroCustoLookup from 'src/lookups/item-centro-custo';
import PacoteLookup from 'src/lookups/pacote';
import AcaoLookup from 'src/lookups/acao';
import Input from 'src/componentes/input';
import ColaboradorLookup from 'src/lookups/colaborador';
import DatePicker from 'src/componentes/date-picker';
import { useIntl } from 'react-intl';
import { capitalizeFirstLetter } from 'src/utils/string';
import useAuth from 'src/hooks/useAuth';
import { MODULOS } from 'src/utils/constants';
import Checkbox from 'src/componentes/checkbox';

const Search = ({ model }) => {
  const intl = useIntl();

  const { terms: resources, module, modules } = useAuth();

  const currentModule = modules?.find((m) => m.id === module.id);
  const hasMOModule = modules.find(
    (m) => m.id === MODULOS.MATRIZ_ORCAMENTARIA && m.exibir
  );

  return (
    <>
      <Row>
        <Col md={4}>
          <ItemLookup
            clearable
            model={{
              label: intl.formatMessage({ id: 'item' }),
              value: model.getModel('item').value,
              requestChange: model.getModel('item').requestChange,
            }}
          />
        </Col>
        {hasMOModule && currentModule?.id === MODULOS.MATRIZ_ORCAMENTARIA && (
          <>
            <Col md={4}>
              <CentroCustoLookup
                clearable
                model={{
                  label: capitalizeFirstLetter(resources.centrocusto),
                  value: model.getModel('centroCusto').value,
                  requestChange: model.getModel('centroCusto').requestChange,
                }}
              />
            </Col>
            <Col md={4}>
              <PacoteLookup
                clearable
                model={{
                  label: intl.formatMessage({ id: 'pacote' }),
                  value: model.getModel('pacote').value,
                  requestChange: model.getModel('pacote').requestChange,
                }}
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col md={12}>
          <Input
            model={{
              label: intl.formatMessage({ id: 'descricao' }),
              value: model.getModel('descricao').value,
              requestChange: model.getModel('descricao').requestChange,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <AcaoLookup
            clearable
            model={{
              label: capitalizeFirstLetter(resources.acao),
              value: model.getModel('acao').value,
              requestChange: model.getModel('acao').requestChange,
            }}
          />
        </Col>
        <Col md={4}>
          <ColaboradorLookup
            clearable
            model={{
              label: capitalizeFirstLetter(resources.responsavel),
              value: model.getModel('responsavel').value,
              requestChange: model.getModel('responsavel').requestChange,
            }}
          />
        </Col>
        <Col md={4}>
          <DatePicker
            clearable
            model={{
              label: intl.formatMessage({ id: 'dataConclusao' }),
              value: model.getModel('dataConclusao').value,
              requestChange: model.getModel('dataConclusao').requestChange,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Checkbox
            className="mb-3"
            model={{
              label: intl.formatMessage({
                id: 'visualizarApenasTarefasPendentes',
              }),
              value: model.getModel('visualizarApenasTarefasPendentes').value,
              requestChange: model.getModel('visualizarApenasTarefasPendentes')
                .requestChange,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Search;
