import React, { lazy } from 'react';
import { autobind } from 'core-decorators';
import { Row, Col, Alert } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ValidarRestricaoNovo from 'src/componentes/validar-restricao-novo/ValidarRestricao';

import Input from 'src/componentes/input';
import CheckBox from 'src/componentes/checkbox';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import PeriodicidadeLookup from 'src/lookups/periodicidade';
import StatusItemLookup from 'src/lookups/status-item';
import Panel from 'src/componentes/panel-collapse';
import MatrizRisco from 'src/lookups/matriz-risco';
import TipoRiscoLookup from 'src/lookups/tipo-risco';
import ModeloRiscoLookup from 'src/lookups/modelo-risco';
import ClassificacaoRiscoLookup from 'src/lookups/classificacao-risco';
import { capitalizeFirstLetter } from 'src/utils/string';
import DataInicioTermino from 'src/paginas/formula/componentes/inicio-termino';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import ContentManager from 'src/componentes/content-manager';
import { MODULOS, ADDONS } from 'src/utils/constants';
import FiltroItemRelatorioPowerBI from 'src/paginas/item/edit/resumo/filtro-relatorio-powerbi';

import TabelaItensAssociados from './risco-itens-associados/relacionamento';
import RiscoItensAssociados from './risco-itens-associados';
import PlanosContingenciaVinculadosView from './PlanosContingenciaVinculados/PlanosContingenciaVinculadosView';
import ToleranciaRiscoLookup from 'src/lookups/ToleranciaRiscoLookup';
import FluxoAprovacaoGenericoLookup from 'src/lookups/FluxoAprovacaoGenericoLookup';
import MdiIcon from 'src/componentes/mdi-icon';
import TagLookup from 'src/lookups/TagLookup';
import ColaboradorEGrupoColaboradoresLookup from 'src/lookups/ColaboradorEGrupoColaboradoresLookup';
const Historico = lazy(() => import('src/paginas/versao-alteracao'));
@autobind
class RiscoResumoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasValueFluxoAprovacao: true,
    };
  }

  getAlteracao() {
    this.setState(
      {
        campoAlterado: true,
      },
      () => {
        this.handleSave();
      }
    );
  }

  handleChangeUtilizarValorPowerBI(value) {
    if (value.target.checked === true) {
      ContentManager.addContent(
        <ValidarRestricaoNovo
          title="labelAlterarConfiguracoesAvaliacoes"
          description="labelDescricaoAlterarConfiguracoesAvaliacoes"
          exibirEstouCiente={false}
          labelCancelar='label.cancelar'
          labelAlterar='alterar'
          width={700}
          minHeight={300}
          customHandleSalvarClick={() => {
            this.props.model.getModel('utilizarValorPowerBI').requestChange(true);
          }}
          customHandleCloseValidarRestricaoClick={() => {
            this.props.model.getModel('utilizarValorPowerBI').requestChange(false);
          }}
        />   
      )
    }
    else {
      this.props.model.getModel('utilizarValorPowerBI').requestChange(value.target.checked);
    }
  };

  handleAreaChange(value) {
    this.props.model.getModel('area').requestChange(value);

    let usarCodigoArea = this.props.model.getModel(
      'utilizarCodigoAreaParaMascara'
    ).value;
    if (usarCodigoArea) {
      let novoCodigoItem =
        value.idIntegracao + this.props.model.getModel('idIntegracao').value;
      this.props.model.getModel('idIntegracao').requestChange(novoCodigoItem);
    }
  }

  handleOpenItensAssociados() {
    const isEP = this.props.module.id === MODULOS.ESCRITORIO_PROJETOS;
    const itDoesntHaveGRLicence =
      this.props.modules.find(
        (m) => m.id === MODULOS.GESTAO_RISCO && m.ativo
      ) == null;

    const showOnlyProjects = isEP && itDoesntHaveGRLicence;

    ContentManager.addContent(
      <RiscoItensAssociados
        modules={this.props.modules}
        showOnlyProjects={showOnlyProjects}
        model={this.props.model.getModel('itensAssociados')}
        risco={this.props.model.value}
        habilitarIndicadoresRisco={
          this.props.model.value.habilitarIndicadoresRisco
        }
      />
    );
  }

  /** @returns {boolean} configuração do risco habilitado para gerar idIntegração automático */
  utilizaCodigoAutomatico = ({
    utilizarCodigoAutomatico,
    permitirAlterarCodigoAutomatico,
  }) => {
    const { model } = this.props;
    const id = model.getModel('id').value;
    return (
      utilizarCodigoAutomatico && (!permitirAlterarCodigoAutomatico || id <= 0)
    );
  };

  renderFiltroRelatorioPowerBI(disabled, model) {
    return (
      model.getModel('itemRelatorioPowerBI').value && (
        <FiltroItemRelatorioPowerBI
          model={model.getModel('itemRelatorioPowerBI')}
          disabled={disabled}
          itemId={model.getModel('id').value}
          tipoItemId={model.getModel('tipo').value?.id}
        />
      )
    );
  }

  setHasValueFluxoAprovacao(data) {
    this.setState({ hasValueFluxoAprovacao: data && data.length > 0 });
  }

  handleChangeFluxoAprovacao(value) {
    const { model } = this.props;
    const configuracaoRisco = model.getModel('configuracaoRisco').value;
    const isNew = model?.value?.id <= 0;

    if (
      value &&
      isNew &&
      configuracaoRisco?.usarFluxoAprovacaoBelt &&
      !configuracaoRisco?.fluxoAprovacaoObrigatorioRisco
    ) {
      model.getModel('status').requestChange({ id: 1 });
    }
    model.getModel('fluxoAprovacao').requestChange(value);
  }

  handleChangePeopleAndGroups(list) {
    const { model } = this.props;

    const newApprovers =
      list?.filter((x) => x.identificadorLista === 'colaboradores') || [];
    const newGroups =
      list?.filter((x) => x.identificadorLista === 'grupos') || [];

    model.getModel('responsaveisMulti').requestChange(newApprovers);
    model.getModel('gruposResponsaveis').requestChange(newGroups);
    model
      .getModel('responsaveis')
      .requestChange([...newApprovers, ...newGroups]);
  }

  render() {
    const { model, resources, intl, disabled, configuracao, module, modules, addons } = this.props;

    const isEP = module.id === MODULOS.ESCRITORIO_PROJETOS;
    const isNew = model?.value?.id <= 0;
    const configuracaoRisco = model.getModel('configuracaoRisco').value;
    const utilizarModel = !isEP && configuracaoRisco && configuracaoRisco.utilizarModelo;
    const utilizarClassificacaoRisco = configuracaoRisco && configuracaoRisco.utilizarClassificacaoRisco;
    const utilizarTipo = configuracaoRisco && configuracaoRisco.utilizarTipo;
    const utilizarToleranciaRisco = model?.getModel('exibirToleranciaRisco')?.value;
    const desabilitarToleranciaRisco = model?.getModel('desabilitarToleranciaRisco')?.value;
    const fluxoAprovacaoHabilitado = configuracaoRisco?.usarFluxoAprovacaoBelt;
    const fluxoAprovacaoObrigatorio = fluxoAprovacaoHabilitado && configuracaoRisco?.fluxoAprovacaoObrigatorioRisco;
    const exibirCamposFluxo = fluxoAprovacaoHabilitado && fluxoAprovacaoObrigatorio && !model.getModel('fluxoAprovacao').value;
    const rowsDescricao = [
      utilizarModel,
      utilizarClassificacaoRisco,
      utilizarTipo,
    ].reduce((acc, curr) => acc + (curr ? 3 : 0), 0);

    const existeFluxoAprovacao = model?.getModel('existeFluxoAprovacao')?.value;
    const responsaveis = model.getModel('responsaveisMulti').value || [];
    const gruposResponsaveis = model.getModel('gruposResponsaveis').value || [];
    const podeVisualizarTagRisco = configuracaoRisco?.podeVisualizarTag;
    const addonIntegracaoPowerBIAvaliacaoRisco = addons && addons.find((m) => m.id === ADDONS.INTEGRACAO_POWERBI_AVALIACAORISCO)?.ativo;

    return (
      <div>
        {isNew && !model.getModel('fluxoAprovacao').value && exibirCamposFluxo && (
          <Panel header={<FormattedMessage id="label.informacoesBasicas" />}>
            <Row>
              {fluxoAprovacaoHabilitado &&
                isNew &&
                !model.getModel('fluxoAprovacao').value && (
                  <>
                    <Col md={4} style={{ paddingBottom: 20 }}>
                      <FluxoAprovacaoGenericoLookup
                        disabled={existeFluxoAprovacao}
                        data={{ tipoItemId: 3 }}
                        required={fluxoAprovacaoObrigatorio}
                        model={model.getModel('fluxoAprovacao')}
                        getDataLookup={this.setHasValueFluxoAprovacao}
                      />
                    </Col>
                    {!this.state.hasValueFluxoAprovacao && (
                      <Col md={8} style={{ paddingTop: 20 }}>
                        <Alert color="warning">
                          <MdiIcon icon="alert" />
                          <span className="ml-2">
                            {intl.formatMessage({
                              id: 'labelFluxoAprovacaoSemValor',
                            })}
                          </span>
                        </Alert>
                      </Col>
                    )}
                  </>
                )}
            </Row>
          </Panel>
        )}
        {(!exibirCamposFluxo ||
          model.getModel('fluxoAprovacao').value ||
          !isNew) && (
          <Panel header={<FormattedMessage id="label.informacoesBasicas" />}>
            <Row className="pb-2">
              {fluxoAprovacaoHabilitado &&
                (isNew || model.getModel('fluxoAprovacao').value) && (
                  <Col md={4} style={{ paddingBottom: 5 }}>
                    <FluxoAprovacaoGenericoLookup
                      data={{ tipoItemId: 3 }}
                      required={fluxoAprovacaoObrigatorio }
                      disabled={disabled || existeFluxoAprovacao || (!isNew && fluxoAprovacaoObrigatorio)}
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage({ id: 'fluxoAprovacao' })
                        ),
                        value: model.getModel('fluxoAprovacao').value,
                        requestChange: this.handleChangeFluxoAprovacao,
                        errors: model.getModel('fluxoAprovacao').errors,
                      }}
                    />
                  </Col>
                )}
              <Col md={4} style={{ paddingBottom: 2 }}>
                <Input
                  autoFocus={true}
                  style={{ height: '45px' }}
                  disabled={disabled}
                  required
                  model={model.getModel('nome')}
                  maxLength={300}
                />
              </Col>
              <Col md={4}>
                <ItemOrganizacaoLookup
                  disabled={disabled}
                  style={{ height: '45px' }}
                  required
                  model={{
                    label: capitalizeFirstLetter(resources.area),
                    value: model.getModel('area').value,
                    requestChange: this.handleAreaChange,
                    errors: model.getModel('area').errors,
                  }}
                />
              </Col>
              {!isNew || !this.utilizaCodigoAutomatico(configuracao) ? (
                <Col md={4}>
                  <Input
                    style={{ height: '45px' }}
                    disabled={
                      disabled || this.utilizaCodigoAutomatico(configuracao)
                    }
                    required={
                      !(disabled || this.utilizaCodigoAutomatico(configuracao))
                    }
                    model={model.getModel('idIntegracao')}
                  />
                </Col>
              ) : null}
              <Col md={4}>
                <ColaboradorEGrupoColaboradoresLookup
                  multi
                  autoFocus={false}
                  style={{ minHeight: '45px', borderColor: model.getModel('responsaveis').errors && (responsaveis?.length == 0 && gruposResponsaveis?.length == 0) && '#f05050' }}
                  formGroupStyle={{ minHeight: '47px', marginBottom: 2 }}
                  disabled={disabled}
                  required
                  model={{
                    label: intl.formatMessage(
                      {
                        id: 'selecioneColaboradoresEouGrupos',
                      },
                      { colaboradores: resources.responsaveis }
                    ),
                    value: [...responsaveis, ...gruposResponsaveis],
                    requestChange: this.handleChangePeopleAndGroups,
                    errors: model.getModel('responsaveis').errors,
                  }}
                />
              </Col>
              <Col md={4}>
                <StatusItemLookup
                  style={{ minHeight: '48px' }}
                  controlStyle={{ marginBottom: 2, minHeight: '45px' }}
                  disabled={
                    disabled ||
                    fluxoAprovacaoObrigatorio ||
                    this.props.rascunhoPendenteAprovacao ||
                    (isNew && model.getModel('fluxoAprovacao').value)
                  }
                  required
                  model={model.getModel('status')}
                  data={{ idsSelecionados: [1, 2, 3, 4, 5] }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={utilizarToleranciaRisco ? 3 : 4}>
                <MatrizRisco
                  required
                  disabled={disabled}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'matrizRisco' },
                        { risco: resources.risco }
                      )
                    ),
                    value: model.getModel('matrizRisco').value,
                    requestChange: model.getModel('matrizRisco').requestChange,
                    errors: model.getModel('matrizRisco').errors,
                  }}
                />
              </Col>
              {utilizarToleranciaRisco && (
                <Col md={3}>
                  <ToleranciaRiscoLookup
                    disabled={desabilitarToleranciaRisco || disabled}
                    saved={this.props.saved}
                    style={{ maxWidth: '300px' }}
                    model={{
                      label: this.props.intl.formatMessage(
                        {
                          id: 'labelToleranciaRisco',
                        },
                        { risco: resources.risco }
                      ),
                      value: model.getModel('toleranciaRisco').value,
                      requestChange:
                        model.getModel('toleranciaRisco').requestChange,
                      errors: model.getModel('toleranciaRisco').errors,
                    }}
                  />
                </Col>
              )}
              <Col md={utilizarToleranciaRisco ? 3 : 4}>
                <DataInicioTermino
                  style={{ height: '45px' }}
                  height={'38px'}
                  disabled={disabled}
                  autoFormatDate={true}
                  showClear={false}
                  dataInicio={model.getModel('dataInicioItem')}
                  dataTermino={model.getModel('dataTerminoItem')}
                  required
                />
              </Col>
            </Row>
            <Row>
              {podeVisualizarTagRisco && 
              (
                <Col md={utilizarToleranciaRisco ? 3 : 4}>
                  <TagLookup
                    disabled={disabled}
                    multi
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'tags' },
                          { tags: resources.tags }
                        )
                      ),
                      value: model.getModel('tags').value,
                      requestChange: model.getModel('tags').requestChange,
                    }}
                    data={{ funcionalidadesIdSelecionaveis: [7] }}
                    podeEditar={false}
                  />
                </Col>
              )}
            </Row>
          </Panel>
        )}
        {(!exibirCamposFluxo ||
          model.getModel('fluxoAprovacao').value ||
          !isNew) && (
          <Panel header={<FormattedMessage id="informacoesComplementares" />}>
            <Row>
              <Col md={4}>
                <ColaboradorSimplificadoLookup
                  disabled={disabled}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'label.responsavelAvaliacao' },
                        { avaliacao: resources.avaliacao }
                      )
                    ),
                    value: model.getModel('responsavelAvaliacao').value,
                    requestChange: model.getModel('responsavelAvaliacao')
                      .requestChange,
                    errors: model.getModel('responsavelAvaliacao').errors,
                  }}
                />
              </Col>
              <Col md={4}>
                <PeriodicidadeLookup
                  disabled={disabled || model.getModel('revisaoUnica').value}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'label.periodicidadeAvaliacao' },
                        { avaliacao: resources.avaliacao }
                      )
                    ),
                    value: model.getModel('periodicidadeAvaliacao').value,
                    requestChange: model.getModel('periodicidadeAvaliacao')
                      .requestChange,
                    errors: model.getModel('periodicidadeAvaliacao').errors,
                  }}
                />
              </Col>
              <Col md={addonIntegracaoPowerBIAvaliacaoRisco ? 1 : 2} className="mt-2">
                <CheckBox
                  style={{ marginTop: 30 }}
                  disabled={disabled}
                  model={model.getModel('revisaoUnica')}
                />
              </Col>
              { addonIntegracaoPowerBIAvaliacaoRisco && (
                <Col md={2} className="mt-2">
                  <CheckBox
                    style={{ marginTop: 30 }}
                    disabled={disabled}
                    model={model.getModel('utilizarValorPowerBI')}
                    onChange={this.handleChangeUtilizarValorPowerBI}
                  />
                </Col>
              )}
              <Col md={addonIntegracaoPowerBIAvaliacaoRisco ? 1 : 2} className="mt-2">
                <ArquivoUploadAnexos
                  rightLabel={true}
                  disabled={disabled}
                  tipoAnexo={this.props.tiposAnexos.item}
                  formGroupStyle={{ marginBottom: 0 }}
                  model={model.getModel('arquivos')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  disabled={disabled}
                  type="textarea"
                  model={model.getModel('descricao')}
                  rows={rowsDescricao < 6 ? 6 : rowsDescricao}
                />
              </Col>
              {(utilizarModel ||
                utilizarClassificacaoRisco ||
                utilizarTipo) && (
                <Col md={4}>
                  {utilizarModel && (
                    <ModeloRiscoLookup
                      disabled={disabled}
                      model={model.getModel('modeloRisco')}
                    />
                  )}
                  {utilizarClassificacaoRisco && (
                    <ClassificacaoRiscoLookup
                      disabled={disabled}
                      model={model.getModel('classificacaoRisco')}
                    />
                  )}
                  {utilizarTipo && (
                    <TipoRiscoLookup
                      disabled={disabled}
                      model={{
                        label: capitalizeFirstLetter(resources.tipoRisco),
                        value: model.getModel('tipoRisco').value,
                        requestChange:
                          model.getModel('tipoRisco').requestChange,
                        errors: model.getModel('tipoRisco').errors,
                      }}
                    />
                  )}
                </Col>
              )}
            </Row>
            {model.getModel('camposCustomizados').value &&
              model.getModel('camposCustomizados').value.length > 0 && (
                <DesenharCampoCustomizado
                  rowsTextArea={6}
                  model={model.getModel('camposCustomizados')}
                  disabled={disabled}
                  mdFields={4}
                />
              )}
          </Panel>
        )}
        {!isNew &&
          model?.getModel('configuracaoRisco')?.value
            ?.habilitarPlanoContigencia && (
            <PlanosContingenciaVinculadosView
              openLastFilter={false}
              allowNew={!disabled}
              model={model.getModel('planosContingencia')}
              requestChange={model.getModel('planosContingencia').requestChange}
            />
          )}
        {!isNew && (
          <Panel
            header={capitalizeFirstLetter(
              intl.formatMessage({ id: 'itensAssociados' })
            )}
          >
            <Row>
              <Col md={12} style={{ marginTop: 20 }}>
                <TabelaItensAssociados
                  handleNewClick={this.handleOpenItensAssociados}
                  allowNew={!disabled}
                  model={model.getModel('itensAssociados')}
                />
              </Col>
            </Row>
          </Panel>
        )}
        {!isNew && this.renderFiltroRelatorioPowerBI(disabled, model)}
        {!isNew && (
          <Panel
            open={false}
            header={intl.formatMessage({ id: 'label.historico' })}
            loadChildrenOnOpen={true}
          >
            <Historico
              url={`/Risco/ObterHistorico`}
              id={model.getModel('id').value}
            />
          </Panel>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    module: state.user.moduloAtual,
    modules: state.user.licenca.modulos,
    tiposAnexos: state.user.tiposAnexos,
    addons: state.user.addonsDisponiveis,
  };
}
export default injectIntl(connect(mapStateToProps)(RiscoResumoEdit));
