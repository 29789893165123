import { Schema } from '../../../utils/form';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default Schema.object({
  area: Schema.string().label(''),
  areasSubordinadas: Schema.string().label(''),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  responsavel: Schema.string().label(''),
  idIntegracao: Schema.string().label(<FormattedMessage id="codigo" />),
  processos: Schema.array().label(''),
  incluirSubprocessos: Schema.boolean().label(''),
  responsaveis: Schema.string().label(''),
});
