import React from 'react';
import { Schema } from '../../../utils/form';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import Input from '../../../componentes/input';
import { autobind } from 'core-decorators';
import Edit from '../../../componentes/edit/edit-dialog';
import { connect } from 'react-redux';
import InputNumber from '../../../componentes/input-number';
import { injectIntl } from 'react-intl';

let schema = Schema.object({
  id: Schema.string(),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  diaPrimeiroEnvio: Schema.string()
    .label(<FormattedMessage id="label.diaPrimeiroEnvio" />)
    .required(),
  diaSegundoEnvio: Schema.string().label(<FormattedMessage id="label.diaSegundoEnvio" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model } = this.props;

    return (
      <div>
        <Row>
          <Col md={4}>
            <Input autoFocus model={model.getModel('nome')} required />
          </Col>
          <Col md={3}>
            <InputNumber autoFocus model={model.getModel('diaPrimeiroEnvio')} min={1} max={31} required />
          </Col>
          <Col md={3}>
            <InputNumber autoFocus model={model.getModel('diaSegundoEnvio')} />
          </Col>
        </Row>
      </div>
    );
  }
}

@autobind
class GrupoEdit extends React.Component {
  render() {
    let { intl } = this.props;
    return (
      <Edit
        url="/GrupoDiaEnvio"
        title={intl.formatMessage({ id: 'label.grupoEnvioEmail' })}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height={600}
        {...this.props}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(GrupoEdit));
