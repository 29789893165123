import { Schema } from '../../utils/form';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  allowEdit: Schema.string(),
  id: Schema.number().label(<FormattedMessage id="label.id" />),
  tipo: Schema.string().label(<FormattedMessage id="tipo" />),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  nomePT: Schema.string().label(<FormattedMessage id="portugues" />),
  nomeEN: Schema.string().label(<FormattedMessage id="ingles" />),
  nomeES: Schema.string().label(<FormattedMessage id="espanhol" />),
  nomeIT: Schema.string().label(<FormattedMessage id="italiano" />),
  nomeNL: Schema.string().label(<FormattedMessage id="holandes" />),
  nomeFR: Schema.string().label(<FormattedMessage id="frances" />),
  nomeDE: Schema.string().label(<FormattedMessage id="alemao" />),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  observacao: Schema.string().label(<FormattedMessage id="observacao" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  area: Schema.string().label(),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  utilizarCodigoAutomatico: Schema.string().label(
    <FormattedMessage id="label.utilizarCodigoAutomatico" />
  ),
  responsaveis: Schema.array()
    .label(<FormattedMessage id="responsaveis" />)
    .required(),
  digitadores: Schema.array().label(),
  perspectiva: Schema.string().label(),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  unidadeMedida: Schema.string().label(
    <FormattedMessage id="unidadeDeMedida" />
  ),
  casasDecimais: Schema.string().label(
    <FormattedMessage id="label.nDeCasasDecimais" />
  ),
  classificacoes: Schema.array().label(),
  classificacoes2: Schema.array().label(),
  temas: Schema.array().label(),
  dataInicioItem: Schema.string().label(<FormattedMessage id="dataInicio" />),
  dataTerminoItem: Schema.string().label(<FormattedMessage id="dataTermino" />),
  tipoConsolidacao: Schema.string().label(
    <FormattedMessage id="tipoConsolidacao" />
  ),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  aprovadoresValores: Schema.array().label(
    <FormattedMessage id="label.aprovadoresDeValor" />
  ),
  enderecoGeoreferencia: Schema.string().label(
    <FormattedMessage id="endereco" />
  ),
  latitudeGeoreferencia: Schema.string().label(
    <FormattedMessage id="latitude" />
  ),
  longitudeGeoreferencia: Schema.string().label(
    <FormattedMessage id="longitude" />
  ),
  CampoAuxiliarTexto1: Schema.array().label(),
  CampoAuxiliarTexto2: Schema.array().label(),
  CampoAuxiliarTexto3: Schema.array().label(),

  partesInteressadas: Schema.array().label(),
  pais: Schema.string().label(<FormattedMessage id="pais" />),
  uf: Schema.string().label(<FormattedMessage id="uf" />),
  itemVinculado: Schema.string().label(
    <FormattedMessage id="label.itemVinculado" />
  ),
  georeferenciaAlterada: Schema.string().label(),
  // aba influencias
  melhor: Schema.string().label(),
  faixa: Schema.string().label(<FormattedMessage id="faixaDeFarol" />),
  usarTabelaValores: Schema.string().label(),
  itensFormulas: Schema.array().label(
    <FormattedMessage id="label.itensDaFormulaDeCalculo" />
  ),
  impactosIndiretos: Schema.array().label(
    <FormattedMessage id="label.itensDeImpactoIndireto" />
  ),
  limiteMinimoRealizado: Schema.string().label(
    <FormattedMessage id="label.limiteMinimoDeValorRealizado" />
  ),
  limiteMaximoRealizado: Schema.string().label(
    <FormattedMessage id="label.limiteMaximoDeValorRealizado" />
  ),
  percentualMetaDesempenhoZero: Schema.string().label(),
  benchmarkHistorico: Schema.string().label(),
  benchmarkInterno: Schema.string().label(),
  benchmarkExterno: Schema.string().label(),

  // aba desdobramento
  desdobramento: Schema.string().label(<FormattedMessage id="desdobramento" />),
  desdobramentos: Schema.array().label(
    <FormattedMessage id="desdobramentos" />
  ),
  valores: Schema.array().label(<FormattedMessage id="valores" />),

  // aba formulas
  formulas: Schema.array().label(''),
  tipoConsolidacaoAcumuladoMeta: Schema.string(),
  tipoConsolidacaoAcumuladoRealizado: Schema.string(),
  processarFormulaSeTodosItensComValor: Schema.string().label(
    <FormattedMessage id="label.somenteProcessarAsFormulasSeHouverValorCadastradoParaTodosOsItens" />
  ),
  retornarZeroFormulaErroDivisao: Schema.string().label(
    <FormattedMessage id="label.retornarZeroSeHouverErroDeDivisaoPorZeroNoCalculoDaFormula" />
  ),
  usarFormulaRealizadoParaMeta: Schema.string().label(),
  obterValores: Schema.array(),
  // aba permissões
  permissoes: Schema.array().label(<FormattedMessage id="label.permissoes" />),

  // configuracoes
  calendario: Schema.array().label(<FormattedMessage id="calendario" />),

  //campos customizados
  camposCustomizadosConfig: Schema.array(),
  camposCustomizados: Schema.array(),

  //RV
  colaboradorRemuneracao: Schema.string().label(
    <FormattedMessage id="label.responsavel" />
  ),
  analytics: Schema.array({
    uri: Schema.string().label(),
    mes: Schema.string().label(),
  }).label(),
  patrocinadoresProjeto: Schema.array().label(
    <FormattedMessage id="patrocinadores" />
  ),
};
