import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import GrupoDiaEnvioEdit from '../paginas/matriz-orcamentaria/grupo-envio/edit';
import GrupoDiaEnvioList from '../paginas/matriz-orcamentaria/grupo-envio/view';

@autobind
class GrupoDiaEnvioLookup extends React.Component {
  render() {
    return (
      <Lookup
        valueComponent={this.renderGrupoEnvio}
        valueKey="id"
        labelKey="nome"
        showSearch
        clearable={true}
        url="/GrupoDiaEnvioLookup"
        editComponent={GrupoDiaEnvioEdit}
        searchComponent={GrupoDiaEnvioList}
        {...this.props}
      />
    );
  }
}

export default GrupoDiaEnvioLookup;
