import React from 'react';
import { injectIntl } from 'react-intl';

import canvasContext from 'src/utils/canvas-context';
import { isNullOrWhiteSpace } from 'src/utils/string';

import { debug } from './index';
class MultilineText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      causaFundamental: props.ehCausaFundamental,
    };
  }

  static measure(props) {
    canvasContext.font = `${props.fontSize}px ${props.fontFamily}`;
    let text = props.text || '';
    let forcedLines = text.split('\n');
    let l = 0;
    let width = 0;
    let lines = [];
    for (let i = 0; i < forcedLines.length; i++) {
      let words = forcedLines[i].split(' ');
      let line = '';
      for (let n = 0; n < words.length; n++) {
        let word = words[n];
        let testLine = line + word + ' ';
        let testLineMetrics = canvasContext.measureText(testLine);
        if (testLineMetrics.width > props.width && n > 0) {
          width = Math.max(width, canvasContext.measureText(line).width);
          lines.push(line);
          l++;
          line = word + ' ';
        } else {
          line = testLine;
        }
      }
      if (line) {
        width = Math.max(width, canvasContext.measureText(line).width);
        lines.push(line);
      }
      l++;
    }
    return {
      width: width,
      height: Math.ceil(l * props.fontSize),
      lines: lines,
    };
  }

  async handleCausaFundamentalClick(text, guid, widgetId) {
    await this.setState({
      causaFundamental: this.state.causaFundamental ? false : true,
    });

    const widget = {
      item: {
        componente: {
          dadosIshikawa: {
            descricao: text,
          },
        },
        id: widgetId,
        causaIshikawaId: guid,
      },
    };

    this.props.handleSaveCausaFundamental(widget, this.state.causaFundamental);
  }

  render() {
    let {
      intl,
      x,
      y,
      width,
      height,
      fontSize,
      fontFamily,
      verticalAlign,
      horizontalAlign,
      text,
      isIshikawaHead,
      guid,
      widgetId,
      handleSaveCausaFundamental,
    } = this.props;

    let metrics = MultilineText.measure(this.props);

    let startX = 0;
    let startY = 0;

    if (verticalAlign == 'middle') {
      startY -= metrics.height / 2;
    }
    if (verticalAlign == 'bottom') {
      startY -= metrics.height;
    }

    if (horizontalAlign == 'center') {
      startX -= metrics.width / 2;
    } else if (horizontalAlign == 'right') {
      startX -= metrics.width;
    }

    let starPath = this.state.causaFundamental
      ? 'M16 6.204l-5.528-.803L8 .392L5.528 5.401L0 6.204l4 3.899l-.944 5.505L8 13.009l4.944 2.599L12 10.103l4-3.899z'
      : 'M16 6.204l-5.528-.803L8 .392L5.528 5.401L0 6.204l4 3.899l-.944 5.505L8 13.009l4.944 2.599L12 10.103l4-3.899zm-8 5.569l-3.492 1.836l.667-3.888L2.35 6.968l3.904-.567L8 2.864l1.746 3.537l3.904.567l-2.825 2.753l.667 3.888L8 11.773z';

    return (
      <g transform={`translate(${x}, ${y})`}>
        {debug && (
          <rect
            x={startX}
            y={startY}
            width={width}
            height={height}
            fill="none"
            stroke="black"
          />
        )}
        {!isIshikawaHead &&
          !isNullOrWhiteSpace(text) &&
          handleSaveCausaFundamental && (
            <svg
              x={startX - 11}
              y={startY - 5}
              width="10"
              height="10"
              style={{ transform: 'rotate(360deg)' }}
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <path d={starPath} fill="#333" />
              <rect
                x="0"
                y="0"
                width="16"
                height="16"
                fill="rgba(0, 0, 0, 0)"
                onClick={this.handleCausaFundamentalClick.bind(
                  this,
                  text,
                  guid,
                  widgetId
                )}
                style={{ cursor: 'pointer' }}
              >
                <title>{intl.formatMessage({ id: 'causaFundamental' })}</title>
              </rect>
            </svg>
          )}
        {metrics.lines.map((t, i) => (
          <text
            key={i}
            x={startX}
            y={startY + i * fontSize + fontSize * 0.8}
            fontFamily={fontFamily}
            fontSize={fontSize}
            alignmentBaseline="baseline"
          >
            {t}
          </text>
        ))}
      </g>
    );
  }
}

export default injectIntl(MultilineText);
