import React from 'react';
import Edit from '../../../componentes/edit/edit-dialog';
import Input from '../../../componentes/input';
import { autobind } from 'core-decorators';
import { Schema } from '../../../utils/form';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../../utils/string';

let schema = Schema.object({
  id: Schema.string(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model } = this.props;

    return (
      <Row>
        <Col md={3}>
          <Input model={model.getModel('idIntegracao')} required />
        </Col>
        <Col md={6}>
          <Input autoFocus model={model.getModel('descricao')} required />
        </Col>
      </Row>
    );
  }
}

@autobind
class CondicionanteEdit extends React.Component {
  render() {
    let { resources } = this.props;
    return (
      <Edit
        url="/Condicionante"
        title={capitalizeFirstLetter(resources.condicionante)}
        formComponent={FormEdit}
        schema={schema}
        width="70%"
        height={600}
        {...this.props}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CondicionanteEdit));
