import { useState, useEffect, useCallback } from 'react';
import PanelCollapse from 'src/componentes/panel-collapse';
import SeveridadeRiscoLookup from 'src/lookups/severidade-risco';
import { capitalizeFirstLetter } from 'src/utils/string';
import Info from 'src/componentes/info';
import { Row, Col, Label } from 'reactstrap';
import http from 'src/services/httpService';
import { getConfiguration } from 'src/services/commonRequests';
import LoadingContainer from 'src/componentes/loading-container';
import { useIntl } from 'react-intl';
import { Input } from 'src/componentes/Form';
import { debounce } from 'lodash';
import errorHandler from 'src/utils/errorHandler';

export default function AvaliacaoTipoSeveridade({
  inerente,
  avaliacaoModel,
  avaliacaoAnterior,
  disabled,
  configuracao = {},
  configuracaoAvaliacao = {},
  descricaoLookups,
  onChangeValueInerente,
  changeValueInerente,
  resources,
  requestChangeSeveridade,
  disabledDadosCalculoScore,
  setIsLoadingAvaliacaoResidual = () => {},
  isLoadingAvaliacaoResidual = false,
  setIsLoadingCalculoTipoSeveridade = () => {},
  isLoadingCalculoTipoSeveridade = false,
}) {
  const [tiposSeveridade, setTiposSeveridades] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [itensLookup, setItensLookup] = useState(null);
  const [descricoesTipos, setDescricoesTipos] = useState(null);
  const [descricoesTiposCarregadas, setDescricoesTiposCarregadas] = useState(false);
  const intl = useIntl();
  const modelName = 'valoresTipoSeveridade';
  const infoName = 'infoTipoSeveridadeDescricao' + inerente + descricaoLookups;
  const tipoRegistro = inerente ? { id: 1 } : { id: 2 };
  const [debouncedValue, setDebouncedValue] = useState('');
  const [timeoutCalculoTipoSeveridadeId, setTimeoutCalculoTipoSeveridadeId] =
    useState(null);
  const [timeoutCalculoSeveridadeGeralId, setTimeoutCalculoSeveridadeGeralId] =
    useState(null);

  const debouncedSetMyValue = debounce((value) => {
    setDebouncedValue(value);
  }, 1000);

  useEffect(() => {
    debouncedSetMyValue(changeValueInerente);
  }, [changeValueInerente]);

  useEffect(() => {
    if (configuracaoAvaliacao?.avaliarSeveridadePorTipo) getColumns();
    else if (configuracaoAvaliacao && !configuracaoAvaliacao.avaliarSeveridadePorTipo)
      setIsLoading(false);
  }, [configuracaoAvaliacao]);

  const getColumns = useCallback(async () => {
    setIsLoading(true);
    await http
      .post(`/TipoSeveridade/List`, {
        parameters: { buscarExcluidos: true },
        pageSize: 1000,
      })
      .then((response) => {
        setTiposSeveridades(response.data.list);
        setIsLoading(false);
      });
  });

  const getValue = (item, tipo, ehComentario = false) => {
    try {
      if (avaliacaoAnterior) {
        if (item == 'valoresTipoSeveridade' && avaliacaoModel && tipo) {
          const dados = avaliacaoModel[item];
          const result = dados?.find(
            (valor) =>
              valor.tipoSeveridade.id == tipo.id &&
              valor.tipoRegistro.id == tipoRegistro.id
          );
          return ehComentario ? result?.comentario : result?.severidade;
        }

        if (avaliacaoModel) return avaliacaoModel[item];

        return null;
      }

      if (item == 'valoresTipoSeveridade' && tipo) {
        const tipoSeveridadeValue = avaliacaoModel.getModel(item).value;

        const result = tipoSeveridadeValue?.find(
          (valor) =>
            valor.tipoSeveridade.id == tipo.id &&
            valor.tipoRegistro.id == tipoRegistro.id
        );
        return ehComentario ? result?.comentario : result?.severidade;
      } else {
        return avaliacaoModel.getModel(item).value;
      }
    } catch (e) {
      return null;
    }
  };

  const renderDescricaoArray = (tipo) => {
    const infos = descricoesTipos?.filter(
      (item) => item.tipoSeveridade.id == tipo.id && item.descricao
    );
    if (infos && infos.length > 0) {
      return (
        <table>
          <tbody>
            {infos.map((obj) => (
              <tr key={obj.nomeSeveridade}>
                <td style={{ fontWeight: 'bold' }}>{obj.nomeSeveridade}:</td>
                <td style={{ textAlign: 'left' }}>{obj.descricao}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return (
        <table>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold' }}>
                {intl.formatMessage({ id: 'labelNenhumDescricaoInformada' })}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  const requestChange = (value, tipo, comentario) => {
    const currentModel = avaliacaoModel.getModel(modelName);
    let valores = currentModel.value;
    if (!valores) valores = [];

    var itemValor = valores.find(
      (item) =>
        item.tipoSeveridade.id == tipo.id &&
        item.tipoRegistro.id == tipoRegistro.id
    );

    if (comentario) {
      if (itemValor) {
        itemValor.comentario = value;
      } else {
        valores.push({
          comentario: value,
          tipoSeveridade: tipo,
          tipoRegistro: tipoRegistro,
        });
      }
    } else {
      if (itemValor) {
        itemValor.severidade = value;
      } else {
        valores.push({
          severidade: value,
          tipoSeveridade: tipo,
          tipoRegistro: tipoRegistro,
        });
      }
    }

    currentModel.requestChange(valores);

    preencherComentarioPorTipo(valores);
    if (inerente && onChangeValueInerente) onChangeValueInerente();

    if (timeoutCalculoSeveridadeGeralId) {
      clearTimeout(timeoutCalculoSeveridadeGeralId);
    }

    const newTimeoutId = setTimeout(
      () => calcularSeveridadeGeral(valores),
      2000
    );
    setTimeoutCalculoSeveridadeGeralId(newTimeoutId);
  };

  const preencherComentarioPorTipo = (valoresAtuais = null) => {
    if (!configuracao || !avaliacaoModel?.getModel) return;

    const currentModel = avaliacaoModel.getModel(modelName);
    const valores = valoresAtuais ? valoresAtuais : currentModel.value;

    const countTipoSeveridade = tiposSeveridade
      ? tiposSeveridade.filter((f) => !f.excluido).length
      : 0;

    const comentarioTipoSeveridadeField = avaliacaoModel.getModel(
      'comentarioTipoSeveridade'
    );

    if (
      valores &&
      valores.filter((i) => !i.comentario).length === 0 &&
      ((!configuracao.avaliarRiscoInerente &&
        countTipoSeveridade &&
        countTipoSeveridade === valores.length) ||
        (configuracao &&
          configuracao.avaliarRiscoInerente &&
          countTipoSeveridade &&
          countTipoSeveridade * 2 === valores.length))
    ) {
      if (comentarioTipoSeveridadeField.value !== 'OK')
        comentarioTipoSeveridadeField.requestChange('OK');
    } else if (!countTipoSeveridade || countTipoSeveridade === 0) {
      comentarioTipoSeveridadeField.requestChange('OK');
    } else {
      comentarioTipoSeveridadeField.requestChange(null);
    }
  };

  useEffect(() => {
    preencherComentarioPorTipo();
  }, [getValue('valoresTipoSeveridade'), tiposSeveridade]);

  const requestChangeComentario = (value, tipo) => {
    requestChange(value, tipo, true);
  };

  const calcularSeveridadeGeral = (valores) => {
    if (
      configuracaoAvaliacao &&
      configuracaoAvaliacao.avaliarSeveridadePorTipo &&
      configuracaoAvaliacao.calcularSeveridadeGeralPorTipo.id > 1 &&
      requestChangeSeveridade
    ) {
      setIsLoadingAvaliacaoResidual(true);
      const riscoAvaliacaoId = avaliacaoModel.getModel('id').value;
      http
        .post(`RiscoAvaliacao/CalcularSeveridadeGeral`, {
          valoresTipoSeveridade: valores,
          riscoAvaliacaoId: riscoAvaliacaoId,
          tipoRegistro: tipoRegistro,
        })
        .then((response) => {
          requestChangeSeveridade(response.data.severidadeCalculada);
        })
        .finally(() => setIsLoadingAvaliacaoResidual(false));
    }
  };

  const loadDataLookup = useCallback(() => {
    const data = { idsSelecionados: getValue('listaSeveridadeIdMatriz') };

    setIsLoading(true);
    http
      .post(`SeveridadeRiscoLookup/Search`, {
        filter: data,
        pageSize: 1000,
      })
      .then((response) => {
        setItensLookup(response.data.list);
      })
      .finally(setIsLoading(false));
  });

  const loadDescricoesLookup = useCallback(async () => {
    if ((descricoesTipos && descricoesTipos.length > 0) || (descricoesTiposCarregadas)) return;
    setIsLoading(true);

    try {
      const response = await http.post(`TipoSeveridadeDescricao/ObterDescricoes`);
      setDescricoesTipos(response.data);
      setDescricoesTiposCarregadas(true);
    } catch (error) {
      errorHandler(error, intl);
    } finally {
      setIsLoading(false);
    }
  }, [descricoesTipos, intl]);

  useEffect(() => {
    loadDescricoesLookup();
  }, [loadDescricoesLookup]);

  useEffect(() => {
    loadDataLookup();
  }, [getValue('listaSeveridadeIdMatriz')]);

  const getValoresInerentes = () => {
    if (!avaliacaoModel || !avaliacaoModel.getModel) return null;

    const currentModel = avaliacaoModel.getModel(modelName);

    if (!inerente && currentModel.value && currentModel.value.length > 0)
      return avaliacaoModel
        .getModel(modelName)
        .value.filter((item) => item.tipoRegistro.id == 1);
  };

  const calcularValoresResiduais = () => {
    if (!disabledDadosCalculoScore) return;

    if (!inerente && !avaliacaoAnterior) {
      const valoresInerentes = getValoresInerentes();
      if (avaliacaoModel && valoresInerentes && valoresInerentes.length > 0) {
        setIsLoadingCalculoTipoSeveridade(true);
        http
          .post('/RiscoAvaliacao/CalcularEfetividadeTipoSeveridade', {
            model: avaliacaoModel.getValue(),
          })
          .then((response) => {
            if (response.data.severidadesCalculadas) {
              if (
                JSON.stringify(response.data.severidadesCalculadas) !=
                JSON.stringify(avaliacaoModel.getModel(modelName).value)
              )
                avaliacaoModel
                  .getModel(modelName)
                  .requestChange(response.data.severidadesCalculadas);
            }
          })
          .finally(() => setIsLoadingCalculoTipoSeveridade(false));
      }
    }
  };

  useEffect(() => {
    if (timeoutCalculoTipoSeveridadeId) {
      clearTimeout(timeoutCalculoTipoSeveridadeId);
    }

    const newTimeoutId = setTimeout(calcularValoresResiduais, 2000);
    setTimeoutCalculoTipoSeveridadeId(newTimeoutId);
  }, [debouncedValue]);

  return (
    <>
      {tiposSeveridade && (
        <PanelCollapse
          header={intl.formatMessage(
            { id: 'labelAvaliacaoTipoSeveridade' },
            { severidade: resources.severidade }
          )}
        >
          <LoadingContainer
            isLoading={
              isLoading ||
              isLoadingAvaliacaoResidual ||
              isLoadingCalculoTipoSeveridade
            }
          >
            <Row>
              {tiposSeveridade &&
                tiposSeveridade?.map(
                  (tipo, index) =>
                    (!tipo.excluido || getValue(modelName, tipo)) && (
                      <Col md={6} className="mb-3" key={tipo.id}>
                        <SeveridadeRiscoLookup
                          disabled={disabled || isLoadingCalculoTipoSeveridade}
                          clearable
                          key={`severidade-${tipo.id}-${index}`}
                          model={{
                            label: (
                              <span>
                                {capitalizeFirstLetter(tipo.nome)}
                                <Info
                                  id={`${infoName}-${tipo.id}`}
                                  tooltipMaxWidth={600}
                                  placement="auto"
                                  renderContent={() =>
                                    renderDescricaoArray(tipo)
                                  }
                                />
                              </span>
                            ),
                            value: getValue(modelName, tipo),
                            requestChange: (value) =>
                              requestChange(value, tipo, false),
                            errors:
                              !avaliacaoAnterior &&
                              avaliacaoModel.getModel(modelName).errors,
                          }}
                          options={itensLookup}
                        />
                        {configuracaoAvaliacao.permitirComentariosPorTipoSeveridade && (
                          <>
                            <Label>
                              {capitalizeFirstLetter(
                                intl.formatMessage({ id: 'comentario' })
                              ) +
                                ' - ' +
                                capitalizeFirstLetter(tipo.nome)}
                              {configuracaoAvaliacao.comentarioPorTipoSeveridadeObrigatorio
                                ? ' *'
                                : ''}
                            </Label>
                            <Input
                              disabled={
                                avaliacaoAnterior ||
                                disabled ||
                                isLoadingCalculoTipoSeveridade
                              }
                              key={`severidade-comentario-${tipo.id}-${index}`}
                              maxLength={5000}
                              type="textarea"
                              rows={2}
                              value={getValue(modelName, tipo, true)}
                              onChange={(e) =>
                                requestChangeComentario(e.target.value, tipo)
                              }
                              onBlur={() =>
                                onChangeValueInerente && onChangeValueInerente()
                              }
                              invalid={
                                configuracaoAvaliacao.comentarioPorTipoSeveridadeObrigatorio &&
                                !getValue(modelName, tipo, true) &&
                                !avaliacaoAnterior &&
                                avaliacaoModel.getModel(
                                  'comentarioTipoSeveridade'
                                ).errors
                              }
                            />
                          </>
                        )}
                      </Col>
                    )
                )}
            </Row>
          </LoadingContainer>
        </PanelCollapse>
      )}
    </>
  );
}
