import React, { useRef } from 'react';
import Button from '../button';
import Dialog from '../dialog';
import jsxArray from '../../utils/jsx-array';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

function Alert({
  title,
  message,
  closeModal,
  deleted,
  reloadAfterDelete = true,
  handleClose,
  afterClose,
  refBase,
  intl,
  labelButton,
  ...rest
}) {
  const modalRef = useRef();

  const handleOkClick = () => {
    if (refBase) handleClose && handleClose(refBase);
    else handleClose && handleClose();

    modalRef.current.close();
    deleted && reloadAfterDelete && window.location.reload();
    afterClose && afterClose();
  };
  const actions = jsxArray(
    <Button autoFocus type="primary" onClick={handleOkClick}>
      {labelButton ? labelButton : intl.formatMessage({ id: 'label.ok' })}
    </Button>
  );

  return (
    <Dialog
      {...rest}
      ref={modalRef}
      title={title ? title : <FormattedMessage id="msgAlerta" />}
      actions={actions}
      onRequestClose={handleOkClick}
      maxWidth="80%"
      maxHeight="60%"
      padContent
    >
      <div className="mb-4">
        {closeModal ? <span onClick={handleOkClick}>{message}</span> : message}
      </div>
    </Dialog>
  );
}

Alert.defaultProps = {
  closeModal: false,
};

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Alert));
