import React, { useState, useEffect, useCallback, Fragment, lazy } from 'react';
import Edit from '../../../componentes/edit/edit-dialog';
import Input from '../../../componentes/input';
import { Schema } from '../../../utils/form';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';
import ResponsavelLookup from '../../../lookups/colaborador';
import TipoPeriodicidadePontoControle from '../../../lookups/tipo-periodicidade-ponto-controle';
import TipoPontoControle from '../../../lookups/tipo-ponto-controle';
import http from 'src/services/httpService';
import CheckBox from '../../../componentes/checkbox';
import InputNumber from '../../../componentes/input-number';
import TipoEvidenciaLookup from '../../../lookups/tipo-evidencia';
import CalendarioLookup from '../../../lookups/calendario';
import TipoToleranciaLookup from '../../../lookups/tipo-tolerancia';
import AreaLookup from 'src/lookups/item-organizacao';
import ValidadorLookup from '../../../lookups/colaborador';
import DesenharCampoCustomizado from '../../item/campo-customizado/componentes/desenhar';
import RecorrenciaPeriodicidade from './recorrencia-periodicidade';
import Panel from '../../../componentes/panel';
import PanelCollapse from 'src/componentes/panel-collapse';
import FuncaoPontoControlelookup from '../../../lookups/funcao-pontocontrole';
import TipoDeControleLookup from '../../../lookups/tipo-de-controle';
import PontoControleLookup from 'src/lookups/ponto-controle';
import ContentManager from 'src/componentes/content-manager';
import Confirm from 'src/componentes/message-box/confirm';
import ValidacaoRestricao from 'src/paginas/item/validacao-restricao';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import IndicadoresRiscoControleView from './controle-indicador-risco/view';
import ConfirmNew from 'src/componentes/NewConfirm';
import RevisaoPontoControleList from 'src/paginas/gestao-risco/risco/RevisaoPontoControle/RevisaoPontoControleListView';
import { FILTROS, COLUNAS, ADDONS } from 'src/utils/constants';
import useAuth from 'src/hooks/useAuth';
const Historico = lazy(() => import('src/paginas/versao-alteracao'));
const TesteControleView = lazy(() =>
  import('src/paginas/gestao-risco/TesteControle/TesteControleView')
);

export const schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  utilizarTipoPontoControle: Schema.array().label(),
  utilizarCodigoAutomatico: Schema.array().label(),
  agrupador: Schema.boolean().label(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required({
      onlyIf: (context) => {
        return !context.parent.value.utilizarCodigoAutomatico;
      },
    }),
  responsavel: Schema.string()
    .label(<FormattedMessage id="responsavel" />)
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value &&
          context.parent.value.responsavelObrigatorio &&
          !context.parent.value.agrupador
        );
      },
    }),
  periodicidade: Schema.string()
    .label(<FormattedMessage id="periodicidade" />)
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value &&
          context.parent.value.tipoDeControle &&
          context.parent.value.tipoDeControle.id == 1 &&
          !context.parent.value.agrupador
        );
      },
    }),
  tipoPontoControle: Schema.string().label(),
  anexoObrigatorio: Schema.string().label(
    <FormattedMessage id="anexoObrigatorio" />
  ),
  utilizarTipoEvidencia: Schema.string(),
  tipoEvidencias: Schema.string().label(
    <FormattedMessage id="tipoEvidencias" />
  ),
  calendario: Schema.string().label(
    <FormattedMessage id="label.calendarioPadrao" />
  ),
  configurarCalendarioPontoControle: Schema.string().label(),
  diasToleranciaRevisao: Schema.string().label(
    <FormattedMessage id="tolerancia" />
  ),
  tipoTolerancia: Schema.string().label(),
  utilizarValidacaoRiscoPontoControle: Schema.string().label(),
  validadores: Schema.string().label(<FormattedMessage id="validadores" />),
  numeroRevisoesGeradasAutomaticamente: Schema.string().label(
    <FormattedMessage id="numeroRevisoesGeradasAutomaticamente" />
  ),
  tipoDeControle: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return context.parent.value && !context.parent.value.agrupador;
      },
    }),
});

export function PontoControleEditForm({
  model,
  resources,
  disabled,
  tipoInclusao,
  itemOrigem,
  setChangeEfetividade,
  handleCloseEdit,
  showConfirmMsg = false,
  showRevisoesControle = true,
  riscoControleView = false,
}) {
  const [config, setConfig] = useState({});
  const [configuracaoRisco, setConfiguracaoRisco] = useState({});
  const [stateIdToolTip, setStateIdToolTip] = useState('');
  const { addons } = useAuth();
  const possuiAddonTesteControle =
    addons?.find((x) => x.id === ADDONS.TESTE_CONTROLE && x.ativo) != null;

  const isDisabled =
    disabled || model.getModel('permissions').value
      ? !model.getModel('permissions').getModel('allowEdit').value
      : false;

  const getConfigurations = useCallback(async () => {
    try {
      const response = await http.get(
        `/PontoControleConfiguracao/ObterConfiguracoes`
      );
      setConfig(response.data);
    } catch (err) {}
  }, []);

  const getConfigurationsRisco = useCallback(async () => {
    try {
      const response = await http.get(
        `/ItemConfiguracaoRisco/ObterConfiguracoes`
      );
      setConfiguracaoRisco(response.data);
    } catch (err) {}
  }, []);

  useEffect(() => {
    getConfigurations();
  }, [getConfigurations]);

  useEffect(() => {
    getConfigurationsRisco();
  }, [getConfigurationsRisco]);

  if (!config) {
    return null;
  }

  const handleMensagemAlteracaoResponsavel = (value) => {
    ContentManager.addContent(
      <ConfirmNew
        showCancel={true}
        primaryMessage={intl.formatMessage(
          {
            id: 'msgConfirmacaoMudancaResponsavelControleRisco',
          },
          {
            pontodecontrole: resources?.pontodecontrole?.toLowerCase(),
            risco: resources?.risco?.toLowerCase(),
            responsavel: resources?.responsavel?.toLowerCase(),
          }
        )}
        onSaved={() => {
          model.getModel('responsavel').requestChange(value);
        }}
      />
    );
  };

  const requestChangeResponsavel = (value) => {
    if (showConfirmMsg && value && value.id > 0) {
      handleMensagemAlteracaoResponsavel(value);
    } else {
      model.getModel('responsavel').requestChange(value);
    }
  };

  const handleMensagemAlteracaoValidadores = (value) => {
    ContentManager.addContent(
      <ConfirmNew
        showCancel={true}
        primaryMessage={intl.formatMessage(
          {
            id: 'msgConfirmacaoMudancaValidadoresControleRisco',
          },
          {
            pontodecontrole: resources?.pontodecontrole?.toLowerCase(),
            risco: resources?.risco?.toLowerCase(),
            revisoesdecontrole: resources?.revisoesdecontrole?.toLowerCase(),
          }
        )}
        onSaved={() => {
          model.getModel('validadores').requestChange(value);
        }}
      />
    );
  };

  const requestChangeValidadores = (value) => {
    if (showConfirmMsg && value) {
      handleMensagemAlteracaoValidadores(value);
    } else {
      model.getModel('validadores').requestChange(value);
    }
  };

  const handleChangeEfetividade = (value) => {
    model.getModel('funcaoPontoControle').requestChange(value);
    setChangeEfetividade && setChangeEfetividade();
  };

  const controleTipoAgrupado = () =>
    (tipoInclusao && tipoInclusao === 2) ||
    (model && model.getModel('agrupador') && model.getModel('agrupador').value);

  const bloquearCodigoAutomatico =
    config.model &&
    config.model.permitirAlterarCodigoAutomatico == false &&
    config.model.utilizarCodigoAutomatico == true
      ? true
      : false;
  const utilizarCodigoAutomatico =
    config.model && config.model.utilizarCodigoAutomatico;
  const ehPeriodicidadeConformeCalendario =
    model.getModel('periodicidade').value &&
    model.getModel('periodicidade').value.id === 10;
  const anexoObrigatorio = config.model && config.model.anexoObrigatorio;
  const periodicidadeObrigatoria =
    model.getModel('tipoDeControle').value &&
    model.getModel('tipoDeControle').value.id === 1
      ? true
      : false;
  const ehTipoDeControleConstante =
    model.getModel('tipoDeControle').value &&
    model.getModel('tipoDeControle').value.id === 2
      ? true
      : false;
  const ehTipoDeControleOcorrencia =
    model.getModel('tipoDeControle').value &&
    model.getModel('tipoDeControle').value.id === 3
      ? true
      : false;
  const responsavelObrigatorio =
    config.model && config.model.responsavelObrigatorio;
  const agrupador = controleTipoAgrupado();
  const controlePai =
    model &&
    model.getModel('controlePai') &&
    model.getModel('controlePai').value;
  const alterarPasta = (value) => {
    if (
      model &&
      model.getModel('id') &&
      model.getModel('id').value > 0 &&
      controlePai &&
      controlePai > 0
    ) {
      ContentManager.addContent(
        <ValidacaoRestricao
          url="/PontoControle/ValidarRestricoes"
          item={model.getModel().value}
          isEdit={true}
          validarAlteracao={true}
          atualizar={(handleClose) => {
            model.getModel('controlePai').requestChange(value);
            handleClose && handleClose();
          }}
        />
      );
    } else {
      model.getModel('controlePai').requestChange(value);
    }
  };

  if (
    agrupador &&
    model &&
    model.getModel('agrupador') &&
    !model.getModel('agrupador').value
  ) {
    model.getModel('agrupador').requestChange(agrupador);
  }

  const controleId =
    model && model.getModel('id') && model.getModel('id').value;

  if (itemOrigem && (!controlePai || controlePai.id !== itemOrigem.id)) {
    const pastaOrigem = { id: itemOrigem.id, descricao: itemOrigem.descricao };
    model.getModel('controlePai').requestChange(pastaOrigem);
  }

  const intl = useIntl();

  const onChangeTooltip = (id) => {
    setStateIdToolTip(id);
  };

  const renderInput = (children, id) => {
    return (
      <div
        id={id}
        onMouseEnter={() => riscoControleView && onChangeTooltip(id)}
        onMouseLeave={() => riscoControleView && onChangeTooltip('')}
      >
        {children}
      </div>
    );
  };

  const exibirCodigoAutomatico =
    !config?.model?.utilizarCodigoAutomatico ||
    (controleId > 0 && config?.model?.utilizarCodigoAutomatico);

  const isNew = model?.getModel('id')?.value === 0;

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <Tab label={<FormattedMessage id="label.geral" />} key="1">
          <Row>
            {exibirCodigoAutomatico && (
              <Col md={2}>
                <Input
                  style={{ height: '38px' }}
                  disabled={isDisabled || bloquearCodigoAutomatico}
                  model={model.getModel('idIntegracao')}
                  required={!utilizarCodigoAutomatico}
                />
              </Col>
            )}
            <Col md={exibirCodigoAutomatico ? 5 : 6}>
              {renderInput(
                <Input
                  rows={1}
                  type="textarea"
                  style={{ height: '38px' }}
                  model={model.getModel('descricao')}
                  required
                  disabled={isDisabled || riscoControleView}
                  maxLength={1500}
                />,
                'div-descricao'
              )}
            </Col>
            <Col md={exibirCodigoAutomatico ? 5 : 6}>
              <ResponsavelLookup
                disabled={isDisabled}
                required={responsavelObrigatorio && !agrupador}
                model={{
                  label: capitalizeFirstLetter(resources.responsavel),
                  value: model.getModel('responsavel').value,
                  requestChange: requestChangeResponsavel,
                  errors: model.getModel('responsavel').errors,
                }}
              />
            </Col>
          </Row>
          <Row>
            {!agrupador &&
              model.getModel('tipoDeControle').value &&
              model.getModel('tipoDeControle').value.id == 1 && (
                <Col md={2}>
                  {renderInput(
                    <InputNumber
                      model={model.getModel(
                        'numeroRevisoesGeradasAutomaticamente'
                      )}
                      disabled={isDisabled || riscoControleView}
                    />,
                    'div-numeroRevisoesGeradasAutomaticamente'
                  )}
                </Col>
              )}
            <Col
              md={
                ehTipoDeControleConstante || ehTipoDeControleOcorrencia ? 4 : 5
              }
            >
              {renderInput(
                <AreaLookup
                  clearable
                  disabled={isDisabled || riscoControleView}
                  model={{
                    label: capitalizeFirstLetter(resources.area),
                    value: model.getModel('area').value,
                    requestChange: model.getModel('area').requestChange,
                  }}
                />,
                'div-area'
              )}
            </Col>
            {!agrupador && config.model && config.model.tipoDeControle && (
              <Col
                md={
                  ehTipoDeControleConstante || ehTipoDeControleOcorrencia
                    ? 4
                    : 5
                }
              >
                {renderInput(
                  <TipoDeControleLookup
                    model={{
                      label: intl.formatMessage(
                        { id: 'tipoDeControle' },
                        { pontodecontrole: resources.pontodecontrole }
                      ),
                      value: model.getModel('tipoDeControle').value,
                      requestChange:
                        model.getModel('tipoDeControle').requestChange,
                      errors: model.getModel('tipoDeControle').errors,
                    }}
                    disabled={
                      model.getModel('bloquearCampoTipoDeControle').value ||
                      riscoControleView
                    }
                    required
                  />,
                  'div-tipoDeControle'
                )}
              </Col>
            )}
            {model.getModel('utilizarTipoEvidencia').value &&
              (ehTipoDeControleConstante || ehTipoDeControleOcorrencia) && (
                <Col md={4}>
                  {renderInput(
                    <TipoEvidenciaLookup
                      multi
                      model={model.getModel('tipoEvidencias')}
                      disabled={isDisabled || riscoControleView}
                    />,
                    'div-tipoEvidencias'
                  )}
                </Col>
              )}
            {agrupador && (
              <Col md={4}>
                {renderInput(
                  <PontoControleLookup
                    model={{
                      label: intl.formatMessage({ id: 'labelPasta' }),
                      value: model.getModel('controlePai').value,
                      requestChange:
                        model.getModel('controlePai').requestChange,
                    }}
                    data={{
                      apenasPasta: true,
                      idRetirar:
                        model &&
                        model.getModel('id') &&
                        model.getModel('id').value,
                    }}
                    clearable
                    disabled={isDisabled || itemOrigem}
                  />,
                  'div-controlePai'
                )}
              </Col>
            )}
          </Row>
          {!agrupador && (
            <Fragment>
              <Row>
                {model.getModel('tipoDeControle').value &&
                  model.getModel('tipoDeControle').value.id == 1 && (
                    <Col md={2}>
                      {renderInput(
                        <TipoPeriodicidadePontoControle
                          model={model.getModel('periodicidade')}
                          required={periodicidadeObrigatoria}
                          disabled={isDisabled || riscoControleView}
                        />,
                        'div-periodicidade'
                      )}
                    </Col>
                  )}
                {((model.getModel('tipoDeControle').value &&
                  model.getModel('tipoDeControle').value.id == 1 &&
                  !ehPeriodicidadeConformeCalendario) ||
                  (model.getModel('tipoDeControle').value &&
                    model.getModel('tipoDeControle').value.id == 2)) && (
                  <React.Fragment>
                    <Col md={1}>
                      {renderInput(
                        <InputNumber
                          model={model.getModel('diasToleranciaRevisao')}
                          disabled={
                            model.getModel('bloquearCampoTipoDeControle')
                              .value || riscoControleView
                          }
                        />,
                        'div-diasToleranciaRevisao'
                      )}
                    </Col>
                    <Col md={4}>
                      {renderInput(
                        <TipoToleranciaLookup
                          style={{ marginTop: 27 }}
                          model={model.getModel('tipoTolerancia')}
                          disabled={
                            model.getModel('bloquearCampoTipoDeControle')
                              .value || riscoControleView
                          }
                        />,
                        'div-tipoTolerancia'
                      )}
                    </Col>
                    {model.getModel('configurarCalendarioPontoControle')
                      .value && (
                      <Col md={5}>
                        {renderInput(
                          <CalendarioLookup
                            model={model.getModel('calendario')}
                            disabled={
                              model.getModel('bloquearCampoTipoDeControle')
                                .value || riscoControleView
                            }
                          />,
                          'div-calendario'
                        )}
                      </Col>
                    )}
                    {!ehPeriodicidadeConformeCalendario &&
                      model.getModel('utilizarValidacaoRiscoPontoControle')
                        .value &&
                      model.getModel('tipoDeControle').value &&
                      model.getModel('tipoDeControle').value.id == 1 && (
                        <Col md={5}>
                          <ValidadorLookup
                            multi
                            disabled={isDisabled}
                            model={{
                              label: intl.formatMessage({ id: 'validadores' }),
                              value: model.getModel('validadores').value,
                              requestChange: requestChangeValidadores,
                              errors: model.getModel('validadores').errors,
                            }}
                          />
                        </Col>
                      )}
                  </React.Fragment>
                )}
              </Row>
              {ehPeriodicidadeConformeCalendario &&
                !ehTipoDeControleConstante &&
                !ehTipoDeControleOcorrencia && (
                  <Row>
                    <Col md={12}>
                      {renderInput(
                        <Panel
                          header={capitalizeFirstLetter(
                            intl.formatMessage({
                              id: 'recorrenciasPeriodicidade',
                            })
                          )}
                        >
                          <RecorrenciaPeriodicidade
                            model={model.getModel('recorrenciasPeriodicidade')}
                            disabled={isDisabled || riscoControleView}
                          />
                        </Panel>,
                        'div-recorrenciasPeriodicidade'
                      )}
                    </Col>
                  </Row>
                )}
              <Row>
                {anexoObrigatorio &&
                  model.getModel('tipoDeControle').value &&
                  model.getModel('tipoDeControle').value.id == 1 && (
                    <Col md={2}>
                      {renderInput(
                        <CheckBox
                          disabled={isDisabled || riscoControleView}
                          style={{ marginTop: 10 }}
                          model={{
                            label: intl.formatMessage({
                              id: 'anexoObrigatorio',
                            }),
                            value: model.getModel('anexoObrigatorio').value,
                            requestChange:
                              model.getModel('anexoObrigatorio').requestChange,
                          }}
                        />,
                        'div-anexoObrigatorio'
                      )}
                    </Col>
                  )}
                {ehPeriodicidadeConformeCalendario &&
                  model.getModel('utilizarValidacaoRiscoPontoControle').value &&
                  model.getModel('tipoDeControle').value &&
                  model.getModel('tipoDeControle').value.id == 1 && (
                    <Col md={5}>
                      <ValidadorLookup
                        multi
                        disabled={isDisabled}
                        model={{
                          label: intl.formatMessage({ id: 'validadores' }),
                          value: model.getModel('validadores').value,
                          requestChange: requestChangeValidadores,
                          errors: model.getModel('validadores').errors,
                        }}
                      />
                    </Col>
                  )}
                {model.getModel('utilizarTipoEvidencia').value &&
                  !ehTipoDeControleConstante &&
                  !ehTipoDeControleOcorrencia && (
                    <Col md={5}>
                      {renderInput(
                        <TipoEvidenciaLookup
                          multi
                          model={model.getModel('tipoEvidencias')}
                          disabled={isDisabled || riscoControleView}
                        />,
                        'div-tipoEvidencias'
                      )}
                    </Col>
                  )}
                {model.getModel('utilizarTipoPontoControle').value &&
                  !ehPeriodicidadeConformeCalendario &&
                  !ehTipoDeControleConstante &&
                  !ehTipoDeControleOcorrencia && (
                    <Col md={5}>
                      {renderInput(
                        <TipoPontoControle
                          id="tipoPontoControle"
                          onMouseOver={() =>
                            onChangeTooltip('tipoPontoControle')
                          }
                          disabled={isDisabled || riscoControleView}
                          clearable
                          model={{
                            label: intl.formatMessage(
                              { id: 'tipoPontoControle' },
                              { pontodecontrole: resources.pontodecontrole }
                            ),
                            value: model.getModel('tipoPontoControle').value,
                            requestChange:
                              model.getModel('tipoPontoControle').requestChange,
                            errors: model.getModel('tipoPontoControle').errors,
                          }}
                        />,
                        'div-tipoPontoControle'
                      )}
                    </Col>
                  )}
              </Row>
              <Row>
                {!agrupador &&
                  model.getModel('utilizarTipoPontoControle').value &&
                  (ehPeriodicidadeConformeCalendario ||
                    ehTipoDeControleConstante ||
                    ehTipoDeControleOcorrencia) && (
                    <Col md={4}>
                      {renderInput(
                        <TipoPontoControle
                          disabled={isDisabled || riscoControleView}
                          clearable
                          model={{
                            label: intl.formatMessage(
                              { id: 'tipoPontoControle' },
                              { pontodecontrole: resources.pontodecontrole }
                            ),
                            value: model.getModel('tipoPontoControle').value,
                            requestChange:
                              model.getModel('tipoPontoControle').requestChange,
                            errors: model.getModel('tipoPontoControle').errors,
                          }}
                        />,
                        'div-tipoPontoControle'
                      )}
                    </Col>
                  )}
                {config.model && config.model.funcaoPontoControle && (
                  <Col
                    md={
                      ehPeriodicidadeConformeCalendario ||
                      ehTipoDeControleConstante ||
                      ehTipoDeControleOcorrencia
                        ? 4
                        : 8
                    }
                  >
                    {renderInput(
                      <FuncaoPontoControlelookup
                        model={{
                          label: intl.formatMessage(
                            { id: 'funcaoPontoControle' },
                            { pontodecontrole: resources.pontodecontrole }
                          ),
                          value: model.getModel('funcaoPontoControle').value,
                          requestChange: handleChangeEfetividade,
                        }}
                        disabled={isDisabled || riscoControleView}
                        clearable
                      />,
                      'div-funcaoPontoControle'
                    )}
                  </Col>
                )}
                {!agrupador && (
                  <Col md={4}>
                    {renderInput(
                      <PontoControleLookup
                        model={{
                          label: intl.formatMessage({ id: 'labelPasta' }),
                          value: model.getModel('controlePai').value,
                          requestChange: alterarPasta,
                        }}
                        data={{
                          apenasPasta: true,
                          idRetirar:
                            model &&
                            model.getModel('id') &&
                            model.getModel('id').value,
                        }}
                        clearable
                        disabled={isDisabled || itemOrigem || riscoControleView}
                      />,
                      'div-controlePai'
                    )}
                  </Col>
                )}
              </Row>
              {model.getModel('camposCustomizados').value &&
                model.getModel('camposCustomizados').value.length > 0 && (
                  <DesenharCampoCustomizado
                    model={model.getModel('camposCustomizados')}
                    disabled={isDisabled || riscoControleView}
                    maxLength={1500}
                  />
                )}
            </Fragment>
          )}
          {model.getModel('utilizarObservacao').value && (
            <Row>
              <Col md={12}>
                {renderInput(
                  <Input
                    rows={5}
                    type="textarea"
                    model={{
                      label: capitalizeFirstLetter(resources.observacao),
                      value: model.getModel('observacao').value,
                      requestChange: model.getModel('observacao').requestChange,
                    }}
                    disabled={isDisabled || riscoControleView}
                  />,
                  'div-observacao'
                )}
              </Col>
            </Row>
          )}
          {!isNew && (
            <PanelCollapse
              open={false}
              header={intl.formatMessage({ id: 'label.historico' })}
              loadChildrenOnOpen={true}
            >
              <Historico
                url={`/PontoControle/ObterHistorico`}
                id={model.getModel('id').value}
              />
            </PanelCollapse>
          )}
          {stateIdToolTip && (
            <UncontrolledTooltip
              //style={{ background: '#fff' }}
              hideArrow
              placement="auto"
              target={stateIdToolTip}
            >
              {intl.formatMessage(
                { id: 'labelCampoBloqueadoControle' },
                { pontosdecontrole: resources.pontosdecontrole }
              )}
            </UncontrolledTooltip>
          )}
        </Tab>
        {!agrupador &&
          configuracaoRisco &&
          configuracaoRisco.habilitarIndicadoresRisco &&
          model.getModel('tipoDeControle').value &&
          model.getModel('tipoDeControle').value.id == 2 &&
          controleId && (
            <Tab
              label={
                <FormattedMessage
                  id="indicadorRisco"
                  values={{ risco: resources.risco }}
                />
              }
              key="2"
            >
              <IndicadoresRiscoControleView
                controleId={controleId}
                closeModal={handleCloseEdit}
                disabled={isDisabled}
              />
            </Tab>
          )}
        {!agrupador && showRevisoesControle && (
          <Tab
            label={
              <FormattedMessage
                id="revisoesPontoControle"
                values={{ revisoesdecontrole: resources.revisoesdecontrole }}
              />
            }
            key="3"
          >
            <RevisaoPontoControleList
              showTitle={false}
              showFastSearch={false}
              filterTag={FILTROS.REVISOES_PONTO_CONTROLE}
              columnsTag={COLUNAS.REVISOES_PONTO_CONTROLE}
              parameters={{ idPontoControle: model.getModel('id').value }}
              exibirAuditado={true}
              openLastFilter={false}
              utilizarClickRenderNomePontoControle={false}
              hideControlesFilter={true}
            />
          </Tab>
        )}
        {possuiAddonTesteControle && model.getModel('visualizarTesteControle').value && (
          <Tab label={<FormattedMessage id="labelTesteControle" />} key="4">
            <TesteControleView
              parameters={{ controleId: model.getModel('id').value }}
              disabled={isDisabled}
              showPanel={false}
              showNew={true}
              showSearch={false}
            />
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

const beforeSave = (
  item,
  handleSave,
  intl,
  fechar,
  resources,
  itemOriginal
) => {
  const agrupador = item?.agrupador;

  if (
    !agrupador &&
    item.id > 0 &&
    item.tipoDeControle?.id &&
    item.tipoDeControle?.id == 1
  ) {
    item.tipoToleranciaAlterada =
      (!itemOriginal.periodicidade?.id && item.periodicidade?.id) ||
      itemOriginal.periodicidade.id !== item.periodicidade.id;

    if (item.tipoToleranciaAlterada) {
      const msg = (
        <span className="text-justify" style={{ whiteSpace: 'pre-wrap' }}>
          {intl.formatMessage(
            { id: 'messagemValidacaoPeriodicidadeControleMudanca' },
            { pontodecontrole: resources.pontodecontrole }
          )}
        </span>
      );
      ContentManager.addContent(
        <Confirm message={msg} handleConfirm={() => handleSave(fechar)} />
      );
    } else {
      handleSave(fechar);
    }
  } else {
    handleSave(fechar);
  }
};

const PontoControleEdit = ({
  resources,
  handleCloseDialog,
  afterSave,
  showDelete,
  ...rest
}) => (
  <Edit
    url="/PontoControle"
    title={capitalizeFirstLetter(resources.pontodecontrole)}
    formComponent={PontoControleEditForm}
    resources={resources}
    handleClose={handleCloseDialog && handleCloseDialog}
    handleConfirmeBeforeSave={beforeSave}
    afterSave={() => {
      handleCloseDialog && handleCloseDialog(true);
      afterSave && afterSave();
    }}
    schema={schema}
    width="70%"
    maxHeight="90%"
    showDelete={showDelete}
    {...rest}
  />
);

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(PontoControleEdit));
