import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

import {
  capitalizeFirstLetter,
  lowerCaseAllLetter,
  lowerCaseFirstLetter,
} from 'src/utils/string';
import InputNumber from 'src/componentes/input-number';
import Input from 'src/componentes/input';
import { InputTranslate } from 'src/componentes/Form';
import CargoLookup from 'src/lookups/cargo';
import http from 'src/services/httpService';
import MdiIcon from 'src/componentes/mdi-icon';
import FaixaFarolLookup from 'src/lookups/faixa-farol';
import PreviewFaixaFarol from 'src/paginas/faixa-farol/preview';
import PontoFaixaResultadoLookup from 'src/lookups/ponto-faixa-resultado';
import ModeloVisualizacaoComissoesLookup from 'src/lookups/ModeloVisualizacaoComissoesLookup';
import FluxoAprovacaoGenericoLookup from 'src/lookups/FluxoAprovacaoGenericoLookup';
import { ADDONS } from 'src/utils/constants';
import useAuth from 'src/hooks/useAuth';
import TagLookup from 'src/lookups/TagLookup';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';

const Funcao = ({ model, disabled }) => {
  const intl = useIntl();

  const { terms: resources, addons } = useAuth();

  const hasCommissionAddon =
    addons?.find((x) => x.id === ADDONS.COMISSOES && x.ativo) != null;

  const possuiAddonNegociacaoMetas =
    addons?.find((x) => x.id === ADDONS.NEGOCIACAO_METAS && x.ativo) != null;

  const [
    habilitarFaixaFarolPontosPorFaixa,
    setHabilitarFaixaFarolPontosPorFaixa,
  ] = useState(false);

  useEffect(() => {
    loadConfiguration();
  }, []);

  const loadConfiguration = async () => {
    const response = await http.post(
      `/ConfiguracaoCardMeta/ObterConfiguracoes`,
      {}
    );
    setHabilitarFaixaFarolPontosPorFaixa(
      response.data.exibirFaixaFarolPontosFaixaFuncoes
    );
  };

  const handleChangeInputTranslate = (field, value) => {
    model.getModel(field).requestChange(value);
  };

  const handleChangeEditTranslate = (
    fieldDefault,
    fieldPT,
    fieldEN,
    fieldES,
    fieldIT,
    fieldNL,
    fieldFR,
    fieldDE,
    fieldTR,
    fieldAR,
    localModel
  ) => {
    model.getModel(fieldDefault).requestChange(localModel[fieldDefault]);
    model.getModel(fieldPT).requestChange(localModel[fieldPT]);
    model.getModel(fieldEN).requestChange(localModel[fieldEN]);
    model.getModel(fieldES).requestChange(localModel[fieldES]);
    model.getModel(fieldIT).requestChange(localModel[fieldIT]);
    model.getModel(fieldNL).requestChange(localModel[fieldNL]);
    model.getModel(fieldFR).requestChange(localModel[fieldFR]);
    model.getModel(fieldDE).requestChange(localModel[fieldDE]);
    model.getModel(fieldTR).requestChange(localModel[fieldTR]);
    model.getModel(fieldAR).requestChange(localModel[fieldAR]);
  };

  const handleChangeFaixa = (value) => {
    model.getModel('faixa').requestChange(value);

    if (value != null) handleOpen(value);
  };

  const handleOpen = async (faixaFarol) => {
    if (faixaFarol != null) {
      const response = await http.post(`/FaixaFarol/ObterFaixaFarol`, {
        faixaId: faixaFarol.id,
      });

      const faixa = response.data;

      if (faixa != null) {
        model
          .getModel('faixa')
          .getModel('faixaMaior')
          .requestChange(faixa.faixaMaior);
      }
    }
  };

  return (
    <>
      <div className="border mb-3 px-3 pt-3">
        <div className="mb-3">
          <span className="font-weight-bolder">
            {intl.formatMessage({ id: 'label.informacoesBasicas' })}
          </span>
        </div>
        <Row>
          <Col md={3}>
            <Input
              disabled={disabled}
              autoFocus
              model={model.getModel('idIntegracao')}
              required
            />
          </Col>
          <Col md={6}>
            <InputTranslate
              disabled={disabled}
              id={model.getModel('id') && model.getModel('id').value}
              maxLength={300}
              required={true}
              fieldDefault={'nome'}
              errorsFieldDefault={model.getModel('nome').errors}
              valueDefault={model.getModel('nome').value}
              labelDefault={model.getModel('nome').label}
              fieldPT={'nomePT'}
              valuePT={model.getModel('nomePT').value}
              fieldEN={'nomeEN'}
              valueEN={model.getModel('nomeEN').value}
              fieldES={'nomeES'}
              valueES={model.getModel('nomeES').value}
              fieldIT={'nomeIT'}
              valueIT={model.getModel('nomeIT').value}
              fieldNL={'nomeNL'}
              valueNL={model.getModel('nomeNL').value}
              fieldFR={'nomeFR'}
              valueFR={model.getModel('nomeFR').value}
              fieldDE={'nomeDE'}
              valueDE={model.getModel('nomeDE').value}
              fieldTR={'nomeTR'}
              valueTR={model.getModel('nomeTR').value}
              fieldAR={'nomeAR'}
              valueAR={model.getModel('nomeAR').value}
              handleChangeInputTranslate={handleChangeInputTranslate}
              handleChangeEditTranslate={handleChangeEditTranslate}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <CargoLookup
                  disabled={disabled}
                  model={{
                    label: intl.formatMessage(
                      { id: 'cargo' },
                      { cargo: resources.cargo }
                    ),
                    value: model.getModel('cargo').value,
                    requestChange: model.getModel('cargo').requestChange,
                    errors: model.getModel('cargo').errors,
                  }}
                />
              </Col>
              {hasCommissionAddon && (
                <Col md={12}>
                  <ModeloVisualizacaoComissoesLookup
                    clearable
                    disabled={disabled}
                    model={{
                      label: intl.formatMessage(
                        { id: 'modeloVisualizacaoComissoes' },
                        { comissoes: lowerCaseFirstLetter(resources.comissoes) }
                      ),
                      value: model.getModel('modeloVisualizacaoComissoes')
                        .value,
                      requestChange: model.getModel(
                        'modeloVisualizacaoComissoes'
                      ).requestChange,
                      errors: model.getModel('modeloVisualizacaoComissoes')
                        .errors,
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col md={8}>
            <Input
              disabled={disabled}
              maxLength={600}
              rows={5}
              type="textarea"
              model={model.getModel('descricao')}
            />
          </Col>
        </Row>
        <Row>
          {possuiAddonNegociacaoMetas && (
            <>
              <Col md={6}>
                <FluxoAprovacaoGenericoLookup
                  model={{
                    label: intl.formatMessage({ id: 'fluxoAprovacao' }),
                    value: model.getModel('fluxoAprovacao').value,
                    requestChange:
                      model.getModel('fluxoAprovacao').requestChange,
                    errors: model.getModel('fluxoAprovacao').errors,
                  }}
                  disabled={disabled}
                  data={{ tipoFluxoId: 2, validarPermissoes: false }} //lista apenas os que são negociação de metas
                />
              </Col>
              <Col md={6}>
                <ColaboradorSimplificadoLookup
                  model={{
                    label: capitalizeFirstLetter(resources.responsavel),
                    value: model.getModel('responsavel').value,
                    requestChange: model.getModel('responsavel').requestChange,
                    errors: model.getModel('responsavel').errors,
                  }}
                  disabled={disabled}
                />
              </Col>
            </>
          )}
          <Col md={6}>
            <TagLookup
              multi
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'tags' }, { tags: resources.tags })
                ),
                value: model.getModel('tags').value,
                requestChange: model.getModel('tags').requestChange,
              }}
              disabled={disabled}
              data={{ funcionalidadesIdSelecionaveis: [10] }}
              podeEditar={model.getModel('podeCadastrarTag').value}
            />
          </Col>
        </Row>
      </div>
      <div className="border mb-3 px-3 pt-3">
        <div className="mb-3">
          <div>
            <span className="font-weight-bolder">
              {intl.formatMessage({ id: 'cadastroSubfuncoes' })}
            </span>
            <MdiIcon
              id="CadastroSubFuncoes"
              icon="information-outline"
              className="ml-1"
            />
            <UncontrolledTooltip placement="right" target="CadastroSubFuncoes">
              {intl.formatMessage({ id: 'cadastroSubfuncoesTip' })}
            </UncontrolledTooltip>
          </div>
          <div>
            <span className="text-sm">
              {intl.formatMessage({ id: 'cadastroSubfuncoesInfo' })}
            </span>
          </div>
          <div className="mt-3">
            <Row>
              <Col md={3}>
                <InputNumber
                  precision={2}
                  disabled={disabled}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'fixarMetaFuncao' },
                        {
                          meta: lowerCaseAllLetter(resources.meta),
                          funcao: lowerCaseAllLetter(resources.funcao),
                        }
                      )
                    ),
                    value: model.getModel('meta').value,
                    requestChange: model.getModel('meta').requestChange,
                  }}
                ></InputNumber>
              </Col>
              <Col md={6}>
                <InputTranslate
                  disabled={disabled}
                  id={
                    model.getModel('id') && model.getModel('id').value + 'card'
                  }
                  maxLength={300}
                  required={false}
                  fieldDefault={'nomeCard'}
                  errorsFieldDefault={model.getModel('nomeCard').errors}
                  valueDefault={model.getModel('nomeCard').value}
                  labelDefault={capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'exibirCardMetasComo' },
                      {
                        cardmetas: lowerCaseAllLetter(resources.cardmetas),
                      }
                    )
                  )}
                  fieldPT={'nomeCardPT'}
                  valuePT={model.getModel('nomeCardPT').value}
                  fieldEN={'nomeCardEN'}
                  valueEN={model.getModel('nomeCardEN').value}
                  fieldES={'nomeCardES'}
                  valueES={model.getModel('nomeCardES').value}
                  fieldIT={'nomeCardIT'}
                  valueIT={model.getModel('nomeCardIT').value}
                  fieldNL={'nomeCardNL'}
                  valueNL={model.getModel('nomeCardNL').value}
                  fieldFR={'nomeCardFR'}
                  valueFR={model.getModel('nomeCardFR').value}
                  fieldDE={'nomeCardDE'}
                  valueDE={model.getModel('nomeCardDE').value}
                  fieldTR={'nomeCardTR'}
                  valueTR={model.getModel('nomeCardTR').value}
                  fieldAR={'nomeCardAR'}
                  valueAR={model.getModel('nomeCardAR').value}
                  handleChangeInputTranslate={handleChangeInputTranslate}
                  handleChangeEditTranslate={handleChangeEditTranslate}
                />
              </Col>
            </Row>
            {habilitarFaixaFarolPontosPorFaixa && (
              <Row>
                <Col>
                  <div className="mb-4">
                    <FaixaFarolLookup
                      clearable={false}
                      model={{
                        label: capitalizeFirstLetter(resources.faixa),
                        value: model.getModel('faixa').value,
                        requestChange: handleChangeFaixa,
                      }}
                    />
                  </div>
                  {model.value.faixa && (
                    <div style={{ minHeight: 50 }}>
                      <PreviewFaixaFarol
                        model={model.getModel('faixa').getModel('faixaMaior')}
                      ></PreviewFaixaFarol>
                    </div>
                  )}
                </Col>
                <Col>
                  <div>
                    <PontoFaixaResultadoLookup
                      data={{ tipoPontoFaixaResultadoId: 2 }}
                      model={{
                        label: intl.formatMessage({
                          id: 'label.pontosFaixaResultado',
                        }),
                        value: model.getModel('pontoFaixaResultado').value,
                        requestChange: model.getModel('pontoFaixaResultado')
                          .requestChange,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Funcao;
