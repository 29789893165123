import React, { useEffect } from 'react';
import { autobind } from 'core-decorators';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import AnaliseSwotEdit from './AnaliseSwotEdit';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ContentManager from 'src/componentes/content-manager';
import MdiIcon from 'src/componentes/mdi-icon';
import Button from 'src/componentes/button';

@autobind
class AnaliseSwotView extends React.Component {
  render() {
    const { intl } = this.props;
    
    return (
      <List
        editComponent={AnaliseSwotEdit}
        url="/RiscoAnaliseSwot"
        showExport={false}
        parameters={{
          tipoSwot: this.props.tipoSwot,
          processoId: this.props.processoId,
        }}
        title={this.props.header}
        styleCard={{ height: '100%' }}
        showTitle={true}
        showPage={false}
        showHeadTable={false}
        {...this.props}
      >
        <ListColumn  headerText={intl.formatMessage({ id: 'descricao' })} valueField="descricao" required/>
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(AnaliseSwotView));
