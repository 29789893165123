import Lookup from 'src/componentes/select2';

const TipoMudancaSolicitacaoGestaoMudancasProjetoLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/TipoMudancaSolicitacaoGestaoMudancasProjetoLookup"
    clearable={false}
    {...rest}
  />
);

export default TipoMudancaSolicitacaoGestaoMudancasProjetoLookup;
