import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import EditDialog from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import DatePicker from 'src/componentes/date-picker';
import InputNumberGroup from 'src/componentes/input-number-group';
import Input from 'src/componentes/input';

let schema = Schema.object({
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  valor: Schema.string()
    .label(<FormattedMessage id="valor" />)
    .required(),
  dataInicio: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, disabled } = this.props;

    return (
      <div>
        <Row>
          <Col md={8}>
            <Input
              disabled={disabled}
              required
              model={model.getModel('idIntegracao')}
            />
          </Col>
          <Col md={4}>
            <InputNumberGroup
              disabled={disabled}
              precision={2}
              required
              model={model.getModel('valor')}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <DatePicker
              disabled={disabled}
              model={model.getModel('dataInicio')}
              required
            />
          </Col>
          <Col md={3}>
            <DatePicker
              disabled={disabled}
              model={model.getModel('dataTermino')}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class SalarioEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;

    return (
      <EditDialog
        url="/Salario"
        title={intl.formatMessage({ id: 'label.salario' }, { salario: resources.salario })}
        formComponent={FormEdit}
        schema={schema}
        width="60%"
        maxHeight="90%"
        resources={resources}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(SalarioEdit));
