import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PaisLookup from '../../../lookups/pais';
import UfLookup from '../../../lookups/uf';
import http from 'src/services/httpService';

@autobind
class paisUf extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  handleChangeLookup(item) {
    this.props.pais.requestChange(item);
    this.props.uf.requestChange(null);
    this.getOptions(item);
  }

  handleChange(model, value) {
    model.requestChange(value);
    if (this.props.getAlteracao != null) this.props.getAlteracao();
  }

  getOptions(item) {
    if (item) {
      http
        .post(`/UfLookup/Search`, {
          paisId: item.id,
          pageSize: 999999,
        })
        .then((response) => {
          this.setState({ data: response.data.list });
        });
    }
  }

  render() {
    let pais = this.props.pais;
    let uf = this.props.uf;

    if (pais.value && this.state.data.length === 0) {
      this.getOptions(pais.value);
    }

    let disabled = this.props.disabled;

    return (
      <Col md={4}>
        <Row>
          <Col md={12}>
            <PaisLookup
              disabled={disabled}
              model={{
                label: pais.label,
                value: pais.value,
                requestChange: this.handleChange.bind(this, pais),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <UfLookup
              disabled={disabled}
              data={{ paisId: pais.value && pais.value.id, pageSize: 10000 }}
              model={{
                label: uf.label,
                value: uf.value,
                requestChange: this.handleChange.bind(this, uf),
              }}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(paisUf));
