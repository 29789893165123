import React from 'react';
import $ from 'jquery';
import { autobind } from 'core-decorators';
import { Row, Col, Container, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import Dialog from 'src/componentes/dialog';
import Pagination from 'src/componentes/pagination';
import { Schema, Form } from 'src/utils/form';

let schema = Schema.object({
  paginas: Schema.string().label(''),
});

@autobind
class GuiaDatasetId extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      abrirModal: this.props.abrirModalUnico,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  handleClose() {
    $('body').removeClass('modal-open');
    this.refs && this.refs.dialog && this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
      this.props.handleClose &&
        this.props.handleClose(this.salvo, this.form.getValue('id'));
    }, 300);
  }

  changeCurrentPage(newValue) {
    this.setState({
      currentPage: newValue,
    });
  }

  renderGuia2() {
    return (
      <Row style={{ marginTop: 25, marginBottom: 30 }}>
        <Col md={12}>
          <Card
            style={{ height: 380, width: '65%', margin: '0 auto' }}
            className="justify-content-center"
          >
            <img
              src={'/static/images/pbi/actioDatasetId.png'}
              width={'100%'}
              height="auto"
              alt="imgActioDatasetId"
            />
          </Card>

          <h6 className="text-center" style={{ marginTop: 15 }}>
            <FormattedMessage id="coleCodigoCopiadoTela" />
          </h6>
        </Col>
      </Row>
    );
  }

  renderGuia1() {
    return (
      <Row className="my-4">
        <Col md={4}>
          <Card style={{ height: 380 }} className="px-4">
            <h5 className="text-center my-4">
              <FormattedMessage id="configuracoes" />
            </h5>

            <p style={{ marginLeft: 10 }}>
              <FormattedMessage id="infoDatasetPBI" />
            </p>

            <p style={{ marginLeft: 10 }}>
              <FormattedMessage id="infoUrlDatasetPBI" />
            </p>
          </Card>
        </Col>

        <Col md={8}>
          <Card style={{ height: 380 }} className="justify-content-center">
            <img
              src={'/static/images/pbi/pbiDatasetUrl.png'}
              width={'100%'}
              height="auto"
              alt="img"
            />
          </Card>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Dialog width={1200} onRequestClose={this.handleClose}>
        <Container>
          <h4 className="text-center" style={{ marginTop: 5 }}>
            <FormattedMessage id="guiaDatasetIdPBI" />
          </h4>

          {!this.state.abrirModal && this.state.currentPage === 1
            ? this.renderGuia1()
            : this.renderGuia2()}

          {!this.state.abrirModal && (
            <Row>
              <Col md={5}></Col>
              <Col md={6}>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  style={{ display: 'inline' }}
                  activePage={this.state.currentPage}
                  totalPages={2}
                  totalDisplayed={2}
                  pageChanged={this.changeCurrentPage}
                />
              </Col>
            </Row>
          )}
        </Container>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    lang: state.user && state.user.idioma,
  };
}

export default injectIntl(connect(mapStateToProps)(GuiaDatasetId));
