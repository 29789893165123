import React from 'react';
import { autobind } from 'core-decorators';

import IshikawaDiagrama from './diagrama';
import guid from 'src/utils/guid';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class Ishikawa extends React.Component {
  constructor(props) {
    super(props);
    let { model, widget } = props;

    let itemWidget = widget.item;
    let componente = itemWidget.componente;
    let dadosIshikawa = componente.dadosIshikawa;

    let layoutModel = model && model.getModel('layout');
    let layoutValue =
      layoutModel &&
      layoutModel.value.find((x) => x.id == (itemWidget && itemWidget.id));

    if (!dadosIshikawa) {
      let item = model.getModel('item') && model.getModel('item').value;
      let desvio = model.getModel('desvio') && model.getModel('desvio').value;

      let dadosDefault = {
        guid: guid(),
        causaFundamental: false,
        descricao: item
          ? `${item.nome} - ${this.props.intl.formatMessage({
              id: 'desvio',
            })}: ${desvio} ${item.unidadeMedida.sigla}`
          : '',
        itens: [
          {
            guid: guid(),
            causaFundamental: false,
            descricao: '',
            itens: [],
          },
          {
            guid: guid(),
            causaFundamental: false,
            descricao: '',
            itens: [],
          },
          {
            guid: guid(),
            causaFundamental: false,
            descricao: '',
            itens: [],
          },
          {
            guid: guid(),
            causaFundamental: false,
            descricao: '',
            itens: [],
          },
          {
            guid: guid(),
            causaFundamental: false,
            descricao: '',
            itens: [],
          },
          {
            guid: guid(),
            causaFundamental: false,
            descricao: '',
            itens: [],
          },
        ],
      };

      if (layoutValue) {
        layoutValue.componente.dadosIshikawa = { ...dadosDefault };
        layoutModel.requestChange(layoutModel.value);
      }
    } else {
      if (layoutValue) {
        const pontosProblematicos = model.getModel('pontosProblematicos').value;

        dadosIshikawa.itens.map((item) => {
          let existe = pontosProblematicos.some(
            (x) =>
              x.widgetId == (itemWidget && itemWidget.id) &&
              x.causaIshikawaId == item.guid
          );

          if (!existe) item.causaFundamental = false;
          return item;
        });

        layoutValue.componente.dadosIshikawa = { ...dadosIshikawa };
        layoutModel.requestChange(layoutModel.value);
      }
    }
  }

  render() {
    let { widget, handleSaveCausaFundamental, showStar } = this.props;

    const item = widget.item;
    const dadosIsikawa = item.componente.dadosIshikawa;

    return (
      <IshikawaDiagrama
        handleSaveCausaFundamental={handleSaveCausaFundamental}
        dados={dadosIsikawa}
        widgetId={item.id}
        style={{
          height: item.height,
          width: item.width,
          zIndex: item.zIndex,
          borderColor: item.borderColor,
          borderStyle: item.borderStyle && item.borderStyle.id,
          borderWidth: item.borderWidth ? item.borderWidth + 'px' : null,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(Ishikawa));
