import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import Dialog from '../../../../componentes/dialog';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Tabs from '../../../../componentes/tabs/tabs';
import Tab from '../../../../componentes/tabs/tab';
import ColunasTabelas from '../../../../componentes/list/componentes/colunas-tabela';
import LoadingContainer from '../../../../componentes/loading-container';
import { capitalizeFirstLetter } from '../../../../utils/string';
@autobind
class Colunas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose() {
    this.refs.dialog.close();
  }

  render() {
    let { intl, resources } = this.props;

    return (
      <Dialog padContent onRequestClose={this.handleClose} mouseDownClose={false} ref="dialog" {...this.props}>
        <Row>
          <Col md={12}>
            <LoadingContainer isLoading={this.state.isLoading}>
              <Tabs defaultActiveKey="1">
                <Tab label={intl.formatMessage({ id: 'card' })} key="1">
                  {this.props.columnsCard && (
                    <ColunasTabelas
                      exibirCancelar={false}
                      close={this.handleClose}
                      palavraChave={'Campos'}
                      modal={false}
                      columns={this.props.columnsCard}
                      allColumns={this.props.allColumnsCard}
                      tag={6000} //tag para ação card
                      handleAtualizarColunas={this.props.handleAtualizarCardKanban}
                    />
                  )}
                </Tab>
                <Tab label={capitalizeFirstLetter(resources.kanban)} key="2">
                  {this.props.columnsKanban && (
                    <ColunasTabelas
                      exibirCancelar={false}
                      close={this.handleClose}
                      modal={false}
                      columns={this.props.columnsKanban}
                      allColumns={this.props.allColumnsKanban}
                      tag={6001} //tag para ação status
                      handleAtualizarColunas={this.props.handleAtualizarColunasKanban}
                    />
                  )}
                </Tab>
              </Tabs>
            </LoadingContainer>
          </Col>
        </Row>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(Colunas));
