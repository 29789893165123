import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Edit from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import { capitalizeFirstLetter } from 'src/utils/string';
import Input from 'src/componentes/input';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';

let schema = Schema.object({
  titulo: Schema.string()
    .label(<FormattedMessage id="titulo" />)
    .required(),
  conteudo: Schema.string()
    .label(<FormattedMessage id="label.conteudo" />)
    .required(),
  destinatarios: Schema.string()
    .label(<FormattedMessage id="label.destinatarios" />)
    .required(),
  responsavel: Schema.string()
    .label(<FormattedMessage id="label.responsavel" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, disabled, riscoId, resources } = this.props;

    return (
      <div>
        <br />
        <Row>
          <Col md={8}>
            <Input
              disabled={disabled}
              model={model.getModel('titulo')}
              required
              maxLength={1000}
              style={{
                height: model.getModel('responsavel').value ? '41px' : '35px',
              }}
            />
          </Col>
          <Col md={4}>
            <ColaboradorSimplificadoLookup
              disabled={disabled}
              model={model.getModel('responsavel')}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ColaboradorSimplificadoLookup
              disabled={disabled}
              model={model.getModel('destinatarios')}
              required
              multi
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              disabled={disabled}
              model={model.getModel('conteudo')}
              required
              rows={12}
              type="textarea"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class CausaEventoRiscoEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;

    return (
      <Edit
        {...this.props}
        parameters={{ eventoRiscoId: this.props.eventoRiscoId }}
        title={`${capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'envioDeEmailEventoRisco' },
            {
              risco: resources.risco,
            }
          )
        )}: ${this.props.eventoRiscoDescricao}`}
        formComponent={FormEdit}
        afterSave={this.props.afterSave && this.props.afterSave}
        schema={schema}
        showActions={true}
        width={800}
        minHeight={600}
        showDelete={false}
        enviarComunicado={true}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CausaEventoRiscoEdit));
