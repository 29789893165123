import React from 'react';
import { autobind } from 'core-decorators';
import Lookup from '../componentes/select2';
import ClassificacaoEdit from '../paginas/classificacao/edit';

@autobind
class ClassificacaoLookup extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" multi={this.props.multi} url="/ClassificacaoLookup" {...this.props} />;
  }
}
ClassificacaoLookup.defaultProps = { multi: true };

export default ClassificacaoLookup;
