import React from 'react';
import { autobind } from 'core-decorators';
import http from 'src/services/httpService';
import { Row, Col } from 'reactstrap';
import { Form, Schema } from '../../../utils/form';
import Button from '../../../componentes/button';
import Dialog from '../../dialog';
import DragDrop from '../../../paginas/configuracao-colunas-arquivo/componentes/drag-drop';
import errorHandler from '../../../utils/error-handler';
import $ from 'jquery';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LoadingContainer from '../../../componentes/loading-container';
import Alert from '../../../componentes/message-box/alert';
import ContentManager from '../../../componentes/content-manager';

let schema = Schema.object({
  listaColunasDisponiveis: Schema.array().label(
    <FormattedMessage id="label.colunasDisponiveis" />
  ),
  listaColunasUsadas: Schema.array().label(
    <FormattedMessage id="label.colunasUsadas" />
  ),
});

@autobind
class ColunasTabela extends React.Component {
  static defaultProps = {
    salvarColunas: true,
    palavraChave: 'Colunas',
    modal: true,
    exibirCancelar: true,
    exibirReplicacao: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      replicarColunas: false,
      alterarColunas: false,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    this.getReplicarColunasPermission();
    this.getAlterarColunasPermission();

    let colunasUsadas =
      this.props.columns && this.props.columns.length > 0
        ? this.props.columns.filter((c) => c?.visible)
        : this.props.allColumns.filter((c) => c?.visible && c?.default);

    this.form.getModel('listaColunasUsadas').requestChange(colunasUsadas);

    if (this.props.allColumns) {
      let colunasDisponiveis = this.props.allColumns
        .filter(
          (e) =>
            colunasUsadas &&
            !colunasUsadas.find((a) => a?.valueField === e?.valueField)
        )
        .filter((c) => c?.visible);

      this.form
        .getModel('listaColunasDisponiveis')
        .requestChange(colunasDisponiveis);
    }
  }

  handleClose() {
    if (this.props.modal) this.refs.dialog.close();
    else this.props.close();
  }

  getReplicarColunasPermission() {
    http
      .get(`/Colaborador/ObterPermissaoReplicacaoColunas`)
      .then((response) => {
        this.setState({
          replicarColunas: response.data.replicarColunas,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  getAlterarColunasPermission() {
    http
      .get(`/ColunasTabela/ObterPermissaoAlterarColunas`)
      .then((response) => {
        this.setState({
          alterarColunas: response.data.alterarColunas,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleSalvarClick() {
    if (this.props.salvarColunas) {
      this.setState({
        isLoading: true,
      });

      http
        .post(`/ColunasTabela/SalvarConfiguracoesColunasTabela`, {
          model: this.form.getValue(),
          tag: this.props.tag,
        })
        .then(() => {
          this.setState({
            isLoading: false,
          });

          this.props.handleAtualizarColunas &&
            this.props.handleAtualizarColunas(
              this.form.getModel('listaColunasUsadas').value
            );
          this.handleClose();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
        });
    } else {
      this.props.handleAtualizarColunas &&
        this.props.handleAtualizarColunas(
          this.form.getModel('listaColunasUsadas').value
        );
      this.handleClose();
    }
  }

  handleReplicarClick() {
    this.setState({
      isLoading: true,
    });

    http
      .post(`/ColunasTabela/ReplicarColunasDoAdminParaTodos`, {
        model: this.form.getValue(),
        tag: this.props.tag,
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });

        ContentManager.addContent(
          <Alert
            title={<FormattedMessage id="concluido" />}
            message={this.props.intl.formatMessage(
              { id: 'colunasForamReplicadasComSucesso' },
              { colaboradores: this.props.resources.colaboradores }
            )}
          />
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });
  }

  handleFecharClick() {
    $('body').removeClass('modal-open');
    this.handleClose();
  }

  renderTitulo() {
    let titulo = this.props.palavraChave;

    if (titulo === 'Colunas') return 'label.configuracaoDeColunas';
    else if (titulo === 'Campos') return 'configuracaoDeCampos';
    else if (titulo == 'Favoritos') return 'dashboardsFavoritos';
  }

  renderContent() {
    return (
      <Row>
        <Col md={12}>
          <DragDrop
            isValidDragToUsedColumns={this.props.isValidDragToUsedColumns}
            palavraChave={this.props.palavraChave}
            listaColunasDisponiveis={this.form.getModel(
              'listaColunasDisponiveis'
            )}
            listaColunasUsadas={this.form.getModel('listaColunasUsadas')}
            esconderColunasDisponiveis={!this.state.alterarColunas}
          />
        </Col>
      </Row>
    );
  }

  render() {
    const { resources, intl, exibirReplicacao } = this.props;

    const buttons = (
      <div>
        {exibirReplicacao && this.state.replicarColunas && (
          <Button
            style={{ marginRight: '20px' }}
            key={3}
            className="ml-2"
            onClick={this.handleReplicarClick}
          >
            {intl.formatMessage(
              { id: 'label.replicarParaColaboradores' },
              { colaboradores: resources.colaboradores }
            )}
          </Button>
        )}
        <Button key={1} color="default" onClick={this.handleFecharClick}>
          {intl.formatMessage({
            id: this.props.exibirCancelar ? 'label.cancelar' : 'label.fechar',
          })}
        </Button>
        <Button key={2} className="ml-2" onClick={this.handleSalvarClick}>
          {intl.formatMessage({ id: 'label.salvar' })}
        </Button>
      </div>
    );

    return this.props.modal ? (
      <Dialog
        title={intl.formatMessage({ id: this.renderTitulo() })}
        actions={[buttons]}
        width={650}
        height={550}
        padContent
        onRequestClose={this.handleClose}
        mouseDownClose={false}
        ref="dialog"
        {...this.props}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          {this.renderContent()}
        </LoadingContainer>
      </Dialog>
    ) : (
      <div>
        {this.renderContent()}
        <div
          style={{
            padding: '5px 15px',
            display: 'flex',
            borderTop: '0.5px #ddd solid',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          {buttons}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ColunasTabela));
