import React, { Fragment } from 'react';
import { autobind } from 'core-decorators';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Input from 'src/componentes/input';
import { InputTranslate } from 'src/componentes/Form';
import InputNumber from 'src/componentes/input-number';
import ColaboradorLookup from 'src/lookups/colaborador';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import PeriodicidadeLookup from 'src/lookups/periodicidade';
import UnidadeMedidaLookup from 'src/lookups/unidade-medida';
import Panel from 'src/componentes/panel';
import ParteInteressada from 'src/paginas/item/parte-interessada';
import PerspectivaLookup from 'src/lookups/perspectiva';
import TemaEstrategicoLookup from 'src/lookups/tema-estrategico';
import ClassificacaoLookup from 'src/lookups/classificacao';
import StatusItemLookup from 'src/lookups/status-item';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import Georeferencia from 'src/paginas/item/georeferencia';
import DesdobramentoLookup from 'src/lookups/desdobramento';
import TabelaValorLookup from 'src/lookups/tabela-valor';
import CheckBox from 'src/componentes/checkbox';
import Preview from 'src/paginas/faixa-farol/preview';
import PreviewTabelaValor from 'src/paginas/tabela-valor/preview';
import Radio from 'src/componentes/radio';
import FaixaFarolLookup from 'src/lookups/faixa-farol';
import { capitalizeFirstLetter } from 'src/utils/string';
import DataInicioTermino from 'src/paginas/formula/componentes/inicio-termino';
import PaisUf from 'src/paginas/item/pais-uf';
import ItemCentroCustoLookup from 'src/lookups/item-centro-custo';
import PacoteLookup from 'src/lookups/pacote';
import PontoFaixaResultadoLookup from 'src/lookups/ponto-faixa-resultado';
import ContentManager from 'src/componentes/content-manager';
import CriticalConfirm from 'src/componentes/message-box/critical-confirm';
import Info from 'src/componentes/info';
import ItemLookup from 'src/lookups/item';
import InputGroup from 'src/componentes/input-number-group';
import TabelaRelacionamento from 'src/paginas/item/relacionamento';
import http from 'src/services/httpService';
import { Form } from 'src/utils/form';
import LoadingContainer from 'src/componentes/loading-container';
import MensagemPersonalizada from 'src/componentes/react-toastify';
import Alert from 'src/componentes/message-box/alert';
import PatrocinadorLookup from 'src/lookups/colaborador';
import DatePicker from 'src/componentes/date-picker';
import MdiIcon from 'src/componentes/mdi-icon';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import GraficoCurvaS from 'src/paginas/item/valor/componentes/grafico';
import IndicadorRiscoLimites from 'src/paginas/gestao-risco/indicador-risco/componentes/indicador-risco-limites';
import {
  TIPO_ITENS,
  STATUS_ITEM,
  TIPO_INTERACAO_SOPHIE,
} from 'src/utils/constants';
import { MODULOS } from 'src/utils/constants';
import FluxoAprovacaoGenericoLookup from 'src/lookups/FluxoAprovacaoGenericoLookup';
import AssistenteVirtual from 'src/components/IA/ChatGPT/AssistenteVirtual';
import Button from 'src/componentes/button';
import history from '../../../../history';
import FaixaMinMax from './faixaMinMax';
import Baseline from './Baseline';
import errorHandler from 'src/utils/error-handler';
import moment from 'moment';
import PontosPorFaixaResultadoList from 'src/paginas/item/edit/resumo/pontos-faixa-resultado';
import FiltroItemRelatorioPowerBI from 'src/paginas/item/edit/resumo/filtro-relatorio-powerbi';
import TagLookup from 'src/lookups/TagLookup';
import Confirm from 'src/componentes/message-box/confirm';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import GraficoCurvaSFinanceira from 'src/paginas/item/edit/resumo/grafico-curvas-financeira';

@autobind
class ResumoEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      campoAlterado: false,
      showMessage: false,
      utilizarFaixaMinimoEMaximo: false,
      utilizarCamposCustomizados: false,
      ignoreActionAlert: false,
    };

    this.form = new Form({
      component: this,
      schema: this.props.schema, //
    });
  }

  componentDidMount() {
    this.refresh();
  }

  refreshConfigPage() {
    http.post(`/ConfiguracaoCardMeta/ObterConfiguracoes`).then((response) => {
      this.setState({
        utilizarFaixaMinimoEMaximo: response.data.utilizarFaixaMinimoEMaximo,
      });
    });
  }

  loadProjetoConfig() {
    http
      .post(`/ItemConfiguracaoProjeto/ObterConfiguracoes`, {})
      .then((response) => {
        this.setState({
          utilizarCamposCustomizados: response.data.utilizarCamposCustomizados,
        });
      });
  }

  refresh() {
    this.loadProjetoConfig();
    this.refreshConfigPage();
  }

  handleUnidadeMedidaChange(value) {
    let model = this.props.model;
    model.getModel('unidadeMedida').requestChange(value);

    if (value && value.casasDecimais !== null) {
      this.handleCasasDecimaisChange(value.casasDecimais);
    }
  }

  handleCasasDecimaisChange(value) {
    let model = this.props.model;
    if (value > 15) {
      ContentManager.addContent(
        <Alert
          message={<FormattedMessage id="limiteCasasDecimais" />}
          handleClose={model.getModel('casasDecimais').requestChange(15)}
        />
      );
    } else
      this.setState({
        campoAlterado: true,
      });
    model.getModel('casasDecimais').requestChange(value);
  }

  handleAreaChange(value) {
    let model = this.props.model;
    model.getModel('area').requestChange(value);

    let usarCodigoArea = model.getModel('utilizarCodigoAreaParaMascara').value;
    if (usarCodigoArea) {
      let novoCodigoItem =
        value.idIntegracao + model.getModel('idIntegracao').value;
      model.getModel('idIntegracao').requestChange(novoCodigoItem);
    }
  }

  handlePeriodicidade(novo) {
    let model = this.props.model;
    let isNew = model.getModel('id').value === 0 ? true : false;

    if (isNew) {
      model.getModel('periodicidade').requestChange(novo);
    } else {
      let antigo = model.getModel('periodicidade').value;
      let ehDiferente = antigo !== novo;
      if (ehDiferente) {
        ContentManager.addContent(
          <CriticalConfirm
            title={this.props.intl.formatMessage({
              id: 'alterar',
            })}
            message={this.props.intl.formatMessage({
              id: 'label.alterarPeriodicidadeExcluiraValoresVinculados',
            })}
            handleConfirm={this.handleChangePeriodicidade.bind(this, novo)}
          />
        );
      }
    }
  }

  handleChangePeriodicidade(value) {
    this.props.model.getModel('periodicidade').requestChange(value);
  }

  handleChangeFaixa(value) {
    let model = this.props.model;
    model.getModel('faixa').requestChange(value);

    if (value != null) this.handleOpen(value);
  }

  async handleOpen(faixaFarol) {
    if (faixaFarol != null) {
      var response = await http.post(`/FaixaFarol/ObterFaixaFarol`, {
        faixaId: faixaFarol.id,
      });

      let melhor = this.props.model.getModel('melhor').value;
      let faixa = response.data;

      if (melhor != null && faixa != null) {
        this.props.model
          .getModel('faixa')
          .getModel('faixaMaior')
          .requestChange(faixa.faixaMaior);
        this.props.model
          .getModel('faixa')
          .getModel('faixaMenor')
          .requestChange(faixa.faixaMenor);
        this.props.model
          .getModel('faixa')
          .getModel('faixaIgual')
          .requestChange(faixa.faixaIgual);
      }
    }
  }

  handleChangeDataTermino() {
    const model = this.props.model;

    const currentStartDate = model.getModel('dataInicioItem').value;
    const currentEndDate = model.getModel('dataTerminoItem').value;
    const currentDate = new Date();

    currentDate.setSeconds(0, 0);

    if (currentEndDate && currentEndDate < currentDate) return;
    else if (currentStartDate > currentDate)
      model.getModel('dataTerminoItem').requestChange(currentStartDate);
    else if (!currentEndDate || currentEndDate > currentDate) {
      model.getModel('dataTerminoItem').requestChange(currentDate);
    }
  }

  changeStatus(value) {
    const model = this.props.model;
    const statusId = value.id;

    model.getModel('status').requestChange(value);

    if (statusId == STATUS_ITEM.CONCLUIDO || statusId == STATUS_ITEM.CANCELADO)
      this.handleChangeDataTermino();
  }

  handleChangeStatus(value) {
    if (value.id === 5) {
      ContentManager.addContent(
        <Confirm
          message={this.props.intl.formatMessage(
            { id: 'label.mensagemStatusItem' },
            { acoes: this.props.resources.acoes }
          )}
          handleConfirm={this.changeStatus.bind(this, value)}
        />
      );
    } else {
      this.changeStatus(value);
    }
  }

  handleItemVinculado(novo) {
    let model = this.props.model;

    let isNew = model.getModel('id').value === 0 ? true : false;

    if (isNew) {
      this.handleChangeItemVinculado(novo);
    } else {
      let antigo = model.getModel('itemVinculado').value;
      let ehDiferente = antigo !== novo;

      if (ehDiferente) {
        this.handleModalItemVinculado(novo);
      }
    }
  }

  handleModalItemVinculado(novo) {
    ContentManager.addContent(
      <Confirm
        message={this.props.intl.formatMessage({
          id: 'label.vincularItemIraApagarValores',
        })}
        handleConfirm={this.handleChangeItemVinculado.bind(this, novo)}
        handleCancel={this.handleCancelItemVinculado.bind(this)}
      />
    );
  }

  handleCancelItemVinculado() {
    let model = this.props.model;
    model.getModel('itemVinculado').requestChange(null);
  }

  handleChangeGeoreferencia(field, value) {
    let model = this.props.model;
    model.getModel(field).requestChange(value);
  }

  handleChangeItemVinculado(value) {
    let model = this.props.model;
    model.getModel('itemVinculado').requestChange(value);
  }

  changeMessageStatus() {
    this.setState({ showMessage: false, isLoading: false });
  }

  async handleOpenActioAlert(data, campo, dataAntiga) {
    if (this.state.ignoreActionAlert) return;

    const model = this.props.model;
    const intl = this.props.intl;

    try {
      const response = await http.post(
        'Item/ExistemAcoesAnterioresNovaDataInicio',
        {
          itemId: model.getModel('id').value,
          dataInicioItem: data,
        }
      );

      const showAlert = response.data;
      if (!showAlert) return;

      ContentManager.addContent(
        <Confirm
          title={intl.formatMessage({
            id: 'msgAlerta',
          })}
          message={intl.formatMessage(
            {
              id: 'existemAcoesComDataInicioInferioresAoItem',
            },
            { acoes: this.props.resources?.acoes }
          )}
          handleCancel={() => {
            model.getModel(campo).requestChange(dataAntiga);
          }}
          handleConfirm={() => {
            this.setState({
              ignoreActionAlert: true,
            });
          }}
        />
      );
    } catch (_) {}
  }

  getAlteracaoData(campo, dataAntiga) {
    let model = this.props.model;

    const itemId = model && model.getModel('id').value;
    const data =
      campo == 'dataInicioItem'
        ? model.getModel('dataInicioItem').value
        : model.getModel('dataTerminoItem').value;
    const errorMsg =
      campo == 'dataInicioItem'
        ? 'label.deletarItemComValoresDataInferiorDataInicio'
        : 'label.deletarItemComValoresDataSuperiorDataTermino';

    // Validação de ações no intervalo de datas.

    if (data && campo == 'dataInicioItem') {
      this.handleOpenActioAlert(data, campo, dataAntiga);
    }

    if (data) {
      http
        .post('/Item/ConfereValorData', {
          itemId: itemId,
          data: data,
          campo: campo,
        })
        .then(() => {})
        .catch(() => {
          ContentManager.addContent(
            <Confirm
              title={<FormattedMessage id="msgAlerta" />}
              message={this.props.intl.formatMessage({ id: errorMsg })}
              handleCancel={this.handleDeletarValorDataCancel.bind(
                this,
                campo,
                dataAntiga
              )}
            />
          );
        });
    }
  }

  async handleDeletarValorDataCancel(campo, data) {
    this.props.model.getModel(campo).requestChange(data);
  }

  handleRelacionamentosClick(id) {
    let url = `?itemId=${id}`;

    history.push({
      pathname: '/gerenciar/arvore',
      search: url,
    });
  }

  ehCampoBloqueado(nomeCampo) {
    let model = this.props.model;
    let camposBloqueados = model.getModel('camposBloqueados');

    if (camposBloqueados.value != null) {
      let campoBloqueado = camposBloqueados.value.find(
        (campo) => campo.campo === nomeCampo
      );

      return campoBloqueado;
    } else return false;
  }

  handleChangeInputTranslate(field, value) {
    let model = this.props.model;
    model.getModel(field).requestChange(value);
  }

  handleChangeEditTranslate(
    fieldDefault,
    fieldPT,
    fieldEN,
    fieldES,
    fieldIT,
    fieldNL,
    fieldFR,
    fieldDE,
    fieldTR,
    fieldAR,
    localModel
  ) {
    let model = this.props.model;
    model.getModel(fieldDefault).requestChange(localModel[fieldDefault]);
    model.getModel(fieldPT).requestChange(localModel[fieldPT]);
    model.getModel(fieldEN).requestChange(localModel[fieldEN]);
    model.getModel(fieldES).requestChange(localModel[fieldES]);
    model.getModel(fieldIT).requestChange(localModel[fieldIT]);
    model.getModel(fieldNL).requestChange(localModel[fieldNL]);
    model.getModel(fieldFR).requestChange(localModel[fieldFR]);
    model.getModel(fieldDE).requestChange(localModel[fieldDE]);
    model.getModel(fieldTR).requestChange(localModel[fieldTR]);
    model.getModel(fieldAR).requestChange(localModel[fieldAR]);
  }

  async validateSelectedRelatedProjects(projects) {
    const { resources, intl } = this.props;

    const currentProjectStartDate =
      this.props.model.getModel('dataInicioItem').value;
    const currentProjectEndDate =
      this.props.model.getModel('dataTerminoItem').value;
    const status = this.props.model.getModel('status').value;

    const acceptedMoves = (internalProject) =>
      status?.id != STATUS_ITEM.CONCLUIDO &&
      internalProject.dataInicioItemSemFormatacao >= currentProjectStartDate &&
      (!currentProjectEndDate ||
        (internalProject.dataTerminoItemSemFormatacao &&
          internalProject.dataTerminoItemSemFormatacao <=
            currentProjectEndDate));

    const validated = projects?.every((x) => acceptedMoves(x));

    if (!validated) {
      const promise = await new Promise((resolve) => {
        resolve({
          response: {
            data: {
              errorMessage: intl.formatMessage(
                { id: 'naoEhPossivelAssociarSubProjetosPeriodoInvalido' },
                {
                  projetos: resources.projetos,
                  projeto: resources.projeto,
                }
              ),
            },
          },
        });
      });
      errorHandler(promise);
    }

    return validated;
  }

  renderBasicInfo(
    tipoItem,
    disabled,
    disabledDefault,
    podeEditar,
    podeEditarCamposDesdobramento,
    permitirAlterarCodigo,
    ehProjetoEmAprovacao,
    ehProjetoReprovado,
    idsSelecionadosStatus,
    model,
    intl,
    resources,
    hasEPModule,
    allowEditStatus
  ) {
    const tiposPeriodicidadeIgnorar =
      tipoItem == TIPO_ITENS.PROJETO ? [1, 2, 3, 5, 7, 8, 9] : null;

    return (
      <>
        <Row>
          {tipoItem != TIPO_ITENS.DESEMPENHO_INDIVIDUAL && (
            <Col md={6}>
              <InputTranslate
                id={model.getModel('id') && model.getModel('id').value}
                maxLength={300}
                fieldDefault={'nome'}
                valueDefault={model.getModel('nome').value}
                labelDefault={model.getModel('nome').label}
                errorsFieldDefault={model.getModel('nome').errors}
                fieldPT={'nomePT'}
                valuePT={model.getModel('nomePT').value}
                fieldEN={'nomeEN'}
                valueEN={model.getModel('nomeEN').value}
                fieldES={'nomeES'}
                valueES={model.getModel('nomeES').value}
                fieldIT={'nomeIT'}
                valueIT={model.getModel('nomeIT').value}
                fieldNL={'nomeNL'}
                valueNL={model.getModel('nomeNL').value}
                fieldFR={'nomeFR'}
                valueFR={model.getModel('nomeFR').value}
                fieldDE={'nomeDE'}
                valueDE={model.getModel('nomeDE').value}
                fieldTR={'nomeTR'}
                valueTR={model.getModel('nomeTR').value}
                fieldAR={'nomeAR'}
                valueAR={model.getModel('nomeAR').value}
                required={true}
                disabled={disabled || !podeEditarCamposDesdobramento}
                handleChangeInputTranslate={this.handleChangeInputTranslate}
                handleChangeEditTranslate={this.handleChangeEditTranslate}
              />
            </Col>
          )}
          <Col md={3}>
            <Input
              model={model.getModel('idIntegracao')}
              disabled={
                disabled ||
                !podeEditarCamposDesdobramento ||
                !permitirAlterarCodigo
              }
              required={!model.getModel('utilizarCodigoAutomatico').value}
            />
          </Col>
          {tipoItem != TIPO_ITENS.DESEMPENHO_INDIVIDUAL && (
            <Col md={3}>
              <ItemOrganizacaoLookup
                required
                data={{ tiposIdSelecionaveis: [1, 2, 3] }}
                disabled={disabled}
                model={{
                  label: capitalizeFirstLetter(resources.area),
                  value: model.getModel('area').value,
                  requestChange: this.handleAreaChange,
                  errors: model.getModel('area').errors,
                }}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={6}>
            {tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL ? (
              <ColaboradorSimplificadoLookup
                disabled={disabled}
                required
                model={{
                  label: capitalizeFirstLetter(resources.responsavel),
                  value: model.getModel('colaboradorRemuneracao').value,
                  requestChange: model.getModel('colaboradorRemuneracao')
                    .requestChange,
                  errors: model.getModel('colaboradorRemuneracao').errors,
                }}
              />
            ) : (
              <ColaboradorSimplificadoLookup
                disabled={disabled}
                multi
                required
                model={{
                  label:
                    tipoItem == TIPO_ITENS.PROJETO
                      ? capitalizeFirstLetter(resources.responsaveisprojeto)
                      : capitalizeFirstLetter(resources.responsaveis),
                  value: model.getModel('responsaveis').value,
                  requestChange: model.getModel('responsaveis').requestChange,
                  errors: model.getModel('responsaveis').errors,
                }}
              />
            )}
          </Col>
          {(!ehProjetoEmAprovacao || ehProjetoReprovado) && (
            <Col md={6}>
              <StatusItemLookup
                required
                model={{
                  label: model.getModel('status').label,
                  value: model.getModel('status').value,
                  requestChange: this.handleChangeStatus,
                }}
                disabled={disabled && !allowEditStatus}
                data={idsSelecionadosStatus}
              />
            </Col>
          )}
          {hasEPModule && ehProjetoEmAprovacao && (
            <Col md={6}>
              <FluxoAprovacaoGenericoLookup
                required
                clearable={false}
                model={{
                  label: intl.formatMessage({ id: 'fluxoAprovacao' }),
                  value: model.getModel('fluxoAprovacao').value,
                  requestChange: model.getModel('fluxoAprovacao').requestChange,
                }}
                disabled={disabledDefault}
              />
            </Col>
          )}
          {(tipoItem == TIPO_ITENS.INDICADOR ||
            tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
            tipoItem == TIPO_ITENS.PARAMETRO ||
            tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
            tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL ||
            tipoItem == TIPO_ITENS.TEMPLATE_INDICADOR ||
            tipoItem == TIPO_ITENS.INDICADOR_RISCO ||
            (tipoItem == TIPO_ITENS.PROJETO &&
              model.getModel('configuracaoGeral').value &&
              model.getModel('configuracaoGeral').value
                .permitirDigitacaoValores)) && (
            <Col md={3}>
              <PeriodicidadeLookup
                required
                model={{
                  label: model.getModel('periodicidade').label,
                  value: model.getModel('periodicidade').value,
                  requestChange: this.handlePeriodicidade,
                  errors: model.getModel('periodicidade').errors,
                }}
                disabled={
                  disabled ||
                  !podeEditarCamposDesdobramento ||
                  this.ehCampoBloqueado('periodicidade')
                }
                data={{ tiposIdsIgnorar: tiposPeriodicidadeIgnorar }}
              />
            </Col>
          )}
        </Row>
        <Row>
          {(tipoItem == TIPO_ITENS.INDICADOR ||
            tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
            tipoItem == TIPO_ITENS.PARAMETRO ||
            tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
            tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL ||
            tipoItem == TIPO_ITENS.TEMPLATE_INDICADOR ||
            tipoItem == TIPO_ITENS.INDICADOR_RISCO) && (
            <Col md={2}>
              <UnidadeMedidaLookup
                required
                model={{
                  label: model.getModel('unidadeMedida').label,
                  value: model.getModel('unidadeMedida').value,
                  requestChange: this.handleUnidadeMedidaChange,
                  errors: model.getModel('unidadeMedida').errors,
                }}
                disabled={
                  disabled ||
                  !podeEditarCamposDesdobramento ||
                  this.ehCampoBloqueado('unidadeMedida')
                }
              />
            </Col>
          )}
          {(tipoItem == TIPO_ITENS.INDICADOR ||
            tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
            tipoItem == TIPO_ITENS.PARAMETRO ||
            tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
            tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL ||
            tipoItem == TIPO_ITENS.TEMPLATE_INDICADOR ||
            tipoItem == TIPO_ITENS.INDICADOR_RISCO) && (
            <Col md={2}>
              <InputNumber
                required
                model={{
                  label: model.getModel('casasDecimais').label,
                  value: model.getModel('casasDecimais').value,
                  requestChange: this.handleCasasDecimaisChange,
                  errors: model.getModel('casasDecimais').errors,
                }}
                disabled={
                  disabled ||
                  !podeEditarCamposDesdobramento ||
                  this.ehCampoBloqueado('casasDecimais')
                }
              />
            </Col>
          )}
          {tipoItem != TIPO_ITENS.PROJETO && (
            <Col md={6}>
              <DataInicioTermino
                getAlteracao={this.getAlteracaoData}
                required
                disabled={disabled}
                showClear={false}
                mesAno={tipoItem == TIPO_ITENS.PROJETO ? false : true}
                dataInicio={model.getModel('dataInicioItem')}
                dataTermino={model.getModel('dataTerminoItem')}
              />
            </Col>
          )}
          {model.getModel('configuracaoGeral').value &&
            model.getModel('configuracaoGeral').value.habilitarEquipe && (
              <Col md={5}>
                <ColaboradorSimplificadoLookup
                  disabled={disabled}
                  multi
                  model={{
                    label: intl.formatMessage({ id: 'equipe' }),
                    value: model.getModel('equipe').value,
                    requestChange: model.getModel('equipe').requestChange,
                    errors: model.getModel('equipe').errors,
                  }}
                />
              </Col>
            )}

          {model.getModel('configuracaoGeral').value &&
            model.getModel('configuracaoGeral').value.utilizarPatrocinador &&
            tipoItem == TIPO_ITENS.PROJETO && (
              <Col md={5}>
                <PatrocinadorLookup
                  multi
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'label.patrocinadores' },
                        { patrocinadores: resources.patrocinadores }
                      )
                    ),
                    value: model.getModel('patrocinadores').value,
                    requestChange:
                      model.getModel('patrocinadores').requestChange,
                  }}
                  disabled={podeEditar}
                />
              </Col>
            )}
          <Col md={2}>
            <ArquivoUploadAnexos
              tipoAnexo={this.props.tiposAnexos.item}
              model={model.getModel('arquivos')}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          {tipoItem == TIPO_ITENS.INDICADOR_RISCO && (
            <Col md={6}>
              <ColaboradorSimplificadoLookup
                multi
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'responsaveisPeloInput' },
                      { responsaveis: resources.responsaveis }
                    )
                  ),
                  value: model.getModel('responsaveisPeloInput').value,
                  requestChange: model.getModel('responsaveisPeloInput')
                    .requestChange,
                  errors: model.getModel('responsaveisPeloInput').errors,
                }}
              />
            </Col>
          )}
        </Row>
      </>
    );
  }

  renderCustomFields(tipoItem, disabled, model) {
    return (
      model.getModel('camposCustomizados').value &&
      model.getModel('camposCustomizados').value.length > 0 &&
      (tipoItem != TIPO_ITENS.PROJETO ||
        this.state.utilizarCamposCustomizados) && (
        <DesenharCampoCustomizado
          model={model.getModel('camposCustomizados')}
          disabled={disabled}
        />
      )
    );
  }

  renderFiltroRelatorioPowerBI(disabled, model) {
    return (
      model.getModel('itemRelatorioPowerBI').value && (
        <FiltroItemRelatorioPowerBI
          model={model.getModel('itemRelatorioPowerBI')}
          disabled={disabled}
          itemId={model.getModel('id').value}
          tipoItemId={model.getModel('tipo').value?.id}
        />
      )
    );
  }

  assistenteVirtualProjeto = (id, intl, resources, user, nomeProjeto) => {
    try {
      http
        .post('/Projeto/GetInfoInsight', {
          projetoId: id,
        })
        .then((response) => {
          const prompt = response?.data?.prompt;

          const msgConsultoraVirtualActio = intl
            .formatMessage({
              id: 'tituloSophieInsightProjeto',
            })
            .replace('{nomeProjeto}', nomeProjeto)
            .replace('{projeto}', resources.projeto);

          ContentManager.addContent(
            <AssistenteVirtual
              user={user}
              prompt={prompt}
              title={msgConsultoraVirtualActio}
              interactionType={TIPO_INTERACAO_SOPHIE.INSIGHTS_PROJETO}
            />
          );
        });
    } catch (err) {
      errorHandler(err);
    }
  };

  renderAssistenteVirtual(tipoItem, model, intl, resources, user) {
    const exibir = [TIPO_ITENS.PROJETO].includes(tipoItem);
    if (!exibir) return;

    const id = model.getModel('id').value;
    const nome = model.getModel('nome').value;

    return (
      exibir && (
        <>
          <Button
            id="headerSophie"
            className="bg-primary pull-right"
            style={{ height: '37px' }}
            onClick={() =>
              this.assistenteVirtualProjeto(id, intl, resources, user, nome)
            }
          >
            <img alt="IA logo" src="/ia/ia_branco.svg" />
            <span className="ml-2">
              {intl.formatMessage({
                id: 'gerarInsights',
              })}
            </span>
          </Button>
          <UncontrolledTooltip placement="auto" target={'headerSophie'}>
            {intl
              .formatMessage({
                id: 'tooltipSophieInsightProjeto',
              })
              .replace('{nomeProjeto}', nome)
              .replace('{projeto}', resources.projeto)}
          </UncontrolledTooltip>
        </>
      )
    );
  }

  render() {
    const {
      resources,
      intl,
      modulosComprados,
      model,
      moduloAtual,
      moduloInteligenciaArtificial,
      user,
      configuracao,
    } = this.props;

    const tipoItem =
      model.getModel('tipo').value && model.getModel('tipo').value.id;

    const disabledDefault = model.getModel('permissions').value
      ? !model.getModel('permissions').getModel('allowEdit').value
      : false;
    
    const allowEditStatus = model.getModel('permissions') && model.getModel('permissions').getModel('allowEditFallback').value;

    const ehProjetoEmAprovacao =
      tipoItem == TIPO_ITENS.PROJETO &&
      model.getModel('status') &&
      model.getModel('status').value &&
      (model.getModel('status').value.id == 6 ||
        model.getModel('status').value.id == 7);

    const ehProjetoReprovado =
      tipoItem == TIPO_ITENS.PROJETO &&
      model.getModel('status') &&
      model.getModel('status').value &&
      model.getModel('status').value.id == 7;

    const idsSelecionadosStatus =
      moduloAtual && moduloAtual.id == 7 && ehProjetoReprovado
        ? { idsSelecionados: [6, 7] }
        : { idsSelecionados: [1, 2, 3, 4, 5] };

    const projetoFluxoAprovacaoDisabled =
      model.getModel('status').value?.id == 6 ? true : disabledDefault;

    const disabled = ehProjetoEmAprovacao
      ? projetoFluxoAprovacaoDisabled
      : disabledDefault;

    let isNew = model.getModel('id').value === 0 ? true : false;
    let permitirAlterarCodigo =
      model.getModel('permitirAlterarCodigo') &&
      model.getModel('permitirAlterarCodigo').value;

    let possuiModuloMO = modulosComprados.find((m) => m.id === 2 && m.ativo);
    let possuiModuloRV = modulosComprados.find((m) => m.id === 3 && m.ativo);
    let possuiModuloA = modulosComprados.find((m) => m.id === 6 && m.ativo);
    let possuiModuloEP = modulosComprados.find((m) => m.id === 7 && m.ativo);

    let grafico =
      model.getModel('graficoCurvaS') && model.getModel('graficoCurvaS').value;

    let podeEditarCamposDesdobramento =
      (model.getModel('desdobramento').value
        ? !model.getModel('pai').value
        : true) && !disabled;

    //para projeto
    let podeEditar = disabled ? true : !model.getModel('podeEditar').value;

    let disabledIntegracaoMO = disabled
      ? true
      : model.getModel('bloquearIntegracaoMO').value;

    const isProject = tipoItem === TIPO_ITENS.PROJETO;
    const hasEPModule = modulosComprados.find(
      (m) => m.id === MODULOS.ESCRITORIO_PROJETOS && m.ativo
    );

    const showRiscos = model.getModel('configuracao').value?.visualizarRiscos;
    const exbibirFaixaFarol =
      tipoItem != TIPO_ITENS.PROJETO ||
      (tipoItem == TIPO_ITENS.PROJETO &&
        model.getModel('configuracaoGeral').value &&
        (model.getModel('configuracaoGeral').value
          .utilizarFarolDesempenhoFaixa ||
          model.getModel('configuracaoGeral').value.permitirDigitacaoValores));

    const exbibirPontosFaixaResultado =
      tipoItem == TIPO_ITENS.INDICADOR ||
      tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
      (tipoItem == TIPO_ITENS.PROJETO &&
        model.getModel('configuracaoGeral').value &&
        (model.getModel('configuracaoGeral').value
          .utilizarPontoFaixaResultado ||
          model.getModel('configuracaoGeral').value.permitirDigitacaoValores));

    const exibirListaRegistros =
      tipoItem != TIPO_ITENS.PROJETO ||
      (tipoItem == TIPO_ITENS.PROJETO &&
        model.getModel('configuracaoGeral').value &&
        model.getModel('configuracaoGeral').value.permitirDigitacaoValores);

    const podeEditarPontosFaixaResultado = model.getModel('permissions').value
      ? model.getModel('permissions').getModel('allowEditPontosFaixaResultado')
          .value
      : false;

    const podeEditarFaixaResultadoMinimoMaximo = model.getModel('permissions')
      .value
      ? model
          .getModel('permissions')
          .getModel('allowEditFaixaResultadoMinimoMaximo').value
      : false;

    const opcoesMelhor =
      tipoItem === 14
        ? model.value.opcoesMelhor
          ? model.value.opcoesMelhor.filter((opcao) => opcao.id === 1)
          : []
        : model.value.opcoesMelhor;

    return (
      <LoadingContainer isLoading={this.state.isLoading}>
        <Panel
          header={<FormattedMessage id="label.informacoesBasicas" />}
          headerStyle={{ display: 'inline-flex' }}
          headerContent={
            moduloInteligenciaArtificial &&
            this.renderAssistenteVirtual(tipoItem, model, intl, resources, user)
          }
        >
          {tipoItem == TIPO_ITENS.PROJETO ? (
            <Panel>
              {this.renderBasicInfo(
                tipoItem,
                disabled,
                disabledDefault,
                podeEditar,
                podeEditarCamposDesdobramento,
                permitirAlterarCodigo,
                ehProjetoEmAprovacao,
                ehProjetoReprovado,
                idsSelecionadosStatus,
                model,
                intl,
                resources,
                hasEPModule,
                allowEditStatus
              )}
            </Panel>
          ) : (
            this.renderBasicInfo(
              tipoItem,
              disabled,
              disabledDefault,
              podeEditar,
              podeEditarCamposDesdobramento,
              permitirAlterarCodigo,
              ehProjetoEmAprovacao,
              ehProjetoReprovado,
              idsSelecionadosStatus,
              model,
              intl,
              resources,
              hasEPModule,
              allowEditStatus
            )
          )}
          {tipoItem == TIPO_ITENS.PROJETO && (
            <>
              <Panel>
                <Row>
                  <Col md={6}>
                    <DataInicioTermino
                      getAlteracao={this.getAlteracaoData}
                      required
                      disabled={disabled}
                      showClear={false}
                      mesAno={tipoItem == TIPO_ITENS.PROJETO ? false : true}
                      dataInicio={model.getModel('dataInicioItem')}
                      dataTermino={model.getModel('dataTerminoItem')}
                    />
                  </Col>
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarPercentualExecucao && (
                      <>
                        <Col md={3}>
                          <InputNumber
                            precision={2}
                            disabled={true}
                            model={{
                              label: resources.percprevisto,
                              value: model.getModel('percentualPrevisto').value,
                              requestChange:
                                model.getModel('percentualPrevisto')
                                  .requestChange,
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <InputNumber
                            precision={2}
                            disabled={true}
                            model={{
                              label: resources.percrealizado,
                              value: model.getModel('percentualExecutado')
                                .value,
                              requestChange: model.getModel(
                                'percentualExecutado'
                              ).requestChange,
                            }}
                          />
                        </Col>
                      </>
                    )}
                  <Col md={12}>
                    <hr />
                  </Col>
                </Row>
                {hasEPModule &&
                  model.getModel('podeVisualizarBaseline').value &&
                  !ehProjetoEmAprovacao && (
                    <Row>
                      <Col md={12}>
                        <Baseline model={model} />
                      </Col>
                    </Row>
                  )}
                {model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarPrazoAcoes &&
                  !ehProjetoEmAprovacao && (
                    <Row>
                      <Col md={12}>
                        <div className="font-weight-bolder mb-3">
                          {capitalizeFirstLetter(resources.acao)}
                        </div>
                      </Col>
                      <Col md={12}>
                        <Row>
                          <Col md={3}>
                            <DatePicker
                              model={{
                                label: intl.formatMessage({
                                  id: 'dataInicioPrevista',
                                }),
                                value: model.getModel('dataInicioAcao').value,
                                requestChange:
                                  model.getModel('dataInicioAcao')
                                    .requestChange,
                              }}
                              disabled={true || podeEditar}
                              timeFormat={false}
                            />
                          </Col>
                          <Col md={3}>
                            <DatePicker
                              model={{
                                label: intl.formatMessage({
                                  id: 'dataTerminoPrevista',
                                }),
                                value: model.getModel('dataTerminoAcao').value,
                                requestChange:
                                  model.getModel('dataTerminoAcao')
                                    .requestChange,
                              }}
                              disabled={true || podeEditar}
                              timeFormat={false}
                            />
                          </Col>
                          <Col md={3}>
                            <DatePicker
                              model={{
                                label: capitalizeFirstLetter(
                                  intl.formatMessage({
                                    id: 'dataInicioRealizada',
                                  })
                                ),
                                value: model.getModel('dataInicioRealizadoAcao')
                                  .value,
                                requestChange: model.getModel(
                                  'dataInicioRealizadoAcao'
                                ).requestChange,
                              }}
                              disabled={true || podeEditar}
                              timeFormat={false}
                            />
                          </Col>
                          <Col md={3}>
                            <DatePicker
                              model={{
                                label: capitalizeFirstLetter(
                                  intl.formatMessage({
                                    id: 'dataTerminoRealizada',
                                  })
                                ),
                                value: model.getModel(
                                  'dataTerminoRealizadoAcao'
                                ).value,
                                requestChange: model.getModel(
                                  'dataTerminoRealizadoAcao'
                                ).requestChange,
                              }}
                              disabled={true || podeEditar}
                              timeFormat={false}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12}>
                        <hr />
                      </Col>
                    </Row>
                  )}
                {model.getModel('configuracaoGeral').value &&
                  (model.getModel('configuracaoGeral').value
                    .utilizarInvestimento ||
                    model.getModel('configuracaoGeral').value
                      .utilizarCusteio) && (
                    <Row>
                      <Col md={12}>
                        <div className="font-weight-bolder mb-3">
                          {capitalizeFirstLetter(resources.orcamento)}
                        </div>
                      </Col>
                      {model.getModel('configuracaoGeral').value &&
                        model.getModel('configuracaoGeral').value
                          .utilizarInvestimento && (
                          <Col md={3}>
                            <InputNumber
                              precision={2}
                              model={{
                                label: intl.formatMessage(
                                  { id: 'investimentoPrevisto' },
                                  { investimento: resources.investimento }
                                ),
                                value: model.getModel('investimentoPrevisto')
                                  .value,
                                requestChange: model.getModel(
                                  'investimentoPrevisto'
                                ).requestChange,
                              }}
                              disabled={
                                model.getModel('bloquearInvestimentoPrevisto')
                                  .value || podeEditar
                              }
                            />
                          </Col>
                        )}
                      {model.getModel('configuracaoGeral').value &&
                        model.getModel('configuracaoGeral').value
                          .utilizarInvestimento &&
                        model.getModel('configuracaoGeral').value
                          .utilizarValorRetificado && (
                          <Col md={4}>
                            <InputNumber
                              precision={2}
                              model={{
                                label: intl.formatMessage(
                                  { id: 'investimentoPrevistoRetificado' },
                                  { investimento: resources.investimento }
                                ),
                                value: model.getModel(
                                  'investimentoPrevistoRetificado'
                                ).value,
                                requestChange: model.getModel(
                                  'investimentoPrevistoRetificado'
                                ).requestChange,
                              }}
                              disabled={
                                model.getModel('bloquearInvestimentoPrevisto')
                                  .value || podeEditar
                              }
                            />
                          </Col>
                        )}
                      {model.getModel('configuracaoGeral').value &&
                        model.getModel('configuracaoGeral').value
                          .utilizarInvestimento && (
                          <Col md={3}>
                            <InputGroup
                              precision={2}
                              disabled={
                                model.getModel('bloquearInvestimentoRealizado')
                                  .value || podeEditar
                              }
                              addon={
                                model.getModel('farolInvestimento').value ? (
                                  <MdiIcon
                                    icon={
                                      model.getModel('farolInvestimento')
                                        .value &&
                                      model.getModel('farolInvestimento').value
                                        .icone
                                    }
                                    color={
                                      model.getModel('farolInvestimento')
                                        .value &&
                                      model.getModel('farolInvestimento').value
                                        .cor
                                    }
                                    colorHover={
                                      model.getModel('farolInvestimento')
                                        .value &&
                                      model.getModel('farolInvestimento').value
                                        .cor
                                    }
                                  ></MdiIcon>
                                ) : (
                                  ''
                                )
                              }
                              model={{
                                label: intl.formatMessage(
                                  { id: 'investimentoRealizado' },
                                  { investimento: resources.investimento }
                                ),
                                value: model.getModel('investimentoRealizado')
                                  .value,
                                requestChange: model.getModel(
                                  'investimentoRealizado'
                                ).requestChange,
                              }}
                            ></InputGroup>
                          </Col>
                        )}
                      {model.getModel('configuracaoGeral').value &&
                        model.getModel('configuracaoGeral').value
                          .utilizarCusteio && (
                          <Col md={3}>
                            <InputNumber
                              precision={2}
                              model={{
                                label: intl.formatMessage(
                                  { id: 'custeioPrevisto' },
                                  { custeio: resources.custeio }
                                ),
                                value: model.getModel('custeioPrevisto').value,
                                requestChange:
                                  model.getModel('custeioPrevisto')
                                    .requestChange,
                              }}
                              disabled={
                                model.getModel('bloquearCusteioPrevisto')
                                  .value || podeEditar
                              }
                            />
                          </Col>
                        )}
                      {model.getModel('configuracaoGeral').value &&
                        model.getModel('configuracaoGeral').value
                          .utilizarCusteio &&
                        model.getModel('configuracaoGeral').value
                          .utilizarValorRetificado && (
                          <Col md={4}>
                            <InputNumber
                              precision={2}
                              model={{
                                label: intl.formatMessage(
                                  { id: 'custeioPrevistoRetificado' },
                                  { custeio: resources.custeio }
                                ),
                                value: model.getModel(
                                  'custeioPrevistoRetificado'
                                ).value,
                                requestChange: model.getModel(
                                  'custeioPrevistoRetificado'
                                ).requestChange,
                              }}
                              disabled={
                                model.getModel('bloquearCusteioPrevisto')
                                  .value || podeEditar
                              }
                            />
                          </Col>
                        )}
                      {model.getModel('configuracaoGeral').value &&
                        model.getModel('configuracaoGeral').value
                          .utilizarCusteio && (
                          <Col md={3}>
                            <InputNumber
                              precision={2}
                              model={{
                                label: intl.formatMessage(
                                  { id: 'custeioRealizado' },
                                  { custeio: resources.custeio }
                                ),
                                value: model.getModel('custeioRealizado').value,
                                requestChange:
                                  model.getModel('custeioRealizado')
                                    .requestChange,
                              }}
                              disabled={
                                model.getModel('bloquearCusteioRealizado')
                                  .value || podeEditar
                              }
                            />
                          </Col>
                        )}

                      <Col md={12}>
                        <hr />
                      </Col>
                    </Row>
                  )}
                {model.getModel('configuracaoGeral')?.value?.utilizarEscopo && (
                  <Row>
                    <Col md={12}>
                      <div className="font-weight-bolder mb-3">
                        {intl.formatMessage({ id: 'escopo' })}
                      </div>
                    </Col>
                    <Col md={12}>
                      <Input
                        rows={3}
                        maxLength={4000}
                        type="textarea"
                        style={{ resize: 'none' }}
                        model={{
                          label: '',
                          value: model.getModel('escopo').value,
                          requestChange: model.getModel('escopo').requestChange,
                        }}
                        disabled={disabled || podeEditar}
                      />
                    </Col>
                  </Row>
                )}
              </Panel>
              {tipoItem == TIPO_ITENS.PROJETO &&
                !ehProjetoEmAprovacao &&
                model.value &&
                model.value.id != 0 &&
                model.getModel('configuracaoGeral').value &&
                model.getModel('configuracaoGeral').value.utilizarCurvaS && (
                  <Panel header={intl.formatMessage({ id: 'curvaS' })}>
                    <Tabs defaultActiveKey="1">
                      <Tab
                        label={intl.formatMessage({
                          id: 'fisica',
                        })}
                        key="1"
                      >
                        <GraficoCurvaS
                          height={320}
                          tituloPersonalizado={{
                            titulo: (
                              <strong>{model.getModel('nome').value}</strong>
                            ),
                            polaridade: grafico && grafico.exibirPolaridade,
                            unidadeMedida: false,
                          }}
                          grafico={grafico}
                        />
                      </Tab>
                      {model.getModel('configuracaoGeral').value &&
                        model.getModel('configuracaoGeral').value
                          .utilizarPlanoContas &&
                        possuiModuloEP && (
                          <Tab
                            label={intl.formatMessage({
                              id: 'financeira',
                            })}
                            key="2"
                          >
                            <GraficoCurvaSFinanceira
                              tituloPersonalizado={{
                                titulo: (
                                  <strong>
                                    {model.getModel('nome').value}
                                  </strong>
                                ),
                                unidadeMedida: false,
                              }}
                              projetoId={model.getModel('id').value}
                            />
                          </Tab>
                        )}
                    </Tabs>
                  </Panel>
                )}
              {tipoItem == TIPO_ITENS.PROJETO &&
                !ehProjetoEmAprovacao &&
                model.value &&
                model.value.id != 0 && (
                  <Panel
                    header={capitalizeFirstLetter(resources.projetosfilhos)}
                  >
                    <Row>
                      <Col md={12} style={{ marginTop: 20 }}>
                        <TabelaRelacionamento
                          parameters={{ tipoId: 4 }}
                          allowNew={!disabled}
                          disabled={disabled || podeEditar}
                          model={model.getModel('filhos')}
                          validateResults={this.validateSelectedRelatedProjects}
                        ></TabelaRelacionamento>
                      </Col>
                    </Row>
                  </Panel>
                )}
            </>
          )}
          {!model.getModel('vincularComMO').value &&
            model.getModel('templateDesdobramento').value && (
              <Row>
                {tipoItem == TIPO_ITENS.INDICADOR && (
                  <Col md={6}>
                    <ItemLookup
                      required
                      model={model.getModel('pai')}
                      disabled={true}
                    />
                  </Col>
                )}
                {(tipoItem == TIPO_ITENS.INDICADOR ||
                  tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
                  tipoItem == TIPO_ITENS.PARAMETRO) && (
                  <Col md={3}>
                    <DesdobramentoLookup
                      clearable={true}
                      data={{ checarStatusInativo: true }}
                      model={{
                        label: (
                          <span>
                            {intl.formatMessage({
                              id: 'desdobramentoSuperior',
                            })}
                          </span>
                        ),
                        value: model.getModel('templateDesdobramento').value,
                        requestChange: model.getModel('templateDesdobramento')
                          .requestChange,
                      }}
                      disabled={true}
                    />
                  </Col>
                )}
                {(tipoItem == TIPO_ITENS.INDICADOR ||
                  tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
                  tipoItem == TIPO_ITENS.PARAMETRO) && (
                  <Col md={3}>
                    <DesdobramentoLookup
                      clearable={true}
                      data={{ checarStatusInativo: true }}
                      model={{
                        label: (
                          <span>
                            {intl.formatMessage({ id: 'desdobramento' })}
                          </span>
                        ),
                        value: model.getModel('desdobramento').value,
                        requestChange:
                          model.getModel('desdobramento').requestChange,
                      }}
                      disabled={true}
                    />
                  </Col>
                )}
              </Row>
            )}
          <Row>
            {model.getModel('configuracaoGeral').value &&
              model.getModel('configuracaoGeral').value
                .utilizarDigitadorValor &&
              (tipoItem == TIPO_ITENS.INDICADOR ||
                tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
                tipoItem == TIPO_ITENS.PARAMETRO ||
                tipoItem == TIPO_ITENS.PROJETO ||
                tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL) && (
                <Col md={6}>
                  <ColaboradorSimplificadoLookup
                    multi
                    model={{
                      label: capitalizeFirstLetter(resources.digitadores),
                      value: model.getModel('digitadores').value,
                      requestChange:
                        model.getModel('digitadores').requestChange,
                    }}
                    disabled={disabled}
                  />
                </Col>
              )}
            {model.getModel('configuracaoGeral').value &&
              model.getModel('configuracaoGeral').value.utilizarEquipe && (
                <Col md={6}>
                  <ColaboradorSimplificadoLookup
                    multi
                    model={{
                      label: intl.formatMessage({ id: 'equipe' }),
                      value: model.getModel('equipe').value,
                      requestChange: model.getModel('equipe').requestChange,
                      errors: model.getModel('equipe').errors,
                    }}
                  />
                </Col>
              )}

            {model.getModel('configuracaoGeral').value &&
              model.getModel('configuracaoGeral').value
                .utilizarAprovadoresdeValoresRealizados &&
              (tipoItem == TIPO_ITENS.INDICADOR ||
                tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
                tipoItem == TIPO_ITENS.PARAMETRO ||
                tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
                tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL) && (
                <Col md={6}>
                  <ColaboradorLookup
                    multi
                    model={model.getModel('aprovadoresValores')}
                    disabled={disabled}
                  />
                </Col>
              )}
            {model.getModel('habilitarEnvioAutomaticoRA').value &&
              (tipoItem == TIPO_ITENS.INDICADOR ||
                tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO) && (
                <Col md={6}>
                  <ColaboradorSimplificadoLookup
                    multi
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'colaboradoresEmailRA' },
                          {
                            colaboradores: resources.colaboradores,
                            relatoriodeacompanhamento:
                              resources.relatoriodeacompanhamento,
                          }
                        )
                      ),
                      value: model.getModel('colaboradoresEmailRA').value,
                      requestChange: model.getModel('colaboradoresEmailRA')
                        .requestChange,
                    }}
                    disabled={disabled}
                  />
                </Col>
              )}
            {(tipoItem == TIPO_ITENS.INDICADOR ||
              tipoItem == TIPO_ITENS.PARAMETRO ||
              tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
              tipoItem == TIPO_ITENS.PROJETO) && (
              <Col md={6}>
                <TagLookup
                  multi
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'tags' },
                        { tags: resources.tags }
                      )
                    ),
                    value: model.getModel('tags').value,
                    requestChange: model.getModel('tags').requestChange,
                  }}
                  disabled={disabled}
                  data={{ funcionalidadesIdSelecionaveis: [tipoItem] }}
                  podeEditar={model.getModel('podeCadastrarTag').value}
                />
              </Col>
            )}
          </Row>
          {tipoItem != TIPO_ITENS.PROJETO && (
            <Row>
              <Col md={12}>
                <Input
                  maxLength={1000}
                  rows={4}
                  type="textarea"
                  model={model.getModel('descricao')}
                  disabled={disabled}
                />
              </Col>
            </Row>
          )}
          {
            <Row>
              {model.getModel('configuracaoGeral').value &&
                model.getModel('configuracaoGeral').value
                  .utilizarCampoAuxiliarTexto1 &&
                (tipoItem == TIPO_ITENS.INDICADOR ||
                  tipoItem == TIPO_ITENS.PARAMETRO ||
                  tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO) && (
                  <Col md={12}>
                    <Input
                      disabled={disabled}
                      maxLength={1000}
                      type="textarea"
                      rows={4}
                      model={{
                        label: capitalizeFirstLetter(
                          resources.campoauxiliartexto1
                        ),
                        value: model.getModel('campoAuxiliarTexto1').value,
                        requestChange: model.getModel('campoAuxiliarTexto1')
                          .requestChange,
                      }}
                    />
                  </Col>
                )}
              {model.getModel('configuracaoGeral').value &&
                model.getModel('configuracaoGeral').value
                  .utilizarCampoAuxiliarTexto2 &&
                (tipoItem == TIPO_ITENS.INDICADOR ||
                  tipoItem == TIPO_ITENS.PARAMETRO ||
                  tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO) && (
                  <Col md={12}>
                    <Input
                      disabled={disabled}
                      maxLength={1000}
                      type="textarea"
                      rows={4}
                      model={{
                        label: capitalizeFirstLetter(
                          resources.campoauxiliartexto2
                        ),
                        value: model.getModel('campoAuxiliarTexto2').value,
                        requestChange: model.getModel('campoAuxiliarTexto2')
                          .requestChange,
                      }}
                    />
                  </Col>
                )}
              {model.getModel('configuracaoGeral').value &&
                model.getModel('configuracaoGeral').value
                  .utilizarCampoAuxiliarTexto3 &&
                (tipoItem == TIPO_ITENS.INDICADOR ||
                  tipoItem == TIPO_ITENS.PARAMETRO ||
                  tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO) && (
                  <Col md={12}>
                    <Input
                      disabled={disabled}
                      maxLength={1000}
                      type="textarea"
                      rows={4}
                      model={{
                        label: capitalizeFirstLetter(
                          resources.campoauxiliartexto3
                        ),
                        value: model.getModel('campoAuxiliarTexto3').value,
                        requestChange: model.getModel('campoAuxiliarTexto3')
                          .requestChange,
                      }}
                    />
                  </Col>
                )}
            </Row>
          }
          {tipoItem == TIPO_ITENS.PROJETO ? (
            <Panel header={intl.formatMessage({ id: 'camposCustomizados' })}>
              {this.renderCustomFields(tipoItem, disabled, model)}
            </Panel>
          ) : (
            this.renderCustomFields(tipoItem, disabled, model)
          )}
        </Panel>
        {tipoItem == TIPO_ITENS.INDICADOR_RISCO && (
          <IndicadorRiscoLimites disabled={disabled} {...this.props} />
        )}
        {possuiModuloMO && tipoItem == TIPO_ITENS.INDICADOR && (
          <Panel
            header={
              <FormattedMessage id="label.integracaoMatrizOrcamentaria" />
            }
          >
            <Row>
              <Col md={4}>
                <div id="vincularComMO">
                  <UncontrolledTooltip
                    placement="bottom"
                    target="vincularComMO"
                  >
                    <FormattedMessage id="label.vincularItemComMOAlert" />
                  </UncontrolledTooltip>
                  <CheckBox
                    model={{
                      label: intl.formatMessage(
                        { id: 'label.vincularIndicadorComMatrizOrcamentaria' },
                        {
                          centrocusto: resources.centrocusto,
                          indicador: resources.indicador,
                        }
                      ),
                      value: model.getModel('vincularComMO').value,
                      requestChange:
                        model.getModel('vincularComMO').requestChange,
                    }}
                    disabled={disabledIntegracaoMO}
                  />
                </div>
              </Col>
              {model.getModel('vincularComMO').value && (
                <Col md={4}>
                  <ItemCentroCustoLookup
                    required
                    model={{
                      label: (
                        <FormattedMessage
                          id="label.centroCusto"
                          values={{ centrocusto: resources.centrocusto }}
                        ></FormattedMessage>
                      ),
                      value: model.getModel('centroCusto').value,
                      requestChange:
                        model.getModel('centroCusto').requestChange,
                      errors: model.getModel('centroCusto').errors,
                    }}
                  />
                </Col>
              )}
              {model.getModel('vincularComMO').value && (
                <Col md={4}>
                  <PacoteLookup
                    model={{
                      label: (
                        <FormattedMessage id="label.pacote"></FormattedMessage>
                      ),
                      value: model.getModel('pacote').value,
                      requestChange: model.getModel('pacote').requestChange,
                      errors: model.getModel('pacote').errors,
                    }}
                    required
                  />
                </Col>
              )}
            </Row>
          </Panel>
        )}
        {(((exbibirFaixaFarol ||
          exbibirPontosFaixaResultado ||
          exibirListaRegistros) &&
          // tipoItem != 17 &&
          tipoItem != TIPO_ITENS.PARAMETRO) ||
          (tipoItem == TIPO_ITENS.PARAMETRO &&
            model.getModel('configuracaoGeral').value &&
            model.getModel('configuracaoGeral').value
              .permitirUsoValoresTabelados)) && (
          <Panel header={<FormattedMessage id="label.referencia" />}>
            <Row>
              <Col md={6}>
                {exibirListaRegistros &&
                  model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .permitirUsoValoresTabelados && (
                    <CheckBox
                      model={{
                        label: intl.formatMessage({ id: 'usarListaRegistros' }),
                        value: model.getModel('usarTabelaValores').value,
                        requestChange:
                          model.getModel('usarTabelaValores').requestChange,
                      }}
                      disabled={disabled || !podeEditarCamposDesdobramento}
                    />
                  )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {exibirListaRegistros &&
                model.getModel('usarTabelaValores').value ? (
                  <div>
                    <Row>
                      <Col md={3}>
                        <TabelaValorLookup
                          required
                          model={{
                            label: intl.formatMessage({ id: 'listaRegistros' }),
                            value: model.getModel('tabelaValor').value,
                            requestChange:
                              model.getModel('tabelaValor').requestChange,
                            errors: model.getModel('tabelaValor').errors,
                          }}
                          disabled={disabled || !podeEditarCamposDesdobramento}
                        />
                      </Col>
                      <Col md={9}>
                        {model.getModel('tabelaValor').value && (
                          <PreviewTabelaValor
                            exibirFarol={tipoItem != 2}
                            disabled={
                              disabled || !podeEditarCamposDesdobramento
                            }
                            model={model
                              .getModel('tabelaValor')
                              .getModel('itens')}
                          ></PreviewTabelaValor>
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : tipoItem != 2 && exbibirFaixaFarol ? (
                  <div>
                    <Row>
                      <Col md={3}>
                        <FaixaFarolLookup
                          data={
                            tipoItem == TIPO_ITENS.INDICADOR_RISCO
                              ? {
                                  tipoFaixaFarolId: 1,
                                }
                              : {}
                          }
                          clearable={tipoItem == TIPO_ITENS.INDICADOR_RISCO}
                          model={{
                            label: capitalizeFirstLetter(resources.faixa),
                            value: model.getModel('faixa').value,
                            requestChange: this.handleChangeFaixa,
                          }}
                          disabled={disabled || this.ehCampoBloqueado('faixa')}
                        />
                      </Col>
                      <Col md={3}>
                        <Radio
                          model={{
                            label: capitalizeFirstLetter(resources.polaridade),
                            value: model.getModel('melhor').value,
                            requestChange:
                              model.getModel('melhor').requestChange,
                          }}
                          name="polaridade"
                          options={opcoesMelhor}
                          labelFunction={this.handleLabelMelhor}
                          disabled={
                            disabled ||
                            !podeEditarCamposDesdobramento ||
                            tipoItem == TIPO_ITENS.PROJETO ||
                            this.ehCampoBloqueado('melhor')
                          }
                          labelField="descricao"
                          idField="id"
                        />
                      </Col>
                      <Col md={6}>
                        {model.getModel('faixa').value &&
                          model.getModel('melhor').value &&
                          (model.getModel('melhor').value.id == 1 ? (
                            <Preview
                              disabled={
                                disabled || !podeEditarCamposDesdobramento
                              }
                              model={model
                                .getModel('faixa')
                                .getModel('faixaMaior')}
                              tipoValorFaixa={
                                model.getModel('faixa').value.tipoValorFaixa
                              }
                            ></Preview>
                          ) : model.getModel('melhor').value.id == 2 ? (
                            <Preview
                              disabled={
                                disabled || !podeEditarCamposDesdobramento
                              }
                              model={model
                                .getModel('faixa')
                                .getModel('faixaMenor')}
                              tipoValorFaixa={
                                model.getModel('faixa').value.tipoValorFaixa
                              }
                            ></Preview>
                          ) : (
                            <Preview
                              disabled={
                                disabled || !podeEditarCamposDesdobramento
                              }
                              model={model
                                .getModel('faixa')
                                .getModel('faixaIgual')}
                              tipoValorFaixa={
                                model.getModel('faixa').value.tipoValorFaixa
                              }
                            ></Preview>
                          ))}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div></div>
                )}
              </Col>
            </Row>
            {possuiModuloRV && exbibirPontosFaixaResultado && (
              <Fragment>
                <Row>
                  <Col md={12}>
                    <PontosPorFaixaResultadoList
                      model={model}
                      disabled={disabled || !podeEditarPontosFaixaResultado}
                      podeEditar={podeEditarPontosFaixaResultado}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Radio
                      model={{
                        label: intl.formatMessage({ id: 'pontosAcumulados' }),
                        value: model.getModel(
                          'tipoConsolidacaoAcumuladoPontosPorFaixa'
                        ).value,
                        requestChange: model.getModel(
                          'tipoConsolidacaoAcumuladoPontosPorFaixa'
                        ).requestChange,
                      }}
                      name="pontosAcumulados"
                      options={
                        model.value
                          .opcoesTipoConsolidacaoAcumuladoPontosPorFaixa
                      }
                      labelField="descricao"
                      idField="id"
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                {this.state.utilizarFaixaMinimoEMaximo && (
                  <Row>
                    <Col md={12}>
                      <FaixaMinMax
                        disabled={
                          disabled || !podeEditarFaixaResultadoMinimoMaximo
                        }
                        itemId={this.props.id}
                      />
                    </Col>
                  </Row>
                )}
              </Fragment>
            )}
          </Panel>
        )}
        {possuiModuloA && tipoItem == TIPO_ITENS.INDICADOR && (
          <Panel header={intl.formatMessage({ id: 'analytics' })}>
            <Row>
              <Col md={6}>
                <Input
                  disabled={disabled}
                  maxLength={1000}
                  rows={5}
                  type="text"
                  model={{
                    label: intl.formatMessage({ id: 'uri' }),
                    value: model.getModel('analytics.uri').value,
                    requestChange:
                      model.getModel('analytics.uri').requestChange,
                  }}
                />
              </Col>
            </Row>
          </Panel>
        )}
        {model.getModel('configuracaoGeral').value &&
        model.getModel('configuracaoGeral').value
          .utilizarAjusteLimiteRealizado &&
        model.getModel('configuracaoGeral').value
          .utilizarAjustePercentuaMetaZero ? (
          <div>
            {(tipoItem == TIPO_ITENS.INDICADOR ||
              tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO) && (
              <Panel header={<FormattedMessage id="label.limites" />}>
                <Row>
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarAjusteLimiteRealizado && (
                      <Col md={3}>
                        <InputNumber
                          disabled={disabled || !podeEditarCamposDesdobramento}
                          model={{
                            label: intl.formatMessage(
                              { id: 'label.limiteMinimoDeValorRealizado' },
                              { limiteminimo: resources.limiteminimo }
                            ),
                            value: model.getModel('limiteMinimoRealizado')
                              .value,
                            requestChange: model.getModel(
                              'limiteMinimoRealizado'
                            ).requestChange,
                          }}
                        ></InputNumber>
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarAjusteLimiteRealizado && (
                      <Col md={3}>
                        <InputNumber
                          disabled={disabled || !podeEditarCamposDesdobramento}
                          model={{
                            label: intl.formatMessage(
                              { id: 'label.limiteMaximoDeValorRealizado' },
                              { limitemaximo: resources.limitemaximo }
                            ),
                            value: model.getModel('limiteMaximoRealizado')
                              .value,
                            requestChange: model.getModel(
                              'limiteMaximoRealizado'
                            ).requestChange,
                          }}
                        ></InputNumber>
                      </Col>
                    )}
                  {model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarAjustePercentuaMetaZero && (
                      <Col md={3}>
                        <InputNumber
                          disabled={disabled || !podeEditarCamposDesdobramento}
                          model={{
                            label: intl.formatMessage(
                              {
                                id: 'label.percentualDaMetaParaDesempenhoZero',
                              },
                              {
                                meta: resources.meta,
                                desempenho: resources.desempenho,
                              }
                            ),
                            value: model.getModel(
                              'percentualMetaDesempenhoZero'
                            ).value,
                            requestChange: model.getModel(
                              'percentualMetaDesempenhoZero'
                            ).requestChange,
                          }}
                        ></InputNumber>
                      </Col>
                    )}
                </Row>
              </Panel>
            )}
          </div>
        ) : null}
        {model.getModel('configuracaoGeral').value &&
          model.getModel('configuracaoGeral').value.utilizarBenchmark &&
          tipoItem != 12 &&
          tipoItem != 13 &&
          tipoItem != 14 &&
          tipoItem != 15 && (
            <Panel header={<FormattedMessage id="label.benchmark" />}>
              <Row>
                <Col md={3}>
                  <InputNumber
                    precision={2}
                    disabled={disabled || !podeEditarCamposDesdobramento}
                    model={{
                      label: capitalizeFirstLetter(resources.historico),
                      value: model.getModel('benchmarkHistorico').value,
                      requestChange:
                        model.getModel('benchmarkHistorico').requestChange,
                    }}
                  ></InputNumber>
                </Col>
                <Col md={3}>
                  <InputNumber
                    precision={2}
                    disabled={disabled || !podeEditarCamposDesdobramento}
                    model={{
                      label: capitalizeFirstLetter(resources.interno),
                      value: model.getModel('benchmarkInterno').value,
                      requestChange:
                        model.getModel('benchmarkInterno').requestChange,
                    }}
                  ></InputNumber>
                </Col>
                <Col md={3}>
                  <InputNumber
                    precision={2}
                    disabled={disabled || !podeEditarCamposDesdobramento}
                    model={{
                      label: capitalizeFirstLetter(resources.externo),
                      value: model.getModel('benchmarkExterno').value,
                      requestChange:
                        model.getModel('benchmarkExterno').requestChange,
                    }}
                  ></InputNumber>
                </Col>
              </Row>
            </Panel>
          )}
        {model.getModel('configuracaoGeral').value &&
          model.getModel('configuracaoGeral').value.utilizarImpactoIndireto &&
          tipoItem != 12 &&
          tipoItem != 13 &&
          tipoItem != 14 &&
          tipoItem != 15 &&
          tipoItem != 17 &&
          !ehProjetoEmAprovacao && (
            <Panel
              header={
                <span style={{ fontSize: 15 }}>
                  {capitalizeFirstLetter(resources.relacionamentos)}
                  <span
                    onClick={this.handleRelacionamentosClick.bind(
                      this,
                      this.props.id
                    )}
                    className="pull-right"
                    style={{ cursor: 'pointer', color: '#0280CE' }}
                  >
                    {this.props.intl.formatMessage(
                      { id: 'label.verRelacionamentos' },
                      { relacionamentos: this.props.resources.relacionamentos }
                    )}
                  </span>
                </span>
              }
            >
              <Row>
                <Col md={12} style={{ marginTop: 20 }}>
                  <TabelaRelacionamento
                    disabled={disabled}
                    model={model.getModel('impactosIndiretos')}
                    modelOkrs={model.getModel('impactosIndiretosObjetivo')}
                    allowNew={!disabled}
                    showOkrs={true}
                    info={{
                      text: intl.formatMessage({
                        id: 'label.informativoImpactoIndireto',
                      }),
                      id: 'infoImpactoIndiretoIndicador',
                    }}
                    parameters={{ tiposIdsSelecionados: [1, 2, 3, 4, 6] }}
                  ></TabelaRelacionamento>
                </Col>
              </Row>
            </Panel>
          )}
        {isProject && hasEPModule && showRiscos && (
          <Panel header={capitalizeFirstLetter(resources.riscos)}>
            <TabelaRelacionamento
              parameters={{ tipoId: 6 }}
              allowNew={!disabled}
              disabled={disabled}
              showFarol={false}
              model={model.getModel('riscos')}
            />
          </Panel>
        )}
        {tipoItem != 17 &&
          model.getModel('configuracaoGeral').value &&
          (model.getModel('configuracaoGeral').value.utilizarPerspectiva ||
            model.getModel('configuracaoGeral').value.utilizarTemaEstrategico ||
            model.getModel('configuracaoGeral').value.utilizarClassificacao ||
            model.getModel('configuracaoGeral').value.utilizarClassificacao2 ||
            model.getModel('configuracaoGeral').value.utilizarMapaComparativo ||
            model.getModel('configuracaoGeral').value.utilizarObservacao) && (
            <Panel header={<FormattedMessage id="label.outrasInformacoes" />}>
              <Row>
                {model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarPerspectiva &&
                  tipoItem != 12 &&
                  tipoItem != 13 &&
                  tipoItem != 14 &&
                  tipoItem != 15 && (
                    <Col md={3}>
                      <PerspectivaLookup
                        clearable={true}
                        model={{
                          label: (
                            <div className="d-flex">
                              {capitalizeFirstLetter(resources.perspectiva)}
                              <div className="ml-1">
                                <Info
                                  id="infoPerspectivaIndicador"
                                  placement="right"
                                  text={intl.formatMessage(
                                    { id: 'label.informativoPerspectiva' },
                                    { perspectiva: resources.perspectiva }
                                  )}
                                />
                              </div>
                            </div>
                          ),
                          value: model.getModel('perspectiva').value,
                          requestChange:
                            model.getModel('perspectiva').requestChange,
                        }}
                        disabled={disabled || !podeEditarCamposDesdobramento}
                      />
                    </Col>
                  )}
                {model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarTemaEstrategico &&
                  (tipoItem == TIPO_ITENS.INDICADOR ||
                    tipoItem == TIPO_ITENS.PARAMETRO ||
                    tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
                    tipoItem == TIPO_ITENS.PROJETO ||
                    tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
                    tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL) && (
                    <Col md={3}>
                      <TemaEstrategicoLookup
                        model={{
                          label: (
                            <div className="d-flex">
                              {capitalizeFirstLetter(resources.temaestrategico)}
                              <div className="ml-1">
                                <Info
                                  id="infoTemaEstrategicoIndicador"
                                  placement="right"
                                  text={intl.formatMessage(
                                    { id: 'label.informativoTemaEstrategico' },
                                    {
                                      objetivosestrategicos:
                                        resources.objetivosestrategicos,
                                    }
                                  )}
                                />
                              </div>
                            </div>
                          ),
                          value: model.getModel('temas').value,
                          requestChange: model.getModel('temas').requestChange,
                        }}
                        disabled={disabled || !podeEditarCamposDesdobramento}
                      />
                    </Col>
                  )}
                {configuracao.utilizarClassificacaoItem &&
                  model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarClassificacao &&
                  (tipoItem == TIPO_ITENS.INDICADOR ||
                    tipoItem == TIPO_ITENS.PARAMETRO ||
                    tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
                    tipoItem == TIPO_ITENS.PROJETO ||
                    tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
                    tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL) && (
                    <Col md={3}>
                      <ClassificacaoLookup
                        model={{
                          label: capitalizeFirstLetter(
                            resources.classificacao1
                          ),
                          value: model.getModel('classificacoes').value,
                          requestChange:
                            model.getModel('classificacoes').requestChange,
                        }}
                        disabled={disabled || !podeEditarCamposDesdobramento}
                      />
                    </Col>
                  )}
                {configuracao.utilizarClassificacaoItem &&
                  model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarClassificacao2 &&
                  (tipoItem == TIPO_ITENS.INDICADOR ||
                    tipoItem == TIPO_ITENS.PARAMETRO ||
                    tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
                    tipoItem == TIPO_ITENS.PROJETO ||
                    tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
                    tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL) && (
                    <Col md={3}>
                      <ClassificacaoLookup
                        model={{
                          label: capitalizeFirstLetter(
                            resources.classificacao2
                          ),
                          value: model.getModel('classificacoes2').value,
                          requestChange:
                            model.getModel('classificacoes2').requestChange,
                        }}
                        disabled={disabled || !podeEditarCamposDesdobramento}
                      />
                    </Col>
                  )}
              </Row>
              <Row>
                {model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarMapaComparativo &&
                  (tipoItem == TIPO_ITENS.INDICADOR ||
                    tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
                    tipoItem == TIPO_ITENS.PROJETO ||
                    tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
                    tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL) && (
                    <PaisUf
                      disabled={disabled}
                      pais={model.getModel('pais')}
                      uf={model.getModel('uf')}
                      model={model}
                    />
                  )}
                {model.getModel('configuracaoGeral').value &&
                  model.getModel('configuracaoGeral').value
                    .utilizarObservacao && (
                    <Col md={8}>
                      <Input
                        maxLength={1000}
                        rows={5}
                        type="textarea"
                        model={{
                          label: capitalizeFirstLetter(resources.observacao),
                          value: model.getModel('observacao').value,
                          requestChange:
                            model.getModel('observacao').requestChange,
                        }}
                        disabled={disabled}
                      />
                    </Col>
                  )}
              </Row>
            </Panel>
          )}

        {this.renderFiltroRelatorioPowerBI(disabled, model)}

        {model.getModel('configuracaoGeral').value &&
          model.getModel('configuracaoGeral').value.utilizarParteInteressada &&
          disabled === false &&
          tipoItem != TIPO_ITENS.PROJETO &&
          tipoItem != 12 &&
          tipoItem != 13 &&
          tipoItem != 14 &&
          tipoItem != 15 && (
            <Panel>
              <ParteInteressada
                disabled={disabled}
                model={{
                  label: capitalizeFirstLetter(resources.partesinteressadas),
                  value: model.getModel('partesInteressadas').value,
                  requestChange:
                    model.getModel('partesInteressadas').requestChange,
                }}
              />
            </Panel>
          )}
        {model.getModel('configuracaoGeral').value &&
          model.getModel('configuracaoGeral').value.utilizarGeoreferencia &&
          (tipoItem == TIPO_ITENS.INDICADOR ||
            tipoItem == TIPO_ITENS.OBJETIVO_ESTRATEGICO ||
            tipoItem == TIPO_ITENS.PROJETO ||
            tipoItem == TIPO_ITENS.CALCULO_INTERMEDIARIO ||
            tipoItem == TIPO_ITENS.DESEMPENHO_INDIVIDUAL) && (
            <Panel
              header={
                <div className="d-flex">
                  <FormattedMessage id="label.georeferencia" />
                  <div className="ml-1">
                    <Info
                      id="infoGeoreferenciaIndicador"
                      placement="right"
                      text={intl.formatMessage({
                        id: 'label.informativoGeoreferencia',
                      })}
                    />
                  </div>
                </div>
              }
            >
              <Georeferencia
                disabled={disabled}
                isNew={isNew}
                nomeItem={model.getModel('nome').value}
                farol={
                  tipoItem == TIPO_ITENS.PROJETO &&
                  !model.getModel('configuracaoGeral').value
                    .utilizarFarolDesempenhoFaixa
                    ? model.getModel('desempenho')
                    : model.getModel('ultimoFarol')
                }
                exibir={true}
                endereco={model.getModel('enderecoGeoreferencia')}
                latitude={model.getModel('latitudeGeoreferencia')}
                longitude={model.getModel('longitudeGeoreferencia')}
                georeferenciaAlterada={model.getModel('georeferenciaAlterada')}
                onChange={this.handleChangeGeoreferencia}
                enderecoField="enderecoGeoreferencia"
                latitudeField="latitudeGeoreferencia"
                longitudeField="longitudeGeoreferencia"
              ></Georeferencia>
            </Panel>
          )}
        {this.state.showMessage && (
          <MensagemPersonalizada
            onClose={this.changeMessageStatus}
            autoClose="800"
            type="info"
            message={intl.formatMessage({ id: 'salvoComSucesso' })}
            position="bottom-right"
          />
        )}
      </LoadingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
    modulosComprados: state.user.licenca.modulos,
    tiposAnexos: state.user.tiposAnexos,
    moduloAtual: state.user.moduloAtual,
    moduloInteligenciaArtificial: state.user.moduloInteligenciaArtificial,
    user: state.user,
  };
}
export default injectIntl(connect(mapStateToProps)(ResumoEdit));
