import React from 'react';
import { Schema } from '../../utils/form';
import { FormattedMessage } from 'react-intl';

export default Schema.object({
  id: Schema.number().label(<FormattedMessage id="label.id" />),
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  codigo: Schema.string().label(<FormattedMessage id="codigo" />),
  utilizarCodigoAutomatico: Schema.string().label(
    <FormattedMessage id="label.utilizarCodigoAutomatico" />
  ),
  pai: Schema.string().label(<FormattedMessage id="registroSuperior" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  duracao: Schema.string().label(<FormattedMessage id="label.duracao" />),
  responsavel: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return context.parent.value.responsavelObrigatorio;
      },
    }),
  dataInicioPrevista: Schema.string()
    .label(<FormattedMessage id="dataInicioPrevista" />)
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.datasPrevistasObrigatorias &&
          context.parent.value.datasPrevistasObrigatorias
        );
      },
    }),
  dataTerminoPrevista: Schema.string()
    .label(<FormattedMessage id="dataTerminoPrevista" />)
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.datasPrevistasObrigatorias &&
          context.parent.value.datasPrevistasObrigatorias
        );
      },
    }),
  dataInicioRealizada: Schema.string().label(
    <FormattedMessage id="dataInicioRealizada" />
  ),
  dataTerminoRealizada: Schema.string().label(
    <FormattedMessage id="dataTerminoRealizada" />
  ),
  percentualPrevisto: Schema.string().label(),
  percentualRealizado: Schema.string().label(),
  baselineInicial: Schema.string().label(
    <FormattedMessage id="baselineInicial" />
  ),
  baselineTermino: Schema.string().label(
    <FormattedMessage id="label.baselineTermino" />
  ),
  itemId: Schema.number(),
  item: Schema.string()
    .label('')
    .required({
      onlyIf: (context) => {
        return context.parent.value.itemObrigatorio;
      },
    }),
  onde: Schema.string().label(),
  como: Schema.string().label(),
  observacao: Schema.string().label(),
  porque: Schema.string().label(),
  causasImpactadas: Schema.string().label(
    <FormattedMessage id="label.causasImpactadas" />
  ),
  esforco: Schema.string().label(<FormattedMessage id="esforco" />),
  investimentoPrevisto: Schema.string().label(),
  investimentoRealizado: Schema.string().label(),
  podeEditarInvestimentoPrevisto: Schema.string().label(),
  podeEditarInvestimentoRealizado: Schema.string().label(),
  podeEditarCusteioPrevisto: Schema.string().label(),
  podeEditarCusteioRealizado: Schema.string().label(),
  farolAcao: Schema.string().label(<FormattedMessage id="farol" />),
  farolInvestimento: Schema.string().label(
    <FormattedMessage id="label.duracao" />
  ),
  filhos: Schema.array().label(<FormattedMessage id="label.filhos" />),
  predecessoras: Schema.string().label(<FormattedMessage id="predecessoras" />),
  predecessorasAlteradasManualmente: Schema.string(),
  custeioPrevisto: Schema.string().label(),
  custeioRealizado: Schema.string().label(),
  peso: Schema.string().label(<FormattedMessage id="peso" />),
  itensVinculados: Schema.string().label(
    <FormattedMessage id="label.itensVinculados" />
  ),
  ehAcaoVinculada: Schema.string().label(),
  utilizarAcaoVinculada: Schema.string().label(),
  dataPrevistaAlterada: Schema.string().label(),
  acaoOrigem: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.utilizarAcaoVinculada &&
          context.parent.value.utilizarAcaoVinculada
        );
      },
    }),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  tipoAcao: Schema.number().label(''),
  equipe: Schema.string().label(<FormattedMessage id="equipe" />),
  porque: Schema.string().label(),
});
