import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import AreaEdit from '../paginas/faixa-farol/edit';

@autobind
class FaixaFarolLookup extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" clearable={true} editComponent={AreaEdit} url="/FaixaFarolLookup" {...this.props} />;
  }
}

export default FaixaFarolLookup;
