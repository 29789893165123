import React from 'react';
import { Badge } from 'reactstrap';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import funcoesEdit from './edit';
import ContentManager from '../../../componentes/content-manager';
import Confirm from '../../../componentes/message-box/confirm';
import http from '../../../services/httpService';
import Alert from '../../../componentes/message-box/alert';
import errorHandler from '../../../utils/error-handler';
import Search from 'src/paginas/remuneracao-variavel/funcoes/search';
import { FILTROS, ADDONS } from 'src/utils/constants';
import { capitalizeFirstLetter } from 'src/utils/string';

@autobind
class Funcao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
    };
  }

  renderTags(item) {
    return item.tags?.map((x) => (
      <Badge pill className="text-white" style={{ background: x.cor }}>
        {x.descricao}
      </Badge>
    ));
  }

  handleDeleteClick(item, index) {
    if (item.possuiCestaIndicadores) {
      ContentManager.addContent(
        <Confirm
          title={<FormattedMessage id="alerta" />}
          message={<FormattedMessage id="label.desejaMesmoExcluirFuncao" />}
          handleConfirm={this.handleDeleteClickConfirm.bind(this, item, index)}
        />
      );
    } else this.handleDeleteClickConfirm(item);
  }

  handleDeleteClickConfirm(item) {
    http
      .post(`Funcao/Remove`, {
        id: item.id,
        parameters: this.props.parameters,
      })
      .then(() => {
        ContentManager.addContent(
          <Alert
            title={<FormattedMessage id="sucesso" />}
            message={<FormattedMessage id="label.registroExcluidoComSucesso" />}
          />
        );
        this.setState({ saved: !this.state.saved });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  renderDescricao(item) {
    return (
      <div
        className="font-weight-bold text-primary cursor-pointer"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {item.nome}
      </div>
    );
  }

  render() {
    let { intl, parameters, resources, addons } = this.props;

    const possuiAddonNegociacaoMetas =
      addons?.find((x) => x.id === ADDONS.NEGOCIACAO_METAS && x.ativo) != null;

    return (
      <List
        url="/Funcao"
        parameters={parameters}
        editComponent={funcoesEdit}
        sort="Nome"
        showSearch={true}
        saved={this.state.saved}
        searchComponent={<Search />}
        filtroId={FILTROS.FUNCOES}
        {...this.props}
        showFilter={true}
        tag={10007}
        onDeleting={this.handleDeleteClick}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'idIntegracao' })}
          valueField="idIntegracao"
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'nome' })}
          required
          valueField="nome"
          sortField="Nome"
          valueFunction={this.renderDescricao}
          default
        />
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'tags' },
            { tags: resources.tags }
          )}
          valueField="tags"
          valueFunction={this.renderTags}
        />
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'cargo' },
            { cargo: resources.cargo }
          )}
          valueField="cargo"
          sortField="cargo"
        />
        {possuiAddonNegociacaoMetas && (
          <ListColumn
            headerText={capitalizeFirstLetter(resources.area)}
            valueField="area"
            sortField="area"
          />
        )}
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    addons: state.user.addonsDisponiveis,
  };
}
export default injectIntl(connect(mapStateToProps)(Funcao));
