import React from 'react';
import { autobind } from 'core-decorators';
import Dropzone from 'react-dropzone';
import LoadingContainer from './../../loading-container/index';
import Dialog from './../../dialog';
import Button from './../../button';
import Confirm from './../../message-box/confirm';
import Alert from './../../message-box/alert';
import ContentManager from './../../content-manager';
import css from './../../../utils/css';
import http from 'src/services/httpService';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DropzoneArquivoExibicao } from './arquivo-exibicao';
import ProgressBar from './ProgressBar';
import errorHandler from 'src/utils/errorHandler';
import DropzoneArquivoExibicaoTabela from './arquivo-exibicao-tabela';

const baseClasses = css`
  .paper {
    float: left;
    height: 100px;
    width: 110px;
    padding: 5px;
    margin-right: 15px;
    margin-bottom: 15px;
    text-align: center;
    display: inline-block;
    line-height: 1.2;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 12px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
      rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  }
  .popover {
    width: 250px;
  }
  .drop {
    cursor: pointer;
    border: 2px dashed #e0e0e0;
    margin-bottom: 5px;
  }
  .placeholder {
    color: #6D757D;
    font-size: 16px;
    line-height: 18px;
  }
`;

const CHUNK_SIZE = 20 * 1024 * 1024; // 20MB
let filesSent = 0;
@autobind
class DropZoneAnexos extends React.Component {
  static defaultProps = {
    disabled: false,
    tipoAnexo: null,
    onlyRead: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      arquivos:
        this.props.model && this.props.model.value
          ? this.props.model.value
          : [],
      files: [],
      current: 0,
      sendingFile: false,
      sizeSent: 0,
    };
  }

  renderDeleteConfirm(anexo) {
    let numAnexos = this.state.arquivos ? this.state.arquivos.length : 0;
    if (numAnexos == 1 && this.props.anexoObrigatorio) {
      ContentManager.addContent(
        <Alert
          message={this.props.intl.formatMessage({
            id: 'obrigatorioPeloMenosUmAnexo',
          })}
        />
      );
    } else {
      ContentManager.addContent(
        <Confirm
          message={this.props.intl.formatMessage({
            id: 'label.desejaMesmoExcluirEsseRegistro',
          })}
          handleConfirm={this.handleDeleteConfirmClick.bind(this, anexo)}
        />
      );
    }
  }

  handleDeleteConfirmClick(anexo) {
    this.props.onDeleting && this.props.onDeleting(anexo);
    let novaLista = [...this.state.arquivos];
    let index = this.state.arquivos.findIndex((a) => a.id === anexo.id);
    if (index !== -1) {
      novaLista.splice(index, 1);
    }
    this.setState({ arquivos: novaLista });
  }

  handleOnDrop(arquivosNovos) {
    let files = this.state.files;

    if (this.props.onlyOneFile) {
      files = [arquivosNovos[arquivosNovos.length - 1]];
      this.setState({ files });
      this.setState({ arquivos: [] });
    } else {
      arquivosNovos.map((file) => {
        files.push(file);
      });
      this.setState({ files });
    }
  }

  handleSair() {
    this.refs.dialog.close();
  }

  uploadFileInChunks = async (file, filesSize) => {
    // Dividir o arquivo em partes de 20MB
    let start = 0;
    let chunkIndex = 0;
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let arquivoSistemaId;
    this.setState({ sendingFile: true });
    let response;

    while (start < file.size) {
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end); // Criar o chunk
      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('chunkIndex', chunkIndex);
      formData.append('totalChunks', totalChunks);
      formData.append('fileName', file.name);
      formData.append('tipoAnexo', this.props.tipoAnexo);
      formData.append('arquivoSistemaId', arquivoSistemaId || null);

      try {
        response = await http.post(
          'ArquivoSistema/uploadArquivoChunk',
          formData
        );

        if (response.status !== 200) {
          throw new Error('Erro ao enviar parte do arquivo');
        } else if (!arquivoSistemaId) {
          arquivoSistemaId = response.data.id;
        }

        filesSent += chunk.size;
      } catch (error) {
        errorHandler(error);
      }

      this.setState({
        current: Math.round((filesSent / filesSize) * 100),
      });

      start = end;
      chunkIndex++;
    }

    return response?.data;
  };

  handleSalvarClick = () => {
    this.setState({ uploading: true });

    var data = new FormData();
    data.append('tipoAnexo', this.props.tipoAnexo);
    data.append('idReferencia', this.props.idReferencia || null);

    const filesSize =
      this.state.files.length > 0
        ? this.state.files.reduce((total, file) => total + file.size, 0)
        : 0;

    if (this.props.useChunks && filesSize > CHUNK_SIZE) {
      let files = this.state.files;

      if (this.props.onlyOneFile && this.state.files.length > 1) {
        files = [this.state.files[this.state.files.length - 1]];
      } else {
        let results = [];
        filesSent = 0;
        const uploadPromises = this.state.files.map(async (file) => {
          results.push(await this.uploadFileInChunks(file, filesSize));
        });

        Promise.all(uploadPromises).then(() => {
          if (this.state.arquivos && this.state.arquivos.length > 0) {
            results = results.concat(this.state.arquivos);
          }

          this.props.onUploadFiles(results);
          this.refs.dialog.close();
          this.props.handleAutomaticSave && this.props.handleAutomaticSave();
        });
      }
    } else {
      if (this.props.onlyOneFile && this.state.files.length > 1) {
        data.append('files', this.state.files.slice(-1));
      } else {
        this.state.files.map((file) => {
          data.append('files', file);
        });
      }

      http
        .post('ArquivoSistema/uploadArquivos', data)
        .then((result) => {
          if (this.props.onUploadFiles) {
            // Verifica se ja tinha arquivos no model que foi enviado
            if (this.state.arquivos && this.state.arquivos.length > 0) {
              result.data = result.data.concat(this.state.arquivos);
            }
            this.props.onUploadFiles(result.data);
          }

          this.refs.dialog.close();
          this.props.handleAutomaticSave && this.props.handleAutomaticSave();
        })
        .catch((error) => {});
    }
  };

  render() {
    let { model, intl, disabled, onlyRead, ...other } = this.props;
    const arquivos = [
      ...this.state.arquivos.map((a) => ({
        ...a,
        isNew: false, 
      })),
      ...this.state.files.map((f) => ({
        nome: f.name,
        responsavelNome: this.props.responsavel.nome || '-',
        fotoColaborador: this.props.responsavel.fotoArquivo,
        dataInclusao: new Date(),
        isNew: true, 
      })),
    ];

    const actions = [
      <div>
        <Button
          key="close-Anexos"
          color="secondary"
          leftIcon="close"
          onClick={this.handleSair.bind(this)}
        >
          {intl.formatMessage({ id: 'label.cancelar' })}
        </Button>

        <Button
          disabled={disabled}
          key="save-Anexos"
          className="ml-2"
          leftIcon="content-save-outline"
          onClick={this.handleSalvarClick.bind(this)}
        >
          {intl.formatMessage({ id: 'label.salvar' })}
        </Button>
      </div>,
    ];

    return (
      <Dialog
        className="popupOpen"
        {...other}
        ref="dialog"
        title={intl.formatMessage({ id: 'label.anexos' })}
        actions={!onlyRead && actions}
        padContent
        onRequestClose={this.handleSair.bind(this)}
        style={{ minWidth: '34.125rem', maxHeight: 'fit-content' }}
      >
        {this.state.sendingFile && (
          <div className="pb-2">
            <p>{intl.formatMessage({ id: 'labelEnviandoUmMaisArquivos' })}</p>
            <ProgressBar current={this.state.current} total={100} />
          </div>
        )}
        <LoadingContainer
          isLoading={this.state.uploading}
          style={{ height: 'auto' }}
        >
          {!onlyRead && (
            <Dropzone
              disabledClick={disabled}
              className={baseClasses.drop}
              style={{
                width: this.props.width ? this.props.width : '100%',
                height: this.props.height ? this.props.height : '8.125rem',
                textAlign: 'center',
                padding: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              multiple={!this.props.onlyOneFile}
              onDrop={this.handleOnDrop}
            >
              <span className={baseClasses.placeholder}>
                {intl.formatMessage({
                  id: 'label.cliqueOuArrasteOArquivoNestaAreaParaAdicionalo',
                })}
              </span>
            </Dropzone>
          )}

          {arquivos.length > 0 && (
            <DropzoneArquivoExibicaoTabela
              arquivos={arquivos}
              onDeleteFile={(arquivo) => {
                if (arquivo.isNew) {
                  const novos = [...this.state.files];
                  const idx = novos.findIndex((nf) => nf.name === arquivo.nome);
                  if (idx !== -1) {
                    novos.splice(idx, 1);
                    this.setState({ files: novos });
                  }
                } else {
                  this.renderDeleteConfirm(arquivo);
                }
              }}
            />
          )}
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    responsavel: state.user,
  };
}
export default injectIntl(connect(mapStateToProps)(DropZoneAnexos));
