import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { useIntl } from 'react-intl';

import http from 'src/services/httpService';
import ContentManager from 'src/componentes/content-manager';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';

import Edit from '../RiscoAvaliacaoPowerBIEditView';

function Actions({ endpoint, onRefresh, item, parameters }) {
  const intl = useIntl();

  const handleEdit = () => {
    ContentManager.addContent(
      <Edit handleClose={onRefresh} id={item?.id} parameters={parameters} />
    );
  };

  const handleDelete = async () => {
    if (!item) return;

    try {
      await http.post(`${endpoint}/Remove`, { id: item.id });
      onRefresh && onRefresh();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="nav-link p-0">
          <MdiIcon icon="dots-vertical" size={18} />
        </DropdownToggle>
        <DropdownMenu container="body">
          <DropdownItem eventkey="edit" onClick={() => handleEdit()}>
            <MdiIcon className="mr-2" icon="pencil" />
            {capitalizeFirstLetter(intl.formatMessage({ id: 'editar' }))}
          </DropdownItem>
          <DropdownItem onClick={handleDelete}>
            <MdiIcon className="mr-2" icon="delete-outline" />
            {capitalizeFirstLetter(intl.formatMessage({ id: 'excluir' }))}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default Actions;
