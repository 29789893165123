import { useIntl } from 'react-intl';
import { Table } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import guid from 'src/utils/guid';
import useAuth from 'src/hooks/useAuth';

import Edit from './Edit';
import MdiIcon from 'src/componentes/mdi-icon';

const List = ({
  model,
  showPeso,
  disabled,
  ehFluxoNegociacaoMetas,
  showDeleteIcon,
}) => {
  const intl = useIntl();

  const { terms: resources } = useAuth();

  const etapas = model.value;

  const handleAdd = () => {
    const newList = [...model.value];
    newList.push({ key: guid(), peso: 1 });

    model.requestChange(newList);
  };

  const reorderList = (startIndex, endIndex) => {
    const newList = [...model.value];
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);

    return newList;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newList = reorderList(result.source.index, result.destination.index);
    model.requestChange(newList);
  };

  const showTrashIcon = etapas?.length > 1 && showDeleteIcon;

  return (
    <div className="w-100 mb-3" style={{ padding: '0 12px' }}>
      <Table className="w-100 table-borderless m-0">
        <thead>
          <tr>
            {!disabled && <th className="p-0" style={{ width: '5%' }} />}
            <th className="p-0 pl-3 font-weight-bold" style={{ width: '20%' }}>
              {intl.formatMessage({ id: 'nomeEtapa' })} *
            </th>
            <th className="p-0 pl-3 font-weight-bold" style={{ width: '60%' }}>
              {intl.formatMessage(
                { id: 'selecioneColaboradoresGruposEouResponsabilidades' },
                { colaboradores: resources.colaboradores }
              )}{' '}
              *
            </th>
            <th className="p-0" style={{ width: '15%' }} />
            {showTrashIcon && !disabled && (
              <th className="p-0" style={{ width: '5%' }} />
            )}
          </tr>
        </thead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="etapasContainer">
            {(provided) => (
              <tbody ref={provided.innerRef}>
                {etapas?.map((e, index) => (
                  <Draggable index={index} draggableId={`${index}`}>
                    {(provided) => {
                      const key = e.id ? `${e.id}` : e.key;
                      return (
                        <>
                          <Edit
                            key={key}
                            provided={provided}
                            etapa={e}
                            index={index}
                            model={model}
                            showPeso={showPeso}
                            showTrashIcon={showTrashIcon}
                            disabled={disabled}
                            ehFluxoNegociacaoMetas={ehFluxoNegociacaoMetas}
                          />
                          {provided.placeholder}
                        </>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      {!disabled && (
        <div
          className="d-inline-flex align-items-center mt-2"
          onClick={handleAdd}
        >
          <div
            className="d-flex justify-content-center align-items-center p-1 bg-primary rounded-lg text-white"
            style={{ width: 20, height: 20, marginLeft: 15 }}
          >
            <MdiIcon className="cursor-pointer" icon="plus" size={14} />
          </div>
          <a className="ml-2 text-primary">
            {intl.formatMessage({ id: 'adicionarEtapa' })}
          </a>
        </div>
      )}
    </div>
  );
};

export default List;
