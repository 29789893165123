import React from 'react';

import Wrapper from './Wrapper';
import Sidebar from './Sidebar';
import Main from './Main';
import Navbar from './Navbar';
import Content from './Content';
import NPS from './NPS';
import useAuth from 'src/hooks/useAuth';
import CSAT from './CSAT';
import ChangelogVersion from './Changelog';

import { SidebarProvider } from 'src/contexts/SidebarContext';

import PrintProvider, { NoPrint } from 'react-easy-print';

const Dashboard = ({ children }) => {
  const { respondeuPesquisaNps, respondeuPesquisaCSAT, module } = useAuth();

  return (
    <PrintProvider>
      <SidebarProvider>
        <Wrapper>
          <div style={{ zIndex: 150 }}>
            <NoPrint force>
              <Sidebar />
            </NoPrint>
          </div>
          <Main module={module}>
            <NoPrint force>
              <Navbar />
            </NoPrint>
            <Content>{children}</Content>
            <NoPrint force>
              <ChangelogVersion />
            </NoPrint>
            <NoPrint force>
              {!respondeuPesquisaNps && <NPS />}
              {!respondeuPesquisaCSAT && <CSAT />}
            </NoPrint>
          </Main>
        </Wrapper>
      </SidebarProvider>
    </PrintProvider>
  );
};

export default Dashboard;
