import {Table } from 'reactstrap';

const PontosFaixaPorPeriodoOuAcumulado = ({
  tipo,
  model,
  resources,
  intl,
}) => {
  const pontoFaixaResultado = model.pontoFaixaResultado;
  const faixas = pontoFaixaResultado?.faixas;
  const interpolarValoresFaixa = pontoFaixaResultado?.interpolarValoresFaixa;
  const tipoPontoFaixaResultadoId =
    pontoFaixaResultado?.tipoPontoFaixaResultado.id;

  const meta = model.metaDecimal;
  const metaAcumulado = model.metaAcumuladoDecimal;

  const melhor = model.melhor;

  const metaDesafio =
    tipo == 'pontos'
      ? model?.metaDesafioFormatado
      : model?.metaDesafioAcumuladoFormatado;

  const content = faixas?.map((faixa) => {
    let simboloInferior =
      faixa.operadorInferior && faixa.operadorInferior.simbolo;
    let simboloSuperior =
      faixa.operadorSuperior && faixa.operadorSuperior.simbolo;

    const pontoInferior = faixa.pontoInferior;
    const pontoSuperior = faixa.pontoSuperior;

    let valoresInterpoladosText = interpolarValoresFaixa
      ? pontoSuperior != null
        ? ` -> ${pontoSuperior.toLocaleString()}`
        : ''
      : '';

    let faixaValorInferior = null;
    let faixaValorSuperior = null;

    const calcMeta = tipo == 'pontos' ? meta : metaAcumulado;

    let valorInferiorCalculado;
    let valorSuperiorCalculado;

    switch (tipoPontoFaixaResultadoId) {
      case 1: // faixaSobreValorRealizado
        faixaValorInferior = faixa.valorInferior;
        faixaValorSuperior = faixa.valorSuperior;
        break;
      case 2: // faixaSobreDesempenhoPercentual
        if (calcMeta < 0) {
          // Valor da meta negativa
          if (melhor.id === 1) {
            // Valor da meta negativa e polaridade positiva
            const percMeta = (calcMeta * 1) / 100;

            valorInferiorCalculado =
              faixa.valorInferior == 100
                ? calcMeta
                : calcMeta + (100 - faixa.valorInferior) * percMeta;
            valorSuperiorCalculado =
              faixa.valorSuperior == 100
                ? calcMeta
                : calcMeta + (100 - faixa.valorSuperior) * percMeta;
          } else {
            // Valor da meta negativa e polaridade negativa
            valorInferiorCalculado = (faixa.valorInferior / 100) * calcMeta;
            valorSuperiorCalculado = (faixa.valorSuperior / 100) * calcMeta;
          }
        } else {
          // Valor da meta positiva
          if (melhor.id === 1) {
            // Valor da meta positiva e polaridade positiva
            valorInferiorCalculado = (faixa.valorInferior / 100) * calcMeta;
            valorSuperiorCalculado = (faixa.valorSuperior / 100) * calcMeta;
          } else {
            // Valor da meta positiva e polaridade negativa
            // Ajusta valores
            valorInferiorCalculado =
              (calcMeta * (200 - faixa.valorInferior)) / 100;
            valorSuperiorCalculado =
              (calcMeta * (200 - faixa.valorSuperior)) / 100;
          }
        }
        if (calcMeta > 0 && melhor.id === 2) {
          // Valor da meta positiva e polaridade negativa
          faixaValorInferior =
            faixa.valorInferior != null ? faixa.valorInferior : null;
          faixaValorSuperior =
            faixa.valorSuperior != null ? faixa.valorSuperior : null;
        } else {
          faixaValorInferior =
            faixa.valorInferior != null ? valorInferiorCalculado : null;
          faixaValorSuperior =
            faixa.valorSuperior != null ? valorSuperiorCalculado : null;
        }
        break;
      case 3: // faixaSobrePercentualMeta
        if (calcMeta < 0) {
          // Valor da meta negativa
          if (melhor.id === 1) {
            // Valor da meta negativa e polaridade positiva
            //calcula 1% da meta (corresponde a 100%)
            const percMeta = (calcMeta * 1) / 100;
            //calcula os valores
            valorInferiorCalculado =
              faixa.valorInferior == 100
                ? calcMeta
                : calcMeta + (100 - faixa.valorInferior) * percMeta;
            valorSuperiorCalculado =
              faixa.valorSuperior == 100
                ? calcMeta
                : calcMeta + (100 - faixa.valorSuperior) * percMeta;
          } else {
            // Valor da meta negativa e polaridade negativa
            valorInferiorCalculado = (calcMeta * faixa.valorInferior) / 100;
            valorSuperiorCalculado = (calcMeta * faixa.valorSuperior) / 100;
          }
        } else {
          // Valor da meta positiva para ambas as polaridades
          valorInferiorCalculado = (faixa.valorInferior / 100) * calcMeta;
          valorSuperiorCalculado = (faixa.valorSuperior / 100) * calcMeta;
        }

        faixaValorInferior =
          faixa.valorInferior != null ? valorInferiorCalculado : null;
        faixaValorSuperior =
          faixa.valorSuperior != null ? valorSuperiorCalculado : null;
        break;
      case 4:
        const metaMinMax = tipo == 'pontos' ? meta : metaAcumulado;

        const valorMinimo = (metaMinMax * model.percentualMinimo) / 100;
        const valorMaximo = (metaMinMax * model.percentualMaximo) / 100;

        const minMaxInferiorId =
          faixa.minimoEMaximoInferior && faixa.minimoEMaximoInferior.id;
        const minMaxSuperiorId =
          faixa.minimoEMaximoSuperior && faixa.minimoEMaximoSuperior.id;

        // inferior
        if (minMaxInferiorId == 1) {
          faixaValorInferior =
            faixa.minimoEMaximoInferior != null ? valorMinimo : null;
        } else if (minMaxInferiorId == 2) {
          faixaValorInferior =
            faixa.minimoEMaximoInferior != null ? metaMinMax : null;
        } else {
          faixaValorInferior =
            faixa.minimoEMaximoInferior != null ? valorMaximo : null;
        }

        // superior
        if (minMaxSuperiorId == 1) {
          faixaValorSuperior =
            faixa.minimoEMaximoSuperior != null ? valorMinimo : null;
        } else if (minMaxSuperiorId == 2) {
          faixaValorSuperior =
            faixa.minimoEMaximoSuperior != null ? metaMinMax : null;
        } else {
          faixaValorSuperior =
            faixa.minimoEMaximoSuperior != null ? valorMaximo : null;
        }

        break;
      default:
        faixaValorInferior = faixa.valorInferior;
        faixaValorSuperior = faixa.valorSuperior;
        break;
    }

    const renderPontos = (
      <>
        <td style={{ width: 150 }}>
          <div
            style={{
              marginLeft: 15,
              paddingLeft: 15,
              borderLeft: '1px solid white',
            }}
          >
            {`${
              pontoInferior != null ? pontoInferior.toLocaleString() : ''
            } ${valoresInterpoladosText}`}
          </div>
        </td>
        <td style={{ width: 150}}>
          {` ${intl.formatMessage({
            id: 'label.pontos',
          })}`}
        </td>
      </>
    );

    const renderSimbolosValores = (simbol, rangeValue, calcValue) => {
      const casasDecimaisIndicador =
        model.casasDecimais && model.casasDecimais < 0
          ? 2
          : model.casasDecimais;
      const simbolo = simbol ?? '';

      let faixaValor =
        rangeValue != null
          ? rangeValue.toLocaleString(undefined, {
              maximumFractionDigits: casasDecimaisIndicador,
              minimumFractionDigits: casasDecimaisIndicador,
            })
          : '';
      const valorCalculado =
        simbol != null && calcValue != null
          ? calcValue.toLocaleString(undefined, {
              maximumFractionDigits: casasDecimaisIndicador,
              minimumFractionDigits: casasDecimaisIndicador,
            })
          : '';

      if (
        tipoPontoFaixaResultadoId == 2 &&
        calcMeta > 0 &&
        melhor &&
        melhor.id === 2
      ) {
        faixaValor = simbol == null ? '' : `${faixaValor}%`;
        return `${simbolo} ${faixaValor} ${valorCalculado}`;
      } else {
        return `${simbolo} ${faixaValor}`;
      }
    };

    return (
      <>
        <tr>
          <td>
            {renderSimbolosValores(
              simboloInferior,
              faixaValorInferior,
              valorInferiorCalculado
            )}
          </td>
          <td>
            {renderSimbolosValores(
              simboloSuperior,
              faixaValorSuperior,
              valorSuperiorCalculado
            )}
          </td>
          {renderPontos}
        </tr>
      </>
    );
  });
  return (
    <Table className="w-100" style={{ borderSpacing: 5 }}>
      {content}
      {metaDesafio && (
        <>
          <tr>
            <td className="text-left" colSpan={4}>
              {` ${intl.formatMessage(
                { id: 'metaDesafio' },
                {
                  meta: resources.meta,
                }
              )} : ` + metaDesafio}
            </td>
          </tr>
        </>
      )}
    </Table>
  );
};

export default PontosFaixaPorPeriodoOuAcumulado;
