import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import PontoControleEdit from '../paginas/gestao-risco/ponto-controle/edit';

@autobind
class PontoControle extends React.Component {
  
  render() {
    return (
      <Lookup 
        labelKey="descricao" 
        valueKey="id" 
        url="/PontoControleLookup" 
        pageSize={99999} 
        editComponent={this.props.disabledNew ? null : PontoControleEdit} 
        {...this.props} 
      />
    );
  }
}

export default PontoControle;
