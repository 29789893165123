import { useState, useEffect, useCallback } from 'react';
import PanelCollapse from 'src/componentes/panel-collapse';
import SeveridadeRiscoLookup from 'src/lookups/severidade-risco';
import { capitalizeFirstLetter } from 'src/utils/string';
import Info from 'src/componentes/info';
import { Row, Col } from 'reactstrap';
import http from 'src/services/httpService';
import { getConfiguration } from 'src/services/commonRequests';
import LoadingContainer from 'src/componentes/loading-container';
import { useIntl } from 'react-intl';

export default function TiposSeveridadeEvento({
  avaliacaoModel,
  disabled,
  descricaoLookups,
  resources,
}) {
  const [tiposSeveridade, setTiposSeveridades] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [configuracao, setConfiguracao] = useState(null);
  const [itensLookup, setItensLookup] = useState(null);
  const [descricoesTipos, setDescricoesTipos] = useState(null);
  const intl = useIntl();
  const modelName = 'valoresTipoSeveridade';
  const infoName = 'infoTipoSeveridadeDescricao' + descricaoLookups;

  useEffect(() => {
    getConfiguration('ItemConfiguracaoRisco')((configuracao) =>
      setConfiguracao(configuracao)
    )();
  }, []);

  useEffect(() => {
    if (configuracao?.avaliarSeveridadePorTipo) getColumns();
    else if (configuracao && !configuracao.avaliarSeveridadePorTipo)
      setIsLoading(false);
  }, [configuracao]);

  const getColumns = useCallback(async () => {
    setIsLoading(true);
    await http
      .post(`/TipoSeveridade/List`, {
        parameters: { buscarExcluidos: true },
        pageSize: 1000,
      })
      .then((response) => {
        setTiposSeveridades(response.data.list);
        setIsLoading(false);
      });
  });

  const getValue = (item, tipo) => {
    if (item == 'valoresTipoSeveridade') {
      const tipoSeveridadeValue = avaliacaoModel.getModel(item).value;
      const result = tipoSeveridadeValue?.find(
        (valor) => valor.tipoSeveridade.id == tipo.id
      );
      return result?.severidade;
    } else {
      return avaliacaoModel.getModel(item).value;
    }
  };

  const renderDescricaoArray = (tipo) => {
    const infos = descricoesTipos?.filter(
      (item) => item.tipoSeveridade.id == tipo.id && item.descricao
    );
    if (infos && infos.length > 0) {
      return (
        <table>
          {infos.map((obj) => (
            <tr>
              <td style={{ fontWeight: 'bold' }}>{obj.nomeSeveridade}:</td>
              <td style={{ textAlign: 'left' }}>{obj.descricao}</td>
            </tr>
          ))}
        </table>
      );
    } else {
      return (
        <table>
          <tr>
            <td style={{ fontWeight: 'bold' }}>
              {intl.formatMessage({ id: 'labelNenhumDescricaoInformada' })}
            </td>
          </tr>
        </table>
      );
    }
  };

  const requestChange = (value, tipo) => {
    const currentModel = avaliacaoModel.getModel(modelName);
    let valores = currentModel.value;
    if (!valores) valores = [];

    var itemValor = valores.find((item) => item.tipoSeveridade.id == tipo.id);

    if (itemValor) {
      itemValor.severidade = value;
    } else {
      valores.push({
        severidade: value,
        tipoSeveridade: tipo,
      });
    }

    currentModel.requestChange(valores);
  };

  const loadDataLookup = useCallback(() => {
    const data = { idsSelecionados: getValue('listaSeveridadeIdMatriz') };

    setIsLoading(true);
    http
      .post(`SeveridadeRiscoLookup/Search`, {
        filter: data,
        pageSize: 1000,
      })
      .then((response) => {
        setItensLookup(response.data.list);
      })
      .finally(setIsLoading(false));
  });

  const loadDescricoesLookup = useCallback(() => {
    setIsLoading(true);

    http.post(`TipoSeveridadeDescricao/ObterDescricoes`).then((response) => {
      setDescricoesTipos(response.data);
    });
    setIsLoading(false);
  });

  useEffect(() => {
    loadDescricoesLookup();
  }, []);

  useEffect(() => {
    loadDataLookup();
  }, [getValue('listaSeveridadeIdMatriz')]);

  return (
    <>
      {tiposSeveridade && (
        <PanelCollapse
          header={intl.formatMessage(
            { id: 'labelAvaliacaoTipoSeveridade' },
            { severidade: resources.severidade }
          )}
        >
          <LoadingContainer isLoading={isLoading}>
            <Row>
              {tiposSeveridade &&
                tiposSeveridade?.map(
                  (tipo) =>
                    (!tipo.excluido || getValue(modelName, tipo)) && (
                      <Col md={4}>
                        <SeveridadeRiscoLookup
                          disabled={disabled}
                          clearable
                          model={{
                            label: (
                              <span>
                                {capitalizeFirstLetter(tipo.nome)}
                                <Info
                                  id={`${infoName}-${tipo.id}`}
                                  tooltipMaxWidth={600}
                                  placement="auto"
                                  renderContent={() =>
                                    renderDescricaoArray(tipo)
                                  }
                                />
                              </span>
                            ),
                            value: getValue(modelName, tipo),
                            requestChange: (value) =>
                              requestChange(value, tipo),
                            errors: avaliacaoModel.getModel(modelName).errors,
                          }}
                          options={itensLookup}
                        />
                      </Col>
                    )
                )}
            </Row>
          </LoadingContainer>
        </PanelCollapse>
      )}
    </>
  );
}
