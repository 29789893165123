import Lookup from 'src/componentes/select2';
import { useIntl } from 'react-intl';

const PontuacaoAnaliseHipoteseLookup = ({
  indexParticipante,
  notasUtilizadoPorParticipante,
  ...rest
}) => {
  const customStyle = {
    option: (base, state) => ({
      ...base,
      color: '#1e2022',
      backgroundColor: state.isSelected ? '#dae1eb' : 'white',
      padding: '.5rem 3rem .5rem .5rem',
      cursor: 'pointer',
    }),
    input: (base, state) => ({
      ...base,
      height: '60px',
    }),
  };
  const intl = useIntl();

  const lookupRenderDescricao = ({ data }) => {
    return (
      <div>
        <div className="font-weight-bold">{data?.value}</div>
        {data?.descricao && (
          <>
            <div className="overflow-hidden line-clamp-2 text-sm text-muted">
              {data?.descricao}
            </div>
            <div className="overflow-hidden line-clamp-2 text-sm text-muted">
              {`${intl.formatMessage({ id: 'totalVotosParticipante' })} - 
              ${
                notasUtilizadoPorParticipante.length > 0
                  ? notasUtilizadoPorParticipante[indexParticipante] &&
                    notasUtilizadoPorParticipante[indexParticipante][
                      (data?.id ?? 1) - 1
                    ]
                  : 0
              }`}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <Lookup
      labelKey="value"
      valueKey="id"
      url="/PontuacaoAnaliseHipoteseLookup"
      valueComponent={lookupRenderDescricao}
      clearable={true}
      {...rest}
      customStyle={customStyle}
    />
  );
};

export default PontuacaoAnaliseHipoteseLookup;
