export const FUNCAO_CONTROLE = {
    Preventivo: 1,
    Corretivo: 2,
  };

  export const CSA_STATUS = {
    RASCUNHO: 0,
    PENDENTEEXECUCAO: 3,
    PENDENTEREVISAO: 4,
    CONCLUIDO: 5,
    REPROVADO: 6,
  };

  export const WIDGET_RANKING_RISCO_TIPO_EXIBICAO = {
    SOMENTE_GRAFICO: 1,
    SOMENTE_RISCOS: 2,
    GRAFICO_RISCOS: 3,
  };