import { useIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import MdiIcon from 'src/componentes/mdi-icon';
import history from 'src/history';
import Button from 'src/componentes/button';
import { useEffect, useState } from 'react';
import http from 'src/services/httpService';
import { ArrowLeft } from 'react-feather';

const Content = ({
  titulo,
  isEllipsis = false,
  children,
  iconSettings = false,
  titleSettings = null,
  urlSettings = '',
  endPointPermissionSettings = null,
  actionsTop = null,
  iconFullScreen = false,
  handleFullScreen = null,
  showBackButton = true,
  handleClickSettings = null,
  handleClickBack = null,
  ...rest
}) => {
  const id = 'PAGE-TITLE';
  const intl = useIntl();
  const [hasAccessSettings, setHasAccessSettings] = useState(
    endPointPermissionSettings == null
  );

  const onClickSettings = () => {
    if (urlSettings) history.push(urlSettings);
    else if (handleClickSettings) handleClickSettings();
  };

  const onClickBack = () => {
    if (handleClickBack) handleClickBack();
    else history.goBack();
  };

  const onClickFullScreen = () => {
    if (handleFullScreen) handleFullScreen();
  };

  const [canGoBack, setCanGoBack] = useState(history.length > 1);

  useEffect(() => {
    if (iconSettings && endPointPermissionSettings) {
      http.post(endPointPermissionSettings).then((response) => {
        setHasAccessSettings(response.data);
      });
    }
  }, [endPointPermissionSettings]);

  useEffect(() => {
    const unsubscribe = history.listen(({ action }) => {
      if (action === 'POP') {
        setCanGoBack(history.length > 1);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <div className="no-style  p-4" {...rest}>
      {titulo && (
        <>
          <h3
            className={`mb-4 pb-md-2 ignoreInPrint ${
              isEllipsis ? 'text-truncate' : ''
            }`}
            style={{ borderBottom: '1px solid #e4eaec' }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                {showBackButton && canGoBack && (
                  <div
                    className="text-primary cursor-pointer mr-2"
                    style={{ marginTop: '-2px' }}
                  >
                    <ArrowLeft size={22} onClick={onClickBack} />
                  </div>
                )}
                <span id={id}>{titulo}</span>
              </div>
              <div>
                {hasAccessSettings && iconSettings && (
                  <div id={'tooltipSettings'}>
                    <MdiIcon
                      icon="cog"
                      className="text-primary"
                      size="19"
                      onClick={onClickSettings}
                      style={{ cursor: 'pointer' }}
                    ></MdiIcon>
                    {titleSettings && (
                      <UncontrolledTooltip
                        target={'tooltipSettings'}
                        placement="auto"
                      >
                        {titleSettings}
                      </UncontrolledTooltip>
                    )}
                  </div>
                )}
                {iconFullScreen && (
                  <div id={'tooltipFullScreen'}>
                    <Button
                      id="matriz-fullscreen"
                      className="mr-2"
                      onClick={() => onClickFullScreen()}
                    >
                      <MdiIcon icon="arrow-expand" color="secondary" />
                    </Button>
                    <UncontrolledTooltip target="matriz-fullscreen">
                      {intl.formatMessage({ id: 'telaCheia' })}
                    </UncontrolledTooltip>
                  </div>
                )}
                {actionsTop && <>{actionsTop}</>}
              </div>
            </div>
          </h3>
          {isEllipsis && (
            <UncontrolledTooltip target={id} placement="auto">
              {titulo}
            </UncontrolledTooltip>
          )}
        </>
      )}
      {children}
    </div>
  );
};

export default Content;
