import React from 'react';
import { autobind } from 'core-decorators';
import MdiIcon from '../componentes/mdi-icon';
import Avatar from '../componentes/avatar';
import { PopoverBody, Popover, UncontrolledTooltip, Label } from 'reactstrap';
import css from '../utils/css';
import ContentManager from '../componentes/content-manager';
import Mensagem from '../paginas/mensagem/modal';
import ResponsavelLookup from '../lookups/colaborador';
import guid from '../utils/guid';

const classes = css`
  .removeHover {
    transition: all ease-in-out 0.1s;
    &:hover {
      cursor: pointer;
      color: #f05050 !important;
    }
  }
  .plusButton {
    transition: all ease-in-out 0.3s;
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #dde6e9;
    cursor: pointer;
    &:hover {
      background: rgb(221, 230, 233, 0.4);
    }
  }
`;

@autobind
class ColaboradorButtonLookup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responsavelLookup: false,
      colaboradorId: null,
      chaveIds: guid(),
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    let valueAntigo = this.props.model && this.props.model.value;
    let valueNovo = newprops.model && newprops.model.value;

    if (valueAntigo != valueNovo && !newprops.multi) {
      this.toggleLookup(false);
    }
  }

  handleChangeValue(valor) {
    this.setState({ valor: valor });
  }

  handleChangeColaborador(colaboradorIdNovo) {
    let { colaboradorId } = this.state;
    let colaboradorIdParam = null;

    if (colaboradorId == null) colaboradorIdParam = colaboradorIdNovo;
    else {
      if (colaboradorId == colaboradorIdNovo) colaboradorIdParam = null;
      else colaboradorIdParam = colaboradorIdNovo;
    }

    this.setState({ colaboradorId: colaboradorIdParam });
  }

  handleMensagemClick(colaborador) {
    let { tipoLookup, acaoId } = this.props;

    this.setState({ colaboradorId: null });

    ContentManager.addContent(
      <Mensagem colaborador={colaborador} tipoLookup={tipoLookup} fromAcao={this.props.fromAcao} fromItem={this.props.fromItem} acaoId={acaoId} />
    );
  }

  toggleLookup(param) {
    this.setState({ responsavelLookup: param ? param : !this.state.responsavelLookup, colaboradorId: null }, () => {
      this.props.callbackLookup && this.props.callbackLookup(this.state.responsavelLookup);
    });
  }

  renderLabel() {
    return (
      this.props.showLabel == true && (
        <div style={{ width: '100%' }}>
          <Label className="control-label">
            {this.props.model && this.props.model.label} {this.props.required ? '*' : ''}
          </Label>
        </div>
      )
    );
  }

  renderPlusButton(model, multi = false) {
    let error = this.getError(model);

    return (
      <div className={classes.plusButton} style={{ border: error && '1px solid rgb(240, 80, 80)', marginTop: multi && -2 }} onClick={() => this.toggleLookup()}>
        <MdiIcon style={{ color: error && 'rgb(240, 80, 80)' }} icon="plus" />
      </div>
    );
  }

  getError(model) {
    if (model && model.errors && model.errors != '') return true;

    return false;
  }

  renderSingle(model) {
    let valor = model && model.value;
    let { chaveIds, colaboradorId } = this.state;
    let id;
    let { disabled } = this.props;

    if (valor) id = `id${valor.id}${chaveIds}`;

    return (
      <div class="form-group" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: this.props.center == true && 'center' }}>
        {this.renderLabel()}
        {valor == null ? (
          <div style={{ display: 'flex' }}>
            {!disabled && this.renderPlusButton(model, false)}
            {this.state.responsavelLookup && (
              <div style={{ marginLeft: 10 }}>
                <ResponsavelLookup {...this.props} className="popupOpen" showLabel={false} autofocus size={30} onlyAvatar={true} clearable={false} />
              </div>
            )}
          </div>
        ) : (
          <div
            id={id}
            onClick={() => this.handleChangeColaborador(valor.id)}
            style={{ cursor: 'pointer', width: 30, display: 'flex', alignItems: 'center', marginTop: this.props.showLabel == true && 3 }}
          >
            <Avatar foto={valor && valor.fotoArquivo} showLabel={false} border={1} size={30} label={valor && valor.nome} />
            <UncontrolledTooltip placement="top" target={id}>
              {valor && valor.nome}
            </UncontrolledTooltip>
            <Popover toggle={() => this.handleChangeColaborador(valor.id)} style={{ minWidth: 200 }} placement="right" target={id} isOpen={colaboradorId == valor.id}>
              <PopoverBody className="popupOpen">
                <span style={{ fontSize: 14 }}>
                  <strong>{valor.nome}</strong>
                </span>
                <br></br>
                <span style={{ fontSize: 14 }}>{valor.unidadeArea}</span>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {this.props.acaoId && this.props.acaoId > 0 ? (
                    <MdiIcon style={{ cursor: 'pointer' }} onClick={() => this.handleMensagemClick(valor)} icon="comment-text-outline" />
                  ) : (
                    ''
                  )}
                  <MdiIcon
                    onClick={() => (this.props.remove ? this.props.remove() : this.remove())}
                    className={classes.removeHover}
                    style={{ marginLeft: 5 }}
                    icon="delete-outline"
                  />
                </div>
              </PopoverBody>
            </Popover>
          </div>
        )}
      </div>
    );
  }

  removeMulti(index) {
    let valores = this.props.model.value;
    let valoresCopia = Object.assign([], valores);

    if (valoresCopia) {
      valoresCopia.splice(index, 1);
      this.props.model.requestChange && this.props.model.requestChange(valoresCopia);
    }
  }

  remove() {
    this.props.model.requestChange && this.props.model.requestChange(null);
  }

  renderMulti(model) {
    let values = model && model.value;
    let { chaveIds, colaboradorId } = this.state;
    let { disabled } = this.props;

    return (
      <div class="form-group">
        {this.renderLabel()}
        <div style={{ display: 'flex' }}>
          {!this.state.responsavelLookup ? (
            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', flexFlow: 'wrap', marginTop: this.props.showLabel == true && 3 }}>
              {values &&
                values.map((colaborador, index) => (
                  <div
                    style={{ marginRight: 5, marginBottom: 5 }}
                    id={`id${colaborador.id}${chaveIds}`}
                    onClick={() => this.handleChangeColaborador(colaborador.id)}
                    key={index}
                  >
                    <Avatar foto={colaborador && colaborador.fotoArquivo} showLabel={false} border={1} size={30} label={colaborador && colaborador.nome} />
                    <UncontrolledTooltip placement="top" target={`id${colaborador.id}${chaveIds}`}>
                      {colaborador && colaborador.nome}
                    </UncontrolledTooltip>
                    <Popover
                      toggle={() => this.handleChangeColaborador(colaborador.id)}
                      style={{ minWidth: 200 }}
                      placement="right"
                      target={`id${colaborador.id}${chaveIds}`}
                      isOpen={colaboradorId == colaborador.id}
                    >
                      <PopoverBody className="popupOpen">
                        <span style={{ fontSize: 14 }}>
                          <strong>{colaborador.nome}</strong>
                        </span>
                        <br></br>
                        <span style={{ fontSize: 14 }}>{colaborador.unidadeArea}</span>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          {this.props.acaoId && this.props.acaoId > 0 && (
                            <MdiIcon style={{ cursor: 'pointer' }} onClick={() => this.handleMensagemClick(colaborador)} icon="comment-text-outline" />
                          )}
                          {!disabled && (
                            <MdiIcon
                              onClick={() => (this.props.remove ? this.props.remove() : this.removeMulti(index))}
                              className={classes.removeHover}
                              style={{ marginLeft: 5 }}
                              icon="delete-outline"
                            />
                          )}
                        </div>
                      </PopoverBody>
                    </Popover>
                  </div>
                ))}
              {!disabled && this.renderPlusButton(model, true)}
            </div>
          ) : (
            <div style={{ marginRight: 10 }}>
              <ResponsavelLookup
                {...this.props}
                className="popupOpen"
                showLabel={false}
                autofocus
                size={28}
                onlyAvatar={true}
                onBlur={() => this.toggleLookup(false)}
                clearable={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return this.props.multi ? this.renderMulti(this.props.model && this.props.model) : this.renderSingle(this.props.model && this.props.model);
  }
}

export default ColaboradorButtonLookup;
