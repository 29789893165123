import React from 'react';
import { autobind } from 'core-decorators';

import Lookup from 'src/componentes/select2';
@autobind
class MinimoEMaximo extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/MinimoEMaximoLookup" {...this.props} />;
  }
}

export default MinimoEMaximo;
