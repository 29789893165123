import { useState, useEffect, useRef } from 'react';

import LoadingContainer from 'src/componentes/loading-container';
import Panel from 'src/componentes/panel';
import {
  getPermissions,
  getFilter,
  getColumns,
  exportList,
  getData,
} from 'src/componentes/NewList';
import errorHandler from 'src/utils/error-handler';
import Toolbar from 'src/componentes/NewList/Toolbar';
import http from 'src/services/httpService';
import { ParseError } from 'src/services/downloadFileUrl';
import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';
import { useIntl } from 'react-intl';

import List from './List';

const NewListTree = ({
  endpoint,
  defaultColumns = [],
  parameters = {},
  filterTag,
  columnsTag,
  entityTag,
  searchComponent,
  searchSchema,
  actions,
  handleNewClick,
  renderMenu,
  exportActions,
  showExport,
  showSearch,
  showNew,
  widgetContainer,
  getRowColor,
  getRowTextColor,
  infoMessage,
  entitiesKey,
  showMenu,
  tipoItem,
  showFullTextColumns,
  containerSize = 143,
  pageSize = 100,
  sortField: sortFieldProps,
  openLastFilter,
  selectedFilter,
  renderNewButton,
  showModal = false,
  select = false,
  onChangeUrl,
  saved,
  ...rest
}) => {
  const ref = useRef();
  const intl = useIntl();

  const [isFirstCall, setIsFirstCall] = useState(true);

  const [isLoadingGeneral, setIsLoadingGeneral] = useState(true);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isLoadingInfinityData, setIsLoadingInfinityData] = useState(false);

  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [sortField, setSortField] = useState(sortFieldProps);
  const [sortFieldBy, setSortFieldBy] = useState('asc');

  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    totalPaginas: 1,
  });

  const [filter, setFilter] = useState({});
  const [expandedIds, setExpandedIds] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [selectedItems, setSelectedItems] = useState(
    rest?.selectedIds ? [...rest?.selectedIds] : []
  );

  const { widgetColumns, usingWidget } = widgetContainer || {};

  const initialPage = page === 0;

  const handleCloseDialog = () => {
    if (ref.current) ref.current.close();
  };

  const loadColumns = async () => {
    try {
      const currentColumns = await getColumns(
        defaultColumns,
        entityTag,
        columnsTag
      );
      setColumns(currentColumns.columns);
      setAllColumns(currentColumns.allColumns);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleGetChildren = async (parentId, newFilter) => {
    try {
      const response = await http.post(`${endpoint}/ExpandParent`, {
        model: { ...newFilter },
        sort: `${sortField} ${sortFieldBy}`,
        parameters: { ...parameters, paiId: parentId },
      });

      updateChildren(parentId, response.data.lista);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleGetData = async (newFilter) => {
    try {
      const resData = await getData(
        endpoint,
        newFilter,
        sortField,
        sortFieldBy,
        pageSize,
        page,
        { ...parameters }
      );

      return { ...resData };
    } catch (err) {
      errorHandler(err);
    }
  };

  const refreshData = async (parentId = null) => {
    const firstLoad = !permissions;

    if (firstLoad && !usingWidget) {
      const newPermissions = await getPermissions(endpoint, parameters);
      setPermissions(newPermissions);

      if (!newPermissions.allowView && !newPermissions.allowList) return;
      await loadColumns();
    }

    let valueFilter = {};

    if (openLastFilter) {
      let newFilter = filterTag ? await getFilter(filterTag, true) : {};

      setFilter(newFilter);

      if (newFilter != null) valueFilter = newFilter.valor;
    } else {
      valueFilter =
        firstLoad && selectedFilter ? selectedFilter?.valor : filter?.valor;
      setFilter(selectedFilter);
    }

    const model = { ...valueFilter, searchText };

    if (firstLoad) setIsLoadingGeneral(false);

    if (parentId) {
      await handleGetChildren(parentId, model);
    } else {
      setIsLoadingInfinityData(true);

      if (initialPage) {
        setExpandedIds([]);
      }

      const { lista, total, totalPaginas, itensExpandidos, possuiFiltros } =
        await handleGetData(usingWidget ? widgetContainer?.filter : model);

      const hasExpandedIds = itensExpandidos?.length > 0;

      if (hasExpandedIds) setExpandedIds(itensExpandidos);

      setData((prev) => {
        const newList = initialPage ? [...lista] : [...prev.lista, ...lista];
        return {
          lista: newList,
          contadorExibicao: hasExpandedIds ? total : newList.length,
          contadorTotal: total,
          totalPaginas: totalPaginas,
          possuiFiltros,
        };
      });

      setIsLoadingInfinityData(false);
    }
  };

  const handleLoadChildren = async (parentId) => await refreshData(parentId);

  const updateChildren = (expandId, children) => {
    setData((prev) => {
      const newList = Object.assign([], prev.lista || []);
      setChildren(expandId, newList, children);

      const childrenLength = children?.length || 0;

      return {
        ...prev,
        contadorExibicao: prev.contadorExibicao + childrenLength,
        lista: newList,
      };
    });
  };

  const setChildren = (expandId, generalList, childrenList) => {
    for (let i = 0; i < generalList.length; i++) {
      if (generalList[i].id === expandId) {
        generalList[i].children = childrenList;
      }
      if (generalList[i].children)
        setChildren(expandId, generalList[i].children, childrenList);
    }
  };

  const handleToggleExpand = async ({ id, children }) => {
    const newList = [...expandedIds];
    const index = newList.indexOf(id);

    if (index !== -1) {
      newList.splice(index, 1);
    } else {
      newList.push(id);
    }

    setExpandedIds(newList);

    if (!children?.length) await handleLoadChildren(id);
  };

  const isExpanded = ({ id }) => expandedIds.includes(id);

  // -- EXPORT --

  const getIdsForExportation = () => {
    const itemsNoParents =
      data?.lista?.length > 0 ? data.lista.filter((x) => !x.paiId) : [];

    const parameter = {
      newListIds: [],
    };

    getExportIds(itemsNoParents, parameter);

    return parameter.newListIds;
  };

  const getExportIds = (items, parameter) => {
    items.forEach(({ id, children }) => {
      parameter.newListIds.push(id);
      const hasChildren = children?.length > 0;

      if (hasChildren) getExportIds(children, parameter);
    });
  };

  const handleExport = async (fileExtension) => {
    try {
      setIsLoadingExport(true);

      let valueFilter = {};
      if (filter != null) valueFilter = filter.valor;

      let newSearchModel = {
        searchText,
        ...valueFilter,
      };

      if (parameters)
        parameters.idsItensParaExportacao = getIdsForExportation();

      await exportList(
        newSearchModel,
        endpoint,
        sortField,
        page,
        parameters,
        fileExtension
      );
    } catch (err) {
      errorHandler(ParseError(err));
    } finally {
      setIsLoadingExport(false);
    }
  };

  // -- END

  const handleSortColumn = (sort, sortBy) => {
    setSortField(sort);
    setSortFieldBy(sortBy);
  };

  const handleSearchChange = (value) => setSearchText(value);

  const handleColumnsChange = async () => {
    await loadColumns();
  };

  const handleRefresh = async (selectedFilterUpdated) => {
    selectedFilter = selectedFilterUpdated;
    initialPage ? await refreshData() : setPage(0);
  };

  const { totalPaginas, possuiFiltros } = data || {};

  const canRefreshData = usingWidget ? false : page <= totalPaginas - 1;

  useEffect(() => {
    if (!isFirstCall || usingWidget) handleRefresh();
  }, [sortField, sortFieldBy, searchText, widgetContainer?.filter]);

  useEffect(() => {
    if (canRefreshData) {
      refreshData();
      if (isFirstCall) setIsFirstCall(false);
    }
  }, [canRefreshData, page, saved]);

  const handleConfirmSelection = () => {
    if (rest.selectedCallback) {
      rest.selectedCallback(selectedItems);
    }
    handleCloseDialog();
  };

  const renderContent = () => (
    <LoadingContainer isLoading={isLoadingGeneral || isLoadingExport}>
      {!usingWidget && (
        <Toolbar
          useSavedFilter
          showSearch
          showNew={showNew}
          renderNewButton={renderNewButton}
          showExport={showExport}
          exportActions={exportActions}
          onExport={handleExport}
          onSearchChange={handleSearchChange}
          onColumnsChange={handleColumnsChange}
          onRefresh={handleRefresh}
          searchText={searchText}
          searchComponent={searchComponent}
          searchSchema={searchSchema}
          filter={filter}
          filterTag={filterTag}
          columnsTag={columnsTag}
          columns={columns}
          allColumns={allColumns}
          actions={actions}
          permissions={permissions || {}}
          handleNewClick={handleNewClick}
          tipoItem={tipoItem}
          onChangeUrl={onChangeUrl} 
        />
      )}
      <div style={{ display: isLoadingGeneral ? 'none' : 'block' }}>
        <List
          {...rest}
          columns={usingWidget ? widgetColumns : columns}
          data={data}
          page={page}
          handleChangePage={setPage}
          isLoading={isLoadingInfinityData}
          expandedIds={expandedIds}
          handleToggleExpand={handleToggleExpand}
          isExpanded={isExpanded}
          renderMenu={renderMenu}
          sortField={sortField}
          sortFieldBy={sortFieldBy}
          handleSortColumn={handleSortColumn}
          showSearch={showSearch}
          showNew={showNew}
          usingWidget={usingWidget}
          getRowColor={getRowColor}
          getRowTextColor={getRowTextColor}
          infoMessage={infoMessage}
          entitiesKey={entitiesKey}
          hasFilters={possuiFiltros}
          showMenu={showMenu}
          showFullTextColumns={showFullTextColumns}
          containerSize={containerSize}
          onRefresh={handleRefresh}
          select={select}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </div>
    </LoadingContainer>
  );

  const actionsModal = select && [
    <Button onClick={handleCloseDialog} key="btnclose" color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      onClick={handleConfirmSelection}
      key="bynSave"
      type="primary"
      style={{ marginLeft: 10 }}
    >
      {intl.formatMessage({ id: 'label.confirmar' })}
    </Button>,
  ];

  return usingWidget ? (
    renderContent()
  ) : showModal ? (
    <Dialog
      ref={ref}
      {...rest}
      onRequestClose={handleCloseDialog}
      padContent
      width="80%"
      height="70%"
      actions={actionsModal}
    >
      <Panel>{renderContent()}</Panel>
    </Dialog>
  ) : (
    <Panel>{renderContent()}</Panel>
  );
};

export default NewListTree;
