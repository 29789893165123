import React from 'react';
import Button from '../button';
import Dialog from '../dialog';
import { autobind } from 'core-decorators';
import { keydown, KEYS } from '../../utils/keydown-decorator';
import jsxArray from '../../utils/jsx-array';
import { ButtonToolbar } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Radio from '../radio';

@autobind
class Orientacao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orientationId: 1,
    };
  }

  @keydown(KEYS.ESC)
  handleCancelClick() {
    this.props.handleCancel && this.props.handleCancel(this.state.orientationId);
    this.refs.dialog.close();
  }

  @keydown(KEYS.ENTER)
  handleConfirmClick() {
    this.props.handleConfirm && this.props.handleConfirm(this.state.orientationId);
    this.refs.dialog.close();
  }

  changeOrientation(value) {
    this.setState({
      orientationId: value,
    });
  }

  render() {
    let { handleCancel, handleConfirm, intl, isFunction, ...other } = this.props;

    const optionsPageOrientation = [
      { id: 1, descricao: intl.formatMessage({ id: 'label.retrato' }) },
      { id: 2, descricao: intl.formatMessage({ id: 'label.paisagem' }) },
    ];

    const actions = jsxArray(
      <ButtonToolbar>
        <Button onClick={this.handleCancelClick} color="secondary">
          {intl.formatMessage({ id: 'label.cancelar' })}
        </Button>
        <Button className="ml-2" autoFocus type="primary" onClick={this.handleConfirmClick}>
          {intl.formatMessage({ id: 'label.confirmar' })}
        </Button>
      </ButtonToolbar>
    );

    return (
      <Dialog {...other} width={380} ref="dialog" title="" actions={actions} onRequestClose={this.handleCancelClick} padContent>
        <Radio
          needId
          model={{
            label: intl.formatMessage({ id: 'label.orientacao' }),
            value: this.state.orientationId,
            requestChange: this.changeOrientation,
          }}
          options={optionsPageOrientation}
          labelField="descricao"
          idField="id"
        />
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Orientacao));
