import { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Input from 'src/componentes/input';

const StrongPassword = ({
  model,
  userName,
  email,
  type = 'password',
  showLabel = true,
}) => {
  const [mensagemErro, setMensagemErro] = useState('');
  const [colorMensagemErro, setColorMensagemErro] = useState('');

  const handleValidaSenhaForte = (value) => {
    let redigito = new RegExp('[0-9]');
    let recharminus = new RegExp('[a-z]');
    let recharespecial = new RegExp('[^a-zA-Z0-9]');
    let recharmaiusc = new RegExp('[A-Z]');

    if (value.length < 9 || value.length > 64) {
      if (value.length === 0) {
        setMensagemErro('');
      } else {
        setMensagemErro(<FormattedMessage id="label.mensagemErroSenha1" />);
        setColorMensagemErro('#FF0000');
      }
    } else if (!redigito.test(value)) {
      setMensagemErro(<FormattedMessage id="label.mensagemErroSenha2" />);
      setColorMensagemErro('#FF0000');
    } else if (!recharminus.test(value)) {
      setMensagemErro(<FormattedMessage id="label.mensagemErroSenha3" />);
      setColorMensagemErro('#FF0000');
    } else if (!recharespecial.test(value)) {
      setMensagemErro(<FormattedMessage id="label.mensagemErroSenha4" />);
      setColorMensagemErro('#FF0000');
    } else if (!recharmaiusc.test(value)) {
      setMensagemErro(<FormattedMessage id="label.mensagemErroSenha5" />);
      setColorMensagemErro('#FF0000');
    } else if (value === userName) {
      setMensagemErro(<FormattedMessage id="label.mensagemErroSenha6" />);
      setColorMensagemErro('#FF0000');
    } else {
      setMensagemErro(<FormattedMessage id="label.mensagemSenhaCorreta" />);
      setColorMensagemErro('#00FF7F');
    }

    model.requestChange(value);
  };

  return (
    <div>
      <form autoComplete="off">
        <Input
          showLabel={showLabel}
          placeholder={(model.value || userName || email) && '******'}
          type={type}
          autoComplete="off"
          model={{
            label: model.label ?? <FormattedMessage id="senha" />,
            value: model.value,
            requestChange: handleValidaSenhaForte,
          }}
        />
      </form>
      <span style={{ color: colorMensagemErro, 'font-size': 13 }}>
        {mensagemErro}
      </span>
    </div>
  );
};

export default injectIntl(StrongPassword);
