import React from 'react';
import history from '../../history';
import { autobind } from 'core-decorators';
import http from 'src/services/httpService';
import { Form, Schema } from '../../utils/form';
import Button from '../../componentes/button';
import Dialog from '../../componentes/dialog';
import { FormattedMessage } from 'react-intl';
import LoadingContainer from '../../componentes/loading-container';
import errorHandler from '../../utils/error-handler';
import { ListGroup, ListGroupItem, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

let schema = Schema.object({
  restricoes: Schema.array(),
  podeExcluir: Schema.string(),
});

@autobind
class ValidacaoRestricao extends React.Component {
  static defaultProps = {
    isEdit: false,
  };
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showModal: true,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    if (this.props.url) {
      this.setState({ isLoading: true });

      const parametros = this.props.validarAlteracao ? { id: this.props.item.id, alteracao: true } : { id: this.props.item.id };
      http
        .post(this.props.url, parametros)
        .then((response) => {
          this.form.setValue(null, response.data);
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ isLoading: false });
        });
    }
    this.setState({ isLoading: false });
  }

  handleClose() {
    this.refs.dialog.close();
  }

  async handleSalvarClick() {
    if (this.props.urlExclusao) {
      this.setState({ isLoading: true });
      await http
        .post(this.props.urlExclusao, {
          id: this.props.item.id,
        })
        .then(() => {
          this.setState({ isLoading: false });
          this.props.afterRemove && this.props.afterRemove(this.props.item);
          this.props.onClose && this.props.onClose(true);
          this.handleClose(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ isLoading: false });
        });
    }
    this.setState({ isLoading: false });
    this.props.atualizar && this.props.atualizar(this.handleClose());
    if (this.props.urlList) {
      window.location.href = this.props.urlList;
    }
  }

  render() {
    let actions = [
      <Button key={1} onClick={this.handleClose} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>,
      <Button key={2} bsStyle="primary" className="ml-2" onClick={this.handleSalvarClick}>
        {this.props.validarAlteracao ? <FormattedMessage id="label.confirmar" /> : <FormattedMessage id="label.confirmarExclusao" />}
      </Button>,
    ];

    return (
      <Dialog
        title={this.props.validarAlteracao ? <FormattedMessage id="alterar" /> : <FormattedMessage id="excluir" />}
        actions={this.form.getModel('podeExcluir').value && actions}
        width={700}
        minHeight={500}
        padContent
        onRequestClose={this.handleClose}
        mouseDownClose={false}
        ref="dialog"
        {...this.props}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <Row>
            <Col>
              {this.form.getModel('restricoes').value &&
                this.form.getModel('restricoes').value.map((restricao, index) => (
                  <Card key={index} style={{ marginBottom: 5 }}>
                    <CardHeader>{restricao.restricaoDescricao}</CardHeader>
                    <CardBody>
                      <ListGroup key={index} flush>
                        {restricao.itensDescricao &&
                          restricao.itensDescricao.map((item, itemIndex) => (
                            <ListGroupItem style={{ cursor: 'default' }} key={itemIndex}>
                              {item}
                            </ListGroupItem>
                          ))}
                      </ListGroup>
                    </CardBody>
                  </Card>
                ))}
            </Col>
          </Row>
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(ValidacaoRestricao);
