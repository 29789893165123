import { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Alert } from 'reactstrap';
import LoadingContainer from 'src/componentes/loading-container';
import Toolbar from 'src/componentes/NewList/Toolbar';
import Results from 'src/componentes/NewList/Results';
import useAuth from 'src/hooks/useAuth';
import Button from 'src/componentes/button';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import ListColumn from 'src/componentes/NewList/ListColumn';
import errorHandler from 'src/utils/error-handler';
import { getConfiguration } from 'src/services/commonRequests';
import ContentManager from 'src/componentes/content-manager';
import Actions from './Actions';
import { getColumns, getData, getPermissions } from 'src/componentes/NewList';
import EfetividadeControleEditView from './EfetividadeControleEdit';

function EfetividadeControleView({
  columnsTag,
  filterTag,
  entityTag,
  endpoint,
  defaultSort,
  defaultSortBy,
  parameters,
  showToolbar,
  riscoControleId,
  funcaoControle,
  disabled,
  alterouEfetividade = false,
}) {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const [updatedItems, setUpdatedItems] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [filter, setFilter] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(defaultSort);
  const [sortFieldBy, setSortFieldBy] = useState(defaultSortBy);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [configuracaoControle, setConfiguracaoControle] = useState({});

  useEffect(() => {
    getConfiguration('PontoControleConfiguracao')((config) => {
      setConfiguracaoControle(config);
    })();
  }, []);

  const loadColumns = async () => {
    try {
      const currentColumns = await getColumns(
        defaultColumns,
        entityTag,
        columnsTag
      );
      setColumns(currentColumns.columns);
      setAllColumns(currentColumns.allColumns);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    loadColumns();
  }, [configuracaoControle]);

  useEffect(() => {
    loadData();
  }, [
    loadData,
    pageSize,
    currentPage,
    searchText,
    sortField,
    sortFieldBy,
    configuracaoControle,
  ]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const newPermissions = await getPermissions(endpoint, parameters);
      setPermissions(newPermissions);

      let newData = [];

      if (newPermissions.allowView || newPermissions.allowList) {
        await loadColumns();

        const model = { searchText };
        newData = await getData(
          endpoint,
          model,
          sortField,
          sortFieldBy,
          pageSize,
          currentPage - 1,
          { ...parameters, riscoControleId: riscoControleId }
        );
        setData(newData.list);
        setCount(newData.count);
      }
      setLoading(false);
      return newData;
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  }, [
    endpoint,
    parameters,
    searchText,
    pageSize,
    currentPage,
    sortField,
    sortFieldBy,
    configuracaoControle,
  ]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  });

  const handleRefresh = useCallback(async (filter) => {
    await loadData(defaultColumns, filter);
  });

  const handleChangeCurrentPage = useCallback((newValue) => {
    setCurrentPage(newValue);
  });

  const handleChangePageSize = useCallback((pageSize) => {
    setPageSize(pageSize);
  });

  const handleSortColumn = useCallback((sort) => {
    setSortField(sort);
  });

  const handleSortByColumn = useCallback((sortBy) => {
    setSortFieldBy(sortBy);
  });

  const handleNew = () =>
    ContentManager.addContent(
      <EfetividadeControleEditView
        funcaoControle={funcaoControle}
        parameters={{ riscoControleId: riscoControleId }}
        configuracaoControle={configuracaoControle}
        handleClose={handleRefresh}
      />
    );

  const handleEdit = (item) =>
    ContentManager.addContent(
      <EfetividadeControleEditView
        funcaoControle={funcaoControle}
        parameters={{ riscoControleId: riscoControleId }}
        configuracaoControle={configuracaoControle}
        item={item}
        disabled={disabled}
        handleClose={handleRefresh}
      />
    );

  const actions = [
    <Button
      key={1}
      className="ml-2"
      onClick={handleNew}
      type="primary"
      disabled={disabled}
    >
      <MdiIcon icon="plus" />
    </Button>,
  ];

  const renderActions = (item) => {
    return (
      <Actions
        disabled={disabled}
        onRefresh={handleRefresh}
        item={item}
        endpoint={endpoint}
        handleOpenEdit={handleEdit}
      />
    );
  };

  const defaultColumns = [
    <ListColumn
      className="text-right"
      valueField=""
      valueFunction={renderActions}
      default
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'dataAnalise' })
      )}
      valueField="dataAnalise"
      default
      sortField="dataAnalise"
      style={{ minWidth: '50%' }}
    />,
    <ListColumn
      visible={!configuracaoControle.avaliarEfetividadeControleSeparadamente}
      headerText={capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'efetividadeControle' },
          { pontodecontrole: resources.pontodecontrole }
        )
      )}
      valueField="efetividadeControle"
      default
      sortField="efetividadeControle"
    />,
    <ListColumn
      visible={
        configuracaoControle.avaliarEfetividadeControleSeparadamente &&
        funcaoControle &&
        funcaoControle.id === 2
      }
      headerText={capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'efetividadeControle' },
          { pontodecontrole: resources.pontodecontrole }
        )
      )}
      valueField="efetividadeControleCorretivo"
      default
      sortField="efetividadeControleCorretivo"
    />,
    <ListColumn
      visible={
        configuracaoControle.avaliarEfetividadeControleSeparadamente &&
        funcaoControle &&
        funcaoControle.id === 1
      }
      headerText={capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'efetividadeControle' },
          { pontodecontrole: resources.pontodecontrole }
        )
      )}
      valueField="efetividadeControlePreventivo"
      default
      sortField="efetividadeControlePreventivo"
    />,
    <ListColumn
      headerText={capitalizeFirstLetter(
        intl.formatMessage({ id: 'observacao' })
      )}
      valueField="observacao"
      default
      sortField="observacao"
    />,
    
  ];

  if (
    !funcaoControle &&
    configuracaoControle.avaliarEfetividadeControleSeparadamente
  )
    return (
      <Row>
        <Col md={12}>
          <Alert color="warning" isOpen={true}>
            {intl.formatMessage(
              { id: 'definaFuncaoControleAbaResumo' },
              { pontodecontrole: resources.pontodecontrole }
            )}
          </Alert>
        </Col>
      </Row>
    );
  else if (alterouEfetividade)
    return (
      <Row>
        <Col md={12}>
          <Alert color="warning" isOpen={true}>
            {intl.formatMessage(
              { id: 'definaFuncaoDeControleSalvarControleAbaResumo' },
              { pontodecontrole: resources.pontodecontrole }
            )}
          </Alert>
        </Col>
      </Row>
    );

  return (
    <LoadingContainer isLoading={isLoading}>
      {showToolbar && (
        <Toolbar
          useSavedFilter
          showSearch
          onSearchChange={handleSearchChange}
          onRefresh={handleRefresh}
          searchText={searchText}
          filter={filter}
          filterTag={filterTag}
          columnsTag={columnsTag}
          columns={columns}
          allColumns={allColumns}
          actions={actions}
        />
      )}
      <Results
        resources={resources}
        count={count}
        columns={columns}
        data={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangeCurrentPage={handleChangeCurrentPage}
        onChangePageSize={handleChangePageSize}
        onSortColumn={handleSortColumn}
        onSortByColumn={handleSortByColumn}
        sort={sortField}
        sortBy={sortFieldBy}
        updatedItems={updatedItems}
      />
    </LoadingContainer>
  );
}

EfetividadeControleView.defaultProps = {
  endpoint: '/RiscoPontoControleEfetividade',
  entityTag: null,
  defaultSort: 'dataAnalise',
  defaultSortBy: 'desc',
  selectedFilter: null,
  openLastFilter: false,
  parameters: {},
  showToolbar: true,
};

export default EfetividadeControleView;
