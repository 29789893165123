import { Fragment, useRef, useState } from 'react';
import { Card, CardTitle, CardText, CardImg, CardBody, Row, Col } from 'reactstrap';
import useAuth from 'src/hooks/useAuth';
import Dialog from 'src/componentes/dialog';
import { FormattedMessage } from 'react-intl';
import Button from 'src/componentes/button';
import ControleEdit from './edit';

const NovoControle = ({ columnSize = 6, isNew, handleClose, tipoInclusaoParams = 0, ...rest }) => {
  const [tipoInclusao, setTipoInclusao] = useState(tipoInclusaoParams);
  const { terms: resources } = useAuth();
  const ref = useRef();

  const handleClick = (tipo) => {
    setTipoInclusao(tipo);
  };

  const handleCloseDialog = (saved) => {
    if (ref.current) ref.current.close();
    if (saved && handleClose) handleClose(true);
  };

  return tipoInclusao === 0 ? (
    <Dialog
      ref={ref}
      title={<FormattedMessage id="label.novo" />}
      width={columnSize == 12 ? '500px' : columnSize == 4 ? '90%' : '80%'}
      style={{ fontSize: 12 }}
      onRequestClose={handleCloseDialog}
      padContent
      {...rest}
    >
      <Row>
        <Col sm={columnSize}>
          <Card>
            <CardImg style={{ maxHeight: 480 }} top width="100%" src={'/static/images/template-item/individual.jpg'} alt="Card image cap" />
            <CardBody>
              <CardTitle>{resources.pontodecontrole}</CardTitle>
              <CardText style={{ minHeight: 40 }}>
                <FormattedMessage id="msgOpcaoTipoControle" values={{ pontodecontrole: resources.pontodecontrole }} />
              </CardText>
              <Button onClick={() => handleClick(1)}>
                <FormattedMessage id="selecionar" />
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col sm={columnSize}>
          <Card>
            <CardImg style={{ maxHeight: 480 }} top width="100%" src={'/static/images/template-item/desdobramento.jpg'} alt="Card image cap" />
            <CardBody>
              <CardTitle>
                <FormattedMessage id="labelPasta" />
              </CardTitle>
              <CardText style={{ minHeight: 40 }}>
                <FormattedMessage id="msgOpcaoTipoPasta" values={{ pontosdecontrole: resources.pontosdecontrole }} />
              </CardText>
              <Button onClick={() => handleClick(2)}>
                <FormattedMessage id="selecionar" />
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Dialog>
  ) : (
    tipoInclusao !== 0 && <ControleEdit tipoInclusao={tipoInclusao} isNew={isNew} handleCloseDialog={handleCloseDialog} {...rest} />
  );
};

export default NovoControle;
