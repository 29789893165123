import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Input from 'src/componentes/input';

@autobind
class PontoFaixaResultadoSearch extends React.Component {
  render() {
    let { model } = this.props;
    return (
      <Row>
        <Col md={6}>
          <Input model={model.getModel('idIntegracao')} />
        </Col>

        <Col md={6}>
          <Input model={model.getModel('descricao')} />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PontoFaixaResultadoSearch));
