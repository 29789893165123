import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import MdiIcon from 'src/componentes/mdi-icon';
import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';

import SearchSchema from './search-schema';
import Search from './search';
import ReuniaoEdit from './edit';
import { Badge } from 'reactstrap';
@autobind
class Reuniao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
    };
  }

  renderEnviarEmail(item) {
    if (item.enviarEmail) {
      return <MdiIcon icon="checkbox-marked-outline" size={20} color={'#1A237E'} />;
    } else {
      return <MdiIcon icon="close-box-outline" size={20} color={'#1A237E'} />;
    }
  }

  renderAssunto(item, callback) {
    if (!item.assunto) {
      return;
    }

    return (
      <a onClick={this.handleDetailClick.bind(this, item, callback)} style={{ cursor: 'pointer' }}>
        {item.assunto}
      </a>
    );
  }

  openDetail(item) {
    ContentManager.addContent(<ReuniaoEdit item={item}></ReuniaoEdit>);
  }

  handleNewClick() {
    this.openDetail(null);
  }

  handleDetailClick(item) {
    this.openDetail(item);
  }

  renderTags(item) {
    return item.tags?.map((x) => (
      <Badge pill className="text-white" style={{ background: x.cor }}>
        {x.descricao}
      </Badge>
    ));
  }

  render() {
    let { intl, disabled, resources } = this.props;

    return (
      <List
        url="/Reuniao"
        editComponent={ReuniaoEdit}
        showSearch
        showFilter
        showNew={!disabled}
        searchSchema={SearchSchema}
        searchComponent={<Search />}
        pageSize={20}
        tag={4006}
        filtroId={54}
        saved={this.state.saved}
        sort="UltimaVersao.Nome"
        refreshOnClose={true}
        ehReuniao={true}
        {...this.props}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'assunto' })}
          valueField="assunto"
          valueFunction={this.renderAssunto}
          sortField="UltimaVersao.Nome"
          default
        />
        <ListColumn headerText={intl.formatMessage({ id: 'dataInicio' })} valueField="dataInicioItem" default />
        <ListColumn headerText={intl.formatMessage({ id: 'dataTermino' })} valueField="dataTerminoItem" default />
        <ListColumn headerText={intl.formatMessage({ id: 'status' })} valueField="statusReuniao" default />
        <ListColumn headerText={capitalizeFirstLetter(resources.responsaveis)} valueField="responsaveis" default />
        <ListColumn headerText={intl.formatMessage({ id: 'label.participantes' })} valueField="participantes" default />
        <ListColumn headerText={capitalizeFirstLetter(resources.acoes)} valueField="acoes" default />
        <ListColumn headerText={intl.formatMessage({ id: 'local' })} valueField="localReuniao" />
        <ListColumn headerText={intl.formatMessage({ id: 'codigo' })} valueField="idIntegracao" sortField="UltimaVersao.IdIntegracao" />
        <ListColumn headerText={intl.formatMessage({ id: 'recorrente' })} valueField="utilizaRecorrencia" />
        <ListColumn headerText={intl.formatMessage({ id: 'pauta' })} valueField="pauta" />
        <ListColumn headerText={intl.formatMessage({ id: 'label.ata' })} valueField="ata" />
        <ListColumn headerText={capitalizeFirstLetter(resources.area)} valueField="area" />
        <ListColumn headerText={capitalizeFirstLetter(resources.tags)} valueField="tags" valueFunction={this.renderTags}/>
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloAtual: state.user.moduloAtual,
    user: state.user,
    lang: state.user && state.user.idioma,
    viewType: state.viewType,
  };
}

export default injectIntl(connect(mapStateToProps)(Reuniao));
