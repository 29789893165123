import { Schema } from '../../utils/form';
import SchemaItem from '../item/schema';
import { FormattedMessage } from 'react-intl';

export const schemaObj = {
  ...SchemaItem,
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required({
      onlyIf: (context) => {
        return !context.parent.value.utilizarCodigoAutomatico;
      },
    }),
  permitirAlterarCodigo: Schema.string().label(
    <FormattedMessage id="label.permitirAlterarCodigoAutomatico" />
  ),
  area: Schema.string()
    .label(<FormattedMessage id="area" />)
    .required(),
  impactosIndiretos: Schema.array().label(
    <FormattedMessage id="label.itensDeImpactoIndireto" />
  ),
  escopo: Schema.string().label(<FormattedMessage id="escopo" />),
  percentualPrevisto: Schema.string().label(),
  percentualExecutado: Schema.string().label(),
  dataInicioAcao: Schema.string(),
  dataTerminoAcao: Schema.string(),
  dataInicioRealizadoAcao: Schema.string(),
  dataTerminoRealizadoAcao: Schema.string(),
  investimentoPrevisto: Schema.string(),
  investimentoRealizado: Schema.string(),
  bloquearInvestimentoPrevisto: Schema.string(),
  bloquearInvestimentoRealizado: Schema.string(),
  bloquearCusteioPrevisto: Schema.string(),
  bloquearCusteioRealizado: Schema.string(),
  bloquearInsercaoManualPlanoAcao: Schema.string()
    .label('Inserção manual de plano de ação')
    .resource('projeto.bloquearInsercaoManualPlanoAcao'),
  farolInvestimento: Schema.string()
    .label('Farol investimento')
    .resource('projeto.farolInvestimento'),
  ultimoFarol: Schema.string().label('Ultimo farol'),
  custeioPrevisto: Schema.string().label(),
  custeioRealizado: Schema.string().label(),
  fluxoAprovacao: Schema.string()
    .label()
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.configuracaoGeral?.utilizarPatrocinador &&
          context.parent.value.aprovarCadastro
        );
      },
    }),
  tabelaValor: Schema.string()
    .label(<FormattedMessage id="tabelaValores" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.usarTabelaValores == true;
      },
    }),
};

export default Schema.object({
  ...schemaObj,
});
