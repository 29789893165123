import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import Input from '../../../componentes/input';
import { Form, Schema } from '../../../utils/form';
import Button from '../../../componentes/button';
import Dialog from '../../../componentes/dialog';
import DatePicker from '../../../componentes/date-picker';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

let schema = Schema.object({
  descricao: Schema.array()
    .label(<FormattedMessage id="descricao" />)
    .required(),
});

@autobind
class FeriadoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      isLoading: false,
      showModal: true,
      feriado: this.props.item,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  handleClose() {
    this.refs.dialog.close();
  }

  handleSalvarClick() {
    if (this.state.feriado.descricao) {
      this.props.handleAtualizarFeriado && this.props.handleAtualizarFeriado(this.state.feriado, false);
    }

    this.handleClose();
  }

  handleApagarClick() {
    if (this.state.feriado.descricao) {
      this.props.handleAtualizarFeriado && this.props.handleAtualizarFeriado(this.state.feriado, true);
    }

    this.handleClose();
  }

  handleChange(item) {
    let feriado = this.props.item;
    feriado.descricao = item;

    this.setState({ feriado: feriado });
  }

  render() {
    let { item } = this.props;

    let actions = [
      <Button key={1} leftIcon="delete-outline" onClick={this.handleApagarClick}>
        {<FormattedMessage id="label.apagar" />}
      </Button>,
      <Button key={2} bsStyle="primary" style={{ marginLeft: 10 }} leftIcon="content-save-outline" onClick={this.handleSalvarClick}>
        {<FormattedMessage id="label.salvar" />}
      </Button>,
    ];

    return (
      <Dialog
        title={<FormattedMessage id="label.feriados" />}
        actions={actions}
        width={600}
        height={350}
        padContent
        onRequestClose={this.handleClose}
        mouseDownClose={false}
        ref="dialog"
        {...this.props}
      >
        <Row>
          <Col md={12}>
            <DatePicker model={{ value: item.data, label: <FormattedMessage id="data" /> }} disabled />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input maxlength="200" model={{ value: item.descricao, label: <FormattedMessage id="descricao" />, requestChange: this.handleChange.bind(this) }} />
          </Col>
        </Row>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(FeriadoModal);
