import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import http from 'src/services/httpService';
import { Form, Schema } from 'src/utils/form';
import errorHandler from 'src/utils/error-handler';
import GrupoEmailLookup from 'src/lookups/grupo-email';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import { capitalizeFirstLetter } from 'src/utils/string';
import Radio from 'src/componentes/radio';

import Button from '../button';
import Dialog from '../dialog';
import LoadingContainer from '../loading-container';
import Input from '../input';
import ItemMulti from '../item-multi-lookup';
import ContentManager from '../content-manager';
import Alert from '../message-box/alert';

let schema = Schema.object({
  email: Schema.string().label(<FormattedMessage id="email" />),
  grupoEmail: Schema.string().label(<FormattedMessage id="grupos" />),
  colaborador: Schema.string().label(),
  assunto: Schema.string().label(<FormattedMessage id="assunto" />),
  conteudo: Schema.string().label(<FormattedMessage id="label.conteudo" />),
  emails: Schema.string(),
  pageOrientation: Schema.string().label(
    <FormattedMessage id="label.orientacao" />
  ),
});

// Função para validar e-mail
// Retorna true : e-mail válido
// Retorna false: e-mail inválido
function ValidarEmail(email) {
  let exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
  let check = /@[\w\-]+\./;
  let checkend = /\.[a-zA-Z]{2,3}$/;
  if (
    email.search(exclude) !== -1 ||
    email.search(check) === -1 ||
    email.search(checkend) === -1
  ) {
    return false;
  } else {
    return true;
  }
}

@autobind
class Email extends React.Component {
  static defaultProps = {
    formatoArquivos: false,
    emails: [],
    showSubject: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showModal: true,
      emails: [],
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    if (this.props.formatoArquivos) {
      let model = this.form.getModel();
      model.getModel('pageOrientation').requestChange({ id: 1 });
    }

    if (this.props.emails && this.props.emails.length > 0) {
      this.setState({
        emails: this.props.emails,
      });
    }
  }

  handleClose() {
    this.refs.dialog.close();
  }

  handleEnviarClick() {
    if (this.state.emails && this.state.emails.length === 0) {
      ContentManager.addContent(
        <Alert
          title={<FormattedMessage id="alerta" />}
          message={<FormattedMessage id="label.nenhumEmailInformado" />}
        />
      );
      return;
    }

    this.setState({
      isLoading: true,
    });

    if (this.props.urlExport) {
      http
        .post(this.props.urlExport, {
          id: this.props.item && this.props.item.id,
          extensaoExport: this.props.extensaoExport,
          addressees: this.state.emails
            .filter(
              (email) =>
                email.colaboradorEmail !== null &&
                email.colaboradorEmail.trim() !== ''
            )
            .map((email) => email.colaboradorEmail)
            .join(','),
          content: this.form.getModel('conteudo').value,
          htmlString: this.props.htmlString && this.props.htmlString,
          subject: this.form.getModel('assunto').value,
          width: this.props.widthExport,
          height: this.props.heightExport,
        })
        .then((response) => {
          this.setState({
            isLoading: false,
          });

          ContentManager.addContent(
            <Alert
              title={<FormattedMessage id="email" />}
              message={response.data && (response.data.mensagem ? response.data.mensagem : response.data)}
            />
          );

          this.handleClose();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });

          errorHandler(error);
        });
    } else {
      http
        .post(`${this.props.url}/Email`, {
          model: this.props.model,
          sort: this.props.sort,
          pageSize: 999999,
          page: 0,
          parameters: this.props.parameters,
          addressees: this.state.emails
            .filter(
              (email) =>
                email.colaboradorEmail !== null &&
                email.colaboradorEmail.trim() !== ''
            )
            .map((email) => email.colaboradorEmail)
            .join(','),
          content: this.form.getModel('conteudo').value,
          subject: this.form.getModel('assunto').value,
        })
        .then((response) => {
          this.setState({
            isLoading: false,
          });

          ContentManager.addContent(
            <Alert
              title={<FormattedMessage id="email" />}
              message={response.data}
            />
          );

          this.handleClose();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });

          errorHandler(error);
        });
    }
  }

  handleLookupChange(item) {
    let emails = this.state.emails;

    const emailsGrupo =
      item.emails && item.emails.length > 0
        ? item.emails.filter((email) => email.trim() !== '')
        : null;

    if (emailsGrupo) {
      emailsGrupo.map((email) => {
        let index = this.state.emails.findIndex(
          (e) => e.colaboradorEmail === email
        );
        if (index === -1) {
          emails.push({
            colaboradorEmail: email,
          });
        }

        return null;
      });
    }
    this.setState({ emails });
  }

  handleColaboradorLookupChange(colaboradores) {
    let emails = this.state.emails;

    if (colaboradores && colaboradores.length > 0) {
      colaboradores.map((colaborador) => {
        let index = this.state.emails.findIndex(
          (e) => e.colaboradorEmail === colaborador.email
        );
        if (index === -1) {
          emails.push({
            colaboradorEmail: colaborador.email,
          });
        }
        return null;
      });
    }
    this.setState({ emails });
  }

  handleEmailTextChange() {
    let emails = this.state.emails;

    if (this.form.getModel('email').value != null) {
      let index = this.state.emails.findIndex(
        (e) => e.colaboradorEmail === this.form.getModel('email').value
      );

      if (index === -1) {
        //Valida email
        if (ValidarEmail(this.form.getModel('email').value)) {
          emails.push({
            colaboradorEmail: this.form.getModel('email').value,
          });
        }
      }
    }
    this.setState({ emails });
    this.form.getModel('email').requestChange(null);
  }

  handleRemove(index) {
    let emails = this.state.emails;
    emails.splice(index, 1);
    this.setState({ emails });
  }

  render() {
    let { resources, formatoArquivos, intl, showSubject, urlExport } =
      this.props;

    const optionsPageOrientation = [
      { id: 1, descricao: intl.formatMessage({ id: 'label.retrato' }) },
      { id: 2, descricao: intl.formatMessage({ id: 'label.paisagem' }) },
    ];

    let actions = [
      <Button key={1} onClick={this.handleClose} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>,
      <Button
        key={2}
        bsStyle="primary"
        className="ml-2"
        onClick={this.handleEnviarClick}
      >
        {<FormattedMessage id="label.enviar" />}
      </Button>,
    ];

    return (
      <Dialog
        title={<FormattedMessage id="label.emailDestinatarios" />}
        actions={actions}
        width={700}
        minHeight={500}
        padContent
        onRequestClose={this.handleClose}
        mouseDownClose={false}
        ref="dialog"
        {...this.props}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <Row>
            <Col md={10}>
              <Input model={this.form.getModel('email')} />
            </Col>
            <Col md={2}>
              <Button
                style={{ marginTop: 25 }}
                bsStyle="primary"
                className="ml-2"
                onClick={this.handleEmailTextChange}
              >
                {<FormattedMessage id="label.inserir" />}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={formatoArquivos ? 4 : 6}>
              <GrupoEmailLookup
                model={{
                  label: this.form.getModel('grupoEmail').label,
                  value: this.form.getModel('grupoEmail').value,
                  requestChange: this.handleLookupChange,
                }}
              />
            </Col>
            <Col md={formatoArquivos ? 4 : 6}>
              <ColaboradorSimplificadoLookup
                multi
                model={{
                  label: capitalizeFirstLetter(resources.colaborador),
                  value: this.form.getModel('colaborador').value,
                  requestChange: this.handleColaboradorLookupChange,
                }}
                data={{ somenteComEmail: true }}
              />
            </Col>
            {formatoArquivos && (
              <Col md={4}>
                <Radio
                  model={this.form.getModel('pageOrientation')}
                  options={optionsPageOrientation}
                  labelField="descricao"
                  idField="id"
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <label
                style={{ display: 'inline-block', marginBottom: '0.5rem' }}
              >
                <FormattedMessage id="label.destinatarios" />
              </label>
              <div
                style={{
                  overflowX: 'auto',
                  height: 150,
                  border: '1px solid #dde6e9',
                  borderRadius: '0.25rem',
                  marginBottom: '0.5rem',
                }}
              >
                {this.state.emails &&
                  this.state.emails.length > 0 &&
                  this.state.emails.map((email, index) => (
                    <ItemMulti
                      fontSize={12}
                      label={email.colaboradorEmail}
                      onRemove={this.handleRemove.bind(this, index)}
                      multi
                    />
                  ))}
              </div>
            </Col>
          </Row>
          {(!urlExport || showSubject) && (
            <Row>
              <Col md={12}>
                <Input
                  rows={3}
                  type="textarea"
                  model={this.form.getModel('assunto')}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <Input
                rows={6}
                type="textarea"
                model={this.form.getModel('conteudo')}
              />
            </Col>
          </Row>
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Email));
