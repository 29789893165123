import { useEffect, useMemo, useState } from 'react';
import { Badge, UncontrolledPopover, PopoverHeader } from 'reactstrap';
import { LinearProgress } from '@material-ui/core';

import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';
import errorHandler from 'src/utils/error-handler';
import http from 'src/services/httpService';
import { TIPO_ITENS } from 'src/utils/constants';
import Dropdown from 'src/paginas/item/dropdown';
import Farol from 'src/paginas/farol';
import { renderValorAcumuladoNaoDisponivel } from 'src/paginas/item/valor/utils/render-campos';
import Grafico from 'src/paginas/item/valor/componentes/grafico';
import TabelaMatrizRisco from 'src/paginas/gestao-risco/matriz-risco/matriz-farol/tabela';
import TabelaMatrizRiscoComScore from 'src/paginas/gestao-risco/matriz-risco/matriz-farol/tabela_com_score';
import Avatar from 'src/componentes/avatar';
import ProgressBarOKR from 'src/componentes/NewProgressBar';
import InputComponent from 'src/componentes/input';
import Slider from 'src/componentes/FakeSlider';
import ProgressBar from 'src/componentes/progress-bar';
import guid from 'src/utils/guid';
import formatarData from 'src/utils/formatarData';
import useRelacionamentoItens from 'src/hooks/Score/useRelacionamentoItens';
import ReactTooltip from 'react-tooltip';

const Card = ({
  itemArvore,
  filtro,
  model,
  url,
  ocultarItensSemValores,
  handleCollapse,
  allConfigurations = [],
  allRelacionamentoConfigurations = [],
  desdobramentoId,
  isConsultaExterna,
  mode,
  ocultarCaixa,
  updatecollapseAll,
  updateShowAll,
  handleCloseModal = () => {},
  ...rest
}) => {
  const intl = useIntl();

  const {
    expandedChildItems: expandedChildItemsFromContext,
    updateChildItems: updateChildItemsFromContext,
  } = useRelacionamentoItens();

  const { user, terms: resources } = useAuth();

  const [componentKey] = useState(guid());

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [item, setItem] = useState({});
  const [chart, setChart] = useState(null);

  const [showContent, setShowContent] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [showChildren, setShowChildren] = useState(itemArvore?.expandido);

  const getData = async () => {
    setIsLoading(true);

    let data = itemArvore?.item ? { item: itemArvore.item } : null;

    if (!data || !data?.item) {
      try {
        const response = await http.post(`/${url}/ObterItemRelacionamento`, {
          itemId: itemArvore?.id,
          desdobramentoId: itemArvore?.desdobramentoId,
          ocultarItensSemValores: ocultarItensSemValores,
          formulaId: itemArvore?.formulaId,
          ehFormula: itemArvore?.ehFormula,
          filtro: filtro,
          painelId: model && model.value[0]?.painelId,
          dataInicio: itemArvore?.dataInicio,
          dataTermino: itemArvore?.dataTermino,
        });

        data = response.data;
      } catch (err) {
        errorHandler(err);
      }
    }

    if (!data) ocultarCaixa && ocultarCaixa();

    setItem(data?.item || {});
    setIsLoading(false);
  };

  const getChart = async () => {
    if (isKR) {
      setChart(kr?.graficoChaveResultado || {});
      return;
    }

    if (
      chart ||
      (!isIndicador && !isParametro && !isProjeto && !isObjetivoEstrategico)
    )
      return;

    setIsLoadingChart(true);

    try {
      const response = await http.post(`/Item/ObterGraficoItemRelacionamento`, {
        itemId: id,
        filtro: filtro,
      });

      setChart(response.data?.grafico || {});
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoadingChart(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setShowContent(false);
  }, [updatecollapseAll]);

  useEffect(() => {
    setShowContent(true);
  }, [updateShowAll]);

  const {
    id,
    area,
    nome,
    tipo,
    responsaveis,
    ehObjetivoOKR,
    ehKR,
    revisaoPontoControle,
    ehFormula,
    possuiFilhos,
    exibirSomenteFarol,
    formatoUnidadeMedida,
    itemValor,
    kr,
    cronograma,
    percentualPrevisto,
    percentualExecutado,
    objetivoOKR,
    grau,
    avaliacaoPendente,
    avaliacao,
    piorFarolPontoControle,
    pontosControle,
    exibirSiglaUnidadeMedidaNaDireita,
    siglaUnidadeMedida,
    exibirGrafico,
    matrizFarol,
    polaridade,
    matrizRiscoId,
    itemVinculado,
    tags,
    dataRevisao,
    status,
    executor,
    temValorPrevio,
  } = item || {};

  const configurations = useMemo(() => {
    return (allConfigurations?.find((x) => x.type === tipo?.id) || {})?.data;
  }, [tipo]);

  const relacionamentoConfigurations = useMemo(() => {
    return (
      allRelacionamentoConfigurations?.find(
        (x) => x.tipoItem?.id === tipo?.id
      ) || null
    );
  }, [tipo]);

  const getConfigurationProp = (field) => {
    if (!relacionamentoConfigurations) return true;
    return relacionamentoConfigurations[field];
  };

  const isIndicador = tipo?.id === TIPO_ITENS.INDICADOR;
  const isParametro = tipo?.id === TIPO_ITENS.PARAMETRO;
  const isObjetivoEstrategico = tipo?.id === TIPO_ITENS.OBJETIVO_ESTRATEGICO;
  const isProjeto = tipo?.id === TIPO_ITENS.PROJETO;
  const isProcesso = tipo?.id === TIPO_ITENS.PROCESSO;
  const isRisco = tipo?.id === TIPO_ITENS.RISCO;
  const isObjetivoOKR = ehObjetivoOKR;
  const isKR = ehKR;

  const showActions = isObjetivoEstrategico
    ? configurations?.permitirCadastrarAcoes
    : true;
  const externalView = mode === 'viewExterno';

  const handleToggleContent = () => {
    setShowContent((prev) => !prev);
  };

  const handleToggleChart = async () => {
    setShowChart((prev) => !prev);
    await getChart();
  };

  const handleToggleChildren = () => {
    let newListContext = [...expandedChildItemsFromContext];

    if (itemArvore.expandido) {
      newListContext = expandedChildItemsFromContext?.filter(
        (item) => item.id !== itemArvore.id
      );
    } else {
      const index = newListContext.findIndex((x) => x.id === itemArvore.id);
      let item = newListContext[index];

      if (item) {
        item.desdobramentoId =
          itemArvore.desdobramentoId != null && itemArvore.desdobramentoId != 0
            ? itemArvore.desdobramentoId
            : null;
      } else {
        const newItem = {
          id: itemArvore.id,
          desdobramentoId:
            itemArvore.desdobramentoId != null &&
            itemArvore.desdobramentoId != 0
              ? itemArvore.desdobramentoId
              : null,
        };

        newListContext.push(newItem);
      }
    }

    updateChildItemsFromContext(newListContext);

    handleCollapse && handleCollapse(itemArvore);
    setShowChildren((prev) => !prev);
  };

  const renderArea = () => {
    const showArea = getConfigurationProp('exibirArea');
    if (!showArea || revisaoPontoControle || isObjetivoOKR || isKR) return '';

    const targetAreaId = `AREA-${id}`;
    return (
      <>
        <div id={targetAreaId} className="d-inline-flex ml-2">
          <div
            className="font-weight-bolder text-truncate"
            style={{ maxWidth: 230 }}
          >
            {area}
          </div>
          {!exibirSiglaUnidadeMedidaNaDireita &&
            tipo &&
            !isProjeto &&
            !isProcesso &&
            !isRisco && (
              <div className="text-muted font-weight-bolder ml-1">
                ({item?.siglaUnidadeMedida})
              </div>
            )}
        </div>
        <UncontrolledPopover
          trigger="hover"
          target={targetAreaId}
          placement="right"
        >
          <div className="p-2">
            <span>{area}</span>
            {!exibirSiglaUnidadeMedidaNaDireita &&
              tipo &&
              !isProjeto &&
              !isProcesso &&
              !isRisco && (
                <span className="text-muted font-weight-bolder ml-1">
                  ({item?.siglaUnidadeMedida})
                </span>
              )}
          </div>
        </UncontrolledPopover>
      </>
    );
  };

  const renderCustomContent = () => {
    const renderProjeto = () => {
      const showActions = getConfigurationProp('exibirCronograma');
      const showPercentage = !configurations
        ? true
        : configurations?.utilizarPercentualExecucao &&
          (getConfigurationProp('exibirPercentualPrevisto') ||
            getConfigurationProp('exibirPercentualRealizado'));

      const hasActions = cronograma?.length > 0;

      return (
        <div className="p-2 border-bottom overflow-auto new-list-tree">
          {showActions && hasActions && (
            <div className="progress" style={{ borderRadius: 6, width: 230 }}>
              {cronograma?.map((acao, index) => (
                <ProgressBar
                  key={index}
                  color={acao?.farolAcao?.cor}
                  value={acao?.percentual}
                />
              ))}
            </div>
          )}
          {showPercentage && (
            <div className="d-flex align-items-center mt-2">
              {getConfigurationProp('exibirPercentualPrevisto') && (
                <>
                  <div className="mr-1">
                    {capitalizeFirstLetter(resources.percprevisto)}:
                  </div>
                  <div className="mr-2 font-weight-bolder">
                    {percentualPrevisto}
                  </div>
                </>
              )}
              {getConfigurationProp('exibirPercentualRealizado') && (
                <>
                  <div className="mr-1">
                    {capitalizeFirstLetter(resources.percrealizado)}:
                  </div>
                  <div className="font-weight-bolder">
                    {percentualExecutado}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      );
    };

    const renderObjetivoOKR = () => {
      const objetivo = objetivoOKR;

      let bgColor = 'info';

      if (objetivo?.nivel?.id == 1) bgColor = 'info';
      else if (objetivo?.nivel?.id == 2) bgColor = 'success';
      else if (objetivo?.nivel?.id == 3) bgColor = 'warning';

      return (
        <div className="p-2 border-bottom overflow-auto new-list-tree">
          <div className="d-flex align-items-center">
            <div className="d-inline-flex mr-2" style={{ width: 230 }}>
              <ProgressBarOKR
                itens={[
                  {
                    percentual: objetivoOKR?.percentual,
                    cor: objetivoOKR?.corFarol,
                  },
                ]}
                containerHeight={15}
                hidePercent
                hidePercentValue={20}
                showBorder={false}
                bgColor="#EEE"
              />
            </div>
            <div className="d-inline-flex ">
              <InputComponent
                disabled
                className="mx-2  text-center"
                style={{ width: 70 }}
                formStyle={{ margin: 0 }}
                model={{
                  value: objetivoOKR?.percentual + '%',
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="d-inline-flex mr-2">
              <Badge
                className="text-white"
                style={{ fontSize: 13, height: 21 }}
                color={bgColor}
              >
                {objetivo?.nivel?.descricao}
              </Badge>
            </div>
            {objetivo?.responsavel && (
              <div className="d-inline-flex ">
                <Avatar
                  size={32}
                  foto={objetivo?.responsavel?.fotoArquivo}
                  label={objetivo?.responsavel?.nome}
                  showLabel={true}
                />
              </div>
            )}
          </div>
        </div>
      );
    };

    const renderKR = () => {
      return (
        <div className="p-2 pt-1 border-bottom overflow-auto new-list-tree">
          <div className="d-flex align-items-center">
            <div className="d-inline-flex mr-2" style={{ width: 230 }}>
              <Slider
                inlineScaleValues
                scaleFirstValue={kr?.valorInicial}
                scaleLastValue={kr?.valorFinal}
                percent={kr?.percentual || 0}
                value={kr?.valorCorrente}
                color={kr?.corFarol || '#218721'}
                containerHeight={15}
                hidePercent
                hidePercentValue={20}
                showBorder={false}
                bgColor="#EEE"
                markers={kr?.marcadores}
              />
            </div>
            <div className="d-inline-flex" style={{ marginTop: 27 }}>
              <InputComponent
                disabled
                className="mx-2  text-center"
                style={{ width: 70 }}
                formStyle={{ margin: 0 }}
                model={{
                  value: kr?.percentual && kr?.percentual + '%',
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mt-3">
            <div className="d-inline-flex mr-3">
              {kr?.responsavelLookup && (
                <Avatar
                  size={32}
                  foto={kr?.responsavelLookup?.fotoArquivo}
                  label={kr?.responsavelLookup?.nome}
                  showLabel
                />
              )}
            </div>
            <div className="d-inline-flex align-items-center text-primary text-sm">
              <span className="text-primary font-weight-bolder">
                {capitalizeFirstLetter(intl.formatMessage({ id: 'confianca' }))}
              </span>
              <MdiIcon
                icon="checkbox-blank"
                size={20}
                className="ml-1"
                color={kr?.grauConfianca?.cor ?? '#BCBCBC'}
                colorHover={kr?.grauConfianca?.cor ?? '#BCBCBC'}
              />
            </div>
          </div>
        </div>
      );
    };

    const renderRisco = () => {
      const showGrau = getConfigurationProp('exibirGrau');
      const showCronograma = getConfigurationProp('exibirCronograma');
      const showControle = getConfigurationProp('exibirPontoControle');

      const targetGrauId = `GRAU-${id}-${componentKey}`;
      const targetPCId = `PC-${id}-${componentKey}`;

      return (
        <div className="p-2 border-bottom overflow-auto new-list-tree">
          <table className="w-100">
            <thead>
              <tr>
                {showGrau && (
                  <td>
                    <div>{intl.formatMessage({ id: 'grau' })}</div>
                  </td>
                )}
                {showCronograma && (
                  <td>
                    <div className="text-center">
                      {capitalizeFirstLetter(resources.planodeacao)}
                    </div>
                  </td>
                )}
                {showControle && (
                  <td>
                    <div className="text-center">
                      {capitalizeFirstLetter(resources.pontodecontrole)}
                    </div>
                  </td>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {showGrau && (
                  <td>
                    {grau && (
                      <div>
                        <MdiIcon
                          id={targetGrauId}
                          color={grau?.cor}
                          colorHover={grau?.cor}
                          icon="circle"
                          size={20}
                        />
                        {avaliacaoPendente && (
                          <strong
                            style={{ color: grau?.cor }}
                            color={grau?.cor}
                          >
                            !
                          </strong>
                        )}
                        {avaliacao && (
                          <UncontrolledPopover
                            trigger="hover"
                            placement="auto"
                            target={targetGrauId}
                          >
                            <PopoverHeader>
                              {capitalizeFirstLetter(
                                intl.formatMessage(
                                  { id: 'grauRisco' },
                                  { risco: resources.risco }
                                )
                              )}
                              : <span>{grau?.descricao}</span>
                            </PopoverHeader>
                            <div className="p-2">
                              <div>
                                <span className="font-weight-bolder">
                                  {intl.formatMessage(
                                    { id: 'label.dataAvaliacao' },
                                    { avaliacao: resources.avaliacao }
                                  )}
                                  :{' '}
                                </span>
                                {avaliacao?.dataAvaliacao && (
                                  <span>
                                    {formatarData(
                                      user.idioma,
                                      avaliacao?.dataAvaliacao
                                    )}
                                  </span>
                                )}
                              </div>
                              <div>
                                <span className="font-weight-bolder">
                                  {resources.probabilidade} :{' '}
                                </span>
                                <span>{avaliacao?.probabilidade}</span>
                              </div>
                              <div>
                                <span className="font-weight-bolder">
                                  {intl.formatMessage(
                                    { id: 'label.severidade' },
                                    { severidade: resources.severidade }
                                  )}
                                  :{' '}
                                </span>
                                <span>{avaliacao?.severidade}</span>
                              </div>
                              {avaliacao?.tratamento && (
                                <div>
                                  <strong>
                                    {intl.formatMessage({ id: 'tratamento' })}:{' '}
                                  </strong>
                                  <span>{avaliacao?.tratamento}</span>
                                </div>
                              )}
                            </div>
                          </UncontrolledPopover>
                        )}
                      </div>
                    )}
                  </td>
                )}
                {showCronograma && (
                  <td>
                    <div className="progress" style={{ borderRadius: 6 }}>
                      {cronograma?.map((acao, index) => (
                        <ProgressBar
                          key={index}
                          color={acao?.farolAcao?.cor}
                          value={acao?.percentual}
                        />
                      ))}
                    </div>
                  </td>
                )}
                {showControle && (
                  <td>
                    <div className="text-center">
                      {piorFarolPontoControle && (
                        <>
                          <MdiIcon
                            id={targetPCId}
                            className="cursor-pointer"
                            icon={piorFarolPontoControle?.icone}
                            color={piorFarolPontoControle?.cor}
                            colorHover={piorFarolPontoControle?.cor}
                            size={22}
                          />
                          <UncontrolledPopover
                            trigger="hover"
                            placement="auto"
                            target={targetPCId}
                          >
                            <div className="p-2">
                              {pontosControle?.map((p) => (
                                <div className="flex justify-content-between align-items-center">
                                  <span className="mr-2">{p?.descricao}</span>
                                  <MdiIcon
                                    icon="triangle"
                                    color={p?.farol?.cor}
                                    colorHover={p?.farol?.cor}
                                    size={18}
                                  />
                                </div>
                              ))}
                            </div>
                          </UncontrolledPopover>
                        </>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      );
    };

    const renderRevisaoPontoControle = () => {
      if (!revisaoPontoControle) return '';
      return (
        <div className="p-2">
          <div className="d-flex">
            <div className="mr-1">
              {intl.formatMessage(
                { id: 'label.dataRevisao' },
                {
                  pontodecontrole: resources.pontodecontrole,
                }
              )}
              :
            </div>
            <div className="mr-2 font-weight-bolder">
              {dataRevisao && formatarData(user.idioma, dataRevisao)}
            </div>
          </div>
          <div className="d-flex">
            <div className="mr-1">
              {intl.formatMessage({ id: 'label.executor' })}:
            </div>
            <div className="mr-2 font-weight-bolder">{executor}</div>
          </div>
          <div className="d-flex">
            <div className="mr-1">{intl.formatMessage({ id: 'status' })}:</div>
            <div className="mr-2 font-weight-bolder">{status}</div>
          </div>
        </div>
      );
    };

    const renderOtherItems = () => {
      const renderRealizado = (isAcumulado = false) => {
        const farol = itemValor?.farol;
        const farolAcumulado = itemValor?.farolAcumulado;

        const getColorFromFarol = (farolParams) => {
          if (
            !farolParams ||
            !farolParams.id ||
            farolParams.id == 4 ||
            farolParams.id == 3
          ) {
            return '#000000';
          }

          return '#FFFFFF';
        };

        let value = '';

        if (isAcumulado) {
          value =
            formatoUnidadeMedida?.id === 1
              ? itemValor?.realizadoAcumulado
              : itemValor?.realizadoAcumuladoHoraMinutos;
        } else {
          value =
            formatoUnidadeMedida?.id === 1
              ? itemValor?.realizadoFormatado
              : itemValor?.realizadoHoraMinutos;
        }

        const content = (
          <>
            <span>{value}</span>
            {exibirSiglaUnidadeMedidaNaDireita && (
              <span className="ml-1"> {siglaUnidadeMedida}</span>
            )}
          </>
        );

        if (
          getConfigurationProp('exibirCorFarolRealizadoERealizadoAcumulado')
        ) {
          return (
            <div
              className="d-flex justify-content-center py-1 px-2 rounded-lg w-100"
              style={{
                color: getColorFromFarol(isAcumulado ? farolAcumulado : farol),
                backgroundColor: isAcumulado ? farolAcumulado?.cor : farol?.cor,
              }}
            >
              {content}
            </div>
          );
        }

        return content;
      };

      const showMeta = getConfigurationProp('exibirMetaEMetaAcumulado');
      const showRealizado = getConfigurationProp(
        'exibirRealizadoERealizadoAcumulado'
      );
      const showDesvio = getConfigurationProp('exibirDesvioEDesvioAcumulado');
      const showPercentualDesvio = getConfigurationProp(
        'exibirPercentualDesvioEPercentualDesvioAcumulado'
      );
      const showDesempenho = getConfigurationProp(
        'exibirDesempenhoEDesempenhoAcumulado'
      );
      const showFarol = getConfigurationProp('exibirFarois');

      return (
        <div className="p-2 border-bottom overflow-auto new-list-tree">
          <table className="w-100">
            <thead>
              <tr>
                <td
                  className="position-sticky bg-white"
                  style={{ width: 80, maxWidth: 80, minWidth: 80, left: 0 }}
                ></td>
                {showMeta && !isParametro && !exibirSomenteFarol && (
                  <td className="px-2 text-center" style={{ minWidth: 60 }}>
                    {capitalizeFirstLetter(resources.meta)}
                  </td>
                )}
                {showFarol && !isProjeto && !isProcesso && !isRisco && (
                  <td style={{ minWidth: 40 }}></td>
                )}
                {showRealizado && !exibirSomenteFarol && (
                  <td className="px-2 text-center">
                    {intl.formatMessage({ id: 'realizado' })}
                  </td>
                )}
                {(isIndicador || isObjetivoEstrategico) && !exibirSomenteFarol && (
                  <>
                    {showDesvio && (
                      <td className="px-2 text-center">
                        {intl.formatMessage({ id: 'desvio' })}
                      </td>
                    )}
                    {showPercentualDesvio && (
                      <td style={{ minWidth: 65 }} className="px-2 text-center">
                        {intl.formatMessage({
                          id: 'percentualDoDesvioAbreviado',
                        })}
                      </td>
                    )}
                    {showDesempenho && (
                      <td className="px-2 text-center">
                        {capitalizeFirstLetter(resources.desempenho)}
                      </td>
                    )}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {/* ÚLTIMO */}
              <tr>
                <td
                  className="font-weight-bolder position-sticky bg-white"
                  style={{ width: 80, maxWidth: 80, minWidth: 80, left: 0 }}
                >
                  {intl.formatMessage({ id: 'ultimo' })}
                </td>
                {showMeta && !isParametro && !exibirSomenteFarol && (
                  <td className="px-2 text-center" style={{ minWidth: 60 }}>
                    {item?.itemValor?.meta && (
                      <div className="d-inline-flex font-weight-bolder">
                        <div>
                          {formatoUnidadeMedida?.id === 1
                            ? itemValor?.metaFormatado
                            : itemValor?.metaHoraMinutos}
                        </div>
                        {exibirSiglaUnidadeMedidaNaDireita &&
                          tipo &&
                          !isProjeto &&
                          !isProcesso &&
                          !isRisco && (
                            <div className="ml-1">{siglaUnidadeMedida}</div>
                          )}
                      </div>
                    )}
                  </td>
                )}
                {showFarol && !isProjeto && !isProcesso && !isRisco && (
                  <td className="px-2 text-center" style={{ minWidth: 40 }}>
                    {itemValor?.farol && (
                      <div className="text-center">
                        <Farol itemValor={itemValor} ehRelacionamento />
                      </div>
                    )}
                  </td>
                )}
                {showRealizado && !exibirSomenteFarol && (
                  <td className="px-2">
                    {itemValor?.realizado != null &&
                      tipo &&
                      !isProjeto &&
                      !isProcesso &&
                      !isRisco && (
                        <div className="d-flex justify-content-center font-weight-bolder">
                          {renderRealizado()}
                        </div>
                      )}
                  </td>
                )}
                {(isIndicador || isObjetivoEstrategico) && !exibirSomenteFarol && (
                  <>
                    {showDesvio && (
                      <td className="px-2 font-weight-bolder text-center">
                        <div>
                          {formatoUnidadeMedida?.id === 1
                            ? itemValor?.desvioFormatado
                            : itemValor?.desvioHoraMinutos}
                        </div>
                      </td>
                    )}
                    {showPercentualDesvio && (
                      <td className="px-2 font-weight-bolder text-center">
                        <div>{itemValor?.percentualDesvio}</div>
                      </td>
                    )}
                    {showDesempenho && (
                      <td className="px-2 font-weight-bolder text-center">
                        <div>{itemValor?.desempenhoFormatado}</div>
                      </td>
                    )}
                  </>
                )}
              </tr>
              {/* ACUMULADO */}
              <tr>
                <td
                  className="font-weight-bolder position-sticky bg-white"
                  style={{ width: 80, maxWidth: 80, minWidth: 80, left: 0 }}
                >
                  {intl.formatMessage({ id: 'acumulado' })}
                </td>
                {showMeta && !isParametro && !exibirSomenteFarol && (
                  <td className="px-2 text-center" style={{ minWidth: 60 }}>
                    {item?.itemValor?.meta != null && (
                      <div className="d-inline-flex font-weight-bolder">
                        <div>
                          {itemValor?.exibirMetaAcumuladoDigitado
                            ? formatoUnidadeMedida?.id === 1
                              ? itemValor?.metaAcumulado
                              : itemValor?.metaAcumuladoHoraMinutos
                            : renderValorAcumuladoNaoDisponivel(
                                'metaAcumulado',
                                itemValor,
                                intl
                              )}
                        </div>
                        {exibirSiglaUnidadeMedidaNaDireita &&
                          tipo &&
                          !isProjeto &&
                          !isProcesso &&
                          !isRisco && (
                            <div className="ml-1">{siglaUnidadeMedida}</div>
                          )}
                      </div>
                    )}
                  </td>
                )}
                {itemValor?.exibirRealizadoAcumuladoDigitado &&
                itemValor?.exibirMetaAcumuladoDigitado
                  ? showFarol &&
                    !isProjeto &&
                    !isProcesso &&
                    !isRisco && (
                      <div className="text-center">
                        <Farol itemValor={itemValor} acumulado />
                      </div>
                    )
                  : showFarol &&
                    renderValorAcumuladoNaoDisponivel(
                      'farolAcumulado',
                      itemValor,
                      intl
                    )}
                {showRealizado && !exibirSomenteFarol && (
                  <td className="px-2">
                    {itemValor?.realizado != null &&
                      tipo &&
                      !isProjeto &&
                      !isProcesso &&
                      !isRisco && (
                        <div className="d-flex justify-content-center font-weight-bolder">
                          {itemValor?.exibirRealizadoAcumuladoDigitado
                            ? renderRealizado(true)
                            : renderValorAcumuladoNaoDisponivel(
                                'realizadoAcumulado',
                                itemValor,
                                intl
                              )}
                        </div>
                      )}
                  </td>
                )}
                {(isIndicador || isObjetivoEstrategico) && !exibirSomenteFarol && (
                  <>
                    {showDesvio && (
                      <td className="px-2 font-weight-bolder text-center">
                        <div>
                          {formatoUnidadeMedida?.id === 1
                            ? itemValor?.desvioAcumulado
                            : itemValor?.desvioAcumuladoHoraMinutos}
                        </div>
                      </td>
                    )}
                    {showPercentualDesvio && (
                      <td className="px-2 font-weight-bolder text-center">
                        <div>
                          {itemValor?.percentualDesvioAcumuladoDecimalFormatado}
                        </div>
                      </td>
                    )}
                    {showDesempenho && (
                      <td className="px-2 font-weight-bolder text-center">
                        <div>{itemValor?.desempenhoAcumulado}</div>
                      </td>
                    )}
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      );
    };

    if (isProjeto) return renderProjeto();
    else if (isRisco) return renderRisco();
    else if (isObjetivoOKR) return renderObjetivoOKR();
    else if (isKR) return renderKR();
    else if (revisaoPontoControle) return renderRevisaoPontoControle();
    else if (tipo != null && !isProjeto && !isProcesso && !isRisco)
      return renderOtherItems();

    return '';
  };

  const renderPeople = () => {
    const targetPeopleId = `PEOPLE-${id}`;
    return (
      <div className="d-inline-flex ml-2">
        <MdiIcon
          id={targetPeopleId}
          className="cursor-pointer"
          size={14}
          icon="account-group"
        />
        <UncontrolledPopover
          trigger="hover"
          target={targetPeopleId}
          placement="right"
        >
          <div className="p-2">{responsaveis}</div>
        </UncontrolledPopover>
      </div>
    );
  };

  const renderChart = () => {
    const showChartByConfiguration = getConfigurationProp('exibirGrafico');
    const showMatriz = getConfigurationProp('exibirMatriz');

    if (
      !showChart ||
      (!showChartByConfiguration && !showMatriz) ||
      exibirSomenteFarol ||
      (!exibirGrafico && !matrizFarol && !isKR)
    )
      return '';

    if (isLoadingChart) {
      return (
        <div className="mt-2">
          <div className="overflow-hidden">
            <LinearProgress />
          </div>
        </div>
      );
    }

    if (isKR) {
      return !kr?.graficoChaveResultado
        ? '-'
        : showChartByConfiguration && (
            <div style={{ width: '95%' }}>
              <Grafico
                height={320}
                grafico={kr?.graficoChaveResultado}
                exibirTitulos={false}
              />
            </div>
          );
    }

    if (chart) {
      return (
        showChartByConfiguration && (
          <div className="d-flex">
            <div className="d-inline-flex mr-2">
              <MdiIcon
                className="cursor-pointer"
                icon={polaridade ? polaridade?.icone : ''}
                size={25}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Grafico height={320} grafico={chart} exibirTitulos={false} />
            </div>
          </div>
        )
      );
    } else if (configurations?.habilitarCalculoDeScores) {
      return (
        showMatriz && (
          <div className="mt-2">
            <TabelaMatrizRiscoComScore
              apenasMaiorQueZero
              informarScore
              matrizFarol={matrizFarol}
              matrizRiscoFarolId={matrizRiscoId}
            />
          </div>
        )
      );
    } else if (matrizFarol && matrizRiscoId) {
      return (
        showMatriz && (
          <div className="mt-2">
            <TabelaMatrizRisco
              apenasMaiorQueZero
              matrizFarol={matrizFarol}
              matrizRiscoFarolId={matrizRiscoId}
            />
          </div>
        )
      );
    }

    return '-';
  };

  const renderName = () => {
    const targetNameId = `NAME-${id}`;
    const renderWithoutLink = ehFormula || ehObjetivoOKR || ehKR;
    return (
      <>
        <div
          id={targetNameId}
          className="d-inline-flex align-items-center text-primary font-weight-bolder"
        >
          {renderWithoutLink ? (
            <div className="text-truncate" style={{ maxWidth: 245 }}>
              {nome}
            </div>
          ) : (
            <div
              className="d-inline-flex"
              style={{ maxWidth: possuiFilhos ? 245 : 290 }}
            >
              {revisaoPontoControle ? (
                <a
                  onClick={() =>
                    history.push(`/gerenciar/revisoes-ponto-controle?id=${id}`)
                  }
                  className="text-truncate d-block"
                  style={{ maxWidth: possuiFilhos ? 245 : 290 }}
                >
                  {nome}
                </a>
              ) : (
                <Dropdown
                  link={false}
                  item={item}
                  desdobramento={{ id: desdobramentoId }}
                  showMenu={!isConsultaExterna && !externalView}
                  renderLabel={() => {
                    return (
                      <a
                        className="overflow-hidden line-clamp-2"
                        style={{ maxWidth: possuiFilhos ? 245 : 290 }}
                      >
                        {nome}
                      </a>
                    );
                  }}
                  exibirPlanoAcao={showActions}
                  handleCloseModal={handleCloseModal}
                />
              )}
            </div>
          )}
        </div>
        <UncontrolledPopover
          trigger="hover"
          target={targetNameId}
          placement="right"
        >
          <div
            className="p-2 font-weight-bolder"
            style={{ maxWidth: '300px', wordWrap: 'break-word' }}
          >
            {nome}
          </div>
        </UncontrolledPopover>
      </>
    );
  };

  const renderActionIcons = () => {
    const tagsLength = tags?.length || 0;
    const targetTagId = `TAG-${id}`;
    const targetValorPrevioId = `VP-${id}`;
    const showTags = tagsLength > 0;

    return (
      <div className="d-inline-flex text-right">
        {temValorPrevio && (
          <div className="mr-2">
            <MdiIcon
              className="mr-1"
              icon="file-document-outline"
              data-tip
              data-for={targetValorPrevioId}
              id={targetValorPrevioId}
            />
            <ReactTooltip
              id={targetValorPrevioId}
              place="left"
              aria-haspopup="true"
            >
              <div
                style={{
                  maxWidth: 200,
                  textAlign: 'center',
                }}
              >
                {resources.valorprevio}
              </div>
            </ReactTooltip>
          </div>
        )}
        {showTags && (
          <div id={targetTagId} className="mr-2">
            <MdiIcon className="mr-1" icon="tag" />
            <Badge style={{ fontSize: 10 }} color="secondary">
              {tagsLength}
            </Badge>
            {tagsLength > 0 && (
              <UncontrolledPopover
                trigger="hover"
                target={targetTagId}
                placement="right"
              >
                <div className="p-2">
                  {tags?.map((t) => (
                    <div className="py-1">
                      <Badge
                        pill
                        className="text-white text-sm"
                        style={{ background: t?.cor }}
                      >
                        {t?.descricao}
                      </Badge>
                    </div>
                  ))}
                </div>
              </UncontrolledPopover>
            )}
          </div>
        )}
        <div
          className={`${itemArvore?.possuiFilhos ? 'border-right pr-2' : ''}`}
        >
          <MdiIcon
            className="cursor-pointer"
            onClick={handleToggleContent}
            icon={!showContent ? 'chevron-down' : 'chevron-up'}
          />
        </div>
        {itemArvore?.possuiFilhos && (
          <div>
            <MdiIcon
              className="cursor-pointer pl-2"
              onClick={handleToggleChildren}
              icon={!showChildren ? 'plus' : 'minus'}
            />
          </div>
        )}
      </div>
    );
  };

  const renderFooter = () => {
    if (
      exibirSomenteFarol ||
      (!exibirGrafico && !matrizFarol && !isKR && !isProcesso)
    )
      return '';

    let label = tipo?.descricao;

    if (isKR) label = resources.keyresult;
    else if (itemVinculado) label = itemVinculado?.nome;
    else if (isProjeto)
      label = intl.formatMessage({
        id: 'curvaS',
      });

    const hideArrow =
      (!getConfigurationProp('exibirGrafico') &&
        !getConfigurationProp('exibirMatriz')) ||
      isProcesso;
    const hidePeople = !getConfigurationProp('exibirResponsavel') || isKR;

    return (
      <div className="p-2">
        <div
          style={{ padding: hideArrow ? 3 : '3px 3px 0px 5px' }}
          className="font-weight-bolder d-inline-flex bg-soft-gray border text-sm"
        >
          <div className={`${!hideArrow ? 'mr-1' : ''}`}>
            {capitalizeFirstLetter(label)}
          </div>
          {!hideArrow && (
            <MdiIcon
              className="cursor-pointer"
              size={14}
              onClick={handleToggleChart}
              icon={!showChart ? 'chevron-down' : 'chevron-up'}
            />
          )}
        </div>
        {!hidePeople && renderPeople()}
        {renderArea()}
        {renderChart()}
      </div>
    );
  };

  const getBorderStyle = () => {
    if (itemArvore?.ehDesdobramento) return 'dashed';
    else if (itemArvore?.ehItensImpactoIndireto) return 'double';
    else return 'solid';
  };

  const renderCard = () => (
    <div
      {...rest}
      className="rounded-lg overflow-hidden"
      style={{
        width: 430,
        minWidth: 430,
        maxWidth: 430,
        borderStyle: getBorderStyle(),
        borderColor: itemArvore?.ehItensImpactoIndireto ? '#5d9cec' : '#dae0e6',
        borderWidth: 1,
      }}
    >
      {isLoading ? (
        <div className="p-2 overflow-hidden">
          <LinearProgress />
        </div>
      ) : (
        item && (
          <>
            <div
              className={`d-flex align-items-center justify-content-between p-2 bg-light-gray rounded-top-lg ${
                showContent ? 'border-bottom' : 'rounded-bottom-lg'
              }`}
            >
              {renderName()}
              {renderActionIcons()}
            </div>
            {showContent && (
              <div className="bg-white rounded-bottom-lg text-sm">
                {renderCustomContent()}
                {renderFooter()}
              </div>
            )}
          </>
        )
      )}
    </div>
  );

  return renderCard();
};

export default Card;
