import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../../componentes/dialog';
import { Schema, Form } from '../../../utils/form';
import http from 'src/services/httpService';
import LoadingContainer from '../../../componentes/loading-container';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import Radio from '../../../componentes/radio';
import ColaboradorSimplificadoLookup from '../../../lookups/ColaboradorSimplificadoLookup';
import Input from '../../../componentes/input';
import UnidadeMedidaLookup from '../../../lookups/unidade-medida';
import PeriodicidadeLookup from '../../../lookups/periodicidade';
import Preview from '../../faixa-farol/preview';
import TabelaValorLookup from '../../../lookups/tabela-valor';
import PreviewTabelaValor from '../../tabela-valor/preview';
import FaixaFarolLookup from '../../../lookups/faixa-farol';
import SvgIcon from '../../../componentes/svg-icon';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';
import MdiIcon from '../../../componentes/mdi-icon';
import DropdownItemList from '../../item/dropdown-item';
import errorHandler from '../../../utils/error-handler';

let schema = Schema.object({
  area: Schema.string().label(<FormattedMessage id="area" />),
  meta: Schema.number().label(
    <FormattedMessage id="label.metaUltimoPeriodo" />
  ),
  realizado: Schema.number().label(
    <FormattedMessage id="label.realizadoUltimoPeriodo" />
  ),
  metaAcumulado: Schema.number().label(),
  realizadoAcumulado: Schema.number().label(
    <FormattedMessage id="realizadoAcumulado" />
  ),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  melhor: Schema.string().label(<FormattedMessage id="label.polaridade" />),
  responsaveis: Schema.array().label(<FormattedMessage id="responsaveis" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  unidadeMedida: Schema.string().label(
    <FormattedMessage id="unidadeDeMedida" />
  ),
  faixa: Schema.string().label(<FormattedMessage id="faixaDeFarol" />),
  tabelaValor: Schema.string().label(<FormattedMessage id="listaRegistros" />),
  farol: Schema.string().label(''),
  farolAcumulado: Schema.string().label(''),
});

@autobind
class Ficha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({
      isLoading: true,
    });
    http
      .post(`/Indicador/ObterDadosFicha`, {
        itemId: this.props.id,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.form.setValue(null, response.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        this.handleClose();
        errorHandler(error);
      });
  }

  handleLabelMelhor(item) {
    return (
      <span>
        <MdiIcon icon={item.icone} size={16}></MdiIcon>
      </span>
    );
  }

  handleClose() {
    this.refs.dialog.close();
    this.props.handleCloseEdit && this.props.handleCloseEdit();
    setTimeout(() => {
      this.props.remove && this.props.remove();
    }, 300);
  }

  handleMenuClick() {
    this.handleClose();
  }

  render() {
    let model = this.form.getModel();
    let { resources, intl, showActions = true } = this.props;

    let actions = (
      <UncontrolledDropdown className="ml-2 d-inline" key="indicador-ficha">
        <DropdownToggle color="secondary">
          <MdiIcon icon="dots-horizontal" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItemList
            exibirFicha={false}
            resources={resources}
            item={{ ...model.getValue(), tipo: { id: 1 } }}
            callback={this.handleMenuClick}
            handleCloseModal={this.props.handleCloseModal}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    return (
      <Dialog
        title={
          model.getModel('nome').value ? (
            model.getModel('nome').value
          ) : (
            <FormattedMessage
              id="label.fichaIndicador"
              values={{
                indicador: resources.indicador,
                ficha: resources.ficha,
              }}
            />
          )
        }
        ref="dialog"
        padContent
        footer={showActions && actions}
        width={900}
        height={450}
        onRequestClose={this.handleClose}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <div>
            <Row>
              <Col md={6}>
                <ColaboradorSimplificadoLookup
                  multi
                  model={{
                    label: capitalizeFirstLetter(resources.responsaveis),
                    value: model.getModel('responsaveis').value,
                    requestChange: model.getModel('responsaveis').requestChange,
                    errors: model.getModel('responsaveis').errors,
                  }}
                  disabled={true}
                />
              </Col>
              <Col md={3}>
                <UnidadeMedidaLookup
                  model={model.getModel('unidadeMedida')}
                  disabled={true}
                />
              </Col>
              <Col md={3}>
                <PeriodicidadeLookup
                  model={model.getModel('periodicidade')}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={5}>
                    <Input
                      model={{
                        label: capitalizeFirstLetter(resources.meta),
                        value: model.getModel('meta').value,
                        requestChange: model.getModel('meta').requestChange,
                      }}
                      disabled={true}
                    />
                  </Col>
                  <Col md={5}>
                    <Input
                      model={model.getModel('realizado')}
                      disabled={true}
                    />
                  </Col>
                  <Col md={2}>
                    {model.getModel('farol').value && (
                      <SvgIcon
                        style={{ marginTop: 30 }}
                        title={model.getModel('farol').value.legenda}
                        size={20}
                        icon={model.getModel('farol').value.icone}
                        color={model.getModel('farol').value.cor}
                        colorHover={model.getModel('farol').value.cor}
                      ></SvgIcon>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <Input
                      model={{
                        label: capitalizeFirstLetter(resources.metaacumulado),
                        value: model.getModel('metaAcumulado').value,
                        requestChange:
                          model.getModel('metaAcumulado').requestChange,
                      }}
                      disabled={true}
                    />
                  </Col>
                  <Col md={5}>
                    <Input
                      model={model.getModel('realizadoAcumulado')}
                      disabled={true}
                    />
                  </Col>
                  <Col md={2}>
                    {model.getModel('farolAcumulado').value && (
                      <SvgIcon
                        style={{ marginTop: 30 }}
                        title={model.getModel('farolAcumulado').value.legenda}
                        size={20}
                        icon={model.getModel('farolAcumulado').value.icone}
                        color={model.getModel('farolAcumulado').value.cor}
                        colorHover={model.getModel('farolAcumulado').value.cor}
                      ></SvgIcon>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {model.getModel('usarTabelaValores').value ? (
                  <div>
                    <Row>
                      <Col md={12}>
                        <TabelaValorLookup
                          model={model.getModel('tabelaValor')}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {model.getModel('tabelaValor').value && (
                          <PreviewTabelaValor
                            exibirFarol={true}
                            model={model
                              .getModel('tabelaValor')
                              .getModel('itens')}
                          ></PreviewTabelaValor>
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Row>
                      <Col md={6}>
                        <FaixaFarolLookup
                          model={{
                            label: capitalizeFirstLetter(resources.faixa),
                            value: model.getModel('faixa').value,
                            requestChange:
                              model.getModel('faixa').requestChange,
                          }}
                          disabled={true}
                        />
                      </Col>
                      <Col md={6}>
                        <Radio
                          model={{
                            label: capitalizeFirstLetter(resources.polaridade),
                            value: model.getModel('melhor').value,
                            requestChange:
                              model.getModel('melhor').requestChange,
                          }}
                          options={model.value.opcoesMelhor}
                          labelFunction={this.handleLabelMelhor}
                          disabled={true}
                          labelField="descricao"
                          idField="id"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {model.getModel('faixa').value &&
                          model.getModel('melhor').value &&
                          (model.getModel('melhor').value.id == 1 ? (
                            <Preview
                              model={model
                                .getModel('faixa')
                                .getModel('faixaMaior')}
                            ></Preview>
                          ) : model.getModel('melhor').value.id == 2 ? (
                            <Preview
                              model={model
                                .getModel('faixa')
                                .getModel('faixaMenor')}
                            ></Preview>
                          ) : (
                            <Preview
                              model={model
                                .getModel('faixa')
                                .getModel('faixaIgual')}
                            ></Preview>
                          ))}
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              {model.getModel('utilizarDigitadorValor').value && (
                <Col md={6}>
                  <ColaboradorSimplificadoLookup
                    onBlur={this.handleSave}
                    multi
                    model={{
                      label: capitalizeFirstLetter(resources.digitadores),
                      value: model.getModel('digitadores').value,
                    }}
                    disabled={true}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col md={12}>
                <Input
                  onBlur={this.handleSave}
                  maxLength={1000}
                  rows={4}
                  type="textarea"
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'descricao' })
                    ),
                    value: model.getModel('descricao').value,
                  }}
                  disabled={true}
                />
              </Col>
            </Row>
          </div>
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Ficha));
