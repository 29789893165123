import { useState } from 'react';
import { useIntl } from 'react-intl';

import MdiIcon from 'src/componentes/mdi-icon';
import FuncaoLookup from 'src/lookups/funcao';
import Checkbox from 'src/componentes/checkbox';

const Edit = ({
  model,
  index,
  funcao,
  provided,
  showTrashIcon,
  disabled,
  disabledCheckbox,
}) => {
  const intl = useIntl();

  const handleChangeFields = (field, value) => {
    const newList = [...model.value];
    const funcao = newList[index];

    if (funcao) funcao[field] = value;

    model.requestChange(newList);
  };

  const handleRemove = () => {
    const newList = [...model.value];
    newList.splice(index, 1);

    model.requestChange(newList);
  };

  return (
    <tr
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
      }}
    >
      <td style={{ width: '70%', verticalAlign: 'top' }} className="pr-3 pt-1">
        <FuncaoLookup
          disabled={disabled}
          required
          autoFocus={false}
          formGroupStyle={{ marginBottom: 0 }}
          model={{
            label: '',
            value: funcao.funcao,
            requestChange: (v) => handleChangeFields('funcao', v),
          }}
        />
      </td>
      <td
        style={{ width: '25%', verticalAlign: 'top' }}
        className="text-center text-primary pr-3 pt-1"
      >
        <Checkbox
          disabled={disabled || disabledCheckbox}
          style={{
            marginBottom: 35,
          }}
          model={{
            label: '',
            value: funcao.funcaoResponsavel,
            requestChange: (v) => handleChangeFields('funcaoResponsavel', v),
          }}
        />
      </td>
      {showTrashIcon && (
        <td style={{ width: '5%' }} className="text-center text-primary pt-1">
          <MdiIcon
            size={20}
            onClick={handleRemove}
            className="cursor-pointer"
            icon="delete"
          />
        </td>
      )}
    </tr>
  );
};

export default Edit;
