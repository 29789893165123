import React from 'react';
import { autobind } from 'core-decorators';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import ObjetivoSearch from 'src/paginas/okr/Objetivo/Search';

@autobind
class ListView extends React.Component {
  renderResponsavel(item) {
    if (item && item.responsavel) {
      return <span>{item.responsavel.nome}</span>;
    }
  }

  render() {
    let {
      intl,
      resources,
      showSearch = false,
      showNew = true,
      hideColumnsButton = false,
    } = this.props;

    return (
      <List
        url="/Objetivo"
        titulo={capitalizeFirstLetter(resources.objetivoOKR)}
        tag={14001}
        searchComponent={<ObjetivoSearch />}
        usarFiltroSalvo={false}
        openLastFilter={true}
        filtroLocal={true}
        onRemoveSaveFilter={false}
        showMenu={false}
        showSearch={showSearch}
        showNew={showNew}
        hideColumnsButton={hideColumnsButton}
        {...this.props}
      >
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'descricao' })
          )}
          valueField="descricao"
          required
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.responsavel)}
          valueField="responsavel"
          valueFunction={this.renderResponsavel}
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.area)}
          valueField="area"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'dataInicio' })
          )}
          valueField="dataInicio"
        />
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'dataTermino' })
          )}
          valueField="dataTermino"
        />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ListView));
