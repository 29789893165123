import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import { InputTranslate } from 'src/componentes/Form';
import { autobind } from 'core-decorators';
import { Schema } from 'src/utils/form';
import DesdobramentoLookup from 'src/lookups/desdobramento';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from 'src/utils/string';
import { connect } from 'react-redux';
import DataInicioTermino from 'src/paginas/formula/componentes/inicio-termino';
import OrganizacaoLookup from 'src/lookups/item-organizacao';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import Historico from '../versao-alteracao';

let schema = Schema.object({
  pai: Schema.string().label(<FormattedMessage id="registroSuperior" />),
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  responsavel: Schema.string().label(''),
  area: Schema.string().label(''),
  digitador: Schema.string().label(''),
});

@autobind
class FormEdit extends React.Component {
  handleChangeInputTranslate(field, value) {
    let model = this.props.model;
    model.getModel(field).requestChange(value);
  }

  handleChangeEditTranslate(
    fieldDefault,
    fieldPT,
    fieldEN,
    fieldES,
    fieldIT,
    fieldNL,
    fieldFR,
    fieldDE,
    fieldTR,
    fieldAR,
    localModel
  ) {
    let model = this.props.model;
    model.getModel(fieldDefault).requestChange(localModel[fieldDefault]);
    model.getModel(fieldPT).requestChange(localModel[fieldPT]);
    model.getModel(fieldEN).requestChange(localModel[fieldEN]);
    model.getModel(fieldES).requestChange(localModel[fieldES]);
    model.getModel(fieldIT).requestChange(localModel[fieldIT]);
    model.getModel(fieldNL).requestChange(localModel[fieldNL]);
    model.getModel(fieldFR).requestChange(localModel[fieldFR]);
    model.getModel(fieldDE).requestChange(localModel[fieldDE]);
    model.getModel(fieldTR).requestChange(localModel[fieldTR]);
    model.getModel(fieldAR).requestChange(localModel[fieldAR]);
  }

  render() {
    const { model, resources } = this.props;

    const isNew = !model.getModel('id').value;

    return (
      <Tabs>
        <Tab key="1" label={<FormattedMessage id="label.resumo" />}>
          <div>
            <Row>
              <Col md={2}>
                <Input model={model.getModel('idIntegracao')} required />
              </Col>
              <Col md={5}>
                <InputTranslate
                  id={model.getModel('id') && model.getModel('id').value}
                  maxLength={300}
                  required={true}
                  fieldDefault={'descricao'}
                  errorsFieldDefault={model.getModel('descricao').errors}
                  valueDefault={model.getModel('descricao').value}
                  labelDefault={model.getModel('descricao').label}
                  fieldPT={'descricaoPT'}
                  valuePT={model.getModel('descricaoPT').value}
                  fieldEN={'descricaoEN'}
                  valueEN={model.getModel('descricaoEN').value}
                  fieldES={'descricaoES'}
                  valueES={model.getModel('descricaoES').value}
                  fieldIT={'descricaoIT'}
                  valueIT={model.getModel('descricaoIT').value}
                  fieldNL={'descricaoNL'}
                  valueNL={model.getModel('descricaoNL').value}
                  fieldFR={'descricaoFR'}
                  valueFR={model.getModel('descricaoFR').value}
                  fieldDE={'descricaoDE'}
                  valueDE={model.getModel('descricaoDE').value}
                  fieldTR={'descricaoTR'}
                  valueTR={model.getModel('descricaoTR').value}
                  fieldAR={'descricaoAR'}
                  valueAR={model.getModel('descricaoAR').value}
                  handleChangeInputTranslate={this.handleChangeInputTranslate}
                  handleChangeEditTranslate={this.handleChangeEditTranslate}
                />
              </Col>
              <Col md={5}>
                <DesdobramentoLookup
                  model={model.getModel('pai')}
                  disabled={!isNew}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <OrganizacaoLookup
                  model={{
                    label: capitalizeFirstLetter(resources.area),
                    value: model.getModel('area').value,
                    requestChange: model.getModel('area').requestChange,
                  }}
                />
              </Col>
              <Col md={6}>
                <DataInicioTermino
                  showClear={false}
                  dataInicio={model.getModel('dataInicio')}
                  dataTermino={model.getModel('dataTermino')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ColaboradorSimplificadoLookup
                  model={{
                    label: capitalizeFirstLetter(resources.responsavel),
                    value: model.getModel('responsavel').value,
                    requestChange: model.getModel('responsavel').requestChange,
                  }}
                />
              </Col>
              <Col md={6}>
                <ColaboradorSimplificadoLookup
                  model={{
                    label: capitalizeFirstLetter(resources.digitador),
                    value: model.getModel('digitador').value,
                    requestChange: model.getModel('digitador').requestChange,
                  }}
                />
              </Col>
            </Row>
          </div>
        </Tab>
        {!isNew && (
          <Tab key="2" label={capitalizeFirstLetter(resources.historico)}>
            <Historico
              url={`/Desdobramento/ObterHistorico`}
              id={this.props.model.getModel('id').value}
            />
          </Tab>
        )}
      </Tabs>
    );
  }
}

class DesdobramentoEdit extends React.Component {
  render() {
    return (
      <Edit
        {...this.props}
        url="/Desdobramento"
        title="desdobramento"
        formComponent={FormEdit}
        schema={schema}
        showExport={false}
        minWidth={'80%'}
        height={400}
      />
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(DesdobramentoEdit);
