import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import Schema from './Schema';

import Input from 'src/componentes/input';
import Edit from 'src/componentes/edit/edit-dialog';
import DatePicker from 'src/componentes/date-picker';
import ColaboradorLookup from 'src/lookups/colaborador';
import CausasEventoRisco from '../CausasEventoRisco';
import ConsequenciasEventoRisco from '../ConsequenciasEventoRisco';
import PanelCollapse from 'src/componentes/panel-collapse';
import TiposSeveridadeEvento from '../Componentes/TipoSeveridades';
import SeveridadeRiscoLookup from 'src/lookups/severidade-risco';
import InputNumber from 'src/componentes/input-number';
import { capitalizeFirstLetter, lowerCaseAllLetter } from 'src/utils/string';
import RespostaOcorrenciaLookup from 'src/lookups/RespostaOcorrenciaLookup';
import useAuth from 'src/hooks/useAuth';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import PlanoContingenciaLookup from 'src/lookups/PlanoContingenciaLookup';
import AcaoView from 'src/paginas/acao';
import http from 'src/services/httpService';
import LoadingContainer from 'src/componentes/loading-container';
import ContentManager from 'src/componentes/content-manager';
import Confirm from 'src/componentes/message-box/confirm';
import { ENTIDADE_ANEXOS } from 'src/utils/constants';
import Panel from 'src/componentes/panel-collapse';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import ComunicadoEventoRisco from '../ComunicadoEventoRisco';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import Reuniao from 'src/paginas/reuniao';

function FormEdit({ model, configuracao, parameters, ...rest }) {
  const { terms: resources } = useAuth();
  const [isLoadingAcoes, setIsLoadingAcoes] = useState(false);
  let generatedNumbers = [];

  const isDisabled = model.getModel('permissions').value
    ? !model.getModel('permissions').value.allowEdit
    : false;

  function handleSaveCausaRisco(item, handleClose) {
    const modelCausa = model.getModel('causas');
    let itens = modelCausa.value;
    if (!itens) itens = [];

    if (!itens?.find((x) => x.causa.id == item.causa.id)) {
      let causa = {
        id: -1,
        descricao: item.causa.descricao,
        causa: item.causa,
        permissions: { allowDelete: true, allowEdit: true },
      };
      itens.push(causa);
    }

    modelCausa.requestChange(itens);

    handleClose();
  }

  function handleDeleteCausaRisco(item) {
    const modelCausa = model.getModel('causas');
    let itens = modelCausa.value;

    const index =
      itens && itens.findIndex((prop) => prop.causa.id === item.causa.id);

    if (index != -1) {
      itens.splice(index, 1);
      modelCausa.requestChange(itens);
    }
  }

  function afterRemove(item) {
    const modelAcoes = model.getModel('acoes');
    let itens = modelAcoes.value;
    const index = itens && itens.findIndex((prop) => prop.id === item.id);

    if (index != -1) {
      itens.splice(index, 1);
      modelAcoes.requestChange(itens);
    }
  }

  function handleChange(item, handleCloseCallBack) {
    const modelAcoes = model.getModel('acoes');
    let itens = modelAcoes.value;
    if (!itens) itens = [];

    if (!itens.find((x) => x.id == item.id)) {
      let randomNumber;
      do {
        randomNumber = (Math.floor(Math.random() * 1000) + 1) * -1;
      } while (generatedNumbers.includes(randomNumber));

      generatedNumbers = [...generatedNumbers, randomNumber];
      const newItem = {
        ...item,
        id: randomNumber,
        editModel: { ...item, id: randomNumber },
      };
      itens.push(newItem);
    } else {
      itens = itens.map((v) => {
        if (v.id === item.id) {
          return { ...item, editModel: item };
        }
        return v;
      });
    }
    modelAcoes.requestChange(itens);
    handleCloseCallBack();
  }

  function handleChangeComunicado(item, handleCloseCallBack) {
    const modelComunicados = model.getModel('comunicados');
    let itens = modelComunicados.value;
    if (!itens) itens = [];

    if (!itens.find((x) => x.id == item.id)) {
      let randomNumber;
      do {
        randomNumber = (Math.floor(Math.random() * 1000) + 1) * -1;
      } while (generatedNumbers.includes(randomNumber));

      generatedNumbers = [...generatedNumbers, randomNumber];
      const newItem = {
        ...item,
        id: randomNumber,
        editModel: { ...item, id: randomNumber },
      };
      itens.push(newItem);
    } else {
      itens = itens.map((v) => {
        if (v.id === item.id) {
          return { ...item, editModel: item };
        }
        return v;
      });
    }
    modelComunicados.requestChange(itens);
    handleCloseCallBack();
  }

  function handleDeleteComunicado(item) {
    const modelComunicados = model.getModel('comunicados');
    let itens = modelComunicados.value;

    const index = itens && itens.findIndex((prop) => prop.id === item.id);

    if (index != -1) {
      itens.splice(index, 1);
      modelComunicados.requestChange(itens);
    }
  }

  function findMissingIds(array1, array2) {
    return array1.filter((id) => !array2.includes(id));
  }

  function handleDeleteConsequenciaRisco(item) {
    const modelConsequencia = model.getModel('consequencias');
    let itens = modelConsequencia.value;
    const index =
      itens &&
      itens.findIndex((prop) => prop.consequencia.id === item.consequencia.id);

    if (index != -1) {
      itens.splice(index, 1);
      modelConsequencia.requestChange(itens);
    }
  }

  function handleRemovePlanoContingencia(currentValue, newValue) {
    const missingIds = findMissingIds(currentValue, newValue);
    const itens = model.getModel('acoes').value;
    const newItens = itens.filter(
      (a) =>
        !a.planoContingenciaId ||
        !missingIds.some((v) => v.id === a.planoContingenciaId)
    );
    model.getModel('acoes').requestChange(newItens);
    model.getModel('planosContingencia').requestChange(newValue);
    setIsLoadingAcoes(false);
  }

  function requestChangePlanoContingencia(value) {
    setIsLoadingAcoes(true);

    const newValue = value;
    const currentValue = model.getModel('planosContingencia').value;

    if (newValue.length < currentValue.length) {
      ContentManager.addContent(
        <Confirm
          message={capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'msgValidacaoRemoverPlanoContingenciaEventoRisco' },
              { acoes: lowerCaseAllLetter(resources.acoes) }
            )
          )}
          handleConfirm={() =>
            handleRemovePlanoContingencia(currentValue, newValue)
          }
        />
      );

      setIsLoadingAcoes(false);
    } else {
      http
        .post('/eventoRisco/ObterAcoesPlanoContingencia', {
          idPlano: value.map((obj) => obj.id),
          eventoRiscoId: model?.getModel('id')?.value,
          dataOcorrencia: model?.getModel('dataOcorrencia')?.value,
          acoes: model?.getModel('acoes')?.value,
          riscoId: model?.getModel('risco')?.value?.id,
        })
        .then((result) => {
          const itens = model.getModel('acoes').value;
          const newItens = [...itens, ...result.data];
          model.getModel('acoes').requestChange(newItens);
          model.getModel('planosContingencia').requestChange(value);
        })
        .finally(setIsLoadingAcoes(false));
    }
  }

  function handleSaveConsequenciaRisco(item, handleClose) {
    const modelConsequencia = model.getModel('consequencias');
    let itens = modelConsequencia.value;
    if (!itens) itens = [];

    if (!itens.find((x) => x.consequencia.id == item.consequencia.id)) {
      let consequencia = {
        id: -1,
        descricao: item.consequencia.descricao,
        consequencia: item.consequencia,
        permissions: { allowDelete: true, allowEdit: true },
      };
      itens.push(consequencia);
    }

    modelConsequencia.requestChange(itens);

    handleClose();
  }

  const intl = useIntl();

  return (
    <Tabs defaultActiveKey="1">
      <Tab tabKey="1" label={intl.formatMessage({ id: 'cadastro' })}>
        <Row>
          <Col md={2}>
            <Input
              style={{ height: '33px' }}
              disabled={isDisabled}
              maxLength={300}
              required={!isDisabled}
              model={model.getModel('idIntegracao')}
            />
          </Col>
          <Col md={2}>
            <DatePicker
              disabled={isDisabled}
              timeFormat={false}
              model={model.getModel('dataOcorrencia')}
              required={true}
            />
          </Col>
          <Col md={5}>
            <Input
              disabled={isDisabled}
              model={model.getModel('descricao')}
              required
              maxLength={1000}
            />
          </Col>
          <Col md={3}>
            <ColaboradorLookup
              disabled={isDisabled}
              model={model.getModel('responsavel')}
              required
            />
          </Col>
          <Col md={9}>
            <Input
              disabled={isDisabled}
              type="textarea"
              model={model.getModel('detalhamentoOcorrencia')}
              rows={3}
              maxLength={4000}
            />
          </Col>
          {configuracao.utilizarCampoRespostaEventoRisco && (
            <Col md={3}>
              <RespostaOcorrenciaLookup
                style={{ marginBottom: '10px' }}
                disabled={isDisabled}
                model={model.getModel('respostaOcorrencia')}
                required={configuracao.definicaoRespostaEventoRiscoObrigatorio}
              />
              <div>
                <ArquivoUploadAnexos
                  classNameFormGroup={'d-flex inline'}
                  formGroupStyle={{ marginBottom: 0 }}
                  onlyRead={isDisabled}
                  tipoAnexo={ENTIDADE_ANEXOS.EVENTO_RISCO}
                  rightLabel={true}
                  model={{
                    label: intl.formatMessage({ id: 'anexo' }),
                    value: model.getModel('arquivos').value,
                    requestChange: model.getModel('arquivos').requestChange,
                  }}
                />
              </div>
            </Col>
          )}
          <Col md={12}>
            <Row>
              {model.getModel('camposCustomizados').value &&
                model.getModel('camposCustomizados').value.length > 0 && (
                  <Col md={12}>
                    <Panel
                      header={capitalizeFirstLetter(
                        intl.formatMessage({
                          id: 'informacoesComplementares',
                        })
                      )}
                    >
                      <DesenharCampoCustomizado
                        model={{
                          label: intl.formatMessage({
                            id: 'informacoesComplementares',
                          }),
                          value: model.getModel('camposCustomizados').value,
                          requestChange:
                            model.getModel('camposCustomizados').requestChange,
                        }}
                      />
                    </Panel>
                  </Col>
                )}
            </Row>
          </Col>
          {configuracao.avaliarCausasOrigemOcorrencia && (
            <Col md={6}>
              <PanelCollapse
                open={true}
                header={capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'labelCausasDoRisco' },
                    {
                      pontosproblematicos: resources.pontosproblematicos,
                      risco: resources.risco,
                    }
                  )
                )}
              >
                <CausasEventoRisco
                  model={model.getModel('causas')}
                  handleSave={handleSaveCausaRisco}
                  onDeleting={handleDeleteCausaRisco}
                  disabled={isDisabled}
                />
              </PanelCollapse>
            </Col>
          )}
          {configuracao.avaliarConsequenciasOcorrencia && (
            <Col md={6}>
              <PanelCollapse
                open={true}
                header={capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'labelConsequenciasDoRisco' },
                    {
                      consequencias: resources.consequencias,
                      risco: resources.risco,
                    }
                  )
                )}
              >
                <ConsequenciasEventoRisco
                  model={model.getModel('consequencias')}
                  handleSave={handleSaveConsequenciaRisco}
                  onDeleting={handleDeleteConsequenciaRisco}
                  disabled={isDisabled}
                />
              </PanelCollapse>
            </Col>
          )}
          <Col md={12}>
            {(configuracao.avaliarImpactoEventoRisco ||
              configuracao.avaliarImpactoFinanceiroOcorrencia) && (
              <PanelCollapse
                header={intl.formatMessage({
                  id: 'avaliacaoImpactoDaOcorrencia',
                })}
              >
                <Row>
                  {configuracao.avaliarImpactoEventoRisco && (
                    <Col md={8}>
                      <TiposSeveridadeEvento
                        disabled={isDisabled}
                        avaliacaoModel={model}
                        resources={resources}
                      />
                    </Col>
                  )}
                  <Col md={4}>
                    <PanelCollapse>
                      <Row>
                        {configuracao.avaliarImpactoEventoRisco && (
                          <Col md={5}>
                            <SeveridadeRiscoLookup
                              disabled={isDisabled}
                              model={{
                                label: capitalizeFirstLetter(
                                  intl.formatMessage(
                                    { id: 'labelSeveridadeInerente' },
                                    { severidade: resources.severidade }
                                  )
                                ),
                                value: model.getModel('severidade').value,
                                requestChange:
                                  model.getModel('severidade').requestChange,
                                errors: model.getModel('severidade').errors,
                              }}
                            />
                          </Col>
                        )}
                        {configuracao.avaliarImpactoFinanceiroOcorrencia && (
                          <Col md={6}>
                            <InputNumber
                              customClassNameLabel={'control-label pb-1'}
                              disabled={isDisabled}
                              model={{
                                label: capitalizeFirstLetter(
                                  `${intl.formatMessage({
                                    id: 'labelImpactoFinanceiro',
                                  })}`
                                ),
                                value:
                                  model.getModel('impactoFinanceiro').value,
                                requestChange:
                                  model.getModel('impactoFinanceiro')
                                    .requestChange,
                              }}
                            />
                          </Col>
                        )}
                        <Col md={10}>
                          <Input
                            disabled={isDisabled}
                            type="textarea"
                            model={model.getModel('comentario')}
                            rows={3}
                            maxLength={1500}
                          />
                        </Col>
                        <Col md={2}>
                          <ArquivoUploadAnexos
                            tipoAnexo={
                              ENTIDADE_ANEXOS.EVENTO_RISCO_AVALIACAOSCO
                            }
                            formGroupStyle={{ marginBottom: 0 }}
                            model={model.getModel('arquivosAvaliacao')}
                          />
                        </Col>
                      </Row>
                    </PanelCollapse>
                  </Col>
                </Row>
              </PanelCollapse>
            )}
          </Col>
        </Row>
        {configuracao?.criarPlanoDeAcaoRespostaOcorrencia && (
          <Row>
            <Col md={12}>
              <PanelCollapse
                header={`${intl.formatMessage(
                  { id: 'labelPlanoAcao' },
                  { acao: resources.acao }
                )} ${
                  configuracao?.tornarDefinicaoPlanoDeAcaoObrigatoria
                    ? ' *'
                    : ''
                }`}
              >
                <Row>
                  <Col md={12}>
                    <PlanoContingenciaLookup
                      disabled={isDisabled}
                      multi
                      model={{
                        label: capitalizeFirstLetter(
                          `${intl.formatMessage({
                            id: 'labelPlanoContigencia',
                          })}`
                        ),
                        value: model.getModel('planosContingencia').value,
                        requestChange: requestChangePlanoContingencia,
                      }}
                      data={parameters}
                      parameters={parameters}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <LoadingContainer isLoading={isLoadingAcoes}>
                      <AcaoView
                        local={true}
                        kanban={false}
                        iconSettings={false}
                        handleSave={handleChange}
                        hideHeader={true}
                        hideSearch={true}
                        showEstatisticaAcoes={false}
                        showExport={false}
                        afterRemove={afterRemove}
                        ignoreStickyHeader={true}
                        parameters={{
                          eventoRiscoId: model?.getModel('id')?.value,
                          exibirLookups: false,
                          itemNaoObrigatorio: true,
                        }}
                        dataSource={model?.getModel('acoes')?.value}
                        showRequired={model?.getModel('acoes')?.errors}
                        itemIdOrigin={model?.getModel('risco')?.value?.id}
                      />
                    </LoadingContainer>
                  </Col>
                </Row>
              </PanelCollapse>
            </Col>
          </Row>
        )}
        <Row>
          {configuracao.permitirEnvioComunicadosEventoRisco && (
            <Col md={12}>
              <PanelCollapse
                open={true}
                header={capitalizeFirstLetter(
                  intl.formatMessage({ id: 'comunicados' })
                )}
              >
                <ComunicadoEventoRisco
                  model={model.getModel('comunicados')}
                  handleSave={handleChangeComunicado}
                  onDeleting={handleDeleteComunicado}
                  disabled={isDisabled}
                  eventoRiscoId={model?.getModel('id')?.value}
                  eventoRiscoDescricao={model?.getModel('descricao')?.value}
                />
              </PanelCollapse>
            </Col>
          )}
        </Row>
      </Tab>
      {model?.getModel('id')?.value > 0 && (
        <Tab tabKey="2" label={intl.formatMessage({ id: 'labelComiteCrise' })}>
          <Reuniao
            parameters={{ eventoRiscoId: model.getModel('id')?.value }}
            showOnlyList={true}
          />
        </Tab>
      )}
    </Tabs>
  );
}

function EventoRiscoEditView({ ...rest }) {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  return (
    <Edit
      respeitarAltura
      url="/EventoRisco"
      title={intl.formatMessage(
        { id: 'eventosRisco' },
        { risco: resources.risco }
      )}
      formComponent={FormEdit}
      schema={Schema}
      width="80%"
      minHeight="60%"
      maxHeight="90%"
      {...rest}
    ></Edit>
  );
}

export default EventoRiscoEditView;
