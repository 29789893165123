import { Schema } from '../../utils/form';
import SchemaItem from '../item/schema';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default Schema.object({
  ...SchemaItem,
  //override
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required({
      onlyIf: (context) => {
        return (
          !context.parent.value.utilizarCodigoAutomatico &&
          context.parent.value.itemTemplate == null
        );
      },
    }),
  pai: Schema.string().label(<FormattedMessage id="registroSuperior" />),
  area: Schema.string()
    .label(<FormattedMessage id="area" />)
    .required(),
  status: Schema.string()
    .label(<FormattedMessage id="status" />)
    .required(),
  unidadeMedida: Schema.string()
    .label(<FormattedMessage id="unidadeDeMedida" />)
    .required(),
  casasDecimais: Schema.string()
    .label(<FormattedMessage id="label.nDeCasasDecimais" />)
    .required(),
  dataInicioItem: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTerminoItem: Schema.string().label(<FormattedMessage id="dataTermino" />),
  periodicidade: Schema.string()
    .label(<FormattedMessage id="periodicidade" />)
    .required(),
  modeloItem: Schema.string(),
  templateDesdobramento: Schema.string()
    .label(<FormattedMessage id="label.templateDesdobramento" />)
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.utilizarTemplateDesdobramento &&
          context.parent.value.utilizarTemplateDesdobramento.id === 2
        );
      },
    }),
  itemTemplate: Schema.string()
    .label(<FormattedMessage id="templateItem" />)
    .required({
      onlyIf: (context) => {},
    }),
  desdobramento: Schema.string().label(<FormattedMessage id="desdobramento" />),
  tabelaValor: Schema.string()
    .label(<FormattedMessage id="tabelaValores" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.usarTabelaValores == true;
      },
    }),
  utilizarCodigoAreaParaMascara: Schema.string().label(''),
  permitirAlterarCodigo: Schema.string().label(
    <FormattedMessage id="label.permitirAlterarCodigoAutomatico" />
  ),
  // aba configurações

  tipoExibicaoRealizadoGrafico: Schema.string().label(
    <FormattedMessage id="label.exibirComo" />
  ),
  tipoLinhaRealizadoGrafico: Schema.string().label(
    <FormattedMessage id="label.tipoDeLinha" />
  ),
  exibirRealizadoOutroIndicadorGrafico: Schema.string().label(''),
  realizadoOutroIndicadorGrafico: Schema.string().label(''),
  corOutroIndicadorGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  exibirEstimadoGrafico: Schema.string().label(
    <FormattedMessage id="label.valoresEstimados" />
  ),
  exibirTendenciaGrafico: Schema.string().label(
    <FormattedMessage id="tendencia" />
  ),
  tipoExibicaoTendenciaGrafico: Schema.string().label(
    <FormattedMessage id="label.exibirComo" />
  ),
  tipoLinhaTendenciaGrafico: Schema.string().label(
    <FormattedMessage id="label.tipoDeLinha" />
  ),
  pontoTendenciaGrafico: Schema.string().label(
    <FormattedMessage id="label.ponto" />
  ),
  rotuloTendenciaGrafico: Schema.string().label(
    <FormattedMessage id="label.rotulo" />
  ),
  corTendenciaGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  exibirAnoAnteriorLadoGrafico: Schema.string().label(
    <FormattedMessage id="label.anoAnteriorLadoALadoComOAtual" />
  ),
  tipoExibicaoAnoAnteriorLadoGrafico: Schema.string().label(
    <FormattedMessage id="label.exibirComo" />
  ),
  tipoLinhaAnoAnteriorLadoGrafico: Schema.string().label(
    <FormattedMessage id="label.tipoDeLinha" />
  ),
  pontoAnoAnteriorLadoGrafico: Schema.string().label(
    <FormattedMessage id="label.ponto" />
  ),
  rotuloAnoAnteriorLadoGrafico: Schema.string().label(
    <FormattedMessage id="label.rotulo" />
  ),
  corAnoAnteriorLadoGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  corRealizadoUmAnoAtrasGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  corRealizadoDoisAnosAtrasGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  corRealizadoTresAnosAtrasGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  corRealizadoQuatroAnosAtrasGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  corRealizadoCincoAnosAtrasGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  corRealizadoSeisAnosAtrasGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  exibirBenchmarkInternoGrafico: Schema.string().label(''),
  exibirBenchmarkExternoGrafico: Schema.string().label(''),
  exibirBenchmarkHistoricoGrafico: Schema.string().label(''),
  rotuloBenchmarkInternoGrafico: Schema.string().label(
    <FormattedMessage id="label.rotulo" />
  ),
  rotuloBenchmarkExternoGrafico: Schema.string().label(
    <FormattedMessage id="label.rotulo" />
  ),
  rotuloBenchmarkHistoricoGrafico: Schema.string().label(
    <FormattedMessage id="label.rotulo" />
  ),
  corBenchmarkInternoGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  corBenchmarkExternoGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  corBenchmarkHistoricoGrafico: Schema.string().label(
    <FormattedMessage id="label.cor" />
  ),
  ultimoFarol: Schema.string().label(<FormattedMessage id="ultimofarol" />),
  pacote: Schema.string()
    .label(<FormattedMessage id="pacoteConta" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.vincularComMO;
      },
    }),
  centroCusto: Schema.string()
    .label(<FormattedMessage id="centroDeCusto" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.vincularComMO;
      },
    }),

  // configurações gerais
  configuracaoGeral: Schema.array().label(
    <FormattedMessage id="configuracoesGerais" />
  ),

  configuracaoGrafico: Schema.object({
    formatoGridGrafico: Schema.string().label(),
    opcoesFormatoGridGrafico: Schema.string().label(),

    tipoExibicaoMetaGrafico: Schema.string().label(
      <FormattedMessage id="label.exibirComo" />
    ),
    tipoLinhaMetaGrafico: Schema.string().label(
      <FormattedMessage id="label.tipoDeLinha" />
    ),
    pontoMetaGrafico: Schema.string().label(
      <FormattedMessage id="label.ponto" />
    ),
    rotuloMetaGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corMetaGrafico: Schema.string().label(<FormattedMessage id="label.cor" />),
    exibirDesempenhoGrafico: Schema.string().label(''),
    tipoLinhaDesempenhoGrafico: Schema.string().label(
      <FormattedMessage id="label.tipoDeLinha" />
    ),
    pontoDesempenhoGrafico: Schema.string().label(
      <FormattedMessage id="label.ponto" />
    ),
    rotuloDesempenhoGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corDesempenhoGrafico: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),
    exibirYtdGrafico: Schema.string().label(),
    rotuloYtdGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corYtdGrafico: Schema.string().label(<FormattedMessage id="label.cor" />),
    tipoLinhaRealizadoGrafico: Schema.string().label(
      <FormattedMessage id="label.tipoDeLinha" />
    ),
    tipoLinhaOutroIndicadorGrafico: Schema.string().label(
      <FormattedMessage id="label.tipoDeLinha" />
    ),
    pontoRealizadoGrafico: Schema.string().label(
      <FormattedMessage id="label.ponto" />
    ),
    corRealizadoGrafico: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),

    exibirRealizadoUmAnoAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.1AnoAtras" />
    ),
    exibirRealizadoDoisAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.2AnosAtras" />
    ),
    exibirRealizadoTresAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.3AnosAtras" />
    ),
    exibirRealizadoQuatroAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.4AnosAtras" />
    ),
    exibirRealizadoCincoAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.5AnosAtras" />
    ),
    exibirRealizadoSeisAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.6AnosAtras" />
    ),
    rotuloRealizadoUmAnoAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    rotuloRealizadoDoisAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    rotuloRealizadoTresAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    rotuloRealizadoQuatroAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    rotuloRealizadoCincoAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    rotuloRealizadoSeisAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),

    exibirMetaAcumuladaAnoGrafico: Schema.string().label(
      <FormattedMessage id="label.metaAcumuladaDoAno" />
    ),
    rotuloMetaAcumuladaAnoGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corMetaAcumuladaAnoGrafico: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),

    exibirRealizadoAcumuladaAnoGrafico: Schema.string().label(
      <FormattedMessage id="realizadoAcumuladoAno" />
    ),
    rotuloRealizadoAcumuladaAnoGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corRealizadoAcumuladaAnoGrafico: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),

    periodoRealizadoUmAnoAtrasGrafico: Schema.string().label(
      <FormattedMessage id="periodo" />
    ),
    periodoRealizadoDoisAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="periodo" />
    ),
    periodoRealizadoTresAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="periodo" />
    ),
    periodoRealizadoQuatroAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="periodo" />
    ),
    periodoRealizadoCincoAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="periodo" />
    ),
    periodoRealizadoSeisAnosAtrasGrafico: Schema.string().label(
      <FormattedMessage id="periodo" />
    ),

    rotuloRealizadoUmAnoAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corRealizadoUmAnoAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),
    rotuloRealizadoDoisAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corRealizadoDoisAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),
    rotuloRealizadoTresAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corRealizadoTresAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),
    rotuloRealizadoQuatroAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corRealizadoQuatroAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),
    rotuloRealizadoCincoAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corRealizadoCincoAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),
    rotuloRealizadoSeisAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corRealizadoSeisAnosAtrasGraficoPeriodo: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),

    opcoesEscalaGrafico: Schema.string().label(),
    escalaGrafico: Schema.string().label(),
    escalaMaxima: Schema.string().label(<FormattedMessage id="label.maxima" />),
    escalaMinima: Schema.string().label(<FormattedMessage id="label.minima" />),

    rotuloRealizadoGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    usarCorFarolRealizadoGrafico: Schema.string().label(
      <FormattedMessage id="label.usarCorDoFarol" />
    ),

    exibirValoresEixoY: Schema.string().label(
      <FormattedMessage id="label.exibirValoresEixoY" />
    ),
    exibirValoresEixoX: Schema.string().label(
      <FormattedMessage id="label.exibirValoresEixoX" />
    ),

    pontoOutroIndicadorGrafico: Schema.string().label(
      <FormattedMessage id="label.ponto" />
    ),
    rotuloOutroIndicadorGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),

    tipoExibicaoEstimadoGrafico: Schema.string().label(
      <FormattedMessage id="label.exibirComo" />
    ),
    tipoLinhaEstimadoGrafico: Schema.string().label(
      <FormattedMessage id="label.tipoDeLinha" />
    ),
    pontoEstimadoGrafico: Schema.string().label(
      <FormattedMessage id="label.ponto" />
    ),
    rotuloEstimadoGrafico: Schema.string().label(
      <FormattedMessage id="label.rotulo" />
    ),
    corEstimadoGrafico: Schema.string().label(
      <FormattedMessage id="label.cor" />
    ),

    fatorDeDivisao: Schema.string().label(
      <FormattedMessage id="label.dividirValorPor" />
    ),
    considerarConfiguracaoPadrao: Schema.string().label(
      <FormattedMessage id="considerarConfiguracaoPadrao" />
    ),
    realizadoEstimadoAcumuladoGrafico: Schema.string().label(
      <FormattedMessage id="utilizarRealizadoEstimadoAcumuladoGrafico" />
    ),
    exibirLegenda: Schema.string().label(
      <FormattedMessage id="exibirLegenda" />
    ),
    exibirPolaridade: Schema.string().label(
      <FormattedMessage id="label.polaridade" />
    ),
    exportarTabelaValores: Schema.string().label(),
    exportarDataExportacao: Schema.string().label(),
    exportarArea: Schema.string().label(),
  }),
  configuracao: Schema.object({
    calendario: Schema.string().label(<FormattedMessage id="calendario" />),
  }),
});
