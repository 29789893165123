const isDarkColor = (color) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

  if (result) {
    const R = parseInt(result[1], 16);
    const G = parseInt(result[2], 16);
    const B = parseInt(result[3], 16);

    return (R * 299 + G * 587 + B * 114) / 1000 <= 128;
  }

  return false;
};

export default isDarkColor;
