import React from 'react';
import Edit from '../../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from '../../../utils/form';
import Tabs from '../../../componentes/tabs/tabs';
import Tab from '../../../componentes/tabs/tab';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';
import Indicadores from './indicadores';
import Resumo from './resumo';

let schema = Schema.object({
  id: Schema.number().label(),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="codigo" />)
    .required(),
  tipo: Schema.string().label(<FormattedMessage id="tipo" />),
  gestores: Schema.string().label(<FormattedMessage id="gestores" />),
  dono: Schema.string().label(<FormattedMessage id="dono" />),
  pai: Schema.string().label(<FormattedMessage id="pai" />),
  grupoDiaEnvio: Schema.string().label(<FormattedMessage id="grupoDiaEnvio" />),
  opcoesTipoRelatorioAcompanhamento: Schema.string(),
  tipoRelatorioAcompanhamento: Schema.string(),
  classificacao: Schema.string().label(<FormattedMessage id="label.classificacao" />),
});

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mensagemErro: '',
      corMensagemErro: '',
    };
  }

  render() {
    let { model, resources } = this.props;

    let id = model.getModel('id') && model.getModel('id').value;
    let filhos = model.getModel('filhos') && model.getModel('filhos').value;

    return (
      <div>
        {model.getModel('id') && (
          <Tabs defaultActiveKey="1">
            {id > -1 && (
              <Tab label={<FormattedMessage id="label.resumo" />} key="1">
                <Resumo model={model} />
              </Tab>
            )}
            {id > 0 && filhos.length == 0 && (
              <Tab label={capitalizeFirstLetter(resources.indicador)} key="2">
                <Indicadores model={model}></Indicadores>
              </Tab>
            )}
          </Tabs>
        )}
      </div>
    );
  }
}

class PacoteEdit extends React.Component {
  render() {
    return <Edit url="/Pacote" title={this.props.intl.formatMessage({ id: 'pacoteConta' })} formComponent={FormEdit} schema={schema} width={1000} {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PacoteEdit));
