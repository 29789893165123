import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

import Dialog from 'src/componentes/dialog';
import Input from 'src/componentes/input';
import Button from 'src/componentes/button';
import IconsHoverSelect from 'src/componentes/IconsHoverSelect';
import LoadingContainer from 'src/componentes/loading-container';
import errorHandler from 'src/utils/error-handler';
import { getSession } from 'src/contexts/AuthContext';

const FeedbackSophie = ({
  requestId,
  subdomain,
  userId,
  interactionType,
  ...rest
}) => {
  const intl = useIntl();
  const ref = useRef();

  const [rating, setRating] = useState({});
  const [comment, setComment] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = getSession();

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const handleSaveFeedback = async () => {
    setIsLoading(true);
    try {
      await fetch(process.env.REACT_APP_API_OPENAI + '/api/open-ai/feedback', {
        method: 'POST',
        body: JSON.stringify({
          requestId,
          subdomain,
          userId,
          interactionType,
          rating: rating.id,
          comment,
        }),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setIsLoading(false);

      handleClose();

      enqueueSnackbar(intl.formatMessage({ id: 'feedbackEnviado' }), {
        variant: 'success',
      });
    } catch (err) {
      setIsLoading(false);
      errorHandler(err);
    }
  };

  const actions = [
    <div>
      <Button
        key="close-feedback"
        color="secondary"
        leftIcon="close"
        onClick={handleClose}
      >
        {intl.formatMessage({ id: 'label.cancelar' })}
      </Button>

      <Button
        disabled={disabled || isLoading}
        key="save-feedback"
        className="ml-2"
        leftIcon="content-save-outline"
        onClick={handleSaveFeedback}
      >
        {intl.formatMessage({ id: 'darFeedback' })}
      </Button>
    </div>,
  ];

  const handleSetRating = (value) => {
    setRating(value);
    setDisabled(false);
  };

  return (
    <Dialog
      ref={ref}
      width={500}
      height={550}
      onRequestClose={handleClose}
      mouseDownClose={true}
      respeitarAltura
      actions={actions}
      {...rest}
    >
      <LoadingContainer isLoading={isLoading}>
        <div className="d-flex flex-column align-items-center justify-content-center px-5">
          <div className="font-weight-bolder mb-4 text-left text-xl">
            {intl.formatMessage({ id: 'feedbackSophie' })}
          </div>

          <div className={`text-left mb-2`}>
            {intl.formatMessage({ id: 'compartilheSeuFeedback' })}
          </div>

          <div className="d-flex mt-4 mb-4 w-100">
            <IconsHoverSelect
              icon="star"
              iconSize={26}
              iconColor={'#FDCC0D'}
              onClick={(value) => handleSetRating(value)}
              value={rating ? { id: rating.id } : null}
              itens={[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]}
            />
          </div>

          <Input
            className="mt-2"
            showLabel
            formStyle={{ width: '100%' }}
            style={{ padding: 25 }}
            type="textarea"
            rows={2}
            maxLength={1000}
            model={{
              label: intl.formatMessage({ id: 'inserirComentario' }),
              value: comment,
              requestChange: (value) => setComment(value),
            }}
          />
        </div>
      </LoadingContainer>
    </Dialog>
  );
};

export default FeedbackSophie;
