import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import ContentManager from 'src/componentes/content-manager';
import EditDialog from 'src/componentes/edit/edit-dialog';
import PanelCollapse from 'src/componentes/panel-collapse';
import { capitalizeFirstLetter, uperCaseAllLetter } from 'src/utils/string';
import MdiIcon from 'src/componentes/mdi-icon';
import PlanoAcaoModal from 'src/paginas/acao/componentes/PlanoAcaoModal';
import { getConfiguration } from 'src/services/commonRequests';
import Avatar from 'src/componentes/item-multi-lookup';
import Input from 'src/componentes/input';
import http from 'src/services/httpService';
import GraficoBowTieView from 'src/paginas/gestao-risco/componentes/GraficoBowTieView';
import { MODULOS } from 'src/utils/constants';

import Schema from './Schema';
import RiscoPontosProblematicos from '../../RiscoEditView/RiscoPontoControleListView';
import Relacionamento from '../../../../item/relacionamento/view';
import CausasRisco from '../../RiscoEditView/causas-risco';
import ConsequenciasRisco from '../../RiscoEditView/consequencias-risco';
import GraficoScorePeriodo from './componentes/grafico-score-periodo';
import GraficoGauge from './componentes/grafico-gauge-avaliacao';
import LoadingContainer from 'src/componentes/loading-container';
@autobind
class RiscoAvaliacaoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracao: {},
      isLoading: { configuracaoRisco: true, configuracaoControle: true },
      configuracaoControle: {},
      changeValueInerente: 0,
      planoAcaoPreenchido: false,
      isLoadingAvaliacaoResidual: false,
      isLoadingCalculoTipoSeveridade: false,
      isLoadingCalculoTipoProbabilidade: false,
    };
  }

  componentDidMount() {
    getConfiguration('ItemConfiguracaoRisco')((configuracao) =>
      this.setState({
        ...this.state,
        configuracao,
        isLoading: { ...this.state.isLoading, configuracaoRisco: false },
      })
    )();
    getConfiguration('PontoControleConfiguracao')((configuracaoControle) =>
      this.setState({
        ...this.state,
        configuracaoControle,
        isLoading: { ...this.state.isLoading, configuracaoControle: false },
      })
    )();
  }

  onChangeValueInerente() {
    this.setState({ changeValueInerente: ++this.state.changeValueInerente });
  }
  renderGrauRisco(item) {
    return (
      <span>
        {item.grauRisco && item.grauRisco.cor && (
          <MdiIcon
            icon="circle"
            color={item.grauRisco.cor}
            colorHover={item.grauRisco.cor}
            size={22}
          ></MdiIcon>
        )}
      </span>
    );
  }

  handlePlanoAcaoClick(itemId, riscoAvaliacaoId = -1, title) {
    ContentManager.addContent(
      <PlanoAcaoModal
        model={{ item: { id: itemId }, riscoAvaliacaoId }}
        title={title}
        naoSalvarFiltro={true}
        showMenuAcao={false}
        showDelete={true}
      />
    );
  }

  hasValue(field) {
    return (
      this.props.model.getModel(field) && this.props.model.getModel(field).value
    );
  }

  efetividadePreenchida = () => {
    const {
      avaliarEfetividadeControleSeparadamente,
      avaliarEfetividadeControle,
      calcularEfetividadeConformeEfetividadeIndividual,
      gerarAvaliacaoRiscoResidualPelaEfetividade,
    } = this.state.configuracaoControle;
    const existeControle = this.hasValue('existeControle');
    const existeControlePreventivo = this.hasValue('existeControlePreventivo');
    const existeControleCorretivo = this.hasValue('existeControleCorretivo');

    if (
      !avaliarEfetividadeControle ||
      !calcularEfetividadeConformeEfetividadeIndividual ||
      !gerarAvaliacaoRiscoResidualPelaEfetividade ||
      !existeControle
    )
      return true;

    // if(efetividadePreenchida && !avaliarEfetividadeControleSeparadamente && !calcularEfetividadeConformeEfetividadeIndividual)
    //   return true;

    return avaliarEfetividadeControleSeparadamente
      ? (!existeControleCorretivo ||
          this.hasValue('efetividadeControleCorretivo')) &&
          (!existeControlePreventivo ||
            this.hasValue('efetividadeControlePreventivo'))
      : this.hasValue('efetividadeControle');
  };

  async calcularRiscoResidual() {
    if (
      !this.hasValue('severidadeInerente') ||
      !this.hasValue('probabilidadeInerente')
    )
      return;

    const {
      avaliarEfetividadeControleSeparadamente,
      calcularEfetividadeConformeEfetividadeIndividual,
      avaliarEfetividadeControle,
      gerarAvaliacaoRiscoResidualPelaEfetividade,
    } = this.state.configuracaoControle;

    const idCorretivo =
      avaliarEfetividadeControleSeparadamente &&
      this.hasValue('efetividadeControleCorretivo') &&
      this.hasValue('efetividadeControleCorretivo').id;
    const idPreventivo =
      avaliarEfetividadeControleSeparadamente &&
      this.hasValue('efetividadeControlePreventivo') &&
      this.hasValue('efetividadeControlePreventivo').id;

    const idEfetividade = avaliarEfetividadeControleSeparadamente
      ? idCorretivo + ';' + idPreventivo
      : this.props.model.getModel('efetividadeControle').value &&
        this.props.model.getModel('efetividadeControle').value.id;
    const dadosBaseCalculo =
      idEfetividade +
      ';' +
      this.props.model.getModel('severidadeInerente').value.id +
      ';' +
      this.props.model.getModel('probabilidadeInerente').value.id +
      ';' +
      this.props.model.getModel('impactoFinanceiroInerente')?.value;

    if (this.state.dadosBaseCalculo === dadosBaseCalculo) return;

    this.setState({ dadosBaseCalculo: dadosBaseCalculo });

    const bloquearAvaliacao =
      !this.props.model.getModel('avaliacaoPendente')?.value;

    if (
      avaliarEfetividadeControle &&
      !calcularEfetividadeConformeEfetividadeIndividual &&
      !avaliarEfetividadeControleSeparadamente &&
      !gerarAvaliacaoRiscoResidualPelaEfetividade &&
      !bloquearAvaliacao
    ) {
      let newModel = this.props.model.value;

      if (
        newModel.severidade &&
        newModel.probabilidade &&
        newModel.impactoFinanceiroInerente
      )
        return;

      newModel = {
        ...newModel,
        severidade: this.props.model.getModel('severidadeInerente').value,
        probabilidade: this.props.model.getModel('probabilidadeInerente').value,
        impactoFinanceiroResidual: this.props.model.getModel(
          'impactoFinanceiroInerente'
        ).value,
      };

      this.props.model.requestChange(newModel);

      return;
    }

    if (!bloquearAvaliacao) {
      await http
        .post('/RiscoAvaliacao/CalcularEfetividade', {
          model: this.props.model.getValue(),
        })
        .then((response) => {
          let newModel = this.props.model.value;
          if (response.data.severidadeCalculada)
            newModel.severidade = response.data.severidadeCalculada;

          if (response.data.probabilidadeCalculada)
            newModel.probabilidade = response.data.probabilidadeCalculada;

          newModel.impactoFinanceiroResidual =
            response.data.impactoFinanceiroCalculado;
          this.props.model.requestChange(newModel);
        });
    }
  }

  getRiscoId(item, parameters) {
    if (item && item.risco) return item.risco.id;
    else if (parameters && parameters.riscoId) return parameters.riscoId;
  }

  handleDeleteCausaRisco(item) {
    const model = this.props.model.getModel('causasRisco');
    let itens = model.value;
    const index = itens && itens.findIndex((prop) => prop.id === item.id);

    if (index != -1) {
      itens.splice(index, 1);
      model.requestChange(itens);
    }
  }

  handleDeleteConsequenciaRisco(item) {
    const model = this.props.model.getModel('consequenciasRisco');
    let itens = model.value;
    const index = itens && itens.findIndex((prop) => prop.id === item.id);

    if (index != -1) {
      itens.splice(index, 1);
      model.requestChange(itens);
    }
  }

  handleSaveConsequenciaRisco(item, handleClose) {
    const model = this.props.model.getModel('consequenciasRisco');
    let itens = model.value;

    if (!itens.find((x) => x.consequencia.id == item.consequenciaRisco.id)) {
      let consequencia = {
        id: -1,
        descricao: item.consequenciaRisco.descricao,
        consequencia: item.consequenciaRisco,
        permissions: { allowDelete: true, allowEdit: true },
      };
      itens.push(consequencia);
    }

    model.requestChange(itens);

    handleClose();
  }

  handleSaveCausaRisco(item, handleClose) {
    const model = this.props.model.getModel('causasRisco');
    let itens = model.value;

    if (!itens.find((x) => x.causa.id == item.causaRisco.id)) {
      let causa = {
        id: -1,
        descricao: item.causaRisco.descricao,
        causa: item.causaRisco,
        permissions: { allowDelete: true, allowEdit: true },
      };
      itens.push(causa);
    }

    model.requestChange(itens);

    handleClose();
  }

  afterSave() {
    this.props.afterSave();
  }

  requestChangeEfetividadeControle(value, field) {
    if (field) {
      const model = this.props.model.getModel(field);

      if (model) {
        this.props.model.getModel(field).requestChange(value);

        this.calcularRiscoResidual(value);
      }
    }
  }

  getAvatar(users, label) {
    return (
      users &&
      users.length > 0 && (
        <div>
          <div className={'ml-1'} style={{ marginBottom: '4px' }}>
            {capitalizeFirstLetter(label)}
          </div>
          <div>
            {users.map((user) => (
              <Avatar
                foto={user && user.fotoArquivo}
                onlyAvatar={false}
                disabled
                avatar
                label={user && user.nome}
                value={user && user.value}
              ></Avatar>
            ))}
          </div>
        </div>
      )
    );
  }

  getIdIntegracao = (idIntegracao, intl) => (
    <Input
      style={{ margin: 0 }}
      disabled
      model={{
        label: capitalizeFirstLetter(
          intl.formatMessage({ id: 'idIntegracao' })
        ),
        value: idIntegracao,
      }}
    />
  );

  getOrganizacao = (area) => (
    <Input
      style={{ margin: 0 }}
      disabled
      model={{
        label: capitalizeFirstLetter(this.props.resources.area),
        value: area && area.nome,
      }}
    />
  );

  setIsLoadingCalculoTipoSeveridade = (isLoading) => {
    this.setState({ isLoadingCalculoTipoSeveridade: isLoading });
  };
  
  setIsLoadingCalculoTipoProbabilidade = (isLoading) => {
    this.setState({ isLoadingCalculoTipoProbabilidade: isLoading });
  };

  setIsLoadingAvaliacaoResidual = (isLoading) => {
    this.setState({ isLoadingAvaliacaoResidual: isLoading });
  };

  render() {
    const { model, resources, intl, item, parameters, modules } = this.props;

    const {
      utilizarCausaRisco,
      utilizarConsequenciaRisco,
      avaliarRiscoInerente,
      exibirDiagramaBowTie,
      tornarDefinicaoPlanoDeAcaoObrigatoria,
      habilitarCalculoDeScores,
    } = this.state.configuracao;

    const {
      gerarAvaliacaoRiscoResidualPelaEfetividade,
      avaliarEfetividadeControle,
    } = this.state.configuracaoControle;

    const riscoId = this.getRiscoId(item, parameters);
    const idAvaliacao = item && item.id;
    const itensImpactados = model.getModel('itensImpactados').value;
    const bloquearAvaliacao =
      model.getModel('avaliacaoPendente') &&
      !model.getModel('avaliacaoPendente').value;
    const disabled =
      model && !model.getModel('permissions').getModel('allowEdit').value;

    const modelo =
      model.getModel('modeloRisco') && model.getModel('modeloRisco').value;

    const isEstrategico = modelo && modelo.id == 1 ? true : false;
    const isOperacional = modelo && modelo.id == 2 ? true : false;
    const isProjeto = modelo && modelo.id == 3 ? true : false;

    const modeloRisco = {
      isEstrategico: isEstrategico,
      isOperacional: isOperacional,
      isProjeto: isProjeto,
    };

    const modelAvaliacaoAnterior = model.getModel('avaliacaoAnterior').value;

    if (
      tornarDefinicaoPlanoDeAcaoObrigatoria &&
      this.props.checkRequiredCustom
    ) {
      if (this.state.callRequired != this.props.checkRequiredCustom) {
        this.setState({ callRequired: this.props.checkRequiredCustom });
        http
          .post('riscoAvaliacao/planoAcaoPreenchido', {
            avaliacaoId: idAvaliacao,
          })
          .then((response) => {
            this.setState({
              showErrorRequired: !response.data.planoAcaoPreenchido,
              planoAcaoPreenchido: response.data.planoAcaoPreenchido,
            });
          });
      }
    }

    const hasGR = modules.find((m) => m.id === MODULOS.GESTAO_RISCO && m.ativo);

    return (
      <div>
        <LoadingContainer
          isLoading={
            this.state.isLoading.configuracaoRisco ||
            this.state.isLoading.configuracaoControle
          }
        >
          <Row>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <div style={{ flexGrow: 1 }}>
                    {this.getIdIntegracao(
                      model.getModel('idIntegracao').value,
                      intl
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div style={{ flexGrow: 1 }}>
                    {model.getModel('area') &&
                      this.getOrganizacao(model.getModel('area').value)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-1">
                  <div>
                    {this.getAvatar(
                      model.getModel('responsaveis').value,
                      intl.formatMessage(
                        { id: 'responsaveisRisco' },
                        { risco: resources.risco }
                      )
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-3 mb-3">
                  <div style={{ flexGrow: 3 }}>
                    {this.getAvatar(
                      [model.getModel('responsavel').value],
                      intl.formatMessage(
                        { id: 'label.responsavelAvaliacao' },
                        { avaliacao: resources.avaliacao }
                      )
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={8}>
              {habilitarCalculoDeScores && (
                <PanelCollapse>
                  <Col md={12}>
                    <GraficoScorePeriodo riscoId={riscoId} />
                  </Col>
                </PanelCollapse>
              )}
            </Col>
          </Row>
          {itensImpactados != null && itensImpactados.length > 0 && (
            <Relacionamento
              header={this.props.intl.formatMessage(
                { id: 'label.relacionamentos' },
                { relacionamentos: this.props.resources.relacionamentos }
              )}
              id={riscoId}
              openLastFilter={false}
              showToolbar={false}
              dataInicio={false}
              dataTermino={false}
              tipoFiltroDataId={4}
              periodicidadeId={4}
              exibirAcumulado={false}
              exibirTabela={true}
              exibirGrafico={true}
              exibirRiscos={true}
              exibirProcessos={true}
              ehRiscoAvaliaco={true}
            ></Relacionamento>
          )}
          <Row>
            {exibirDiagramaBowTie && hasGR && (
              <Col md={12}>
                <PanelCollapse
                  open={true}
                  header={capitalizeFirstLetter(
                    intl.formatMessage({ id: 'analiseBowTie' })
                  )}
                >
                  <GraficoBowTieView riscoId={riscoId} />
                </PanelCollapse>
              </Col>
            )}
          </Row>
          {!exibirDiagramaBowTie && (
            <Row>
              {utilizarCausaRisco && (
                <Col md={6}>
                  <PanelCollapse
                    open={true}
                    header={uperCaseAllLetter(
                      intl.formatMessage(
                        { id: 'labelCausasDoRisco' },
                        {
                          pontosproblematicos: resources.pontosproblematicos,
                          risco: resources.risco,
                        }
                      )
                    )}
                  >
                    <CausasRisco
                      riscoId={riscoId}
                      model={model.getModel('causasRisco')}
                      handleSave={this.handleSaveCausaRisco}
                      onDeleting={this.handleDeleteCausaRisco}
                      disabled={true}
                    />
                  </PanelCollapse>
                </Col>
              )}
              {utilizarConsequenciaRisco && (
                <Col md={6}>
                  <PanelCollapse
                    open={true}
                    header={uperCaseAllLetter(
                      intl.formatMessage(
                        { id: 'labelConsequenciasDoRisco' },
                        {
                          consequencias: resources.consequencias,
                          risco: resources.risco,
                        }
                      )
                    )}
                  >
                    <ConsequenciasRisco
                      riscoId={riscoId}
                      model={model.getModel('consequenciasRisco')}
                      handleSave={this.handleSaveConsequenciaRisco}
                      onDeleting={this.handleDeleteConsequenciaRisco}
                      disabled={true}
                    />
                  </PanelCollapse>
                </Col>
              )}
            </Row>
          )}
          <Row>
            {avaliarRiscoInerente && (
              <Col md={12}>
                <PanelCollapse
                  header={capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'avaliacaoRiscoInerente' },
                      { avaliacao: resources.avaliacao, risco: resources.risco }
                    )
                  )}
                >
                  <Row>
                    <Col md={6}>
                      <GraficoGauge
                        avaliacaoModel={modelAvaliacaoAnterior}
                        modelOrigem={model}
                        dataAvaliacaoAtual={moment(
                          model.getModel('dataAvaliacao').value
                        ).format('L')}
                        riscoId={riscoId}
                        {...this.props}
                        disabled={true}
                        avaliacaoAnterior={true}
                        avaliacaoInerente={true}
                        idAvaliacao={idAvaliacao}
                      />
                    </Col>
                    <Col md={6}>
                      <GraficoGauge
                        afterChangeScore={this.calcularRiscoResidual.bind(this)}
                        avaliacaoInerente={true}
                        avaliacaoModel={model}
                        idAvaliacao={idAvaliacao}
                        modelOrigem={model}
                        riscoId={riscoId}
                        setIsLoadingAvaliacaoResidual={(isLoading) => this.setIsLoadingAvaliacaoResidual(isLoading)}
                        isLoadingAvaliacaoResidual={this.state.isLoadingAvaliacaoResidual}
                        setIsLoadingCalculoTipoSeveridade={(isLoading) => this.setIsLoadingCalculoTipoSeveridade(isLoading)}
                        isLoadingCalculoTipoSeveridade={this.state.isLoadingCalculoTipoSeveridade}
                        setIsLoadingCalculoTipoProbabilidade={(isLoading) => this.setIsLoadingCalculoTipoProbabilidade(isLoading)}
                        isLoadingCalculoTipoProbabilidade={this.state.isLoadingCalculoTipoProbabilidade}
                        {...this.props}
                        disabled={bloquearAvaliacao || disabled}
                        avaliacaoAnterior={false}
                        onChangeValueInerente={this.onChangeValueInerente}
                      />
                    </Col>
                  </Row>
                </PanelCollapse>
              </Col>
            )}
          </Row>
          <RiscoPontosProblematicos
            modeloRisco={modeloRisco}
            riscoId={riscoId}
            showPaginacaoSimples={true}
            disabled={true}
            pageSize={5}
            model={model}
            configuracaoAvaliacao={this.state.configuracaoControle}
            modelOrigem={{
              label: capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'efetividadeDosControles' },
                  { pontosdecontrole: resources.pontosdecontrole }
                )
              ),
              value: model.getModel('efetividadeControle').value,
              requestChange: (value) =>
                this.requestChangeEfetividadeControle(
                  value,
                  'efetividadeControle'
                ),
            }}
            modelCorretivo={{
              label: capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'efetividadeDosControlesCorretivos' },
                  { pontosdecontrole: resources.pontosdecontrole }
                )
              ),
              value: model.getModel('efetividadeControleCorretivo').value,
              requestChange: (value) =>
                this.requestChangeEfetividadeControle(
                  value,
                  'efetividadeControleCorretivo'
                ),
            }}
            modelPreventivo={{
              label: capitalizeFirstLetter(
                intl.formatMessage(
                  { id: 'efetividadeDosControlesPreventivos' },
                  { pontosdecontrole: resources.pontosdecontrole }
                )
              ),
              value: model.getModel('efetividadeControlePreventivo').value,
              requestChange: (value) =>
                this.requestChangeEfetividadeControle(
                  value,
                  'efetividadeControlePreventivo'
                ),
            }}
            showEfetividadeControlePanel={avaliarEfetividadeControle}
            disabledEfetividadeControlePanel={
              bloquearAvaliacao ||
              disabled ||
              (model.getModel('efetividadeAutomatica') &&
                model.getModel('efetividadeAutomatica').value)
            }
            efetividadeAutomatica={
              model.getModel('efetividadeAutomatica') &&
              model.getModel('efetividadeAutomatica').value
            }
            requiredEfetividadeControlePanel={
              //Deixar trecho comentado para testes de outros cenários com os clientes, e caso necessite de voltar seja mais rápido.
              /*habilitarCalculoDeScores &&
              gerarAvaliacaoRiscoResidualPelaEfetividade &&
              avaliarEfetividadeControle &&
              avaliarRiscoInerente*/
              false
            }
          />
          <Row>
            <Col md={12}>
              <PanelCollapse
                header={capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'avaliacaoRiscoResidual' },
                    { avaliacao: resources.avaliacao, risco: resources.risco }
                  )
                )}
              >
                <Row>
                  <Col md={6}>
                    <GraficoGauge
                      avaliacaoModel={modelAvaliacaoAnterior}
                      modelOrigem={model}
                      dataAvaliacaoAtual={moment(
                        model.getModel('dataAvaliacao').value
                      ).format('L')}
                      riscoId={riscoId}
                      {...this.props}
                      disabled={true}
                      avaliacaoAnterior={true}
                      idAvaliacao={idAvaliacao}
                    />
                  </Col>
                  <Col md={6}>
                    <GraficoGauge
                      efetividadePreenchida={() => this.efetividadePreenchida()}
                      avaliacaoModel={model}
                      idAvaliacao={idAvaliacao}
                      modelOrigem={model}
                      riscoId={riscoId}
                      {...this.props}
                      isLoadingAvaliacaoResidual = {this.state.isLoadingAvaliacaoResidual}
                      setIsLoadingAvaliacaoResidual={(isLoading) => this.setIsLoadingAvaliacaoResidual(isLoading)}
                      setIsLoadingCalculoTipoSeveridade={(isLoading) => this.setIsLoadingCalculoTipoSeveridade(isLoading)}
                      setIsLoadingCalculoTipoProbabilidade={(isLoading) => this.setIsLoadingCalculoTipoProbabilidade(isLoading)}
                      disabledDadosCalculoScore={
                        gerarAvaliacaoRiscoResidualPelaEfetividade &&
                        avaliarRiscoInerente &&
                        avaliarEfetividadeControle
                      }
                      disabled={bloquearAvaliacao || disabled || this.state.isLoadingAvaliacaoResidual}
                      avaliacaoAnterior={false}
                      planoAcaoObrigatorioAvaliacao={
                        tornarDefinicaoPlanoDeAcaoObrigatoria
                      }
                      planoAcaoPreenchido={this.state.planoAcaoPreenchido}
                      showErrorRequired={
                        this.props.showErrorRequired ||
                        this.state.showErrorRequired
                      }
                      changeValueInerente={this.state.changeValueInerente}
                    />
                  </Col>
                </Row>
              </PanelCollapse>
            </Col>
          </Row>
        </LoadingContainer>
      </div>
    );
  }
}

class RiscoAvaliacaoEdit extends React.Component {
  render() {
    let { resources, parameters, afterSave, showErrorRequired } = this.props;

    return (
      <EditDialog
        url="/RiscoAvaliacao"
        title={capitalizeFirstLetter(
          this.props.intl.formatMessage(
            { id: 'avaliacaoDeRisco' },
            { risco: resources.risco, avaliacao: resources.avaliacao }
          )
        )}
        titleField="nomeRisco"
        ignoreResourceTitle={true}
        parameters={parameters}
        formComponent={RiscoAvaliacaoForm}
        schema={Schema}
        width="85%"
        height={600}
        showErrorRequired={showErrorRequired}
        resources={resources}
        afterSave={afterSave}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    tiposAnexos: state.user.tiposAnexos,
    modules: state.user.licenca.modulos,
  };
}
export default injectIntl(connect(mapStateToProps)(RiscoAvaliacaoEdit));
