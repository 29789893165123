import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import Table from '../../../../../../componentes/table';
import { Row, Col } from 'reactstrap';
import Button from '../../../../../../componentes/button';
import IconButton from '../../../../../../componentes/icon-button';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from '../../../../../../utils/string';
import { connect } from 'react-redux';

@autobind
class ItensAssociados extends React.Component {
  handleSelect(itens) {
    let itensAtuais = this.props.model.value.map((d) => d);
    itensAtuais.map((item) => itens.push(item));

    this.props.model.requestChange([...itens]);
  }

  handleRemove(index) {
    let novaLista = this.props.model.value;
    novaLista.splice(index, 1);
    this.props.model.requestChange(novaLista);
  }

  getTipo(string) {
    let tipo = '';

    for (let i = 0; i < string.length; i++) {
      const char = string[i];

      if (char != '{' && char != '}') tipo += char;
    }

    return tipo;
  }

  render() {
    let { model, allowNew, resources, handleNewClick } = this.props;

    return (
      <div>
        <Row>
          <Col md={6}>{model.label && <label className="control-label">{model.label}</label>}</Col>
          <Col md={6}>
            {allowNew && (
              <Button className="pull-right" leftIcon="magnify" type="primary" style={{ marginBottom: 10 }} onClick={handleNewClick}>
                <FormattedMessage id="label.buscar" />
              </Button>
            )}
          </Col>
        </Row>
        {model.value && model.value.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="nome" />
                </th>
                <th>
                  <FormattedMessage id="label.tipoDoItem" />
                </th>
                <th>{capitalizeFirstLetter(resources.responsaveis)}</th>
                <th>{capitalizeFirstLetter(resources.area)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {model.value &&
                model.value.map((item, index) => (
                  <tr>
                    <td>{item.nome}</td>
                    <td>{item.tipo && capitalizeFirstLetter(item.tipoNome ? item.tipoNome : resources[this.getTipo(item.tipo.descricao)])}</td>
                    <td>{item.responsaveis}</td>
                    <td>{item.area}</td>
                    <td style={{ width: 40 }}>{allowNew && <IconButton icon="close-circle" className="clarear" onClick={this.handleRemove.bind(this, index)} />}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <i>
            <FormattedMessage id="label.naoHaItensRelacionados" />
          </i>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ItensAssociados));
