import Table from 'src/componentes/table';
import { Row, Col } from 'reactstrap';
import Button from 'src/componentes/button';
import IconButton from 'src/componentes/icon-button';
import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';
import RiscosAssociadosAdd from './RiscosAssociadosAdd';
import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';

const RiscosAssociadosView = ({
  model,
  allowNew,
  selectedCallback,
  requestChange,
  fieldName,
  ...rest
}) => {
  const { terms: resources } = useAuth();
  const intl = useIntl();

  const handleNewClick = () => {
    ContentManager.addContent(
      <RiscosAssociadosAdd
        parameters={{ parentId: 0, itemIdIgnorar: rest.itemIdIgnorar }} // somente itens que não tenham pai
        showModal={true}
        removeDialog={true}
        multiple
        disabled
        showExport={false}
        select={true}
        selectedCallback={selectedCallback}
        selectedIds={model}
        selectedItems={model}
        {...rest}
      />
    );
  };

  const handleRemove = (index) => {
    let novaLista = model;
    novaLista.splice(index, 1);
    requestChange(fieldName, novaLista);
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          {model?.label && (
            <label className="control-label">{model.label}</label>
          )}
        </Col>
        <Col md={6}>
          {allowNew && (
            <Button
              className="pull-right"
              leftIcon="magnify"
              type="primary"
              style={{ marginBottom: 10 }}
              onClick={handleNewClick}
            >
              {intl.formatMessage({ id: 'label.buscar' })}
            </Button>
          )}
        </Col>
      </Row>
      {model && model.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'nome' })}</th>
              <th>
                {intl.formatMessage(
                  { id: 'matrizRisco' },
                  { risco: resources.risco }
                )}
              </th>
              <th>{capitalizeFirstLetter(resources.responsaveis)}</th>
              <th>{capitalizeFirstLetter(resources.area)}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {model &&
              model.map((item, index) => (
                <tr>
                  <td>{item.nome}</td>
                  <td>{item.matrizRisco}</td>
                  <td>{item.responsaveis}</td>
                  <td>{item.area}</td>
                  <td style={{ width: 40 }}>
                    {allowNew && (
                      <IconButton
                        icon="close-circle"
                        className="clarear"
                        onClick={() => handleRemove(index)}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <i>{intl.formatMessage({ id: 'label.naoHaItensRelacionados' })}</i>
      )}
    </div>
  );
};
export default RiscosAssociadosView;
