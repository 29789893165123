import { FormattedMessage } from 'react-intl';
import { Schema } from 'src/utils/form';

const schema = Schema.object({
  dataOcorrencia: Schema.string()
    .label(<FormattedMessage id="dataOcorrencia" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  responsavel: Schema.string()
    .label(<FormattedMessage id="label.responsavel" />)
    .required(),
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  escalas: Schema.array().label(<FormattedMessage id="escalas" />),

  detalhamentoOcorrencia: Schema.string().label(
    <FormattedMessage id="detalhamentoOcorrencia" />
  ),
  severidade: Schema.string().label(
    <FormattedMessage id="labelSeveridadeInerente" />
  ),
  comentario: Schema.string().label(<FormattedMessage id="campoComentario" />),
  respostaOcorrencia: Schema.string()
    .label(<FormattedMessage id="respostaOcorrencia" />)
    .required({
      onlyIf: (context) => {
        return (
          context.parent.value.utilizarCampoRespostaEventoRisco &&
          context.parent.value.definicaoRespostaEventoRiscoObrigatorio
        );
      },
    }),
  acoes: Schema.string().required({
    onlyIf: (context) => {
      return (
        context.parent.value.criarPlanoDeAcaoRespostaOcorrencia &&
        context.parent.value.tornarDefinicaoPlanoDeAcaoObrigatoria
      );
    },
  }),
  arquivosAvaliacao: Schema.array().label(
    <FormattedMessage id="label.anexos" />
  ),
});

export default schema;
