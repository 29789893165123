import { useState, useEffect, useCallback } from 'react';
import PanelCollapse from 'src/componentes/panel-collapse';
import ProbabilidadeRiscoLookup from 'src/lookups/probabilidade-risco';
import { capitalizeFirstLetter } from 'src/utils/string';
import Info from 'src/componentes/info';
import { Row, Col, Label } from 'reactstrap';
import http from 'src/services/httpService';
import { getConfiguration } from 'src/services/commonRequests';
import LoadingContainer from 'src/componentes/loading-container';
import { useIntl } from 'react-intl';
import { Input } from 'src/componentes/Form';
import { debounce } from 'lodash';
import errorHandler from 'src/utils/errorHandler';

export default function AvaliacaoTipoProbabilidade({
  inerente,
  avaliacaoModel,
  avaliacaoAnterior,
  disabled,
  descricaoLookups,
  onChangeValueInerente,
  configuracao = {},
  configuracaoAvaliacao = {},
  changeValueInerente,
  resources,
  requestChangeProbabilidade,
  disabledDadosCalculoScore,
  setIsLoadingAvaliacaoResidual = () => {},
  isLoadingAvaliacaoResidual = false,
  setIsLoadingCalculoTipoProbabilidade = () => {},
  isLoadingCalculoTipoProbabilidade = false,
}) {
  const [tiposProbabilidade, setTiposProbabilidades] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [itensLookup, setItensLookup] = useState(null);
  const [descricoesTipos, setDescricoesTipos] = useState([]);
  const [descricoesTiposCarregadas, setDescricoesTiposCarregadas] = useState(false);
  const intl = useIntl();
  const modelName = 'valoresTipoProbabilidade';
  const infoName =
    'infoTipoProbabilidadeDescricao' + inerente + descricaoLookups;
  const tipoRegistro = inerente ? { id: 1 } : { id: 2 };
  const [debouncedValue, setDebouncedValue] = useState('');
  const [
    timeoutCalculoTipoProbabilidadeId,
    setTimeoutCalculoTipoProbabilidadeId,
  ] = useState(null);
  const [timeoutCalculoGeralId, setTimeoutCalculoGeralId] = useState(null);

  const debouncedSetMyValue = debounce((value) => {
    setDebouncedValue(value);
  }, 2000);

  useEffect(() => {
    debouncedSetMyValue(changeValueInerente);
  }, [changeValueInerente]);

  useEffect(() => {
    if (configuracaoAvaliacao?.avaliarProbabilidadePorTipo) getColumns();
    else if (configuracaoAvaliacao && !configuracaoAvaliacao.avaliarProbabilidadePorTipo)
      setIsLoading(false);
  }, [configuracaoAvaliacao]);

  const getColumns = useCallback(async () => {
    setIsLoading(true);
    await http
      .post(`/TipoProbabilidade/List`, {
        parameters: { buscarExcluidos: true },
        pageSize: 1000,
      })
      .then((response) => {
        setTiposProbabilidades(response.data.list);
        setIsLoading(false);
      });
  });

  const getValue = (item, tipo, ehComentario = false) => {
    if (avaliacaoAnterior) {
      if (item == 'valoresTipoProbabilidade' && avaliacaoModel && tipo) {
        const dados = avaliacaoModel[item];
        const result = dados?.find(
          (valor) =>
            valor.tipoProbabilidade?.id == tipo?.id &&
            valor.tipoRegistro?.id == tipoRegistro?.id
        );
        return ehComentario ? result?.comentario : result?.probabilidade;
      }

      if (avaliacaoModel) return avaliacaoModel[item];

      return null;
    }

    if (item == 'valoresTipoProbabilidade' && tipo) {
      const tipoProbabilidadeValue = avaliacaoModel.getModel(item).value;
      const result = tipoProbabilidadeValue?.find(
        (valor) =>
          valor.tipoProbabilidade?.id == tipo?.id &&
          valor.tipoRegistro?.id == tipoRegistro?.id
      );
      return ehComentario ? result?.comentario : result?.probabilidade;
    } else {
      return avaliacaoModel.getModel(item).value;
    }
  };

  const renderDescricaoArray = (tipo) => {
    const infos = descricoesTipos?.filter(
      (item) => item.tipoProbabilidade.id == tipo.id && item.descricao
    );
    if (infos && infos.length > 0) {
      return (
        <table>
          <tbody>
            {infos.map((obj) => (
              <tr>
                <td style={{ fontWeight: 'bold' }}>{obj.nomeProbabilidade}:</td>
                <td style={{ textAlign: 'left' }}>{obj.descricao}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return (
        <table>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold' }}>
                {intl.formatMessage({ id: 'labelNenhumDescricaoInformada' })}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  const requestChange = (value, tipo, comentario = false) => {
    const currentModel = avaliacaoModel.getModel(modelName);
    let valores = currentModel.value;
    if (!valores) valores = [];

    var itemValor = valores.find(
      (item) =>
        item.tipoProbabilidade.id == tipo.id &&
        item.tipoRegistro.id == tipoRegistro.id
    );

    if (comentario) {
      if (itemValor) {
        itemValor.comentario = value;
      } else {
        valores.push({
          comentario: value,
          tipoProbabilidade: tipo,
          tipoRegistro: tipoRegistro,
        });
      }
    } else {
      if (itemValor) {
        itemValor.probabilidade = value;
      } else {
        valores.push({
          probabilidade: value,
          tipoProbabilidade: tipo,
          tipoRegistro: tipoRegistro,
        });
      }
    }

    currentModel.requestChange(valores);

    preencherComentarioPorTipo(valores);
    if (inerente && onChangeValueInerente) onChangeValueInerente();

    if (timeoutCalculoGeralId) {
      clearTimeout(timeoutCalculoGeralId);
    }

    const newTimeoutId = setTimeout(
      () => calcularProbabilidadeGeral(valores),
      3000
    );
    setTimeoutCalculoGeralId(newTimeoutId);
  };

  const preencherComentarioPorTipo = (valoresAtuais = null) => {
    if (!configuracao || !avaliacaoModel?.getModel) return;

    const currentModel = avaliacaoModel.getModel(modelName);
    const valores = valoresAtuais ? valoresAtuais : currentModel.value;

    const countTipoProbabilidade = tiposProbabilidade
      ? tiposProbabilidade.filter((f) => !f.excluido).length
      : 0;

    const comentarioTipoProbabilidadeField = avaliacaoModel.getModel(
      'comentarioTipoProbabilidade'
    );

    if (
      valores &&
      valores.filter((i) => !i.comentario).length === 0 &&
      ((!configuracao.avaliarRiscoInerente &&
        countTipoProbabilidade &&
        countTipoProbabilidade === valores.length) ||
        (configuracao &&
          configuracao.avaliarRiscoInerente &&
          countTipoProbabilidade &&
          countTipoProbabilidade * 2 === valores.length))
    ) {
      if (comentarioTipoProbabilidadeField.value !== 'OK')
        comentarioTipoProbabilidadeField.requestChange('OK');
    } else if (!countTipoProbabilidade || countTipoProbabilidade === 0) {
      comentarioTipoProbabilidadeField.requestChange('OK');
    } else {
      comentarioTipoProbabilidadeField.requestChange(null);
    }
  };

  useEffect(() => {
    preencherComentarioPorTipo();
  }, [getValue('valoresTipoProbabilidade'), tiposProbabilidade]);

  const requestChangeComentario = (value, tipo) => {
    requestChange(value, tipo, true);
    /*const currentModel = avaliacaoModel.getModel(modelName);
    let valores = currentModel.value;
    if (!valores) valores = [];

    var itemValor = valores.find(
      (item) =>
        item.tipoProbabilidade.id == tipo.id &&
        item.tipoRegistro.id == tipoRegistro.id
    );

    if (itemValor) {
      itemValor.comentario = value;
    } else {
      valores.push({
        comentario: value,
        tipoProbabilidade: tipo,
        tipoRegistro: tipoRegistro,
      });
    }

    if (configuracao.comentarioPorTipoProbabilidadeObrigatorio) {
      const comentariosPreenchidos = valores.filter(
        (i) => i.tipoRegistro?.id === 1 && i.comentario != null
      ).length;
      if (
        comentariosPreenchidos >=
        tiposProbabilidade.filter((t) => !t.excluido || getValue(modelName, t))
          .length
      ) {
        avaliacaoModel.getModel('comentarioTipoProbabilidade').requestChange(true);
      }
    }
    currentModel.requestChange(valores);*/
  };

  const calcularProbabilidadeGeral = (valores) => {
    if (
      configuracaoAvaliacao &&
      configuracaoAvaliacao.avaliarProbabilidadePorTipo &&
      configuracaoAvaliacao.calcularProbabilidadeGeralPorTipo.id > 1 &&
      requestChangeProbabilidade
    ) {
      setIsLoadingAvaliacaoResidual(true);
      const riscoAvaliacaoId = avaliacaoModel.getModel('id').value;
      http
        .post(`RiscoAvaliacao/CalcularProbabilidadeGeral`, {
          valoresTipoProbabilidade: valores,
          riscoAvaliacaoId: riscoAvaliacaoId,
          tipoRegistro: tipoRegistro,
        })
        .then((response) => {
          requestChangeProbabilidade(response.data.probabilidadeCalculada);
        })
        .finally(() => setIsLoadingAvaliacaoResidual(false));
    }
  };
  const loadDataLookup = useCallback(() => {
    const data = { idsSelecionados: getValue('listaProbabilidadeIdMatriz') };

    setIsLoading(true);
    http
      .post(`ProbabilidadeRiscoLookup/Search`, {
        filter: data,
        pageSize: 1000,
      })
      .then((response) => {
        setItensLookup(response.data.list);
      })
      .finally(setIsLoading(false));
  });

  const loadDescricoesLookup = useCallback(async () => {
    if ((descricoesTipos && descricoesTipos.length > 0) || (descricoesTiposCarregadas)) return;
    setIsLoading(true);

    try {
      const response = await http.post(`TipoProbabilidadeDescricao/ObterDescricoes`);
      setDescricoesTipos(response.data);
      setDescricoesTiposCarregadas(true);
    } catch (error) {
      errorHandler(error, intl);
    } finally {
      setIsLoading(false);
    }
  }, [descricoesTipos, intl]);

  useEffect(() => {
    loadDescricoesLookup();
  }, [loadDescricoesLookup]);

  useEffect(() => {
    loadDataLookup();
  }, [getValue('listaProbabilidadeIdMatriz')]);

  const getValoresInerentes = () => {
    if (!avaliacaoModel || !avaliacaoModel.getModel) return null;

    const currentModel = avaliacaoModel.getModel(modelName);

    if (!inerente && currentModel.value && currentModel.value.length > 0)
      return avaliacaoModel
        .getModel(modelName)
        .value.filter((item) => item.tipoRegistro.id == 1);
  };

  const calcularValoresResiduais = () => {
    if (!disabledDadosCalculoScore) return;

    if (!inerente && !avaliacaoAnterior) {
      const valoresInerentes = getValoresInerentes();
      if (avaliacaoModel && valoresInerentes && valoresInerentes.length > 0) {
        //const riscoAvaliacaoId = avaliacaoModel.getModel('id').value;
        setIsLoadingCalculoTipoProbabilidade(true);
        http
          .post('/RiscoAvaliacao/CalcularEfetividadeTipoProbabilidade', {
            model: avaliacaoModel.getValue(),
          })
          .then((response) => {
            if (response.data.probabilidadesCalculadas) {
              if (
                JSON.stringify(response.data.probabilidadesCalculadas) !=
                JSON.stringify(avaliacaoModel.getModel(modelName).value)
              )
                avaliacaoModel
                  .getModel(modelName)
                  .requestChange(response.data.probabilidadesCalculadas);
            }
          })
          .finally(() => setIsLoadingCalculoTipoProbabilidade(false));
      }
    }
  };

  useEffect(() => {
    if (timeoutCalculoTipoProbabilidadeId) {
      clearTimeout(timeoutCalculoTipoProbabilidadeId);
    }

    const newTimeoutId = setTimeout(calcularValoresResiduais, 3000);
    setTimeoutCalculoTipoProbabilidadeId(newTimeoutId);
  }, [debouncedValue]);

  return (
    <>
      {tiposProbabilidade && (
        <PanelCollapse
          header={intl.formatMessage(
            { id: 'labelAvaliacaoTipoSeveridade' },
            { severidade: resources.probabilidade }
          )}
        >
          <LoadingContainer
            isLoading={
              isLoading ||
              isLoadingAvaliacaoResidual ||
              isLoadingCalculoTipoProbabilidade
            }
          >
            <Row>
              {tiposProbabilidade &&
                tiposProbabilidade?.map(
                  (tipo, index) =>
                    (!tipo.excluido || getValue(modelName, tipo)) && (
                      <Col
                        key={`col-tipoProbabilidade-${index}`}
                        md={6}
                        className="mb-3"
                      >
                        <ProbabilidadeRiscoLookup
                          //required={!avaliacaoAnterior}
                          disabled={
                            disabled || isLoadingCalculoTipoProbabilidade
                          }
                          key={`probabilidade-${tipo.id}-${index}`}
                          clearable
                          model={{
                            label: (
                              <span>
                                {capitalizeFirstLetter(tipo.nome)}
                                <Info
                                  id={`${infoName}-${tipo.id}`}
                                  tooltipMaxWidth={600}
                                  placement="auto"
                                  renderContent={() =>
                                    renderDescricaoArray(tipo)
                                  }
                                />
                              </span>
                            ),
                            value: getValue(modelName, tipo),
                            requestChange: (value) =>
                              requestChange(value, tipo),
                            errors:
                              !avaliacaoAnterior &&
                              avaliacaoModel.getModel(modelName).errors,
                          }}
                          options={itensLookup}
                        />
                        {configuracaoAvaliacao.permitirComentariosPorTipoProbabilidade && (
                          <>
                            <Label>
                              {capitalizeFirstLetter(
                                intl.formatMessage({ id: 'comentario' })
                              ) +
                                ' - ' +
                                capitalizeFirstLetter(tipo.nome)}
                              {configuracaoAvaliacao.comentarioPorTipoProbabilidadeObrigatorio
                                ? ' *'
                                : ''}
                            </Label>
                            <Input
                              key={`probabilidade-comentario-${tipo.id}-${index}`}
                              disabled={
                                avaliacaoAnterior ||
                                disabled ||
                                isLoadingCalculoTipoProbabilidade
                              }
                              maxLength={5000}
                              type="textarea"
                              rows={2}
                              value={getValue(modelName, tipo, true)}
                              onChange={(e) =>
                                requestChangeComentario(e.target.value, tipo)
                              }
                              onBlur={() =>
                                onChangeValueInerente && onChangeValueInerente()
                              }
                              invalid={
                                configuracaoAvaliacao.comentarioPorTipoProbabilidadeObrigatorio &&
                                !getValue(modelName, tipo, true) &&
                                !avaliacaoAnterior &&
                                avaliacaoModel.getModel(
                                  'comentarioTipoProbabilidade'
                                ).errors
                              }
                            />
                          </>
                        )}
                      </Col>
                    )
                )}
            </Row>
          </LoadingContainer>
        </PanelCollapse>
      )}
    </>
  );
}
