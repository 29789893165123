import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class ProbabilidadeRiscoLookup extends React.Component {
  render() {
    return <Lookup title="nome" labelKey="nome" valueKey="id" url="/ProbabilidadeRiscoLookup" clearable={false} {...this.props} />;
  }
}

export default ProbabilidadeRiscoLookup;
