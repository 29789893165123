import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../componentes/list/tree';
import ListColumn from '../../componentes/list/list-column';
import AreaEdit from './edit';
import Content from '../../componentes/pages/content';
import ContentManager from '../../componentes/content-manager';
import Search from './search';
import SearchModal from './search-modal';
import SearchSchema from './search-schema';
import SearchSchemaModal from './search-schema-modal';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import ValidacaoRestricao from '../item/validacao-restricao';
import history from '../../history';

@autobind
class Organizao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alertVisible: true,
      saved: false,
    };
  }

  handleNewClick(item, parameters, handleEditClose) {
    ContentManager.addContent(
      <AreaEdit
        mode={<FormattedMessage id="label.edit" />}
        parameters={{ ...parameters, parentId: item.id }}
        handleClose={handleEditClose}
      />
    );
  }

  handleClose(saved) {
    if (saved) this.setState({ saved: !this.state.saved });
  }

  handleRelacionamentosClick(item) {
    history.push({
      pathname: '/gerenciar/arvoreArea',
      search: `?id=${item.id}`,
    });
  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/Organizacao/ValidarRestricoes"
        urlExclusao="/Organizacao/AtualizarStatusExcluido"
        item={item}
        onClose={this.handleClose}
      />
    );
  }

  renderNome(item) {
    const handleEdit = () => {
      ContentManager.addContent(
        <AreaEdit
          handleClose={(saved) => {
            saved &&
              this.setState({
                saved: !this.state.saved,
              });
          }}
          showDelete={true}
          item={item}
        />
      );
    };

    return (
      <a
        className="font-weight-bold text-primary cursor-pointer"
        onClick={() => handleEdit(item)}
      >
        {item.nome}
      </a>
    );
  }

  render() {
    let { resources, intl, showModal, configuracao } = this.props;

    let createChildItems = [
      {
        label: <FormattedMessage id="label.empresaSubordinada" />,
        icon: 'format-list-bulleted',
        parameters: { tipoId: 1 },
        visible: (item) => item.tipoId == 1,
        onClick: this.handleNewClick,
      },
      {
        label: capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'label.areaSubordinada' },
            { area: resources.area }
          )
        ),
        icon: 'format-list-bulleted',
        parameters: { tipoId: 2 },
        visible: (item) => item.tipoId == 1 || item.tipoId == 2,
        onClick: this.handleNewClick,
      },
      {
        label: capitalizeFirstLetter(resources.relacionamentos),
        icon: 'sitemap',
        visible: () => true,
        onClick: this.handleRelacionamentosClick,
      },
    ];

    return (
      <Content titulo={capitalizeFirstLetter(resources.areas)}>
        <List
          url="/Organizacao"
          editComponent={AreaEdit}
          createChildItems={createChildItems}
          sort="nome"
          hierarchy={true}
          tag={showModal ? 1006 : 1002}
          showSearch={true}
          searchComponent={showModal ? <SearchModal /> : <Search />}
          searchSchema={showModal ? SearchSchemaModal : SearchSchema}
          filtroId={showModal ? 23 : 9}
          onDeleting={this.handleDeleteClick}
          saved={this.state.saved}
          {...this.props}
        >
          <ListColumn
            headerText={intl.formatMessage({ id: 'nome' })}
            valueField="nome"
            valueFunction={this.renderNome}
            required
            default
            tree
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'registroSuperior' })}
            valueField="registroSuperior"
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'tipo' })}
            valueField="tipo"
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'tipo' })}
            resource="organizacao.tipoId"
            visible={false}
            valueField="tipoId"
          />
          <ListColumn
            headerText={capitalizeFirstLetter(resources.responsavel)}
            valueField="responsavel"
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'idIntegracao' })}
            valueField="idIntegracao"
            sortField="idIntegracao"
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'status' })}
            valueField="status"
            default
          />
          {configuracao.utilizarSubordinacaoMatricial && (
            <ListColumn
              headerText={intl.formatMessage({ id: 'subordinacaoMatricial' })}
              valueField="subordinacaoMatricial"
              default
            />
          )}
        </List>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(Organizao));
