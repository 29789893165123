import React from 'react';
import { autobind } from 'core-decorators';
import {
  FormFeedback,
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { connect } from 'react-redux';
import BigNumber from 'bignumber.js/bignumber.mjs';

import { parseNumber } from 'src/utils/number';

@autobind
class InputNumberGroup extends React.Component {
  static defaultProps = {
    locale: null,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    precision: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
      value: this.getValueFromProps(),
      textValue: this.getTextValueFromProps(props),
    };
  }

  getValueFromProps(props = this.props) {
    return props.model && props.model.value;
  }

  getTextValueFromProps(props = this.props) {
    if (props.model) {
      if (props.model.value == 0) {
        return props.model.value;
      } else {
        if (props.model.value) {
          return this.formatBigNumber(props.model.value, this.props.precision);
        } else {
          return '';
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.getValueFromProps(newProps) !== this.state.value) {
      this.setState({
        value: this.getValueFromProps(newProps),
        textValue: this.getTextValueFromProps(newProps),
      });
    }
  }

  handleChange(e) {
    let { precision, decimalSeparator } = this.props;

    let number = parseNumber(e.target.value, precision, decimalSeparator);

    this.setState(
      {
        value: this.formatBigNumberString(number),
        textValue: e.target.value,
      },
      () => {
        this.props.model.requestChange(this.state.value);
      }
    );
  }

  handleFocus() {
    this.setState({
      hasFocus: true,
    });
  }

  handleBlur() {
    this.setState({
      hasFocus: false,
      value: this.getValueFromProps(),
      textValue: this.getTextValueFromProps(),
    });
  }

  formatBigNumber(value, precision) {
    var BN = BigNumber.clone({
      FORMAT: {
        groupSize: 3,
        groupSeparator: this.props.thousandsSeparator,
        decimalSeparator: this.props.decimalSeparator,
      },
    });
    var bigNumber = new BN(value);
    return bigNumber.toFormat(precision);
  }

  formatBigNumberString(value) {
    if (value == 0) {
      return '0';
    } else if (value) {
      var bigNumber = new BigNumber(value);
      return bigNumber.toString();
    } else {
      return null;
    }
  }

  render() {
    let {
      model,
      autoFocus,
      maxLength,
      placeholder,
      required,
      mask,
      disabled,
      detail,
      addon,
      row,
      bsSize,
      formGroupStyle,
      style,
      ...other
    } = this.props;

    let value = model.value;

    return (
      <FormGroup row={row} style={formGroupStyle}>
        {model.label && (
          <Label className="control-label">
            {model.label} {required ? '*' : ''}
          </Label>
        )}
        {detail ? (
          <div style={{ whiteSpace: 'pre' }} {...other}>
            {value}
          </div>
        ) : (
          <InputGroup size={bsSize}>
            <Input
              autoFocus={autoFocus}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              value={this.state.textValue}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              {...other}
              style={{ ...style, padding: 5 }}
            />
            {addon && (
              <InputGroupAddon addonType="append" style={{ maxHeight: 31 }}>
                <InputGroupText>{addon}</InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        )}
        {model.erros && <FormFeedback>{model.erros}</FormFeedback>}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    decimalSeparator: state.user.decimalSeparator,
    thousandsSeparator: state.user.thousandsSeparator,
    locale: state.user.locale,
  };
}
export default connect(mapStateToProps)(InputNumberGroup);
