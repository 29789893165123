import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Input from 'src/componentes/input';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import Panel from 'src/componentes/panel';
import PanelCollapse from 'src/componentes/panel-collapse';
import { capitalizeFirstLetter } from 'src/utils/string';
import TabelaRelacionamento from '../processo-filho';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import http from 'src/services/httpService';
import DesenharCampoCustomizado from '../../../item/campo-customizado/componentes/desenhar';
import AnaliseSwot from '../componentes/AnaliseSwot/AnaliseSwot';
import FiltroItemRelatorioPowerBI from 'src/paginas/item/edit/resumo/filtro-relatorio-powerbi';
import MatrizRiscoLookup from 'src/lookups/matriz-risco';
import RiscoListView from '../../risco/RiscoListView';
import ContentManager from 'src/componentes/content-manager';
import ItemList from 'src/paginas/item';
import Confirm from 'src/componentes/message-box/confirm';
import DataInicioTermino from 'src/paginas/formula/componentes/inicio-termino';

@autobind
class ProcessoResumoEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracoes: [],
      refreshRiscoList: 0,
      ignoredIds: [],
    };

    http
      .get(`/ItemConfiguracaoProcesso/ObterConfiguracoes`)
      .then((response) => {
        this.setState({
          configuracoes: response.data,
        });
      });
  }

  handleAreaChange(value) {
    this.props.model.getModel('area').requestChange(value);

    let usarCodigoArea = this.props.model.getModel(
      'utilizarCodigoAreaParaMascara'
    ).value;
    if (usarCodigoArea) {
      let novoCodigoItem =
        value.idIntegracao + this.props.model.getModel('idIntegracao').value;
      this.props.model.getModel('idIntegracao').requestChange(novoCodigoItem);
    }
  }

  renderFiltroRelatorioPowerBI(disabled, model) {
    return (
      model.getModel('itemRelatorioPowerBI').value && (
        <FiltroItemRelatorioPowerBI
          model={model.getModel('itemRelatorioPowerBI')}
          disabled={disabled}
          itemId={model.getModel('id').value}
          tipoItemId={model.getModel('tipo').value?.id}
        />
      )
    );
  }

  handleSelect(itens) {
    const modelItens = this.props.model.getModel('riscosAssociados');

    let itensAtuais =
      modelItens?.value?.length > 0 ? modelItens.value.map((d) => d) : [];
    itensAtuais.map((item) => itens.push(item));

    modelItens.requestChange([...itens]);

    this.refresh();
  }

  refresh(saved) {
    this.setState({ refreshRiscoList: ++this.state.refreshRiscoList });
  }

  handleNewClick() {
    const modelItens = this.props.model.getModel('riscosAssociados');

    ContentManager.addContent(
      <ItemList
        showModal
        select
        multiple
        selectedCallback={this.handleSelect}
        selectedIds={modelItens.value}
        parameters={{ tiposIdsSelecionados: [6] }}
      />
    );
  }

  handleDeleteConfirm(itemRemove) {
    const { intl } = this.props;
    ContentManager.addContent(
      <Confirm
        message={capitalizeFirstLetter(
          intl.formatMessage({ id: 'label.desejaMesmoExcluirEsseRegistro' })
        )}
        handleConfirm={() => this.handleRemoveAssociation(itemRemove)}
      />
    );
  }

  handleRemoveAssociation(itemRemove) {
    const modelItens = this.props.model.getModel('riscosAssociados');
    const updatedItens = modelItens.value.filter(
      (item) => item.id !== itemRemove.id
    );
    modelItens.requestChange(updatedItens);

    this.setState({
      refreshRiscoList: ++this.state.refreshRiscoList,
      ignoredIds: [...this.state.ignoredIds, itemRemove.id],
    });
  }

  addNewRiskItem = (item) => {
    const modelItens = this.props.model.getModel('riscosAssociados');
    const newItem = {
      id: item.id,
      nome: item.nome,
      probabilidade: item.probabilidade,
      severidade: item.severidade,
      score: item.score,
    };

    let itensAtuais =
      modelItens?.value?.length > 0 ? modelItens.value.map((d) => d) : [];
    itensAtuais.push(newItem);
    modelItens.requestChange([...itensAtuais]);
  };
  render() {
    const { model, resources, disabled } = this.props;
    const podeEditar = disabled ? true : !model.getModel('podeEditar').value;
    const { configuracoes } = this.state;
    const bloquearCodigoAutomatico =
      disabled ||
      (configuracoes &&
        configuracoes.permitirAlterarCodigoAutomatico == false &&
        configuracoes.utilizarCodigoAutomatico == true)
        ? true
        : false;
    const utilizarCodigoAutomatico =
      configuracoes && configuracoes.utilizarCodigoAutomatico;

    return (
      <div>
        <Panel header={<FormattedMessage id="label.informacoesBasicas" />}>
          <Row>
            <Col md={configuracoes.exibirAnexarEmProcessos ? 5 : 6}>
              <Input
                disabled={disabled}
                required
                model={model.getModel('nome')}
              />
            </Col>
            <Col md={3}>
              <ItemOrganizacaoLookup
                disabled={disabled}
                required
                model={{
                  label: capitalizeFirstLetter(resources.area),
                  value: model.getModel('area').value,
                  requestChange: this.handleAreaChange,
                  errors: model.getModel('area').errors,
                }}
              />
            </Col>
            <Col md={3}>
              <Input
                disabled={bloquearCodigoAutomatico}
                required={!utilizarCodigoAutomatico}
                model={model.getModel('idIntegracao')}
              />
            </Col>

            {configuracoes.exibirAnexarEmProcessos && (
              <Col md={1} className={'d-flex align-items-center'}>
                <ArquivoUploadAnexos
                  disabled={disabled}
                  tipoAnexo={this.props.tiposAnexos.item}
                  formGroupStyle={{ marginBottom: 0 }}
                  model={model.getModel('arquivos')}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={3}>
              <ColaboradorSimplificadoLookup
                disabled={disabled}
                required
                multi
                model={{
                  label: capitalizeFirstLetter(resources.responsaveis),
                  value: model.getModel('responsaveis').value,
                  requestChange: model.getModel('responsaveis').requestChange,
                  errors: model.getModel('responsaveis').errors,
                }}
              />
            </Col>
            <Col md={3}>
              <MatrizRiscoLookup
                style={{ height: '42px' }}
                controlStyle={{ height: '43px' }}
                clearable
                model={{
                  label: capitalizeFirstLetter(
                    this.props.intl.formatMessage(
                      { id: 'matrizRisco' },
                      { risco: resources.risco }
                    )
                  ),
                  value: model.getModel('matrizRisco').value,
                  requestChange: model.getModel('matrizRisco').requestChange,
                }}
              />
            </Col>
            <Col>
              <DataInicioTermino
                viewMode="days"
                style={{ height: '45px' }}
                height={43}
                disabled={disabled}
                autoFormatDate={true}
                showClear={false}
                mesAno={false}
                dataInicio={model.getModel('dataInicioItem')}
                dataTermino={model.getModel('dataTerminoItem')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                disabled={disabled}
                type="textarea"
                model={model.getModel('descricao')}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {model.getModel('camposCustomizados').value &&
                model.getModel('camposCustomizados').value.length > 0 && (
                  <DesenharCampoCustomizado
                    model={model.getModel('camposCustomizados')}
                    colValue={
                      model.getModel('camposCustomizados').value.length <= 4
                        ? 12 / model.getModel('camposCustomizados').value.length
                        : 3
                    }
                    disabled={disabled}
                  />
                )}
            </Col>
          </Row>
        </Panel>
        {model.value && model.value.id != 0 && (
          <PanelCollapse
            header={capitalizeFirstLetter(resources.processosfilhos)}
            open={false}
          >
            <Row>
              <Col md={12} style={{ marginTop: 20 }}>
                <TabelaRelacionamento
                  itemIdIgnorar={model.value.id}
                  allowNew={!disabled}
                  disabled={disabled || podeEditar}
                  model={model}
                ></TabelaRelacionamento>
              </Col>
            </Row>
          </PanelCollapse>
        )}
        {model?.value?.id != null && model?.value?.id > 0 && (
          <PanelCollapse header={resources.riscos} open={false}>
            <Row>
              <Col>
                <RiscoListView
                  handleSelectRisk={disabled ? null : this.handleNewClick}
                  afterSave={this.addNewRiskItem}
                  select={false}
                  allowNew={!disabled}
                  disabled={disabled || !podeEditar}
                  showCustomFields={false}
                  showColumns={['nome', 'probabilidade', 'severidade', 'score']}
                  showSearchButton={true}
                  ignoreFiltroId={true}
                  usarFiltroSalvo={false}
                  showSearch={false}
                  hideHead={true}
                  selectedItems={model.getModel('riscosAssociados').value}
                  ignoreTag={true}
                  params={{
                    processoId: model.value.id,
                    ignorarIds: this.state.ignoredIds,
                  }}
                  handleRemoveAssociation={(item) =>
                    this.handleDeleteConfirm(item)
                  }
                  processoId={model.value.id}
                  saved={this.state.refreshRiscoList}
                  refreshList={() => this.refresh()}
                />
              </Col>
            </Row>
          </PanelCollapse>
        )}
        {model.value &&
          model.value.id != 0 &&
          configuracoes.utilizarAnaliseSwot && (
            <PanelCollapse header={<FormattedMessage id="labelAnaliseSWOT" />}>
              {model.getModel('id').value && (
                <AnaliseSwot
                  disabled={disabled}
                  processoId={model.getModel('id').value}
                  intl={this.props.intl}
                />
              )}
            </PanelCollapse>
          )}

        {model.value &&
          model.value.id != 0 &&
          this.renderFiltroRelatorioPowerBI(disabled, model)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
    modulosComprados: state.user.licenca.modulos,
    tiposAnexos: state.user.tiposAnexos,
  };
}
export default injectIntl(connect(mapStateToProps)(ProcessoResumoEdit));
