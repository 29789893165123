import List from 'src/componentes/list/';
import ListColumn from 'src/componentes/list/list-column';
import Search from 'src/paginas/gestao-risco/auditoria/Search';
import SearchSchema from 'src/paginas/gestao-risco/auditoria/SearchSchema';
import { capitalizeFirstLetter } from 'src/utils/string';
import { Badge } from 'reactstrap';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import { FILTROS } from 'src/utils/constants';
import Avatar from 'src/componentes/avatar';

const ControlSelfAssessmentList = ({ ...rest }) => {

  const intl = useIntl();
  const { terms: resources } = useAuth();

  const renderStatus = (item) => {
    if (item?.status)
      return (
        <span className="text-primary">
          <Badge
            color="custom"
            style={{ backgroundColor: item.status.cor, color: 'white' }}
          >
            {item.status.nome}
          </Badge>
        </span>
      );
  };

  const renderResponsavel = (item) => {
    if (item.responsavel) {
      return (
        <div className="d-flex align-items-center">
          <Avatar
            size={30}
            label={item.responsavel?.nome}
            foto={item.responsavel?.fotoArquivo}
            showLabel={false}
          />
          <div className="ml-2">{item.responsavel?.nome}</div>
        </div>
      );
    }

    return <>{intl.formatMessage({ id: 'naoInformado' })}</>;
  };

  return (
    <List
        url="/ControlSelfAssessment"
        showSearch={true}
        searchComponent={<Search />}
        searchSchema={SearchSchema}
        pageSize={10}
        showMenu={false}
        showNew={false}
        showExport={false}
        filtroId={FILTROS.CONTROL_SELF_ASSESSMENT}
        showPagination={true}
        showModal={true}
        modalClose={true}
        {...rest}
    >
        <ListColumn
            headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'idIntegracao' }))}
            valueField="idIntegracao"
            minWidth={150}
        />
        <ListColumn
            headerText={intl.formatMessage({ id: 'nome' })}
            valueField="nome"
        />
        <ListColumn
            headerText={capitalizeFirstLetter(resources.responsavel)}
            valueField="responsavel"
            valueFunction={renderResponsavel}
        />
        <ListColumn
            headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'periodo' }))}
            valueField="periodo"
            minWidth={150}
        />
        <ListColumn
            headerText={capitalizeFirstLetter(intl.formatMessage(
                { id: 'area' },
                { area: resources.area }
            ))}
            valueField="area"
        />
        <ListColumn
            headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'status' }))}
            valueField="status"
            valueFunction={renderStatus}
        />
        <ListColumn
            headerText={capitalizeFirstLetter(resources.pontosdecontrole)}
            valueField="controles"
        />
    </List>
  );
};

export default ControlSelfAssessmentList;