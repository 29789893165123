import React from 'react';
import Lookup from '../componentes/select2';
import SvgIcon from '../componentes/svg-icon';

const FarolIndicadorRiscoLookup = (props) => {
  const renderFarol = (item) => {
    return (
      item &&
      item.data && (
        <span>
          <SvgIcon
            icon={item.data.icone}
            size={18}
            color={item.data.cor}
            colorHover={item.data.cor}
          ></SvgIcon>
        </span>
      )
    );
  };

  return (
    <Lookup
      labelKey="descricao"
      valueKey="id"
      url="/FarolIndicadorRiscoLookup"
      clearable={false}
      valueComponent={renderFarol}
      {...props}
    />
  );
};

export default FarolIndicadorRiscoLookup;
