import { PlanejamentoInicialProvider } from 'src/contexts/PlanejamentoInicialContext';
import usePlanejamentoInicial from 'src/hooks/usePlanejamentoInicial';
import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';

import EstratificacaoPareto from './EstratificacaoPareto';
import AnaliseCausa from './AnaliseCausa';
import AnaliseHipotese from './AnaliseHipotese';
import AlertaRevisao from './AlertaRevisao';
import CincoPorques from './CincoPorques';
import PlanoAcao from './PlanoAcao';

import Stepper from 'src/components/Stepper';
import ContentManager from 'src/componentes/content-manager';
import Confirm from 'src/componentes/NewConfirm';
import LoadingContainer from 'src/componentes/loading-container';
import { capitalizeFirstLetter } from 'src/utils/string';

const Content = ({ item, model, planejamentoInicialId, statusID }) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const {
    step,
    changeStep,
    handleSave,
    step1Data,
    step2Data,
    step3Data,
    step4Data,
    handleChangeData,
    isLoading,
    changeLoadAllDatas
  } = usePlanejamentoInicial();

  const allowEdit =
    model &&
    model.value &&
    model.value.permissions &&
    model.value.permissions.allowEdit;

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="overflow-auto new-list-tree" style={{ height: 550 }}>
            <EstratificacaoPareto
              item={item}
              step1Data={step1Data}
              handleChangeData={handleChangeData}
              allowEdit={allowEdit}
            />
          </div>
        );
      case 2:
        return (
          <div className="overflow-auto new-list-tree" style={{ height: 550 }}>
            {step2Data && (
              <AnaliseCausa
                model={step2Data}
                handleChangeData={handleChangeData}
                allowEdit={allowEdit}
              />
            )}
          </div>
        );
      case 3:
        return (
          step3Data && (
            <AnaliseHipotese
              model={step3Data.analiseHipotesesModel}
              contadorInicialColunaParticipantes={
                step3Data.contadorInicialColunaParticipantes
              }
              handleChangeData={handleChangeData}
              allowEdit={allowEdit}
            />
          )
        );
      case 4:
        return (
          <div className="overflow-auto new-list-tree" style={{ height: 550 }}>
            {step4Data && step4Data.cincoPorquesModel && (
              <CincoPorques
                model={step4Data.cincoPorquesModel}
                handleChangeData={handleChangeData}
                allowEdit={allowEdit}
              />
            )}
          </div>
        );
      case 5:
        return (
          <div className="overflow-auto new-list-tree" style={{ height: 550 }}>
            <PlanoAcao
              item={item}
              model={step4Data}
              planejamentoInicialId={planejamentoInicialId}
              allowEdit={allowEdit}
            />
          </div>
        );
    }
  };

  const obterMaioresEstratificacoesTabela = () => {
    var registrosMaisValiosos =
      step1Data &&
      step1Data.estratificacoesValores &&
      step1Data.estratificacoesValores.reduce(function (acumulador, objeto) {
        if (
          !acumulador[objeto.codigoTabela] ||
          objeto.percentualTotal >
            acumulador[objeto.codigoTabela].percentualTotal
        ) {
          acumulador[objeto.codigoTabela] = objeto;
        }
        return acumulador;
      }, {});

    var resultado = Object.values(registrosMaisValiosos);
    return resultado;
  };

  const contarRegistros = (objetos) => {
    var totalRegistros = 0;

    objetos &&
      objetos.forEach(function (objeto) {
        if (objeto.descricao && objeto.descricao !== '') {
          totalRegistros++;

          if (objeto.itens && Array.isArray(objeto.itens)) {
            totalRegistros += contarRegistros(objeto.itens);
          }
        }
      });

    return totalRegistros;
  };

  const obterQuantidadeCausasIshikawa = () => {
    let acumulador = 0;

    step2Data.analiseCausasModel.forEach(function (item) {
      acumulador += contarRegistros(item.componente.dadosIshikawa?.itens);
    });

    return acumulador;
  };

  const obterRegistrosFavoritados = (objetos) => {
    var registrosFavoritados = [];

    objetos.forEach(function (objeto) {
      if (objeto.favoritado === true) {
        registrosFavoritados.push(objeto);
      }

      if (objeto.motivos && Array.isArray(objeto.motivos)) {
        var registrosFilhos = obterRegistrosFavoritados(objeto.motivos);
        registrosFavoritados = registrosFavoritados.concat(registrosFilhos);
      }
    });

    return registrosFavoritados;
  };

  const renderCausaIshikawaAlerta = (items) => {
    const validItems = items?.filter((x) => x.descricao !== '');

    return validItems?.map((item) => (
      <>
        <li>
          <span className="font-weight-bolder">{item.descricao}</span>
        </li>
        {item.itens && renderCausaIshikawaAlerta(item.itens)}
      </>
    ));
  };

  const renderContentAlert = () => {
    switch (step) {
      case 1:
        const estratificacoes = obterMaioresEstratificacoesTabela();
        return (
          <div style={{ background: '#ddebed' }} className="my-4 p-3 rounded">
            <div className="d-flex align-items-center">
              <div
                style={{ minWidth: 150, maxWidth: 150, marginTop: 38 }}
                className="font-weight-bolder"
              >
                {estratificacoes &&
                  estratificacoes.map((estrafifcicao) => (
                    <div className="text-truncate overflow-hidden mb-2">
                      <span title={estrafifcicao.descricao}>
                        {estrafifcicao.descricao}
                      </span>
                    </div>
                  ))}
              </div>
              <div className="d-inline-flex justify-content-center px-3 w-100">
                <div
                  style={{ borderRight: '1px solid gray' }}
                  className="d-inline-flex flex-column px-3"
                >
                  <div className="mb-3">
                    {intl.formatMessage({ id: 'quantidadeValor' })}
                  </div>
                  <div className="font-weight-bolder">
                    {estratificacoes &&
                      estratificacoes.map((estrafifcicao) => (
                        <div className="d-flex justify-content-center align-items-center mb-2">
                          {estrafifcicao.quantidadeValor &&
                            parseFloat(estrafifcicao.quantidadeValor).toFixed(
                              2
                            )}
                        </div>
                      ))}
                  </div>
                </div>
                <div
                  style={{ borderRight: '1px solid gray' }}
                  className="d-inline-flex flex-column px-3"
                >
                  <div style={{ minWidth: 90 }} className="mb-3">
                    {intl.formatMessage({ id: 'percentualAcumulado' })}
                  </div>
                  <div className="font-weight-bolder">
                    {estratificacoes &&
                      estratificacoes.map((estrafifcicao) => (
                        <div className="d-flex justify-content-center align-items-center mb-2">
                          {estrafifcicao.percentualAcumulado &&
                            parseFloat(
                              estrafifcicao.percentualAcumulado
                            ).toFixed(2)}
                        </div>
                      ))}
                  </div>
                </div>
                <div
                  className="d-inline-flex flex-column px-3"
                  style={{ minWidth: 95, maxWidth: 95 }}
                >
                  <div className="mb-3">
                    {intl.formatMessage({ id: 'percentualTotal' })}
                  </div>
                  <div className="font-weight-bolder">
                    {estratificacoes &&
                      estratificacoes.map((estrafifcicao) => (
                        <div className="d-flex justify-content-center align-items-center mb-2">
                          {estrafifcicao.percentualTotal &&
                            parseFloat(estrafifcicao.percentualTotal).toFixed(
                              2
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div style={{ background: '#ddebed' }} className="my-4 p-3 rounded">
            {step2Data &&
              step2Data.analiseCausasModel &&
              step2Data.analiseCausasModel.map((analiseCausa, index) => {
                return (
                  <div className="mb-3">
                    <div className="font-weight-bolder">
                      {intl.formatMessage({ id: 'ishikawa' }) +
                        ' ' +
                        (index + 1)}
                    </div>
                    <div>
                      <ul className="d-inline-flex flex-column m-0 text-left">
                        {analiseCausa.componente &&
                        analiseCausa.componente.dadosIshikawa &&
                        analiseCausa.componente.dadosIshikawa.itens &&
                        analiseCausa.componente.dadosIshikawa.itens.some(
                          function (objeto) {
                            return (
                              objeto.descricao !== null &&
                              objeto.descricao !== ''
                            );
                          }
                        ) ? (
                          renderCausaIshikawaAlerta(
                            analiseCausa.componente &&
                              analiseCausa.componente.dadosIshikawa &&
                              analiseCausa.componente.dadosIshikawa.itens
                          )
                        ) : (
                          <li>
                            <span className="font-weight-bolder">
                              {intl.formatMessage({
                                id: 'msgNaoHaCausasCadastradas',
                              })}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      case 3:
        const hipoteses =
          step3Data &&
          step3Data.analiseHipotesesModel.filter((a) => a.favoritado);

        return (
          <div style={{ background: '#ddebed' }} className="my-4 p-3 rounded">
            {
              <ul className="d-inline-flex flex-column m-0 text-left">
                {hipoteses &&
                  hipoteses.map((hipotese) => {
                    return (
                      <li>
                        <span className="font-weight-bolder">
                          {hipotese.descricaoCausa +
                            ' - ' +
                            hipotese.pontuacaoTotalCausa}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            }
          </div>
        );
      case 4:
        var todosRegistrosFavoritados = obterRegistrosFavoritados(
          step4Data && step4Data.cincoPorquesModel
        );

        return (
          <div style={{ background: '#ddebed' }} className="my-4 p-3 rounded">
            {
              <ul className="d-inline-flex flex-column m-0 text-left">
                {todosRegistrosFavoritados &&
                  todosRegistrosFavoritados.map((porque) => {
                    return (
                      <li>
                        <span className="font-weight-bolder">
                          {porque.descricao}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            }
          </div>
        );
    }
  };

  const alerts = [
    {
      numero: 1,
      titulo: intl.formatMessage({ id: 'estratificacaoProblema' }),
      mensagemTitulo: intl.formatMessage(
        { id: 'msgReviseInformacoesEtapa' },
        { numeroEtapa: 1 }
      ),
      mensagemPrincipal: intl.formatMessage({
        id: 'msgPrincipalAlertaPlanejamentoInicial',
      }),
      mensagemConfirmacao: intl.formatMessage({
        id: 'msgPrincipalAlertaConfirmacao',
      }),
      urlImagem: '/seed/validacao_1.svg',
    },
    {
      numero: 2,
      titulo: intl.formatMessage({ id: 'analiseCausas' }),
      mensagemTitulo: intl.formatMessage(
        { id: 'msgReviseInformacoesEtapa' },
        { numeroEtapa: 2 }
      ),
      mensagemPrincipal: intl.formatMessage({
        id: 'msgPrincipalAlertaPlanejamentoInicial',
      }),
      mensagemConfirmacao: intl.formatMessage({
        id: 'msgPrincipalAlertaConfirmacao',
      }),
      urlImagem: '/seed/validacao_1.svg',
    },
    {
      numero: 3,
      titulo: intl.formatMessage({ id: 'analiseHipoteses' }),
      mensagemTitulo: intl.formatMessage(
        { id: 'msgReviseInformacoesEtapa' },
        { numeroEtapa: 3 }
      ),
      mensagemPrincipal: intl.formatMessage({
        id: 'msgPrincipalAlertaPlanejamentoInicial',
      }),
      mensagemConfirmacao: intl.formatMessage({
        id: 'msgPrincipalAlertaConfirmacao',
      }),
      urlImagem: '/seed/validacao_1.svg',
    },
    {
      numero: 4,
      titulo: intl.formatMessage({ id: 'w5Porques' }),
      mensagemTitulo: intl.formatMessage(
        { id: 'msgPrincipalAlerta4Titulo' },
        { planodeacao: resources.planodeacao }
      ),
      mensagemPrincipal: intl.formatMessage(
        { id: 'msgPrincipalAlerta4PlanejamentoInicial' },
        { planodeacao: resources.planodeacao }
      ),
      mensagemConfirmacao: intl.formatMessage({
        id: 'msgPrincipalAlertaConfirmacao',
      }),
      urlImagem: '/seed/validacao_3.svg',
    },
  ];

  const verificarDescricaoNonNull = (objetos) => {
    for (var i = 0; i < objetos.length; i++) {
      if (objetos[i].descricao) {
        if (objetos[i].descricao !== null || objetos[i].descricao !== '') {
          return true;
        }
      }

      if (
        objetos[i].componente &&
        objetos[i].componente.dadosIshikawa &&
        objetos[i].componente.dadosIshikawa &&
        objetos[i].componente.dadosIshikawa.itens &&
        objetos[i].componente.dadosIshikawa.itens.length > 0
      ) {
        var temDescricaoNonNullFilho = verificarDescricaoNonNull(
          objetos[i].componente.dadosIshikawa.itens
        );
        if (temDescricaoNonNullFilho) {
          return true;
        }
      }
    }

    return false;
  };

  function verificarCamposNullEstratificacao(objetos) {
    return objetos.some(function (objeto) {
      return (
        objeto.descricao === null ||
        objeto.descricao === '' ||
        objeto.quantidadeValor === null
      );
    });
  }

  const validateStep = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        // Validação para o step 1
        if (
          step1Data &&
          (step1Data.estratificacoesValores == null ||
            (step1Data.estratificacoesValores != null &&
              step1Data.estratificacoesValores.length === 0) ||
            verificarCamposNullEstratificacao(step1Data.estratificacoesValores))
        ) {
          ContentManager.addContent(
            <Confirm
              showCancel={false}
              labelConfirm={intl.formatMessage({ id: 'entendi' })}
              primaryMessage={intl.formatMessage({
                id: 'msgSemEstratificacaoAvancarEtapa',
              })}
            />
          );
          return false;
        }
        return true;
      case 1:
        // Validação para o step 2
        if (obterQuantidadeCausasIshikawa() > 18) {
          ContentManager.addContent(
            <Confirm
              showCancel={false}
              labelConfirm={intl.formatMessage({ id: 'entendi' })}
              primaryMessage={intl.formatMessage({
                id: 'msgLimite18CausasIshikawa',
              })}
            />
          );
          return false;
        }
        if (
          !verificarDescricaoNonNull(step2Data && step2Data.analiseCausasModel)
        ) {
          ContentManager.addContent(
            <Confirm
              showCancel={false}
              labelConfirm={intl.formatMessage({ id: 'entendi' })}
              primaryMessage={intl.formatMessage({
                id: 'msgObrigatoriedadeInserirCausaIshikawa',
              })}
            />
          );
          return false;
        }
        return true;
      case 2:
        // Validação para o step 3
        const favoritados =
          step3Data &&
          step3Data.analiseHipotesesModel?.some((objeto) => objeto.favoritado);
        if (!favoritados) {
          ContentManager.addContent(
            <Confirm
              showCancel={false}
              labelConfirm={intl.formatMessage({ id: 'entendi' })}
              primaryMessage={intl.formatMessage({
                id: 'msgObrigatoriedadePriorizarCausa',
              })}
            />
          );
          return false;
        }
        return true;
      case 3:
        // Validação para o step 4
        const favoritadosStep4 = obterRegistrosFavoritados(
          step4Data && step4Data.cincoPorquesModel
        );
        if (favoritadosStep4 && favoritadosStep4.length === 0) {
          ContentManager.addContent(
            <Confirm
              showCancel={false}
              labelConfirm={intl.formatMessage({ id: 'entendi' })}
              primaryMessage={intl.formatMessage({
                id: 'msgObrigatoriedadePriorizarCausaPorque',
              })}
            />
          );
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  const nextCallback = (newStep, setActiveStepIndex, activeStepIndex, stepClick = false) => {
    changeLoadAllDatas(false);

    const oldStep = step - 1;
    const alert = alerts[oldStep];
    if (stepClick) {
      // Verifica se todos os passos anteriores foram concluídos
      for (let i = 0; i < newStep - 1; i++) {
        if (!validateStep(i)) {
          // Se algum passo anterior não está concluído, não avança
          return;
        }
      }
    } else{
      if (!validateStep(oldStep)) {
        // Se algum passo anterior não está concluído, não avança
        return;
      }
    }

    let listaAlertaRevisao = [];
    listaAlertaRevisao.push(step1Data?.alterado);
    listaAlertaRevisao.push(step2Data?.alterado);
    listaAlertaRevisao.push(step3Data?.alterado);
    listaAlertaRevisao.push(step4Data?.alterado);

    let showAlertaRevisao = listaAlertaRevisao[oldStep];
    if (showAlertaRevisao) {
      ContentManager.addContent(
        <AlertaRevisao
          showHeader={false}
          handleConfirm={() =>
            handleConfirm(setActiveStepIndex, activeStepIndex, step + 1)
          }
          model={alert}
        >
          {renderContentAlert()}
        </AlertaRevisao>
      );
    } else {
      handleConfirm(setActiveStepIndex, activeStepIndex, newStep);
    }
  };

  const handleConfirm = (setActiveStepIndex, activeStepIndex, nextPage) => {
    handleSave(nextPage);    
    setActiveStepIndex(nextPage - 1);
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <Stepper
        blockButtons={isLoading}
        initialStepIndex={step - 1}
        nextCallback={nextCallback}
        backCallback={changeStep}
        canClickOnStep={statusID === 1}
        steps={[
          {
            titulo: intl.formatMessage({ id: 'estratificacaoProblema' }),
            descricao: intl.formatMessage({
              id: 'msgGereParetoEstratificacoes',
            }),
          },
          {
            titulo: intl.formatMessage({ id: 'analiseCausas' }),
            descricao: intl.formatMessage({ id: 'msgInsiraCausasIshikawa' }),
          },
          {
            titulo: intl.formatMessage({ id: 'analiseHipoteses' }),
            descricao: intl.formatMessage({ id: 'msgVoteCausasPriorizacao' }),
          },
          {
            titulo: intl.formatMessage({ id: 'w5Porques' }),
            descricao: intl.formatMessage({ id: 'msgEncontreCausaRaiz' }),
          },
          {
            titulo: capitalizeFirstLetter(
              intl.formatMessage(
                { id: 'label.planoAcao' },
                { planodeacao: resources.planodeacao }
              )
            ),
            descricao: intl.formatMessage({ id: 'msgCrieSeuPlanoAcao' }),
          },
        ]}
        containerHeight={550}
        confirmNextStep={true}
      >
        {renderContent()}
      </Stepper>
    </LoadingContainer>
  );
};

const Main = ({ model, planejamentoInicialId, item, statusID }) => (
  <PlanejamentoInicialProvider planejamentoInicialId={planejamentoInicialId} loadAllDatas={statusID === 1}>
    <Content
      item={item}
      model={model}
      planejamentoInicialId={planejamentoInicialId}
      statusID={statusID}
    />
  </PlanejamentoInicialProvider>
);

export default Main;
