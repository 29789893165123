import { createContext, useEffect, useReducer } from 'react';

import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';

const initialState = {
  step: 1,
  step1Data: null,
  step2Data: null,
  step3Data: null,
  step4Data: null,
  step5Data: null,
  isLoading: false,
  loadAllDatas: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_STEP': {
      const { step } = action.payload;

      return {
        ...state,
        step,
      };
    }

    case 'INIT_STEP_DATA_1': {
      const newState1 = { ...state };
      newState1.step1Data = action.payload.model;

      return newState1;
    }

    case 'INIT_STEP_DATA_2': {
      const newState2 = { ...state };
      newState2.step2Data = action.payload.model;

      return newState2;
    }

    case 'INIT_STEP_DATA_3': {
      const newState3 = { ...state };
      newState3.step3Data = action.payload.model;

      return newState3;
    }

    case 'INIT_STEP_DATA_4': {
      const newState4 = { ...state };
      newState4.step4Data = action.payload.model;

      return newState4;
    }

    case 'CHANGE_STEP_DATA': {
      const { listName, field, value } = action.payload;
      return {
        ...state,
        [listName]: {
          ...state[listName],
          [field]: value,
        },
      };
    }
    case 'CHANGE_LOADING': {
      const { status } = action.payload;
      return {
        ...state,
        isLoading: status,
      };
    }
    case 'CHANGE_LOAD_ALL_DATAS': {      
      return {
        ...state,
        loadAllDatas: action.payload.value,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const PlanejamentoInicialContext = createContext({
  ...initialState,
  handleSave: () => {},
  setIsLoading: () => {},
  handleChangeData: () => {},
});

export const PlanejamentoInicialProvider = ({
  children,
  planejamentoInicialId,
  loadAllDatas
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    planejamentoInicialId,
    loadAllDatas
  });

  const changeStep = (step) => {
    dispatch({
      type: 'CHANGE_STEP',
      payload: { step },
    });
  };

  const changeLoadAllDatas = (value) => {
    dispatch({
      type: 'CHANGE_LOAD_ALL_DATAS',
      payload: { value },
    });
  };

  const handleChangeData = (listName, field, value) => {
    dispatch({
      type: 'CHANGE_STEP_DATA',
      payload: { listName, field, value },
    });
  };

  const setIsLoading = (status) => {
    dispatch({
      type: 'CHANGE_LOADING',
      payload: { status },
    });
  };

  const initStepData1 = (model) => {
    dispatch({
      type: 'INIT_STEP_DATA_1',
      payload: { model },
    });
  };

  const initStepData2 = (model) => {
    dispatch({
      type: 'INIT_STEP_DATA_2',
      payload: { model },
    });
  };

  const initStepData3 = (model) => {
    dispatch({
      type: 'INIT_STEP_DATA_3',
      payload: { model },
    });
  };

  const initStepData4 = (model) => {
    dispatch({
      type: 'INIT_STEP_DATA_4',
      payload: { model },
    });
  };

  const getStepData = async () => {
    const getStepDataPromise = async () => {
      switch (state.step) {
        case 1: {
          getEstratificacao();
          return;
        }
        case 2: {
          getAnaliseCausas();
          return;
        }
        case 3: {
          getAnaliseHipoteses();
          return;
        }
        case 4: {
          getCincoPorques();
          return;
        }
      }
    };

    try {
      setIsLoading(true);
      await getStepDataPromise();
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
     if(!state.loadAllDatas){
      getStepData();  
    }    
  }, [state.step]);

  const getAllStepsData = async () => {
    const getAllStepDataPromise = async () => {
      await getEstratificacao();
    };

    try {
      setIsLoading(true);
      await getAllStepDataPromise();      
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllStepsData();
  }, []);

  useEffect(() => {
    if(state.loadAllDatas && state.step1Data){
      getAnaliseCausas();
    }
  }, [state.step1Data]);

  useEffect(() => {
    if(state.loadAllDatas && state.step2Data){
      getAnaliseHipoteses();
    }
  }, [state.step2Data]);

  useEffect(() => {
    if(state.loadAllDatas && state.step3Data){
      getCincoPorques();
    }
  }, [state.step3Data]);

  const getEstratificacao = async () => {
    const response = await http.post(
      'EstratificacaoValor/ObterEstratificacaoValor',
      {
        planejamentoInicialId: state.planejamentoInicialId,
      }
    );
    initStepData1(response.data);
  };

  const saveEstratificacao = async () => {
    await http.post('EstratificacaoValor/SalvarEstratificacaoValor', {
      planejamentoInicialId: state.planejamentoInicialId,
      estratificacoesValores:
        state.step1Data && state.step1Data.estratificacoesValores,
      alterado: state.step1Data && state.step1Data.alterado,
    });
  };

  const getAnaliseCausas = async () => {
    if(state.step1Data && state.step1Data.estratificacoesValores.length > 0){
      const response = await http.post('PlanejamentoInicial/ObterAnaliseCausas', {
        planejamentoInicialId: state.planejamentoInicialId,
        estratificacoesValores:
          state.step1Data && state.step1Data.estratificacoesValores,
      });
  
      initStepData2(response.data);
    }    
  };

  const saveAnaliseCausas = async () => {
    await http.post('PlanejamentoInicial/SalvarAnaliseCausas', {
      planejamentoInicialId: state.planejamentoInicialId,
      model: state.step2Data && state.step2Data.analiseCausasModel,
      alterado: state.step2Data && state.step2Data.alterado,
    });
  };

  const getAnaliseHipoteses = async () => {
    if(state.step2Data && state.step2Data.analiseCausasModel.length > 0){
      const response = await http.post(
        'PlanejamentoInicial/ObterAnaliseHipoteses',
        {
          planejamentoInicialId: state.planejamentoInicialId,
          analisesCausas: state.step2Data && state.step2Data.analiseCausasModel,
        }
      );
      initStepData3(response.data);
    }    
  };

  const saveAnaliseHipoteses = async () => {
    await http.post('PlanejamentoInicial/SalvarAnaliseHipoteses', {
      planejamentoInicialId: state.planejamentoInicialId,
      model: state.step3Data && state.step3Data.analiseHipotesesModel,
      contadorFinalColunaParticipantes:
        state.step3Data && state.step3Data.contadorFinalColunaParticipantes,
      alterado: state.step3Data && state.step3Data.alterado,
    });
  };

  const getCincoPorques = async () => {
    if(state.step3Data && state.step3Data.analiseHipotesesModel.length > 0){
      const response = await http.post('PlanejamentoInicial/ObterCincoPorques', {
        planejamentoInicialId: state.planejamentoInicialId,
        analisesHipoteses:
          state.step3Data && state.step3Data.analiseHipotesesModel,
      });
  
      initStepData4(response.data);
      changeLoadAllDatas(false);
    }
  };

  const saveCincoPorques = async () => {
    await http.post('PlanejamentoInicial/SalvarCincoPorques', {
      planejamentoInicialId: state.planejamentoInicialId,
      model: state.step4Data && state.step4Data.cincoPorquesModel,
      alterado: state.step4Data && state.step4Data.alterado,
    });
  };

  const handleSave = async (nextPage) => {
    
    const handleSavePromise = async () => {
      switch (state.step) {
        case 1: {
          await saveEstratificacao();
          return;
        }
        case 2: {
          await saveAnaliseCausas();
          return;
        }
        case 3: {
          await saveAnaliseHipoteses();
          return;
        }
        case 4: {
          await saveCincoPorques();
          return;
        }
      }
    };

    try {
      setIsLoading(true);
      await handleSavePromise();
      changeStep(nextPage);
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PlanejamentoInicialContext.Provider
      value={{
        ...state,
        handleSave,
        setIsLoading,
        handleChangeData,
        changeStep,
        changeLoadAllDatas
      }}
    >
      {children}
    </PlanejamentoInicialContext.Provider>
  );
};

export default PlanejamentoInicialContext;
