import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Input from 'src/componentes/input';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import Panel from 'src/componentes/panel';
import PacoteLookup from 'src/lookups/pacote';
import TipoPacoteLookup from 'src/lookups/tipo-pacote';
import ClassificacaoLookup from 'src/lookups/classificacao-mo';

class PacoteSearch extends React.Component {
  render() {
    let { model, exibirClassificacao } = this.props;
    return (
      <Panel>
        <Row>
          <Col md={3}>
            <Input model={model.getModel('nome')} />
          </Col>
          {exibirClassificacao && (
            <Col md={3}>
              <ClassificacaoLookup clearable model={model.getModel('classificacao')} />
            </Col>
          )}
          <Col md={3}>
            <PacoteLookup model={model.getModel('pai')} />
          </Col>
          <Col md={3}>
            <ColaboradorSimplificadoLookup model={model.getModel('gestor')} />
          </Col>
          <Col md={3}>
            <TipoPacoteLookup model={model.getModel('tipo')} />
          </Col>
        </Row>
      </Panel>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(PacoteSearch));
