import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from 'src/utils/form';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import ConsequenciaRiscoLookup from 'src/lookups/consequencia-risco';

let schema = Schema.object({
  consequencia: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, disabled } = this.props;

    return (
      <div className="mh-450px">
        <br />
        <Row>
          <Col md={12}>
            <Col md={6}>
              <ConsequenciaRiscoLookup
                disabled={disabled}
                model={model.getModel('consequencia')}
                data={{ riscoId: this.props.riscoId }}
              />
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

class ConsequenciaEventoRiscoEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;
    return (
      <Edit
        {...this.props}
        parameters={{ eventoRiscoId: this.props.eventoRiscoId }}
        title={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'labelConsequenciasDoRisco' },
            { consequencias: resources.consequencias, risco: resources.risco }
          )
        )}
        formComponent={FormEdit}
        afterSave={this.props.afterSave && this.props.afterSave}
        schema={schema}
        showActions={true}
        width={600}
        minHeight={240}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(
  connect(mapStateToProps)(ConsequenciaEventoRiscoEdit)
);
