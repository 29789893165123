import React from 'react';
import { autobind } from 'core-decorators';
import Content from '../../../componentes/pages/content';
import View from './view';
import ListColumn from '../../../componentes/list/list-column';
import CondicionanteEdit from './edit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';
import List from '../../../componentes/list';

@autobind
class Condicionante extends React.Component {
  render() {
    let { resources } = this.props;
    let { intl } = this.props;
    return (
      <Content titulo={capitalizeFirstLetter(resources.condicionantes)}>
        <List url="/Condicionante" editComponent={CondicionanteEdit} sort="Descricao" showSearch={true} {...this.props} tag={11002}>
          <ListColumn headerText={intl.formatMessage({ id: 'idIntegracao' })} valueField="idIntegracao" default />
          <ListColumn headerText={intl.formatMessage({ id: 'descricao' })} required valueField="descricao" sortField="descricao" default />
        </List>
      </Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Condicionante));
