import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import CategoriaEdit from './edit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@autobind
class Categoria extends React.Component {
  render() {
    let { intl } = this.props;

    return (
      <List url="/Categoria" editComponent={CategoriaEdit} sort="Nome" showSearch={true} {...this.props} tag={11001}>
        <ListColumn headerText={intl.formatMessage({ id: 'idIntegracao' })} valueField="idIntegracao" default />
        <ListColumn headerText={intl.formatMessage({ id: 'nome' })} required valueField="nome" sortField="Nome" valueFunction={this.renderDescricao} default />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Categoria));
