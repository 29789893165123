import Lookup from 'src/componentes/select2';
import Edit from 'src/paginas/EscritorioProjetos/FluxoAprovacaoGenerico/Edit';

const FluxoAprovacaoGenericoLookup = ({ ...rest }) => (
  <Lookup
    labelKey="nome"
    valueKey="id"
    url="/FluxoAprovacaoGenericoLookup"
    editComponent={Edit}
    clearable
    {...rest}
  />
);

export default FluxoAprovacaoGenericoLookup;
