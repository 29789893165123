import React from 'react';
import { Schema } from '../../utils/form';
import { FormattedMessage } from 'react-intl';

export default Schema.object({
  responsavel: Schema.string().label(''),
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  codigo: Schema.string().label(<FormattedMessage id="codigo" />),
  area: Schema.string().label(''),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  areaSubordinada: Schema.string().label(<FormattedMessage id="label.incluirAreasSubordinadas2" />),
  item: Schema.string().label(<FormattedMessage id="item" />),
  tema: Schema.string().label(),
  /*melhor: Schema.string().label(""),*/
  dataInicioPrevista: Schema.string().label(<FormattedMessage id="dataInicioPrevista" />),
  dataTerminoPrevista: Schema.string().label(<FormattedMessage id="dataTerminoPrevista" />),
  dataInicioRealizada: Schema.string().label(<FormattedMessage id="dataInicioRealizada" />),
  dataTerminoRealizada: Schema.string().label(<FormattedMessage id="dataTerminoRealizada" />),
  //camposCustomizados: Schema.array()
});
