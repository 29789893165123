import List from 'src/componentes/list/';
import ListColumn from 'src/componentes/list/list-column';
import Search from 'src/paginas/gestao-risco/auditoria/Search';
import SearchSchema from 'src/paginas/gestao-risco/auditoria/SearchSchema';
import { capitalizeFirstLetter } from 'src/utils/string';
import { Badge } from 'reactstrap';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import { FILTROS } from 'src/utils/constants';

const AuditoriaRiscoList = ({ ...rest }) => {

  const intl = useIntl();
  const { terms: resources } = useAuth();

  const renderStatus = (item) => {
    if (item?.status)
      return (
        <span className="text-primary">
          <Badge
            color="custom"
            style={{ backgroundColor: item.status.cor, color: 'white' }}
          >
            {intl.formatMessage({ id: item.status.descricao })}
          </Badge>
        </span>
      );
  };

  return (
    <List
      url="/AuditoriaRisco"
      showSearch={true}
      searchComponent={<Search />}
      searchSchema={SearchSchema}
      pageSize={10}
      showMenu={false}
      showNew={false}
      showExport={false}
      filtroId={FILTROS.AUDITORIA_RISCO}
      showPagination={true}
      showModal={true}
      modalClose={true}
      {...rest}
    >
      <ListColumn
        headerText={intl.formatMessage({ id: 'nome' })}
        valueField="nome"
      />
      <ListColumn
        headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'idIntegracao' }))}
        valueField="idIntegracao"
        minWidth={150}
      />
      <ListColumn
        headerText={capitalizeFirstLetter(resources.responsavel)}
        valueField="responsaveis"
      />
      <ListColumn
        headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'labelPeriodoAuditoria' }, { auditoria: resources.auditoria }))}
        valueField="periodo"
        minWidth={150}
      />
      <ListColumn
        headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'status' }))}
        valueField="status"
        valueFunction={renderStatus}
      />
      <ListColumn
        headerText={capitalizeFirstLetter(intl.formatMessage({ id: 'titulo.Checklist' }))}
        valueField="checkList"
      />
      <ListColumn
        headerText={capitalizeFirstLetter(intl.formatMessage(
          { id: 'area' },
          { area: resources.area }
        ))}
        valueField="area"
      />
    </List>
  );
};

export default AuditoriaRiscoList;