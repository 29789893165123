import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import qs from 'query-string';
import { injectIntl } from 'react-intl';
import ObjeticoEstrategicoBaseEdit from '../objetivo-estrategico-base/edit.jsx';

@autobind
class ObjetivoEstrategicoEdit extends React.Component {
  render() {
    let { showDelete, showPrompt, resources } = this.props;
    let id = this.props.id ? this.props.id : this.props.location && qs.parse(this.props.location.search).id;

    return (
      <ObjeticoEstrategicoBaseEdit
        showDelete={showDelete}
        urlValidarRestricoes="/ObjetivoEstrategico/ValidarRestricoes"
        urlAtualizarStatusExcluido="/ObjetivoEstrategico/AtualizarStatusExcluido"
        urlList="/gerenciar/objetivo-estrategico"
        urlEdit="/gerenciar/objetivo-estrategico/edit"
        urlController="/ObjetivoEstrategico"
        tipoItemId={3}
        title={capitalizeFirstLetter(resources.objetivoestrategico)}
        id={id}
        showPrompt={showPrompt}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ObjetivoEstrategicoEdit));
