import React from 'react';
import Lookup from 'src/componentes/select2';

const ModuloLookup = ({ ...rest }) => (
  <Lookup
    labelKey="nome"
    valueKey="id"
    url="/ModuloLookup"
    clearable={true}
    moduloLookup={true} 
    {...rest}
  />
);

export default ModuloLookup;
