import { useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import formatDateFns from 'src/utils/formatDateFns';
import useAuth from 'src/hooks/useAuth';
import Button from 'src/componentes/button';
import Slider from 'src/componentes/FakeSlider';
import LoadingContainer from 'src/componentes/loading-container';
import ContentManager from 'src/componentes/content-manager';
import MdiIcon from 'src/componentes/mdi-icon';

import Alert from './Alert';
import Historic from './Historic';

const Baseline = ({ model }) => {
  const intl = useIntl();

  const { user } = useAuth();
  const { currentDisplay, currentLocale } = formatDateFns(user?.idioma, true);

  const [isLoading, setIsLoading] = useState(false);
  const [openHistoric, setOpenHistoric] = useState(false);

  const renderDate = (data) =>
    format(data, currentDisplay, { locale: currentLocale });

  const initialBaseline = model.getModel('baselineInicial').value;
  const endBaseline = model.getModel('baselineTermino').value;
  const executedPercentual = model.getModel('percentualExecutado').value;

  const dateNowFormatted = renderDate(new Date());
  const initialBaselineFormatted =
    initialBaseline && renderDate(initialBaseline);
  const endBaselineFormatted = endBaseline && renderDate(endBaseline);

  const renderEmptyContent = () => (
    <div className="d-flex justify-content-center align-items-center bg-light-gray p-4 text-muted">
      {intl.formatMessage({ id: 'nenhumaBaselineGeradaAinda' })}
    </div>
  );

  const renderContent = () => (
    <div className="mt-4">
      <Slider
        inlineScaleValues
        scaleFirstValue={initialBaselineFormatted}
        scaleLastValue={endBaselineFormatted}
        value={dateNowFormatted}
        percent={executedPercentual || 0}
        hidePercentValue={15}
        color="#1D7789"
        bgColor="#E5E5E5"
        containerHeight={13}
        showBorder={false}
        roundeSize={10}
        labelSizeClass="text-xs"
      />
    </div>
  );

  const handlePreGenerate = () => {
    ContentManager.addContent(
      <Alert model={model} setIsLoadingContainer={setIsLoading} />
    );
  };

  const noBaselineGenerated = !initialBaseline || !endBaseline;

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center font-weight-bolder">
          <div>{intl.formatMessage({ id: 'baseline' })}</div>
          {!noBaselineGenerated && (
            <div
              onClick={() => setOpenHistoric(true)}
              className="d-flex align-items-center text-primary cursor-pointer"
            >
              <MdiIcon className="mr-2" icon="history" />
              <div>{intl.formatMessage({ id: 'historicoBaselines' })}</div>
            </div>
          )}
        </div>
        {noBaselineGenerated && (
          <div className="my-3">
            <Button onClick={handlePreGenerate}>
              {intl.formatMessage({ id: 'gerarBaseline' })}
            </Button>
          </div>
        )}
        {noBaselineGenerated ? renderEmptyContent() : renderContent()}
        <hr />
      </div>
      <Historic model={model} open={openHistoric} setOpen={setOpenHistoric} />
    </LoadingContainer>
  );
};

export default Baseline;
