import { FormattedMessage } from 'react-intl';

import { Schema } from 'src/utils/form';

export default Schema.object({
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  idIntegracao: Schema.string().label(<FormattedMessage id="label.CodigoDoTeste" />),
  responsavel: Schema.string().label(<FormattedMessage id="responsavel" />),
  responsaveisSimplificado: Schema.string().label(''),
  area: Schema.string().label(''),
  areas: Schema.string().label(''),
  status: Schema.string().label(<FormattedMessage id="status" />),
  resultado: Schema.string().label(<FormattedMessage id="resultado" />),
  dataConclusao: Schema.string().label(<FormattedMessage id="dataConclusao" />),
  controle: Schema.string(),
  riscos: Schema.string(),
  sugestao: Schema.string().label(<FormattedMessage id="labelListaSugestoes" />),
  statusTesteControleList: Schema.string().label(<FormattedMessage id="status" />),
  avaliacaoTesteControleList: Schema.string().label(<FormattedMessage id="label.resultado" />),
  tipoFiltroDataPeriodoExecucao: Schema.string().label(<FormattedMessage id="label.PeriodoDataExecucao" />),
  deDataInicialExecucao: Schema.string().label(<FormattedMessage id="deDataInicialExecucao" />),
  ateDataFinalExecucao: Schema.string().label(<FormattedMessage id="ateDataFinalExecucao" />),
  tipoFiltroDataPeriodoConclusao: Schema.string().label(<FormattedMessage id="label.PeriodoDataConclusao" />),
  deDataInicialConclusao: Schema.string().label(<FormattedMessage id="deDataInicialConclusao" />),
  ateDataFinalConclusao: Schema.string().label(<FormattedMessage id="ateDataFinalConclusao" />),
});
