import React from 'react';
import { Schema } from '../../utils/form';
import { FormattedMessage } from 'react-intl';

export default Schema.object({
  responsavel: Schema.string().label(''),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />).required(),
  codigo: Schema.string().label(<FormattedMessage id="codigo" />),
  area: Schema.string().label(''),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  areaSubordinada: Schema.string().label(<FormattedMessage id="label.incluirAreasSubordinadas2" />),
  tema: Schema.string().label(),
  /*melhor: Schema.string().label(""),*/
  dataInicioPrevista: Schema.string().label(<FormattedMessage id="dataInicioPrevista" />),
  dataTerminoPrevista: Schema.string().label(<FormattedMessage id="dataTerminoPrevista" />),
  dataInicioRealizada: Schema.string().label(<FormattedMessage id="dataInicioRealizada" />),
  dataTerminoRealizada: Schema.string().label(<FormattedMessage id="dataTerminoRealizada" />),
  camposCustomizados: Schema.array(),
  item: Schema.string().label(<FormattedMessage id="item" />),
  incluirItensFormula: Schema.string().label(<FormattedMessage id="incluirItensFormula" />),
  incluirDesdobramento: Schema.string().label(<FormattedMessage id="incluirDesdobramento" />),
  incluirImpactosIndiretos: Schema.string().label(<FormattedMessage id="incluirImpactosIndiretos" />),
  apenasAcoesModuloAtual: Schema.string().label(''),
  apenasAcoesOrigem: Schema.string().label(''),
  areaSubordinadaColaboradores: Schema.string().label(<FormattedMessage id="label.incluirAreasSubordinadas2" />),
  areaColaboradores: Schema.string().label(''),
  tipoOrigem: Schema.string().label(<FormattedMessage id="tipoOrigem" />),
  porque: Schema.string().label(''),
  tipoAcao: Schema.number().label(''),
  centrosSubordinados: Schema.string().label(<FormattedMessage id="label.centrosSubordinados" />),
  pacotesSubordinados: Schema.string().label(<FormattedMessage id="label.pacotesSubordinados" />),
  desdobramento: Schema.string().label(<FormattedMessage id="desdobramento" />),
  desdobramentoSubordinados: Schema.string().label(<FormattedMessage id="incluirDesdobramentoSubordinados" />),
  nivelAcao: Schema.string().label(''),
  pacote: Schema.string()
    .label(<FormattedMessage id="pacoteConta" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.vincularComMO;
      },
    }),
  centroCusto: Schema.string()
    .label(<FormattedMessage id="centroDeCusto" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.vincularComMO;
      },
    }),
  equipe: Schema.array(),
  tags: Schema.string().label(<FormattedMessage id="tags" />),
  auditorias: Schema.string().label(''),
  tipoFiltroDataCriacaoAcao: Schema.string().label(''),
  dataInicialCriacaoAcao: Schema.string().label(''),
  dataFinalCriacaoAcao: Schema.string().label(''),
  controlSelfAssessments: Schema.string().label(<FormattedMessage id="labelControlSelfAssessment" />),
  acoes: Schema.array().label(<FormattedMessage />),
});
