import { useEffect, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useIntl } from 'react-intl';

import { capitalizeFirstLetter } from 'src/utils/string';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/errorHandler';
import useAuth from 'src/hooks/useAuth';

const ColunaLicencaColaborador = ({
  item,
  modulosDisponiveis,
  estatisticaLicencaModulos,
  setEstatisticaLicencaModulos,
}) => {
  const [modulosAtivos, setModulosAtivos] = useState([]);
  const [saving, setSaving] = useState(false);
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const onClick = (id) => {
    const list = modulosAtivos;
    const index = list.findIndex((x) => x === id);

    if (index === -1) list.push(id);
    else list.splice(index, 1);

    setModulosAtivos([...list]);

    save(list);
    updateEstatistica(id, index === -1);
  };

  const updateEstatistica = (id, adicionado) => {
    const list = estatisticaLicencaModulos;
    const estat = list.find((x) => x.id === id);

    estat.numeroColaboradores = adicionado
      ? estat.numeroColaboradores + 1
      : estat.numeroColaboradores - 1;

    setEstatisticaLicencaModulos(list);
  };

  const save = async (modulos) => {
    try {
      setSaving(true);
      await http.post(`/Colaborador/AlterarPermissaoModulos`, {
        modulos: modulos,
        colaboradorId: item.id,
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const renderTooltip = (id, ativo) => {
    const numeroColaboradores = estatisticaLicencaModulos?.find(
      (e) => e.id === id
    )?.numeroColaboradores;
    const moduloInfo = modulosDisponiveis?.find((e) => e.id === id);

    if (ativo) {
      return (
        <>
          <div>{intl.formatMessage({ id: 'habilitado' })}</div>
          <div>{`${numeroColaboradores} ${
            moduloInfo && !moduloInfo.usuariosIlimitados
              ? `de ${moduloInfo?.usuarios}`
              : ''
          }`}</div>
        </>
      );
    } else {
      return intl.formatMessage(
        { id: 'habilitarModuloParaColaborador' },
        { colaborador: resources.colaborador }
      );
    }
  };

  useEffect(() => {
    setModulosAtivos(item.colaboradorModulos);
  }, [item]);

  const modulosHabilitados = modulosDisponiveis.filter((e) => e.exibir);

  return (
    <div
      className="row"
      style={
        modulosHabilitados
          ? { width: modulosHabilitados.length * 65, rowGap: '0.5rem' }
          : {}
      }
    >
      {modulosHabilitados?.map((modulo, index) => {
        const targetId = `mod${item.id}-${index}`;
        const ativo = modulosAtivos?.find((e) => e == modulo.id);
        return (
          <div
            id={targetId}
            onClick={() => !saving && onClick(modulo.id)}
            style={{ width: 50, cursor: `${saving ? 'wait' : 'pointer'}` }}
            className={`d-flex ml-2 rounded-lg ${
              ativo ? 'bg-primary' : 'bg-secondary'
            } border-white border text-white`}
          >
            <div className="ml-auto mr-auto">
              {capitalizeFirstLetter(modulo.icone ?? modulo.abreviacao)}
            </div>
            <UncontrolledTooltip placement="auto" target={targetId}>
              {renderTooltip(modulo.id, ativo)}
            </UncontrolledTooltip>
          </div>
        );
      })}
    </div>
  );
};

export default ColunaLicencaColaborador;
