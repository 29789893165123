import { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import http from 'src/services/httpService';
import Edit from 'src/componentes/edit/edit-dialog';

import { capitalizeFirstLetter } from 'src/utils/string';
import { Schema } from 'src/utils/form';
import { TIPO_CESTA_INDICADOR } from 'src/utils/constants';

import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import Panel from 'src/componentes/panel';

import FormulaVisao from 'src/paginas/formula/componentes/formula-visao';

import HistoricoCestaIndicador from './components/historico';
import GatilhoList from './components/gatilho';
import EditBonus from './Bonus';
import EditComissoes from './Comissoes';

const schema = Schema.object({
  id: Schema.string(),
  idIntegracao: Schema.string().required(),
  dataInicio: Schema.string().required(),
  dataTermino: Schema.string(),
});

const FormEdit = ({ model, parameters }) => {
  const intl = useIntl();

  const [cardMetaConfiguration, setCardMetaConfiguration] = useState();

  const isBonus =
    parameters.tipoCestaIndicadorId === TIPO_CESTA_INDICADOR.BONUS;

  useEffect(() => {
    loadCardMetaConfiguration();
  }, []);

  const loadCardMetaConfiguration = async () => {
    try {
      const response = await http.post(
        `/ConfiguracaoCardMeta/ObterConfiguracoes`,
        {}
      );
      setCardMetaConfiguration(response.data);
    } catch (_) {}
  };

  const handleChangeDataInicio = (value) => {
    model.getModel('dataInicio').requestChange(value);
    parameters.alterado = true;
  };

  const handleChangeDataTermino = (value) => {
    model.getModel('dataTermino').requestChange(value);
    parameters.alterado = true;
  };

  const handleChangeIdIntegracao = (value) => {
    model.getModel('idIntegracao').requestChange(value);
  };

  const handleChangeTipoCalculoScore = (value) => {
    model.getModel('tipoCalculoScore').requestChange(value);
  };

  const handleChangeTipoCard = (value) => {
    model.getModel('tipoCardId').requestChange(value);
  };

  const renderRadioButton = () => {
    const optionsRadio = [
      {
        id: 1,
        descricao: capitalizeFirstLetter(intl.formatMessage({ id: 'pesos' })),
      },
      {
        id: 2,
        descricao: capitalizeFirstLetter(intl.formatMessage({ id: 'formula' })),
      },
    ];

    if (cardMetaConfiguration?.utilizarTipoCard) {
      optionsRadio.push({
        id: 3,
        descricao: capitalizeFirstLetter(
          intl.formatMessage({ id: 'tipoCard' })
        ),
      });
    }

    return optionsRadio;
  };

  const renderFormula = (item) => {
    return (
      <FormulaVisao formula={item.expressao} isItem={false}></FormulaVisao>
    );
  };

  const id = model.getModel('id') && model.getModel('id').value;
  const isNotNew = id > 0;

  const disabled = model.getModel('permissions').value
    ? !model.getModel('permissions').getModel('allowEdit').value
    : false;

  const EditComponent = isBonus ? EditBonus : EditComissoes;

  return (
    <div>
      <Tabs>
        <Tab
          key="1"
          label={capitalizeFirstLetter(intl.formatMessage({ id: 'resumo' }))}
        >
          <EditComponent
            isNotNew={isNotNew}
            model={model}
            disabled={disabled}
            renderFormula={renderFormula}
            renderRadioButton={renderRadioButton}
            handleChangeIdIntegracao={handleChangeIdIntegracao}
            handleChangeDataInicio={handleChangeDataInicio}
            handleChangeDataTermino={handleChangeDataTermino}
            handleChangeTipoCalculoScore={handleChangeTipoCalculoScore}
            handleChangeTipoCard={handleChangeTipoCard}
          />
        </Tab>
        {isBonus && isNotNew && cardMetaConfiguration?.habilitarGatilhoCesta && (
          <Tab
            key="1"
            label={capitalizeFirstLetter(
              intl.formatMessage({ id: 'gatilhos' })
            )}
          >
            <Row>
              <Col md={12}>
                <Panel>
                  <GatilhoList
                    parameters={{
                      CestaIndicadorId: model.getModel('id').value,
                    }}
                    showExport={false}
                    model={model}
                    disabled={disabled}
                  />
                </Panel>
              </Col>
            </Row>
          </Tab>
        )}
        {isNotNew && (
          <Tab
            key="1"
            label={capitalizeFirstLetter(
              intl.formatMessage({ id: 'historico' })
            )}
          >
            <Row>
              <Col md={12}>
                <Panel>
                  <HistoricoCestaIndicador cestaIndicadorId={id} />
                </Panel>
              </Col>
            </Row>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

const CestaIndicadoresEdit = ({ parameters, ...rest }) => {
  const intl = useIntl();

  const handleOnClose = async () => {
    await http.post(`/CestaIndicador/Reprocessar`, {
      funcaoId: parameters.funcaoId,
    });
  };

  return (
    <Edit
      url="/CestaIndicador"
      title={intl.formatMessage({ id: 'label.cestaIndicadores' })}
      formComponent={FormEdit}
      parameters={parameters}
      schema={schema}
      width="70%"
      height={600}
      showClose={false}
      handleOnClose={handleOnClose}
      {...rest}
    />
  );
};

export default CestaIndicadoresEdit;
