import React, { Component } from 'react';
import Valores from './valores';

class Edit extends Component {
  render() {
    const { acaoId } = this.props;
    return (
      <div>
        <Valores acaoId={acaoId} />
      </div>
    );
  }
}

export default Edit;
