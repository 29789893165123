import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import List from '../../../componentes/list';
import ListColumn from '../../../componentes/list/list-column';
import GrupoDiaEnvioEdit from './edit';

@autobind
class GrupoDiaEnvio extends React.Component {
  render() {
    let { intl } = this.props;

    return (
      <List url="/GrupoDiaEnvio" editComponent={GrupoDiaEnvioEdit} tag={10004} sort="Nome" {...this.props}>
        <ListColumn headerText={intl.formatMessage({ id: 'nome' })} required valueField="nome" sortField="Nome" valueFunction={this.renderDescricao} default />
        <ListColumn headerText={intl.formatMessage({ id: 'label.diaPrimeiroEnvio' })} valueField="diaPrimeiroEnvio" default />
        <ListColumn headerText={intl.formatMessage({ id: 'label.diaSegundoEnvio' })} valueField="diaSegundoEnvio" default />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(GrupoDiaEnvio));
