import React from 'react';
import { SketchPicker } from 'react-color';
import { autobind } from 'core-decorators';
import { FormGroup } from 'reactstrap';

import guid from '../../utils/guid';

@autobind
class Color extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
    };
    this.guid = 'color' + guid();
  }

  handleClick() {
    if (!this.props.disabled) {
      if (this.state.displayColorPicker == true)
        if (this.props.getAlteracao) this.props.getAlteracao();

      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }
  }

  handleClose() {
    this.setState({ displayColorPicker: false });
  }

  handleChange(color) {
    this.setState({ color: color.rgb });
    this.props.model.requestChange(color.hex);

    if (this.props.getAlteracao) this.props.getAlteracao();
  }

  render() {
    const {
      model,
      required,
      position,
      formStyle = {},
      hideLabel = false,
    } = this.props;
    const cor =
      this.props.model && this.props.model.value ? this.props.model.value : '';

    return (
      <FormGroup style={formStyle} onMouseLeave={this.handleClose}>
        {!hideLabel && (
          <label className="control-label">
            {model.label} {required ? '*' : ''}
          </label>
        )}

        <div id={this.guid} onClick={this.handleClick}>
          <div
            style={{
              padding: '5px',
              width: '40px',
              height: 24,
              background: '#fff',
              borderRadius: '4px',
              boxShadow: `${
                model.errors
                  ? '0 0 0 1px rgba(217,83, 79, 0.8)'
                  : '0 0 0 1px rgba(0,0,0,.1)'
              }`,
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '14px',
                borderRadius: '2px',
                background: cor,
              }}
            />
          </div>
        </div>

        {this.state.displayColorPicker && (
          <div
            style={{
              position: position ? position : 'absolute',
              zIndex: 20,
            }}
          >
            <SketchPicker
              color={cor}
              onChange={this.handleChange}
              disableAlpha
            />
          </div>
        )}
      </FormGroup>
    );
  }
}

export default Color;
