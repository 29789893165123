import React from 'react';
import ColaboradorSimplificadoLookup from '../../../../lookups/ColaboradorSimplificadoLookup';
import PacoteLookup from '../../../../lookups/pacote';
import TipoPacoteLookup from '../../../../lookups/tipo-pacote';
import GrupoDiaEnvioLookup from '../../../../lookups/grupo-dia-envio';
import { Col, Row } from 'reactstrap';
import Input from '../../../../componentes/input';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Panel from '../../../../componentes/panel';
import Checkbox from '../../../../componentes/checkbox';
import Radio from '../../../../componentes/radio';
import ClassificacaoLookup from '../../../../lookups/classificacao-mo';

class PacoteResumoEdit extends React.Component {
  handleChangePermiteGerarRelatorio(value) {
    this.props.model.getModel('gerarRelatorioAcompanhamento').requestChange(value);
    if (!value) this.props.model.getModel('gerarRelatorioAutomatico').requestChange(value);
  }

  render() {
    let { model, intl, resources } = this.props;
    let gerarRelatorio = model && model.getModel('gerarRelatorioAcompanhamento').value;

    return (
      <div>
        <Row>
          <Col md={6}>
            <Input maxLength={500} required model={model.getModel('nome')} />
          </Col>
          <Col md={3}>
            <Input required model={model.getModel('idIntegracao')} />
          </Col>
        </Row>
        <Row>
          {model.getModel('exibirClassificacao').value && model.getModel('exibirClassificacao').value && (
            <Col md={3}>
              <ClassificacaoLookup model={model.getModel('classificacao')} required />
            </Col>
          )}
          <Col md={3}>
            <TipoPacoteLookup model={model.getModel('tipo')} />
          </Col>
          <Col md={6}>
            <PacoteLookup model={model.getModel('pai')} />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <ColaboradorSimplificadoLookup multi model={model.getModel('gestores')} />
          </Col>
          <Col md={3}>
            <ColaboradorSimplificadoLookup model={model.getModel('dono')} />
          </Col>
          <Col md={12}>
            <Panel header={resources.relatoriodeacompanhamento}>
              <Row>
                <Col md={5}>
                  <Checkbox
                    model={{
                      label: intl.formatMessage({ id: 'label.gerarRelatorioAcompanhamento' }, { relatoriodeacompanhamento: resources.relatoriodeacompanhamento }),
                      value: model.getModel('gerarRelatorioAcompanhamento').value,
                      requestChange: this.handleChangePermiteGerarRelatorio.bind(this),
                    }}
                    style={{ marginTop: 20 }}
                  />
                </Col>
                <Col md={5}>
                  <Checkbox
                    disabled={!gerarRelatorio}
                    model={{
                      label: intl.formatMessage(
                        { id: 'label.gerarRelatorioAcompanhamentoAutomatico' },
                        { relatoriodeacompanhamento: resources.relatoriodeacompanhamento }
                      ),
                      value: model.getModel('gerarRelatorioAutomatico').value,
                      requestChange: model.getModel('gerarRelatorioAutomatico').requestChange,
                    }}
                    style={{ marginTop: 20 }}
                  />
                </Col>
              </Row>
              {gerarRelatorio && (
                <Col md={12}>
                  <Radio
                    model={model.getModel('tipoRelatorioAcompanhamento')}
                    options={model.getModel('opcoesTipoRelatorioAcompanhamento').value}
                    labelField="descricao"
                    idField="id"
                    className="mb-2 ml-0 mt-2"
                  />
                </Col>
              )}
            </Panel>
          </Col>
          <Col md={12}>
            <Panel header={<FormattedMessage id="label.enviarEmailValorPrevistoNaoAlcancado" />}>
              <Row>
                <Col md={6}>
                  <Checkbox
                    model={{
                      label: intl.formatMessage({ id: 'label.enviarEmailGestorPacote' }),
                      value: model.getModel('enviarEmailGestorPacote').value,
                      requestChange: model.getModel('enviarEmailGestorPacote').requestChange,
                    }}
                    style={{ marginTop: 27 }}
                  />
                </Col>
                <Col md={6}>
                  <Checkbox
                    model={{
                      label: intl.formatMessage({ id: 'label.enviarEmailGestorCentroCusto' }, { centrocusto: resources.centrocusto }),
                      value: model.getModel('enviarEmailGestorCentroCusto').value,
                      requestChange: model.getModel('enviarEmailGestorCentroCusto').requestChange,
                    }}
                    style={{ marginTop: 27 }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <GrupoDiaEnvioLookup model={model.getModel('grupoDiaEnvio')} />
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(PacoteResumoEdit));
