import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Row, Col, DropdownItem } from 'reactstrap';

import PanelCollapse from 'src/componentes/panel-collapse';
import RiscoPontoControleEdit from './RiscoPontoControleEditView';
import List from 'src/componentes/list/tree';
import ListColumn from 'src/componentes/list/list-column';
import { capitalizeFirstLetter } from 'src/utils/string';
import MdiIcon from 'src/componentes/mdi-icon';
import Legenda from 'src/paginas/gestao-risco/legenda-farol-revisao';
import CausasRisco from './causas-risco';
import { COLUNAS, TIPO_INTERACAO_SOPHIE } from 'src/utils/constants';
import ContentManager from 'src/componentes/content-manager';
import { getConfiguration } from 'src/services/commonRequests';
import { MODULOS } from 'src/utils/constants';
import Button from 'src/componentes/button';
import { UncontrolledTooltip } from 'reactstrap';
import AssistenteVirtual from 'src/components/IA/ChatGPT/AssistenteVirtual';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/errorHandler';

import ConsequenciasRisco from './consequencias-risco';
import GraficoBowTieView from '../../componentes/GraficoBowTieView';
import EfetividadeControle from './risco-controle/components/AvaliacaoEfetividadeControle';
import LoadingContainer from 'src/componentes/loading-container';
import { Alert } from 'reactstrap';
import Content from 'src/layout/DashboardLayout/Content';
import ValidarRestricaoNovo from 'src/componentes/validar-restricao-novo/ValidarRestricao';

const ValidacaoRestricao = lazy(() =>
  import('src/paginas/item/validacao-restricao')
);
const AlertMessageBox = lazy(() => import('src/componentes/message-box/alert'));

@autobind
class RiscoPontoControleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: -1,
      configuracao: {},
      isLoading: true,
      pesoControle: null,
      isLoadingPesoControle: true,
    };
  }

  static defaultProps = {
    disabled: false,
  };

  componentDidMount() {
    getConfiguration('PontoControleConfiguracao')((configuracao) =>
      this.setState({ configuracao: configuracao, isLoading: false })
    )();
  }

  obterPesoPorFuncaoControle(riscoId) {
    if (!riscoId) return;

    try {
      http
        .post('/RiscoPontoControle/ObterPesoControleRisco', {
          idRisco: riscoId,
        })
        .then((response) => {
          if (response?.data) this.setState({ pesoControle: response.data });
        });
    } catch (err) {
      errorHandler(err);
    } finally {
      this.setState({ isLoadingPesoControle: false });
    }
  }

  forceRefresh() {
    this.setState({ saved: ++this.state.saved, isLoadingPesoControle: true });
    this.obterPesoPorFuncaoControle(this.props.riscoId);
  }

  renderFarol(item) {
    return (
      <span>
        {item.farol && item.farol.cor && (
          <MdiIcon
            icon={item.farol.icone}
            color={item.farol.cor}
            colorHover={item.farol.cor}
            size={22}
          ></MdiIcon>
        )}
      </span>
    );
  }

  handleEditClick = (item, parameters) => {
    ContentManager.addContent(
      <RiscoPontoControleEdit
        item={item}
        currenttabIndex={0}
        parameters={parameters}
        afterSave={this.forceRefresh}
        {...this.props}
      />
    );
  };

  renderMenu = (item, parameters) => {
    const { intl, resources } = this.props;

    return (
      <DropdownItem onClick={this.handleEditClick.bind(this, item, parameters)}>
        <MdiIcon className="mr-2" icon="message-draw" />
        {intl.formatMessage(
          { id: 'label.revisaoPontoControle' },
          { revisaodecontrole: resources.revisaodecontrole }
        )}
      </DropdownItem>
    );
  };

  handleDelete(item) {
    ContentManager.addContent(
      <ValidarRestricaoNovo
        title="labelExcluirVinculoControleRisco"
        description="labelExcluirVinculoControleRiscoDescricao"
        subDescription="labelExcluirVinculoControleRiscoDescricao2"
        url="/RiscoPontoControle/ValidarRestricoes"
        urlExclusao="/RiscoPontoControle/Remove"
        item={item}
        onClose={this.handleDeleteClickConfirm.bind(this)}
      />
    );
    // ContentManager.addContent(
    //   <ValidacaoRestricao
    //     url="/RiscoPontoControle/ValidarRestricoes"
    //     urlExclusao="/RiscoPontoControle/Remove"
    //     item={item}
    //     onClose={this.handleDeleteClickConfirm.bind(this)}
    //   />
    // );
  }

  handleDeleteClickConfirm(excluido) {
    if (excluido) {
      ContentManager.addContent(
        <AlertMessageBox
          title={this.props.intl.formatMessage({ id: 'sucesso' })}
          message={this.props.intl.formatMessage({
            id: 'label.registroExcluidoComSucesso',
          })}
        />
      );

      this.forceRefresh(this.state.saved + 1);
    }
  }

  renderPeso = (item) => {
    return <>{item?.peso ? `${item.peso}%` : ''}</>;
  };

  renderPontoControle = (item, parameters) => {
    return (
      <a tabindex="-1" onClick={() => this.handleEditClick(item, parameters)}>
        {item.pontoControle}
      </a>
    );
  };

  handleOpenAddControle() {
    let riscoId = this.props.riscoId;
    if (!riscoId) {
      riscoId = this.props.model.getValue('id');
    }

    ContentManager.addContent(
      <RiscoPontoControleEdit
        url="/RiscoPontoControle"
        parameters={{ riscoId: riscoId }}
        saved={this.state.saved}
        forceRefresh={true}
        afterSave={this.forceRefresh}
        {...this.props}
      />
    );
  }

  renderButtonAddControleRiscoIA() {
    return [
      <Button
        id={'botaoMapearControlesRisco'}
        key={1}
        className="ml-2"
        onClick={() => this.handleOpenAddControle()}
        type="primary"
      >
        <MdiIcon icon="plus" />
        <span className="ml-2">
          {capitalizeFirstLetter(this.props.resources.pontosdecontrole)}
        </span>
      </Button>,
    ];
  }

  handleAssistenteVirtualClick() {
    const { user, intl, resources, model } = this.props;

    let riscoId = this.props.riscoId;
    if (!riscoId) {
      riscoId = model.getValue('id');
    }

    try {
      http
        .post('/RiscoPontoControle/ObterPromptMapearControles', {
          idRisco: riscoId,
        })
        .then((response) => {
          const prompt = response.data?.prompt;
          const nome = response.data?.nome;

          const msgConsultoraVirtual = intl
            .formatMessage({ id: 'tituloSophieMapearControlesRisco' })
            .replace(/{risco}|{nomeRisco}/gi, function (matched) {
              switch (matched) {
                case '{risco}':
                  return resources.risco;
                case '{nomeRisco}':
                  return nome;
              }
            });

          ContentManager.addContent(
            <AssistenteVirtual
              user={user}
              prompt={prompt}
              title={msgConsultoraVirtual}
              actionsToolBar={this.renderButtonAddControleRiscoIA()}
              questionBold={intl.formatMessage({ id: 'vamosComecar' })}
              interactionType={TIPO_INTERACAO_SOPHIE.SUGERIR_CONTROLES_RISCO}
            />
          );
        });
    } catch (err) {
      errorHandler(err);
    }
  }

  renderAlertaPesoDistribuido = (
    preventivo,
    habilitadoEfetividadePonderada
  ) => {
    if (!habilitadoEfetividadePonderada) return null;
    const { intl, resources } = this.props;
    const { pesoControle } = this.state;

    if (this.state.isLoadingPesoControle) return null;
    if (!pesoControle?.haControles) return null;

    if (
      this.state.configuracao.avaliarEfetividadeControleSeparadamente &&
      ((preventivo && pesoControle?.pesoPreventivo != 100) ||
        (!preventivo && pesoControle?.pesoCorretivo != 100))
    ) {
      return (
        <Row>
          <Col md={12}>
            <Alert color="warning">
              <p>
                {`${intl.formatMessage(
                  {
                    id: preventivo
                      ? 'msgValidacaoAtencaoPesoTodosControlesNaoTotalizam100Preventivo'
                      : 'msgValidacaoAtencaoPesoTodosControlesNaoTotalizam100Corretivo',
                  },
                  { pontosdecontrole: resources.pontosdecontrole }
                )} ${
                  preventivo
                    ? pesoControle?.pesoPreventivo
                    : pesoControle?.pesoCorretivo
                }%`}
              </p>
            </Alert>
          </Col>
        </Row>
      );
    } else if (
      !this.state.configuracao.avaliarEfetividadeControleSeparadamente &&
      pesoControle?.peso != 100
    ) {
      return (
        <Row>
          <Col md={12}>
            <Alert color="warning">
              <p>
                {`${intl.formatMessage(
                  {
                    id: 'msgValidacaoAtencaoPesoTodosControlesNaoTotalizam100',
                  },
                  { pontosdecontrole: resources.pontosdecontrole }
                )} ${pesoControle?.peso}%`}
              </p>
            </Alert>
          </Col>
        </Row>
      );
    }
  };

  render() {
    let {
      model,
      intl,
      resources,
      riscoId,
      disabled,
      showPaginacaoSimples,
      pageSize,
      modules,
      moduloInteligenciaArtificial,
    } = this.props;
    const allowDelete = disabled
      ? false
      : model && model.getModel('permissions').getModel('allowDelete').value;

    if (!riscoId) riscoId = model.getValue('id');

    const hasGR = modules.find((m) => m.id === MODULOS.GESTAO_RISCO && m.ativo);
    const configuracaoRisco = model.getModel('configuracaoRisco')
      ? model.getModel('configuracaoRisco').value
      : null;

    const habilitadoEfetividadePonderada =
      this.state.configuracao?.avaliarEfetividadeControle &&
      this.state.configuracao
        ?.calcularEfetividadeConformeEfetividadeIndividual &&
      this.state.configuracao?.calcularEfetividadeGeralFormaPonderada;

    const pesoHabilitado =
      this.state.configuracao?.avaliarEfetividadeControle &&
      this.state.configuracao?.avaliarEfetividadeControleIndividualmente &&
      this.state.configuracao
        ?.calcularEfetividadeConformeEfetividadeIndividual &&
      this.state.configuracao?.calcularEfetividadeGeralFormaPonderada;

    if (habilitadoEfetividadePonderada && this.state.isLoadingPesoControle) {
      this.obterPesoPorFuncaoControle(riscoId);
    }

    const actionsToolBar = moduloInteligenciaArtificial
      ? [
          <>
            <Button
              id={'botaoMapearControlesRisco'}
              key={1}
              className="ml-2"
              onClick={this.handleAssistenteVirtualClick}
              type="primary"
            >
              <img alt="IA logo" src="/ia/ia_branco.svg" />
              <span className="ml-2">
                {intl.formatMessage(
                  { id: 'mapearControles' },
                  {
                    pontosdecontrole: capitalizeFirstLetter(
                      resources.pontosdecontrole
                    ),
                  }
                )}
              </span>
            </Button>
            <UncontrolledTooltip
              placement="right"
              target={'botaoMapearControlesRisco'}
            >
              {intl.formatMessage(
                {
                  id: 'labelSophieToolTipMapearControlesRisco',
                },
                {
                  riscos: resources.riscos,
                }
              )}
            </UncontrolledTooltip>
          </>,
        ]
      : [];

    return (
      <>
        <Row>
          {hasGR && (
            <Col md={12}>
              {configuracaoRisco?.exibirDiagramaBowTie && (
                <PanelCollapse
                  open={true}
                  header={capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'labelBowTie' },
                      { risco: resources.risco }
                    )
                  )}
                >
                  <GraficoBowTieView
                    riscoId={riscoId}
                    saved={this.state.saved}
                  />
                </PanelCollapse>
              )}
            </Col>
          )}
        </Row>
        <Row>
          {configuracaoRisco?.utilizarCausaRisco && (
            <Col md={6}>
              <PanelCollapse
                open={true}
                header={capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'labelCausasDoRisco' },
                    {
                      pontosproblematicos: resources.pontosproblematicos,
                      risco: resources.risco,
                    }
                  )
                )}
              >
                <CausasRisco
                  refreshCount={this.forceRefresh}
                  model={model.getModel('causasRisco')}
                  riscoId={riscoId}
                  configuracao={this.props.configuracao}
                  pageSize={999}
                  refreshAfterRemove={this.state.saved}
                  disabled={disabled}
                />
              </PanelCollapse>
            </Col>
          )}
          {configuracaoRisco?.utilizarConsequenciaRisco && (
            <Col md={6}>
              <PanelCollapse
                open={true}
                header={capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'labelConsequenciasDoRisco' },
                    {
                      consequencias: resources.consequencias,
                      risco: resources.risco,
                    }
                  )
                )}
              >
                <ConsequenciasRisco
                  refreshCount={this.forceRefresh}
                  model={model.getModel('consequenciasRisco')}
                  riscoId={riscoId}
                  configuracao={this.props.configuracao}
                  pageSize={999}
                  refreshAfterRemove={this.state.saved}
                  disabled={disabled}
                />
              </PanelCollapse>
            </Col>
          )}
        </Row>

        {hasGR && (
          <PanelCollapse
            header={capitalizeFirstLetter(resources.pontosdecontrole)}
          >
            {this.state.configuracao.avaliarEfetividadeControleSeparadamente &&
              this.renderAlertaPesoDistribuido(true, pesoHabilitado)}
            {this.renderAlertaPesoDistribuido(false, pesoHabilitado)}
            <LoadingContainer isLoading={this.state.isLoading}>
              {this.props.showEfetividadeControlePanel && (
                <EfetividadeControle
                  {...this.props}
                  model={this.props.modelOrigem}
                  disabled={this.props.disabledEfetividadeControlePanel}
                  required={this.props.requiredEfetividadeControlePanel}
                />
              )}
              {!this.state.isLoading && (
                <List
                  url="/RiscoPontoControle"
                  tipoItem={6}
                  parameters={{ riscoId: riscoId }}
                  editComponent={RiscoPontoControleEdit}
                  showPage
                  pageSize={pageSize ? pageSize : 20}
                  showExport={false}
                  disabled={disabled ? disabled : false}
                  showDelete={allowDelete}
                  showPaginacaoSimples={showPaginacaoSimples}
                  modelOrigem={this.props.modelOrigem}
                  showNew={disabled ? false : true}
                  saved={this.state.saved}
                  tag={COLUNAS.RISCO_PONTO_CONTROLE}
                  hierarchy={true}
                  showPagination={true}
                  retirarTreeCamposCustomizados={true}
                  showFilter={false}
                  showSearch={false}
                  showQuickSearch={true}
                  showMenu={true}
                  onDeleting={this.handleDelete}
                  forceRefresh={true}
                  actionsToolBarEnd={actionsToolBar}
                  hideInsertDropDown={true}
                  afterSave={() => this.forceRefresh()}
                  {...this.props}
                >
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      resources.pontodecontrole
                    )}
                    tree={true}
                    default
                    valueField="pontoControle"
                    required
                    valueFunction={this.renderPontoControle}
                  />
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage({ id: 'observacao' })
                    )}
                    valueField="descricao"
                  />
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage({ id: 'periodicidade' })
                    )}
                    valueField="periodicidade"
                  />
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'responsavel' },
                        { responsavel: resources.responsavel }
                      )
                    )}
                    valueField="responsavel"
                  />
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage({ id: 'status' })
                    )}
                    valueField="status"
                  />
                  <ListColumn
                    headerText={capitalizeFirstLetter(resources.farol)}
                    valueField="farol"
                    valueFunction={this.renderFarol}
                  />
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage({ id: 'tolerancia' })
                    )}
                    valueField="diasToleranciaRevisao"
                    sortField="DiasToleranciaRevisao"
                  />
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage({ id: 'tipoTolerancia' })
                    )}
                    valueField="tipoTolerancia"
                    sortField="TipoTolerancia.Descricao"
                  />
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage({
                        id: 'numeroRevisoesGeradasAutomaticamente',
                      })
                    )}
                    valueField="numeroRevisoesGeradasAutomaticamente"
                    sortField="NumeroRevisoesGeradasAutomaticamente"
                  />
                  {this.state.configuracao &&
                    this.state.configuracao
                      .avaliarEfetividadeControleSeparadamente && (
                      <ListColumn
                        headerText={capitalizeFirstLetter(
                          intl.formatMessage(
                            { id: 'funcaoPontoControle' },
                            { pontodecontrole: resources.pontodecontrole }
                          )
                        )}
                        valueField="funcaoPontoControle"
                      />
                    )}
                  <ListColumn
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage({ id: 'tipoDeControle' })
                    )}
                    valueField="tipoDeControle"
                    sortField="TipoDeControle"
                  />
                  <ListColumn
                    visible={
                      this.state.configuracao &&
                      this.state.configuracao.avaliarEfetividadeControle &&
                      this.state.configuracao
                        .avaliarEfetividadeControleIndividualmente
                    }
                    headerText={capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'efetividadeControle' },
                        { pontodecontrole: resources.pontodecontrole }
                      )
                    )}
                    valueField="ultimaEfetividade"
                  />
                  <ListColumn
                    headerText={`${capitalizeFirstLetter(
                      intl.formatMessage({ id: 'peso' })
                    )} (%)`}
                    visible={habilitadoEfetividadePonderada && pesoHabilitado}
                    valueField="peso"
                    valueFunction={this.renderPeso}
                    sortField="peso"
                  />
                </List>
              )}
              <Legenda tipoFarol={1} />
            </LoadingContainer>
          </PanelCollapse>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    modules: state.user.licenca.modulos,
    moduloInteligenciaArtificial: state.user.moduloInteligenciaArtificial,
    user: state.user,
  };
}

export default injectIntl(connect(mapStateToProps)(RiscoPontoControleList));
