import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../dialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Georeferencia from '../../../paginas/item/georeferencia';
import Panel from '../../../componentes/panel';
import $ from 'jquery';
import http from 'src/services/httpService';
import errorHandler from '../../../utils/error-handler';

@autobind
class GeoreferenciaExibicao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listaItensCoordenadas: null,
      filtroId: this.props.filtroId,
    };
  }

  handleClose() {
    $('body').removeClass('modal-open');
    this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
      this.props.handleClose && this.props.handleClose(this.salvo, this.form.getValue('id'));
    }, 300);
  }

  componentDidMount() {
    http
      .post(`/Item/ObterCoordenadasGeoreferencia`, {
        tipo: this.props.filtroId,
        searchModel: this.props.searchModel,
      })
      .then((response) => {
        this.setState({
          listaItensCoordenadas: response.data,
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  render() {
    return (
      <Dialog
        ref="dialog"
        title={<FormattedMessage id="label.georeferencia" />}
        width={800}
        heigth={700}
        style={{ fontSize: 12 }}
        onRequestClose={this.handleClose}
        padContent
      >
        {this.state.listaItensCoordenadas && (
          <Panel header={<FormattedMessage id="label.georeferencia" />}>
            <Georeferencia
              exibir={false}
              isNew={true}
              endereco=""
              latitude=""
              longitude=""
              listaItensCoordenadas={this.state.listaItensCoordenadas}
              onClose={this.handleClose}
              filtroId={this.state.filtroId}
            ></Georeferencia>
          </Panel>
        )}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(GeoreferenciaExibicao));
