import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import TipoCardLookup from 'src/lookups/tipo-card';
import http from 'src/services/httpService';
import LoadingContainer from 'src/componentes/loading-container';

import TipoCardEdit from './tipo-card-edit';
@autobind
class TipoCardView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tipoCardId: 0,
      itens: [],
    };
  }

  componentDidMount() {
    this.refreshView();
  }

  refreshView() {
    this.getItens();
  }

  getItens() {
    let { model } = this.props;
    const parameters = {
      ...this.props.parameters,
      tipoCardId: model.getModel('tipoCard').value
        ? model.getModel('tipoCard').value.id
        : 0,
      tipoEntidade: 3,
    };
    this.setState({ isLoading: true });
    http
      .post(`/CestaIndicador/ObterCategoriasTipoCard`, {
        parameters: parameters,
      })
      .then((response) => {
        this.setState({ itens: response.data.item1, isLoading: false });
      });
  }

  render() {
    let { intl, model, disabled } = this.props;
    const parameters = {
      ...this.props.parameters,
      tipoCardId: model.getModel('tipoCard').value
        ? model.getModel('tipoCard').value.id
        : 0,
      tipoEntidade: 3,
    };
    let refresh = true;

    if (this.state.tipoCardId !== parameters.tipoCardId) {
      this.setState({ tipoCardId: parameters.tipoCardId }, () =>
        this.getItens()
      );
    } else refresh = false;

    return (
      <div>
        <Row>
          <Col md={3}>
            <TipoCardLookup
              disabled={disabled}
              model={{
                label: intl.formatMessage({ id: 'tipoCard' }),
                value: model.getModel('tipoCard').value,
                requestChange: model.getModel('tipoCard').requestChange,
                errors: model.getModel('tipoCard').errors,
              }}
            />
          </Col>
        </Row>
        {model.getModel('tipoCard').value && (
          <LoadingContainer isLoading={this.state.isLoading}>
            {this.state.itens && this.state.itens.length > 0 && (
              <Row>
                <Col md={12}>
                  <List
                    {...this.props}
                    dataSource={this.state.itens}
                    editComponent={TipoCardEdit}
                    parameters={parameters}
                    showSearch={false}
                    showDelete={false}
                    showNew={false}
                    showExport={false}
                    local={true}
                    refreshModel={this.refreshView}
                    forceRefresh={refresh}
                    showMenu={!disabled}
                  >
                    <ListColumn
                      valueField="idIntegracao"
                      default
                      visible={false}
                    />
                    <ListColumn
                      valueField="categoriaId"
                      default
                      visible={false}
                    />
                    <ListColumn
                      headerText={intl.formatMessage({ id: 'nome' })}
                      valueField="nome"
                      default
                    />
                  </List>
                </Col>
              </Row>
            )}
          </LoadingContainer>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(TipoCardView));
