const ProgressBar = ({ current, total }) => {
  const percentage = (current / total) * 100;

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#e0e0e0',
        borderRadius: '5px',
        height: '20px',
        position: 'relative', // Para posicionar o texto absolutamente dentro deste container
      }}
    >
      <div
        style={{
          width: `${percentage}%`,
          backgroundColor: '#1D7789',
          height: '100%',
          borderRadius: '5px',
        }}
      />
      <div
        style={{
          position: 'absolute', // Posiciona o texto absolutamente
          top: '50%', // Centraliza verticalmente
          left: '50%', // Centraliza horizontalmente
          transform: 'translate(-50%, -50%)', // Ajusta o centro exato
          color: '#ffffff', // Cor do texto
          fontSize: '12px', // Tamanho da fonte
          fontWeight: 'bold', // Texto em negrito
        }}
      >
        {percentage.toFixed(2)}%
      </div>
    </div>
  );
};

export default ProgressBar;