import React from 'react';
import { autobind } from 'core-decorators';
import List from '../../../../componentes/list';
import ListColumn from '../../../../componentes/list/list-column';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/string';
import TipoAcessoLookup from '../../../../lookups/tipo-acesso';
import PermissaoEdit from './edit';
import http from 'src/services/httpService';
import errorHandler from '../../../../utils/error-handler';
import Panel from '../../../../componentes/panel/index';
import { Row, Col } from 'react-bootstrap';
import Info from '../../../../componentes/info';
import { Form } from '../../../../utils/form';
import Schema from './schema';
import MensagemPersonalizada from '../../../../componentes/react-toastify';
import LoadingContainer from '../../../../componentes/loading-container';
@autobind
class ParteInteressada extends React.Component {
  static defaultProps = {
    url: '/ItemPermissao',
    action: 'AtualizarTipoAcesso',
  };

  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      disabled: true,
    };

    this.form = new Form({
      component: this,
      schema: Schema,
    });
  }

  componentDidMount() {
    let model = this.form.getModel();
    if (this.props.actionPermission != null) {
      this.setState({
        isLoading: true,
      });
      http
        .post(`${this.props.url}/${this.props.actionPermission}`, {
          itemId: this.props.id,
          tipoAcessoArea: true,
        })
        .then((response) => {
          this.setState({
            disabled: response.data
              ? !response.data.permissions.allowEdit
              : false,
            isLoading: false,
            allowView: response.data?.permissions?.allowView,
          });

          model
            .getModel('tipoAcessoArea')
            .requestChange(response.data.tipoAcessoArea);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
        });
    }
  }

  handleChangeTipoAcessoArea(value) {
    http
      .post(`Item/SaveTipoAcessoArea`, {
        itemId: this.props.id,
        tipoAcesso: value,
      })
      .then((response) => {
        this.setState({
          saved: Math.random(),
          isLoading: true,
          showMessage: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });
  }

  handleChangeAcesso(item, valor) {
    http
      .post(`${this.props.url}/${this.props.action}`, {
        itemPermissaoId: item.id,
        tipoAcessoId: valor ? valor.id : 0,
      })
      .then((response) => {
        this.setState({
          saved: Math.random(),
          isLoading: true,
          showMessage: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        errorHandler(error);
      });
  }

  renderAcesso(item) {
    return (
      <TipoAcessoLookup
        disabled={this.state.disabled}
        model={{
          value: item.tipoAcesso,
          requestChange: this.handleChangeAcesso.bind(this, item),
        }}
        style={{ marginBottom: '-5px' }}
      ></TipoAcessoLookup>
    );
  }

  render() {
    let { model, resources, intl, clearable, parameters, ...other } =
      this.props;
    let { tipoItemId } = parameters;
    let { disabled, allowView } = this.state;

    return (
      <div>
        {tipoItemId &&
          (tipoItemId === 1 ||
            tipoItemId === 2 ||
            tipoItemId === 3 ||
            tipoItemId === 4) && (
            <LoadingContainer isLoading={this.state.isLoading}>
              <Row>
                <Col md={4}>
                  <div style={{ display: 'inline-flex' }}>
                    <TipoAcessoLookup
                      disabled={disabled}
                      clearable={true}
                      model={{
                        label: intl.formatMessage(
                          { id: 'label.acessoParaColaboradoresPermissaoArea' },
                          {
                            colaboradores: resources.colaboradores,
                            area: resources.area,
                          }
                        ),
                        value: model.getModel('tipoAcessoArea').value,
                        requestChange:
                          model.getModel('tipoAcessoArea').requestChange,
                      }}
                    />
                    <Info
                      id="utilizarCodigoAutomatico"
                      placement="right"
                      text={intl.formatMessage(
                        {
                          id: 'label.acessoParaColaboradoresPermissaoAreaObservacao',
                        },
                        {
                          colaborador: resources.colaborador,
                          area: resources.area,
                          indicador: resources.indicador,
                        }
                      )}
                    />
                  </div>
                </Col>
              </Row>
            </LoadingContainer>
          )}
        {allowView && (
          <List
            url={this.props.url}
            saved={this.state.saved}
            editComponent={PermissaoEdit}
            showSearch={true}
            showNew={!disabled}
            showMenu={!disabled}
            parameters={parameters}
            forceRefresh={true}
            {...other}
          >
            <ListColumn
              headerText={capitalizeFirstLetter(resources.colaborador)}
              valueField="colaborador"
            ></ListColumn>
            <ListColumn
              headerText={capitalizeFirstLetter(resources.area)}
              valueField="area"
            ></ListColumn>
            <ListColumn
              headerText={intl.formatMessage({ id: 'label.acesso' })}
              valueFunction={this.renderAcesso}
              minHeight={38}
            ></ListColumn>
          </List>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ParteInteressada));
