export function visitTree(array, childrenField, visitor, level = 0, parent) {
  for (let i = 0; i < array.length; i++) {
    let item = array[i];
    visitor(item, level, parent);
    let children = item[childrenField];
    if (children) visitTree(children, childrenField, visitor, level + 1, item);
  }
}

export function filterTree(array, childrenField, predicate) {
  let matches = [];
  visitTree(array, childrenField, (item, level, parent) => {
    if (predicate(item, level, parent)) {
      matches.push(item);
    }
  });
  return matches;
}
