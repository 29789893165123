import React from 'react';
import { autobind } from 'core-decorators';
import Edit from '../../../componentes/edit/edit-dialog';
import ContentManager from '../../../componentes/content-manager';
import Schema from './schema';
import Tabs from '../../../componentes/tabs/tabs';
import Tab from '../../../componentes/tabs/tab';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';
import qs from 'query-string';
import ValidacaoRestricao from '../../item/validacao-restricao';
import Resumo from './resumo';
import Permissoes from 'src/paginas/item/edit/permissao';
import Acao from 'src/paginas/NewGantt';

let disabled;

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false,
    };
  }

  handleCallBack() {
    this.setState({
      saved: !this.state.saved,
    });
  }

  render() {
    let { model, resources, disabledEdit = false } = this.props;
    disabled = disabledEdit ? disabledEdit : model.getModel('permissions').value
      ? !model.getModel('permissions').getModel('allowEdit').value
      : false;
    const idProcesso = model.getModel('id')?.value;
    return (
      <div style={{ marginTop: 15 }}>
        <Tabs>
          <Tab key="1" label={<FormattedMessage id="label.resumo" />}>
            <Resumo model={model} disabled={disabled} />
          </Tab>
          {idProcesso && idProcesso > 0 && (
            <Tab key={'3'} label={capitalizeFirstLetter(resources.planodeacao)}>
              <Acao itemId={idProcesso} ehApresentacao={true} />
            </Tab>
          )}
          {idProcesso && idProcesso > 0 && (
            <Tab
              key="2"
              label={<FormattedMessage id="label.permissoesDeAcesso" />}
            >
              <Permissoes
                model={model}
                id={idProcesso}
                url="/ItemPermissao"
                action="AtualizarTipoAcesso"
                actionPermission="ObterItemPermissions"
                parameters={{
                  itemId: idProcesso,
                  tipoItemId: 5,
                  url: '/ItemPermissao',
                }}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

@autobind
class ProcessoEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id
        ? this.props.id
        : this.props.location && qs.parse(this.props.location.search).id,
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleSaved(data) {
    this.setState({ id: data.id });
  }

  handleCloseEdit(saved) {
    this.props.handleClose && this.props.handleClose(saved);
    if (saved) this.setState({ saved: ++this.state.saved });

  }

  handleDeleteClick(item) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/Processo/ValidarRestricoes"
        urlExclusao="/Processo/AtualizarStatusExcluido"
        item={item}
        urlList="/gerenciar/Processo"
        urlEdit="/gerenciar/Processo/edit"
        onClose={this.handleCloseEdit}
        onRequestClose={this.props.onRequestClose}
        isEdit={true}
      />
    );
  }

  render() {
    let { resources, showPrompt, disabledEdit = false, ...rest } = this.props;
    let id = this.state.id;

    return (
      <div>
        <Edit
          width="85%"
          height="90%"
          {...rest}
          url="/Processo"
          parameters={{ tipoId: 5 }}
          disabledEdit={disabledEdit}
          titleField="nome"
          title={capitalizeFirstLetter(resources.processo)}
          formComponent={FormEdit}
          schema={Schema}
          handleSaved={(saved) => this.handleSaved(saved)}
          handleClose={(saved) => this.handleCloseEdit(saved)}
          showDelete={this.props.hideDelete ? false : true}
          onDeleting={this.handleDeleteClick}
          resources={resources}
          showPrompt={showPrompt}
          handleCloseCustom={this.props.onRequestClose}
          showActions={true}
          showSaveClose={!disabledEdit && true}        
        ></Edit>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(ProcessoEdit);
