import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import css from '../../../utils/css';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const classes = css`
  .cabecalho {
    height: 32px;
    font-size: 14px;
    padding: 5px;
    background: #edf1f2;
  }
`;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// using some little inline style helpers to make the app look okay
const grid = 8;
const getItemStyle = (draggableStyle, isDragging, required) => ({
  // some basic styles to make the items look a bit nicer
  fontSize: '12px',
  userSelect: 'none',
  padding: '0px',
  margin: `0 0 0px 0`,
  //borderRadius: '0px',
  cursor: !required && 'move',

  // change background colour if dragging
  background: isDragging ? '#white' : required ? '#fee0bd' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#edf1f2' : 'white',
  height: 400,
  width: 270,
  overflow: 'auto',
  border: 'solid 1px #ccc',
  borderRadius: 6,
  textAlign: 'center',
});

class DragDropComponent extends React.Component {
  static defaultProps = {
    palavraChave: 'Colunas',
    esconderColunasDisponiveis: false,
  };

  constructor(props) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let CamposDisponiveis = this.props.listaColunasDisponiveis.value
      ? this.props.listaColunasDisponiveis.value
      : [];
    let CamposUsados = this.props.listaColunasUsadas.value
      ? this.props.listaColunasUsadas.value
      : [];

    //Jogando itens disponíveis para a lista de usados
    //e atualizando a ordem
    if (result.destination.droppableId == 'droppable2') {
      if (this.props.isValidDragToUsedColumns) {
        const isValid = this.props.isValidDragToUsedColumns(result);
        if (!isValid) return;
      }

      if (result.source.droppableId == 'droppable') {
        CamposUsados.push(CamposDisponiveis[result.source.index]);
        CamposDisponiveis.splice(result.source.index, 1);

        let items = reorder(
          CamposUsados,
          CamposUsados.length - 1,
          result.destination.index
        );

        this.props.listaColunasUsadas.requestChange(items);
      }
      //Ordenando itens dentro da própria lista
      else {
        let items = reorder(
          this.props.listaColunasUsadas.value,
          result.source.index,
          result.destination.index
        );

        this.props.listaColunasUsadas.requestChange(items);
      }
    }

    if (result.destination.droppableId == 'droppable') {
      //Jogando itens usados para a lista de disponíresponsáveis
      //e atualizando a ordem
      if (result.source.droppableId == 'droppable2') {
        let required = CamposUsados[result.source.index].descricao
          ? CamposUsados[result.source.index].obrigatorio
          : CamposUsados[result.source.index].required
          ? CamposUsados[result.source.index].required
          : false;
        //Só retira da lista de usados os campos não obrigatórios
        if (!required) {
          CamposDisponiveis.push(CamposUsados[result.source.index]);
          CamposUsados.splice(result.source.index, 1);

          let items = reorder(
            CamposDisponiveis,
            CamposDisponiveis.length - 1,
            result.destination.index
          );

          this.props.listaColunasDisponiveis.requestChange(items);
        }
      }
      //Ordenando itens dentro da própria lista
      else {
        let items = reorder(
          this.props.listaColunasDisponiveis.value,
          result.source.index,
          result.destination.index
        );

        this.props.listaColunasDisponiveis.requestChange(items);
      }
    }
  }

  renderLabelDisponiveis() {
    let titulo = this.props.palavraChave;

    if (titulo === 'Colunas') return 'label.colunasDisponiveis';
    else if (titulo === 'Campos') return 'camposDisponiveis';
    else if (titulo == 'Favoritos') return 'todos';
  }

  renderLabelUtilizadas() {
    let titulo = this.props.palavraChave;

    if (titulo === 'Colunas') return 'label.colunasUsadas';
    else if (titulo === 'Campos') return 'camposUsados';
    else if (titulo == 'Favoritos') return 'favoritos';
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    let {
      listaColunasDisponiveis,
      listaColunasUsadas,
      esconderColunasDisponiveis,
    } = this.props;

    return (
      <div className="d-flex justify-content-center">
        <DragDropContext onDragEnd={this.onDragEnd}>
          {!esconderColunasDisponiveis && (
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className="mr-4"
                >
                  <div className={classes.cabecalho}>
                    <FormattedMessage id={this.renderLabelDisponiveis()} />
                  </div>
                  <div style={{ padding: grid }}>
                    {listaColunasDisponiveis.value &&
                      listaColunasDisponiveis.value.map((item, index) => (
                        <Draggable
                          index={index}
                          isDragDisabled={item.disabled}
                          key={(item.id ? item.id : item.valueField) + ''}
                          draggableId={
                            (item.id ? item.id : item.valueField) + ''
                          }
                        >
                          {(provided, snapshot) => (
                            <div className={'pb-2 shadow p-0 rounded'}>
                              <div
                                className={'shadow p-0 rounded'}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  provided.draggableProps.style,
                                  snapshot.isDragging,
                                  false
                                )}
                              >
                                {item.descricao || item.headerText}
                              </div>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className={classes.cabecalho}>
                  <FormattedMessage id={this.renderLabelUtilizadas()} />
                </div>
                <div style={{ padding: grid }}>
                  {listaColunasUsadas.value &&
                    listaColunasUsadas.value.map((item, index) => (
                      <Draggable
                        index={index}
                        isDragDisabled={item.disabled}
                        key={(item.id ? item.id : item.valueField) + ''}
                        draggableId={(item.id ? item.id : item.valueField) + ''}
                      >
                        {(provided, snapshot) => (
                          <div className={'pb-2 shadow p-0 rounded'}>
                            <div
                              className={'shadow p-0 rounded'}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                provided.draggableProps.style,
                                snapshot.isDragging,
                                item.descricao
                                  ? item.obrigatorio
                                  : item.required
                                  ? item.required
                                  : false
                              )}
                            >
                              {item.descricao || item.headerText}
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(DragDropComponent));
