import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class SeveridadeRiscoLookup extends React.Component {
  render() {
    return <Lookup title="nome" labelKey="nome" valueKey="id" url={this.props.options ? null : "/SeveridadeRiscoLookup"} clearable={false} {...this.props} />;
  }
}

export default SeveridadeRiscoLookup;
