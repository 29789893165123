import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import SvgIcon from '../componentes/svg-icon';

@autobind
class FarolLookup extends React.Component {
  static defaultProps = {
    showDescription: false,
  };
  renderFarol(item) {
    return (
      item &&
      item.data && (
        <span>
          <SvgIcon
            icon={item.data.icone}
            size={18}
            color={item.data.cor}
            colorHover={item.data.cor}
          ></SvgIcon>
          {this.props.showDescription && <span>{item.data.legenda}</span>}
        </span>
      )
    );
  }
  render() {
    return (
      <Lookup
        labelKey="legenda"
        valueKey="id"
        url="/FarolLookup"
        clearable={false}
        data={{ idsIgnorados: [6] }}
        valueComponent={this.renderFarol}
        {...this.props}
      />
    );
  }
}

export default FarolLookup;
