import React from 'react';
import Edit from '../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import Input from '../../componentes/input';
import { Row, Col } from 'reactstrap';
import Tabela from './tabela';
import { FormattedMessage } from 'react-intl';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  itens: Schema.array().label(<FormattedMessage id="label.itens" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model } = this.props;
    let id = model.getModel('id') && model.getModel('id').value;

    return (
      <div>
        <Row>
          <Col md={8}>
            <Input model={model.getModel('descricao')} required />
          </Col>
          <Col md={4}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Tabela
              model={model.getModel('itens')}
              allowNewItem={id > 0 ? true : false}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default class TabelaValorEdit extends React.Component {
  render() {
    return (
      <Edit
        url="/TabelaValor"
        title="listaRegistros"
        formComponent={FormEdit}
        schema={schema}
        width={900}
        height={500}
        {...this.props}
      />
    );
  }
}
