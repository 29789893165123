import React from 'react';
import { Badge } from 'reactstrap';
import { autobind } from 'core-decorators';
import ListTree from '../../componentes/list/tree';
import ListColumn from '../../componentes/list/list-column';
import Search from './search';
import SearchSchema from './search-schema';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';

@autobind
class Item extends React.Component {
  renderTipo(item) {
    return <div>{capitalizeFirstLetter(item.tipoNome)}</div>;
  }

  renderTags(item) {
    return item.tags?.map((x) => (
      <Badge pill className="text-white" style={{ background: x.cor }}>
        {x.descricao}
      </Badge>
    ));
  }

  preventRemoveFilterChip(field, filter) {
    console.log('field', field);
    const { parameters } = this.props;
    return (
      field === 'negociacaoMetasCatalogo' &&
      parameters?.negociacaoMetasCatalogo != null
    );
  }

  render() {
    const { intl, resources, hierarchy = false, parameters } = this.props;

    return (
      <ListTree
        showSearch={true}
        searchComponent={<Search />}
        searchSchema={SearchSchema}
        url="/Item"
        titulo="Itens"
        hierarchy={hierarchy}
        pageSize={hierarchy ? 999 : 10}
        showMenu={false}
        showNew={false}
        filtroId={parameters?.negociacaoMetasCatalogo ? 218 : 17}
        tag={2015}
        showPagination={true}
        parameters={parameters}
        sort="UltimaVersao.Nome"
        preventRemoveFilterChip={this.preventRemoveFilterChip}
        openLastFilter={!parameters?.negociacaoMetasCatalogo}
        selectedFilter={
          parameters?.negociacaoMetasCatalogo
            ? {
                valor: {
                  negociacaoMetasCatalogo: parameters?.negociacaoMetasCatalogo,
                },
              }
            : null
        }
        {...this.props}
      >
        <ListColumn
          tree={hierarchy}
          headerText={intl.formatMessage({ id: 'nome' })}
          valueField="nome"
          sortField="UltimaVersao.Nome"
          default
          required
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'codigo' })}
          tooltip="Código de Importação"
          valueField="idIntegracao"
          sortField="UltimaVersao.idIntegracao"
          default
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.area)}
          valueField="area"
          sortField="UltimaVersao.Area.Nome"
          default
        />
        <ListColumn
          headerText={capitalizeFirstLetter(resources.responsaveis)}
          valueField="responsaveis"
          default
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'tipo' })}
          valueField="tipo"
          valueFunction={this.renderTipo}
          sortField="tipoItem"
          default
          required
        />
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'tags' },
            { tags: resources.tags }
          )}
          valueField="tags"
          valueFunction={this.renderTags}
        />
      </ListTree>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Item));
