import React from 'react';
import { useIntl } from 'react-intl';

import Panel from 'src/componentes/panel-collapse';
import Radio from 'src/componentes/radio';

function AutorizacaoDivulgacaoAniversario({ model, changeModel, disabled }) {
  const intl = useIntl();

  const options = [
    { id: 1, descricao: intl.formatMessage({ id: 'label.sim' }) },
    { id: 0, descricao: intl.formatMessage({ id: 'label.nao' }) },
  ];

  const getOption = (resposta) => {
    if (resposta == null) return null;

    return options.find((x) => x.id == resposta);
  };

  return (
    <Panel errors={model.getModel('autorizarDivulgacaoAniversario').errors} open={false} header={intl.formatMessage({ id: 'autorizacaoDivulgacaoAniversario' })}>
      <Radio
        name="autorizarDivulgacaoAniversario"
        disabled={disabled}
        className="mb-4"
        model={{
          label: '',
          value: getOption(model.getValue('autorizarDivulgacaoAniversario')),
          requestChange: (value) => model.getModel('autorizarDivulgacaoAniversario').requestChange(value.id),
          errors: model.getModel('autorizarDivulgacaoAniversario').errors,
        }}
        options={options}
        labelField="descricao"
        idField="id"
      />
    </Panel>
  );
}

export default AutorizacaoDivulgacaoAniversario;
