import React from 'react';

import VerticalArrow from './vertical-arrow';
import MultilineText from './multiline-text';

export const configs = {
  getFontSize: (level) => [10, 8, 8][level] || 6,
  getArrowPadding: (level) => [30, 10, 10][level] || 8,
  getLegsGap: (level) => [30, 10, 10][level] || 8,
  getLabelGap: (level) => [5, 2, 2][level] || 1,
  getMarkerSize: (level) => [6, 5, 4][level] || 3,
  getStrokeWidth: (level) => [3, 2, 1.5][level] || 1.2,
  fontFamily: 'Verdana',
};

export default class RootArrow extends React.Component {
  static measureLabel(props) {
    return MultilineText.measure({
      width: 120,
      text: props.item.descricao || '',
      fontSize: configs.getFontSize(0) * 2,
      fontFamily: configs.fontFamily,
    });
  }

  static measureLegs(props) {
    let item = props.item;
    let width = 0;
    let height = 0;

    if (item.itens) {
      let half = Math.ceil(item.itens.length / 2);
      for (let i = 0; i < half; i++) {
        let subItemTop = item.itens[i];
        let groupSize = VerticalArrow.measure({ item: subItemTop, level: 1 });

        let subItemBottom = item.itens[i + half];
        if (subItemBottom) {
          let subItemBottomSize = VerticalArrow.measure({
            item: subItemBottom,
            level: 1,
          });
          groupSize.width = Math.max(groupSize.width, subItemBottomSize.width);
          groupSize.height = Math.max(
            groupSize.height,
            subItemBottomSize.height
          );
        }

        width += groupSize.width + (i < half - 1 ? configs.getLegsGap(0) : 0);
        height = Math.max(height, groupSize.height * 2);
      }
    }
    return {
      width,
      height,
    };
  }

  static measure(props) {
    let labelSize = RootArrow.measureLabel(props);
    let legsSize = RootArrow.measureLegs(props);
    return {
      width:
        labelSize.width +
        configs.getLabelGap(0) +
        configs.getArrowPadding(0) * 2 +
        legsSize.width,
      height: Math.max(labelSize.height, legsSize.height),
    };
  }

  render() {
    let { item, x, y, width, height, handleSaveCausaFundamental, widgetId } =
      this.props;

    let labelSize = RootArrow.measureLabel(this.props);

    let children = [];

    let centerY = height / 2;

    if (item.itens) {
      let half = Math.ceil(item.itens.length / 2);
      let legX = configs.getArrowPadding(0);
      for (let i = 0; i < half; i++) {
        let subItemTop = item.itens[i];
        let groupSize = VerticalArrow.measure({ item: subItemTop, level: 1 });

        let subItemBottom = item.itens[i + half];
        if (subItemBottom) {
          let subItemBottomSize = VerticalArrow.measure({
            item: subItemBottom,
            level: 1,
          });
          groupSize.width = Math.max(groupSize.width, subItemBottomSize.width);
          groupSize.height = Math.max(
            groupSize.height,
            subItemBottomSize.height
          );
          children.push(
            <VerticalArrow
              key={`${i}-bottom`}
              item={subItemBottom}
              x={legX}
              y={centerY}
              width={groupSize.width}
              height={centerY}
              level={1}
              reverse
              handleSaveCausaFundamental={handleSaveCausaFundamental}
              guid={item.guid}
              ehCausaFundamental={item.causaFundamental}
              widgetId={widgetId}
            />
          );
        }

        children.push(
          <VerticalArrow
            key={`${i}-top`}
            item={subItemTop}
            x={legX}
            y={centerY}
            width={groupSize.width}
            height={centerY}
            level={1}
            handleSaveCausaFundamental={handleSaveCausaFundamental}
            guid={item.guid}
            ehCausaFundamental={item.causaFundamental}
            widgetId={widgetId}
          />
        );
        legX += groupSize.width + (i < half - 1 ? configs.getLegsGap(0) : 0);
      }
    }

    return (
      <g transform={`translate(${x}, ${y})`}>
        {children}

        <line
          x1={0}
          y1={centerY}
          x2={
            width -
            labelSize.width -
            configs.getLabelGap(0) -
            configs.getMarkerSize(0)
          }
          y2={centerY}
          strokeWidth={configs.getStrokeWidth(0)}
          stroke="black"
          markerEnd="url(#head)"
        />

        <MultilineText
          x={width - labelSize.width}
          y={centerY}
          width={labelSize.width}
          height={labelSize.height}
          text={item.descricao || ''}
          guid={item.guid}
          verticalAlign="middle"
          fontFamily={configs.fontFamily}
          fontSize={configs.getFontSize(0)}
          handleSaveCausaFundamental={handleSaveCausaFundamental}
          isIshikawaHead
          ehCausaFundamental={item.causaFundamental}
          widgetId={widgetId}
        />
      </g>
    );
  }
}
