import { Schema } from '../../utils/form';
import SchemaItem from '../item/schema';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default Schema.object({
  ...SchemaItem,
  idIntegracao: Schema.string().label(<FormattedMessage id="codigo" />),
  area: Schema.string()
    .label(<FormattedMessage id="area" />)
    .required(),
  assunto: Schema.string().label(<FormattedMessage id="assunto" />),
  dataInicioItem: Schema.string().label(<FormattedMessage id="dataInicio" />),
  dataTerminoItem: Schema.string().label(<FormattedMessage id="dataTermino" />),
  localReuniao: Schema.string().label(<FormattedMessage id="local" />),
  responsaveis: Schema.string().label(''),
  enviarEmail: Schema.string().label(<FormattedMessage id="enviarEmail" />),
  alerta: Schema.string().label(<FormattedMessage id="alerta" />),
  ata: Schema.string().label(<FormattedMessage id="label.ata" />),
  pauta: Schema.string().label(<FormattedMessage id="pauta" />),
  statusReuniao: Schema.string().label(<FormattedMessage id="status" />),
  utilizaRecorrencia: Schema.string().label(<FormattedMessage id="recorrente" />),
  participantes: Schema.string().label(<FormattedMessage id="label.participantes" />),
  tags: Schema.string().label(<FormattedMessage id="tags" />),
});
