import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class Operador extends React.Component {
  render() {
    return <Lookup labelKey="simbolo" valueKey="id" url="/OperadorLookup" {...this.props} />;
  }
}

export default Operador;
