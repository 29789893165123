import React from 'react';
import Button from '../../../componentes/button';
import Dialog from '../../../componentes/dialog';
import { autobind } from 'core-decorators';
import Input from '../../../componentes/input';
import AreaLookup from '../../../lookups/item-organizacao';
import { Row, Col } from 'reactstrap';
import ContentManager from '../../../componentes/content-manager';
import Alert from '../../../componentes/message-box/alert';
import { Form, Schema } from '../../../utils/form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../utils/string';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  area: Schema.string().label(),
});

@autobind
class CopiarLayout extends React.Component {
  static defaultProps = {
    showArea: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      descricao: null,
      area: {},
    };

    this.form = new Form({
      component: this,
      schema: this.props.schema ? this.props.schema : schema,
    });
  }

  handleOkClick() {
    if (!this.form.isValid()) {
      this.form.showAllErrors();
      ContentManager.addContent(<Alert message={<FormattedMessage id="label.osCamposDestacadosEmVermelhoSaoObrigatorios" />} />);
      return;
    }

    this.props.salvarLayout && this.props.salvarLayout(this.form.getValue());
    this.refs.dialog.close();
  }

  handleCancelarClick() {
    this.refs.dialog.close();
  }

  handleChange(value) {
    this.setState({ descricao: value });
  }

  handleChangeLookup(value) {
    this.setState({ area: value });
  }

  render() {
    const actions = [
      <Button key={1} onClick={this.handleCancelarClick} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>,
      <Button key={2} type="primary" onClick={this.handleOkClick} htmlType="submit" className="ml-2">
        {<FormattedMessage id="label.salvar" />}
      </Button>,
    ];

    let { resources, showArea } = this.props;

    return (
      <Dialog
        {...this.props}
        ref="dialog"
        title={<FormattedMessage id="label.copiarLayout" />}
        actions={actions}
        onRequestClose={this.handleCancelarClick}
        width={700}
        minHeight={300}
        style={{ fontSize: 15 }}
        padContent
      >
        <Row>
          <Col md={6}>
            <Input model={this.form.getModel('descricao')} required />
          </Col>

          {showArea && (
            <Col md={6}>
              <AreaLookup
                model={{
                  label: capitalizeFirstLetter(resources.area),
                  value: this.form.getModel('area').value,
                  requestChange: this.form.getModel('area').requestChange,
                }}
              />
            </Col>
          )}
        </Row>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(CopiarLayout));
