import React from 'react';
import Edit from '../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import ColaboradorSelect from '../colaborador/select-list';
import Input from '../../componentes/input';
import { Row, Col } from 'reactstrap';
import Panel from '../../componentes/panel';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import DataInicioTermino from 'src/paginas/formula/componentes/inicio-termino';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  colaboradores: Schema.array().label(''),
  dataInicio: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, resources } = this.props;
    return (
      <div>
        <Row>
          <Col md={4}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
          <Col md={8}>
            <Input model={model.getModel('descricao')} required />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DataInicioTermino
              required
              mesAno
              dataInicio={model.getModel('dataInicio')}
              dataTermino={model.getModel('dataTermino')}
            />
          </Col>
        </Row>
        <Panel header={capitalizeFirstLetter(resources.colaboradores)}>
          <ColaboradorSelect
            model={model.getModel('colaboradores')}
            bloquearSelecaoSemEmail={true}
          />
        </Panel>
      </div>
    );
  }
}

@autobind
class GrupoEmailEdit extends React.Component {
  render() {
    return (
      <Edit
        {...this.props}
        url="/GrupoEmail"
        title="grupos"
        formComponent={FormEdit}
        schema={schema}
        showExport={false}
        width={700}
        height={500}
      />
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(GrupoEmailEdit);
