import React, { Fragment } from 'react';
import GraficoScorePeriodo from '../../../RiscoEditView/grafico/score_por_periodo';
import { useState, useEffect } from 'react';
import http from 'src/services/httpService';
import { getConfiguration } from 'src/services/commonRequests';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';
import Avatar from 'src/componentes/item-multi-lookup';
import Input from 'src/componentes/input';
import Button from 'src/componentes/button';
import MdiIcon from 'src/componentes/mdi-icon';
import Filtro from 'src/componentes/filtro';
import ContentManager from 'src/componentes/content-manager';
import Search from '../../../RiscoEditView/grafico/search';
import SearchSchema from '../../../RiscoEditView/grafico/searchSchema';
import { FILTROS } from 'src/utils/constants';
import { getFilter } from 'src/componentes/NewList';
import FiltroChip from 'src/componentes/filtro-chip';
import LoadingContainer from 'src/componentes/loading-container';

const Graficos = ({ riscoId, exibirGraficoScorePeriodo = true, exibirGraficoScorePeriodoGauge = true, resources, updater, selectedFilter, ...props }) => {
  const getFilterDefaultChart = () => {
    return {
      valor: {
        tipoFiltroData: null,
        dataInicio: null,
        dataTermino: null,
      },
    };
  };
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [avaliacaoGauge, setAvaliacaoGauge] = useState(null);
  const [searchModelState, setSearchModel] = useState(getFilterDefaultChart());
  const [grausDaMatrizRisco, setGrausDaMatriz] = useState([]);
  const [maiorScoreDaMatriz, setMaiorScoreDaMatriz] = useState(0);
  const [configuracao, setConfiguracao] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getConfiguration('ItemConfiguracaoRisco')(setConfiguracao)();
  }, []);

  useEffect(() => {
    refresh();
  }, [configuracao, updater]);

  const refresh = async (searchModel) => {
    if (!searchModel) {
      searchModel = await getFilter(FILTROS.RISCO_GRAFICOSCORE, true);
      setSearchModel(searchModel);
    }

    if (configuracao && configuracao.habilitarCalculoDeScores) {
      /* Executa requisicao apenas se configuração habilitarCalculoDeScores */
      setIsLoading(true);
      try {
        await http
          .post('/RiscoAvaliacao/ObterGraficoDeScore', { riscoId: riscoId, model: searchModel && searchModel.valor ? searchModel.valor : searchModel })
          .then((response) => {
            setAvaliacoes(response.data.avaliacoes);
            setAvaliacaoGauge(response.data.avaliacaoGauge);
            setGrausDaMatriz(response.data.grausDaMatriz);
            setMaiorScoreDaMatriz(response.data.maiorScoreDaMatriz ? response.data.maiorScoreDaMatriz : 0);
            setIsLoading(false);
          });
      } catch (err) {
        errorHandler(err);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleSearch = (searchModel) => {
    setSearchModel(searchModel);

    if (searchModel && searchModel.valor) refresh(searchModel.valor);
  };

  const handleFiltroModal = () => {
    ContentManager.addContent(
      <Filtro
        {...props}
        /*modelValue={searchModelState}*/
        filtroId={FILTROS.RISCO_GRAFICOSCORE}
        usarFiltroSalvo={true}
        searchComponent={<Search />}
        searchSchema={SearchSchema}
        onSearch={handleSearch}
        showModal={true}
        selectedFilter={searchModelState ? searchModelState : getFilterDefaultChart()}
        ignorarCamposCustomizados={true}
      />
    );
  };

  const handleFiltroChip = () => {
    let searchModel = searchModelState && searchModelState.valor != null ? searchModelState.valor : searchModelState;
    return (
      <FiltroChip filtroId={FILTROS.RISCO_GRAFICOSCORE} onSearch={handleSearch} searchSchema={SearchSchema} selectedFilter={searchModel} onRemoveSaveFilter={true} />
    );
  };

  return (
    <div>
      {configuracao && configuracao.habilitarCalculoDeScores && (
        <LoadingContainer isLoading={isLoading}>
          <Row>
            <Col md={10}>
              <div className={'mt-3 mr-1 pull-right'}>{handleFiltroChip()}</div>
            </Col>
            <Col md={2}>
              <div className={'mt-3 mr-3 pull-right'}>
                <Button className="ml-2 d-inline" onClick={handleFiltroModal}>
                  <MdiIcon icon="filter" />
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {exibirGraficoScorePeriodo && (
                <GraficoScorePeriodo handleSearch={handleSearch} avaliacoes={avaliacoes} grausDaMatriz={grausDaMatrizRisco} maiorScoreDaMatriz={maiorScoreDaMatriz} />
              )}
            </Col>
          </Row>
        </LoadingContainer>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default connect(mapStateToProps)(Graficos);
