import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Content from 'src/componentes/pages/content';

import View from './View';

@autobind
class Risco extends React.Component {
  render() {
    const { resources, showModal, intl, ...rest } = this.props;
    return this.props.hideHead ? (
      <View showCustomFields={true} {...rest} showModal={showModal} />
    ) : (
      <Content
        titulo={intl.formatMessage(
          {
            id: 'riscosMapeados',
          },
          { riscos: resources.riscos }
        )}
        iconSettings={true}
        urlSettings="/configuracoes/risco"
        endPointPermissionSettings="/itemConfiguracaoRisco/PossuiPermissaoConfiguracao"
      >
        <View showCustomFields={true} {...rest} showModal={showModal} />
      </Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Risco));
