import { useIntl } from 'react-intl';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import ProgressBar from 'src/componentes/NewProgressBar';
import LoadingContainer from 'src/componentes/loading-container';

export default function ToleranciaRiscoBarra({
  model = {},
  itens = [],
}) {
  const intl = useIntl();
  const renderEmptyBar = () => (
    <div
      style={{ height: 25 }}
      className="d-flex justify-content-center align-items-center text-xs w-100"
    ></div>
  );

  const renderBar = (id) => {
    const quadranteId = `TOLERANCIA-RISCO-BARRA-${id}`;
    const valorFinalId = `TOLERANCIA-RISCO-VALORFINAL-${id}`;
    const primeiroItem =
      model?.itens && model?.itens.length > 0 ? model?.itens[0] : { de: 0 };
    const ultimoItem =
      model?.itens && model?.itens.length > 0
        ? model?.itens[model?.itens.length - 1]
        : { ate: 100 };
    const isEmpty =
      !model?.itens ||
      model?.itens.length === 0 ||
      model?.itens.every((x) => !x.percentual);

    return (
      <div className="d-flex align-items-center mb-1 pr-2">
        <div
          id={quadranteId}
          className="pr-1 pt-2"
          style={{
            width: 30,
            textAlign: 'right',
            fontSize: '10px',
            color: primeiroItem?.cor ? primeiroItem.cor : '',
          }}
        >
          {primeiroItem.de}
        </div>
        {!isEmpty ? (
          <ProgressBar
            showBorder={false}
            labelSizeClass="text-xs"
            itens={model?.itens}
            showPercent={true}
            showLabel={false}
            hidePercentValue={10}
            containerHeight={20}
            showLegenda={false}
            utilizarMarcador={true}
            alertaMarcador={false}
            ajustarPercentualMarcador={0}
            showCursor={false}
          />
        ) : (
          renderEmptyBar()
        )}
        <div
          id={valorFinalId}
          className="mr-1 pt-2"
          style={{
            width: 30,
            fontSize: '10px',
            color: ultimoItem?.cor ? ultimoItem.cor : '',
          }}
        >
          {ultimoItem.ate}
        </div>
      </div>
    );
  };

  const renderLegend = () => {
    return (
      <div>
        <Row className="pb-2 pt-1">
          <Col
            md={12}
            className="d-flex justify-content-center"
            style={{ fontSize: '11px', fontWeight: 'bold' }}
          >
            {model?.faixaAvaliacao?.descricao}
          </Col>
        </Row>
        {model?.itens &&
          model.itens.map((item) => (
            <Row className="pl-5 d-flex justify-content-center">
              <Col md={1} className="p-0 pt-1">
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: item.cor,
                  }}
                ></div>
              </Col>
              <Col
                md={10}
                className="pl-1 pt-1  text-bold"
                style={{ fontSize: '10px', fontWeight: 'bold' }}
              >
                {item.label}
              </Col>
            </Row>
          ))}
      </div>
    );
  };

  return (
    model?.toleranciaRisco ? (
      <div>
        <Row className="pb-3 pt-1">
          <Col
            md={12}
            className="d-flex justify-content-center"
            style={{ fontSize: '12px', fontWeight: 'bold' }}
          >
            {intl.formatMessage({ id: 'labelTolerancia' })}:{' '}
            {model?.toleranciaRisco?.descricao}
          </Col>
        </Row>
        <Row>
          <Col md={12}>{renderBar(0)}</Col>
        </Row>
        <div className="pb-2">{renderLegend(itens)}</div>
      </div>
    ) : <></>
  );
}
