import history from './../history';
import errorHandler from './error-handler';
import { parseQuery, encodeQueryData } from './string';

/**
 * Altera a URI ou a QueryString, facilitando a navegação nativa do browser (voltar e avançar)
 * @param {object} newSearch obj JS dos parametros da query string
 * @param {string} newPath caso nulo, mantem URI corrente. OBS: com '/' no início
 * @param {bool} concat default=true; caso falso, não concatena newSearch com a queryString anterior
 */
export function changeUriOrQueryString(newSearch, newPath, concat = true) {
  try {
    if (newPath || !history || !history.location || !history.location.search || history.location.search.substr(1) != encodeQueryData(newSearch)) {
      if (concat) Object.assign(newSearch, parseQuery(history.location.search));

      if (newPath !== history.location.pathname)
        history.push({
          pathname: newPath,
          search: encodeQueryData(newSearch),
        });
      else
        history.replace({
          pathname: newPath || history.location.pathname,
          search: encodeQueryData(newSearch),
        });
    }
  } catch (error) {
    errorHandler(error);
  }
}
