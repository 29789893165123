import React from 'react';
import MdiIcon from 'src/componentes/mdi-icon';
import getEditUrl from 'src/utils/item/getEditUrl';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

const ContextMenuIcon = ({
  item,
  origemClick,
  obterFiltroUrl,
  obterFiltroUrlMatrizResultados,
  getInternalTabGestaoMudancas,
}) => {
  const handleAbrirEmNovaAba = (item, origemClick) => {
    let url = '';
    const abrirEmNovaAba = (url) => {
      window.open(url, '_blank');
    };

    if (!item || !origemClick) return;

    if (origemClick == 'editar') {
      const tipo = item.tipo && item.tipo?.id;
      url = getEditUrl(tipo) + `?id=${item.id}`;
    } else if (origemClick == 'resumo') {
      url = `/gerenciar/projeto/resumo?id=${item.id}`;
    } else if (origemClick == 'valores') {
      const urlFiltro = obterFiltroUrl(item);
      url = '/gerenciar/valor' + urlFiltro;
    } else if (origemClick == 'relacionamentos') {
      const urlFiltro = obterFiltroUrl(item);
      url = '/gerenciar/arvore' + urlFiltro;
    } else if (origemClick == 'matrizResultados') {
      const urlFiltro = obterFiltroUrlMatrizResultados(item);
      url = '/acompanhar/matriz-resultado' + urlFiltro;
    } else if (origemClick == 'planoAcao') {
      const urlFiltro = obterFiltroUrl(item);
      url = '/gerenciar/gantt' + urlFiltro;
    } else if (origemClick == 'gestaoMudancas') {
      const internalTab = getInternalTabGestaoMudancas(item);
      url =
        '/minhas-tarefas/gestao-mudancas' +
        `?tab=1&tabInterna=${internalTab}&isNew=true&entityId=${item.id}`;
    } else if (origemClick == 'matrizResponsabilidades') {
      url = '/acompanhar/matriz-responsabilidades' + `?id=${item.id}`;
    } else if (origemClick == 'statusReport') {
      url = '/gerenciar/projeto/edit' + `?id=${item.id}&activeTab=7`;
    } else if (origemClick == 'eap') {
      const urlFiltro = obterFiltroUrl(item);
      url = '/acompanhar/eap' + urlFiltro;
    }

    abrirEmNovaAba(url);
  };

  const iconId = item ? `icon-${origemClick}-${item.id}` : '';

  return (
    <div>
      {item && (
        <>
          <MdiIcon
            id={iconId}
            className="ml-2"
            icon="open-in-new"
            onClick={() => handleAbrirEmNovaAba(item, origemClick)}
            data-tip
            data-for={iconId}
          />
          <ReactTooltip id={iconId} place="top" effect="solid">
            <FormattedMessage id="abrirEmNovaAba" />
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

export default ContextMenuIcon;
