/* eslint-disable react/no-find-dom-node */
import ReactDOM from 'react-dom';
import KEY_CODES from './key-codes';

export let KEYS = KEY_CODES;

export function keydown(...keys) {
  return keyDownNew(false, ...keys)
}

export function keyDownNew(useAltKey, ...keys) {
  return function (target, key, descriptor) {
    const { componentDidMount, componentWillUnmount, componentDidUpdate } =
      target;

    let component;
    let element;

    function keyDownListener(e) {
      let keyCode = e.which || e.keyCode;

      if (
        (!useAltKey || (useAltKey && e.altKey)) &&
        keys.indexOf(keyCode) !== -1 &&
        !(e.target.tagName === 'TEXTAREA' && keyCode === KEYS.ENTER)
      ) {
        descriptor.value.call(component, e);
        e.preventDefault();
      }
    }

    target.componentDidMount = function () {
      component = this;
      element = ReactDOM.findDOMNode(this);
      if (element) {
        element.addEventListener(`keydown`, keyDownListener);
      }
      if (componentDidMount) return componentDidMount.call(this);
    };

    target.componentDidUpdate = function () {
      component = this;
      let newElement = ReactDOM.findDOMNode(this);
      if (element !== newElement) {
        if (element) {
          element.removeEventListener(`keydown`, keyDownListener);
        }
        if (newElement) {
          newElement.addEventListener(`keydown`, keyDownListener);
        }
        element = newElement;
      }
      if (componentDidUpdate) return componentDidUpdate.call(this);
    };

    target.componentWillUnmount = function () {
      element && element.removeEventListener(`keydown`, keyDownListener);
      if (componentWillUnmount) return componentWillUnmount.call(this);
    };

    return descriptor;
  };
}
