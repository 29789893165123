import React, { Suspense, lazy } from 'react';
import { autobind } from 'core-decorators';
import moment from 'moment';
import qs from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col, UncontrolledTooltip, Label } from 'reactstrap';

import http from 'src/services/httpService';
import Edit from 'src/componentes/edit/edit-dialog';
import Input from 'src/componentes/input';
import InputNumber from 'src/componentes/input-number';
import StatusAcaoLookup from 'src/lookups/status-acao';
import DatePicker from 'src/componentes/date-picker';
import SvgIcon from 'src/componentes/svg-icon';
import MdiIcon from 'src/componentes/mdi-icon';
import errorHandler from 'src/utils/error-handler';
import AcaoLookup from 'src/lookups/acao';
import { capitalizeFirstLetter } from 'src/utils/string';
import Checkbox from 'src/componentes/checkbox';
import Button from 'src/componentes/button';
import ContentManager from 'src/componentes/content-manager';
import Confirm from 'src/componentes/message-box/confirm';
import Alert from 'src/componentes/message-box/alert';
import Radio from 'src/componentes/radio';
import GanhosPorAcaoEdit from './ganhos-por-acao/edit';
import ItemLookup from 'src/lookups/item';
import CentroCustoLookup from 'src/lookups/item-centro-custo';
import PacoteLookup from 'src/lookups/pacote';
import Checklist from './componentes/checklist';
import ResponsavelButtonLookup from 'src/lookups/colaborador-button';
import InputTextLookup from 'src/lookups/input-text';
import Panel from 'src/componentes/panel-collapse';
import formatarData from 'src/utils/formatarData';
import css from 'src/utils/css';
import { setGanhosPorAcao } from 'src/actions/ganhos-por-acao';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import ResponsiveList from 'src/componentes/list/responsive-list';
import Info from 'src/componentes/info';
import LoadingScreen from 'src/componentes/LoadingScreen';
import { MODULOS, STATUS_ACAO } from 'src/utils/constants';
import TagLookup from 'src/lookups/TagLookup';
import { TIPO_ITENS } from 'src/utils/constants';

import PercentualAvancoFisicoList from './componentes/PercentualAvancoFisicoList';
import schema from './schema';

const ItemLink = lazy(() => import('../item/link'));
const Historico = lazy(() => import('../versao-alteracao'));
const TabelaRelacionamento = lazy(() => import('../item/relacionamento'));
const ValidacaoRestricao = lazy(() => import('../item/validacao-restricao'));
const DesenharCampoCustomizado = lazy(() =>
  import('../item/campo-customizado/componentes/desenhar')
);
const IdIntegracaoAcaoVinculada = lazy(() =>
  import('./componentes/id-acao-vinculada')
);

const classes = css`
  .inputText {
    margin-left: 20px;
    min-width: 75px;
    max-width: 125px;
  }
`;

function disabledDate(current) {
  if (!current) {
    // allow empty select
    return false;
  }
  const date = moment();
  date.hour(23);
  date.minute(59);
  date.second(59);
  return current.valueOf() > date.valueOf(); // não pode selecionar data realizada depois da data atual
}

@autobind
class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFieldDate: [
        'dataInicioPrevista',
        'dataTerminoPrevista',
        'dataInicioRealizada',
        'dataTerminoRealizada',
      ],
      configuracoes: [],
      itemId: window.location && qs.parse(window.location.search).itemId,
      centroCustoId:
        window.location && qs.parse(window.location.search).centroCustoId,
      pacoteId: window.location && qs.parse(window.location.search).pacoteId,
      editarDemaisCampos: this.fillEditarDemaisCampos(),
      editarDataPrevista: this.fillEditarDataPrevista(),
      editarDataRealizadaPercentualObservacao:
        this.fillEditarDataRealizadaPercentualObservacao(),
      editarAcaoOrigem: true,
      camposDesabilitados: false,
      disableVincularAcao: false,
      editarEsforco: this.fillEditarDemaisCampos(), //Campo esforço faz parte de "editarDemaisCampos", mas ações vinculadas podem alterar o esforço
      paiAlterado:
        this.props.paiAlterado != null ? this.props.paiAlterado : false,
      permissoesAcaoValor: {
        allowView: false,
        podeEditarMeta: false,
        podeEditarRealizado: false,
      },
      isLoadingHintAcao: false,
      acaoHintId: 0,
      arvoreHintAcao: null,
    };

    http.get(`/AcaoConfiguracao/ObterConfiguracoes`).then((response) => {
      this.setState({
        configuracoes: response.data,
      });
    });

    http
      .get(`/AcaoValor/ObterPermissoesAcaoValorPorId`)
      .then((response) => {
        if (response.data != null) {
          this.setState({
            permissoesAcaoValor: { ...response.data },
          });
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleSelectDate(fieldName) {
    this.setState((prevState) => ({
      selectedFieldDate: this.state.selectedFieldDate.filter(
        (item) => item !== fieldName
      ),
    }));
  }

  fillPontosProblematicos() {
    let ponto = [];
    if (
      this.props.pontosProblematicos &&
      (!this.props.model.getModel('pontosProblematicos').value ||
        this.props.model.getModel('pontosProblematicos').value.length == 0)
    ) {
      ponto.push(this.props.pontosProblematicos[0]);
      this.props.model.getModel('pontosProblematicos').requestChange(ponto);
    } else if (
      !this.props.pontosProblematicos &&
      ponto.push(this.props.model.getModel('causasImpactadas').value)
    ) {
      //ponto.push(this.props.model.getModel('causasImpactadas').value)
      //this.props.model.getModel('pontosProblematicos').requestChange(ponto);
    }
  }

  fillEditarDemaisCampos() {
    let detail = this.props.mode ? this.props.mode === 'detail' : true;
    let editarDemaisCampos = detail
      ? true
      : this.props.model.getModel('editarDemaisCampos').value;
    return editarDemaisCampos;
  }

  fillEditarDataPrevista() {
    let detail = this.props.mode ? this.props.mode === 'detail' : true;
    let editarDataPrevista = detail
      ? true
      : this.props.model.getModel('editarDataPrevista').value;
    return editarDataPrevista;
  }

  fillEditarDataRealizadaPercentualObservacao() {
    let detail = this.props.mode ? this.props.mode === 'detail' : true;
    let editarDataRealizadaPercentualObservacao = detail
      ? true
      : this.props.model.getModel('editarDataRealizadaPercentualObservacao')
          .value;
    return editarDataRealizadaPercentualObservacao;
  }

  handleEdit() {
    this.props.onChangeMode && this.props.onChangeMode('edit');
  }

  handleClose() {
    this.props.onRequestClose && this.props.onRequestClose();
  }

  addDay(date, days) {
    let newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  handleChangeDataInicioPrevista(value) {
    this.props.model.getModel('dataPrevistaAlterada').requestChange(true);
    this.props.model.getModel('dataInicioPrevista').requestChange(value);
    let itemId =
      this.props.model.getModel('item').value &&
      this.props.model.getModel('item').value.id;

    if (
      this.props.model.getModel('dataTerminoPrevista').value == null &&
      value != null
    ) {
      this.props.model
        .getModel('dataTerminoPrevista')
        .requestChange(this.addDay(value, 1));
    }

    if (value != null) {
      http
        .post(`/Acao/ObterDataTermino`, {
          itemId: itemId,
          duracao: this.props.model.getModel('duracao').value,
          dataInicio: value,
        })
        .then((response) => {
          this.props.model
            .getModel('dataTerminoPrevista')
            .requestChange(response.data);
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }

  handleChangeDataTerminoPrevisto(value) {
    this.props.model.getModel('dataPrevistaAlterada').requestChange(true);
    this.props.model.getModel('dataTerminoPrevista').requestChange(value);

    if (
      this.props.model.getModel('dataInicioPrevista').value == null &&
      value != null
    ) {
      this.props.model
        .getModel('dataInicioPrevista')
        .requestChange(this.addDay(value, -1));
    }

    let itemId =
      this.props.model.getModel('item').value &&
      this.props.model.getModel('item').value.id;
    http
      .post(`/Acao/ObterDuracao`, {
        itemId: itemId,
        dataInicio: this.props.model.getModel('dataInicioPrevista').value,
        dataTermino: value,
      })
      .then((response) => {
        this.props.model.getModel('duracao').requestChange(response.data);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleChangeDuracao(value) {
    this.props.model.getModel('duracao').requestChange(value);
    let itemId =
      this.props.model.getModel('item').value &&
      this.props.model.getModel('item').value.id;
    if (value >= 0 && this.props.model.getModel('dataInicioPrevista').value) {
      this.props.model.getModel('dataPrevistaAlterada').requestChange(true);

      http
        .post(`/Acao/ObterDataTermino`, {
          itemId: itemId,
          duracao: value,
          dataInicio: this.props.model.getModel('dataInicioPrevista').value,
        })
        .then((response) => {
          this.props.model
            .getModel('dataTerminoPrevista')
            .requestChange(response.data);
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }

  handleChangedataInicioRealizada(value) {
    this.props.model.getModel('dataInicioRealizada').requestChange(value);
    if (!value) {
      this.props.model.getModel('percentualRealizado').requestChange(null);
    }
  }

  handleChangePercentualRealizado(value) {
    this.props.model.getModel('percentualRealizado').requestChange(value);
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  changeCheckBoxAcaoVinculada() {
    this.props.model
      .getModel('utilizarAcaoVinculada')
      .requestChange(!this.props.model.getModel('utilizarAcaoVinculada').value);
    let podeEditar = !this.props.model.getModel('utilizarAcaoVinculada').value;
    this.setState({
      editarDemaisCampos: podeEditar,
      editarDataPrevista: podeEditar,
      editarDataRealizadaPercentualObservacao: podeEditar,
    });
  }

  confirmarAcaoVinculada(acaoVinculada, itemId) {
    if (acaoVinculada) {
      ContentManager.addContent(
        <Confirm
          message={this.props.intl.formatMessage(
            { id: 'label.desejaVincularEstaAcao' },
            { acao: this.props.resources.acao }
          )}
          handleConfirm={this.handleVincularAcaoConfirm.bind(
            this,
            acaoVinculada,
            itemId
          )}
        />
      );
    } else {
      ContentManager.addContent(
        <Alert
          title={this.props.intl.formatMessage({ id: 'alerta' })}
          message={this.props.intl.formatMessage(
            { id: 'label.selecioneAcaoOrigem' },
            { acao: this.props.resources.acao }
          )}
        />
      );
    }
  }

  handleCloseEdit() {
    this.props.handleClose && this.props.handleClose(true);
    this.props.handleCloseEdit && this.props.handleCloseEdit();
  }

  handleVincularAcaoConfirm(acaoVinculada, itemId) {
    let relatorioId = this.props.parameters
      ? this.props.parameters.relatorioId
      : 0;
    let { configuracoes } = this.state;
    if (configuracoes.model.utilizarCodigoAutomatico === false) {
      ContentManager.addContent(
        <IdIntegracaoAcaoVinculada
          model={this.props.model}
          acaoOrigemId={acaoVinculada.id}
          itemId={itemId}
          auditoriaId={this.props.model?.getModel('auditoriaRisco')?.value?.id}
          relatorioId={relatorioId}
          closeEdit={this.handleCloseEdit}
        />
      );
    } else {
      http
        .post(`/Acao/VincularAcao`, {
          acaoOrigemId: acaoVinculada.id,
          itemId: itemId,
          relatorioId: relatorioId,
          auditoriaId: this.props.model?.getModel('auditoriaRisco')?.value?.id,
          elementoId: this.props?.parameters?.elementoId ?? null,
        })
        .then((_) => {
          this.handleCloseEdit();
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }

  validaSeNaoPodeAdicionarCausa(pontosProblematicos) {
    return (
      pontosProblematicos &&
      pontosProblematicos.length > 0 &&
      pontosProblematicos.filter((ponto) => ponto.ehPontoProblematicoDaAcao)
        .length > 0
    );
  }

  alterarModelVinculada(modelOrigem) {
    let { model } = this.props;
    model.getModel('acaoOrigem').requestChange(modelOrigem.acaoOrigem);
    model
      .getModel('baselineInicial')
      .requestChange(modelOrigem.baselineInicial);
    model
      .getModel('baselineTermino')
      .requestChange(modelOrigem.baselineTermino);
    model
      .getModel('camposCustomizados')
      .requestChange(modelOrigem.camposCustomizados);
    model.getModel('centroCusto').requestChange(modelOrigem.centroCusto);
    model.getModel('codigo').requestChange(modelOrigem.codigo);
    model.getModel('como').requestChange(modelOrigem.como);
    model
      .getModel('custeioPrevisto')
      .requestChange(modelOrigem.custeioPrevisto);
    model
      .getModel('custeioRealizado')
      .requestChange(modelOrigem.custeioRealizado);
    model
      .getModel('dataInicioPrevista')
      .requestChange(modelOrigem.dataInicioPrevista);
    model
      .getModel('dataInicioRealizada')
      .requestChange(modelOrigem.dataInicioRealizada);
    model
      .getModel('dataTerminoPrevista')
      .requestChange(modelOrigem.dataTerminoPrevista);
    model
      .getModel('dataTerminoRealizada')
      .requestChange(modelOrigem.dataTerminoRealizada);
    model.getModel('descricao').requestChange(modelOrigem.descricao);
    model.getModel('duracao').requestChange(modelOrigem.duracao);
    model
      .getModel('editarDataPrevista')
      .requestChange(modelOrigem.editarDataPrevista);
    model
      .getModel('editarDataRealizadaPercentualObservacao')
      .requestChange(modelOrigem.editarDataRealizadaPercentualObservacao);
    model
      .getModel('editarDemaisCampos')
      .requestChange(modelOrigem.editarDemaisCampos);
    model
      .getModel('ehAcaoVinculada')
      .requestChange(modelOrigem.ehAcaoVinculada);
    model.getModel('esforco').requestChange(modelOrigem.esforco);
    model.getModel('farolAcao').requestChange(modelOrigem.farolAcao);
    model
      .getModel('farolInvestimento')
      .requestChange(modelOrigem.farolInvestimento);
    model.getModel('filhos').requestChange(modelOrigem.filhos);
    model.getModel('id').requestChange(modelOrigem.id);
    model
      .getModel('investimentoPrevisto')
      .requestChange(modelOrigem.investimentoPrevisto);
    model
      .getModel('investimentoRealizado')
      .requestChange(modelOrigem.investimentoRealizado);
    model.getModel('item').requestChange(modelOrigem.item);
    model.getModel('itemAcaoOrigem').requestChange(modelOrigem.itemAcaoOrigem);
    model
      .getModel('itensVinculados')
      .requestChange(modelOrigem.itensVinculados);
    model.getModel('observacao').requestChange(modelOrigem.observacao);
    model.getModel('onde').requestChange(modelOrigem.onde);
    model.getModel('pacote').requestChange(modelOrigem.pacote);
    model.getModel('pai').requestChange(modelOrigem.pai);
    model
      .getModel('percentualPrevisto')
      .requestChange(modelOrigem.percentualPrevisto);
    model
      .getModel('percentualRealizado')
      .requestChange(modelOrigem.percentualRealizado);
    model.getModel('permissions').requestChange(modelOrigem.permissions);
    model
      .getModel('podeEditarCusteioPrevisto')
      .requestChange(modelOrigem.podeEditarCusteioPrevisto);
    model
      .getModel('podeEditarCusteioRealizado')
      .requestChange(modelOrigem.podeEditarCusteioRealizado);
    model
      .getModel('podeEditarInvestimentoPrevisto')
      .requestChange(modelOrigem.podeEditarInvestimentoPrevisto);
    model
      .getModel('podeEditarInvestimentoRealizado')
      .requestChange(modelOrigem.podeEditarInvestimentoRealizado);
    model.getModel('porque').requestChange(modelOrigem.porque);
    model.getModel('predecessoras').requestChange(modelOrigem.predecessoras);
    model.getModel('responsavel').requestChange(modelOrigem.responsavel);
    model.getModel('status').requestChange(modelOrigem.status);
    model.getModel('utilizarAcaoVinculada').requestChange(true);
    model.getModel('tipoAcao').requestChange(modelOrigem.tipoAcao);
    model
      .getModel('descricaoMatrizRisco')
      .requestChange(modelOrigem.descricaoMatrizRisco);
    model
      .getModel('avaliacaoPendente')
      .requestChange(modelOrigem.avaliacaoPendente);
    model
      .getModel('descricaoGrauRisco')
      .requestChange(modelOrigem.descricaoGrauRisco);
    model.getModel('auditoriaRisco').requestChange(modelOrigem.auditoriaRisco);
    model
      .getModel('controlSelfAssessment')
      .requestChange(modelOrigem.controlSelfAssessment);

    this.setState({
      editarAcaoOrigem: false,
    });
  }

  clickItemAcaoOrigem(item) {
    let { parameters } = this.props;
    http
      .post(`/Acao/TemPermissaoAcessarItemAcaoOrigem`, {
        itemId: item && item.id,
        acaoId: this.props.item && this.props.item.id,
      })
      .then((response) => {
        let temPermissao = response.data.temPermissao;
        let acaoOrigem = response.data.acaoOrigem;
        if (temPermissao) {
          this.props.handleCloseEdit && this.props.handleCloseEdit();
          ContentManager.addContent(
            <AcaoEdit
              item={acaoOrigem}
              handleClose={
                this.props.onRequestClose && this.props.onRequestClose
              }
              parameters={{
                itemId: acaoOrigem && acaoOrigem.item && acaoOrigem.item.id,
                centroCustoId: parameters && parameters.centroCustoId,
                pacoteId: parameters && parameters.pacoteId,
              }}
              resources={this.props.resources}
            ></AcaoEdit>
          );
        } else {
          ContentManager.addContent(
            <Alert
              title={this.props.intl.formatMessage({ id: 'erro' })}
              message={this.props.intl.formatMessage({
                id: 'label.voceNaoTemPermissaoParaVisualizarEstesRegistros',
              })}
            />
          );
        }
      });
  }

  handleChangeAcaoSuperior(value) {
    let model = this.props.model;
    model.getModel('pai').requestChange(value);

    if (this.props.item == null) {
      if (value != null) {
        this.setState(
          {
            paiAlterado: true,
          },
          () => {
            http
              .post(`/Acao/GerarCodigo`, {
                editModel: model.getModel().value,
                parameters: this.props.parameters,
                parentId: value.id,
              })
              .then((response) => {
                model.getModel('codigo').requestChange(response.data);
              });
          }
        );
      } else {
        this.setState({
          paiAlterado: false,
        });
      }
    }
  }

  afterSave() {
    this.props.handleRefresh && this.props.handleRefresh();
  }

  renderRadioButton() {
    const optionsRadio = [
      {
        id: 1,
        descricao: this.props.intl.formatMessage({ id: 'preventiva' }),
      },
      {
        id: 2,
        descricao: this.props.intl.formatMessage({ id: 'corretiva' }),
      },
    ];
    return optionsRadio;
  }

  async handleMouseOver(acao) {
    this.setState({
      isLoadingHintAcao: true,
      acaoHintId: acao.id,
    });

    try {
      var retorno = await http.post(`/Acao/ObterHintAcoes`, {
        itemNome: acao.nomeItem,
        acaoId: acao.id,
        acaoDescricao: acao.descricao,
      });

      this.setState({ arvoreHintAcao: retorno, isLoadingHintAcao: false });
    } catch (error) {
      errorHandler(error);
      this.setState({
        isLoadingHintAcao: false,
      });
    }
  }

  handleMouseOut() {
    this.setState({ acaoHintId: 0 });
  }

  renderNomeItem(
    ehAuditoria,
    ehControlSelfAssessment,
    isAcaoPreAprovacaoCadastro,
    model,
    resources,
    itemId,
    intl
  ) {
    if (model.getModel('auditoriaRiscoChecklistPergunta').value)
      return (
        <div style={{ marginTop: '10px' }}>
          <span>
            <b>{intl.formatMessage({ id: 'item' })}: </b>
          </span>
          <span>{model.getModel('auditoriaRiscoChecklistPergunta').value}</span>
        </div>
      );

    return (
      <div>
        {ehControlSelfAssessment && !isAcaoPreAprovacaoCadastro && (
          <div style={{ marginTop: '10px' }}>
            <span>
              <b>{intl.formatMessage({ id: 'item' })}: </b>
            </span>
            <span>{model.getModel('controlSelfAssessment.nome').value}</span>
          </div>
        )}
        {ehAuditoria && !isAcaoPreAprovacaoCadastro && (
          <div style={{ marginTop: '10px' }}>
            <span>
              <b>{intl.formatMessage({ id: 'item' })}: </b>
            </span>
            <span>{model.getModel('auditoriaRisco.nome').value}</span>
          </div>
        )}
        {itemId &&
          !isAcaoPreAprovacaoCadastro &&
          !ehAuditoria &&
          !ehControlSelfAssessment && (
            <div style={{ marginTop: '10px' }}>
              <span>
                <b>{intl.formatMessage({ id: 'item' })}: </b>
              </span>
              <ItemLink model={model.getModel('item')}></ItemLink>
            </div>
          )}
        {model.getModel('itemAcaoOrigem') &&
          model.getModel('itemAcaoOrigem').value &&
          !isAcaoPreAprovacaoCadastro &&
          !ehAuditoria &&
          !ehControlSelfAssessment && (
            <div style={{ marginTop: '10px' }}>
              <span>
                <b>{intl.formatMessage({ id: 'itemOrigem' })}: </b>
              </span>
              <ItemLink model={model.getModel('itemAcaoOrigem')}></ItemLink>
            </div>
          )}
        {model.getModel('acaoOrigem') &&
          model.getModel('acaoOrigem').value &&
          !isAcaoPreAprovacaoCadastro && (
            <div style={{ marginTop: '10px' }}>
              <span>
                <b>
                  {intl.formatMessage(
                    { id: 'label.acaoOrigem' },
                    { acao: capitalizeFirstLetter(resources.acao) }
                  )}
                  :{' '}
                </b>
              </span>
              <ItemLink
                afterSave={this.afterSave}
                tipo={901}
                model={model.getModel('acaoOrigem')}
              ></ItemLink>
            </div>
          )}
      </div>
    );
  }
  render() {
    let {
      model,
      resources,
      intl,
      moduloAtual,
      parameters,
      paramsFiltro,
      modules,
    } = this.props;
    let acaoId = model.getModel('id').value;

    const isNew = acaoId == 0;
    const isAcaoPreAprovacaoCadastro =
      model &&
      model.getModel('aprovarCadastro') &&
      model.getModel('aprovarCadastro').value &&
      (isNew ||
        (model.getModel('status') &&
          (model.getModel('status').value.id == STATUS_ACAO.PENDENTE_CADASTRO ||
            model.getModel('status').value.id == STATUS_ACAO.REPROVADA)));

    const isAcaoAprovacaoReprovada =
      model &&
      model.getModel('aprovarCadastro') &&
      model.getModel('aprovarCadastro').value &&
      model.getModel('status') &&
      model.getModel('status').value.id == STATUS_ACAO.REPROVADA;

    const idsStatus = isAcaoAprovacaoReprovada ? [8, 9] : [1, 2, 3, 4, 5, 6, 7];

    let { editarAcaoOrigem, editarEsforco, permissoesAcaoValor } = this.state;

    // habilitar edição de campos se não tiver filho
    let listIdFilhos = model.getModel('filhos');
    let temFilhos =
      listIdFilhos.value === null ||
      (listIdFilhos.value && listIdFilhos.value.length === 0)
        ? false
        : true;

    let {
      editarDemaisCampos,
      editarDataPrevista,
      editarDataRealizadaPercentualObservacao,
    } = model.value;
    let ehAcaoVinculada =
      model &&
      model.getModel('ehAcaoVinculada') &&
      model.getModel('ehAcaoVinculada').value;
    let allowEdit =
      model &&
      model.getModel('permissions') &&
      model.getModel('permissions').value &&
      model.getModel('permissions').value.allowEdit;
    let registroPreenchido = false;
    let configuracoes = this.state.configuracoes;
    let bloquearCodigoAutomatico =
      configuracoes.model &&
      configuracoes.model.permitirAlterarCodigoAutomatico == false &&
      configuracoes.model.utilizarCodigoAutomatico == true
        ? true
        : false;

    const ehAuditoria =
      model.getModel('auditoriaRisco').value != null ? true : false;
    const ehControlSelfAssessment =
      model.getModel('controlSelfAssessment').value != null ? true : false;

    let tipoOrigemModel = null;

    if (!ehAuditoria && !ehControlSelfAssessment)
      tipoOrigemModel = model.getModel('item').value;

    const tipoOrigem = model.getModel('auditoriaRiscoChecklistPergunta').value
      ? 'auditoriaChecklist'
      : ehAuditoria
      ? 'auditoria'
      : ehControlSelfAssessment
      ? 'controlSelfAssessment'
      : tipoOrigemModel &&
        tipoOrigemModel.tipo &&
        tipoOrigemModel.tipo.resources;

    if (model.getModel('tipoAcao').value == 0) {
      if (this.props.parameters && this.props.parameters.relatorioId) {
        model.getModel('tipoAcao').requestChange(2);
      } else {
        model.getModel('tipoAcao').requestChange(1);
      }
    }

    if (allowEdit == null) {
      allowEdit = true;
    }

    if (
      (model.getModel('descricao') && model.getModel('descricao').value) ||
      (model.getModel('responsavel') && model.getModel('responsavel').value)
    ) {
      registroPreenchido = true;
    }

    let itemId =
      this.props.parameters && this.props.parameters.itemId
        ? this.props.parameters.itemId
        : model.getModel('item').value && model.getModel('item').value.id;
    let centroCustoId =
      this.props.parameters && this.props.parameters.centroCustoId
        ? this.props.parameters.centroCustoId
        : model.getModel('centroCusto').value &&
          model.getModel('centroCusto').value.id;
    let pacoteId =
      this.props.parameters && this.props.parameters.pacoteId
        ? this.props.parameters.pacoteId
        : model.getModel('pacote').value && model.getModel('pacote').value.id;

    if (!itemId) {
      itemId =
        window.location && qs.parse(window.location.search).itemId
          ? qs.parse(window.location.search).itemId
          : null;
    }

    if (paramsFiltro) {
      if (paramsFiltro.item && model.getModel('item').value == null)
        model.getModel('item').requestChange(paramsFiltro.item);

      if (
        paramsFiltro.centroCusto &&
        model.getModel('centroCusto').value == null
      )
        model.getModel('centroCusto').requestChange(paramsFiltro.centroCusto);

      if (paramsFiltro.pacote && model.getModel('pacote').value == null)
        model.getModel('pacote').requestChange(paramsFiltro.pacote);
    }

    let responsavelOpcional =
      configuracoes && configuracoes.model
        ? configuracoes.model.responsavelOpcional
        : false;

    this.fillPontosProblematicos();

    const hasAction = acaoId > 0;
    const hasEPModule = modules.find(
      (m) => m.id === MODULOS.ESCRITORIO_PROJETOS && m.ativo
    );
    const hasBELT = modules.find(
      (m) => m.id === MODULOS.GESTAO_RISCO && m.ativo
    );

    const canEditResponsavel = model.getModel('habilitarResponsavel').value;

    const disabledDataRealizada =
      model &&
      model.getModel('tipoCalculoFarolAcao') &&
      model.getModel('tipoCalculoFarolAcao').value &&
      model.getModel('tipoCalculoFarolAcao').value.id == 2 &&
      model.getModel('status') &&
      model.getModel('status').value &&
      model.getModel('status').value.id != STATUS_ACAO.EM_PLANEJAMENTO &&
      model.getModel('status').value.id != STATUS_ACAO.NAO_INICIADA;

    const itemIdModel =
      this.props.model.getModel('item').value &&
      this.props.model.getModel('item').value.id;

    const pacoteIdModel =
      this.props.model.getModel('pacote').value &&
      this.props.model.getModel('pacote').value.id;

    const centroCustoIdModel =
      this.props.model.getModel('centroCusto').value &&
      this.props.model.getModel('centroCusto').value.id;

    const utilizarPlanoContas =
      configuracoes.model && configuracoes.model.utilizarPlanoContas;
    const podeEditarCusteioInvestimento =
      !model.getModel('item') ||
      !model.getModel('item').value ||
      (model.getModel('item') &&
        model.getModel('item').value &&
        (model.getModel('item').value.tipo.id != 4 ||
          (model.getModel('item').value.tipo.id == 4 && !utilizarPlanoContas)));

    return (
      <Suspense fallback={<LoadingScreen />}>
        <div
          style={{
            color: '#000',
            fontSize: 15,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          {capitalizeFirstLetter(this.props.resources.acao)}
          {!editarDemaisCampos &&
            !isAcaoPreAprovacaoCadastro &&
            model.getModel('acaoOrigem') &&
            model.getModel('acaoOrigem').value && (
              <Info
                id="acaoVinculadaAviso"
                placement="right"
                text={intl.formatMessage(
                  { id: 'edicaoAcaoBloqueadaAcaoVinculada' },
                  { acao: resources.acao }
                )}
              />
            )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            minHeight: 60,
          }}
        >
          {this.renderNomeItem(
            ehAuditoria,
            ehControlSelfAssessment,
            isAcaoPreAprovacaoCadastro,
            model,
            resources,
            itemId,
            intl
          )}
          <div style={{ display: 'flex' }}>
            <div className={classes.inputText}>
              <InputTextLookup
                text
                required
                input={Input}
                disabled={
                  bloquearCodigoAutomatico ||
                  !editarDemaisCampos ||
                  this.state.paiAlterado === true
                }
                model={model.getModel('codigo')}
              />
            </div>
            {(itemId || centroCustoId || pacoteId) &&
              !isAcaoPreAprovacaoCadastro && (
                <div className={classes.inputText}>
                  <InputTextLookup
                    labelField="descricao"
                    input={AcaoLookup}
                    showModal
                    model={{
                      label: model.getModel('pai').label,
                      value: model.getModel('pai').value,
                      requestChange: this.handleChangeAcaoSuperior,
                    }}
                    disabled={
                      !editarDemaisCampos || this.props.preventParentChange
                    }
                    data={{
                      itemId: itemIdModel ?? itemId,
                      centroCustoId: centroCustoIdModel ?? centroCustoId,
                      pacoteId: pacoteIdModel ?? pacoteId,
                      acaoId,
                      relatorioId:
                        this.props.parameters &&
                        this.props.parameters.relatorioId,
                      filtrarAcoesVinculadas: true,
                      ignorarAcaoId: true,
                      isGanttSearch: this.props.isGanttSearch,
                    }}
                  />
                </div>
              )}
            {(!isAcaoPreAprovacaoCadastro || isAcaoAprovacaoReprovada) && (
              <div className={classes.inputText}>
                <InputTextLookup
                  style={{ minWidth: 110 }}
                  labelField="descricao"
                  input={StatusAcaoLookup}
                  model={model.getModel('status')}
                  disabled={
                    isAcaoPreAprovacaoCadastro
                      ? false
                      : !editarDemaisCampos || temFilhos
                  }
                  data={{
                    exibirApenas: idsStatus,
                  }}
                  required
                />
              </div>
            )}
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.tipoAcao && (
                <div className={classes.inputText}>
                  <InputTextLookup
                    disabled={!editarDemaisCampos}
                    input={Radio}
                    needId={true}
                    required
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'tipoAcao' },
                          { acao: resources.acao }
                        )
                      ),
                      value:
                        model.getModel('tipoAcao').value == 0
                          ? 1
                          : model.getModel('tipoAcao').value,
                      requestChange: model.getModel('tipoAcao').requestChange,
                    }}
                    options={this.renderRadioButton()}
                    labelField="descricao"
                    idField="id"
                  />
                </div>
              )}
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.tipoOrigem && (
                <div className={classes.inputText}>
                  <Label className="control-label">
                    {intl.formatMessage({ id: 'tipoOrigem' })}
                  </Label>
                  <br></br>
                  {tipoOrigem == null && (pacoteId || centroCustoId) ? (
                    <strong>
                      {capitalizeFirstLetter(resources.orcamento)}
                    </strong>
                  ) : tipoOrigem === 'reuniao' ? (
                    <strong>
                      <FormattedMessage id="reuniao" />
                    </strong>
                  ) : tipoOrigem === 'auditoria' ? (
                    <strong>
                      {capitalizeFirstLetter(resources.auditoria)}
                    </strong>
                  ) : tipoOrigem === 'auditoriaChecklist' ? (
                    <strong>
                      {this.props.intl.formatMessage({
                        id: 'titulo.auditoriaChecklist',
                      })}
                    </strong>
                  ) : tipoOrigem === 'controlSelfAssessment' ? (
                    <strong>
                      <FormattedMessage id="labelControlSelfAssessment" />
                    </strong>
                  ) : (
                    <strong>
                      {capitalizeFirstLetter(resources[tipoOrigem])}
                    </strong>
                  )}
                </div>
              )}
            <div className={classes.inputText} style={{ minWidth: 40 }}>
              <div class={'form-group border-0'}>
                <ArquivoUploadAnexos
                  disabled={!editarDemaisCampos}
                  tipoAnexo={this.props.tiposAnexos.acao}
                  model={this.props.model.getModel('arquivos')}
                />
              </div>
            </div>
          </div>
        </div>
        {acaoId == 0 &&
          parameters &&
          parameters.exibirLookups &&
          moduloAtual &&
          moduloAtual.id !== 2 && (
            <Row>
              <Col md={4}>
                <ItemLookup
                  required
                  clearable={true}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'item' })
                    ),
                    value: model.getModel('item').value,
                    requestChange: model.getModel('item').requestChange,
                    errors: model.getModel('item').errors,
                  }}
                  data={{ tiposIdsIgnorar: [2], tiposStatusIgnorar: [3, 5] }}
                />
              </Col>
              {acaoId == 0 &&
                configuracoes &&
                configuracoes.model &&
                configuracoes.model.utilizarAprovacaoAcoes && (
                  <Col md={3}>
                    <Checkbox
                      style={{ marginTop: '27px' }}
                      model={{
                        label: intl.formatMessage(
                          { id: 'aprovarCadastroAcoes' },
                          { acao: resources.acao, acoes: resources.acoes }
                        ),
                        value: model.getModel('aprovarCadastro').value,
                        requestChange:
                          model.getModel('aprovarCadastro').requestChange,
                      }}
                    />
                  </Col>
                )}
            </Row>
          )}
        {parameters &&
          parameters.exibirLookups &&
          moduloAtual &&
          moduloAtual.id == 2 && (
            <Row>
              <Col md={4}>
                <CentroCustoLookup
                  required={model.getModel('pacote').value == null}
                  clearable={true}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'centroCusto' },
                        { centrocusto: resources.centrocusto }
                      )
                    ),
                    value: model.getModel('centroCusto').value,
                    requestChange: model.getModel('centroCusto').requestChange,
                  }}
                />
              </Col>
              <Col md={4}>
                <PacoteLookup
                  required={model.getModel('centroCusto').value == null}
                  clearable={true}
                  model={{
                    label: intl.formatMessage({ id: 'pacote' }),
                    value: model.getModel('pacote').value,
                    requestChange: model.getModel('pacote').requestChange,
                  }}
                />
              </Col>
              {acaoId == 0 &&
                configuracoes &&
                configuracoes.model &&
                configuracoes.model.utilizarAprovacaoAcoes && (
                  <Col md={3}>
                    <Checkbox
                      style={{ marginTop: '27px' }}
                      model={{
                        label: intl.formatMessage(
                          { id: 'aprovarCadastroAcoes' },
                          { acao: resources.acao, acoes: resources.acoes }
                        ),
                        value: model.getModel('aprovarCadastro').value,
                        requestChange:
                          model.getModel('aprovarCadastro').requestChange,
                      }}
                    />
                  </Col>
                )}
            </Row>
          )}
        <Row>
          <Col md={6} className="acao-step2">
            <Input
              style={{ height: 'calc(100% - 40px)' }}
              type="textarea"
              maxLength={4000}
              model={model.getModel('descricao')}
              required
              rows={isAcaoPreAprovacaoCadastro ? 6 : ''}
              disabled={!editarDemaisCampos}
            />
          </Col>
          <Col md={2}>
            <Row>
              <Col md={12}>
                <ResponsavelButtonLookup
                  formGroupStyle={{ marginBottom: 0 }}
                  showLabel
                  disabled={canEditResponsavel ? false : !editarDemaisCampos}
                  required={!responsavelOpcional}
                  tipoLookup="acao"
                  acaoId={acaoId}
                  fromAcao
                  multi={false}
                  model={{
                    label: capitalizeFirstLetter(resources.responsavel),
                    value: model.getModel('responsavel').value,
                    requestChange: model.getModel('responsavel').requestChange,
                    errors: model.getModel('responsavel').errors,
                  }}
                />
              </Col>
              {configuracoes &&
                configuracoes.model &&
                configuracoes.model.habilitarEquipe && (
                  <Col
                    style={{
                      marginTop:
                        configuracoes &&
                        configuracoes.model &&
                        configuracoes.model.habilitarBaseline &&
                        15,
                    }}
                    md={12}
                  >
                    <ResponsavelButtonLookup
                      formGroupStyle={{ marginBottom: 0 }}
                      showLabel
                      disabled={!editarDemaisCampos}
                      tipoLookup="acao"
                      acaoId={acaoId}
                      fromAcao
                      multi
                      model={model.getModel('equipe')}
                    />
                  </Col>
                )}
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col
                style={{ paddingLeft: 0 }}
                md={isAcaoPreAprovacaoCadastro ? 12 : 7}
                 className="acao-step3"
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DatePicker
                      timeFormat={false}
                      autoFormatDate={true}
                      model={{
                        value: model.getModel('dataInicioPrevista').value,
                        label: intl.formatMessage({ id: 'label.previsto' }),
                        requestChange: this.handleChangeDataInicioPrevista,
                        errors: model.getModel('dataInicioPrevista').errors,
                      }}
                      onFocus={() =>
                        this.handleSelectDate('dataInicioPrevista')
                      }
                      disabled={
                        !editarDataPrevista ||
                        temFilhos ||
                        model.getModel('predecessoras').value
                      }
                      required={
                        model.getModel('datasPrevistasObrigatorias').value
                      }
                    />
                    {configuracoes &&
                      configuracoes.model &&
                      configuracoes.model.habilitarBaseline &&
                      !isAcaoPreAprovacaoCadastro && (
                        <div
                          id="baselineInicial"
                          style={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: -12,
                            fontSize: 11,
                          }}
                        >
                          {model &&
                            model.getModel('baselineInicial') &&
                            model.getModel('baselineInicial').value &&
                            formatarData(
                              this.props.user.idioma,
                              model.getModel('baselineInicial').value
                            )}
                          <UncontrolledTooltip
                            placement="top"
                            target={'baselineInicial'}
                          >
                            {intl.formatMessage({ id: 'baselineInicial' })}
                          </UncontrolledTooltip>
                        </div>
                      )}
                  </div>
                  <span
                    style={{
                      margin: '8px 8px 0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {intl.formatMessage({ id: 'a' })}
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 'calc(0.5rem + 19px)',
                    }}
                  >
                    <DatePicker
                      timeFormat={false}
                      autoFormatDate={true}
                      model={{
                        value: model.getModel('dataTerminoPrevista').value,
                        label: '',
                        errors: model.getModel('dataTerminoPrevista').errors,
                        requestChange: this.handleChangeDataTerminoPrevisto,
                      }}
                      onFocus={() =>
                        this.handleSelectDate('dataTerminoPrevista')
                      }
                      disabled={!editarDataPrevista || temFilhos}
                      required={
                        model.getModel('datasPrevistasObrigatorias').value
                      }
                    />
                    {configuracoes &&
                      configuracoes.model &&
                      configuracoes.model.habilitarBaseline &&
                      !isAcaoPreAprovacaoCadastro && (
                        <div
                          id="baselineTermino"
                          style={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: -12,
                            fontSize: 11,
                          }}
                        >
                          {model &&
                            model.getModel('baselineTermino') &&
                            model.getModel('baselineTermino').value &&
                            formatarData(
                              this.props.user.idioma,
                              model.getModel('baselineTermino').value
                            )}
                          <UncontrolledTooltip
                            placement="top"
                            target={'baselineTermino'}
                          >
                            {intl.formatMessage({ id: 'baselineTermino' })}
                          </UncontrolledTooltip>
                        </div>
                      )}
                  </div>
                </div>
              </Col>
              {!isAcaoPreAprovacaoCadastro && (
                <>
                  {configuracoes &&
                    configuracoes.model &&
                    configuracoes.model.utilizarPercentualPrevisto && (
                      <Col style={{ paddingLeft: 0 }} md={2}>
                        <InputNumber
                          precision={2}
                          disabled={true}
                          model={{
                            label: '%',
                            value: model.getModel('percentualPrevisto').value,
                            requestChange:
                              model.getModel('percentualPrevisto')
                                .requestChange,
                          }}
                        />
                      </Col>
                    )}
                  <Col style={{ paddingLeft: 0 }} md={2}>
                    <InputNumber
                      model={{
                        value: model.getModel('duracao').value,
                        label: model.getModel('duracao').label,
                        requestChange: this.handleChangeDuracao,
                      }}
                      disabled={!editarDataPrevista || temFilhos}
                    ></InputNumber>
                  </Col>
                  <Col style={{ paddingLeft: 0 }} md={1}>
                    {model.getModel('farolAcao').value && (
                      <div id={'farolAcao' + acaoId}>
                        <Label className="control-label">
                          {capitalizeFirstLetter(resources.farol)}
                        </Label>
                        <span
                          title={model.getModel('farolAcao').value.descricao}
                          className="list-title-item list-item-comum"
                        >
                          <SvgIcon
                            style={{ margin: '-10px 0 -10px 5px' }}
                            icon={model.getModel('farolAcao').value.icone}
                            size={model.getModel('farolAcao').value.tamanho}
                            color={model.getModel('farolAcao').value.cor}
                            colorHover={model.getModel('farolAcao').value.cor}
                          />
                        </span>
                      </div>
                    )}
                  </Col>
                </>
              )}
            </Row>
            {!isAcaoPreAprovacaoCadastro && (
              <Row
                style={{
                  marginTop:
                    configuracoes &&
                    configuracoes.model &&
                    configuracoes.model.habilitarBaseline &&
                    10,
                }}
                className="acao-step4"
              >
                <Col style={{ paddingLeft: 0 }} md={7}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <DatePicker
                      timeFormat={false}
                      autoFormatDate={true}
                      disabledDate={disabledDate}
                      model={{
                        label: intl.formatMessage({ id: 'realizado' }),
                        value: model.getModel('dataInicioRealizada').value,
                        requestChange: this.handleChangedataInicioRealizada,
                      }}
                      onFocus={() =>
                        this.handleSelectDate('dataInicioRealizada')
                      }
                      disabled={
                        disabledDataRealizada ||
                        !editarDataRealizadaPercentualObservacao ||
                        temFilhos
                      }
                    />

                    <span style={{ margin: '8px 8px 0px' }}>
                      {intl.formatMessage({ id: 'a' })}
                    </span>

                    <div style={{ marginTop: 'calc(0.5rem + 19px)' }}>
                      <DatePicker
                        timeFormat={false}
                        autoFormatDate={true}
                        model={{
                          label: '',
                          value: model.getModel('dataTerminoRealizada').value,
                          requestChange: model.getModel('dataTerminoRealizada')
                            .requestChange,
                        }}
                        onFocus={() =>
                          this.handleSelectDate('dataTerminoRealizada')
                        }
                        disabled={
                          disabledDataRealizada ||
                          !editarDataRealizadaPercentualObservacao ||
                          temFilhos
                        }
                      />
                    </div>
                  </div>
                </Col>
                {configuracoes &&
                  configuracoes.model &&
                  configuracoes.model.utilizarPercentualRealizado && (
                    <Col style={{ paddingLeft: 0 }} md={2}>
                      <InputNumber
                        precision={2}
                        disabled={
                          !editarDataRealizadaPercentualObservacao || temFilhos
                        }
                        model={{
                          label: '%',
                          value: model.getModel('percentualRealizado').value,
                          requestChange: this.handleChangePercentualRealizado,
                        }}
                      />
                    </Col>
                  )}
                {configuracoes &&
                  configuracoes.model &&
                  configuracoes.model.utilizarEsforco && (
                    <Col style={{ paddingLeft: 0 }} md={2}>
                      <InputNumber
                        model={model.getModel('esforco')}
                        disabled={
                          !editarDemaisCampos || !editarEsforco || temFilhos
                        }
                      />
                    </Col>
                  )}
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          {configuracoes &&
            configuracoes.model &&
            configuracoes.model.habilitarChecklistTarefas && (
              <Col md={6}>
                {!isAcaoPreAprovacaoCadastro && (
                  <Checklist
                    disabled={!editarDemaisCampos}
                    acaoId={acaoId}
                    tarefas={model.getModel('tarefas')}
                  />
                )}
              </Col>
            )}
          {configuracoes &&
            configuracoes.model &&
            (configuracoes.model.utilizarInvestimentoPrevisto ||
              configuracoes.model.utilizarInvestimentoRealizado ||
              configuracoes.model.utilizarCusteioPrevisto ||
              configuracoes.model.utilizarCusteioRealizado ||
              configuracoes.model.vincularAcaoNaMatriz ||
              configuracoes.model.exibirGrauDescricaoAcaoVinculadaRisco) && (
              <Col style={{ paddingLeft: 0 }} md={6}>
                <Row>
                  <Col md={4}></Col>
                  {(configuracoes.model.utilizarCusteioPrevisto ||
                    configuracoes.model.utilizarInvestimentoPrevisto) && (
                    <Col
                      style={{ paddingLeft: 10, marginBottom: '0.5rem' }}
                      md={isAcaoPreAprovacaoCadastro ? 4 : 3}
                    >
                      <span>
                        {capitalizeFirstLetter(
                          intl.formatMessage({ id: 'label.previsto' })
                        )}
                      </span>
                    </Col>
                  )}
                  {(configuracoes.model.utilizarInvestimentoRealizado ||
                    configuracoes.model.utilizarCusteioRealizado) && (
                    <Col
                      style={{
                        marginBottom: '0.5rem',
                        paddingLeft:
                          configuracoes.model &&
                          (configuracoes.model.utilizarInvestimentoPrevisto ||
                            configuracoes.model.utilizarCusteioPrevisto) &&
                          7,
                      }}
                      md={isAcaoPreAprovacaoCadastro ? 4 : 3}
                    >
                      <span>
                        {capitalizeFirstLetter(
                          intl.formatMessage({ id: 'realizado' })
                        )}
                      </span>
                    </Col>
                  )}
                </Row>
                <Row>
                  {(configuracoes.model.utilizarInvestimentoPrevisto ||
                    configuracoes.model.utilizarInvestimentoRealizado) && (
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingTop: 5,
                        paddingRight: 0,
                      }}
                      md={4}
                    >
                      <span style={{ marginRight: 5 }}>
                        {capitalizeFirstLetter(resources.investimento)}
                      </span>
                    </Col>
                  )}
                  {(configuracoes.model.utilizarCusteioPrevisto ||
                    configuracoes.model.utilizarInvestimentoPrevisto) && (
                    <Col
                      style={{ paddingLeft: 10, paddingRight: 7 }}
                      md={isAcaoPreAprovacaoCadastro ? 4 : 3}
                    >
                      {configuracoes &&
                        configuracoes.model &&
                        configuracoes.model.utilizarInvestimentoPrevisto &&
                        model.getModel('podeEditarInvestimentoPrevisto') &&
                        model.getModel('podeEditarInvestimentoPrevisto')
                          .value != null && (
                          <InputNumber
                            precision={2}
                            model={{
                              label: '',
                              value: model.getModel('investimentoPrevisto')
                                .value,
                              requestChange: model.getModel(
                                'investimentoPrevisto'
                              ).requestChange,
                            }}
                            disabled={
                              !editarDemaisCampos ||
                              !model.getModel('podeEditarInvestimentoPrevisto')
                                .value ||
                              !podeEditarCusteioInvestimento
                            }
                          />
                        )}
                    </Col>
                  )}
                  {
                    <Col
                      style={{
                        paddingRight: 7,
                        paddingLeft:
                          configuracoes.model &&
                          configuracoes.model.utilizarInvestimentoPrevisto &&
                          7,
                      }}
                      md={isAcaoPreAprovacaoCadastro ? 4 : 3}
                    >
                      {configuracoes &&
                        configuracoes.model &&
                        configuracoes.model.utilizarInvestimentoRealizado &&
                        model.getModel('podeEditarInvestimentoRealizado') &&
                        model.getModel('podeEditarInvestimentoRealizado')
                          .value != null && (
                          <InputNumber
                            precision={2}
                            model={{
                              label: '',
                              value: model.getModel('investimentoRealizado')
                                .value,
                              requestChange: model.getModel(
                                'investimentoRealizado'
                              ).requestChange,
                            }}
                            disabled={
                              !editarDemaisCampos ||
                              !model.getModel('podeEditarInvestimentoRealizado')
                                .value ||
                              !podeEditarCusteioInvestimento
                            }
                          ></InputNumber>
                        )}
                    </Col>
                  }
                  {(configuracoes.model.utilizarInvestimentoPrevisto ||
                    configuracoes.model.utilizarInvestimentoRealizado) &&
                    model.getModel('farolInvestimento').value && (
                      <Col md={2}>
                        <div style={{ marginTop: 4 }}>
                          <MdiIcon
                            icon={
                              model.getModel('farolInvestimento').value &&
                              model.getModel('farolInvestimento').value.icone
                            }
                            color={
                              model.getModel('farolInvestimento').value &&
                              model.getModel('farolInvestimento').value.cor
                            }
                            colorHover={
                              model.getModel('farolInvestimento').value &&
                              model.getModel('farolInvestimento').value.cor
                            }
                          ></MdiIcon>
                        </div>
                      </Col>
                    )}
                </Row>
                <Row>
                  {(configuracoes.model.utilizarCusteioPrevisto ||
                    configuracoes.model.utilizarCusteioRealizado) && (
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingTop: 5,
                        paddingRight: 0,
                      }}
                      md={4}
                    >
                      <span style={{ marginRight: 5 }}>
                        {capitalizeFirstLetter(resources.custeio)}
                      </span>
                    </Col>
                  )}
                  {(configuracoes.model.utilizarCusteioPrevisto ||
                    configuracoes.model.utilizarInvestimentoPrevisto) && (
                    <Col
                      style={{ paddingLeft: 10, paddingRight: 7 }}
                      md={isAcaoPreAprovacaoCadastro ? 4 : 3}
                    >
                      {configuracoes &&
                        configuracoes.model &&
                        configuracoes.model.utilizarCusteioPrevisto &&
                        model.getModel('podeEditarCusteioPrevisto') &&
                        model.getModel('podeEditarCusteioPrevisto').value !=
                          null && (
                          <InputNumber
                            precision={2}
                            model={{
                              label: '',
                              value: model.getModel('custeioPrevisto').value,
                              requestChange:
                                model.getModel('custeioPrevisto').requestChange,
                            }}
                            disabled={
                              !editarDemaisCampos ||
                              !model.getModel('podeEditarCusteioPrevisto')
                                .value ||
                              !podeEditarCusteioInvestimento
                            }
                          />
                        )}
                    </Col>
                  )}
                  <Col
                    style={{
                      paddingRight: 7,
                      paddingLeft:
                        configuracoes.model &&
                        configuracoes.model.utilizarCusteioPrevisto &&
                        7,
                    }}
                    md={isAcaoPreAprovacaoCadastro ? 4 : 3}
                  >
                    {configuracoes &&
                      configuracoes.model &&
                      configuracoes.model.utilizarCusteioRealizado &&
                      model.getModel('podeEditarCusteioRealizado') &&
                      model.getModel('podeEditarCusteioRealizado').value !=
                        null && (
                        <InputNumber
                          precision={2}
                          model={{
                            label: '',
                            value: model.getModel('custeioRealizado').value,
                            requestChange:
                              model.getModel('custeioRealizado').requestChange,
                          }}
                          disabled={
                            !editarDemaisCampos ||
                            !model.getModel('podeEditarCusteioRealizado')
                              .value ||
                            !podeEditarCusteioInvestimento
                          }
                        />
                      )}
                  </Col>
                </Row>
                <Row>
                  {hasBELT &&
                    configuracoes.model.vincularAcaoNaMatriz &&
                    model.getModel('descricaoMatrizRisco').value && (
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingTop: 5,
                          paddingRight: 0,
                        }}
                        md={4}
                      >
                        <span style={{ marginRight: 5 }}>
                          {capitalizeFirstLetter(
                            intl.formatMessage(
                              { id: 'matrizRisco' },
                              { risco: resources.risco }
                            )
                          )}
                        </span>
                      </Col>
                    )}
                  {hasBELT &&
                    configuracoes.model.vincularAcaoNaMatriz &&
                    model.getModel('descricaoMatrizRisco').value && (
                      <Col
                        style={{
                          paddingLeft: 10,
                          paddingRight: 7,
                          paddingTop: 5,
                        }}
                        md={4}
                      >
                        <span>
                          <b>
                            {capitalizeFirstLetter(
                              model.getModel('descricaoMatrizRisco').value
                            )}
                          </b>
                        </span>
                      </Col>
                    )}
                </Row>
                <Row>
                  {hasBELT &&
                    configuracoes.model.exibirGrauDescricaoAcaoVinculadaRisco &&
                    model.getModel('grauRisco').value &&
                    model.getModel('grauRisco').value != '' &&
                    model.getModel('descricaoGrauRisco').value &&
                    model.getModel('descricaoGrauRisco').value != '' && (
                      <>
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingTop: 10,
                            paddingRight: 0,
                          }}
                          md={4}
                        >
                          <span style={{ marginRight: 5 }}>
                            {capitalizeFirstLetter(
                              intl.formatMessage(
                                { id: 'grauRisco' },
                                { risco: resources.risco }
                              )
                            )}
                          </span>
                        </Col>
                        <Col
                          style={{
                            paddingLeft: 10,
                            paddingRight: 7,
                            paddingTop: 5,
                          }}
                          md={4}
                        >
                          <div
                            onMouseEnter={this.handleMouseOver.bind(this)}
                            onMouseLeave={() => this.handleMouseOut()}
                            id={'colgrauRisco' + model.getModel('id').value}
                          >
                            <div>
                              <MdiIcon
                                icon="circle"
                                color={model.getModel('grauRisco.cor').value}
                                colorHover={
                                  model.getModel('grauRisco.cor').value
                                }
                                size={22}
                              ></MdiIcon>
                              {model.getModel('avaliacaoPendente').value && (
                                <strong
                                  style={{
                                    color:
                                      model.getModel('grauRisco.cor').value,
                                  }}
                                  color={model.getModel('grauRisco.cor').value}
                                >
                                  !
                                </strong>
                              )}
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                  {hasBELT &&
                    configuracoes.model.exibirGrauDescricaoAcaoVinculadaRisco &&
                    (!model.getModel('grauRisco').value ||
                      model.getModel('grauRisco').value == '' ||
                      !model.getModel('descricaoGrauRisco').value ||
                      model.getModel('descricaoGrauRisco').value == '') && (
                      <>
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingTop: 5,
                            paddingRight: 0,
                          }}
                          md={4}
                        >
                          <span style={{ marginRight: 5 }}>
                            {capitalizeFirstLetter(
                              intl.formatMessage(
                                { id: 'grauRisco' },
                                { risco: resources.risco }
                              )
                            )}
                          </span>
                        </Col>
                        <Col
                          style={{
                            paddingLeft: 10,
                            paddingRight: 7,
                            paddingTop: 5,
                          }}
                          md={4}
                        >
                          <span>
                            <b>-</b>
                          </span>
                        </Col>
                      </>
                    )}
                </Row>
                <Row>
                  <>
                    {configuracoes.model
                      .exibirGrauDescricaoAcaoVinculadaRisco && (
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingTop: 5,
                          paddingRight: 0,
                        }}
                        md={4}
                      >
                        <span style={{ marginRight: 5 }}>
                          {capitalizeFirstLetter(
                            `${intl.formatMessage({
                              id: 'descricao',
                            })} ${intl.formatMessage(
                              { id: 'grauRisco' },
                              { risco: resources.risco }
                            )}`
                          )}
                        </span>
                      </Col>
                    )}
                    {configuracoes.model
                      .exibirGrauDescricaoAcaoVinculadaRisco &&
                      model.getModel('descricaoGrauRisco').value &&
                      model.getModel('descricaoGrauRisco').value != '' && (
                        <Col
                          style={{
                            paddingLeft: 10,
                            paddingRight: 7,
                            paddingTop: 5,
                          }}
                          md={4}
                        >
                          <span>
                            <b>
                              {capitalizeFirstLetter(
                                model.getModel('descricaoGrauRisco').value
                              )}
                            </b>
                          </span>
                        </Col>
                      )}
                    {configuracoes.model
                      .exibirGrauDescricaoAcaoVinculadaRisco &&
                      (!model.getModel('descricaoGrauRisco').value ||
                        model.getModel('descricaoGrauRisco').value == '') && (
                        <Col
                          style={{
                            paddingLeft: 10,
                            paddingRight: 7,
                            paddingTop: 5,
                          }}
                          md={4}
                        >
                          <span>
                            <b>-</b>
                          </span>
                        </Col>
                      )}
                  </>
                </Row>
              </Col>
            )}
        </Row>

        {hasEPModule && (
          <Row>
            <Col md={3}>
              <InputNumber
                precision={2}
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'custoAcao' },
                      { acao: resources.acao }
                    )
                  ),
                  value: model.getModel('custo').value,
                  requestChange: model.getModel('custo').requestChange,
                }}
                disabled={!editarDemaisCampos}
              />
            </Col>
          </Row>
        )}
        <div className="acao-step5">
          <Row>
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.utilizarObservacao && (
                <Col md={!isAcaoPreAprovacaoCadastro ? 6 : 12}>
                  <Input
                    rows={3}
                    type="textarea"
                    disabled={!editarDataRealizadaPercentualObservacao}
                    model={{
                      label: capitalizeFirstLetter(resources.observacaoacao),
                      value: model.getModel('observacao').value,
                      requestChange: model.getModel('observacao').requestChange,
                    }}
                  />
                </Col>
              )}
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.utilizarCausasImpactadas &&
              !isAcaoPreAprovacaoCadastro && (
                <Col md={6}>
                  <ResponsiveList
                    disabled={!editarDemaisCampos}
                    acaoId={acaoId}
                    tarefas={model.getModel('pontosProblematicos')}
                    titulo={resources.pontosproblematicos}
                    hideAddButton={this.validaSeNaoPodeAdicionarCausa(
                      model.getModel('pontosProblematicos').value
                    )}
                  />
                </Col>
              )}
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.utilizarComo &&
              !isAcaoPreAprovacaoCadastro && (
                <Col md={6}>
                  <Input
                    rows={3}
                    type="textarea"
                    disabled={!editarDemaisCampos}
                    model={{
                      label: capitalizeFirstLetter(resources.como),
                      value: model.getModel('como').value,
                      requestChange: model.getModel('como').requestChange,
                    }}
                  />
                </Col>
              )}
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.utilizarOnde &&
              !isAcaoPreAprovacaoCadastro && (
                <Col md={6}>
                  <Input
                    rows={3}
                    type="textarea"
                    disabled={!editarDemaisCampos}
                    model={{
                      label: capitalizeFirstLetter(resources.onde),
                      value: model.getModel('onde').value,
                      requestChange: model.getModel('onde').requestChange,
                    }}
                  />
                </Col>
              )}
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.utilizarPorque &&
              !isAcaoPreAprovacaoCadastro && (
                <Col md={6}>
                  <Input
                    rows={3}
                    type="textarea"
                    disabled={!editarDemaisCampos}
                    model={{
                      label: capitalizeFirstLetter(resources.porque),
                      value: model.getModel('porque').value,
                      requestChange: model.getModel('porque').requestChange,
                    }}
                  />
                </Col>
              )}
          </Row>
          <Row>
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.podeVisualizarTag && (
                <Col md={6}>
                  <TagLookup
                    multi
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'tags' },
                          { tags: resources.tags }
                        )
                      ),
                      value: model.getModel('tags').value,
                      requestChange: model.getModel('tags').requestChange,
                    }}
                    disabled={!editarDemaisCampos}
                    data={{ funcionalidadesIdSelecionaveis: [6] }}
                    podeEditar={model.getModel('podeCadastrarTag').value}
                  />
                </Col>
              )}
          </Row>
        </div>

        {model.getModel('camposCustomizados').value &&
          model.getModel('camposCustomizados').value.length > 0 && (
            <DesenharCampoCustomizado
              colValue={6}
              model={model.getModel('camposCustomizados')}
              disabled={!editarDemaisCampos}
            />
          )}

        {acaoId == 0 &&
          configuracoes &&
          configuracoes.model &&
          configuracoes.model.habilitarVinculoMaisDeUmItem &&
          !isAcaoPreAprovacaoCadastro && (
            <Row>
              <Col md={7}>
                {model.getModel('utilizarAcaoVinculada') &&
                  model.getModel('utilizarAcaoVinculada').value && (
                    <AcaoLookup
                      required
                      disabled={!editarAcaoOrigem || ehAcaoVinculada}
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage(
                            { id: 'label.acaoOrigem' },
                            { acao: resources.acao }
                          )
                        ),
                        value: model.getModel('acaoOrigem').value,
                        requestChange:
                          model.getModel('acaoOrigem').requestChange,
                        errors: model.getModel('acaoOrigem').errors,
                      }}
                      data={{
                        filtrarAcoesVinculadas: true,
                        polaridadeId:
                          model.getModel('item') &&
                          model.getModel('item').value &&
                          model.getModel('item').value.polaridade &&
                          model.getModel('item').value.polaridade.id,
                        itemIdIgnorar: itemId,
                        tipoItemId:
                          model.getModel('item') && model.getModel('item').value
                            ? model.getModel('item').value.tipo.id
                            : null,
                      }}
                    />
                  )}
              </Col>
              <Col md={2}>
                {model.getModel('utilizarAcaoVinculada') &&
                  model.getModel('utilizarAcaoVinculada').value && (
                    <Button
                      disabled={ehAcaoVinculada || registroPreenchido}
                      style={{ marginTop: '27px' }}
                      onClick={this.confirmarAcaoVinculada.bind(
                        this,
                        model.getModel('acaoOrigem').value,
                        model.getModel('item').value &&
                          model.getModel('item').value.id
                      )}
                    >
                      {intl.formatMessage(
                        { id: 'label.vincularAcao' },
                        { acao: resources.acao }
                      )}
                    </Button>
                  )}
              </Col>
              {moduloAtual && moduloAtual.id !== 2 && (
                <Col md={3}>
                  <Checkbox
                    style={{ marginTop: '27px' }}
                    disabled={ehAcaoVinculada || registroPreenchido}
                    model={{
                      label: intl.formatMessage(
                        { id: 'label.usarAcaoDeOutroItem' },
                        { acao: resources.acao }
                      ),
                      value: model.getModel('utilizarAcaoVinculada').value,
                      requestChange: this.changeCheckBoxAcaoVinculada.bind(
                        this,
                        editarDemaisCampos
                      ),
                    }}
                  />
                </Col>
              )}
            </Row>
          )}
        {!isAcaoPreAprovacaoCadastro &&
          permissoesAcaoValor.allowView &&
          hasAction &&
          configuracoes &&
          configuracoes.model &&
          configuracoes.model.utilizarGanhosPorAcao &&
          moduloAtual &&
          (moduloAtual.id === MODULOS.GESTAO_ESTRATEGICA ||
            moduloAtual.id === MODULOS.MATRIZ_ORCAMENTARIA ||
            moduloAtual.id === MODULOS.ESCRITORIO_PROJETOS) &&
          !isAcaoPreAprovacaoCadastro && (
            <Row>
              <Col md={12}>
                <Panel
                  open={false}
                  header={intl.formatMessage(
                    { id: 'ganhosPorAcao' },
                    { acao: resources.acao }
                  )}
                >
                  <GanhosPorAcaoEdit acaoId={acaoId} />
                </Panel>
              </Col>
            </Row>
          )}
        {!isAcaoPreAprovacaoCadastro &&
          hasAction &&
          configuracoes &&
          configuracoes.model &&
          configuracoes.model.habilitarRelacionamentos && (
            <Row>
              <Col md={12}>
                <Panel
                  open={false}
                  header={intl.formatMessage({ id: 'label.relacionamentos' })}
                >
                  {ehAcaoVinculada && (
                    <p>
                      {intl.formatMessage(
                        { id: 'label.origemDaAcao' },
                        { acao: resources.acao }
                      )}
                      <a
                        style={{ color: '#005ce6' }}
                        onClick={this.clickItemAcaoOrigem.bind(
                          this,
                          model.getModel('itemAcaoOrigem').value
                        )}
                      >
                        {model &&
                          model.getModel('itemAcaoOrigem') &&
                          model.getModel('itemAcaoOrigem').value &&
                          model.getModel('itemAcaoOrigem').value.nome}
                      </a>
                    </p>
                  )}
                  {model &&
                    model.getModel('ehAcaoVinculada') &&
                    !model.getModel('ehAcaoVinculada').value && (
                      <TabelaRelacionamento
                        model={model.getModel('itensVinculados')}
                        allowNew={
                          model.getModel('permissions') &&
                          model.getModel('permissions').value != null
                            ? model.getModel('permissions').value.allowEdit
                            : false
                        }
                        disabled={
                          model.getModel('permissions') &&
                          model.getModel('permissions').value != null
                            ? !model.getModel('permissions').value.allowEdit
                            : true
                        }
                        closeModal={this.props.handleCloseEdit}
                        parameters={{ itemIdIgnorar: itemId }}
                      ></TabelaRelacionamento>
                    )}
                </Panel>
              </Col>
            </Row>
          )}
        {!isAcaoPreAprovacaoCadastro && hasAction && (
          <>
            {configuracoes?.model?.visualizarPercentualAvancoFisico &&
              hasEPModule && (
                <Row>
                  <Col md={12}>
                    <Panel
                      open={false}
                      header={intl.formatMessage(
                        { id: 'percentualAvancoFisicoAcao' },
                        { acao: resources.acao }
                      )}
                    >
                      <PercentualAvancoFisicoList
                        acaoId={acaoId}
                        configuration={configuracoes?.model}
                      />
                    </Panel>
                  </Col>
                </Row>
              )}
            <Row>
              <Col md={12}>
                <Panel
                  open={false}
                  header={intl.formatMessage({ id: 'label.historico' })}
                >
                  <Historico url={`/Acao/ObterHistorico`} id={acaoId} />
                </Panel>
              </Col>
            </Row>
          </>
        )}
      </Suspense>
    );
  }
}

@autobind
class AcaoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { refreshId: 0 };
    if (this.props.item && this.props.item.id)
      this.ehAcaoVinculada(this.props.item.id);
  }

  async ehAcaoVinculada(acaoId) {
    const { data } = await http.post(`/Acao/EhAcaoVinculada`, { acaoId });
    this.setState({ ehAcaoVinculada: data });
  }

  handleDelete(item, index) {
    ContentManager.addContent(
      <ValidacaoRestricao
        url="/Acao/ValidarRestricoes"
        urlExclusao="/Acao/Remove"
        item={item}
        onClose={this.handleDeleteClickConfirm.bind(this)}
      />
    );
  }

  handleDeleteClickConfirm(excluido) {
    if (excluido) {
      ContentManager.addContent(
        <Alert
          title={this.props.intl.formatMessage({ id: 'sucesso' })}
          message={this.props.intl.formatMessage({
            id: 'label.registroExcluidoComSucesso',
          })}
        />
      );
    }
  }

  handleCallBack() {
    const { ganhosPorAcao } = this.props;
    const acaoId = this.props.item ? this.props.item.id : 0;

    if (
      acaoId != 0 &&
      ganhosPorAcao?.valoresGanhosPorAcao &&
      ganhosPorAcao.valoresGanhosPorAcao.length > 0
    ) {
      this.setState({
        isLoading: true,
      });

      http
        .post(`/AcaoValor/SalvarValores`, {
          valores: ganhosPorAcao.valoresGanhosPorAcao,
          acaoId: acaoId,
        })
        .then((response) => {
          this.setState({
            ...this.state,
            isLoading: false,
            valores: response.data.valores,
          });
        });
    }

    // if (ganhosPorAcao && ganhosPorAcao.valoresGanhosPorAcao){
    //   this.props.setGanhosPorAcao(ganhosPorAcao.valoresGanhosPorAcao, acaoId).then();
    // }
  }

  handleRefresh() {
    this.setState({ refreshId: this.state.refreshId + 1 });
  }

  render() {
    const { endpoint = '/Acao', handleCallBack } = this.props || {};

    const parameters = {
      ...this.props.parameters,
      acaoId: this.props.item && this.props.item.id,
    };

    return (
      <Edit
        respeitarAltura
        id="editAcao"
        url={endpoint}
        title={''}
        formComponent={FormEdit}
        showDelete={true}
        refreshId={this.state.refreshId}
        handleRefresh={this.handleRefresh}
        width="85%"
        height="90%"
        resources={this.props.resources}
        schema={schema}
        onDeleting={this.state.ehAcaoVinculada && this.handleDelete}
        handleCallBack={handleCallBack ? handleCallBack : this.handleCallBack}
        urlExport={`/Acao/ExportarAcao`}
        {...this.props}
        parameters={parameters}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloAtual: state.user.moduloAtual,
    user: state.user,
    ganhosPorAcao: state.ganhosPorAcao,
    tiposAnexos: state.user.tiposAnexos,
    modules: state.user.licenca.modulos,
  };
}

export default injectIntl(
  connect(mapStateToProps, { setGanhosPorAcao })(AcaoEdit)
);
