import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { LocaleManager, LocaleHelper } from '@bryntum/gantt';

import '@bryntum/gantt/gantt.stockholm.css';
import '@bryntum/gantt/locales/gantt.locale.En';
import { Tooltip } from '@bryntum/gantt';

import useAuth from 'src/hooks/useAuth';
import './components/GanttContainer/CustomColumns/TextColumn';
import './components/GanttContainer/CustomColumns/SubTaskColumn';
import './components/GanttContainer/CustomColumns/EditTaskColumn';
import './components/GanttContainer/CustomColumns/UserColumn';
import './components/GanttContainer/CustomColumns/FarolColumn';

import GanttContainer from './components/GanttContainer';
import { handleHttp } from './utils';

// *** LOCALES
import NlLocale from '@bryntum/gantt/locales/gantt.locale.Nl';
import PtLocale from './locales/custom.locale.Pt';
import EsLocale from '@bryntum/gantt/locales/gantt.locale.Es';
import DeLocale from '@bryntum/gantt/locales/gantt.locale.De';
import TrLocale from '@bryntum/gantt/locales/gantt.locale.Tr';
import ArLocale from '@bryntum/gantt/locales/gantt.locale.Ar';
import FrLocale from '@bryntum/gantt/locales/gantt.locale.FrFr';
// ***

LocaleHelper.publishLocale('Pt', { desc: 'Portuguese', locale: PtLocale });
LocaleHelper.publishLocale('Nl', { desc: 'Dutch', locale: NlLocale });
LocaleHelper.publishLocale('Es', { desc: 'Spanish', locale: EsLocale });
LocaleHelper.publishLocale('De', { desc: 'Dutch', locale: DeLocale });
LocaleHelper.publishLocale('Tr', { desc: 'Turkish', locale: TrLocale });
LocaleHelper.publishLocale('Ar', { desc: 'Arabic', locale: ArLocale });
LocaleHelper.publishLocale('FrFr', { desc: 'French', locale: FrLocale });

const View = ({
  itemId: itemIdProps,
  filter,
  handleChangeFieldFilter,
  handleChangePageTitle,
  handleCloseModal,
  ...rest
}) => {
  const history = useHistory();
  const { user } = useAuth();

  const { location } = history || {};

  const [urlProps] = useState({
    itemId: (location && qs.parse(location.search).itemId) || itemIdProps,
    centroCustoId: location && qs.parse(location.search).centroCustoId,
    pacoteId: location && qs.parse(location.search).pacoteId,
    auditoriaRiscoId: location && qs.parse(location.search).auditoriaRiscoId,
  });

  const { itemId, centroCustoId, pacoteId, auditoriaRiscoId } = urlProps;

  const getItemById = async (field, id) => {
    const item = await handleHttp(`/${field}/ObterItemPorId`, { id });
    handleChangeFieldFilter(field, item);

    return item;
  };

  const syncItems = async () => {
    if (itemId && itemId != filter.item?.id) {
      const item = await getItemById('item', itemId);
      handleChangePageTitle && handleChangePageTitle(item?.nome);
    }
    if (centroCustoId && centroCustoId != filter.centroCusto?.id) {
      const centroCusto = await getItemById('centroCusto', centroCustoId);
      handleChangePageTitle && handleChangePageTitle(centroCusto?.nome);
    }
    if (pacoteId && pacoteId != filter.pacote?.id) {
      const pacote = await getItemById('pacote', pacoteId);
      handleChangePageTitle &&
        handleChangePageTitle((prev) =>
          prev ? `${prev} - ${pacote?.nome}` : pacote?.nome
        );
    }
    if (auditoriaRiscoId) {
      const auditoriaRisco = await getItemById(
        'auditoriaRisco',
        auditoriaRiscoId
      );
      handleChangePageTitle && handleChangePageTitle(auditoriaRisco?.nome);
    }
  };

  useEffect(() => {
    syncItems();
  }, [urlProps]);

  // Modificando o zIndex no evento
  Tooltip.prototype.on('beforeShow', ({ source }) => {
    source.element.style.zIndex = '15000';
  });

  useEffect(() => {
    switch (user.idioma) {
      case 'pt-br': {
        LocaleManager.applyLocale('Pt');
        break;
      }
      case 'nl': {
        LocaleManager.applyLocale('Nl');
        break;
      }
      case 'es': {
        LocaleManager.applyLocale('Es');
        break;
      }
      case 'de': {
        LocaleManager.applyLocale('De');
        break;
      }
      case 'tr': {
        LocaleManager.applyLocale('Tr');
        break;
      }
      case 'ar': {
        LocaleManager.applyLocale('Ar');
        break;
      }
      case 'fr': {
        LocaleManager.applyLocale('FrFr');
        break;
      }

      default: {
        LocaleManager.applyLocale('En');
        break;
      }
    }
  }, []);

  return (
    <GanttContainer
      {...rest}
      {...urlProps}
      filter={filter}
      parameters={{
        ...rest.parameters,
        auditoriaRiscoId: rest.parameters?.auditoriaRiscoId
          ? rest.parameters?.auditoriaRiscoId
          : urlProps?.auditoriaRiscoId,
      }}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default View;
