import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../../componentes/dialog';
import { Schema, Form } from '../../../utils/form';
import http from 'src/services/httpService';
import LoadingContainer from '../../../componentes/loading-container';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import MdiIcon from '../../../componentes/mdi-icon';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import DropdownItemList from '../../item/dropdown-item';
import { capitalizeFirstLetter } from '../../../utils/string';
import errorHandler from '../../../utils/error-handler';
import GraficoCurvaS from '../../item/valor/componentes/grafico';
import PanelCollapse from 'src/componentes/panel-collapse';
import Avatar from 'src/componentes/avatar';
import AvatarGroup from 'src/componentes/AvatarGroup';
import { format } from 'date-fns';
import formatDateFns from 'src/utils/formatDateFns';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import CadastroContent from 'src/components/AbasFichaItem/CadastroContent';
import PontosFaixaPorPeriodoOuAcumulado from 'src/components/AbasFichaItem/PontosFaixaPorPeriodoOuAcumulado';

let schema = Schema.object({
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  id: Schema.number().label(<FormattedMessage id="label.id" />),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  responsaveis: Schema.array().label(<FormattedMessage id="responsaveis" />),
  dataInicioItem: Schema.string().label(
    <FormattedMessage id="label.dataDeInicio" />
  ),
  dataTerminoItem: Schema.string().label(
    <FormattedMessage id="label.dataDeTermino" />
  ),
  percentualPrevisto: Schema.string().label(),
  percentualExecutado: Schema.string().label(),
  investimentoPrevisto: Schema.string().label(),
  investimentoRealizado: Schema.string().label(),
  dataInicioAcao: Schema.string().label(),
  dataFimAcao: Schema.string().label(),
  dataInicioRealizadoAcao: Schema.string().label(),
  dataFimRealizadoAcao: Schema.string().label(),
  escopo: Schema.string().label(<FormattedMessage id="escopo" />),
  utilizarPontosFaixaResultados: Schema.string().label(''),
  pontoFaixaResultado: Schema.string().label(''),
  pontoFaixaResultadoPeriodo: Schema.string().label(''),
  pontoFaixaResultadoYTD: Schema.string().label(''),
  faixas: Schema.string().label(''),
  exibirPrazo: Schema.string().label(''),
  exibirEscopo: Schema.string().label(''),
  exibirCusto: Schema.string().label(''),
  exibirPontosFaixaResultado: Schema.string().label(''),
  exibirCamposCustomizados: Schema.string().label(''),
});

@autobind
class Ficha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isPanelOpen: false,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    http
      .post(`Item/ObterPermissao`, {
        id: this.props.id,
      })
      .then((response) => {
        this.setState(
          {
            exibir: true,
          },
          () => {
            this.fichaProjeto();
          }
        );
      })
      .catch((error) => {
        this.setState({
          exibir: false,
        });
        errorHandler(error);
        this.handleClose();
      });
  }

  fichaProjeto() {
    this.setState({
      isLoading: true,
    });

    http
      .post(`/Projeto/Edit`, {
        id: this.props.id,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.form.setValue(null, response.data);
      });
  }

  handleLabelMelhor(item) {
    return (
      <span>
        <MdiIcon icon={item.icone} size={16}></MdiIcon>
      </span>
    );
  }

  handleClose() {
    this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
    }, 300);
  }

  handleMenuClick() {
    this.handleClose();
  }

  handleDesenharCampoCustomizado(item) {
    const visible = item.campo.visivel == null || item.campo.visivel;

    if (!visible) return;

    if (item.campo.tipo.id !== 5) {
      return (
        <Row className="mb-3">
          <Col md={3} className="font-weight-bolder">
            {item.campo.nome}
          </Col>
          <Col md={9}>{item.valor}</Col>
        </Row>
      );
    }
    if (item.campo.tipo.id === 5) {
      if (item.campo.multiSelecao) {
        return (
          <Row className="mb-3 d-flex align-items-center">
            <Col md={3} className="font-weight-bolder">
              {item.campo.nome}
            </Col>
            <Col md={9}>
              {item.campo.valores &&
                item.campo.valores.map(({ id, descricao }) => (
                  <div>{descricao}</div>
                ))}
            </Col>
          </Row>
        );
      } else {
        let value =
          item.campo.opcoesTabela &&
          item.campo.opcoesTabela.find((op) => op.id == item.valor)
            ? item.campo.opcoesTabela.find((op) => op.id == item.valor)
            : null;

        return (
          <Row className="mb-3">
            <Col md={3} className="font-weight-bolder">
              {item.campo.nome}
            </Col>
            <Col md={9}>{value?.descricao}</Col>
          </Row>
        );
      }
    }
  }

  togglePanelCollapse() {
    this.setState((prevState) => ({
      isPanelOpen: !prevState.isPanelOpen, // Inverte o estado
    }));
  }

  render() {
    let model = this.form.getModel();
    let { resources, intl, user, modulosComprados } = this.props;
    let grafico =
      model.getModel('graficoCurvaS') && model.getModel('graficoCurvaS').value;

    const { currentDisplay, currentLocale } = formatDateFns(user?.idioma, true);

    let possuiModuloRV = modulosComprados.find((m) => m.id === 3 && m.ativo);

    let actions = (
      <UncontrolledDropdown className="ml-2 d-inline" key="indicador-ficha">
        <DropdownToggle color="secondary">
          <MdiIcon icon="dots-horizontal" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItemList
            exibirFicha={false}
            resources={resources}
            item={{ ...model.getValue(), tipo: { id: 4 } }}
            callback={this.handleMenuClick}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    return (
      <Dialog
        title={model.getModel('nome').value}
        ref="dialog"
        padContent
        footer={actions}
        width={900}
        height={550}
        onRequestClose={this.handleClose}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          {this.state.exibir === true && (
            <div className="pb-3">
              <PanelCollapse
                open={false}
                header={capitalizeFirstLetter(
                  intl.formatMessage({ id: 'dadosCadastro' })
                )}
              >
                <Row className="my-3 d-flex align-items-center">
                  <Col md={3} className="font-weight-bolder">
                    {capitalizeFirstLetter(
                      intl.formatMessage({ id: 'responsaveis' })
                    )}
                  </Col>
                  <Col md={9}>
                    {model.getModel('responsaveis').value && (
                      <AvatarGroup max={15}>
                        {model.getModel('responsaveis').value.map((item) => (
                          <Avatar
                            showTooltip
                            id={`avatar-${item.id}`}
                            foto={item.fotoArquivo}
                            label={item.nome}
                          />
                        ))}
                      </AvatarGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={3} className="font-weight-bolder">
                    {model.getModel('idIntegracao').label}
                  </Col>
                  <Col md={9}>{model.getModel('idIntegracao').value}</Col>
                </Row>
                <Row className="my-3">
                  <Col md={3} className="font-weight-bolder">
                    {capitalizeFirstLetter(resources.percprevisto)}
                  </Col>
                  <Col md={9}>{model.getModel('percentualPrevisto').value}</Col>
                </Row>
                <Row className="my-3">
                  <Col md={3} className="font-weight-bolder">
                    {capitalizeFirstLetter(resources.percrealizado)}
                  </Col>
                  <Col md={9}>
                    {model.getModel('percentualExecutado').value}
                  </Col>
                </Row>
              </PanelCollapse>
              {model.getModel('exibirPrazo').value && (
                <PanelCollapse
                  open={this.state.isPanelOpen}
                  header={capitalizeFirstLetter(
                    intl.formatMessage({ id: 'prazo' })
                  )}
                  collapseCallback={this.togglePanelCollapse}
                >
                  <Row className="my-3">
                    <Col md={4} className="font-weight-bolder">
                      {capitalizeFirstLetter(
                        intl.formatMessage({ id: 'label.dataDeInicio' })
                      )}
                    </Col>
                    <Col md={8}>
                      {model.getModel('dataInicioItem').value &&
                        format(
                          model.getModel('dataInicioItem').value,
                          currentDisplay,
                          {
                            locale: currentLocale,
                          }
                        )}
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={4} className="font-weight-bolder">
                      {capitalizeFirstLetter(
                        intl.formatMessage({ id: 'label.dataTermino' })
                      )}
                    </Col>
                    <Col md={8}>
                      {model.getModel('dataTerminoItem').value &&
                        format(
                          model.getModel('dataTerminoItem').value,
                          currentDisplay,
                          {
                            locale: currentLocale,
                          }
                        )}
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={4} className="font-weight-bolder">
                      {capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'label.dataInicioAcao' },
                          { acao: resources.acao }
                        )
                      )}
                    </Col>
                    <Col md={8}>
                      {model.getModel('dataInicioAcao').value &&
                        format(
                          model.getModel('dataInicioAcao').value,
                          currentDisplay,
                          {
                            locale: currentLocale,
                          }
                        )}
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={4} className="font-weight-bolder">
                      {capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'label.dataTerminoAcao' },
                          { acao: resources.acao }
                        )
                      )}
                    </Col>
                    <Col md={8}>
                      {model.getModel('dataTerminoAcao').value &&
                        format(
                          model.getModel('dataTerminoAcao').value,
                          currentDisplay,
                          {
                            locale: currentLocale,
                          }
                        )}
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={4} className="font-weight-bolder">
                      {capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'label.dataInicioRealizadoAcao' },
                          { acao: resources.acao }
                        )
                      )}
                    </Col>
                    <Col md={8}>
                      {model.getModel('dataInicioRealizadoAcao').value &&
                        format(
                          model.getModel('dataInicioRealizadoAcao').value,
                          currentDisplay,
                          {
                            locale: currentLocale,
                          }
                        )}
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={4} className="font-weight-bolder">
                      {capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'label.dataTerminoRealizadoAcao' },
                          { acao: resources.acao }
                        )
                      )}
                    </Col>
                    <Col md={8}>
                      {model.getModel('dataTerminoAcao').value &&
                        format(
                          model.getModel('dataTerminoAcao').value,
                          currentDisplay,
                          {
                            locale: currentLocale,
                          }
                        )}
                    </Col>
                  </Row>
                  {model.value &&
                    model.value.id != 0 &&
                    model.getModel('configuracaoGeral').value &&
                    model.getModel('configuracaoGeral').value
                      .utilizarCurvaS && (
                      <Row>
                        <Col md={12} className="font-weight-bolder">
                          <div>{intl.formatMessage({ id: 'curvaS' })}</div>
                        </Col>
                        <Col md={12} style={{ marginTop: 20 }}>
                          <GraficoCurvaS
                            height={320}
                            tituloPersonalizado={{
                              titulo: (
                                <strong>{model.getModel('nome').value}</strong>
                              ),
                              polaridade: grafico && grafico.exibirPolaridade,
                              unidadeMedida: false,
                            }}
                            grafico={grafico}
                          ></GraficoCurvaS>
                        </Col>
                      </Row>
                    )}
                </PanelCollapse>
              )}
              {model.getModel('exibirEscopo').value && (
                <PanelCollapse
                  open={false}
                  header={capitalizeFirstLetter(
                    intl.formatMessage({ id: 'escopo' })
                  )}
                >
                  <Row className="my-3">
                    <Col md={9}>{model.getModel('escopo').value}</Col>
                  </Row>
                </PanelCollapse>
              )}
              {model.getModel('exibirCusto').value && (
                <PanelCollapse
                  open={false}
                  header={capitalizeFirstLetter(
                    intl.formatMessage({ id: 'custo' })
                  )}
                >
                  <Row className="my-3">
                    <Col md={4} className="font-weight-bolder">
                      {capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'investimentoPrevisto' },
                          { investimento: resources.investimento }
                        )
                      )}
                    </Col>
                    <Col md={8}>
                      {model.getModel('investimentoPrevisto').value}
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={4} className="font-weight-bolder">
                      {capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'investimentoRealizado' },
                          { investimento: resources.investimento }
                        )
                      )}
                    </Col>
                    <Col md={8}>
                      {model.getModel('investimentoRealizado').value}
                    </Col>
                  </Row>
                </PanelCollapse>
              )}
              {model.getModel('exibirPontosFaixaResultado').value &&
                possuiModuloRV &&
                model.getModel('utilizarPontosFaixaResultados').value && (
                  <PanelCollapse
                    open={false}
                    header={capitalizeFirstLetter(
                      intl.formatMessage({ id: 'label.pontosFaixaResultado' })
                    )}
                  >
                    {model.getModel('pontoFaixaResultado').value && (
                      <Tabs>
                        <Tab
                          label={intl.formatMessage({ id: 'cadastro' })}
                          key="1"
                        >
                          <CadastroContent
                            model={model}
                            resources={resources}
                            intl={intl}
                          />
                        </Tab>
                        <Tab
                          label={intl.formatMessage({ id: 'periodo' })}
                          key="2"
                        >
                          <PontosFaixaPorPeriodoOuAcumulado
                            tipo="pontos"
                            model={model.value}
                            resources={resources}
                            intl={intl}
                          />
                        </Tab>
                        <Tab
                          label={intl.formatMessage({ id: 'yearToDate' })}
                          key="3"
                        >
                          <PontosFaixaPorPeriodoOuAcumulado
                            tipo="pontosAcumulado"
                            model={model.value}
                            resources={resources}
                            intl={intl}
                          />
                        </Tab>
                      </Tabs>
                    )}
                  </PanelCollapse>
                )}
              {model.getModel('exibirCamposCustomizados').value && (
                <PanelCollapse
                  open={false}
                  header={capitalizeFirstLetter(
                    intl.formatMessage({ id: 'camposCustomizados' })
                  )}
                >
                  {model.getModel('camposCustomizados').value &&
                    model
                      .getModel('camposCustomizados')
                      .value.map((item) =>
                        this.handleDesenharCampoCustomizado(item)
                      )}
                </PanelCollapse>
              )}
            </div>
          )}
        </LoadingContainer>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    user: state.user,
    modulosComprados: state.user.licenca.modulos,
  };
}
export default injectIntl(connect(mapStateToProps)(Ficha));
