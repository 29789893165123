import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { capitalizeFirstLetter } from 'src/utils/string';
import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';

import Edit from './edit';

function ViewModal({ intl, resources, id, showDelete, ...rest }) {
  const modalRef = useRef();

  const handleClose = () => {
    modalRef.current.close();
  };

  const actions = [
    <Button onClick={handleClose} color="default">
      {<FormattedMessage id="label.fechar" />}
    </Button>,
  ];

  return (
    <Dialog
      ref={modalRef}
      title={capitalizeFirstLetter(resources.objetivoestrategico)}
      actions={actions}
      onRequestClose={handleClose}
      width={'80%'}
      minHeight={500}
      padContent
      {...rest}
    >
      <Edit id={id} showDelete={showDelete} showToolbar={false} showPrompt={false} />
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(ViewModal));
