import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import DesdobramentoList from '../paginas/desdobramento';

@autobind
class Desdobramento extends React.Component {
  render() {
    return (
      <Lookup
        valueKey="id"
        labelKey="descricao"
        showSearch
        clearable={true}
        hierarchy={true}
        url="/DesdobramentoLookup"
        searchComponent={DesdobramentoList}
        {...this.props}
        data={{ ...this.props.data, viewListSearch: true }}
      />
    );
  }
}

export default Desdobramento;
