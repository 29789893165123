import { FormattedMessage } from 'react-intl';

import Panel from 'src/componentes/panel-collapse';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';

function CamposCustomizados({ model, disabled }) {
  const modelCampos = model.getModel('camposCustomizados');
  const anyVisible =
    modelCampos &&
    modelCampos.value &&
    modelCampos.value.some((x) => x.campo?.visivel);

  if (!anyVisible) {
    return <></>;
  }

  return (
    <Panel open={false} header={<FormattedMessage id="outros" />}>
      <DesenharCampoCustomizado
        customIsDisabledValidation={(i) =>
          i?.campo?.informacaoPessoal ? disabled : false
        }
        model={modelCampos}
        desconsiderarObrigatorio={
          !model?.getModel('ehAlteracaoProprioUsuario').value
        }
      />
    </Panel>
  );
}

export default CamposCustomizados;
