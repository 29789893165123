import { Schema } from '../../utils/form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default Schema.object({
  area: Schema.string().label(),
  areasSubordinadas: Schema.string(),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  idIntegracao: Schema.string().label(<FormattedMessage id="matricula" />),
  perfis: Schema.string().label(<FormattedMessage id="perfis" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  telefone: Schema.string().label(<FormattedMessage id="telefone" />),
  email: Schema.string().label(<FormattedMessage id="email" />),
  idioma: Schema.string().label(<FormattedMessage id="label.idioma" />),
  bloqueadoTentativaLogin: Schema.object().label(
    <FormattedMessage id="label.usuariosBloqueados" />
  ),
  emailBoasVindasEnviado: Schema.object().label(
    <FormattedMessage id="emailBoasVindasEnviado" />
  ),
  modulos: Schema.object().label(<FormattedMessage id="label.modulos" />),
});
