import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import useAuth from 'src/hooks/useAuth';
import DatePicker from 'src/componentes/date-picker';
import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';
import Input from 'src/componentes/input';
import { capitalizeFirstLetter } from 'src/utils/string';

function ExperienciasAnterioresEditView({ handleClose, item, indexItem, ...rest }) {
  const intl = useIntl();
  const modalRef = useRef();
  const { terms: resources } = useAuth();

  const [currentItem, setCurrentItem] = useState(item);
  const [isNew] = useState(item == null);

  const handleCurrentClose = (isSaved = false) => {
    modalRef.current.close();
    setTimeout(() => {
      handleClose && handleClose(isSaved, isNew, currentItem, indexItem);
    }, 300);
  };

  const handleChange = (field, value) => {
    setCurrentItem((prev) => ({
      ...prev,
      permissions: {
        allowEdit: true,
        allowDelete: true,
      },
      [field]: value,
    }));
  };

  const handleCloseClick = () => {
    handleCurrentClose();
  };

  const handleSave = () => {
    handleCurrentClose(true);
  };

  const isDisabled = () => !currentItem || !currentItem.cargo || !currentItem.empresa || !currentItem.dataInicio || !currentItem.dataTermino;

  const editActions = [
    <Button key={1} onClick={handleCloseClick} color="secondary">
      {<FormattedMessage id="label.cancelar" />}
    </Button>,
    <Button key={2} disabled={isDisabled()} type="primary" onClick={handleSave} htmlType="submit" className="ml-2">
      {<FormattedMessage id="label.salvar" />}
    </Button>,
  ];

  return (
    <Dialog
      id="formacoes-academicas-edit"
      ref={modalRef}
      width={750}
      minHeight={250}
      title={capitalizeFirstLetter(
        intl.formatMessage({ id: 'experienciasAnteriores' })
      )}
      padContent
      actions={editActions}
      onRequestClose={handleCurrentClose}
      {...rest}
    >
      <Row>
        <Col md={5}>
          <Input
            required
            model={{
              label: capitalizeFirstLetter(resources.cargo),
              value: currentItem && currentItem.cargo,
              requestChange: (e) => handleChange('cargo', e),
            }}
          />
        </Col>
        <Col md={5}>
          <Input
            required
            model={{
              label: <FormattedMessage id="empresa" />,
              value: currentItem && currentItem.empresa,
              requestChange: (e) => handleChange('empresa', e),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <DatePicker
            required
            autoFormatDate={true}
            timeFormat={false}
            model={{
              label: <FormattedMessage id="dataInicio" />,
              value: currentItem && currentItem.dataInicio,
              requestChange: (e) => handleChange('dataInicio', e),
            }}
          />
        </Col>
        <Col md={4}>
          <DatePicker
            required
            autoFormatDate={true}
            timeFormat={false}
            model={{
              label: <FormattedMessage id="dataTermino" />,
              value: currentItem && currentItem.dataTermino,
              requestChange: (e) => handleChange('dataTermino', e),
            }}
          />
        </Col>
      </Row>
    </Dialog>
  );
}

export default ExperienciasAnterioresEditView;
