import { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';

import LoadingContainer from 'src/componentes/loading-container';
import UserSearchSelect from './components/userSearchSelect';
import DatePicker from 'src/componentes/date-picker';
import Panel from 'src/componentes/panel';
import { createEvent } from './services/eventsService';
import { getToken } from './services/auth';
import { useIntl } from 'react-intl';

const CreateEvent = ({ onEventCreated, model, setShowCreateEvent }) => {
  const intl = useIntl();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);

  const activeAccount = instance.getActiveAccount();
  const userEmail = activeAccount?.username;
  const userName = activeAccount?.name;

  const utcMoment = moment.utc();
  utcMoment.minutes(0);
  utcMoment.seconds(0);
  utcMoment.milliseconds(0);

  const [eventDetailsState, setEventDetailsState] = useState({
    subject: '',
    start: {
      dateTime: new Date(
        utcMoment.add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      ),
      timeZone: 'UTC',
    },
    end: {
      dateTime: new Date(
        utcMoment.add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      ),
      timeZone: 'UTC',
    },
    attendees: [],
    isonlineMeeting: true,
    onlineMeetingProvider: 'teamsForBusiness',
    // Isso será implementado em uma próxima entrega
    // body: {
    //   contentType: 'html',
    //   content: `<html>
    //       <body>
    //         <a href="https://actiosoftware.com/">
    //           Aqui será enviado o link para a apresentação (quando tiver)
    //         </a>
    //       </body>
    //     </html>`,
    // },
    organizer: {
      emailAddress: {
        address: userEmail,
        name: userName,
      },
    },
    recurrence: {
      pattern: {
        type: '',
        interval: 1,
      },
      range: {
        type: '',
        startDate: '',
        endDate: '',
        numberOfOccurrences: 1,
      },
    },
  });
  const [optionalAttendees, setOptionalAttendees] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setEventDetailsState((prevDetails) => ({
      ...prevDetails,
      organizer: {
        emailAddress: {
          address: userEmail,
          name: userName,
        },
      },
    }));
  }, [userEmail, userName]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let finalValue = type === 'checkbox' ? checked : value;

    if (name.includes('.')) {
      const [key, subKey, subSubKey] = name.split('.');
      setEventDetailsState((prevDetails) => ({
        ...prevDetails,
        [key]: {
          ...prevDetails[key],
          [subKey]: subSubKey
            ? { ...prevDetails[key][subKey], [subSubKey]: finalValue }
            : finalValue,
        },
      }));
    } else {
      setEventDetailsState((prevDetails) => ({
        ...prevDetails,
        [name]: finalValue,
      }));
    }
  };

  const handleChangeTitle = (e) => {
    const { value } = e.target;
    model.getModel('assunto').requestChange(value);
    handleChange(e);
  };

  const handleUserSelect = (selectedUsers, attendeeType) => {
    const updatedAttendees = selectedUsers.map((user) => ({
      emailAddress: {
        address: user.value,
      },
      type: attendeeType,
    }));

    if (attendeeType === 'required') {
      setEventDetailsState((prevDetails) => ({
        ...prevDetails,
        attendees: updatedAttendees,
      }));
    } else {
      setOptionalAttendees(updatedAttendees);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      const accessToken = await getToken(instance);

      const formattedEventDetails = {
        subject: eventDetailsState.subject,
        start: {
          dateTime: moment.utc(eventDetailsState.start.dateTime).format(),
          timeZone: 'UTC',
        },
        end: {
          dateTime: moment.utc(eventDetailsState.end.dateTime).format(),
          timeZone: 'UTC',
        },
        attendees: [...eventDetailsState.attendees, ...optionalAttendees],
        isonlineMeeting: eventDetailsState.isonlineMeeting,
        onlineMeetingProvider: eventDetailsState.onlineMeetingProvider,
        organizer: eventDetailsState.organizer,
      };

      // if (
      //   isRecurring &&
      //   eventDetailsState.recurrence.pattern.type &&
      //   eventDetailsState.recurrence.range.type
      // ) {
      //   formattedEventDetails.recurrence = {
      //     pattern: {
      //       type: eventDetailsState.recurrence.pattern.type,
      //       interval: eventDetailsState.recurrence.pattern.interval,
      //     },
      //     range: {
      //       type: eventDetailsState.recurrence.range.type,
      //       startDate: moment
      //         .utc(eventDetailsState.start.dateTime)
      //         .format('YYYY-MM-DD'),
      //     },
      //   };

      //   if (eventDetailsState.recurrence.range.type === 'endDate') {
      //     formattedEventDetails.recurrence.range.endDate = moment
      //       .utc(eventDetailsState.recurrence.range.endDate)
      //       .format('YYYY-MM-DD');
      //   } else if (eventDetailsState.recurrence.range.type === 'numbered') {
      //     formattedEventDetails.recurrence.range.numberOfOccurrences =
      //       eventDetailsState.recurrence.range.numberOfOccurrences;
      //   }
      // }

      const response = await createEvent(accessToken, formattedEventDetails);

      if (response && response.id) {
        const meetingId = response.id;
      }
      enqueueSnackbar(intl.formatMessage({ id: 'eventoAgendadoSucesso' }), {
        variant: 'success',
      });
      onEventCreated();
    } catch (error) {
      console.error('Error creating event:', error);
      enqueueSnackbar(
        intl.formatMessage({ id: 'erroCriarEvento' }) + error.message,
        {
          variant: 'error',
        }
      );
    } finally {
      setIsLoading(false);
      setShowCreateEvent(false);
    }
  };

  const getColSize = () => {
    const baseColSize = 12;
    let numCols = 3;
    if (
      eventDetailsState.recurrence.range.type === 'endDate' ||
      eventDetailsState.recurrence.range.type === 'numbered'
    ) {
      numCols = 4;
    }
    return baseColSize / numCols;
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <Panel className="w-100">
        <Form className="py-3">
          <Row>
            <Col md={6}>
              <Label for="subject">
                {' '}
                {intl.formatMessage({ id: 'assunto' })}
              </Label>
              <Input
                type="text"
                name="subject"
                id="subject"
                value={eventDetailsState.subject}
                onChange={handleChangeTitle}
                required
              />
            </Col>

            <Col md={3}>
              <Label for="start">
                {intl.formatMessage({ id: 'dataInicio' })}
              </Label>
              <DatePicker
                timeFormat
                autoFormatDate={true}
                model={{
                  value: eventDetailsState.start.dateTime,
                  requestChange: (value) =>
                    handleChange({
                      target: {
                        name: 'start.dateTime',
                        value,
                      },
                    }),
                }}
              />
            </Col>

            <Col md={3}>
              <Label for="end">
                {intl.formatMessage({ id: 'dataTermino' })}
              </Label>
              <DatePicker
                timeFormat
                autoFormatDate={true}
                model={{
                  value: eventDetailsState.end.dateTime,
                  requestChange: (value) =>
                    handleChange({
                      target: {
                        name: 'end.dateTime',
                        value,
                      },
                    }),
                }}
              />
            </Col>
          </Row>

          <FormGroup className="mb-3">
            <Label for="userSearch">
              {intl.formatMessage({ id: 'convidados' })}
            </Label>
            <UserSearchSelect
              onUserSelect={handleUserSelect}
              attendeeType="required"
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="optionalAttendees">
              {intl.formatMessage({ id: 'convidadosOpcionais' })}
            </Label>
            <UserSearchSelect
              onUserSelect={handleUserSelect}
              attendeeType="optional"
            />
          </FormGroup>
          {/* <FormGroup check className="mb-3">
            <Label check>
              <Input
                type="checkbox"
                name="isRecurring"
                checked={isRecurring}
                onChange={(e) => setIsRecurring(e.target.checked)}
              />{' '}
              {intl.formatMessage({ id: 'recorrencia' })}
            </Label>
          </FormGroup>
          {isRecurring && (
            <Row className="mb-3">
              <Col md={getColSize()}>
                <FormGroup>
                  <Label for="recurrence.type">
                    {intl.formatMessage({ id: 'repetirACada' })}
                  </Label>
                  <Input
                    type="select"
                    name="recurrence.pattern.type"
                    id="recurrence.type"
                    value={eventDetailsState.recurrence.pattern.type}
                    onChange={handleChange}
                    required
                  >
                    <option value="daily">
                      {intl.formatMessage({ id: 'diariamente' })}
                    </option>
                    <option value="weekly">
                      {intl.formatMessage({ id: 'semanalmente' })}
                    </option>
                    <option value="absoluteMonthly">
                      {intl.formatMessage({ id: 'mensalmente' })}
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={getColSize()}>
                <FormGroup>
                  <Label for="recurrence.interval">
                    {intl.formatMessage({ id: 'intervalo' })}
                  </Label>
                  <Input
                    type="number"
                    name="recurrence.pattern.interval"
                    id="recurrence.interval"
                    value={eventDetailsState.recurrence.pattern.interval}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={getColSize()}>
                <FormGroup>
                  <Label for="recurrence.range.type">
                    {intl.formatMessage({ id: 'tipoTermino' })}
                  </Label>
                  <Input
                    type="select"
                    name="recurrence.range.type"
                    id="recurrence.range.type"
                    value={eventDetailsState.recurrence.range.type}
                    onChange={handleChange}
                    required
                  >
                    <option value="endDate">
                      {intl.formatMessage({ id: 'dataTermino' })}
                    </option>
                    <option value="numbered">
                      {intl.formatMessage({ id: 'ocorrencia' })}
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              {eventDetailsState.recurrence.range.type === 'endDate' && (
                <Col md={getColSize()}>
                  <FormGroup>
                    <Label for="recurrence.range.endDate">
                      {intl.formatMessage({ id: 'dataTermino' })}
                    </Label>
                    <DatePicker
                      autoFormatDate={true}
                      model={{
                        value: '',
                        requestChange: (value) =>
                          handleChange({
                            target: {
                              name: 'recurrence.range.endDate',
                              value,
                            },
                          }),
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              {eventDetailsState.recurrence.range.type === 'numbered' && (
                <Col md={getColSize()}>
                  <FormGroup>
                    <Label for="recurrence.range.numberOfOccurrences">
                      {intl.formatMessage({ id: 'label.numeroDeOcorrencias' })}
                    </Label>
                    <Input
                      type="number"
                      name="recurrence.range.numberOfOccurrences"
                      id="recurrence.range.numberOfOccurrences"
                      value={
                        eventDetailsState.recurrence.range.numberOfOccurrences
                      }
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          )} */}

          <Button
            type="button"
            color="primary"
            className="float-right"
            onClick={handleSave}
          >
            {intl.formatMessage({ id: 'agendar' })}
          </Button>
        </Form>
      </Panel>
    </LoadingContainer>
  );
};

export default CreateEvent;
