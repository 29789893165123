import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { capitalizeFirstLetter } from 'src/utils/string';
import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';

import ProcessoEdit from './edit';
import ContentManager from 'src/componentes/content-manager';

function ProcessoViewModal({ resources, allowSave, id, ...rest }) {
  const modalRef = useRef();

  const handleClose = () => {
    modalRef.current.close();
  };

  const actions = [
    <Button onClick={handleClose} color="secondary">
      {<FormattedMessage id="label.fechar" />}
    </Button>,
  ];

  return allowSave ? (
    <ProcessoEdit id={id} showToolbar={true} showPrompt={false} showButtonSave={true} hideDelete={true} handleClose={handleClose} {...rest} />
  ) : (
    <ProcessoEdit id={id} showToolbar={false} showPrompt={false} {...rest} />
  );
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(ProcessoViewModal));
