import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../../componentes/dialog';
import Button from '../../../componentes/button';
import errorHandler from '../../../utils/error-handler';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingContainer from '../../../componentes/loading-container';
import DesdobramentoLookup from '../../../lookups/desdobramento';
import { Row, Col } from 'reactstrap';
import http from 'src/services/httpService';

@autobind
class DesdobramentoMover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      origem: this.props.origem,
      destino: {},
    };
  }

  componentDidMount() {}

  handleClose() {
    this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
      this.props.handleClose && this.props.handleClose(true);
    }, 300);
  }

  handleMove() {
    this.setState({
      isLoading: true,
    });

    const destino = this.state.destino;

    http
      .post(`/Desdobramento/Mover`, {
        id: this.props.desdobramentoId,
        desdobramentoDestino: destino,
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });

        this.handleClose();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        errorHandler(error);
      });
  }

  handleChange(item) {
    this.setState({ destino: item });
  }

  render() {
    let editActions = [
      <div key="edit-toolbar">
        <Button color="secondary" onClick={this.handleClose}>
          <FormattedMessage id="label.cancelar" />
        </Button>
        <Button className="ml-2" onClick={this.handleMove}>
          <FormattedMessage id="label.mover" />
        </Button>
      </div>,
    ];

    let { origem, destino } = this.state;
    let { intl, desdobramentoId } = this.props;

    return (
      <Dialog
        ref="dialog"
        title={intl.formatMessage({ id: 'label.moverDesdobramento' })}
        minWidth={'60%'}
        style={{ fontSize: 12 }}
        onRequestClose={this.handleClose}
        padContent
        actions={editActions}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <Row style={{ marginBottom: 30 }}>
            <Col md={6}>
              <DesdobramentoLookup
                clearable={false}
                disabled
                model={{
                  label: intl.formatMessage({ id: 'origem' }),
                  value: origem,
                }}
              />
            </Col>
            <Col md={6}>
              <DesdobramentoLookup
                required
                clearable={false}
                data={{ idIgnorar: desdobramentoId }}
                model={{
                  label: intl.formatMessage({ id: 'destino' }),
                  requestChange: this.handleChange,
                  value: destino,
                }}
              />
            </Col>
          </Row>
        </LoadingContainer>
      </Dialog>
    );
  }
}

export default injectIntl(DesdobramentoMover);
