import React from 'react';
import { autobind } from 'core-decorators';
import DatePicker from '../../componentes/date-picker';
import TipoFiltroDataLookup from '../../lookups/tipo-filtro-data';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Alert from '../../componentes/message-box/alert';
import ContentManager from 'src/componentes/content-manager';
import moment from 'moment';
import { connect } from 'react-redux';
import Info from '../../componentes/info';

@autobind
class PeriodoData extends React.Component {
  static defaultProps = {
    dataInicioField: 'dataInicio',
    dataTerminoField: 'dataTermino',
    tipoFiltroDataField: 'tipoFiltroData',
    styleDataFields: {},
    disabled: false,
    customLabelTipoPeriodo: null,
    customLabelDataInicio: null,
    customLabelDataTermino: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      dataInicio: this.props.dataInicio && (typeof this.props.dataInicio === 'string' ? this.props.dataInicio.substring(0, 10) : this.props.dataInicio),
      dataTermino: this.props.dataTermino && (typeof this.props.dataInicio === 'string' ? this.props.dataTermino.substring(0, 10) : this.props.dataTermino),
      tipoFiltroData: this.props.tipoFiltroData,
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState){
  //   if (nextProps.tipoFiltroData !== prevState.tipoFiltroData){
  //     return {tipoFiltroData: nextProps.tipoFiltroData};
  //   }
  //   if (nextProps.dataInicio !== prevState.dataInicio){
  //     return {dataInicio: nextProps.dataInicio};
  //   }
  //   if (nextProps.dataTermino !== prevState.dataTermino){
  //     return {dataTermino: nextProps.dataTermino};
  //   }
  //   else return null;
  // }

  // componentDidUpdate(prevProps, prevState){
  //   if (prevProps.tipoFiltroData !== prevState.tipoFiltroData){
  //     let tipoFiltroDataProps = this.props.tipoFiltroData;
  //     this.setState({tipoFiltroData: tipoFiltroDataProps});
  //   }
  //   if (prevProps.dataInicio !== prevState.dataInicio){
  //     let dataInicioProps = this.props.dataInicio;
  //     this.setState({dataInicio: dataInicioProps});
  //   }
  //   if (prevProps.dataTermino !== prevState.dataTermino){
  //     let dataTerminoProps = this.props.dataTermino;
  //     this.setState({dataTermino: dataTerminoProps});
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) return nextProps;
    else return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== prevState) {
      this.setState({ ...this.props });
    }
  }

  handleChangeDataInicio(field, value) {
    this.props.onChange(this.props.dataInicioField, value);
    this.setState({
      [field]: value,
    });
  }

  validateDataInicio(value) {
    let dataTermino = this.state.dataTermino;

    if (moment(value) > moment(dataTermino)) {
      ContentManager.addContent(<Alert message={<FormattedMessage id="label.dataInicioDeveSerMenorDataTermino" />} />);
      return false;
    } else {
      return true;
    }
  }

  handleChangeDataTermino(field, value) {
    let dataInicio = this.state.dataInicio;

    this.props.onChange(this.props.dataTerminoField, value);
    this.setState({
      [field]: value,
    });

    if (dataInicio == null) this.props.onChange(field, dataInicio);
  }

  validateDataTermino(value) {
    let dataInicio = this.state.dataInicio;

    if (moment(value) < moment(dataInicio)) {
      ContentManager.addContent(<Alert message={<FormattedMessage id="label.dataTerminoDeveSerMaiorDataInicio" />} />);
      return false;
    } else {
      return true;
    }
  }

  handleTipoFiltroChange(value) {
    if (value && value.id === 1) {
      moment.locale(this.props.lang);
      let now = new Date();
      let dataInicioDefault = new Date(now.getFullYear(), 0, 1);
      let dataTerminoDefault = new Date(now.getFullYear(), 11, 1);

      this.setState({
        dataInicio: dataInicioDefault,
        dataTermino: dataTerminoDefault,
      });

      //necessário timeout para preencher todos os campos já que estava preenchendo só o tipofiltrodata
      setTimeout(() => {
        this.props.onChange(this.props.dataInicioField, dataInicioDefault);
      }, 500);

      setTimeout(() => {
        this.props.onChange(this.props.dataTerminoField, dataTerminoDefault);
      }, 500);
    } else {
      this.setState({
        dataInicio: null,
        dataTermino: null,
      });

      //necessário timeout para preencher todos os campos já que estava preenchendo só o tipofiltrodata
      setTimeout(() => {
        this.props.onChange(this.props.dataInicioField, null);
      }, 500);

      setTimeout(() => {
        this.props.onChange(this.props.dataTerminoField, null);
      }, 500);
    }

    this.setState({
      tipoFiltroData: value,
    });
    this.props.onChange(this.props.tipoFiltroDataField, value);
  }

  handleChangeTipoFiltro(field, value) {
    this.setState({
      [field]: value,
    });
    this.props.onChange(field, value);
  }

  render() {
    let { dataInicio, dataTermino, tipoFiltroData } = this.state;
    let { lang, configuracao, intl, disabled, adjustSize = false, clearable = false } = this.props;
    let { periodoCiclo } = configuracao;

    moment.locale(lang);

    let tipoFiltroDataDefault = tipoFiltroData ? tipoFiltroData : this.props.tipoFiltroData ? this.props.tipoFiltroData : null;

    let exibirDatas = tipoFiltroDataDefault && tipoFiltroDataDefault.id === 1;

    let dataInicioV = dataInicio ? dataInicio : periodoCiclo && moment(periodoCiclo.dataInicio).utc(lang);
    let dataTerminoV = dataTermino ? dataTermino : periodoCiclo && moment(periodoCiclo.dataTermino).utc(lang);

    const firstColumnSize = adjustSize ? (!exibirDatas ? 12 : 4) : 4;

    return (
      <Row>
        <Col md={firstColumnSize}>
          <TipoFiltroDataLookup
            disabled={disabled}
            data={this.props.data}
            options={this.props.options}
            collapse={false}
            clearable={clearable}
            model={{
              label:
                tipoFiltroDataDefault && tipoFiltroDataDefault.id === 7 ? (
                  <span>
                    <FormattedMessage id="label.tipoFiltroData" />
                    <Info className="ml-2" id="tipoFiltroData" placement="right" text={intl.formatMessage({ id: 'label.infoDesdeQuandoHaValores' })} />
                  </span>
                ) : (
                  this.props.customLabelTipoPeriodo ? this.props.customLabelTipoPeriodo : <FormattedMessage id="label.tipoFiltroData" />
                ),
              value: tipoFiltroDataDefault && tipoFiltroDataDefault.id,
              requestChange: this.handleTipoFiltroChange,
            }}
            objectValue={tipoFiltroDataDefault}
          />
        </Col>
        {exibirDatas && (
          <Col md={4}>
            <DatePicker
              disabled={disabled}
              style={this.props.styleDataFields}
              month
              model={{
                label: this.props.customLabelDataInicio ? this.props.customLabelDataInicio : <FormattedMessage id="dataInicio" />,
                value: moment(dataInicioV).format('MM/YYYY'),
                requestChange: this.handleChangeDataInicio.bind(this, 'dataInicio'),
                validateBeforeChange: this.validateDataInicio.bind(this),
              }}
            ></DatePicker>
          </Col>
        )}
        {exibirDatas && (
          <Col md={4}>
            <DatePicker
              disabled={disabled}
              style={this.props.styleDataFields}
              month
              model={{
                label: this.props.customLabelDataTermino ? this.props.customLabelDataTermino : <FormattedMessage id="dataTermino" />,
                value: moment(dataTerminoV).format('MM/YYYY'),
                requestChange: this.handleChangeDataTermino.bind(this, 'dataTermino'),
                validateBeforeChange: this.validateDataTermino.bind(this),
              }}
            ></DatePicker>
          </Col>
        )}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    lang: state.user && state.user.idioma,
    configuracao: state.user && state.user.configuracao,
  };
}
export default injectIntl(connect(mapStateToProps)(PeriodoData));
