import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { autobind } from 'core-decorators';

@autobind
class Pager extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Current: this.props.activePage ? this.props.activePage : 1,
      Pages: this.getItems(1),
      automaticPage: true,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.activePage !== this.props.activePage) {
      if (newProps.ehApresentacao) {
        if (this.state.automaticPage)
          if (newProps.activePage > this.props.activePage) {
            this.nextClicked(false);
          } else {
            this.prevClicked(false);
          }
        else {
          this.setState({ automaticPage: true });
        }
      } else {
        this.setState({ Current: newProps.activePage });
      }
    }
  
    if (newProps.totalPages !== this.props.totalPages) {
      this.setState({ Pages: this.getItems(1, newProps.totalPages, newProps.totalDisplayed) });
    }
  }

  handleUpdatePages() {
    setTimeout(() => {
      this.setState(
        {
          Current: this.props.activePage,
          Pages: this.getItems(1),
        },
        () => {}
      );
    }, 200);
  }

  getItems(startPage, totalPagesParameter, totalDisplayedParameter) {
    let items = [];

    const totalPagesFinal = totalPagesParameter ?? this.props.totalPages;
    const totalDisplayedFinal = totalDisplayedParameter ?? this.props.totalDisplayed;

    var maxItems = Math.min(totalPagesFinal, totalDisplayedFinal);
    for (var i = startPage; i <= maxItems + startPage - 1; i++) {
      items[i] = i;
    }
    return items;
  }

  pageClicked(e) {
    e.preventDefault();

    this.setState({ automaticPage: false });

    let pageNumber = parseInt(e.target.getAttribute('href'), 10);
    //only change page if user clicks on a different one than what is current
    if (pageNumber !== this.state.Current) {
      this.changePage(pageNumber);
    }
  }

  changePage(pageNumber, pages) {
    if (pages) {
      this.setState({ Current: pageNumber, Pages: pages }, () => {
        this.props.pageChanged(this.state.Current);
      });
    } else {
      this.setState({ Current: pageNumber }, () => {
        this.props.pageChanged(this.state.Current);
      });
    }
  }
  nextClicked(stopAutomatic = false) {
    //e != null && e.preventDefault();

    if (stopAutomatic) this.setState({ automaticPage: false });

    const nextPage = this.state.Current + 1;
    const items = this.state.Pages.includes(nextPage) ? null : this.getItems(nextPage - this.props.totalDisplayed + 1);
    this.changePage(nextPage, items);
  }

  prevClicked(stopAutomatic = false) {
    //e != null && e.preventDefault();

    if (stopAutomatic) this.setState({ automaticPage: false });

    const nextPage = this.state.Current - 1;

    const pages = this.state.Pages.includes(nextPage) ? null : this.getItems(nextPage);

    this.changePage(nextPage, pages);
  }

  render() {
    return (
      <Pagination {...this.props.other}>
        <PaginationItem disabled={this.state.Current === 1}>
          <PaginationLink className={this.state.Current === 1 ? 'bg-light-gray' : ''} previous onClick={this.prevClicked.bind(true)} />
        </PaginationItem>

        {this.state.Pages.map((p) => {
          return (
            <PaginationItem key={p} active={p === this.state.Current}>
              <PaginationLink onClick={this.pageClicked} href={p}>
                {p}
              </PaginationLink>
            </PaginationItem>
          );
        })}
        <PaginationItem disabled={this.state.Current === this.props.totalPages}>
          <PaginationLink className={this.state.Current === this.props.totalPages ? 'bg-light-gray' : ''} next onClick={this.nextClicked.bind(true)} />
        </PaginationItem>
      </Pagination>
    );
  }
}

Pager.propTypes = {
  //fired when user clicks a page number
  pageChanged: PropTypes.func.isRequired,
  //the total number of pages
  totalPages: function (props) {
    if (!props.totalPages) {
      return new Error('totalPages is required');
    } else if (props.totalPages < props.totalDisplayed) {
      return new Error('totalpages must be >= totalDisplayed');
    } else if (props.totalPages < 2) {
      return new Error('totalPages must be 2 or greater');
    }
  },
  //the total number of pages displayed
  totalDisplayed: PropTypes.number,
};

Pager.defaultProps = {
  totalDisplayed: 5,
};
export default Pager;
