import React from 'react';
import Button from '../../../componentes/button';
import { autobind } from 'core-decorators';
import ContentManager from '../../../componentes/content-manager';
import Avatar from '../../../componentes/avatar';
import SelectImage from '../../../componentes/arquivo-upload/select-image';
import MdiIcon from '../../../componentes/mdi-icon';
import { connect } from 'react-redux';

@autobind
class AvatarUsuario extends React.Component {
  handleChangeImg() {
    ContentManager.addContent(
      <SelectImage
        tipoAnexo={this.props.tiposAnexos.colaborador}
        idReferencia={this.props.usuarioId}
        model={this.props.model}
      />
    );
  }

  handleDeletePicture() {
    this.props.model.requestChange(null);
  }

  render() {
    let { model, label, disabled } = this.props;

    return (
      <div style={{ width: '100%', textAlign: 'center', display: 'block' }}>
        <div style={{ width: 120, margin: '0 auto' }}>
          <Avatar
            foto={model && model.value}
            size={120}
            labelSize={60}
            label={label.value}
            showLabel={false}
          />
        </div>
        <div className="mt-2">
          <Button
            disabled={disabled}
            onClick={this.handleChangeImg}
            color="secondary"
          >
            <MdiIcon icon="camera" />
          </Button>
          <Button
            disabled={disabled}
            className="ml-2"
            outline
            onClick={this.handleDeletePicture}
            color="danger"
          >
            <MdiIcon icon="delete-outline" />
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tiposAnexos: state.user.tiposAnexos,
  };
}

export default connect(mapStateToProps)(AvatarUsuario);
