import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import http from 'src/services/httpService';
import Alert from 'src/componentes/message-box/alert';
import Confirm from 'src/componentes/message-box/confirm';
import ContentManager from 'src/componentes/content-manager';
import DesdobramentoEdit from 'src/paginas/desdobramento/edit';
import Erro from 'src/paginas/desdobramento/erro';
import Mover from 'src/paginas/desdobramento/mover';
import Search from 'src/paginas/desdobramento/search';
import SearchSchema from 'src/paginas/desdobramento/search-schema';
import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';
import NewListTree from 'src/componentes/NewList/NewListTree';
import ListColumn from 'src/componentes/NewList/ListColumn';
import Email from 'src/componentes/email';
import MdiIcon from 'src/componentes/mdi-icon';

const endpoint = 'Desdobramento';

@autobind
class Desdobramento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: false,
    };
  }
  renderStatus(item) {
    if (!item.status) return;

    if (item.status.id == 1)
      return <Badge color="success">{item.status.descricao}</Badge>;
    else return <Badge color="light">{item.status.descricao}</Badge>;
  }

  renderDataInicio(item) {
    return (
      <span>
        {item.dataInicio && (
          <span>
            {moment(item.dataInicio).locale('pt-Br').format('MM/YYYY')}
          </span>
        )}
      </span>
    );
  }

  renderDataTermino(item) {
    return (
      <div>
        {item.dataTermino && (
          <span>
            {moment(item.dataTermino).locale('pt-Br').format('MM/YYYY')}
          </span>
        )}
      </div>
    );
  }

  handleNew(callback, item, params) {
    ContentManager.addContent(
      <DesdobramentoEdit
        mode="edit"
        handleClose={callback}
        parameters={{
          parentId: item && item.id,
          tipoId: params && params.tipoId,
        }}
        item={params && params.tipoId ? null : item}
      />
    );
  }

  handleRemove(item) {
    http
      .post(`/Desdobramento/Remove`, {
        id: item.id,
      })
      .then((response) => {
        if (response.data.status === 501) {
          // erro
          ContentManager.addContent(
            <Erro
              desdobramentoId={item.id}
              descricao={item.descricao}
              message={response.data.mensagem}
            />
          );
        } else {
          this.setState({ saved: Math.random() });
          ContentManager.addContent(
            <Alert
              title={<FormattedMessage id="sucesso" />}
              message={
                <FormattedMessage id="label.registroExcluidoComSucesso" />
              }
            />
          );
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  hadleMove(item, callback) {
    ContentManager.addContent(
      <Mover
        origem={item.pai}
        desdobramentoId={item.id}
        handleClose={callback}
      />
    );
  }

  handleChageStatus(item, callback) {
    ContentManager.addContent(
      <Confirm
        message={<FormattedMessage id="label.alterarStatusDoRegistroEFilhos" />}
        handleConfirm={this.handleChageStatusConfirm.bind(this, item, callback)}
      />
    );
  }

  handleChageStatusConfirm(item, callback) {
    http
      .post(`/Desdobramento/AlterarStatus`, {
        id: item.id,
        status: item.status.id == 1 ? { id: 0 } : { id: 1 },
      })
      .then(() => {
        ContentManager.addContent(
          <Alert
            title={<FormattedMessage id="sucesso" />}
            message={<FormattedMessage id="label.registroAlteradoComSucesso" />}
          />
        );
        callback && callback();
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleDeleteClick(item, onRefresh) {
    ContentManager.addContent(
      <Confirm
        message={<FormattedMessage id="label.desejaMesmoExcluirEsseRegistro" />}
        handleConfirm={this.handleDeleteClickConfirm.bind(
          this,
          item,
          onRefresh
        )}
      />
    );
  }

  handleDeleteClickConfirm(item, callback) {
    http
      .post(`/${endpoint}/Remove`, { id: item.id })
      .then(() => {
        ContentManager.addContent(
          <Alert
            title={<FormattedMessage id="sucesso" />}
            message={<FormattedMessage id="label.registroExcluidoComSucesso" />}
          />
        );
        callback && callback();
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  render() {
    let { resources, intl } = this.props;

    const renderMenu = (item, onRefresh) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="nav-link fixed"
            style={{ padding: 0 }}
          >
            <MdiIcon icon="dots-vertical" size={18} />
          </DropdownToggle>
          <DropdownMenu container="body" placement="left">
            <DropdownItem onClick={this.handleNew.bind(this, onRefresh, item)}>
              <MdiIcon className="mr-2" icon="pencil" />
              <FormattedMessage
                id="label.editar"
                values={{ editar: resources.editar }}
              />
            </DropdownItem>
            <DropdownItem
              onClick={this.handleDeleteClick.bind(this, item, onRefresh)}
            >
              <MdiIcon className="mr-2" icon="delete-outline" />
              <FormattedMessage id="excluir" />
            </DropdownItem>
            <DropdownItem
              onClick={this.handleNew.bind(this, onRefresh, item, {
                tipoId: 2,
              })}
            >
              <MdiIcon className="mr-2" icon="plus" />
              <FormattedMessage id="label.novoItem" />
            </DropdownItem>
            {item && item.pai != null && (
              <DropdownItem
                onClick={this.hadleMove.bind(this, item, onRefresh)}
              >
                <MdiIcon className="mr-2" icon="arrow-up-down-bold-outline" />
                <FormattedMessage id="label.mover" />
              </DropdownItem>
            )}
            {item && item.status && item.status.id == 1 && (
              <DropdownItem
                onClick={this.handleChageStatus.bind(this, item, onRefresh)}
              >
                <MdiIcon className="mr-2" icon="format-list-checks" />
                <FormattedMessage id="label.inativar" />
              </DropdownItem>
            )}
            {item && item.status && item.status.id == 0 && (
              <DropdownItem
                onClick={this.handleChageStatus.bind(this, item, onRefresh)}
              >
                <MdiIcon className="mr-2" icon="format-list-checks" />
                <FormattedMessage id="label.ativar" />
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    };

    const renderTreeAlert = (id) => (
      <ReactTooltip id={id} aria-haspopup="true">
        {intl.formatMessage(
          {
            id: 'esteDesdobramentoApareceNaListaApenasPorqueFazParteHierarquia',
          },
          { desdobramento: intl.formatMessage({ id: 'desdobramento' }) }
        )}
      </ReactTooltip>
    );

    const renderDescricao = (item) => {
      if (!item.descricao) return;

      const showTreeAlert = item.estaNaListaApenasPorHierarquia;

      const id = `DESDOBRAMENTO-TREE-${item.id}`;

      return (
        <div className="d-flex justify-content-center align-items-center">
          <div
            id={id}
            data-tip
            data-for={id}
            className={`font-weight-bold ${
              showTreeAlert
                ? 'text-muted font-weight-bold'
                : 'text-primary font-weight-bolder'
            }`}
          >
            {item.descricao}
          </div>
          {showTreeAlert && renderTreeAlert(id)}
        </div>
      );
    };

    const defaultColumns = [
      <ListColumn
        headerText={intl.formatMessage({ id: 'descricao' })}
        valueField="descricao"
        valueFunction={renderDescricao}
        tree
        required
      />,
      <ListColumn
        headerText={intl.formatMessage({ id: 'idIntegracao' })}
        valueField="idIntegracao"
        sortField="UltimaVersao.idIntegracao"
      />,
      <ListColumn
        headerText={intl.formatMessage({ id: 'status' })}
        valueField="status"
        valueFunction={this.renderStatus}
      />,
      <ListColumn
        headerText={capitalizeFirstLetter(resources.area)}
        valueField="area"
      />,
      <ListColumn
        headerText={capitalizeFirstLetter(resources.responsavel)}
        valueField="responsavel"
      />,
      <ListColumn
        headerText={capitalizeFirstLetter(resources.digitador)}
        valueField="digitador"
      />,
      <ListColumn
        headerText={intl.formatMessage({ id: 'dataInicio' })}
        valueField="dataInicio"
        valueFunction={this.renderDataInicio}
      />,
      <ListColumn
        headerText={intl.formatMessage({ id: 'dataTermino' })}
        valueField="dataTermino"
        valueFunction={this.renderDataTermino}
      />,
    ];

    const entitiesKey = intl.formatMessage({ id: 'desdobramentos' });

    const infoMessage = `${intl.formatMessage({ id: 'carregando' })}...`;

    const handleSendEmail = (filter, searchText, sort) => {
      ContentManager.addContent(
        <Email
          url={endpoint}
          model={{
            searchText,
            ...filter?.valor,
          }}
          sort={sort}
        />
      );
    };

    const exportActions = (filter = {}, searchText = '', sort = '') => {
      return [
        <DropdownItem onClick={() => handleSendEmail(filter, searchText, sort)}>
          {intl.formatMessage({ id: 'enviarEmail' })}
        </DropdownItem>,
      ];
    };

    return (
      <NewListTree
        endpoint={endpoint}
        defaultColumns={defaultColumns}
        editComponent={DesdobramentoEdit}
        sortField="UltimaVersao.Descricao"
        searchComponent={<Search />}
        searchSchema={SearchSchema}
        showSearch={true}
        columnsTag={1005}
        filterTag={50}
        infoMessage={infoMessage}
        entitiesKey={entitiesKey}
        showNew
        handleNewClick={this.handleNew}
        showExport
        exportActions={exportActions}
        openLastFilter
        renderMenu={renderMenu}
        {...this.props}
      />
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(Desdobramento));
