import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../../componentes/dialog';
import { Schema, Form } from '../../../utils/form';
import http from 'src/services/httpService';
import LoadingContainer from '../../../componentes/loading-container';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import MdiIcon from '../../../componentes/mdi-icon';
import DropdownItemList from '../../item/dropdown-item';
import { capitalizeFirstLetter } from '../../../utils/string';
import errorHandler from '../../../utils/error-handler';
import PanelCollapse from 'src/componentes/panel-collapse';
import Avatar from 'src/componentes/avatar';
import AvatarGroup from 'src/componentes/AvatarGroup';

let schema = Schema.object({
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  area: Schema.string().label(<FormattedMessage id="area" />),
  meta: Schema.number().label(
    <FormattedMessage id="label.metaUltimoPeriodo" />
  ),
  realizado: Schema.number().label(
    <FormattedMessage id="label.realizadoUltimoPeriodo" />
  ),
  metaAcumulado: Schema.number().label(),
  realizadoAcumulado: Schema.number().label(
    <FormattedMessage id="realizadoAcumulado" />
  ),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  melhor: Schema.string().label(<FormattedMessage id="label.polaridade" />),
  responsaveis: Schema.array().label(<FormattedMessage id="responsaveis" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  unidadeMedida: Schema.string().label(
    <FormattedMessage id="unidadeDeMedida" />
  ),
  tabelaValor: Schema.string().label(<FormattedMessage id="listaRegistros" />),
  farol: Schema.string().label(''),
  farolAcumulado: Schema.string().label(''),
  exibirValores: Schema.string().label(''),
  exibirCamposCustomizados: Schema.string().label(''),
});

@autobind
class FichaParametroBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.form = new Form({
      component: this,
      schema: schema,
    });
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({
      isLoading: true,
    });
    //http.post(`/Parametro/ObterDadosFicha`, {
    http
      .post(this.props.obterDadosFichaUrl, {
        itemId: this.props.id,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.form.setValue(null, response.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        this.handleClose();
        errorHandler(error);
      });
  }

  handleClose() {
    this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
    }, 300);
  }

  handleMenuClick() {
    this.handleClose();
  }

  handleDesenharCampoCustomizado(item) {
    const visible = item.campo.visivel == null || item.campo.visivel;

    if (!visible) return;

    if (item.campo.tipo.id !== 5) {
      return (
        <Row className="mb-3">
          <Col md={3} className="font-weight-bolder">
            {item.campo.nome}
          </Col>
          <Col md={9}>{item.valor}</Col>
        </Row>
      );
    }
    if (item.campo.tipo.id === 5) {
      if (item.campo.multiSelecao) {
        return (
          <Row className="mb-3 d-flex align-items-center">
            <Col md={3} className="font-weight-bolder">
              {item.campo.nome}
            </Col>
            <Col md={9}>
              {item.campo.valores &&
                item.campo.valores.map(({ id, descricao }) => (
                  <div>{descricao}</div>
                ))}
            </Col>
          </Row>
        );
      } else {
        let value =
          item.campo.opcoesTabela &&
          item.campo.opcoesTabela.find((op) => op.id == item.valor)
            ? item.campo.opcoesTabela.find((op) => op.id == item.valor)
            : null;

        return (
          <Row className="mb-3">
            <Col md={3} className="font-weight-bolder">
              {item.campo.nome}
            </Col>
            <Col md={9}>{value?.descricao}</Col>
          </Row>
        );
      }
    }
  }

  render() {
    let model = this.form.getModel();
    let { resources, intl, showActions = true } = this.props;

    let actions = (
      <UncontrolledDropdown className="ml-2 d-inline" key="indicador-ficha">
        <DropdownToggle color="secondary">
          <MdiIcon icon="dots-horizontal" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItemList
            exibirFicha={false}
            resources={resources}
            item={{ ...model.getValue(), tipo: { id: 2 } }}
            callback={this.handleMenuClick}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    return (
      <Dialog
        title={
          model.getModel('nome').value ? (
            model.getModel('nome').value
          ) : (
            <FormattedMessage
              id="label.fichaParametro"
              values={{ ficha: resources.ficha }}
            />
          )
        }
        ref="dialog"
        padContent
        footer={showActions && actions}
        width={900}
        height={450}
        onRequestClose={this.handleClose}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <div className="pb-3">
            <PanelCollapse
              open={false}
              header={capitalizeFirstLetter(
                intl.formatMessage({ id: 'dadosCadastro' })
              )}
            >
              <Row>
                <Col md={3} className="font-weight-bolder">
                  {model.getModel('idIntegracao').label}
                </Col>
                <Col md={9}>{model.getModel('idIntegracao').value}</Col>
              </Row>
              <Row className="my-3">
                <Col md={3} className="font-weight-bolder">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'unidadeDeMedida' })
                  )}
                </Col>
                <Col md={9}>
                  {model.getModel('unidadeMedida').value?.descricao}
                </Col>
              </Row>
              <Row className="my-3">
                <Col md={3} className="font-weight-bolder">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'periodicidade' })
                  )}
                </Col>
                <Col md={9}>
                  {model.getModel('periodicidade').value?.descricao}
                </Col>
              </Row>
              <Row className="my-3 d-flex align-items-center">
                <Col md={3} className="font-weight-bolder">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'responsaveis' })
                  )}
                </Col>
                <Col md={9}>
                  {model.getModel('responsaveis').value && (
                    <AvatarGroup max={15}>
                      {model.getModel('responsaveis').value.map((item) => (
                        <Avatar
                          showTooltip
                          id={`avatar-${item.id}`}
                          foto={item.fotoArquivo}
                          label={item.nome}
                        />
                      ))}
                    </AvatarGroup>
                  )}
                </Col>
              </Row>

              {model.getModel('utilizarDigitadorValor').value && (
                <Row className="my-3 d-flex align-items-center">
                  <Col md={3} className="font-weight-bolder">
                    {capitalizeFirstLetter(resources.digitadores)}
                  </Col>
                  <Col md={9}>
                    {model.getModel('digitadores').value && (
                      <AvatarGroup max={15}>
                        {model.getModel('digitadores').value.map((item) => (
                          <Avatar
                            showTooltip
                            id={`avatar-${item.id}`}
                            foto={item.fotoArquivo}
                            label={item.nome}
                          />
                        ))}
                      </AvatarGroup>
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={3} className="font-weight-bolder">
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'descricao' })
                  )}
                </Col>
                <Col md={9}>{model.getModel('descricao').value}</Col>
              </Row>
            </PanelCollapse>
            {model.getModel('exibirValores').value && (
              <PanelCollapse
                open={false}
                header={capitalizeFirstLetter(
                  intl.formatMessage({ id: 'valoresNumericos' })
                )}
              >
                <Row className="my-3">
                  <Col md={3} className="font-weight-bolder">
                    {capitalizeFirstLetter(
                      intl.formatMessage({ id: 'label.realizadoUltimoPeriodo' })
                    )}
                  </Col>
                  <Col md={3}>{model.getModel('realizado').value}</Col>
                </Row>
                <Row>
                  <Col md={3} className="font-weight-bolder">
                    {capitalizeFirstLetter(
                      intl.formatMessage({ id: 'realizadoAcumulado' })
                    )}
                  </Col>
                  <Col md={3}>{model.getModel('realizadoAcumulado').value}</Col>
                </Row>
              </PanelCollapse>
            )}
            {model.getModel('exibirCamposCustomizados').value && (
              <PanelCollapse
                open={false}
                header={capitalizeFirstLetter(
                  intl.formatMessage({ id: 'camposCustomizados' })
                )}
              >
                {model.getModel('camposCustomizados').value &&
                  model
                    .getModel('camposCustomizados')
                    .value.map((item) =>
                      this.handleDesenharCampoCustomizado(item)
                    )}
              </PanelCollapse>
            )}
          </div>
        </LoadingContainer>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(FichaParametroBase));
