import { getValueOnPath, setValueOnPath } from './accessors';

export default class Form {
  constructor({ component, name = 'form', schema, model = {}, resources }) {
    this.component = component;
    this.name = name;
    this.schema = schema;
    this.resources = resources;
    this.setState({
      model: model,
      errors: [],
      modifiedPaths: [],
      showAllErrors: false,
    });
    this.validate(true);
    this.initialized = true;
  }

  isValid() {
    return this.component.state[this.name].errors.length == 0;
  }

  handleErrorRequired(){
    this.setValue('handleErrorRequired', '1')
  }

  getErrorMessage(path) {
    if (this.component.state[this.name].errors)
      return this.component.state[this.name].errors
        .filter((e) => e.path == path)
        .map((e) => e.message + '.')
        .join(' ');
  }

  getValue(path) {
    return getValueOnPath(path, this.component.state[this.name].model);
  }

  setValue(path, value) {
    var newModifiedPaths = this.component.state[this.name].modifiedPaths;
    if (path && !newModifiedPaths.find((f) => f[path])) {
      newModifiedPaths.push({ [path]: true });
    }

    this.setState({
      model: setValueOnPath(path, this.component.state[this.name].model, value),
      modifiedPaths: newModifiedPaths,
    });

    this.validate();
  }

  setValue2(path, value) {
    this.setState({
      model: setValueOnPath(path, this.component.state[this.name].model, value),
      modifiedPaths: {
        ...this.component.state[this.name].modifiedPaths,
        [path]: true,
      },
    });

    this.validate();

    return setValueOnPath(path, this.component.state[this.name].model, value);
  }

  hideAllErrors() {
    this.setState({
      showAllErrors: false,
      modifiedPaths: [],
    });
  }

  showAllErrors() {
    this.setState({
      showAllErrors: true,
    });
  }

  validate() {
    if (this.schema) {
      const errors = this.schema.validate(this.component.state[this.name].model);
      this.setState({ errors });
    }
  }

  getModifiedPaths() {
    return this.component.state[this.name].modifiedPaths;
  }

  getModel(path, interceptor) {
    let form = this;

    let schema = this.schema && this.schema.getSchema(path);

    return {
      path: path,
      value: form.getValue(path),
      metadata: {},
      label: schema && (schema._resource ? (this.resources ? this.resources[schema._resource] : schema._label) : schema._label),
      placeholder: schema && schema._placeholder,
      errors: (this.component.state[this.name].showAllErrors || this.component.state[this.name].modifiedPaths[path]) && this.getErrorMessage(path),
      getResource: (resource) => this.resources && this.resources[resource],
      getValue: (subPath) => form.getValue(path ? path + '.' + subPath : subPath),
      getModel: (subPath, interceptor) => form.getModel(path ? path + '.' + subPath : subPath, interceptor),
      getModels: (predicate) => {
        let value = form.getValue(path);
        if (!value) {
          return [];
        }

        let result = [];

        for (let i = 0; i < value.length; i++) {
          if (!predicate || predicate(value[i], i)) {
            result.push(form.getModel(path ? path + '.' + i : i));
          }
        }

        return result;
      },
      requestChange: (value) => {
        if (interceptor) {
          interceptor(value);
        }
        form.setValue(path, value);
      },
    };
  }

  setState(state) {
    let newState = {
      ...(this.component.state && this.component.state[this.name]),
      ...state,
    };

    if (this.initialized) {
      this.component.setState({
        [this.name]: newState,
      });
    } else {
      this.component.state = {
        ...this.component.state,
        [this.name]: newState,
      };
    }
  }
}
