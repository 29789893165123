import React from 'react';
import { autobind } from 'core-decorators';
import Edit from '../item/edit';
import ContentManager from '../../componentes/content-manager';
import Schema from './schema';
import { connect } from 'react-redux';
import qs from 'query-string';
import CriticalValidacaoRestricao from '../item/critical-validacao-restricao';

@autobind
class IndicadorBaseEdit extends React.Component {
  static defaultProps = {
    showDelete: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleSaved(data) {
    this.setState({ id: data.id });
  }

  handleDeleteClick(item, urlList) {
    const commonProps = {
      url: this.props.urlValidarRestricoes,
      urlExclusao: this.props.urlAtualizarStatusExcluido,
      item: item,
      onClose: this.handleClose,
      isEdit: true,
    };

    if (urlList) {
      commonProps.urlList = urlList;
    }

    ContentManager.addContent(<CriticalValidacaoRestricao {...commonProps} />);
  }

  render() {
    let {
      showDelete,
      showPrompt,
      resources,
      urlList,
      urlEdit,
      urlController,
      tipoItemId,
      id,
      exibirCorDoFarol,
    } = this.props;

    let activeTab =
      this.props.location && qs.parse(this.props.location.search).activeTab;

    return (
      <div>
        <Edit
          urlList={urlList}
          urlEdit={urlEdit}
          url={urlController}
          parameters={{ tipoId: tipoItemId }}
          titleField="nome"
          title={this.props.title}
          schema={Schema}
          handleSaved={this.handleSaved}
          activeTab={activeTab}
          id={id}
          tipoId={tipoItemId}
          showDelete={showDelete}
          onDeleting={this.handleDeleteClick}
          resources={resources}
          exibirCorDoFarol={exibirCorDoFarol}
          location={this.props.location}
          showPrompt={showPrompt}
        ></Edit>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(IndicadorBaseEdit);
