import React from 'react';
import Edit from '../../componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import Input from '../../componentes/input';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';

let schema = Schema.object({
  descricao: Schema.string()
    .label(<FormattedMessage id="descricao" />)
    .required(),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="idIntegracao" />)
    .required(),
  observacao: Schema.string().label(<FormattedMessage id="observacao" />),
});

@autobind
class FormEdit extends React.Component {
  render() {
    let { model, resources } = this.props;

    return (
      <div>
        <Row>
          <Col md={12}>
            <Input model={model.getModel('descricao')} required />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Input model={model.getModel('idIntegracao')} required />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              type="textarea"
              rows={5}
              model={{
                label: capitalizeFirstLetter(resources.observacao),
                value: model.getModel('observacao').value,
                requestChange: model.getModel('observacao').requestChange,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class ParteInteressadaEdit extends React.Component {
  render() {
    let { resources } = this.props;
    return (
      <Edit
        url="/ParteInteressada"
        title={capitalizeFirstLetter(resources.partesinteressadas)}
        formComponent={FormEdit}
        schema={schema}
        resources={resources}
        width={600}
        height={450}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ParteInteressadaEdit));
