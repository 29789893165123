import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import CausaRiscoEdit from '../paginas/gestao-risco/causas/edit';

@autobind
class CausaRisco extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/CausaRiscoLookup" editComponent={CausaRiscoEdit} {...this.props} />;
  }
}

export default CausaRisco;
