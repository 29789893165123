import React from 'react';

import HorizontalArrow from './horizontal-arrow';
import MultilineText from './multiline-text';
import { configs } from './root-arrow';

export default class VerticalArrow extends React.Component {
  static measureLabel(props) {
    return MultilineText.measure({
      width: 120,
      text: (props.item && props.item.descricao) || '',
      fontSize: configs.getFontSize(props.level),
      fontFamily: configs.fontFamily,
    });
  }

  static measureLegs(props) {
    let item = props.item;
    let level = props.level;
    let width = 0;
    let height = 0;

    if (item.itens) {
      if (item.itens.length > 0) {
        for (let i = 0; i < item.itens.length; i++) {
          let subItem = item.itens[i];
          let subSize = HorizontalArrow.measure({
            item: subItem,
            level: level + 1,
          });
          width = Math.max(width, subSize.width);
          height +=
            subSize.height +
            (i < item.itens.length - 1 ? configs.getLegsGap(level) : 0);
        }
      } else {
        height = 26;
        width = 24;
      }
    }

    return { width, height };
  }

  static measure(props) {
    let level = props.level;
    let labelSize = VerticalArrow.measureLabel(props);
    let legsSize = VerticalArrow.measureLegs(props);
    return {
      width: Math.max(labelSize.width, legsSize.width),
      height:
        labelSize.height +
        configs.getLabelGap(level) +
        configs.getArrowPadding(level) * 2 +
        legsSize.height,
    };
  }

  static calculateDisplacement(y) {
    return Math.abs(y * Math.sin((30 * Math.PI) / 180));
  }

  render() {
    let {
      item,
      x,
      y,
      width,
      height,
      level,
      reverse,
      handleSaveCausaFundamental,
      widgetId,
    } = this.props;

    let labelMetrics = VerticalArrow.measureLabel(this.props);

    let children = [];

    let lineY1, lineY2, textY;

    if (reverse) {
      lineY1 = height - labelMetrics.height - configs.getLabelGap(level);
      lineY2 = configs.getMarkerSize(level);
      textY = height - labelMetrics.height;
    } else {
      lineY1 = -height + labelMetrics.height + configs.getLabelGap(level);
      lineY2 = -configs.getMarkerSize(level);
      textY = -height + labelMetrics.height;
    }

    if (item.itens) {
      let lineHeight = Math.abs(lineY2 - lineY1) + configs.getMarkerSize(level);
      let legsSize = VerticalArrow.measureLegs(this.props);
      let legsOffsetY = Math.abs(lineHeight - legsSize.height) / 2;

      let legY = reverse
        ? legsOffsetY
        : -height +
          labelMetrics.height +
          configs.getLabelGap(level) +
          legsOffsetY;

      for (let i = 0; i < item.itens.length; i++) {
        let subItem = item.itens[i];
        let subItemSize = HorizontalArrow.measure({
          item: subItem,
          level: level + 1,
        });
        let subitemY = legY + subItemSize.height / 2;

        children.push(
          <HorizontalArrow
            key={i}
            item={subItem}
            x={width - VerticalArrow.calculateDisplacement(subitemY)}
            y={subitemY}
            width={width}
            height={subItemSize.height}
            level={level + 1}
            handleSaveCausaFundamental={handleSaveCausaFundamental}
            guid={item.guid}
            ehCausaFundamental={item.causaFundamental}
            widgetId={widgetId}
          />
        );

        legY +=
          subItemSize.height +
          (i < item.itens.length - 1 ? configs.getLegsGap(level) : 0);
      }
    }

    return (
      <g transform={`translate(${x}, ${y})`}>
        {children}

        <line
          x1={width - VerticalArrow.calculateDisplacement(lineY1)}
          y1={lineY1}
          x2={width - VerticalArrow.calculateDisplacement(lineY2)}
          y2={lineY2}
          strokeWidth={configs.getStrokeWidth(level)}
          stroke="black"
          markerEnd="url(#head)"
        />

        <MultilineText
          x={width - VerticalArrow.calculateDisplacement(textY)}
          y={textY}
          width={labelMetrics.width}
          height={labelMetrics.height}
          text={item.descricao || ''}
          fontFamily={configs.fontFamily}
          fontSize={configs.getFontSize(level)}
          horizontalAlign="right"
          verticalAlign={reverse ? 'top' : 'bottom'}
          handleSaveCausaFundamental={handleSaveCausaFundamental}
          guid={item.guid}
          ehCausaFundamental={item.causaFundamental}
          widgetId={widgetId}
        />
      </g>
    );
  }
}
