import React, { useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import http from 'src/services/httpService';

import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import DatePicker from 'src/componentes/date-picker';
import LoadingContainer from 'src/componentes/loading-container';
import Button from 'src/componentes/button';
import ContentManager from 'src/componentes/content-manager';
import Alert from 'src/componentes/message-box/alert';
import Info from 'src/componentes/info';

import { capitalizeFirstLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';
import ItemLink from 'src/paginas/item/link';
import GraficoValores from 'src/paginas/item/valor/componentes/grafico';
import PlanejamentoContexto from './components/index';
import ItemLookup from 'src/lookups/item';
import Input from 'src/componentes/input';

const EditForm = ({ model, parameters }) => {
  const { terms: resources } = useAuth();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = () => {
    setIsLoading(true);

    http
      .post(`/PlanejamentoInicial/Save`, {
        model: model && model.value,
        parameters: parameters,
      })
      .then(() => {
        setIsLoading(false);
        ContentManager.addContent(
          <Alert
            title={intl.formatMessage({ id: 'sucesso' })}
            message={intl.formatMessage({
              id: 'label.registroSalvoComSucesso',
            })}
          />
        );
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error);
      });
  };

  const isNew = model.getModel('id').value === 0;
  let grafico = model.getModel('grafico').value;
  let item = model.getModel('item').value;

  const allowEdit =
    model.getModel('permissions') && model.getModel('permissions').value != null
      ? model.getModel('permissions').value.allowEdit
      : false;

  return (
    <LoadingContainer isLoading={isLoading}>
      <Tabs>
        <Tab key="1" label={<FormattedMessage id="label.resumo" />}>
          <Row>
            {parameters && parameters.isListGeral ? (
              <Col md={3}>
                <ItemLookup
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'item' })
                    ),
                    value: model.getModel('item').value,
                    requestChange: model.getModel('item').requestChange,
                  }}
                  data={{ TipoId: 1 }}
                />
              </Col>
            ) : (
              <Col md={3}>
                <span>
                  <b>
                    <FormattedMessage id="item" />:{' '}
                  </b>
                </span>
                <ItemLink model={model.getModel('item')}></ItemLink>
              </Col>
            )}
            <Col>
              <Input
                style={{ height: '36px' }}
                model={{
                  value: model.getModel('problema').value,
                  label: (
                    <>
                      {intl.formatMessage({ id: 'problema' })}
                      <Info
                        className="ml-2"
                        style={{ marginTop: 33 }}
                        tooltipTextAlign="left"
                        tooltipMaxWidth={500}
                        id="msgProblemaPlanejamento"
                        placement="right"
                        text={intl.formatMessage({
                          id: 'msgSalvarProblemaPlanejamentoInicial',
                        })}
                      />
                    </>
                  ),
                  requestChange: model.getModel('problema').requestChange,
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col md={2}>
              <DatePicker
                year
                timeFormat={false}
                model={{
                  label: intl.formatMessage({ id: 'periodo' }),
                  value: model.getModel('dataPeriodo').value,
                  requestChange: model.getModel('dataPeriodo').requestChange,
                }}
                disabled={!isNew}
                height={39}
              />
            </Col>
            <Col md={2}>
              <div className="w-100 d-flex align-items-center">
                <Button
                  style={{ marginTop: 33 }}
                  className="ml-2"
                  onClick={() => handleSave()}
                  disabled={!allowEdit || !model.getModel('item').value}
                >
                  <FormattedMessage id="label.salvar" />
                </Button>
                <Info
                  className="ml-2"
                  style={{ marginTop: 33 }}
                  tooltipTextAlign="left"
                  tooltipMaxWidth={500}
                  id="msgPeriodoPlanejamento"
                  placement="right"
                  text={intl.formatMessage({
                    id: 'msgSalvarPrimeiroPeriodoPlanejamentoInicial',
                  })}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span>
                <FormattedMessage id="status" />:
              </span>{' '}
              <span className="font-weight-bolder">
                {model.getModel('status').value &&
                  model.getModel('status').value.descricao}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {grafico && item && (
                <div id="graficoPlanejamentoInicialPag">
                  <GraficoValores
                    item={item}
                    grafico={grafico}
                    tituloPersonalizado={{
                      titulo: '',
                      polaridade: item && !item.usarTabelaValores,
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row></Row>
        </Tab>
        {!isNew && (
          <Tab
            key="2"
            label={capitalizeFirstLetter(
              intl.formatMessage({ id: 'planejamento' })
            )}
          >
            {' '}
            {item && (
              <PlanejamentoContexto
                model={model}
                item={item}
                planejamentoInicialId={model.getModel('id').value}
                statusID={model.getModel('status').value.id}
              />
            )}
          </Tab>
        )}
      </Tabs>
    </LoadingContainer>
  );
};

export default EditForm;
