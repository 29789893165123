import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';

import ItemLookup from 'src/lookups/item';
import StatusPlanejamentoInicial from 'src/lookups/StatusPlanejamentoInicial';
import DatePicker from 'src/componentes/date-picker';

@autobind
class PlanejamentoInicialSearch extends React.Component {
  render() {
    let { model, intl } = this.props;

    return (
      <div>
        <Row>
          <Col md={2}>
            <DatePicker
              year
              timeFormat={false}
              model={{
                label: intl.formatMessage({ id: 'periodo' }),
                value: model.getModel('dataPeriodo').value,
                requestChange: model.getModel('dataPeriodo').requestChange,
              }}
              height={39}
            />
          </Col>
          <Col md={4}>
            <StatusPlanejamentoInicial
              clearable={true}
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'status' })
                ),
                value: model.getModel('status').value,
                requestChange: model.getModel('status').requestChange,
              }}
            />
          </Col>
          <Col md={6}>
            <ItemLookup
              clearable={true}
              model={{
                label: capitalizeFirstLetter(
                  intl.formatMessage({ id: 'item' })
                ),
                value: model.getModel('item').value,
                requestChange: model.getModel('item').requestChange,
              }}
              data={{ TipoId: 1 }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(PlanejamentoInicialSearch));
