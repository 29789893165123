import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

import Panel from 'src/componentes/panel-collapse';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';

import Edit from './IdiomasEditView';

function Idiomas({ disabled, model, ...rest }) {
  const intl = useIntl();

  const handleRemove = (item, index) => {
    model.value.splice(index, 1);
    model.requestChange(model.value);
  };

  const handleSaveItem = (saved, isNew, item, index) => {
    if (saved) {
      let novaLista = model.value;

      if (isNew) {
        if (novaLista && novaLista.length > 0) novaLista.push(item);
        else novaLista = [].concat(item);
      } else novaLista[index] = item;

      model.requestChange(novaLista);
    }
  };

  const renderIdioma = (item) => <span>{item.lingua && item.lingua.nome}</span>;
  const renderNivelProficiencia = (item) => <span>{item.nivelProficiencia && item.nivelProficiencia.nome}</span>;

  return (
    <Panel open={false} header={<FormattedMessage id="idiomas" />}>
      <List
        local
        showTitle={false}
        dataSource={model.value}
        actionsWidth={30}
        handleSaveItem={handleSaveItem}
        handleRemove={handleRemove}
        editComponent={Edit}
        showPage={false}
        showSearch={false}
        showNew={!disabled}
        {...rest}
      >
        <ListColumn headerText={intl.formatMessage({ id: 'idioma' })} valueFunction={renderIdioma}></ListColumn>
        <ListColumn headerText={intl.formatMessage({ id: 'nivelProficiencia' })} valueFunction={renderNivelProficiencia}></ListColumn>
      </List>
    </Panel>
  );
}

export default Idiomas;
