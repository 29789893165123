import { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';
import { capitalizeFirstLetter } from 'src/utils/string';
import LinguaLookup from 'src/lookups/LinguaLookup';
import NivelProficienciaLookup from 'src/lookups/NivelProficienciaLookup';
import Input from 'src/componentes/input';

function FormacoesAcademicasEditView({ handleClose, item, indexItem, ...rest }) {
  const intl = useIntl();
  const modalRef = useRef();
  const [currentItem, setCurrentItem] = useState(item);
  const [isNew] = useState(item == null);

  const handleCurrentClose = (isSaved = false) => {
    modalRef.current.close();
    setTimeout(() => {
      handleClose && handleClose(isSaved, isNew, currentItem, indexItem);
    }, 300);
  };

  const handleChange = (field, value) => {
    setCurrentItem((prev) => ({
      ...prev,
      permissions: {
        allowEdit: true,
        allowDelete: true,
      },
      [field]: value,
    }));
  };

  const handleCloseClick = () => {
    handleCurrentClose();
  };

  const handleSave = () => {
    handleCurrentClose(true);
  };

  const isDisabled = () => !currentItem || !currentItem.lingua || !currentItem.nivelProficiencia;

  const editActions = [
    <Button key={1} onClick={handleCloseClick} color="secondary">
      {<FormattedMessage id="label.cancelar" />}
    </Button>,
    <Button key={2} disabled={isDisabled()} type="primary" onClick={handleSave} htmlType="submit" className="ml-2">
      {<FormattedMessage id="label.salvar" />}
    </Button>,
  ];

  return (
    <Dialog
      id="idioma-edit"
      ref={modalRef}
      width={650}
      minHeight={250}
      title={capitalizeFirstLetter(intl.formatMessage({ id: 'idioma' }))}
      padContent
      actions={editActions}
      onRequestClose={handleCurrentClose}
      {...rest}
    >
      <Row>
        <Col md={6}>
          <LinguaLookup
            required
            model={{
              label: <FormattedMessage id="idioma" />,
              value: currentItem && currentItem.lingua,
              requestChange: (e) => handleChange('lingua', e),
            }}
          />
        </Col>
        <Col md={6}>
          <NivelProficienciaLookup
            required
            model={{
              label: <FormattedMessage id="nivelProficiencia" />,
              value: currentItem && currentItem.nivelProficiencia,
              requestChange: (e) => handleChange('nivelProficiencia', e),
            }}
          />
        </Col>
      </Row>
    </Dialog>
  );
}

export default FormacoesAcademicasEditView;
