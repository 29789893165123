import { Schema } from '../../../utils/form';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default Schema.object({
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  descricao: Schema.string().label(<FormattedMessage id="descricao" />),
  tipoPontoControle: Schema.string().label(''),
  responsaveis: Schema.string().label(''),
  periodicidade: Schema.string().label(<FormattedMessage id="periodicidade" />),
  observacao: Schema.array(),
  utilizarObservacao: Schema.array(),
  anexoObrigatorio: Schema.string().label(<FormattedMessage id="anexoObrigatorio" />),
  tipoTolerancia: Schema.string().label(<FormattedMessage id="tipoTolerancia" />),
  diasToleranciaRevisao: Schema.string().label(<FormattedMessage id="tolerancia" />),
  numeroRevisoesGeradasAutomaticamente: Schema.string().label(<FormattedMessage id="numeroRevisoesGeradasAutomaticamente" />),
  statusControlSelfAssessment: Schema.string().label(<FormattedMessage id="statusCSA" />),
  utilizarTipoEvidencia: Schema.string(),
  tipoEvidencias: Schema.string().label(<FormattedMessage id="tipoEvidencias" />),
  area: Schema.string(),
  camposCustomizados: Schema.array(),
});
