import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Panel from 'src/componentes/panel-collapse';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import useAuth from 'src/hooks/useAuth';
import Edit from './ExperienciasAnterioresEditView';

function ExperienciasAnteriores({ disabled, model, ...rest }) {
  const intl = useIntl();
  const { terms: resources } = useAuth();

  const handleRemove = (item, index) => {
    model.value.splice(index, 1);
    model.requestChange(model.value);
  };

  const handleSaveItem = (saved, isNew, item, index) => {
    if (saved) {
      let novaLista = model.value;

      if (isNew) {
        if (novaLista && novaLista.length > 0) novaLista.push(item);
        else novaLista = [].concat(item);
      } else novaLista[index] = item;

      model.requestChange(novaLista);
    }
  };

  const renderData = (field, item) => {
    if (!item[field]) return;

    return <span>{moment(item[field]).format('DD/MM/YYYY')}</span>;
  };

  return (
    <Panel
      open={false}
      header={<FormattedMessage id="experienciasAnteriores" />}
    >
      <List
        local
        showTitle={false}
        dataSource={model.value}
        actionsWidth={30}
        handleSaveItem={handleSaveItem}
        handleRemove={handleRemove}
        editComponent={Edit}
        showPage={false}
        showSearch={false}
        showNew={!disabled}
        {...rest}
      >
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'cargo' },
            { cargo: resources.cargo }
          )}
          valueField="cargo"
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'empresa' })}
          valueField="empresa"
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'dataInicio' })}
          valueFunction={(e) => renderData('dataInicio', e)}
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'dataTermino' })}
          valueFunction={(e) => renderData('dataTermino', e)}
        ></ListColumn>
      </List>
    </Panel>
  );
}

export default ExperienciasAnteriores;
