import React from 'react';
import { Row, Col } from 'reactstrap';
import Slider from 'src/paginas/remuneracao-variavel/pontos-faixa-resultado/slider';

const CadastroContent = ({ model, resources, intl }) => {
  const tipoPontos =
    model.value.pontoFaixaResultado.tipoPontoFaixaResultado?.descricao;
  const valorMaximo = model.value.valorMaximoFormatado;
  const valorMinimo = model.value.valorMinimoFormatado;
  const metaDesafio = model.value.metaDesafioFormatado;

  return (
    <>
      <Row>
        <Col md={12}>
          <Slider
            disabled
            model={model.getModel('faixas')}
            interpolarValoresFaixa={
              model.getModel('pontoFaixaResultado').value?.interpolarValoresFaixa
            }
            tipoPontoFaixaResultado={
              model.getModel('pontoFaixaResultado').value
                ?.tipoPontoFaixaResultado
            }
          />
        </Col>
      </Row>
      <Row>
        <div>
          {`${intl.formatMessage({
            id: 'tipo',
          })}: ${tipoPontos}`}
          {valorMinimo != null &&
            ` | ${intl.formatMessage({ id: 'minimo' })}: ${valorMinimo}`}
          {valorMaximo != null &&
            ` | ${intl.formatMessage({ id: 'maximo' })}: ${valorMaximo}`}
          {metaDesafio != null &&
            ` | ${intl.formatMessage(
              { id: 'metaDesafio' },
              {
                meta: resources.meta,
              }
            )}: ${metaDesafio}`}
        </div>
      </Row>
    </>
  );
};

export default CadastroContent;
