import http from 'src/services/httpService';
import httpClienteExterno from 'src/services/httpExternalService';

const httpSelector = {
  getHttp(mode) {
    if (mode === 'viewExterno') return httpClienteExterno;

    return http;
  },
};

export default httpSelector;
