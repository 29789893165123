const distinctBy = (data, key) => {
  const newArray = [];
  data?.forEach((item) => {
    const i = newArray.findIndex((x) => x[key] === item[key]);
    if (i <= -1) {
      newArray.push(item);
    }
  });

  return newArray;
};

export default distinctBy;
