import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import Alert from '../../../../componentes/message-box/alert';
import ContentManager from '../../../../componentes/content-manager';
import Button from '../../../../componentes/button';
import Dialog from '../../../../componentes/dialog';
import PeriodicidadeLookup from '../../../../lookups/periodicidade';
import DatePicker from '../../../../componentes/date-picker';
import Checkbox from '../../../../componentes/checkbox';
import { Row, Col, ButtonToolbar } from 'reactstrap';
import useAuth from 'src/hooks/useAuth';
import ConfirmNew from 'src/componentes/NewConfirm';

function RecorrenciaPeriodicidadeEdit({
  item,
  indexItem,
  handleClose,
  ...rest
}) {
  const { terms: resources } = useAuth();
  const defaultItem = {
    periodicidade: null,
    dataInicio: null,
    dataTermino: null,
    utilizarRecorrencia: false,
    excluido: false,
    editado: true,
    permissions: {
      allowEdit: true,
      allowDelete: true,
      allowView: true,
    },
  };

  const modalRef = useRef();
  const intl = useIntl();
  const [isNew] = useState(item == null ? true : false);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [currentItem, setCurrentItem] = useState(
    item == null ? defaultItem : item
  );

  const handleCurrentClose = (isSaved = false) => {
    modalRef.current.close();
    setTimeout(() => {
      handleClose && handleClose(isSaved, isNew, currentItem, indexItem);
    }, 300);
  };

  const handleCloseClick = () => {
    handleCurrentClose();
  };

  const handleSaveClick = () => {
    let error = false;
    let message = '';

    if (currentItem.dataInicio == null) {
      error = true;
      message = intl.formatMessage({ id: 'dataInicioItemObrigatoria' });
    } else if (
      currentItem.utilizarRecorrencia &&
      currentItem.dataTermino == null
    ) {
      error = true;
      message = intl.formatMessage({
        id: 'aoSelecionarUtilizarRecorrenciaDevemosSelecionarUmaDataTermino',
      });
    } else if (
      currentItem.utilizarRecorrencia &&
      currentItem.periodicidade == null
    ) {
      error = true;
      message = intl.formatMessage({
        id: 'aoSelecionarUtilizarRecorrenciaDevemosSelecionarUmaPeriodicidade',
      });
    }

    if (error) {
      ContentManager.addContent(
        <Alert title={<FormattedMessage id="erro" />} message={message} />
      );
    } else {
      handleCurrentClose(true);
    }
  };

  const handleMensagemAlteracaoDataTerminoPeriodicidade = (value) => {
    const dataTerminoAntiga = currentItem.dataTermino;

    ContentManager.addContent(
      <ConfirmNew
        showCancel={true}
        primaryMessage={intl.formatMessage(
          {
            id: 'msgConfirmacaoAlteracaoDataTerminoPeriodicidade',
          },
          {
            revisoesdecontrole: resources?.revisoesdecontrole?.toLowerCase(),
          }
        )}
        handleCancel={() =>
          setCurrentItem((current) => ({
            ...current,
            dataTermino: dataTerminoAntiga,
          }))
        }
        onSaved={() =>
          setCurrentItem((current) => ({
            ...current,
            dataTermino: value,
            editado: true,
          }))
        }
      />
    );
  };

  const handleChangeValue = (field, value) => {
    if (field === 'dataTermino') {
      if (value < currentItem.dataInicio) {
        ContentManager.addContent(
          <Alert
            message={
              <FormattedMessage id="label.dataTerminoDeveSerMaiorDataInicio" />
            }
          />
        );
      } else if (currentItem.id > 0 && currentItem.dataTermino != value) {
        handleMensagemAlteracaoDataTerminoPeriodicidade(value);
        setForceRefresh(forceRefresh + 1);
      } else {
        setCurrentItem((current) => ({
          ...current,
          [field]: value,
          editado: true,
        }));
      }

      if (currentItem.dataInicio == null)
        setCurrentItem((current) => ({
          ...current,
          [field]: currentItem.dataInicio,
        }));
    } else {
      setCurrentItem((current) => ({ ...current, [field]: value }));
    }
  };

  const editActions = [
    <ButtonToolbar key="toolbar-tela">
      <Button onClick={handleCloseClick} color="secondary">
        {<FormattedMessage id="label.cancelar" />}
      </Button>
      ,
      <Button
        type="primary"
        onClick={handleSaveClick}
        htmlType="submit"
        className="ml-2"
      >
        {<FormattedMessage id="label.salvar" />}
      </Button>
    </ButtonToolbar>,
  ];

  return (
    <Dialog
      id="recorrencia-edit"
      ref={modalRef}
      width="400px"
      minHeight="50%"
      title={<FormattedMessage id="recorrenciasPeriodicidade" />}
      padContent
      actions={editActions}
      {...rest}
    >
      <Row>
        <Col md={12}>
          <DatePicker
            disabled={!isNew}
            timeFormat={false}
            model={{
              label: intl.formatMessage({ id: 'dataInicio' }) + '*',
              value: currentItem && currentItem.dataInicio,
              requestChange: (e) => handleChangeValue('dataInicio', e),
            }}
          />
          <Checkbox
            disabled={!isNew}
            model={{
              label: <FormattedMessage id="utilizaRecorrencia" />,
              value: currentItem && currentItem.utilizarRecorrencia,
              requestChange: (e) => handleChangeValue('utilizarRecorrencia', e),
            }}
          />
          {currentItem && currentItem.utilizarRecorrencia && (
            <React.Fragment>
              <PeriodicidadeLookup
                disabled={!isNew}
                model={{
                  label: <FormattedMessage id="periodicidade" />,
                  value: currentItem && currentItem.periodicidade,
                  requestChange: (e) => handleChangeValue('periodicidade', e),
                }}
              />
              <DatePicker
                id="dataTermino"
                disabled={false}
                timeFormat={false}
                forceRefresh={forceRefresh}
                model={{
                  label: intl.formatMessage({ id: 'dataTermino' }) + '*',
                  value: currentItem.dataTermino,
                  requestChange: (e) => handleChangeValue('dataTermino', e),
                }}
              />
            </React.Fragment>
          )}
        </Col>
      </Row>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default connect(mapStateToProps)(RecorrenciaPeriodicidadeEdit);
