import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { capitalizeFirstLetter } from 'src/utils/string';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import DatePicker from 'src/componentes/date-picker';

@autobind
class ColaboradorFuncaoSearch extends React.Component {
  render() {
    let { model, resources } = this.props;

    return (
      <Row>
        <Col md={6}>
          <ColaboradorSimplificadoLookup
            model={{
              label: capitalizeFirstLetter(resources.colaborador),
              value: model.getModel('colaborador').value,
              requestChange: model.getModel('colaborador').requestChange,
            }}
          />
        </Col>
        <Col md={3}>
          <div style={{ marginBottom: 15 }}>
            <DatePicker model={model.getModel('dataInicio')} />
          </div>
        </Col>
        <Col md={3}>
          <div style={{ marginBottom: 15 }}>
            <DatePicker model={model.getModel('dataTermino')} />
          </div>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ColaboradorFuncaoSearch));
