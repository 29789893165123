import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { injectIntl } from 'react-intl';

import Table from 'src/componentes/table/index.jsx';
import ContentManager from 'src/componentes/content-manager';
import { capitalizeFirstLetter } from 'src/utils/string';

import ListRisco from 'src/paginas/gestao-risco/risco/RiscoListView';
import css from 'src/utils/css';

const classes = css`
  .verticalTxtTh {
    writing-mode: tb-rl;
    vertical-align: middle !important;
  }

  .fillAvaliableSpace {
    position: absolute;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: 0px !important;
  }

  .parentOfFillAvaliableSpace {
    position: relative;
  }

  .renderAllTxt {
    padding: 0px !important;
  }

  .centralizedInsideAbs {
    color: #000000 !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

class TabelaMatrizRisco extends React.Component {
  handleRiscoClick(riscoAvaliacoes, severidade, probabilidade, inerente) {
    const riscos =
      riscoAvaliacoes && inerente
        ? riscoAvaliacoes.filter(
            (a) => a.severidadeInerente && a.probabilidadeInerente && a.severidadeInerente.id == severidade.id && a.probabilidadeInerente.id == probabilidade.id
          )
        : riscoAvaliacoes.filter((a) => a.severidade.id == severidade.id && a.probabilidade.id == probabilidade.id);

    const { resources } = this.props;

    ContentManager.addContent(
      <ListRisco
        openLastFilter={true}
        usarFiltroSalvo={true}
        showModal={true}
        showNew={false}
        params={{ idsSelecionados: riscos.map(({ risco: { id } }) => id) }}
        title={capitalizeFirstLetter(resources.risco)}
      />
    );
  }

  getSelectedsIds(riscoAvaliacoes, severidade, probabilidade, inerente) {
    const riscos =
      riscoAvaliacoes && inerente
        ? riscoAvaliacoes.filter(
            (a) => a.severidadeInerente && a.severidadeInerente.id == severidade.id && a.probabilidadeInerente && a.probabilidadeInerente.id == probabilidade.id
          )
        : riscoAvaliacoes.filter((a) => a.severidade && a.severidade.id == severidade.id && a.probabilidade && a.probabilidade.id == probabilidade.id);

    return riscos.length == 0 ? [] : riscos.map(({ risco: { id } }) => id);
  }

  setFilter(riscoAvaliacoes, severidade, probabilidade, inerente) {
    const idsSelecionados = this.getSelectedsIds(riscoAvaliacoes, severidade, probabilidade, inerente);

    const cancelSelection = this.props.idsSelecionados && JSON.stringify(this.props.idsSelecionados.sort()) === JSON.stringify(idsSelecionados.sort());

    if (cancelSelection) {
      if (idsSelecionados.length !== 0) this.props.setFilter && this.props.setFilter(cancelSelection ? [] : idsSelecionados, {}, {});
    } else this.props.setFilter && this.props.setFilter(idsSelecionados, severidade, probabilidade);
  }

  rowSelected(riscoAvaliacoes, severidade, probabilidade, inerente) {
    if (!this.props.idsSelecionados || this.props.idsSelecionados.length === 0) return true;

    const idsRow = this.getSelectedsIds(riscoAvaliacoes, severidade, probabilidade, inerente);

    const result = this.props.idsSelecionados.some((x) => {
      if (idsRow.includes(x)) return true;
    });

    return result;
  }

  setPositionMouse(column, inerente) {
    this.props.setPositionMouse && this.props.setPositionMouse(column, inerente);
  }

  renderRowsInvertida(matrizFarol) {
    const { apenasMaiorQueZero, heightBase, isWidget, inerente } = this.props;
    const { probabilidades, severidades, itens, riscoAvaliacoes } = matrizFarol;
    const heightMatrizRisco = isWidget ? `${heightBase * 0.1}px` : '60px';

    return (
      severidades &&
      severidades.map((severidade, indexP) => (
        <tr key={indexP} style={{ height: heightMatrizRisco }}>
          <td
            className={`font-weight-bold text-center ${classes.renderAllTxt}`}
            style={{ padding: '0px 0px 0px 0.75rem', verticalAlign: 'middle' }}
          >
            {severidade.nome}
          </td>
          {probabilidades.map((probabilidade, indexS) => {
            const item = itens.find(
              (i) =>
                i.probabilidade.id === probabilidade.id &&
                i.severidade.id === severidade.id
            );

            if (item) {
              const totalItens = this.countOfRiscoAtScore(
                riscoAvaliacoes,
                severidade,
                probabilidade,
                inerente
              );
              const styleFadeOut = this.rowSelected(
                riscoAvaliacoes,
                severidade,
                probabilidade,
                inerente
              )
                ? this.props.idsSelecionados &&
                  this.props.idsSelecionados.length > 0
                  ? {
                      opacity: '1',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: 'black',
                    }
                  : {}
                : { opacity: '0.2' };

              return (
                <td
                  key={indexP + indexS}
                  style={{ padding: '0px' }}
                  className={`${classes.parentOfFillAvaliableSpace}`}
                >
                  <div
                    onClick={() =>
                      this.setFilter(
                        riscoAvaliacoes,
                        severidade,
                        probabilidade,
                        inerente
                      )
                    }
                    onMouseEnter={() =>
                      this.setPositionMouse(indexLinhaSeveridade, inerente)
                    }
                    onMouseLeave={() => this.setPositionMouse(-1)}
                    style={Object.assign(
                      { backgroundColor: item.cor },
                      styleFadeOut
                    )}
                    className={`text-center h5 ${classes.fillAvaliableSpace} ${
                      totalItens > 0 && 'text-hover-link'
                    }`}
                  >
                    {(!apenasMaiorQueZero ||
                      (apenasMaiorQueZero && totalItens > 0)) && (
                      <Badge
                        className={`${classes.centralizedInsideAbs}`}
                        color="light"
                        pill
                      >
                        {totalItens}
                      </Badge>
                    )}
                  </div>
                </td>
              );
            } else {
              return <td></td>;
            }
          })}
        </tr>
      ))
    );
  }

  countOfRiscoAtScore(riscoAvaliacoes, severidade, probabilidade, inerente) {
    if (inerente)
      return riscoAvaliacoes
        ? riscoAvaliacoes.filter(
            (a) => a.severidadeInerente && a.severidadeInerente.id == severidade.id && a.probabilidadeInerente && a.probabilidadeInerente.id == probabilidade.id
          ).length
        : 0;

    return riscoAvaliacoes ? riscoAvaliacoes.filter((a) => a.severidade.id == severidade.id && a.probabilidade.id == probabilidade.id).length : 0;
  }

  renderRows(matrizFarol, inverter) {
    if (inverter) return this.renderRowsInvertida(matrizFarol);

    const { apenasMaiorQueZero, isWidget, heightBase, inerente } = this.props;
    const { probabilidades, severidades, itens, riscoAvaliacoes } = matrizFarol;
    const heightMatrizRisco = isWidget ? `${heightBase * 0.1}px` : '60px';

    return (
      probabilidades &&
      probabilidades.map((probabilidade, indexP) => (
        <tr key={indexP} style={{ height: heightMatrizRisco }}>
          <td className={`font-weight-bold text-center ${classes.renderAllTxt}`} style={{ padding: '0px 0px 0px 0.75rem', verticalAlign: 'middle' }}>
            {probabilidade.nome}
          </td>
          {severidades.map((severidade, indexLinhaSeveridade) => {
            const item = itens.find((i) => i.probabilidade.id === probabilidade.id && i.severidade.id === severidade.id);

            if (item) {
              const totalItens = this.countOfRiscoAtScore(riscoAvaliacoes, severidade, probabilidade, inerente);
              const styleFadeOut = this.rowSelected(riscoAvaliacoes, severidade, probabilidade, inerente)
                ? this.props.idsSelecionados && this.props.idsSelecionados.length > 0
                  ? { opacity: '1', borderStyle: 'solid', borderWidth: '1px', borderColor: 'black' }
                  : {}
                : { opacity: '0.2' };

              return (
                <td key={indexP + indexLinhaSeveridade} style={{ padding: '0px' }} className={`${classes.parentOfFillAvaliableSpace}  cursor-pointer`}>
                  <div
                    onClick={() => this.setFilter(riscoAvaliacoes, severidade, probabilidade, inerente)}
                    onMouseEnter={() => this.setPositionMouse(indexLinhaSeveridade, inerente)}
                    onMouseLeave={() => this.setPositionMouse(-1)}
                    style={Object.assign({ backgroundColor: item.cor }, styleFadeOut)}
                    className={`text-center h5 ${classes.fillAvaliableSpace} ${totalItens > 0 && 'text-hover-link'}`}
                  >
                    {(!apenasMaiorQueZero || (apenasMaiorQueZero && totalItens > 0)) && (
                      <Badge className={`${classes.centralizedInsideAbs}`} color="light" pill>
                        {totalItens}
                      </Badge>
                    )}
                  </div>
                </td>
              );
            } else {
              return <td></td>;
            }
          })}
        </tr>
      ))
    );
  }

  renderHead(inverter, severidades, probabilidades, resources) {
    const { positionInerente, inerente, positionMouse } = this.props;

    if (inverter)
      return (
        <thead>
          <tr>
            <th></th>
            <th colSpan={probabilidades ? probabilidades.length : 0} className="text-center text-uppercase">
              {resources.probabilidade}
            </th>
          </tr>
          <tr>
            <th className={`text-uppercase text-center ${classes.verticalTxtTh}`}>{resources.severidade}</th>
            {probabilidades &&
              probabilidades.length > 0 &&
              probabilidades.map((probabilidade, index) => (
                <th
                  className={`text-center ${classes.verticalTxtTh}`}
                  key={index}
                  style={positionInerente === inerente && positionMouse >= 0 && positionMouse === index ? { backgroundColor: 'rgba(0, 0, 0, 0.075)' } : {}}
                >
                  {probabilidade.nome}
                </th>
              ))}
          </tr>
        </thead>
      );

    return (
      <thead>
        <tr>
          <th></th>
          <th colSpan={severidades ? severidades.length : 0} className="text-center text-uppercase">
            {resources.severidade}
          </th>
        </tr>
        <tr>
          <th className={`text-uppercase text-center ${classes.verticalTxtTh}`}>{resources.probabilidade}</th>
          {severidades &&
            severidades.length > 0 &&
            severidades.map((severidade, index) => (
              <th
                className={`text-center ${classes.verticalTxtTh}`}
                key={index}
                style={positionInerente === inerente && positionMouse >= 0 && positionMouse === index ? { backgroundColor: 'rgba(0, 0, 0, 0.075)' } : {}}
              >
                {severidade.nome}
              </th>
            ))}
        </tr>
      </thead>
    );
  }

  render() {
    const { resources, matrizFarol, styleDiv, header } = this.props;
    const { severidades, probabilidades, inverter } = matrizFarol;

    return (
      <div>
        {header && (
          <p colSpan={probabilidades ? probabilidades.length : 0} className="pt-3 text-center text-uppercase font-weight-bolder text-muted">
            {header}
          </p>
        )}
        <Table styleDiv={{ ...styleDiv }} style={{ tableLayout: 'fixed' }} responsive size="md" bordered>
          {this.renderHead(inverter, severidades, probabilidades, resources)}
          <tbody>{matrizFarol && this.renderRows(matrizFarol, inverter)}</tbody>
        </Table>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(TabelaMatrizRisco));
