import React from 'react';
import { autobind } from 'core-decorators';
import SvgIcon from '../../componentes/svg-icon';
import RelatorioAcompanhamento from '../relatorio-acompanhamento/edit';
import ContentManager from '../../componentes/content-manager';

@autobind
export default class Farol extends React.Component {
  static defaultProps = {
    acumulado: false,
    marginBottom: 0,
  };

  handleRelatorioAcompanhamento(itemValor) {
    if (itemValor.relatorioAcompanhamento != null) {
      if (itemValor.relatorioAcompanhamento.id != null) {
        ContentManager.addContent(
          <RelatorioAcompanhamento
            item={{ id: itemValor.relatorioAcompanhamento.id }}
            parameters={{ itemValorId: itemValor.id, itemId: itemValor.itemId }}
            handleClose={this.handleClose}
          ></RelatorioAcompanhamento>
        );
      }
    }
  }

  render() {
    let { itemValor, acumulado, marginBottom, ehRelacionamento } = this.props;
    return (
      <div>
        {!acumulado
          ? itemValor.farol &&
            itemValor.status &&
            itemValor.status.id === 1 && (
              <SvgIcon
                style={{ cursor: 'pointer', marginBottom: marginBottom }}
                title={itemValor.farol.legenda}
                icon={itemValor.farol.icone}
                color={itemValor.farol.cor}
                colorHover={itemValor.farol.cor}
                ehRelacionamento={ehRelacionamento}
                iconBadge={
                  itemValor.relatorioAcompanhamento && itemValor.relatorioAcompanhamento.status && itemValor.relatorioAcompanhamento.sinalizarRelatorioPreenchido
                    ? itemValor.relatorioAcompanhamento.status.icone
                    : null
                }
                onClick={this.handleRelatorioAcompanhamento.bind(this, itemValor)}
              ></SvgIcon>
            )
          : itemValor.farolAcumulado &&
            itemValor.status &&
            itemValor.status.id === 1 && (
              <SvgIcon
                style={{ cursor: 'pointer', marginBottom: marginBottom }}
                title={itemValor.farolAcumulado.legenda}
                icon={itemValor.farolAcumulado.icone}
                color={itemValor.farolAcumulado.cor}
                colorHover={itemValor.farolAcumulado.cor}
              ></SvgIcon>
            )}
      </div>
    );
  }
}
