import { Column, ColumnStore } from '@bryntum/gantt';

export default class SubTaskColumn extends Column {
  static get $name() {
    return 'addSubtask';
  }

  static get type() {
    return 'addSubtask';
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      field: 'addSubtask',
      exportable: false,
      editor: false,
      filterable: false,
      sortable: false,
    };
  }
}

ColumnStore.registerColumnType(SubTaskColumn);
