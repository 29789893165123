import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import Panel from 'src/componentes/panel';

import FormEdit from './edit';
@autobind
class FaixaMinMaxView extends React.Component {
  render() {
    let { intl, disabled } = this.props;

    return (
      <Panel header={intl.formatMessage({ id: 'label.faixaMinMax' })}>
        <List
          url="/ItemFaixaMinimoMaximo"
          editComponent={FormEdit}
          parameters={{ Alterado: false, itemId: this.props.itemId }}
          showSearch
          autoFocus={false}
          showExport={false}
          showNew={!disabled}
          showDelete={!disabled}
          showEdit={!disabled}
          disabled={disabled}
          podeEditar={!disabled}
          {...this.props}
          tag={12008}
        >
          <ListColumn
            headerText={intl.formatMessage({ id: 'idIntegracao' })}
            valueField="idIntegracao"
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'label.dataInicio' })}
            valueField="dataInicio"
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'label.dataTermino' })}
            valueField="dataTermino"
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'valorMinimo' })}
            valueField="valorMinimo"
            default
          />
          <ListColumn
            headerText={intl.formatMessage({ id: 'valorMaximo' })}
            valueField="valorMaximo"
            default
          />
        </List>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(FaixaMinMaxView));
