import { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import Input from 'src/componentes/input';

import CardCustom from 'src/componentes/panel-collapse';
import MatrizRiscoLookup from 'src/lookups/matriz-risco';
import Button from 'src/componentes/button';
import PeriodoData from 'src/paginas/data/periodo-data.jsx';
import { capitalizeFirstLetter } from 'src/utils/string';
import LoadingContainer from 'src/componentes/loading-container';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import ContentManager from 'src/componentes/content-manager';
import Alerta from 'src/componentes/message-box/alert';
import dataPeriodo from 'src/utils/dataPeriodo';
import Checkbox from 'src/componentes/checkbox';
import ProcessoLookup from 'src/lookups/processo';
import { getConfiguration } from 'src/services/commonRequests';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import { MODULOS, TIPO_MATRIZ_RISCO } from 'src/utils/constants';

import TabelaMatrizRisco from '../matriz-farol/tabela';
import TabelaMatrizRiscoComScore from '../matriz-farol/tabela_com_score';
import ProcessoListView from '../../processo/view';
import InputNumber from 'src/componentes/input-number';

const filtroId = 339;

function MatrizRiscoFarol({
  openLastFilter = true,
  showToolbar = true,
  filtroDefault,
  id,
  isWidget,
  tipoExibicao,
  showTabela = true,
  showMatriz = true,
}) {
  const emptyFilter = {
    tipoFiltroData: { id: 4 },
    dataInicio: dataPeriodo.inicio,
    dataTermino: dataPeriodo.termino,
    matrizRisco: null,
    areas: null,
    areasSubordinadas: false,
    processos: null,
    subProcessos: false,
    reducaoImpactoFinanceiro: null,
    impactoFinanceiroResidual: null,
    impactoFinanceiroInerente: null,
    scoreRisco: null,
    camposCustomizados: [],
  };

  const intl = useIntl();
  const { terms: resources, modules: modulosPermitidos } = useAuth();
  const [filtroDefaultCurrent, setFiltroDefaultCurrent] = useState(
    filtroDefault ? filtroDefault : emptyFilter
  );
  const [configuracao, setConfiguracao] = useState({});
  const [refreshRiscoList, setRefreshRiscoList] = useState(0);
  const [idsSelecionados, setIdsSelecionados] = useState([]);
  const [severidadeSelected, setSeveridadeSelected] = useState({});
  const [probabilidadeSelected, setProbabilidadeSelected] = useState({});
  const [positionMouse, setPositionMouse] = useState(-1);
  const [positionInerente, setPositionInerente] = useState(false);
  const [matrizFarol, setMatrizFarol] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [callFirstTime, setCallFirstTime] = useState(false);
  const [isLoadingRisco, setIsLoadingRisco] = useState(true);

  // busca o ultimo filtro acessado
  const getLastFilter = () => {
    if (openLastFilter) {
      setIsLoading(true);
      http
        .post(`/FiltroColaborador/${filtroId}/AbrirUltimoFiltro`, {})
        .then((response) => {
          if (response.data) {
            const newFilter = {
              id: response.data.id,
              camposCustomizados: response.data.valor.camposCustomizados,
              ...response.data.valor,
            };
            setFiltroDefaultCurrent(newFilter);
            setCallFirstTime(true);
            setIsLoading(false);
          }
        });
    } else {
      setCallFirstTime(true);
    }
  };

  useEffect(() => {
    getConfiguration('ItemConfiguracaoRisco')((configuracao) =>
      setConfiguracao(configuracao)
    )();
    if (id) {
      setFiltroDefaultCurrent({
        id: 0,
        matrizRisco: null,
        tipoFiltroData: { id: 4 },
        dataInicio: null,
        dataTermino: null,
        areas: null,
        areasSubordinadas: false,
        processos: null,
        subProcessos: false,
        reducaoImpactoFinanceiroResidual: null,
        reducaoImpactoFinanceiroInerente: null,
        impactoFinanceiroResidual: null,
        impactoFinanceiroInerente: null,
        objetivos: null,
        toleranciaRisco: null,
        toleranciaRiscoFaixas: null,
      });
    } else {
      getLastFilter();
    }
  }, [id, openLastFilter]);

  const refresh = () => {
    if (
      !filtroDefaultCurrent.matrizRisco ||
      filtroDefaultCurrent.matrizRisco.id == null
    ) {
      if (!isWidget) {
        ContentManager.addContent(
          <Alerta
            title={<FormattedMessage id="erro" />}
            message={
              <FormattedMessage id="label.necessarioSelecionarUmaMatriz" />
            }
          />
        );
      }
      setIsLoading(false);
      return false;
    }

    if (filtroDefaultCurrent.matrizRisco.id > 0) {
      setIsLoading(true);

      http
        .post('/MatrizItensImpactados/ObterMatrizFarol', {
          filtro: filtroDefaultCurrent,
        })
        .then((response) => {
          setMatrizFarol(response.data.matrizFarol);
          setRefreshRiscoList(refreshRiscoList + 1);
          setIsLoading(false);
        })
        .catch((error) => {
          errorHandler(error);
          setIsLoading(false);
        });
    }
  };

  const refreshWidget = useCallback(() => {
    if (isWidget && filtroDefaultCurrent) refresh();
  }, [isWidget, filtroDefaultCurrent]);

  useEffect(() => {
    if (filtroDefaultCurrent && callFirstTime) {
      refresh();
      setCallFirstTime(false);
      obterCamposCustomizados();
    }
  }, [filtroDefaultCurrent, callFirstTime]);

  const handleChangeFiltro = (field, value) => {
    setFiltroDefaultCurrent({
      ...filtroDefaultCurrent,
      [field]: value,
    });
  };

  const handleSave = () => {
    http
      .post(`/FiltroColaborador/${filtroId}/SalvarFiltro`, {
        filtroId: filtroId,
        valor: {
          ...filtroDefaultCurrent,
        },
      })
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    refreshWidget();
  }, [isWidget, filtroDefaultCurrent]);

  const handleSearch = () => {
    refresh();
    if (openLastFilter) {
      handleSave();
    }
  };

  const obterCamposCustomizados = () => {
    http
      .post(`/CampoCustomizado/ObterListaCamposCustomizados`, {
        tipoItem: 5,
      })
      .then((response) => {
        if (response.data.length > 0) {
          //let lista = this.form.getModel('camposCustomizados').value;
          //if (lista == null || lista == undefined || (lista != null && lista != undefined && lista[0].tipoItem == response.data[0].tipoItem)) {
          setFiltroDefaultCurrent({
            ...filtroDefaultCurrent,
            camposCustomizados: mergeFilteredVlrs(
              filtroDefaultCurrent?.camposCustomizados,
              response.data
            ),
          });
        }
      });
  };

  const mergeFilteredVlrs = (currentFilter, listaCamposCustomizados) => {
    const camposCustomizados = cloneDeep(currentFilter); // forçar imutabilidade
    const listaCamposCustomizadosClone = cloneDeep(listaCamposCustomizados);
    try {
      for (let i = 0; i < camposCustomizados.length; i++) {
        const e = camposCustomizados[i];
        camposCustomizados[i].campo.valores = e.campo.valores;
        camposCustomizados[i].valor = e.valor;
      }
    } catch (err) {}

    return listaCamposCustomizadosClone;
  };

  const renderMatrizRiscoScore = (matrizFarol) => {
    return (
      <Row>
        <Col md={12}>
          <Card>
            <TabelaMatrizRiscoComScore
              matrizFarol={matrizFarol}
              idsSelecionados={idsSelecionados}
              probabilidadeSelected={probabilidadeSelected}
              severidadeSelected={severidadeSelected}
              setPositionMouse={setPositionMouse}
              positionMouse={positionMouse}
              positionInerente={positionInerente}
              setFilter={setFilter}
              isFiltroMatrizRisco={true}
              filtroMatrizRisco={filtroDefaultCurrent}
            />
          </Card>
        </Col>
      </Row>
    );
  };

  const setFilter = (idsSelecionados, severidade, probabilidade) => {
    setIdsSelecionados(idsSelecionados);
    setSeveridadeSelected(severidade);
    setProbabilidadeSelected(probabilidade);
    setRefreshRiscoList(refreshRiscoList + 1);
  };

  const setPositions = (mouse, inerente) => {
    setPositionMouse(mouse);
    setPositionInerente(inerente);
  };

  const renderMatrizRiscoScoreComInerente = (matrizFarol) => {
    return (
      <Row>
        {(!tipoExibicao || tipoExibicao.id == 3 || tipoExibicao.id == 1) && (
          <Col md={tipoExibicao && tipoExibicao.id != 1 ? 12 : 6}>
            <Card>
              <TabelaMatrizRiscoComScore
                matrizFarol={matrizFarol}
                inerente={true}
                idsSelecionados={idsSelecionados}
                probabilidadeSelected={probabilidadeSelected}
                severidadeSelected={severidadeSelected}
                setPositionMouse={setPositions}
                positionMouse={positionMouse}
                positionInerente={positionInerente}
                header={intl.formatMessage(
                  { id: 'processosInerentes' },
                  { processos: resources.processos }
                )}
                setFilter={setFilter}
                isFiltroMatrizRisco={true}
                filtroMatrizRisco={filtroDefaultCurrent}
              />
            </Card>
          </Col>
        )}
        {(!tipoExibicao || tipoExibicao.id == 2 || tipoExibicao.id == 1) && (
          <Col md={tipoExibicao && tipoExibicao.id != 1 ? 12 : 6}>
            <Card>
              <TabelaMatrizRiscoComScore
                idsSelecionados={idsSelecionados}
                probabilidadeSelected={probabilidadeSelected}
                severidadeSelected={severidadeSelected}
                matrizFarol={matrizFarol}
                setPositionMouse={setPositions}
                positionMouse={positionMouse}
                positionInerente={positionInerente}
                header={intl.formatMessage(
                  { id: 'processosResiduais' },
                  { processos: resources.processos }
                )}
                setFilter={setFilter}
                filtroMatrizRisco={filtroDefaultCurrent}
              />
            </Card>
          </Col>
        )}
      </Row>
    );
  };

  const {
    tipoFiltroData,
    dataInicio,
    dataTermino,
    matrizRisco,
    areasSubordinadas,
    areas,
    processos,
    incluirSubprocessos,
    impactoFinanceiroResidual,
    impactoFinanceiroInerente,
    reducaoImpactoFinanceiro,
    responsaveis,
    scoreRisco,
  } = filtroDefaultCurrent;

  const {
    habilitarCalculoDeScores,
    avaliarRiscoInerente,
    acompanharImpactoFinanceiroEventoRisco,
  } = configuracao;

  if (isWidget && filtroDefault) {
    if (JSON.stringify(filtroDefault) != JSON.stringify(filtroDefaultCurrent))
      setFiltroDefaultCurrent(filtroDefault, () => refresh());
  }

  const hasGR = modulosPermitidos.find(
    (m) => m.id === MODULOS.GESTAO_RISCO && m.exibir
  );

  return (
    <div>
      {showToolbar && (
        <CardCustom open header={intl.formatMessage({ id: 'label.filtro' })}>
          <Row>
            <Col md={3}>
              <MatrizRiscoLookup
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage({ id: 'labelMatriz' })
                  ),
                  value: matrizRisco,
                  requestChange: handleChangeFiltro.bind(this, 'matrizRisco'),
                }}
                required
              />
            </Col>
            <Col md={2}>
              <ItemOrganizacaoLookup
                style={{ height: '34px', minHeight: '34px' }}
                multi={true}
                model={{
                  label: capitalizeFirstLetter(resources.area),
                  value: areas,
                  requestChange: handleChangeFiltro.bind(this, 'areas'),
                }}
                data={{ tiposIdSelecionaveis: [1, 2, 3] }}
              />
            </Col>
            <Col md={2}>
              <Checkbox
                model={{
                  label: intl.formatMessage(
                    { id: 'label.considerarAreasSubordinadas' },
                    { areas: resources.areas }
                  ),
                  value: areasSubordinadas,
                  requestChange: handleChangeFiltro.bind(
                    this,
                    'areasSubordinadas'
                  ),
                }}
                style={{ marginTop: 27 }}
              />
            </Col>
            <Col md={5}>
              <PeriodoData
                data={{ idsIgnorados: [7] }}
                tipoFiltroData={tipoFiltroData}
                dataInicio={dataInicio}
                dataTermino={dataTermino}
                onChange={handleChangeFiltro}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ProcessoLookup
                multi
                data={{ TipoId: 5 }}
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'label.processos' },
                      { processos: resources.processos }
                    )
                  ),
                  value: processos,
                  requestChange: handleChangeFiltro.bind(this, 'processos'),
                }}
              />
            </Col>
            <Col md={3}>
              <Checkbox
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'incluirSubprocessos' },
                      {
                        processosfilhosplural:
                          resources.processosfilhosplural.toLowerCase(),
                      }
                    )
                  ),
                  value: incluirSubprocessos,
                  requestChange: handleChangeFiltro.bind(
                    this,
                    'incluirSubprocessos'
                  ),
                }}
                style={{ marginTop: 27 }}
              />
            </Col>
            <Col md={3}>
              <ColaboradorSimplificadoLookup
                disabled={false}
                multi
                model={{
                  label: capitalizeFirstLetter(resources.responsaveis),
                  value: responsaveis,
                  requestChange: handleChangeFiltro.bind(this, 'responsaveis'),
                }}
              />
            </Col>
            {habilitarCalculoDeScores && (
              <Col md={3}>
                <InputNumber
                  style={{ height: '34px', minHeight: '34px' }}
                  model={{
                    label: intl.formatMessage(
                      { id: 'labelScoreRisco' },
                      { risco: resources.risco }
                    ),
                    value: scoreRisco,
                    requestChange: handleChangeFiltro.bind(this, 'scoreRisco'),
                  }}
                />
              </Col>
            )}
          </Row>
          {/* {acompanharImpactoFinanceiroEventoRisco && (
            <Row>
              {avaliarRiscoInerente && (
                <Col md={3}>
                  <InputNumber
                    style={{ height: '34px', minHeight: '34px' }}
                    model={{
                      label: intl.formatMessage({
                        id: 'labelImpactoFinanceiroInerente',
                      }),
                      value: impactoFinanceiroInerente,
                      requestChange: handleChangeFiltro.bind(
                        this,
                        'impactoFinanceiroInerente'
                      ),
                    }}
                  />
                </Col>
              )}
              <Col md={3}>
                <InputNumber
                  style={{ height: '34px', minHeight: '34px' }}
                  model={{
                    label: intl.formatMessage({
                      id: 'labelImpactoFinanceiroResidual',
                    }),
                    value: impactoFinanceiroResidual,
                    requestChange: handleChangeFiltro.bind(
                      this,
                      'impactoFinanceiroResidual'
                    ),
                  }}
                />
              </Col>
              {avaliarRiscoInerente && (
                <Col md={3}>
                  <InputNumber
                    style={{ height: '34px', minHeight: '34px' }}
                    model={{
                      label: intl.formatMessage({
                        id: 'labelReducaoImpactoFinanceiroInerente',
                      }),
                      value: reducaoImpactoFinanceiro,
                      requestChange: handleChangeFiltro.bind(
                        this,
                        'reducaoImpactoFinanceiro'
                      ),
                    }}
                  />
                </Col>
              )}
            </Row>
          )} */}
          {filtroDefaultCurrent && filtroDefaultCurrent.camposCustomizados && (
            <DesenharCampoCustomizado
              model={{
                value: filtroDefaultCurrent.camposCustomizados,
                requestChange: (value) =>
                  handleChangeFiltro('camposCustomizados', value),
              }}
              usarFiltroMultiSelecaoListaOpcao={true}
              disabled={false}
            />
          )}
          <Row>
            <Col className="pull-right">
              <Button
                className="pull-right"
                style={{ marginTop: 27 }}
                onClick={handleSearch}
                leftIcon="magnify"
              >
                <FormattedMessage id="label.filtrar"></FormattedMessage>
              </Button>
            </Col>
          </Row>
        </CardCustom>
      )}
      {showMatriz && (
        <LoadingContainer isLoading={isLoading}>
          {matrizFarol && habilitarCalculoDeScores ? (
            avaliarRiscoInerente ? (
              renderMatrizRiscoScoreComInerente(matrizFarol)
            ) : (
              renderMatrizRiscoScore(matrizFarol)
            )
          ) : matrizFarol != null &&
            matrizFarol.severidades &&
            matrizFarol.severidades.length > 0 ? (
            <Row>
              {avaliarRiscoInerente &&
                (!tipoExibicao ||
                  tipoExibicao.id == 3 ||
                  tipoExibicao.id == 1) && (
                  <Col md={tipoExibicao && tipoExibicao.id != 1 ? 12 : 6}>
                    <Card>
                      <TabelaMatrizRisco
                        header={intl.formatMessage(
                          { id: 'processosInerentes' },
                          { processos: resources.processos }
                        )}
                        idsSelecionados={idsSelecionados}
                        matrizFarol={matrizFarol}
                        matrizRiscoFarolId={matrizRisco?.id}
                        editMode="page"
                        inerente={true}
                        setPositionMouse={setPositions}
                        positionMouse={positionMouse}
                        positionInerente={positionInerente}
                        setFilter={setFilter}
                      />
                    </Card>
                  </Col>
                )}
              {(!tipoExibicao ||
                tipoExibicao.id == 2 ||
                tipoExibicao.id == 1) && (
                <Col
                  md={
                    !avaliarRiscoInerente ||
                    (tipoExibicao && tipoExibicao.id != 1)
                      ? 12
                      : 6
                      ? 6
                      : 12
                  }
                >
                  <Card>
                    <TabelaMatrizRisco
                      header={intl.formatMessage(
                        { id: 'processosResiduais' },
                        { processos: resources.processos }
                      )}
                      idsSelecionados={idsSelecionados}
                      matrizFarol={matrizFarol}
                      matrizRiscoFarolId={matrizRisco?.id}
                      editMode="page"
                      inerente={false}
                      setPositionMouse={setPositions}
                      positionMouse={positionMouse}
                      positionInerente={positionInerente}
                      setFilter={setFilter}
                    />
                  </Card>
                </Col>
              )}
            </Row>
          ) : (
            <Alert color="light">
              {intl.formatMessage({ id: 'label.nenhumRegistroEncontrado' })}
            </Alert>
          )}
        </LoadingContainer>
      )}
      {matrizFarol && matrizRisco && showTabela && (
        <ProcessoListView
          hideHead={true}
          showNew={false}
          saved={refreshRiscoList}
          hideFiltroChip={true}
          showSearch={false}
          usoMatrizRisco={true}
          filtroId={0}
          customAction="ObterRiscosMatrizItensImpactados"
          filtroDefault={filtroDefaultCurrent}
          params={{
            idsSelecionados: idsSelecionados,
            isFiltroMatrizRisco: true,
            filtroMatrizRisco: filtroDefaultCurrent,
          }}
          usarFiltroSalvo={false}
          setIsLoading={setIsLoadingRisco}
          hierarchy={false}
          hiddenConsolidarOption={true}
          openLastFilter={false}
        />
      )}
    </div>
  );
}

export default MatrizRiscoFarol;
