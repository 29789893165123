import React from 'react';
import { autobind } from 'core-decorators';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import css from '../../../utils/css';
import { Col, Row, Progress, Label } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../../componentes/button';
import { capitalizeFirstLetter } from '../../../utils/string';
import MdiIcon from '../../../componentes/mdi-icon';
import Panel from '../../../componentes/panel';
import Checkbox from '../../../componentes/checkbox';
import Input from '../../../componentes/input';
import DatePicker from '../../../componentes/date-picker';
import ResponsavelButtonLookup from '../../../lookups/colaborador-button';
import { FormattedMessage } from 'react-intl';
import Avatar from '../../../componentes/avatar';
// import ConfigApp from '../../../services/configApp';
import formatarData from '../../../utils/formatarData';
import clsx from 'clsx';

const classes = css`
  .hoverEffect {
    padding: 2px;
    &:hover {
      background: #f3f3f3;
    }
  }
  .background {
    padding: 2px;
    background: #f3f3f3;
  }
  .removeHover {
    transition: all ease-in-out 0.1s;
    &:hover {
      cursor: pointer;
      color: #f05050 !important;
    }
  }
`;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const novaTarefaEsqueleto = {
  isNew: true,
  descricao: null,
  responsavel: null,
  dataConclusao: null,
  concluida: false,
};

@autobind
class ChecklistAcoes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adicionarNovo: false,
      novaTarefa: novaTarefaEsqueleto,
      tarefaEdicao: null,
      rascunhos: [],
      responsavelLookup: false,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    var elements1 = document.getElementsByClassName('rdtPicker');
    var elements2 = document.getElementsByClassName('popupOpen');

    var array1 = [].slice.call(elements1);
    var array2 = [].slice.call(elements2);

    let ref = this.tarefaRef && this.tarefaRef.node;
    let isNew = this.tarefaRef && this.tarefaRef.isNew;

    if (ref && !ref.contains(event.target)) {
      var validacao = false;

      if (array1 && array1.length > 0) {
        array1.forEach((element) => {
          if (element && element.contains(event.target)) {
            validacao = true;
          }
        });
      }

      if (!validacao && array2 && array2.length > 0) {
        array2.forEach((element) => {
          if (element && element.contains(event.target)) {
            validacao = true;
          }
        });
      }

      let nomeClasse = event.target.className;

      if (!validacao) {
        if (nomeClasse.includes('Select-option')) return;

        if (isNew) {
          this.setState({
            adicionarNovo: false,
            responsavelLookup: false,
          });
        } else {
          this.setState({ tarefaEdicao: null });
        }
      }
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let items = reorder(
      this.props.tarefas.value,
      result.source.index,
      result.destination.index
    );

    this.props.tarefas.requestChange(items);
  }

  handleChangeTarefaRascunho(field, id, value) {
    let rascunhos = Object.assign([], this.state.rascunhos);
    let rascunho = rascunhos.find((r) => r.id == id);

    if (rascunho != null) {
      rascunho[field] = value;
      rascunho['alterado'] = true;
    }

    this.setState({
      rascunhos: rascunhos,
    });
  }

  handleChangeNovaTarefa(field, value) {
    this.setState({
      novaTarefa: {
        ...this.state.novaTarefa,
        [field]: value,
      },
    });
  }

  closeNew() {
    this.setState({
      novaTarefa: novaTarefaEsqueleto,
      adicionarNovo: false,
      responsavelLookup: false,
    });
  }

  close(id) {
    this.setState({
      tarefaEdicao: null,
      rascunhos: this.removeRascunho(id),
      responsavelLookup: false,
    });
  }

  verificaIdTarefa(id) {
    return (
      this.props.tarefas &&
      this.props.tarefas.value &&
      this.props.tarefas.value.find((t) => t.id == id) == null
    );
  }

  handleSalvar(isNew, tarefa) {
    let tarefas = Object.assign([], this.props.tarefas.value || []);
    if (isNew) {
      let tarefaAdd = Object.assign({}, this.state.novaTarefa);

      //identificador temporário para o rascunho (tem que ser int)
      let idValido = false;
      let id;

      do {
        id = Math.floor(Math.random() * 10000) + 1;
        idValido = this.verificaIdTarefa(id);
      } while (!idValido);

      tarefaAdd.id = id;
      tarefas.push(tarefaAdd);
      this.props.tarefas.requestChange(tarefas);
      this.closeNew();
    } else {
      let index = tarefas.findIndex((a) => a.id == tarefa.id);
      if (tarefas && index != -1 && tarefas[index] != null) {
        //mantendo a conclusão
        tarefas[index] = {
          ...this.criarOuRetornarRascunho(tarefa),
          concluida: tarefa.concluida,
        };
        this.setState({
          tarefaEdicao: null,
          rascunhos: this.removeRascunho(tarefa.id),
        });
        this.props.tarefas.requestChange(tarefas);
      }
    }
  }

  checkTarefa(index) {
    let tarefas = Object.assign([], this.props.tarefas.value || []);

    if (tarefas && tarefas[index] != null) {
      tarefas[index].concluida = !tarefas[index].concluida;
      this.props.tarefas && this.props.tarefas.requestChange(tarefas);
    }
  }

  podeSalvar(descricao) {
    if (descricao == null || descricao.length < 1) return false;

    return true;
  }

  calculaCorDataConclusao(data) {
    let dataHoje = new Date();
    dataHoje.setHours(0, 0, 0, 0);

    if (data < dataHoje) return '#f05050';
  }

  handleRemoveTarefa(id) {
    let tarefas = Object.assign([], this.props.tarefas.value || []);

    if (tarefas) {
      let index = tarefas.findIndex((t) => t.id == id);

      if (index != -1 && tarefas[index] != null) {
        this.removeRascunho(tarefas[index].id);
        tarefas.splice(index, 1);
        this.props.tarefas && this.props.tarefas.requestChange(tarefas);
      }
    }
  }

  handleAtivarEdicao(tarefa) {
    this.criarOuRetornarRascunho(tarefa);
    this.setState({
      tarefaEdicao: tarefa,
    });
  }

  renderTarefa(tarefa, index, isDragging) {
    let { disabled } = this.props;
    return (
      <div
        className={clsx(classes.hoverEffect, {
          [classes.background]: isDragging,
        })}
        style={{ width: '100%' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            disabled={disabled}
            height={17}
            width={17}
            style={{ marginTop: -30, marginRight: 5 }}
            model={{
              value: tarefa.concluida,
              requestChange: () => this.checkTarefa(index),
            }}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              minHeight: 35,
            }}
            onClick={() => this.handleAtivarEdicao(tarefa)}
          >
            <div
              style={{
                overflow: 'hidden',
                padding: '0px 5px',
                textAlign: 'left',
                flexGrow: 4,
                flexBasis: 0,
                textDecoration: tarefa.concluida && 'line-through',
              }}
            >
              {tarefa.descricao}
            </div>
            <div
              style={{
                padding: '0px 5px',
                flexGrow: 1,
                flexBasis: 0,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginRight: 4,
                }}
              >
                {tarefa.responsavel != null && (
                  <div
                    id={`id${tarefa.responsavel && tarefa.responsavel.id}`}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Avatar
                      foto={
                        tarefa.responsavel && tarefa.responsavel.fotoArquivo
                      }
                      showLabel={false}
                      border={1}
                      size={30}
                      label={tarefa.responsavel && tarefa.responsavel.nome}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                overflow: 'hidden',
                padding: '0px 5px',
                flexGrow: 1,
                flexBasis: 0,
                color:
                  !tarefa.concluida &&
                  this.calculaCorDataConclusao(tarefa.dataConclusao),
              }}
            >
              {tarefa.dataConclusao &&
                formatarData(this.props.user.idioma, tarefa.dataConclusao)}
            </div>
          </div>
        </div>
        {this.retornaRascunhoAlterado(tarefa.id) != null && (
          <div style={{ display: 'flex' }}>
            <span>
              {this.props.intl.formatMessage({
                id: 'voceTemEdicoesNaoSalvasNesteCampo',
              })}
            </span>
            <a
              tabIndex="-1"
              onClick={() => this.handleAtivarEdicao(tarefa)}
              style={{ marginLeft: 5 }}
            >
              {this.props.intl.formatMessage({ id: 'exibirEdicao' })}
            </a>
            <a
              tabIndex="-1"
              onClick={() => this.close(tarefa.id)}
              style={{ marginLeft: 5 }}
            >
              {this.props.intl.formatMessage({ id: 'descartar' })}
            </a>
          </div>
        )}
      </div>
    );
  }

  handleChangeLookup(lookupReponsavel) {
    this.setState({ responsavelLookup: lookupReponsavel });
  }

  criarOuRetornarRascunho(tarefa) {
    let rascunho = this.retornaRascunho(tarefa.id);

    if (rascunho != null) return rascunho;
    else {
      let novoRascunho = { ...tarefa, alterado: false };
      let rascunhos = Object.assign([], this.state.rascunhos);

      rascunhos.push(novoRascunho);
      this.setState({ rascunhos: rascunhos }, () => {
        return this.retornaRascunho(tarefa.id);
      });
    }
  }

  retornaRascunho(id) {
    return this.state.rascunhos.find((r) => r.id == id);
  }

  retornaRascunhoAlterado(id) {
    return this.state.rascunhos.find((r) => r.id == id && r.alterado == true);
  }

  removeRascunho(id) {
    let rascunhos = Object.assign([], this.state.rascunhos);
    let index = rascunhos.findIndex((r) => r.id == id);

    if (index != -1) {
      rascunhos.splice(index, 1);
    }

    return rascunhos;
  }

  renderTarefaEdicao(isNew, tarefaParam = null) {
    let { disabled } = this.props;
    let tarefa;

    if (!isNew) {
      let rascunho = this.criarOuRetornarRascunho(tarefaParam);
      tarefa = rascunho;
    } else {
      tarefa = this.state.novaTarefa;
    }

    return (
      <div
        ref={(node) => (this.tarefaRef = { isNew: isNew, node: node })}
        style={{ padding: 10 }}
        className={classes.hoverEffect}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ textAlign: 'left', flexGrow: 5, flexBasis: 0 }}>
            <Label>
              {this.props.intl.formatMessage({ id: 'descricao' })} *
            </Label>
            <Input
              disabled={disabled}
              rows={2}
              required
              style={{ marginBottom: 0 }}
              type="textarea"
              model={{
                value: tarefa.descricao,
                requestChange: !isNew
                  ? this.handleChangeTarefaRascunho.bind(
                      this,
                      'descricao',
                      tarefa.id
                    )
                  : this.handleChangeNovaTarefa.bind(this, 'descricao'),
              }}
            />
          </div>
          <div
            style={{
              textAlign: 'left',
              flexGrow: this.state.responsavelLookup ? 3 : 1,
              flexBasis: 0,
              margin: '0 10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0px 5px',
              }}
            >
              <ResponsavelButtonLookup
                disabled={disabled}
                showLabel
                center
                callbackLookup={this.handleChangeLookup}
                fromAcao
                acaoId={this.props.acaoId}
                tipoLookup="acao"
                remove={() =>
                  !isNew
                    ? this.handleChangeTarefaRascunho(
                        'responsavel',
                        tarefa.id,
                        null
                      )
                    : this.handleChangeNovaTarefa('responsavel', null)
                }
                formGroupStyle={{ marginBottom: 0 }}
                multi={false}
                model={{
                  label: capitalizeFirstLetter(
                    this.props.resources.responsavel
                  ),
                  value: tarefa.responsavel,
                  requestChange: !isNew
                    ? this.handleChangeTarefaRascunho.bind(
                        this,
                        'responsavel',
                        tarefa.id
                      )
                    : this.handleChangeNovaTarefa.bind(this, 'responsavel'),
                }}
              />
            </div>
          </div>
          <div style={{ textAlign: 'left', flexGrow: 2, flexBasis: 0 }}>
            <DatePicker
              disabled={disabled}
              timeFormat={false}
              autoFormatDate={true}
              model={{
                label: this.props.intl.formatMessage({ id: 'data' }),
                value: tarefa.dataConclusao,
                requestChange: !isNew
                  ? this.handleChangeTarefaRascunho.bind(
                      this,
                      'dataConclusao',
                      tarefa.id
                    )
                  : this.handleChangeNovaTarefa.bind(this, 'dataConclusao'),
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={disabled}
            key={1}
            onClick={() => (isNew ? this.closeNew() : this.close(tarefa.id))}
            color="secondary"
          >
            {<FormattedMessage id="label.cancelar" />}
          </Button>
          {!isNew && (
            <Button
              disabled={disabled}
              className="ml-2"
              color="danger"
              onClick={() => this.handleRemoveTarefa(tarefa.id)}
            >
              <FormattedMessage id="excluir" />
            </Button>
          )}
          <Button
            disabled={!this.podeSalvar(tarefa.descricao) || disabled}
            key={2}
            type="primary"
            onClick={() => this.handleSalvar(isNew, tarefaParam)}
            htmlType="submit"
            className="ml-2"
          >
            {<FormattedMessage id="label.salvar" />}
          </Button>
        </div>
      </div>
    );
  }

  calculaValorProgresso(tarefas) {
    if (tarefas && tarefas.value && tarefas.value.length > 0) {
      let value = Math.floor(
        (100 * tarefas.value.filter((a) => a.concluida == true).length) /
          tarefas.value.length
      );
      return value + '';
    }

    return 0;
  }

  render() {
    let { tarefas, intl, disabled } = this.props;

    let value = this.calculaValorProgresso(tarefas);

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex' }}>
            <span>
              {capitalizeFirstLetter(
                intl.formatMessage({ id: 'checklistTarefas' })
              )}
            </span>
            <span style={{ marginLeft: 10 }}>
              {tarefas &&
                tarefas.value &&
                tarefas.value.filter((a) => a.concluida == true).length}
              /{tarefas && tarefas.value && tarefas.value.length}
            </span>
          </div>
          <div style={{ display: 'flex', marginTop: -3 }}>
            <span style={{ margin: '0 5px' }}>{value}%</span>
            <Progress
              style={{ width: 75, height: 8, marginTop: 6 }}
              value={value}
            />
          </div>
          <Button
            disabled={disabled}
            style={{
              display: 'flex',
              height: 35,
              width: 40,
              justifyContent: 'center',
            }}
            className="ml-2"
            onClick={() => this.setState({ adicionarNovo: true })}
          >
            <MdiIcon icon="plus" />
          </Button>
        </div>
        <div style={{ width: '100%' }}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <div className={classes.config}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div ref={provided.innerRef}>
                        {tarefas &&
                          tarefas.value &&
                          tarefas.value.map((tarefa, index) =>
                            this.state.tarefaEdicao != null &&
                            this.state.tarefaEdicao.id == tarefa.id ? (
                              this.renderTarefaEdicao(false, tarefa)
                            ) : (
                              <Draggable
                                index={index}
                                key={index + ''}
                                draggableId={index + ''}
                              >
                                {(provided, snapshot) => (
                                  <div>
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      {this.renderTarefa(
                                        tarefa,
                                        index,
                                        snapshot.isDragging
                                      )}
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </Col>
            </Row>
          </DragDropContext>
          {this.state.adicionarNovo && this.renderTarefaEdicao(true)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    user: state.user,
  };
}

export default injectIntl(connect(mapStateToProps)(ChecklistAcoes));
