import React from 'react';
import { autobind } from 'core-decorators';
import { Schema, Form } from 'src/utils/form';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ContentManager from 'src/componentes/content-manager';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import Dialog from 'src/componentes/dialog';
import DatePicker from 'src/componentes/date-picker';
import Button from 'src/componentes/button';
import Alert from 'src/componentes/message-box/alert';
import LoadingContainer from 'src/componentes/loading-container';

let schemaReprocessar = Schema.object({
  dataInicio: Schema.string()
    .label(<FormattedMessage id="label.dataInicio" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.dataTermino != null;
      },
    }),
  dataTermino: Schema.string()
    .label(<FormattedMessage id="label.dataTermino" />)
    .required({
      onlyIf: (context) => {
        return context.parent.value.dataInicio != null;
      },
    }),
});

@autobind
export default class ReprocessarDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.form = new Form({
      component: this,
      schema: schemaReprocessar,
    });
  }

  componentDidMount() {
    let modelReprocessar = this.form.getModel();
    let now = new Date();

    modelReprocessar
      .getModel('dataInicio')
      .requestChange(new Date(now.getFullYear(), 0, 1));
    modelReprocessar
      .getModel('dataTermino')
      .requestChange(new Date(now.getFullYear(), 11, 1));
  }

  handleReprocessaFaixa(model, close) {
    if (!this.form.isValid()) {
      this.form.showAllErrors();
      ContentManager.addContent(
        <Alert
          message={
            <FormattedMessage id="label.osCamposDestacadosEmVermelhoSaoObrigatoriosFavorPreenchelos" />
          }
        />
      );
      return;
    }

    let modelReprocessar = this.form.getModel();

    modelReprocessar.dataInicio = modelReprocessar.getModel('dataInicio').value;
    modelReprocessar.dataTermino =
      modelReprocessar.getModel('dataTermino').value;

    this.setState({ isLoading: true });

    http
      .post(`/PontoFaixaResultado/Save`, {
        model: model,
        parameters: {
          ...this.props.parameters,
          reprocessar: true,
          dataInicio: modelReprocessar.dataInicio,
          dataTermino: modelReprocessar.dataTermino,
        },
      })
      .then(() => {
        this.handleCancelarClick();
        close();
      })
      .catch((error) => {
        errorHandler(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  handleCancelarClick() {
    this.refs.dialog.close();
  }

  render() {
    let { model, close } = this.props;
    let modelReprocessar = this.form.getModel();

    let actions = [
      <div key="confirm-reprocessa">
        <Button
          disabled={this.state.isLoading}
          color="primary"
          onClick={this.handleReprocessaFaixa.bind(this, model, close)}
        >
          <FormattedMessage id="label.confirmar" />
        </Button>
      </div>,
    ];

    return (
      <Dialog
        {...this.props}
        ref="dialog"
        title={this.props.intl.formatMessage({ id: 'label.reprocessar' })}
        actions={actions}
        //onRequestClose={this.handleCancelarClick}
        width={700}
        minHeight={200}
        padContent
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          <Row>
            <Col md={6}>
              <DatePicker
                required
                model={{
                  label: modelReprocessar.getModel('dataInicio').label,
                  value: modelReprocessar.getModel('dataInicio').value,
                  requestChange:
                    modelReprocessar.getModel('dataInicio').requestChange,
                  errors: modelReprocessar.getModel('dataInicio').errors,
                }}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                required
                model={{
                  label: modelReprocessar.getModel('dataTermino').label,
                  value: modelReprocessar.getModel('dataTermino').value,
                  requestChange:
                    modelReprocessar.getModel('dataTermino').requestChange,
                  errors: modelReprocessar.getModel('dataTermino').errors,
                }}
              />
            </Col>
            <Col>
              {
                <FormattedMessage id="desejaReprocessarPontosPorDesempenhoItenVinculadoFaixa2" />
              }
            </Col>
          </Row>
        </LoadingContainer>
      </Dialog>
    );
  }
}
