import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import PeriodoData from 'src/paginas/data/periodo-data';

@autobind
class GraficoScoreRiscoSearch extends React.Component {
  handleChangeFiltro(field, value) {
    let { model } = this.props;
    model.getModel(field).requestChange(value);
  }

  render() {
    let { model } = this.props;
    return (
      <div style={{ minWidth: 1000 }}>
        <Row>
          <Col md={12}>
            <PeriodoData
              clearable
              tipoFiltroData={model.getModel('tipoFiltroData').value}
              dataInicio={model.getModel('dataInicio').value}
              dataTermino={model.getModel('dataTermino').value}
              onChange={this.handleChangeFiltro}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(GraficoScoreRiscoSearch));
