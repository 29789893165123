import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonToolbar } from 'reactstrap';

import Button from 'src/componentes/button';
import IconDialog from 'src/componentes/icon-dialog';
import Checkbox from 'src/componentes/checkbox';
import MdiIcon from 'src/componentes/mdi-icon';

const CriticalConfirm = ({
  handleConfirm,
  handleCancel,
  isFunction,
  title,
  message,
  labelCancel,
  labelConfirm,
  labelCheck,
  ...rest
}) => {
  const ref = useRef();
  const intl = useIntl();
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = (value) => {
    setChecked(value);
  };

  const handleCancelClick = () => {
    handleCancel && handleCancel();
    if (ref.current) ref.current.close();
  };

  const handleConfirmClick = () => {
    handleConfirm && handleConfirm();
    if (ref.current) ref.current.close();
  };

  const actions = [
    <ButtonToolbar>
      <Button onClick={handleCancelClick} color="secondary">
        {labelCancel
          ? labelCancel
          : intl.formatMessage({ id: 'label.cancelar' })}
      </Button>

      <Button
        disabled={!checked}
        className="ml-2"
        autoFocus
        color="danger"
        onClick={handleConfirmClick}
      >
        {labelConfirm
          ? labelConfirm
          : intl.formatMessage({ id: 'label.confirmar' })}
      </Button>
    </ButtonToolbar>,
  ];

  const TitleIcon = () => (
    <MdiIcon
      style={{
        backgroundColor: '#FFEBEB',
        height: 40,
        width: 40,
        padding: '10px',
      }}
      icon="alert-outline"
      className="text-danger rounded-circle d-flex justify-content-center align-items-center mt-2 ml-2"
      size={20}
    />
  );

  return (
    <IconDialog
      ref={ref}
      width={500}
      title={title}
      titleColor="text-danger"
      actions={actions}
      onRequestClose={handleCancelClick}
      padContent
      TitleIcon={TitleIcon}
      {...rest}
    >
      <div className="mb-3">{isFunction ? message() : message}</div>

      <Checkbox
        className="mb-2"
        model={{
          label: labelCheck ? labelCheck : intl.formatMessage({ id: 'cienteAcaoIrreversivel' }),
          value: checked,
          requestChange: (value) => handleChangeChecked(value),
        }}
      />
    </IconDialog>
  );
};

export default CriticalConfirm;
