import React from 'react';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Edit from 'src/componentes/edit/edit-dialog';
import { Schema } from 'src/utils/form';
import Editor from 'src/paginas/formula';

let schema = Schema.object({
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  expressao: Schema.string().label(<FormattedMessage id="label.expressao" />),
  dataInicio: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
  tipo: Schema.string().label(<FormattedMessage id="tipo" />),
  alterado: Schema.string().label(<FormattedMessage id="label.alterado" />),
  excluido: Schema.string().label(<FormattedMessage id="label.excluido" />),
  item: Schema.string().label(<FormattedMessage id="item" />),
  desdobramento: Schema.string(),
});

@autobind
class FormEdit extends React.Component {
  render() {
    return <Editor {...this.props} />;
  }
}

@autobind
class FormulaEdit extends React.Component {
  render() {
    let { resources, intl } = this.props;
    return (
      <Edit
        {...this.props}
        url="/Formula"
        title={intl.formatMessage({ id: 'label.editorFormula' })}
        formComponent={FormEdit}
        showDelete={false}
        width="80%"
        height="580px"
        resources={resources}
        schema={schema}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(FormulaEdit));
