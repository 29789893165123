import Input from './Input';
import FormGroup from './FormGroup';
import Label from './Label';
import { FormFeedback } from 'reactstrap';

function InputGroup({ label, required, error, formGroupStyle, ...rest }) {
  return (
    <FormGroup style={formGroupStyle}>
      <Label>
        {label}
        {required && ' *'}
      </Label>
      <Input invalid={error} {...rest} />
      {error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
}

export default InputGroup;
