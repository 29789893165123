import { ProjectModel, TaskModel, CalendarModel } from '@bryntum/gantt';
import { addDays } from 'date-fns';
import { saveAs } from 'file-saver';
import BigNumber from 'bignumber.js/bignumber.mjs';
import { Badge } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import { manager as cardMetasManager } from 'src/services/cardMetaItemPermissions';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import { capitalizeFirstLetter } from 'src/utils/string';
import { getSession } from 'src/contexts/AuthContext';
import ConfigApp from 'src/services/configApp';
import getInitials from 'src/utils/getInitials';
import { FAROL_ACAO, STATUS_ACAO } from 'src/utils/constants';

const MAX_YEARS_RANGE = 15;
const MAX_YEARS_RANGE_CALCULATION = MAX_YEARS_RANGE * 365 * 24 * 3600000;

class TaskModelClass extends TaskModel {
  get style() {
    return !this.isMilestone
      ? `background-color: ${this.farol?.cor || '#D6D6D6'};`
      : 'inherit';
  }

  get cls() {
    return Object.assign(super.cls, {
      'b-critical': this.critical,
      'task-gantt-bg-1': this.farol?.id === FAROL_ACAO.ROXO,
      'task-gantt-bg-2': this.farol?.id === FAROL_ACAO.AZUL,
      'task-gantt-bg-3': this.farol?.id === FAROL_ACAO.VERDE,
      'task-gantt-bg-4': this.farol?.id === FAROL_ACAO.AMARELO,
      'task-gantt-bg-5': this.farol?.id === FAROL_ACAO.VERMELHO,
      'task-gantt-bg-6': this.farol?.id === FAROL_ACAO.BRANCO,
      'task-gantt-bg-7': this.farol?.id === FAROL_ACAO.PRETO,
      'task-gantt-bg-8': !this.farol,
    });
  }

  static get fields() {
    return [
      { name: 'critical', persist: false },
      { name: 'expanded', persist: false },
      { name: 'note', persist: false },
      { name: 'rollup', persist: false },
      { name: 'showInTimeline', persist: false },
      { name: 'draggable', persist: false },
      { name: 'resizable', persist: false },
      { name: 'constraintDate', persist: false },
      { name: 'constraintType', persist: false },
      { name: 'deadlineDate', persist: false },
      { name: 'durationUnit', persist: false },
      { name: 'earlyEndDate', persist: false },
      { name: 'earlyStartDate', persist: false },
      { name: 'effort', persist: false },
      { name: 'effortDriven', persist: false },
      { name: 'effortUnit', persist: false },
      { name: 'inactive', persist: false },
      { name: 'lateEndDate', persist: false },
      { name: 'lateStartDate', persist: false },
      { name: 'manuallyScheduled', persist: false },
      { name: 'slackUnit', persist: false },
      { name: 'totalSlack', persist: false },
      { name: 'wbsValue', persist: false },
      { name: 'utilizarDatasJaSalvas', persist: true },
    ];
  }

  constraintType =
    this.temDependencias || this.isParent ? null : 'startnoearlierthan';
}

const getDefaultTask = (
  intl,
  resources,
  user,
  planoAcaoId = 'main',
  customProps = {}
) => {
  const startDate = new Date();
  const endDate = addDays(startDate, 1);

  return {
    descricao: intl.formatMessage({ id: 'novaAcao' }, { acao: resources.acao }),
    dataInicioPrevista: startDate,
    dataTerminoPrevista: endDate,
    responsavel: user,
    editarDemaisCampos: true,
    planoAcaoId,
    planoAcaoExterno: planoAcaoId != 'main',
    interacoesLiberadasEntreContextos: false,
    ...customProps,
  };
};

const handleHttp = async (url, params = {}) => {
  try {
    const response = await http.post(url, { ...params });
    return response?.data;
  } catch (err) {
    errorHandler(err);
    return {};
  }
};

const getDefaultColumns = (
  intl,
  resources,
  configurations = {},
  customFields = []
) => {
  const columns = [
    {
      headerText: intl.formatMessage({ id: 'codigo' }),
      valueField: 'codigo',
      visible: true,
      default: true,
    },
    {
      headerText: intl.formatMessage({ id: 'descricao' }),
      valueField: 'acoes',
      required: true,
      visible: true,
      default: true,
    },
    {
      headerText: intl.formatMessage({ id: 'dataInicioPrevista' }),
      valueField: 'dataInicio',
      visible: true,
      default: true,
    },
    {
      headerText: intl.formatMessage({ id: 'dataTerminoPrevista' }),
      valueField: 'dataTermino',
      visible: true,
      default: true,
    },
    {
      headerText: resources.percprevisto,
      valueField: 'percentualPrevisto',
      visible: configurations.utilizarPercentualPrevisto,
    },
    {
      headerText: resources.percrealizado,
      valueField: 'percentualRealizado',
      visible: configurations.utilizarPercentualRealizado,
    },
    {
      headerText: capitalizeFirstLetter(resources.responsavel),
      valueField: 'responsavel',
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'baselineInicial' }),
      valueField: 'baselineInicial',
      default: false,
      visible: configurations.habilitarBaseline,
    },
    {
      headerText: intl.formatMessage({ id: 'label.baselineTermino' }),
      valueField: 'baselineTermino',
      default: false,
      visible: configurations.habilitarBaseline,
    },
    {
      headerText: intl.formatMessage({ id: 'dataInicioRealizada' }),
      valueField: 'dataInicioRealizada',
      default: false,
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'dataTerminoRealizada' }),
      valueField: 'dataTerminoRealizada',
      default: false,
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'label.duracao' }),
      valueField: 'duracao',
      default: false,
      visible: true,
    },
    {
      headerText: capitalizeFirstLetter(
        intl.formatMessage({ id: 'label.itensVinculados' })
      ),
      valueField: 'idIntegracaoItensVinculados',
      default: false,
      visible: true,
    },
    {
      headerText: intl.formatMessage(
        { id: 'tipoAcao' },
        { acao: resources.acao }
      ),
      valueField: 'tipoAcao',
      default: false,
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'checklistTarefas' }),
      valueField: 'checklist',
      default: false,
      visible: configurations.habilitarChecklistTarefas,
    },
    {
      headerText: intl.formatMessage({ id: 'predecessoras' }),
      valueField: 'predecessoras',
      default: false,
      visible: true,
    },
    {
      headerText: intl.formatMessage({ id: 'sucessoras' }),
      valueField: 'sucessoras',
      default: false,
      visible: true,
    },
    {
      headerText: intl.formatMessage(
        { id: 'criadorAcao' },
        { acao: resources.acao }
      ),
      valueField: 'criadorAcao',
      visible: true,
      default: false,
    },
    {
      headerText: intl.formatMessage(
        { id: 'dataCriacaoAcao' },
        { acao: resources.acao }
      ),
      valueField: 'dataCriacaoAcao',
      visible: true,
      default: false,
    },
    {
      headerText: capitalizeFirstLetter(resources.farol),
      valueField: 'farol',
      visible: true,
      default: false,
    },
    {
      headerText: intl.formatMessage({ id: 'status' }),
      valueField: 'status',
      visible: true,
      default: false,
    },
    {
      headerText: intl.formatMessage(
        { id: 'investimentoPrevisto' },
        { investimento: resources.investimento }
      ),
      valueField: 'investimentoPrevisto',
      visible: true,
      default: false,
    },
    {
      headerText: intl.formatMessage(
        { id: 'investimentoRealizado' },
        { investimento: resources.investimento }
      ),
      valueField: 'investimentoRealizado',
      visible: true,
      default: false,
    },
    {
      headerText: intl.formatMessage(
        { id: 'custeioPrevisto' },
        { custeio: resources.custeio }
      ),
      valueField: 'custeioPrevisto',
      visible: true,
      default: false,
    },
    {
      headerText: intl.formatMessage(
        { id: 'custeioRealizado' },
        { custeio: resources.custeio }
      ),
      valueField: 'custeioRealizado',
      visible: true,
      default: false,
    },
    {
      headerText: intl.formatMessage(
        { id: 'metaGanhosPorAcao' },
        { acao: resources.acao, meta: resources.meta }
      ),
      valueField: 'metaGanhosPorAcao',
      visible: configurations.utilizarGanhosPorAcao,
      default: false,
    },
    {
      headerText: intl.formatMessage(
        { id: 'realizadoGanhosPorAcao' },
        { acao: resources.acao }
      ),
      valueField: 'realizadoGanhosPorAcao',
      visible: configurations.utilizarGanhosPorAcao,
      default: false,
    },
    {
      headerText: intl.formatMessage(
        { id: 'desvioGanhosPorAcao' },
        { acao: resources.acao }
      ),
      valueField: 'desvioGanhosPorAcao',
      visible: configurations.utilizarGanhosPorAcao,
      default: false,
    },
    {
      headerText: intl.formatMessage({ id: 'esforco' }),
      valueField: 'esforco',
      visible: configurations.utilizarEsforco,
      default: false,
    },
    {
      headerText: intl.formatMessage({ id: 'tags' }, { tags: resources.tags }),
      valueField: 'tags',
      visible: true,
      default: true,
    },
    {
      headerText: intl.formatMessage(
        { id: 'causa' },
        { tags: resources.causa }
      ),
      valueField: 'causa',
      visible: true,
    },
    {
      headerText: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'areaColaboradorResponsavelKR' },
          {
            area: capitalizeFirstLetter(resources.area),
            responsavel: capitalizeFirstLetter(resources.responsavel),
          }
        )
      ),
      valueField: 'areaColaboradorResponsavel',
      visible: true,
    },
  ];

  if (customFields?.length > 0) {
    customFields.forEach((c) => {
      columns.push({
        headerText: c.campo?.nome,
        valueField: `CUSTOM-${c.campo?.id}`,
        visible: true,
        default: false,
        isCustomField: true,
        customFieldId: c.campo?.id,
      });
    });
  }

  return columns;
};

const getColumns = (
  intl,
  resources,
  newColumns,
  configurations,
  customFields
) => {
  const allColumns = getDefaultColumns(
    intl,
    resources,
    configurations,
    customFields
  );

  let columns = [];

  if (newColumns?.length > 0) {
    columns = newColumns.map((column) => {
      const index = allColumns.findIndex(
        (c) => c.valueField === column.valueField
      );

      if (index !== -1) {
        allColumns[index].width = column.width ? column.width : null;
        return allColumns[index];
      }

      return null;
    });
  } else {
    columns = allColumns.filter((c) => c.visible && c.default);
  }

  return columns?.filter((x) => x != null);
};

const fillChildren = (tasks, allTasks) => {
  const newList = [];

  tasks.forEach((t) => {
    const newModel = taskMapper(t);

    const children = allTasks.filter((x) => x.pai?.id === t.id);
    const hasChildren = children?.length > 0;

    if (hasChildren) newModel.children = fillChildren(children, allTasks);

    newList.push(newModel);
  });

  return newList;
};

const getMappedTasks = (items) => {
  const tasksNoParents = items.filter((x) => !x.pai) || [];
  return fillChildren(tasksNoParents, items);
};

const getMappedColumns = (columns, intl, user, appInfoModel, allRecords) => {
  const everyCodeIsNumber = !(
    allRecords.find((c) => isNaN(c.codigoOrdenacao)) != null
  );
  return columns?.map((c) => {
    return columnMapper(c, intl, user, appInfoModel, everyCodeIsNumber);
  });
};

const convertCalendar = ({
  feriados,
  definirSabadoComoDiaUtil,
  definirDomingoComoDiaUtil,
}) => {
  let intervals = [];

  // Domingo

  if (definirDomingoComoDiaUtil) {
    intervals.push({
      recurrentStartDate: 'on Sun at 17:00',
      recurrentEndDate: 'on Mon at 09:00',
      isWorking: false,
    });
  } else {
    intervals.push({
      recurrentStartDate: 'on Sun at 09:00',
      recurrentEndDate: 'on Mon at 09:00',
      isWorking: false,
    });
  }

  // Dias da semana

  intervals = [
    ...intervals,
    ...[
      {
        recurrentStartDate: 'on Mon at 17:00',
        recurrentEndDate: 'on Tue at 09:00',
        isWorking: false,
      },
      {
        recurrentStartDate: 'on Tue at 17:00',
        recurrentEndDate: 'on Wed at 09:00',
        isWorking: false,
      },
      {
        recurrentStartDate: 'on Wed at 17:00',
        recurrentEndDate: 'on Thu at 09:00',
        isWorking: false,
      },
      {
        recurrentStartDate: 'on Thu at 17:00',
        recurrentEndDate: 'on Fri at 09:00',
        isWorking: false,
      },
      {
        recurrentStartDate: 'on Fri at 17:00',
        recurrentEndDate: 'on Sat at 09:00',
        isWorking: false,
      },
    ],
  ];

  // Sábado

  if (definirSabadoComoDiaUtil) {
    intervals.push({
      recurrentStartDate: 'on Sat at 17:00',
      recurrentEndDate: 'on Sun at 09:00',
      isWorking: false,
    });
  } else {
    intervals.push({
      recurrentStartDate: 'on Sat at 09:00',
      recurrentEndDate: 'on Sun at 09:00',
      isWorking: false,
    });
  }

  // Feriados

  if (feriados?.length > 0) {
    feriados?.map((f) => {
      intervals.push({
        startDate: f.data,
        endDate: addDays(f.data, 1),
        isWorking: false,
      });
    });
  }

  return new CalendarModel({
    name: 'Default',
    intervals,
  });
};

const getDefaultCalendar = () => {
  const calendar = {
    feriados: [],
    definirSabadoComoDiaUtil: false,
    definirDomingoComoDiaUtil: false,
  };

  return convertCalendar(calendar);
};

const getProjectModel = async (startDate, items, links, calendar) => {
  const { accessToken } = getSession();
  const { encryptedData, iv } = await cardMetasManager.getList();

  const projetctModel = new ProjectModel({
    startDate: new Date(startDate),
    hoursPerDay: 8,
    autoSync: true,
    taskModelClass: TaskModelClass,
    transport: {
      sync: {
        url: `${ConfigApp.ApiUrl}/Acao/SincronizarAcoesGantt`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ContentType: 'application/json',
          ...(encryptedData
            ? {
                AllowedItemIdsByCardMetas: encryptedData,
                AllowedItemIdsByCardMetas_IV: iv,
              }
            : {}),
        },
        method: 'POST',
      },
    },
    tasksData: items,
    dependenciesData: links,
    calendar: calendar ? convertCalendar(calendar) : getDefaultCalendar(),
    autoCalculatePercentDoneForParentTasks: false,
    maxCalendarRange: MAX_YEARS_RANGE_CALCULATION,
  });

  return projetctModel;
};

const handleUpdateUrl = (
  history,
  itemId,
  centroCustoId,
  pacoteId,
  responsavelId,
  acoesItensFormula = false
) => {
  let url = '';

  if (itemId) {
    url += `itemId=${itemId}&acoesItensFormula=${
      acoesItensFormula != null ? acoesItensFormula : false
    }`;
  }

  if (centroCustoId) {
    url += `&centroCustoId=${centroCustoId}`;
  }

  if (pacoteId) {
    url += `&pacoteId=${pacoteId}`;
  }

  if (responsavelId) {
    url += `&responsavelId=${responsavelId}`;
  }

  history.push({
    pathname: '/gerenciar/gantt',
    search: `?${url}`,
  });
};

const renderUserColumn = ({ nome, fotoArquivo }) =>
  `<div class="d-flex align-items-center">
        <div class="mr-2">
          ${
            fotoArquivo
              ? `<img
            style="width: 30px; height: 30px; border-radius: 50%;"
            alt="Colaborador"
            src="${ConfigApp.ApiUrl}/ArquivoSistema/DownloadImagem?guid=${fotoArquivo?.guid}"
          />`
              : `<div
            style="display: flex; justify-content: center; align-items: center; color: #FFF; width: 30px; height: 30px; border-radius: 50%; background: #BCBCBC;"
          >${getInitials(nome)}</div>`
          }
        </div>
        <div>${nome}</div>
      </div>`;

const renderTags = ({ record }) => {
  return (
    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
      {record.tags && record.tags.length > 0 && (
        <div>
          {record.tags.map((t, index) => (
            <div
              key={index}
              className="mb-1 mr-1"
              style={{ display: 'inline-block', marginRight: '10px' }}
            >
              <Badge
                pill
                className="text-white text-sm"
                style={{ background: t?.cor }}
              >
                <div className="text-truncate" style={{ maxWidth: 120 }}>
                  {t?.descricao}
                </div>
              </Badge>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const columnMapper = (
  { headerText, valueField, width, isCustomField, customFieldId },
  intl,
  user,
  { decimalSeparator, thousandsSeparator },
  everyCodeIsNumber
) => {
  const dateFormat = user.idioma === 'en-us' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  const BN = BigNumber.clone({
    FORMAT: {
      groupSize: 3,
      groupSeparator: thousandsSeparator,
      decimalSeparator: decimalSeparator,
    },
  });
  const finalWidth = width ? parseInt(width, 10) : null;

  switch ((headerText, valueField)) {
    case 'codigo': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        field: 'codigo',
        editor: true,
        filterType: 'string',
        sortable: (first, second) => {
          return everyCodeIsNumber
            ? parseInt(first.codigoOrdenacao, 10) <
              parseInt(second.codigoOrdenacao, 10)
              ? -1
              : 1
            : first.codigoOrdenacao < second.codigoOrdenacao
            ? -1
            : 1;
        },
        filterable: ({ value, record }) => {
          return record?.codigo?.toLowerCase()?.includes(value?.toLowerCase());
        },
        uniqueKey: 'codigo',
        width: finalWidth,
      };
    }
    case 'acoes': {
      return {
        type: 'name',
        text: headerText,
        tooltip: headerText,
        field: 'name',
        tooltipRenderer: ({ record }) => {
          return record.name;
        },
        uniqueKey: 'acoes',
        width: finalWidth,
      };
    }
    case 'dataInicio': {
      return {
        type: 'startdate',
        text: headerText,
        tooltip: headerText,
        field: 'startDate',
        format: dateFormat,
        instantUpdate: false,
        uniqueKey: 'dataInicio',
        width: finalWidth || 150,
      };
    }
    case 'dataTermino': {
      return {
        type: 'enddate',
        text: headerText,
        tooltip: headerText,
        field: 'endDate',
        format: dateFormat,
        instantUpdate: false,
        uniqueKey: 'dataTermino',
        width: finalWidth || 150,
      };
    }
    case 'percentualPrevisto': {
      return {
        type: 'percentdone',
        text: headerText,
        tooltip: headerText,
        align: 'center',
        showCircle: true,
        field: 'percentualPrevisto',
        editor: null,
        uniqueKey: 'percentualPrevisto',
        width: finalWidth,
      };
    }
    case 'percentualRealizado': {
      return {
        type: 'percentdone',
        text: headerText,
        tooltip: headerText,
        align: 'center',
        showCircle: true,
        field: 'percentDone',
        editor: true,
        uniqueKey: 'percentualRealizado',
        width: finalWidth,
      };
    }
    case 'responsavel': {
      return {
        type: 'user',
        text: headerText,
        tooltip: headerText,
        field: 'responsavelId',
        filterType: 'string',
        sortable: (first, second) => {
          return first.responsavel?.nome < second.responsavel?.nome ? -1 : 1;
        },
        filterable: ({ value, record }) => {
          return record?.responsavel?.nome
            ?.toLowerCase()
            ?.includes(value?.toLowerCase());
        },
        renderer: ({ record }) => {
          return record.responsavel ? renderUserColumn(record.responsavel) : '';
        },
        uniqueKey: 'responsavel',
        width: finalWidth,
      };
    }
    case 'duracao': {
      return {
        type: 'number',
        text: headerText,
        tooltip: headerText,
        field: 'duration',
        uniqueKey: 'duracao',
        width: finalWidth,
      };
    }
    case 'baselineInicial': {
      return {
        type: 'date',
        text: headerText,
        tooltip: headerText,
        field: 'baselineInicial',
        editor: null,
        format: dateFormat,
        uniqueKey: 'baselineInicial',
        width: finalWidth,
      };
    }
    case 'baselineTermino': {
      return {
        type: 'date',
        text: headerText,
        tooltip: headerText,
        field: 'baselineTermino',
        editor: null,
        format: dateFormat,
        uniqueKey: 'baselineTermino',
        width: finalWidth,
      };
    }
    case 'dataInicioRealizada': {
      return {
        type: 'date',
        text: headerText,
        tooltip: headerText,
        field: 'dataInicioRealizada',
        editor: null,
        format: dateFormat,
        uniqueKey: 'dataInicioRealizada',
        width: finalWidth,
      };
    }
    case 'dataTerminoRealizada': {
      return {
        type: 'date',
        text: headerText,
        tooltip: headerText,
        field: 'dataTerminoRealizada',
        editor: null,
        format: dateFormat,
        uniqueKey: 'dataTerminoRealizada',
        width: finalWidth,
      };
    }
    case 'idIntegracaoItensVinculados': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          return record.itensVinculados?.map((x) => x.idIntegracao)?.join('; ');
        },
        editor: null,
        uniqueKey: 'idIntegracaoItensVinculados',
        width: finalWidth,
      };
    }
    case 'tipoAcao': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        field: 'tipoAcao',
        filterType: 'string',
        sortable: (first, second) => {
          return first.tipoAcao < second.tipoAcao ? -1 : 1;
        },
        filterable: ({ value, record }) => {
          const matchPrev = intl
            .formatMessage({
              id: 'preventiva',
            })
            ?.toLowerCase()
            ?.includes(value?.toLowerCase());

          const matchCorr = intl
            .formatMessage({
              id: 'corretiva',
            })
            ?.toLowerCase()
            ?.includes(value?.toLowerCase());

          return (
            (matchPrev && matchCorr) ||
            (matchPrev && record.tipoAcao === 1) ||
            (matchCorr && record.tipoAcao !== 1)
          );
        },
        renderer: ({ record }) => {
          if (record.readOnly) return '';

          return intl.formatMessage({
            id: record.tipoAcao === 1 ? 'preventiva' : 'corretiva',
          });
        },
        editor: null,
        uniqueKey: 'tipoAcao',
        width: finalWidth,
      };
    }
    case 'checklist': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        field: 'checklist',
        renderer: ({ record }) => {
          const { checklist } = record;
          const finished = checklist?.filter((x) => x.concluida).length;
          const total = checklist?.length;

          return `${finished}/${total}`;
        },
        editor: null,
        uniqueKey: 'checklist',
        width: finalWidth,
      };
    }
    case 'predecessoras': {
      return {
        type: 'predecessor',
        text: headerText,
        tooltip: headerText,
        uniqueKey: 'predecessoras',
        width: finalWidth,
      };
    }
    case 'sucessoras': {
      return {
        type: 'successor',
        text: headerText,
        tooltip: headerText,
        uniqueKey: 'sucessoras',
        width: finalWidth,
      };
    }
    case 'criadorAcao': {
      return {
        type: 'user',
        text: headerText,
        tooltip: headerText,
        field: 'criadorId',
        editor: null,
        filterType: 'string',
        sortable: (first, second) => {
          return first.criadorAcao?.nome < second.criadorAcao?.nome ? -1 : 1;
        },
        filterable: ({ value, record }) => {
          return record?.criadorAcao?.nome
            ?.toLowerCase()
            ?.includes(value?.toLowerCase());
        },
        renderer: ({ record }) => {
          return record.criadorAcao ? renderUserColumn(record.criadorAcao) : '';
        },
        uniqueKey: 'criadorAcao',
        width: finalWidth,
      };
    }
    case 'dataCriacaoAcao': {
      return {
        type: 'date',
        text: headerText,
        tooltip: headerText,
        field: 'dataCriacaoAcao',
        editor: null,
        format: dateFormat,
        uniqueKey: 'dataCriacaoAcao',
        width: finalWidth,
      };
    }
    case 'farol': {
      return {
        type: 'farol',
        text: headerText,
        tooltip: headerText,
        uniqueKey: 'farol',
        width: finalWidth,
      };
    }
    case 'status': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          const isAction = record.id > 0;
          return isAction ? record.status?.descricao : null;
        },
        editor: null,
        sortable: (first, second) => {
          return first.status?.descricao < second.status?.descricao ? -1 : 1;
        },
        filterable: ({ value, record }) => {
          return record?.status?.descricao
            ?.toLowerCase()
            ?.includes(value?.toLowerCase());
        },
        uniqueKey: 'status',
        width: finalWidth,
      };
    }
    case 'investimentoPrevisto': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          const value = record.investimentoPrevisto;

          if (value == null) return '';

          const newValue = new BN(value);
          return newValue.toFormat(2);
        },
        editor: null,
        uniqueKey: 'investimentoPrevisto',
        width: finalWidth,
      };
    }
    case 'investimentoRealizado': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          const value = record.investimentoRealizado;

          if (value == null) return '';

          const newValue = new BN(value);
          return newValue.toFormat(2);
        },
        editor: null,
        uniqueKey: 'investimentoRealizado',
        width: finalWidth,
      };
    }
    case 'custeioPrevisto': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          const value = record.custeioPrevisto;

          if (value == null) return '';

          const newValue = new BN(value);
          return newValue.toFormat(2);
        },
        editor: null,
        uniqueKey: 'custeioPrevisto',
        width: finalWidth,
      };
    }
    case 'custeioRealizado': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          const value = record.custeioRealizado;

          if (value == null) return '';

          const newValue = new BN(value);
          return newValue.toFormat(2);
        },
        editor: null,
        uniqueKey: 'custeioRealizado',
        width: finalWidth,
      };
    }
    case 'metaGanhosPorAcao': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          return record.metaGanhosPorAcao;
        },
        editor: null,
        uniqueKey: 'metaGanhosPorAcao',
        width: finalWidth,
      };
    }
    case 'realizadoGanhosPorAcao': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          return record.realizadoGanhosPorAcao;
        },
        editor: null,
        uniqueKey: 'realizadoGanhosPorAcao',
        width: finalWidth,
      };
    }
    case 'desvioGanhosPorAcao': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          return record.desvioGanhosPorAcao;
        },
        editor: null,
        uniqueKey: 'desvioGanhosPorAcao',
        width: finalWidth,
      };
    }
    case 'esforco': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          return record.esforco;
        },
        editor: null,
        uniqueKey: 'esforco',
      };
    }
    case 'tags': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        field: 'tags',
        renderer: ({ record }) => renderTags({ record }),
        tooltipRenderer: ({ record }) => {
          return `<div style="display: flex;">
              ${record.tags
                ?.map(
                  (t) => `
                <div style="background: ${t.cor}; color: white; border-radius: 2rem; padding-left: 10px; padding-right: 10px; margin-left: 4px; margin-right: 4px;">
                  ${t.descricao}
                </div>
              `
                )
                .join(' ')}
            </div>`;
        },
        uniqueKey: 'tags',
        editor: null,
        width: finalWidth,
      };
    }
    case 'causa': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          return record.causasImpactadas;
        },
        tooltipRenderer: ({ record }) => {
          return record.causasImpactadas;
        },
        editor: null,
        uniqueKey: 'causa',
      };
    }
    case 'areaColaboradorResponsavel': {
      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          return record?.areaColaboradorResponsavel?.nome;
        },
        tooltipRenderer: ({ record }) => {
          return record?.areaColaboradorResponsavel?.nome;
        },
        filterable: ({ value, record }) => {
          return record?.areaColaboradorResponsavel?.nome
            ?.toLowerCase()
            ?.includes(value?.toLowerCase());
        },
        editor: null,
        uniqueKey: 'areaColaboradorResponsavel',
      };
    }

    default: {
      if (!isCustomField) return {};

      const getValue = (record) => {
        return (
          record?.customFields?.find((x) => x.campo?.id === customFieldId)
            ?.valor ?? null
        );
      };

      return {
        type: 'text',
        text: headerText,
        tooltip: headerText,
        renderer: ({ record }) => {
          return getValue(record);
        },
        tooltipRenderer: ({ record }) => {
          return getValue(record);
        },
        editor: null,
      };
    }
  }
};

const taskMapperForUpdates = (task) => {
  const newModel = taskMapper(task);

  delete newModel.planoAcaoId;
  delete newModel.planoAcaoExterno;
  delete newModel.temDependencias;
  delete newModel.expanded;
  delete newModel.editarDataPrevista;
  delete newModel.editarDataRealizadaPercentualObservacao;
  delete newModel.editarDemaisCampos;

  return newModel;
};

const taskMapper = (task) => {
  const {
    id,
    descricao,
    codigo,
    dataInicioPrevista,
    dataTerminoPrevista,
    dataInicioRealizada,
    dataTerminoRealizada,
    responsavel,
    criadorAcao,
    baselineInicial,
    baselineTermino,
    itensVinculados,
    tipoAcao,
    farolAcao,
    status,
    tarefas,
    percentualPrevisto,
    percentualRealizado,
    investimentoPrevisto,
    investimentoRealizado,
    custeioPrevisto,
    custeioRealizado,
    dataCriacaoAcao,
    editarDataPrevista,
    editarDemaisCampos,
    planoAcaoId,
    planoAcaoExterno,
    expandir,
    codigoOrdenacao,
    duracao,
    metaGanhosPorAcao,
    realizadoGanhosPorAcao,
    desvioGanhosPorAcao,
    utilizarDatasJaSalvas,
    temDependencias,
    interacoesLiberadasEntreContextos,
    allowCreate,
    camposCustomizados,
    esforco,
    tags,
    causasImpactadas,
    areaColaboradorResponsavel,
  } = task;

  const isMilestone = duracao == 0;

  if (dataInicioPrevista)
    dataInicioPrevista.setHours(isMilestone ? 17 : 9, 0, 0, 0);
  if (dataTerminoPrevista) dataTerminoPrevista.setHours(17, 0, 0, 0);

  // Ações inativas irão parar de ser "alimentadas" por predecessoras, etc, por limitação
  // do bryntum até o momento (manuallyScheduled = false, irá retornar erro em determinados
  // cenários)

  const isInactive = status?.id === STATUS_ACAO.CANCELADA;

  const newTask = {
    id: id,
    name: descricao,
    startDate: dataInicioPrevista,
    endDate: dataTerminoPrevista,
    farol: farolAcao,
    checklist: tarefas,
    percentDone: percentualRealizado || 0,
    percentualPrevisto: percentualPrevisto || 0,
    responsavelId: responsavel?.id,
    criadorId: criadorAcao?.id,
    expanded: expandir,
    baselineInicial: baselineInicial && new Date(baselineInicial),
    baselineTermino: baselineTermino && new Date(baselineTermino),
    dataInicioRealizada: dataInicioRealizada && new Date(dataInicioRealizada),
    dataTerminoRealizada:
      dataTerminoRealizada && new Date(dataTerminoRealizada),
    readOnly: id < 0,
    utilizarDatasJaSalvas:
      utilizarDatasJaSalvas != null ? utilizarDatasJaSalvas : false,
    inactive: isInactive,
    manuallyScheduled: isInactive,
    customFields: camposCustomizados,
    esforco,
    status,
    tipoAcao,
    itensVinculados,
    codigo,
    editarDataPrevista,
    editarDemaisCampos,
    planoAcaoId,
    planoAcaoExterno,
    dataCriacaoAcao,
    // --- UTILIZADOS PARA FILTRO ---
    responsavel,
    criadorAcao,
    // ---
    codigoOrdenacao,
    investimentoPrevisto,
    investimentoRealizado,
    custeioPrevisto,
    custeioRealizado,
    metaGanhosPorAcao,
    realizadoGanhosPorAcao,
    desvioGanhosPorAcao,
    temDependencias,
    interacoesLiberadasEntreContextos,
    allowCreate,
    tags,
    causasImpactadas,
    areaColaboradorResponsavel,
  };

  if (baselineInicial && baselineTermino) {
    newTask.baselines = [];
    newTask.baselines.push({
      startDate: baselineInicial,
      endDate: baselineTermino,
    });
  }

  if (duracao != null) newTask.duration = duracao;

  return newTask;
};

const handleDownloadFile = async ({ response }) => {
  if (response?.status != 200) return;

  try {
    let file = await response.arrayBuffer();
    file = new Blob([file], { type: undefined });
    saveAs(file, 'Gantt.pdf');
  } catch (err) {
    errorHandler(err);
  }
};

export {
  getColumns,
  getDefaultColumns,
  getMappedColumns,
  getMappedTasks,
  getProjectModel,
  handleUpdateUrl,
  taskMapper,
  taskMapperForUpdates,
  columnMapper,
  handleHttp,
  getDefaultTask,
  handleDownloadFile,
};
