import React from 'react';
import moment from 'moment';
import { autobind } from 'core-decorators';
import List from 'src/componentes/list/tree';
import ListColumn from 'src/componentes/list/list-column';
import ComunicadoRiscoEdit from './edit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Avatar from 'src/componentes/avatar';
import AvatarGroup from 'src/componentes/AvatarGroup';
import Info from 'src/componentes/info';
@autobind
class ComunicadoEventoRiscoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: -1,
      savedAfterRemove: -1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshAfterRemove !== this.props.refreshAfterRemove) {
      this.setState({ saved: this.state.saved + 1 });
    }
  }

  handleCloseEdit(saved) {
    if (saved) {
      const refreshTemp = Math.random();
      this.props.refreshCount && this.props.refreshCount(refreshTemp);
      this.setState({ saved: refreshTemp });
    }
  }

  renderContentInfoStatus() {
    return (
      <div >
        {this.props.intl.formatMessage({
            id: 'hint.prontoEnvio',
          })}
      </div>
    )
  }

  renderDataEnvio(i) {
    return (
      <div >
        {i.dataEnvio && i.status?.id === 1 ? moment(i.dataEnvio).locale('pt-Br').format('DD/MM/YYYY') : ''}
      </div>
    )
  }

  renderStatus(i) {
    return i.status?.id === 1 ? (
      this.props.intl.formatMessage({ id: 'enviado' })
    ) : (
      <>
        {this.props.intl.formatMessage({ id: 'prontoEnvio' })}
        <Info
          id="prontoEnvio"
          placement="right"
          renderContent={this.renderContentInfoStatus}
        />
      </>
    );
  }

  renderResponsavel(item) {
    return (
      <Avatar
        size={37}
        showLabel
        showTooltip
        foto={item?.responsavel?.fotoArquivo}
        label={item?.responsavel?.nome}
      />
    );
  }

  renderDestinatarios(item) {
    return (
      <AvatarGroup max={5} avatarSize={37}>
        {item.destinatarios.length > 0 &&
          item.destinatarios.map((c) => (
            <Avatar
              showTooltip
              id={`avatar-${c.id}-${item.id}`}
              foto={c.fotoArquivo}
              label={c.nome}
            />
          ))}
      </AvatarGroup>
    );
  }

  render() {
    const { configuracao, disabled, intl } = this.props;
    return (
      <List
        editComponent={ComunicadoRiscoEdit}
        url="/EventoRiscoComunicado"
        showDelete={true}
        showExportItem={false}
        showExport={false}
        valueField="descricao"
        saved={this.state.saved}
        showNew={!disabled}
        showMenu={!disabled}
        parameters={{ eventoRiscoId: this.props.eventoRiscoId }}
        afterSave={this.props.refreshCount}
        afterDelete={this.props.refreshCount}
        local
        modelValue={this.props.model.value}
        dataSource={this.props.model.value}
        {...this.props}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'titulo' })}
          valueField="titulo"
          required
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'label.responsavel' })}
          valueField="responsavel"
          valueFunction={this.renderResponsavel}
          required
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'label.destinatarios' })}
          valueField="destinatarios"
          valueFunction={this.renderDestinatarios}
          required
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'dataEnvio' })}
          valueField="dataEnvio"
          valueFunction={this.renderDataEnvio}
        />
        <ListColumn
          headerText={intl.formatMessage({ id: 'status' })}
          valueField="titulo"
          valueFunction={this.renderStatus}
        />
      </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ComunicadoEventoRiscoList));
