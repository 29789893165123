import React, { Suspense } from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { Table } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import LoadingScreen from 'src/componentes/LoadingScreen';
import guid from 'src/utils/guid';
import DropDown from '../../dropdown';
import PolaridadeGrafico from './polaridade-grafico';

const Grafico = React.lazy(() => import('src/componentes/chart'));

@autobind
class GraficoItem extends React.Component {
  static defaultProps = {
    graficoPizza: false,
  };

  renderColunas() {
    let categories = this.props.grafico.tabelaGrafico.colunas;
    return categories && categories.map((category) => <th>{category}</th>);
  }

  renderDados() {
    const itens = this.props.grafico.tabelaGrafico.dados;
    const { filtro } = this.props;

    return itens.map((item) => (
      <tr>
        {item.item.map((x) => (
          <td>
            {x.item ? (
              <a tabIndex="-1">
                <DropDown
                  showMenu={!this.props.isConsultaExterna}
                  handleCloseModal={this.props.handleCloseModal}
                  filtro={filtro}
                  item={x.item}
                  renderLabel={this.props.renderLabel}
                />
              </a>
            ) : (
              x.valor
            )}
          </td>
        ))}
      </tr>
    ));
  }

  dataPlot(ev, props) {
    // Recupera o DataSet do gráfico
    let graficoP = this.props.grafico;
    let dataset = null;

    //se não for de colunas empilhadas
    if (!this.props.graficoColunasEmpilhadas) {
      dataset =
        graficoP && graficoP.dataSet && graficoP.dataSet.length > 0
          ? graficoP.dataSet[0]
          : null;
    }
    //se for golunas empilhadas repuera o dataset clicado
    else {
      dataset =
        graficoP &&
        graficoP.dataSet &&
        graficoP.dataSet.length > 0 &&
        ev &&
        ev.data
          ? graficoP.dataSet[ev.data.datasetIndex]
          : null;
    }

    // Verifica se tem dataSet
    if (dataset) {
      // Tenta recuperar o objeto clicado
      let dado = dataset.data[props.index];
      // Chama o método de data clicado passando o Dado recuperado
      if (this.props.dataClick) {
        !this.props.graficoColunasEmpilhadas
          ? this.props.dataClick(dado)
          : this.props.dataClick(
              dado,
              ev.data.categoryLabel,
              ev.data.datasetName
            );
      }
    }
  }

  tituloPersonalizado(item, tituloPersonalizado) {
    let {
      titulo,
      unidadeMedidaPersonalizada,
      background,
      marginBottom,
      polaridade,
    } = tituloPersonalizado;

    return (
      <div
        style={{
          fontSize: 14,
          overflow: 'hidden',
          width: '100%',
          minHeight: 30,
          backgroundColor: background ? background : 'transparent',
          padding: '5px 10px',
          textAlign: 'left',
          marginBottom: marginBottom != null ? marginBottom : 15,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '30vw',
            }}
          >
            {titulo}
          </div>

          <PolaridadeGrafico polaridade={polaridade} item={item} />
        </div>
        {tituloPersonalizado.unidadeMedida ? (
          <span style={{ fontSize: 12 }}>
            {item &&
              item.unidadeMedida &&
              item.tipo &&
              item.tipo.id != 4 &&
              item.unidadeMedida.descricao}
          </span>
        ) : (
          unidadeMedidaPersonalizada != null && (
            <span style={{ fontSize: 12 }}>{unidadeMedidaPersonalizada}</span>
          )
        )}
      </div>
    );
  }

  render() {
    const {
      grafico,
      showTable = false,
      item,
      graficoPizza,
      tituloPersonalizado = null,
      showChart = true,
    } = this.props;

    let dadosGrafico =
      grafico && grafico.dataSet && grafico.dataSet.length > 0 ? true : false;
    let tipoDoGrafico =
      grafico && grafico.tipoGrafico != null
        ? grafico.tipoGrafico
        : 'MSCombi2D';
    let config;

    if (tipoDoGrafico == 'Bubble' || tipoDoGrafico == 'Scatter') {
      config = {
        width: '100%',
        height: showTable
          ? this.props.height
            ? this.props.height / 2
            : '400'
          : this.props.height
          ? this.props.height - 10
          : '400',
        type: tipoDoGrafico,
        containerBackgroundOpacity:
          grafico && grafico.containerBackgroundOpacity,
        dataFormat: item ? null : 'json',
        dataSource: {
          chart: {
            ...(grafico && grafico.chart),
            caption:
              tituloPersonalizado == null
                ? item
                  ? item && item.nome
                  : ' '
                : ' ',
          },
          annotations:
            grafico && grafico.annotations ? grafico.annotations : null,
          categories:
            grafico && grafico.usarCategorias
              ? grafico.categories
              : [
                  {
                    verticalLineDashed: '1',
                    verticalLineDashLen: '1',
                    verticalLineDashGap: '1',
                    verticalLineThickness: '1',
                    verticalLineColor: '#000000',
                    category: grafico.dataSet && grafico.dataSet[0].data,
                  },
                ],
          dataSet: dadosGrafico ? grafico.dataSet : null,
        },
        events: {
          dataPlotClick: this.dataPlot.bind(this),
        },
      };
    }

    if (
      tipoDoGrafico == 'msbar2d' ||
      tipoDoGrafico == 'Bar2D' ||
      tipoDoGrafico == 'MSCombi2D' ||
      tipoDoGrafico == 'StackedColumn2d' ||
      tipoDoGrafico == 'mscombidy2d' ||
      tipoDoGrafico == 'stackedbar2d'
    )
      config = {
        width: '100%',
        height: showTable
          ? this.props.height
            ? this.props.height / 2
            : '400'
          : this.props.height
          ? this.props.height - 10
          : '400',
        type: tipoDoGrafico,
        containerBackgroundOpacity:
          grafico && grafico.containerBackgroundOpacity,
        dataFormat: item ? null : 'json',
        dataSource: {
          chart: {
            ...(grafico && grafico.chart),
            caption:
              tituloPersonalizado == null
                ? item
                  ? item && item.nome
                  : ' '
                : ' ',
            chartRightMargin: 60,
          },
          annotations:
            grafico && grafico.annotations ? grafico.annotations : null,
          categories: grafico && grafico.categories,
          data: dadosGrafico
            ? grafico.dataSet && grafico.dataSet[0].data
            : null,
          dataset: dadosGrafico ? grafico.dataSet : null,
        },
        events: {
          dataPlotClick: this.dataPlot.bind(this),
        },
      };

    if (graficoPizza || tipoDoGrafico == 'Pie2D')
      config = {
        width: '100%',
        height: this.props.height ? this.props.height - 50 : '400',
        type:
          grafico && grafico.tipoGrafico != null
            ? grafico.tipoGrafico
            : 'mscombi2d',
        containerBackgroundOpacity:
          grafico && grafico.containerBackgroundOpacity,
        dataFormat: item ? null : 'json',
        dataSource: {
          chart: {
            ...(grafico && grafico.chart),
            captionPadding: 0,
            chartLeftMargin: 0,
            chartRightMargin: 0,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            legendNumColumns: 2,
            legendItemFontSize: 11,
            caption:
              tituloPersonalizado == null
                ? item
                  ? item && item.nome
                  : ' '
                : ' ',
            bgColor: this.props.transparent
              ? '#FFFFFF00'
              : grafico?.chart.bgColor, // Setting the background color to transparent
            bgAlpha: this.props.transparent ? '0' : grafico?.chart.bgAlpha,
            //"subcaption": grafico && grafico.tipoTabela === "Desvio" ? grafico && grafico.chart && grafico.chart.subcaption : null,
          },
          annotations:
            grafico && grafico.annotations ? grafico.annotations : null,
          categories: grafico && grafico.categories,
          data: dadosGrafico
            ? grafico.dataSet[0] && grafico.dataSet[0].data
            : null,
        },
      };

    if (tipoDoGrafico == 'Pareto2D')
      config = {
        width: '100%',
        height: showTable
          ? this.props.height
            ? this.props.height / 2
            : '400'
          : this.props.height
          ? this.props.height - 10
          : '400',
        type: tipoDoGrafico,
        containerBackgroundOpacity:
          grafico && grafico.containerBackgroundOpacity,
        dataFormat: item ? null : 'json',
        dataSource: {
          chart: {
            ...(grafico && grafico.chart),
            caption:
              tituloPersonalizado == null
                ? item
                  ? item && item.nome
                  : ' '
                : ' ',
          },
          annotations:
            grafico && grafico.annotations ? grafico.annotations : null,
          categories: grafico && grafico.categories,
          data: dadosGrafico
            ? grafico.dataSet && grafico.dataSet[0].data
            : null,
          dataset: dadosGrafico ? grafico.dataSet : null,
        },
      };

    return (
      <Suspense fallback={<LoadingScreen />}>
        {showChart && (
          <Row id={`relatorioPag${!item ? '' : item && item.id}`}>
            <Col style={{ borderSpacing: 0, padding: 2 }}>
              {tituloPersonalizado &&
                this.tituloPersonalizado(item, tituloPersonalizado)}
              <Grafico key={guid()} {...config} id="graficoValoresItem" />
            </Col>
          </Row>
        )}
        <div>
          <Row>
            <Col md={12}>
              {showTable &&
                this.props.grafico &&
                this.props.grafico.tabelaGrafico.dados && (
                  <Table
                    hover
                    size="sm"
                    style={{
                      background: '#FFF',
                      marginTop: 15,
                      overflow: 'visible',
                    }}
                  >
                    <thead>
                      <tr>{this.renderColunas()}</tr>
                    </thead>
                    <tbody>{this.renderDados()}</tbody>
                  </Table>
                )}
            </Col>
          </Row>
        </div>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(GraficoItem));
