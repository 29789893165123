import React from 'react';
import { Card, CardTitle, CardBody, CardHeader, CardFooter } from 'reactstrap';

export default class CardCustom extends React.Component {
  render() {
    let {
      header,
      children,
      subtitle,
      footer,
      className,
      headerStyle,
      cardHeaderStyle = {},
      classBody = '',
      cardWidth,
      style,
      headerContent,
      ...rest
    } = this.props;

    return (
      <Card
        style={{ marginBottom: 15, ...style, width: cardWidth }}
        className={className}
        {...rest}
      >
        {header && (
          <CardHeader style={cardHeaderStyle}>
            {header && (
              <CardTitle style={{ ...(headerStyle && headerStyle) }}>
                {header}
              </CardTitle>
            )}
            {subtitle && (
              <h6 className="card-subtitle text-muted text-sm mt-2">
                {subtitle}
              </h6>
            )}
            {headerContent}
          </CardHeader>
        )}
        <CardBody className={classBody}>{children}</CardBody>
        {footer && <CardFooter>{footer}</CardFooter>}
      </Card>
    );
  }
}
