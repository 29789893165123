import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { capitalizeFirstLetter } from 'src/utils/string';
import useAuth from 'src/hooks/useAuth';
import Gantt from 'src/paginas/NewGantt';
import Button from 'src/componentes/button';
import Panel from 'src/componentes/panel-collapse';
import ContentManager from 'src/componentes/content-manager';
import AcaoEdit from 'src/paginas/acao/edit';

import { Table } from 'reactstrap';

const PlanoAcao = ({ item, model, planejamentoInicialId, allowEdit }) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const [data, setData] = useState([]);
  const [ganttKey, setGanttKey] = useState(1);

  useEffect(() => {
    let favoritados = obterRegistrosFavoritados(
      model && model.cincoPorquesModel
    );
    setData(favoritados || []);
  }, [model]);

  const obterRegistrosFavoritados = (objetos) => {
    var registrosFavoritados = [];

    objetos.forEach(function (objeto) {
      if (objeto.favoritado === true) {
        registrosFavoritados.push(objeto);
      }

      if (objeto.motivos && Array.isArray(objeto.motivos)) {
        var registrosFilhos = obterRegistrosFavoritados(objeto.motivos);
        registrosFavoritados = registrosFavoritados.concat(registrosFilhos);
      }
    });

    return registrosFavoritados;
  };

  const renderCausas = () => {
    return (
      <Table size="sm">
        <thead>
          <th>
            <div>{intl.formatMessage({ id: 'descricao' })}</div>
          </th>
          <th style={{ width: 35, maxWidth: 35 }}></th>
        </thead>
        <tbody>
          {data?.map((item) => {
            return (
              <tr>
                <td>
                  <span>{item.descricao}</span>
                </td>
                <td>
                  {allowEdit && (
                    <Button
                      outline
                      onClick={() => handleAddAcao(item.descricao)}
                      leftIcon="plus-circle"
                      color="primary"
                      tabIndex="-1"
                    >
                      {capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'acao' },
                          { acao: resources.acao }
                        )
                      )}
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const handleAddAcao = (causa) => {
    ContentManager.addContent(
      <AcaoEdit
        parameters={{
          itemId: item.id,
          causasImpactadas: causa,
          planejamentoInicialId: planejamentoInicialId,
        }}
        handleCallBack={handleCallBack}
      />
    );
  };

  const handleCallBack = () => {
    setGanttKey((prev) => prev + 1);
  };

  return (
    <>
      <Panel header={intl.formatMessage({ id: 'causasPorquesPriorizados' })}>
        <span>
          {intl.formatMessage(
            { id: 'msgCrieAcoesCausasPorquesPriorizados' },
            { acoes: resources.acoes }
          )}
        </span>
        <hr></hr>
        {renderCausas()}
      </Panel>
      <Gantt
        key={ganttKey}
        itemId={item.id}
        showToolbar={false}
        isReadOnly={!allowEdit}
      />
    </>
  );
};

export default PlanoAcao;
