export function prepareHtml(html) {
  if (!html) {
    return;
  }

  html = html.replace(/\<p[^>]*\>(?:\s|&nbsp;)*\<\/p\>/g, '');

  return { __html: html };
}

export function stripHtml(html) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}
