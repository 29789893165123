import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import ObjetivoList from 'src/paginas/okr/Objetivo/ListView';

@autobind
class Objetivo extends React.Component {
  render() {
    return <Lookup labelKey="descricao" valueKey="id" url="/ObjetivoLookup" searchComponent={ObjetivoList} showSearch {...this.props} />;
  }
}

export default Objetivo;
