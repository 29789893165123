import moment from 'moment';
import errorHandler from 'src/utils/error-handler';

export const listEvents = async (accessToken, currentDate) => {
  const startDateTime = moment(currentDate).startOf('week').toISOString();
  const endDateTime = moment(currentDate).endOf('week').toISOString();

  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=${startDateTime}&enddatetime=${endDateTime}&attendees`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch events');
    }

    const data = await response.json();
    const sortedEvents = data.value.sort(
      (a, b) => new Date(a.start.dateTime) - new Date(b.start.dateTime)
    );
    return sortedEvents;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
};

export const getSingleEventById = async (accessToken, id) => {
  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/me/calendar/events/${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch events');
    }

    const data = await response.json();

    return [data];
  } catch (error) {
    return [];
  }
};

export const createEvent = async (accessToken, eventDetails) => {
  try {
    const response = await fetch('https://graph.microsoft.com/v1.0/me/events', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventDetails),
    });

    if (!response.ok) {
      throw new Error('Failed to create event');
    }

    return await response.json();
  } catch (error) {
    errorHandler(error);
    throw error;
  }
};

export const searchUsers = async (accessToken, query) => {
  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${query}') or startswith(mail,'${query}')`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch users: ${response.statusText}`);
    }

    const data = await response.json();
    return data.value;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
};

export const deleteEvent = async (accessToken, eventId) => {
  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/me/events/${eventId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to delete event');
    }
    return response;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
};

export const fetchEvents = async (instance, currentDate) => {
  try {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) {
      throw new Error('No active account found');
    }

    const accessToken = await instance
      .acquireTokenSilent({
        scopes: ['https://graph.microsoft.com/Calendars.Read'],
        account: activeAccount,
      })
      .catch(() =>
        instance.acquireTokenPopup({
          scopes: ['https://graph.microsoft.com/Calendars.Read'],
        })
      );

    const eventsData = await listEvents(accessToken.accessToken, currentDate);
    return eventsData.filter((event) => !event.isCancelled);
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const updateEvent = async (accessToken, eventId, eventDetails) => {
  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/me/events/${eventId}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventDetails),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to update event');
    }

    return await response.json();
  } catch (error) {
    errorHandler(error);
    throw error;
  }
};

export const fetchEventById = async (instance, id) => {
  try {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) {
      throw new Error('No active account found');
    }

    const accessToken = await instance
      .acquireTokenSilent({
        scopes: ['https://graph.microsoft.com/Calendars.Read'],
        account: activeAccount,
      })
      .catch(() =>
        instance.acquireTokenPopup({
          scopes: ['https://graph.microsoft.com/Calendars.Read'],
        })
      );

    const eventsData = await getSingleEventById(accessToken.accessToken, id);
    return eventsData.filter((event) => !event.isCancelled);
  } catch (error) {
    console.error('Error fetching event:', error);
    throw error;
  }
};
