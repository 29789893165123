import React from 'react';

export default function jsxArray(...items) {
  return items.map((item, index) => {
    return (
      item &&
      React.cloneElement(item, {
        key: index.toString(),
      })
    );
  });
}
