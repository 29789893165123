import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownItem, PopoverBody, Popover } from 'reactstrap';

import Ishikawa from 'src/paginas/widget-custom/ishikawa';
import EditIshikawa from 'src/paginas/widget-custom/ishikawa/edit';
import ContentManager from 'src/componentes/content-manager';
import MdiIcon from 'src/componentes/mdi-icon';
import useAuth from 'src/hooks/useAuth';

const AnaliseCausa = ({ model, handleChangeData, allowEdit }) => {
  const [widgetId, setWidgetId] = useState(0);
  const { terms: resources } = useAuth();

  const renderComponent = (widget) => {
    return (
      <div>
        <div
          id={'widget' + widget.item.id}
          onMouseDown={(e) => handleEditClick(e, widget.item.id)}
          onContextMenu={(event) => event.preventDefault()}
        >
          {<Ishikawa widget={widget} showStar={false} />}
        </div>
        {allowEdit && (
          <Popover
            placement="auto"
            target={'widget' + widget.item.id}
            isOpen={widgetId == widget.item.id}
            toggle={(e) => handleEditClick(e, widget.item.id)}
          >
            <PopoverBody>
              <DropdownItem onClick={() => handleEditWidget(widget)}>
                <MdiIcon style={{ marginRight: 5 }} icon="pencil" />
                {
                  <FormattedMessage
                    id="label.editar"
                    values={{ editar: resources.editar }}
                  />
                }
              </DropdownItem>
            </PopoverBody>
          </Popover>
        )}
      </div>
    );
  };

  const handleSave = (dadosIshikawa, index) => {
    let newModel = Object.assign([], model.analiseCausasModel);
    let itemCorrente = newModel[index];

    if (itemCorrente) {
      itemCorrente.componente.dadosIshikawa = dadosIshikawa;
    }

    newModel[index] = itemCorrente;
    handleChangeData &&
      handleChangeData('step2Data', 'analiseCausasModel', newModel);

    handleChangeData && handleChangeData('step2Data', 'alterado', true);
  };

  const handleEditWidget = (widget) => {
    setWidgetId(0);

    ContentManager.addContent(
      <EditIshikawa
        widget={widget}
        showPropertiesStyle={false}
        handleSave={handleSave}
      />
    );
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    if (e.button == 2) setWidgetId(id);
    else if (e.button == 0) {
      setWidgetId(0);
    }
  };

  return (
    <div
      className="position-relative w-100 p-1"
      style={{
        minHeight:
          model &&
          model.analiseCausasModel &&
          model.analiseCausasModel.length > 0
            ? model.analiseCausasModel.length * 600 + 10
            : 600,
        marginTop: '15px',
      }}
    >
      {model &&
        model.analiseCausasModel &&
        model.analiseCausasModel.length > 0 &&
        model.analiseCausasModel.map((item, index) => (
          <div
            style={{
              position: 'absolute',
              zIndex: item.zIndex,
              marginTop: item.y,
            }}
          >
            <div>{renderComponent({ item: item, index: index })}</div>
          </div>
        ))}
    </div>
  );
};

export default AnaliseCausa;
