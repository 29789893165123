import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import Checkbox from 'src/componentes/checkbox';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
import IdiomaLookup from 'src/lookups/idioma';
import PerfilLookup from 'src/lookups/perfil';
import Input from 'src/componentes/input';
import CheckBox from 'src/componentes/checkbox';
import StatusColaboradorLookup from 'src/lookups/status-colaborador';
import CondicionalLookup from 'src/lookups/condicional';
import ModuloLookup from 'src/lookups/ModuloLookup';

@autobind
class ColaboradorSearch extends React.Component {
  render() {
    let { model, resources, intl } = this.props;
    return (
      <Row>
        <Col md={3}>
          <Input model={model.getModel('nome')} />
        </Col>
        <Col md={3}>
          <ItemOrganizacaoLookup
            model={{
              label: capitalizeFirstLetter(resources.area),
              value: model.getModel('area').value,
              requestChange: model.getModel('area').requestChange,
            }}
            data={{ tiposIdSelecionaveis: [1, 2, 3] }}
          />
        </Col>
        <Col md={3}>
          <Checkbox
            model={{
              label: intl.formatMessage(
                { id: 'label.considerarAreasSubordinadas' },
                { areas: resources.areas }
              ),
              value: model.getModel('areasSubordinadas').value,
              requestChange: model.getModel('areasSubordinadas').requestChange,
            }}
            disabled={!model.getModel('area').value}
            style={{ marginTop: 27 }}
          />
        </Col>
        <Col md={3}>
          <PerfilLookup model={model.getModel('perfis')}></PerfilLookup>
        </Col>
        <Col md={3}>
          <IdiomaLookup model={model.getModel('idioma')}></IdiomaLookup>
        </Col>
        <Col md={3}>
          <Input model={model.getModel('email')} />
        </Col>
        <Col md={3}>
          <CheckBox
            style={{ marginTop: 30 }}
            model={model.getModel('bloqueadoTentativaLogin')}
          />
        </Col>
        <Col md={3}>
          <Input model={model.getModel('idIntegracao')} />
        </Col>
        <Col md={3}>
          <StatusColaboradorLookup model={model.getModel('status')} />
        </Col>
        <Col md={3}>
          <CondicionalLookup
            clearable
            model={model.getModel('emailBoasVindasEnviado')}
          />
        </Col>
        <Col md={4}>
          <ModuloLookup multi model={model.getModel('modulos')} />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ColaboradorSearch));
