import ptBr from 'date-fns/locale/pt-BR';
import enUs from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import tr from 'date-fns/locale/tr';
import ar from 'date-fns/locale/ar';

const format = (language, formal, hours = false) => {
  let currentDisplay = formal
    ? `MM/dd/yyyy${hours ? ' HH:mm' : ''}`
    : 'MMM d, yyyy';
  let currentLocale = enUs;

  switch (language) {
    case 'pt-br':
      currentDisplay = formal
        ? `dd/MM/yyyy${hours ? ' HH:mm' : ''}`
        : 'd MMM, yyyy';
      currentLocale = ptBr;
      break;
    case 'es':
      currentDisplay = formal
        ? `dd/MM/yyyy${hours ? ' HH:mm' : ''}`
        : 'd MMM, yyyy';
      currentLocale = es;
      break;
    case 'en-US':
      currentDisplay = formal
        ? `MM/dd/yyyy${hours ? ' HH:mm' : ''}`
        : 'MMM d, yyyy';
      currentLocale = enUs;
      break;
    case 'de':
      currentDisplay = formal
        ? `dd.MM.yyyy${hours ? ' HH:mm' : ''}`
        : 'd. MMM yyyy';
      currentLocale = de;
      break;
    case 'it':
      currentDisplay = formal
        ? `dd/MM/yyyy${hours ? ' HH:mm' : ''}`
        : 'd MMM yyyy';
      currentLocale = it;
      break;
    case 'fr':
      currentDisplay = formal
        ? `dd/MM/yyyy${hours ? ' HH:mm' : ''}`
        : 'd MMM yyyy';
      currentLocale = fr;
      break;
    case 'nl':
      currentDisplay = formal
        ? `dd-MM-yyyy${hours ? ' HH:mm' : ''}`
        : 'd MMM yyyy';
      currentLocale = nl;
      break;
    case 'tr':
      currentDisplay = formal
        ? `dd.MM.yyyy${hours ? ' HH:mm' : ''}`
        : 'd MMM yyyy';
      currentLocale = tr;
      break;
    case 'ar':
      currentDisplay = formal
        ? `dd/MM/yyyy${hours ? ' HH:mm' : ''}`
        : 'd MMM، yyyy';
      currentLocale = ar;
      break;
    default:
      break;
  }

  return { currentDisplay, currentLocale };
};

export default format;
