import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Row,
  Col,
} from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import { autobind } from 'core-decorators';
import Button from '../button';
import Dialog from '../dialog';
import LoadingContainer from '../loading-container';
import { Form } from '../../utils/form';
import http from 'src/services/httpService';
import httpSelector from 'src/services/httpSelector';
import Alert from '../message-box/alert';
import ContentManager from '../content-manager';
import Confirm from '../message-box/confirm';
import errorHandler from '../../utils/error-handler';
import MdiIcon from '../mdi-icon';
import Email from '../email';
import { DownloadFile, ParseError } from 'src/services/downloadFileUrl';
import MessageCancelEdit from './message-cancel-edit';
import ReactTooltip from 'react-tooltip';

@autobind
class EditModal extends React.Component {
  static defaultProps = {
    showActions: true,
    showDelete: false,
    showExport: false,
    showSave: true,
    showExportExcel: true,
    showExportPDF: true,
    urlExport: '',
    refreshModel: null,
    asposeToPdf: true,
    usePuppeteerPDF: false,
    refreshOnDelete: true,
    reloadAfterDelete: true,
    validateAlterModel: true,
    showEmailSubject: false,
    enviarComunicado: false,
  };

  constructor(props) {
    super(props);

    this.salvo = false;

    this.state = {
      loaded: false,
      isLoading: false,
      id: this.props.item ? this.props.item.id : null,
      modelInicial: null,
      refreshId: 0,
      showErrorRequired: false,
      checkRequiredCustom: 0,
    };

    this.form = new Form({
      component: this,
      schema: this.props.schema,
    });
  }

  componentDidMount() {
    this.refresh();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.refreshId && nextProps.refreshId != this.state.refreshId) {
      this.setState({ refreshId: nextProps.refreshId });
      this.refresh();
      return true;
    }

    if (nextState != this.state) return true;
  }

  refresh() {
    this.setState({
      isLoading: true,
    });

    if (this.props.local && this.props.item && this.props.item.id <= 0) {
      const modelInicial = this.props.item.editModel
        ? this.props.item.editModel
        : this.props.item;

      setTimeout(() => {
        this.form.setValue(null, modelInicial);
      }, 250);

      this.setState({
        loaded: true,
        isLoading: false,
        modelInicial: { ...modelInicial },
      });
    } else if (
      this.props.url &&
      (!this.props.item || this.props.item.id === -1 || this.props.isNew)
    ) {
      http
        .post(`${this.props.url}/Create`, {
          parameters: this.props.parameters,
        })
        .then((response) => {
          this.form.setValue(null, response.data);

          this.setState({
            loaded: true,
            isLoading: false,
            modelInicial: { ...response.data },
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
          this.handleClose();
        });
    } else if (this.props.url) {
      httpSelector
        .getHttp(this.props.viewType)
        .post(`${this.props.url}/Edit`, {
          id: this.props.item.id,
          parameters: this.props.parameters,
        })
        .then((response) => {
          this.form.setValue(null, response.data);

          this.setState({
            loaded: true,
            isLoading: false,
            modelInicial: { ...response.data },
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(error);
          this.handleClose();
        });
    } else {
      this.setState({
        loaded: true,
        isLoading: false,
      });
    }
  }

  showErrors() {
    const isValid = this.form.isValid();

    if (!isValid) {
      this.props.handleErrorRequired &&
        this.form.handleErrorRequired &&
        this.form.handleErrorRequired();
      this.form.showAllErrors();
      this.setState({
        showErrorRequired: true,
        checkRequiredCustom: this.state.checkRequiredCustom + 1,
      });
      ContentManager.addContent(
        <Alert
          message={
            <FormattedMessage id="label.osCamposDestacadosEmVermelhoSaoObrigatoriosFavorPreenchelos" />
          }
        />
      );
      return;
    }

    return isValid;
  }

  handleSaveBeforeSave(fechar) {
    if (this.props.handleConfirmeBeforeSave)
      this.props.handleConfirmeBeforeSave(
        this.form.getValue(),
        this.handleSave,
        this.props.intl,
        fechar,
        this.props.resources,
        this.state.modelInicial,
        this.showErrors,
        this.props.afterSave
      );
    else this.handleSave(fechar);
  }

  handleSave(fechar) {
    const model = this.form.getValue();
    const isNew = model.id <= 0;

    this.showErrors();
    if (!this.form.isValid()) return;

    if (this.props.handleSave) {
      this.salvo = true;
      this.props.handleSave(this.form.getValue(), this.handleClose);
    } else {
      this.setState({
        isLoading: true,
      });

      http
        .post(`${this.props.url}/Save`, {
          model,
          parameters: this.props.parameters,
        })
        .then((response) => {
          if (this.props.sendMailSave) {
            const model = this.form.getModel();
            let htmlString = '';
            let width = 0;
            let height = 0;

            if (this.props.handleHtmlString) {
              const relatorio = this.props.handleHtmlString(model);

              if (relatorio) {
                htmlString = relatorio.htmlString;
                width = relatorio.width;
                height = relatorio.height;
              }
            }

            this.props.sendMailSave(htmlString, width, height);
          }

          this.salvo = true;
          this.setState({
            isLoading: false,
            id: response.data.model.id,
            modelInicial: { ...response.data.model },
          });
          this.form.setValue(null, response.data.model);

          this.props.handleCallBack && this.props.handleCallBack();
          if (fechar) {
            this.handleClose();
          }
          this.props.refreshModel != null && this.props.refreshModel();
          this.props.afterSave != null &&
            this.props.afterSave(this.form.getValue(), isNew);

          {
            !fechar &&
              this.props.messageSave &&
              this.props.messageSave != '' &&
              ContentManager.addContent(
                <Alert
                  title={this.props.intl.formatMessage({ id: 'sucesso' })}
                  message={this.props.intl.formatMessage({
                    id: this.props.messageSave,
                  })}
                />
              );
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            checkRequiredCustom: ++this.state.checkRequiredCustom,
          });

          errorHandler(error);
        });
    }
  }

  confirmClose() {
    const modelAtual = this.form.getValue();
    let { validateAlterModel } = this.props;

    if (
      validateAlterModel &&
      JSON.stringify(this.state.modelInicial) != JSON.stringify(modelAtual)
    ) {
      ContentManager.addContent(
        <MessageCancelEdit
          handleNaoSalvar={this.handleClose}
          handleSave={this.handleSaveBeforeSave}
        />
      );
    } else {
      this.handleClose();
    }
  }

  handleClose(deleted = false, closeModalChild = false) {
    $('body').removeClass('modal-open');
    this.refs && this.refs.dialog && this.refs.dialog.close();

    setTimeout(() => {
      this.props.remove && this.props.remove();
      this.props.handleOnClose && this.props.handleOnClose();
      this.props.handleClose &&
        this.props.handleClose(
          this.salvo || this.props.forceRefresh,
          this.form.getValue('id'),
          this.form.getValue(),
          deleted,
          closeModalChild
        );
    }, 300);
  }

  handleDeleteClick(item, index) {
    if (this.props.onDeleting) {
      this.props.onDeleting && this.props.onDeleting(item, index);
      this.refs.dialog.close();
    } else {
      ContentManager.addContent(
        <Confirm
          message={this.props.intl.formatMessage({
            id: 'label.desejaMesmoExcluirEsseRegistro',
          })}
          handleConfirm={this.handleDeleteClickConfirm.bind(this, item, index)}
        />
      );
    }
  }

  handleDeleteClickConfirm(item, index) {
    if (this.props.local) {
      this.props.handleRemove && this.props.handleRemove(item, index);
    } else {
      this.setState({
        isLoading: true,
      });
      http
        .post(`${this.props.url}/Remove`, {
          id: item ? item.id : this.state.id,
          parameters: this.props.parameters,
        })
        .then(() => {
          ContentManager.addContent(
            <Alert
              title={this.props.intl.formatMessage({ id: 'sucesso' })}
              message={this.props.intl.formatMessage({
                id: 'label.registroExcluidoComSucesso',
              })}
              deleted={this.props.refreshOnDelete}
              reloadAfterDelete={this.props.reloadAfterDelete}
            />
          );
          this.handleClose(true);
        })
        .catch((error) => {
          errorHandler(error);
        })
        .finally(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  handleEmailClick(item, extensaoExport) {
    let htmlString = '';
    let width = 0;
    let height = 0;

    if (this.props.handleHtmlString) {
      const relatorio = this.props.handleHtmlString(this.form.getModel());

      if (relatorio) {
        htmlString = relatorio.htmlString;
        width = relatorio.width;
        height = relatorio.height;
      }
    } else {
      htmlString =
        document.getElementById('relatorioPag') &&
        document.getElementById('relatorioPag').outerHTML;
    }

    ContentManager.addContent(
      <Email
        url={this.props.url}
        urlExport={this.props.urlExport}
        showSubject={this.props.showEmailSubject}
        item={item}
        extensaoExport={extensaoExport}
        parameters={this.props.parameters}
        htmlString={htmlString}
        widthExport={width}
        heightExport={height}
      />
    );
  }

  handleExportClick(item, extensaoExport) {
    let htmlString = '';
    let width = 0;
    let height = 0;

    if (this.props.handleHtmlString) {
      const relatorio = this.props.handleHtmlString(this.form.getModel());

      if (relatorio) {
        htmlString = relatorio.htmlString;
        width = relatorio.width;
        height = relatorio.height;
      }
    } else {
      htmlString =
        document.getElementById('relatorioPag') &&
        document.getElementById('relatorioPag').outerHTML;
    }

    // TODO: este codigo deve ser feito dentro de cada tela que chama o edit-dialog
    // remover este codigo e colocar dentro da tela que chamou o edit-dialog
    this.handleSaveBeforeSave(false);

    if (this.props.urlExport) {
      this.setState({
        isLoading: true,
      });
      ContentManager.addContent(
        <Alert
          message={this.props.intl.formatMessage({ id: 'downloadEmBreve' })}
        />
      );
      http
        .post(
          this.props.urlExport,
          {
            id: item.id,
            extensaoExport: extensaoExport,
            parameters: this.props.parameters,
            htmlString: htmlString,
            width: width,
            height: height,
          },
          {
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          this.setState({
            isLoading: false,
          });

          DownloadFile(response);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });

          errorHandler(ParseError(error));
        });
    }
  }

  handleExportPuppeteerPDF(item, parameters, extensaoExport) {
    this.handleSaveBeforeSave(false);
    if (this.props.usePuppeteerPDF && this.props.divIdToExport) {
      this.setState({
        isLoading: true,
      });
      ContentManager.addContent(
        <Alert
          message={this.props.intl.formatMessage({ id: 'downloadEmBreve' })}
        />
      );
      http
        .post(
          this.props.urlExportPuppeteerPDF,
          {
            relatorioId: item.id,
            fileExtension: extensaoExport,
            orientacaoId: 1,
            parameters: parameters,
          },
          {
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          this.setState({
            isLoading: false,
          });

          DownloadFile(response);

          this.refs.dialog.close();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          errorHandler(ParseError(error));
        });
    }
  }

  render() {
    const { HeaderCustomComponent, showDelete, ehReuniao } = this.props;
    const model = this.form.getValue();
    const isNew = model.id <= 0;

    let allowDelete = this.form
      .getModel('permissions')
      .getModel('allowDelete').value;

    let allowEdit =
      this.form.getModel('permissions').getModel('allowEdit').value ||
      this.form.getModel('permissions').getModel('allowEditFallback').value;

    let footerButton = this.props.showActions &&
      this.props.showExport &&
      this.state.id && (
        <UncontrolledDropdown
          group
          key="menu-exportar"
          className="mr-2 d-inline"
        >
          <DropdownToggle color="secondary">
            <MdiIcon icon="dots-horizontal" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              eventkey="1"
              onClick={this.handleEmailClick.bind(
                this,
                this.props.item,
                'xlsx'
              )}
            >
              <FormattedMessage id="enviarEmail" />
            </DropdownItem>
            {this.props.asposeToPdf && this.props.showExportPDF && (
              <DropdownItem
                eventKey="2"
                onClick={this.handleExportClick.bind(
                  this,
                  this.props.item,
                  'pdf'
                )}
              >
                <FormattedMessage id="label.exportarPDF" />
              </DropdownItem>
            )}
            {this.props.showExportExcel && (
              <DropdownItem
                eventKey="3"
                onClick={this.handleExportClick.bind(
                  this,
                  this.props.item,
                  'xlsx'
                )}
              >
                <FormattedMessage id="label.exportarExcel" />
              </DropdownItem>
            )}
            {this.props.usePuppeteerPDF && (
              <DropdownItem
                eventKey="4"
                onClick={this.handleExportPuppeteerPDF.bind(
                  this,
                  this.props.item,
                  this.props.parameters,
                  'pdf'
                )}
              >
                <FormattedMessage id="label.exportarPDF" />
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );

    let editActions = this.props.showActions
      ? [
          <div key="edit-toolbar" className={ehReuniao ? 'reuniao-step7' : ''}>
            {!this.props.showIconDelete && (
              <Button color="secondary" onClick={this.handleClose}>
                <FormattedMessage id="label.cancelar" />
              </Button>
            )}
            {!this.props.showIconDelete && this.state.id && this.props.actions}
            {!this.props.showIconDelete &&
              this.props.showActions &&
              showDelete &&
              allowDelete &&
              this.state.id && (
                <Button
                  className="ml-2"
                  color="danger"
                  onClick={this.handleDeleteClick.bind(
                    this,
                    this.props.item,
                    this.props.item
                  )}
                >
                  <FormattedMessage id="excluir" />
                </Button>
              )}
            {this.props.showIconDelete &&
              this.props.showActions &&
              showDelete &&
              allowDelete &&
              this.state.id && (
                <Button
                  className="ml-2 btn btn-outline-danger"
                  color="outline-primary"
                  onClick={this.handleDeleteClick.bind(
                    this,
                    this.props.item,
                    this.props.item
                  )}
                >
                  <div>
                    <MdiIcon
                      color="red"
                      icon="delete-outline"
                      size={15}
                    ></MdiIcon>
                  </div>
                </Button>
              )}
            {this.props.showIconDelete && (
              <Button
                className="ml-2 btn btn-outline-primary"
                color="outline-primary"
                onClick={this.handleClose}
              >
                <FormattedMessage id="label.cancelar" />
              </Button>
            )}
            {!this.props.disabledEdit &&
              (this.props.forceShowSave ||
                (this.props.showActions &&
                  this.props.showSave &&
                  allowEdit)) && (
                <>
                  <Button
                    id="btnSaveEditDailog"
                    disabled={this.state.isLoading}
                    className="ml-2 acao-step6"
                    onClick={this.handleSaveBeforeSave.bind(
                      this,
                      this.props.close !== undefined
                        ? this.props.close
                        : !this.props.showSaveClose
                    )}
                  >
                    {this.props.enviarComunicado ? (
                      <FormattedMessage id="label.enviar" />
                    ) : (
                      <FormattedMessage id="label.salvar" />
                    )}
                  </Button>
                </>
              )}
            {!this.props.disabledEdit &&
              (this.props.forceShowSave ||
                (this.props.showActions &&
                  this.props.showSaveClose &&
                  allowEdit)) && (
                <>
                  <Button
                    id="btnSaveEditDailog"
                    disabled={this.state.isLoading}
                    className="ml-2"
                    onClick={this.handleSaveBeforeSave.bind(this, true)}
                  >
                    <FormattedMessage id="label.salvarEFechar" />
                  </Button>
                </>
              )}
            {this.props.showIconDelete && this.state.id && this.props.actions}
            {this.props.renderButtonActions &&
              this.props.renderButtonActions(
                model,
                isNew,
                this.form.isValid(),
                () => this.form.showAllErrors(),
                () => this.handleClose(true)
              )}
            {this.props.showIconDelete &&
              this.state.id &&
              this.props.renderActions(this.handleClose)}
          </div>,
        ]
      : null;

    const {
      formComponent: FormComponent,
      title,
      titleField,
      mode,
      actions,
      ...other
    } = this.props;
    const showTitleField =
      titleField &&
      this.form.getModel().getModel(titleField) &&
      this.form.getModel().getModel(titleField).value;
    return (
      <Dialog
        width={600}
        {...other}
        padContent
        ref="dialog"
        title={`${
          title && showTitleField
            ? `${title} - ${this.form.getModel().getModel(titleField).value}`
            : showTitleField
            ? this.form.getModel().getModel(titleField).value || title
            : title
        }`}
        titleField={`${
          titleField && this.form.getModel().getModel(titleField).value
        }`}
        footer={footerButton}
        actions={editActions}
        onRequestClose={this.confirmClose}
      >
        <LoadingContainer isLoading={this.state.isLoading}>
          {this.props.handleAssistenteVirtualClick &&
            this.props.moduloInteligenciaArtificial && (
              <Row>
                <Col md={12} className="d-flex justify-content-end">
                  <Button
                    autoFocus={false}
                    data-tip
                    data-for={'botaoGerarInsight'}
                    key={1}
                    className="ml-2"
                    onClick={this.props.handleAssistenteVirtualClick}
                    type="primary"
                  >
                    <img alt="IA logo" src="/ia/ia_branco.svg" />
                    <span
                      className="ml-2"
                      style={{ marginTop: '2px', marginBottom: '2px' }}
                    >
                      {this.props.intl.formatMessage({ id: 'gerarInsights' })}
                    </span>
                  </Button>
                  <ReactTooltip
                    id={'botaoGerarInsight'}
                    aria-haspopup="true"
                    className="w-25"
                    place="bottom"
                    effect="solid"
                  >
                    {this.props.intl.formatMessage(
                      {
                        id: this.props.resourceSophieToolTip,
                      },
                      { risco: this.props.resources.risco }
                    )}
                  </ReactTooltip>
                </Col>
              </Row>
            )}
          {this.form.getModel().value &&
            HeaderCustomComponent &&
            this.props.item &&
            this.props.item.id > 0 && (
              <Row>
                <Col md={12}>
                  {React.cloneElement(HeaderCustomComponent, {
                    model: this.form.getModel(),
                  })}
                </Col>
              </Row>
            )}
          <FormComponent
            {...other}
            mode={this.state.mode}
            model={this.form.getModel()}
            resources={this.props.resources}
            handleCloseEdit={this.handleClose}
            checkRequiredCustom={this.state.checkRequiredCustom}
            showErrorRequired={this.state.showErrorRequired}
          />
        </LoadingContainer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    viewType: state.viewType,
    moduloInteligenciaArtificial: state.user.moduloInteligenciaArtificial,
  };
}

export default injectIntl(connect(mapStateToProps)(EditModal));
