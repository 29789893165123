import React, { useState, useEffect, useRef } from 'react';
import Dialog from 'src/componentes/dialog';
import http from 'src/services/httpService';
import { Row, Col } from 'reactstrap';
import { prepareHtml } from 'src/utils/html';
import MemoriaCalculo from './MemoriaCalculo';
import ContentManager from 'src/componentes/content-manager';
import { useIntl } from 'react-intl';
import LoadingContainer from 'src/componentes/loading-container';
import { AlertTriangle, CheckCircle } from 'react-feather';
import useAuth from 'src/hooks/useAuth';

const MemoriaCalculoValor = ({
  resultado,
  valorEncontrado = false,
  ...props
}) => {
  const [formula, setFormula] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const dialogRef = useRef(null);
  const intl = useIntl();
  const { terms: resources } = useAuth();

  useEffect(() => {
    const { riscoId, avaliacaoModel, dataAvaliacao } = props;
    setIsLoading(true);

    http
      .post(`/RiscoAvaliacao/ObterMemoriaCalculo`, {
        riscoId: riscoId,
        criterios: avaliacaoModel.getModel('criteriosAvaliacao')?.value,
        dataReferencia: dataAvaliacao,
      })
      .then((response) => {
        setFormula(response.data.formula);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [valorEncontrado, resultado]);

  const handleClose = () => {
    dialogRef.current.close();
    setTimeout(() => {
      props.remove && props.remove();
    }, 300);
  };

  const handleDivClick = (e) => {
    let clickedElement = e.target;
    let link = clickedElement.closest('a');
    if (!link) return;

    let itemId = link.dataset.itemId;
    let valor = link.dataset.valor;
    let { ano, periodo } = props.item;
    let { desdobramento, tipoValor, dataInicioAcumulado } = props;

    ContentManager.addContent(
      <MemoriaCalculo
        item={{ itemId: itemId, ano: ano, periodo: periodo }}
        valor={valor}
        desdobramento={desdobramento}
        tipoValor={tipoValor}
        periodicidade={props.periodicidade != null ? props.periodicidade : null}
        dataInicioFiltro={props.dataInicioFiltro}
        dataTerminoFiltro={props.dataTerminoFiltro}
        tipoFiltroData={
          props.tipoFiltroData != null ? props.tipoFiltroData : null
        }
        dataInicioAcumulado={dataInicioAcumulado}
      ></MemoriaCalculo>
    );
  };

  const { item, tipoValor, valor } = props;

  return (
    <Dialog
      width={850}
      height={420}
      respeitarAltura={true}
      padContent
      onRequestClose={handleClose}
      ref={dialogRef}
    >
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col md={2}>
            {!resultado?.hasError ? (
              <div className="d-flex align-items-center justify-content-center">
                <CheckCircle size={30} color="green" />
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(255, 0, 0, 0.5)', // Vermelho com 50% de transparência
                  width: '56px', // Largura de 100px
                  height: '56px', // Altura igual à largura para formar o círculo
                  borderRadius: '50%', // Transforma em círculo
                  backgroundColor: 'red',
                  background: '#FFEBEB',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AlertTriangle size={30} color="red" />
              </div>
            )}
          </Col>
          <Col md={10}>
            {resultado?.hasError && (
              <>
                <Row>
                  <Col md={12}>
                    <div
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: '700',
                        fontSize: '20px',
                        color: '#A90F0F',
                      }}
                    >
                      {intl.formatMessage({ id: 'labelValorNaoEncontrado' })}
                    </div>
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col md={12}>
                    <div
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: '500',
                        fontSize: '16px',
                      }}
                    >
                      <b>
                        <div>
                          {intl.formatMessage(
                            {
                              id: 'labelValorGeradoProbabilidadeNaoDentroParametrizMatriz',
                            },
                            { probabilidade: resources?.probabilidade }
                          )}
                        </div>
                        <span style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({
                            id: 'labelReavalieExporessaoAutalFormulaVinculadaMatriz',
                          })}
                        </span>
                      </b>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <hr />
            {formula && (
              <>
                <Row className="pt-2">
                  <Col md={12}>
                    <div
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: '700',
                        fontSize: '16px',
                      }}
                    >
                      {intl.formatMessage({ id: 'label.memoriaDeCalculo' })}
                    </div>
                  </Col>
                </Row>
                {resultado && (
                  <Row className="pt-2">
                    <Col md={12}>
                      <div className="pt-2 d-inline-flex">
                        <div>
                          <b
                            style={{
                              fontFamily: 'Nunito',
                              fontWeight: '700',
                              fontSize: '14px',
                            }}
                          >
                            {intl.formatMessage({ id: 'label.resultado' })}:{' '}
                          </b>
                        </div>
                        <div>
                          {resultado.resultadoFormatado ? (
                            <span className="pl-2 d-flex align-items-center">
                              {resultado.resultadoFormatado}
                            </span>
                          ) : (
                            <span className="pl-2 d-flex align-items-center">
                              0,00
                            </span>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={12}>
                    <div style={{ marginTop: 15 }}>
                      <div
                        onClick={handleDivClick}
                        dangerouslySetInnerHTML={prepareHtml(formula)}
                      ></div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </LoadingContainer>
    </Dialog>
  );
};

export default MemoriaCalculoValor;
