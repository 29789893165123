import React from 'react';
import { autobind } from 'core-decorators';
import { InputGroup, Input } from 'reactstrap';

@autobind
class InputSearch extends React.Component {
  handleSearchClick() {
    this.props.onChange && this.props.onChange();
  }
  render() {
    let { placeholder, ...other } = this.props;

    return (
      <InputGroup>
          <Input placeholder={placeholder} type="text" {...other} />
      </InputGroup>
    );
  }
}

export default InputSearch;
