import React from 'react';
import Table from '../../componentes/table';
import SvgIcon from '../../componentes/svg-icon';
import { autobind } from 'core-decorators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils/string';
@autobind
class TabelaValorPreview extends React.Component {
  static defaultProps = {
    type: 'vertical',
  };

  render() {
    let { model, type, exibirFarol } = this.props;

    return (
      <div>
        {model.value && model.value.length > 0 ? (
          type == 'horizontal' ? (
            model.value &&
            model.value.length > 0 &&
            model.value.map((item) => (
              <div style={{ display: 'inline-block', marginRight: 20 }}>
                <span style={{ marginRight: 7 }}>{item.descricao}</span>
                <span>
                  <SvgIcon
                    icon={item.farol.icone}
                    color={item.farol.cor}
                    colorHover={item.farol.cor}
                  ></SvgIcon>
                </span>
                <span style={{ marginLeft: 7 }}>{item.valor}</span>
              </div>
            ))
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="descricao" />
                  </th>
                  {exibirFarol && (
                    <th>{capitalizeFirstLetter(this.props.resources.farol)}</th>
                  )}
                  <th>
                    <FormattedMessage id="valor" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {model.value &&
                  model.value.length > 0 &&
                  model.value.map((item) => (
                    <tr>
                      <td>{item.descricao}</td>
                      {exibirFarol && (
                        <td>
                          <SvgIcon
                            icon={item.farol.icone}
                            color={item.farol.cor}
                            colorHover={item.farol.cor}
                            style={{ marginRight: 10 }}
                          ></SvgIcon>
                          {item.farol.legenda}
                        </td>
                      )}
                      <td>{item.valor}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )
        ) : (
          <i>
            {this.props.intl.formatMessage(
              { id: 'label.nenhumaOpcaoConfiguradaParaEstaPolaridade' },
              { polaridade: this.props.resources.polaridade }
            )}
          </i>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(TabelaValorPreview));
