import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import css from 'src/utils/css';
import http from 'src/services/httpSelector';
import errorHandler from 'src/utils/error-handler';
import DragScroll from 'src/componentes/dragscroll';
import Caixa from 'src/componentes/caixa-item';
import { TIPO_ITENS } from 'src/utils/constants';
import MdiIcon from 'src/componentes/mdi-icon';

import ArvoreItem from './arvore-item';

const classes = css`
  .margin {
    & > .item-arvore {
      margin-top: 2px;
    }
  }
`;

const Tree = ({
  model,
  mode,
  ocultarItensSemValores,
  handleCloseModal,
  filtro,
  ...rest
}) => {
  const intl = useIntl();

  const [keysToHide, setKeysToHide] = useState([]);
  const [configurationsList, setConfigurationsList] = useState([]);
  const [
    configurationsRelacionamentosList,
    setConfigurationsRelacionamentosList,
  ] = useState([]);
  const [updatecollapseAll, setUpdateCollapseAll] = useState(1);
  const [updateShowAll, setUpdateShowAll] = useState(1);

  const getProjetoConfiguration = async () => {
    try {
      const response = await http
        .getHttp(mode)
        .post('ItemConfiguracaoProjeto/ObterConfiguracoes', {});
      setConfigurationsList((prev) => [
        ...prev,
        {
          type: TIPO_ITENS.PROJETO,
          data: response.data,
        },
      ]);
    } catch (err) {
      errorHandler(err);
    }
  };

  const getRiscoConfiguration = async () => {
    try {
      const response = await http
        .getHttp(mode)
        .post('ItemConfiguracaoRisco/ObterConfiguracoes', {});
      setConfigurationsList((prev) => [
        ...prev,
        {
          type: TIPO_ITENS.RISCO,
          data: response.data,
        },
      ]);
    } catch (err) {
      errorHandler(err);
    }
  };

  const getObjetivoEstrategicoConfiguration = async () => {
    try {
      const response = await http
        .getHttp(mode)
        .post('ItemConfiguracaoObjetivo/ObterConfiguracoes', {});
      setConfigurationsList((prev) => [
        ...prev,
        {
          type: TIPO_ITENS.OBJETIVO_ESTRATEGICO,
          data: response.data,
        },
      ]);
    } catch (err) {
      errorHandler(err);
    }
  };

  const getRelacionamentosConfiguration = async () => {
    try {
      const response = await http
        .getHttp(mode)
        .post('ConfiguracaoRelacionamentosItens/ObterTodasConfiguracoes', {});
      setConfigurationsRelacionamentosList(response.data);
    } catch (err) {
      errorHandler(err);
    }
  };

  const getConfigurations = async () => {
    await Promise.all([
      getProjetoConfiguration(),
      getRiscoConfiguration(),
      getObjetivoEstrategicoConfiguration(),
      getRelacionamentosConfiguration(),
    ]);
  };

  useEffect(() => {
    getConfigurations();
  }, []);

  const expandItem = (listaItens, key) => {
    if (listaItens) {
      for (let i = 0; i < listaItens.length; i++) {
        if (listaItens[i].key === key) {
          listaItens[i].expandido = !listaItens[i].expandido;
        }
        expandItem(listaItens[i].filhos, key);
      }
    }
  };

  const handleCollapse = (item) => {
    let itemArvore = model.value;
    obterFilhos(itemArvore, item);
    expandItem(itemArvore, item.key);
    model.requestChange(itemArvore);
  };

  const obterFilhos = (listaItens, item) => {
    const url = item.ehObjetivoOKR
      ? 'Objetivo'
      : item.ehKR
      ? 'ChaveResultado'
      : 'Item';

    if (!item.filhos) {
      http
        .getHttp(mode)
        .post(`/${url}/ObterFilhosRelacionamento`, {
          itemId: item.id,
          formulaId: item.formulaId,
          filtro: filtro,
          exibirFormula: true,
        })
        .then((response) => {
          if (response.data)
            atualizarFilhos(listaItens, item.key, response.data.filhos);
          model.requestChange(listaItens);
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  const atualizarFilhos = (listaItens, key, filhos) => {
    if (listaItens) {
      for (let i = 0; i < listaItens.length; i++) {
        if (listaItens[i].key === key) {
          listaItens[i].filhos = filhos;
        }
        if (listaItens[i].filhos)
          atualizarFilhos(listaItens[i].filhos, key, filhos);
      }
    }
  };

  const ocultarCaixa = (key) => {
    const list = [...keysToHide];
    list.push(key);
    setKeysToHide(list);
  };

  const renderItemArvore = (item) => {
    if (keysToHide.indexOf(item.key) > -1) return null;
    const url = item.ehObjetivoOKR
      ? 'Objetivo'
      : item.ehKR
      ? 'ChaveResultado'
      : 'Item';

    return (
      <ArvoreItem
        mode={mode}
        item={item}
        itemFormula={item.ehFormula}
        key={item.key}
        label={
          <Caixa
            key={item.key}
            handleCollapse={handleCollapse}
            url={url}
            itemArvore={item}
            ocultarItensSemValores={ocultarItensSemValores}
            ocultarCaixa={() => ocultarCaixa(item.key)}
            mode={mode}
            handleCloseModal={handleCloseModal}
            allConfigurations={configurationsList}
            allRelacionamentoConfigurations={configurationsRelacionamentosList}
            updatecollapseAll={updatecollapseAll}
            updateShowAll={updateShowAll}
            filtro={filtro}
            {...rest}
          />
        }
      >
        {item.possuiFilhos &&
          item.filhos &&
          item.filhos.length > 0 &&
          item.filhos.map((filho, filhoIndex) =>
            renderItemArvore(filho, filhoIndex)
          )}
      </ArvoreItem>
    );
  };

  const showTree = configurationsList?.length >= 3;

  if (!showTree) return '';

  return (
    <>
      <div className="d-flex align-items-center justify-content-end mb-2">
        <a
          onClick={() => setUpdateShowAll((prev) => prev + 1)}
          className="text-primary mr-3"
        >
          <span className="mr-1">
            {intl.formatMessage({ id: 'expandirTodos' })}
          </span>
          <MdiIcon
            className="cursor-pointer mr-3 text-primary"
            icon="chevron-double-down"
          />
        </a>
        <a
          onClick={() => setUpdateCollapseAll((prev) => prev + 1)}
          className="text-primary"
        >
          <span className="mr-1">
            {intl.formatMessage({ id: 'colapsarTodos' })}
          </span>
          <MdiIcon
            className="cursor-pointer mr-3 text-primary"
            icon="chevron-double-up"
          />
        </a>
      </div>
      <DragScroll overflowY="inherit" height="auto" width="100%">
        <div className={`${classes.margin}`}>
          {model.value?.length > 0 &&
            model.value.map((itemArvore, index) =>
              renderItemArvore(itemArvore, index)
            )}
        </div>
      </DragScroll>
    </>
  );
};

export default Tree;
