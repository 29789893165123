import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Hint from 'src/paginas/item/componentes/hint';
import Button from 'src/componentes/button';
import ListColumn from 'src/componentes/NewList/ListColumn';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import ContentManager from 'src/componentes/content-manager';
import errorHandler from 'src/utils/error-handler';
import pPipe from 'src/utils/pPipe';
import {
  getColumns,
  getFilter,
  getData,
  getPermissions,
  exportList,
} from 'src/componentes/NewList';
import { ParseError } from 'src/services/downloadFileUrl';
import LoadingContainer from 'src/componentes/loading-container';
import Toolbar from 'src/componentes/NewList/Toolbar';
import Legenda from 'src/paginas/gestao-risco/legenda-farol-revisao';
import Content from 'src/componentes/pages/content';
import { FILTROS, COLUNAS } from 'src/utils/constants';
import Results from 'src/componentes/NewList/Results';
import handleError from 'src/utils/error-handler';
import http from 'src/services/httpService';
import { changeUriOrQueryString } from 'src/utils/historyUtils';
import { renderScoreRisco } from './../../components/renderScoreRisco';
import ProgressBar from 'src/componentes/progress-bar';

import RevisoesGerenciarSearch from '../AvaliacaoPendenteListView/Search';
import SearchSchema from '../AvaliacaoPendenteListView/SearchSchema';
import AvaliacaoEditView from '../AvaliacaoEditView';
import { getConfiguration } from 'src/services/commonRequests';
import { formatNumber } from 'src/utils/number';
import useAuth from 'src/hooks/useAuth';
import { Badge, Popover, PopoverBody } from 'reactstrap';
import css from 'src/utils/css';

const classes = css`
  .popoverBody {
    max-height: 600px;
    max-width: 600px;
    overflow-y: auto;
    overflow-x: auto;
  }
`;
function RiscoAvaliacaoList({
  resources,
  intl,
  exibirRisco,
  exibirAcao,
  entityTag,
  columnsTag,
  filterTag,
  endpoint,
  parameters,
  defaultSort,
  defaultSortBy,
  selectedFilter,
  openLastFilter,
  showToolbar,
  showTitle,
  showSearch = true,
  showNew,
  funcAtualizarAnscestral,
  refreshResumo,
  habilitarCalculoDeScores,
  refreshHeaderCustom,
  habilitarLinkRisco = true,
  acoesCronograma,
  configuration,
  habilitarBotaoSincronizarAvaliacoes = false,
}) {
  const [configuracao, setConfiguracao] = useState(configuration);
  const [configuracaoAvaliacao, setConfiguracaoAvaliacao] = useState({});
  const [isLoadingConfiguration, setIsLoadingConfiguration] = useState(false);
  const [saved, setSaved] = useState(false);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [filter, setFilter] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(defaultSort);
  const [sortFieldBy, setSortFieldBy] = useState(defaultSortBy);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [tiposSeveridade, setTiposSeveridade] = useState([]);
  const [tiposProbabilidade, setTiposProbabilidade] = useState([]);
  const [comentarioClickHintId, setComentarioClickHintId] = useState(null);
  const [comentarioHintId, setComentarioHintId] = useState(null);
  const { user } = useAuth();

  const getTiposSeveridades = async () => {
    return await http
      .post(`/TipoSeveridade/List`, {
        parameters: { buscarExcluidos: false },
        pageSize: 1000,
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.list &&
          response.data.list.length > 0
        ) {
          const fields = response.data.list;
          const colunas = [];
          if (fields && fields.length > 0) {
            fields.map((tipo, index) => {
              let colunaCC = (
                <ListColumn
                  headerText={tipo?.nome}
                  valueField={`severidadeTipo${index}`}
                  sortField="Severidade.Nome"
                  popoverFunction={(item, propsPopOver) => {
                    const valorTipoSeveridade =
                      item.valoresTipoSeveridade?.find(
                        (v) => v?.tipoSeveridade?.id == tipo.id
                      );

                    return popoverTipoSeveridade(
                      item,
                      valorTipoSeveridade,
                      index,
                      propsPopOver
                    );
                  }}
                  valueFunction={(item, propsPopOver) => {
                    const valorTipoSeveridade =
                      item.valoresTipoSeveridade?.find(
                        (v) => v?.tipoSeveridade?.id == tipo.id
                      );
                    const idComentario = obterIdPopoverTipoSeveridade(
                      item,
                      index
                    );
                    return (
                      <div
                        style={{
                          cursor: valorTipoSeveridade?.comentario
                            ? 'pointer'
                            : 'default',
                        }}
                        onClick={() =>
                          valorTipoSeveridade?.comentario &&
                          handleMouseClickTipoSeveridade(
                            item,
                            index,
                            propsPopOver
                          )
                        }
                        onMouseEnter={() =>
                          handleMouseOverTipoSeveridade(
                            item,
                            index,
                            propsPopOver
                          )
                        }
                        onMouseLeave={() => handleMouseOutPopOver(propsPopOver)}
                        //id={idComentario}
                      >
                        <span
                          style={{
                            fontWeight:
                              propsPopOver?.comentarioClickHintId ==
                              idComentario
                                ? 'bold'
                                : 'normal',
                          }}
                        >
                          {valorTipoSeveridade
                            ? valorTipoSeveridade.severidade?.nome
                            : '-'}
                        </span>
                      </div>
                    );
                  }}
                />
              );
              colunas.push(colunaCC);
            });
          }

          setTiposSeveridade(colunas);
          return colunas;
        }
      });
  };

  const getTiposProbabilidades = async () => {
    return await http
      .post(`/TipoProbabilidade/List`, {
        parameters: { buscarExcluidos: false },
        pageSize: 1000,
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.list &&
          response.data.list.length > 0
        ) {
          const fields = response.data.list;
          const colunas = [];
          if (fields && fields.length > 0) {
            fields.map((tipo, index) => {
              let colunaCC = (
                <ListColumn
                  headerText={tipo?.nome}
                  valueField={`probabilidadeTipo${index}`}
                  sortField="Probabilidade.Nome"
                  popoverFunction={(item, propsPopOver) => {
                    const valorTipoProbabilidade =
                      item.valoresTipoProbabilidade?.find(
                        (v) => v?.tipoProbabilidade?.id == tipo.id
                      );

                    return popoverTipoProbabilidade(
                      item,
                      valorTipoProbabilidade,
                      index,
                      propsPopOver
                    );
                  }}
                  valueFunction={(item, propsPopOver) => {
                    const valorTipoProbabilidade =
                      item.valoresTipoProbabilidade?.find(
                        (v) => v?.tipoProbabilidade?.id == tipo.id
                      );
                    const idComentario = obterIdPopoverTipoProbabilidade(
                      item,
                      index,
                      propsPopOver
                    );
                    return (
                      <div
                        style={{
                          cursor: valorTipoProbabilidade?.comentario
                            ? 'pointer'
                            : 'default',
                        }}
                        onClick={() =>
                          valorTipoProbabilidade?.comentario &&
                          handleMouseClickTipoProbabilidade(
                            item,
                            index,
                            propsPopOver
                          )
                        }
                        onMouseEnter={() =>
                          handleMouseOverTipoProbabilidade(
                            item,
                            index,
                            propsPopOver
                          )
                        }
                        onMouseLeave={() => handleMouseOutPopOver(propsPopOver)}
                        //id={idComentario}
                      >
                        <span
                          style={{
                            fontWeight:
                              propsPopOver?.comentarioClickHintId ==
                              idComentario
                                ? 'bold'
                                : 'normal',
                          }}
                        >
                          {valorTipoProbabilidade
                            ? valorTipoProbabilidade.probabilidade?.nome
                            : '-'}
                        </span>
                      </div>
                    );
                  }}
                />
              );
              colunas.push(colunaCC);
            });
          }

          setTiposProbabilidade(colunas);
          return colunas;
        }
      });
  };

  const handleMouseOutPopOver = useCallback((propsPopOver) => {
    if (propsPopOver?.comentarioClickHintId) return;

    setComentarioHintId(null);
  }, []);

  const popoverTipoProbabilidade = useCallback(
    (item, valorTipoProbabilidade, index = 0, propsPopOver) => {
      const idComentario = obterIdPopoverTipoProbabilidade(item, index);

      if (!valorTipoProbabilidade?.comentario) return;

      return (
        <Popover
          placement="left"
          isOpen={propsPopOver?.comentarioHintId == idComentario}
          target={idComentario}
        >
          <PopoverBody className={classes.popoverBody}>
            <span>{valorTipoProbabilidade?.comentario}</span>
          </PopoverBody>
        </Popover>
      );
    },
    []
  );

  const handleMouseOverTipoProbabilidade = useCallback(
    (item, index = 0, propsPopOver) => {
      const idComentario = obterIdPopoverTipoProbabilidade(item, index);
      setComentarioHintId(idComentario);
      setComentarioClickHintId(
        idComentario != propsPopOver?.comentarioClickHintId
          ? null
          : comentarioClickHintId
      );
    },
    []
  );

  const obterIdPopoverTipoProbabilidade = (item, index = 0) => {
    return `colprobabilidadeTipo${index}${item.id}`;
  };

  const handleMouseClickTipoProbabilidade = useCallback(
    (item, index, propsPopOver) => {
      const idTipoProbabilidade = obterIdPopoverTipoProbabilidade(item, index);

      if (propsPopOver?.comentarioClickHintId == idTipoProbabilidade) {
        setComentarioClickHintId(null);
      } else setComentarioClickHintId(idTipoProbabilidade);
    },
    []
  );

  const popoverTipoSeveridade = useCallback(
    (item, valorTipoSeveridade, index = 0, propsPopOver) => {
      const idComentario = obterIdPopoverTipoSeveridade(item, index);

      if (!valorTipoSeveridade?.comentario) return;

      return (
        <Popover
          placement="left"
          isOpen={propsPopOver?.comentarioHintId == idComentario}
          target={idComentario}
        >
          <PopoverBody className={classes.popoverBody}>
            <span>{valorTipoSeveridade?.comentario}</span>
          </PopoverBody>
        </Popover>
      );
    },
    []
  );

  const handleMouseOverTipoSeveridade = useCallback(
    (item, index = 0, propsPopOver) => {
      const idComentario = obterIdPopoverTipoSeveridade(item, index);
      setComentarioHintId(idComentario);
      setComentarioClickHintId(
        idComentario != propsPopOver?.comentarioClickHintId
          ? null
          : propsPopOver?.comentarioClickHintId
      );
    },
    []
  );

  const obterIdPopoverTipoSeveridade = (item, index = 0) => {
    return `colseveridadeTipo${index}${item.id}`;
  };

  const handleMouseClickTipoSeveridade = (item, index, propsPopOver) => {
    const idTipoSeveridade = obterIdPopoverTipoSeveridade(item, index);

    if (propsPopOver?.comentarioClickHintId == idTipoSeveridade)
      setComentarioClickHintId(null);
    else setComentarioClickHintId(idTipoSeveridade);
  };

  const popoverSeveridade = (item, propsPopOver) => {
    const idComentario = obterIdPopoverSeveridade(item);
    if (!item?.descricao && !item?.severidadeComentario) return;

    return (
      <Popover
        placement="left"
        isOpen={propsPopOver?.comentarioHintId == idComentario}
        target={idComentario}
      >
        <PopoverBody className={classes.popoverBody}>
          <span>
            {item?.severidadeComentario
              ? item?.severidadeComentario
              : item?.descricao}
          </span>
        </PopoverBody>
      </Popover>
    );
  };

  const handleMouseClickSeveridade = useCallback((item, propsPopOver) => {
    const idSeveridade = obterIdPopoverSeveridade(item);

    if (propsPopOver?.comentarioClickHintId == idSeveridade) {
      setComentarioClickHintId(null);
    } else setComentarioClickHintId(idSeveridade);
  }, []);

  const handleMouseOverSeveridade = useCallback((item, propsPopOver) => {
    const idComentario = obterIdPopoverSeveridade(item);
    setComentarioHintId(idComentario);
    setComentarioClickHintId(
      idComentario != propsPopOver?.comentarioClickHintId
        ? null
        : propsPopOver?.comentarioClickHintId
    );
  }, []);

  const obterIdPopoverSeveridade = (item) => {
    return `colseveridade${item.id}`;
  };

  const popoverProbabilidade = (item, propsPopOver) => {
    const idComentario = obterIdPopoverProbabilidade(item);
    if (!item?.descricao && !item?.probabilidadeComentario) return;

    //if (propsPopOver?.comentarioHintId == idComentario) alert('nice' + item?.descricao);

    return (
      <Popover
        placement="left"
        isOpen={propsPopOver?.comentarioHintId == idComentario}
        target={idComentario}
      >
        <PopoverBody className={classes.popoverBody}>
          <span>
            {item?.probabilidadeComentario
              ? item?.probabilidadeComentario
              : item?.descricao}
          </span>
        </PopoverBody>
      </Popover>
    );
  };

  const handleMouseClickProbabilidade = useCallback((item, propsPopOver) => {
    const idProbabilidade = obterIdPopoverProbabilidade(item);

    if (propsPopOver?.comentarioClickHintId == idProbabilidade) {
      setComentarioClickHintId(null);
    } else setComentarioClickHintId(idProbabilidade);
  }, []);

  const handleMouseOverProbabilidade = useCallback((item, propsPopOver) => {
    const idComentario = obterIdPopoverProbabilidade(item);
    setComentarioHintId(idComentario);
    setComentarioClickHintId(
      idComentario != propsPopOver?.comentarioClickHintId
        ? null
        : propsPopOver?.comentarioClickHintId
    );
  }, []);

  const obterIdPopoverProbabilidade = (item) => {
    return `colprobabilidade${item.id}`;
  };

  const loadColumns = async (defaultColumns) => {
    try {
      const currentColumns = await getColumns(
        defaultColumns,
        entityTag,
        columnsTag
      );

      setColumns(currentColumns.columns);
      setAllColumns(currentColumns.allColumns);
    } catch (err) {
      errorHandler(err);
    }
  };

  const loadData = useCallback(
    async (defaultColumns, filter) => {
      if (isLoadingConfiguration) return;
      try {
        if (!defaultColumns || !defaultColumns.length)
          defaultColumns = _getDefaultColumns();

        setLoading(true);
        const newPermissions = await getPermissions(endpoint, parameters);
        setPermissions(newPermissions);

        let newData = [];

        if (newPermissions.allowView || newPermissions.allowList) {
          const colunasSeveridade =
            configuracaoAvaliacao?.avaliarSeveridadePorTipo
              ? tiposSeveridade && tiposSeveridade.length > 0
                ? tiposSeveridade
                : await getTiposSeveridades()
              : [];

          const colunasProbabilidade =
            configuracaoAvaliacao?.avaliarProbabilidadePorTipo
              ? tiposProbabilidade && tiposProbabilidade.length > 0
                ? tiposProbabilidade
                : await getTiposProbabilidades()
              : [];

          await loadColumns(
            defaultColumns.concat(colunasSeveridade, colunasProbabilidade)
          );

          let newFilter = selectedFilter;
          if (openLastFilter && filterTag)
            newFilter = await getFilter(filterTag, true);

          if (filter && filter.valor) newFilter.valor = filter.valor;

          setFilter(newFilter);
          let valueFilter = {};
          if (newFilter != null) valueFilter = newFilter.valor;

          const model = { ...valueFilter, searchText };
          newData = await getData(
            endpoint,
            model,
            sortField,
            sortFieldBy,
            pageSize,
            currentPage - 1,
            parameters
          );

          setData(newData.list);
          setCount(newData.count);
        }
        setLoading(false);
        return newData;
      } catch (err) {
        setLoading(false);
        errorHandler(err);
      }
    },
    [
      endpoint,
      parameters,
      searchText,
      pageSize,
      currentPage,
      sortField,
      sortFieldBy,
      isLoadingConfiguration,
    ]
  );

  useEffect(() => {
    if (!configuration)
      getConfiguration('ItemConfiguracaoRisco')((configuracao) =>
        setConfiguracao(configuracao)
      )();
  }, []);

  useEffect(() => {
    setIsLoadingConfiguration(true);
    http
      .post(`/riscoAvaliacao/ObterConfiguracoesAvaliacaoRisco`, {
        riscoId: parameters.riscoId,
      })
      .then((response) => {
        setConfiguracaoAvaliacao(response.data);
      })
      .finally(() => setIsLoadingConfiguration(false));
  }, []);

  useEffect(() => {
    if (isLoadingConfiguration) return;

    loadData();
  }, [
    loadData,
    pageSize,
    currentPage,
    searchText,
    sortField,
    sortFieldBy,
    selectedFilter,
    isLoadingConfiguration,
  ]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  });

  const handleColumnsChange = useCallback(async () => {
    await loadColumns(defaultColumns);
    await loadData(defaultColumns);
  });

  const handleRefresh = useCallback(async (filter) => {
    await loadData(defaultColumns, filter);
    funcAtualizarAnscestral && funcAtualizarAnscestral();
    refreshResumo && refreshResumo();
  });

  const handleChangeCurrentPage = useCallback((newValue) => {
    setCurrentPage(newValue);
  });

  const handleChangePageSize = useCallback((pageSize) => {
    setPageSize(pageSize);
  });

  const handleSortColumn = useCallback((sort) => {
    setSortField(sort);
  });

  const handleSortByColumn = useCallback((sortBy) => {
    setSortFieldBy(sortBy);
  });

  const handleExport = async (fileExtension) => {
    try {
      setLoading(true);

      let valueFilter = {};
      if (filter != null) valueFilter = filter.valor;

      let newSearchModel = {
        searchText,
        ...valueFilter,
      };

      await exportList(
        newSearchModel,
        endpoint,
        sortField,
        currentPage,
        parameters,
        fileExtension
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandler(ParseError(err));
    }
  };

  const renderData = (item) => {
    return (
      <a tabIndex="-1" onClick={() => handleDataClick(item)}>
        {moment(item.dataAvaliacao)
          .locale(user?.idioma ? user.idioma : 'pt-Br')
          .format('DD MMMM YYYY')}
      </a>
    );
  };

  const renderToleranciaRisco = (item) => {
    const { ratingToleranciaRisco } = item;
    if (!ratingToleranciaRisco) return;
    return (
      <div>
        <Badge
          pill
          className="text-white"
          style={{ background: ratingToleranciaRisco?.cor }}
        >
          {ratingToleranciaRisco?.descricao}
        </Badge>
      </div>
    );
  };

  const renderCronograma = (item) => {
    if (!item.acoesCronograma || item.acoesCronograma.length == 0) {
      return;
    }

    return (
      <div className="progress" style={{ borderRadius: 6 }}>
        {item.acoesCronograma &&
          item.acoesCronograma.map((acao, index) => (
            <ProgressBar
              key={index}
              color={acao.farolAcao.cor}
              value={acao.percentual}
            />
          ))}
      </div>
    );
  };

  const renderGrauRisco = (item) => (
    <span>
      {item.grauRisco && item.grauRisco.cor && (
        <MdiIcon
          icon="circle"
          color={item.grauRisco.cor}
          colorHover={item.grauRisco.cor}
          size={22}
        ></MdiIcon>
      )}
    </span>
  );

  const handleAvaliacaoEditCallBack = () => {
    refreshHeaderCustom && refreshHeaderCustom();
    setSaved(!saved);
    handleRefresh();
  };

  const handleNewClick = async () => {
    try {
      if (parameters)
        await pPipe(
          criarAvaliacaoComVlresPadrao,
          handleRefresh
        )(parameters.riscoId);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDataClick = async (item) => {
    try {
      if (item) {
        ContentManager.addContent(
          <AvaliacaoEditView
            handleCallBack={handleAvaliacaoEditCallBack}
            item={{ id: item.id }}
            parameters={{ riscoId: item.risco.id }}
          ></AvaliacaoEditView>
        );
      } else if (parameters) await handleNewClick();
    } catch (error) {
      handleError(error);
    }
  };

  const criarAvaliacaoComVlresPadrao = async (riscoId) =>
    await http.post('/RiscoAvaliacao/Save', { parameters: { riscoId } });

  const renderRisco = (itemFromList) => {
    const { item, risco } = itemFromList;
    return habilitarLinkRisco ? (
      <a
        tabIndex="-1"
        onClick={() =>
          changeUriOrQueryString(
            { id: item ? item.id : risco.id },
            '/gerenciar/risco/edit',
            false
          )
        }
      >
        {itemFromList.nomeRisco}
      </a>
    ) : (
      <div>{itemFromList.nomeRisco}</div>
    );
  };

  const renderFarol = (item) => {
    return (
      <span>
        {item.farolRiscoAvaliacao && item.farolRiscoAvaliacao.cor && (
          <MdiIcon
            icon={item.farolRiscoAvaliacao.icone}
            color={item.farolRiscoAvaliacao.cor}
            colorHover={item.farolRiscoAvaliacao.cor}
            size={22}
          ></MdiIcon>
        )}
      </span>
    );
  };

  const renderImpactosIndiretos = (item) => (
    <div>
      {item.impactosIndiretos &&
        item.impactosIndiretos.map((itemImpacto) => (
          <div>
            <Hint item={item} itemImpacto={itemImpacto} allowSave={true} />
          </div>
        ))}
    </div>
  );

  const renderSeveridade = (item, propsPopOver) => {
    const idComentario = obterIdPopoverSeveridade(item, propsPopOver);
    const descricao = item?.severidadeComentario
      ? item?.severidadeComentario
      : item?.descricao;
    return (
      <div
        style={{
          cursor: descricao ? 'pointer' : 'default',
        }}
        onClick={() =>
          descricao && handleMouseClickSeveridade(item, propsPopOver)
        }
        onMouseEnter={() => handleMouseOverSeveridade(item, propsPopOver)}
        onMouseLeave={() => handleMouseOutPopOver(propsPopOver)}
      >
        <span
          style={{
            fontWeight:
              propsPopOver?.comentarioClickHintId == idComentario
                ? 'bold'
                : 'normal',
          }}
        >
          {item.severidade ? item.severidade : '-'}
        </span>
      </div>
    );
  };

  const renderProbabilidade = (item, propsPopOver) => {
    const idComentario = obterIdPopoverProbabilidade(item, propsPopOver);
    const descricao = item?.probabilidadeComentario
      ? item?.probabilidadeComentario
      : item?.descricao;
    return (
      <div
        style={{
          cursor: descricao ? 'pointer' : 'default',
        }}
        onClick={() =>
          descricao && handleMouseClickProbabilidade(item, propsPopOver)
        }
        onMouseEnter={() => handleMouseOverProbabilidade(item, propsPopOver)}
        onMouseLeave={() => handleMouseOutPopOver(propsPopOver)}
      >
        <span
          style={{
            fontWeight:
              propsPopOver?.comentarioClickHintId == idComentario
                ? 'bold'
                : 'normal',
          }}
        >
          {item.probabilidade ? item.probabilidade : '-'}
        </span>
      </div>
    );
  };

  const handleSyncClick = async () => {
    setLoading(true);
    try {
      await http.post('/RiscoAvaliacaoPowerBI/Sync', {
        riscoId: parameters.riscoId,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      handleRefresh();
    }
  };

  const customButtons = () => {
    if (!habilitarBotaoSincronizarAvaliacoes) return;

    return (
      <Button
        className="ml-2"
        outline
        color="primary"
        onClick={() => handleSyncClick()}
      >
        <MdiIcon icon={'sync'} />
        {intl.formatMessage(
          { id: 'sincronizarAvaliacoes' },
          { avaliacoes: resources.avaliacoes }
        )}
      </Button>
    );
  };

  const _getDefaultColumns = () => {
    let colunas = [
      <ListColumn
        headerText={capitalizeFirstLetter(resources.risco)}
        valueFunction={renderRisco}
        minWidth={'150px'}
        valueField="risco"
        sortField="Risco.UltimaVersao.Nome"
        visible={exibirRisco}
        default
      />,
      <ListColumn
        headerText={intl.formatMessage({ id: 'idIntegracao' })}
        valueField="idIntegracao"
      />,
      <ListColumn
        minWidth={'150px'}
        headerText={intl.formatMessage({ id: 'label.itensDeImpactoIndireto' })}
        valueFunction={renderImpactosIndiretos}
        valueField="impactosIndiretos"
        default
      />,
      <ListColumn
        headerText={intl.formatMessage(
          { id: 'label.dataAvaliacao' },
          { avaliacao: resources.avaliacao }
        )}
        valueField="dataAvaliacao"
        valueFunction={renderData}
        sortField="DataAvaliacao"
        default
      />,
      <ListColumn
        headerText={intl.formatMessage(
          { id: 'label.severidade' },
          { severidade: resources.severidade }
        )}
        popoverFunction={(item, propsPopOver) => {
          return popoverSeveridade(item, propsPopOver);
        }}
        onClick={(item) =>
          (item?.descricao || !item?.severidadeComentario) &&
          handleMouseClickSeveridade(item, propsPopOver)
        }
        onMouseEnter={(item) => handleMouseOverSeveridade(item, propsPopOver)}
        onMouseLeave={(item) => handleMouseOutPopOver(propsPopOver)}
        valueFunction={(item, propsPopOver) =>
          renderSeveridade(item, propsPopOver)
        }
        valueField="severidade"
        sortField="Severidade.Nome"
        default
      />,
      <ListColumn
        headerText={resources.probabilidade}
        popoverFunction={(item, propsPopOver) => {
          return popoverProbabilidade(item, propsPopOver);
        }}
        onClick={(item) =>
          (item?.descricao || !item?.probabilidadeComentario) &&
          handleMouseClickProbabilidade(item, propsPopOver)
        }
        onMouseEnter={(item) =>
          handleMouseOverProbabilidade(item, propsPopOver)
        }
        onMouseLeave={(item) => handleMouseOutPopOver(propsPopOver)}
        valueFunction={(item, propsPopOver) =>
          renderProbabilidade(item, propsPopOver)
        }
        valueField="probabilidade"
        sortField="Probabilidade.Nome"
        default
      />,
      <ListColumn
        headerText={intl.formatMessage(
          { id: 'grauRisco' },
          { risco: resources.risco }
        )}
        valueField="grauRisco"
        valueFunction={renderGrauRisco}
        default
      />,
      <ListColumn
        headerText={intl.formatMessage({ id: 'tratamento' })}
        valueField="tratamento"
        sortField="Tratamento.Nome"
        default
      />,
      <ListColumn
        headerText={capitalizeFirstLetter(
          intl.formatMessage(
            { id: 'farolAvaliacao' },
            { avaliacao: resources.avaliacao }
          )
        )}
        valueField="farolRiscoAvaliacao"
        valueFunction={renderFarol}
      />,
      <ListColumn
        headerText={intl.formatMessage({ id: 'label.responsavel' })}
        valueField="responsavel"
        sortField="Responsavel.Nome"
        default
      />,
      <ListColumn
        headerText={intl.formatMessage({ id: 'cronograma' })}
        valueField="acoesCronograma"
        valueFunction={renderCronograma}
        default
      />,
      <ListColumn
        headerText={intl.formatMessage({
          id: 'impactoFinanceiroResidualEstimado',
        })}
        valueFunction={(item) =>
          item.impactoFinanceiroResidualEstimado ? (
            <div style={{ minWidth: 100 }}>
              {configuracao?.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
              {formatNumber(item.impactoFinanceiroResidualEstimado, 2)}
            </div>
          ) : (
            <>-</>
          )
        }
        valueField="impactoFinanceiroResidualEstimado"
        visible={configuracao?.acompanharImpactoFinanceiroEventoRisco}
      />,
      <ListColumn
        headerText={intl.formatMessage({
          id: 'impactoFinanceiroInerenteEstimado',
        })}
        valueFunction={(item) =>
          item.impactoFinanceiroInerenteEstimado ? (
            <div style={{ minWidth: 100 }}>
              {configuracao?.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
              {formatNumber(item.impactoFinanceiroInerenteEstimado, 2)}
            </div>
          ) : (
            <>-</>
          )
        }
        valueField="impactoFinanceiroInerenteEstimado"
        visible={
          configuracao?.avaliarRiscoInerente &&
          configuracao?.acompanharImpactoFinanceiroEventoRisco
        }
      />,

      <ListColumn
        headerText={`
                  ${intl.formatMessage({
                    id: 'reducaoImpactoFinanceiroRealizado',
                  })} ${
          configuracao?.unidadeMedidaImpactoFinanceiro?.sigla
            ? `(${configuracao?.unidadeMedidaImpactoFinanceiro?.sigla})`
            : ''
        }`}
        valueFunction={(item) =>
          item.reducaoImpactoFinanceiroRealizado ? (
            <div style={{ minWidth: 100 }}>
              {configuracao?.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
              {formatNumber(item.reducaoImpactoFinanceiroRealizado, 2)}
            </div>
          ) : (
            <>-</>
          )
        }
        valueField="reducaoImpactoFinanceiroRealizado"
        visible={
          configuracao?.avaliarRiscoInerente &&
          configuracao?.acompanharImpactoFinanceiroEventoRisco
        }
      />,
      <ListColumn
        headerText={intl.formatMessage({
          id: 'reducaoImpactoFinanceiroPercentual',
        })}
        valueFunction={(item) =>
          item.reducaoImpactoFinanceiroPercentual ? (
            <div style={{ minWidth: 80 }}>
              {formatNumber(item.reducaoImpactoFinanceiroPercentual, 2)} %
            </div>
          ) : (
            <>-</>
          )
        }
        valueField="reducaoImpactoFinanceiroPercentual"
        visible={
          configuracao?.avaliarRiscoInerente &&
          configuracao?.acompanharImpactoFinanceiroEventoRisco
        }
      />,
    ];
    if (habilitarCalculoDeScores)
      colunas.push(
        <ListColumn
          headerText={intl.formatMessage({ id: 'pontuacao' })}
          valueFunction={renderScoreRisco}
          valueField="score"
          sortField="Score"
          default
        />
      );

    if (configuracaoAvaliacao?.exibirColunaCriterios)
      colunas.push(
        <ListColumn
          headerText={intl.formatMessage({ id: 'labelCriterios' })}
          valueField="criterios"
          default
        />
      );

    if (configuracao?.habilitarAcompanhamentoToleranciaRisco) {
      colunas.push(
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'labelToleranciaRisco' },
            { risco: resources.risco }
          )}
          valueField="toleranciaRisco"
          default
        />,
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'labelRatingRisco' },
            { risco: resources.risco }
          )}
          valueField="ratingToleranciaRisco"
          valueFunction={(item) => renderToleranciaRisco(item)}
          default
        />
      );
    }
    return colunas;
  };

  const content = (
    <LoadingContainer isLoading={isLoading}>
      {showToolbar && (
        <Toolbar
          useSavedFilter
          showSearch={showSearch}
          showExport
          onExport={handleExport}
          onColumnsChange={handleColumnsChange}
          onSearchChange={handleSearchChange}
          onRefresh={handleRefresh}
          searchText={searchText}
          searchSchema={SearchSchema}
          searchComponent={<RevisoesGerenciarSearch config={configuracao} />}
          filter={filter}
          filterTag={filterTag}
          permissions={permissions}
          columnsTag={columnsTag}
          columns={columns}
          allColumns={allColumns}
          handleNewClick={handleNewClick}
          showNew={showNew}
          customButtons={customButtons}
        />
      )}
      <Results
        resources={resources}
        count={count}
        columns={columns}
        data={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangeCurrentPage={handleChangeCurrentPage}
        onChangePageSize={handleChangePageSize}
        onSortColumn={handleSortColumn}
        onSortByColumn={handleSortByColumn}
        sort={sortField}
        sortBy={sortFieldBy}
        updatedItems={updatedItems}
        handleEditClick={handleDataClick}
        url={'/RiscoAvaliacao'}
        onRefresh={handleRefresh}
        showMenu
        propsPopOver={{
          comentarioHintId: comentarioHintId,
          comentarioClickHintId: comentarioClickHintId,
        }}
      />
      <Legenda />
    </LoadingContainer>
  );

  return (
    <React.Fragment>
      {showTitle ? <Content>{content}</Content> : content}
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

RiscoAvaliacaoList.defaultProps = {
  filterTag: FILTROS.AVALIACOES_PENDENTES,
  columnsTag: COLUNAS.AVALIACAO,
  endpoint: '/RiscoAvaliacao',
  entityTag: null,
  defaultSort: 'DataAvaliacao',
  defaultSortBy: 'desc',
  showToolbar: true,
  showTitle: true,
  openLastFilter: true,
};

export default injectIntl(connect(mapStateToProps)(RiscoAvaliacaoList));
