import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  span {
    margin-right: 5px;
  }
  .popover {
    pointer-events: none;
  }
`;

export const ContainerSpacos = styled.div`
  display: flex;
  width: 80px;
  border-radius: 20px;
  overflow: auto;
`;
