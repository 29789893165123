import { Schema } from '../../utils/form';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default Schema.object({
  area: Schema.string().label(''),
  areasSubordinadas: Schema.string().label(''),
  nome: Schema.string().label(<FormattedMessage id="nome" />),
  status: Schema.string().label(<FormattedMessage id="status" />),
  responsavel: Schema.string().label(''),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
  tipoItem: Schema.string().label(<FormattedMessage id="label.tipoItem" />),
  negociacaoMetasCatalogo: Schema.string().label(
    <FormattedMessage id="catalogo" />
  ),
});
