import React from 'react';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import GatilhoLookup from 'src/lookups/gatilho';
import EditDialog from 'src/componentes/edit/edit-dialog';

function GatilhoFormEdit({ model }) {
  const intl = useIntl();

  return (
    <React.Fragment>
      <Row>
        <Col md={5}>
          <div style={{ marginBottom: 15 }}>
            <GatilhoLookup
              model={{
                label: intl.formatMessage({ id: 'gatilho' }),
                value: model.getModel('gatilho').value,
                requestChange: model.getModel('gatilho').requestChange,
                errors: model.getModel('gatilho').errors,
              }}
              clearable={false}
              required
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

function GatilhoEdit({ ...rest }) {
  const intl = useIntl();
  return (
    <EditDialog
      url="/ColaboradorGatilho"
      title={intl.formatMessage({ id: 'gatilho' })}
      formComponent={GatilhoFormEdit}
      width="60%"
      height="40%"
      respeitarAltura
      maxHeight="90%"
      {...rest}
    />
  );
}

export default GatilhoEdit;
