import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'reactstrap';
import http from 'src/services/httpService';
import Checkbox from '../../componentes/checkbox';
import ItemOrganizacaoLookup from '../../lookups/item-organizacao';
import StatusRelatorioAcompanhamento from '../../lookups/status-relatorio-acompanhamento';
import ResponsavelLookup from '../../lookups/colaborador';
import ItemLookup from '../../lookups/item';
import PeriodoData from '../../paginas/data/periodo-data';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import DesdobramentoLookup from '../../lookups/desdobramento';
import dataPeriodo from '../../utils/dataPeriodo';
import ClassificacaoRelatorioAcompanhamentoLookup from 'src/lookups/ClassificacaoRelatorioAcompanhanetoLookup';
import FarolLookup from '../../lookups/farol';

@autobind
class RelatorioAcompanhamentoSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      filtroDefault: {
        tipoFiltroData: {
          id: this.props.model.getModel('tipoFiltroData').value
            ? this.props.model.getModel('tipoFiltroData').value.id
            : 4,
        },
        dataInicio: this.props.model.getModel('dataInicio').value
          ? this.props.model.getModel('dataInicio').value
          : dataPeriodo.inicio,
        dataTermino: this.props.model.getModel('dataInicio').value
          ? this.props.model.getModel('dataTermino').value
          : dataPeriodo.termino,
      },
    };
  }

  componentDidMount() {
    this.props.model
      .getModel('tipoFiltroData')
      .requestChange(this.state.filtroDefault.tipoFiltroData);
    this.props.model
      .getModel('dataInicio')
      .requestChange(this.state.filtroDefault.dataInicio);
    this.props.model
      .getModel('dataTermino')
      .requestChange(this.state.filtroDefault.dataTermino);
    this.getOptionsPeriodo();
  }

  getOptionsPeriodo() {
    http
      .post(`/TipoFiltroDataLookup/Search`, {
        pageSize: 999999,
      })
      .then((response) => {
        let list = response.data.list.filter((x) => x.id !== 6);
        this.setState({
          filtroDefault: {
            ...this.state.filtroDefault,
          },
          options: list,
        });
      });
  }

  handleChangeFiltro(field, value) {
    let { model } = this.props;
    model.getModel(field).requestChange(value);
  }

  handleChangeItem(value) {
    if (value == null)
      this.props.model.getModel('desdobramento').requestChange(null);
    else
      this.props.model
        .getModel('desdobramento')
        .requestChange(value.desdobramento);

    this.props.model.getModel('itens').requestChange(value);
  }

  render() {
    let { model, resources, intl, configuracaoSistema } = this.props;

    return (
      <>
        <Row>
          <Col md={3}>
            <ItemOrganizacaoLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.areas),
                value: model.getModel('areas').value,
                requestChange: model.getModel('areas').requestChange,
              }}
              data={{ tiposIdSelecionaveis: [1, 2, 3] }}
            />
          </Col>
          <Col md={3}>
            <Checkbox
              model={{
                label: intl.formatMessage(
                  { id: 'label.considerarAreasSubordinadas' },
                  { areas: resources.areas }
                ),
                value: model.getModel('areasSubordinadas').value,
                requestChange:
                  model.getModel('areasSubordinadas').requestChange,
              }}
              disabled={!model.getModel('areas').value}
              style={{ marginTop: 27 }}
            />
          </Col>
          <Col md={6}>
            <PeriodoData
              options={this.state.options}
              tipoFiltroData={model.getModel('tipoFiltroData').value}
              dataInicio={model.getModel('dataInicio').value}
              dataTermino={model.getModel('dataTermino').value}
              onChange={this.handleChangeFiltro}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <ResponsavelLookup
              multi={true}
              model={{
                label: capitalizeFirstLetter(resources.responsaveis),
                value: model.getModel('responsaveis').value,
                requestChange: model.getModel('responsaveis').requestChange,
              }}
            />
          </Col>
          <Col md={2}>
            <StatusRelatorioAcompanhamento
              clearable
              data={{ idSelecionaveis: [0, 1, 2] }}
              model={model.getModel('statusRelatorioAcompanhamento')}
            />
          </Col>
          <Col md={3}>
            <ItemLookup
              multi={true}
              model={{
                label: model.getModel('itens').label,
                value: model.getModel('itens').value,
                requestChange: this.handleChangeItem,
              }}
              data={{ tiposIdsSelecionados: [1, 2, 3, 10, 11] }}
            />
          </Col>
          {configuracaoSistema.utilizarFuncionalidadeDesdobramento && (
            <Col md={3}>
              <DesdobramentoLookup
                model={model.getModel('desdobramento')}
              ></DesdobramentoLookup>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={3}>
            <ClassificacaoRelatorioAcompanhamentoLookup
              model={model.getModel('classificacao')}
            />
          </Col>
          <Col md={2}>
            <FarolLookup
              data={{ idsIgnorados: null }}
              clearable
              model={{
                label: capitalizeFirstLetter(resources.farol),
                value: model.getModel('farol').value,
                requestChange: model.getModel('farol').requestChange,
              }}
            />
          </Col>
          <Col md={2}>
            <FarolLookup
              data={{ idsIgnorados: null }}
              clearable
              model={{
                label: capitalizeFirstLetter(resources.farolAcumulado),
                value: model.getModel('farolAcumulado').value,
                requestChange: model.getModel('farolAcumulado').requestChange,
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracaoSistema: state.user.configuracao,
  };
}
export default injectIntl(
  connect(mapStateToProps)(RelatorioAcompanhamentoSearch)
);
