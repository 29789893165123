import React from 'react';
import { autobind } from 'core-decorators';
import '../style/item-arvore.scss';

@autobind
class ItemArvore extends React.Component {
  render() {
    let { children, label, item } = this.props;

    let expandido = item.expandido && item.filhos && item.filhos.length;

    return (
      <div className={`item-arvore ${expandido ? 'expandido' : ''}`}>
        {label}
        {children && <div className="sub-itens">{children}</div>}
      </div>
    );
  }
}

export default ItemArvore;
