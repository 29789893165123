import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import http from 'src/services/httpService';
import errorHandler from 'src/utils/error-handler';
import Button from 'src/componentes/button';
import LoadingContainer from 'src/componentes/loading-container';
import Input from 'src/componentes/input';
import { Row, Col, Badge, Label } from 'reactstrap';
import Dialog from 'src/componentes/dialog';
import OrganizacaoLookup from 'src/lookups/item-organizacao';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import useAuth from 'src/hooks/useAuth';
import { capitalizeFirstLetter } from 'src/utils/string';
import PanelCollapse from 'src/componentes/panel-collapse';
import Panel from 'src/componentes/panel';
import Alert from 'src/componentes/message-box/alert';
import ContentManager from 'src/componentes/content-manager';
import DatePicker from 'src/componentes/date-picker';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import PlanoTesteEdit from './PlanoTeste/PlanoTesteEdit';
import TesteControleResultado from './Resultado/TesteControleResultado';
import { AVALIACAO_TESTE_CONTROLE } from 'src/utils/constants';

const TesteControleEdit = ({
  item,
  openEdit,
  handleRefresh,
  endpoint,
  configuracao,
  disabled,
  isNew,
  ...rest
}) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlanoTeste, setIsLoadingPlanoTeste] = useState(false);
  const [isLoadingConcluir, setIsLoadingConcluir] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const testeConcluido = form?.status?.id === 2;
  const isDisabled =
    !form?.permissions?.allowEdit || (isNew && disabled) || testeConcluido;

  const handleChange = (field, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const getModel = async () => {
    const id = item?.id;

    try {
      setIsLoading(true);
      const url = !isNew ? `${endpoint}/Edit` : `${endpoint}/Create`;
      const response = isNew
        ? await http.post(url, { ...rest?.parameters, id })
        : await http.post(url, { id });

      setForm({ ...response.data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getModel();
  }, []);

  const validateRequiredField = (form, concluir) => {

    const requiredFields = [
      'nome',
      'idIntegracao',
      'responsavel',
      'area',
      'dataExecucao',
    ];

    const requiredFieldsPlanoTeste = ['nome'];

    if (form.utilizarPlanoAcao || form.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.FRACO || form.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.DEFICIENCIA_SEVERA){
      requiredFields.push('responsavelPlanoAcao');
      requiredFields.push('sugestao');    
    }

    const errors = {
      planoTeste: {},
    };
    let hasError = false;

    for (const field of requiredFields) {

      const value = _.get(form, field);

      if (field == 'sugestao') {
        if (!isNew && concluir) {
          if (value == null || !value) {
            errors[field] = true;
            hasError = true;
          }
        }
      }
      else {
        if (value == null || !value) {
          errors[field] = true;
          hasError = true;
        }
      }
    }

    if (!isNew && concluir) {
      for (const fieldPlano of requiredFieldsPlanoTeste) {
        const value = _.get(form.planoTeste, fieldPlano);
        if (value == null || !value) {
          errors.planoTeste[fieldPlano] = true;
          hasError = true;
        }
      }
    }

    setErrors(errors);

    return { hasError };
  };

  const handleSavePlanoTeste = async (planoTeste) => {
    try {
      setIsLoadingPlanoTeste(true);
      await http.post(`planoTeste/Save`, {
        model: {
          ...planoTeste,
          testeControleId: form.id,
        },
        parameters: {
          testeControleId: form.id,
        },
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoadingPlanoTeste(false);
    }
  };

  const handleConcluir = async (reverter) => {
    try {
      setIsLoadingConcluir(true);
      await http.post(`TesteControle/Concluir`, {
        idTesteControle: form.id,
        reverter: reverter,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoadingConcluir(false);
    }
  };

  const handlePreConcluir = async (reverter) => {
    handleSave(true, reverter);
  };

  const handleSave = async (concluir, reverter) => {
    var { hasError } = reverter ? false : validateRequiredField(form, concluir);

    if (hasError)
      ContentManager.addContent(
        <Alert
          message={intl.formatMessage({
            id: 'label.osCamposDestacadosEmVermelhoSaoObrigatoriosFavorPreenchelos',
          })}
        />
      );
    else {
      try {
        setIsLoading(true);
        if (!reverter) {
          await http.post(`${endpoint}/Save`, {
            model: {
              ...form,
              id: item.id,
            },
            parameters: {
              concluir: concluir,
              reverter: reverter,
            },
          });

          enqueueSnackbar(
            intl.formatMessage({ id: 'label.registroSalvoComSucesso' }),
            {
              variant: 'success',
            }
          );
        }

        !isNew && !reverter && handleSavePlanoTeste(form.planoTeste);
        concluir && handleConcluir(reverter);
        handleClose();
        handleRefresh();
      } catch (err) {
        errorHandler(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const selectRiscosAssociados = (itens) => {
    handleChange('riscosAssociados', itens);
  };

  const editActions = [
    <Button key={1} onClick={handleClose} color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button
      key={2}
      disabled={isDisabled}
      type="primary"
      onClick={() => handleSave(false)}
      htmlType="submit"
      className="ml-2"
    >
      {intl.formatMessage({ id: 'label.salvar' })}
    </Button>,
  ];

  if (testeConcluido) {
    editActions.push(
      <Button
        key={4}
        disabled={(isLoadingConcluir && !testeConcluido) || !form?.podeReverter}
        type="primary"
        onClick={() => handlePreConcluir(true)}
        htmlType="submit"
        className="ml-2"
      >
        {intl.formatMessage({ id: 'labelReverterConclusao' })}
      </Button>
    );
  } else if (!isNew) {
    editActions.push(
      <Button
        key={3}
        disabled={isDisabled}
        type="primary"
        onClick={() => handlePreConcluir(false)}
        htmlType="submit"
        className="ml-2"
      >
        {intl.formatMessage({ id: 'concluir' })}
      </Button>
    );
  }

  const renderStatus = () => {
    const status = form?.status;

    if (!status) return null;

    if (!status?.cor)
      return (
        <div style={{ marginBottom: '1rem' }}>
          <Label>{intl.formatMessage({ id: 'status' })}</Label>
          <div>{intl.formatMessage({ id: status.descricao })}</div>
        </div>
      );

    return (
      <div style={{ marginBottom: '1rem' }}>
        <Label>{intl.formatMessage({ id: 'status' })}</Label>
        <div>
          <Badge
            color="custom"
            style={{ backgroundColor: status.cor, color: 'white' }}
          >
            {intl.formatMessage({ id: status?.descricao })}
          </Badge>
        </div>
      </div>
    );
  };

  const renderRowInfo = (label, value) => {
    if (!value) return null;
    return (
      <Row>
        <Col className="d-flex inline">
          {label}: <p className="font-weight-bolder pl-1">{value}</p>
        </Col>
      </Row>
    );
  };

  const renderRisco = (risco, index) => {
    return (
      <div id={`risco-${index}`}>
        <Row>
          <Col className="d-flex inline">
            {resources.risco}:
            <p className="font-weight-bolder pl-1">{risco?.nome}</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex inline">
            {capitalizeFirstLetter(resources.area)}:
            <p className="font-weight-bolder pl-1">{risco?.area}</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex inline">
            {capitalizeFirstLetter(resources.responsaveis)}:
            <p className="font-weight-bolder pl-1">
              {risco?.responsavelControle}
            </p>
          </Col>
        </Row>
        <hr />
      </div>
    );
  };

  const renderCampoCustomizado = (campoCustomizado, index) => {
    return (
      <div id={`campoCustomizado-${index}`} key={index}>
        <Row>
          <Col className="d-flex inline">
            {campoCustomizado?.campo?.nome}:
            <p className="font-weight-bolder pl-1">{campoCustomizado?.valor}</p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Dialog
      minWidth={'80%'}
      maxWidth={'80%'}
      minHeight={'60%'}
      actions={editActions}
      onRequestClose={handleClose}
      ref={ref}
      padContent
      textCapitalizeTitle={false}
      titleCapitalizeNone={true}
      title={'labelTesteControle'}
      {...rest}
    >
      <LoadingContainer
        className="pb-3"
        isLoading={isLoading || isLoadingPlanoTeste || isLoadingConcluir}
        onlyLoading={true}
        style={{ overflow: false }}
      >
        <Tabs>
          <Tab key="1" label={intl.formatMessage({ id: 'label.informacoes' })}>
            <Panel
              header={intl.formatMessage({ id: 'label.informacoesBasicas' })}
            >
              <Row>
                <Col md={2}>
                  <Input
                    required
                    disabled={isDisabled}
                    style={{ height: '37px' }}
                    maxLength={100}
                    model={{
                      label: intl.formatMessage({ id: 'label.CodigoDoTeste' }),
                      value: form?.idIntegracao,
                      requestChange: (v) => handleChange('idIntegracao', v),
                      errors: errors?.idIntegracao,
                    }}
                  />
                </Col>
                <Col md={8}>
                  <Input
                    required
                    disabled={isDisabled}
                    style={{ height: '37px' }}
                    maxLength={1000}
                    type="text"
                    model={{
                      label: intl.formatMessage({ id: 'nome' }),
                      value: form?.nome,
                      requestChange: (v) => handleChange('nome', v),
                      errors: errors?.nome,
                    }}
                  />
                </Col>
                <Col md={2}>{renderStatus()}</Col>
              </Row>
              <Row>
                <Col md={6}>
                  <OrganizacaoLookup
                    disabled={isDisabled}
                    controlStyle={{ height: '43px' }}
                    required
                    model={{
                      label: capitalizeFirstLetter(resources.area),
                      value: form?.area,
                      requestChange: (v) => handleChange('area', v),
                      errors: errors?.area,
                    }}
                  />
                </Col>
                <Col md={6}>
                  <ColaboradorSimplificadoLookup
                    required
                    disabled={isDisabled}
                    controlStyle={{ height: '43px' }}
                    type="text"
                    model={{
                      label: capitalizeFirstLetter(resources.responsavel),
                      value: form?.responsavel,
                      requestChange: (v) => handleChange('responsavel', v),
                      errors: errors?.responsavel,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <DatePicker
                    required
                    timeFormat={false}
                    autoFormatDate={true}
                    disabledDate={isDisabled}
                    disabled={isDisabled}
                    height="43px"
                    model={{
                      label: intl.formatMessage({ id: 'dataOperacao' }),
                      value: form?.dataExecucao,
                      requestChange: (v) => handleChange('dataExecucao', v),
                      errors: errors?.dataExecucao,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <DatePicker
                    timeFormat={true}
                    autoFormatDate={true}
                    disabledDate={true}
                    disabled={true}
                    height="43px"
                    model={{
                      label: intl.formatMessage({ id: 'dataConclusao' }),
                      value: form?.dataConclusao,
                    }}
                  />
                </Col>
                <Col md={6}>
                  <ColaboradorSimplificadoLookup
                    required={form?.utilizarPlanoAcao || form?.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.FRACO || form?.avaliacaoTeste == AVALIACAO_TESTE_CONTROLE.DEFICIENCIA_SEVERA}
                    multi={false}
                    disabled={isDisabled}
                    controlStyle={{ height: '43px' }}
                    type="text"
                    model={{
                      label: capitalizeFirstLetter(intl.formatMessage(
                        {
                          id: 'label.ResponsavelPeloPlanoDeAcao',
                        },
                        {
                          responsavel: resources.responsavel,
                          acao: resources.acao,
                        }
                      )),
                      value: form?.responsavelPlanoAcao,
                      requestChange: (v) => handleChange('responsavelPlanoAcao', v),
                      errors: errors?.responsavelPlanoAcao,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Input
                    disabled={isDisabled}
                    type="textarea"
                    maxLength={10000}
                    rows={4}
                    model={{
                      label: intl.formatMessage({ id: 'descricao' }),
                      value: form?.descricao,
                      requestChange: (v) => handleChange('descricao', v),
                    }}
                  />
                </Col>
              </Row>
            </Panel>
          </Tab>
          {!isNew && (
            <Tab
              key="2"
              label={intl.formatMessage({
                id: 'labelPlanoTesteResumoResultado',
              })}
            >
              <PanelCollapse
                header={intl.formatMessage(
                  { id: 'labelInformacoesControle' },
                  { pontodecontrole: resources.pontodecontrole }
                )}
              >
                {renderRowInfo(
                  intl.formatMessage(
                    { id: 'idIntegracao' },
                    { pontodecontrole: resources.pontodecontrole }
                  ),
                  form?.informacoesBasicas?.idIntegracaoControle
                )}
                {renderRowInfo(
                  intl.formatMessage(
                    { id: 'nomePontoControle' },
                    { pontodecontrole: resources.pontodecontrole }
                  ),
                  form?.informacoesBasicas?.controle
                )}
                {renderRowInfo(
                  intl.formatMessage({ id: 'periodicidade' }),
                  form?.informacoesBasicas?.periodicidade
                )}
                {renderRowInfo(
                  intl.formatMessage({ id: 'tipoEvidencia' }),
                  form?.informacoesBasicas?.tipoEvidencia
                )}
                {form?.camposCustomizados?.map((campoCustomizado, index) => 
                  renderCampoCustomizado(campoCustomizado, index)
                )}
                <hr />
                {form?.informacoesBasicas?.riscos?.map((risco, index) =>
                  renderRisco(risco, index)
                )}
              </PanelCollapse>
              <PlanoTesteEdit
                isDisabled={isDisabled}
                errors={errors}
                planoTeste={form?.planoTeste}
                intl={intl}
                controleId={form?.controle?.id}
                testeControleId={form?.id}
                handleChange={handleChange}
                handleSave={handleSavePlanoTeste}
              />
              <TesteControleResultado 
                isDisabled={isDisabled} 
                model={form} 
                intl={intl} 
                errors={errors} 
                handleChange={handleChange} 
                resources={resources} />
            </Tab>
          )}
        </Tabs>
      </LoadingContainer>
    </Dialog>
  );
};

export default TesteControleEdit;
