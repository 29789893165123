import React from 'react';
import http from 'src/services/httpSelector';
import LoadingContainer from '../loading-container';
import SvgIcon from '../svg-icon';
import { connect } from 'react-redux';

class LegendaAcao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      farois: [],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({
      isLoading: true,
    });

    http
      .getHttp(this.props.viewType)
      .get(`/Acao/ObterFaroisLegendaAcao`)
      .then((response) => {
        this.setState({
          farois: response.data.map((id) => id),
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <LoadingContainer
        isLoading={!this.props.silentLoading && this.state.isLoading}
      >
        <div className="d-flex justify-content-between">
          {this.state.farois &&
            this.state.farois.map((farol, index) => (
              <div className="d-flex align-items-center mx-2" key={index}>
                <SvgIcon
                  icon={farol.icone}
                  color={farol.cor}
                  colorHover={farol.cor}
                  style={{ marginRight: 10, marginTop: 5 }}
                />
                <div>{farol.descricao}</div>
              </div>
            ))}
        </div>
      </LoadingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    viewType: state.viewType,
  };
}
export default connect(mapStateToProps)(LegendaAcao);
