import { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import ProgressBar from 'src/componentes/NewProgressBar';
import guid from 'src/utils/guid';

const FakeSlider = ({
  percent,
  scaleFirstValue,
  scaleLastValue,
  value,
  color,
  markers,
  containerHeight,
  inlineScaleValues = false,
  ...rest
}) => {
  const [componentId] = useState(guid());
  const stablePercent = percent < 0 ? 0 : percent > 100 ? 100 : percent;

  const stringPercent = `${stablePercent}%`;
  const objectsSize = `calc(${containerHeight}px + 7px)`;

  return (
    <div className="w-100">
      <div className="d-flex w-100">
        {inlineScaleValues && (
          <div className="mr-3 invisible">{scaleFirstValue}</div>
        )}
        <div className="w-100">
          <div
            className="d-flex justify-content-center position-relative mb-1"
            style={{
              height: objectsSize,
              width: objectsSize,
              left: stringPercent,
              transform: 'translate(-50%, -50%)',
            }}
          >
            {value}
          </div>
        </div>
        {inlineScaleValues && (
          <div className="ml-3 invisible">{scaleLastValue}</div>
        )}
      </div>
      <div className="d-flex">
        {inlineScaleValues && <div className="mr-3">{scaleFirstValue}</div>}
        <div className="position-relative d-flex align-items-center w-100">
          <ProgressBar
            containerHeight={containerHeight}
            itens={[
              {
                percentual: stablePercent,
                cor: color,
              },
            ]}
            {...rest}
          />
          <div
            className="position-absolute rounded-circle"
            style={{
              height: objectsSize,
              width: objectsSize,
              background: color,
              top: '50%',
              left: stringPercent,
              transform: 'translate(-50%, -50%)',
              border: '2px solid #fff',
              boxShadow: 'rgba(0, 0, 0, 0.8) 0px 2px 15px',
            }}
          ></div>
          {markers &&
            markers.map((m) => {
              const id = `FAKE-SLIDER-MARKER-ID-${componentId}-${m.id}`;
              return (
                <>
                  <div
                    id={id}
                    className="position-absolute bg-primary"
                    style={{
                      bottom: 0,
                      left: `${m.percentual}%`,
                      width: 3,
                      height: objectsSize,
                      zIndex: 5,
                    }}
                  ></div>
                  <UncontrolledTooltip target={id} placement="right">
                    {m.valor}
                  </UncontrolledTooltip>
                </>
              );
            })}
        </div>
        {inlineScaleValues && <div className="ml-3">{scaleLastValue}</div>}
      </div>
      {!inlineScaleValues && (
        <div className="d-flex justify-content-between mt-3">
          <div>{scaleFirstValue}</div>
          <div>{scaleLastValue}</div>
        </div>
      )}
    </div>
  );
};

export default FakeSlider;
