import { Schema } from 'src/utils/form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default Schema.object({
  colaborador: Schema.string().label(),
  area: Schema.string().label(),
  cargo: Schema.string().label(),
  dataInicio: Schema.string().label(<FormattedMessage id="dataInicio" />),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
});
