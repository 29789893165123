import MdiIcon from 'src/componentes/mdi-icon';
import Button from 'src/componentes/button';
import ContentManager from 'src/componentes/content-manager';

function ButtonRestartStepsTour({ setCurrentStep }) {

  const handleClick = () => {
    ContentManager.removeAllContentsByTarget();
    setCurrentStep(0);
  };

  return (
    <Button
      style={{
        background: 'white',
        border: 'none',
        cursor: 'pointer',
        color: '#646464',
      }}
      onClick={handleClick}
    >
      <MdiIcon icon="restart" size={20} color={'#646464'} />
    </Button>
  );
}

export default ButtonRestartStepsTour;
