import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Table, Badge } from 'reactstrap';
import PontuacaoAnaliseHipoteseLookup from 'src/lookups/PontuacaoAnaliseHipoteseLookup';
import MdiIcon from 'src/componentes/mdi-icon';
import Confirm from 'src/componentes/NewConfirm';
import ContentManager from 'src/componentes/content-manager';

const quantidadeMaxPontuacaoCausa = [
  {
    id: 1,
    notas1: 1,
    notas3: 0,
    notas5: 0,
    descricao: '1 causa',
  },
  {
    id: 2,
    notas1: 1,
    notas3: 1,
    notas5: 0,
    descricao: '2 causas',
  },
  {
    id: 3,
    notas1: 1,
    notas3: 1,
    notas5: 1,
    descricao: '3 causas',
  },
  {
    id: 4,
    notas1: 2,
    notas3: 1,
    notas5: 1,
    descricao: '4 causas',
  },
  {
    id: 5,
    notas1: 2,
    notas3: 2,
    notas5: 1,
    descricao: '5 causas',
  },
  {
    id: 6,
    notas1: 3,
    notas3: 2,
    notas5: 1,
    descricao: '6 causas',
  },
  {
    id: 7,
    notas1: 4,
    notas3: 2,
    notas5: 1,
    descricao: '7 causas',
  },
  {
    id: 8,
    notas1: 4,
    notas3: 2,
    notas5: 2,
    descricao: '8 causas',
  },
  {
    id: 9,
    notas1: 4,
    notas3: 3,
    notas5: 2,
    descricao: '9 causas',
  },
  {
    id: 10,
    notas1: 5,
    notas3: 3,
    notas5: 2,
    descricao: '10 causas',
  },
  {
    id: 11,
    notas1: 6,
    notas3: 3,
    notas5: 2,
    descricao: '11 causas',
  },
  {
    id: 12,
    notas1: 6,
    notas3: 4,
    notas5: 2,
    descricao: '12 causas',
  },
  {
    id: 13,
    notas1: 6,
    notas3: 4,
    notas5: 3,
    descricao: '13 causas',
  },
  {
    id: 14,
    notas1: 7,
    notas3: 4,
    notas5: 3,
    descricao: '14 causas',
  },
  {
    id: 15,
    notas1: 7,
    notas3: 5,
    notas5: 3,
    descricao: '15 causas',
  },
  {
    id: 16,
    notas1: 8,
    notas3: 5,
    notas5: 3,
    descricao: '16 causas',
  },
  {
    id: 17,
    notas1: 9,
    notas3: 5,
    notas5: 3,
    descricao: '17 causas',
  },
  {
    id: 18,
    notas1: 9,
    notas3: 5,
    notas5: 4,
    descricao: '18 causas',
  },
];

const AnaliseHipotese = ({
  model,
  contadorInicialColunaParticipantes = 2,
  handleChangeData,
  allowEdit,
}) => {
  const intl = useIntl();
  const [columnsCount, setColumnsCount] = useState(0);
  const [data, setData] = useState([]);
  const [objetoMaxPontuacaoCausa, setObjetoMaxPontuacaoCausa] = useState(
    quantidadeMaxPontuacaoCausa[1]
  );
  const [notasUtilizadoPorParticipante, setNotasUtilizadoPorParticipante] =
    useState([]);
  const [sort, setSort] = useState(null);

  const tablesArray = Array(
    columnsCount + contadorInicialColunaParticipantes
  ).fill(0);

  useEffect(() => {
    setData(model || []);
    if (model && model.length > 0) {
      setObjetoMaxPontuacaoCausa(quantidadeMaxPontuacaoCausa[model.length - 1]);
    }
  }, [model]);

  useEffect(() => {
    handleChangeData &&
      handleChangeData(
        'step3Data',
        'contadorFinalColunaParticipantes',
        columnsCount + contadorInicialColunaParticipantes
      );
  }, [columnsCount]);

  const atualizaQuantidadeVotosRealizadosPorParticipante = (analises) => {
    const lenght =
      (analises && analises[0]?.pontuacaoParticipantes?.length) ?? 0;
    var notasUtilizadoPorParticipante = [lenght];

    for (let i = 0; i < lenght; i++) {
      notasUtilizadoPorParticipante[i] = [
        obterQuantidadeVotosPorParticipante(i, 1),
        obterQuantidadeVotosPorParticipante(i, 2),
        obterQuantidadeVotosPorParticipante(i, 3),
      ];
    }

    setNotasUtilizadoPorParticipante(notasUtilizadoPorParticipante);
  };

  const obterQuantidadeVotosPorParticipante = (indexParticipante, nota) => {
    var quantidadeNotas =
      data &&
      data.reduce(function (acumulador, objeto) {
        if (
          objeto.pontuacaoParticipantes &&
          objeto.pontuacaoParticipantes.length > 0 &&
          objeto.pontuacaoParticipantes[indexParticipante] != null &&
          objeto.pontuacaoParticipantes[indexParticipante].id === nota
        ) {
          acumulador++;
        }
        return acumulador;
      }, 0);

    return quantidadeNotas;
  };

  const renderContent = () => {
    return (
      <Table bordered size="sm">
        <thead>
          <th
            className="text-white bg-primary py-3 position-sticky"
            style={{ minWidth: 350, left: 0, top: 0, zIndex: 3 }}
          >
            <div>{intl.formatMessage({ id: 'causaInfluente' })}</div>
          </th>
          {tablesArray?.map((_, index) => {
            return (
              <th
                className="text-white bg-primary py-3 text-center position-sticky"
                style={{ minWidth: 280, top: 0, zIndex: 2 }}
              >
                {renderColumn(index)}
              </th>
            );
          })}
          <th
            className="text-white bg-primary text-center py-3 position-sticky"
            style={{ minWidth: 80, right: 0, top: 0, zIndex: 3 }}
          >
            {intl.formatMessage({ id: 'total' })}
            <MdiIcon
              size={12}
              onClick={handleChangeSort}
              style={{ marginTop: 1 }}
              className="ml-2 cursor-pointer"
              icon={
                !sort
                  ? 'unfold-more-horizontal'
                  : sort === 'asc'
                  ? 'chevron-down'
                  : 'chevron-up'
              }
            />
          </th>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((analise, index) => {
              return (
                <tr>
                  <td
                    className="position-sticky bg-white"
                    style={{ left: 0, zIndex: 2 }}
                  >
                    {renderRowDescricaoCausa(index, analise)}
                  </td>
                  {analise &&
                    analise.pontuacaoParticipantes &&
                    analise.pontuacaoParticipantes.map(
                      (value, indexParticipante) => (
                        <td>
                          {renderRowParticipantes(
                            value,
                            index,
                            indexParticipante
                          )}
                        </td>
                      )
                    )}
                  <td
                    className="text-center position-sticky bg-white"
                    style={{ right: 0, zIndex: 2 }}
                  >
                    {analise &&
                      analise.pontuacaoParticipantes &&
                      renderRowTotalCausa(analise)}
                  </td>
                </tr>
              );
            })}
          <tr>
            <td
              className="font-weight-bolder py-2 position-sticky bg-white"
              style={{ left: 0, zIndex: 3 }}
            >
              {intl.formatMessage({ id: 'total' })}
            </td>
            {tablesArray?.map((_, index) => {
              return (
                <td className="text-center py-2">
                  {renderRowTotalParticipante(index)}
                </td>
              );
            })}
          </tr>
          <tr>
            <td
              className="py-2 position-sticky bg-white"
              style={{ color: '#8D8787', fontWeight: 700, left: 0, zIndex: 3 }}
            >
              {intl.formatMessage({ id: 'notas5' })}
            </td>
            {tablesArray?.map((_, index) => {
              return (
                <td className="text-center py-2">
                  {renderRowTotalQuantidadePontuacaoEspecifica(index, 5)}
                </td>
              );
            })}
          </tr>
          <tr>
            <td
              className="py-2 position-sticky bg-white"
              style={{ color: '#8D8787', fontWeight: 700, left: 0, zIndex: 3 }}
            >
              {intl.formatMessage({ id: 'notas3' })}
            </td>
            {tablesArray?.map((_, index) => {
              return (
                <td className="text-center py-2">
                  {renderRowTotalQuantidadePontuacaoEspecifica(index, 3)}
                </td>
              );
            })}
          </tr>
          <tr>
            <td
              className="py-2 position-sticky bg-white"
              style={{ color: '#8D8787', fontWeight: 700, left: 0, zIndex: 3 }}
            >
              {intl.formatMessage({ id: 'notas1' })}
            </td>
            {tablesArray?.map((_, index) => {
              return (
                <td className="text-center py-2">
                  {renderRowTotalQuantidadePontuacaoEspecifica(index, 1)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    );
  };

  const handleChangeSort = () =>
    handleOrderAnalisesHipoteses((prev) => (prev === 'asc' ? 'desc' : 'asc'));

  const handleOrderAnalisesHipoteses = (fn) => {
    const newSort = fn ? fn(sort) : null;
    setSort(newSort);

    const newList = Object.assign([], data);

    const isAsc = sort === 'asc';

    if (isAsc) {
      newList.sort((a, b) => {
        const firstValue = a.pontuacaoTotalCausa || 0;
        const secondValue = b.pontuacaoTotalCausa || 0;

        return firstValue - secondValue;
      });
    } else {
      newList.sort((a, b) => {
        const firstValue = a.pontuacaoTotalCausa || 0;
        const secondValue = b.pontuacaoTotalCausa || 0;

        return secondValue - firstValue;
      });
    }

    setData(newList);
  };

  const renderColumn = (index) => {
    const isNotRemove = index === 0 || index === 1;
    const nameColumn =
      intl.formatMessage({ id: 'participante' }) + ' ' + (index + 1);

    if (isNotRemove) return <div>{nameColumn}</div>;
    else {
      return (
        <div>
          <span>{nameColumn}</span>
          <MdiIcon
            className="cursor-pointer ml-1"
            icon="delete-outline"
            size={20}
            onClick={() => handleOpenAlertDelete(index)}
          />
        </div>
      );
    }
  };

  const renderRowDescricaoCausa = (index, analise) => {
    return (
      <>
        <span style={{ color: '#8D8787', fontWeight: 700 }}>{index + 1}</span>
        <MdiIcon
          className="cursor-pointer ml-3 text-primary"
          icon={analise.favoritado ? 'star' : 'star-outline'}
          size={24}
          onClick={() => handleHeaderClickStar(index)}
        />
        <span className="ml-2 mt-1">{analise.descricaoCausa}</span>
        <Badge
          pill
          className="ml-2"
          style={{ background: '#E9ECEF', color: '#3F3F3F' }}
        >
          {`Ish. ${analise.ishikawa}`}
        </Badge>
      </>
    );
  };

  const renderRowParticipantes = (value, index, indexParticipante) => {
    return (
      <PontuacaoAnaliseHipoteseLookup
        disabled={!allowEdit}
        autoFocus={false}
        indexParticipante={indexParticipante}
        notasUtilizadoPorParticipante={notasUtilizadoPorParticipante}
        model={{
          label: '',
          value: value,
          requestChange: (v) =>
            handleChangePontuacaoParticipante(v, index, indexParticipante),
        }}
      />
    );
  };

  const renderRowTotalCausa = (analise) => {
    var soma = analise.pontuacaoParticipantes.reduce(function (
      acumulador,
      objeto
    ) {
      return objeto ? acumulador + objeto.value : acumulador + 0;
    },
    0);

    analise.pontuacaoTotalCausa = soma;

    return <div className="font-weight-bolder">{soma}</div>;
  };

  const renderRowTotalParticipante = (index) => {
    var soma =
      data &&
      data.reduce(function (acumulador, objeto) {
        if (
          objeto.pontuacaoParticipantes &&
          objeto.pontuacaoParticipantes.length > 0
        ) {
          acumulador += objeto.pontuacaoParticipantes[index]
            ? objeto.pontuacaoParticipantes[index].value
            : 0;
        }
        return acumulador;
      }, 0);

    return <div className="font-weight-bolder">{soma}</div>;
  };

  const renderRowTotalQuantidadePontuacaoEspecifica = (index, pontuacao) => {
    var max =
      pontuacao == 5
        ? objetoMaxPontuacaoCausa.notas5
        : pontuacao == 3
        ? objetoMaxPontuacaoCausa.notas3
        : pontuacao == 1
        ? objetoMaxPontuacaoCausa.notas1
        : 0;
    var ocorrencias =
      data &&
      data.reduce(function (acumulador, objeto) {
        if (
          objeto.pontuacaoParticipantes &&
          objeto.pontuacaoParticipantes.length > 0 &&
          objeto.pontuacaoParticipantes[index] != null &&
          objeto.pontuacaoParticipantes[index].value === pontuacao
        ) {
          acumulador++;
        }
        return acumulador;
      }, 0);

    return (
      <div className="font-weight-bolder">{`${ocorrencias} ${intl.formatMessage(
        { id: 'de' }
      )} ${max}`}</div>
    );
  };

  const handleAdd = () => {
    if (columnsCount + contadorInicialColunaParticipantes < 10) {
      setColumnsCount((prev) => prev + 1);

      data.map((analise) => {
        const newList = Object.assign([], analise.pontuacaoParticipantes);
        newList.push(null);
        analise.pontuacaoParticipantes = newList;
      });

      handleSave();
    }
  };

  const handleDelete = (index) => {
    setColumnsCount((prev) => prev - 1);

    data.map((analise) => {
      const newList = Object.assign([], analise.pontuacaoParticipantes);
      newList.splice(index, 1);
      analise.pontuacaoParticipantes = newList;
    });

    handleSave();
  };

  const handleChangePontuacaoParticipante = (
    item,
    index,
    indexParticipante
  ) => {
    let atingiuLimite = false;

    if (
      data[index]?.pontuacaoParticipantes[indexParticipante] != null &&
      item != null &&
      data[index].pontuacaoParticipantes[indexParticipante].id == item.id
    )
      return;

    if (
      objetoMaxPontuacaoCausa &&
      objetoMaxPontuacaoCausa.id === 1 &&
      item &&
      (item.value === 5 || item.value === 3)
    ) {
      return;
    }

    if (
      objetoMaxPontuacaoCausa &&
      objetoMaxPontuacaoCausa.id === 2 &&
      item &&
      item.value === 5
    ) {
      return;
    }

    if (item) {
      var ocorrencias =
        data &&
        data.reduce(function (acumulador, objeto) {
          if (
            objeto.pontuacaoParticipantes &&
            objeto.pontuacaoParticipantes.length > 0 &&
            objeto.pontuacaoParticipantes[indexParticipante] != null &&
            objeto.pontuacaoParticipantes[indexParticipante].value ===
              item.value
          ) {
            acumulador++;
          }
          return acumulador;
        }, 0);

      if (ocorrencias > 0) {
        if (ocorrencias > 0 && item.value === 1) {
          if (ocorrencias >= objetoMaxPontuacaoCausa.notas1)
            atingiuLimite = true;
        } else if (ocorrencias > 0 && item.value === 3) {
          if (ocorrencias >= objetoMaxPontuacaoCausa.notas3)
            atingiuLimite = true;
        } else if (ocorrencias > 0 && item.value === 5) {
          if (ocorrencias >= objetoMaxPontuacaoCausa.notas5)
            atingiuLimite = true;
        }
      }
    }

    if (!atingiuLimite) {
      const newList = Object.assign([], data);
      newList[index].pontuacaoParticipantes[indexParticipante] = item;

      setData(newList);
      atualizaQuantidadeVotosRealizadosPorParticipante(newList);

      handleSave();
    } else {
      ContentManager.addContent(
        <Confirm
          showCancel={false}
          labelConfirm={intl.formatMessage({ id: 'entendi' })}
          primaryMessage={intl.formatMessage(
            { id: 'msgAtingiuNumeroMaxNotasParticipanteCorrente' },
            { nota: item.value }
          )}
        />
      );
    }
  };

  const handleHeaderClickStar = (index) => {
    if (allowEdit) {
      const newList = Object.assign([], data);
      newList[index].favoritado = !newList[index].favoritado;

      setData(newList);

      handleSave();
    }
  };

  const handleSave = () => {
    handleChangeData &&
      handleChangeData('step3Data', 'analiseHipotesesModel', data);

    handleChangeData && handleChangeData('step3Data', 'alterado', true);
  };

  const handleOpenAlertDelete = (index) => {
    ContentManager.addContent(
      <Confirm
        showCancel
        labelConfirm={intl.formatMessage({ id: 'excluir' })}
        primaryMessage={intl.formatMessage({
          id: 'msgVoceEstaExcluindoParticipanteAnalise',
        })}
        secondaryMessage={intl.formatMessage({ id: 'desejaRealmenteExcluir' })}
        onSaved={() => handleDelete(index)}
      />
    );
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        {allowEdit && (
          <div className="cursor-pointer mb-2" onClick={() => handleAdd()}>
            <span>
              {intl.formatMessage({ id: 'adicionar' }) +
                ' ' +
                intl.formatMessage({ id: 'participante' })}
            </span>
            <MdiIcon className="ml-1" icon="plus" size={20} color="#1D7789" />
          </div>
        )}
      </div>
      <div
        style={{ height: 508, maxHeight: 508 }}
        className="overflow-auto new-list-tree w-100"
      >
        {renderContent()}
      </div>
    </>
  );
};

export default AnaliseHipotese;
