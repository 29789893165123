import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';
import AcaoList from 'src/paginas/acao/select-list';
import Radio from 'src/componentes/radio';
import Card from 'src/componentes/panel-collapse';
import { Row, Col, Container } from 'reactstrap';
import http from 'src/services/httpService';
import Table from 'src/componentes/table';
import EfetividadeLookup from 'src/lookups/ponto-controle-revisao';
import ContentManager from 'src/componentes/content-manager';
import Alert from 'src/componentes/message-box/alert';
import { capitalizeFirstLetter } from 'src/utils/string';

@autobind
class ConformeEfetivo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      podeConfirmar: false,
      podeOptarEfetivo: false,
      efetivo: null,
      opcoesEfetivo: [
        {
          id: 1,
          descricao: this.props.intl.formatMessage({ id: 'efetivo' }),
        },
        {
          id: 2,
          descricao: this.props.intl.formatMessage({ id: 'naoEfetivo' }),
        },
      ],
      conforme: {},
      opcoesConforme: [
        {
          id: 1,
          descricao: resources.conforme,
        },
        {
          id: 2,
          descricao: resources.naoconforme,
        },
      ],
      openListaAcao: true,
      openListaAcaoEfetivar: true,
      openConforme: true,
    };
  }

  handleChange(field, value) {
    this.setState({
      ...this.state,
      [field]: value,
    });
  }

  handleLookupChange(efetividade, index, valor) {
    let listaRevisaoAcaoModelChange = this.state.listaRevisaoAcaoModel;
    listaRevisaoAcaoModelChange[index].efetividade = valor;

    this.setState({
      listaRevisaoAcaoModel: listaRevisaoAcaoModelChange,
    });
  }

  handlePodeConfirmar() {
    http
      .post(`/RiscoPontoControleRevisao/PodeConfirmar`, {
        pontoControleId: this.props.pontoControleId,
      })
      .then((response) => {
        this.setState({
          ...response.data,
        });
      });
  }

  componentDidMount() {
    this.handlePodeConfirmar();
    this.ObterRevisaoAcoesModelParaEfetivar();
  }

  renderAcaoList() {
    return (
      <AcaoList
        showDelete={false}
        params={{
          itemId: this.props.riscoId,
          riscoPontoControleRevisaoId: this.props.revisaoId,
        }}
        showPrevious={false}
        showLink={true}
        showNew={false}
        disabled={this.state.podeConfirmar}
        showExport={false}
        showSearch={false}
        showMenu={false}
      />
    );
  }

  renderConforme() {
    let { openConforme, conforme, opcoesConforme } = this.state;

    return (
      <Card open={openConforme} header={this.props.intl.formatMessage({ id: 'conforme' })}>
        <Row>
          <Col md={6}>
            <Radio
              model={{
                label: '',
                value: conforme,
                requestChange: this.handleChange.bind(this, 'conforme'),
              }}
              options={opcoesConforme}
              labelField="descricao"
              idField="id"
            />
          </Col>
        </Row>
      </Card>
    );
  }

  handleClose() {
    this.refs.dialog && this.refs.dialog.close();
  }

  handleSalvar() {
    http
      .post(`/RiscoPontoControleRevisao/SalvarRevisaoAcao`, {
        riscoPontoControleRevisaoId: this.props.revisaoId,
        listaRevisaoAcaoModel: this.state.listaRevisaoAcaoModel,
        conforme: this.state.conforme,
      })
      .then((response) => {
        this.refs && this.refs.dialog.close();
        ContentManager.addContent(<Alert title={response.data.title} message={response.data.message} />);
      });
  }

  ObterRevisaoAcoesModelParaEfetivar() {
    http
      .post(`/RiscoPontoControleRevisao/ObterRevisaoAcoesModelParaEfetivar`, {
        revisaoId: this.props.revisaoId,
        conforme: this.state.conforme,
      })
      .then((response) => {
        this.setState({
          ...response.data,
        });
      });
  }

  renderListaAcaoParaEfetivar() {
    let { listaRevisaoAcaoModel, podeOptarEfetivo } = this.state;
    let { intl } = this.props;

    return (
      <Table>
        <thead>
          <th>{intl.formatMessage({ id: 'codigo' })}</th>
          <th>{intl.formatMessage({ id: 'descricao' })}</th>
          <th>{intl.formatMessage({ id: 'label.efetividade' })}</th>
        </thead>
        <tbody>
          {listaRevisaoAcaoModel &&
            listaRevisaoAcaoModel.map((acaoRevisao, acaoIndex) => (
              <tr key={acaoIndex + 1}>
                <td>{acaoRevisao.acao.codigo}</td>
                <td>{acaoRevisao.acao.descricao}</td>
                <td>
                  {
                    <EfetividadeLookup
                      clearable
                      disabled={!podeOptarEfetivo}
                      required
                      model={{
                        value: acaoRevisao.efetividade,
                        requestChange: this.handleLookupChange.bind(this, 'efetividade', acaoIndex),
                      }}
                    />
                  }
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }

  render() {
    let { intl, resources } = this.props;
    let { podeOptarEfetivo, conforme, openListaAcaoEfetivar } = this.state;
    let actions = [
      <Button key={1} onClick={this.handleClose} color="secondary">
        {intl.formatMessage({ id: 'label.cancelar' })}
      </Button>,
      <Button key={2} bsStyle="primary" className="ml-2" onClick={this.handleSalvar}>
        {intl.formatMessage({ id: 'label.confirmar' })}
      </Button>,
    ];

    return (
      <Dialog
        title={capitalizeFirstLetter(resources.planodeacao)}
        width="70%"
        maxHeight="90%"
        onRequestClose={this.handleCloseDialog}
        mouseDownClose={false}
        ref="dialog"
        actions={actions}
        {...this.props}
      >
        <Container>
          <Row>
            <Col>{this.renderAcaoList()}</Col>
          </Row>
          <Row>
            <Col>{podeOptarEfetivo && conforme && conforme.id === 2 && <Card open={openListaAcaoEfetivar}>{this.renderListaAcaoParaEfetivar()}</Card>}</Col>
          </Row>
        </Container>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(connect(mapStateToProps)(ConformeEfetivo));
