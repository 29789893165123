import React from 'react';
import { autobind } from 'core-decorators';
import { Schema } from '../../utils/form';
import EditPage from '../../componentes/edit/edit-page';
import EditDialog from '../../componentes/edit/edit-dialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/string';
import FormEdit from './edit-form';
import http from 'src/services/httpService';
import errorHandler from '../../utils/error-handler';

let schema = Schema.object({
  id: Schema.number().label('id').resource('relatorioacompanhamento.id'),
  arquivos: Schema.array().label(<FormattedMessage id="label.anexos" />),
  pontosProblematicos: Schema.array().label(
    <FormattedMessage id="label.anexos" />
  ),
  configuracao: Schema.string().label(<FormattedMessage id="configuracoes" />),
  item: Schema.string().label(<FormattedMessage id="item" />),
  descricaoPeriodo: Schema.string().label(<FormattedMessage id="periodo" />),
  status: Schema.string().label(),
  statusDefasadoAlterado: Schema.string().label(),
  meta: Schema.string().label(),
  realizado: Schema.string().label(<FormattedMessage id="realizado" />),
  relatorioAcompanhamentoOrigem: Schema.string().label(),
});

const divIdToExport = 'relatorioAcompanhamentoModalEdit';

@autobind
class RelatorioAcompanhamentoEdit extends React.Component {
  static defaultProps = {
    mode: 'dialog',
  };

  constructor(props) {
    super(props);

    this.state = {
      habilitarEnvioAutomatico: false,
    };
  }

  componentDidMount() {
    http
      .post(`/RelatorioAcompanhamento/ObterConfiguracaoEmailAutomatico`, {})
      .then((response) => {
        if (response.data !== null) {
          this.setState({
            habilitarEnvioAutomatico: response.data.habilitarEnvioAutomatico,
          });
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleClose() {
    this.refs && this.refs.dialog && this.refs.dialog.close();
    if (this.props && this.props.refreshWidgetCount) {
      this.props.refreshWidgetCount();
      this.props.saved();
      this.props.handleClose && this.props.handleClose();
    }
  }

  handleHtmlString(model) {
    const relatorioPag = document.getElementById('graficoRelatorioPag');
    const htmlString = relatorioPag && relatorioPag.outerHTML;

    //seleciona as medidas do gráfico para evitar problema com o zoom da tela
    const svgs = relatorioPag && relatorioPag.querySelectorAll('svg');

    const width = (svgs && svgs[0] && svgs[0].getAttribute('width')) || 1040;
    const height = (svgs && svgs[0] && svgs[0].getAttribute('height')) || 400;

    return {
      htmlString: htmlString,
      width: width,
      height: height,
    };
  }

  handleSendMailSave(htmlString, width, height) {
    const { parameters, item } = this.props;
    const classificacaoId =
      parameters?.classificacao?.id ?? item?.classificacao?.id;
    const ehPlanejamentoInicial = classificacaoId == 2;

    if (this.state.habilitarEnvioAutomatico && !ehPlanejamentoInicial) {
      http
        .post(
          '/RelatorioAcompanhamento/EnviarEmailAutomaticoRelatorioAcompanhamento',
          {
            id: this.props.item && this.props.item.id,
            extensaoExport: 'xlsx',
            htmlString: htmlString,
            width: width,
            height: height,
          }
        )
        .catch((error) => {
          errorHandler(error);
        });
    }
  }

  handleOnClose() {
    http
      .post(
        '/RelatorioAcompanhamentoPontoProblematico/ExcluirPontosProblematicosSemAcoes',
        {
          relatorioId: this.props.item && this.props.item.id,
        }
      )
      .catch((error) => {
        errorHandler(error);
      });
  }

  render() {
    let { mode, resources, intl, parameters, item } = this.props;
    const classificacaoId =
      parameters?.classificacao?.id ?? item?.classificacao?.id;
    const ehPlanejamentoInicial = classificacaoId == 2;

    return mode == 'page' ? (
      <EditPage
        url="/RelatorioAcompanhamento"
        formComponent={FormEdit}
        exportApresentacao={this.props.exportApresentacao}
        schema={schema}
        showTopToolbar={false}
        showExport
        handleHtmlString={this.handleHtmlString}
        urlExport={`/RelatorioAcompanhamento/ExportarRelatorioAcompanhamento`}
        urlExportPuppeteerPDF={`/RelatorioAcompanhamento/ExportToHIQPDF`}
        divIdToExport={divIdToExport}
        asposeToPdf={true}
        usePuppeteerPDF={false}
        showClose={false}
        isConsultaExterna={this.props.isConsultaExterna}
        {...this.props}
      />
    ) : (
      <EditDialog
        url="/RelatorioAcompanhamento"
        title={capitalizeFirstLetter(
          ehPlanejamentoInicial
            ? intl.formatMessage({ id: 'planejamentoInicial' })
            : resources.relatoriodeacompanhamento
        )}
        formComponent={FormEdit}
        exportApresentacao={this.props.exportApresentacao}
        schema={schema}
        width="85%"
        maxHeight="90%"
        showExport
        handleHtmlString={this.handleHtmlString}
        saveLabel={intl.formatMessage({ id: 'label.salvarEFechar' })}
        urlExport={`/RelatorioAcompanhamento/ExportarRelatorioAcompanhamento`}
        refreshModel={this.props.refreshModel}
        handleClose={this.handleClose}
        urlExportPuppeteerPDF={`/RelatorioAcompanhamento/ExportToHIQPDF`}
        sendMailSave={this.handleSendMailSave}
        messageSave={
          this.state.habilitarEnvioAutomatico && !ehPlanejamentoInicial
            ? 'label.salvarRelatorio'
            : ''
        }
        divIdToExport={divIdToExport}
        asposeToPdf={true}
        usePuppeteerPDF={false}
        showClose={false}
        handleOnClose={this.handleOnClose}
        ehPlanejamentoInicial={ehPlanejamentoInicial}
        isConsultaExterna={this.props.isConsultaExterna}
        showSave={!this.props.isConsultaExterna}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default injectIntl(
  connect(mapStateToProps)(RelatorioAcompanhamentoEdit)
);
