import { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';

import http from 'src/services/httpService';
import Input from 'src/componentes/input';
import PanelCollapse from 'src/componentes/panel-collapse';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import { capitalizeFirstLetter } from 'src/utils/string';
import StatusAcaoLookup from 'src/lookups/status-acao';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import DatePicker from 'src/componentes/date-picker';
import PeriodicidadeLookup from 'src/lookups/periodicidade';
import Checkbox from 'src/componentes/checkbox';
import TemaEstrategicoLookup from 'src/lookups/tema-estrategico';
import ItemCentroCustoLookup from 'src/lookups/item-centro-custo';
import PacoteLookup from 'src/lookups/pacote';
import FarolAcaoLookup from 'src/lookups/farol-acao';
import TipoAcaoLookup from 'src/lookups/tipo-acao';
import ClassificacaoLookup from 'src/lookups/classificacao-mo';
import NivelAcaoLookup from 'src/lookups/nivel-acao';
import MatrizRiscoLookup from 'src/lookups/matriz-risco';
import TagLookup from 'src/lookups/TagLookup';
import GrauRiscoLookup from 'src/lookups/grau-risco';
import DesenharCampoCustomizado from 'src/paginas/item/campo-customizado/componentes/desenhar';
import PeriodoData from 'src/paginas/data/periodo-data.jsx';
import StatusBaselineLookup from 'src/lookups/StatusBaselineLookup';
import { getFilter } from 'src/componentes/NewList';
import { useSelector } from 'react-redux';

import Button from 'src/componentes/button';
import Dialog from 'src/componentes/dialog';

const FiltroKanban = ({
  filterKanban,
  atualizarState,
  abrirUltimoFiltro,
  ...rest
}) => {
  const intl = useIntl();
  const dialogRef = useRef(null);
  const user = useSelector((state) => state.user);
  const { terms: resources } = useAuth();

  const moduloAtual = user.moduloAtual;

  const [form, setForm] = useState({});
  const [configuracoes, setConfiguracoes] = useState({});
  const [exibirClassificacao, setExibirClassificacao] = useState(false);

  useEffect(() => {
    obterConfiguracoes();
  }, []);

  useEffect(() => {
    setForm({ ...filterKanban });
  }, [filterKanban]);

  const obterConfiguracoes = () => {
    http.get(`/AcaoConfiguracao/ObterConfiguracoes`).then((response) => {
      setConfiguracoes(response.data);
    });

    if (moduloAtual.id === 2) {
      http
        .post(`/CentroCusto/ObterConfiguracaoSistema`, {})
        .then((response) => {
          setExibirClassificacao(response.data.exibirClassificacao);
        });
    }
  };

  const handleChangeForm = (field, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSaveFilter = (updatedFilter) => {
    const filtroId = 73;
    http.post(`/FiltroColaborador/${filtroId}/SalvarFiltro`, {
      valor: updatedFilter,
      filtroId: filtroId,
    });
  };

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
    }
  };

  const handleChangeFilter = (filter) => {
    setForm(() => {
      const updatedFilter = { ...filter };

      atualizarState(updatedFilter);
      handleSaveFilter(updatedFilter);

      return updatedFilter;
    });
    handleClose();
  };

  const handleSave = () => {
    handleChangeFilter(form);
    setOpenFilter(false);
  };

  const actions = [
    <Button onClick={handleSave} className="pull-right" leftIcon="magnify">
      {intl.formatMessage({ id: 'label.filtrar' })}
    </Button>,
  ];

  const handleClearFilter = () => {
    const fields = { ...form };

    for (let item in fields) {
      if (fields[item] === true || fields[item] === false) {
        fields[item] = false;
      } else if (item === 'camposCustomizados') {
        // Caso especial para campos customizados
        const array = fields.camposCustomizados || [];
        const newList = array.map((campoCustomizado) => {
          if (campoCustomizado.campo.multiSelecao) {
            campoCustomizado.campo.valores = null; // Reseta seleção múltipla
          } else if (campoCustomizado.campo.tipo.id === 5) {
            campoCustomizado.valor = null;
          } else {
            campoCustomizado.valor = '';
          }
          return campoCustomizado;
        });
        fields.camposCustomizados = newList;
      } else {
        fields[item] = null;
      }
    }

    setForm(fields);
  };

  const footerButton = (
    <div className="pt-1">
      <a className="text-primary" onClick={handleClearFilter}>
        {intl.formatMessage({ id: 'limparTodos' })}
      </a>
    </div>
  );

  return (
    <Dialog
      className="flex-grow-1"
      ref={dialogRef}
      id="filterChecklist"
      onClose={handleClose}
      minWidth={'50%'}
      maxWidth={'50%'}
      minHeight={'10%'}
      maxHeight={'90%'}
      padContent="p-4"
      title={intl.formatMessage({ id: 'label.filtro' })}
      {...rest}
      footer={footerButton}
      actions={actions}
    >
      <>
        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(resources.acao)}
          headerStyle="text-primary"
        >
          <Row>
            <Col md={12}>
              <Input
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage({ id: 'descricao' })
                  ),
                  value: form?.descricao,
                  requestChange: (value) =>
                    handleChangeForm('descricao', value),
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Input
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage({ id: 'codigo' })
                  ),
                  value: form?.codigo,
                  requestChange: (value) => handleChangeForm('codigo', value),
                }}
              />
            </Col>

            <Col md={4}>
              <FarolAcaoLookup
                multi
                clearable
                model={{
                  label: capitalizeFirstLetter(resources.farol),
                  value: form?.farol,
                  requestChange: (value) => handleChangeForm('farol', value),
                }}
              />
            </Col>

            <Col md={4}>
              <StatusAcaoLookup
                multi
                clearable
                model={{
                  label: intl.formatMessage({ id: 'status' }),
                  value: form?.listaStatus,
                  requestChange: (value) =>
                    handleChangeForm('listaStatus', value),
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <ItemOrganizacaoLookup
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'label.colaboradoresArea' },
                      {
                        colaboradores: resources.colaboradores,
                        area: resources.area,
                      }
                    )
                  ),
                  value: form?.areaColaboradores,
                  requestChange: (value) =>
                    handleChangeForm('areaColaboradores', value),
                }}
                data={{ tiposIdSelecionaveis: [1, 2, 3] }}
              />
            </Col>

            <Col md={6}>
              <ColaboradorSimplificadoLookup
                multi={true}
                model={{
                  label: capitalizeFirstLetter(resources.responsaveis),
                  value: form?.responsaveis,
                  requestChange: (value) =>
                    handleChangeForm('responsaveis', value),
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <NivelAcaoLookup
                clearable
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'nivelAcao' },
                      { acao: resources.acao }
                    )
                  ),
                  value: form?.nivelAcao,
                  requestChange: (value) =>
                    handleChangeForm('nivelAcao', value),
                }}
              />
            </Col>

            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.podeVisualizarTag && (
                <Col md={6}>
                  <TagLookup
                    multi
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'tags' },
                          { tags: resources.tags }
                        )
                      ),
                      value: form?.tags,
                      requestChange: (value) => handleChangeForm('tags', value),
                    }}
                    data={{ funcionalidadesIdSelecionaveis: [6] }}
                    podeEditar={false}
                  />
                </Col>
              )}
          </Row>

          <Row>
            <Col md={12}>
              <PeriodoData
                adjustSize={true}
                clearable
                data={{ idsIgnorados: [7, 8, 9, 10] }}
                tipoFiltroData={form?.tipoFiltroDataCriacaoAcao}
                dataInicio={form?.dataInicialCriacaoAcao}
                dataTermino={form?.dataFinalCriacaoAcao}
                tipoFiltroDataField={'tipoFiltroDataCriacaoAcao'}
                dataInicioField={'dataInicialCriacaoAcao'}
                dataTerminoField={'dataFinalCriacaoAcao'}
                onChange={handleChangeForm}
                customLabelTipoPeriodo={capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'tipoFiltroDataCriacaoAcao' },
                    { acao: resources.acao }
                  )
                )}
                customLabelDataInicio={capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'dataInicialCriacaoAcao' },
                    { acao: resources.acao }
                  )
                )}
                customLabelDataTermino={capitalizeFirstLetter(
                  intl.formatMessage(
                    { id: 'dataFinalCriacaoAcao' },
                    { acao: resources.acao }
                  )
                )}
              />
            </Col>
          </Row>

          <Row>
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.tipoAcao && (
                <Col md={6}>
                  <TipoAcaoLookup
                    clearable
                    model={{
                      label: intl.formatMessage(
                        { id: 'tipoAcao' },
                        { acao: resources.acao }
                      ),
                      value: form?.tipoAcao,
                      requestChange: (value) =>
                        handleChangeForm('tipoAcao', value),
                    }}
                  />
                </Col>
              )}

            <Col md={6}>
              <ColaboradorSimplificadoLookup
                model={{
                  label: intl.formatMessage(
                    { id: 'criadorAcao' },
                    { acao: resources.acao }
                  ),
                  value: form?.criadorAcao,
                  requestChange: (value) =>
                    handleChangeForm('criadorAcao', value),
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <PeriodicidadeLookup
                clearable
                model={{
                  label: intl.formatMessage({ id: 'periodicidade' }),
                  value: form?.periodicidade,
                  requestChange: (value) =>
                    handleChangeForm('periodicidade', value),
                }}
              />
            </Col>
            <Col md={6}>
              <TemaEstrategicoLookup
                multi={false}
                clearable
                model={{
                  label: capitalizeFirstLetter(resources.temaestrategico),
                  value: form?.tema,
                  requestChange: (value) => handleChangeForm('tema', value),
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <DatePicker
                autoFormatDate={true}
                model={{
                  value: form?.dataInicioPrevista,
                  label: intl.formatMessage({ id: 'labelDataInicioPrevisto' }),
                  requestChange: (value) =>
                    handleChangeForm('dataInicioPrevista', value),
                }}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                autoFormatDate={true}
                model={{
                  value: form?.dataTerminoPrevista,
                  label: intl.formatMessage({ id: 'labelDataTerminoPrevisto' }),
                  requestChange: (value) =>
                    handleChangeForm('dataTerminoPrevista', value),
                }}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                autoFormatDate={true}
                model={{
                  value: form?.dataInicioRealizada,
                  label: intl.formatMessage({ id: 'dataInicioRealizada' }),
                  requestChange: (value) =>
                    handleChangeForm('dataInicioRealizada', value),
                }}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                autoFormatDate={true}
                model={{
                  value: form?.dataTerminoRealizada,
                  label: intl.formatMessage({ id: 'dataTerminoRealizada' }),
                  requestChange: (value) =>
                    handleChangeForm('dataTerminoRealizada', value),
                }}
              />
            </Col>
          </Row>

          <Row>
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.habilitarBaseline && (
                <>
                  <Col md={6}>
                    <DatePicker
                      autoFormatDate={true}
                      model={{
                        label: intl.formatMessage({ id: 'baselineInicial' }),
                        value: form?.baselineInicial,
                        requestChange: (value) =>
                          handleChangeForm('baselineInicial', value),
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <DatePicker
                      autoFormatDate={true}
                      model={{
                        label: intl.formatMessage({ id: 'baselineTermino' }),
                        value: form?.baselineTermino,
                        requestChange: (value) =>
                          handleChangeForm('baselineTermino', value),
                      }}
                    />
                  </Col>
                  {configuracoes.model.exibirStatusBaseLineTermino && (
                    <Col md={6}>
                      <StatusBaselineLookup
                        clearable
                        model={{
                          label: intl.formatMessage({
                            id: 'labelStatusBaseLine',
                          }),
                          value: form?.statusBaseline,
                          requestChange: (value) =>
                            handleChangeForm('statusBaseline', value),
                        }}
                      />
                    </Col>
                  )}

                  {configuracoes &&
                    configuracoes.model &&
                    configuracoes.model.vincularAcaoNaMatriz && (
                      <Col md={6}>
                        <MatrizRiscoLookup
                          clearable
                          model={{
                            label: capitalizeFirstLetter(
                              intl.formatMessage(
                                { id: 'matrizRisco' },
                                { risco: resources.risco }
                              )
                            ),
                            value: form?.matrizRisco,
                            requestChange: (value) =>
                              handleChangeForm('matrizRisco', value),
                          }}
                        />
                      </Col>
                    )}
                </>
              )}
          </Row>

          <Row>
            <Col md={4}>
              <Checkbox
                model={{
                  label: intl.formatMessage(
                    { id: 'label.apenasAcoesModuloAtual' },
                    { acoes: resources.acoes }
                  ),
                  value: form?.apenasAcoesModuloAtual,
                  requestChange: (value) =>
                    handleChangeForm('apenasAcoesModuloAtual', value),
                }}
              />
            </Col>
            <Col md={4}>
              <Checkbox
                model={{
                  label: intl.formatMessage(
                    { id: 'label.apenasAcoesOrigem' },
                    { acoes: resources.acoes }
                  ),
                  value: form?.apenasAcoesOrigem,
                  requestChange: (value) =>
                    handleChangeForm('apenasAcoesOrigem', value),
                }}
              />
            </Col>
            <Col md={4}>
              <Checkbox
                model={{
                  label: intl.formatMessage(
                    { id: 'acaoCadastradaAprovacao' },
                    {
                      acoes: resources.acoes,
                      acao: capitalizeFirstLetter(resources.acao),
                    }
                  ),
                  value: form?.acaoCadastradaAprovacao,
                  requestChange: (value) =>
                    handleChangeForm('acaoCadastradaAprovacao', value),
                }}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <ItemOrganizacaoLookup
                multi={true}
                model={{
                  label: capitalizeFirstLetter(resources.areas),
                  value: form?.areas,
                  requestChange: (value) => handleChangeForm('areas', value),
                }}
                data={{ tiposIdSelecionaveis: [1, 2, 3] }}
              />

              <Checkbox
                model={{
                  label: capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'label.areaSubordinada' },
                      { area: resources.area }
                    )
                  ),
                  value: form?.areaSubordinada,
                  requestChange: (value) =>
                    handleChangeForm('areaSubordinada', value),
                }}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.habilitarEquipe && (
                <Col md={12}>
                  <ColaboradorSimplificadoLookup
                    multi
                    model={{
                      label: intl.formatMessage({ id: 'equipe' }),
                      value: form?.equipe,
                      requestChange: (value) =>
                        handleChangeForm('equipe', value),
                    }}
                  />

                  {configuracoes?.model?.habilitarEquipe && (
                    <Checkbox
                      model={{
                        label: intl.formatMessage(
                          { id: 'labelConsiderarResponsavelNaEquipe' },
                          { acoes: resources.acoes }
                        ),
                        value: form?.exibirAcoesComoMembroEquipe,
                        requestChange: (value) =>
                          handleChangeForm(
                            'exibirAcoesComoMembroEquipe',
                            value
                          ),
                      }}
                    />
                  )}
                </Col>
              )}
          </Row>

          <Row className="mt-3">
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.utilizarPorque && (
                <Col md={12}>
                  <Input
                    rows={1}
                    type="textarea"
                    model={{
                      label: capitalizeFirstLetter(resources.porque),
                      value: form?.porque,
                      requestChange: (value) =>
                        handleChangeForm('porque', value),
                    }}
                  />
                </Col>
              )}
          </Row>

          {moduloAtual?.id === 2 && (
            <>
              <Row>
                {exibirClassificacao && (
                  <Col md={6}>
                    <ClassificacaoLookup
                      clearable
                      model={{
                        label: intl.formatMessage({
                          id: 'label.classificacao',
                        }),
                        value: form?.classificacao,
                        requestChange: (value) =>
                          handleChangeForm('classificacao', value),
                      }}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={6}>
                  <ItemCentroCustoLookup
                    model={{
                      label: intl.formatMessage(
                        { id: 'centroDeCusto' },
                        { centrocusto: resources.centrocusto }
                      ),
                      value: form?.centroCusto,
                      requestChange: (value) =>
                        handleChangeForm('centroCusto', value),
                    }}
                    data={{ classificacaoId: 1 }}
                  />

                  <Checkbox
                    model={{
                      label: intl.formatMessage(
                        { id: 'label.centrosSubordinados' },
                        { centroscusto: resources.centroscusto }
                      ),
                      value: form?.centrosSubordinados,
                      requestChange: (value) =>
                        handleChangeForm('centrosSubordinados', value),
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <PacoteLookup
                    model={{
                      label: intl.formatMessage({ id: 'pacoteConta' }),
                      value: form?.pacote,
                      requestChange: (value) =>
                        handleChangeForm('pacote', value),
                    }}
                  />

                  <Checkbox
                    model={{
                      label: intl.formatMessage(
                        { id: 'label.pacoteSubordinado' },
                        { acoes: resources.acoes }
                      ),
                      value: form?.pacotesSubordinados,
                      requestChange: (value) =>
                        handleChangeForm('pacotesSubordinados', value),
                    }}
                  />
                </Col>
              </Row>
            </>
          )}

          <Row>
            {configuracoes &&
              configuracoes.model &&
              configuracoes.model.exibirGrauDescricaoAcaoVinculadaRisco && (
                <Col md={6}>
                  <GrauRiscoLookup
                    clearable
                    multi
                    model={{
                      label: capitalizeFirstLetter(
                        intl.formatMessage(
                          { id: 'grauRisco' },
                          { risco: resources.risco }
                        )
                      ),
                      value: form?.grausRisco,
                      requestChange: (value) =>
                        handleChangeForm('grausRisco', value),
                    }}
                  />
                </Col>
              )}
          </Row>
        </PanelCollapse>

        {configuracoes && configuracoes.camposCustomizados && (
          <PanelCollapse
            md={12}
            open={false}
            header={capitalizeFirstLetter(
              intl.formatMessage({ id: 'label.camposCustomizados' })
            )}
            headerStyle="text-primary"
          >
            <Row>
              <Col md={12}>
                <DesenharCampoCustomizado
                  model={{
                    label: intl.formatMessage({ id: 'camposCustomizados' }),
                    value: form?.camposCustomizados,
                    requestChange: (value) =>
                      handleChangeForm('camposCustomizados', value),
                  }}
                />
              </Col>
            </Row>
          </PanelCollapse>
        )}
      </>
    </Dialog>
  );
};

export default FiltroKanban;
