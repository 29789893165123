import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import qs from 'query-string';

import Content from 'src/componentes/pages/content';
import { capitalizeFirstLetter } from 'src/utils/string';
import useAuth from 'src/hooks/useAuth';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import errorHandler from 'src/utils/error-handler';
import LoadingContainer from 'src/componentes/loading-container';
import http from 'src/services/httpService';
import ContentManager from 'src/componentes/content-manager';

import AcaoEdit from './edit';
import Tarefas from './Tarefas/ListView';
import View from './view';
import { useHistory } from 'react-router-dom';

const List = ({
  //location,
  afterRemove,
  ignoreStickyHeader = false,
  itemIdOrigin,
  iconSettings = true,
  ...rest
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { terms: resources } = useAuth();

  const [isLoading, setIsloading] = useState(true);
  const [saved, setSaved] = useState(0);
  const [configurations, setConfigurations] = useState(null);

  useEffect(() => {
    const getConfigurations = async () => {
      try {
        const response = await http.post(
          `/AcaoConfiguracao/ObterConfiguracoes`,
          {}
        );
        setConfigurations(response.data.model);
      } catch (err) {
        errorHandler(err);
      } finally {
        setIsloading(false);
      }
    };

    getConfigurations();
  }, []);

  const qsObj = window?.location ? qs.parse(window.location.search) : null;
  const itemId = itemIdOrigin
    ? itemIdOrigin
    : qsObj && qsObj.itemId;
  const acaoId = qsObj ? qsObj.acaoid ? qsObj.acaoid : qsObj.acaoId : null;
  const tipoItem = qsObj && qsObj.tipoItem;
  const centroCustoId = qsObj && qsObj.centroCustoId;
  const pacoteId = qsObj && qsObj.pacoteId;

  const renderContent = () => {
    if (!configurations.habilitarChecklistTarefas)
      return renderDefaultContent();

    return (
      <Tabs>
        <Tab label={capitalizeFirstLetter(resources.acoes)} key="1">
          {renderDefaultContent()}
        </Tab>
        <Tab label={intl.formatMessage({ id: 'tarefas' })} key="2">
          <Tarefas />
        </Tab>
      </Tabs>
    );
  };

  const refresh = () => {
    setSaved(saved + 1);
  }

  useEffect(() => {
    if(acaoId && acaoId > 0){
      ContentManager.addContent(
        <AcaoEdit
          item={{id: acaoId}}
          handleClose={refresh}
          {...rest}
        />
      );
    }
  }, [acaoId])

  const renderDefaultContent = () => (
    <View
      {...rest}
      location={location}
      afterRemove={afterRemove}
      params={{
        itemId,
        acaoId,
        tipoItem,
        centroCustoId,
        pacoteId,
      }}
      refreshList={saved}
      showModal={false}
      stickyHeader={ignoreStickyHeader ? false : true}
      configurations={configurations}
    />
  );

  return (
    <Content
      titulo={capitalizeFirstLetter(resources.acoes)}
      iconSettings={iconSettings}
      titleSettings={intl.formatMessage(
        {
          id: 'label.configuracoesAcao',
        },
        { acoes: resources.acoes }
      )}
      urlSettings="/configuracoes/acao"
      endPointPermissionSettings="/AcaoConfiguracao/PossuiPermissaoConfiguracao"
    >
      <LoadingContainer isLoading={isLoading}>
        {configurations ? renderContent() : ''}
      </LoadingContainer>
    </Content>
  );
};

export default List;
