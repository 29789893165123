import React from 'react';
import http from 'src/services/httpSelector';
import LoadingContainer from '../loading-container';
import SvgIcon from '../svg-icon';

export default class LegendaItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      farois: [],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({
      isLoading: true,
    });

    http
      .getHttp(this.props.mode)
      .post(`/FaixaFarol/ObterFaroisLegenda`, { tipoFarol: this.props.tipoItemId, itemId: this.props.itemId })
      .then((response) => {
        this.setState({
          farois: response.data.map((id) => id),
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <LoadingContainer isLoading={this.state.isLoading}>
        {this.state.farois &&
          this.state.farois.map((farol, index) => (
            <div key={index} style={{ display: 'inline-block', marginRight: 15 }}>
              <SvgIcon icon={farol.icone} color={farol.cor} colorHover={farol.cor} style={{ marginRight: 5 }}></SvgIcon>
              {farol.legenda}
            </div>
          ))}
      </LoadingContainer>
    );
  }
}
