import { Schema } from 'src/utils/form';
import { COLABORADOR_CAMPOS, MODULOS } from 'src/utils/constants';
import { FormattedMessage } from 'react-intl';

const schema = Schema.object({
  id: Schema.number().label(),
  nome: Schema.string()
    .label(<FormattedMessage id="nome" />)
    .required(),
  email: Schema.string().label(<FormattedMessage id="email" />),
  emailExterno: Schema.string()
    .label(<FormattedMessage id="emailExterno" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.EMAIL);
        if (!campo) return false;

        return campo.obrigatorio;
      },
    }),
  idioma: Schema.string().label(<FormattedMessage id="label.idioma" />),
  fusoHorario: Schema.string().label(<FormattedMessage id="labelFusoHorario" />),
  nomeUsuario: Schema.string().label(<FormattedMessage id="usuario" />),
  senha: Schema.string(),
  senhaAlterada: Schema.string().label(<FormattedMessage id="senha" />),
  telefone: Schema.string()
    .label(<FormattedMessage id="telefone" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.TELEFONE);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  ramal: Schema.string().label(<FormattedMessage id="ramal" />),
  celular: Schema.string()
    .label(<FormattedMessage id="celular" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.CELULAR);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  numeroRegistro: Schema.string().label(<FormattedMessage id="label.numeroRegistro" />),
  idIntegracao: Schema.string()
    .label(<FormattedMessage id="matricula" />)
    .required(),
  unidadeArea: Schema.string()
    .label(<FormattedMessage id="label.unidadeArea" />)
    .required(),
  perfis: Schema.array().label(<FormattedMessage id="perfis" />),
  fotoArquivo: Schema.object().label(<FormattedMessage id="label.foto" />),
  bloqueadoTentativaLogin: Schema.object().label(<FormattedMessage id="label.bloquearUsuario" />),
  utilizarSenhaForte: Schema.object().label(<FormattedMessage id="label.senhaForte" />),
  centroCusto: Schema.string().label(<FormattedMessage id="centroDeCusto" />),
  telaInicial: Schema.string().label(<FormattedMessage id="label.telaInicial" />),
  utilizarAdLdap: Schema.string().label(<FormattedMessage id="label.utilizarAdldap" />),
  permissaoDemaisAreas: Schema.string(),
  adLdapAtivo: Schema.string(),
  separadorDecimal: Schema.string()
    .label(<FormattedMessage id="separadorDecimal" />)
    .required(),
  separadorMilhar: Schema.string()
    .label(<FormattedMessage id="separadorMilhar" />)
    .required(),
  linkedIn: Schema.string()
    .label('Linkedin')
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;
        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.LINKEDIN);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  dataNascimento: Schema.string().label(<FormattedMessage id="dataNascimento" />),
  dataAdmissao: Schema.string()
    .label(<FormattedMessage id="dataAdmissao" />)
    .required({
      onlyIf: (context) => {
        const { moduloId, interno, ehAlteracaoProprioUsuario } = context.parent.value;
        return !ehAlteracaoProprioUsuario && moduloId === MODULOS.AVALIACAO_DESEMPENHO && interno;
      },
    }),
  dataDesligamento: Schema.string().label(<FormattedMessage id="dataDesligamento" />),
  genero: Schema.string()
    .label(<FormattedMessage id="genero" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.GENERO);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  nomeSocial: Schema.string()
    .label(<FormattedMessage id="nomeSocial" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.NOME_SOCIAL);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  raca: Schema.string()
    .label(<FormattedMessage id="raca" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.RACA);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  orientacaoSexual: Schema.string()
    .label(<FormattedMessage id="orientacaoSexual" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.ORIENTACAO_SEXUAL);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  formacoesAcademicas: Schema.string().label(<FormattedMessage id="formacoesAcademicas" />),
  cursos: Schema.string().label(<FormattedMessage id="cursos" />),
  experiencias: Schema.string().label(<FormattedMessage id="experiencias" />),
  disponivelParaMudanca: Schema.string()
    .label(<FormattedMessage id="disponivelParaMudanca" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.DISPONIBILIDADE_MUDANCA);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  localidades: Schema.string().label(<FormattedMessage id="localidades" />),
  interesseOutrasAreas: Schema.string()
    .label(<FormattedMessage id="interesseOutrasAreas" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.INTERESSE_OUTRAS_AREAS);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  areasInteresse: Schema.string().label(<FormattedMessage id="areasInteresse" />),
  autorizarDivulgacaoAniversario: Schema.string()
    .label(<FormattedMessage id="autorizacaoDivulgacaoAniversario" />)
    .required({
      onlyIf: (context) => {
        const { campos, ehAlteracaoProprioUsuario, aceitouTermosUso  } = context.parent.value;
        if (!campos || !ehAlteracaoProprioUsuario || !aceitouTermosUso) return false;

        const campo = campos.find((c) => c.campoId === COLABORADOR_CAMPOS.AUTORIZACAO_DIVULGACAO_ANIVERSARIO);
        if (!campo) return false;
        return campo.obrigatorio;
      },
    }),
  handleErrorRequired: Schema.string().label(),
});

export default schema;
