import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../componentes/dialog';
import RelatorioAcompanhamentoViewList from './view-list';
import { capitalizeFirstLetter } from '../../utils/string';
import { connect } from 'react-redux';
import http from 'src/services/httpService';
import errorHandler from '../../utils/error-handler';

@autobind
class ModalRelatorioAcompanhamento extends React.Component {
  static defaultProps = {
    dialogWidth: '90%',
    dialogHeight: '70%',
  };
  constructor(props) {
    super(props);

    this.state = {
      exibir: false,
    };
  }

  componentDidMount() {
    if (this.props.fromItem)
      http
        .post(`Item/ObterPermissao`, {
          id: this.props.item && this.props.item.id,
        })
        .then((response) => {
          this.setState({
            exibir: true,
          });
        })
        .catch((error) => {
          this.setState({
            exibir: false,
          });
          errorHandler(error);
          this.handleClose();
        });
    else this.setState({ exibir: true });
  }

  handleClose() {
    this.refs.dialog.close();
  }

  render() {
    let { resources, item, componente, filtro } = this.props;

    let params = {
      item: item,
      itens: [item],
      itemId: item.id,
      periodo: item.periodicidade && item.periodicidade.id,
      tipoFiltroData: componente && componente.tipoFiltroData,
      desdobramento: item.desdobramento,
    };

    if (filtro && filtro != null && filtro != undefined) {
      if (filtro.desdobramentoId && filtro.desdobramentoId > 0) params.desdobramento = { id: filtro.desdobramentoId };
      params.tipoFiltroData = filtro.tipoFiltroData;
      params.dataInicio = filtro.dataInicio;
      params.dataTermino = filtro.dataTermino;
    }

    return (
      <Dialog
        title={capitalizeFirstLetter(resources.relatoriodeacompanhamento)}
        padContent
        maxWidth="100%"
        maxHeight="100%"
        width={this.props.dialogWidth}
        height={this.props.dialogHeight}
        onRequestClose={this.handleClose}
        mouseDownClose={true}
        ref="dialog"
        {...this.props}
      >
        {this.state.exibir === true && <RelatorioAcompanhamentoViewList localModal params={params} handleCloseModal={this.handleClose} />}
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}
export default connect(mapStateToProps)(ModalRelatorioAcompanhamento);
