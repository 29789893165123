import {
  Suspense,
  lazy,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, UncontrolledTooltip, Label, Alert } from 'reactstrap';

import http from 'src/services/httpService';
import { getConfiguration } from 'src/services/commonRequests';
import { capitalizeFirstLetter, uperCaseAllLetter } from 'src/utils/string';
import errorHandler from 'src/utils/error-handler';
import Input from 'src/componentes/input';
import MdiIcon from 'src/componentes/mdi-icon';
import ContentManager from 'src/componentes/content-manager';
import ProbabilidadeRiscoLookup from 'src/lookups/probabilidade-risco';
import SeveridadeRiscoLookup from 'src/lookups/severidade-risco';
import TratamentoLookup from 'src/lookups/tratamento-risco';
import { Box } from '@material-ui/core';
import PlanoAcaoModal from 'src/paginas/acao/componentes/PlanoAcaoModal';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import Info from 'src/componentes/info';
import LoadingContainer from 'src/componentes/loading-container';
import LoadingScreen from 'src/componentes/LoadingScreen';
import AvaliacaoTipoSeveridade from './avaliacaoTipoSeveridade';
import AvaliacaoTipoProbabilidade from './avaliacaoTipoProbabilidade';
import { debounce } from 'lodash';
import InputNumber from 'src/componentes/input-number';
import { useIntl } from 'react-intl';
import ToleranciaRiscoBarra from './ToleranciaRiscoBarra';
import CriteriosAvaliacao from './CriteriosAvaliacao';

const GraficoGauge = lazy(() =>
  import('src/paginas/item/valor/componentes/grafico-gauge')
);

const Graficos = ({
  avaliacaoModel,
  modelOrigem,
  resources,
  riscoId,
  bloquearAvaliacao,
  disabled,
  idAvaliacao,
  planoAcaoObrigatorioAvaliacao,
  exibirPlanoAcaoNoRisco,
  planoAcaoPreenchido,
  avaliacaoAnterior,
  dataAvaliacaoAtual,
  setIsLoadingAvaliacaoResidual = () => {},
  isLoadingAvaliacaoResidual = false,
  setIsLoadingCalculoTipoSeveridade = () => {},
  isLoadingCalculoTipoSeveridade = false,
  setIsLoadingCalculoTipoProbabilidade = () => {},
  isLoadingCalculoTipoProbabilidade = false,
  model,
  showErrorRequired = false,
  onChangeValueInerente,
  changeValueInerente,
  ...props
}) => {
  const [avaliacaoGauge, setAvaliacaoGauge] = useState(null);
  const [maiorScoreDaMatriz, setMaiorScoreDaMatriz] = useState([]);
  const [configuracao, setConfiguracao] = useState(null);
  const [configuracaoAvaliacao, setConfiguracaoAvaliacao] = useState({});
  const [grausDaMatrizRisco, setGrausDaMatriz] = useState([]);
  const [risco, setRisco] = useState(-1);
  const [score, setScore] = useState(null);
  const [toleranciaRisco, setToleranciaRisco] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [descricoesCompletas, setDescricoesCompletas] = useState('');
  const ultimoCalculoScore = useRef({ severidadeId: -1, probabilidadeId: -1 });
  const [debouncedValue, setDebouncedValue] = useState('');
  const nomeCampoImpactoFinanceiro = props.avaliacaoInerente
    ? 'impactoFinanceiroInerente'
    : 'impactoFinanceiroResidual';

  useEffect(() => {
    if (!configuracao)
      getConfiguration('ItemConfiguracaoRisco')(setConfiguracao)();
  }, [risco]);

  useEffect(() => {
    http
      .post(`/riscoAvaliacao/ObterConfiguracoesAvaliacaoRisco`, {
        riscoId: riscoId,
      })
      .then((response) => {
        setConfiguracaoAvaliacao(response.data);
      });
  }, []);

  useEffect(() => {
    refresh();
  }, [risco, dataAvaliacaoAtual, configuracao]);

  useEffect(() => {
    calcularScore();
  }, [avaliacaoModel]);

  useEffect(() => {
    obterDescricoesCompletas();
  }, []);

  const obterDescricoesCompletas = async () => {
    const descricaoCompleta = await http.post(
      '/RiscoAvaliacao/ObterDescricoes',
      { riscoId: risco }
    );
    setDescricoesCompletas(descricaoCompleta.data);
  };

  const dadosScorePreenchidos = () => {
    const severidade = props.avaliacaoInerente
      ? getValue('severidadeInerente')
      : getValue('severidade');
    const probabilidade = props.avaliacaoInerente
      ? getValue('probabilidadeInerente')
      : getValue('probabilidade');

    return severidade && severidade.id && probabilidade && probabilidade.id;
  };

  const calcularScore = async () => {
    if (!dadosScorePreenchidos()) return;

    const severidade = props.avaliacaoInerente
      ? getValue('severidadeInerente')
      : getValue('severidade');
    const probabilidade = props.avaliacaoInerente
      ? getValue('probabilidadeInerente')
      : getValue('probabilidade');

    if (
      ultimoCalculoScore?.current?.severidadeId != severidade.id ||
      ultimoCalculoScore?.current?.probabilidadeId != probabilidade.id
    ) {
      ultimoCalculoScore.current = {
        severidadeId: severidade.id,
        probabilidadeId: probabilidade.id,
      };

      const scoreNovo = await http.post('/RiscoAvaliacao/CalcularScore', {
        severidadeId: severidade.id,
        probabilidadeId: probabilidade.id,
        riscoId: riscoId,
        severidadeInerenteId: props.avaliacaoInerente
          ? getValue('severidadeInerente').id
          : null,
        probabilidadeInerenteId: props.avaliacaoInerente
          ? getValue('probabilidadeInerente').id
          : null,
        efetividadeId: !props.avaliacaoInerente
          ? getValue('efetividadeControle')?.id
          : null,
      });
      setScore(scoreNovo.data.score);
      setToleranciaRisco(scoreNovo.data.toleranciaRisco);

      props.afterChangeScore && props.afterChangeScore();
    }
  };

  useEffect(() => {
    if (props.avaliacaoInerente) {
      debouncedSetMyValue(avaliacaoModel?.value?.impactoFinanceiroInerente);
    }
  }, [avaliacaoModel?.value?.impactoFinanceiroInerente]);

  useEffect(() => {
    props.afterChangeScore && props.afterChangeScore();
  }, [debouncedValue]);

  const debouncedSetMyValue = debounce((value) => {
    setDebouncedValue(value);
  }, 2000);

  const refresh = async () => {
    if (configuracao && configuracao.habilitarCalculoDeScores) {
      setIsLoading(true);
      try {
        await http
          .post('/RiscoAvaliacao/ObterGraficoDeScore', {
            riscoId: riscoId,
            model: {
              avaliacaoAnterior: avaliacaoAnterior,
              dataAvaliacaoAtual: dataAvaliacaoAtual,
              idAvaliacao: idAvaliacao,
            },
          })
          .then((response) => {
            setAvaliacaoGauge(response.data.avaliacaoGauge);
            setGrausDaMatriz(response.data.grausDaMatriz);
            setMaiorScoreDaMatriz(response.data.maiorScoreDaMatriz);
            setIsLoading(false);
          });
      } catch (err) {
        errorHandler(err);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  if (risco !== riscoId) setRisco(riscoId);

  const handlePlanoAcaoClick = (itemId, riscoAvaliacaoId = -1, title) => {
    ContentManager.addContent(
      <PlanoAcaoModal
        model={{ item: { id: itemId }, riscoAvaliacaoId }}
        avaliacaoInerente={props.avaliacaoInerente}
        title={title}
        naoSalvarFiltro={true}
        showMenuAcao={false}
        showDelete={true}
      />
    );
  };

  const generateGaugeGraph = () => {
    return {
      chart: {
        lowerLimit: '0',
        upperLimit: maiorScoreDaMatriz,
        showValue: '1',
        theme: 'fusion',
        showToolTip: '0',
      },
      color:
        grausDaMatrizRisco &&
        grausDaMatrizRisco.length > 0 &&
        grausDaMatrizRisco.map(({ de, ate, grauRisco: { cor } }, idx) => ({
          minValue: de,
          maxValue:
            idx === grausDaMatrizRisco.length - 1 ? maiorScoreDaMatriz : ate,
          label: 'show',
          code: cor,
        })),
      value: !dadosScorePreenchidos()
        ? 0
        : score
        ? score
        : avaliacaoGauge && avaliacaoGauge.score,
    };
  };

  const getValue = (item) => {
    if (avaliacaoAnterior) {
      if (avaliacaoModel) return avaliacaoModel[item];

      return null;
    }

    return avaliacaoModel.getModel(item).value;
  };

  const getModel = (item) => {
    if (avaliacaoAnterior) {
      if (avaliacaoModel && modelOrigem)
        return {
          label: modelOrigem.getModel(item).label,
          value: avaliacaoModel[item],
        };

      if (!avaliacaoModel && modelOrigem)
        return {
          label: modelOrigem.getModel(item).label,
          value: null,
        };

      return {};
    }

    return avaliacaoModel.getModel(item);
  };

  const inerente = props.avaliacaoInerente ? 'Inerente' : '';

  const renderTitleAvaliacaoAnterior = () => {
    if (getValue('dataAvaliacao'))
      return (
        uperCaseAllLetter(
          props.intl.formatMessage(
            { id: 'avaliacaoAnterior' },
            { avaliacao: resources.avaliacao }
          )
        ) +
        ' - ' +
        moment(getValue('dataAvaliacao')).format('L')
      );

    return (
      uperCaseAllLetter(
        props.intl.formatMessage(
          { id: 'avaliacaoAnterior' },
          { avaliacao: resources.avaliacao }
        )
      ) +
      ' - ' +
      uperCaseAllLetter(props.intl.formatMessage({ id: 'naoExistente' }))
    );
  };
  const descricaoLookups = avaliacaoAnterior ? 'anterior' : '';

  const renderDescricaoArray = (infos) => {
    if (infos) {
      return (
        <table>
          <tbody>
            {infos.map((obj) => (
              <tr>
                <td style={{ fontWeight: 'bold' }}>{obj.nome}:</td>
                <td style={{ textAlign: 'left' }}>{obj.descricao}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

  const disabledSeveridade = () => {
    return (
      configuracaoAvaliacao &&
      configuracaoAvaliacao.avaliarSeveridadePorTipo &&
      configuracaoAvaliacao.calcularSeveridadeGeralPorTipo?.id > 1
    );
  };

  const disabledProbabilidade = () => {
    return (
      configuracaoAvaliacao &&
      configuracaoAvaliacao.avaliarProbabilidadePorTipo &&
      configuracaoAvaliacao.calcularProbabilidadeGeralPorTipo?.id > 1
    );
  };

  const habilitadoToleranciaRisco = () => {
    return (
      configuracao?.habilitarCalculoDeScores &&
      configuracao?.habilitarAcompanhamentoToleranciaRisco
    );
  };

  const renderSemScore = () => {
    return (
      <div className={'card mt-3 mb-3'}>
        <Row className={'mr-3'}>
          <Col md={12} className={'ml-3 mt-3'}>
            {avaliacaoAnterior ? (
              <Label
                className="font-weight-bold"
                style={{
                  textAlign: 'left',
                  display: 'block',
                  whiteSpace: 'nowrap',
                }}
              >
                {renderTitleAvaliacaoAnterior()}
              </Label>
            ) : (
              <Label
                className="font-weight-bold"
                style={{
                  textAlign: 'left',
                  display: 'block',
                  whiteSpace: 'nowrap',
                }}
              >{`${uperCaseAllLetter(
                props.intl.formatMessage(
                  { id: 'novaAvaliacao' },
                  { avaliacao: resources.avaliacao }
                )
              )} - ${moment(getValue('dataAvaliacao')).format('L')}`}</Label>
            )}
          </Col>
        </Row>
        <div className="pt-3 p-2">
          <AvaliacaoTipoSeveridade
            avaliacaoAnterior={avaliacaoAnterior}
            onChangeValueInerente={onChangeValueInerente}
            changeValueInerente={changeValueInerente}
            avaliacaoModel={avaliacaoModel}
            configuracao={configuracao}
            configuracaoAvaliacao={configuracaoAvaliacao}
            riscoId={riscoId}
            setIsLoadingAvaliacaoResidual={setIsLoadingAvaliacaoResidual}
            isLoadingAvaliacaoResidual={isLoadingAvaliacaoResidual}
            setIsLoadingCalculoTipoSeveridade={
              setIsLoadingCalculoTipoSeveridade
            }
            isLoadingCalculoTipoSeveridade={isLoadingCalculoTipoSeveridade}
            inerente={props.avaliacaoInerente}
            disabled={disabled || props.disabledDadosCalculoScore}
            disabledDadosCalculoScore={
              props.disabledDadosCalculoScore && props.avaliarEfetividade
            }
            resources={resources}
            requestChangeSeveridade={
              !avaliacaoAnterior &&
              avaliacaoModel.getModel('severidade' + inerente).requestChange
            }
          />
        </div>
        <div className="pt-0 p-2">
          <AvaliacaoTipoProbabilidade
            configuracao={configuracao}
            configuracaoAvaliacao={configuracaoAvaliacao}
            avaliacaoAnterior={avaliacaoAnterior}
            onChangeValueInerente={onChangeValueInerente}
            changeValueInerente={changeValueInerente}
            avaliacaoModel={avaliacaoModel}
            riscoId={riscoId}
            setIsLoadingAvaliacaoResidual={setIsLoadingAvaliacaoResidual}
            isLoadingAvaliacaoResidual={isLoadingAvaliacaoResidual}
            setIsLoadingCalculoTipoProbabilidade={
              setIsLoadingCalculoTipoProbabilidade
            }
            isLoadingCalculoTipoProbabilidade={
              isLoadingCalculoTipoProbabilidade
            }
            inerente={props.avaliacaoInerente}
            disabled={disabled || props.disabledDadosCalculoScore}
            disabledDadosCalculoScore={
              props.disabledDadosCalculoScore && props.avaliarEfetividade
            }
            resources={resources}
            requestChangeProbabilidade={
              !avaliacaoAnterior &&
              avaliacaoModel.getModel('probabilidade' + inerente).requestChange
            }
          />
        </div>
        <Row className={'mr-3 ml-3'}>
          <Col md={6}>
            <SeveridadeRiscoLookup
              required={!avaliacaoAnterior}
              disabled={
                disabled ||
                props.disabledDadosCalculoScore ||
                disabledSeveridade()
              }
              model={{
                label: (
                  <span>
                    {capitalizeFirstLetter(resources.severidade)}
                    <Info
                      id={'severidadeDescricao' + inerente + descricaoLookups}
                      tooltipMaxWidth={500}
                      placement="right"
                      renderContent={() =>
                        renderDescricaoArray(
                          descricoesCompletas.severidadesDescricaoCompleta
                        )
                      }
                    />
                  </span>
                ),
                value: getValue('severidade' + inerente),
                requestChange:
                  !avaliacaoAnterior &&
                  avaliacaoModel.getModel('severidade' + inerente)
                    .requestChange,
                errors:
                  !avaliacaoAnterior &&
                  avaliacaoModel.getModel('severidade' + inerente).errors,
              }}
              data={{ idsSelecionados: getValue('listaSeveridadeIdMatriz') }}
            />
          </Col>
          <Col md={6}>
            <ProbabilidadeRiscoLookup
              required={!avaliacaoAnterior}
              disabled={
                disabled ||
                props.disabledDadosCalculoScore ||
                disabledProbabilidade()
              }
              model={{
                label: (
                  <span>
                    {capitalizeFirstLetter(resources.probabilidade)}
                    <Info
                      id={
                        'probabilidadeDescricao' + inerente + descricaoLookups
                      }
                      tooltipMaxWidth={500}
                      placement="right"
                      renderContent={() =>
                        renderDescricaoArray(
                          descricoesCompletas.probabilidadesDescricaoCompleta
                        )
                      }
                    />
                  </span>
                ),
                value: getValue('probabilidade' + inerente),
                requestChange:
                  !avaliacaoAnterior &&
                  avaliacaoModel.getModel('probabilidade' + inerente)
                    .requestChange,
                errors:
                  !avaliacaoAnterior &&
                  avaliacaoModel.getModel('probabilidade' + inerente).errors,
              }}
              data={{ idsSelecionados: getValue('listaProbabilidadeIdMatriz') }}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-end ml-3">
          {configuracao?.acompanharImpactoFinanceiroEventoRisco &&
            getValue('exibirImpactoFinanceiro') && (
              <Col md={6}>
                <InputNumber
                  customClassNameLabel={'control-label pb-0'}
                  required={
                    !avaliacaoAnterior &&
                    configuracao.impactoFinanceiroEventoRiscoObrigatorio
                  }
                  disabled={
                    disabled ||
                    (configuracao.avaliarEfetividadeControle &&
                      (configuracao.calcularEfetividadeConformeEfetividadeIndividual ||
                        configuracao.avaliarEfetividadeControleSeparadamente ||
                        configuracao.gerarAvaliacaoRiscoResidualPelaEfetividade) &&
                      !props.avaliacaoInerente &&
                      configuracao?.calcularAutomaticamenteImpactoFinanceiroResidual)
                  }
                  style={{ height: '38px' }}
                  model={{
                    label: (
                      <span>
                        {getValue('moedaDefinida') &&
                          capitalizeFirstLetter(
                            `${props.intl.formatMessage({
                              id: 'labelImpactoFinanceiro',
                            })}
                      (${getValue('moedaDefinida')?.sigla})`
                          )}
                        <Info
                          id={
                            'impactoFinanceiroDescricao' +
                            inerente +
                            descricaoLookups
                          }
                          tooltipMaxWidth={500}
                          placement="right"
                          renderContent={() =>
                            renderDescricaoArray(
                              descricoesCompletas.tratamentosDescricaoCompleta
                            )
                          }
                        />
                      </span>
                    ),
                    value: getValue(nomeCampoImpactoFinanceiro),
                    errors:
                      !avaliacaoAnterior &&
                      avaliacaoModel.getModel(nomeCampoImpactoFinanceiro)
                        .errors,
                    requestChange:
                      !avaliacaoAnterior &&
                      model.getModel(nomeCampoImpactoFinanceiro).requestChange,
                  }}
                />
              </Col>
            )}
          {!props.avaliacaoInerente && getValue('utilizarTratamento') && (
            <Col md={6}>
              <TratamentoLookup
                required={
                  !avaliacaoAnterior && getValue('tratamentoObrigatorio')
                }
                disabled={disabled}
                model={{
                  label: (
                    <span>
                      {capitalizeFirstLetter(
                        props.intl.formatMessage({ id: 'tratamento' })
                      )}
                      <Info
                        id={'tratamentoDescricao' + inerente + descricaoLookups}
                        tooltipMaxWidth={500}
                        placement="right"
                        renderContent={() =>
                          renderDescricaoArray(
                            descricoesCompletas.tratamentosDescricaoCompleta
                          )
                        }
                      />
                    </span>
                  ),
                  value: getValue('tratamento' + inerente),
                  errors:
                    !avaliacaoAnterior &&
                    avaliacaoModel.getModel('tratamento' + inerente).errors,
                  requestChange:
                    !avaliacaoAnterior &&
                    model.getModel('tratamento' + inerente).requestChange,
                }}
              />
            </Col>
          )}
          <Col md={2}>
            <ArquivoUploadAnexos
              required={getValue('anexoObrigatorio')}
              tipoAnexo={props.tiposAnexos.riscoAvaliacao}
              formGroupStyle={{ marginBottom: 0 }}
              model={getModel(
                props.avaliacaoInerente ? 'arquivosInerentes' : 'arquivos'
              )}
            />
          </Col>
          {props.avaliacaoInerente &&
            configuracao &&
            configuracao.exibirPlanoAcaoNoRisco && <Col md={4} />}
          {!props.avaliacaoInerente &&
            configuracao &&
            configuracao.exibirPlanoAcaoNoRisco && (
              <Col md={4}>
                <Label
                  style={{
                    textAlign: 'center',
                    display: 'block',
                    whiteSpace: 'nowrap',
                  }}
                >{`${capitalizeFirstLetter(resources.planodeacao)}${
                  !avaliacaoAnterior && planoAcaoObrigatorioAvaliacao
                    ? ' *'
                    : ''
                }`}</Label>
                <Box style={{ textAlign: 'center' }}>
                  <MdiIcon
                    id={`idPlanoAcao${getValue('id')}${1}`}
                    size={20}
                    style={{
                      cursor: 'pointer',
                      color:
                        planoAcaoObrigatorioAvaliacao &&
                        showErrorRequired &&
                        !planoAcaoPreenchido &&
                        !avaliacaoAnterior
                          ? 'red'
                          : '',
                    }}
                    className="ml-2"
                    icon="chart-gantt"
                    onClick={() =>
                      handlePlanoAcaoClick(
                        riscoId,
                        getValue('id'),
                        resources.avaliacao
                      )
                    }
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`idPlanoAcao${getValue('id')}${1}`}
                  >
                    {capitalizeFirstLetter(resources.planodeacao)}
                  </UncontrolledTooltip>
                </Box>
              </Col>
            )}
        </Row>
        <Row className={'mr-3 ml-3'}>
          <Col md={12}>
            {!getModel('avaliacaoComentarioSeparadoEntreImpactoProbabilidade')
              .value ? (
              <Row>
                <Col md={12} className="p-0">
                  <Input
                    rows={4}
                    style={{
                      minHeight: habilitadoToleranciaRisco()
                        ? '230px'
                        : '140px',
                    }}
                    type="textarea"
                    maxLength={5000}
                    disabled={disabled}
                    model={{
                      label: capitalizeFirstLetter(
                        props.intl.formatMessage({ id: 'comentario' })
                      ),
                      value: getModel('descricao' + inerente).value,
                      requestChange: getModel('descricao' + inerente)
                        .requestChange,
                      errors: getModel('descricao' + inerente).errors,
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={6} className="p-0 pr-2">
                  <Input
                    rows={4}
                    type="textarea"
                    maxLength={5000}
                    style={{
                      minHeight: habilitadoToleranciaRisco()
                        ? '230px'
                        : '140px',
                    }}
                    disabled={disabled}
                    required={
                      getModel('avaliacaoComentarioObrigatorioSeveridade').value
                    }
                    model={{
                      label:
                        capitalizeFirstLetter(
                          props.intl.formatMessage({ id: 'comentario' })
                        ) +
                        ' - ' +
                        resources.severidade,
                      value: getModel('comentarioSeveridade' + inerente).value,
                      errors:
                        !avaliacaoAnterior &&
                        avaliacaoModel.getModel(
                          'comentarioSeveridade' + inerente
                        ).errors,
                      requestChange: getModel('comentarioSeveridade' + inerente)
                        .requestChange,
                    }}
                  />
                </Col>
                <Col md={6} className="p-0">
                  <Input
                    rows={4}
                    type="textarea"
                    maxLength={5000}
                    style={{
                      minHeight: habilitadoToleranciaRisco()
                        ? '230px'
                        : '140px',
                    }}
                    disabled={disabled}
                    required={
                      getModel('avaliacaoComentarioObrigatorioProbabilidade')
                        .value
                    }
                    model={{
                      label:
                        capitalizeFirstLetter(
                          props.intl.formatMessage({ id: 'comentario' })
                        ) +
                        ' - ' +
                        resources.probabilidade,
                      value: getModel('comentarioProbabilidade' + inerente)
                        .value,
                      errors:
                        !avaliacaoAnterior &&
                        avaliacaoModel.getModel(
                          'comentarioProbabilidade' + inerente
                        ).errors,
                      requestChange: getModel(
                        'comentarioProbabilidade' + inerente
                      ).requestChange,
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Suspense fallback={<LoadingScreen />}>
      {configuracao && configuracao.habilitarCalculoDeScores ? (
        <div className={'card mt-3 mb-3'}>
          <Row className={'mr-3'}>
            <Col md={12} className={'ml-3 mt-3'}>
              {avaliacaoAnterior ? (
                <Label
                  className="font-weight-bold"
                  style={{
                    textAlign: 'left',
                    display: 'block',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {renderTitleAvaliacaoAnterior()}
                </Label>
              ) : (
                <Label
                  className="font-weight-bold"
                  style={{
                    textAlign: 'left',
                    display: 'block',
                    whiteSpace: 'nowrap',
                  }}
                >{`${uperCaseAllLetter(
                  props.intl.formatMessage(
                    { id: 'novaAvaliacao' },
                    { avaliacao: resources.avaliacao }
                  )
                )} - ${moment(getValue('dataAvaliacao')).format('L')}`}</Label>
              )}
            </Col>
          </Row>
          <div className="pt-3 p-2">
            <AvaliacaoTipoSeveridade
              configuracao={configuracao}
              configuracaoAvaliacao={configuracaoAvaliacao}
              avaliacaoAnterior={avaliacaoAnterior}
              onChangeValueInerente={onChangeValueInerente}
              changeValueInerente={changeValueInerente}
              avaliacaoModel={avaliacaoModel}
              inerente={props.avaliacaoInerente}
              disabled={disabled || props.disabledDadosCalculoScore}
              disabledDadosCalculoScore={props.disabledDadosCalculoScore}
              isLoadingAvaliacaoResidual={isLoadingAvaliacaoResidual}
              isLoadingCalculoTipoSeveridade={isLoadingCalculoTipoSeveridade}
              setIsLoadingCalculoTipoSeveridade={
                setIsLoadingCalculoTipoSeveridade
              }
              setIsLoadingAvaliacaoResidual={setIsLoadingAvaliacaoResidual}
              resources={resources}
              riscoId={riscoId}
              requestChangeSeveridade={
                !avaliacaoAnterior &&
                avaliacaoModel.getModel('severidade' + inerente).requestChange
              }
            />
          </div>
          {!getValue('calcularProbabilidadePorFormula') && <div className="pt-0 p-2">
            <AvaliacaoTipoProbabilidade
              configuracao={configuracao}
              configuracaoAvaliacao={configuracaoAvaliacao}
              avaliacaoAnterior={avaliacaoAnterior}
              onChangeValueInerente={onChangeValueInerente}
              changeValueInerente={changeValueInerente}
              avaliacaoModel={avaliacaoModel}
              inerente={props.avaliacaoInerente}
              riscoId={riscoId}
              disabled={disabled || props.disabledDadosCalculoScore}
              disabledDadosCalculoScore={props.disabledDadosCalculoScore}
              isLoadingAvaliacaoResidual={isLoadingAvaliacaoResidual}
              isLoadingCalculoTipoProbabilidade={
                isLoadingCalculoTipoProbabilidade
              }
              setIsLoadingCalculoTipoProbabilidade={
                setIsLoadingCalculoTipoProbabilidade
              }
              setIsLoadingAvaliacaoResidual={setIsLoadingAvaliacaoResidual}
              resources={resources}
              requestChangeProbabilidade={
                !avaliacaoAnterior &&
                avaliacaoModel.getModel('probabilidade' + inerente)
                  .requestChange
              }
            />
          </div>}
          {props.avaliacaoInerente && getValue('calcularProbabilidadePorFormula') && (
            <div className="pt-0 p-2">
              <CriteriosAvaliacao
                riscoId={riscoId}
                avaliacaoModel={avaliacaoModel}
                disabled={disabled}
                avaliacaoAnterior={avaliacaoAnterior}
                dataAvaliacao={getValue('dataAvaliacao')}
                setIsLoadingFormula={setIsLoadingCalculoTipoProbabilidade}
                isLoadingFormula={isLoadingCalculoTipoProbabilidade}
              />
            </div>
          )}
          <Row className={'mr-3'}>
            <Col md={5} className={'justify-content-center pr-0'}>
              <Row className={'row-fluid'}>
                <LoadingContainer isLoading={isLoading}>
                  <GraficoGauge
                    backgroundTransparent={true}
                    grafico={generateGaugeGraph()}
                    height={250}
                    disabled={disabled}
                  />
                </LoadingContainer>
              </Row>
              {habilitadoToleranciaRisco() && (
                <Row>
                  <Col md={12}>
                    <ToleranciaRiscoBarra
                      model={
                        toleranciaRisco
                          ? toleranciaRisco
                          : getValue('toleranciaRisco' + inerente)
                      }
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col md={7} className="p-0">
              <Row>
                {!avaliacaoAnterior &&
                !props.avaliacaoInerente &&
                !props.efetividadePreenchida ? (
                  <Col md={12}>
                    <Alert
                      color="warning"
                      isOpen={true}
                      className="p-1"
                      style={{ fontSize: 12 }}
                    >
                      {props.intl.formatMessage(
                        { id: 'validacaoPreencherEfetividadeCalcularResidual' },
                        { pontosdecontrole: resources.pontosdecontrole }
                      )}
                    </Alert>
                  </Col>
                ) : (
                  <>
                    <Col md={6} className="p-0">
                      <SeveridadeRiscoLookup
                        required={!avaliacaoAnterior}
                        disabled={
                          disabled ||
                          props.disabledDadosCalculoScore ||
                          disabledSeveridade()
                        }
                        model={{
                          label: (
                            <span>
                              {capitalizeFirstLetter(resources.severidade)}
                              <Info
                                id={
                                  'severidadeDescricao' +
                                  inerente +
                                  descricaoLookups
                                }
                                tooltipMaxWidth={500}
                                placement="right"
                                renderContent={() =>
                                  renderDescricaoArray(
                                    descricoesCompletas.severidadesDescricaoCompleta
                                  )
                                }
                              />
                            </span>
                          ),
                          value: getValue('severidade' + inerente),
                          requestChange:
                            !avaliacaoAnterior &&
                            avaliacaoModel.getModel('severidade' + inerente)
                              .requestChange,
                          errors:
                            !avaliacaoAnterior &&
                            avaliacaoModel.getModel('severidade' + inerente)
                              .errors,
                        }}
                        data={{
                          idsSelecionados: getValue('listaSeveridadeIdMatriz'),
                        }}
                      />
                    </Col>
                    <Col md={6} className="p-0 pl-1">
                      <ProbabilidadeRiscoLookup
                        required={!avaliacaoAnterior}
                        disabled={
                          disabled ||
                          props.disabledDadosCalculoScore ||
                          disabledProbabilidade()
                        }
                        model={{
                          label: (
                            <span>
                              {capitalizeFirstLetter(resources.probabilidade)}
                              <Info
                                id={
                                  'probabilidadeDescricao' +
                                  inerente +
                                  descricaoLookups
                                }
                                tooltipMaxWidth={500}
                                placement="right"
                                renderContent={() =>
                                  renderDescricaoArray(
                                    descricoesCompletas.probabilidadesDescricaoCompleta
                                  )
                                }
                              />
                            </span>
                          ),
                          value: getValue('probabilidade' + inerente),
                          requestChange:
                            !avaliacaoAnterior &&
                            avaliacaoModel.getModel('probabilidade' + inerente)
                              .requestChange,
                          errors:
                            !avaliacaoAnterior &&
                            avaliacaoModel.getModel('probabilidade' + inerente)
                              .errors,
                        }}
                        data={{
                          idsSelecionados: getValue(
                            'listaProbabilidadeIdMatriz'
                          ),
                        }}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row className="d-flex justify-content-end">
                {configuracao?.acompanharImpactoFinanceiroEventoRisco &&
                  getValue('exibirImpactoFinanceiro') && (
                    <Col md={6} className="p-0">
                      <InputNumber
                        customClassNameLabel={'control-label pb-1'}
                        style={{ height: '38px' }}
                        required={
                          !avaliacaoAnterior &&
                          configuracao.impactoFinanceiroEventoRiscoObrigatorio
                        }
                        disabled={
                          disabled ||
                          (configuracao.avaliarEfetividadeControle &&
                            (configuracao.calcularEfetividadeConformeEfetividadeIndividual ||
                              configuracao.avaliarEfetividadeControleSeparadamente ||
                              configuracao.gerarAvaliacaoRiscoResidualPelaEfetividade) &&
                            !props.avaliacaoInerente &&
                            configuracao?.calcularAutomaticamenteImpactoFinanceiroResidual)
                        }
                        precision={getValue('moedaDefinida')?.casasDecimais}
                        model={{
                          label: (
                            <span>
                              {getValue('moedaDefinida') &&
                                capitalizeFirstLetter(
                                  `${props.intl.formatMessage({
                                    id: 'labelImpactoFinanceiro',
                                  })}
                      (${getValue('moedaDefinida')?.sigla})`
                                )}
                            </span>
                          ),
                          value: getValue(nomeCampoImpactoFinanceiro)
                            ? getValue(nomeCampoImpactoFinanceiro)
                            : '',
                          errors:
                            !avaliacaoAnterior &&
                            avaliacaoModel.getModel(nomeCampoImpactoFinanceiro)
                              .errors,
                          requestChange:
                            !avaliacaoAnterior &&
                            model.getModel(nomeCampoImpactoFinanceiro)
                              .requestChange,
                        }}
                      />
                    </Col>
                  )}
                {!props.avaliacaoInerente && getValue('utilizarTratamento') && (
                  <Col md={6} className="p-0 pl-1">
                    <TratamentoLookup
                      required={
                        !avaliacaoAnterior && getValue('tratamentoObrigatorio')
                      }
                      disabled={disabled}
                      model={{
                        label: (
                          <span>
                            {capitalizeFirstLetter(
                              props.intl.formatMessage({ id: 'tratamento' })
                            )}
                            <Info
                              id={
                                'tratamentoDescricao' +
                                inerente +
                                descricaoLookups
                              }
                              tooltipMaxWidth={500}
                              placement="right"
                              renderContent={() =>
                                renderDescricaoArray(
                                  descricoesCompletas.tratamentosDescricaoCompleta
                                )
                              }
                            />
                          </span>
                        ),
                        value: getValue('tratamento' + inerente),
                        errors:
                          !avaliacaoAnterior &&
                          avaliacaoModel.getModel('tratamento' + inerente)
                            .errors,
                        requestChange:
                          !avaliacaoAnterior &&
                          model.getModel('tratamento' + inerente).requestChange,
                      }}
                    />
                  </Col>
                )}
                <Col md={2}>
                  <ArquivoUploadAnexos
                    required={getValue('anexoObrigatorio')}
                    tipoAnexo={props.tiposAnexos.riscoAvaliacao}
                    formGroupStyle={{ marginBottom: 0 }}
                    model={getModel(
                      props.avaliacaoInerente ? 'arquivosInerentes' : 'arquivos'
                    )}
                  />
                </Col>
                {props.avaliacaoInerente &&
                  configuracao.exibirPlanoAcaoNoRisco && <Col md={4} />}
                {!props.avaliacaoInerente &&
                  configuracao.exibirPlanoAcaoNoRisco && (
                    <Col md={4}>
                      <Label
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          whiteSpace: 'nowrap',
                        }}
                      >{`${capitalizeFirstLetter(resources.planodeacao)}${
                        !avaliacaoAnterior && planoAcaoObrigatorioAvaliacao
                          ? ' *'
                          : ''
                      }`}</Label>
                      <Box style={{ textAlign: 'center' }}>
                        <MdiIcon
                          id={`idPlanoAcao${getValue('id')}${1}`}
                          size={20}
                          style={{
                            cursor: 'pointer',
                            color:
                              planoAcaoObrigatorioAvaliacao &&
                              showErrorRequired &&
                              !planoAcaoPreenchido &&
                              !avaliacaoAnterior
                                ? 'red'
                                : '',
                          }}
                          className="ml-2"
                          icon="chart-gantt"
                          onClick={() =>
                            handlePlanoAcaoClick(
                              riscoId,
                              getValue('id'),
                              resources.avaliacao
                            )
                          }
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target={`idPlanoAcao${getValue('id')}${1}`}
                        >
                          {capitalizeFirstLetter(resources.planodeacao)}
                        </UncontrolledTooltip>
                      </Box>
                    </Col>
                  )}
              </Row>
              {!getModel('avaliacaoComentarioSeparadoEntreImpactoProbabilidade')
                .value ? (
                <Row>
                  <Col md={12} className="p-0">
                    <Input
                      rows={4}
                      style={{
                        minHeight: habilitadoToleranciaRisco()
                          ? '230px'
                          : '140px',
                      }}
                      type="textarea"
                      maxLength={5000}
                      disabled={disabled}
                      model={{
                        label: capitalizeFirstLetter(
                          props.intl.formatMessage({ id: 'comentario' })
                        ),
                        value: getModel('descricao' + inerente).value,
                        requestChange: getModel('descricao' + inerente)
                          .requestChange,
                        errors: getModel('descricao' + inerente).errors,
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={6} className="p-0 pr-2">
                    <Input
                      rows={4}
                      type="textarea"
                      maxLength={5000}
                      style={{
                        minHeight: habilitadoToleranciaRisco()
                          ? '230px'
                          : '140px',
                      }}
                      disabled={disabled}
                      required={
                        getModel('avaliacaoComentarioObrigatorioSeveridade')
                          .value
                      }
                      model={{
                        label:
                          capitalizeFirstLetter(
                            props.intl.formatMessage({ id: 'comentario' })
                          ) +
                          ' - ' +
                          resources.severidade,
                        value: getModel('comentarioSeveridade' + inerente)
                          .value,
                        errors:
                          !avaliacaoAnterior &&
                          avaliacaoModel.getModel(
                            'comentarioSeveridade' + inerente
                          ).errors,
                        requestChange: getModel(
                          'comentarioSeveridade' + inerente
                        ).requestChange,
                      }}
                    />
                  </Col>
                  <Col md={6} className="p-0">
                    <Input
                      rows={4}
                      type="textarea"
                      maxLength={5000}
                      style={{
                        minHeight: habilitadoToleranciaRisco()
                          ? '230px'
                          : '140px',
                      }}
                      disabled={disabled}
                      required={
                        getModel('avaliacaoComentarioObrigatorioProbabilidade')
                          .value
                      }
                      model={{
                        label:
                          capitalizeFirstLetter(
                            props.intl.formatMessage({ id: 'comentario' })
                          ) +
                          ' - ' +
                          resources.probabilidade,
                        value: getModel('comentarioProbabilidade' + inerente)
                          .value,
                        errors:
                          !avaliacaoAnterior &&
                          avaliacaoModel.getModel(
                            'comentarioProbabilidade' + inerente
                          ).errors,
                        requestChange: getModel(
                          'comentarioProbabilidade' + inerente
                        ).requestChange,
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      ) : (
        renderSemScore()
      )}
    </Suspense>
  );
};
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default connect(mapStateToProps)(Graficos);
