import Lookup from 'src/componentes/select2';

const StatusAuditoriaRisco = ({...rest}) => {
  return(
    <Lookup
      labelKey="descricao"
      valueKey="id"
      url="/StatusAuditoriaRiscoLookup"
      {...rest}
    />
  )
}

export default StatusAuditoriaRisco;