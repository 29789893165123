import React from 'react';
import Lookup from '../componentes/select-async';
import { autobind } from 'core-decorators';
import ColaboradorEdit from '../paginas/colaborador/edit';
import ColaboradorList from '../paginas/colaborador/view';
import Avatar from '../componentes/item-multi-lookup';
import { UncontrolledTooltip } from 'reactstrap';

@autobind
class ColaboradorLookup extends React.Component {
  renderColaborador(item) {
    const isValid =
      item.value &&
      (typeof item.value === 'object' ? item.value.id : item.value.length > 0);

    return (
      <>
        {isValid && (
          <Avatar
            foto={item && item.value && item.value.fotoArquivo}
            size={this.props.size}
            onlyAvatar={this.props.onlyAvatar}
            disabled={this.props.disabled}
            avatar
            label={item.value.nome}
            onRemove={item.onRemove && item.onRemove.bind(this, item.value)}
            value={item.value}
            multi={this.props.multi}
          ></Avatar>
        )}
      </>
    );
  }

  render() {
    let somenteAtivos = this.props.data
      ? this.props.data.somenteAtivos
      : !this.props.exibirInativos;
    const {
      selecionarAvaliador,
      processoAvaliativoId,
      avaliadoId,
      showHint,
      hint,
      optionRenderer,
    } = this.props;

    return (
      <div id={'lkpcolaborador-hint'}>
        <Lookup
          valueComponent={this.renderColaborador}
          valueKey="id"
          labelKey="nome"
          showSearch
          clearable={true}
          url="/ColaboradorLookup"
          editComponent={ColaboradorEdit}
          searchComponent={ColaboradorList}
          {...this.props}
          optionRenderer={optionRenderer && optionRenderer}
          data={{
            ...this.props.data,
            viewListSearch: true,
            somenteAtivos: somenteAtivos,
            carregarPodeSerAvaliador: selecionarAvaliador,
            processoAvaliativoId: processoAvaliativoId,
            avaliadoId: avaliadoId,
          }}
        />
        {showHint && (
          <UncontrolledTooltip placement="auto" target={'lkpcolaborador-hint'}>
            {hint}
          </UncontrolledTooltip>
        )}
      </div>
    );
  }
}

export default ColaboradorLookup;
