import React from 'react';
import Edit from 'src/componentes/edit/edit-dialog';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Schema } from 'src/utils/form';
import { FormattedMessage } from 'react-intl';
import ItemLookup from 'src/lookups/item';
import Radio from 'src/componentes/radio';
import InputNumber from 'src/componentes/input-number';
import FuncaoLookup from 'src/lookups/funcao';
import TipoCalculoItemCestaLookup from 'src/lookups/tipo-calculo-itemcesta';
import { capitalizeFirstLetter } from 'src/utils/string';

@autobind
class FormEdit extends React.Component {
  handleChangeTipoCalculoItemCesta(value) {
    let { model } = this.props;

    model.getModel('tipoCalculoItemCesta').requestChange(value);
    model.getModel('peso').requestChange(0);
  }

  render() {
    let { model, intl, resources, modulosComprados } = this.props;
    let item = model.getModel('item');

    const possuiModuloSeed = modulosComprados.find(
      (m) => m.id === 1 && m.ativo
    );
    const possuiModuloScore = modulosComprados.find(
      (m) => m.id === 3 && m.ativo
    );
    let tiposIdsSelecionados = [1, 3];

    if (possuiModuloSeed && possuiModuloScore) tiposIdsSelecionados = [1, 3, 4];

    return (
      <div>
        <Row>
          <Col md={3}>
            <Radio
              required
              model={model.getModel('tipoItemCesta')}
              options={model.getModel('opcoesTipoItemCesta').value}
              labelField="descricao"
              name="opcoesTipoItemCesta"
              idField="id"
              style={{ marginTop: 10 }}
            />
          </Col>
          {model.getModel('utilizarAdicionalReducional').value && (
            <Col md={3}>
              <TipoCalculoItemCestaLookup
                model={{
                  value: model.getModel('tipoCalculoItemCesta').value,
                  requestChange:
                    this.handleChangeTipoCalculoItemCesta.bind(this),
                  errors: model.getModel('tipoCalculoItemCesta').errors,
                }}
                clearable={true}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={4}>
            {model.getModel('tipoItemCesta').value &&
            model.getModel('tipoItemCesta').value.id == 1 ? (
              <ItemLookup
                model={{
                  label: intl.formatMessage({ id: 'item' }) + ' *',
                  value: item.value,
                  requestChange: model.getModel('item').requestChange,
                  errors: model.getModel('item').errors,
                }}
                data={{ tiposIdsSelecionados: tiposIdsSelecionados }}
              ></ItemLookup>
            ) : (
              <FuncaoLookup
                model={{
                  label: capitalizeFirstLetter(resources.funcao) + ' *',
                  value: model.getModel('funcao').value,
                  requestChange: model.getModel('funcao').requestChange,
                  errors: model.getModel('funcao').errors,
                }}
                clearable={false}
              />
            )}
          </Col>
          {((model.getModel('utilizarAdicionalReducional').value &&
            !model.getModel('tipoCalculoItemCesta').value) ||
            !model.getModel('utilizarAdicionalReducional').value) && (
            <Col md={2}>
              <InputNumber
                precision={2}
                model={{
                  value: model.getModel('peso').value,
                  label: model.getModel('peso').label,
                  requestChange: model.getModel('peso').requestChange,
                }}
                required
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12}>
            <Radio
              disabled={
                model.getModel('tipoItemCesta').value &&
                model.getModel('tipoItemCesta').value.id == 2
              }
              required
              model={model.getModel('valorAplicado')}
              options={model.getModel('opcoesValorAplicado').value}
              labelField="descricao"
              name="opcoesValorAplicado"
              idField="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

@autobind
class CestaIndicadoresItemEdit extends React.Component {
  render() {
    let { intl, parameters, resources } = this.props;

    let schema = Schema.object({
      id: Schema.string(),
      item: Schema.string().label(<FormattedMessage id="label.item" />),
      funcao: Schema.string().label(''),
      valorAplicado: Schema.string().label(
        <FormattedMessage id="label.valorAplicado" />
      ),
      peso: Schema.string()
        .label(<FormattedMessage id={capitalizeFirstLetter(resources.peso)} />)
        .required(),
    });
    return (
      <Edit
        url="/CestaIndicadorItem"
        title={intl.formatMessage({ id: 'item' })}
        formComponent={FormEdit}
        parameters={parameters}
        schema={schema}
        width="70%"
        height={600}
        {...this.props}
      ></Edit>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    configuracao: state.user.configuracao,
    modulosComprados: state.user.licenca.modulos,
  };
}
export default injectIntl(connect(mapStateToProps)(CestaIndicadoresItemEdit));
