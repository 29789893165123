import React from 'react';
import { autobind } from 'core-decorators';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  PontoControleEditForm,
  schema,
} from 'src/paginas/gestao-risco/ponto-controle/edit';
import { Form } from 'src/utils/form';
import { capitalizeFirstLetter } from 'src/utils/string';
import ArquivoUploadAnexos from 'src/componentes/arquivo-upload/anexos';
import EditDialog from 'src/componentes/edit/edit-dialog';
import Tab from 'src/componentes/tabs/tab';
import Tabs from 'src/componentes/tabs/tabs';
import Input from 'src/componentes/input';
import Panel from 'src/componentes/panel';
import PontoControleLookup from 'src/lookups/ControleLookup';
import IndicadoresRiscoControleView from 'src/paginas/gestao-risco/ponto-controle/controle-indicador-risco/view';
import StatusLookup from 'src/lookups/status-risco-ponto-controle';
import { COLUNAS } from 'src/utils/constants';

import Schema from './RiscoPontoControleSchema';
import RevisaoPontoControleListView from 'src/paginas/gestao-risco/risco/RevisaoPontoControle/RevisaoPontoControleListView';
import EfetividadeControleView from 'src/paginas/gestao-risco/risco/RevisaoPontoControle/EfetividadeControle/EfetividadeControleView';
import { getConfiguration } from 'src/services/commonRequests';
import ContentManager from 'src/componentes/content-manager';
import Confirm from 'src/componentes/message-box/confirm';
import { lowerCaseAllLetter } from 'src/utils/string';
import PlanosContingenciaVinculadosView from 'src/paginas/gestao-risco/risco/RiscoEditView/PlanosContingenciaVinculados/PlanosContingenciaVinculadosView';
import InputNumber from 'src/componentes/input-number';
import { Alert } from 'reactstrap';
import { FUNCAO_CONTROLE } from 'src/paginas/gestao-risco/constants/BeltConstants';

@autobind
class RiscoPontoControleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configuracao: {},
      configuracaoRisco: {},
      alterouEfetividade: false,
    };
  }

  componentDidMount() {
    getConfiguration('PontoControleConfiguracao')((configuracao) =>
      this.setState({ configuracao: configuracao })
    )();
    getConfiguration('ItemConfiguracaoRisco')((configuracaoRisco) =>
      this.setState({ configuracaoRisco: configuracaoRisco })
    )();
  }

  handleChangePontoControle(value) {
    if (value == null) {
      this.props.model.getModel('periodicidade').requestChange(null);
      this.props.model.getModel('responsaveis').requestChange(null);
      this.props.model.getModel('anexoObrigatorio').requestChange(null);
      this.props.model.getModel('diasToleranciaRevisao').requestChange(null);
      this.props.model.getModel('tipoTolerancia').requestChange(null);
      this.props.model.getModel('calendario').requestChange(null);
      this.props.model.getModel('tipoEvidencias').requestChange(null);
    } else {
      this.props.model
        .getModel('periodicidade')
        .requestChange(value.periodicidade);
      this.props.model
        .getModel('responsaveis')
        .requestChange(value.responsaveis);
      this.props.model
        .getModel('anexoObrigatorio')
        .requestChange(value.anexoObrigatorio);
      this.props.model
        .getModel('diasToleranciaRevisao')
        .requestChange(value.diasToleranciaRevisao);
      this.props.model
        .getModel('tipoTolerancia')
        .requestChange(value.tipoTolerancia);
      this.props.model.getModel('calendario').requestChange(value.calendario);
      this.props.model
        .getModel('tipoEvidencias')
        .requestChange(value.tipoEvidencias);
    }

    this.props.model.getModel('pontoControle').requestChange({id: value?.id, descricao: value?.descricao});
  }

  setChangeEfetividade() {
    this.setState({ alterouEfetividade: true });
  }

  closeModal(closeModalChild) {
    this.props.handleCloseEdit &&
      this.props.handleCloseEdit(false, closeModalChild);
  }

  pesoHabilitado() {
    return this.props.model.getModel('pesoHabilitado').value;
  }

  render() {
    let {
      model,
      intl,
      resources,
      disabled,
      currenttabIndex,
      parameters,
      item,
    } = this.props;
    let id = model.getModel('id').value;
    const isNew = !id || id == 0;
    const idPontoControle = model.getValue('pontoControle')?.id;

    const isDisabled =  disabled
    ? disabled
    : model && !model.getModel('permissions').getModel('allowEdit').value;
      
    const pontoControleEditForm = new Form({
      component: this,
      schema: schema,
      model: model.getModel('pontoControleEdit').value,
    });

    if (!currenttabIndex) currenttabIndex = 0;

    const funcao =
      model?.getModel('pontoControleEdit')?.value?.funcaoPontoControle;
    const pesoTotal =
      isNew && this.state.configuracao?.avaliarEfetividadeControleSeparadamente
        ? funcao?.id === FUNCAO_CONTROLE.Corretivo
          ? model.getModel('pesoTotalCorretivo').value
          : model.getModel('pesoTotalPreventivo').value
        : model.getModel('pesoTotal').value || 0;

    const pesoItem = model.getModel('peso').value || 0;
    const pesoDistribuido = Number(pesoTotal) + Number(pesoItem);
    const charCount = model?.getModel('descricao')?.value
      ? model.getModel('descricao').value.length
      : 0;
    return (
      <Tabs currenttabIndex={currenttabIndex}>
        <Tab key="1" label={intl.formatMessage({ id: 'label.resumo' })}>
          <Row>
            <Col md={5}>
              <PontoControleLookup
                disabled={isDisabled || id > 0}
                model={{
                  label: capitalizeFirstLetter(resources.pontodecontrole),
                  value: model.getModel('pontoControle').value,
                  requestChange: this.handleChangePontoControle,
                  errors: model.getModel('pontoControle').errors,
                }}
                data={{
                  riscoId: parameters.riscoId,
                  todosTipos: true,
                  pontoControleId: idPontoControle,
                }}
                required
              />
            </Col>

            {this.pesoHabilitado() && (
              <Col md={3}>
                <InputNumber
                  disabled={isDisabled}
                  integer={true}
                  symbolEnd="%"
                  style={{ height: '38px' }}
                  model={{
                    label: intl.formatMessage({ id: 'peso' }) + ' (%)',
                    value: model.getModel('peso').value,
                    requestChange: model.getModel('peso').requestChange,
                    errors: model.getModel('peso').errors,
                  }}
                />
              </Col>
            )}
            <Col md={3}>
              <StatusLookup
                disabled={isDisabled}
                model={model.getModel('status')}
                required
              />
            </Col>
            {id > 0 && (
              <Col md={1}>
                <ArquivoUploadAnexos
                  tipoAnexo={this.props.tiposAnexos.riscoPontoControle}
                  formGroupStyle={{ marginBottom: 0 }}
                  model={model.getModel('arquivos')}
                />
              </Col>
            )}
          </Row>
          {(!isNew || funcao) &&
            this.pesoHabilitado() &&
            pesoDistribuido != 100 && (
              <Row>
                <Col md={12}>
                  <Alert color="warning">
                    <p>
                      {funcao?.id === FUNCAO_CONTROLE.Preventivo
                        ? intl.formatMessage(
                            {
                              id: 'msgValidacaoAtencaoPesoTodosControlesNaoTotalizam100Preventivo',
                            },
                            { pontosdecontrole: resources.pontosdecontrole }
                          ) +
                          ' ' +
                          pesoDistribuido +
                          '%'
                        : intl.formatMessage(
                            {
                              id: 'msgValidacaoAtencaoPesoTodosControlesNaoTotalizam100Corretivo',
                            },
                            { pontosdecontrole: resources.pontosdecontrole }
                          ) +
                          ' ' +
                          pesoDistribuido +
                          '%'}
                    </p>
                  </Alert>
                </Col>
              </Row>
            )}
          <Row>
            <Col md={12}>
              <Input
                rows={2}
                type="textarea"
                disabled={isDisabled}
                model={model.getModel('descricao')}
                maxLength={300}
              />
              <span>
                {intl.formatMessage(
                  { id: 'labelMsgQtdadeCaracteresNome' },
                  { charCount: charCount }
                )}
              </span>
            </Col>
          </Row>
          {id > 0 && (
            <Panel
              header={
                <FormattedMessage
                  id="pontoControle"
                  values={{
                    pontodecontrole: capitalizeFirstLetter(
                      resources.pontodecontrole
                    ),
                  }}
                />
              }
            >
              <PontoControleEditForm
                model={pontoControleEditForm.getModel()}
                resources={resources}
                setChangeEfetividade={this.setChangeEfetividade}
                intl={intl}
                disabled={isDisabled}
                showConfirmMsg={true}
                showRevisoesControle={false}
                riscoControleView={true}
              />
            </Panel>
          )}
          {id > 0 && false && <PlanosContingenciaVinculadosView />}
        </Tab>
        {id > 0 && (
          <Tab
            key="2"
            label={intl.formatMessage(
              { id: 'label.revisaoPontoControle' },
              { revisaodecontrole: resources.revisaodecontrole }
            )}
          >
            <RevisaoPontoControleListView
              id={id}
              tag={COLUNAS.RISCO_REVISOES_PONTO_CONTROLE}
              disabled={isDisabled ? isDisabled : false}
              showDelete={isDisabled ? false : true}
              showNew={isDisabled ? false : true}
              showTitle={false}
              idRiscoPontoControle={id}
              parameters={{
                idRisco: parameters.riscoId,
                idRiscoPontoControle: id,
              }}
            />
          </Tab>
        )}
        {id > 0 &&
          this.state.configuracao &&
          this.state.configuracao.avaliarEfetividadeControle && (
            <Tab
              key="3"
              label={intl.formatMessage(
                { id: 'efetividadeControle' },
                { pontodecontrole: resources.pontodecontrole }
              )}
            >
              <EfetividadeControleView
                funcaoControle={
                  pontoControleEditForm &&
                  pontoControleEditForm.getModel('funcaoPontoControle') &&
                  pontoControleEditForm.getModel('funcaoPontoControle').value
                }
                alterouEfetividade={this.state.alterouEfetividade}
                riscoControleId={id}
                disabled={isDisabled ? isDisabled : false}
                showDelete={isDisabled ? false : true}
                showNew={isDisabled ? false : true}
              />
            </Tab>
          )}
        {id > 0 &&
          this.state.configuracaoRisco &&
          this.state.configuracaoRisco.habilitarIndicadoresRisco &&
          model.getModel('pontoControleEdit').getModel('tipoDeControle')
            .value &&
          model.getModel('pontoControleEdit').getModel('tipoDeControle').value
            .id == 2 && (
            <Tab
              label={intl.formatMessage(
                { id: 'indicadorRisco' },
                { risco: resources.risco }
              )}
              key="4"
            >
              <IndicadoresRiscoControleView
                controleId={idPontoControle}
                closeModal={this.closeModal}
              />
            </Tab>
          )}
      </Tabs>
    );
  }
}

class RiscoPontoControleEdit extends React.Component {
  handleConfirmeBeforeSave(
    item,
    handleSave,
    intl,
    fechar,
    resources,
    modelInicial,
    showErrors,
    afterSave
  ) {
    if (item && item.pontoControle && item.pontoControle.agrupador) {
      const msgId =
        item.pontoControle.totalFilhos <= 1
          ? 'msgVinculacaoAgrupadorControlesRiscoSingular'
          : 'msgVinculacaoAgrupadorControlesRisco';
      ContentManager.addContent(
        <Confirm
          message={intl
            .formatMessage(
              { id: msgId },
              {
                pontosdecontrole: lowerCaseAllLetter(
                  resources.pontosdecontrole
                ),
                pontodecontrole: lowerCaseAllLetter(resources.pontodecontrole),
                risco: lowerCaseAllLetter(resources.risco),
              }
            )
            .replaceAll('{totalfilhos}', `${item.pontoControle.totalFilhos}`)}
          handleConfirm={() => {
            handleSave(fechar);
          }}
        />
      );
    } else {
      handleSave(fechar);
      afterSave && afterSave();
    }
  }

  render() {
    const { resources, parameters } = this.props;

    return (
      <EditDialog
        url="/RiscoPontoControle"
        title={capitalizeFirstLetter(resources.pontodecontrole)}
        parameters={parameters}
        formComponent={RiscoPontoControleForm}
        schema={Schema}
        width="85%"
        maxHeight="93%"
        handleConfirmeBeforeSave={this.handleConfirmeBeforeSave}
        resources={resources}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    tiposAnexos: state.user.tiposAnexos,
  };
}
export default injectIntl(connect(mapStateToProps)(RiscoPontoControleEdit));
