import React from 'react';
import { autobind } from 'core-decorators';
import Dialog from '../../../componentes/dialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../componentes/button';

const PowerBI = React.lazy(() => import('./embed-power-bi'));
@autobind
class EmbedPowerBi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullScreen: false,
    };
  }

  handleClose() {
    this.refs.dialog.close();
    setTimeout(() => {
      this.props.remove && this.props.remove();
    }, 300);
  }

  setFullscreen() {
    this.setState({ fullScreen: true });
  }

  render() {
    const {
      relatorioPBI,
      embedURL,
      workspacePBI,
      filtros,
      embedToken,
      usarAutenticacaoMicrosoft,
    } = this.props;

    let actions = [
      <div key="edit-toolbar">
        <Button className="pull-right" onClick={this.setFullscreen}>
          {<FormattedMessage id="telaCheia" />}
        </Button>
      </div>,
    ];

    return (
      <Dialog
        width="75%"
        minHeight="99%"
        onRequestClose={this.handleClose}
        h100
        actions={actions}
        ref="dialog"
      >
        <PowerBI
          relatorioPBI={relatorioPBI}
          embedURL={embedURL}
          workspacePBI={workspacePBI}
          filtros={filtros}
          embedToken={embedToken}
          fullScreen={this.state.fullScreen}
          usarAutenticacaoMicrosoft={usarAutenticacaoMicrosoft}
        />
      </Dialog>
    );
  }
}
export default injectIntl(EmbedPowerBi);
