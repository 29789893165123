import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';

@autobind
class TipoFiltroData extends React.Component {
  render() {
    let { clearable = false } = this.props;
    return <Lookup clearable={clearable} labelKey="descricao" valueKey="id" url="/TipoFiltroDataLookup" {...this.props} />;
  }
}

export default TipoFiltroData;
