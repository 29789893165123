import React from 'react';
import { autobind } from 'core-decorators';
import { FormGroup } from 'reactstrap';
import MdiIcon from '../mdi-icon';
import ContentManager from '../content-manager';
import DropZoneAnexos from './dropzone/anexos';
import FileBucket from './dropzone/file-bucket';

@autobind
class ArquivoUploadAnexos extends React.Component {

  static defaultProps = {
    formGroupStyle: {},
    iconSize: 20,
    colorBadge: '#ddd',
    disabledClick: false,
    tipoAnexo: null,
    required: false,
    onlyRead: false,
    onlyOneFile: false,
    isFileBucket: false,
    useChunks: false,
  };

  handleClick() {
    if (!this.props.disabledClick) {
      if (this.props.isFileBucket) {
        ContentManager.addContent(
          <FileBucket
            model={this.props.model}
            onUploadFiles={this.handleOnUploadFiles}
            disabled={this.props.disabled}
            anexoObrigatorio={this.props.anexoObrigatorio}
            handleAutomaticSave={this.props.handleAutomaticSave}
            onlyRead={this.props.onlyRead}
            onlyOneFile={this.props.onlyOneFile}
          />
        );
      } else {
        ContentManager.addContent(
          <DropZoneAnexos
            model={this.props.model}
            onUploadFiles={this.handleOnUploadFiles}
            disabled={this.props.disabled}
            tipoAnexo={this.props.tipoAnexo}
            anexoObrigatorio={this.props.anexoObrigatorio}
            handleAutomaticSave={this.props.handleAutomaticSave}
            onlyRead={this.props.onlyRead}
            onlyOneFile={this.props.onlyOneFile}
            useChunks={this.props.useChunks}
          />
        );
      }
    }
  }

  handleOnUploadFiles(arquivos) {
    if (this.props.model.requestChange)
      this.props.model.requestChange(arquivos);

    if (this.props.onChange) this.props.onChange(arquivos);
  }

  renderRightLabel() {
    const {
      model,
      required,
      anexoObrigatorio,
      maxWidth = '50px',
      classNameFormGroup = 'd-flex inline pt-4',
    } = this.props;

    return (
      <FormGroup
        className="popupOpen"
        style={this.props.formGroupStyle}
        validationstate={model.errors ? 'error' : null}
      >
        <div className={classNameFormGroup} style={{ maxWidth: maxWidth }}>
          <MdiIcon
            size={this.props.iconSize}
            icon="attachment"
            style={{ cursor: 'pointer' }}
            onClick={this.handleClick}
          ></MdiIcon>
          {anexoObrigatorio && <span> {anexoObrigatorio ? '*' : ''} </span>}
          {model.value && model.value.length > 0 && (
            <span
              style={{
                backgroundColor: this.props.colorBadge,
                marginTop: 4,
                height: 18,
              }}
              className="badge"
            >
              {model.value.length}{' '}
            </span>
          )}
          {model.label && (
            <label className="control-label pl-1" style={{ marginTop: '6px' }}>
              {model.label} {required ? '*' : ''}
            </label>
          )}
        </div>
      </FormGroup>
    );
  }

  renderItem() {
    const { model, required, anexoObrigatorio } = this.props;

    return (
      <>
        {model.label && (
          <label className="control-label">
            {model.label} {required ? '*' : ''}
          </label>
        )}
        <div
          style={{
            maxWidth: '50px',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <MdiIcon
            size={this.props.iconSize}
            icon="attachment"
            style={{ cursor: 'pointer' }}
            onClick={this.handleClick}
          ></MdiIcon>
          {anexoObrigatorio && <span> {anexoObrigatorio ? '*' : ''} </span>}
          {model.value && model.value.length > 0 && (
            <span
              style={{
                backgroundColor: this.props.colorBadge,
                color: '#444343',
              }}
              className="badge"
            >
              {model.value.length}{' '}
            </span>
          )}
        </div>
      </>
    );
  }

  render() {
    const { model, rightLabel } = this.props;

    return (
      <FormGroup
        className="popupOpen"
        style={this.props.formGroupStyle}
        validationstate={model.errors ? 'error' : null}
      >
        {rightLabel ? this.renderRightLabel() : this.renderItem()}
      </FormGroup>
    );
  }
}

export default ArquivoUploadAnexos;
