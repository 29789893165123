import React from 'react';
import { autobind } from 'core-decorators';
import { FormGroup, FormFeedback } from 'reactstrap';
import DatePicker from '../date-picker-custom';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { connect } from 'react-redux';

@autobind
class Date extends React.Component {
  static defaultProps = {
    timeFormat: false,
    disabled: false,
    forceRefresh: 0,
    disableFutureDates: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      model: this.props.model,
    };
  }

  componentDidMount() {
    moment.locale(this.props.lang);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.model.value !== this.props.model.value || newProps.forceRefresh !== this.props.forceRefresh) {
      this.setState({
        model: {
          value: newProps.model.value,
        },
      });
    }
  }

  handleBlur(e) {
    /*if (e) {
      !moment(e, this.getFormat(), true).isValid() &&
      this.props.model.requestChange(moment(new Date()).format(this.forceFormat(e)));//'MM/DD/YYYY' before time format
    } else {
      this.props.model.requestChange(null);
    }*/
  }

  handleChange(e) {
    const { timeFormat } = this.props;
    if (moment(e, this.getFormat(), true).isValid() || e == null || e == '') {
      const inputDate = moment(e, this.getFormat(), true); // Data inserida
      const today = moment().endOf('day');
      
      if (this.props.disableFutureDates && inputDate.isAfter(today)) {
        this.handleClear();
      }

      if (
        !this.props.model.validateBeforeChange ||
        this.props.model.validateBeforeChange(
          e != null && e != '' ? e.toDate && e.toDate() : null
        )
      ) {
        if (!timeFormat)
          this.props.model.requestChange &&
            this.props.model.requestChange(
              e != null && e != '' ? e.toDate && e.toDate() : null
            );
        else if (
          this.props.model.requestChange &&
          e != null &&
          e != '' &&
          e.toDate
        )
          this.props.model.requestChange(e.toDate());

        if (!timeFormat) {
          this.setState({
            model: {
              value: e != null && e != '' ? e.toDate && e.toDate() : null,
            },
          });
        } else if (e != null && e != '' && e.toDate) {
          this.setState({
            model: {
              value: e.toDate(),
            },
          });
        }
      } else this.setState({});
    }
  }

  getFormat() {
    return this.props.format
      ? this.props.format
      : this.props.month
      ? 'MM/YYYY'
      : 'DD/MM/YYYY';
  }

  handleClear() {
    this.setState(
      {
        model: {
          value: null,
        },
      },
      () => this.props.model.requestChange(null)
    );
  }

  // renderInput(props){
  //     return (
  //       <InputGroup>
  //         <Input style={{borderColor: this.props.model.errors ? 'rgb(240, 80, 80)' : ''}} {...props} />
  //         <InputGroupAddon addonType="append">
  //           <InputGroupText style={{cursor: 'pointer'}} onClick={this.handleClear} >
  //             &times;
  //           </InputGroupText>
  //         </InputGroupAddon>
  //       </InputGroup>
  //     );
  // }

  render() {
    let {
      model,
      required,
      disabled,
      placeholder,
      id,
      timeFormat,
      month,
      year,
      height,
      ...rest
    } = this.props;
    let dateFormat,
      viewMode = '';
    if (month) {
      dateFormat = 'MM/YYYY';
      viewMode = 'months';
    } else if (year) {
      dateFormat = 'YYYY';
      viewMode = 'years';
    } else {
      viewMode = 'days';
    }

    return (
      <FormGroup validationstate={model.errors ? 'error' : null} {...rest}>
        {model.label && (
          <label className="control-label">
            {model.label}
            {required && ' * '}
          </label>
        )}
        {
          <DatePicker
            disableFutureDates={this.props.disableFutureDates}
            placement={this.props.placement}
            autoFormatDate={this.props.autoFormatDate}
            locale={this.props.lang}
            inputProps={{
              placeholder: placeholder,
              disabled: disabled,
              required: required,
              id: id,
            }}
            renderInput={this.renderInput}
            dateFormat={dateFormat}
            month={this.props.month}
            viewMode={viewMode}
            timeFormat={timeFormat}
            closeOnSelect
            onChange={this.handleChange}
            onFocus={this.handleCalendarFocus}
            openCalendar={this.handleOpenCalendar}
            value={this.state.model.value}
            handleClear={this.handleClear}
            style={{
              height: height ? height : 33,
              borderColor: this.props.model.errors ? 'rgb(240, 80, 80)' : '',
              padding: 5,
              textAlign: 'center',
            }}
            props={this.props}
            disabled={disabled}
          />
        }
        {model.erros && <FormFeedback>{model.erros}</FormFeedback>}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.user && state.user.idioma,
  };
}

export default connect(mapStateToProps)(Date);
