import React from 'react';
import Lookup from 'src/componentes/select2';

const TipoFluxoAprovacaoLookup = ({ ...rest }) => (
  <Lookup
    labelKey="descricao"
    valueKey="id"
    url="/TipoFluxoAprovacaoLookup"
    clearable={false}
    {...rest}
  />
);

export default TipoFluxoAprovacaoLookup;
