import React from 'react';
import Lookup from '../componentes/select2';
import { autobind } from 'core-decorators';
import MdiIcon from '../componentes/mdi-icon';

@autobind
class IconeOption extends React.Component {
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  render() {
    let iconStyle = {
      borderRadius: 3,
      display: 'inline-block',
      marginRight: 10,
      position: 'relative',
      top: -2,
      verticalAlign: 'middle',
    };
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
      >
        <MdiIcon icon={this.props.option.icone} size={20} style={iconStyle} />
        {this.props.children}
      </div>
    );
  }
}

export default class Melhor extends React.Component {
  static defaultProps = {
    clearable: false,
  };

  renderValueComponent(item) {
    return (
      item.data && (
        <span style={{ marginLeft: 7 }}>
          <MdiIcon
            icon={item.data.icone}
            size={20}
            style={{ borderRadius: 3, display: 'inline-block', marginRight: 10, position: 'relative', top: 2, verticalAlign: 'middle' }}
          />
          {item.children}
        </span>
      )
    );
  }

  render() {
    return (
      <Lookup
        optionComponent={IconeOption}
        valueComponent={this.renderValueComponent}
        labelKey="descricao"
        valueKey="id"
        url="/MelhorLookup"
        clearable={this.props.clearable}
        {...this.props}
      />
    );
  }
}
