import React from 'react';
import { autobind } from 'core-decorators';
import EditDialog from '../../../componentes/edit/edit-dialog';
import { Schema } from '../../../utils/form';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import DatePicker from '../../../componentes/date-picker';
import { capitalizeFirstLetter } from '../../../utils/string';
import FuncaoLookup from '../../../lookups/funcao';
import Input from 'src/componentes/input';

let schema = Schema.object({
  funcao: Schema.string()
    .label(<FormattedMessage id="funcao" />)
    .required(),
  dataInicio: Schema.string()
    .label(<FormattedMessage id="dataInicio" />)
    .required(),
  dataTermino: Schema.string().label(<FormattedMessage id="dataTermino" />),
  idIntegracao: Schema.string().label(<FormattedMessage id="idIntegracao" />),
});

@autobind
class FormEdit extends React.Component {
  handleChange(e) {
    this.props.model.requestChange &&
      this.props.model.requestChange(e.target.checked);
  }

  render() {
    let { model, resources, podeEditar } = this.props;
    return (
      <div>
        <Row>
          <Col md={5}>
            <div style={{ marginBottom: 15 }}>
              <FuncaoLookup
                model={{
                  label: capitalizeFirstLetter(resources.funcao),
                  value: model.getModel('funcao').value,
                  requestChange: model.getModel('funcao').requestChange,
                  errors: model.getModel('funcao').errors,
                }}
                clearable={false}
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div style={{ marginBottom: 15 }}>
              <DatePicker
                month
                model={model.getModel('dataInicio') || podeEditar}
                required
              />
            </div>
          </Col>
          <Col md={3}>
            <div style={{ marginBottom: 15 }}>
              <DatePicker
                month
                model={model.getModel('dataTermino') || podeEditar}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

class FuncaoEdit extends React.Component {
  render() {
    let { resources } = this.props;

    return (
      <EditDialog
        url="/ColaboradorFuncao"
        title={capitalizeFirstLetter(resources.funcao)}
        formComponent={FormEdit}
        schema={schema}
        width="60%"
        maxHeight="90%"
        resources={resources}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
  };
}

export default injectIntl(connect(mapStateToProps)(FuncaoEdit));
