import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Panel from 'src/componentes/panel-collapse';
import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';

import Edit from './CursosExtracurricularesEditView';
import useAuth from 'src/hooks/useAuth';
import Checkbox from 'src/componentes/checkbox';
import { capitalizeFirstLetter } from 'src/utils/string';
import ContentManager from 'src/componentes/content-manager';

function CursosExtracurriculares({ disabled, model,isRequiredCursosExtracurriculares,naoPossuiCursosCadastrados, ...rest }) {
  const { terms: resources } = useAuth();
  const intl = useIntl();
  const handleRemove = (item, index) => {
    model.value.splice(index, 1);
    model.requestChange(model.value);
  };

  const handleSaveItem = (saved, isNew, item, index) => {
    if (saved) {
      let novaLista = model.value;

      if (isNew) {
        if (novaLista && novaLista.length > 0) novaLista.push(item);
        else novaLista = [].concat(item);
      } else novaLista[index] = item;

      model.requestChange(novaLista);
    }
  };

  const renderData = (item) => {
    if (!item.dataConclusao) return;

    return <span>{moment(item.dataConclusao).format('DD/MM/YYYY')}</span>;
  };

  const labelCursosExtracurriculares = () => {
    return (
      <div style={{ display: 'flex', gap: 15, padding: 0, height: 0 }}>
        <span>
          {capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'cursosExtracurriculares' },
              { cursosextracurriculares: resources.cursosextracurriculares }
            )
          )}
        </span>
        <p> - </p>
        <span style={{ position: 'relative', bottom: 0 }}>
          <Checkbox
            model={{
              label: intl.formatMessage({ id: 'naoPossuoCursosACadastrar' }),
              value: naoPossuiCursosCadastrados.value,
              requestChange: naoPossuiCursosCadastrados.requestChange,
            }}
          />
        </span>
      </div>
    );
  };

  const editComponent = (props) => {
    return <Edit isRequired={isRequiredCursosExtracurriculares} {...props} {...rest}/>;
  }

  return (
    <Panel open={false} header={labelCursosExtracurriculares()}>
      <List
        local
        showTitle={false}
        dataSource={model.value}
        actionsWidth={30}
        handleSaveItem={handleSaveItem}
        handleRemove={handleRemove}
        editComponent={(props) => editComponent(props)}
        showPage={false}
        showSearch={false}
        showNew={!disabled}
        {...rest}
      >
        <ListColumn
          headerText={intl.formatMessage({ id: 'curso' })}
          valueField="curso"
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'instituicaoEnsino' })}
          valueField="instituicaoEnsino"
        ></ListColumn>
        <ListColumn
          headerText={intl.formatMessage({ id: 'dataConclusao' })}
          valueFunction={renderData}
        ></ListColumn>
      </List>
    </Panel>
  );
}

export default CursosExtracurriculares;
