import React from 'react';
import Cropper from 'react-cropper';
import Button from '../../button';
import Dialog from '../../dialog';
import { autobind } from 'core-decorators';
import { keydown, KEYS } from '../../../utils/keydown-decorator';
import { ButtonToolbar } from 'reactstrap';
import Dragger from 'react-dropzone';
import MdiIcon from '../../mdi-icon';
import http from 'src/services/httpService';
import 'cropperjs/dist/cropper.css';
import './style/file.scss';
import { injectIntl } from 'react-intl';
import LoadingContainer from '../../loading-container';
import errorHandler from 'src/utils/error-handler';

@autobind
class SelectImage extends React.Component {
  static defaultProps = {
    tipoAnexo: null,
    subtitle: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      arquivo: null,
      isUploading: false,
    };
  }

  handleChange(files) {
    if (!files || files.length == 0) return;

    this.setState({ arquivo: files[0] });
  }

  _crop() {}

  @keydown(KEYS.ESC)
  handleCancelClick() {
    this.refs.dialog.close();
  }

  @keydown(KEYS.ENTER)
  handleConfirmClick() {
    if (
      typeof this.refs.cropper.getCroppedCanvas() === 'undefined' ||
      !this.state.arquivo
    ) {
      return;
    }

    this.setState({ isUploading: true });

    // Transforma a imagem em Arquivo
    this.refs.cropper.getCroppedCanvas().toBlob((file) => {
      var data = new FormData();
      data.append('tipoAnexo', this.props.tipoAnexo);
      data.append('idReferencia', this.props.idReferencia || null);
      data.append('file', file, this.state.arquivo.name);

      http
        .post('ArquivoSistema/uploadArquivo', data)
        .then((result) => {
          // Propaga o Resultado
          if (this.props.model && this.props.model.requestChange)
            this.props.model.requestChange(result.data);

          // Fecha o Modal
          this.refs.dialog.close();
        })
        .catch((error) => {
          this.setState({
            isUploading: false,
          });
          errorHandler(error);
        });
    });
  }

  render() {
    let { title, subtitle, intl, maxSize, aspectRatio, ...other } = this.props;

    const actions = [
      <ButtonToolbar key="toolbar-img">
        <Button
          onClick={this.handleCancelClick}
          className="pull-left"
          leftIcon="close"
          color="secondary"
        >
          {intl.formatMessage({ id: 'label.fechar' })}
        </Button>
        <Button
          onClick={this.handleConfirmClick}
          className="ml-2"
          leftIcon="content-save-outline"
          type="primary"
        >
          {intl.formatMessage({ id: 'label.salvarEFechar' })}
        </Button>
      </ButtonToolbar>,
    ];
    return (
      <Dialog
        {...other}
        ref="dialog"
        title={title}
        width={600}
        height={450}
        actions={actions}
        padContent
        onRequestClose={this.handleCancelClick}
      >
        <LoadingContainer isLoading={this.state.isUploading}>
          {subtitle}
          <div className="pb-3">
            {!this.state.arquivo && (
              <Dragger
                maxSize={maxSize}
                multiple={false}
                accept={'.jpg, .jpeg, .png'}
                onDrop={this.handleChange}
                style={{
                  width: '100%',
                  height: 220,
                  border: '1px dashed #DDD',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  padding: 15,
                }}
              >
                <p>
                  {intl.formatMessage({
                    id: 'label.cliqueOuArrasteOArquivoNestaAreaParaAdicionalo',
                  })}
                </p>
                <MdiIcon icon="attachment"></MdiIcon>
              </Dragger>
            )}
            {this.state.arquivo && (
              <Cropper
                style={{ height: 220, width: '100%', overflow: 'auto' }}
                aspectRatio={aspectRatio}
                preview=".img-preview"
                guides={false}
                src={this.state.arquivo && this.state.arquivo.preview}
                ref="cropper"
                crop={this._crop}
              />
            )}
          </div>
        </LoadingContainer>
      </Dialog>
    );
  }
}

export default injectIntl(SelectImage);
